import * as React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ILoginViewModel } from '../../types/ILoginViewModel';
import { MSALAuthenticated } from './MSALAuthenticated';
import { Redirect } from 'react-router-dom';


interface IMSALCallbackCatchProps {
    login: (viewModel: ILoginViewModel, redirect: any) => void;
    redirect?: any;
    isSignedIn: boolean;
}
interface IMSALCallbackCatchState {
}
export class MSALCallbackCatch extends React.Component<IMSALCallbackCatchProps, IMSALCallbackCatchState>{
    viewData;
    appVersionNumber;
    constructor(props: IMSALCallbackCatchProps) {
        super(props);
    }


    componentDidMount() {
    }

    render() {
        const { login, redirect, isSignedIn } = this.props

        return (
            <div>
                <AuthenticatedTemplate>
                    <MSALAuthenticated login={login} redirect={redirect} isSignedIn={isSignedIn} />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Redirect to={{ pathname: "/login" }} />
                </UnauthenticatedTemplate>
            </div>
        );
    }
}