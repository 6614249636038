import { IShoppingCartState } from "../../redux/types/IShoppingCartState";
import { IValidationMessage } from "../types/IValidationMessage";

export class LicenseValidator {

    public static validateLicenseRequest(license: IShoppingCartState): IValidationMessage[]{
        let licenseValidationMessages: IValidationMessage[];
        licenseValidationMessages = [];
        if (license.userName.value === undefined || license.userName.value === '') {
            licenseValidationMessages.push({ messageKey: "licenseAffiliatedUserValidationMessage" });
        }
        if (license.shoppingCartName.value === undefined || license.shoppingCartName.value === '') {
            licenseValidationMessages.push({ messageKey: "licenseCartNameValidationMessage" });            
        }
        if (license.uses.value === undefined || license.uses.value === '') {
            licenseValidationMessages.push({ messageKey: "licenseUsesValidationMessage" }); 
        }
        if (license.commercialUse.value === undefined || !license.commercialUse.value) {
            licenseValidationMessages.push({ messageKey: "licenseCommercialUseValidationMessage" });
        }
        if (license.geographicalArea.value === undefined || !license.geographicalArea.value) {
            licenseValidationMessages.push({ messageKey: "licenseGeographicalAreaValidationMessage" });
        }
        if (license.sector.value === undefined || !license.sector.value) {
            licenseValidationMessages.push({ messageKey: "licenseSectorValidationMessage" });
        }
        if (license.userTypes.value === undefined || !license.userTypes.value) {
            licenseValidationMessages.push({ messageKey: "licenseUserTypesValidationMessage" });
        }

        if (license.licenseRequests[0].licenseRequestWorks.filter(w => (w.percentageVA + w.percentageVE) != 100).length > 0) {
            licenseValidationMessages.push({ messageKey: "licenseRightsHoldersSharesValidationMessage" });
        }

        if (license.licenseRequests[0].licenseRequestWorks.filter(w => ((w.percentageVAA + w.percentageVAT) > w.percentageVA) ).length > 0) {
            licenseValidationMessages.push({ messageKey: "licenseAuthorSharesValidationMessage" });
        }

        return licenseValidationMessages;
    }
}