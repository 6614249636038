// Action names
type SHOW_MODAL_TYPE = "SHOW_MODAL";
type HIDE_MODAL_TYPE = "HIDE_MODAL";
type UPDATE_COMMENT_TYPE = "UPDATE_COMMENT";

// Action types
export interface ShowModal {
    type: SHOW_MODAL_TYPE;
    payload: {
        modalContent: string;
        modalProps: any;
        modalComponentInstance: string;
        displayModalCloseButton: boolean;
        title: string;
        modalMessage?: string;
        isReadOnly?: boolean;
        key?: string;
        index?: number;
    };
}

export interface HideModal {
    type: HIDE_MODAL_TYPE;
}

export interface UpdateComment {
    type: UPDATE_COMMENT_TYPE;
    payload: {
        value: any;
    };
}

export type ActionTypes = ShowModal | HideModal | UpdateComment;

// Action names
export const SHOW_MODAL: SHOW_MODAL_TYPE = "SHOW_MODAL";
export const HIDE_MODAL: HIDE_MODAL_TYPE = "HIDE_MODAL";
export const UPDATE_COMMENT: UPDATE_COMMENT_TYPE = "UPDATE_COMMENT";
