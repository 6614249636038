import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { setDataSource } from "../../redux/reducers/RepertoireReducer";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import DraftWorksPage from "../draftWorks/DraftWorksPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { IAllRegisteredWorkSearchQuery } from "../types/IAllRegisteredWorkSearchQuery";
import { IAllRegisteredWorkSearchResult } from "../types/IAllRegisteredWorkSearchResult";
import { ExpandWorkResult } from "../../redux/actions/MembersPortalActions";

export interface IAllRegisteredWorksPageProps {
    allRegisteredWorksPageData: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchAllRegisteredWorks?: (query: IAllRegisteredWorkSearchQuery) => void;
    allRegisteredWorkSearchResults: IAllRegisteredWorkSearchResult[];
    searchSuccessful: boolean;
    registeredWorksMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getAllRegisteredWorkDetails: (dataSource: string, workID: number, lookupValues: ILookupDictionary, memberWorkMaintenanceDataViewData: IMembersPortalComponentDataItem) => void;
    addNewDraftWorks: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, registeredWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    setDataSource: typeof setDataSource;
    dataSource: string;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    memberPortalSearchType: MemberPortalSearchTypeItem;
    expandWorkResult?: (workResult: number) => void;
    expandedWorkResults?: number[];
    expandAllResults: () => void;
    expandAll: boolean;
    currentUserNameNumber?: string;
}

const AllRegisteredWorksPage = (props: IAllRegisteredWorksPageProps) => {
    // TODO: EB 2023/04/03. DraftWorksPage should be replaced by a new component like
    // MemberWorksPage in order for the reutilization to have more sense.
    let memberWorksPage = DraftWorksPage

    return memberWorksPage({
        draftWorksPageData: props.allRegisteredWorksPageData,
        searchViewData: props.searchViewData,
        searchResultsTableData: props.searchResultsTableData,
        searchDraftWorks: props.searchAllRegisteredWorks,
        draftWorkSearchResults: null,
        memberWorkSearchResults: props.allRegisteredWorkSearchResults,
        searchSuccessful: props.searchSuccessful,
        draftWorksMaintenanceGeneralDataViewData: props.registeredWorksMaintenanceGeneralDataViewData,
        lookupValues: props.lookupValues,
        indexOfFirstResult: props.indexOfFirstResult,
        indexOfLastResult: props.indexOfLastResult,
        resultsPerPage: props.resultsPerPage,
        currentPage: props.currentPage,
        resetPagination: props.resetPagination,
        updatePagination: props.updatePagination,
        getDraftWorkDetails: props.getAllRegisteredWorkDetails,
        addNewDraftWorks: props.addNewDraftWorks,
        sortSearchResults: props.sortSearchResults,
        defaultActiveAccordions: props.defaultActiveAccordions,
        allResultsPerPage: props.allResultsPerPage,
        updateUserPreferences: props.updateUserPreferences,
        disabled: props.disabled,
        roles: props.roles,
        saveRoles: props.saveRoles,
        setDataSource: props.setDataSource,
        dataSource: props.dataSource,
        activeTab: props.activeTab,
        tabs: props.tabs,
        memberPortalSearchType: props.memberPortalSearchType,
        expandWorkResult: props.expandWorkResult,
        expandedWorkResults: props.expandedWorkResults,
        expandAllResults: props.expandAllResults,
        expandAll: props.expandAll,
        currentUserNameNumber: props.currentUserNameNumber,
})
}

export default AllRegisteredWorksPage;
