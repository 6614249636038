import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IValidationMessage } from "../../../../repertoire/types/IValidationMessage";
import { MessageBanner, MessageType } from "../../../../repertoire/components/messageBanner/MessageBanner";
import { isNullOrUndefined } from "../../../../util/tools";

export interface ISaveResultProps {
    componentInstance: string;
    saveSuccess?: boolean;
    saveResultData: IRepertoireComponentDataItem;
    messages: IValidationMessage[];
    resetMessageBanner: () => void;
    approvedSuccess?: boolean;
    rejectedSuccess?: boolean;
}

export default class UsageSaveResult extends React.Component<
    ISaveResultProps
    > {

    constructor(props) {
        super(props)
    }

    renderValidationMessages(): string[] {
        const { messages, saveResultData } = this.props;
        const validationMessages: string[] = [];

        if (messages && messages.length) {
            messages.forEach(e => {
                try {
                    let message = saveResultData.fields.find(x => x.name.toString() === e.messageKey).data
                    if (e.additionalInfo) {
                        message = message + ' ' + e.additionalInfo;
                    }
                    validationMessages.push(message);
                }
                catch (error) {
                    let message = e.messageKey
                    if (e.additionalInfo) {
                        message = message + ' ' + e.additionalInfo;
                    }
                    validationMessages.push(message);
                }
            })
        }
        else {
            validationMessages.push(saveResultData.fields.find(x => x.name.toString() === 'saveFailedMessage').data);
        }

        return validationMessages;
    }

    render() {
        const {
            saveSuccess,
            saveResultData,
            resetMessageBanner,
            componentInstance,
            approvedSuccess,
            rejectedSuccess
        } = this.props;

        if (isNullOrUndefined(saveSuccess)) {
            return <div />;
        }
        else if (saveSuccess === false) {
            return <MessageBanner
                resetMessageBanner={resetMessageBanner}
                messageType={MessageType.Danger}
                messages={this.renderValidationMessages()} />
        }
        else if(approvedSuccess) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'approveSuccess').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else if(rejectedSuccess) {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'rejectSuccess').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
        else {
            const messages: string[] = [saveResultData.fields.find(x => x.name.toString() === 'saveSuccessMessage').data];
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Success} messages={messages} />
        }
    }
}