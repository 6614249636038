import React from 'react';
import { ContainerDetailsWindow } from '../../../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindow';
import { IComponentDataItem } from '../../../../core/types/IComponentDataItem';
import { IStoragePathItemData } from '../../../../dataingestion/types/IStoragePathItemData';
import { CleanUploadFilesState, addFileToUpload, fileMetadataChanged, setInDropZone, setProgressUploadFileProccess, sortColumn } from '../../../../redux/reducers/DataIngestionReducer';
import {
    addDroppedFile as mpAddDroppedFile,
    cleanDroppedFilesRepertoireState as mpCleanDroppedFilesRepertoireState,
    setProgressUploadFileRepertoireProccess as mpSetProgressUploadFileRepertoireProccess
} from "../../../../redux/reducers/MembersPortalReducer";
import IDroppedFiles from '../../../../redux/types/IDroppedFiles';
import { IFileSystem } from '../../../../dataingestion/types/IFileSystem';
import { ITreeData } from '../../../types/ITreeData';
import { IAttachedFile } from '../../../../redux/types/IAttachedFile';
import { IUsageGroupState } from '../../../../redux/types/IUsageGroupState';
import { DataSource } from '../../../types/DataSource';
import { RepertoireService } from '../../../../redux/services/RepertoireService';
import IFile from '../../../../redux/types/IFiles';
import { IAddUsageAttachmentModel } from '../../../../redux/types/IAddUsageAttachmentModel';
import { IRemoveUsageAttachment } from '../../../types/IRemoveUsageBlobAttachment';
import { USAGE_PAGE_VIEW } from '../../../Consts';
import { IPaginationDetail } from '../../../../redux/types/IPaginationDetail';
import { ILookupDictionary } from '../../../../lookup/types/ILookupDictionary';
import { ITabReduxItem } from '../../../../redux/types/ITabReduxItem';
import { addDroppedFile, cleanDroppedFilesRepertoireState, setProgressUploadFileRepertoireProccess } from '../../../../redux/reducers/RepertoireReducer';

export interface IUsageMaintenanceAttachmentsGridProps {
    containerDetailsWindowComponentData: IComponentDataItem;
    storagePathItemData: IComponentDataItem;
    attachedFiles: IStoragePathItemData[];
    usageGroup: IUsageGroupState;
    dataSource: string;
    paginationDetails: IPaginationDetail[];
    lookupValues: ILookupDictionary;
    tabs: ITabReduxItem[];
    activeTab: number;
    isDropZone: boolean;
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    addFileToUpload: typeof addFileToUpload;
    setInDropZone: typeof setInDropZone;
    setProgressUploadFileProcess: typeof setProgressUploadFileProccess;
    sortColumn: typeof sortColumn;
    refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    updatePagination?: (resultsPerPage: number, currentPage: number, section: string) => void;
    removeAttachmentIfExists?: (attachedFile: IAttachedFile, source: string) => void;
    addDroppedFile: typeof addDroppedFile;
    addDroppedFileRepertoire?: typeof addDroppedFile | typeof mpAddDroppedFile;
    setProgressUploadFileRepertoire?: typeof setProgressUploadFileRepertoireProccess | typeof mpSetProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire?: typeof cleanDroppedFilesRepertoireState | typeof mpCleanDroppedFilesRepertoireState;
    refreshUsageDetails: (usageId: string, activeTabItem: ITabReduxItem) => void;
}

const UsageMaintenanceAttachmentsGrid = (props: IUsageMaintenanceAttachmentsGridProps) => {

    const entityID = parseInt(props.usageGroup.id);

    const uploadUsageAttachment = (fileName: string, id: number, dataSource: DataSource) => {
        const model: IAddUsageAttachmentModel = {
            attachedFile: {
                fileName: fileName,
                usageGroupID: props.usageGroup.id
            },
            dataSource: dataSource
        }
        return Promise.resolve(
            RepertoireService.addUsageAttachment(model)
                .then((res: IAttachedFile) => {
                    return res;
                })
                .catch((err: any) => {
                    const newFile: IFile = {
                        isInProgress: true,
                        message: err,
                        name: fileName,
                        size: 0
                    }
                    props.setProgressUploadFileProcess(err, newFile, true);
                    return false;
                })
        )
    }

    const removeAttachmentFromMaintenanceScreen = (fullName: string, id: number, dataSource: DataSource, guid: string, index: number) => {
        const { refreshUsageDetails, usageGroup, tabs, activeTab } = props;
        const attachedFile: IAttachedFile = {
            fileName: fullName,
            usageGroupID: props.usageGroup.id
        }
        const model: IRemoveUsageAttachment = {
            Guid: guid,
            Source: dataSource,
            usageGroupID: usageGroup.id
        }

        RepertoireService.removeUsageBlobAttachment(model)
            .then(res => {
                if (res) {
                    RepertoireService.removeUsageAttachment({ attachedFile: attachedFile, dataSource: dataSource })
                        .then((res: boolean) => {
                            if (res) {
                                refreshUsageDetails(usageGroup.id, tabs[activeTab]);
                            }
                        })
                }
            })
    }



    return (
        <ContainerDetailsWindow
            addFileToUpload={props.addFileToUpload}
            cleanUploadFilesState={props.cleanUploadFilesState}
            containerDetailsWindowComponentData={props.containerDetailsWindowComponentData}
            currentDirectory={"usage"}
            pageContentIsActive={true}
            droppedFiles={props.droppedFiles}
            isDropZone={props.isDropZone}
            selectedFileSystem={props.selectedFileSystem}
            setInDropZone={props.setInDropZone}
            setProgressUploadFileProccess={props.setProgressUploadFileProcess}
            sortColumn={props.sortColumn}
            storagePathItemData={props.storagePathItemData}
            storagePaths={props.attachedFiles}
            refreshDirectory={props.refreshDirectory}
            isRepertoireModule={true}
            addRepertoireAttachmentToState={props.addRepertoireAttachmentToState}
            entityID={entityID}
            uploadAttachment={uploadUsageAttachment}
            removeAttachmentIfExists={props.removeAttachmentIfExists}
            addDroppedFileRepertoire={props.addDroppedFile}
            attachmentsDropped={props.droppedFiles}
            cleanDroppedFilesRepertoire={props.cleanDroppedFilesRepertoire}
            setProgressUploadFileRepertoire={props.setProgressUploadFileRepertoire}
            component={USAGE_PAGE_VIEW}
            removeAttachmentMaintenanceScreen={removeAttachmentFromMaintenanceScreen}
            displayRemove={true}
            paginationDetails={props.paginationDetails}
            updatePagination={props.updatePagination}
            lookupValues={props.lookupValues}
            tabs={props.tabs}
            activeTab={props.activeTab}
        />
    )
}

export default UsageMaintenanceAttachmentsGrid;