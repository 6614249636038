import { IAccountComponentData } from "../../accounts/types/IAccountComponentData";

// Action names
type GET_USERS_REQUEST_TYPE = "GET_USERS_REQUEST";
type GET_USERS_SUCCESS_TYPE = "GET_USERS_SUCCESS";
type GET_USERS_FAILURE_TYPE = "GET_USERS_FAILURE";
type LOGIN_REQUEST_TYPE = "LOGIN_REQUEST";
type LOGIN_SUCCESS_TYPE = "LOGIN_SUCCESS";
type LOGIN_FAILURE_TYPE = "LOGIN_FAILURE";
type LOGOUT_REQUEST_TYPE = "LOGOUT_REQUEST";
type LOGOUT_SUCCESS_TYPE = "LOGOUT_SUCCESS";
type LOGOUT_FAILURE_TYPE = "LOGOUT_FAILURE";
type FETCH_ACCOUNT_COMPONENT_DATA_REQUEST_TYPE = "FETCH_ACCOUNT_COMPONENT_DATA_REQUEST";
type FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS_TYPE = "FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS";
type FETCH_ACCOUNT_COMPONENT_DATA_FAILURE_TYPE = "FETCH_ACCOUNT_COMPONENT_DATA_FAILURE";
type FETCH_APP_VERSION_NUMBER_REQUEST_TYPE = "FETCH_APP_VERSION_NUMBER_REQUEST";
type FETCH_APP_VERSION_NUMBER_SUCCESS_TYPE = "FETCH_APP_VERSION_NUMBER_SUCCESS";
type FETCH_APP_VERSION_NUMBER_FAILURE_TYPE = "FETCH_APP_VERSION_NUMBER_FAILURE";
type LOGIN_TYPE_REQUEST_TYPE = "LOGIN_TYPE_REQUEST";
type LOGIN_TYPE_SUCCESS_TYPE = "LOGIN_TYPE_SUCCESS";
type LOGIN_TYPE_FAILURE_TYPE = "LOGIN_TYPE_FAILURE";
type SAVE_ROLES_TYPE = "SAVE_ROLES";
type SET_PORTAL_TYPE_TYPE = "SET_PORTAL_TYPE";
type GET_CUSTOMER_SUCCESS_TYPE = "GET_CUSTOMER_SUCCESS";
type GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS_TYPE = "GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS"
type GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS_TYPE = "GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS";
type GET_CUSTOMER_TIME_ZONE_CONFIGURATION_SUCCESS_TYPE = "GET_CUSTOMER_TIME_ZONE_CONFIGURATION_SUCCESS";
type GET_USER_NAMENUMBER_SUCCESS_TYPE = "GET_USER_NAMENUMBER_SUCCESS";
type GET_USER_CRM_VALUES_SUCCESS_TYPE = "GET_USER_CRM_VALUES_SUCCESS";
type SET_CURRENT_USER_NAME_NUMBER_TYPE = "SET_CURRENT_USER_NAME_NUMBER";

// Action types
export interface GetUsersRequest {
    type: GET_USERS_REQUEST_TYPE;
}

export interface GetUsersSuccess {
    type: GET_USERS_SUCCESS_TYPE;
    payload: {
        users: string[];
    };
}

export interface GetUsersFailure {
    type: GET_USERS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface LoginRequest {
    type: LOGIN_REQUEST_TYPE;
}

export interface LoginSuccess {
    type: LOGIN_SUCCESS_TYPE;
    payload: {
        username: string;
        currentUserNameNumber: string
    };
}

export interface LoginFailure {
    type: LOGIN_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface LogoutRequest {
    type: LOGOUT_REQUEST_TYPE;
}

export interface LogoutSuccess {
    type: LOGOUT_SUCCESS_TYPE;
}

export interface LogoutFailure {
    type: LOGOUT_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface AccountComponentDataRequest {
    type: FETCH_ACCOUNT_COMPONENT_DATA_REQUEST_TYPE;
}

export interface AccountComponentDataSuccess {
    type: FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS_TYPE;
    payload: {
        componentData: IAccountComponentData;
    };
}

export interface AccountComponentDataFailure {
    type: FETCH_ACCOUNT_COMPONENT_DATA_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface AppVersionNumberRequest {
    type: FETCH_APP_VERSION_NUMBER_REQUEST_TYPE;
}

export interface AppVersionNumberSuccess {
    type: FETCH_APP_VERSION_NUMBER_SUCCESS_TYPE;
    payload: {
        appVersionNumber: string;
    };
}

export interface AppVersionNumberFailure {
    type: FETCH_APP_VERSION_NUMBER_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface LoginTypeRequest {
    type: LOGIN_TYPE_REQUEST_TYPE;
}

export interface LoginTypeSuccess {
    type: LOGIN_TYPE_SUCCESS_TYPE;
    payload: {
        loginType: string;
    };
}

export interface LoginTypeFailure {
    type: LOGIN_TYPE_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SaveRoles {
    type: SAVE_ROLES_TYPE;
    payload: {
        roles: string[];
    };
}

export interface SetPortalType {
    type: SET_PORTAL_TYPE_TYPE;
    payload: {
        portalType: string;
    }
}

export interface GetCustomerSuccess {
    type: GET_CUSTOMER_SUCCESS_TYPE;
    payload: {
        customer: string;
    }
}

export interface GetCustomerDefaultTerritoriesSuccess {
    type: GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS_TYPE;
    payload: {
        defaultTerritories: any;
    }
}


export interface GetCustomerDateConfigurationSuccess {
    type: GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS_TYPE;
    payload: {
        customerDate: string;
    }
}

export interface GetCustonerTimeZoneConfigurationSuccess {
    type: GET_CUSTOMER_TIME_ZONE_CONFIGURATION_SUCCESS_TYPE;
    payload: {
        customerTimeZone: string;
    }
}

export interface GetUserCRMValuesSuccess {
    type: GET_USER_CRM_VALUES_SUCCESS_TYPE;
    payload: {
        currentUserNameNumber: string;
    }
}

export interface SetCurrentUserNameNumber {
    type: SET_CURRENT_USER_NAME_NUMBER_TYPE;
    payload: {
        nameNumber: string;
        userName: string;
    }
}


export type ActionTypes =
    GetUsersRequest
    | GetUsersFailure
    | GetUsersSuccess
    | LoginRequest
    | LoginFailure
    | LoginSuccess
    | LogoutRequest
    | LogoutFailure
    | LogoutSuccess
    | AccountComponentDataRequest
    | AccountComponentDataFailure
    | AccountComponentDataSuccess
    | AppVersionNumberRequest
    | AppVersionNumberFailure
    | AppVersionNumberSuccess
    | LoginTypeRequest
    | LoginTypeFailure
    | LoginTypeSuccess
    | SaveRoles
    | SetPortalType
    | GetUserCRMValuesSuccess
    | GetCustomerSuccess
    | GetCustomerDefaultTerritoriesSuccess
    | GetCustomerDateConfigurationSuccess
    | GetCustonerTimeZoneConfigurationSuccess
    | SetCurrentUserNameNumber;

export const GET_USERS_REQUEST: GET_USERS_REQUEST_TYPE = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS: GET_USERS_SUCCESS_TYPE = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE: GET_USERS_FAILURE_TYPE = "GET_USERS_FAILURE";
export const LOGIN_REQUEST: LOGIN_REQUEST_TYPE = "LOGIN_REQUEST";
export const LOGIN_SUCCESS: LOGIN_SUCCESS_TYPE = "LOGIN_SUCCESS";
export const LOGIN_FAILURE: LOGIN_FAILURE_TYPE = "LOGIN_FAILURE";
export const LOGOUT_REQUEST: LOGOUT_REQUEST_TYPE = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS: LOGOUT_SUCCESS_TYPE = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE: LOGOUT_FAILURE_TYPE = "LOGOUT_FAILURE";
export const FETCH_ACCOUNT_COMPONENT_DATA_REQUEST: FETCH_ACCOUNT_COMPONENT_DATA_REQUEST_TYPE = "FETCH_ACCOUNT_COMPONENT_DATA_REQUEST";
export const FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS: FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS_TYPE = "FETCH_ACCOUNT_COMPONENT_DATA_SUCCESS";
export const FETCH_ACCOUNT_COMPONENT_DATA_FAILURE: FETCH_ACCOUNT_COMPONENT_DATA_FAILURE_TYPE = "FETCH_ACCOUNT_COMPONENT_DATA_FAILURE";
export const FETCH_APP_VERSION_NUMBER_REQUEST: FETCH_APP_VERSION_NUMBER_REQUEST_TYPE = "FETCH_APP_VERSION_NUMBER_REQUEST";
export const FETCH_APP_VERSION_NUMBER_SUCCESS: FETCH_APP_VERSION_NUMBER_SUCCESS_TYPE = "FETCH_APP_VERSION_NUMBER_SUCCESS";
export const FETCH_APP_VERSION_NUMBER_FAILURE: FETCH_APP_VERSION_NUMBER_FAILURE_TYPE = "FETCH_APP_VERSION_NUMBER_FAILURE";
export const LOGIN_TYPE_REQUEST: LOGIN_TYPE_REQUEST_TYPE = "LOGIN_TYPE_REQUEST";
export const LOGIN_TYPE_SUCCESS: LOGIN_TYPE_SUCCESS_TYPE = "LOGIN_TYPE_SUCCESS";
export const LOGIN_TYPE_FAILURE: LOGIN_TYPE_FAILURE_TYPE = "LOGIN_TYPE_FAILURE";
export const SAVE_ROLES: SAVE_ROLES_TYPE = "SAVE_ROLES";
export const SET_PORTAL_TYPE: SET_PORTAL_TYPE_TYPE = "SET_PORTAL_TYPE"
export const GET_CUSTOMER_SUCCESS: GET_CUSTOMER_SUCCESS_TYPE = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS: GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS_TYPE = "GET_CUSTOMER_DEFAULT_TERRITORIES_SUCCESS"
export const GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS: GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS_TYPE = "GET_CUSTOMER_DATE_CONFIGURATION_SUCCESS";
export const GET_CUSTOMER_TIME_ZONE_CONFIGURATION_SUCCESS: GET_CUSTOMER_TIME_ZONE_CONFIGURATION_SUCCESS_TYPE = "GET_CUSTOMER_TIME_ZONE_CONFIGURATION_SUCCESS";
export const GET_USER_NAMENUMBER_SUCCESS: GET_USER_NAMENUMBER_SUCCESS_TYPE = "GET_USER_NAMENUMBER_SUCCESS";
export const GET_USER_CRM_VALUES_SUCCESS: GET_USER_CRM_VALUES_SUCCESS_TYPE = "GET_USER_CRM_VALUES_SUCCESS";

export const SET_CURRENT_USER_NAME_NUMBER: SET_CURRENT_USER_NAME_NUMBER_TYPE = "SET_CURRENT_USER_NAME_NUMBER";

