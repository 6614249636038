import * as React from "react";
import { ITreeNodeData } from "../types/TreeNodeData";
import { TreeNode } from "./Node";

interface ITreeViewProps<T> {
    data: ITreeNodeData<T>[];
    selectedNodeChanged: (source: ITreeNodeData<T>) => void;
    selectedNode: ITreeNodeData<T>;
    confirmChange: () => boolean;
    isEditing: boolean;
    isDeleting?: boolean;
    addSourceButtonText: string;
    deleteSourceButtonText: string;
    addSource: (parent: T) => void;
    deleteSource: (source: T) => void;
    sidebarCollapsed: boolean;
    alwaysExpanded: boolean;
    deleteRoleDisabled?: boolean;
}

export class TreeView<T> extends React.Component<ITreeViewProps<T>, {}> {
    constructor(props: ITreeViewProps<T>) {
        super(props);
        this.state = { selectedNode: null };
    }

    render() {
        const props = this.props;
        if (!props.data) {
            return null;
        }

        return (
            <div id="treeview" className="treeview">
                <ul className="list-group">
                    {props.data.map((node, index) => (
                        !!node && <TreeNode
                            key={node.id || index}
                            nodeData={node}
                            selectedNodeChanged={props.selectedNodeChanged.bind(this)}
                            isEditing={props.isEditing}
                            addSourceButtonText={props.addSourceButtonText}
                            deleteSourceButtonText={props.deleteSourceButtonText}
                            addSource={props.addSource}
                            deleteSource={props.deleteSource}
                            alwaysExpanded={props.alwaysExpanded}
                            indentation={0}
                            deleteRoleDisabled={props.deleteRoleDisabled}
                        />
                    ))}
                </ul>
            </div>
        );
    }
}
