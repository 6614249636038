import * as React from "react";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { DRAFT_WORKS_ARTISTS_STATE_KEY, DRAFT_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY, DRAFT_WORKS_DURATION_STATE_KEY, DRAFT_WORKS_GENRE_STATE_KEY, DRAFT_WORKS_INSTRUMENTATION_STATE_KEY, DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY, DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY, DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY, DRAFT_WORKS_LANGUAGE_STATE_KEY, DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY, DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY, DRAFT_WORKS_TITLES_STATE_KEY, DRAFT_WORKS_WORK_PROPERTIES } from "../../../Consts";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import { SizedCheckboxDataInput } from "../../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import { IWorkState } from "../../../../redux/types/IWorkState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import HeaderOneText from "../../../sharedComponents/text/HeaderOneText";
import DisplayTable from "../../../sharedComponents/displayTable/DisplayTable";
import { IRepertoireField } from "../../../../repertoire/types/IRepertoireField";

export interface IRegisteredWorksGeneralDataViewProps {
    draftWorkMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    draftWorkMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    work: IWorkState;
    lookupValues: ILookupDictionary;
    editableFields: any[];
}

const RegisteredWorksGeneralDataView: React.FC<IRegisteredWorksGeneralDataViewProps> = ({
    draftWorkMaintenanceGeneralDataViewData,
    dataGridTableData,
    draftWorkMaintenanceGridsViewData,
    work,
    lookupValues,
}) => {
    const [loading, setLoading] = React.useState(true);
    const [viewData, setViewData] = React.useState({});
    const [instruments, setInstruments] = React.useState([]);

    const {
        titles,
        duration,
        genre,
        instrumentation,
        isArrangement,
        isLibraryWork,
        isCommissionedForCommercial,
        isOriginalWork,
        originalTitle,
        contributors,
        artists,
        language
    } = work;

    const alternativeTitles = titles.filter(title => title.type.value !== 'OT');

    const filteredTableDataDraftWorkContributer = dataGridTableData.fields.filter(
        (field: IRepertoireField) =>
            field.componentInstance === DRAFT_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY
    );
    const filteredTableDataDraftWorkStateKey = dataGridTableData.fields.filter(
        (field: IRepertoireField) =>
            field.componentInstance === DRAFT_WORKS_TITLES_STATE_KEY
    );
    const filteredTableDataDraftWorkInsrumentation = dataGridTableData.fields.filter(
        (field: IRepertoireField) =>
            field.componentInstance === DRAFT_WORKS_INSTRUMENTATION_STATE_KEY
    );
    const filteredTableDataDraftWorkArtist = dataGridTableData.fields.filter(
        (field: IRepertoireField) =>
            field.componentInstance === DRAFT_WORKS_ARTISTS_STATE_KEY
    );

    React.useEffect(() => {
        const { fields } = draftWorkMaintenanceGeneralDataViewData

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            const viewDataAccumulator = {};
            visibleFields.forEach(item => {
                viewDataAccumulator[item.name] = item.data;
            })
            setViewData(viewDataAccumulator);
            setLoading(false);
        }
    }, [draftWorkMaintenanceGeneralDataViewData]);

    React.useEffect(() => {
        const instruments = lookupValues.Instrumentation;
        if (instruments && instruments.lookups) {
            const dispalyIntruments = instrumentation.map(inst => {
                const foundInstrument = instruments.lookups.find(instru => instru.code === inst.type.value);
                inst.type.value = foundInstrument && foundInstrument.description ? foundInstrument.description : '-';
                return inst;
            });
            setInstruments(dispalyIntruments)
        }
    }, [lookupValues, instrumentation])

    const renderGridViewHeaderSections = (header: IMembersPortalField) => {
        return <HeaderOneText text={header.data} />
    };

    const changeData = (value: any, fieldName: string) => {
        // Todo we should not need to pass this function if we just display the data
    }

    if (!loading) {
        return (
            <>
                <div className="row marginVerticalVeryLarge paddingVerticalVeryLarge">
                    <div className="headerContainer marginMedium">
                        {renderGridViewHeaderSections(draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'membersPortalGeneralTitle'))}
                    </div>

                    <div className="flexRow flexJustifyContentSpaceBetween paddingRightVeryLarge">
                        <div className="flexColumn marginHorizontalSmall">
                            <label>{`${viewData[DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY]}:`}</label>
                            <span>{originalTitle}</span>
                        </div>
                        <div className="flexColumn marginHorizontalSmall">
                            <label>{`${viewData[DRAFT_WORKS_LANGUAGE_STATE_KEY]}:`}</label>
                            <span>{language}</span>
                        </div>
                        <div className="flexColumn marginHorizontalSmall">
                            <label>{`${viewData[DRAFT_WORKS_DURATION_STATE_KEY]}:`}</label>
                            <span>{new Date(duration * 1000).toISOString().slice(11, 19)}</span>
                        </div>
                        <div className="flexColumn marginHorizontalSmall">
                            <label>{`${viewData[DRAFT_WORKS_GENRE_STATE_KEY]}:`}</label>
                            <span>{genre}</span>
                        </div>
                    </div>
                </div>
                {alternativeTitles && alternativeTitles.length > 0 && <div className="row">
                    <div className="headerContainer marginMedium">
                        {renderGridViewHeaderSections(draftWorkMaintenanceGridsViewData.fields.find(field => field.name.toString() === 'membersPortalWorkTitle'))}
                    </div>
                    <DisplayTable
                        tableContents={alternativeTitles}
                        dataGridTableData={filteredTableDataDraftWorkStateKey}
                        showRowNumber={false}
                    />
                </div>}
                {instrumentation && instrumentation.length > 0 && <div className="row">
                    <div className="headerContainer marginMedium">
                        {renderGridViewHeaderSections(draftWorkMaintenanceGridsViewData.fields.find(field => field.name.toString() === 'membersPortalWorkInstruments'))}
                    </div>
                    <DisplayTable
                        tableContents={instruments}
                        dataGridTableData={filteredTableDataDraftWorkInsrumentation}
                        showRowNumber={false}
                    />
                </div>}

                <div>
                    <div className="headerContainer marginMedium">
                        <HeaderOneText text={"Contributor Details"} />
                    </div>
                    <DisplayTable
                        tableContents={contributors['repertoire']}
                        dataGridTableData={filteredTableDataDraftWorkContributer}
                        showRowNumber={true}
                    />
                </div>
                {artists && <div>
                    <div className="headerContainer marginMedium">
                        <HeaderOneText text={"Artist Details"} />
                    </div>
                    <DisplayTable
                        tableContents={artists}
                        dataGridTableData={filteredTableDataDraftWorkArtist}
                        showRowNumber={false}
                    />
                </div>}
                <div className="row">
                    <div className="headerContainer marginMedium">
                        <div className="fontHeader">
                            <HeaderOneText text={"Additional Work Properties"} />
                        </div>
                    </div>
                    <SizedCheckboxDataInput
                        label={viewData[DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY]}
                        fieldName={DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY}
                        value={isOriginalWork}
                        changeData={changeData}
                        readonly={true}
                    />

                    <SizedCheckboxDataInput
                        label={viewData[DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY]}
                        fieldName={DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY}
                        value={isArrangement}
                        changeData={changeData}
                        readonly={true}
                    />

                    <SizedCheckboxDataInput
                        label={viewData[DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY]}
                        fieldName={DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY}
                        value={isLibraryWork}
                        changeData={changeData}
                        readonly={true}
                    />

                </div>
                <div className="row">
                    <SizedCheckboxDataInput
                        label={viewData[DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY]}
                        fieldName={DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY}
                        value={isCommissionedForCommercial}
                        changeData={changeData}
                        readonly={true}
                    />
                </div>
            </>
        );
    }
    return <div>Loading...</div>;
}

export default RegisteredWorksGeneralDataView;