import { useMsal } from "@azure/msal-react";
import React, { useState } from 'react';
import { ComponentsHelper } from "../../core/services/ComponentHelper";

export interface IMyAccountPageProps {
    isCRMIntegrationEnabled: boolean;
    powerPortalURL: string;
}

const MyAccountPage = (props: IMyAccountPageProps) => {
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    let accountPage = <div></div>;
    
    if (props.isCRMIntegrationEnabled) {
        accountPage = <iframe src={`${props.powerPortalURL + "/SignIn?ReturnUrl=/en-US/profile"}`} style={{ width: '100%', height: '100vh' }} ></iframe>
    }

    return (
        accountPage      
    );
}

export default MyAccountPage;