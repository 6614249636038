import React from "react";
import DisplayText from "../text/DisplayText";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import { IInstrumentation } from "../../../repertoire/types/IInstrumentation";
import { IArtist } from "../../../repertoire/types/IArtist";
import { IContributorItem } from "../../../repertoire/types/IContributorItem";
import { ITitle } from "../../../repertoire/types/ITitle";
import { IProductWorkDataItems } from "../../../repertoire/types/IProductWorkDataItems";
import { isTableAction } from "../../../repertoire/types/ITableAction";
import { DEPENDENT_ROW_ACTION, REMOVE_ACTION, UPDATE_ACTION } from "../../Consts";
import { BOOLEAN_READONLY_INPUT, DATE_INPUT, DROPDOWN_INPUT, READ_ONLY_TIMESPAN_INPUT } from "../../../repertoire/Consts";
import DisplayDuration from "../text/DisplayDuration";
import DropdownDataInput from "../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { ILookupOptionsAndOnChangeFunctions, IRowActions } from "./DisplayTable";
import IconTextButton from "../../../repertoire/components/iconTextButton/IconTextButton";
import DisplayDate from "../text/DisplayDate";
import { InlineActionButton } from "../../../repertoire/components/actionButton/ActionButton";
import { IUnpaidClaimsSearchResult } from "../../types/IUnpaidClaimsSearchResult";
import TimeDataInputWrapper from "../../../repertoire/components/timeFieldDataInput/TimeDataInputWrapper";
import { InputNumber } from "antd";
import SizedDateDataInput from "../../../repertoire/components/dateDataInput/DateDataInput";
export interface IDisplayTableDataCellsProps {
    cellData: IRepertoireField;
    isExpanded: boolean;
    tableContentItem: IInstrumentation | IArtist | IContributorItem | ITitle | IProductWorkDataItems | IUnpaidClaimsSearchResult | any;
    rowActions?: IRowActions;
    removeRowAction?: (item: IProductWorkDataItems | IInstrumentation | IArtist | IContributorItem | ITitle | IUnpaidClaimsSearchResult) => void;
    lookupOptionsAndOnChangeFunctions?: ILookupOptionsAndOnChangeFunctions;
    rowIndex: number;
    isSearchResultTable?: boolean;
    isLinkTitle?: boolean;
    getAgreementDetails?: (dataSource: string, agreementID) => void;
}

const DisplayTableDataCells: React.FC<IDisplayTableDataCellsProps> = ({
    cellData,
    isExpanded,
    tableContentItem,
    rowActions,
    removeRowAction,
    lookupOptionsAndOnChangeFunctions,
    rowIndex,
    isSearchResultTable,
    isLinkTitle,
    getAgreementDetails,
}) => {
    const isNumberInputs = cellData.fieldType === 'integer';
    const isRemoveRowActionCell = isTableAction(cellData.fieldType) && cellData.fieldType === REMOVE_ACTION && removeRowAction;
    const isIconButton = isTableAction(cellData.fieldType) && cellData.fieldType === DEPENDENT_ROW_ACTION && rowActions && rowActions.iconButton && rowActions.iconButton.onClick
    const isTextButton = isTableAction(cellData.fieldType) && cellData.fieldType === UPDATE_ACTION && rowActions && rowActions.textButton && rowActions.textButton.onClick
    const isEditableDurationCell = cellData.fieldType === READ_ONLY_TIMESPAN_INPUT && lookupOptionsAndOnChangeFunctions.duration && lookupOptionsAndOnChangeFunctions.duration.onChangeValue;
    const isDurationCell = cellData.fieldType === READ_ONLY_TIMESPAN_INPUT;
    const isAgreementWorkType = cellData.name === 'type' && cellData.componentInstance === "applicableWorks"
    const isActiveDropDownCell = cellData.fieldType === DROPDOWN_INPUT;
    const isBooleanCell = cellData.fieldType === BOOLEAN_READONLY_INPUT;
    const isDateCell = cellData.fieldType === DATE_INPUT;
    const isDateCellAgreementWorks = cellData.fieldType === DATE_INPUT && (cellData.name === 'excludedUntilDate' || cellData.name === 'workRetentionEndDate');
    const showLinkedTitleInPlaceOfNavigationIcon = isLinkTitle && (cellData.name === 'title' || cellData.name === 'fullTitle' || cellData.name === 'workName') || cellData.name === 'ipNameNumber';
    const isUsageArtist = cellData.name === "usageArtist"
    const isDraftAgreementStatus = cellData.name === "agreementStatus" && cellData.componentInstance === "draftAgreements";
    if (isDraftAgreementStatus) {
        cellData.name = "agreementStatus";
    } 
    const isRegisteredAgreementStatus = cellData.name === "status" && cellData.componentInstance === "registeredAgreements";
    if (isRegisteredAgreementStatus) {
        cellData.name = "agreementStatus";
    }
    let value;
    if(isUsageArtist){
     value =  tableContentItem["fullPerformer"];
    }   
    else if(cellData.name==='Territory'){
     value = tableContentItem["agreementTerritory"].join(',')
    }else{
        value= tableContentItem[cellData.name];
    }
    let readOnlyValue = value && value.value ? value.value : '-';
   
    if (isSearchResultTable) {
        readOnlyValue = value ? value : '-'
    }
    const renderExpandedValues = (values: string | string[]) => {
        if(cellData.name==='Territory'){
            values= String(values).split(',')  
        }
        if (Array.isArray(values)) {
            return isExpanded ? <div className='multipleTitlesDiv'>
                {values.map((value, index) => {
                    return index + 1 <= 3 && <div>
                        {value}
                        {index + 1 < values.length && <span>,</span>}
                    </div>
                })}
            </div> : <div>
                {values[0]}
            </div>
        }

        return <div>{readOnlyValue} </div>
    }
    const cellContent = () => {
       

        if (isNumberInputs && lookupOptionsAndOnChangeFunctions && lookupOptionsAndOnChangeFunctions['sideTrackSequence'] && lookupOptionsAndOnChangeFunctions['sideTrackSequence']?.onChangeValue) {
            const onChangeSideTrackSequence = lookupOptionsAndOnChangeFunctions['sideTrackSequence'].onChangeValue;
            return <InputNumber
                size='large'
                min={0}
                max={99}
                name={cellData.name}
                value={tableContentItem[cellData.name]?.value}
                onChange={dropDownValue => {
                    onChangeSideTrackSequence(dropDownValue, cellData.name, rowIndex)
                }}
            />
        }


        if (showLinkedTitleInPlaceOfNavigationIcon) {
            return <div className="workTitle">
                <a onClick={() => rowActions.iconButton.onClick(tableContentItem)}>{renderExpandedValues(readOnlyValue)}</a>
            </div>
        }

        if (isIconButton) {
            return isLinkTitle ? <></> : <IconTextButton icon={rowActions.iconButton.icon} onClick={() => rowActions.iconButton.onClick(tableContentItem)} />
        }

        if (isTextButton) {
            return <InlineActionButton
                buttonText={cellData.data}
                buttonAction={() => rowActions.textButton.onClick(tableContentItem)}
            />
        }

        if (isRemoveRowActionCell) {
            return <IconTextButton icon={"icon ms-Icon ms-Icon--Delete"} onClick={() => removeRowAction(tableContentItem)} />
        }

        if (isAgreementWorkType && lookupOptionsAndOnChangeFunctions && lookupOptionsAndOnChangeFunctions['type']) {
            const onChange = lookupOptionsAndOnChangeFunctions[cellData.name].onChangeValue;
            return <DropdownDataInput
                value={tableContentItem[cellData.name]?.value}
                fieldName={cellData.name}
                options={null}
                changeData={dropDownValue => onChange(dropDownValue, cellData.name, rowIndex)}
                allowNull={true}
                readOnly={true}
                isHidden={false}
            />
        }

        if (isActiveDropDownCell && lookupOptionsAndOnChangeFunctions && lookupOptionsAndOnChangeFunctions[cellData.name]) {
            const options = lookupOptionsAndOnChangeFunctions[cellData.name].options;
            const onChange = lookupOptionsAndOnChangeFunctions[cellData.name].onChangeValue;
            return <DropdownDataInput
                value={value.value}
                options={options}
                changeData={dropDownValue => onChange(dropDownValue, cellData.name, rowIndex)}
                fieldName={cellData.name}
                allowNull={true}
                useComboBoxAsMenuWidth={false}
                isHidden={false}
            />
        }

        if (isEditableDurationCell) {
            const onChangeTime = lookupOptionsAndOnChangeFunctions.duration.onChangeValue;
            return <TimeDataInputWrapper
                value={value.value}
                changeData={durationValue => onChangeTime(durationValue, cellData.name, rowIndex)}
                fieldName={cellData.name}
                label={''}
                isFormik={true}
            />
        }

        if (isDurationCell) {
            return <DisplayDuration time={value.value} />
        }

        if (isDateCellAgreementWorks && lookupOptionsAndOnChangeFunctions && lookupOptionsAndOnChangeFunctions['excludedUntilDate'] && lookupOptionsAndOnChangeFunctions['excludedUntilDate']?.onChangeValue) {
            const onChangeDate = lookupOptionsAndOnChangeFunctions['excludedUntilDate'].onChangeValue;
            return <SizedDateDataInput
                value={value.value}
                fieldName={cellData.name}
                label={undefined}
                changeData={value => onChangeDate(value, cellData.name, rowIndex)}
                readOnly={false}
                isHidden={false}
            />
        }

        if (isDateCell) {
            return <DisplayDate date={readOnlyValue} />
        }

        if (isBooleanCell && value?.value != null) {
            return <DisplayText text={value?.value?.valueOf().toString().replace(/true/gi, "Yes").replace(/false/gi, "No")} />
        }
        return renderExpandedValues(readOnlyValue)
    }

    return <td key={`tableCell${cellData.name}${rowIndex}`} className="td" title={`${cellData.data}`}>
        <div className={["tableCell flexRow flexAlignItemsCenter", isExpanded && "rowNotExpanded"].join(" ")}>
            {cellContent()}
        </div>
    </td>
}

export default DisplayTableDataCells;