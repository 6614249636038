import * as React from "react";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { addTab, updateClaimField } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import {
    CLAIMED_WORKS, TYPES_OF_CALCULATION, SEARCH_ACTION, CLAIM_CALCULATIONS, KEEP_ORIGINALVALUE_STATE_KEY, MINIMUM_AMOUNT_STATE_KEY,
    FIRST_VALUE_STATE_KEY, CALCULATEALL_AFFECTED_STATE_KEY, CLAIMS_MAINTENANCE_GRIDS_VIEW
} from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { IClaimState, IClaimStateKeys } from "../../../../redux/types/IClaimState";
import { showModal, hideModal } from "../../../../redux/reducers/ModalReducer";
import { clearModalSearchResults } from "../../../../redux/reducers/RepertoireReducer";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import { Checkbox, Table } from "antd";
import { ColumnsType} from "antd/lib/table/interface";
import { IClaimCalculationType } from "../../../types/IClaimCalculationType";
import { DISTRIBUTION_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { IValidationMessage } from "../../../types/IValidationMessage";
import { If } from "../../../../core/components/If";
import SaveResult from "../../../components/saveResult/SaveResult";


export interface IClaimMaintenanceGridsViewProps {
    claimMaintenanceGridsViewData: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    showModal: typeof showModal;
    addTab: typeof addTab;
    tabs: ITabReduxItem[];
    activeTab: number;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    activeHeaderSection?: string;
    ipBaseNumber?: string;
    claim: IClaimState;
    updateClaimField: typeof updateClaimField;
    clearModalSearchResults: typeof clearModalSearchResults;
    hideModal: typeof hideModal;
    saveResultData?: IRepertoireComponentDataItem;
    resetMessageBanner: () => void;
}

interface IClaimMaintenanceGridsViewState {
    activeHeaderSection: string;
    loaded: boolean;
}

export class ClaimMaintenanceGridsView extends React.PureComponent<
    IClaimMaintenanceGridsViewProps,
    IClaimMaintenanceGridsViewState
> {
    viewData;
    constructor(props: IClaimMaintenanceGridsViewProps) {
        super(props);
        this.state = {
            activeHeaderSection: CLAIMED_WORKS,
            loaded: false
        };
        this.viewData = {};
    }

    componentDidUpdate() {
        this.loadData();
    }

    componentDidMount() {
        this.loadData();
        const {
            claimMaintenanceGridsViewData: { fields }
        } = this.props;
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;
                
                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    loadData() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.claimMaintenanceGridsViewData) {
                return;
            }
            else {
                this.setState({ loaded: true });
            }
        }
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    changeData = (value: any, name: IRepertoireStateKeys) => {
        const { updateClaimField } = this.props;
        updateClaimField(value, name as IClaimStateKeys);
    }
    
    onCalculateChange = (value: boolean, fieldName: string, record: IClaimCalculationType) => {
        const { claim } = this.props;
        let claimCalculations = claim.calculationType.map(x => x.calculationType);

        if (value) {
            if (claimCalculations.indexOf(record.calculationType, 0) == -1) {
                claim.calculationType.push(record);
                this.changeData(claim.calculationType, "claimCalculations");
            }            
        }
        else {
            let index = claimCalculations.indexOf(record.calculationType, 0);
            if (index > -1) {
                claim.calculationType.splice(index, 1);
            }
            this.changeData(claim.calculationType,"claimCalculations");
        }      
    }

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { claimMaintenanceGridsViewData } = this.props;

        if (claimMaintenanceGridsViewData && claimMaintenanceGridsViewData.fields) {
            return claimMaintenanceGridsViewData.fields.filter(f => f.fieldType === null).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    };

    getColumns = () => {
        const {
            dataGridTableData,
            lookupValues
        } = this.props;

        let columns: ColumnsType<IClaimCalculationType> = dataGridTableData.fields.filter(x => x.componentInstance === CLAIM_CALCULATIONS).map((field, index) => {
            switch (field.name) {                               
                case 'name':
                    return {
                        title: 'Name',
                        dataIndex: field.name,
                        width: '20em',
                        key: `${field.name}`,
                        render: (value, record, index) => {
                            return record.name;
                        }
                    }
                case 'calculate':
                    return {
                        title: '',
                        dataIndex: field.name,
                        width: '3em',
                        key: field.name,
                        fixed: 'left',
                        render: (value, record) => {
                            return <Checkbox checked={record.selected} onChange={event => this.onCalculateChange(event.target.checked, field.name, record)}></Checkbox>
                            }
                    }                    
                default:
                    return {
                        title: field.data, dataIndex: field.name,
                        width: '6em',
                        key: `${field.name}`,
                        align: 'center',
                        sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                        render: (value) => value !== null ? <div key={`${value}_${index}`}>{value}</div> : null
                    }
            }
        })
        return columns;
    }

    getData = () => {
        const {
            lookupValues, 
            claim
        } = this.props;

        const calculationTypesLookup = lookupValues[DISTRIBUTION_TYPE_LOOKUP];    
        let data: IClaimCalculationType[] = [];
        let claimCalculationTypes = [];
        
        if (claim.calculationType) {
            claimCalculationTypes = claim.calculationType.map(x => x.calculationType);
        }

        if (calculationTypesLookup) {
            data = calculationTypesLookup.lookups.map((row, index) => ({
                key: index,
                name: row.description,
                calculationType: row.typeID,
                selected: (claimCalculationTypes.indexOf(row.typeID) > -1 ? true : false)
            }));
        }
            
             
        return data;
    }

    render() {
        const {
            activeHeaderSection,
            loaded,
        } = this.state;

        const {
            claim,
            isReadonly,
            dataGridTableData,
            clearModalSearchResults,
            hideModal,           
            showModal,
            tabs, activeTab, saveResultData,resetMessageBanner
        } = this.props;

        const sections = [];
        const messages: IValidationMessage[] = [];
       
        if(tabs[activeTab].validationMessages != null) {
            tabs[activeTab].validationMessages.forEach(e => {
                const section =saveResultData.fields.find(x => x.name.toString() === e.messageKey)?.section;
                if(section) {
                    sections.push(saveResultData.fields.find(x => x.name.toString() === e.messageKey)?.section);
                    let validationMessage: IValidationMessage = { messageKey: e.messageKey, additionalInfo: e.additionalInfo }
                    messages.push(validationMessage);
                    this.setState({activeHeaderSection:section});
                }
            })
        }

        if (!loaded)
            return <div className="loading" />

        const columns = this.getColumns();
        const data = this.getData();
        
        return (
            <>
                <div className="GridsView">
                    <div className="headerContainer">
                        {this.renderGridViewHeaderSections()}
                    </div>                    

                    {activeHeaderSection === CLAIMED_WORKS ?
                        <DataGridTable
                            dataGridTableData={dataGridTableData}
                            componentInstance={CLAIMED_WORKS}
                            tableContents={claim.claimedWorks}
                            tableActions={[{ name: SEARCH_ACTION }]}
                            showModal={showModal}
                            clearModalSearchResults={clearModalSearchResults}
                            stateKey={CLAIMED_WORKS}
                            hideModal={hideModal}
                            isExpandable={false}
                            changeData={this.changeData.bind(this)}
                            showRowNumber={false}
                            sortData={undefined}
                        /> : <div> </div>}

                    {activeHeaderSection === TYPES_OF_CALCULATION ?
                        <>      
                         <If condition={messages.length > 0}>
                            <SaveResult
                                componentInstance={CLAIMS_MAINTENANCE_GRIDS_VIEW}
                                saveSuccess={tabs[activeTab].saveSuccessful}
                                saveResultData={saveResultData}
                                messages={messages}
                                resetMessageBanner={resetMessageBanner}
                            />
                         </If>                      
                            <Table
                                columns={columns}
                                dataSource={data}
                                rowClassName={() => 'editable-row'}
                                size={'middle'}
                                onChange={() => { }}
                                className="parent-table"
                                tableLayout={"fixed"}

                            />
                            <div className="row">
                                <SizedTextDataInput
                                    label={this.viewData[MINIMUM_AMOUNT_STATE_KEY]}
                                    fieldName={MINIMUM_AMOUNT_STATE_KEY}
                                    value={claim.calculation?.minimumAmount}
                                    fieldType={'integer'}
                                    changeData={this.changeData.bind(this)}
                                    readOnly={isReadonly}
                                    isHidden={false} />
                                <SizedCheckboxDataInput
                                    label={this.viewData[FIRST_VALUE_STATE_KEY]}
                                    fieldName={FIRST_VALUE_STATE_KEY}
                                    value={claim.calculation?.firstValue}
                                    changeData={this.changeData.bind(this)}
                                    readonly={isReadonly}
                                    isHidden={false} />
                            </div>
                            <div className="row">
                                <SizedCheckboxDataInput
                                    label={this.viewData[CALCULATEALL_AFFECTED_STATE_KEY]}
                                    fieldName={CALCULATEALL_AFFECTED_STATE_KEY}
                                    value={claim.calculation?.calculateAllAffected}
                                    changeData={this.changeData.bind(this)}
                                    readonly={isReadonly}
                                    isHidden={false} />
                            </div>
                        </>
                        : <div> </div>
                    }
                </div>
            </>
        );
    }
}

