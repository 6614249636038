import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    PAYMENTRUN_DATE_STATE_KEY, PAYMENTRUN_STATUS_STATE_KEY, PAYMENTRUN_INTERNAL_NARRATIVE_STATE_KEY,
    PAYMENTRUN_EXTERNAL_NARRATIVE_STATE_KEY,
     PAYMENTRUN_CODE_STATE_KEY, PAYMENTRUN_TYPE_STATE_KEY, PAYMENTRUN_AVAILABLE_ON_PORTAL_FROM_STATE_KEY, PAYMENTRUN_AVAILABLE_ON_PORTAL_TO_STATE_KEY
} from "../../../Consts";
import { updatePaymentRunField, updateUsageField } from "../../../../redux/reducers/RepertoireReducer";

import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IUsageGroupStateKeys } from "../../../../redux/types/IUsageGroupState";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { getLookupDefault, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { PAYMENTRUN_TYPE_LOOKUP, PAYMENTRUN_STATUS_LOOKUP } from "../../../../lookup/Consts";
import { IPaymentRunState, IPaymentRunStateKeys } from "../../../../redux/types/IPaymentRunState";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";

export interface IPaymentRunMaintenanceGeneralDataViewProps {
    paymentRunMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    paymentRun: IPaymentRunState;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    updatePaymentRunField: typeof updatePaymentRunField;
}

interface IPaymentRunMaintenanceGeneralDataViewState {
    loaded: boolean;
}

export default class PaymentRunMaintenanceGeneralDataView extends React.PureComponent<
    IPaymentRunMaintenanceGeneralDataViewProps,
    IPaymentRunMaintenanceGeneralDataViewState> {
    viewData;
    constructor(props: IPaymentRunMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            loaded: false
        };
        this.viewData = {};
    }


    componentDidMount() {
        const {
            paymentRunMaintenanceGeneralDataViewData: { fields }
        } = this.props;

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);

            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }


    showFieldsModal = () => {

    }

    render() {
        const {
            isReadonly,
            lookupValues,
            updatePaymentRunField,
        } = this.props;

        const {
            paymentRunID,
            status,
            code,
            type,
            internalNarrative,
            externalNarrative,
            paymentRunDate,
            showOnPortal,
            showStatusChangeWarning,
            availableOnPortalFrom,
            availableOnPortalTo,
        } = this.props.paymentRun;

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            const { status } = this.props.paymentRun;
            if (name === "status" && status === "Closed" && value === "Open") {
                updatePaymentRunField(true, 'showStatusChangeWarning');
            }
            updatePaymentRunField(value, name as IPaymentRunStateKeys);
        }

        if (loaded) {
            return (
                <>
                    <div>
                        <div className="row">
                            <div className="col-md-12 editableSectionHeading">
                                <h1 className="borderBootom">Payment Run Header</h1>
                            </div>
                        </div>
                        <div className="row">
                            {<SizedTextDataInput
                                label={this.viewData[PAYMENTRUN_CODE_STATE_KEY]}
                                fieldName={PAYMENTRUN_CODE_STATE_KEY}
                                changeData={changeData}
                                value={code}
                                isHidden={false}
                                readOnly={status==='Closed'}
                            />}

                            {<SizedDropdownDataInput
                                label={this.viewData[PAYMENTRUN_TYPE_STATE_KEY]}
                                fieldName={PAYMENTRUN_TYPE_STATE_KEY}
                                value={type ? type : getLookupDefault(PAYMENTRUN_TYPE_LOOKUP, lookupValues)}
                                changeData={changeData}
                                options={getLookupValues(PAYMENTRUN_TYPE_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={status==='Closed'}
                                isHidden={false}

                            />}

                        </div>

                        <div className="row">

                            {<SizedDateDataInput
                                label={this.viewData[PAYMENTRUN_DATE_STATE_KEY]}
                                fieldName={PAYMENTRUN_DATE_STATE_KEY}
                                value={paymentRunDate ? paymentRunDate.toString() : null}
                                changeData={changeData}
                                isHidden={false}
                                readOnly={status==='Closed'}
                            />}

                            {<SizedDropdownDataInput
                                label={this.viewData[PAYMENTRUN_STATUS_STATE_KEY]}
                                fieldName={PAYMENTRUN_STATUS_STATE_KEY}
                                value={status ? status : getLookupDefault(PAYMENTRUN_STATUS_LOOKUP, lookupValues)}
                                changeData={changeData}
                                options={getLookupValues(PAYMENTRUN_STATUS_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={false}
                                isHidden={false}

                            />}

                        </div>

                        <div className="row">

                            {<SizedTextDataInput
                                label={this.viewData[PAYMENTRUN_INTERNAL_NARRATIVE_STATE_KEY]}
                                fieldName={PAYMENTRUN_INTERNAL_NARRATIVE_STATE_KEY}
                                changeData={changeData}
                                value={internalNarrative}
                                isHidden={!this.viewData[PAYMENTRUN_INTERNAL_NARRATIVE_STATE_KEY]}
                                readOnly={status==='Closed'}
                            />}

                            {<SizedTextDataInput
                                label={this.viewData[PAYMENTRUN_EXTERNAL_NARRATIVE_STATE_KEY]}
                                fieldName={PAYMENTRUN_EXTERNAL_NARRATIVE_STATE_KEY}
                                changeData={changeData}
                                value={externalNarrative}
                                isHidden={!this.viewData[PAYMENTRUN_EXTERNAL_NARRATIVE_STATE_KEY]}
                                readOnly={status==='Closed'}
                            />}

                        </div>


                        <div className="row">

                            {<SizedDateDataInput
                                label={this.viewData[PAYMENTRUN_AVAILABLE_ON_PORTAL_FROM_STATE_KEY]}
                                fieldName={PAYMENTRUN_AVAILABLE_ON_PORTAL_FROM_STATE_KEY}
                                changeData={changeData}
                                value={availableOnPortalFrom ? availableOnPortalFrom.toString() : null}
                                isHidden={!this.viewData[PAYMENTRUN_AVAILABLE_ON_PORTAL_FROM_STATE_KEY]}
                                readOnly={status === 'Closed'}
                            />}

                            {<SizedDateDataInput
                                label={this.viewData[PAYMENTRUN_AVAILABLE_ON_PORTAL_TO_STATE_KEY]}
                                fieldName={PAYMENTRUN_AVAILABLE_ON_PORTAL_TO_STATE_KEY}
                                changeData={changeData}
                                value={availableOnPortalTo ? availableOnPortalTo.toString() : null}
                                isHidden={!this.viewData[PAYMENTRUN_AVAILABLE_ON_PORTAL_TO_STATE_KEY]}
                                readOnly={status === 'Closed'}
                            />}

                        </div>
                        <hr />
                    </div>
                </>
            );
        }
        return <div />;
    }
}