import * as React from "react";
import path from 'path'
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { addDroppedFile, cleanDroppedFilesRepertoireState, clearModalSearchResults, incrementTempID, setEditableFields, setProgressUploadFileRepertoireProccess } from "../../../../redux/reducers/MembersPortalReducer";
import { IUsageGroupState, IUsageGroupStateKeys } from "../../../../redux/types/IUsageGroupState";
import { SizedDropdownDataInput } from "../../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { USAGE_GROUP_VENUE_TYPE_LOOKUP, USAGE_GROUP_PERFORMANCE_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { SizedTextDataInput } from "../../../../repertoire/components/textDataInput/TextDataInput";
import { ADD_NEW_DATE_ACTION, LIVE_PERFORMANCE_END_TIME_STATE_KEY, LIVE_PERFORMANCE_LOCATION_STATE_KEY, LIVE_PERFORMANCE_OTHER_ARTIST_PERFORM_STATE_KEY, LIVE_PERFORMANCE_PERFORMANCE_DATE_STATE_KEY, LIVE_PERFORMANCE_PERFORMANCE_TYPE_STATE_KEY, LIVE_PERFORMANCE_PERFORMER_STATE_KEY, LIVE_PERFORMANCE_PROMOTER_STATE_KEY, LIVE_PERFORMANCE_SET_LIST_STATE_KEY, LIVE_PERFORMANCE_STAGE_STATE_KEY, LIVE_PERFORMANCE_START_TIME_STATE_KEY, LIVE_PERFORMANCE_STATE_KEY, LIVE_PERFORMANCE_VENUE_CONTACT_FIRST_NAME_STATE_KEY, LIVE_PERFORMANCE_VENUE_CONTACT_LAST_NAME_STATE_KEY, LIVE_PERFORMANCE_VENUE_CONTACT_PHONE_NUMBER_STATE_KEY, LIVE_PERFORMANCE_VENUE_OTHER_VENUE_TYPE_STATE_KEY, LIVE_PERFORMANCE_VENUE_TYPE_STATE_KEY, SEARCH_VIEW_DRAFT_SET_LISTS, SET_LIST_MODAL_SEARCH_VIEW } from "../../../Consts";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import { SizedCheckboxDataInput } from "../../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import DataGridTable from "../../../../repertoire/components/dataGridTable/DataGridTable";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { MEMBERS_PORTAL } from "../../../../accounts/Consts";
import SimpleTimeDataInput from "../../../../repertoire/components/timeFieldDataInput/SimpleTimeDataInput";
import SizedDatePickerDataInput from "../../../../repertoire/components/dateDataInput/DatePickerDataInput";
import { LIVE_PERFORMANCE_PAGE_VIEW, SEARCH_VIEW } from "../../../../repertoire/Consts";
import MapSearch from "../../../components/maps/MapSearch";
import { IUsageGroupLocation } from "../../../../repertoire/types/usageTypes/IUsageGroupLocation";
import { CleanUploadFilesState, addFileToUpload, fileMetadataChanged, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { IDistribution } from "../../../../repertoire/types/usageTypes/IDistribution";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { MembersPortalService } from "../../../../redux/services/MembersPortalService";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { ITreeData } from "../../../../repertoire/types/ITreeData";
import { IRemoveWorkBlobAttachment } from "../../../../repertoire/types/IRemoveWorkBlobAttachment";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IPaginationDetail } from "../../../../redux/types/IPaginationDetail";
import ScrollEventListenerWrapperContainerDetailsWindow from "../../../../dataingestion/components/containerDetailsWindow/ScrollEventListenerWrapperContainerDetailsWindow";

export interface ILivePerformanceMaintenanceGeneralDataViewProps {
    livePerformanceMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    usage: IUsageGroupState;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: any[];
    isNewLivePerformance?: boolean;
    updateLivePerformanceField: (value: any, fieldName: IUsageGroupStateKeys, index?: number) => void;
    resetMessageBanner: () => void;
    changesMade: boolean;
    updateComponentFieldsItems?: (fields: IMembersPortalField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IMembersPortalComponentDataItem;
    dataGridTableEditableFieldsData?: IMembersPortalComponentDataItem;
    setEditableFields?: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    incrementTempID?: () => void;
    activeHeaderSection?: string;
    isMandatory?: boolean;
    livePerformanceMaintenanceGridsViewData: IMembersPortalComponentDataItem;
    addFileToUpload: typeof addFileToUpload;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    containerDetailsWindowComponentData: IComponentDataItem;
    destinations: ILookupInstance[];
    distributions: IDistribution[];
    droppedFiles: IDroppedFiles;
    isDropZone: boolean;
    selectedFileSystem: IFileSystem;
    setInDropZone: typeof setInDropZone;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    sortColumn: typeof sortColumn;
    storagePathItemData: IComponentDataItem;
    attachedFiles: IStoragePathItemData[];
    refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    removeWorkAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => void;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    workAttachmentsDropped?: IDroppedFiles;
    cleanDroppedFilesRepertoire: typeof cleanDroppedFilesRepertoireState;
    setProgressUploadFileRepertoire: typeof setProgressUploadFileRepertoireProccess;
    refreshWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], activeTabItem: ITabReduxItem, readonlyIndicatorsWorkFlagTypes: string[], formats?: FormatFields[]) => void;
    dataSource: DataSource;
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    getDataIngestionComponentData?: () => void;
    currentUserNameNumber: string;
}

interface ILivePerformanceGeneralDataViewState {
    loaded: boolean;
    activeHeaderSection?: string;
}

export default class LivePerformanceMaintenanceGeneralDataView extends React.PureComponent<
    ILivePerformanceMaintenanceGeneralDataViewProps,
    ILivePerformanceGeneralDataViewState> {
    viewData;
    constructor(props: ILivePerformanceMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            activeHeaderSection: props.activeHeaderSection ?? "draftWorkWorkProperties",
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            livePerformanceMaintenanceGeneralDataViewData: { fields }
        } = this.props;

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);

            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }

    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    showFieldsModal = () => {

    }

    onClick = (fieldName: string) => {
        this.showSearchModal(fieldName, SET_LIST_MODAL_SEARCH_VIEW)
    }

    showSearchModal = (modalProps: any, searchViewKey: string) => {
        const { showModal } = this.props;
        clearModalSearchResults();
        showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search");
    };

    renderGridViewHeaderSections = (header: IMembersPortalField, isMandatory=false) => {
        return (
            <div
                className={["headerSection", "activeSection", (isMandatory ? "isMandatory" : "")].join(" ")}
                key={header.name}
                data-testid={header.name + "-section"}            >
                {header.data}
            </div>
        );
    };

    updateGoogleVenue = (venue: IUsageGroupLocation) => {
        const { updateLivePerformanceField } = this.props;
        updateLivePerformanceField(venue, LIVE_PERFORMANCE_LOCATION_STATE_KEY as IUsageGroupStateKeys)
    }

    renderLocationSection = () => {
        const { usage: { location } } = this.props;

        const placeUrl = location && location.googleVenue ? `https://www.google.com/maps/embed/v1/place?key=AIzaSyCa72Yu-mARGglrWXu6VgUKmwsr2QvCF14&q=place_id:${location.googleVenue}` : ""
        return (
            <>
                <div><MapSearch updateGoogleVenue={this.updateGoogleVenue} /></div>
                <div>
                    {location && location.googleVenue &&
                        <iframe
                            title="MapsIframe"
                            width="1000"
                            height="500"
                            className="mapFrame"
                            src={placeUrl}>
                        </iframe>
                    }
                </div>
            </>
        )
        // }

    }

    refreshWorkDetails() {
        const { tabs, activeTab, lookupValues, dataSource } = this.props;
        let currentTab = tabs[activeTab];
        this.props.refreshWorkDetails(
            dataSource,
            currentTab.workMaintenanceState.work.workID,
            lookupValues,
            tabs[activeTab].otherIndicatorWorkFlagTypes,
            currentTab,
            tabs[activeTab].readonlyIndicatorWorkFlagTypes
        )
    }

    removeAttachmentFromMaintenanceScreen(fullName: string, workId: number, dataSource: DataSource, guid: string, index: number) {
        const attachedFile: IAttachedFile = {
            fileName: fullName,
            workId: workId
        }
        const model: IRemoveWorkBlobAttachment = {
            Guid: guid,
            Source: dataSource,
            workID: workId
        }

        MembersPortalService.removeWorkBlobAttachment(model)
            .then(res => {
                if (res) {
                    MembersPortalService.removeWorkAttachment({ attachedFile: attachedFile, dataSource: dataSource })
                        .then((res: boolean) => {
                            if (res) {
                                this.refreshWorkDetails();
                            }
                        })
                }
            })
    }

    renderForm = (isReadOnly: boolean) => {
        const {
            lookupValues,
            updateLivePerformanceField,
            livePerformanceMaintenanceGridsViewData,
            usage,
            dataGridTableData,
            tabs,
            activeTab,
            addFileToUpload,
            cleanUploadFilesState,
            containerDetailsWindowComponentData,
            destinations,
            distributions,
            droppedFiles,
            isDropZone,
            selectedFileSystem,
            setInDropZone,
            setProgressUploadFileProccess,
            sortColumn,
            storagePathItemData,
            refreshDirectory,
            addRepertoireAttachmentToState,
            removeWorkAttachmentIfExists,
            addDroppedFileRepertoire,
            workAttachmentsDropped,
            cleanDroppedFilesRepertoire,
            setProgressUploadFileRepertoire,
            paginationDetails,
            updatePagination,
            dataSource,
            currentUserNameNumber
        } = this.props;
        const { fullPerformer, location } = this.props.usage;
        const attachedFiles = this.props.attachedFiles ?? [];

        const changeData = (value: any, fieldName: string) => {
            updateLivePerformanceField(value, fieldName as IUsageGroupStateKeys);
        }

        return (
            <>
                <div className="topMargin">
                    <div className="row">
                        <div className="headerContainer">
                            <h5>The <span className="important-label">*</span>indicates a mandatory field.</h5>
                        </div>
                        {<SizedDatePickerDataInput
                            value={usage.usages[0].usageDate.value ? usage.usages[0].usageDate.value : null}
                            fieldName={LIVE_PERFORMANCE_PERFORMANCE_DATE_STATE_KEY}
                            changeData={changeData}
                            label={this.viewData[LIVE_PERFORMANCE_PERFORMANCE_DATE_STATE_KEY]}
                            isHidden={false}
                            isMandatory={true}
                            readOnly={isReadOnly}
                        />}
                        <div className="form-group col-md-4 col-sm-6 col-xs-12">
                            {<SimpleTimeDataInput
                                label={this.viewData[LIVE_PERFORMANCE_START_TIME_STATE_KEY]}
                                value={usage.usages[0].programStartDate.value ? usage.usages[0].programStartDate.value : null}
                                changeData={changeData}
                                fieldName={LIVE_PERFORMANCE_START_TIME_STATE_KEY}
                                isMandatory={true}
                                readonly={isReadOnly}
                            />}
                        </div>

                        <div className="form-group col-md-4 col-sm-6 col-xs-12">
                            {<SimpleTimeDataInput
                                label={this.viewData[LIVE_PERFORMANCE_END_TIME_STATE_KEY]}
                                value={usage.usages[0].programEndDate.value ? usage.usages[0].programEndDate.value : null}
                                changeData={changeData}
                                fieldName={LIVE_PERFORMANCE_END_TIME_STATE_KEY}
                                isMandatory={true}
                                readonly={isReadOnly}
                            />}
                        </div>
                        {<SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[LIVE_PERFORMANCE_PERFORMER_STATE_KEY]}
                            fieldName={LIVE_PERFORMANCE_PERFORMER_STATE_KEY}
                            changeData={changeData}
                            readOnly={isReadOnly}
                            isHidden={false}
                            value={fullPerformer}
                            isMandatory={true}
                        />}
                    </div>
                    <div className="row">
                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(livePerformanceMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'livePerformanceMoreDates'))}
                        </div>
                        <div>
                            <h5>Note that this option should only be used for submissions where the VENUE and SET LIST ARE EXACTLY THE SAME</h5>
                            <DataGridTable
                                tableContents={usage.moreDates}
                                changeData={changeData}
                                dataGridTableData={dataGridTableData}
                                stateKey={LIVE_PERFORMANCE_STATE_KEY}
                                componentInstance={LIVE_PERFORMANCE_STATE_KEY}
                                showRowNumber={false}
                                isReadOnly={isReadOnly}
                                tableActions={[{ name: ADD_NEW_DATE_ACTION }]}
                                lookupValues={lookupValues}
                                incrementTempID={incrementTempID}
                                tabs={tabs}
                                activeTab={activeTab}
                                showDropdownsAsCodes={false}
                                clearModalSearchResults={clearModalSearchResults}
                                portalType={MEMBERS_PORTAL}
                                showModal={showModal}
                                hideModal={hideModal}
                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(livePerformanceMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'livePerformanceLocation'))}
                        </div>
                        <br></br>
                        <b>Venue: <span className="important-label">*</span></b>
                        {this.renderLocationSection()}
                    </div>
                    <div className="row">
                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(livePerformanceMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'livePerformanceVenueDetails'))}
                        </div>

                        {<SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[LIVE_PERFORMANCE_VENUE_CONTACT_FIRST_NAME_STATE_KEY]}
                            fieldName={LIVE_PERFORMANCE_VENUE_CONTACT_FIRST_NAME_STATE_KEY}
                            changeData={changeData}
                            readOnly={isReadOnly}
                            isHidden={false}
                            value={usage.venueDetails?.firstName}
                            isMandatory={true}
                        />}
                        {<SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[LIVE_PERFORMANCE_VENUE_CONTACT_LAST_NAME_STATE_KEY]}
                            fieldName={LIVE_PERFORMANCE_VENUE_CONTACT_LAST_NAME_STATE_KEY}
                            changeData={changeData}
                            readOnly={isReadOnly}
                            isHidden={false}
                            value={usage.venueDetails?.lastName}
                            isMandatory={true}
                        />}
                        {<SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[LIVE_PERFORMANCE_VENUE_CONTACT_PHONE_NUMBER_STATE_KEY]}
                            fieldName={LIVE_PERFORMANCE_VENUE_CONTACT_PHONE_NUMBER_STATE_KEY}
                            changeData={changeData}
                            readOnly={isReadOnly}
                            isHidden={false}
                            value={usage.venueDetails?.phoneNumber}
                            isMandatory={true}
                        />}
                        {<SizedDropdownDataInput
                            label={this.viewData[LIVE_PERFORMANCE_VENUE_TYPE_STATE_KEY]}
                            fieldName={LIVE_PERFORMANCE_VENUE_TYPE_STATE_KEY}
                            changeData={changeData}
                            options={getLookupValues(USAGE_GROUP_VENUE_TYPE_LOOKUP, lookupValues)}
                            readOnly={isReadOnly}
                            allowNull={false}
                            isHidden={false}
                            value={usage.venueDetails?.venueType}
                            isMandatory={true}
                        />}
                        {<SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[LIVE_PERFORMANCE_VENUE_OTHER_VENUE_TYPE_STATE_KEY]}
                            fieldName={LIVE_PERFORMANCE_VENUE_OTHER_VENUE_TYPE_STATE_KEY}
                            changeData={changeData}
                            readOnly={isReadOnly}
                            isHidden={false}
                            value={usage.venueDetails?.otherTypeOfVenue}
                            isMandatory={false}
                        />}

                    </div>
                    <div className="row">
                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(livePerformanceMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'livePerformanceSetList'))}
                        </div>
                        {<SizedTextDataInput
                            fieldType={"text"}
                            label={this.viewData[LIVE_PERFORMANCE_SET_LIST_STATE_KEY]}
                            fieldName={LIVE_PERFORMANCE_SET_LIST_STATE_KEY}
                            changeData={changeData}
                            readOnly={isReadOnly}
                            isHidden={false}
                            onClick={this.onClick}
                            clickPrompt={!isReadOnly}
                            value={usage.livePerformanceSetList?.title ? usage.livePerformanceSetList?.title[0] : '...'}
                            isMandatory={true}
                        />}
                    </div>
                    <div className="row">
                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(livePerformanceMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'livePerformanceProofOfPerformance'), true)}
                        </div>
                        <h5>Please attach a Venue/Organizer official confirmation/proof of performance(e.g Letterhead/Email/Signed document) in any of the following formats: PDF,JPG,PNG.</h5>
                        <br></br>
                        <b>Upload file here: </b>
                        {<div className="height">
                            {containerDetailsWindowComponentData.fields.length > 0 && (<ScrollEventListenerWrapperContainerDetailsWindow
                                isLivePerformance={true}
                                addFileToUpload={addFileToUpload}
                                cancelChanges={undefined}
                                cleanUploadFilesState={cleanUploadFilesState}
                                containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                                currentDirectory={"usage"}
                                destinations={destinations}
                                editingChanged={undefined}
                                fileMetadataChanged={fileMetadataChanged}
                                jobs={[]}
                                matchNow={undefined}
                                matchNowSubmit={undefined}
                                openDirectory={undefined}
                                pageContentIsActive={true}
                                distributions={distributions}
                                droppedFiles={droppedFiles}
                                isDropZone={isDropZone}
                                selectedFileSystem={selectedFileSystem}
                                setInDropZone={setInDropZone}
                                setProgressUploadFileProccess={setProgressUploadFileProccess}
                                sortColumn={sortColumn}
                                storagePathItemData={storagePathItemData}
                                storagePaths={attachedFiles}
                                refreshDirectory={refreshDirectory}
                                saveChanges={undefined}
                                saveState={undefined}
                                isRepertoireModule={true}
                                toggleSidebarCollapsed={undefined}
                                validationMessageChanged={undefined}
                                addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                                entityID={1}
                                entitySource={dataSource}
                                removeAttachmentIfExists={removeWorkAttachmentIfExists}
                                addDroppedFileRepertoire={addDroppedFileRepertoire}
                                attachmentsDropped={workAttachmentsDropped}
                                cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                                setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                                component={LIVE_PERFORMANCE_PAGE_VIEW}
                                removeAttachmentMaintenanceScreen={this.removeAttachmentFromMaintenanceScreen.bind(this)}
                                displayRemove={true}
                                paginationDetails={paginationDetails}
                                updatePagination={updatePagination}
                                lookupValues={lookupValues}
                                tabs={tabs}
                                activeTab={activeTab}
                                isProductAttachmentTypeNotRequired={true}
                                isProductAttachmentTypeOptional={false}
                                isWorkAttachmentTypeNotRequired={true}
                                isWorkAttachmentTypeOptional={false}
                                currentUserNameNumber={currentUserNameNumber}
                            />)}
                            <br></br>
                        </div>}
                    </div>
                    <div className="row">
                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(livePerformanceMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'livePerformanceAdditionSection'))}
                        </div>
                        <div className="row">
                            {<SizedDropdownDataInput
                                label={this.viewData[LIVE_PERFORMANCE_PERFORMANCE_TYPE_STATE_KEY]}
                                fieldName={LIVE_PERFORMANCE_PERFORMANCE_TYPE_STATE_KEY}
                                options={getLookupValues(USAGE_GROUP_PERFORMANCE_TYPE_LOOKUP, lookupValues)}
                                changeData={changeData}
                                readOnly={isReadOnly}
                                isHidden={false}
                                value={usage.setListAdditional?.performanceType}
                                isMandatory={false}
                                allowNull={false}
                            />}

                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={this.viewData[LIVE_PERFORMANCE_STAGE_STATE_KEY]}
                                fieldName={LIVE_PERFORMANCE_STAGE_STATE_KEY}
                                changeData={changeData}
                                readOnly={isReadOnly}
                                isHidden={false}
                                value={usage.setListAdditional?.stage}
                                isMandatory={false}
                            />}
                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={this.viewData[LIVE_PERFORMANCE_PROMOTER_STATE_KEY]}
                                fieldName={LIVE_PERFORMANCE_PROMOTER_STATE_KEY}
                                changeData={changeData}
                                readOnly={isReadOnly}
                                isHidden={false}
                                value={usage.setListAdditional?.promoter}
                                isMandatory={false}
                            />}
                        </div>
                        <div className="row">
                            {<SizedCheckboxDataInput
                                label={this.viewData[LIVE_PERFORMANCE_OTHER_ARTIST_PERFORM_STATE_KEY]}
                                fieldName={LIVE_PERFORMANCE_OTHER_ARTIST_PERFORM_STATE_KEY}
                                value={usage.setListAdditional?.otherArtistPerform}
                                changeData={changeData}
                                readonly={isReadOnly}
                            />}
                        </div>

                    </div>
                </div>
            </>
        );
    }

    render() {
        const { usage } = this.props;
        const { loaded } = this.state;

        if (!loaded) {
            return <div />
        }

        let isReadOnly: boolean;

        if (usage.approvalStatus === "Approved" || usage.approvalStatus === "Rejected") {
            isReadOnly = true;
        }
        else {
            isReadOnly = false;
        }

        return this.renderForm(isReadOnly);
    }
}