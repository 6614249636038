import { ISourceSearchResult } from "../types/ISourceSearchResult";
import { ActionType } from "../types/ActionType";

export class SourceUpdatingService {
  public static addChild(
    sourceTree: ISourceSearchResult,
    parentID: number,
    name: string,
    description: string,
    // rejectDuplicateUsageFiles: boolean,
    fakeId?:number,
    parentName?: string
  ): ISourceSearchResult {
    
    if (sourceTree == null) {
      return;
    }

    if (sourceTree.children == null) {
      sourceTree.children = [];
    }

    if(parentID === 0 ){
      if(sourceTree.name === parentName){
        sourceTree.children.push(
          SourceUpdatingService.createSource(sourceTree, name, description, fakeId)
        );
      }
    }
    else {
        if (sourceTree.sourceID == parentID ) {
          sourceTree.children.push(
            SourceUpdatingService.createSource(sourceTree, name, description, fakeId)
          );
      }
    }

    sourceTree.children.map(c =>
      SourceUpdatingService.addChild(c, parentID, name, description,fakeId,parentName)
    );


    return sourceTree;
  }

  private static createSource(
    parent: ISourceSearchResult,
    name: string,
    description: string,
    fakeId?:number
  ): ISourceSearchResult {
    let source: ISourceSearchResult = {
      sourceID: parent.sourceID === 0 ? -1 : 0,
      level: parent.level + 1,
      description: description,
      name: name,
      parameterInstances: null,
      children: null,
      parentID: parent.sourceID,
      actionType: ActionType.Added,
      matchType: parent.matchType,
      fakeID: fakeId ? fakeId : 0,
      rejectDuplicateUsageFiles: false,
      code: null
    };

    return source;
  }
}
