import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { showYesNoPrompt, hideModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    CLAIM_MAINTENANCE_TOOLBAR,    
    SAVE_METHOD_TOOLBAR,
    SAVE_CHANGES_STATE_KEY,    
    ADD_NEW_CLAIM_STATE_KEY,
    ADD_METHOD_TOOLBAR,
    UPDATE_CLAIMS_ROLE,
    ALL_ROLES,
    PROCESS_CLAIM_STATE_KEY,
    PROCESS_CLAIM_TOOLBAR,
    PROCESS_CLAIM_CONFIRMATION_STATE_KEY
} from "../../../Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { clearModalSearchResults } from "../../../../redux/reducers/RepertoireReducer";
import { IClaim } from "../../../types/IClaim";
import { IClaimState } from "../../../../redux/types/IClaimState";
import { triggerManualJob } from "../../../../redux/thunks/HomeThunks";
import { DISTRIBUTION_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
export interface IClaimMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData?: IRepertoireComponentDataItem;
    claim: IClaimState;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    addNewClaim: () => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    isReadonly: boolean;
    roles?: string[];
    processClaim: (claim: IClaimState) => void;
    triggerManualJob: typeof triggerManualJob;
    lookupValues: ILookupDictionary;
}

export type IClaimtMaintenanceToolbarKeys = keyof IClaimMaintenanceToolbarProps;

export interface IClaimMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];    
}

class ClaimMaintenanceToolbar extends React.Component<
    IClaimMaintenanceToolbarProps,
    IClaimMaintenanceToolbarState
> {

    constructor(props) {
        super(props)
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    saveChangesAction = () => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_CLAIMS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    processClaimAction = () => {
        const { showYesNoPrompt, hideModal,toolbarData } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === PROCESS_CLAIM_CONFIRMATION_STATE_KEY).data;

        const propsModal: IYesNoPromptViewModalProps = {
            yesCallback: () => { this.processClaim(); hideModal(); },
            noCallback: () => { hideModal(); }
        }

        showYesNoPrompt(title, propsModal);
    }

    processClaim = () => {

        const { claim, triggerManualJob, lookupValues } = this.props;
        const calculationTypesLookup = lookupValues[DISTRIBUTION_TYPE_LOOKUP];
        let selectedClaimCalculationTypes = [];

        if (claim.calculationType && calculationTypesLookup) {
            claim.calculationType.forEach((calcType, index) => {
                calculationTypesLookup.lookups.forEach((distributionType, index) => {
                    if (distributionType.typeID === calcType.calculationType) {
                        selectedClaimCalculationTypes.push(distributionType.description.replace(/\s/g, ''))
                    }
                })

            });
        }
        claim.selectedCalculationTypes = selectedClaimCalculationTypes
       const dataAction = [...this.state.dataAction];
       const dataActionIndex = dataAction.findIndex(d=> d.method === PROCESS_CLAIM_TOOLBAR);
       dataAction[dataActionIndex].isReadOnly = true;

       this.setState({
        dataAction : dataAction
       })
        let params = JSON.stringify(claim)
        triggerManualJob({ jobType: 'BC', jobParameters: params })
    }

    getDataAction() {
        const {
            addNewClaim,
            toolbarData: { fields },
            processClaim
        } = this.props;

        const actionList: IDataActionToolbar[] = [];

        const add: IDataActionToolbar = {
            action: addNewClaim,
            component: CLAIM_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(add => add.name === ADD_NEW_CLAIM_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(add);

        const process: IDataActionToolbar = {
            action: this.processClaimAction,
            component: CLAIM_MAINTENANCE_TOOLBAR,
            method: PROCESS_CLAIM_TOOLBAR,
            name: fields.find(process => process.name === PROCESS_CLAIM_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--TriggerAuto',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(process);

        const save: IDataActionToolbar = {
            action: this.saveChangesAction,
            component: CLAIM_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        return actionList;
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            claim,
            activeTab,
            toolbarData,
            tabs,
            isReadonly,
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade

        return (
            <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction}
                    toolbarData={toolbarData}
                    component={CLAIM_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].claimMaintenanceState.isNew}
                    isReadonly={isReadonly}

                />
            </div>
        );
    }

}

export default ClaimMaintenanceToolbar;
