import * as React from "react";
import { IRepertoireField } from "../../../types/IRepertoireField";


export interface ISubmissionPromptViewModalProps {  
    hideCallback: () => void;
    field: string;
    title: string
}

export interface SubmissionPromptViewProps {
    props: ISubmissionPromptViewModalProps;
}


const copyToClipboard = (id: string) => {
    var r = document.createRange();
    r.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();

    var copyMessage = document.getElementById("tooltipMessage");
    copyMessage.innerHTML = "Copied";
}

const outFunc = ()  => {
    var copyMessage = document.getElementById("tooltipMessage");
    copyMessage.innerHTML = "Copy to clipboard";
}

const SubmissionPromptView : React.SFC<SubmissionPromptViewProps> = ({props}) => {

return (
     <div id="codeModal">
         <div className="modal-dialog codeModal">
           <div className="modal-content">
             {/* <!-- Header--> */}
             <div className="modal-header">
               <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                  <i className="icon ms-Icon ms-Icon--BackToWindow" title="Close" onClick={props.hideCallback}></i>
               </button>
               <h4 className="modal-title" id="myModalLabel">{props.title}</h4>
             </div>
             {/* <!-- Content --> */}
             <div className="modal-body">
                <div className="codeContainer">
                    {/* <!-- Content heading--> */}
                    <div className="codeHeader">
                        <span className="language">JSON</span>
                        <button className="action copyMessage" 
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            copyToClipboard('codetoCopy'); return false;
                            }}
                            onMouseOut={(event: React.MouseEvent<HTMLElement>) => {
                                outFunc();
                            }}>
                            <span className="tooltiptext" id="tooltipMessage">Copy to clipboard</span>
                            <i className="icon ms-Icon ms-Icon--Copy" title="Copy"></i>Copy
                        </button>
                    </div>
                    {/* <!-- Box with Code Preformated--> */}
                    <pre id="codetoCopy">
                        {
                            props.field
                        }
                    </pre>  
                </div>
            </div>
          </div>
        </div>
    </div> 
);

}

export default SubmissionPromptView;