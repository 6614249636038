import * as React from 'react';
import { PropertyWindow } from './PropertyWindow';
import { TreeView } from '../../treeview/components/Tree-view';
import { ITreeNodeData } from '../../treeview/types/TreeNodeData';
import { ActionType } from '../types/ActionType';
import { ISourceSearchResult } from '../types/ISourceSearchResult';
import { IMatchType } from '../types/IMatchType';
import { IProductType } from '../types/IProductType';
import { TreeNodeBuilder } from '../services/TreeNodeBuilder';
import { TreeNodeBuilder as FolderTreeNodeBuilder } from "../../dataingestion/services/TreeNodeBuilder";
import { SourceService } from '../services/SourceService';
import { If } from '../../core/components/If';
import { ComponentFields } from '../../core/services/ComponentService';
import { ComponentsHelper as CH, ComponentsHelper } from '../../core/services/ComponentHelper';
import { IParameterData, ParameterData } from '../types/IParameterData';
import { ParameterValidator, ValidationResult } from '../services/ParameterValidator';
import { PropertyWindowSaveState } from '../types/PropertyWindowSaveState';
import { SourceUpdatingService } from '../services/SourceUpdatingService';
import { SettingType } from '../types/SettingType';
import { useMsal } from '@azure/msal-react';
import { msalConfig } from '../../accounts/types/msalAuthConfig';
import { ALL_ROLES, CUSTOM_NOTEBOOK, DELETE_MATCHING_SETTINGS_ROLE, DELETE_VALIDATION_SETTINGS_ROLE, PRODUCT_BASED_FOLDERS_PATH, UPDATE_MATCHING_SETTINGS_ROLE, UPDATE_VALIDATION_SETTINGS_ROLE, USAGE_FILE_SYSTEM_TYPE, USAGE_INGESTION_AUTO_PROCESS, USAGE_INGESTION_DEFAULT_DISTRIBUTION, USAGE_INGESTION_DEFAULT_INPUT_FILE_FORMAT, USAGE_INGESTION_DEFAULT_POOL, USAGE_INGESTION_FOLDER_NAME, USAGE_TO_PRODUCT_TYPE, USAGE_TO_WORK_TYPE, VIEW_MATCHING_SETTINGS_ROLE, VIEW_VALIDATION_SETTINGS_ROLE, WORK_BASED_FOLDERS_PATH } from '../Consts';
import { VIEW_DISTRIBUTIONS_ROLE, VIEW_POOLS_ROLE } from '../../dataingestion/Consts';
import { IDistribution } from '../../repertoire/types/usageTypes/IDistribution';
import { IUsagePool } from '../../repertoire/types/usageTypes/IUsagePool';
import { ILookupDictionary } from '../../lookup/types/ILookupDictionary';
import { ILookupInstance } from '../../lookup/types/ILookup';
import { FolderSection } from '../../dataingestion/types/FolderSection';
import { IFileSystem } from '../../dataingestion/types/IFileSystem';
import { ITreeData } from '../../repertoire/types/ITreeData';
import { IParameterSearchResult } from '../types/IParameterSearchResult';

export interface ISettingsPageProps {
    settingType: SettingType;
    path?: string;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    distributions: IDistribution[];
    pools?: IUsagePool[];
    getDistributions: () => void;
    getPools?: () => void;
    lookupValues?: ILookupDictionary;
    initialiseLookupsThunk: (lookupValues: ILookupDictionary) => void;
    formats: ILookupInstance[];
    getPathFilesystems: (path: string, disallowedFolderSections: FolderSection[]) => void;
    filesystems: IFileSystem[];
    selectedFolderNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => void;
    folderTreeNodes: ITreeNodeData<IFileSystem>[];
    folderSelectedNode: ITreeNodeData<IFileSystem>;
    sources?: ITreeData[];
    getMatchingSources?: () => void;
    getUsedFolderList?: () => void;
    sorcesFolderList?: IParameterSearchResult[];
}

enum Fields {
    PageTitle,
    MatchTypeCombobox,
    ParameterTypeError,
    ConfirmExitMessage,
    ParameterRequiredError,
    EditHierarchy,
    EditHierarchySave,
    EditHierarchyCancel,
    DefaultSourceName,
    DefaultSubSourceName,
    DefaultSourceDescription,
    DefaultSubSourceDescription,
    AddSource,
    DeleteSource
}

enum SourceMode {
    Viewing,
    EditingHierarchy,
    EditingSource
}

export const SettingsPage = (props: ISettingsPageProps) => {
    const ComponentName: string = "SettingsPage";

    const [selectedSource, setSelectedSource] = React.useState(null);
    const [sources, setSources] = React.useState(null);
    const [treeNodes, setTreeNodes] = React.useState(null);
    const [selectedNode, setSelectedNode] = React.useState(null);
    const [editingParameter, setEditingParameter] = React.useState(null);
    const [matchTypes, setMatchTypes] = React.useState(null);
    const [activeFields, setActiveFields] = React.useState({});
    const [selectedMatchType, setSelectedMatchType] = React.useState(null);
    const [selectSourceParameters, setSelectSourceParameters] = React.useState(null);
    const [selectedSourceName, setSelectedSourceName] = React.useState(null);
    const [selectedSourceCode, setSelectedSourceCode] = React.useState(null);
    const [selectedSourceDescription, setSelectedSourceDescription] = React.useState(null);
    const [rejectDuplicateUsageFiles, setRejectDuplicateUsageFiles] = React.useState(null);
    const [isDirty, setIsDirty] = React.useState(false);
    const [saveState, setSaveState] = React.useState(PropertyWindowSaveState.Unchanged);
    const [productTypes, setProductTypes] = React.useState(null);
    const [selectedProductType, setSelectedProductType] = React.useState(null);
    const [selectedSourceIsTopLevel, setSelectedSourceIsTopLevel] = React.useState(true);
    const [sourceMode, setSourceMode] = React.useState(SourceMode.Viewing);
    const [sidebarWrapperIsActive, setSidebarWrapperIsActive] = React.useState(true);
    const [sidebarIsCollapsed, setSidebarIsCollapsed] = React.useState(false);
    const [topLevelSource, setTopLevelSource] = React.useState(null);
    const [deletedNodes, setDeletedNodes] = React.useState([]);
    const [fakeId, setFakeId] = React.useState(1000);
    const [sourceValidationErrorMsg, setSourceValidationErrorMsg] = React.useState("");
    const [selectedSourceID, setSelectedSourceID] = React.useState(-1);
    const [usageIngestionFolderName, setUsageIngestionFolderName] = React.useState(null);
    const [usageIngestionDefaultPool, setUsageIngestionDefaultPool] = React.useState(null);
    const [usageIngestionDefaultDistribution, setUsageIngestionDefaultDistribution] = React.useState(null);
    const [usageIngestionDefaultInputFileFormat, setUsageIngestionDefaultInputFileFormat] = React.useState(null);
    const [usageIngestionAutoProcess, setUsageIngestionAutoProcess] = React.useState(null);
    const [customNotebook, setCustomNotebook] = React.useState(null);
    const [isDeletingSwitch, setIsDeletingSwitch] = React.useState(false);

    const { instance, accounts } = useMsal()
    const { filesystems, roles } = props;

    const startup = () => {

        ComponentFields.get(ComponentName)
            .then(af => {
                setActiveFields(af)

                if (CH.getField(Fields.PageTitle, Fields, activeFields).exists) {
                    document.title = CH.getField(Fields.PageTitle, Fields, activeFields).text;
                }
            });

        SourceService.getMatchTypes()
            .then(m => {

                setMatchTypes(m);

                if (m == null || m.length === 0) {
                    setMatchTypes(m);
                    setSelectedMatchType(null);

                    return;
                }
                else {
                    const validationMatchType = m.find(mt => mt.name === "Work to Work");
                    const selectedMatchTypeID: number = props.settingType === SettingType.Matching ? m[0].matchTypeID : validationMatchType.matchTypeID;

                    setMatchTypes(m);
                    setSelectedMatchType(selectedMatchTypeID);

                    loadSelectedMatchType(selectedMatchTypeID);
                }
            });
    }

    React.useEffect(() => {

        startup();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();

        const {
            initialiseLookupsThunk,
            lookupValues,
            formats
        } = props;

        if (!formats || formats.length === 0) {
            initialiseLookupsThunk(lookupValues);
        }

        const { getPathFilesystems, getMatchingSources, getUsedFolderList } = props;
        let disallowedFolderSections: FolderSection[] = []
        disallowedFolderSections.push(FolderSection.Repertoire);
        disallowedFolderSections.push(FolderSection.Payment);

        if (getPathFilesystems && getMatchingSources) {
            getPathFilesystems(USAGE_FILE_SYSTEM_TYPE, disallowedFolderSections);
            getMatchingSources();
        }

        if (getUsedFolderList) getUsedFolderList();

        window.addEventListener("beforeunload", confirmExit);

        return () => {
            window.removeEventListener("beforeunload", confirmExit);
        }
    }, [])

    React.useEffect(() => {
        const { getDistributions, getPools, roles } = props;
        if (getDistributions && (roles.includes(VIEW_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES))) getDistributions();
        if (getPools && (roles.includes(VIEW_POOLS_ROLE) || roles.includes(ALL_ROLES))) getPools();
    }, [roles])


    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }


    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const loadSelectedMatchType = (matchTypeID?: number): Promise<boolean> => {
        return new Promise((resolve) => {
            SourceService.getSources(matchTypeID)
                .then(s => {
                    let selected: ISourceSearchResult = s ? s.sources[0] : null;
                    let data: ITreeNodeData<ISourceSearchResult> = TreeNodeBuilder.build(selected, deletedNodes);

                    setSources(s.sources);
                    setTreeNodes(data);
                    setProductTypes(s.productTypes);
                    setSelectedProductType(s.productTypes != null && s.productTypes.length > 0 ? s.productTypes[0] : null);
                    setTopLevelSource(selected);

                    loadSelectedSource(selected.sourceID, PropertyWindowSaveState.Unchanged, selectedProductType)
                        .then(r => resolve(r));
                });
        });
    }

    const loadSelectedSource = (sourceID: number, saveStateParameter: PropertyWindowSaveState, productType: IProductType): Promise<boolean> => {
        return new Promise((resolve) => {
            SourceService.loadSource(sourceID, productType != null ? productType.name : null, props.settingType)
                .then(s => {

                    let parameterData: IParameterData[] = s ? ParameterData.FromSearchResults(s.parameterInstances) : null;

                    setSelectedSource(s);
                    setSelectSourceParameters(parameterData);
                    setSelectedSourceName(s ? s.name : null);
                    setSelectedSourceCode(s ? s.code : null);
                    setSelectedSourceDescription(s ? s.description : null);
                    setRejectDuplicateUsageFiles(s ? s.rejectDuplicateUsageFiles : null);
                    setSelectedSourceID(sourceID);
                    setSelectedSourceIsTopLevel(s ? s.level === 0 : false);
                    setIsDirty(false);
                    setSaveState(saveStateParameter);
                    setSelectedProductType(productType);

                    setUsageIngestionFolderName(s?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_FOLDER_NAME)[0]);
                    setUsageIngestionDefaultPool(s?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_DEFAULT_POOL)[0]);
                    setUsageIngestionDefaultDistribution(s?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_DEFAULT_DISTRIBUTION)[0]);
                    setUsageIngestionDefaultInputFileFormat(s?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_DEFAULT_INPUT_FILE_FORMAT)[0]);
                    setUsageIngestionAutoProcess(s?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_AUTO_PROCESS)[0]);
                    setCustomNotebook(s?.parameterInstances.filter(p => p.parameterName === CUSTOM_NOTEBOOK)[0]);

                    resolve(true);
                });
        });
    }
    const selectedSourceChanged = (source: ISourceSearchResult) => {
        setSelectSourceParameters(null);
        setSelectedSourceName(source ? source.name : null);
        setSelectedSourceCode(source ? source.code : null);
        setSelectedSourceDescription(source ? source.description : null);
        setRejectDuplicateUsageFiles(source ? source.rejectDuplicateUsageFiles : null);

        setUsageIngestionFolderName(source?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_FOLDER_NAME)[0]);
        setUsageIngestionDefaultPool(source?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_DEFAULT_POOL)[0]);
        setUsageIngestionDefaultDistribution(source?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_DEFAULT_DISTRIBUTION)[0]);
        setUsageIngestionDefaultInputFileFormat(source?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_DEFAULT_INPUT_FILE_FORMAT)[0]);
        setUsageIngestionAutoProcess(source?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_AUTO_PROCESS)[0]);
        setCustomNotebook(source?.parameterInstances.filter(p => p.parameterName === CUSTOM_NOTEBOOK)[0]);

        loadSelectedSource(source.sourceID, PropertyWindowSaveState.Unchanged, selectedProductType);
    }

    const selectFolderNodeChanged = (node: ITreeNodeData<ISourceSearchResult>) => {
        const { selectedFolderNodeChanged } = props;

        let usageToWorkType = USAGE_TO_WORK_TYPE;
        let usageToProductType = USAGE_TO_PRODUCT_TYPE;
        let workBasedPath = WORK_BASED_FOLDERS_PATH;
        let productBasedPath = PRODUCT_BASED_FOLDERS_PATH;

        let folderNode = node.object.matchType.name === usageToWorkType
            ? FolderTreeNodeBuilder.build(filesystems.filter(f => f.fullName === workBasedPath))[0]
            : node.object.matchType.name === usageToProductType
                ? FolderTreeNodeBuilder.build(filesystems.filter(f => f.fullName === productBasedPath))[0]
                : null

        if (folderNode != null) selectedFolderNodeChanged(folderNode, sources);
    }


    const selectedNodeChanged = (node: ITreeNodeData<ISourceSearchResult>) => {
        if (!confirmChange() || node === null) {
            return;
        }
        const prev = selectedNode;
        if (prev) {
            prev.active = false;
        }

        if (prev && prev.id === node.id)
            node.isExpanded = !node.isExpanded;
        else
            node.isExpanded = true;

        node.active = true;
        setSelectedNode(node);
        setFakeId(node.object.fakeID);
        selectedSourceChanged(node.object);
        selectFolderNodeChanged(node);
    }

    const updateSource = () => {
        setIsDirty(false);

        if (selectSourceParameters.find(p => p.hasError)) {
            setSaveState(PropertyWindowSaveState.SaveValidationFailed);
        }
        else {
            SourceService.update(ParameterData.ToSearchResults(selectSourceParameters))
                .then(res => {
                    setSaveState(res ? PropertyWindowSaveState.SaveSucceeded : PropertyWindowSaveState.SaveFailed);

                    loadSelectedSource(selectedSourceID, saveState, selectedProductType);
                });
        }
    }

    const cancelChanges = () => {
        loadSelectedSource(selectedSourceID, PropertyWindowSaveState.Cancelled, selectedProductType);
    }

    const overrideChanged = (parameterID: number, override: boolean) => {

        let isTopLevel: boolean = selectedSourceIsTopLevel;
        let isDefaultProductType: boolean = selectedProductType != null ? selectedProductType.isDefault : true;
        if (isTopLevel && isDefaultProductType) { return; }

        let tempParameter = selectSourceParameters.find(p => p.parameterID === parameterID);
        tempParameter.overrideParentValue = override;

        if (!override) {
            tempParameter.currentValue = tempParameter.defaultValue;
            tempParameter.hasError = false;
            tempParameter.errorMessage = '';
        }

        setEditingParameter(tempParameter);
        setIsDirty(true);
    }

    const currentValueChanged = (parameterID: number, currentValue: string) => {
        let tempParameter = selectSourceParameters.find(p => p.parameterID === parameterID);
        tempParameter.currentValue = currentValue;

        let valid: ValidationResult = ParameterValidator.isValid(tempParameter);

        if (valid === ValidationResult.Valid) {
            tempParameter.hasError = false;
            tempParameter.errorMessage = '';
        }
        else if (valid === ValidationResult.RequiredError) {
            tempParameter.hasError = true;
            tempParameter.errorMessage = CH.getField(Fields.ParameterRequiredError, Fields, activeFields).text;
        }
        else {
            tempParameter.hasError = true;
            tempParameter.errorMessage = CH.getField(Fields.ParameterTypeError, Fields, activeFields).text + tempParameter.parameterType;
        }

        selectSourceParameters[selectSourceParameters.indexOf(tempParameter)] = tempParameter;
        setSelectSourceParameters(Object.assign([], selectSourceParameters));
        setEditingParameter(tempParameter);
        setIsDirty(true);
    }

    const selectedMatchTypeChanged = (event: React.SyntheticEvent<HTMLSelectElement>) => {
        if (confirmChange()) {
            let matchTypeId: number = parseInt(event.currentTarget.value);
            setSelectedMatchType(matchTypeId);

            loadSelectedMatchType(matchTypeId);
        }
    }

    const selectedProductTypeChanged = (event: React.SyntheticEvent<HTMLSelectElement>) => {
        if (confirmChange()) {
            let productTypeName: string = event.currentTarget.value;
            let productType: IProductType = productTypes.find(p => p.name === productTypeName);
            setSelectedProductType(productType);
            loadSelectedSource(selectedSourceID, PropertyWindowSaveState.Unchanged, productType);
        }
    }

    const editHierarchyClicked = () => {
        setSourceMode(SourceMode.EditingHierarchy)
    }

    const editHierarchySave = () => {
        const { getMatchingSources } = props;

        setSourceMode(SourceMode.Viewing);
        setDeletedNodes([]);

        SourceService.updateSourceHierarchy(treeNodes.object)
            .then(s => {
                if (s != null) {
                    let selected: ISourceSearchResult = s ? s.sources[0] : null;
                    let data: ITreeNodeData<ISourceSearchResult> = TreeNodeBuilder.build(selected, deletedNodes);

                    setSources(s.sources);
                    setTreeNodes(data);
                    setSourceMode(SourceMode.Viewing);

                    getMatchingSources();

                    loadSelectedSource(selected.sourceID, PropertyWindowSaveState.Unchanged, null);
                }
                else {
                    startup();
                }
            });

    }

    const editHierarchyCancel = () => {
        setSourceMode(SourceMode.Viewing);
        if (deletedNodes.length > 0) {
            let nodes = TreeNodeBuilder.showNode(treeNodes, deletedNodes);
            setTreeNodes(nodes);
            return;
        }
        loadSelectedMatchType(selectedMatchType);
    }

    const confirmExit = (e: BeforeUnloadEvent): void => {
        if (isDirty) {
            e.preventDefault();
            const msg: string = CH.getField(Fields.ConfirmExitMessage, Fields, activeFields).text;
            e.returnValue = msg;
        }
    }

    const confirmChange = (): boolean => {
        if (isDirty) {
            return window.confirm(CH.getField(Fields.ConfirmExitMessage, Fields, activeFields).text);
        }
        else {
            return true;
        }
    }

    const editSourceClick = () => {
        setSourceMode(SourceMode.EditingSource);
    }

    const editSourceSaveClick = (sourceID: number, sourceName: string, sourceCode: string, sourceDescription: string, rejectDuplicateUsageFiles: boolean, usageConfigurationParameters: IParameterSearchResult[]) => {

        const { getUsedFolderList } = props;
        setSourceMode(SourceMode.EditingSource);

        let source: ISourceSearchResult = selectedSource;

        SourceService.updateSource(sourceID, sourceName, sourceCode, sourceDescription, rejectDuplicateUsageFiles)
            .then((r: boolean) => {
                loadSelectedMatchType(selectedMatchType)
                    .then(r => selectedSourceChanged(source));

                setSourceMode(SourceMode.Viewing);

                if (usageConfigurationParameters.length !== 0) {
                    SourceService.update(usageConfigurationParameters)
                        .then(res => {
                            loadSelectedSource(selectedSourceID, saveState, selectedProductType);

                            if (getUsedFolderList) getUsedFolderList();
                        });
                }
            }, (rejectedReason: Error) => {
                updateSourceReject(rejectedReason.message);
            });
    }

    const editSourceCancelClick = () => {
        setSourceMode(SourceMode.Viewing);
    }



    const getHighestNumber = (parent: ISourceSearchResult, name: string) => {
        let highestDuplicateSourceName: number = 0;
        parent.children.filter(childrenItem =>
            childrenItem.name.includes(name))
            .map((children) => {
                let nameArray = children.name.split(' ');
                let currentNumber: number = Number((nameArray[nameArray.length - 1]));
                if (currentNumber > highestDuplicateSourceName)
                    highestDuplicateSourceName = currentNumber
            });

        return highestDuplicateSourceName;
    }

    const addSource = (parent: ISourceSearchResult) => {
        let description: string = '';
        let name: string = '';

        if (parent.level == 0) {
            name = CH.getField(Fields.DefaultSourceName, Fields, activeFields).text;
            description = CH.getField(Fields.DefaultSourceDescription, Fields, activeFields).text;
        }
        else {
            name = CH.getField(Fields.DefaultSubSourceName, Fields, activeFields).text;
            description = CH.getField(Fields.DefaultSubSourceDescription, Fields, activeFields).text;
        }

        const defaultNameSources = getHighestNumber(parent, name) + 1;

        if (defaultNameSources > 0)
            name = `${name} 0${defaultNameSources.toString()}`;
        const newFakeId: number = fakeId + 1

        SourceUpdatingService.addChild(treeNodes.object, parent.sourceID, name, description, fakeId, parent.name);
        setFakeId(newFakeId);

        let nodes = TreeNodeBuilder.build(treeNodes.object, deletedNodes);
        setTreeNodes(nodes);
    }

    const deleteSource = (source: ISourceSearchResult) => {
        source.actionType = ActionType.Deleted;
        setIsDeletingSwitch(!isDeletingSwitch);
        const newDeletedNodes = deletedNodes;
        const deletedNodeID: number = source.sourceID <= 0 ? source.fakeID : source.sourceID;

        newDeletedNodes.push(deletedNodeID);
        setDeletedNodes(newDeletedNodes);

        let nodes = TreeNodeBuilder.hideNode(treeNodes, deletedNodeID, source.fakeID);
        setTreeNodes(nodes);
    }

    const onToggleSidebarCollapsedClick = () => {
        setSidebarIsCollapsed(!sidebarIsCollapsed);
        setSidebarWrapperIsActive(!sidebarWrapperIsActive);
    }

    const clearBanner = () => {
        setSaveState(PropertyWindowSaveState.Unchanged)
    }

    const updateSourceReject = (error: string) => {
        setSaveState(PropertyWindowSaveState.SaveFailed);
        setSourceValidationErrorMsg(error);
    }

    const getUpdateRoleDisabled = (): boolean => {
        const { roles, settingType } = props;
        if (settingType === SettingType.Matching) {
            if (roles && (roles.includes(UPDATE_MATCHING_SETTINGS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
        }
        else if (settingType === SettingType.Validation) {
            if (roles && (roles.includes(UPDATE_VALIDATION_SETTINGS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles, settingType } = props;
        if (settingType === SettingType.Matching) {
            if (roles && (roles.includes(VIEW_MATCHING_SETTINGS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
        }
        else if (settingType === SettingType.Validation) {
            if (roles && (roles.includes(VIEW_VALIDATION_SETTINGS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
        }
        return true;
    }

    const getDeleteRoleDisabled = (): boolean => {
        const { roles, settingType } = props;
        if (settingType === SettingType.Matching) {
            if (roles && (roles.includes(DELETE_MATCHING_SETTINGS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
        }
        else if (settingType === SettingType.Validation) {
            if (roles && (roles.includes(DELETE_VALIDATION_SETTINGS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
        }
        return true;
    }

    let className: string = "sidebarWrapper";

    if (!sidebarWrapperIsActive) {
        className = className + " active";
    }

    const showContainingDiv = !getViewRoleDisabled();


    return (
        <If condition={showContainingDiv}>
            <div className="wrapperPageLateralmenu">

                {/* Sources */}
                <nav className={className}>
                    <button type="button" id="sidebarClose"
                        className="navbar-toggle btnClose"
                        onClick={onToggleSidebarCollapsedClick}>
                        <i className="glyphicon glyphicon-remove" ></i>Close
                    </button>

                    {/* Match type combobox */}
                    <div className='dropdownSelect'>
                        <If condition={CH.getField(Fields.MatchTypeCombobox, Fields, activeFields).exists}>
                            <If condition={props.settingType === SettingType.Matching && matchTypes !== null && matchTypes.length > 1}>
                                <div>
                                    <label>{CH.getField(Fields.MatchTypeCombobox, Fields, activeFields).text}</label>
                                    <select onChange={selectedMatchTypeChanged}
                                        value={selectedMatchType ? selectedMatchType : 1}>
                                        {
                                            matchTypes ? matchTypes.map((m: IMatchType, idx: number) =>
                                                <option key={idx} value={m.matchTypeID}>{m.name}</option>) : null
                                        }
                                    </select>
                                </div>
                            </If>

                        </If>
                    </div>

                    {/* Edit hierarchy */}
                    <If condition={sourceMode == SourceMode.Viewing}>
                        <If condition={CH.getField(Fields.EditHierarchy, Fields, activeFields).exists}>
                                <li className="list-group-item node-treeview" onClick={editHierarchyClicked} style={{
                                    border: "none",
                                    cursor:"pointer"
                                }}>
                                    <i className="glyphicon glyphicon-pencil"
                                        aria-hidden="true">
                                </i>
                                {CH.getField(Fields.EditHierarchy, Fields, activeFields).text}
                            </li>
                    </If>
                </If>

                                {/* Cancel button */}
                                <If condition={sourceMode == SourceMode.EditingHierarchy}>
                    <If condition={CH.getField(Fields.EditHierarchyCancel, Fields, activeFields).exists}>
                        <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6 ">
                            <button className="button btn-defaultSecondary" onClick={editHierarchyCancel}>
                                {CH.getField(Fields.EditHierarchyCancel, Fields, activeFields).text}
                            </button>
                        </div>
                    </If>
                </If>

                {/* Save button */}
                <If condition={sourceMode == SourceMode.EditingHierarchy}>
                    <If condition={CH.getField(Fields.EditHierarchySave, Fields, activeFields).exists}>
                        <div className="col-lg-6 col-md-6 col-sm-6  col-xs-6 ">
                            <button className="button btn-defaultPrimary" onClick={editHierarchySave}>
                                {CH.getField(Fields.EditHierarchySave, Fields, activeFields).text}
                            </button>
                        </div>
                    </If>
                </If>

                {/* Sources tree view */}
                <div className="scrollBarY scrollBarMatchingSettings">
                    <TreeView<ISourceSearchResult>
                        data={[treeNodes]}
                        selectedNode={selectedNode}
                        selectedNodeChanged={selectedNodeChanged}
                        confirmChange={confirmChange}
                        isEditing={sourceMode == SourceMode.EditingHierarchy}
                        isDeleting={isDeletingSwitch}
                        addSourceButtonText={CH.getField(Fields.AddSource, Fields, activeFields).text}
                        deleteSourceButtonText={CH.getField(Fields.DeleteSource, Fields, activeFields).text}
                        addSource={addSource}
                        deleteSource={deleteSource}
                        sidebarCollapsed={sidebarIsCollapsed}
                        alwaysExpanded={true}
                        deleteRoleDisabled={getDeleteRoleDisabled()}
                    />
                </div>
            </nav>

            {/* Property window */}
            <PropertyWindow
                data={selectSourceParameters}
                updateSource={updateSource}
                cancelChanges={cancelChanges}
                overrideChanged={overrideChanged}
                currentValueChanged={currentValueChanged}
                selectSourceName={selectedSourceName}
                selectSourceCode={selectedSourceCode}
                sourceDescription={selectedSourceDescription}
                rejectDuplicateUsageFiles={rejectDuplicateUsageFiles}
                saveState={saveState}
                productTypes={productTypes}
                selectedProductType={selectedProductType}
                selectedProductTypeChanged={selectedProductTypeChanged}
                isDirty={isDirty}
                source={selectedSource}
                isEditingSource={sourceMode == SourceMode.EditingSource}
                editSourceClick={editSourceClick}
                cancelSourceChangesClick={editSourceCancelClick}
                saveSourceChangesClick={editSourceSaveClick}
                onToggleSidebarCollapsedClick={onToggleSidebarCollapsedClick}
                pageContentIsActive={!sidebarWrapperIsActive}
                clearBanner={clearBanner}
                sourceValidationErrorMsg={sourceValidationErrorMsg}
                updateRoleDisabled={getUpdateRoleDisabled()}
                distributions={props.distributions}
                pools={props.pools}
                lookupValues={props.lookupValues}
                folderSelectedNode={props.folderSelectedNode}
                sources={props.sources}
                sorcesFolderList={props.sorcesFolderList}
                usageIngestionFolderName={usageIngestionFolderName}
                usageIngestionDefaultPool={usageIngestionDefaultPool}
                usageIngestionDefaultDistribution={usageIngestionDefaultDistribution}
                usageIngestionDefaultInputFileFormat={usageIngestionDefaultInputFileFormat}
                usageIngestionAutoProcess={usageIngestionAutoProcess}
                customNotebook={customNotebook}
            />
        </div>
        </If>
    );
}
