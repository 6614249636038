import { connect } from "react-redux";
import MembersPortalPage from "./MembersPortalPage";
import IAppState from "../redux/types/IAppState";
import { showModal, showYesNoPrompt, updateComment } from "../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../redux/types/ITabReduxItem";
import {
    getDistributionsThunk,
    getMatchingSourcesThunk,
    searchIPsThunk
} from "../redux/thunks/RepertoireThunks";
import { Dispatch } from "redux";
import { clearModalSearchResultsThunk } from "../redux/thunks/ModalThunks";
import { RouteChildrenProps } from 'react-router';
import { setCurrentPage } from "../redux/reducers/HomeReducer";
import { IYesNoPromptViewModalProps } from "../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { addFileToUpload, setInDropZone, setProgressUploadFileProccess } from "../redux/reducers/DataIngestionReducer";
import IFile from "../redux/types/IFiles";
import { getLookupEntitiesThunk } from "../redux/thunks/LookupThunks";
import { getMembersPortalComponentDataThunk, loadMembersPortalFromPathThunk, saveContributorCommentThunk, searchAllRegisteredWorksThunk, searchArtistsThunk, searchContributorsThunk, searchDraftAgreementsIPThunk, searchDraftAgreementsWorksThunk, searchDraftSetListsThunk, searchDraftWorksThunk, searchRegisteredWorksThunk, updateWorkContributorIPThunk } from "../redux/thunks/MembersPortalThunks";
import { IMembersPortalPathInfo } from "./types/IMembersPortalPathInfo";
import { displayRepresentationFlag, searchDataChanged, setDataSource, updateSelectionCriteria } from "../redux/reducers/RepertoireReducer";
import { expandAllResults, mp_openTab, mp_removeTab, resetPagination, updateEditableFields, updatePagination, addArtistToWork, addWorkToSetList, addSetListToLivePerformance, updateDraftAgreementWorkField } from "../redux/reducers/MembersPortalReducer";
import { DataSource } from "../repertoire/types/DataSource";
import { IContributorItem } from "../repertoire/types/IContributorItem";
import { IP } from "../repertoire/types/IP";
import { IContributorSearchQuery } from "../repertoire/types/IContributorSearchQuery";
import { IArtistSearchQuery } from "../repertoire/types/IArtistSearchQuery";
import { IArtist } from "../repertoire/types/IArtist";
import { LOADING_VIEW } from "../repertoire/Consts";
import { getCustomerDefaultTerritoriesThunk, getUserCRMValuesThunk } from "../redux/thunks/AccountThunks";
import { IDraftWorkSearchQuery } from "./types/IDraftWorkSearchQuery";
import { IRegisteredWorkSearchQuery } from "./types/IRegisteredWorkSearchQuery";
import { IDraftWorkSearchResult } from "./types/IDraftWorkSearchResult";
import { IAllRegisteredWorkSearchQuery } from "./types/IAllRegisteredWorkSearchQuery";
import { ILookupDictionary } from "../lookup/types/ILookupDictionary";
import { IDraftSetListSearchQuery } from "./types/IDraftSetListSearchQuery";
import { IDraftSetListSearchResult } from "./types/IDraftSetListSearchResult";
import { IIPsSearchQuery } from "../repertoire/types/IIPsSearchQuery";
import { updateDraftAgreementField } from "../redux/reducers/MembersPortalReducer";
import { IWorksSearchQuery } from "../repertoire/types/IWorksSearchQuery";
import { IAgreementStateKeys } from "../redux/types/IAgreementState";

export default connect(
    (state: IAppState, props?: RouteChildrenProps) => ({
        pathInfo: props.match.params,
        displayModal: state.membersPortal.isSearching ? true : state.modal.displayModal,
        modalContent: state.membersPortal.isSearching && !state.membersPortal.isModalSearch ? LOADING_VIEW : state.modal.modalContent,
        modalProps: state.modal.modalProps,
        modalIndex: state.modal.index,
        modalKey:state.modal.key,
        agreement: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState.agreement,
        modalComponentInstance: state.modal.modalComponentInstance,
        modalTitle: state.modal.title,
        displayModalCloseButton: state.modal.displayModalCloseButton,
        tabs: state.membersPortal.tabs,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        dataSource: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState ? state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState.dataSource : undefined,
        loadingViewData: state.membersPortal.componentData.LoadingView,
        yesNoPromptViewData: state.membersPortal.componentData.YesNoPromptView,
        tabsViewData: state.membersPortal.componentData.TabsView,
        activeTab: state.membersPortal.activeTab,
        closeTabDisabled: state.membersPortal.saveInProgress,
        artistsSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].artistsSearchResult,
        contributorsSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].contributorsSearchResult,
        worksSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].worksSearchResult,
        membersPortalSideMenuDataView: state.membersPortal.componentData.MembersPortalSideMenuDataView,
        repertoirePageDataView: state.membersPortal.componentData.MembersPortalPageDataView,
        searchSuccessful: state.membersPortal.searchSuccessful,
        currentUser: state.account.currentUser,
        editableFields: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].editableFields,
        expandAll: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandAll,
        modalMessage: state.modal.modalMessage,
        enabledModules: state.home.enableModules,
        indexOfFirstResult: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].indexOfFirstResult ? state.membersPortal.tabs[state.membersPortal.activeTab].indexOfFirstResult : undefined,
        indexOfLastResult: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].indexOfLastResult ? state.membersPortal.tabs[state.membersPortal.activeTab].indexOfLastResult : undefined,
        resultsPerPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].resultsPerPage ? state.membersPortal.tabs[state.membersPortal.activeTab].resultsPerPage : undefined,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        component: state.membersPortal.tabs && state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].component,
        lookupValues: state.lookupEntities,
        draftSetListSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListSearchResult,
        dataGridTableData: state.membersPortal.componentData.DataGridTable,
        isReadOnlyModal: state.modal.isReadOnlyModal,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        droppedFiles: state.dataIngestion.droppedFiles,
        currentUserNameNumber: state.account.currentUserNameNumber,
        draftWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkSearchResult,
        registeredWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].registeredWorkSearchResult,
        allRegisteredWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].allRegisteredWorkSearchResult,
        draftAgreementWorksSearchResult: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftAgreementWorksSearchResult,
        draftAgreementIPSearchResult: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftAgreementIPSearchResult,
        isDropZone: state.dataIngestion.droppedFiles.inDropZone,
        defaultTerritories:state.account.defaultTerritories
    }),
    (dispatch: Dispatch) => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string,
            modalMessage?: string,
            isReadOnly?: boolean,
            key?: string,
            index?: number

        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title,modalMessage,isReadOnly,key,index)
            ),
        hideModal: () => dispatch<any>(clearModalSearchResultsThunk()),
        updateModalComment: (value: any) => dispatch(updateComment(value)),
        getMembersPortalFields: () => dispatch<any>(getMembersPortalComponentDataThunk()),
        removeTab: (index: number) => dispatch(mp_removeTab(index)),
        openTab: (index: number) => dispatch(mp_openTab(index)),
        loadMembersPortalFromPath: (pathInfo: IMembersPortalPathInfo, currentUserNameNumber: string, location: Location, tabs?: ITabReduxItem[], lookups?: ILookupDictionary) => dispatch<any>(loadMembersPortalFromPathThunk(pathInfo, currentUserNameNumber, location, tabs, lookups)),
        setCurrentPage: (link: string) => dispatch(setCurrentPage(link)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        updateEditableFields: (value: any[]) =>
            dispatch(updateEditableFields(value)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        searchDataChanged: (field: string, value: any) => dispatch(searchDataChanged(field, value)),
        getSources: () => dispatch<any>(getMatchingSourcesThunk()),
        updateSelectionCriteria: (distributionPoolId: number, value: any) => dispatch<any>(updateSelectionCriteria(distributionPoolId, value)),
        displayRepresentation: (display: boolean) => dispatch(displayRepresentationFlag(display)),
        setInDropZone: (isDropZone: boolean) => dispatch<any>(setInDropZone(isDropZone)),
        addFileToUpload: (file: IFile, inProgress: boolean) => dispatch<any>(addFileToUpload(file, inProgress)),
        setProgressUploadFileProccess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        getLookupEntities: () => dispatch<any>(getLookupEntitiesThunk()),
        updateWorkContributorIP: (dataSource: DataSource, workContributor: IContributorItem, ip: IP) => dispatch<any>(updateWorkContributorIPThunk(dataSource, workContributor, ip)),
        searchContributors: (query: IContributorSearchQuery) => dispatch<any>(searchContributorsThunk(query)),
        searchArtists: (query: IArtistSearchQuery) => dispatch<any>(searchArtistsThunk(query, 'Repertoire', true)),
        addArtistToWork: (artist: IArtist, id: number) => dispatch(addArtistToWork(artist, id)),
        getUserCRMValuesThunk: () => dispatch<any>(getUserCRMValuesThunk()),
        searchDraftWorks: (query: IDraftWorkSearchQuery) =>
            dispatch<any>(searchDraftWorksThunk(query, true)),
        searchRegisteredWorks: (query: IRegisteredWorkSearchQuery) =>
            dispatch<any>(searchRegisteredWorksThunk(query)),
        searchAllRegisteredWorks: (query: IAllRegisteredWorkSearchQuery) =>
            dispatch<any>(searchAllRegisteredWorksThunk(query, true)),
        searchDraftSetLists: (query: IDraftSetListSearchQuery) =>
            dispatch<any>(searchDraftSetListsThunk(query)),
        addWorkToSetList: (work: IDraftWorkSearchResult) => dispatch(addWorkToSetList(work)),
        addSetListToLivePerformance: (setList: IDraftSetListSearchResult) => dispatch(addSetListToLivePerformance(setList)),
        getDistributions: () => dispatch<any>(getDistributionsThunk()),
        saveContributorComment: (contributorID: number, comments: string) => dispatch<any>(saveContributorCommentThunk(contributorID,comments)),
        searchIPs: (query: IIPsSearchQuery, keepModalOpenAfterSearch?: boolean) => dispatch<any>(searchDraftAgreementsIPThunk(query, keepModalOpenAfterSearch)),
        updateDraftAgreementField: (field: string, value: any) => dispatch(updateDraftAgreementField(field, value)),
        searchWorks: (query: IWorksSearchQuery, keepModalOpenAfterSearch?: boolean) => dispatch<any>(searchDraftAgreementsWorksThunk(query, keepModalOpenAfterSearch)),
        updateDraftAgreementWorkField: (value: any, fieldName: IAgreementStateKeys, index?: number) =>
            dispatch(updateDraftAgreementWorkField(value, fieldName, index)),
        getCustomerDefaultTerritories:() => dispatch<any>(getCustomerDefaultTerritoriesThunk()),
    })
)(MembersPortalPage);
