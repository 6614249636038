import React from "react";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { showModal } from "../../../redux/reducers/MembersPortalReducer";
import { hideModal, showUpdateFieldsModal } from "../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, matchProductBatchRequest, postProductBatchRequest, setEditableFields, updateProductField, updateProductFields } from "../../../redux/reducers/RepertoireReducer";
import { IProductState } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { DataSource } from "../../types/DataSource";
import { IRepertoireField } from "../../types/IRepertoireField";
import { IWorkSourceIP } from "../../types/IWorkSourceIP";
import ProductMaintenanceGeneralDataViewFormik from "./ProductMaintenanceGeneralDataViewFormik";

export interface IProductMaintenanceGeneralDataViewProps {
    setIsChangeGeneralForm: () => void;
    productDataFormikRef: any;
    productMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    updateProductField: typeof updateProductField;
    updateProductFields: typeof updateProductFields;
    lookupValues: ILookupDictionary;
    dataSource: DataSource;
    changesMade: boolean;
    saveInProgress: boolean;
    productType: string;
    id1: string;
    id2: string;
    duration: number;
    released: Date;
    trackCount: number;
    source: string;
    maintained: boolean;
    production: number;
    licenseeID: number;
    lastModifiedDate: Date;
    productBatchID: number;
    postProductBatch: typeof postProductBatchRequest;
    matchProductBatch: typeof matchProductBatchRequest;
    productBatchOperationInProgress: boolean;
    productSourceIP?: IWorkSourceIP;
    isReadonly: boolean;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    hideModal?: typeof hideModal;
    roles: string[];
    dateCreated?: Date;
    sourceIP?: number;
    status?: string;
    sourceType?: string;
    clearModalSearchResults?: typeof clearModalSearchResults;
    showModal?: typeof showModal;
    product: IProductState;
    saveChanges: () => void;
}

const ProductMaintenanceWrapperFormik = ({
    setIsChangeGeneralForm,
    productDataFormikRef,
    productMaintenanceGeneralDataViewData,
    updateProductField,
    updateProductFields,
    lookupValues,
    dataSource,
    changesMade,
    saveInProgress,
    productType,
    id1,
    id2,
    duration,
    released,
    trackCount,
    source,
    maintained,
    production,
    licenseeID,
    lastModifiedDate,
    productBatchID,
    postProductBatch,
    matchProductBatch,
    productBatchOperationInProgress,
    isReadonly,
    tabs,
    activeTab,
    sourceIP,
    sourceType,
    status,
    productSourceIP,
    product,
    editableFields,
    updateComponentFieldsItems,
    showUpdateFieldsModal,
    editableFieldsDataView,
    dataGridTableEditableFieldsData,
    setEditableFields,
    roles,
    hideModal,
    showModal,
    saveChanges
}) => {

    
    const [productMaintenanceData, setProductMaintenanceData] = React.useState<IProductMaintenanceGeneralDataViewProps | null>(null);
    const [initialLoad, setInitialLoad] = React.useState(true);

    React.useEffect(() => {
      if (initialLoad && productMaintenanceGeneralDataViewData) {
        const initialState = {
            setIsChangeGeneralForm: setIsChangeGeneralForm,
            productDataFormikRef: productDataFormikRef,
            productMaintenanceGeneralDataViewData: productMaintenanceGeneralDataViewData,
            updateProductField: updateProductField,
            updateProductFields: updateProductFields,
            lookupValues: lookupValues,
            dataSource: dataSource,
            changesMade: changesMade,
            saveInProgress: saveInProgress,
            productType: productType,
            id1: id1,
            id2: id2,
            duration: duration,
            released: released,
            trackCount: trackCount,
            source: source,
            maintained: maintained,
            production: production,
            licenseeID: licenseeID,
            lastModifiedDate: lastModifiedDate,
            productBatchID: productBatchID,
            postProductBatch: postProductBatch,
            matchProductBatch: matchProductBatch,
            productBatchOperationInProgress: productBatchOperationInProgress,
            isReadonly: isReadonly,
            tabs: tabs,
            activeTab: activeTab,
            sourceIP: sourceIP,
            sourceType: sourceType,
            status: status,
            productSourceIP: productSourceIP,
            product: product,
            editableFields: editableFields,
            updateComponentFieldsItems: updateComponentFieldsItems,
            showUpdateFieldsModal: showUpdateFieldsModal,
            editableFieldsDataView: editableFieldsDataView,
            dataGridTableEditableFieldsData: dataGridTableEditableFieldsData,
            setEditableFields: setEditableFields,
            roles: roles,
            hideModal: hideModal,
            showModal: showModal,
            saveChanges: saveChanges,
        }
        setProductMaintenanceData(initialState);

        setInitialLoad(false);
      }
    }, [initialLoad]);

    return productMaintenanceData && productMaintenanceData.productMaintenanceGeneralDataViewData ? <ProductMaintenanceGeneralDataViewFormik
                        // props that will updated the state
                        editableFields={editableFields}
                        productDataFormikRef={productDataFormikRef}
                        productBatchID={productBatchID}
                        productBatchOperationInProgress={productBatchOperationInProgress}
                        productSourceIP={productSourceIP}
                        sourceIP={sourceIP}

                        // props that don't update state after first load
                        setIsChangeGeneralForm={productMaintenanceData.setIsChangeGeneralForm}
                        productMaintenanceGeneralDataViewData={productMaintenanceData.productMaintenanceGeneralDataViewData}
                        saveChanges={productMaintenanceData.saveChanges}
                        updateProductField={productMaintenanceData.updateProductField}
                        updateProductFields={productMaintenanceData.updateProductFields}
                        lookupValues={productMaintenanceData.lookupValues}
                        dataSource={productMaintenanceData.dataSource}
                        changesMade={productMaintenanceData.changesMade}
                        saveInProgress={productMaintenanceData.saveInProgress}
                        productType={productMaintenanceData.productType}
                        id1={productMaintenanceData.id1}
                        id2={productMaintenanceData.id2}
                        duration={productMaintenanceData.duration}
                        released={productMaintenanceData.released}
                        trackCount={productMaintenanceData.trackCount}
                        source={productMaintenanceData.source}
                        maintained={productMaintenanceData.maintained}
                        production={productMaintenanceData.production}
                        licenseeID={productMaintenanceData.licenseeID}
                        lastModifiedDate={productMaintenanceData.lastModifiedDate}
                        postProductBatch={productMaintenanceData.postProductBatch}
                        matchProductBatch={productMaintenanceData.matchProductBatch}
                        isReadonly={productMaintenanceData.isReadonly}
                        updateComponentFieldsItems={productMaintenanceData.updateComponentFieldsItems}
                        showUpdateFieldsModal={productMaintenanceData.showUpdateFieldsModal}
                        editableFieldsDataView={productMaintenanceData.editableFieldsDataView}
                        dataGridTableEditableFieldsData={productMaintenanceData.dataGridTableEditableFieldsData}
                        setEditableFields={productMaintenanceData.setEditableFields}
                        activeTab={productMaintenanceData.activeTab}
                        tabs={productMaintenanceData.tabs}
                        hideModal={productMaintenanceData.hideModal}
                        roles={productMaintenanceData.roles}
                        sourceType={productMaintenanceData.sourceType}
                        status={productMaintenanceData.status}
                        showModal={productMaintenanceData.showModal}
                        product={productMaintenanceData.product}
    /> : <>Loading...</>

}

export default ProductMaintenanceWrapperFormik;