import throttle from "lodash.throttle";
import * as React from "react";
import { If } from "../../../../core/components/If";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import {
    hideModal,
    showModal,
    showYesNoPrompt
} from "../../../../redux/reducers/ModalReducer";
import {
    addDroppedFile,
    cleanDroppedFilesRepertoireState,
    setEditableFields, setProgressUploadFileRepertoireProccess
} from "../../../../redux/reducers/MembersPortalReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    ADD_NEW_ACTION,
    DRAFT_WORKS_ARTISTS_STATE_KEY,
    DRAFT_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY,
    EMPTY_STRING_VALUE,
} from "../../../Consts";
import DraftWorksMaintenanceToolbar from "../draftWorksMaintenanceToolbar/DraftWorksMaintenanceToolbar";
import DraftWorksMaintenanceGeneralDataView from "../draftWorksMaintenanceGeneralDataView/DraftWorksMaintenaceGeneralDataView";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { WorkDetailsMapper } from "../../../../repertoire/services/WorkDetailsMapper";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { Step } from "../../../components/multiStepForm/MultiStepForm";
import { IContributorItem } from "../../../../repertoire/types/IContributorItem";
import { Dictionary } from "../../../../core/types/Dictionary";
import { CONTRIBUTORS_KEY, DATE_INPUT, DROPDOWN_INPUT, INTEGER_INPUT, INTEGER_PERCENTAGE_INPUT, READ_ONLY_INPUT, TEXT_INPUT, WORKS_PAGE_VIEW } from "../../../../repertoire/Consts";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ITreeData } from "../../../../repertoire/types/ITreeData";
import IFile from "../../../../redux/types/IFiles";
import { ContainerDetailsWindow } from "../../../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindow";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IDistribution } from "../../../../repertoire/types/usageTypes/IDistribution";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import { CleanUploadFilesState, addFileToUpload, fileMetadataChanged, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { IPaginationDetail } from "../../../../redux/types/IPaginationDetail";
import { IAddWorkAttachmentModel } from "../../../../redux/types/IAddAttchmentWorkModel";
import { IRemoveWorkBlobAttachment } from "../../../../repertoire/types/IRemoveWorkBlobAttachment";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { MembersPortalService } from "../../../../redux/services/MembersPortalService";
import { MEMBERS_PORTAL } from "../../../../accounts/Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { SearchRequests } from "../../../../repertoire/services/SearchRequests";
import { IContributorSearchQuery } from "../../../../repertoire/types/IContributorSearchQuery";
import { getLookupDefault } from "../../../../lookup/services/LookupHelpers";
import { SET_TYPE_GROUP_LOOKUP, SET_TYPE_LOOKUP, TERRITORY_LOOKUP, WORK_CONTRIBUTOR_TYPE_LOOKUP, WORK_SOURCE_LOOKUP, WORK_CONTRIBUTOR_SUB_TYPE_LOOKUP, SOCIETY_OF_LICENSE_LOOKUP } from "../../../../lookup/Consts";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../../../ConfigurationConsts";
import DraftWorksMaintenanceForm from "../draftWorksMaintenanceForm/DraftWorksMaintenanceForm";
import DataGridTable from "../../../../repertoire/components/dataGridTable/DataGridTable";
import { clearModalSearchResults } from "../../../../redux/reducers/RepertoireReducer";

export interface IDraftWorksMaintenancePageProps {
    draftWorkMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    draftWorkMaintenanceToolbarData?: IMembersPortalComponentDataItem;
    draftWorkMaintenanceSaveResultData?: IMembersPortalComponentDataItem;
    draftWorkMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    work?: IWorkState;
    activeTab: number;
    tabs: ITabReduxItem[];
    dataSource: DataSource;
    lookupValues?: ILookupDictionary;
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    resetMessageBanner: () => void;
    addNewDraftWork: (lookupValues: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    updateDraftWorkField?: (value: any, fieldName: IWorkStateKeys, index?: number) => void;
    saveChanges: (work: IWorkState, draftWorksMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, lookupValues: ILookupDictionary) => void;
    undoDraftWorks?: (dataSource: string, draftWorksID: number, lookupValues: ILookupDictionary) => void;
    deleteDraftWork: (dataSource: string, draftWorksID: number, activeTab: number) => void;
    getDraftWorkDetails: (dataSource: string, draftWorksID: number, lookupValues: ILookupDictionary, draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem) => void;
    showModal: typeof showModal;
    clearModalSearchResults: () => void;
    copyDraftWork: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => void;
    incrementTempID?: () => void;
    containerDetailsWindowComponentData: IComponentDataItem;
    distributions: IDistribution[];
    formats: ILookupInstance[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    attachedFiles: IStoragePathItemData[];
    removeWorkAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => void;
    workAttachmentsDropped?: IDroppedFiles;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire: typeof setProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire: typeof cleanDroppedFilesRepertoireState;
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    sortColumn: typeof sortColumn;
    getDataIngestionComponentData?: () => void;
    getFilesystems?: (isRepertoireModule?: boolean) => void;
    refreshWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], activeTabItem: ITabReduxItem, readonlyIndicatorsWorkFlagTypes: string[], formats?: FormatFields[]) => void;
    stepsCurrent?: number;
    stepsSetCurrent?: (current: number) => void;
    currentUser: string;
    portalType: string;
    currentUserNameNumber: string;
    getUserCRMIPNumber: (guid: string) => void;
    shareDecimalsToDisplay?: number;
    updatedScroll?: number;
}


interface IDraftWorksMaintenancePageState {
    toolbarWidth: number;
    isBulkCheckboxActive?: boolean;
    contributorsLocal?: IContributorItem[];
}

export class DraftWorksMaintenancePage extends React.PureComponent<
    IDraftWorksMaintenancePageProps,
    IDraftWorksMaintenancePageState
> {
    toolbarParentRef;
    constructor(props: IDraftWorksMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            isBulkCheckboxActive: false,
            contributorsLocal: [],
        };

        this.toolbarParentRef = React.createRef();
    }

    componentDidMount() {
        const {
            getFilesystems,
            getDataIngestionComponentData,
            containerDetailsWindowComponentData,
            cleanUploadFilesState, 
            currentUserNameNumber,
            getUserCRMIPNumber,
         } = this.props;

         const {
            contributors
        } = this.props.work;
        if (!containerDetailsWindowComponentData || !containerDetailsWindowComponentData.fields || containerDetailsWindowComponentData.fields.length === 0) {
            getDataIngestionComponentData();
        }
        getFilesystems(true);
        cleanUploadFilesState();

        if (!currentUserNameNumber) {
            getUserCRMIPNumber(sessionStorage.getItem('currentGuid'));
        }

        let isNewDraftWork: boolean = this.props.work ? this.props.work.workID && this.props.work.workID > 0 ? false : true : true;
        if (isNewDraftWork) {
            this.addCurrentUserToContributors();
        }

        const newContributors = this.calculateContributorRelationships(contributors['repertoire']);
        this.setState({ contributorsLocal: newContributors });
    }

    componentDidUpdate(prevProps) {
        if (this.props.work?.contributors && prevProps.work?.contributors && this.props.work.contributors !== prevProps.work.contributors) {
          const newContributors = this.calculateContributorRelationships(this.props.work.contributors['repertoire']);
          this.setState({ contributorsLocal: newContributors });
        }
      }



    confirmAndSubmit = () => {
        const { saveChanges, work, draftWorkMaintenanceGeneralDataViewData, lookupValues } = this.props;
        let draftWork: IWorkState = JSON.parse(JSON.stringify(work));
        draftWork.draftSubmitted = true
        saveChanges(draftWork, draftWorkMaintenanceGeneralDataViewData, lookupValues);
        this.setState({ isBulkCheckboxActive: false });
        return draftWork.draftSubmitted
    }

    saveAsDraftWork = () => {
        const { saveChanges, work, draftWorkMaintenanceGeneralDataViewData, lookupValues } = this.props;
        let draftWork: IWorkState = JSON.parse(JSON.stringify(work));
        draftWork.draftSubmitted = false;
        saveChanges(draftWork, draftWorkMaintenanceGeneralDataViewData, lookupValues);
        this.setState({ isBulkCheckboxActive: false });
    }

    deleteDraftWork() {
        const { dataSource, activeTab, work } = this.props;
        this.props.deleteDraftWork(dataSource, work.workID, activeTab);
    }

    addNewDraftWorks() {
        const { addNewDraftWork, lookupValues, draftWorkMaintenanceGeneralDataViewData, currentUserNameNumber } = this.props;
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                addNewDraftWork(lookupValues, currentUserNameNumber, true, draftWorkMaintenanceGeneralDataViewData, formats)
            });
    }

    undoChanges = () => {
        const { dataSource, work, undoDraftWorks, lookupValues } = this.props;
        undoDraftWorks(dataSource, work.workID, lookupValues);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    changeBulkCheckbox = (value: boolean) => {
        this.setState({ isBulkCheckboxActive: value });
    }

    refreshWorkDetails() {
        const { tabs, activeTab, lookupValues, dataSource } = this.props;
        let currentTab = tabs[activeTab];
        this.props.refreshWorkDetails(
            dataSource,
            currentTab.draftWorkMaintenanceState.work.workID,
            lookupValues,
            tabs[activeTab].otherIndicatorWorkFlagTypes,
            currentTab,
            tabs[activeTab].readonlyIndicatorWorkFlagTypes
        )
    }

    removeAttachmentFromMaintenanceScreen(fullName: string, workId: number, dataSource: DataSource, guid: string, index: number) {
        const attachedFile: IAttachedFile = {
            fileName: fullName,
            workId: workId
        }
        const model: IRemoveWorkBlobAttachment = {
            Guid: guid,
            Source: dataSource,
            workID: workId
        }

        MembersPortalService.removeWorkBlobAttachment(model)
            .then(res => {
                if (res) {
                    MembersPortalService.removeWorkAttachment({ attachedFile: attachedFile, dataSource: dataSource })
                        .then((res: boolean) => {
                            if (res) {
                                this.refreshWorkDetails();
                            }
                        })
                }
            })
    }


    uploadWorkAttachment(fileName: string, workId: number, dataSource: DataSource) {
        const model: IAddWorkAttachmentModel = {
            attachedFile: {
                fileName: fileName,
                workId: workId
            },
            dataSource: dataSource
        }
        return Promise.resolve(
            MembersPortalService.addAttachmentRepertoire(model)
                .then((res: IAttachedFile) => {
                    return res;
                })
                .catch((err: any) => {
                    const newFile: IFile = {
                        isInProgress: true,
                        message: err,
                        name: fileName,
                        size: 0
                    }
                    setProgressUploadFileProccess(err, newFile, true);
                    return false;
                })
        )
    }


    updateContributorRelationships(value: string, contributors: IContributorItem[], rowIndex: number): void {
        const contributor = contributors[rowIndex];
        contributor.relation.value = value;
        contributor.relationships = value;
        contributor.relationshipIDs = [];

        const str = !!value ? value.trim() : '';
        // if the string is empty, remove all relationships from this contributor to others 
        // and from other contributors to this one
        if (str == '') {
            contributor.relationshipIDs = [];
            contributor.isEditingRelationships = false;

            for (let i = 0; i < contributors.length; i++) {
                if (contributors[i].relationshipIDs) {
                    const k = contributors[i].relationshipIDs.indexOf(contributor.workContributorID); // if contributor has relationship
                    if (k > -1) {
                        contributors[i].relationshipIDs.splice(k, 1); // remove the relationship
                    }
                }
            }
        }
        else if (str.endsWith(';')) {
            contributor.isEditingRelationships = false;
            const relsTo = str.split(';').filter(r => r.startsWith('T'));

            // add relationships to other contributors
            relsTo.map(r => {
                let index: number = parseInt(r.trim().substring(1))
                if (!isNaN(index)) {
                    const contributorIndex: number = index - 1;
                    if (
                        contributorIndex >= 0
                        && contributorIndex <= contributors.length - 1 // index is within array bounds
                        && contributorIndex !== rowIndex) { // does not refer to the contributor getting edited

                        const id: number = contributors[contributorIndex].workContributorID;
                        if (!contributor.relationshipIDs.find(x => x === id)) {
                            contributor.relationshipIDs.push(id);
                        }
                    }
                }
            });

            // get all relationships from other contributors to this one.
            // remove them if they don't have a corresponding F relationship here.
            const relsFrom = str.split(';').filter(r => r.startsWith('F')); // remove rel from if it has been deleted
            const indexesOfContributorsWithRelations: number[] = [];
            relsFrom.map(r => {
                let index: number = parseInt(r.trim().substring(1))
                if (!isNaN(index)) {
                    const contributorIndex: number = index - 1;
                    if (
                        contributorIndex >= 0
                        && contributorIndex <= contributors.length - 1 // index is within array bounds
                        && contributorIndex !== rowIndex) { // does not refer to the contributor getting edited
                        indexesOfContributorsWithRelations.push(contributorIndex);
                    }
                }
            });
            for (let i = 0; i < contributors.length; i++) {
                if (contributors[i].relationshipIDs) {
                    let k = contributors[i].relationshipIDs.indexOf(contributor.workContributorID); // if contributor has relationship
                    if (k === -1) {
                        k = contributors[i].relationshipIDs.indexOf(-contributor.workContributorID);
                    }
                    if (k > -1 && !str.includes('F' + (i + 1) + ';')) {
                        contributors[i].relationshipIDs.splice(k, 1); // remove the relationship
                    }
                }

            }
        }
        else {
            contributor.isEditingRelationships = true; // suppress recalculating while the user is enter text. Wait for ';'
        }


        const tableContentsCopy: Dictionary<IContributorItem[]> = { 'repertoire': contributors };
        this.calculateContributorRelationships(tableContentsCopy['repertoire'])
        this.props.updateDraftWorkField(tableContentsCopy, CONTRIBUTORS_KEY);

    }

    addCurrentUserToContributors(): void {
        const { work, tabs, activeTab, lookupValues, incrementTempID, updateDraftWorkField, currentUserNameNumber, getUserCRMIPNumber } = this.props;

        let nextTempID: number = tabs[activeTab].nextTempID;
        
        if (!currentUserNameNumber) {
            getUserCRMIPNumber(sessionStorage.getItem('currentGuid'));
        }

        const currentUserIP = this.props.currentUserNameNumber;

        const searchQuery: IContributorSearchQuery = {
            ipNumber: currentUserIP,
            fullName: '',
            accountNumber: '',
            legalEntityType: '',
            isMembersPortal: undefined
        };

        SearchRequests.getContributors(searchQuery).then(res => {
            const searchResult: any = res[0];

            let currentUser: IContributorItem = {
                workContributorID: nextTempID,
                ipBaseNumber: searchResult.ipBaseNumber,
                accountNumber: { value: searchResult.accountNumber, inputType: READ_ONLY_INPUT },
                ipiNumber:
                {
                    value: searchResult.ipiNumber.toString(),
                    inputType: READ_ONLY_INPUT
                },
                mechPercentage:
                {
                    value: 0,
                    inputType: INTEGER_PERCENTAGE_INPUT
                },
                name:
                {
                    value: searchResult.ipName,
                    inputType: READ_ONLY_INPUT
                },
                percentageOfPub:
                {
                    value: "",
                    inputType: TEXT_INPUT
                },
                perfPercentage:
                {
                    value: 0,
                    inputType: INTEGER_PERCENTAGE_INPUT
                },
                regDate:
                {
                    value: null,
                    inputType: DATE_INPUT
                },
                societyOfLicense: {
                    value: searchResult.societyOfLicense ?? getLookupDefault(SOCIETY_OF_LICENSE_LOOKUP, lookupValues),
                    inputType: DROPDOWN_INPUT
                },
                relation:
                {
                    value: "",
                    inputType: TEXT_INPUT
                },
                reference:
                 {
                     value: "",
                     inputType: TEXT_INPUT
                 },
                setType: getLookupDefault(SET_TYPE_LOOKUP, lookupValues),
                setTypeGroup: getLookupDefault(SET_TYPE_GROUP_LOOKUP, lookupValues),
                source:
                {
                    value: getLookupDefault(WORK_SOURCE_LOOKUP, lookupValues),
                    inputType: DROPDOWN_INPUT
                },
                territory:
                {
                    value: getLookupDefault(TERRITORY_LOOKUP, lookupValues),
                    inputType: DROPDOWN_INPUT
                },
                role:
                {
                    value: getLookupDefault(WORK_CONTRIBUTOR_TYPE_LOOKUP, lookupValues),
                    inputType: DROPDOWN_INPUT
                },
                subType:
                {
                    value: getLookupDefault(WORK_CONTRIBUTOR_SUB_TYPE_LOOKUP, lookupValues),
                    inputType: DROPDOWN_INPUT
                },
                senderRep:
                {
                    value: null,
                    inputType: DROPDOWN_INPUT
                },
                agreementChain: [],
                sourceIP: null,
                sourceType: "",
                sourceSociety: "",
                mechSocietyAffiliation: EMPTY_STRING_VALUE,
                perfSocietyAffiliation: EMPTY_STRING_VALUE,
                performingShareToDisplay: {
                    value: '0',
                    inputType: INTEGER_PERCENTAGE_INPUT
                },
                mechanicalShareToDisplay: {
                    value: '0',
                    inputType: INTEGER_PERCENTAGE_INPUT
                },
                copyLevyPercentage: {
                    value: 0,
                    inputType: INTEGER_INPUT
                },
                copyLicencePercentage: {
                    value: 0,
                    inputType: INTEGER_INPUT
                },
                comments: EMPTY_STRING_VALUE
            };

            let tableContentsCopy: Dictionary<IContributorItem[]>
            if(Object.keys(work.contributors).length == 0){
                let contributors: IContributorItem[] = [currentUser];
                tableContentsCopy = { 'repertoire': contributors };
            } else { 
                tableContentsCopy = work.contributors

                var index = tableContentsCopy['repertoire'].findIndex(x => x.accountNumber?.value == currentUser.accountNumber?.value)
                if (index !== -1)
                    tableContentsCopy['repertoire'].push(currentUser)
            }

            updateDraftWorkField(tableContentsCopy, CONTRIBUTORS_KEY);
            incrementTempID()
        });

    }

    calculateContributorRelationships = (contributors) => {
        if (contributors) {
            contributors.map(c => {
                if (!c.isEditingRelationships) { // suppress recalculating while the user is enter text. Wait for ';'
                    let relationships: string = '';
                    if (c.relationshipIDs) {
                        c.relationshipIDs.map(r => {
                            const index: number = contributors.findIndex(c => Math.abs(c.workContributorID) === Math.abs(r));
                            if (index !== -1) {
                                relationships = relationships + 'T' + (index + 1) + ';'
                            }
                        });
                    }
                    c.relationships = relationships;
                    c.relation.value = relationships;
                }
            });
            contributors.map(c => {
                if (!c.isEditingRelationships) {
                    const otherContributors = contributors.filter(oc => Math.abs(oc.workContributorID) !== Math.abs(c.workContributorID));
                    otherContributors.map(oc => {
                        if (oc.relationshipIDs && oc.relationshipIDs.findIndex(x => Math.abs(x) == Math.abs(c.workContributorID)) > -1) {
                            c.relationships = c.relationships + 'F' + (contributors.findIndex(x => Math.abs(x.workContributorID) == Math.abs(oc.workContributorID)) + 1) + ';'
                            c.relation.value = c.relationships + 'F' + (contributors.findIndex(x => Math.abs(x.workContributorID) == Math.abs(oc.workContributorID)) + 1) + ';'
                        }
                    })
                }
            });
        }
        return contributors;
    }


    render() {
        if (this.props.work) {
            const {
                tabs,
                activeTab,
                work,
                draftWorkMaintenanceGeneralDataViewData,
                draftWorkMaintenanceToolbarData,
                draftWorkMaintenanceSaveResultData,
                draftWorkMaintenanceGridsViewData,
                hideModal,
                showYesNoPrompt,
                addNewDraftWork,
                updateDraftWorkField,
                dataGridTableData,
                showModal,
                copyDraftWork,
                incrementTempID,
                dataSource,
                setInDropZone,
                refreshDirectory,
                addFileToUpload,
                setProgressUploadFileProccess,
                cleanUploadFilesState,
                isDropZone,
                containerDetailsWindowComponentData,
                destinations,
                distributions,
                droppedFiles,
                selectedFileSystem,
                sortColumn,
                storagePathItemData,
                attachedFiles,
                addRepertoireAttachmentToState,
                removeWorkAttachmentIfExists,
                addDroppedFileRepertoire,
                workAttachmentsDropped,
                cleanDroppedFilesRepertoire,
                setProgressUploadFileRepertoire,
                paginationDetails,
                updatePagination,
                stepsCurrent,
                stepsSetCurrent,
                currentUser,
                currentUserNameNumber,
                portalType,
                shareDecimalsToDisplay,
            } = this.props;
            const {
                originalTitle,
                artists,
                contributors
            } = this.props.work;


            let isNewDraftWork: boolean = this.props.work?.workID < 1 ? true : false;
            let { lookupValues } = this.props;

            const title = WorkDetailsMapper.getOriginalTitleFromTitles(work.workID, work.titles)

            const changesMade = tabs[activeTab].changesMade || false;
            const isReadonly: boolean = tabs[activeTab].isReadonly;

            let readOnlyDataGridTableData = JSON.parse(JSON.stringify(dataGridTableData));
            readOnlyDataGridTableData.fields = readOnlyDataGridTableData.fields.filter(f => f.fieldType !== 'remove_action')

            const changeContributorData = (contributors: IContributorItem[]) => {
                const tableContentsCopy: Dictionary<IContributorItem[]> = { 'repertoire': contributors };
                Object.keys(tableContentsCopy).forEach(key => {
                    if (tableContentsCopy[key] && tableContentsCopy[key].length > 0) {
                        tableContentsCopy[key].forEach(x => {
                            if (x.mechanicalShareToDisplay != null && x.mechPercentage != null)
                                x.mechanicalShareToDisplay.value = x.mechPercentage.value.toString();
                            if (x.performingShareToDisplay != null && x.perfPercentage != null)
                                x.performingShareToDisplay.value = x.perfPercentage.value.toString();
                        });
                    }
                })
                updateDraftWorkField(tableContentsCopy, CONTRIBUTORS_KEY);
            }

            const changeData = (value: any, fieldName: string) => {
                updateDraftWorkField(value, fieldName as IWorkStateKeys);
            }

            let steps: Step[] =
                [
                    {
                        title: draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftWorkWorkProperties').data,
                        content: <DraftWorksMaintenanceGeneralDataView
                            draftWorkMaintenanceGeneralDataViewData={draftWorkMaintenanceGeneralDataViewData}
                            work={work}
                            isReadonly={isReadonly}
                            lookupValues={lookupValues}
                            updateDraftWorkField={updateDraftWorkField}
                            showModal={showModal}
                            hideModal={hideModal}
                            isNewDraftWork={isNewDraftWork}
                            activeTab={activeTab}
                            tabs={tabs}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            incrementTempID={incrementTempID}
                            editableFields={[]}
                            changesMade={changesMade}
                            setEditableFields={setEditableFields}
                            activeHeaderSection={tabs[activeTab].activeHeaderSection}
                            dataGridTableData={dataGridTableData}
                            draftWorkMaintenanceGridsViewData={draftWorkMaintenanceGridsViewData}
                        />
                    },
                    {
                        title: draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftWorkContributorDetails').data,//CONTRIBUTOR_DETAILS_STATE_KEY,
                        content:
                            <div className="GridsView">
                                <div className="AccordionContainer">
                                    <h3
                                        className={["headerSection", "activeSection", "isMandatory"].join(" ")}
                                        key={'contributors'}
                                    >
                                        {'Contributors'}
                                    </h3>
                                    <div className="contributorsDataView">
                                        <DataGridTable
                                            tableContents={contributors['repertoire']}
                                            changeData={changeContributorData}
                                            showModal={showModal}
                                            hideModal={hideModal}
                                            dataGridTableData={dataGridTableData}
                                            stateKey={DRAFT_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY}
                                            componentInstance={DRAFT_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY}
                                            showRowNumber={true}
                                            isReadOnly={false}
                                            incrementTempID={incrementTempID}
                                            tabs={tabs}
                                            activeTab={activeTab}
                                            tableActions={[{ name: ADD_NEW_ACTION }]}
                                            lookupValues={lookupValues}
                                            clearModalSearchResults={clearModalSearchResults}
                                            portalType={MEMBERS_PORTAL}
                                            updateContributorRelationships={this.updateContributorRelationships.bind(this)}
                                            shareDecimalsToDisplay={shareDecimalsToDisplay}
                                        />
                                    </div>
                                </div>
                            </div>
                    },
                    {
                        title: draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftWorkAttachments').data,
                        content: <div className={'topMarginRepertoireAttachment'}>
                            <h3
                                className={["headerSection", "activeSection"].join(" ")}
                                key={'artists'}
                            >
                                {'Attachments'}
                            </h3>
                            <ContainerDetailsWindow
                                addFileToUpload={addFileToUpload}
                                cancelChanges={undefined}
                                cleanUploadFilesState={cleanUploadFilesState}
                                containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                                currentDirectory={"work"}
                                destinations={destinations}
                                editingChanged={undefined}
                                fileMetadataChanged={fileMetadataChanged}
                                jobs={[]}
                                matchNow={undefined}
                                matchNowSubmit={undefined}
                                openDirectory={undefined}
                                pageContentIsActive={true}
                                distributions={distributions}
                                droppedFiles={droppedFiles}
                                isDropZone={isDropZone}
                                selectedFileSystem={selectedFileSystem}
                                setInDropZone={setInDropZone}
                                setProgressUploadFileProccess={setProgressUploadFileProccess}
                                sortColumn={sortColumn}
                                storagePathItemData={storagePathItemData}
                                storagePaths={attachedFiles}
                                refreshDirectory={refreshDirectory}
                                saveChanges={undefined}
                                saveState={undefined}
                                isRepertoireModule={true}
                                toggleSidebarCollapsed={undefined}
                                validationMessageChanged={undefined}
                                addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                                entityID={work.workID}
                                entitySource={work.dataSource}
                                uploadAttachment={this.uploadWorkAttachment.bind(this)}
                                removeAttachmentIfExists={removeWorkAttachmentIfExists}
                                addDroppedFileRepertoire={addDroppedFileRepertoire}
                                attachmentsDropped={workAttachmentsDropped}
                                cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                                setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                                component={WORKS_PAGE_VIEW}
                                removeAttachmentMaintenanceScreen={this.removeAttachmentFromMaintenanceScreen.bind(this)}
                                displayRemove={true}
                                paginationDetails={paginationDetails}
                                updatePagination={updatePagination}
                                lookupValues={lookupValues}
                                tabs={tabs}
                                activeTab={activeTab}
                                isProductAttachmentTypeNotRequired={true}
                                isProductAttachmentTypeOptional={false}
                                isWorkAttachmentTypeNotRequired={true}
                                isWorkAttachmentTypeOptional={false}
                            />
                        </div>
                    },
                    {
                        title: draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftWorkAdditionalDetails').data,
                        content: <div className="row">
                            <h3
                                className={["headerSection", "activeSection"].join(" ")}
                                key={'artists'}
                            >
                                {'Artists'}
                            </h3>
                            <DataGridTable
                                tableContents={artists}
                                changeData={changeData}
                                dataGridTableData={dataGridTableData}
                                stateKey={DRAFT_WORKS_ARTISTS_STATE_KEY}
                                componentInstance={DRAFT_WORKS_ARTISTS_STATE_KEY}
                                showRowNumber={false}
                                isReadOnly={false}
                                incrementTempID={incrementTempID}
                                tabs={tabs}
                                activeTab={activeTab}
                                tableActions={[{ name: ADD_NEW_ACTION }]}
                                lookupValues={lookupValues}
                                dataSource={DataSource.Intray}
                                clearModalSearchResults={clearModalSearchResults}
                                showModal={showModal}
                                hideModal={hideModal}
                            />
                        </div>
                    },
                    {
                        title: draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftWorksubmisssion').data,
                        content: <div>
                            <div className="">
                                <h3>General</h3>
                                <DraftWorksMaintenanceGeneralDataView
                                    draftWorkMaintenanceGeneralDataViewData={draftWorkMaintenanceGeneralDataViewData}
                                    work={work}
                                    isReadonly={(isReadonly ?? true)}
                                    lookupValues={lookupValues}
                                    updateDraftWorkField={updateDraftWorkField}
                                    showModal={showModal}
                                    hideModal={hideModal}
                                    isNewDraftWork={isNewDraftWork}
                                    activeTab={activeTab}
                                    tabs={tabs}
                                    resetMessageBanner={this.resetMessageBanner.bind(this)}
                                    incrementTempID={incrementTempID}
                                    editableFields={[]}
                                    changesMade={changesMade}
                                    setEditableFields={setEditableFields}
                                    activeHeaderSection={tabs[activeTab].activeHeaderSection}
                                    dataGridTableData={readOnlyDataGridTableData}
                                    draftWorkMaintenanceGridsViewData={draftWorkMaintenanceGridsViewData}
                                    shareDecimalsToDisplay={shareDecimalsToDisplay}
                                />
                            </div>
                            <div className="">
                                <h3
                                    className={["headerSection", "activeSection"].join(" ")}
                                    key={'contributors'}
                                >
                                    {'Contributor Details'}
                                </h3>
                                <DataGridTable
                                    tableContents={this.state.contributorsLocal && this.state.contributorsLocal ? this.state.contributorsLocal : []}
                                    changeData={changeContributorData}
                                    showModal={showModal}
                                    hideModal={hideModal}
                                    dataGridTableData={readOnlyDataGridTableData}
                                    stateKey={DRAFT_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY}
                                    componentInstance={DRAFT_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY}
                                    showRowNumber={true}
                                    isReadOnly={(isReadonly ?? true)}
                                    incrementTempID={incrementTempID}
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    lookupValues={lookupValues}
                                    clearModalSearchResults={clearModalSearchResults}
                                    updateContributorRelationships={this.updateContributorRelationships.bind(this)}
                                    shareDecimalsToDisplay={shareDecimalsToDisplay}
                                />
                            </div>
                            <div className="">
                                <h3
                                    className={["headerSection", "activeSection"].join(" ")}
                                    key={'artists'}
                                >
                                    {'Artist Details'}
                                </h3>
                                <DataGridTable
                                    tableContents={artists}
                                    changeData={changeData}
                                    dataGridTableData={readOnlyDataGridTableData}
                                    stateKey={DRAFT_WORKS_ARTISTS_STATE_KEY}
                                    componentInstance={DRAFT_WORKS_ARTISTS_STATE_KEY}
                                    showRowNumber={false}
                                    isReadOnly={(isReadonly ?? true)}
                                    incrementTempID={incrementTempID}
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    lookupValues={lookupValues}
                                />
                            </div>
                        </div>
                    }
                ];

            if (dataSource !== DataSource.Intray) {
                steps = steps.filter(s => s.title === 'Submission')
            }

            let zeroedStepsCurrent = stepsCurrent ?? 0;

            const { updatedScroll } = this.props;
            const {
                toolbarWidth
            } = this.state;

            return (
                <div className="">
                    <div><span className="title">{portalType === MEMBERS_PORTAL ? originalTitle : title}</span></div>
                    <If condition={tabs[activeTab].versionNumber != null}>
                        <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                    </If>

                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>

                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <DraftWorksMaintenanceToolbar
                            changesMade={changesMade}
                            showYesNoPrompt={showYesNoPrompt}
                            showModal={showModal}
                            hideModal={hideModal}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            toolbarData={draftWorkMaintenanceToolbarData}
                            saveResultData={draftWorkMaintenanceSaveResultData}
                            work={work}
                            activeTab={activeTab}
                            tabs={tabs}
                            dataSource={dataSource}
                            saveChanges={this.saveAsDraftWork.bind(this)}
                            isReadonly={isReadonly}
                            undoDraftWorksChanges={this.undoChanges.bind(this)}
                            addNewDraftWorks={() => SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                                                    .then(formats => {
                                                        addNewDraftWork(lookupValues, currentUserNameNumber, true, draftWorkMaintenanceGeneralDataViewData, formats)
                            })}
                            isNew={tabs[activeTab].draftWorkMaintenanceState?.isNew}
                            lookupValues={lookupValues}
                            copyDraftWork={copyDraftWork}
                            currentUser={currentUser}
                        />
                    </div>

                    <DraftWorksMaintenanceForm
                        steps={steps}
                        readonly={false}
                        saveChanges={this.saveAsDraftWork}
                        confirmAndSubmit={this.confirmAndSubmit}
                        dataSource={dataSource}
                        current={zeroedStepsCurrent}
                        setCurrent={stepsSetCurrent}
                        tabs={tabs}
                        activeTab={activeTab}
                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                    />
                </div>
            );
        }
        else
            return null;
    }
}
