import * as React from "react";
import { If } from "../../../core/components/If";
import { IComponentDataItem } from "../../../core/types/IComponentDataItem";
import { IDistribution } from "../../types/usageTypes/IDistribution";
import { IFileSystem } from "../../../dataingestion/types/IFileSystem";
import { IStoragePathItemData } from "../../../dataingestion/types/IStoragePathItemData";
import { ILookupInstance } from "../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../redux/reducers/DataIngestionReducer";
import { hideModal, showMessage, showModal, showUpdateFieldsModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import { addDroppedFile, addTab, cleanDroppedFilesRepertoireState, clearModalSearchResults, copyExistingProduct, matchProductBatchRequest, nextWorkflowInSession, postProductBatchRequest, setChangesMade, setEditableFields, setProgressUploadFileRepertoireProccess, sortProductFieldData, updateProductField, updateProductFields } from "../../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../../redux/types/FormatFields";
import { IAttachedFile } from "../../../redux/types/IAttachedFile";
import IDroppedFiles from "../../../redux/types/IDroppedFiles";
import { IProductState } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { ITreeNodeData } from "../../../treeview/types/TreeNodeData";
import { getDataAction } from "../../components/toolBar/ToolbarHelper";
import {
    CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY,
    PRODUCT_MAINTENANCE_GROUP,
    WORK_MAINTENANCE_GROUP
} from "../../ConfigurationConsts";
import {
    COMPONENT_INSTANCE_PRODUCTS,
     PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW,
      PRODUCT_MAINTENANCE_TOOLBAR,
       WORK_MAINTENANCE_TOOLBAR
} from "../../Consts";
import { ProductDetailsMapper } from "../../services/ProductDetailsMapper";
import { SearchRequests } from "../../services/SearchRequests";
import { DataSource } from "../../types/DataSource";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";
import { IProductAVRequest } from "../../types/IProductAVRequest";
import { IProductAVWorkSubmission } from "../../types/IProductAVWorkSubmission";
import { IProductMaintenanceSubmissionConfig } from "../../types/IProductMaintenanceSubmissionConfig";
import { IResultsPerPage } from "../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../redux/types/IActiveAccordion";
import { IRepertoireField } from "../../types/IRepertoireField";
import ProductMaintenanceGeneralDataView from "../productMaintenanceGeneralDataView/ProductMaintenanceGeneralDataView";
import ProductMaintenanceGridsView from "../productMaintenanceGridsView/ProductMaintenanceGridsView";
import ProductMaintenanceToolbar from "../productMaintenanceToolbar/ProductMaintenanceToolbar";
import { SaveOption } from "../../types/SaveOption";
import { ITreeData } from "../../types/ITreeData";
import { IPaginationDetail } from "../../../redux/types/IPaginationDetail";
import { IWorkflowSearchResult } from "../../types/IWorkflowSearchResult";
import { IWorkflowParams } from "../../types/IWorkFlowParams";
import { ManualMergeOptions } from "../../types/ManualMergeOptions";
import { productGeneralForAPI } from "../productMaintenanceGeneralDataView/ProductMaintenanceGeneralDataViewFormik";
import ProductMaintenanceWrapperFormik from "../productMaintenanceGeneralDataView/ProductMaintenanceWrapper";
import { formatProductRecordingDetailsForState } from "../productMaintenanceGridsView/ProductMaintenanceRecordingSection";


export interface IProductMaintenancePageProps {
    productMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    productMaintenanceGridsViewData?: IRepertoireComponentDataItem;
    productMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    productMaintenanceToolbarData?: IRepertoireComponentDataItem;
    productMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    productMaintenanceSearchResultData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    contributorsDataViewData?: IRepertoireComponentDataItem;
    avWorkDetailsAccordianViewData?: IRepertoireComponentDataItem;
    avSubmissionDetailsAccordianViewData?: IRepertoireComponentDataItem;
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    setChangesMade: typeof setChangesMade;
    addTab: typeof addTab;
    updateProductField: typeof updateProductField;
    updateProductFields: typeof updateProductFields;
    sortProductFieldData: typeof sortProductFieldData;
    saveChanges: (
        updatedProduct: IProductState,
        saveOption: SaveOption,
        lookups: ILookupDictionary,
        dataActions: IDataActionToolbar[],
        activeTab?: number,
        formats?: FormatFields[],
        workflowsSessionItems?: IWorkflowSearchResult[],
        currentWorkflowIndex?: number,
        isOpenForWorkflowSession?: boolean,
        paneIndex?: number,
    ) => void;
    undoChanges: (dataSource: string, productID: number, lookups: ILookupDictionary, paneIndex: number) => void;
    submitProductAVRequest: (productAVRequest: IProductAVRequest, productCoreID: number) => IProductAVWorkSubmission[];
    product: IProductState;
    isFetchingProduct: boolean;
    lookupValues: ILookupDictionary;
    activeAccordian: string[];
    openAccordian: (accordionName: string, componentName: string) => void;
    closeAccordian: (accordionName: string, componentName: string) => void;
    addNewProduct: (lookups: ILookupDictionary, isNew?: boolean, productMaintenanceGeneralViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], paneIndex?: number) => void;
    copyExistingProduct: typeof copyExistingProduct;
    deleteProduct: (dataSource: DataSource, productId: number, activeTab: number, paneIndex: number) => void;
    showMessage: typeof showMessage;
    activeTab: number;
    tabs: ITabReduxItem[];
    dataSource: DataSource;
    saveInProgress: boolean;
    resetMessageBanner: () => void;
    searchProductVersionHistory: (productCoreID: number, formats: FormatFields[]) => void;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string, ipMaintenanceGeneralDataView: IRepertoireComponentDataItem) => void;
    postProductBatch: typeof postProductBatchRequest;
    matchProductBatch: typeof matchProductBatchRequest;
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    ipMaintenanceGeneralDataView: IRepertoireComponentDataItem;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    getFieldFormats: (key: string, group: string) => void;
    formatFields: FormatFields[];
    workflowFieldsData: IRepertoireComponentDataItem;
    isWorkflowSessionStarted?: boolean;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[],
        readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems: IWorkflowSearchResult[];
    currentWorkflowIndex?: number
    isOpenForWorkflowSession?: boolean;
    skipUsageWorkflowItem: (activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    getProductDetails: (dataSource: string, productID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats: FormatFields[], isOpenForWorkflowSession?: boolean, dataAction?: IDataActionToolbar[]) => void;
    getProductMaintenanceSubmissionConfig: () => void;
    productMaintenanceSubmissionConfig?: IProductMaintenanceSubmissionConfig;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    containerDetailsWindowComponentData: IComponentDataItem;
    distributions: IDistribution[];
    formats: ILookupInstance[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    customFields: number[];
    getFilesystems: (isRepertoireModule?: boolean) => void;
    filesystems: IFileSystem[];
    treeNodes: ITreeNodeData<IFileSystem>[];
    selectedNode: ITreeNodeData<IFileSystem>;
    selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => void;
    getDataIngestionComponentData: () => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    attachedFiles: IStoragePathItemData[];
    removeProductAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => void;
    productAttachmentsDropped?: IDroppedFiles;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire: typeof setProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire: typeof cleanDroppedFilesRepertoireState;
    refreshProductDetails: (dataSource: string, productCoreID: number, activeTabItem: ITabReduxItem, editableFields: IRepertoireField[], formats: FormatFields[]) => void;
    editableFields: IRepertoireField[];
    currentUser?: string;
    sources: ITreeData[];
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    expandedProductResults?: number[];
    expandMatchingProduct?: (index: number) => void;
    roles: string[];
    incrementTempID?: () => void;
    manualMergeProduct: (product: IProductState, matchingProductId: number, manualMergeOption: ManualMergeOptions, lookups: ILookupDictionary, dataAction: IDataActionToolbar[], mergeIntoMatch: boolean, paneIndex?: number) => void;
    commentCategoryFlag?: boolean;
    useNewUIWorkMaintenanceGeneralView: boolean;
    useNewUIProductMaintenanceGeneralView: boolean;
    setValidationWarningMessage: (value: boolean) => void;
    updatedScroll?: number;
    cueSheetsEnabled?:boolean;
    cuesheetDatasource:any;
    addToPackages?:(userName:string,entityType:string,entityID:number, paneIndex: number)=>void;
    paneIndex: number;
    skipProductWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , dataActions: IDataActionToolbar[]
        , formats: FormatFields[]
        ) =>void;
}

interface IProductMaintenancePageState {
    toolbarWidth: number;
    mergeThisProduct: boolean;
    isCompleteWorkflowOnSave: boolean;
    isChangeGeneralForm: boolean;
    isChangeRecordingForm: boolean;
}

export class ProductMaintenancePage extends React.PureComponent<
    IProductMaintenancePageProps,
    IProductMaintenancePageState
> {
    toolbarParentRef;
    productDataFormikRef;
    productRecordingFormikRef;
    constructor(props: IProductMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            mergeThisProduct: false,
            isCompleteWorkflowOnSave: true,
            isChangeGeneralForm: false,
            isChangeRecordingForm: false
        };
        this.productDataFormikRef = React.createRef();
        this.productRecordingFormikRef = React.createRef();
        this.toolbarParentRef = React.createRef();
    }
    
    
    setIsChangeGeneralForm = (isChangeGeneralForm) => {
        this.setState({
            isChangeGeneralForm: isChangeGeneralForm
        });
    }

    setIsChangeRecordingForm = (isChangeRecordingForm) => {
        this.setState({
            isChangeRecordingForm: isChangeRecordingForm
        });
    }

    componentDidMount() {
        setEditableFields(PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_PRODUCTS);

        const {
            getProductMaintenanceSubmissionConfig,
            productMaintenanceSubmissionConfig,
            getFieldFormats,
            containerDetailsWindowComponentData,
            getDataIngestionComponentData,
            getFilesystems,
            cleanUploadFilesState,
            formatFields,
        } = this.props;
        if (getProductMaintenanceSubmissionConfig && !productMaintenanceSubmissionConfig) {
            getProductMaintenanceSubmissionConfig();
        }

        if (!formatFields)
            getFieldFormats(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP);

        if (!containerDetailsWindowComponentData || !containerDetailsWindowComponentData.fields || containerDetailsWindowComponentData.fields.length === 0) {
            getDataIngestionComponentData();
        }

        getFilesystems(true);
        cleanUploadFilesState();
    }

    handleSubmitGeneralProductDataFormikForm = async () => {
        if (this.productDataFormikRef.current) {
            await this.productDataFormikRef.current.submitForm(); 
            await this.productDataFormikRef.current.validateForm(this.productDataFormikRef.current.values);                 
        }
    };

    handleSubmitRecordingProductDataFormikForm = async () => {
        if (this.productRecordingFormikRef.current) {
            await this.productRecordingFormikRef.current.submitForm(); 
            await this.productRecordingFormikRef.current.validateForm(this.productRecordingFormikRef.current.values);   
        }
    };

    saveProduct = async (saveOption: SaveOption) => {
        const { saveChanges,product, useNewUIProductMaintenanceGeneralView, lookupValues, activeTab, formatFields, isOpenForWorkflowSession, workflowSessionItems, currentWorkflowIndex, setValidationWarningMessage, paneIndex } = this.props;
        const dataActions = getDataAction(product.dataSource, PRODUCT_MAINTENANCE_TOOLBAR);
        const { isCompleteWorkflowOnSave } = this.state;
        let newProduct = product;
        if (this.productRecordingFormikRef.current.values && product.productType === "RECORDING") {
            await this.handleSubmitRecordingProductDataFormikForm();
            if (this.productRecordingFormikRef.current.errors && Object.keys(this.productRecordingFormikRef.current.errors).length > 0) {
                setValidationWarningMessage(true);
                return;
            }
            if (this.productRecordingFormikRef.current.values) {
                let recordingDetails = formatProductRecordingDetailsForState(this.productRecordingFormikRef.current.values, product.recordingDetails);
                newProduct = {
                    ...product,
                   recordingDetails: recordingDetails,
                }
            }


        }

        if (useNewUIProductMaintenanceGeneralView && this.productDataFormikRef.current) {
            await this.handleSubmitGeneralProductDataFormikForm();
            if (this.productDataFormikRef.current.errors && Object.keys(this.productDataFormikRef.current.errors).length > 0) {
                setValidationWarningMessage(true);
                return;
            }
            if (this.productDataFormikRef.current.values) {
                let generalProduct = productGeneralForAPI(this.productDataFormikRef.current.values);

                newProduct = {
                    ...product,
                    source: generalProduct?.source,
                    productType: generalProduct.productType,
                    sourceType: generalProduct.sourceType,
                    duration: generalProduct.duration,
                    maintained: generalProduct.maintained,
                    mergeThisProduct: generalProduct.mergeThisProduct,
                    lastModifiedDate: generalProduct.lastModifiedDate,
                    id1: generalProduct.id1,
                    id2: generalProduct.id2,
                    released: generalProduct.released,
                    sourceIP: generalProduct.sourceIP,
                    productSourceIP: generalProduct?.productSourceIP,

                }
            }
        }
        this.setIsChangeGeneralForm(false);
        this.setIsChangeRecordingForm(false);

        if (isCompleteWorkflowOnSave) {
            if (newProduct.workflows && product.workflows[0] && newProduct.workflows[0].status) {
                newProduct.workflows[0].status = "Completed";
            }
        }
        saveChanges(newProduct, saveOption, lookupValues, dataActions, activeTab, formatFields, workflowSessionItems, currentWorkflowIndex + 1, isOpenForWorkflowSession, paneIndex);        
    }

    manualMergeProduct(matchingProductId: number, mergeOption: string) {
        const { manualMergeProduct, product, lookupValues, paneIndex } = this.props;

        let mergeAction = ManualMergeOptions[mergeOption];
        const dataActions = getDataAction(DataSource.Repertoire, PRODUCT_MAINTENANCE_TOOLBAR);

        manualMergeProduct(product, matchingProductId, mergeAction, lookupValues, dataActions, true, paneIndex);
    }

    addProductToPackage(userName:string, entityType:string, entityID:number,) {
        const { addToPackages, paneIndex } = this.props;
        addToPackages(userName, entityType, entityID, paneIndex);
     }

    deleteProduct() {
        const { activeTab, dataSource, product, paneIndex } = this.props;
        this.props.deleteProduct(dataSource, product.productCoreID, activeTab, paneIndex);
    }

    toolBarMergeProduct(matchingProductId: number) {
        const { manualMergeProduct, product, lookupValues } = this.props;

        let mergeAction = ManualMergeOptions.MergeRepertoireTitlesNumbers;
        const dataActions = getDataAction(DataSource.Repertoire, PRODUCT_MAINTENANCE_TOOLBAR);

        manualMergeProduct(product, matchingProductId, mergeAction, lookupValues, dataActions, false);
    }

    undoChanges() {
        const { undoChanges, product, lookupValues, resetMessageBanner, paneIndex } = this.props;
        undoChanges(product.dataSource.toString(), product.productCoreID, lookupValues, paneIndex);
        resetMessageBanner();
    }

    getWorkDetails(dataSource: string, workID: number) {
        const { lookupValues, workMaintenanceGeneralDataViewData, tabs, activeTab } = this.props;
        const dataActionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                this.props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, tabs[activeTab].readonlyIndicatorWorkFlagTypes, dataActionList, workMaintenanceGeneralDataViewData, formats);
            });
    }

    getIPDetails(ipBaseNumber: string, accountNumber: string) {
        const { ipMaintenanceGeneralDataView } = this.props;
        this.props.getIPDetails(ipBaseNumber, accountNumber, ipMaintenanceGeneralDataView);
    }

    getProductDetails = (dataSource: string, productID: number) => {
        const { productMaintenanceGeneralDataViewData, lookupValues, isOpenForWorkflowSession, tabs, activeTab } = this.props;
        const dataActionList = getDataAction(dataSource, PRODUCT_MAINTENANCE_TOOLBAR);
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP)
            .then(formats => {
                this.props.getProductDetails(dataSource, productID, productMaintenanceGeneralDataViewData, formats, isOpenForWorkflowSession, dataActionList);
            });
    }

    refreshProductDetail() {
        const { refreshProductDetails, dataSource, product, tabs, activeTab, editableFieldsDataView, formatFields } = this.props;

        refreshProductDetails(dataSource, product.productCoreID, tabs[activeTab], editableFieldsDataView.fields, formatFields);
    }

    openAccordion(accordionName: string, componentName: string): void {
        const { openAccordian, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        openAccordian(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, true, componentName);
    }
    closeAccordion(accordionName: string, componentName: string): void {
        const { closeAccordian, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        closeAccordian(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, false, componentName);
    }

    completeWorkflowOnSave(value: boolean) {
        this.setState({
            isCompleteWorkflowOnSave: value
        });
    }

    copyProduct() {
        const { copyExistingProduct, paneIndex } = this.props;
        copyExistingProduct(paneIndex);
    }

    addProduct(lookups: ILookupDictionary, isNew?: boolean, productMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) {
       const { addNewProduct, paneIndex } = this.props;
        addNewProduct(lookups, isNew, productMaintenanceGeneralDataViewData, formats, paneIndex);
    }

    render() {
        const {
            productMaintenanceGeneralDataViewData,
            productMaintenanceGridsViewData,
            productMaintenanceAccordionViewData,
            productMaintenanceToolbarData,
            productMaintenanceSaveResultData,
            avWorkDetailsAccordianViewData,
            avSubmissionDetailsAccordianViewData,
            dataGridTableData,
            showModal,
            hideModal,
            clearModalSearchResults,
            showYesNoPrompt,
            isFetchingProduct,
            lookupValues,
            activeAccordian,
            addNewProduct,
            copyExistingProduct,
            updateProductField,
            updateProductFields,
            sortProductFieldData,
            tabs,
            activeTab,
            addTab,
            product,
            dataSource,
            saveInProgress,
            resetMessageBanner,
            searchProductVersionHistory,
            postProductBatch,
            matchProductBatch,
            setEditableFields,
            editableFieldsDataView,
            showUpdateFieldsModal,
            workflowFieldsData,
            submitProductAVRequest,
            productMaintenanceSubmissionConfig,
            formats,
            destinations,
            distributions,
            selectedFileSystem,
            droppedFiles,
            setInDropZone,
            sortColumn,
            addFileToUpload,
            isDropZone,
            setProgressUploadFileProccess,
            cleanUploadFilesState,
            storagePaths,
            storagePathItemData,
            containerDetailsWindowComponentData,
            refreshDirectory,
            customFields,
            addRepertoireAttachmentToState,
            attachedFiles,
            removeProductAttachmentIfExists,
            addDroppedFileRepertoire,
            productAttachmentsDropped,
            setProgressUploadFileRepertoire,
            cleanDroppedFilesRepertoire,
            refreshProductDetails,
            editableFields,
            updateComponentFieldsItems,
            currentUser,
            sources,
            paginationDetails,
            updatePagination,
            expandedProductResults,
            expandMatchingProduct,
            roles,
            incrementTempID,
            workflowSessionItems,
            isWorkflowSessionStarted,
            skipWorkflowItem,
            nextWorkflowItem,
            saveWorkflow,
            isOpenForWorkflowSession,
            cancelWorkflowSession,
            currentWorkflowIndex,
            commentCategoryFlag,
            useNewUIProductMaintenanceGeneralView,
            updatedScroll,
            cueSheetsEnabled,
            cuesheetDatasource,
            addToPackages,
            showMessage,
            skipProductWorkflowItem
        } = this.props;

        const changesMade = tabs[activeTab].changesMade || (useNewUIProductMaintenanceGeneralView && this.state.isChangeGeneralForm) || this.state.isChangeRecordingForm;
        const isReadonly: boolean = tabs[activeTab].isReadonly;
        if (!isFetchingProduct && this.props.product) {
            const {
                productCoreID,
                dataSource,
                productType,
                id1,
                id2,
                duration,
                released,
                trackCount,
                source,
                maintained,
                production,
                licenseeID,
                productName,
                productNumber,
                productPerformer,
                productWork,
                productDuplicate,
                intrayProductDuplicate,
                productAVWork,
                productContributor,
                lastModifiedDate,
                productBatchID,
                productAVRequest,
                productAVWorkSubmissions,
                comments,
                matchingRepertoireProducts,
                productSourceIP,
                sourceIP,
                status,
                sourceType,
                societyInterestPercentage,
                replacedProducts,
            } = this.props.product;

            const {
                mergeThisProduct,
                toolbarWidth,
            } = this.state;

            const title = ProductDetailsMapper.getOriginalTitleFromProductNamesState(productCoreID, productName);
            const productBatchOperationInProgress = tabs[activeTab].productMaintenanceState && tabs[activeTab].productMaintenanceState.productBatchOperationInProgress;
            return (
                <div className="">
                    <div><span className="title">{title}</span></div>
                    <If condition={tabs[activeTab].versionNumber != null}>
                        <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                    </If>
                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>

                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <ProductMaintenanceToolbar
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            toolbarData={productMaintenanceToolbarData}
                            saveResultData={productMaintenanceSaveResultData}
                            saveChanges={this.saveProduct.bind(this)}
                            changesMade={changesMade}
                            undoChanges={this.undoChanges.bind(this)}
                            product={product}
                            addNewProduct={this.addProduct.bind(this)}
                            copyExistingProduct={this.copyProduct.bind(this)}
                            deleteProduct={this.deleteProduct.bind(this)}
                            mergeProduct={this.toolBarMergeProduct.bind(this)}
                            activeTab={activeTab}
                            lookupValues={lookupValues}
                            tabs={tabs}
                            showYesNoPrompt={showYesNoPrompt}
                            hideModal={hideModal}
                            resetMessageBanner={resetMessageBanner}
                            searchProductVersionHistory={searchProductVersionHistory}
                            clearModalSearchResults={clearModalSearchResults}
                            dataSource={dataSource}
                            isReadonly={isReadonly}
                            productMaintenanceGeneralDataViewData={productMaintenanceGeneralDataViewData}
                            workflowFieldsData={workflowFieldsData}
                            roles={roles}
                            isWorkflowSessionStarted={isWorkflowSessionStarted}
                            nextWorkflowItem={nextWorkflowItem}
                            skipWorkflowItem={skipWorkflowItem}
                            cancelWorkflowSession={cancelWorkflowSession}
                            workflowSessionItems={workflowSessionItems}
                            currentWorkflowIndex={currentWorkflowIndex}
                            isOpenForWorkflowSession={isOpenForWorkflowSession}
                            refreshProductDetails={this.refreshProductDetail.bind(this)}
                            saveWorkflow={saveWorkflow}
                            completeWorkflowOnSave={this.completeWorkflowOnSave.bind(this)}
                            cueSheetsEnabled={cueSheetsEnabled}
                            cuesheetDatasource ={cuesheetDatasource}
                            addToPackages={this.addProductToPackage.bind(this)}
                            currentUser={currentUser}
                            showMessage={showMessage}
                            skipProductWorkflowItem={skipProductWorkflowItem}
                        />
                    </div>
                    {useNewUIProductMaintenanceGeneralView ? 
                    <ProductMaintenanceWrapperFormik
                        setIsChangeGeneralForm={this.setIsChangeGeneralForm}
                        productDataFormikRef={this.productDataFormikRef}
                        productMaintenanceGeneralDataViewData={productMaintenanceGeneralDataViewData}   
                        saveChanges={this.saveProduct.bind(this)}
                        updateProductField={updateProductField}
                        updateProductFields={updateProductFields}
                        lookupValues={lookupValues}
                        dataSource={dataSource}
                        changesMade={changesMade}
                        saveInProgress={saveInProgress}
                        productType={productType}
                        id1={id1}
                        id2={id2}
                        duration={duration}
                        released={released}
                        trackCount={trackCount}
                        source={source}
                        maintained={maintained}
                        production={production}
                        licenseeID={licenseeID}
                        lastModifiedDate={lastModifiedDate}
                        productBatchID={productBatchID}
                        postProductBatch={postProductBatch}
                        matchProductBatch={matchProductBatch}
                        productBatchOperationInProgress={productBatchOperationInProgress}
                        isReadonly={isReadonly}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        editableFields={editableFields}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        dataGridTableEditableFieldsData={dataGridTableData}
                        setEditableFields={setEditableFields}
                        activeTab={activeTab}
                        tabs={tabs}
                        hideModal={hideModal}
                        roles={roles}
                        sourceIP={sourceIP}
                        sourceType={sourceType}
                        productSourceIP={productSourceIP}
                        status={status }
                        showModal={showModal}
                        product={product}
                    />
                     : 
                    <ProductMaintenanceGeneralDataView
                        productMaintenanceGeneralDataViewData={productMaintenanceGeneralDataViewData}
                        updateProductField={updateProductField}
                        lookupValues={lookupValues}
                        dataSource={dataSource}
                        changesMade={changesMade}
                        saveInProgress={saveInProgress}
                        productType={productType}
                        id1={id1}
                        id2={id2}
                        duration={duration}
                        released={released}
                        trackCount={trackCount}
                        source={source}
                        maintained={maintained}
                        production={production}
                        licenseeID={licenseeID}
                        lastModifiedDate={lastModifiedDate}
                        productBatchID={productBatchID}
                        postProductBatch={postProductBatch}
                        matchProductBatch={matchProductBatch}
                        productBatchOperationInProgress={productBatchOperationInProgress}
                        isReadonly={isReadonly}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        editableFields={editableFields}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        dataGridTableEditableFieldsData={dataGridTableData}
                        setEditableFields={setEditableFields}
                        activeTab={activeTab}
                        tabs={tabs}
                        hideModal={hideModal}
                        roles={roles}
                        sourceIP={sourceIP}
                        sourceType={sourceType}
                        productSourceIP={productSourceIP}
                        status={status }
                        showModal={showModal}
                        product={product}
                    />}
                    <ProductMaintenanceGridsView
                        productRecordingFormikRef={this.productRecordingFormikRef}
                        updateProductFields={updateProductFields}
                        updateProductField={updateProductField}
                        sortProductFieldData={sortProductFieldData}
                        productMaintenanceGridsViewData={productMaintenanceGridsViewData}
                        productMaintenanceAccordionViewData={productMaintenanceAccordionViewData}
                        avWorkDetailsAccordianViewData={avWorkDetailsAccordianViewData}
                        avSubmissionDetailsAccordianViewData={avSubmissionDetailsAccordianViewData}
                        dataGridTableData={dataGridTableData}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        activeAccordian={activeAccordian}
                        openAccordian={this.openAccordion.bind(this)}
                        closeAccordian={this.closeAccordion.bind(this)}
                        productType={productType}
                        dataSource={this.props.dataSource}
                        productNames={productName}
                        productNumbers={productNumber}
                        productPerformers={productPerformer}
                        productWorks={productWork}
                        productDuplicates={productDuplicate}
                        intrayProductDuplicate={intrayProductDuplicate}
                        productAVWork={productAVWork}
                        productContributors={productContributor}
                        societyInterestPercentage={societyInterestPercentage}
                        addTab={addTab}
                        tabs={tabs}
                        activeTab={activeTab}
                        getWorkDetails={this.getWorkDetails.bind(this)}
                        getIPDetails={this.getIPDetails.bind(this)}
                        getProductDetails={this.getProductDetails.bind(this)}
                        isReadonly={isReadonly}
                        productAVRequest={productAVRequest}
                        productAVWorkSubmissions={productAVWorkSubmissions}
                        productCoreID={productCoreID}
                        submitProductAVRequest={submitProductAVRequest}
                        productMaintenanceSubmissionConfig={productMaintenanceSubmissionConfig}
                        addFileToUpload={addFileToUpload}
                        cleanUploadFilesState={cleanUploadFilesState}
                        containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                        destinations={destinations}
                        distributions={distributions}
                        droppedFiles={droppedFiles}
                        isDropZone={isDropZone}
                        selectedFileSystem={selectedFileSystem}
                        setInDropZone={setInDropZone}
                        setProgressUploadFileProccess={setProgressUploadFileProccess}
                        sortColumn={sortColumn}
                        storagePathItemData={storagePathItemData}
                        storagePaths={attachedFiles}
                        refreshDirectory={(x) => refreshDirectory(x, sources)}
                        addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                        attachedFiles={attachedFiles}
                        removeProductAttachmentIfExists={removeProductAttachmentIfExists}
                        addDroppedFileRepertoire={addDroppedFileRepertoire}
                        productAttachmentsDropped={productAttachmentsDropped}
                        setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                        cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                        refreshProductDetails={this.refreshProductDetail.bind(this)}
                        hideModal={hideModal}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        editableFields={editableFields}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        setEditableFields={setEditableFields}
                        productNotes={comments || []}
                        currentUser={currentUser}
                        paginationDetails={paginationDetails}
                        updatePagination={updatePagination}
                        matchingProducts={matchingRepertoireProducts}
                        expandedProductResults={expandedProductResults}
                        expandMatchingProduct={expandMatchingProduct}
                        incrementTempID={incrementTempID}
                        manualMergeProduct={this.manualMergeProduct.bind(this)}
                        product={product}
                        commentCategoryFlag={commentCategoryFlag}
                        setIsChangeRecordingForm={this.setIsChangeRecordingForm.bind(this)}
                        replacedProducts={replacedProducts}
                    />
                </div>
            );
        }
        return <div>Loading...</div>;
    }
}
