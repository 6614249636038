import * as React from "react";
import { If } from "../../core/components/If";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { setDataSource } from "../../redux/reducers/RepertoireReducer";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import SearchView from "../components/searchView/SearchView";
import {
    ALL_ROLES,
    SEARCH_VIEW_PAYMENTRUN,
    UPDATE_PAYMENT_RUNS_ROLE,
    VIEW_PAYMENT_RUNS_ROLE
} from "../Consts";
import { IPaymentRunSearchResult } from "../types/IPaymentRunSearchResult";
import { IPaymentRunSearchQuery } from "../types/IPaymentRunSearchQuery";
import { IPaymentRun } from "../types/usageTypes/IPaymentRun";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { msalConfig } from "../../accounts/types/msalAuthConfig";

export interface IPaymentRunPageProps {
    paymentRunPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchPaymentRuns: (query: IPaymentRunSearchQuery) => void;
    paymentRunSearchResults: IPaymentRun[];
    searchSuccessful: boolean;
    paymentRunMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getPaymentRunDetails: (paymentRunID: number, lookupValues: ILookupDictionary) => void;
    addNewPaymentRun: (lookups: ILookupDictionary, isNew?: boolean, paymentRunMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
}

const PaymentRunPage = (props: IPaymentRunPageProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const checkIfLoaded = () => {
        const { paymentRunPageData } = props;

        if (paymentRunPageData.fields && paymentRunPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = paymentRunPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const addNewPaymentRun = (): void => {
        const { addNewPaymentRun, lookupValues, paymentRunMaintenanceGeneralDataViewData } = props;
        addNewPaymentRun(lookupValues, true, paymentRunMaintenanceGeneralDataViewData)
    }
    
    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const {
        searchViewData,
        searchResultsTableData,
        searchPaymentRuns,
        paymentRunSearchResults,
        searchSuccessful,
        indexOfFirstResult,
        indexOfLastResult,
        resultsPerPage,
        currentPage,
        resetPagination,
        updatePagination,
        getPaymentRunDetails,
        sortSearchResults
    } = props;

    let { lookupValues } = props;

    const getUpdateRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(UPDATE_PAYMENT_RUNS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(VIEW_PAYMENT_RUNS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    if (loaded) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Payment Run</span>
                        <div className="newWorkButton" title="Create New Payment Run" hidden={getUpdateRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewPaymentRun}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                New Payment Run
                            </div>
                        </div>
                    </div>
                </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_PAYMENTRUN}
                    modalProps={undefined}
                    dataSource={undefined}
                    hideModal={undefined}
                    lookupValues={lookupValues}
                    searchPaymentRuns={searchPaymentRuns}
                    paymentRunSearchResults={paymentRunSearchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    getPaymentRunDetails={getPaymentRunDetails}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={getViewRoleDisabled()}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default PaymentRunPage;
