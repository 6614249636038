import * as React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { ILoginViewModel } from '../../types/ILoginViewModel';
import { B2CAuthenticated } from './B2CAuthenticated';
import { Redirect } from 'react-router-dom';
import { B2CUnauthenticated } from './B2CUnauthenticated';


interface IB2CCallbackCatchProps {
    login: (viewModel: ILoginViewModel, redirect: any) => void;
    redirect?: any;
    isSignedIn: boolean;
}

export const B2CCallbackCatch = (props: IB2CCallbackCatchProps) => {
    
    const { login, redirect, isSignedIn } = props
    const { instance } = useMsal()

    return (
        <div>
            <AuthenticatedTemplate>
                <B2CAuthenticated login={login} redirect={redirect} isSignedIn={isSignedIn} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <B2CUnauthenticated/>
            </UnauthenticatedTemplate>
        </div>
    );

}
