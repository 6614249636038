import * as React from "react";
import SearchView from "../components/searchView/SearchView";
import SelectorView from "../components/selectorView/SelectorView";
import {
    SEARCH_VIEW_PRODUCTS,
    REPERTOIRE,
    INTRAY,
    SIMPLE,
    ALL_ROLES,
    UPDATE_PRODUCTS_ROLE,
    VIEW_PRODUCTS_ROLE
} from "../Consts";
import { setProductDataSource } from "../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IProductSearchQuery } from "../types/IProductSearchQuery";
import { IProductSearchResult } from "../types/IProductSearchResult";
import { FormatFields } from "../../redux/types/FormatFields";
import { SearchRequests } from "../services/SearchRequests";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import ProductsSearchView from "./ProductSeachView";
import { IMyPackagesResult } from "../types/IMyPackagesResult";

export interface IProductsPageProps {
    productsPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    dataSource: string;
    setProductDataSource: typeof setProductDataSource;
    getProductDetails: (dataSource: string, productCoreID: number,productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats?: FormatFields[],openEntityForWorflowSession?: boolean,openNewTab?: boolean,id1?:string,id2?:string,productType?:string,originOfRevision?:string,enableCuesheet?:boolean,productionType?:string,musicDuration?:number[]) => void;
    addNewProduct: (lookups: ILookupDictionary, isNew?: boolean,productMaintenanceGeneralDataView?: IRepertoireComponentDataItem,formats?: FormatFields[]) => void;
    searchProducts: (query: IProductSearchQuery, modalOpen: boolean) => void;
    productSearchResults: IProductSearchResult[];
    lookupValues: ILookupDictionary;
    searchSuccessful: boolean;
    productMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    expandedProductResults: number[];
    expandAll: boolean;
    expandProductResult: (productResult: number) => void;
    expandAllResults: () => void;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    getShowCommentSubjectAsDropdownConfigurationParameter?: (group: string) => void;
    useNewUIgridSearchResults: boolean;
    enableCuesheet:boolean;
    cuesheetDatasource:any;
    currentUser?:string;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>IMyPackagesResult;
}

const ProductsPage = ({
    productsPageData,
    saveRoles,
    dataSource,
    searchViewData,
    setProductDataSource,
    searchResultsTableData,
    searchProducts,
    productSearchResults,
    lookupValues,
    searchSuccessful,
    getShowCommentSubjectAsDropdownConfigurationParameter,
    getProductDetails,
    addNewProduct, 
    productMaintenanceGeneralDataViewData,
    expandedProductResults,
    roles,

    expandAll,
    expandProductResult,
    expandAllResults,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    sortSearchResults,
    defaultActiveAccordions,
    allResultsPerPage,
    updateUserPreferences, 
    updatePagination,
    useNewUIgridSearchResults,
    enableCuesheet,
    cuesheetDatasource,
    currentUser,
    addToPackages
}) => {
    const [searchType, setSearchType] = React.useState(SIMPLE)
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [loaded, productsPageData])

    const checkIfLoaded = () => {

        if (productsPageData.fields && productsPageData.fields.length > 1 && !loaded) {
            setLoaded(true)
            const header = productsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const addNewProductLocal = (): void => {
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY,PRODUCT_MAINTENANCE_GROUP)
        .then(formats => {
            addNewProduct(lookupValues, true,productMaintenanceGeneralDataViewData,formats);
        })
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    if (loaded) {

        const {
            fields: {
                0: { data: dataSourceTitle },
            }
           } = productsPageData;



        const getUpdateRoleDisabled = (): boolean => {
            if (roles && (roles.includes(UPDATE_PRODUCTS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const getViewRoleDisabled = (): boolean => {
            if (roles && (roles.includes(VIEW_PRODUCTS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const getProductDetailsLocal = (dataSource: string, productID: number, isForWorkflowSession?: boolean, openNewTab?: boolean,id1?:string,id2?:string,type?:string,originOfRevision?:string) => {
            SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY,PRODUCT_MAINTENANCE_GROUP)
            .then(formats => {
                getProductDetails(dataSource, productID,productMaintenanceGeneralDataViewData, formats,'','',id1,id2,type,originOfRevision,enableCuesheet,cuesheetDatasource);
            });
        }
        const username = sessionStorage.getItem('username');
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Product</span>
                        <div className="newWorkButton" title="Create New Product" hidden={getUpdateRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewProductLocal}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                    New Product
                                </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <SelectorView
                        selection={dataSource}
                        setSelection={setProductDataSource}
                        option1={REPERTOIRE}
                        option2={INTRAY}
                        selectorTitle={dataSourceTitle}
                    />
                </div>
                {useNewUIgridSearchResults ? 
                <ProductsSearchView
                    getProductDetails={getProductDetailsLocal}
                    searchProducts={searchProducts}
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    dataSource={dataSource}
                    productSearchResults={productSearchResults}
                    lookupValues={lookupValues}
                    searchSuccessful={searchSuccessful}
                    searchDisabled={getViewRoleDisabled()}
                    getShowCommentSubjectAsDropdownConfigurationParameter={getShowCommentSubjectAsDropdownConfigurationParameter}

                    expandAll={expandAll}
                    expandProductResult={expandProductResult}
                    expandAllResults={expandAllResults}
                    expandedProductResults={expandedProductResults}
                />
             : 
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_PRODUCTS}
                    modalProps={undefined}
                    dataSource={dataSource}
                    getProductDetails={getProductDetailsLocal}
                    searchProducts={searchProducts}
                    productSearchResults={productSearchResults}
                    lookupValues={lookupValues}
                    hideModal={undefined}
                    searchSuccessful={searchSuccessful}
                    expandedProductResults={expandedProductResults}
                    expandAll={expandAll}
                    expandProductResult={expandProductResult}
                    expandAllResults={expandAllResults}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    currentUser={username}
                    addToPackages={addToPackages}
                    updatePagination={updateUserPagination}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={getViewRoleDisabled()}
                    getShowCommentSubjectAsDropdownConfigurationParameter={getShowCommentSubjectAsDropdownConfigurationParameter}
                    groupName={PRODUCT_MAINTENANCE_GROUP}
                    resultPageData={productsPageData}
                />
    } 
            </div>
        );
    }
    return <div />;
}

export default ProductsPage;
