import * as React from "react";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { createCopyOfTableContents } from "../../services/TableFunctions";
import PaginationView from "../../../repertoire/components/paginationView/PaginationView";
import DisplayTable, { IRowActions } from "../displayTable/DisplayTable";
import DisplayText from "../text/DisplayText";
import { IUnpaidClaimsSearchResult } from "../../types/IUnpaidClaimsSearchResult";
import { IDraftWorkSearchResult } from "../../types/IDraftWorkSearchResult";


interface ISearchResultsPageProps {
    searchSuccessful: boolean;
    searchResultsTableData: IRepertoireField[];
    searchResults: IUnpaidClaimsSearchResult[] | IDraftWorkSearchResult[];
    lookupValues?: ILookupDictionary;
    indexOfFirstResult?: number; 
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    sortSearchResults?: (results: any) => void;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    rowActions?: IRowActions;
    getAgreementDetails?: (dataSource: string, agreementID) => void;
}

const DisplayTableSearchResults: React.FC<ISearchResultsPageProps> = ({
    searchSuccessful,
    searchResultsTableData,
    searchResults,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    updatePagination,
    sortSearchResults,
    rowActions,
    getAgreementDetails,
}) => {
    const [indexOfFirstResultLocal, setIndexOfFirstResult] = React.useState(indexOfFirstResult);
    const [indexOfLastResultLocal, setIndexOfLastResult] = React.useState(indexOfLastResult);
    const [resultsPerPageLocal, setResultsPerPage] = React.useState(resultsPerPage);
    const [currentPageLocal, setCurrentPage] = React.useState(currentPage);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [activeSortHeaderSection, setActiveHeaderSection] = React.useState('');
    const [isAscending, setIsAscending] = React.useState(false);

    React.useEffect(() => {

        if (searchResults && currentTableData.length >= 0) {
            const currentResults = searchResults.slice(
                indexOfFirstResultLocal,
                indexOfLastResultLocal
            );
            setCurrentTableData(currentResults)
        }

    }, [searchResultsTableData, searchResults, currentPage, resultsPerPage, indexOfLastResult, indexOfFirstResult])

    const updateContentsTable = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        setIndexOfFirstResult(indexOfFirstResult);
        setIndexOfLastResult(indexOfLastResult);
        setResultsPerPage(resultsPerPage);
        setCurrentPage(currentPage);

        const currentResults = searchResults.slice(
            indexOfFirstResult,
            indexOfLastResult
        );
        setCurrentTableData(currentResults);
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
    }

    const sortTableByColumn = (section: string) => {

        let tableContentsCopy = createCopyOfTableContents(searchResults);

        if (activeSortHeaderSection !== section) {
            setIsAscending(false);
        }

        tableContentsCopy.sort((a: any, b: any) => {
            let newA = a[section] || "";
            let newB = b[section] || "";

            newA = newA ? newA : "";
            newB = newB ? newB : "";

            if (activeSortHeaderSection === 'dateLoaded') {
                newA = newA.split('/').reverse().join()
                newB = newB.split('/').reverse().join()
            }

            if (isAscending) {
                return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
            }
            return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });
        });

        const currentResults = tableContentsCopy.slice(
            indexOfFirstResultLocal,
            indexOfLastResultLocal
        );
        setCurrentTableData(currentResults);
        setActiveHeaderSection(section);

        setIsAscending(!isAscending);

        if (sortSearchResults) {
            sortSearchResults(tableContentsCopy);
        }
    };

    if (searchSuccessful && (searchResultsTableData  && searchResultsTableData.length > 0) && (searchResults && searchResults.length > 0)) {
        return <div className="row">
        <div className="col-md-12 searchResultsDiv">
            <div className="SearchResultsTable">
                <PaginationView 
                    key='pagination1'
                    contentsLength={searchResults ? searchResults.length : 0}
                    resultsPerPage={resultsPerPageLocal}
                    currentPage={currentPageLocal}
                    updateContentsTable={updateContentsTable}
                />
                    <DisplayTable
                        tableContents={currentTableData}
                        dataGridTableData={searchResultsTableData}  
                        isSearchResultTable={true}
                        sortTableByColumn={(section) => sortTableByColumn(section)}
                        activeSortHeaderSection={activeSortHeaderSection}
                        isAscending={isAscending}
                        rowActions={rowActions}
                        isExpandable={true}
                        getAgreementDetails={getAgreementDetails}
                     />
                <PaginationView 
                    key='pagination2'
                    contentsLength={searchResults ? searchResults.length : 0}
                    resultsPerPage={resultsPerPageLocal}
                    currentPage={currentPageLocal}
                    updateContentsTable={updateContentsTable}
                />
            </div>
        </div>
    </div>
    }

    return (
        <div className="row">
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                No search result
            </div>
        </div>
    )
}

export default DisplayTableSearchResults