import React, { useEffect } from "react";
import { hideModal } from "../../../../redux/reducers/ModalReducer";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import DataGridTable from "../../../../repertoire/components/dataGridTable/DataGridTable";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ADD_NEW_ACTION, DRAFT_AGREEMENT_ASSIGNEE_VIEW } from "../../../Consts";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { MEMBERS_PORTAL } from "../../../../accounts/Consts";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import { INTEGER_PERCENTAGE_INPUT } from "../../../../repertoire/Consts";

interface DraftAgreementsMaintenanceAssigneeViewProps {
    draftAgreementMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    updateDraftAgreementField: (value: any, fieldName: IAgreementStateKeys, index?: number) => void;
    showModal: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    ) =>void;
    hideModal: typeof hideModal;
    isNewDraftAgreement: boolean;
    dataGridTableData?: IRepertoireComponentDataItem;
    activeTab: number;
    tabs: ITabReduxItem[];
    incrementTempID: () => void;
    clearModalSearchResults: () => void;
    lookupValues: any;
    agreements: IAgreementState;
}

export const SELECT_IP = 'Select IP';

const DraftAgreementsMaintenanceAssigneeView: React.FC<DraftAgreementsMaintenanceAssigneeViewProps> = ({
    draftAgreementMaintenanceGeneralDataViewData,
    dataGridTableData,
    incrementTempID,
    tabs,
    activeTab,
    lookupValues,
    clearModalSearchResults,
    updateDraftAgreementField,
    showModal,
    agreements,
}) => {

    const [assigneeData, setAssigneeData] = React.useState([]);

    const changeAssigneeData = (data: any) => {
        updateAgreementAdminIP(data);
    }

    const updateAgreementAdminIP = (updatedAgreements) => {
        const oldAgreementAdminIP = agreements.agreementAdminIP;
        const newAgreementAdminIP = updatedAgreements.map((item, index) => {
            if (oldAgreementAdminIP[index])
                {
                    return {
                        ...oldAgreementAdminIP[index],
                        administeredByIPBaseNumber: {
                            value: item?.administeredByIPBaseNumber?.value ? item.administeredByIPBaseNumber.value : '', 
                            inputType: 'readonly'
                        },
                        societyAffiliation: {
                            value: item?.societyAffiliation?.value ? item.societyAffiliation.value : '',
                            inputType: 'readonly'
                        },
                        performingShare: {
                            value: item?.performingShare?.value ? item.performingShare.value : 0,
                            inputType: INTEGER_PERCENTAGE_INPUT
                        },
                        mechanicalShare: {
                            value: item?.mechanicalShare?.value ? item.mechanicalShare.value : 0,
                            inputType: INTEGER_PERCENTAGE_INPUT
                        },
                        administeredIP: {
                            value: item.assigneeNameNumber?.value ? item.assigneeNameNumber.value : '',
                            inputType: 'readonly'
                        },
                    }
            }
            return {
                administeredByIPBaseNumber: {
                    value: item.administeredByIPBaseNumber?.value ? item.administeredByIPBaseNumber.value : '', 
                    inputType: 'readonly'
                },
                administeredName: {
                    value: item.assigneeName?.value ? item.assigneeName.value : '',
                    inputType: 'readonly'
                },
                administeredIP: {
                    value: item.assigneeNameNumber?.value ? item.assigneeNameNumber.value : '',
                    inputType: 'readonly'
                },
                accountNumber: {
                    value: null,
                    inputType: 'readonly'
                },
                societyAffiliation: {
                    value: item.societyAffiliation?.value ? item.societyAffiliation.value : '',
                    inputType: 'readonly'
                },
                performingShare: {
                    value: item.performingShare?.value ? item.performingShare.value : 0,
                    inputType: INTEGER_PERCENTAGE_INPUT
                },
                mechanicalShare: {
                    value: item.mechanicalShare?.value ? item.mechanicalShare.value : 0,
                    inputType: INTEGER_PERCENTAGE_INPUT
                },
                agreementId: 0,
                ref: null,
            }

        });
        updateDraftAgreementField(newAgreementAdminIP, 'agreementAdminIP')
    }

    useEffect(() => {

        if (agreements?.agreementAdminIP) {
        const assigneeData = agreements.agreementAdminIP.map((item, index) => {
            let updatedAssigneeNameNumber;
            if(item?.assigneeNameNumber){
                updatedAssigneeNameNumber= item?.assigneeNameNumber.value?item.assigneeNameNumber.value:''
                
            }else{
                updatedAssigneeNameNumber= item.administeredIP?.value ? item.administeredIP.value : '';
            }
            return  {
                ...item,
                assigneeName: {
                    value: item.administeredName?.value ? item.administeredName.value : '',
                    inputType: 'readonly'
                },
                assigneeNameNumber: {
                    value: updatedAssigneeNameNumber,
                    inputType: 'readonly'
                },
                mechanicalShare: {
                    value: item.mechanicalShare?.value ? item.mechanicalShare.value : 0,
                    inputType: INTEGER_PERCENTAGE_INPUT
                },
                performingShare: {
                    value: item.performingShare?.value ? item.performingShare.value : 0,
                    inputType: INTEGER_PERCENTAGE_INPUT
                },
                societyAffiliation: {
                    value: item.societyAffiliation?.value ? item.societyAffiliation.value : '',
                    inputType: 'readonly'
                }
        }
        });

        setAssigneeData(assigneeData);
    }

    }, [agreements]);

    const  renderGridViewHeaderSections = (header: IMembersPortalField) => {

        return (
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={header.name}
                data-testid={header.name + "-section"}
            >
                {header.data}
            </div>
        );
    };

    
    return <div className="topMargin">
        <div className="row">
        <div className="headerContainer">
            {renderGridViewHeaderSections(draftAgreementMaintenanceGeneralDataViewData.fields.find(x => x.name.toString() === 'draftAgreementsAssignee'))}
        </div>
        </div>

        <DataGridTable
            tableContents={assigneeData ? assigneeData : []}
            changeData={changeAssigneeData}
            showModal={showModal}
            hideModal={hideModal}
            dataGridTableData={dataGridTableData}
            stateKey={DRAFT_AGREEMENT_ASSIGNEE_VIEW}
            componentInstance={DRAFT_AGREEMENT_ASSIGNEE_VIEW}
            showRowNumber={true}
            isReadOnly={false}
            incrementTempID={incrementTempID}
            tabs={tabs}
            activeTab={activeTab}
            tableActions={[{ name: ADD_NEW_ACTION }]}
            lookupValues={lookupValues}
            clearModalSearchResults={clearModalSearchResults}
            portalType={MEMBERS_PORTAL}
        />

    </div>
}

export default DraftAgreementsMaintenanceAssigneeView;