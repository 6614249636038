import * as React from "react";
import {
    PAYMENTRUN_MAINTENANCE_TOOLBAR,
    SAVE_METHOD_TOOLBAR,
    SAVE_CHANGES_STATE_KEY,
    DELETE_METHOD_TOOLBAR,
    DELETE_PAYMENTRUN_STATE_KEY,
    ADD_METHOD_TOOLBAR,
    ADD_NEW_PAYMENTRUN_STATE_KEY,
    UNDO_METHOD_TOOLBAR,
    COPY_METHOD_TOOLBAR,
    COPY_PAYMENTRUN_STATE_KEY,
    UNDO_CHANGES_PAYMENTRUN_STATE_KEY,
    VERSION_HISTORY_STATE_KEY,
    HISTORY_METHOD_TOOLBAR,
    DELETE_PAYMENT_RUNS_ROLE,
    ALL_ROLES,
    UPDATE_PAYMENT_RUNS_ROLE,
} from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { clearModalSearchResults, copyExistingPaymentRun } from "../../../../redux/reducers/RepertoireReducer";
import { showYesNoPrompt, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IPaymentRunState } from "../../../../redux/types/IPaymentRunState";
import { FormatFields } from "../../../../redux/types/FormatFields";

export interface IPaymentRunMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData?: IRepertoireComponentDataItem;
    paymentRun: IPaymentRunState;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    deletePaymentRun: () => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    isReadonly: boolean;
    undoPaymentRunChanges: () => void;
    searchVersionHistory: (paymentRunId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) => void;
    addNewPaymentRun: () => void;
    isNew?: boolean;
    currentUser?: string;
    lookupValues: ILookupDictionary;
    showModal: typeof showModal;
    copyPaymentRun: typeof copyExistingPaymentRun;
    roles: string[];
}

export interface IPaymentRunMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

export type PaymentRunMaintenanceToolbarKeys = keyof IPaymentRunMaintenanceToolbarProps;

class PaymentRunMaintenanceToolbar extends React.Component<
    IPaymentRunMaintenanceToolbarProps,
    IPaymentRunMaintenanceToolbarState
    > {
    actionData;

    constructor(props) {
        super(props);
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    saveChangesAction = () => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    copyPaymentRun = () => {
        const { tabs, activeTab, copyPaymentRun } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        copyPaymentRun(actionList);
    }
    deletePaymentRunAction = () => {
        const { deletePaymentRun, toolbarData, showYesNoPrompt, hideModal } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === DELETE_PAYMENTRUN_STATE_KEY).data;
        const propsModal: IYesNoPromptViewModalProps = {
            yesCallback: () => { deletePaymentRun(); hideModal(); },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, propsModal);
    }

    searchVersionHistory = () => {
        const { searchVersionHistory, clearModalSearchResults, paymentRun, lookupValues, tabs, activeTab } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        clearModalSearchResults();
        searchVersionHistory(paymentRun.paymentRunID, lookupValues, actionList, tabs[activeTab].formatFields);
    };

    getDeleteRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(DELETE_PAYMENT_RUNS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_PAYMENT_RUNS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getDataAction() {
        const {
            deletePaymentRun,
            addNewPaymentRun,
            toolbarData: { fields },
            undoPaymentRunChanges,
            tabs,
            activeTab,
            paymentRun
        } = this.props;

        const actionList: IDataActionToolbar[] = [];

        const add: IDataActionToolbar = {
            action: addNewPaymentRun,
            component: PAYMENTRUN_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(p => p.name === ADD_NEW_PAYMENTRUN_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition',
            isReadOnly: this.getUpdateRoleDisabled()
        }
        actionList.push(add);

        const deletePaymentRunAction: IDataActionToolbar = {
            action: this.deletePaymentRunAction,
            component: PAYMENTRUN_MAINTENANCE_TOOLBAR,
            method: DELETE_METHOD_TOOLBAR,
            name: fields.find(p => p.name === DELETE_PAYMENTRUN_STATE_KEY).data,
            isReadOnly: tabs[activeTab].paymentRunMaintenanceState.paymentRun.status === 'Closed' || this.getDeleteRoleDisabled() || this.getUpdateRoleDisabled(),
            icon: 'icon ms-Icon ms-Icon--Delete'
        }
        actionList.push(deletePaymentRunAction);

        const save: IDataActionToolbar = {
            action: this.saveChangesAction.bind(this),
            component: PAYMENTRUN_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        const undo: IDataActionToolbar = {
            action: undoPaymentRunChanges,
            component: PAYMENTRUN_MAINTENANCE_TOOLBAR,
            method: UNDO_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === UNDO_CHANGES_PAYMENTRUN_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Undo',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(undo);

        const searchVersionHistory: IDataActionToolbar = {
            action: this.searchVersionHistory,
            name: fields.find(save => save.name === VERSION_HISTORY_STATE_KEY).data,
            component: PAYMENTRUN_MAINTENANCE_TOOLBAR,
            method: HISTORY_METHOD_TOOLBAR,
            icon: 'icon ms-Icon ms-Icon--History'
        };
        actionList.push(searchVersionHistory);

        const copyPaymentRunAction: IDataActionToolbar = {
            action: this.copyPaymentRun,
            component: PAYMENTRUN_MAINTENANCE_TOOLBAR,
            method: COPY_METHOD_TOOLBAR,
            name: fields.find(d => d.name === COPY_PAYMENTRUN_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Copy',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(copyPaymentRunAction);

        return actionList;
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            activeTab,
            tabs,
            toolbarData,
            paymentRun,
            clearModalSearchResults,
            lookupValues,
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade

        const isReadonly: boolean = tabs[activeTab].isReadonly;
        let { dataAction } = { ...this.state };

        return (
            <div className={isReadonly && !changesMade ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction}
                    toolbarData={toolbarData}
                    component={PAYMENTRUN_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].paymentRunMaintenanceState.isNew}
                    isReadonly={isReadonly}
                    paymentRun={paymentRun}
                    lookupValues={lookupValues}
                    clearModalSearchResults={clearModalSearchResults}
                />
            </div>
        );
    }
}

export default PaymentRunMaintenanceToolbar;
