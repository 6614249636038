import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import {
    ActionTypes,
    ClearLookupCache,
    CLEAR_LOOKUP_CACHE,
    GetLookupEntitiesFailure,
    GetLookupEntitiesRequest,
    GetLookupEntitiesSuccess,
    GET_LOOKUP_ENTITIES_FAILURE,
    GET_LOOKUP_ENTITIES_REQUEST,
    GET_LOOKUP_ENTITIES_SUCCESS
} from "../actions/LookupActions";

export function getLookupEntitiesRequest(): GetLookupEntitiesRequest {
    return {
        type: GET_LOOKUP_ENTITIES_REQUEST
    };
}

export function getLookupEntitiesSuccess(
    entities: ILookupDictionary
): GetLookupEntitiesSuccess {
    return {
        type: GET_LOOKUP_ENTITIES_SUCCESS,
        payload: {
            entities
        }
    };
}

export function getLookupEntitiesFailure(
    error: string
): GetLookupEntitiesFailure {
    return {
        type: GET_LOOKUP_ENTITIES_FAILURE,
        payload: {
            error
        }
    };
}

export function clearLookupCache() : ClearLookupCache{
    return {
        type: CLEAR_LOOKUP_CACHE
    }
}

const lookupReducer = (state: ILookupDictionary = {}, action: ActionTypes): ILookupDictionary => {
    switch (action.type) {
        case GET_LOOKUP_ENTITIES_SUCCESS: {
            const { entities } = action.payload;
            return entities;
        }
        case CLEAR_LOOKUP_CACHE:{
            let newState: ILookupDictionary = JSON.parse(JSON.stringify(state)) as ILookupDictionary;
            newState = undefined;
           
            return newState;
        }   
        default:
            return state;
    }
};

export default lookupReducer;