import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { IPRepresentationPage } from "./IPRepresentationPage";
import { IInterestedPartyStateKeys } from "../../../types/IInterestedParty";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addTab, sortInterestedPartyFieldData, setChangesMade, updateInterestedPartyField, createNewAccount, resetMessageBanner, updateIpAgreementsFilterStateField, updateEditableFields, setEditableFields, openAccordion, closeAccordion, displayRepresentationFlag } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ISocIP } from "../../../types/ISocIP";
import { deleteAccountThunk, filterIpAgreementsThunk, saveAccountChangesThunk, addNewRepresentationThunk, undoIPChangesThunk, updateComponentFieldItemsThunk, searchSocIPVersionHistoryThunk, updateUserPreferencesThunk, getIPMaintenaceDisplaySettingsThunk, getRepresentationGroupsThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IAgreementSearchState, IAgreementSearchStateKeys } from "../../../types/IAgreementSearchState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { IRepresentation } from "../../../types/usageTypes/IRepresentation";
import { newRepresentationThunk } from "../../../../redux/thunks/RepresentationThunks";
import { ITabProps } from "../../../../redux/types/ITabProps";


export default connect(
    (state: IAppState, props: ITabProps) => ({
        ipMaintenanceGeneralDataViewData:
            state.repertoire.componentData.IPMaintenanceGeneralDataView,
        ipMaintenanceGridsViewData:
            state.repertoire.componentData.IPMaintenanceGridsView,
        ipMaintenanceAccordionViewData:
            state.repertoire.componentData.IPMaintenanceAccordionView,
        ipMaintenanceToolbarData:
            state.repertoire.componentData.IPMaintenanceToolbar,
        ipMaintenanceSaveResultData:
            state.repertoire.componentData.SaveResult,
        dataGridTableData:
            state.repertoire.componentData.DataGridTable,
        activeTab: state.repertoire.panes[props.paneIndex].activeTab,
        activePane: props.paneIndex,
        tabs: state.repertoire.panes[props.paneIndex].tabs,
        activeAccordian: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].activeAccordions,
        loaded: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].interestedPartyMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].interestedPartyMaintenanceState.loaded,
        ipMaintenancePageUIConfiguration: state.repertoire.ipMaintenancePageUIConfiguration,
        interestedParty: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].interestedPartyMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].interestedPartyMaintenanceState.interestedParty,
        ipMaintenanceTimeRangeSelectorViewData: state.repertoire.componentData.IpMaintenanceTimeRangeSelector,
        ipAgrementsSearchState: state.repertoire.ipAgrementsSearchState,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        lookupValues: state.lookupEntities,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        editableFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields,
        displayRepresentationBoolean: state.repertoire.displayRepresentation,
        ipRepresentationGeneralDataViewData: state.repertoire.componentData.IPRepresentationGeneralDataView,
        ipBaseNumber: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState &&
            state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState.representationState &&
            state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState.representationState.ipBaseNumber,
        representationGroups: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState &&
            state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState.representationState &&
            state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState.representationGroups,
        representationUses: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState &&
            state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState.representationState &&
            state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState.representationUses,
        representationWorkGroups: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState &&
            state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState.representationState &&
            state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].representationMaintenanceState.representationWorkGroups,
        activeAccordion: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].activeAccordions,
    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch(hideModal()),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        addTab: (tab: ITabReduxItem) =>
            dispatch(addTab(tab)),
        setChangesMade: (tabIndex: number, changesMade: boolean) =>
            dispatch(setChangesMade(tabIndex, changesMade)),
        updateInterestedPartyField: (value: any, fieldName: IInterestedPartyStateKeys) =>
            dispatch(updateInterestedPartyField(value, fieldName)),
        saveChanges: (account: ISocIP) =>
            dispatch<any>(saveAccountChangesThunk(account)),
        addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) =>
            dispatch<any>(createNewAccount(lookupValues, isNew, ipMaintenanceGeneralDataView)),
        sortIPFieldData: (value: any, fieldName: IInterestedPartyStateKeys) =>
            dispatch(sortInterestedPartyFieldData(value, fieldName)),
        openIPAccordion: (accordionName: string, componentName: string) =>
            dispatch(openAccordion(accordionName, componentName)),
        closeIPAccordion: (accordionName: string, componentName: string) =>
            dispatch(closeAccordion(accordionName, componentName)),
        undoIPChanges: (ipBaseNumber?: string, accountNumber?: string) =>
            dispatch<any>(undoIPChangesThunk(ipBaseNumber, accountNumber)),
        deleteAccount: (ipBaseNumber: string, account: string, activeTab?: number) =>
            dispatch<any>(deleteAccountThunk(ipBaseNumber, account, activeTab)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        updateIpAgreementsFilterStateField: (value: any, field: IAgreementSearchStateKeys) =>
            dispatch<any>(updateIpAgreementsFilterStateField(value, field)),
        filterIpAgreements: (searchState: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]) =>
            dispatch<any>(filterIpAgreementsThunk(searchState, ipBaseNumber, accountNumber, creationClasses)),
        setEditableFields: (componentFieldName: string, componentInstance: string) =>
            dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        updateEditableField: (value: any, key: string) =>
            dispatch(updateEditableFields(value, key)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) =>
            dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        searchSocIPVersionHistory: (socIPID: number, activeTabItem: ITabReduxItem, formats: FormatFields[], lookups: ILookupDictionary) =>
            dispatch<any>(searchSocIPVersionHistoryThunk(socIPID, activeTabItem, formats,lookups)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        getIPMaintenaceDisplaySettings: () =>
            dispatch<any>(getIPMaintenaceDisplaySettingsThunk()),
        displayRepresentation: (display: boolean) => dispatch<any>(displayRepresentationFlag(display)),
        addNewRepresentation: (representation: IRepresentation, activeTab?: number, activePane?: number) =>
            dispatch<any>(addNewRepresentationThunk(representation, activeTab, activePane)),
        createNewRepresentation: (ipBaseNumber: string, lookups: ILookupDictionary, isNew?: boolean, representationMaintenanceGeneralDataView?: IRepertoireComponentDataItem) =>
            dispatch<any>(newRepresentationThunk(ipBaseNumber, lookups, isNew, representationMaintenanceGeneralDataView)),
        getRepresentationGroups: (ipBaseNumber: string, activeTab?: number) =>
            dispatch<any>(getRepresentationGroupsThunk(ipBaseNumber, activeTab))
    })
)(IPRepresentationPage);