import * as React from "react";
import {  hideModal, showYesNoPrompt, updateComment } from "../redux/reducers/ModalReducer";
import { ILookupDictionary } from "../lookup/types/ILookupDictionary";
import { ITabReduxItem } from "../redux/types/ITabReduxItem";
import { setCurrentPage } from "../redux/reducers/HomeReducer";
import { IEnableModulesConfigurationParametersItem } from "../redux/types/IEnableModulesConfigurationParameterItem";
import { IActiveAccordion } from "../redux/types/IActiveAccordion";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn } from "../redux/reducers/DataIngestionReducer";
import IDroppedFiles from "../redux/types/IDroppedFiles";
import { IComponentDataItem } from "../core/types/IComponentDataItem";
import IMembersPortalComponentDataItem from "../redux/types/IMembersPortalComponentDataItem";
import { IMembersPortalPathInfo } from "./types/IMembersPortalPathInfo";
import { displayRepresentationFlag, searchDataChanged, setDataSource, updateSelectionCriteria } from "../redux/reducers/RepertoireReducer";
import { mp_openTab, mp_removeTab, updateDraftAgreementField, updateEditableFields } from "../redux/reducers/MembersPortalReducer";
import TabsView from "./components/tabsView/TabsView";
import { DataSource } from "../repertoire/types/DataSource";
import { IContributorItem } from "../repertoire/types/IContributorItem";
import { IP } from "../repertoire/types/IP";
import MembersPortalModal from "./components/membersPortalModal/MembersPortalModal";
import FooterContainer from "../core/components/footer/FooterContainer";
import { Redirect } from "react-router-dom";
import { getUserCRMValuesThunk } from "../redux/thunks/AccountThunks";
import { IDraftWorkSearchQuery } from "./types/IDraftWorkSearchQuery";
import { IDraftWorkSearchResult } from "./types/IDraftWorkSearchResult";
import { IRegisteredWorkSearchQuery } from "./types/IRegisteredWorkSearchQuery";
import { IRegisteredWorkSearchResult } from "./types/IRegisteredWorkSearchResult";
import { IAllRegisteredWorkSearchQuery } from "./types/IAllRegisteredWorkSearchQuery";
import { IAllRegisteredWorkSearchResult } from "./types/IAllRegisteredWorkSearchResult";
import { IDraftSetListSearchQuery } from "./types/IDraftSetListSearchQuery";
import { IDraftSetListSearchResult } from "./types/IDraftSetListSearchResult";
import CleanUpJobMonitoringService from "./CleanUpJobMonitoringService";
import { IDraftAgreementSearchQuery } from "./types/IDraftAgreementSearchQuery";
import { IRegisteredAgreementSearchQuery } from "./types/IRegisteredAgreementSearchQuery";
import { FormatFields } from "../redux/types/FormatFields";
import { IIPsSearchQuery } from "../repertoire/types/IIPsSearchQuery";
import { IIPsSearchResult } from "../repertoire/types/IIPsSearchResult";
import { IWorksSearchResult } from "../repertoire/types/IWorksSearchResult";
import { IAgreementState, IAgreementStateKeys } from "../redux/types/IAgreementState";
export interface IMembersPortalPageProps {
    pathInfo: IMembersPortalPathInfo;
    setDataSource: typeof setDataSource;
    showModal: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    )=>void;
    hideModal: typeof hideModal;
    updateModalComment: typeof updateComment;
    displayModal: boolean;
    loadMembersPortalFromPath: (pathInfo: IMembersPortalPathInfo, currentUserNameNumber: string, location: Location, tabs?: ITabReduxItem[], lookups?: ILookupDictionary) => void;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    displayModalCloseButton: boolean;
    modalTitle: string;
    getMembersPortalFields: () => void;
    tabs: ITabReduxItem[];
    removeTab: typeof mp_removeTab;
    openTab: typeof mp_openTab;
    setCurrentPage: typeof setCurrentPage;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    membersPortalSideMenuDataView: IMembersPortalComponentDataItem;
    workNotFoundViewData: IMembersPortalComponentDataItem;
    loadingViewData: IMembersPortalComponentDataItem;
    yesNoPromptViewData: IMembersPortalComponentDataItem;
    membersPortalPageDataView: IMembersPortalComponentDataItem;
    tabsViewData: IMembersPortalComponentDataItem;
    dataGridTableData: IMembersPortalComponentDataItem;
    containerDetailsWindowComponentData: IComponentDataItem;
    dataSource: string;
    agreementsDataSource: string;
    productDataSource: string;
    lookupEntities: ILookupDictionary;
    reduxStorageLoaded: boolean;
    activeTab: number;
    closeTabDisabled: boolean;
    searchSuccessful: boolean;
    showYesNoPrompt?: typeof showYesNoPrompt;
    currentUser?: string;
    editableFields?: string[];
    updateEditableFields: typeof updateEditableFields;
    expandedWorkResults?: number[];
    expandedProductResults?: number[];
    expandAll?: boolean;
    expandWorkResult?: (workResult: number) => void;
    expandProductResult?: (productResult: number) => void;
    expandAllResults?: () => void;
    modalMessage: string;
    enabledModules: IEnableModulesConfigurationParametersItem[];
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    component?: string;
    lookupValues: ILookupDictionary;
    searchDataChanged: typeof searchDataChanged;
    getSources: () => void;
    updateSelectionCriteria: typeof updateSelectionCriteria;
    isReadOnlyModal?: boolean;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    isRepertoireModule?: boolean;
    getLookupEntities?: () => void;
    displayRepresentation: typeof displayRepresentationFlag;
    updateWorkContributorIP: (dataSource: DataSource, workContributor: IContributorItem, ip: IP) => void;
    getUserCRMValuesThunk: () => void;
    currentUserNameNumber: string;
    searchDraftWorks?: (query: IDraftWorkSearchQuery) => void;
    searchDraftAgreements?: (query: IDraftAgreementSearchQuery) => void;
    addNewDraftAgreement?: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    searchRegisteredAgreements?: (query: IRegisteredAgreementSearchQuery) => void;
    addNewRegisteredAgreement?: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, registeredAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    searchRegisteredWorks?: (query: IRegisteredWorkSearchQuery) => void;
    draftWorkSearchResults: IDraftWorkSearchResult[];
    registeredWorkSearchResults?: IRegisteredWorkSearchResult[];
    addWorkToSetList?: (work: IDraftWorkSearchResult) => void;
    addSetListToLivePerformance?: (setList: IDraftSetListSearchResult) => void;
    allRegisteredWorkSearchResults: IAllRegisteredWorkSearchResult[];
    searchAllRegisteredWorks: (query: IAllRegisteredWorkSearchQuery) => void;
    searchDraftSetLists?: (query: IDraftSetListSearchQuery) => void;
    draftSetListSearchResults?: IDraftSetListSearchResult[];
    getDistributions: () => void;
    saveContributorComment: (contributorID: number, comments: string) => void;
    searchIPs?: (query: IIPsSearchQuery) => void;
    draftAgreementIPSearchResult?:IIPsSearchResult[];
    updateDraftAgreementField?: typeof updateDraftAgreementField;
    draftAgreementWorksSearchResult?:IWorksSearchResult[];
    updateDraftAgreementWorkField?:(value: any, fieldName: IAgreementStateKeys, index?: number)=>void;
    modalIndex?:number;
    modalKey?:string;
    agreement: IAgreementState;
    defaultTerritories?:any;
    getCustomerDefaultTerritories?:()=> void;
}

interface IMembersPortalPageState {
    loaded: boolean;
}

export default class MembersPortalPage extends React.Component<
    IMembersPortalPageProps, IMembersPortalPageState
> {
    viewData;
    constructor(props: IMembersPortalPageProps) {
        super(props);
        this.state = { loaded: false };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            getMembersPortalFields,
            searchViewData,
            pathInfo,
            loadMembersPortalFromPath,
            getSources,
            lookupValues,
            getLookupEntities,
            currentUserNameNumber,
            getDistributions,
            tabs,modalIndex,modalProps,
            getCustomerDefaultTerritories
        } = this.props;

        if (!searchViewData || searchViewData.fields.length === 0) {
            getMembersPortalFields && getMembersPortalFields();
        }
        if (pathInfo && pathInfo.submodule) {
            loadMembersPortalFromPath && loadMembersPortalFromPath(pathInfo, currentUserNameNumber, window.location, tabs, lookupValues);
        }
        if (getSources) {
            getSources();
        }

        if (!lookupValues || Object.keys(lookupValues).length == 0) {
            getLookupEntities && getLookupEntities();
        }
        if (getDistributions) {
            getDistributions();
        }
        if(getCustomerDefaultTerritories){
            getCustomerDefaultTerritories();
        }
    }

    componentDidUpdate(prevProps: IMembersPortalPageProps, prevState: IMembersPortalPageState) {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.membersPortalPageDataView) {
                return;
            }

            const { membersPortalPageDataView: { fields } } = this.props;

            fields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === fields.length) {
                    this.setState({ loaded: true });
                }
            });
        }

        const { pathInfo, loadMembersPortalFromPath, tabs, currentUserNameNumber } = this.props;
        const prevPath = prevProps.pathInfo && prevProps.pathInfo.submodule;
        const newPath = pathInfo && pathInfo.submodule;
        if (newPath && newPath !== prevPath) {
            loadMembersPortalFromPath(pathInfo, currentUserNameNumber, window.location, tabs);
        }
    }

    renderTabsView = () => {
        const {
            tabs, tabsViewData, removeTab, openTab, activeTab, closeTabDisabled, setCurrentPage, showYesNoPrompt, hideModal, displayRepresentation
        } = this.props;
        if (tabs && tabs.length > 0)
            return (
                <div className="row">
                    <TabsView
                        tabs={tabs}
                        tabsViewData={tabsViewData}
                        removeTab={removeTab}
                        activeTab={activeTab}
                        openTab={openTab}
                        closeTabDisabled={closeTabDisabled}
                        hideModal={hideModal}
                        showYesNoPrompt={showYesNoPrompt}
                        displayRepresentation={displayRepresentation}
                        setCurrentPage={setCurrentPage}
                        portalType="MembersPortal"
                    />
                </div>
            )
        else return <div></div>
    }    


    render() {
        const { displayModal, currentUserNameNumber } = this.props;
        let contentClassName: string = "container-fluid pageContent active";
        if (!currentUserNameNumber) {
            getUserCRMValuesThunk();
        }

        return (
            <>
            <CleanUpJobMonitoringService />
                {currentUserNameNumber ? <div className="wrapperPage">
                    <div className={contentClassName}>
                        {this.renderTabsView()}
                        <FooterContainer />
                    </div>
                    <a target="_blank" id="downloadRepertoireAttachments"></a>
                    {displayModal ? (<MembersPortalModal {...this.props} />) : null}

                </div> :
                    <Redirect to={'/'} />}
            </>
        );
    }
}
