import * as React from "react";
import { IRepertoireField } from "../../../types/IRepertoireField";

export interface IWorkNotFoundViewProps {
    fields: IRepertoireField[];
    workId: number;
}

const WorkNotFoundView: React.SFC<IWorkNotFoundViewProps> = ({ fields, workId }) => {
    const message: string = fields[0].data;
    return (
        <div className="workNotFoundView">
            <div>{message}{workId}</div>
        </div>
    );
};

export default WorkNotFoundView;