import * as React from 'react'
import { IComponentDataItem } from '../../../core/types/IComponentDataItem';
import { updateJobSearchStateField } from '../../../redux/reducers/HomeReducer';
import { updateIpAgreementsFilterStateField, updateIpRepresentationFilterStateField } from '../../../redux/reducers/RepertoireReducer';
import { IP_LICENSING_REPRESENTATION_KEY, IP_MAINTENANCE_REPRESENTATION_TIME_RANGE_SELECTOR, IP_MAINTENANCE_TIME_RANGE_SELECTOR, IP_REPRESENTATION_KEY } from '../../../repertoire/Consts';
import { AGREEMENT_CUSTOM_DATE_RANGE, AGREEMENT_DATE_FROM, AGREEMENT_DATE_RANGE, AGREEMENT_DATE_TO, IAgreementSearchState } from '../../../repertoire/types/IAgreementSearchState';
import { OPTIONS } from '../../Consts';
import {
    IJobSearchState,
    JOB_CUSTOM_DATE_RANGE,
    JOB_DATE_FROM,
    JOB_DATE_RANGE,
    JOB_DATE_TO
} from '../../types/IJobSearchState';
import {
    CUSTOM_RANGE_FIELD,
    FROM_FIELD,
    SHOW_AGREEMENTS_FROM_FIELD,
    SHOW_IP_REPRESENTATION_FROM_FIELD,
    SHOW_JOBS_FROM_FIELD,
    TO_FIELD,
    WITH_REFERENCE
} from './TimeRangeSelectorFields';
import { IRepresentationSearchState, REPRESENTATION_CUSTOM_DATE_RANGE, REPRESENTATION_DATE_FROM, REPRESENTATION_DATE_RANGE, REPRESENTATION_DATE_TO } from '../../../repertoire/types/IRepresentationSearchState';

interface ITimeRangeSelectorProps {
    timeRangeSelectorData: IComponentDataItem;
    updateJobSearchStateField: typeof updateJobSearchStateField;
    jobSearchState: IJobSearchState;
    componentDataName?: string;
    agreementSearchState?: IAgreementSearchState;
    updateIpAgreementsFilterStateField?: typeof updateIpAgreementsFilterStateField;
    representationSearchState?: IRepresentationSearchState;
    updateIpRepresentationFilterStateField?:typeof updateIpRepresentationFilterStateField;
    timeRangeOptions: { code: string, description: string }[];
    dateRangeDefault: string;
    componentName?:string;
}

interface ITimeRangeSelectorState {
    loaded: boolean
}

export class TimeRangeSelector extends React.Component<ITimeRangeSelectorProps, ITimeRangeSelectorState>{
    viewData;
    constructor(props: ITimeRangeSelectorProps) {
        super(props);
        this.state = { loaded: false };
        this.viewData = {};
    }

    componentDidUpdate() {
        this.loadData();
    }

    componentDidMount() {
        const { dateRangeDefault, componentDataName, updateIpAgreementsFilterStateField, updateJobSearchStateField,updateIpRepresentationFilterStateField,componentName } = this.props
        this.loadData();
        const isAgreement: boolean = (componentDataName && componentDataName.length > 0 && componentDataName === IP_MAINTENANCE_TIME_RANGE_SELECTOR)
        const isRepresentation: boolean = (componentDataName && componentDataName.length > 0 && componentDataName === IP_MAINTENANCE_REPRESENTATION_TIME_RANGE_SELECTOR)
        
        if (isAgreement && componentName===IP_MAINTENANCE_TIME_RANGE_SELECTOR) {
            
            updateIpAgreementsFilterStateField(dateRangeDefault, AGREEMENT_DATE_RANGE)
        }
        else if (isRepresentation && componentName==='ipRepresentation'){
            updateIpRepresentationFilterStateField(dateRangeDefault, REPRESENTATION_DATE_RANGE)
        }
    }

    loadData() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.timeRangeSelectorData) {
                return;
            }

            const { timeRangeSelectorData: { fields } } = this.props;
            if (fields) {

                fields.forEach(item => {
                    this.viewData[item.name] = item.data;

                    if (Object.keys(this.viewData).length === fields.length) {
                        this.setState({ loaded: true });
                    }
                });
            }
        }
    }

    render() {
        const { jobSearchState
                , updateJobSearchStateField
                , componentDataName
                , agreementSearchState
                , representationSearchState
                , updateIpAgreementsFilterStateField
                , updateIpRepresentationFilterStateField
                , dateRangeDefault
            } = this.props;
        const { loaded } = this.state;
        if (!loaded)
            return <div className="loading"/>

        const renderOptions = () => {
            const options = [];
            const { timeRangeOptions } = this.props;
            timeRangeOptions.map((t) => options.push(<option value={t.code} key={t.code}>{t.description}</option>));
            return options;
        }
        const dateToString = (date: Date): string => {
            return date ? date.toString() : "";
        }
        const isAgreement: boolean = (componentDataName &&  componentDataName.length > 0 &&  componentDataName === IP_MAINTENANCE_TIME_RANGE_SELECTOR)
        const isRepresentation: boolean = (componentDataName &&  componentDataName.length > 0 &&  componentDataName === IP_MAINTENANCE_REPRESENTATION_TIME_RANGE_SELECTOR)

        const showFromFieldText = isAgreement ? this.viewData[SHOW_AGREEMENTS_FROM_FIELD] : this.viewData[SHOW_JOBS_FROM_FIELD];
        const showIPFromFieldText = isRepresentation ? this.viewData[SHOW_IP_REPRESENTATION_FROM_FIELD] : this.viewData[SHOW_JOBS_FROM_FIELD];
        let dateRangeValue = "";
        if (isAgreement) {
            dateRangeValue = agreementSearchState.dateRange ?? dateRangeDefault;
        }
        else if(isRepresentation){
            dateRangeValue = representationSearchState.dateRange ?? dateRangeDefault;
        }
        else {
            dateRangeValue = jobSearchState.dateRange ?? dateRangeDefault;
        }
        return (
            <>
            { isAgreement ? (<div className="row margin-bottomMedium">
                <div className="form-group col-md-3 col-sm-6">
                    <label>{showFromFieldText}</label>
                   <div>
                        <select value={dateRangeValue}
                            onChange={event => isAgreement ? updateIpAgreementsFilterStateField(event.target.value, AGREEMENT_DATE_RANGE) : updateJobSearchStateField(event.target.value, JOB_DATE_RANGE)}
                            disabled={isAgreement ? agreementSearchState.customDateRange : jobSearchState.customDateRange}>
                        {renderOptions()}
                    </select>
                    </div>
                </div>

                <div className="form-group col-md-3 col-sm-6 col-xs-12">
                    <label>{this.viewData[CUSTOM_RANGE_FIELD]}</label>
                    <div className="checkboxContainer">
                        <label className="subContainer">
                            <input type="checkbox" id="customRange" 
                                checked={isAgreement ? agreementSearchState.customDateRange : jobSearchState.customDateRange}
                                onChange={(e) => isAgreement ? updateIpAgreementsFilterStateField(e.currentTarget.checked,AGREEMENT_CUSTOM_DATE_RANGE) : updateJobSearchStateField(e.currentTarget.checked, JOB_CUSTOM_DATE_RANGE)} />
                            <span className="inputCheckbox"></span>
                            <div className="checkboxLabel">
                                Yes
                                    </div>
                        </label>
                    </div>
                </div>

                <div className="form-group col-md-3  col-sm-6 col-xs-12 disabledItem">
                    <label>{this.viewData[FROM_FIELD]}</label>
                    <input type="date" id="dateFrom"
                        name="dateFrom"
                        disabled={isAgreement ? !agreementSearchState.customDateRange : !jobSearchState.customDateRange}
                        value={dateToString(isAgreement ? agreementSearchState.dateFrom : jobSearchState.dateFrom) }
                        onChange={event => isAgreement ? updateIpAgreementsFilterStateField(event.target.value,AGREEMENT_DATE_FROM) : updateJobSearchStateField(event.target.value, JOB_DATE_FROM)}
                    />
                </div>
                <div className="form-group col-md-3  col-sm-6 col-xs-12 disabledItem">
                    <label>{this.viewData[TO_FIELD]}</label>
                    <input type="date" id="dateTo" name="dateTo"
                        disabled={ isAgreement ? !agreementSearchState.customDateRange : !jobSearchState.customDateRange}
                        value={dateToString(isAgreement ? agreementSearchState.dateTo : jobSearchState.dateTo)}
                        onChange={event => isAgreement ? updateIpAgreementsFilterStateField(event.target.value ,AGREEMENT_DATE_TO) : updateJobSearchStateField(event.target.value, JOB_DATE_TO)}
                    />
                </div>
            </div>) : 
            <div className="row margin-bottomMedium">
            <div className="form-group col-md-3 col-sm-6">
                <label>{showIPFromFieldText}</label>
               <div>
                    <select value={dateRangeValue}
                        onChange={event => isRepresentation ? updateIpRepresentationFilterStateField(event.target.value, REPRESENTATION_DATE_RANGE) : updateJobSearchStateField(event.target.value, JOB_DATE_RANGE)}
                        disabled={isRepresentation ? representationSearchState.customDateRange : jobSearchState.customDateRange}>
                    {renderOptions()}
                </select>
                </div>
            </div>

            <div className="form-group col-md-3 col-sm-6 col-xs-12">
                <label>{this.viewData[CUSTOM_RANGE_FIELD]}</label>
                <div className="checkboxContainer">
                    <label className="subContainer">
                        <input type="checkbox" id="customRange" 
                            checked={isRepresentation ? representationSearchState.customDateRange : jobSearchState.customDateRange}
                            onChange={(e) => isRepresentation ? updateIpRepresentationFilterStateField(e.currentTarget.checked,REPRESENTATION_CUSTOM_DATE_RANGE) : updateJobSearchStateField(e.currentTarget.checked, JOB_CUSTOM_DATE_RANGE)} />
                        <span className="inputCheckbox"></span>
                        <div className="checkboxLabel">
                            Yes
                                </div>
                    </label>
                </div>
            </div>

            <div className="form-group col-md-3  col-sm-6 col-xs-12 disabledItem">
                <label>{this.viewData[FROM_FIELD]}</label>
                <input type="date" id="dateFrom"
                    name="dateFrom"
                    disabled={isRepresentation ? !representationSearchState.customDateRange : !jobSearchState.customDateRange}
                    value={dateToString(isRepresentation ? representationSearchState.dateFrom : jobSearchState.dateFrom) }
                    onChange={event => isRepresentation ? updateIpRepresentationFilterStateField(event.target.value,REPRESENTATION_DATE_FROM) : updateJobSearchStateField(event.target.value, JOB_DATE_FROM)}
                />
            </div>
            <div className="form-group col-md-3  col-sm-6 col-xs-12 disabledItem">
                <label>{this.viewData[TO_FIELD]}</label>
                <input type="date" id="dateTo" name="dateTo"
                    disabled={ isRepresentation ? !representationSearchState.customDateRange : !jobSearchState.customDateRange}
                    value={dateToString(isRepresentation ? representationSearchState.dateTo : jobSearchState.dateTo)}
                    onChange={event => isRepresentation ? updateIpRepresentationFilterStateField(event.target.value ,REPRESENTATION_DATE_TO) : updateJobSearchStateField(event.target.value, JOB_DATE_TO)}
                />
            </div>
        </div>}
            </>
        );
    }
}