import { ITreeNodeData } from '../../treeview/types/TreeNodeData';
import { IFileSystem } from '../types/IFileSystem';

export class TreeNodeBuilder {
    public static build(systems: IFileSystem[]): ITreeNodeData<IFileSystem>[] {
        if (systems === null) { return null; }

        let id = 0;
        const nodes: ITreeNodeData<IFileSystem>[] = [];

        if (systems) {
            systems.map(fs => nodes.push(TreeNodeBuilder.buildFilesystem(fs, id++)));
        }

        return nodes;
    }

    private static buildFilesystem(fs: IFileSystem, id: number): ITreeNodeData<IFileSystem> {
        if (fs === null) { return null; }

        const node: ITreeNodeData<IFileSystem> = {
            name: fs.name,
            children: [],
            level: 1,
            id: id,
            active: false,
            object: fs,
            visible: true,
            icon: "icon node-icon ms-Icon ms-Icon--FabricFolder",
            isExpanded: false
        };

        return node;
    }
}