import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { resetPagination, updatePagination } from "../../../../redux/reducers/RepertoireReducer";
import { Dispatch } from "redux";
import UnPaidClaimsWorkSearchModalView from "./UnpaidClaimsWorkSearchModalView";
import { claimUnpaidUsageThunk } from "../../../../redux/thunks/MembersPortalThunks";
import { IClaimUnidentifiedClaimModal } from "../../../../membersportal/types/IClaimUnidentifiedClaimModal";

export default connect(
    (state: IAppState) => ({
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'unpaidClaimsMembersWorkSearch')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'unpaidClaimsMembersWorkSearch')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'unpaidClaimsMembersWorkSearch')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        continuationToken: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].continuationToken ? state.membersPortal.tabs[state.membersPortal.activeTab].continuationToken : undefined,
        currentUserNameNumber: state.account.currentUserNameNumber,
        fields: state.membersPortal.componentData.UnpaidClaimsRegisteredWorksSearch.fields,
    }), 
    (dispatch: Dispatch) => ({
        claimUnpaidUsage: (query: IClaimUnidentifiedClaimModal) =>
        dispatch<any>(claimUnpaidUsageThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
    })
    )
(UnPaidClaimsWorkSearchModalView);