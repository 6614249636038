import React from "react";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { Field, Form, Formik } from "formik";
import { RECORDING_COMPANY, RECORDING_DETAILS, RECORDING_PRODUCT_COUNTRY_OF_ORIGIN, RECORDING_PRODUCT_FORMAT, RECORDING_PRODUCT_LABEL, RECORDING_PRODUCT_MUSIC_DURATION, RECORDING_PRODUCT_PRODUCT_CATALOGUE_NUMBER, RECORDING_PRODUCT_RELEASE_DATE, RECORDING_PRODUCT_SCHEME_TYPE, RECORDING_PRODUCT_TOTAL_DURATION, RECORDING_PRODUCT_TYPES_OF_RELEASE, REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";
import { CustomDurationPicker, CustomInputComponent } from "../../works/components/workMaintenanceGeneralDataView/WorkMaintenanceGeneralDataViewFormik";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { SizedDropdownDataInputFormik, makeOptionsList } from "../../components/dropdownDataInput/DropdownDataInputFormik";
import DatePickerFormikField from "../../../membersportal/unpaidClaims/DatePickerFormik";
import { TERRITORY_LOOKUP } from "../../../lookup/Consts";
import * as Yup from 'yup';
import { updateProductField, updateProductFields } from "../../../redux/reducers/RepertoireReducer";
import { IProductState } from "../../../redux/types/IProductState";
import { InputFieldFormik } from "../../works/components/workMaintenanceGeneralDataView/InputFieldFormik";

const productRecordingTabSchema = (fieldData) => {
    const schema = Yup.object().shape(
        {
            recordingCompany: fieldData?.recordingCompany?.isMandatory ? Yup.object().shape({
                data: Yup.string().nullable(),
            }
            ).test(
                'empty-check',
                'Recording company can not be empty',
                val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                    data: Yup.string().nullable(),
                }).nullable().notRequired(),
            format: fieldData?.format?.isMandatory ? Yup.object().shape({
                data: Yup.string().nullable(),
            }
            ).test(
                'empty-check',
                'format can not be empty',
                val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                    data: Yup.string().nullable(),
                }).nullable().notRequired(),
            typesOfRelease: fieldData?.typesOfRelease?.isMandatory ? Yup.object().shape({ data: Yup.string().nullable() }
            ).test(
                'empty-check',
                'format can not be empty',
                val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                    data: Yup.string().nullable(),
                }).nullable().notRequired(),
            schemeType: fieldData?.schemeType?.isMandatory ? Yup.object().shape({ data: Yup.string().nullable() }
            ).test(
                'empty-check',
                'format can not be empty',
                val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                    data: Yup.string().nullable(),
                }).nullable().notRequired(),
            countryOfOrigin: fieldData?.countryOfOrigin?.isMandatory ? Yup.object().shape({ data: Yup.string().nullable() }
            ).test(
                'empty-check',
                'format can not be empty',
                val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                    data: Yup.string().nullable(),
                }).nullable().notRequired(),
            musicDuration: fieldData?.productMusicDuration?.isMandatory ? Yup.string().test(
                'empty-check',
                'Duration can not be empty',
                string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
            totalDuration: fieldData?.productTotalDuration?.isMandatory ? Yup.string().test(
                'empty-check',
                'Duration can not be empty',
                string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
            releaseDate: fieldData?.releaseDate?.isMandatory ? Yup.string().test(
                'empty-check',
                'Source Type can not be empty',
                string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
            productCatalogueNumber: fieldData?.productCatalogueNumber?.isMandatory ? Yup.string().test(
                'empty-check',
                'Product Catalogue number can not be empty',
                string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
            label: fieldData?.label?.isMandatory ? Yup.string().test(
                'empty-check',
                'Label can not be empty',
                string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
        },
    )
    return schema;
}

const RECORD_COMPANY_LOOKUP_TYPE = 'RecordCompany';
const RECORD_FORMAT_LOOKUP_TYPE = 'RecordingFormat';
const RECORDING_RELEASE_TYPE_LOOKUP_TYPE = 'RecordingReleaseType';
const RECORDING_SCHEME_TYPE = "RecordingSchemeType";

export const formatProductRecordingDetailsForState = (values, recordingDetails) => {
    const formatProductRecordingDetailsForState = {
        ...recordingDetails,
        recordCompany: values?.recordingCompany?.data ? values.recordingCompany.data : null,
        label: values.label,
        recordingFormat: values?.format?.data ? values.format.data : null,

        recordingReleaseType: values?.typesOfRelease?.data ? values.typesOfRelease.data : null,
        recordingSchemeType: values?.schemeType?.data ? values.schemeType.data : null,
        countryOfOrigin: values?.countryOfOrigin?.data ? values.countryOfOrigin.data : null,

        musicDuration: values.musicDuration,
        totalDuration: values.totalDuration,
        releaseDate: values.releaseDate === '' ? null : values.releaseDate,
        productCatalogueNumber: values?.productCatalogueNumber === '' ? null : values.productCatalogueNumber,
    }
    return formatProductRecordingDetailsForState;
}
export interface IProductMaintenanceRecordingSectionProps {
    dataGridTableData?: IRepertoireComponentDataItem;
    lookupValues?: ILookupDictionary;
    isFormVisible: boolean;
    productRecordingFormikRef: any;
    updateProductFields: typeof updateProductFields;
    product?: IProductState;
    setIsChangeRecordingForm: (value: boolean) => void;
    changeData: typeof updateProductField;
}

const DropDownField = (viewData, name, lookupValues, componentInstance, isReadOnly, errors, onChange, arrayName) => {
    let lookUpValuesLocal = getLookupValues(componentInstance, lookupValues);
    return <SizedDropdownDataInputFormik
        label={viewData.data}
        fieldName={name}
        useComboBoxAsMenuWidth={true}
        options={lookUpValuesLocal}
        allowNull={true}
        readOnly={isReadOnly}
        isHidden={!viewData.data}
        errors={errors}
        onChange={onChange}
    />
}

const ProductMaintenanceRecordingSection: React.FC<IProductMaintenanceRecordingSectionProps> = ({
    product,
    dataGridTableData,
    lookupValues,
    isFormVisible,
    productRecordingFormikRef,
    updateProductFields,
    setIsChangeRecordingForm,
    changeData,
}) => {

    const [fields, setFields] = React.useState([]);
    const [viewData, setViewData]: any = React.useState({});

    React.useEffect(() => {
        const filteredFields = dataGridTableData?.fields?.filter(field => field.componentInstance === RECORDING_DETAILS);
        setFields(filteredFields);
    }, [
        dataGridTableData
    ]);

    React.useEffect(() => {
        if (dataGridTableData.fields) {
            const visibleFields = dataGridTableData.fields.filter(field => field.componentInstance === RECORDING_DETAILS);
            const data = {};
            visibleFields.forEach(item => {
                data[item.name] = item;

                if (Object.keys(data).length === visibleFields.length) {
                    setViewData(data);
                }
            });
        }
    }, [dataGridTableData])

    React.useEffect(() => {

        return () => {
            productRecordingFormikRef?.current?.values && handleSubmit(productRecordingFormikRef.current.values)
        }
    }, [])

    const isFieldPresent = (fieldName, viewData) => {
        return viewData && viewData.name && viewData.name === fieldName;
    }

    const handleSubmit = (values) => {
        updateProductFields({
            isProductRecordDetails: true,
            recordingDetails: formatProductRecordingDetailsForState(values, product.recordingDetails)
        });
    }
    const handleDataChange = () => {
        setIsChangeRecordingForm(true);
    }

    const handleDataChangeCatalogNumber = (event) => {
        changeData(event.target.value, 'productCatalogueNumber');
        setIsChangeRecordingForm(true);
    }

    const getInitialValueDropDown = (initialValue, lookupName) => {
        const lookUpValuesLocal = getLookupValues(lookupName, lookupValues);
        const sourceOptions = makeOptionsList(true, lookupName, lookUpValuesLocal, false)
        const foundOption = sourceOptions.find(option => option.data === initialValue || option.text === initialValue);
        return foundOption ? foundOption : {}
    }

    return <div className="AccordionContainer">
        <Formik
            enableReinitialize
            validateOnChange
            initialValues={{
                recordingCompany: product?.recordingDetails?.recordCompany ? getInitialValueDropDown(product.recordingDetails.recordCompany, RECORD_COMPANY_LOOKUP_TYPE) : { data: '' },
                label: product?.recordingDetails?.label ? product?.recordingDetails?.label : '',
                format: product?.recordingDetails?.recordingFormat ? getInitialValueDropDown(product.recordingDetails.recordingFormat, RECORD_FORMAT_LOOKUP_TYPE) : { data: '' },

                typesOfRelease: product?.recordingDetails?.recordingReleaseType ? getInitialValueDropDown(product.recordingDetails.recordingReleaseType, RECORDING_RELEASE_TYPE_LOOKUP_TYPE) : { data: '' },
                schemeType: product?.recordingDetails?.recordingSchemeType ? getInitialValueDropDown(product.recordingDetails.recordingSchemeType, RECORDING_SCHEME_TYPE) : { data: '' },
                countryOfOrigin: product?.recordingDetails?.countryOfOrigin ? getInitialValueDropDown(product.recordingDetails.countryOfOrigin, TERRITORY_LOOKUP) : { data: '' },

                musicDuration: product?.recordingDetails?.musicDuration ? product?.recordingDetails?.musicDuration : 0,
                totalDuration: product?.recordingDetails?.totalDuration ? product?.recordingDetails?.totalDuration : 0,
                releaseDate: product?.recordingDetails?.releaseDate ? product?.recordingDetails?.releaseDate : '',

                productCatalogueNumber: product?.productCatalogueNumber ? product?.productCatalogueNumber : '',
            }}
            validate={(values) => {
                const schema = productRecordingTabSchema(viewData);
                try {
                    schema.validateSync(values, { abortEarly: false });
                    return {};
                } catch (errors: any) {
                    const formikErrors = {};
                    errors && errors.inner && errors.inner.forEach((error) => {
                        formikErrors[error.path] = error.message;
                    });
                    return formikErrors;
                }
            }}
            onSubmit={(values, { setSubmitting }) => {
                handleSubmit(values);
                setSubmitting(false);
            }}
        >
            {({ values, submitForm, errors, validateForm }) => {
                productRecordingFormikRef.current = {
                    values: values,
                    submitForm: submitForm,
                    validateForm: validateForm,
                    errors: errors,
                }
                return (
                    <Form>
                        {isFormVisible && <div className="formItemsContainer flexColumn">

                            <div className="row">

                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_RELEASE_DATE, field) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                        <label>{field.data}</label>
                                        <DatePickerFormikField label={field.data} name={RECORDING_PRODUCT_RELEASE_DATE} isReadonly={false} onChange={handleDataChange} />
                                    </div>
                                })}


                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_COMPANY, field) && DropDownField(field, RECORDING_COMPANY, lookupValues, RECORD_COMPANY_LOOKUP_TYPE, false, errors, handleDataChange, undefined);
                                })}
                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_LABEL, field) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem flexAlignItemsCenter`}>
                                        <label>{field.data}</label>
                                        <Field
                                            label={field.data}
                                            name={RECORDING_PRODUCT_LABEL}
                                            component={CustomInputComponent}
                                            disabled={false}
                                            onClick={handleDataChange}

                                        />
                                    </div>

                                })}

                            </div>

                            <div className="row">
                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_PRODUCT_CATALOGUE_NUMBER, field) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                        <label>{field.data}</label>
                                        <InputFieldFormik
                                            label={field.data}
                                            name={RECORDING_PRODUCT_PRODUCT_CATALOGUE_NUMBER}
                                            disabled={false}
                                            onClick={handleDataChange}
                                            onChange={handleDataChangeCatalogNumber}
                                        />
                                    </div>
                                })}

                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_FORMAT, field) && DropDownField(field, RECORDING_PRODUCT_FORMAT, lookupValues, RECORD_FORMAT_LOOKUP_TYPE, false, errors, handleDataChange, undefined);
                                })}


                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_TYPES_OF_RELEASE, field) && DropDownField(field, RECORDING_PRODUCT_TYPES_OF_RELEASE, lookupValues, RECORDING_RELEASE_TYPE_LOOKUP_TYPE, false, errors, handleDataChange, undefined)
                                })}

                            </div>

                            <div className="row">
                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_SCHEME_TYPE, field) && DropDownField(field, RECORDING_PRODUCT_SCHEME_TYPE, lookupValues, RECORDING_SCHEME_TYPE, false, errors, handleDataChange, undefined)
                                })}



                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_TOTAL_DURATION, field) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                        <label>{field.data}</label>
                                        <Field
                                            name={'totalDuration'}
                                            component={CustomDurationPicker}
                                            disabled={false}
                                            isFormik={true}
                                            onClick={handleDataChange}

                                        />
                                    </div>
                                })}
                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_MUSIC_DURATION, field) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                        <label>{field.data}</label>
                                        <Field
                                            name={'musicDuration'}
                                            component={CustomDurationPicker}
                                            disabled={false}
                                            isFormik={true}
                                            onClick={handleDataChange}

                                        />
                                    </div>
                                })}




                            </div>
                            <div className="row">
                                {fields && fields.map(field => {
                                    return isFieldPresent(RECORDING_PRODUCT_COUNTRY_OF_ORIGIN, field) && DropDownField(field, RECORDING_PRODUCT_COUNTRY_OF_ORIGIN, lookupValues, TERRITORY_LOOKUP, false, errors, handleDataChange, undefined)
                                })}
                            </div>

                        </div>}
                    </Form>)
            }}
        </Formik>
    </div>

}

export default ProductMaintenanceRecordingSection;