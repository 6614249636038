import { AllocationDetailRowType, AllocationHeaderRowType } from "../../adjustments/components/allocationSelectionView/AllocationSelectionView";
import { IUsageAllocationCuesheetDetail } from "../../types/usageTypes/IUsageAllocationCuesheetDetail";
import { IUsageAllocations } from "../../types/usageTypes/IUsageAllocations";


export class UsageAllocationMapper {

    public static mapAllocationUsageToHeaderRowType(allocationUsage: IUsageAllocations, key: number): AllocationHeaderRowType {
        if (!allocationUsage)
            return null;

        const {
            allocationStatus,
            allocationDate,
            distribution,
            description,
            poolCode,
            segment,
            amount,
            productID1,
            productName,
            allocationCuesheetDetails
        } = allocationUsage;

        const allocationHeaderRowType: AllocationHeaderRowType = {
            key: key.toString(),
            Status: allocationStatus,
            AllocationDate: allocationDate,
            Distribution: distribution,
            Description: description,
            PoolCode: poolCode,
            Segment: segment,
            TotalAmount: amount,
            ProductNumber: productID1,
            ProductName: productName,
            UsageDetails: allocationCuesheetDetails?.map(all => this.mapAllocationUsageDetailToDetailRowType(all))
        }

        return allocationHeaderRowType;
    }

    public static mapAllocationUsageDetailToDetailRowType(allocationUsageDetail: IUsageAllocationCuesheetDetail): AllocationDetailRowType {
        if (!allocationUsageDetail)
            return null;

        const {
            workName,
            workID,
            workDuration,
            amount,
            isIntray
        } = allocationUsageDetail;

        const allocationDetailRowType: AllocationDetailRowType = {
            key: 0,
            WorkName: workName,
            WorkID: workID,
            WorkDuration: workDuration,
            Amount: amount ?? "",
            IsIntray: isIntray ? "Yes" : "No"
        }

        return allocationDetailRowType;
    }




}
