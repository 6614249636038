import * as React from "react";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { setEditableFields } from "../../../../redux/reducers/MembersPortalReducer";
import { SizedDropdownDataInput } from "../../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { LANGUAGE_LOOKUP, WORK_GENRE_LOOKUP, PORTAL_DEFAULT_VALUES_LOOKUP } from "../../../../lookup/Consts";
import { SizedTextDataInput } from "../../../../repertoire/components/textDataInput/TextDataInput";
import { ADD_NEW_ACTION, DRAFT_WORKS_DURATION_STATE_KEY, DRAFT_WORKS_GENRE_STATE_KEY, DRAFT_WORKS_INSTRUMENTATION_STATE_KEY, DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY, DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY, DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY, DRAFT_WORKS_LANGUAGE_STATE_KEY, DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY, DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY, DRAFT_WORKS_TITLES_STATE_KEY, DRAFT_WORKS_WORK_PROPERTIES } from "../../../Consts";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import { SizedCheckboxDataInput } from "../../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import DataGridTable from "../../../../repertoire/components/dataGridTable/DataGridTable";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import FormattedTimeDataInput from "../../../../repertoire/components/timeFieldDataInput/FormattedTimeDataInput";
import { MEMBERS_PORTAL } from "../../../../accounts/Consts";
import { IContributorItem } from "../../../../repertoire/types/IContributorItem";

export interface IDraftWorkMaintenanceGeneralDataViewProps {
    draftWorkMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    draftWorkMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    work: IWorkState;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: any[];
    isNewDraftWork?: boolean;
    updateDraftWorkField: (value: any, fieldName: IWorkStateKeys, index?: number) => void;
    resetMessageBanner: () => void;
    changesMade: boolean;
    updateComponentFieldsItems?: (fields: IMembersPortalField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IMembersPortalComponentDataItem;
    dataGridTableEditableFieldsData?: IMembersPortalComponentDataItem;
    setEditableFields?: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    incrementTempID?: () => void;
    activeHeaderSection?: string;
    isMandatory?: boolean;
    shareDecimalsToDisplay?: number;
}

interface IDraftWorkMaintenanceGeneralDataViewState {
    loaded: boolean;
    activeHeaderSection?: string;
}

export default class DraftWorksMaintenanceGeneralDataView extends React.PureComponent<
    IDraftWorkMaintenanceGeneralDataViewProps,
    IDraftWorkMaintenanceGeneralDataViewState> {
    viewData;
    constructor(props: IDraftWorkMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            activeHeaderSection: props.activeHeaderSection ?? "draftWorkWorkProperties",
            loaded: false
        };
        this.viewData = {};
    }


    componentDidMount() {
        const {
            draftWorkMaintenanceGeneralDataViewData: { fields }
        } = this.props;

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);

            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = (header: IMembersPortalField) => {

        return (
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={header.name}
                data-testid={header.name + "-section"}
            >
                {header.data}
            </div>
        );
    };


    showFieldsModal = () => {

    }

    render() {
        const {
            isReadonly,
            lookupValues,
            updateDraftWorkField,
            incrementTempID,
            dataGridTableData,
            draftWorkMaintenanceGridsViewData,
            tabs,
            activeTab,
            shareDecimalsToDisplay,
            showModal
        } = this.props;

        const {
            titles,
            artists,
            contributors,
            duration,
            genre,
            instrumentation,
            isArrangement,
            isLibraryWork,
            isCommissionedForCommercial,
            isOriginalWork,
            originalTitle,
            language
        } = this.props.work;

        const { loaded, activeHeaderSection } = this.state;

        const changeData = (value: any, fieldName: string) => {
            updateDraftWorkField(value, fieldName as IWorkStateKeys);
        }

        const ADDITIONAL_WORK_PROPERTIES_HEADER = 'Additional work properties';

        if (loaded) {
            return (
                <>
                    <div className="topMargin">
                        <div className="row">
                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'membersPortalGeneralTitle'))}
                        </div>
                            {<SizedTextDataInput  
                                fieldType={"text"}
                                label={this.viewData[DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY]}
                                fieldName={DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY}
                                changeData={changeData}
                                readOnly={isReadonly}
                                isHidden={!this.viewData[DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY]}
                                value={originalTitle}
                                isMandatory={true}
                             />}
                            {<SizedDropdownDataInput
                                label={this.viewData[DRAFT_WORKS_LANGUAGE_STATE_KEY]}
                                fieldName={DRAFT_WORKS_LANGUAGE_STATE_KEY}
                                changeData={changeData}
                                options={getLookupValues(LANGUAGE_LOOKUP, lookupValues)}
                                readOnly={isReadonly}
                                allowNull={false}
                                isHidden={false}
                                value={language}
                                isMandatory={true}
                            />}
                            {<FormattedTimeDataInput
                                fieldName={DRAFT_WORKS_DURATION_STATE_KEY}
                                label={this.viewData[DRAFT_WORKS_DURATION_STATE_KEY]}
                                isHidden={!this.viewData[DRAFT_WORKS_DURATION_STATE_KEY]}
                                value={duration}
                                changeData={changeData}
                                readOnly={isReadonly}
                                tabs={tabs}
                                activeTab={activeTab}
                                isDataGridSize={false}
                                isMandatory={true}
                            />}

                            {<SizedDropdownDataInput
                                label={this.viewData[DRAFT_WORKS_GENRE_STATE_KEY]}
                                fieldName={DRAFT_WORKS_GENRE_STATE_KEY}
                                value={genre}
                                changeData={changeData}
                                options={getLookupValues(WORK_GENRE_LOOKUP, lookupValues)}
                                readOnly={isReadonly}
                                allowNull={false}
                                isHidden={false}

                            />}
                        </div>
                    </div>

                    {<div className="row">

                        <div className="headerContainer">
                                {this.renderGridViewHeaderSections(draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'membersPortalWorkTitle'))}
                        </div>
                        <DataGridTable
                            tableContents={titles.filter(x=>x.type.value!=='OT')}
                            changeData={changeData}
                            dataGridTableData={dataGridTableData}
                            stateKey={DRAFT_WORKS_TITLES_STATE_KEY}
                            componentInstance={DRAFT_WORKS_TITLES_STATE_KEY}
                            showRowNumber={false}
                            isReadOnly={isReadonly}
                            tableActions={isReadonly ? [] : [{ name: ADD_NEW_ACTION }]}
                            lookupValues={lookupValues}
                            incrementTempID={incrementTempID}
                            tabs={tabs}
                            activeTab={activeTab}
                            showDropdownsAsCodes={false}
                        />

                    </div>}
                    <div className="row">
                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(draftWorkMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'membersPortalWorkInstruments'))}
                        </div>
                        <DataGridTable
                            tableContents={instrumentation}
                            changeData={changeData}
                            dataGridTableData={dataGridTableData}
                            stateKey={DRAFT_WORKS_INSTRUMENTATION_STATE_KEY}
                            componentInstance={DRAFT_WORKS_INSTRUMENTATION_STATE_KEY}
                            showRowNumber={false}
                            isReadOnly={isReadonly}
                            incrementTempID={incrementTempID}
                            tabs={tabs}
                            activeTab={activeTab}
                            tableActions={isReadonly ? [] : [{ name: ADD_NEW_ACTION }]}
                            lookupValues={lookupValues}
                            showDropdownsAsCodes={false}
                            portalType={MEMBERS_PORTAL}
                        />
                    </div>

                    <div
                        className="fontHeader"
                        key={ADDITIONAL_WORK_PROPERTIES_HEADER}
                        data-testid={`${ADDITIONAL_WORK_PROPERTIES_HEADER}-section`}
                    >
                        {ADDITIONAL_WORK_PROPERTIES_HEADER}
                    </div>

                    <div className="row">
                        <SizedCheckboxDataInput
                            label={this.viewData[DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY]}
                            fieldName={DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY}
                            value={isOriginalWork}
                            changeData={changeData}
                            readonly={isReadonly}
                        />

                        <SizedCheckboxDataInput
                            label={this.viewData[DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY]}
                            fieldName={DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY}
                            value={isArrangement}
                            changeData={changeData}
                            readonly={isReadonly}
                        />

                        <SizedCheckboxDataInput
                            label={this.viewData[DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY]}
                            fieldName={DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY}
                            value={isLibraryWork}
                            changeData={changeData}
                            readonly={isReadonly}
                        />

                    </div>
                    <div className="row">
                        <SizedCheckboxDataInput
                            label={this.viewData[DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY]}
                            fieldName={DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY}
                            value={isCommissionedForCommercial}
                            changeData={changeData}
                            readonly={isReadonly}
                        />
                    </div>
                    
                </>
            );
        }
        return <div />;
    }
}