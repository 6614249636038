import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import * as React from 'react';
import { IMemberStatementPaymentRunDetails, IMemberStatementPaymentRunUsageDetail, IMemberStatementPaymentRunWork } from '../../../types/IMemberStatementSearchResult';
import IMembersPortalComponentDataItem from '../../../../redux/types/IMembersPortalComponentDataItem';
import { Key, useState } from 'react';
import { mp_addTab } from '../../../../redux/reducers/MembersPortalReducer';
import { ITabReduxItem } from '../../../../redux/types/ITabReduxItem';

export interface IMemberStatementUsageDetailsGridProps {
    gridDetails: IMemberStatementPaymentRunWork;
    usageDetailsGridsComponentData: IMembersPortalComponentDataItem;
}


export const MemberStatementUsageDetailsGrid: React.FC<IMemberStatementUsageDetailsGridProps> = (props: IMemberStatementUsageDetailsGridProps) => {

    const dataSource: IMemberStatementPaymentRunUsageDetail[] = props.gridDetails.usageDetails;

    const columns: ColumnsType<IMemberStatementPaymentRunUsageDetail> = props.usageDetailsGridsComponentData.fields.map(f => (
        {
            title: f.data,
            dataIndex: f.name,
            key: f.name,
            sorter: ['usageTitles', 'usagePerformers'].includes(f.name) ? (a, b) => a[f.data].localeCompare(b[f.data]) : null
        }
    ));


    return <div>
        <Table dataSource={dataSource} columns={columns} />
    </div>
}