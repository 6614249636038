import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IUsagePoolState } from "../../redux/types/IUsagePoolState";
import { CHECKBOX_INPUT, DATE_INPUT, DROPDOWN_INPUT, DROPDOWN_TREE_INPUT, INTEGER_INPUT, READ_ONLY_INPUT, TEXT_INPUT, TIME_INPUT } from "../Consts";
import { ITreeData } from "../types/ITreeData";
import { IUsagePoolRightTypeDataItem } from "../types/usageTypes/IUsagePoolRightTypeDataItem";
import { IUsagePoolSourceDataItems } from "../types/usageTypes/IUsagePoolSourceDataItems";
import { IUsagePoolCore } from "../types/usageTypes/IUsagePoolCore";
import { IUsagePoolSource } from "../types/usageTypes/IUsagePoolSource";
import { IUsagePoolRight } from "../types/usageTypes/IUsagePoolRight";
import { IUsagePoolAllocationSettingsDataItems } from "../types/usageTypes/IUsagePoolAllocationSettingsDataItems";
import { IUsagePoolAllocationSettings } from "../types/usageTypes/IUsagePoolAllocationSettings";
import { IUsagePoolWeightSettings } from "../types/usageTypes/IUsagePoolWeightSettings";
import { IUsageWeightSettingsDataItems } from "../types/usageTypes/IUsageWeightSettingsDataItems";
import { IDimensionAndDates } from "../types/usageTypes/IDimensionsAndDates";


export class UsagePoolDetailsMapper {

    public static mapStateToRights(stateRights: IUsagePoolRightTypeDataItem[]): IUsagePoolRight[] {
        if (stateRights && stateRights.length > 0) {
            const rights: IUsagePoolRight[] = [];
            stateRights.map(r => {
                rights.push({
                    poolID: r.poolID,
                    poolRightID: r.poolRightID,
                    economicRight: r.economicRights.value,
                    shareSet: r.shareSet.value
                })
            })
            return rights;
        }
        else {
            return [];
        }
    }

    public static mapRightsToState(rights: IUsagePoolRight[]): IUsagePoolRightTypeDataItem[] {
        if (rights && rights.length > 0) {
            const rightsState: IUsagePoolRightTypeDataItem[] = [];
            rights.map(r => {
                rightsState.push({
                    poolID: r.poolID,
                    poolRightID: r.poolRightID,
                    economicRights: { value: r.economicRight, inputType: DROPDOWN_INPUT },
                    shareSet: { value: r.shareSet, inputType: DROPDOWN_INPUT },
                    economicRightDescription: { value: r.description, inputType: READ_ONLY_INPUT }
                })
            })
            return rightsState;
        }
        else {
            return []
        }
    }

    public static mapStateToWeightMultipliers(weightMultipliers: IUsageWeightSettingsDataItems[], datesAndDimensions: IDimensionAndDates[]): IUsagePoolWeightSettings[] {
        if (weightMultipliers && weightMultipliers.length > 0) {
            const weightSettings: IUsagePoolWeightSettings[] = [];
            weightMultipliers.map(w => {
                let relatedDateAndDimensionRow = datesAndDimensions.find(x => x.poolDimensionsAndDatesID === w.dimensionsAndDatesID);
                weightSettings.push({
                    weightingCode: w.weightingCode.value,
                    musicSource: w.musicSource.value,
                    usageDimension1: w.usageDimensionOne.value,
                    usageDimension2: w.usageDimensionTwo.value,
                    usageDimension3: w.usageDimensionThree.value,
                    usageDimension4: w.usageDimensionFour.value,
                    usageDimension5: w.usageDimensionFive.value,
                    use: w.use.value,
                    effectiveDateRange: relatedDateAndDimensionRow ? relatedDateAndDimensionRow.effectiveDateRange : w.effectiveDateRange.value,
                    fromTime: w.fromTime.value,
                    toTime: w.toTime.value,
                    weightMultiplier: w.weightMultiplier.value,
                    copySection: false,
                    dimensionsAndDatesID: relatedDateAndDimensionRow ? relatedDateAndDimensionRow.poolDimensionsAndDatesID : w.dimensionsAndDatesID,
                    poolWeightMultiplierID: w.poolWeightMultiplierID,
                    poolID: w.poolID,
                })
            })
            return weightSettings;
        }
        else return [];
    }

    public static mapWeightMultipliersToState(weightMultipliers: IUsagePoolWeightSettings[], dimensionsAndDates: IDimensionAndDates[]): IUsageWeightSettingsDataItems[] {
        if (weightMultipliers && weightMultipliers.length > 0) {
            const weightSettings: IUsageWeightSettingsDataItems[] = []
            weightMultipliers.map(w => {
                let relatedDateAndDimensionRow = dimensionsAndDates.find(x => x.poolDimensionsAndDatesID === w.dimensionsAndDatesID);
                weightSettings.push({
                    weightingCode: { value: w.weightingCode, inputType: TEXT_INPUT },
                    musicSource: { value: w.musicSource, inputType: TEXT_INPUT },
                    usageDimensionOne: { value: w.usageDimension1, inputType: TEXT_INPUT },
                    usageDimensionTwo: { value: w.usageDimension2, inputType: TEXT_INPUT },
                    usageDimensionThree: { value: w.usageDimension3, inputType: TEXT_INPUT },
                    usageDimensionFour: { value: w.usageDimension4, inputType: TEXT_INPUT },
                    usageDimensionFive: { value: w.usageDimension5, inputType: TEXT_INPUT },
                    use: { value: w.use, inputType: TEXT_INPUT },
                    effectiveDateRange: { value: relatedDateAndDimensionRow ? relatedDateAndDimensionRow.effectiveDateRange : w.effectiveDateRange, inputType: DROPDOWN_INPUT },
                    fromTime: { value: w.fromTime, inputType: TIME_INPUT },
                    toTime: { value: w.toTime, inputType: TIME_INPUT },
                    weightMultiplier: { value: w.weightMultiplier, inputType: TEXT_INPUT },
                    copySection: { value: '', inputType: CHECKBOX_INPUT },
                    dimensionsAndDatesID: relatedDateAndDimensionRow ? relatedDateAndDimensionRow.poolDimensionsAndDatesID : w.dimensionsAndDatesID,
                    poolWeightMultiplierID: w.poolWeightMultiplierID,
                    poolID: w.poolID,
                })
            })
            return weightSettings;
        }
        else return [];
    }

    public static mapDatesAndDimensionsToState(dimensionsAndDates: any[]): any[] {
        if (dimensionsAndDates && dimensionsAndDates.length > 0) {
            const dimensionsAndDatesState: any[] = []
            dimensionsAndDates.map(w => {
                dimensionsAndDatesState.push({
                    dimension1: w.usageDimension1,
                    dimension2: w.usageDimension2,
                    dimension3: w.usageDimension3,
                    dimension4: w.usageDimension4,
                    dimension5: w.usageDimension5,
                    time: w.time,
                    use: w.use,
                    musicSource: w.musicSource,
                    effectiveFrom: w.effectiveFrom,
                    effectiveTo: w.effectiveTo,
                    effectiveDateRange: w.effectiveDateRange,
                    poolDimensionsAndDatesID: w.poolDimensionsAndDatesID,
                    productMusicUsageDimension: w.productMusicUsageDimension

                })
            })
            return dimensionsAndDatesState;
        }
        else return [];
    }

    public static mapEstimatedValuePerPointToState(estimatedValue: any[]): any[] {
        if (estimatedValue && estimatedValue.length > 0) {
            const estimatedValueState: any[] = []
            estimatedValue.map(w => {
                var fromDate = w.effectiveFrom.split('T')[0];
                var toDate = w.effectiveTo ? w.effectiveTo.split('T')[0] : null;
                estimatedValueState.push({
                    effectiveFrom: { value: fromDate, inputType: DATE_INPUT },
                    effectiveTo: { value: toDate, inputType: DATE_INPUT },
                    evpp: { value: w.evpp, inputType: TEXT_INPUT },
                })
            })
            return estimatedValueState;
        }
        else return [];
    }


    public static mapStateToSources(stateSources: IUsagePoolSourceDataItems[]): IUsagePoolSource[] {
        if (stateSources && stateSources.length > 0) {
            const sources: IUsagePoolSource[] = [];
            stateSources.map(s => {
                sources.push({
                    source: s.source.value,
                    poolID: s.poolID,
                    poolSourceID: s.poolSourceID
                })
            })
            return sources;
        }
        else {
            return [];
        }
    }

    public static mapDatesAndDimensions(datesAndDimensions: any): any {
        if (datesAndDimensions && datesAndDimensions.length > 0) {
            return datesAndDimensions.map(lineItem => {
                return {
                    usageDimension1: lineItem.dimension1,
                    usageDimension2: lineItem.dimension2,
                    usageDimension3: lineItem.dimension3,
                    usageDimension4: lineItem.dimension4,
                    usageDimension5: lineItem.dimension5,
                    time: lineItem.time,
                    use: lineItem.use,
                    musicSource: lineItem.musicSource,
                    effectiveFrom: lineItem.effectiveFrom,
                    effectiveTo: lineItem.effectiveTo,
                    poolDimensionsAndDatesID: lineItem.poolDimensionsAndDatesID,
                    effectiveDateRange: lineItem.effectiveDateRange,
                    productMusicUsageDimension: lineItem.productMusicUsageDimension
                }

            })
        }
        else return []
    }

    public static mapStateToEstimatedValuePerPoint(estimatedValues: any): any {
        if (estimatedValues && estimatedValues.length > 0) {
            return estimatedValues.map(lineItem => {
                var fromDate = `${lineItem.effectiveFrom.value}T00:00:00`;
                var toDate = lineItem.effectiveTo.value ? `${lineItem.effectiveTo.value}T23:59:59` : null;
                return {
                    effectiveFrom: fromDate,
                    effectiveTo: toDate,
                    evpp: lineItem.evpp.value,
                }

            })
        }
        else return []
    }

    public static mapSourcesToState(sources: IUsagePoolSource[], matchingSources: ITreeData[]): IUsagePoolSourceDataItems[] {
        if (sources && sources.length > 0) {
            const sourcesState: IUsagePoolSourceDataItems[] = [];
            sources.map(s => {
                const matchingSource = s.source == null ? null : matchingSources.find(m => m.id === s.source);
                sourcesState.push({
                    poolID: s.poolID,
                    poolSourceID: s.poolSourceID,
                    source: { value: s.source, inputType: DROPDOWN_TREE_INPUT },
                    hierarchy: { value: matchingSource?.hierarchy, inputType: READ_ONLY_INPUT },
                    usageType: { value: matchingSource?.matchType.toString(), inputType: DROPDOWN_INPUT }
                })
            })
            return sourcesState;
        }
        else {
            return [];
        }
    }

    public static mapStateToAllocationSettings(stateSettings: IUsagePoolAllocationSettingsDataItems): IUsagePoolAllocationSettings {
        if (stateSettings) {
            const settings: IUsagePoolAllocationSettings = {
                poolID: stateSettings.poolID,
                poolAllocationSettingsID: stateSettings.poolAllocationSettingsID,
                unidentifiedUsage: stateSettings.unidentifiedUsage.value,
                nonSocietyIPs: stateSettings.nonSocietyIPs.value,
                referenceIPs: stateSettings.referenceIPs.value,
                missingShares: stateSettings.missingShares.value,
                warsawRule: stateSettings.warsawRule.value,
                percentageDurationForUnidentifiedUsage: stateSettings.percentageDurationForUnidentifiedUsage.value,
                defaultDurationPercentage: stateSettings.defaultDurationPercentage.value,
                reciprocalAgreements: stateSettings.reciprocalAgreements.value,
                pdWork100Percent: stateSettings.pdWork100Percent.value,
                pdiPs: stateSettings.pdiPs.value,
                useAdvertisementDurationForMatchedUsage: stateSettings && stateSettings.useAdvertisementDurationForMatchedUsage && stateSettings.useAdvertisementDurationForMatchedUsage.value,
                useCueSheetDurationForMatchedUsage: stateSettings && stateSettings.useCueSheetDurationForMatchedUsage && stateSettings.useCueSheetDurationForMatchedUsage.value,
                customNotebook:stateSettings && stateSettings.customNotebook && stateSettings.customNotebook.value,
                allocateSpecificRoles:stateSettings && stateSettings.allocateSpecificRoles && stateSettings.allocateSpecificRoles.value,
                allocateSpecificSocieties:stateSettings && stateSettings.allocateSpecificSocieties && stateSettings.allocateSpecificSocieties.value
            }

            settings.allocateSpecificRoles = (stateSettings.allocateSpecificRoles && stateSettings.allocateSpecificRoles.value && stateSettings.allocateSpecificRoles.value.length > 0 )
                        ? stateSettings.allocateSpecificRoles.value : null;
            
            settings.allocateSpecificSocieties = (stateSettings.allocateSpecificSocieties && stateSettings.allocateSpecificSocieties.value && stateSettings.allocateSpecificSocieties.value.length > 0 )
                        ? stateSettings.allocateSpecificSocieties.value : null;

            return settings;
        } else {
            return undefined;
        }
    }

    public static mapAllocationSettingsToState(settings: IUsagePoolAllocationSettings): IUsagePoolAllocationSettingsDataItems {
        if (settings) {
            const stateSettings: IUsagePoolAllocationSettingsDataItems = {
                poolID: settings.poolID,
                poolAllocationSettingsID: settings.poolAllocationSettingsID,
                unidentifiedUsage: { value: settings.unidentifiedUsage, inputType: CHECKBOX_INPUT },
                nonSocietyIPs: { value: settings.nonSocietyIPs, inputType: CHECKBOX_INPUT },
                referenceIPs: { value: settings.referenceIPs, inputType: CHECKBOX_INPUT },
                missingShares: { value: settings.missingShares, inputType: CHECKBOX_INPUT },
                warsawRule: { value: settings.warsawRule, inputType: CHECKBOX_INPUT },
                percentageDurationForUnidentifiedUsage: { value: settings.percentageDurationForUnidentifiedUsage, inputType: CHECKBOX_INPUT },
                defaultDurationPercentage: { value: settings.defaultDurationPercentage, inputType: INTEGER_INPUT },
                reciprocalAgreements: { value: settings.reciprocalAgreements, inputType: CHECKBOX_INPUT },
                pdWork100Percent: { value: settings.pdWork100Percent, inputType: CHECKBOX_INPUT },
                pdiPs: { value: settings.pdiPs, inputType: CHECKBOX_INPUT },
                useCueSheetDurationForMatchedUsage: {
                    value: settings.useCueSheetDurationForMatchedUsage, inputType: CHECKBOX_INPUT
                },
                useAdvertisementDurationForMatchedUsage: {
                    value: settings.useAdvertisementDurationForMatchedUsage, inputType: CHECKBOX_INPUT
                },
                customNotebook: {
                    value: settings.customNotebook, inputType: TEXT_INPUT
                },
                allocateSpecificRoles:{
                    value: settings.allocateSpecificRoles, inputType: DROPDOWN_INPUT
                },
                allocateSpecificSocieties:{
                    value: settings.allocateSpecificSocieties, inputType: DROPDOWN_INPUT
                } 
            }
            return stateSettings;
        } else {
            return undefined;
        }
    }

    public static mapStateToPool(state: IUsagePoolState): IUsagePoolCore {
        if (state) {
            const {
                poolID,
                poolCode,
                description,
                cat1,
                cat2,
                invoice,
                payment,
                allocationRule,
                distributionCategoryType,
                licenseeID,
                poolSources,
                poolRightTypes,
                poolAllocationSettings,
                versionNumber,
                poolWeightSettings,
                datesAndDimensions,
                weightSettingsIsEnabled,
                evppSettingsIsEnabled,
                poolEstimatedValuePerPointSettings,
                isForeign
            } = state;
            const usagePool: IUsagePoolCore = {
                poolID,
                poolCode,
                description,
                cat1,
                cat2,
                invoice,
                payment,
                allocationRule,
                distributionCategoryType,
                licenseeID,
                weightSettingsIsEnabled,
                evppSettingsIsEnabled,
                dimensionsAndDates: this.mapDatesAndDimensions(datesAndDimensions),
                sources: this.mapStateToSources(poolSources),
                rights: this.mapStateToRights(poolRightTypes),
                allocationSettings: this.mapStateToAllocationSettings(poolAllocationSettings),
                weightMultipliers: this.mapStateToWeightMultipliers(poolWeightSettings, datesAndDimensions),
                versionNumber,
                estimatedValuePerPoints: this.mapStateToEstimatedValuePerPoint(poolEstimatedValuePerPointSettings),
                isForeign: isForeign
            };

            return usagePool;
        }
        else {
            return null;
        }
    }

    public static mapPoolToState(usagePool: IUsagePoolCore, matchingSources: ITreeData[] = []): IUsagePoolState {
        if (usagePool) {
            const {
                poolID,
                poolCode,
                description,
                cat1,
                cat2,
                invoice,
                payment,
                allocationRule,
                distributionCategoryType,
                licenseeID,
                sources,
                rights,
                allocationSettings,
                versionNumber,
                weightMultipliers,
                poolEnabled,
                dimensionsAndDates,
                weightSettingsIsEnabled,
                evppSettingsIsEnabled,
                estimatedValuePerPoints,
                isForeign
            } = usagePool;

            const state: IUsagePoolState = {
                poolID,
                poolCode,
                description,
                cat1,
                cat2,
                invoice,
                payment,
                allocationRule,
                distributionCategoryType,
                licenseeID,
                versionNumber,
                poolSources: this.mapSourcesToState(sources, matchingSources),
                poolRightTypes: this.mapRightsToState(rights),
                poolAllocationSettings: this.mapAllocationSettingsToState(allocationSettings),
                poolWeightSettings: this.mapWeightMultipliersToState(weightMultipliers, dimensionsAndDates),
                poolEnabled,
                datesAndDimensions: this.mapDatesAndDimensionsToState(dimensionsAndDates),
                weightSettingsIsEnabled,
                evppSettingsIsEnabled,
                poolEstimatedValuePerPointSettings: this.mapEstimatedValuePerPointToState(estimatedValuePerPoints),
                isForeign
            };

            return state;
        }
        else {
            return null;
        }
    }

    public static createNewPoolState(lookups: ILookupDictionary): IUsagePoolState {
        return {
            poolID: -1,
            poolCode: '',
            description: '',
            cat1: '',
            cat2: '',
            invoice: false,
            payment: false,
            poolEnabled: false,
            allocationRule: '',
            distributionCategoryType: '',
            licenseeID: '',
            versionNumber: 0,
            poolAllocationSettings: {
                poolID: -1,
                poolAllocationSettingsID: 0,
                unidentifiedUsage: { value: false, inputType: CHECKBOX_INPUT },
                nonSocietyIPs: { value: false, inputType: CHECKBOX_INPUT },
                referenceIPs: { value: false, inputType: CHECKBOX_INPUT },
                missingShares: { value: false, inputType: CHECKBOX_INPUT },
                warsawRule: { value: false, inputType: CHECKBOX_INPUT },
                percentageDurationForUnidentifiedUsage: { value: false, inputType: CHECKBOX_INPUT },
                defaultDurationPercentage: { value: 0, inputType: TEXT_INPUT },
                reciprocalAgreements: { value: false, inputType: CHECKBOX_INPUT },
                pdWork100Percent: { value: false, inputType: CHECKBOX_INPUT },
                pdiPs: { value: false, inputType: CHECKBOX_INPUT },
                useCueSheetDurationForMatchedUsage: { value: false, inputType: CHECKBOX_INPUT },
                useAdvertisementDurationForMatchedUsage: { value: false, inputType: CHECKBOX_INPUT },
                customNotebook:{ value: '', inputType: TEXT_INPUT },
                allocateSpecificRoles:{ value: [], inputType: DROPDOWN_INPUT },
                allocateSpecificSocieties:{ value: [], inputType: DROPDOWN_INPUT }
            },
            poolWeightSettings: [],
            poolEstimatedValuePerPointSettings: [],
            isForeign: false
        }
    }
}