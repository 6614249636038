import React, { PureComponent, FunctionComponent } from "react";
import { REPERTOIRE_PAGE_FORM_WIDE_SIZE, DONOTCARE_ABOUTWORKFLOWS, HASOPEN_WORKFLOWS, WITHOUTOPEN_WORKFLOWS } from "../../Consts";

export interface IRadiobuttonDataInputProps {
    onSelectionChange: (selection: string) => void,
    disabled?: boolean;
    customStyle?: string;
    radioName: string;
    title?: string;
    selectedValue?: string;
    options?: { code: string, desc: string }[];
    key?: string | number;
}

export const SizedRadiobuttonDataInput: FunctionComponent<IRadiobuttonDataInputProps> = (props: IRadiobuttonDataInputProps) => {
    return <div className={REPERTOIRE_PAGE_FORM_WIDE_SIZE}>
        <RadiobuttonDataInput {...props} />
    </div>
}

export default class RadiobuttonDataInput extends PureComponent<
    IRadiobuttonDataInputProps
    > {
    constructor(props) {
        super(props);
    }

    onSelectionChange(event) {
        const { onSelectionChange } = this.props;
        onSelectionChange(event.target.value)
    }

    render() {
        const { radioName, title, selectedValue, options, key } = this.props;

        return (
            <div key={key}>
                <label className="selectorTitle">{title?`${title} :`:``}</label>
                <div className="radioContainer">
                    {
                        options.map(({ code, desc }, i) => (
                            <label key={i} className="radio-inline">
                                <div>
                                    <input type="radio"
                                        name={radioName}
                                        checked={selectedValue === code}
                                        onChange={this.onSelectionChange.bind(this)}
                                        value={code} />
                                    {desc}
                                </div>
                            </label>

                        ))
                    }
                </div>
            </div>
        );
    }
}
