import * as React from 'react';
import { Link } from "react-router-dom";
import { NavItem, NavLink } from 'reactstrap';
import { IMenuItem } from '../types/IMenuItem';
import { If } from '../components/If';
import { IEnableModulesConfigurationParametersItem } from '../../redux/types/IEnableModulesConfigurationParameterItem';
import {
    WORKS_PAGE,
    AGREEMENTS_PAGE,
    IPS_PAGE,
    PRODUCTS_PAGE,
    WORKFLOW_PAGE,
    FINDUSAGE_PAGE,
    ARTISTS_PAGE,
    USAGE_POOLS_PAGE,
    DISTRIBUTIONS_PAGE,
    PAYMENTRUN_PAGE,
    LICENSE_PAGE,
    ADJUSTMENTS_PAGE,
    CLAIMS_PAGE
} from '../../repertoire/Consts';
import { MATCHING_ENGINE, MEMBERS_PORTAL } from "../../accounts/Consts";
import {
    ALL_REGISTERED_WORKS_PAGE, DRAFT_WORKS_PAGE, MEMBER_STATEMENTS_PAGE, REGISTERED_WORKS_PAGE,
    DRAFT_SET_LISTS_PAGE,
    REGISTERED_SET_LISTS_PAGE,
    LIVE_PERFORMANCE_SUBMISSION_PAGE,
    UNPAID_CLAIMS_PAGE,
    CLAIMS_HISTORY_PAGE,
    DRAFT_AGREEMENTS_PAGE,
    REGISTERED_AGREEMENTS_PAGE,
} from '../../membersportal/Consts';
import { ITabReduxItem } from '../../redux/types/ITabReduxItem';
import { ILookupDictionary } from '../../lookup/types/ILookupDictionary';
import { SearchRequests } from '../../repertoire/services/SearchRequests';
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP,AGREEMENT_MAINTENANCE_GROUP } from '../../repertoire/ConfigurationConsts';
import IMembersPortalComponentDataItem from '../../redux/types/IMembersPortalComponentDataItem';

interface IMenuProps {
    items: IMenuItem[];
    updateHeader: (menuItem: IMenuItem) => void;
    enabledModules: IEnableModulesConfigurationParametersItem[]
    addTab: (tab: ITabReduxItem) => void;
    isMembersPortal?: boolean;
    customer: string;
    lookups: ILookupDictionary;
    currentUserNameNumber: string;
    addWork: (lookups: ILookupDictionary, currentUserNumber: string, formats: any) => void;
    addSetLists: (lookups: ILookupDictionary, currentUserNumber: string) => void;
    addAgreements: (lookups: ILookupDictionary, currentUserNumber: string,isNew?:boolean,draftAgreementMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem) => void;
}

export class Menu extends React.Component<IMenuProps>{
    constructor(props: IMenuProps) {
        super(props);
    }

    onClick(item: IMenuItem) {
        const { addTab, addWork, addSetLists,addAgreements, currentUserNameNumber, lookups } = this.props;

        const META_DATA_KEY: any = {
            'works': { 'tab': WORKS_PAGE, instance: MATCHING_ENGINE },
            'agreements': { 'tab': AGREEMENTS_PAGE, instance: MATCHING_ENGINE },
            'ips': { 'tab': IPS_PAGE, instance: MATCHING_ENGINE },
            'artists': { 'tab': ARTISTS_PAGE, instance: MATCHING_ENGINE },
            'products': { 'tab': PRODUCTS_PAGE, instance: MATCHING_ENGINE },
            'workflows': { 'tab': WORKFLOW_PAGE, instance: MATCHING_ENGINE },
            'usages': { 'tab': FINDUSAGE_PAGE, instance: MATCHING_ENGINE },
            'pools': { 'tab': USAGE_POOLS_PAGE, instance: MATCHING_ENGINE },
            'claims': { 'tab': CLAIMS_PAGE, instance: MATCHING_ENGINE },
            'distributions': { 'tab': DISTRIBUTIONS_PAGE, instance: MATCHING_ENGINE },
            'payment runs': { 'tab': PAYMENTRUN_PAGE, instance: MATCHING_ENGINE },
            'licenses': { 'tab': LICENSE_PAGE, instance: MATCHING_ENGINE },
            'adjustments': { 'tab': ADJUSTMENTS_PAGE, instance: MATCHING_ENGINE },
            'view my draft works': { 'tab': DRAFT_WORKS_PAGE, instance: MEMBERS_PORTAL },
            'view my draft agreement': { 'tab': DRAFT_AGREEMENTS_PAGE, instance: MEMBERS_PORTAL },
            'view my registered agreement': { 'tab': REGISTERED_AGREEMENTS_PAGE, instance: MEMBERS_PORTAL },
            'my registered works': { 'tab': REGISTERED_WORKS_PAGE, instance: MEMBERS_PORTAL },
            'all registered works': { 'tab': ALL_REGISTERED_WORKS_PAGE, instance: MEMBERS_PORTAL },
            'statements': { 'tab': MEMBER_STATEMENTS_PAGE, instance: MEMBERS_PORTAL },
            'view my draft set lists': { 'tab': DRAFT_SET_LISTS_PAGE, instance: MEMBERS_PORTAL },
            'unpaid claims': { 'tab': UNPAID_CLAIMS_PAGE, instance: MEMBERS_PORTAL },
            'view my registered set lists': { 'tab': REGISTERED_SET_LISTS_PAGE, instance: MEMBERS_PORTAL },
            'view my live performance submissions': { 'tab': LIVE_PERFORMANCE_SUBMISSION_PAGE, instance: MEMBERS_PORTAL },
            'claims history': { 'tab': CLAIMS_HISTORY_PAGE, instance: MEMBERS_PORTAL },
        }

        this.props.updateHeader(item);

        if(item.label.toLocaleLowerCase() === 'register new work')
            SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    addWork(lookups, currentUserNameNumber, formats);
            });
        else if(item.label.toLocaleLowerCase() === 'register a new set list')
            addSetLists(lookups, currentUserNameNumber);

        if (item.label.toLocaleLowerCase() === 'register new agreement')
            addAgreements(lookups, currentUserNameNumber,true,undefined);

        if (META_DATA_KEY[item.label.toLowerCase()])
            if(META_DATA_KEY[item.label.toLowerCase()].instance === item.portalType)
                addTab(META_DATA_KEY[item.label.toLowerCase()].tab);
    }

    renderMenuItem(item: IMenuItem, key: number): JSX.Element {
        const { enabledModules } = this.props;

        let filteredItem: IMenuItem = item;
        const disableModules = enabledModules && enabledModules.length > 0 ?
            enabledModules.filter(x => !x.enable) : [];

        let hasDisabledModulesAndItemChildren = disableModules && disableModules.length > 0 && filteredItem.children.length > 0;

        filteredItem.children = hasDisabledModulesAndItemChildren ?
            item.children.filter((current) => disableModules.findIndex(x => x.link === current.link) === -1 ? current : null) :
            filteredItem.children;

        return (
            <NavItem className="dropdown" key={key}>
                <NavLink tag={Link}
                    className="dropdown-toggle"
                    to={filteredItem.link}
                    onClick={() => this.onClick(filteredItem)}>
                    <span className={filteredItem.icon} aria-hidden="true"></span>
                    {" " + filteredItem.label + " "}
                    <If condition={filteredItem.children && filteredItem.children.length > 0}>
                        <b className="caret"></b>
                    </If>
                </NavLink>
                <If condition={filteredItem.children && filteredItem.children.length > 0}>
                    <ul className="dropdown-menu">
                        {
                            filteredItem.children.filter(c => c.isEnabled).map((c: IMenuItem, idx: number) =>
                                <NavItem key={idx}>
                                    <NavLink tag={Link} to={c.link} onClick={() => this.onClick(c)}>{c.label}</NavLink>
                                </NavItem>)
                        }
                    </ul>
                </If>
            </NavItem >
        );
    }

    renderRAStatmentLink(): JSX.Element {
        const RA_STATEMENT = 'RA Statement';
        const RA_LINK = 'https://www.robsav.com/Account-Login'

            return (
                <li className="dropdown nav-item">
                    <a href={`${RA_LINK}`} className="dropdown-toggle nav-link"  target="_blank">
                        <span className={'icon ms-Icon ms-Icon--OpenInNewTab'} aria-hidden="true"></span>
                        {` ${RA_STATEMENT} `}
                    </a>
                </li>
            )

    }

    renderMenuItems(items: IMenuItem[]): JSX.Element[] {


        if (items && items.length > 0) {
            return items.filter(i => i.isEnabled).map((i: IMenuItem, idx: number) => this.renderMenuItem(i, idx));
        }
        else {
            return null;
        }
    }

    render() {
        const { customer } = this.props;
        let isCustomerSamro = customer && customer.toLowerCase() === 'samro';
        return (
            <ul className="nav navbar-nav">
                {this.renderMenuItems(this.props.items)}
                {this.props.isMembersPortal && isCustomerSamro && this.renderRAStatmentLink()}
            </ul>);
    }
}