import * as React from "react";
import { PRODUCT_SOURCE_LOOKUP, PRODUCT_SOURCE_TYPE_LOOKUP, PRODUCT_STATUS_TYPE_LOOKUP, PRODUCT_TYPE_LOOKUP } from "../../../lookup/Consts";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { ILookupInstance } from "../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../redux/reducers/ModalReducer";
import { matchProductBatchRequest, postProductBatchRequest, setEditableFields, updateProductField, clearModalSearchResults, updateProductFields } from "../../../redux/reducers/RepertoireReducer";
import { IProductState, IProductStateKeys } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { IShowFieldSettingViewModalProps } from "../../components/repertoireModal/RepertoireModal";
import { 
    ALL_ROLES, 
    COMPONENT_INSTANCE_PRODUCTS,
    CONFIGURE_PRODUCTS_ROLE,
    DURATION_STATE_KEY,
    EDITABLE_FIELDS,
    GENERAL_DATA_EDITABLE_FIELDS,
    MATCH_PRODUCT_BATCH_STATE_KEY,
    MERGE_THIS_PRODUCT_STATE_KEY,
    POST_PRODUCT_BATCH_STATE_KEY,
    PRODUCT_BATCH_ID_STATE_KEY,
    PRODUCT_ID1_STATE_KEY,
    PRODUCT_ID2_STATE_KEY,
    PRODUCT_LAST_MODIFIED_DATE_STATE_KEY,
    PRODUCT_MAINTAINED_STATE_KEY,
    PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW,
    PRODUCT_RELEASED_DATE_STATE_KEY,
    PRODUCT_SOURCE_STATE_KEY,
    PRODUCT_SOURCE_TYPE_STATE_KEY,
    PRODUCT_STATUS_KEY,
    PRODUCT_TYPE_STATE_KEY,
    SEARCH_VIEW, SEARCH_VIEW_PRODUCTSOURCE_IP,
    PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY,
    PRODUCT_SOURCE_IP_STATE_KEY,
    TITLE_EDITABLE_FIELDS_VIEW,
    REPERTOIRE_PAGE_FORM_ITEM_SIZE,
    DATA_GRID_TABLE,
    RECORDING_DETAILS,
    PRODUCT_IS_DISTRIBUTED_STATE_KEY
} from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { IRepertoireField } from "../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { ISourceIP } from "../../types/ISourceIP";
import WorkBatchInput from "../../works/components/workBatchInput/WorkBatchInput";
import { Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import dayjs from 'dayjs';
import DatePickerFormikField from "../../../membersportal/unpaidClaims/DatePickerFormik";
import { AntTimePicker } from "../../components/timeFieldDataInput/AntFieldsFormik";
import { SizedDropdownDataInputFormik, makeOptionsList } from "../../components/dropdownDataInput/DropdownDataInputFormik";
import { CustomCheckBox, CustomInputComponent, newCustomDropDown } from "../../works/components/workMaintenanceGeneralDataView/WorkMaintenanceGeneralDataViewFormik";


function usePreviousValue(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value; 
    }, [value]); 
    return ref.current; 
  };

  export const productGeneralForAPI = (values) => {
    let totalTimeInSeconds = 0;
    if (values.duration) {
    const hours = values.duration.hour();
    const minutes = values.duration.minute();
    const seconds = values.duration.second();
    totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
    }
  
    const updatedProduct = {
        ...values,
        source: values?.source?.data ? values.source.data : null,
        productType: values?.productType?.data ? values.productType.data : null,
        duration: totalTimeInSeconds,
        lastModifiedDate: values.lastModifiedDate === '' ? null : values.lastModifiedDate,
        statusType: values?.statusType?.data ? values.statusType.data : null,
        sourceType: values?.sourceType?.data ? values.sourceType.data : null,
        productSourceIP: values?.productSourceIP,
        sourceIP: null, // this value is carried on the productSourceIP
        sourceIPINumber: null,  // this value is carried on the productSourceIP
    }
    return updatedProduct;
  }



  const productMaintenanceGeneralSchema = (fieldData) => { 
    const schema = Yup.object().shape(
    {
        productType: fieldData?.productType?.isMandatory ? Yup.object().shape({
            data: Yup.string().nullable(),
          }
          ).test(
            'empty-check',
            'Product Type can not be empty',
            val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                data: Yup.string().nullable(),
              }).nullable().notRequired(),
        id1:  fieldData?.id1?.isMandatory ? Yup.string().test(
            'empty-check',
            'Id1 can not be empty',
            string => !(!string || string === "" || string.length <= 0),) : Yup.string().nullable().notRequired(),
        id2:  fieldData?.id2?.isMandatory ? Yup.string().test(
            'empty-check',
            'Id2 can not be empty',
            string => !(!string || string === "" || string.length <= 0),) : Yup.string().nullable().notRequired(),
        source: fieldData?.source?.isMandatory ? Yup.object().shape({
            data: Yup.string().nullable(),
          }
          ).test(
            'empty-check',
            'Source can not be empty',
            val => !(!val.data || val.data === "" || val.data.length <= 0),) : Yup.object().shape({
                data: Yup.string().nullable(),
              }).nullable().notRequired(),
        sourceType: fieldData?.sourceType?.isMandatory ? Yup.object().shape({
            data: Yup.string().nullable(),
          }
          ).test(
            'empty-check',
            'Source Type can not be empty',
            val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                data: Yup.string().nullable(),
              }).nullable().notRequired(),
        released: fieldData?.released?.isMandatory ? Yup.string().test(
            'empty-check',
            'Source Type can not be empty',
            string => !(!string || string === "" || string.length <= 0),) : Yup.string().nullable().notRequired(),
        statusType: fieldData?.sourceType?.isMandatory ? Yup.object().shape({
            data: Yup.string().nullable(),
          }
          ).test(
            'empty-check',
            'Status Type can not be empty',
            val => !(!val.data || val.data === "" || val.data.length <= 0),) : Yup.object().shape({
                data: Yup.string().nullable(),
              }).nullable().notRequired(),
        sourceIP: fieldData?.sourceIP?.isMandatory ? Yup.string().test(
            'empty-check',
            'Source Type can not be empty',
            string => !(!string || string === "" || string.length <= 0),) : Yup.string().nullable().notRequired(),
        sourceIPINumber: fieldData?.sourceIPINumber?.isMandatory ? Yup.string().test(
            'empty-check',
            'Source IP Number can not be empty',
            string => !(!string || string === "" || string.length <= 0),) : Yup.string().nullable().notRequired(),
        duration: fieldData?.duration?.isMandatory ? Yup.string().test(
                'empty-check',
                'Duration can not be empty',
                string => !(!string || string === "" || string.length <= 0),)  : Yup.string().nullable().notRequired(),
    },
)
return schema;
}

const isFieldHaveDefaultValue = (fieldName, viewData) => {
    return !!(viewData && viewData[fieldName] && viewData[fieldName].defaultValue);
}

export interface IProductMaintenanceGeneralDataViewProps {
    productDataFormikRef: any;
    productMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    updateProductField: typeof updateProductField;
    updateProductFields: typeof updateProductFields;
    lookupValues: ILookupDictionary;
    dataSource: DataSource;
    changesMade: boolean;
    saveInProgress: boolean;
    productType: string;
    id1: string;
    id2: string;
    duration: number;
    released: Date;
    trackCount: number;
    source: string;
    maintained: boolean;
    production: number;
    licenseeID: number;
    lastModifiedDate: Date;
    productBatchID: number;
    postProductBatch: typeof postProductBatchRequest;
    matchProductBatch: typeof matchProductBatchRequest;
    productBatchOperationInProgress: boolean;
    productSourceIP?: ISourceIP;
    isReadonly: boolean;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    hideModal?: typeof hideModal;
    roles: string[];
    dateCreated?: Date;
    sourceIP?: number;
    status?: string;
    sourceType?: string;
    clearModalSearchResults?: typeof clearModalSearchResults;
    showModal?: typeof showModal;
    product: IProductState;
    saveChanges: () => void;
}

const ProductMaintenanceGeneralDataViewFormik = ({
    setIsChangeGeneralForm,
    productDataFormikRef,
    productMaintenanceGeneralDataViewData,
    updateProductField,
    updateProductFields,
    lookupValues,
    dataSource,
    changesMade,
    saveInProgress,
    productType,
    id1,
    id2,
    duration,
    released,
    trackCount,
    source,
    maintained,
    production,
    licenseeID,
    lastModifiedDate,
    productBatchID,
    postProductBatch,
    matchProductBatch,
    productBatchOperationInProgress,
    isReadonly,
    tabs,
    activeTab,
    sourceIP,
    sourceType,
    status,
    productSourceIP,
    product,
    editableFields,
    updateComponentFieldsItems,
    showUpdateFieldsModal,
    editableFieldsDataView,
    dataGridTableEditableFieldsData,
    setEditableFields,
    roles,
    hideModal,
    showModal,
    saveChanges
}) => {
    const [viewData, setViewData]: any = React.useState({});
    const [loaded, setLoading] = React.useState(false);
    const [isHiddenIP, setIsHiddenIP] = React.useState(true);
    const prevProductTypeValue = usePreviousValue(productType);

    React.useEffect(() => {
        if (productMaintenanceGeneralDataViewData.fields) {
            const visibleFields = productMaintenanceGeneralDataViewData.fields.filter(field => !field.hidden);
            const data = {};
            visibleFields.forEach(item => {
                data[item.name] = item;
    
                if (Object.keys(data).length === visibleFields.length) {
                    setViewData(data);
                    setLoading(true);
                }
            });
        }
        setReadOnlySourceIPFields();
    }, [productMaintenanceGeneralDataViewData])

    React.useEffect(() => {
        if (prevProductTypeValue != productType) {
            setReadOnlySourceIPFields();
        }
    }, [productType]);

    React.useEffect(() => {
        setEditableFields(PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_PRODUCTS)
    }, []);

    const onSaveEditableFields = () => {
        editableFields && editableFields.default && updateComponentFieldsItems(editableFields.default, PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_PRODUCTS, PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    const showFieldsModal = () => {
        setEditableFields(PRODUCT_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_PRODUCTS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableEditableFieldsData,
            onClickSave: () => onSaveEditableFields()
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    const setReadOnlySourceIPFields = () => {
        if (productType === "SET LIST") {
            setIsHiddenIP(false);
        }
        else {
            setIsHiddenIP(true);
        }
    }

    const onClick = (fieldName: IRepertoireStateKeys) => {
        if (fieldName == PRODUCT_SOURCE_IP_STATE_KEY) {
            setIsChangeGeneralForm(true);
            showSearchModal(fieldName, SEARCH_VIEW_PRODUCTSOURCE_IP)
        }
    }

    const handleDataChange = () => {
        setIsChangeGeneralForm(true);
    }

    const handleTypeChange = (fieldName, value) => {
        changeData(value.data, fieldName);
        setIsChangeGeneralForm(true);
    }

    const showSearchModal = (modalProps: any, searchViewKey: string) => {
        clearModalSearchResults();
        showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search");
    };

    const getStatusOptions = (lookupValues, dataSource): ILookupInstance[] => {
        let options = [];
        dataSource === DataSource.Intray ?
            options = getLookupValues(PRODUCT_STATUS_TYPE_LOOKUP, lookupValues)?.slice(0, 2) :
            getLookupValues(PRODUCT_STATUS_TYPE_LOOKUP, lookupValues);
        return options;
    }

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            updateProductField(value, name as IProductStateKeys);
        }

        const getConfigureRoleDisabled = (): boolean => {
            if (roles && (roles.includes(CONFIGURE_PRODUCTS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const secondsIntToTimeString =(value: number) => {
            return value && !isNaN(value) && new Date(value * 1000).toISOString().substr(11, 8)
        }

        const isFieldPresent = (fieldName, viewData) => {
            return viewData && viewData[fieldName] && viewData[fieldName].data;
        }

        const handleSave = (values) => {
            const updatedProduct = productGeneralForAPI(values)
            updateProductFields(updatedProduct);
        }

        const stringDuration = secondsIntToTimeString(duration);
        const statusOptions = getStatusOptions(lookupValues, dataSource);
        const isHiddenIPLocal = isReadonly ? !isReadonly : isHiddenIP;
        const productSourceIPFullName = (productSourceIP === null) ? '' : productSourceIP.fullName.value;
        const productSourceIpiNameNumber = productSourceIP && productSourceIP.ipiNameNumber ? productSourceIP.ipiNameNumber : '';

        const getDefaultValue = (fieldName, viewData) => isFieldHaveDefaultValue(fieldName, viewData) ? viewData[fieldName].defaultValue : '';
        const getInitialValueDropDown = (initialValue, lookupName, fieldName) => {
            const lookUpValuesLocal = getLookupValues(lookupName, lookupValues);
            const initialSelected = initialValue ? initialValue : getDefaultValue(fieldName, viewData);
            const sourceOptions = makeOptionsList(true, lookupName, lookUpValuesLocal, false)
            const foundOption = sourceOptions.find(option => option.data === initialSelected || option.text === initialSelected);
        return foundOption ? foundOption : {}
        }


        const renderPostBatch = (): JSX.Element => {
            if (dataSource === DataSource.Intray && viewData && viewData[PRODUCT_BATCH_ID_STATE_KEY] && viewData[PRODUCT_BATCH_ID_STATE_KEY].data) {
                return <WorkBatchInput
                    label={viewData[PRODUCT_BATCH_ID_STATE_KEY].data}
                    fieldName={PRODUCT_BATCH_ID_STATE_KEY}
                    value={productBatchID}
                    changeData={changeData}
                    postButtonLabel={viewData[POST_PRODUCT_BATCH_STATE_KEY].data}
                    postButtonAction={() => postProductBatch(productBatchID)}
                    matchButtonLabel={viewData[MATCH_PRODUCT_BATCH_STATE_KEY].data}
                    matchButtonAction={() => matchProductBatch(productBatchID)}
                    isEnabled={!changesMade && !saveInProgress && !productBatchOperationInProgress && !isReadonly}
                    isRemoveStyleWrapper={true}
                />
            }
            else {
                return null;
            }
        }

        if (loaded) {
            return (
                <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">General</h1>
                            <button className="editBtn" title="Configure data fields" onClick={showFieldsModal} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                    <Formik
                        enableReinitialize
                        validateOnChange
                        initialValues={{
                            maintained: maintained ? maintained : false,
                            mergeThisProduct: false, 
                            lastModifiedDate: lastModifiedDate ? lastModifiedDate : '',
                            productType: getInitialValueDropDown(productType, PRODUCT_TYPE_LOOKUP, PRODUCT_TYPE_STATE_KEY),
                            id1: id1 ? id1 : '',
                            id2: id2 ? id2 : '',
                            source:  getInitialValueDropDown(source, PRODUCT_SOURCE_LOOKUP, PRODUCT_SOURCE_STATE_KEY),
                            sourceType:  getInitialValueDropDown(sourceType, PRODUCT_SOURCE_TYPE_LOOKUP, PRODUCT_SOURCE_TYPE_STATE_KEY),
                            released: released ? released : '',
                            statusType: getInitialValueDropDown(status, PRODUCT_STATUS_TYPE_LOOKUP, PRODUCT_STATUS_KEY),
                            sourceIP: productSourceIPFullName,
                            sourceIPINumber: productSourceIpiNameNumber,
                            productSourceIP: productSourceIP,
                            duration: duration ? dayjs(stringDuration, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss"),
                        }}
                        validate={(values) => {
                            const schema = productMaintenanceGeneralSchema(viewData)
                            try {
                                schema.validateSync(values, { abortEarly: false });
                                return {}; 
                              } catch (errors: any) {
                                const formikErrors = {};
                                errors && errors.inner && errors.inner.forEach((error) => {
                                  formikErrors[error.path] = error.message;
                                });
                                return formikErrors;
                              }
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            handleSave(values);
                            setSubmitting(false);
                        }}
                > 
                {({ values, errors, submitForm, validateForm }) => {
                    productDataFormikRef.current = {
                        values: values,
                        submitForm: submitForm,
                        validateForm: validateForm,
                        errors: errors,
                    }
                    return (
        <Form>
            <div style={{paddingBottom: '60px'}}>
            <div className="formItemsContainer">
                        {isFieldPresent(PRODUCT_MAINTAINED_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem flexRow flexAlignItemsCenter`}>
                                    <Field 
                                        label={viewData[PRODUCT_MAINTAINED_STATE_KEY].data} 
                                        name={PRODUCT_MAINTAINED_STATE_KEY} 
                                        errorName={PRODUCT_MAINTAINED_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleDataChange}
                                    />
                                      <label className='marginHorizontalVerySmall'>{viewData[PRODUCT_MAINTAINED_STATE_KEY].data}</label>
                                </div>}
                        {isFieldPresent(MERGE_THIS_PRODUCT_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem flexRow flexAlignItemsCenter`}>
                                    <Field 
                                        label={viewData[MERGE_THIS_PRODUCT_STATE_KEY].data} 
                                        name={MERGE_THIS_PRODUCT_STATE_KEY} 
                                        errorName={MERGE_THIS_PRODUCT_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleDataChange}
                                    />
                                    <label className="marginHorizontalVerySmall">{viewData[MERGE_THIS_PRODUCT_STATE_KEY].data}</label>
                                </div>}

                        {isFieldPresent(PRODUCT_LAST_MODIFIED_DATE_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                            <label>{viewData[PRODUCT_LAST_MODIFIED_DATE_STATE_KEY].data}</label>
                                            <DatePickerFormikField 
                                                onChange={handleDataChange}
                                                label={viewData[PRODUCT_LAST_MODIFIED_DATE_STATE_KEY]} 
                                                name={PRODUCT_LAST_MODIFIED_DATE_STATE_KEY} 
                                                disabled={true} 
                                                size={'large'}
                                            />
                                        </div>}
                    {isFieldPresent(PRODUCT_TYPE_STATE_KEY, viewData) && newCustomDropDown(viewData, PRODUCT_TYPE_STATE_KEY, lookupValues,  PRODUCT_TYPE_LOOKUP, isReadonly, errors, handleTypeChange)} 
                        {isFieldPresent(PRODUCT_ID1_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={PRODUCT_ID1_STATE_KEY}>
                                        <label>{viewData[PRODUCT_ID1_STATE_KEY].data}</label>
                                        <Field 
                                            label={viewData[PRODUCT_ID1_STATE_KEY].data}
                                            name={PRODUCT_ID1_STATE_KEY}
                                            component={CustomInputComponent}
                                            onClick={handleDataChange}
                                        />
                                    </div>
                                </div>}
                        {isFieldPresent(PRODUCT_ID2_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={PRODUCT_ID2_STATE_KEY}>
                                        <label>{viewData[PRODUCT_ID2_STATE_KEY].data}</label>
                                        <Field 
                                            label={viewData[PRODUCT_ID2_STATE_KEY].data}
                                            name={PRODUCT_ID2_STATE_KEY}
                                            component={CustomInputComponent}
                                            onClick={handleDataChange}
                                        />
                                    </div>
                                </div>}
                    {isFieldPresent(PRODUCT_SOURCE_STATE_KEY, viewData) && newCustomDropDown(viewData, PRODUCT_SOURCE_STATE_KEY, lookupValues,  PRODUCT_SOURCE_LOOKUP, isReadonly, errors, handleDataChange)} 
                        {!isHiddenIP && isFieldPresent(PRODUCT_SOURCE_TYPE_STATE_KEY, viewData) && newCustomDropDown(viewData, PRODUCT_SOURCE_TYPE_STATE_KEY, lookupValues,  PRODUCT_SOURCE_TYPE_LOOKUP, isReadonly, errors, handleDataChange)}
                                {isFieldPresent(PRODUCT_RELEASED_DATE_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                            <label>{viewData[PRODUCT_RELEASED_DATE_STATE_KEY].data}</label>
                                            <DatePickerFormikField 
                                            label={viewData[PRODUCT_RELEASED_DATE_STATE_KEY]} 
                                            name={PRODUCT_RELEASED_DATE_STATE_KEY}
                                            onChange={handleDataChange} 
                                            size={'large'}
                                             />
                                        </div>}
                              
                    {!isHiddenIP && isFieldPresent(PRODUCT_STATUS_KEY, viewData) && <SizedDropdownDataInputFormik
                                                                                        label={viewData[PRODUCT_STATUS_KEY].data}
                                                                                        fieldName={PRODUCT_STATUS_KEY}
                                                                                        useComboBoxAsMenuWidth={true}
                                                                                        options={statusOptions}
                                                                                        allowNull={true}
                                                                                        readOnly={isReadonly}
                                                                                        isHidden={!viewData[PRODUCT_STATUS_KEY].data}
                                                                                        errors={errors}
                                                                                        onChange={handleDataChange}
                                                                                    /> }
                            {!isHiddenIPLocal && isFieldPresent(PRODUCT_SOURCE_IP_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={PRODUCT_SOURCE_IP_STATE_KEY}>
                                        <label>{viewData[PRODUCT_SOURCE_IP_STATE_KEY].data}</label>
                                        <Field 
                                            onClick={() => onClick(PRODUCT_SOURCE_IP_STATE_KEY)}
                                            label={viewData[PRODUCT_SOURCE_IP_STATE_KEY].data}
                                            name={PRODUCT_SOURCE_IP_STATE_KEY}
                                            component={CustomInputComponent}
                                        />
                                    </div>
                                </div>}
                            {!isHiddenIPLocal && isFieldPresent(PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY}>
                                        <label>{viewData[PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY].data}</label>
                                        <Field 
                                            onClick={handleDataChange}
                                            label={viewData[PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY].data}
                                            name={PRODUCT_SOURCE_IPI_NUMBER_STATE_KEY}
                                            component={CustomInputComponent}
                                        />
                                    </div>
                                    </div>}
                            {isFieldPresent(PRODUCT_IS_DISTRIBUTED_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                                <div className={PRODUCT_IS_DISTRIBUTED_STATE_KEY}>
                                    <Field
                                        onClick={handleDataChange}
                                                label={viewData[PRODUCT_IS_DISTRIBUTED_STATE_KEY].data}
                                                name={PRODUCT_IS_DISTRIBUTED_STATE_KEY}
                                                component={CustomCheckBox}
                                                disabled={true}
                                            />
                                    <label>{viewData[PRODUCT_IS_DISTRIBUTED_STATE_KEY].data}</label>
                                </div>
                            </div>}

                    {isFieldPresent(DURATION_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE} formItem`}>
                        <label>{viewData[DURATION_STATE_KEY].data}</label>
                            <Field
                                onClick={handleDataChange}
                                component={AntTimePicker}
                                name={DURATION_STATE_KEY}
                                hasFeedback
                            />
                    </div>}
                    <div style={{paddingLeft: "15px"}}>
                {renderPostBatch()}
                </div>
                </div>
                        </div>
        </Form>)}}


                    </Formik>
                </div>
            );
        }
        return <div />;
}

export default ProductMaintenanceGeneralDataViewFormik;