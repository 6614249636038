import { connect } from "react-redux";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showMessage, showModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import {
    mp_addTab,

    incrementTempID,
    resetMessageBanner,
    mp_setChangesMade,
    setWarningMessage,
    registerStepsSetCurrent,
    createNewDraftSetLists,
    copyExistingDraftSetList,
    updateDraftSetListsField
} from "../../../../redux/reducers/MembersPortalReducer";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import {
    undoDraftSetListChangesThunk, deleteDraftSetListThunk, saveDraftSetListThunk
} from "../../../../redux/thunks/MembersPortalThunks";
import IAppState from "../../../../redux/types/IAppState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IPopUpInfoProps } from "../../../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import { IYesNoPromptViewModalProps } from "../../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { FormatFields } from "../../../../redux/types/FormatFields";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { DraftSetListsMaintenancePage } from "./DraftSetListsMaintenancePage";
import { ISetListsStateKeys } from "../../../types/ISetListsState";
import { IProductState } from "../../../../redux/types/IProductState";
import DraftSetListsMaintenancePageScrollListener from "./DraftSetListsMaintenancePageScrollListener";

export default connect(
    (state: IAppState) => ({
        draftSetListsMaintenanceGeneralDataViewData: state.membersPortal.componentData.DraftSetListsMaintenanceGeneralDataView,
        draftSetListsMaintenanceToolbarData:
            state.membersPortal.componentData.DraftSetListsMaintenanceToolbar,
        draftWorkMaintenanceSaveResultData: state.membersPortal.componentData.SaveResult,
        dataGridTableData: state.membersPortal.componentData.DataGridTable,
        draftSetListsMaintenanceGridsViewData: state.membersPortal.componentData.DraftSetListsMaintenanceGridsViewData,
        setList: (state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState.setLists),
        activeTab: state.membersPortal.activeTab,
        tabs: state.membersPortal.tabs,
        lookupValues: state.lookupEntities,
        dataSource: state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState!.setLists && state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState!.dataSource,
        paginationDetails: state.dataIngestion.paginationDetails,
        stepsCurrent: state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].registerStepsCurrent,
        currentUser: state.account.currentUser,
        portalType: state.account.portalType,
        currentUserNameNumber: state.account.currentUserNameNumber
    }),
    dispatch => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        hideModal: () => dispatch(hideModal()),
        updateDraftSetListsField: (value: any, fieldName: ISetListsStateKeys, index?: number) =>
            dispatch(updateDraftSetListsField(value, fieldName, index)),
        saveChanges: (draftSetLists: IProductState, draftSetListsMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, lookupValues: ILookupDictionary) =>
            dispatch<any>(saveDraftSetListThunk(draftSetLists, draftSetListsMaintenanceGeneralDataViewData, lookupValues)),
        addNewDraftSetList: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftSetListsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch(createNewDraftSetLists(lookups, currentUserNameNumber, isNew, draftSetListsMaintenanceGeneralDataViewData, formats)),
        deleteDraftSetList: (dataSource: string, setListId: number, activeTab: number) =>
            dispatch<any>(deleteDraftSetListThunk(dataSource, setListId, activeTab)),
        showModal: (modalContent: string, modalComponentInstance: string, modalProps: any, displayModalCloseButton: boolean, title: string, modalMessage?: string, isReadOnly?: boolean) =>
            dispatch<any>(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title, modalMessage, isReadOnly)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        copyDraftSetList: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => dispatch<any>(copyExistingDraftSetList(actionList, userName, lookups)),
        showMessage: (props: IPopUpInfoProps) => dispatch(showMessage(props)),
        undoDraftSetListChanges: (dataSource: string, setListId: number, lookups: ILookupDictionary) => dispatch<any>(undoDraftSetListChangesThunk(dataSource, setListId, lookups)),
        setChangesMade: (tabIndex: number, changesMade: boolean) => dispatch(mp_setChangesMade(tabIndex, changesMade)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        setWarningMessage: (displayWarning: boolean) => dispatch(setWarningMessage(displayWarning)),
        undoDraftSetList: (dataSource: string, setListId: number, lookupValues: ILookupDictionary) =>
            dispatch<any>(undoDraftSetListChangesThunk(dataSource, setListId, lookupValues)),
        updatePagination: (resultsPerPage: number, currentPage: number, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),
        stepsSetCurrent: (current: number) => dispatch(registerStepsSetCurrent(current)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
    })
)(DraftSetListsMaintenancePageScrollListener);