import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { createNewUsagePool } from "../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ISourceSearchResult } from "../../settings/types/ISourceSearchResult";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";
import SearchView from "../components/searchView/SearchView";
import { ALL_ROLES, SEARCH_VIEW_POOLS, SIMPLE, UPDATE_POOLS_ROLE, VIEW_POOLS_ROLE } from "../Consts";
import { IUsagePool } from "../types/usageTypes/IUsagePool";
import { IUsagePoolSearchQuery } from "../types/usageTypes/IUsagePoolSearchQuery";
import { ITreeData } from "../types/ITreeData";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { msalConfig } from "../../accounts/types/msalAuthConfig";

export interface IPoolsPageProps {
    usagePoolsPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchUsagePools: (query: IUsagePoolSearchQuery) => void;
    usagePoolsSearchResults: IUsagePool[];
    searchSuccessful: boolean;
    usagePoolGeneralDataView: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    createNewUsagePool: typeof createNewUsagePool;
    getUsagePool: (poolId: number, matchingSources: ITreeData[]) => void;
    resetPagination: (repertoireSection: string) => void;
    getSources: () => void;
    sources?: ITreeData[];
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
}

const PoolsPage = (props: IPoolsPageProps) => {
    const [searchType, setSearchType] = React.useState(SIMPLE)
    const [loaded, setLoaded] = React.useState(false)
    const [selectedSource, setSelectedSource] = React.useState(null)
    const [sourcesState, setSourcesState] = React.useState(null)
    const [treeNodes, setTreeNodes] = React.useState(null)
    const [selectedNode, setSelectedNode] = React.useState(null)
    const [topLevelSource, setTopLevelSource] = React.useState(null)
    const [treeData, setTreeData] = React.useState([])
    const { instance, accounts } = useMsal()

    const addNewUsagePool = (): void => {
        const {createNewUsagePool, lookupValues, usagePoolGeneralDataView} = props;
        createNewUsagePool(lookupValues, true, usagePoolGeneralDataView);
    }

    React.useEffect(() => {
        const { getSources } = props;
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getSources();
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])

    const checkIfLoaded = () => {
        const { usagePoolsPageData, sources, getSources } = props;
        
        if (usagePoolsPageData.fields && usagePoolsPageData.fields.length >= 1 && sources && sources.length > 0 && !loaded) {
            setLoaded(true);
            const header = usagePoolsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

        const {
            searchViewData,
            searchResultsTableData,
            searchUsagePools,
            usagePoolsSearchResults,
            searchSuccessful,
            usagePoolGeneralDataView,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            updatePagination,
            lookupValues,
            getUsagePool,
            sources,
            sortSearchResults
        } = props;

        const getUsagePoolDetails = (usagePoolID: number) => {
            getUsagePool(usagePoolID, sources);
        }

        const getUpdateRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(UPDATE_POOLS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const getViewRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(VIEW_POOLS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        if (loaded) {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <span className="title">Find Pool</span>
                            <div className="newWorkButton" title="Create New Pool" hidden={getUpdateRoleDisabled()}>
                                <div className="IconTextButton" onClick={addNewUsagePool}>
                                    <i className="icon ms-Icon ms-Icon--CircleAddition"
                                        aria-hidden="true">
                                    </i>
                                    New Pool
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <SearchView
                        searchViewData={searchViewData}
                        searchResultsTableData={searchResultsTableData}
                        componentInstance={SEARCH_VIEW_POOLS}
                        modalProps={undefined}
                        dataSource={undefined}
                        hideModal={undefined}
                        lookupValues={lookupValues}
                        searchUsagePools={searchUsagePools}
                        usagePoolsSearchResults={usagePoolsSearchResults}
                        getUsagePoolDetails={getUsagePoolDetails}
                        searchSuccessful={searchSuccessful}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        resetPagination={resetPagination}
                        updatePagination={updatePagination}
                        sortSearchResults={sortSearchResults}
                        searchDisabled={getViewRoleDisabled()}
                    />
                </div>
            );
        }
        return <div>Loading ...</div>


    
}

export default PoolsPage;