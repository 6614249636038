import { connect } from "react-redux";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, resetPagination, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showMessage, showModal, showUpdateFieldsModal, showWorkSubmission, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import {
    mp_addTab,

    createNewDraftWork,
    incrementTempID,
    resetMessageBanner,
    mp_setChangesMade,
    setWarningMessage,
    updateDraftWorkField,
    getDraftWorkDetailsRequest,
    addRepertoireAttachmentSuccess,
    setProgressUploadFileRepertoireProccess,
    addDroppedFile,
    cleanDroppedFilesRepertoireState,
    registerStepsSetCurrent,
    copyExistingDraftWork,
    createNewDraftAgreement,
    updateDraftAgreementField,
    updateDraftAgreementWorkField
} from "../../../../redux/reducers/MembersPortalReducer";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import {
    saveDraftWorkThunk, deleteDraftWorkThunk, undoDraftWorkChangesThunk, getDraftWorkDetailsThunk, removeWorkAttachmentIfExistsThunk, getShareDecimalsToDisplayThunk, refreshWorkDetailsThunk,
    searchDraftAgreementsWorksThunk,
    saveDraftAgreementThunk,
    removeAgreementAttachmentIfExistsThunk,
    refreshAgreementDetailsThunk
} from "../../../../redux/thunks/MembersPortalThunks";
import IAppState from "../../../../redux/types/IAppState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IPopUpInfoProps } from "../../../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import { IYesNoPromptViewModalProps } from "../../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { FormatFields } from "../../../../redux/types/FormatFields";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { getWorkDetailsThunk, searchWorksThunk, updateUserPreferencesThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import { getDataIngestionComponentDataThunk, getFilesystemsThunk, refreshDirectoryThunk, selectedNodeChangedThunk } from "../../../../redux/thunks/DataIngestionThunks";
import IFile from "../../../../redux/types/IFiles";
import { ITreeData } from "../../../../repertoire/types/ITreeData";
import { ITreeNodeData } from "../../../../treeview/types/TreeNodeData";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { getCustomerDefaultTerritoriesThunk, getUserCRMIpNumberByGuidThunk } from "../../../../redux/thunks/AccountThunks";
import DraftAgreementsMaintenancePageScrollListener from "./DraftAgreementsMaintenancePageScrollListener";
import { string } from "yup";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import { IWorksSearchQuery } from "../../../../repertoire/types/IWorksSearchQuery";

export default connect(
    (state: IAppState) => ({
        draftAgreementMaintenanceGeneralDataViewData: state.membersPortal.componentData.DraftAgreementsMaintenanceGeneralDataView,
        draftAgreementMaintenanceToolbarData:
            state.membersPortal.componentData.DraftAgreementsMaintenanceToolbar,
        draftAgreementMaintenanceSaveResultData: state.membersPortal.componentData.SaveResult,
        dataGridTableData: state.membersPortal.componentData.DataGridTable,
        draftAgreementMaintenanceGridsViewData: state.membersPortal.componentData.DraftAgreementsMaintenanceGridsViewData,
        draftAgreementMaintenanceCommentsView: state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState.agreement?.draftAgreementsComments && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState.agreement?.draftAgreementsComments,
        draftAgreementsMaintenanceApplicableWorksView: state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState.agreement?.agreementWorks && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState.agreement.agreementWorks,
        agreements: (state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState.agreement),
        activeTab: state.membersPortal.activeTab,
        tabs: state.membersPortal.tabs,
        lookupValues: state.lookupEntities,
        dataSource: state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState!.agreement && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState!.agreement!.dataSource,
        filesystems: state.dataIngestion.filesystems,
        treeNodes: state.dataIngestion.treeNodes,
        selectedNode: state.dataIngestion.selectedNode,
        storagePaths: state.dataIngestion.storagePaths,
        selectedFileSystem: state.dataIngestion.selectedFileSystem,
        destinations: state.dataIngestion.destinations && state.dataIngestion.destinations.lookups,
        droppedFiles: state.dataIngestion.droppedFiles,
        isDropZone: state.dataIngestion.droppedFiles.inDropZone,
        storagePathItemData: state.dataIngestion.componentData.StoragePathItem,
        distributions: state.dataIngestion.distributions,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        formats: state.dataIngestion.formats,
        paginationDetails: state.dataIngestion.paginationDetails,
        stepsCurrent: state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].registerStepsCurrent,
        currentUser: state.account.currentUser,
        portalType: state.account.portalType,
        currentUserNameNumber: state.account.currentUserNameNumber,
        shareDecimalsToDisplay: state.membersPortal.shareDecimalsToDisplay,
        attachedFiles: state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState!.agreement && state.membersPortal.tabs[state.membersPortal.activeTab].agreementMaintenanceState!.agreement.files,
        index: state.modal.index,
        agreementAttachmentsDropped: state.membersPortal.tabs && state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].droppedFiles,
        defaultTerritories:state.account.defaultTerritories
    }),
    dispatch => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        hideModal: () => dispatch(hideModal()),
        updateDraftAgreementField: (value: any, fieldName: IAgreementStateKeys, index?: number) =>
            dispatch(updateDraftAgreementField(value, fieldName, index)),
        saveChanges: (draftAgreement: IAgreementState, draftAgreementMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, lookupValues: ILookupDictionary, postAgreement: boolean, currentUserNumber: string,defaultTerritories?:any) =>
            dispatch<any>(saveDraftAgreementThunk(draftAgreement, draftAgreementMaintenanceGeneralDataViewData, lookupValues, postAgreement, currentUserNumber,defaultTerritories)),
        addNewDraftAgreement: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftAgreementMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch(createNewDraftAgreement(lookups, currentUserNameNumber, isNew, draftAgreementMaintenanceGeneralDataViewData, formats)),
        showModal: (modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string,
            modalMessage?: string,
            isReadOnly?: boolean,
            key?: string,
            index?: number) =>
            dispatch<any>(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title, modalMessage, isReadOnly,key,index)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        showMessage: (props: IPopUpInfoProps) => dispatch(showMessage(props)),
        undoDraftWorkChanges: (dataSource: string, workId: number, lookups: ILookupDictionary) => dispatch<any>(undoDraftWorkChangesThunk(dataSource, workId, lookups)),
        setChangesMade: (tabIndex: number, changesMade: boolean) => dispatch(mp_setChangesMade(tabIndex, changesMade)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        setWarningMessage: (displayWarning: boolean) => dispatch(setWarningMessage(displayWarning)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        setInDropZone: (inDropZone: boolean) => dispatch(setInDropZone(inDropZone)),
        refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(refreshDirectoryThunk(filesystem, sources)),
        addFileToUpload: (file: IFile, isComplete: boolean) => dispatch<any>(addFileToUpload(file, isComplete)),
        setProgressUploadFileProccess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        cleanUploadFilesState: () => dispatch(CleanUploadFilesState()),
        refreshAgreementDetails: (dataSource: string, agreementID: number, activeTabItem: ITabReduxItem, formats: FormatFields[]) =>
            dispatch<any>(refreshAgreementDetailsThunk(dataSource, agreementID, activeTabItem, formats)),
        getFilesystems: (isRepertoireModule?: boolean) => dispatch<any>(getFilesystemsThunk(isRepertoireModule)),
        selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => dispatch<any>(selectedNodeChangedThunk(node, sources)),
        getDataIngestionComponentData: () => dispatch<any>(getDataIngestionComponentDataThunk()),
        addDroppedFileRepertoire: (file: IFile, inProgress: boolean) => dispatch(addDroppedFile(file, inProgress)),
        setProgressUploadFileRepertoire: (message: string, file: IFile, isUploadComplete: boolean) => dispatch(setProgressUploadFileRepertoireProccess(message, file, isUploadComplete)),
        cleanDroppedFilesRepertoire: () => dispatch(cleanDroppedFilesRepertoireState()),
        updatePagination: (resultsPerPage: number, currentPage: number, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),
        stepsSetCurrent: (current: number) => dispatch(registerStepsSetCurrent(current)),
        getUserCRMIPNumber: (guid: string) => dispatch<any>(getUserCRMIpNumberByGuidThunk(guid)),
        getShareDecimalsToDisplay: () => dispatch<any>(getShareDecimalsToDisplayThunk()),
        searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchDraftAgreementsWorksThunk(query, true)),
        updateDraftAgreementWorkField: (value: any, fieldName: IAgreementStateKeys, index?: number) =>
            dispatch(updateDraftAgreementWorkField(value, fieldName, index)),
        getDraftAgreementWorkDetails: (dataSource: string, draftWorksID: number, lookupValues: ILookupDictionary, draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getDraftWorkDetailsThunk(dataSource, draftWorksID, lookupValues, draftWorkMaintenanceDataViewData, formats)),
        addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => dispatch<any>(addRepertoireAttachmentSuccess(attachedFile)),
        removeAgreementAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => dispatch<any>(removeAgreementAttachmentIfExistsThunk(attachedFile, source)),
        getCustomerDefaultTerritories:() => dispatch<any>(getCustomerDefaultTerritoriesThunk()),
    })
)(DraftAgreementsMaintenancePageScrollListener);