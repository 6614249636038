import { connect } from "react-redux";
import { Dispatch } from "redux";
import { showModal } from "../../redux/reducers/ModalReducer";
import { clearModalSearchResultsThunk } from "../../redux/thunks/ModalThunks";
import IAppState from "../../redux/types/IAppState";
import ViewAsPage from "./ViewAsPage";
import { RouteChildrenProps } from 'react-router';
import { resetPagination, resetViewAsSaveStatus, updatePagination } from "../../redux/reducers/MembersPortalReducer";
import { searchDataChanged, updateSelectionCriteria } from "../../redux/reducers/RepertoireReducer";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { cancelAccessRequestThunk, getMembersPortalComponentDataThunk, getViewAsAccessRequestsThunk, saveViewAsAccessThunk, searchContributorsThunk, searchRequestAccessIPThunk } from "../../redux/thunks/MembersPortalThunks";
import { IViewAsAccessRequest } from "../types/IViewAsAccessRequest";
import { setCurrentUserNameNumber } from "../../redux/reducers/AccountReducer";
import { setCurrentPage } from "../../redux/reducers/HomeReducer";
import { LOADING_VIEW } from "../../repertoire/Consts";


export default connect(
    (state: IAppState, props?: RouteChildrenProps) => ({
        viewAsPageData: state.membersPortal.componentData.ViewAsPage,
        searchSuccessful: state.membersPortal.searchSuccessful,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        menuItems: state.home.menuItems,
        currentUser: state.account.currentUser,
        editableFields: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].editableFields,
        modalMessage: state.modal.modalMessage,
        enabledModules: state.home.enableModules,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        component: state.membersPortal.tabs && state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].component,
        lookupValues: state.lookupEntities,
        viewAsAccessRequests: state.membersPortal.viewAsAccessRequests,
        currentUserNameNumber: state.account.currentUserNameNumber,
        viewAsAccessSaveSuccess: state.membersPortal.viewAsAccessSaveSuccess,
        cancelAccessRequestSuccess: state.membersPortal.cancelAccessRequestSuccess,
        currentUserViewAsName: state.account.currentViewAsUserName,
        displayModal: state.membersPortal.isSearching ? true : state.modal.displayModal,
        modalProps: state.modal.modalProps,
        modalComponentInstance: state.modal.modalComponentInstance,
        modalTitle: state.modal.title,
        displayModalCloseButton: state.modal.displayModalCloseButton,
        isReadOnlyModal: state.modal.isReadOnlyModal,
        modalContent: state.membersPortal.isSearching && !state.membersPortal.isModalSearch ? LOADING_VIEW : state.modal.modalContent,
        loadingViewData: state.membersPortal.componentData.LoadingView,
        yesNoPromptViewData: state.membersPortal.componentData.YesNoPromptView,
        dataGridTableData: state.membersPortal.componentData.DataGridTable,
        dataSource: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState ? state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState.dataSource : undefined,
        draftWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkSearchResult,
        draftAgreementIPSearchResult : state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftAgreementIPSearchResult,
        allRegisteredWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].allRegisteredWorkSearchResult,
    }),
    (dispatch: Dispatch) => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch<any>(clearModalSearchResultsThunk()),
        getMembersPortalFields: () => dispatch<any>(getMembersPortalComponentDataThunk()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        searchDataChanged: (field: string, value: any) => dispatch(searchDataChanged(field, value)),
        updateSelectionCriteria: (distributionPoolId: number, value: any) => dispatch<any>(updateSelectionCriteria(distributionPoolId, value)),
        searchContributors: (query: IContributorSearchQuery) => dispatch<any>(searchRequestAccessIPThunk(query)),
        getViewAsRequests: (userName: string) => dispatch<any>(getViewAsAccessRequestsThunk(userName)),
        saveViewAsAccess: (viewAsAccounts: IViewAsAccessRequest[], userName: string) => dispatch<any>(saveViewAsAccessThunk(viewAsAccounts, userName)),
        setCurrentUserNameNumber: (nameNumber: string, userName:string) => dispatch<any>(setCurrentUserNameNumber(nameNumber, userName)),
        setCurrentPage: (link: string) => dispatch(setCurrentPage(link)),
        cancelAccessRequest: (ipiNameNumber: string, userName: string) => dispatch<any>(cancelAccessRequestThunk(ipiNameNumber,userName)),
        resetViewAsSaveStatus: () => dispatch<any>(resetViewAsSaveStatus())
    })
)(ViewAsPage);