import React, { Component } from "react";
import {
    DELETE_WORK_PROMPT_STATE_KEY,
    WORK_MAINTENANCE_TOOLBAR,
    COPY_METHOD_TOOLBAR,
    UNDO_METHOD_TOOLBAR,
    MERGE_METHOD_TOOLBAR,
    ADD_METHOD_TOOLBAR,
    MATCH_METHOD_TOOLBAR,
    DELETE_METHOD_TOOLBAR,
    SAVE_METHOD_TOOLBAR,
    HISTORY_METHOD_TOOLBAR,
    UNDO_CHANGES_ACCOUNT_KEY,
    ADD_NEW_WORK_STATE_KEY,
    COPY_WORK_STATE_KEY,
    ADD_TO_PACKAGE_KEY,
    MERGE_WORK_STATE_KEY,
    AUTO_MATCH_WORK_STATE_KEY,
    SAVE_CHANGES_STATE_KEY,
    DELETE_WORK_STATE_KEY,
    VERSION_HISTORY_STATE_KEY,
    MERGE_WORK_PROMPT_STATE_KEY,
    MERGE_WORK_WARNING_PROMPT_STATE_KEY,
    DELETE_WORKS_ROLE,
    ALL_ROLES,
    UPDATE_WORKS_ROLE,
    ADD_TO_PACKAGES_TOOLBAR,
    OPEN_SEARCH_WORK_STATE_KEY
} from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { cancelWorkflowSession, clearModalSearchResults, nextWorkflowInSession } from "../../../../redux/reducers/RepertoireReducer";
import { showYesNoPrompt, hideModal, showMessage } from "../../../../redux/reducers/ModalReducer";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { DataSource } from "../../../types/DataSource";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { SaveOption } from "../../../types/SaveOption";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IPopUpInfoProps } from "../../../components/modalViews/popUpInfo/PopUpInfo";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { IWorkflow } from "../../../types/IWorkflow";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";
import { EntityType } from "../../../types/EntityType";

export interface IWorkMaintenanceToolbarProps {
    changesMade: boolean;
    copyExistingWork: () => void;
    showYesNoPrompt: typeof showYesNoPrompt;
    showMessage: typeof showMessage;
    hideModal: typeof hideModal;
    undoChanges: () => void;
    deleteWork: () => void;
    addNewWork: (lookups: ILookupDictionary, actionData?: IDataActionToolbar[]) => void;
    autoMatchWork: (matchWork: boolean) => void;
    resetMessageBanner: () => void;
    saveChanges: (saveOption: SaveOption) => void;
    mergeWork?: (workIds: number[]) => void;
    searchVersionHistory: (workId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[], otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataSource: DataSource, paneIndex: number) => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData: IRepertoireComponentDataItem;
    work: IWorkState;
    activeTab: number;
    lookupValues: ILookupDictionary;
    tabs: ITabReduxItem[];
    dataSource: DataSource;
    isReadonly: boolean;
    workflowFieldsData: IRepertoireComponentDataItem;
    currentUser?: string;
    isWorkflowSessionStarted?: boolean;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    currentWorkflowIndex?: number;
    dataActionList?: IDataActionToolbar[];
    workFormats?: FormatFields[];
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    isOpenForWorkflowSession?: boolean;
    skipUsageWorkflowItem: (activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    refreshWorkDetails?: () => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    roles?: string[];
    completeWorkflowOnSave: (value: boolean) => void;
    customer?: string;
    addToPackages?:() =>void;
    paneIndex?: number;
    areTwoPanesOpen?: boolean;
}

export type WorkMaintenanceToolbarKeys = keyof IWorkMaintenanceToolbarProps;

class WorkMaintenanceToolbar extends Component<
    IWorkMaintenanceToolbarProps,
    null
> {

    constructor(props) {
        super(props);
    }

    searchVersionHistory = () => {
        const { searchVersionHistory, clearModalSearchResults, work, lookupValues, tabs, activeTab, dataSource, paneIndex } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        clearModalSearchResults();
        searchVersionHistory(work.workID, lookupValues, actionList, tabs[activeTab].formatFields, tabs[activeTab].otherIndicatorWorkFlagTypes, tabs[activeTab].readonlyIndicatorWorkFlagTypes, dataSource, paneIndex);
    };

    deleteWorkAction = () => {
        const { deleteWork, toolbarData, showYesNoPrompt, hideModal } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === DELETE_WORK_PROMPT_STATE_KEY).data;
        const propsModal: IYesNoPromptViewModalProps = {
            yesCallback: () => { deleteWork(); hideModal(); },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, propsModal);
    }

    saveChangesAction = (saveOption: SaveOption) => {
        const { saveChanges } = this.props;
        saveChanges(saveOption)
    }

    setActionList = () => {
        const {
            tabs,
            activeTab
        } = this.props;

        const actionList = { ...tabs[activeTab] };
        const newActionList: IDataActionToolbar[] = [];

        actionList.dataActionToolbar.map(action => {
            newActionList.push(this.setActionAndName(action));
        });

        return newActionList;
    }

    copyWork() {
        const { copyExistingWork } = this.props;

        copyExistingWork();
    }

    addNew() {
        const { addNewWork, lookupValues, tabs, activeTab } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        addNewWork(lookupValues, actionList)
    }

    mergeWorksAction = () => {
        const { mergeWork, toolbarData, showYesNoPrompt, hideModal, tabs, activeTab, showMessage } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === MERGE_WORK_PROMPT_STATE_KEY).data;

        if (tabs[activeTab].workMaintenanceState.work.mergeThisWork !== undefined && tabs[activeTab].workMaintenanceState.work.mergeThisWork !== false) {

            if (tabs.length > 1) {
                const worksToMerge = [...tabs];
                worksToMerge.splice(activeTab, 1);

                var filteredWorksToMerge = worksToMerge.filter(x => x.workMaintenanceState != undefined
                    && x.workMaintenanceState.work.mergeThisWork != undefined
                    && x.workMaintenanceState.work.mergeThisWork == true);

                if (filteredWorksToMerge.length == 1) {
                    var result = filteredWorksToMerge.map(x => ("(" + x.workMaintenanceState.work.workID + ") " + x.workMaintenanceState.work.titles.find(y => y.type.value === "OT").title.value));

                    var workIds = filteredWorksToMerge.map(x => x.workMaintenanceState.work.workID);

                    const propsModal: IYesNoPromptViewModalProps = {
                        yesCallback: () => { mergeWork(workIds); hideModal(); },
                        noCallback: () => { hideModal(); }
                    }
                    showYesNoPrompt(title, propsModal, result.toString());
                }
                else {

                    const warning: string = toolbarData.fields.find(f => f.name === MERGE_WORK_WARNING_PROMPT_STATE_KEY).data;
                    const propsModal: IPopUpInfoProps = {
                        textToScreen: warning
                    }

                    showMessage(propsModal);
                }
            }

        }
    }

    getDeleteRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(DELETE_WORKS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_WORKS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    AddtoPackage= () => {
        const { addToPackages } = this.props;
        addToPackages();
    }

    setActionAndName(action: IDataActionToolbar) {
        const {
            undoChanges,
            mergeWork,
            addNewWork,
            autoMatchWork,
            toolbarData: { fields },
            tabs,
            activeTab,
        } = this.props;

        switch (action.method) {

            case COPY_METHOD_TOOLBAR: {
                return {
                    action: this.copyWork.bind(this),
                    name: fields.find(copy => copy.name === COPY_WORK_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--Copy',
                    isReadOnly: this.getUpdateRoleDisabled()
                }
            }

            case UNDO_METHOD_TOOLBAR: {
                return {
                    action: undoChanges,
                    name: fields.find(merge => merge.name === UNDO_CHANGES_ACCOUNT_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--Undo',
                    isReadOnly: this.getUpdateRoleDisabled()
                }

            }

            case MERGE_METHOD_TOOLBAR: {
                return {
                    action: this.mergeWorksAction,
                    name: fields.find(merge => merge.name === MERGE_WORK_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--BranchMerge',
                    isReadOnly: this.getUpdateRoleDisabled()
                }
            }

            case ADD_METHOD_TOOLBAR: {
                return {
                    action: this.addNew.bind(this),
                    name: fields.find(add => add.name === ADD_NEW_WORK_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--CircleAddition',
                    isReadOnly: this.getUpdateRoleDisabled()
                }
            }

            case MATCH_METHOD_TOOLBAR: {
                return {
                    action: autoMatchWork,
                    name: fields.find(match => match.name === AUTO_MATCH_WORK_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--TriggerAuto',
                    isReadOnly: this.getUpdateRoleDisabled()
                }
            }

            case DELETE_METHOD_TOOLBAR: {
                return {
                    action: this.deleteWorkAction,
                    name: fields.find(match => match.name === DELETE_WORK_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--Delete',
                    isReadOnly: this.getDeleteRoleDisabled() || this.getUpdateRoleDisabled()
                }
            }

            case SAVE_METHOD_TOOLBAR: {
                return {
                    action: this.saveChangesAction,
                    name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: '',
                    isReadOnly: this.getUpdateRoleDisabled()
                }
            }
            case ADD_TO_PACKAGES_TOOLBAR: {
                return {
                    action: this.AddtoPackage,
                    name: fields.find(addPackage => addPackage.name === ADD_TO_PACKAGE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--AppIconDefaultAdd',
                    isReadOnly: this.getUpdateRoleDisabled()
                }
            }
            case HISTORY_METHOD_TOOLBAR: {
                return {
                    action: this.searchVersionHistory,
                    name: fields.find(save => save.name === VERSION_HISTORY_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--History'
                }
            }
        }
    }


    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            clearModalSearchResults,
            scroll,
            toolbarWidth,
            saveResultData,
            work,
            activeTab,
            lookupValues,
            tabs,
            dataSource,
            isReadonly,
            toolbarData,
            workflowFieldsData,
            isWorkflowSessionStarted,
            nextWorkflowItem,
            cancelWorkflowSession,
            skipWorkflowItem,
            workflowSessionItems,
            currentWorkflowIndex,
            workMaintenanceGeneralDataViewData,
            workFormats,
            isOpenForWorkflowSession,
            skipUsageWorkflowItem,
            saveWorkflow,
            addToPackages,
            refreshWorkDetails,
            roles,
            completeWorkflowOnSave,
            customer,
            areTwoPanesOpen,
        } = this.props;

        let {
            changesMade,
        } = this.props;

        let changesMadeAndFormValid = !this.getUpdateRoleDisabled() && changesMade

        const actionList = this.setActionList()
        const actionFilter = areTwoPanesOpen ? ['Version History','Add to Package','Copy Work'] : [];

        return (
            <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    resetMessageBanner={resetMessageBanner}
                    changesMade={changesMadeAndFormValid}
                    work={work}
                    activeTab={activeTab}
                    lookupValues={lookupValues}
                    tabs={tabs}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    dataSource={dataSource}
                    clearModalSearchResults={clearModalSearchResults}
                    isReadonly={isReadonly}
                    dataActionToolbar={actionList.filter(
                        action =>
                        !actionFilter.includes(action.name)
                    )}
                    toolbarData={toolbarData}
                    component={WORK_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].workMaintenanceState.isNew}
                    workflowFieldsData={workflowFieldsData}
                    isWorkflowSessionStarted={isWorkflowSessionStarted}
                    nextWorkflowItem={nextWorkflowItem}
                    skipWorkflowItem={skipWorkflowItem}
                    cancelWorkflowSession={cancelWorkflowSession}
                    secondaryActions={actionList.filter(
                        action =>
                        actionFilter.includes(action.name)
                    )}
                    showSecondaryActions={areTwoPanesOpen}
                    workflowSessionItems={workflowSessionItems}
                    workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                    currentWorkflowIndex={currentWorkflowIndex}
                    workFormats={workFormats}
                    isOpenForWorkflowSession={isOpenForWorkflowSession}
                    skipUsageWorkflowItem={skipUsageWorkflowItem}
                    refreshWorkDetails={refreshWorkDetails}
                    saveWorkflow={saveWorkflow}
                    addToPackages={addToPackages}
                    roles={roles}
                    completeWorkflowOnSave={completeWorkflowOnSave}
                    customer={customer}
                />
            </div>
        );
    }
}

export default WorkMaintenanceToolbar;