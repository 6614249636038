import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import {
    SEARCH_VIEW_DRAFT_SET_LISTS,
} from "../Consts";
import { IDraftSetListSearchQuery } from "../types/IDraftSetListSearchQuery";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { IDraftSetListSearchResult } from "../types/IDraftSetListSearchResult";
import { setDataSource } from "../../redux/reducers/MembersPortalReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { DataSource } from "../../repertoire/types/DataSource";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { IMemberProductSearchResult } from "../types/IMemberProductSearchResult";
import DraftSetListSearchView from "./components/DraftSetListSearchView";
import { SearchRequests } from "../services/SearchRequests";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { FormatFields } from "../../redux/types/FormatFields";

export interface ISetListsPageProps {
    draftSetListsMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    setListsPageData?: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchDraftSetLists?: (query: IDraftSetListSearchQuery) => void;
    draftSetListSearchResults?: IDraftSetListSearchResult[];
    memberSetListSearchResults?: IMemberProductSearchResult[];
    searchSuccessful: boolean;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    setDataSource: typeof setDataSource;
    dataSource: string;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    currentUserNameNumber?: string;
    memberPortalSearchType: MemberPortalSearchTypeItem;
    getDraftSetListDetails: (dataSource: string, productCoreId: number, lookupValues: ILookupDictionary, formats?: FormatFields[]) => void;
    addNewDraftSetLists: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftSetListsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
}

const DraftSetListsPage = (props: ISetListsPageProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const checkIfLoaded = () => {
        const { setListsPageData } = props;

        if (setListsPageData.fields && setListsPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = setListsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }


    let currentDataSource = "";
    switch (props.memberPortalSearchType.code) {
        case MemberPortalSearchType.MyDraftSearch.code:
            setDataSource(DataSource.Intray);
            currentDataSource = DataSource.Intray;
            break;
        case MemberPortalSearchType.MyRegisteredSearch.code:
            setDataSource(DataSource.Repertoire);
            currentDataSource = DataSource.Repertoire;
            break;
    }


    const addNewDraftSetLists = (): void => {
        const { addNewDraftSetLists, lookupValues, draftSetListsMaintenanceGeneralDataViewData, currentUserNameNumber } = props;
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                addNewDraftSetLists(lookupValues, currentUserNameNumber, true, draftSetListsMaintenanceGeneralDataViewData, formats);
            });
    }

    if (loaded) {
        const {
            setListsPageData,
            searchViewData,
            searchResultsTableData,
            searchDraftSetLists,
            searchSuccessful,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,

            sortSearchResults,
            lookupValues,
            currentUserNameNumber
        } = props;


        let searchPageLabelTitle = null;
        if (setListsPageData && setListsPageData.fields.length > 0) {
            searchPageLabelTitle = setListsPageData.fields.find((v, i, a) => v.name == "searchPageLabelTitle").data;
        }

        let searchResults = null

        switch (props.memberPortalSearchType.code) {
            case MemberPortalSearchType.MyDraftSearch.code:
                searchResults = props.draftSetListSearchResults;
                break;

            case MemberPortalSearchType.MyRegisteredSearch.code:
                searchResults = props.memberSetListSearchResults;
                break;
        }
        
        const getDraftSetListDetails = (dataSource: string, productCoreId: number) => {
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getDraftSetListDetails(dataSource, productCoreId, lookupValues, formats);
                });
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">{searchPageLabelTitle}</span>
                        <div className="newWorkButton" title="Register New Work">
                            <div className="IconTextButton" onClick={addNewDraftSetLists}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                Register New Set List
                            </div>
                        </div>
                    </div>
                </div>
                <DraftSetListSearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_DRAFT_SET_LISTS}
                    modalProps={undefined}
                    dataSource={currentDataSource}
                    hideModal={undefined}
                    lookupValues={lookupValues}
                    searchDraftSetLists={searchDraftSetLists}
                    draftSetListsSearchResults={searchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={false}
                    getDraftSetListDetails={getDraftSetListDetails}
                    memberPortalSearchType={props.memberPortalSearchType}
                    currentUserNameNumber={currentUserNameNumber}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default DraftSetListsPage;
