import * as React from "react";
import { ALL_REGISTERED_WORKS_PAGE, DRAFT_WORKS_MAINTENANCE_PAGE, DRAFT_WORKS_PAGE, MEMBER_STATEMENTS_DETAILS_PAGE, MEMBER_STATEMENTS_PAGE, MEMBER_STATEMENTS_USAGE_DETAILS_PAGE, REGISTERED_WORKS_MAINTENANCE_PAGE, DRAFT_SET_LISTS_PAGE, REGISTERED_WORKS_PAGE, REGISTERED_SET_LISTS_PAGE, DRAFT_SET_LISTS_MAINTENANCE_PAGE, LIVE_PERFORMANCE_SUBMISSION_PAGE, SUBMIT_NEW_LIVE_PERFORMANCE_PAGE, LIVE_PERFORMANCE_MAINTENANCE_PAGE, REGISTERED_WORKS_VIEW_PAGE, UNPAID_CLAIMS_PAGE, CLAIMS_HISTORY_PAGE, DRAFT_AGREEMENTS_PAGE, DRAFT_AGREEMENTS_MAINTENANCE_PAGE, REGISTERED_AGREEMENTS_PAGE } from "../../../membersportal/Consts";
import DraftWorksPageContainer from "../../../membersportal/draftWorks/DraftWorksPageContainer";
import DraftWorksMaintenancePageContainer from "../../../membersportal/draftWorks/components/draftWorksMaintenancePage/DraftWorksMaintenancePageContainer";
import { displayRepresentationFlag } from "../../../redux/reducers/RepertoireReducer";
import RegisteredWorksPageContainer from "../../registeredWorks/RegisteredWorksPageContainer";
import AllRegisteredWorksPageContainer from "../../allRegisteredWorks/AllRegisteredWorksPageContainer";
import MemberStatementsPageContainer from "../../statements/MemberStatementsPageContainer";
import MemberStatementDetailsPageContainer from "../../statements/components/statementDetailsPage/MemberStatementDetailsPageContainer";
import MemberStatementUsageDetailsPageContainer from "../../statements/components/statementDetailsPage/MemberStatementUsageDetailsPageContainer";
import DraftSetListsPageContainer from "../../draftSetLists/DraftSetListsPageContainer";
import RegisteredSetListsPageContainer from "../../registeredSetLists/RegisteredSetListsContainer";
import DraftSetListsMaintenancePageContainer from "../../draftSetLists/components/draftSetListsMaintenancePage/DraftSetListsMaintenancePageContainer";
import LivePerformanceSubmissionContainer from "../../livePerformanceSubmission/LivePerformanceSubmissionContainer";
import RegisteredWorksViewPageContainer from "../../registeredWorks/components/registeredWorksViewPage/RegisteredWorksViewPageContainer";
import LivePerformanceMaintenanceContainer from "../../livePerformanceSubmission/components/livePerformanceMaintenancePage/LivePerformanceMaintenanceContainer";
import UnpaidClaimsMembersPageContainer from "../../unpaidClaims/UnpaidClaimsMembersPageContainer";
import ClaimsHistoryPageContainer from "../../claimsHistory/ClaimsHistoryPageContainer";
import DraftAgreementsPageContainer from "../../draftAgreements/DraftAgreementsPageContainer";
import DraftAgreementsMaintenancePageContainer from "../../draftAgreements/components/draftAgreementsMaintenancePage/DraftAgreementsMaintenancePageContainer";
import RegisteredAgreementsPageContainer from "../../registeredAgreements/RegisteredAgreementsPageContainer";
import { MY_PACKAGES_PAGE } from "../../../repertoire/Consts";
import MyPackagePageContainerMembers from "../../../repertoire/myPackages/MyPackagePageContainerMembers";

interface ITabComponentProps {
    component: string;
    displayRepresentation: typeof displayRepresentationFlag;
}

const TabComponent: React.FC<ITabComponentProps> = ({ component }) => {
 
    const components = {
        [DRAFT_WORKS_PAGE.component]: DraftWorksPageContainer,
        [DRAFT_AGREEMENTS_PAGE.component]: DraftAgreementsPageContainer,
        [REGISTERED_AGREEMENTS_PAGE.component]: RegisteredAgreementsPageContainer,
        [DRAFT_WORKS_MAINTENANCE_PAGE.component]: DraftWorksMaintenancePageContainer,
        [DRAFT_AGREEMENTS_MAINTENANCE_PAGE.component]: DraftAgreementsMaintenancePageContainer,
        [REGISTERED_WORKS_VIEW_PAGE.component]: RegisteredWorksViewPageContainer,
        [REGISTERED_WORKS_MAINTENANCE_PAGE.component]: RegisteredWorksViewPageContainer,
        [REGISTERED_WORKS_PAGE.component]: RegisteredWorksPageContainer,
        [ALL_REGISTERED_WORKS_PAGE.component]: AllRegisteredWorksPageContainer,
        [MEMBER_STATEMENTS_PAGE.component]: MemberStatementsPageContainer,
        [MEMBER_STATEMENTS_DETAILS_PAGE.component]: MemberStatementDetailsPageContainer,
        [MEMBER_STATEMENTS_USAGE_DETAILS_PAGE.component]: MemberStatementUsageDetailsPageContainer,
        [DRAFT_SET_LISTS_PAGE.component]: DraftSetListsPageContainer,
        [UNPAID_CLAIMS_PAGE.component]: UnpaidClaimsMembersPageContainer,
        [DRAFT_SET_LISTS_MAINTENANCE_PAGE.component]:DraftSetListsMaintenancePageContainer,
        [REGISTERED_SET_LISTS_PAGE.component]:RegisteredSetListsPageContainer,
        [LIVE_PERFORMANCE_SUBMISSION_PAGE.component]:LivePerformanceSubmissionContainer, 
        [LIVE_PERFORMANCE_MAINTENANCE_PAGE.component]:LivePerformanceMaintenanceContainer,   
        [CLAIMS_HISTORY_PAGE.component]: ClaimsHistoryPageContainer,  
        [MY_PACKAGES_PAGE.component]: MyPackagePageContainerMembers,
    };
    const TagName = components[component];
    
    // @ts-ignore
     return <TagName />;
};

export default TabComponent;
