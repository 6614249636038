import * as React from "react";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showModal } from "../../../redux/reducers/ModalReducer";
import { cancelWorkflowSession, nextWorkflowInSession, skipWorkflowInSession } from "../../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../../redux/types/FormatFields";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { WORKFLOW_ASSIGNED_TO, WORKFLOW_COLLAPSE, WORKFLOW_COMPLETE_ON_SAVE, WORKFLOW_CREATED_DATE, WORKFLOW_EXPAND, WORKFLOW_INPROGRESS, WORKFLOW_MESSAGE, WORKFLOW_DIRECT_COMPLETE_ON_SAVE, ALL_ROLES, VIEW_WORKFLOWS_ROLE, UPDATE_WORKFLOWS_ROLE, WORKFLOW_APPROVE, WORKFLOW_REJECT, CLAIM_WORKFLOW, WORKFLOW_PAGE_TOOLBAR, REJECT_UP_WORKFLOW } from "../../Consts";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";
import { IWorkflow } from "../../types/IWorkflow";
import { IWorkflowParams } from "../../types/IWorkFlowParams";
import { IWorkflowSearchResult } from "../../types/IWorkflowSearchResult";
import DateDisplay from "../dateDisplay/DateDisplay";

interface IWorkflowBannerProps {
    workflows: IWorkflow[];
    workflowFieldsData: IRepertoireComponentDataItem;
    isWorkflowSessionStarted?: boolean;
    tabs: ITabReduxItem[];
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    skipUsageWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    lookupValues?: ILookupDictionary;
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem
    workFormats?: FormatFields[];
    currentWorkflowIndex: number;
    activeTab: number;
    workflowSessionItems?: IWorkflowSearchResult[];
    dataActionList?: IDataActionToolbar[];
    isOpenForWorkflowSession?: boolean;
    refreshDetails?: () => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    roles?: string[];
    updateWorkflowAndEntityStatus?: (workflowparms: IWorkflowParams, approvalStatus: boolean, refreshDetails?: () => void) => void;
    updateClaimWorkflowAndEntityStatus?: (workflowparms: IWorkflowParams, callFrom: string, refreshDetails?: () => void) => void;
    completeWorkflowOnSave?: (value: boolean) => void;
    skipProductWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , dataActions: IDataActionToolbar[]
        , formats: FormatFields[]
        ) =>void;
}


interface IWorkflowBannerState {
    checkboxChecked: boolean;
    expandAll: boolean;
}

export class WorkflowBanner extends React.Component<IWorkflowBannerProps, IWorkflowBannerState>{
    constructor(props: IWorkflowBannerProps) {
        super(props);

        this.state = {
            checkboxChecked: true,
            expandAll: true
        };
    }

    sortByDateDesc(a: any, b: any) {
        return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
    }

    completeWorkflowOnSave(event: React.ChangeEvent<HTMLInputElement>, workflow: IWorkflow) {

        var { completeWorkflowOnSave } = this.props;

        if (event.target.checked) {
            completeWorkflowOnSave(true);
        }
        else {
            completeWorkflowOnSave(false);
        }
    }

    onSaveCompleteWorkflow(workflow: IWorkflow) {
        workflow.status = "Completed";
        const { saveWorkflow, refreshDetails, workflowSessionItems, workflows, activeTab, currentWorkflowIndex, lookupValues,
            workFormats, dataActionList, tabs } = this.props;
        workflows.sort(this.sortByDateDesc);
        let checkWorkFlowSession: boolean = false;
        let filteredworkflow = workflows.filter(n => { return n.id != workflow.id; });
        checkWorkFlowSession = filteredworkflow.length == 0 && workflowSessionItems.length > 0;
        saveWorkflow({
            workflow, activeTab,
            currentWorkflowIndex: currentWorkflowIndex + 1,
            workflowSessionItems,
            isWFSession: checkWorkFlowSession,
            lookupValues, workFormats, dataActionList,
            otherIndicatorsWorkFlagTypes: tabs[activeTab].otherIndicatorWorkFlagTypes,
            readonlyIndicatorWorkFlagTypes: tabs[activeTab].readonlyIndicatorWorkFlagTypes
        });
        if (!checkWorkFlowSession) {
            refreshDetails()
        }
    }

    showRejectReasonModal(workflow: IWorkflow) {
        const { showModal, workflowFieldsData, } = this.props;
        const title = workflowFieldsData.fields?.find(f => f.name === 'workflowRejectReasonTitle')?.data;
        const modalProps = {
            workflowFieldsData: workflowFieldsData,
            workflow: workflow,
            onApproveOrReject: this.onApproveOrReject.bind(this),
            title: title
        };
        showModal(
            REJECT_UP_WORKFLOW, WORKFLOW_PAGE_TOOLBAR, modalProps, true, title
        );
    }

    onApproveOrReject(workflow: IWorkflow, approved: boolean) {
        const { updateWorkflowAndEntityStatus, updateClaimWorkflowAndEntityStatus, refreshDetails, workflowSessionItems, workflows, activeTab, currentWorkflowIndex, lookupValues,
            workFormats, dataActionList, tabs } = this.props;

        workflows.sort(this.sortByDateDesc);
        if (workflow.entityType === "Usage (Live Performance)") {
            workflow.status = "Completed";
            let checkWorkFlowSession: boolean = false;
            let filteredworkflow = workflows.filter(n => { return n.id != workflow.id; });
            checkWorkFlowSession = filteredworkflow.length == 0 && workflowSessionItems?.length! > 0;
            updateWorkflowAndEntityStatus!({
                workflow, activeTab,
                currentWorkflowIndex: currentWorkflowIndex + 1,
                workflowSessionItems,
                isWFSession: checkWorkFlowSession,
                lookupValues, workFormats, dataActionList,
                otherIndicatorsWorkFlagTypes: tabs[activeTab].otherIndicatorWorkFlagTypes!,
                readonlyIndicatorWorkFlagTypes: tabs[activeTab].readonlyIndicatorWorkFlagTypes!
            },
                approved,
                refreshDetails)
        } else {
            if (approved) {
                workflow.status = "Approved";
                workflow.rejectReason = null;
            } else {
                workflow.status = "Rejected";
            }
            let checkWorkFlowSession: boolean = false;
            let filteredworkflow = workflows.filter(n => { return n.id != workflow.id; });
            checkWorkFlowSession = filteredworkflow.length == 0 && workflowSessionItems?.length! > 0;
            updateClaimWorkflowAndEntityStatus({
                workflow, activeTab,
                currentWorkflowIndex: currentWorkflowIndex + 1,
                workflowSessionItems,
                isWFSession: checkWorkFlowSession,
                lookupValues, workFormats, dataActionList,
                otherIndicatorsWorkFlagTypes: tabs[activeTab].otherIndicatorWorkFlagTypes!,
                readonlyIndicatorWorkFlagTypes: tabs[activeTab].readonlyIndicatorWorkFlagTypes!
            }, CLAIM_WORKFLOW,
                refreshDetails)
        }

    }

    expandWorkflows(event: React.MouseEvent<HTMLElement>) {

        this.setState({
            expandAll: !this.state.expandAll
        });
    }

    renderExpandAll = () => {
        const { workflowFieldsData } = this.props;

        const expandText = workflowFieldsData.fields.find(e => e.name === WORKFLOW_EXPAND).data;
        const collapseText = workflowFieldsData.fields.find(e => e.name === WORKFLOW_COLLAPSE).data;

        return (
            <div className='arrowIconExpandDiv'>
                <div className={this.state.expandAll ? "arrowIconExpandAllExpanded" : "arrowIconExpandAll"}>
                    <i className="icon ms-Icon ms-Icon--DoubleChevronLeft" aria-hidden="true" id="expandResultsButton" onClick={(event) => this.expandWorkflows(event)} title={this.state.expandAll ? collapseText : expandText}></i>
                </div>
                {this.state.expandAll ? <div className='arrowIconExpandText'>{collapseText}</div> : <div className='arrowIconExpandText'>{expandText}</div>}
            </div>
        );
    }

    onSkipWorkflowItem = () => {
        const { skipWorkflowItem, skipUsageWorkflowItem, skipProductWorkflowItem,workFormats, dataActionList, lookupValues, workMaintenanceGeneralDataViewData, currentWorkflowIndex, activeTab, workflowSessionItems, cancelWorkflowSession, tabs } = this.props;
        if (currentWorkflowIndex < workflowSessionItems.length - 1) {
            let entityType = workflowSessionItems[currentWorkflowIndex + 1].entityType
            if (entityType === "Usage (Work Based)" || entityType === "Usage (Product Based)" || entityType == "Usage (Live Performance)" || entityType == "Usage (Unidentified Performance)") {
                skipUsageWorkflowItem(activeTab, currentWorkflowIndex + 1, workflowSessionItems);
            }else if(entityType === "Intray Product"){
                skipProductWorkflowItem(activeTab, currentWorkflowIndex + 1, workflowSessionItems,[],workFormats);
            }
            else {
                skipWorkflowItem(activeTab, currentWorkflowIndex + 1, workflowSessionItems, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, dataActionList, workMaintenanceGeneralDataViewData, workFormats, tabs[activeTab].readonlyIndicatorWorkFlagTypes);
            }
        } else {
            cancelWorkflowSession(activeTab);
        }
    }

    onCancelWorkflowSession = () => {
        const { cancelWorkflowSession, activeTab } = this.props;
        cancelWorkflowSession(activeTab);
    }

    renderWorkflowSessionActions = () => {
        const { isWorkflowSessionStarted, currentWorkflowIndex, workflowSessionItems, isOpenForWorkflowSession } = this.props;

        if (isWorkflowSessionStarted && isOpenForWorkflowSession) {
            return (
                <div>
                    <div className="col-md-12 alert alert-warning">
                        Workflow session in progress - {currentWorkflowIndex.toString()} out of {workflowSessionItems?.length.toString()} workflows complete.
                    </div>
                    <div className="workBatchInput">
                        <button className="enabledButton" onClick={this.onSkipWorkflowItem}> Skip workflow</button>
                        <button className="enabledButton" onClick={this.onCancelWorkflowSession}> Cancel session </button>
                    </div>
                </div>
            )
        }

        else return null;
    }

    getViewRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(VIEW_WORKFLOWS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_WORKFLOWS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    renderCompleteOnSaveControls = (workflow: any) => {
        const { workflowFieldsData, completeWorkflowOnSave } = this.props;

        return (
            <div>
                <div className="form-group col-md-3 col-sm-6 col-xs-12">
                    <div className="checkboxContainer">
                        <label className="subContainer" form="isMaintained">
                            <input type="checkbox"
                                id="isMaintained"
                                defaultChecked={true}
                                onChange={(event) => this.completeWorkflowOnSave(event, workflow)}
                            />
                            <span className="inputCheckbox"></span>
                            <div className="checkboxLabel">
                                {workflowFieldsData.fields.find(e => e.name === WORKFLOW_COMPLETE_ON_SAVE)?.data}
                            </div>
                        </label>
                    </div>
                </div>
                <div className="form-group col-md-3 col-sm-6 col-xs-12">
                    <div >
                        <button title={workflowFieldsData.fields.find(e => e.name === WORKFLOW_DIRECT_COMPLETE_ON_SAVE)?.data}
                            type="button"
                            className={"button btn-defaultPrimary noMargin"}
                            onClick={() => this.onSaveCompleteWorkflow(workflow)}
                            disabled={this.getUpdateRoleDisabled()}
                        >
                            <span>{workflowFieldsData.fields.find(e => e.name === WORKFLOW_DIRECT_COMPLETE_ON_SAVE)?.data}</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    renderValidationControls = (workflow: any) => {
        const { workflowFieldsData } = this.props;

        return (
            <div>
                <div className="form-group col-md-3 col-sm-6 col-xs-12">
                    <div >
                        <button title={workflowFieldsData.fields.find(e => e.name === WORKFLOW_APPROVE)?.data}
                            type="button"
                            className={"button btn-defaultPrimary noMargin"}
                            onClick={() => this.onApproveOrReject(workflow, true)}
                            disabled={this.getUpdateRoleDisabled()}
                        >
                            <span>{workflowFieldsData.fields.find(e => e.name === WORKFLOW_APPROVE)?.data}</span>
                        </button>
                    </div>
                </div>
                <div className="form-group col-md-3 col-sm-6 col-xs-12">
                    <div >
                        <button title={workflowFieldsData.fields.find(e => e.name === WORKFLOW_REJECT)?.data}
                            type="button"
                            className={"button btn-defaultPrimary noMargin"}
                            onClick={() => this.showRejectReasonModal(workflow)}
                            disabled={this.getUpdateRoleDisabled()}
                        >
                            <span>{workflowFieldsData.fields.find(e => e.name === WORKFLOW_REJECT)?.data}</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    renderWorkflowControls = (workflow: any) => {
        const { workflowFieldsData } = this.props;
        if (workflow.entityType === "Usage (Live Performance)" || workflow.entityType === "Usage (Unidentified Performance)") {
            return this.renderValidationControls(workflow);
        }
        else {
            return this.renderCompleteOnSaveControls(workflow);
        }
    }

    renderWorkflow = () => {
        const { workflows, workflowFieldsData } = this.props;

        let workflowList: JSX.Element[] = [];
        workflows.sort(this.sortByDateDesc);
        workflowList.push(
            <div className="row" key={0}>
                <div className="col-md-12 heading">
                    {workflowFieldsData.fields.find(e => e.name === WORKFLOW_INPROGRESS)?.data} {workflows[0].workflowType}
                </div>
                <div className="col-sm-12">
                    <div className="row" key={0}>
                        <div className="col-md-3 col-sm-6 col-xs-12 date">
                            <p className="workflowLabel">{workflowFieldsData.fields.find(e => e.name === WORKFLOW_CREATED_DATE)?.data}</p>
                            <p><DateDisplay value={workflows[0].dateCreated.toString()} showTime={true} /></p>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 assinged">
                            <p className="workflowLabel">{workflowFieldsData.fields.find(e => e.name === WORKFLOW_ASSIGNED_TO)?.data}</p>
                            <p>{workflows[0].assignedTo}</p>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12 message">
                            <p className="workflowLabel">{workflowFieldsData.fields.find(e => e.name === WORKFLOW_MESSAGE)?.data}</p>
                            <p dangerouslySetInnerHTML={{ __html: workflows[0].errorMessages }}></p>
                        </div>
                        {this.renderWorkflowControls(workflows[0])}
                    </div>
                    <hr></hr>
                </div>
            </div>
        )

        return workflowList;
    };

    render() {
        const { workflows, isWorkflowSessionStarted, isOpenForWorkflowSession } = this.props;
        workflows.sort(this.sortByDateDesc);
        return <div className="col-md-12 row-workflow">
            {isWorkflowSessionStarted && isOpenForWorkflowSession || (workflows[0].status === "Open" || workflows[0].status === "Assigned") ? this.renderExpandAll() : null}
            {this.state.expandAll && !this.getViewRoleDisabled() ?
                <div>
                    {(workflows[0].status === "Open" || workflows[0].status === "Assigned") ?
                        <div className="col-md-12 workflowInfo">
                            <span className="icon info ms-Icon ms-Icon--Info"></span>
                            <span>
                                {this.renderWorkflow()}
                            </span>
                        </div> : null}
                    {this.renderWorkflowSessionActions()}
                </div>
                :
                <div className="col-md-12 row-workflow-empty"></div>
            }
        </div>
    }
}