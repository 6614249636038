import { connect } from "react-redux";
import { RouteChildrenProps } from 'react-router';
import { Dispatch } from "redux";
import { logoutThunk } from "../../../redux/thunks/AccountThunks";
import IAppState from "../../../redux/types/IAppState";
import { SignOut } from "./SignOut";

export default connect(
    (state: IAppState, props: RouteChildrenProps) => ({
        isSignedIn: state.account.isSignedIn,
        portalType: state.account.portalType
    }),
    (dispatch: Dispatch) => ({
        logout: () => dispatch<any>(logoutThunk()),
    })
)(SignOut);