import { ComponentsHelper } from "../../core/services/ComponentHelper";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IProductState } from "../../redux/types/IProductState";
import { IWorkState } from "../../redux/types/IWorkState";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";
import { DRAFTSET_LISTS_SAVE_VALIDATION_DRAFTSET_LISTS_TITLE_NOT_SET, DRAFTWORKS_SAVE_VALIDATION_DRAFTWORKS_TITLE_NOT_SET, EMPTY_STRING_VALUE } from "../Consts";
import { ISetListsState } from "../types/ISetListsState";

export class MembersPortalSetListsValidator {

    public static validateSetLists(setList: IProductState, draftSetListsMaintenanceGeneralData?: IMembersPortalComponentDataItem): IValidationMessage[] {
        let setListsIsValid: IValidationMessage[];
        setListsIsValid = [];
        if (setList.productName.length < 1)
        setListsIsValid.push({ 
            messageKey:ComponentsHelper.getFieldValueByName(
                DRAFTSET_LISTS_SAVE_VALIDATION_DRAFTSET_LISTS_TITLE_NOT_SET, 
                draftSetListsMaintenanceGeneralData.fields, 
                EMPTY_STRING_VALUE)
        });
        return setListsIsValid;
    }
}