import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import SearchView from "../../repertoire/components/searchView/SearchView";
import {
    SEARCH_VIEW_MEMBER_STATEMENTS,
} from "../Consts";

import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { IDraftWorkSearchResult } from "../types/IDraftWorkSearchResult";
import { getDataAction } from "../components/toolBar/ToolbarHelper";
import { setDataSource } from "../../redux/reducers/MembersPortalReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { DataSource } from "../../repertoire/types/DataSource";
import { IMemberWorkSearchResult } from "../types/IMemberWorkSearchResult";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";
import { SearchRequests } from "../../repertoire/services/SearchRequests";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { FormatFields } from "../../redux/types/FormatFields";
import { ExpandMemberStatementResult, ExpandWorkResult } from "../../redux/actions/MembersPortalActions";
import { IMemberStatementSearchQuery } from "../types/IMemberStatementSearchQuery";
import { IMemberStatementSearchResult } from "../types/IMemberStatementSearchResult";
import { expandAllResults } from "../../redux/reducers/RepertoireReducer";

export interface IMemberStatementPageProps {
    statementsPageData: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchMemberStatements?: (query: IMemberStatementSearchQuery) => void;
    memberStatementSearchResults?: IMemberStatementSearchResult[];
    searchSuccessful: boolean;
    paymentStatementGeneralDataViewData?: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getMemberStatementDetails?: (payment: IMemberStatementSearchResult, lookupValues: ILookupDictionary, userNameNumber: string, formats?: FormatFields[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    memberPortalSearchType?: MemberPortalSearchTypeItem;
    currentUserNameNumber?: string;
    expandMemberStatementResult?: (statementResult: number) => ExpandMemberStatementResult;
    expandedMemberStatementResults?: number[];
    expandAllResults: () => void;
    expandAll: boolean;
}

const MemberStatementsPage = (props: IMemberStatementPageProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const checkIfLoaded = () => {
        const { statementsPageData } = props;

        if (statementsPageData.fields && statementsPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = statementsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    if (loaded) {
        const {
            statementsPageData,
            searchViewData,
            searchResultsTableData,
            searchMemberStatements,
            searchSuccessful,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            updatePagination,
            sortSearchResults,
            lookupValues,
            expandMemberStatementResult,
            expandedMemberStatementResults,
            memberStatementSearchResults,
            currentUserNameNumber,
            expandAllResults,
            expandAll
        } = props;

        const getMemberStatementDetails = (id: string) => {
            let payment = memberStatementSearchResults.find(x => x.id === id);
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getMemberStatementDetails(payment, lookupValues, currentUserNameNumber, formats);
                });
        }

        let searchPageLabelTitle = "";
        if (statementsPageData && statementsPageData.fields.length > 0) {
            searchPageLabelTitle = statementsPageData.fields.find((v, i, a) => v.name == "searchPageLabelTitle").data;
        }

        let searchResults = props.memberStatementSearchResults;

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">{searchPageLabelTitle}</span>
                    </div>
                </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_MEMBER_STATEMENTS}
                    modalProps={undefined}
                    dataSource={undefined}
                    hideModal={undefined}
                    lookupValues={lookupValues}
                    searchMemberStatements={searchMemberStatements}
                    memberStatementSearchResults={searchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    getMemberStatementDetails={getMemberStatementDetails}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={false}
                    expandAllResults={expandAllResults}
                    expandAll={expandAll}
                    expandMemberStatementResult={expandMemberStatementResult}
                    expandedMemberStatementResults={expandedMemberStatementResults}
                    currentUserNameNumber={currentUserNameNumber}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default MemberStatementsPage;
