// Components
export const LOGIN_PAGE: string = "LoginPage";

// Portal Type
export const MATCHING_ENGINE = 'MatchingEngine';
export const MEMBERS_PORTAL = 'MembersPortal';

export const AAD_LOGIN_TYPE = 'AAD';
export const B2C_LOGIN_TYPE = 'B2C';

