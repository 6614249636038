import { ComponentFields } from "../../core/services/ComponentService";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import IAppState from "../types/IAppState";
import {
    cleanStoragePath,
    getDataIngestionComponentDataFailure,
    getDataIngestionComponentDataRequest,
    getDataIngestionComponentDataSuccess,
    getDistributionsFailure,
    getDistributionsRequest,
    getDistributionsSuccess,
    getFilesystemsFailure,
    getFilesystemsRequest,
    getFilesystemsSuccess,
    getPoolsFailure,
    getPoolsRequest,
    getPoolsSuccess,
    setDestinations,
    setFileFormats,
    setSelectedNode,
    undoUsageIngestionFailure,
    undoUsageIngestionRequest,
    undoUsageIngestionSuccess,
    searchRightHoldersRequest,
    searchRightHoldersSuccess,
    searchRightHoldersFailure,
    searchLicenses,
    searchLicensesRequestSuccess,
    searchLicensesRequestFailure,
    getFolderDefaultConfigurationSuccess,
} from "../reducers/DataIngestionReducer";
import IDataIngestionComponentData from "../../dataingestion/types/IDataIngestionComponentData";
import { DistributionService } from '../../dataingestion/services/DistributionService';
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { SourceService } from '../../settings/services/SourceService';
import { StorageService } from '../../dataingestion/services/StorageService';
import { IFileSystem } from "../../dataingestion/types/IFileSystem";
import { SaveState } from "../../dataingestion/types/SaveState";
import { IStoragePathItemData } from "../../dataingestion/types/IStoragePathItemData";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";
import { TreeNodeBuilder } from "../../dataingestion/services/TreeNodeBuilder";
import { JobService } from "../services/JobService";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { getLookupEntitiesFailure, getLookupEntitiesRequest, getLookupEntitiesSuccess } from "../reducers/LookupReducer";
import { LookupService } from "../../lookup/services/LookupService";
import { FILE_TYPE_LOOKUP, MATCHING_OUTPUT_DESTINATION_LOOKUP } from "../../lookup/Consts";
import IFile from "../types/IFiles";
import { ERROR_OBTAINING_SAS, ERROR_UPLOADING_FILE_SAS, OBTAINED_SAS, REQUEST_SAS, UPLOADING_FILE_SAS } from "../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindowFields";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { RepertoireService } from "../services/RepertoireService";
import { IUsagePool } from "../../repertoire/types/usageTypes/IUsagePool";
import { hideModal, showLoading, showMessage } from "../reducers/ModalReducer";
import { UsageService } from "../../repertoire/services/usageServices/UsageServices";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { SearchRequests } from "../../repertoire/services/SearchRequests";
import { FolderSection } from "../../dataingestion/types/FolderSection";
import { IShoppingCartSearchQuery } from "../../repertoire/types/IShoppingCartSearchQuery";
import { IShoppingCart } from "../../repertoire/types/IShoppingCart";

export const getDataIngestionComponentDataThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDataIngestionComponentDataRequest());
        return ComponentFields.getDataIngestionComponentData()
            .then((res: IDataIngestionComponentData) => {
                dispatch(getDataIngestionComponentDataSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getDataIngestionComponentDataFailure(error));
            });
    };
};

export const getDistributionsThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDistributionsRequest());
        return DistributionService.getDistributions()
            .then((res: IDistribution[]) => {
                dispatch(getDistributionsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getDistributionsFailure(error));
            });
    };
};

export const getFilesystemsThunk = (isRepertoireModule?: boolean, disallowedFolderSections?: FolderSection[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getFilesystemsRequest());

        return StorageService.getFilesystems('', isRepertoireModule ? true : false)
            .then((res: IFileSystem[]) => {
                dispatch(getFilesystemsSuccess(res, disallowedFolderSections));
            })
            .catch((error: any) => {
                dispatch(getFilesystemsFailure(error));
            });
    };
};

export const getPoolsThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getPoolsRequest());

        return RepertoireService.getUsagePools()
            .then((res) => {
                dispatch(getPoolsSuccess(res.data));
            })
            .catch((error: any) => {
                dispatch(getPoolsFailure(error));
            });
    };
};

export const saveChangesThunk = (fileSystem: IFileSystem, storagePaths: IStoragePathItemData[], sources: ITreeData[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        const items = storagePaths.filter(p => !p.isDirectory && p.isDirty);
        function encode(item, index, a) {
            a[index].metadata.poolCode = encodeURIComponent(item.metadata.poolCode);
        }
        items.forEach(encode);
        if (items && items.length > 0) {
            StorageService.updateMetadata(items)
                .then(() => openFilesystem(dispatch, fileSystem, SaveState.SaveSucceeded, sources))
                .catch(() => openFilesystem(dispatch, fileSystem, SaveState.SaveFailed, sources));
        }
    };
};

export const matchNowThunk = (fileSystem: IFileSystem, storagePaths: IStoragePathItemData[], sources: ITreeData[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        const items = storagePaths.filter(p => !p.isDirectory && p.isDirty);
        function encode(item, index, a) {
            a[index].metadata.poolCode = encodeURIComponent(item.metadata.poolCode);
        }
        items.forEach(encode);
        if (items && items.length > 0) {
            StorageService.updateMetadata(items)
                .then(() => openFilesystem(dispatch, fileSystem, SaveState.IngestionProcessStaredMessage, sources))
                .catch(() => openFilesystem(dispatch, fileSystem, SaveState.SaveFailed, sources));
            items.filter(x => x.metadata).forEach(item => JobService.matchIngestionFile(item))
        }
    };
};

export const cancelChangesThunk = (fileSystem: IFileSystem, sources: ITreeData[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return openFilesystem(dispatch, fileSystem, SaveState.Cancelled, sources);
    };
};

export const selectedNodeChangedThunk = (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return openNode(dispatch, node, SaveState.Unchainged, sources);
    };
};

const openFilesystem = (dispatch: Dispatch, filesystem: IFileSystem, saveState: SaveState, sources: ITreeData[]) => {
    const node = TreeNodeBuilder.build([filesystem])[0];
    return openNode(dispatch, node, saveState, sources);
}

const openNode = (dispatch: Dispatch, node: ITreeNodeData<IFileSystem>, saveState: SaveState, sources: ITreeData[]) => {
    return Promise.all([
        StorageService.getFilesystems(node.object.fullName),
        StorageService.getBlobs(node.object.fullName),
        SourceService.getFolderDefaultConfiguration(node.object.path)
    ])
        .then(res => {
            dispatch(getFolderDefaultConfigurationSuccess(res[2]));
            dispatch(setSelectedNode(node, res[0], res[1], saveState, sources));
        })
        .catch((error: any) => {
            dispatch(getFilesystemsFailure(error));
        });
}

export const openDirectoryThunk = (filesystem: IFileSystem, sources: ITreeData[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return openFilesystem(dispatch, filesystem, SaveState.Unchainged, sources)
    };
};

export const refreshDirectoryThunk = (filesystem: IFileSystem, sources: ITreeData[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return openFilesystem(dispatch, filesystem, SaveState.Refresh, sources)
    };
};

export const initialiseLookupsThunk = (lookups: ILookupDictionary): ThunkAction<void, IAppState, null, Action<string>> => {
    if (lookups && lookups[FILE_TYPE_LOOKUP] && lookups[MATCHING_OUTPUT_DESTINATION_LOOKUP]) {
        return (dispatch: Dispatch) => {
            dispatch(setFileFormats(lookups[FILE_TYPE_LOOKUP]));
            dispatch(setDestinations(lookups[MATCHING_OUTPUT_DESTINATION_LOOKUP]));
        }
    }
    else {
        return (dispatch: Dispatch) => {
            dispatch(getLookupEntitiesRequest());
            return LookupService.getLookUpEntities()
                .then((res: ILookupDictionary) => {
                    dispatch(getLookupEntitiesSuccess(res));
                    dispatch(setFileFormats(res[FILE_TYPE_LOOKUP]));
                    dispatch(setDestinations(res[MATCHING_OUTPUT_DESTINATION_LOOKUP]));
                })
                .catch((error: any) => {
                    dispatch(getLookupEntitiesFailure(error));
                });
        };
    }
};

export const cleanStoragePathThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(cleanStoragePath());
    }
}


export const undoUsageIngestionThunk = (
    fullFilePath: string,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoUsageIngestionRequest());
        dispatch(showLoading());
        return UsageService.undoUsageIngestion(fullFilePath)
            .then((res) => {
                if (res.response.status === 400){
                    dispatch(hideModal());
                    dispatch(showMessage({ textToScreen: res.response.data }));
                }
                else {
                    dispatch(undoUsageIngestionSuccess());
                    dispatch(hideModal());
                }
            })
            .catch(err => {
                console.debug(`uui error: ${err}`);
                undoUsageIngestionFailure(err);
                dispatch(hideModal());
            });
    };
};

export const searchRightHoldersThunk = (
    searchBody: IContributorSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchRightHoldersRequest());
        return SearchRequests.getContributors(searchBody)
            .then((res: IContributorSearchResult[]) => {
                dispatch(searchRightHoldersSuccess(res));
            })
            .catch(err => {
                dispatch(searchRightHoldersFailure(err));
            });
    };
};

export const searchLicensesThunk = (
    searchBody: IShoppingCartSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchLicenses());
        return SearchRequests.getLicenses(searchBody)
            .then((res: IShoppingCart[]) => {
                dispatch(searchLicensesRequestSuccess(res));
            })
            .catch(err => {
                dispatch(searchLicensesRequestFailure(err));
            });
    };
};

export const getPathFilesystems = (path: string, isRepertoireModule?: boolean, disallowedFolderSections?: FolderSection[]): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getFilesystemsRequest());

        return StorageService.getFilesystems(path, isRepertoireModule ? true : false)
            .then((res: IFileSystem[]) => {
                dispatch(getFilesystemsSuccess(res, disallowedFolderSections));
            })
            .catch((error: any) => {
                dispatch(getFilesystemsFailure(error));
            });
    };
}