import * as React from "react";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IComponentField } from "../../../../core/types/IComponentField";
import PaginationView from "../../../components/paginationView/PaginationView";
import TableHeaderSection from "../../../components/tableHeaderSection/TableHeaderSection";
import { createCopyOfTableContents } from "../../../services/TableFunctions";
import { IMyPackagesDetails } from "../../../types/IMyPackagesDetails"; 
import DateDisplay from "../../../components/dateDisplay/DateDisplay";
import IRepertoireComponentData from "../../../../redux/types/IRepertoireComponentData";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import IconTextButton from "../../../components/iconTextButton/IconTextButton";
import moment from 'moment';
import { EntityType } from "../../../types/EntityType";
import { RepertoireService } from "../../../../redux/services/RepertoireService";

export interface IMyPackagesGridViewProps {
    myPackagesGridView: IRepertoireComponentDataItem;
    tableContents: IMyPackagesDetails[];
    newSearch?: boolean;
    currentUser?:string;
    getMyPackagesDetails?: (userName: string, modalOpen: boolean) => void;
    componentInstance?:string;   
}

interface IMyPackagesGridViewState {
    tableContents: IMyPackagesDetails[];
    activeSortHeaderSection: string;
    indexOfFirstResult: number;
    indexOfLastResult: number;
    resultsPerPage: number;
    currentPage: number;
   }

export default class MyPackagesGridView extends React.PureComponent<
IMyPackagesGridViewProps,
IMyPackagesGridViewState
    > {

    constructor(props: IMyPackagesGridViewProps) {
        super(props);

        this.state = {
            tableContents: props.tableContents,
            activeSortHeaderSection: undefined,
            indexOfFirstResult: 0,
            indexOfLastResult: 10,
            resultsPerPage: 10,
            currentPage: 1
        };
    }

    public ascending: boolean = false;

    componentDidUpdate = (prevProps: IMyPackagesGridViewProps) => {
        const { tableContents, newSearch } = this.props;
        const { indexOfLastResult, resultsPerPage } = this.state;
       
        if (prevProps.tableContents !== tableContents) {         
            this.setState({
                tableContents
            });
        }
        if(newSearch){
            this.updateContentsTable(0, indexOfLastResult, resultsPerPage, 1)
        }
    };


    sortTableByColumn = (section: string) => {
        const { activeSortHeaderSection, tableContents } = this.state;
        const tableContentsCopy = createCopyOfTableContents(tableContents);

        if (activeSortHeaderSection !== section) {
            this.ascending = false;
        }

        tableContentsCopy.sort((a: any, b: any) => {
            // set to emtpy string if null
            let newA = a[section] || "";
            let newB = b[section] || "";

            newA = newA ? newA : "";
            newB = newB ? newB : "";

            if (!this.ascending) {
                return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
            }
            return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });
        });

        this.setState({
            tableContents: tableContentsCopy,
            activeSortHeaderSection: section
        });

        this.ascending = !this.ascending;
    };

    updateContentsTable(indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number): void {
        this.setState({ indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage });
    }

    getHeaderString = (key: string) => {
        const { myPackagesGridView } = this.props;

        for (let x = 0; x < myPackagesGridView.fields.length; x++) {
            if (myPackagesGridView.fields[x].name === key) {
                return myPackagesGridView.fields[x].data;
            }
        }
    };

    renderHeaderSections = () => {
        const { myPackagesGridView } = this.props;
        const { activeSortHeaderSection } = this.state;
    
        if (myPackagesGridView && myPackagesGridView.fields) {
            return myPackagesGridView.fields.map((section: IRepertoireField, index: number) => {
                const widthStyles = [
                    { width: '20%' },
                    { width: '65%' },
                    { width: '15%' }
                ];
               
                return (
                    <td key={index} className="td" >
                        <TableHeaderSection
                            section={section.data}
                            fieldName={section.name}
                            isSortingActive={section.name === activeSortHeaderSection}
                            ascending={this.ascending}
                            onClickHeaderSection={this.sortTableByColumn}
                            sortable
                        />
                    </td>
                );
            });
        }
    };
    renderPaginationView(): JSX.Element {
        const { tableContents, resultsPerPage, currentPage } = this.state;
        return <PaginationView
            contentsLength={tableContents ? tableContents.length : 0}
            resultsPerPage={resultsPerPage}
            currentPage={currentPage}
            updateContentsTable={this.updateContentsTable.bind(this)}
        />
    }

    onDownloadFile(downloadlink, fileFormat) {
        RepertoireService.downloadFile(downloadlink, fileFormat).then(
            (downloadLink: string) => {
                const link = document.getElementById("downloadLinkPackages");
                link.setAttribute('href', downloadLink);
                link.click();
                link.removeAttribute('href');
            }
        );
    }

    renderSearchResults = () => {

        const { indexOfFirstResult, indexOfLastResult } = this.state;
        const {  myPackagesGridView, tableContents,currentUser } = this.props;
       
       if (tableContents && tableContents.length > 0) {
        const currentResults = tableContents.slice(
            indexOfFirstResult,
            indexOfLastResult
        );

            const keys: { name, title }[] = [];
            myPackagesGridView.fields.map(field => keys.push({ name: field.name, title: field.data }));
        
            return currentResults.map(
                (tableContentItem: any, index: number) => {
                    const sections: JSX.Element[] = [];
                    const widthStyles = [
                        { width: '20%' },
                        { width: '65%' },
                        { width: '15%' }
                    ];
                   
                    keys.map((key: { name, title }, x: number) => {
                      
                        if (key.name.trim().toLowerCase() === 'downloadlink') {
                            sections.push(
                                <td key={`${index}.${x}`} className="td" title={key.title} style={widthStyles[x] || {}}>
                                    <div className="tableCell wrapText" style={{ paddingLeft: '15px' }}>
                                      {tableContentItem[key.name] &&
                                        <IconTextButton
                                            icon={"icon ms-Icon ms-Icon--Download"}
                                            onClick={() => this.onDownloadFile(tableContentItem[key.name], tableContentItem["downloadFileFormat"])}
                                        />
                                      }
                                    </div>
                                </td>
                            );
                            return;
                        } else if (key.name.trim().toLowerCase() === 'lastmodifiedate') {  
                            sections.push(
                                <td key={`${index}.${x}`} className="td" title={key.title} style={widthStyles[x] || {}} >
                                    <div className="tableCell wrapText">
                                       {moment(tableContentItem[key.name]).format("DD/MM/YYYY hh:mm A")}
                                    </div>
                                </td>
                            );
                            return;
                        } else {
                            sections.push(
                                <td key={`${index}.${x}`} className="td" title={key.title} style={widthStyles[x] || {}}>
                                    <div className="tableCell wrapText">
                                        {tableContentItem[key.name]}
                                    </div>
                                </td>
                            );
                        }
                            
                        }
                    
                    );
                    
                    return <tr key={index} className="tr">
                        {sections}
                    </tr>
                }
            );
       }
    };

    render() {
        return (
             <div className="SearchResultsTable">
                 {this.renderPaginationView()}
                 <div className="tableContainer">
                     <table className="table">
                         <thead className="thead">
                             <tr className="tr">{this.renderHeaderSections()}</tr>
                         </thead>
                         <tbody className="tbody">{this.renderSearchResults()}</tbody>
                     </table>
                     {this.renderPaginationView()}
                 </div>
             </div>
        );
    }
}
