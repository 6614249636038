import * as React from "react";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { updateUsagePoolField } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ADD_NEW_ACTION, COPY_ACTION, POOL_ESTIMATED_VALUE_PER_POINT_SETTINGS_KEY, POOL_SOURCES_KEY, REMOVE_ACTION } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { ITreeData } from "../../../types/ITreeData";

export interface IPoolMaintenanceEstimatedValuePerPointSettingsGridProps {
    estimatedValuePerPointSettingsGridViewData?: IRepertoireComponentDataItem;
    isReadonly: boolean;
    updateUsagePoolField: typeof updateUsagePoolField;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    lookupSources: ITreeData[];
    incrementTempID?: () => void;
    lookupValues: ILookupDictionary;
    tableContents: any;
    changeData?: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    componentInstance: string;
    dataGridTableData: IRepertoireComponentDataItem;
    stateKey: IRepertoireStateKeys;
    poolEnabled?:boolean;
    evppSettingsIsEnabled?:boolean;
}

export interface IIPoolMaintenanceWeightSettingsGridState {
    loaded: boolean;
    activeHeaderSection: string;
}

export default class PoolMaintenanceEstimatedValuePerPointSettingsGrid extends React.Component<IPoolMaintenanceEstimatedValuePerPointSettingsGridProps, IIPoolMaintenanceWeightSettingsGridState>{
    viewData;
    constructor(props: IPoolMaintenanceEstimatedValuePerPointSettingsGridProps) {
        super(props);

        this.state = { loaded: false,activeHeaderSection:POOL_SOURCES_KEY };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            estimatedValuePerPointSettingsGridViewData: { fields }
        } = this.props;
    }

    render() {

        const { 
            lookupSources, 
            incrementTempID,
            lookupValues,
            changeData, 
            tableContents, 
            evppSettingsIsEnabled,
            dataGridTableData,
            tabs,
            activeTab,
            poolEnabled,
            stateKey
        } = this.props;

        return (
            <div>
                            <DataGridTable
                            tableContents={tableContents}
                            changeData={changeData}
                            dataGridTableData={dataGridTableData}
                            stateKey={stateKey}
                            componentInstance={POOL_ESTIMATED_VALUE_PER_POINT_SETTINGS_KEY}
                            showRowNumber={true}
                            isReadOnly={poolEnabled?false:true}
                            tableActions={[{ name: ADD_NEW_ACTION,disabled:evppSettingsIsEnabled?false:true},{name: REMOVE_ACTION } ]}
                            lookupValues={lookupValues}
                            lookupSources={lookupSources}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                        />

                    
            </div>
            )

    }
}