
import { IJobsState } from "../types/IJobsState";
import {
    ActionTypes,
    RenderJobProgress,
    RENDER_JOB_PROGRESS
} from "../actions/JobActions";
import { IJob } from "../../job/types/IJob";

const initialState: IJobsState = {
    jobs: []
}

export function renderJobProgress(jobs: IJob[]): RenderJobProgress {
    return {
        type: RENDER_JOB_PROGRESS,
        payload: {
            jobs
        }
    }
}

const jobReducer = (
    state = initialState,
    action: ActionTypes
): IJobsState => {
    switch (action.type) {
        case RENDER_JOB_PROGRESS: {
            const { jobs } = action.payload;
            if (JSON.stringify(jobs) === JSON.stringify(state.jobs)) {
                return state;
            }
            else {
                const newState: IJobsState = {
                    jobs: jobs.sort((a, b) => a.type.localeCompare(b.type))
                }
                return newState;
            }
        }
        default:
            return state;
    }
}

export default jobReducer;