import IAppState from "../../redux/types/IAppState";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import MyPackagesPage from "./MyPackagesPage"
import { addTab } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { getDownloadFileFormatThunk, getMyPackageDetailsThunk, clearPakagesThunk, loadRepertoireFromPathThunk, generatePackageThunk, closePackageThunk, addPackageNoteThunk } from "../../redux/thunks/RepertoireThunks";
import { getUserCRMValuesThunk } from "../../redux/thunks/AccountThunks";
import { IMyPackagesDetails } from "../types/IMyPackagesDetails";
import { IRepertoirePathInfo } from "../types/IRepertoirePathInfo";
import { RouteChildrenProps } from 'react-router';

export default connect(
    (state: IAppState, props?: RouteChildrenProps) => ({
        packageMaintenanceGridsViewData: state.membersPortal.componentData.MyPackagesMaintenancePage,
        myPackagesGeneralDataViewData:state.membersPortal.componentData.MyPackagesGeneralDataView,
        myPackagesGridView:state.membersPortal.componentData.MyPackagesGridView,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.MyPackagesGridView,
        lookupValues: state.lookupEntities,
        downloadFileFormat: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].downloadFileFormat,
        username: state.account.currentUser,
        myPackagesResult: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].myPackagesResult,
        portalType: state.account.portalType,
        alertMessage: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].myPackagesGeneratePackageMessage,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        getDownloadFileFormat: () => dispatch<any>(getDownloadFileFormatThunk()),
        getMyPackagesDetails: (userName: string, modalOpen: boolean) =>
            dispatch<any>(getMyPackageDetailsThunk(userName, true)),
        getUserCRMValues: () => dispatch<any>(getUserCRMValuesThunk()),
        clearPackages:(myPackages:IMyPackagesDetails)=>dispatch<any>(clearPakagesThunk(myPackages)),
        loadRepertoireFromPath: (pathInfo: IRepertoirePathInfo, location: Location) => dispatch<any>(loadRepertoireFromPathThunk(pathInfo, location)),
        generatePackage: (myPackage: IMyPackagesDetails) => dispatch<any>(generatePackageThunk(myPackage)),
        closePackage: (myPackage: IMyPackagesDetails) => dispatch<any>(closePackageThunk(myPackage)),
        addPackageNote: (myPackage: IMyPackagesDetails) => dispatch<any>(addPackageNoteThunk(myPackage)),

    })
) (MyPackagesPage);