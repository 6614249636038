
import * as React from "react";
import { SizedReadOnlyText } from "../../../../core/components/sizedReadOnlyText/SizedReadOnlyText";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { REMOVE_ACTION, SEARCH_VIEW, USAGE_AMOUNT_STATE_KEY, USAGE_APPLICABLE_ROYALTY_RATE_STATE_KEY, USAGE_DETAILS_ROWS_SECTION_KEY, USAGE_ID_STATE_KEY, USAGE_INBOUND_FEES_IN_ERROR_STATE_KEY, USAGE_IPI_NUMBER_STATE_KEY, USAGE_LICENSE_REF_ID_STATE_KEY, USAGE_NAME_STATE_KEY, USAGE_OUTBOUND_FEES_IN_ERROR_STATE_KEY, USAGE_ROYALTY_RATE_STATE_KEY, USAGE_SENDING_LICENSEE_STATE_KEY, USAGE_SHARE_STATE_KEY } from "../../../Consts";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IUsageRowData } from "../../../types/usageTypes/IUsageRowData";
import { PerformanceNumbersSection } from "../performanceNumbersSection/PerformanceNumbersSection";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { InputNumber, Space, Table } from "antd";
import { SizedActionButton } from "../../../components/actionButton/ActionButton";
import ShowMore from "../../../components/showMore/ShowMore";
import { showModal } from "../../../../redux/reducers/ModalReducer";
import { addUsageDetailsShareIPRow } from "../../../../redux/reducers/RepertoireReducer";
import IconTextButton from "../../../components/iconTextButton/IconTextButton";
import { IUsageDetailsRowData } from "../../../types/usageTypes/IUsageDetailsRowData";
import { MessageBanner, MessageType } from "../../../components/messageBanner/MessageBanner";
import { If } from "../../../../core/components/If";

export interface IUsageRowDropdownProps {
    usage: IUsageRowData;
    usageDataGridTableData: IRepertoireComponentDataItem;
    componentInstance: string;
    lookupValues?: ILookupDictionary;
    changeData?: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    showModal: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    )=>void;
    addUsageDetailsShareIPRow?: typeof addUsageDetailsShareIPRow;
    usageRowViewIndex: number;
    tableContents: any;
    resetMessageBanner: () => void;
}

export interface IUsageDetailsAdjustmentsDataItem {
    direction: string;
    id: string,
    ipiNumber: number,
    name: string,
    share: string,
    applicableRoyaltyRate: string,
    amount: string,
    licenseRefID: number,
    royaltyRate: string,
    inboundFeesInError: string,
    outboundFeesInError: string
}

interface IUsageRowDropdownState {
    usageDetailsIndex: number;
}

export class UsageRowDropdown extends React.PureComponent<
    IUsageRowDropdownProps,
    IUsageRowDropdownState
    > {
    viewData;
    constructor(props: IUsageRowDropdownProps) {
        super(props);
        this.viewData = {};
        this.state = {
            usageDetailsIndex: 0,
        }
    }

    createViewData() {
        const {
            usageDataGridTableData: { fields },
            componentInstance
        } = this.props;
        let filteredFields: IRepertoireField[] = fields.filter(
            (field: IRepertoireField) => field.componentInstance === componentInstance);
        filteredFields.forEach(item => this.viewData[item.name] = item.data);
    }


    addRowToUsageDetails = (index) => {
        const { usage, usageRowViewIndex, addUsageDetailsShareIPRow } = this.props;
        let data: IUsageDetailsAdjustmentsDataItem[] = [];
        if(usage && usage.usageDetails) {
            usage.usageDetails[index].adjustments.forEach(ele => {
                data.push(ele);
            })
            data.push({
                direction: 'To',
                id: usage.usageDetails[index].id,
                ipiNumber: 0,
                name: '',
                share: '0',
                applicableRoyaltyRate: '0',
                amount: '0',
                licenseRefID: usage.usageDetails[index].licenseRefID,
                royaltyRate: '0',
                inboundFeesInError: usage.usageDetails[index].inboundFeesInError,
                outboundFeesInError: usage.usageDetails[index].outboundFeesInError
            })
        }
        addUsageDetailsShareIPRow(data, usageRowViewIndex, index);
    }

    onInputChange = (e, key, usageDetailsIndex, childIndex, id) => {
        const { usage, usageRowViewIndex, addUsageDetailsShareIPRow } = this.props;
        let data: IUsageDetailsAdjustmentsDataItem[] = [];
        if (e !== undefined && e !== null) {
            if (key === 'ipiNumber') {
                usage.usageDetails[usageDetailsIndex].adjustments[childIndex][key] = e;
            } else if (key === 'share' ){
                const totalamount = usage.usageDetails[usageDetailsIndex].adjustments.reduce((acc, each) => each.direction === "From"? acc + parseFloat(each.amount): acc, 0);
                const totalshare = usage.usageDetails[usageDetailsIndex].adjustments.reduce((acc, each) => each.direction === "From"? acc + parseFloat(each.share): acc, 0);
                const newamount = ((e / totalshare ) * totalamount)

                usage.usageDetails[usageDetailsIndex].adjustments[childIndex][key] = e.toString();
                usage.usageDetails[usageDetailsIndex].adjustments[childIndex]['amount'] = newamount.toFixed(10).toString();
            } else if (key === 'applicableRoyaltyRate' || key === 'amount' || key === 'royaltyRate') {
                usage.usageDetails[usageDetailsIndex].adjustments[childIndex][key] = e.toString();
            }
            else {
                usage.usageDetails[usageDetailsIndex].adjustments.find(x => x.id === id)[key] = e;
            }
        }
        usage.usageDetails[usageDetailsIndex].adjustments.forEach(adj => data.push(adj));
        addUsageDetailsShareIPRow(data, usageRowViewIndex, usageDetailsIndex);
    };

    showSearchModal = (modalProps: any) => {
        const { showModal } = this.props;
        showModal(SEARCH_VIEW, USAGE_DETAILS_ROWS_SECTION_KEY, modalProps, true, "Search");
    };

    onClickRemove = (direction: string, usageDetailsIndex: number, childindex: number, usageDetails?: IUsageDetailsRowData) => {
        const { usage, usageRowViewIndex, addUsageDetailsShareIPRow } = this.props;
        let data: IUsageDetailsAdjustmentsDataItem[] = [];
        if (direction === 'From') {
            data = [];
        }
        else if (direction === 'To') {
            if(usage.usageDetails && usage.usageDetails.length > 0 && usage.usageDetails[usageDetailsIndex].adjustments && usage.usageDetails[usageDetailsIndex].adjustments.length > 0) {
                usage.usageDetails[usageDetailsIndex].adjustments.splice(childindex, 1);
                usage.usageDetails[usageDetailsIndex].adjustments.forEach(adj => data.push(adj));
                if(usage.usageDetails[usageDetailsIndex].adjustments && usage.usageDetails[usageDetailsIndex].adjustments.length === 1) {
                    data = [];
                }
            }
        }
        addUsageDetailsShareIPRow(data, usageRowViewIndex, usageDetailsIndex);
    };

    onClickAddUsageDetails = (index) => {
        const { usage, usageRowViewIndex, addUsageDetailsShareIPRow } = this.props;
        let data: IUsageDetailsAdjustmentsDataItem[] = [];

        if(usage && usage.usageDetails && usage.usageDetails[index].adjustments && usage.usageDetails[index].adjustments.length === 0) {
            data.push({
                direction: 'From',
                id: usage.usageDetails[index].id,
                ipiNumber: usage.usageDetails[index].ipiNumber,
                name: usage.usageDetails[index].name,
                share: usage.usageDetails[index].share,
                applicableRoyaltyRate: usage.usageDetails[index].applicableRoyaltyRate,
                amount: usage.usageDetails[index].amount,
                licenseRefID: usage.usageDetails[index].licenseRefID,
                royaltyRate: usage.usageDetails[index].royaltyRate,
                inboundFeesInError: usage.usageDetails[index].inboundFeesInError,
                outboundFeesInError: usage.usageDetails[index].outboundFeesInError
            })
    
            data.push({
                direction: 'To',
                id: usage.usageDetails[index].id,
                ipiNumber: usage.usageDetails[index].ipiNumber,
                name: usage.usageDetails[index].name,
                share: usage.usageDetails[index].share,
                applicableRoyaltyRate: usage.usageDetails[index].applicableRoyaltyRate,
                amount: usage.usageDetails[index].amount,
                licenseRefID: usage.usageDetails[index].licenseRefID,
                royaltyRate: usage.usageDetails[index].royaltyRate,
                inboundFeesInError: usage.usageDetails[index].inboundFeesInError,
                outboundFeesInError: usage.usageDetails[index].outboundFeesInError
            })
            this.setState({usageDetailsIndex: index})
            addUsageDetailsShareIPRow(data, usageRowViewIndex, index);
        }
    }

    render() {
        const {
            usage,
            usageDataGridTableData,
            lookupValues,
            changeData,
            usageRowViewIndex,
            resetMessageBanner,
            addUsageDetailsShareIPRow
        } = this.props;
        const {
            usageDetailsIndex,
        } = this.state;

        const amountOfColumns: number = Object.entries(usage).filter(x => x => x[1] !== null && x[1].inputType === 'row').length + 1;
        const readOnlyTextSize: string = "col-md-6";

        this.createViewData();
        let tableList = [];
        let usageDetailsGridData =[];

        usage.usageDetails = usage.usageDetails.filter(item => {
            return item != null || item === undefined;
        })
       
        if (usage.usageDetails && usage.usageDetails.length > 0) {
            
            usage.usageDetails.forEach((element, index) => {
                if (element.adjustments && element.adjustments.length > 0) {
                    let data: IUsageDetailsAdjustmentsDataItem[] = [];
                    data = element.adjustments.sort((p1, p2) => (p1.direction < p2.direction) ? -1 : (p1.direction > p2.direction) ? 1 : 0);
                    let columns = [
                        {
                            title: '', dataIndex: 'direction', key: 'direction', render: (_, value, index) => {
                                const text = value.direction;
                                return <><Space size="middle">{text}</Space></>
                            },
                        },
                        {
                            title: this.viewData[USAGE_ID_STATE_KEY], dataIndex: 'id', key: 'id', render: (text, value, index) => {
                                return <><div className={"widerFieldNumber"}><span>{value.id}</span></div></>
                            }
                        },
                        {
                            title: this.viewData[USAGE_IPI_NUMBER_STATE_KEY], dataIndex: 'ipiNumber', key: 'ipiNumber', render: (text, value, childIndex) => (
                                <div className='hasIcon'>
                                    <InputNumber className={"widerFieldNumber"} value={value.ipiNumber} readOnly={value.direction === 'From'} type={'number'} controls={false} onChange={event => this.onInputChange(event, "ipiNumber", index, childIndex, value.id)}/>
                                    {(value.direction === 'To') &&
                                        <ShowMore
                                            options={[
                                                {
                                                    text: 'Select IP',
                                                    onClick: () => { this.showSearchModal({ usageDetailsIndex: index, usageRowIndex: usageRowViewIndex, adjustmentsIndex: childIndex }); },
                                                    icon: "assets/external.svg"
                                                }
                                            ]}
                                        />}
                                </div>
                            )
                        },
                        {
                            title: this.viewData[USAGE_NAME_STATE_KEY], dataIndex: 'name', key: 'name', render: (text, value, index) => {
                                return <><div className={"widerFieldNumber"}><span>{value.name}</span></div></>
                            }
                        },
                        {
                            title: this.viewData[USAGE_SHARE_STATE_KEY], dataIndex: 'share', key: 'share', render: (text, value, childIndex) => {
                                return <InputNumber className={"percentageField"} value={value.share} readOnly={value.direction === 'From'} type={'number'} controls={false} onChange={event => this.onInputChange(event, "share", index, childIndex, value.id)} />
                            }
                        },
                        {
                            title: this.viewData[USAGE_APPLICABLE_ROYALTY_RATE_STATE_KEY], dataIndex: 'applicableRoyaltyRate', key: 'applicableRoyaltyRate', render: (text, value, childIndex) => {
                                return <InputNumber className={"percentageField"} value={value.applicableRoyaltyRate} readOnly={value.direction === 'From'} type={'number'} controls={false} onChange={event => this.onInputChange(event, "applicableRoyaltyRate", index, childIndex, value.id)} />
                            }
                        },
                        {
                            title: this.viewData[USAGE_AMOUNT_STATE_KEY], dataIndex: 'amount', key: 'amount', render: (text, value, childIndex) => {
                                return <InputNumber className={"percentageField"} value={value.amount} readOnly={value.direction === 'From'} type={'number'} controls={false} onChange={event => this.onInputChange(event, "amount", index, childIndex, value.id)} />
                            }
                        },
                        {
                            title: this.viewData[USAGE_LICENSE_REF_ID_STATE_KEY], dataIndex: 'licenseRefID', key: 'licenseRefID', render: (text, value, index) => {
                                return <><div className={"widerFieldNumber"}><span>{value.licenseRefID}</span></div></>
                            }
                        },
                        {
                            title: this.viewData[USAGE_ROYALTY_RATE_STATE_KEY], dataIndex: 'royaltyRate', key: 'royaltyRate', render: (text, value, childIndex) => {
                                return <InputNumber className={"percentageField"} value={value.royaltyRate} readOnly={value.direction === 'From'} type={'number'} controls={false} onChange={event => this.onInputChange(event, "royaltyRate", index, childIndex, value.id)} />
                            }
                        },
                        {
                            title: this.viewData[USAGE_INBOUND_FEES_IN_ERROR_STATE_KEY], dataIndex: 'inboundFeesInError', key: 'inboundFeesInError', render: (text, value, index) => {
                                return <><div className={"widerFieldNumber"}><span>{value.inboundFeesInError}</span></div></>
                            }
                        },
                        {
                            title: this.viewData[USAGE_OUTBOUND_FEES_IN_ERROR_STATE_KEY], dataIndex: 'outboundFeesInError', key: 'outboundFeesInError', render: (text, value, index) => {
                                return <><div className={"widerFieldNumber"}><span>{value.outboundFeesInError}</span></div></>
                            }
                        },
                        {
                            title: '', dataIndex: REMOVE_ACTION, key: REMOVE_ACTION, render: (text, value, childindex) => (
                                value.direction === 'From' ?
                                    <div className="tableCell">
                                        <IconTextButton onClick={() => this.onClickRemove('From', index, childindex, value)} text={'Detail'} icon={"icon ms-Icon ms-Icon--Delete"} disabled={value.dataSource === 'Posted'} />
                                    </div>
                                    :
                                    <div>
                                        <IconTextButton onClick={() => this.onClickRemove('To', index, childindex, value)} text={'Row'} icon={"icon ms-Icon ms-Icon--Delete"} disabled={value.dataSource === 'Posted'} />
                                    </div>
                            )
                        }
                    ]

                    let messages: string[] = [];
                    if(element.errorMessage && element.errorMessage.length > 0) {
                        if(element.errorMessage.includes('usageNotSavedBecauseOfAdjustmentsShare'))
                            messages.push(usageDataGridTableData.fields.find(x => x.name.toString() === 'usageNotSavedBecauseOfAdjustmentsShare').data);
                        if(element.errorMessage.includes('usageNotSavedBecauseOfAdjustmentsApplicableRoyaltyRate'))
                            messages.push(usageDataGridTableData.fields.find(x => x.name.toString() === 'usageNotSavedBecauseOfAdjustmentsApplicableRoyaltyRate').data);
                        if(element.errorMessage.includes('usageNotSavedBecauseOfAdjustmentsAmount'))
                            messages.push(usageDataGridTableData.fields.find(x => x.name.toString() === 'usageNotSavedBecauseOfAdjustmentsAmount').data);
                        if(element.errorMessage.includes('usageNotSavedBecauseOfAdjustmentsRoyaltyRate'))
                            messages.push(usageDataGridTableData.fields.find(x => x.name.toString() === 'usageNotSavedBecauseOfAdjustmentsRoyaltyRate').data);
                    }

                    tableList.push(
                        <div className='tableContainer'>
                            {(messages && messages.length > 0) ? <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Danger} messages={messages} /> : null}                            
                            <Table
                                rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                            />
                            <div className='row'>
                                <SizedActionButton buttonAction={() => this.addRowToUsageDetails(index)} buttonText={'Add New'} />
                            </div>
                        </div>
                    )
                }
            })               
                usage.usageDetails.forEach((obj,index)=>{
                    delete obj['id'];
                    delete obj['errorMessage'];
                    delete obj['licenseRefID'];
                    delete obj['UserAdjustment'];
                    if(Object.values(obj).every(value =>
                        value === "" || value === null || value === undefined || parseFloat(value) === 0||value.length===0)){
                        delete usage.usageDetails[index];
                    }else{
                        usageDetailsGridData.push(obj);
                    }});
        }

        return (
            <tr className="usageRowDropdown">
                <td colSpan={amountOfColumns}>
                    <div>
                        <If condition={parseFloat(usage.amount) > 0}>
                            <SizedReadOnlyText label={this.viewData[USAGE_AMOUNT_STATE_KEY]} value={usage.amount} size={readOnlyTextSize} />
                        </If>
                        <SizedReadOnlyText label={this.viewData[USAGE_SENDING_LICENSEE_STATE_KEY]} value={usage.sendingLicensee} size={readOnlyTextSize} />
                        <PerformanceNumbersSection usage={usage} />
                        {usageDetailsGridData.length>0 && <DataGridTable
                            dataGridTableData={usageDataGridTableData}
                            componentInstance={USAGE_DETAILS_ROWS_SECTION_KEY}
                            tableContents={usageDetailsGridData}
                            stateKey={USAGE_DETAILS_ROWS_SECTION_KEY}
                            showRowNumber={false}
                            sortData={undefined}
                            lookupValues={lookupValues}
                            changeData={changeData}
                            addUsageDetailsShareIPRow={addUsageDetailsShareIPRow}
                            onClickAddUsageDetails={this.onClickAddUsageDetails}
                        />}
                        <div>{tableList}</div>
                    </div>
                </td>
            </tr>
        );
    }
}