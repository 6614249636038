import { CheckboxJobParameterControl } from "../types/JobParameterControls/CheckboxJobParameterControl";
import { CheckboxListJobParameterControl } from "../types/JobParameterControls/CheckboxListJobParameterControl";
import { ComboBoxJobParameterControl } from "../types/JobParameterControls/ComboBoxJobParameterControl";
import { DatePickerJobParameterControl } from "../types/JobParameterControls/DatePickerJobParameterControl";
import { IDataSource, IDataSourceBase } from "../types/JobParameterControls/IDataSource";
import { IJobParameterControlBase } from "../types/JobParameterControls/IJobParameterControlBase";
import { MultiselectJobParameterControl } from "../types/JobParameterControls/MultiselectJobParameterControl";
import { NumericJobParameterControl } from "../types/JobParameterControls/NumericJobParameterControl";
import { RadioButtonJobParameterControl } from "../types/JobParameterControls/RadioButtonJobParameterControl";
import { TextInputJobParameterControl } from "../types/JobParameterControls/TextInputJobParameterControl";

export class JobParameterControlFactory {
    public static InstantiateControlList(controlList: IJobParameterControlBase[], dataSources: {[key: string]: IDataSourceBase}): IJobParameterControlBase[] {
        return controlList.map((e, i, a) => JobParameterControlFactory.InstantiateControl(e, dataSources));
    }

    public static InstantiateControl(control, dataSources: { [key: string]: IDataSourceBase }): IJobParameterControlBase {
        let result: IJobParameterControlBase = null
        switch(control.Type) {
            case "combobox":
                result = new ComboBoxJobParameterControl();
                for (let prop in control)
                    result[prop] = control[prop];

                let dsResult = result as ComboBoxJobParameterControl;

                if (typeof (dsResult.Options) == 'string') {
                    dsResult.DataSourceRepo = dataSources as { [key: string]: IDataSource<string> }
                    for (let dataSource in dataSources)
                        dsResult.DataSourceRepo[dataSource].Read = () => dsResult.DataSourceRepo[dataSource]
                }
                break;

            case "text-input":
                result = new TextInputJobParameterControl();
                for (let prop in control)
                    result[prop] = control[prop];
                break;

            case "date-picker":
                result = new DatePickerJobParameterControl();
                for (let prop in control)
                    result[prop] = control[prop];
                break;

            case "numeric":
                result = new NumericJobParameterControl();
                for (let prop in control)
                    result[prop] = control[prop];
                break;

            case "radio-button":
                result = new RadioButtonJobParameterControl();
                for (let prop in control)
                    result[prop] = control[prop];
                break;

            case "checkbox":
                result = new CheckboxJobParameterControl();
                for (let prop in control)
                    result[prop] = control[prop];
                break;

            case "checkbox-list":
                result = new CheckboxListJobParameterControl();
                for (let prop in control)
                    result[prop] = control[prop];
                break;

            case "multiselect":
                result = new MultiselectJobParameterControl();
                for (let prop in control)
                    result[prop] = control[prop];
                let typedResult = result as MultiselectJobParameterControl;

                if (typeof(typedResult.Options) == 'string') {
                    typedResult.DataSourceRepo = dataSources as {[key: string]: IDataSource<string>}
                    for (let dataSource in dataSources)
                        typedResult.DataSourceRepo[dataSource].Read = () => typedResult.DataSourceRepo[dataSource].sort((a, b) => a.code.localeCompare(b.code, undefined, { sensitivity: 'base' }))
                }
                break;

            default:
        }

        return result;
    }
}