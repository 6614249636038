import { TextField } from "@fluentui/react";
import { ReactNode } from "react";
import React from "react";
import { IJobParameterControl } from "./IJobParameterControl";

export class TextInputJobParameterControl
  implements IJobParameterControl<string>
{
  CurrentValue: string;
  DefaultValue: string;
  Options?: { code: string; description: string }[] = null;
  ControlID: string;
  Type:
    | "combobox"
    | "text-input"
    | "date-picker"
    | "numeric"
    | "radio-button"
    | "checkbox"
    | "checkbox-list"
    | "multiselect" = "text-input";
  Caption: string;
  Readonly: boolean;

  constructor() {}

  Render(): ReactNode {
    return (
        <div className="dropdownDataInput">
          <TextField
            readOnly={this.Readonly || false}
            label={this.Caption}
            id={this.ControlID}
            defaultValue={this.CurrentValue}
            onChange={(e, v) => {
              this.CurrentValue = v;
            }}
          />
        </div>
    );
  }
}
