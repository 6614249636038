import React from "react";
import WorkMaintenanceGeneralDataViewFormik, { IWorkMaintenanceGeneralDataViewFormikProps } from "./WorkMaintenanceGeneralDataViewFormik";

const WorkMaintenanceWrapper = ({
    myGeneralDataFormikRef,
    setIsChangeGeneralForm,
    workMaintenanceGeneralDataViewData,
    saveChanges,
    stampID,
    compoundType,
    isMaintained,
    registrationDate,
    source,
    sourceType,
    sourceSociety,
    genre,
    distributionCategory,
    workType,
    otherIndicators,
    readonlyIndicators,
    otherIndicatorsWorkFlagTypes,
    readonlyIndicatorsWorkFlagTypes,
    readonlyFlagsField,
    workBatchID,
    territorySpecificInfo,
    mergeThisWork,
    updateWorkField,
    updateWorkFields,
    lookupValues,
    genreCategories,
    dataSource,
    changesMade,
    postWorkBatch,
    matchWorkBatch,
    switchSetTypeGroup,
    saveInProgress,
    workBatchOperationInProgress,
    isReadonly,
    isOriginalWork,
    duration,
    isArrangement,
    isDistributed,
    isMaintainedMCPSI,
    showModal,
    hideModal,
    clearModalSearchResults,
    workSourceIP,
    updateComponentFieldsItems,
    refreshWorkDetails,
    showUpdateFieldsModal,
    editableFieldsDataView,
    dataGridTableData,
    setEditableFields,
    updateEditableField,
    activeTab,
    tabs,
    formatFields,
    isLibraryWork,
    isCommissionedForCommercial,
    editableFields,
    musicSource,
    workPart,
    workCreationDate,
    genreCategoriesByDomOrInt, 
    openAccordion,
    closeAccordion,
    defaultActiveAccordions,
    roles,
    isMaintainedLibraryWork,
    excludeSourceTypePickShowIP,
    musicArrangement,
    lyricAdaptation,
    filterGenreCategories,
    resetMessageBanner,
}) => {

    const [workMaintenanceData, setWorkMaintenanceData] = React.useState<IWorkMaintenanceGeneralDataViewFormikProps | null>(null);
    const [initialLoad, setInitialLoad] = React.useState(true);

    const handleWorkDetailRefresh = () => {
        refreshWorkDetails();
        setInitialLoad(true);
    }



    React.useEffect(() => {
      if (initialLoad && workMaintenanceGeneralDataViewData) {
        const initialState = {
            workMaintenanceGeneralDataViewData: workMaintenanceGeneralDataViewData,
            stampID: stampID,
            compoundType: compoundType,
            isMaintained: isMaintained,
            registrationDate: registrationDate,
            source: source,
            sourceType: sourceType,
            sourceSociety: sourceSociety,
            mergeThisWork: mergeThisWork,
            territorySpecificInfo: territorySpecificInfo,
            updateWorkField: updateWorkField,
            updateWorkFields: updateWorkFields,
            lookupValues: lookupValues,
            dataSource: dataSource,
            workBatchID: workBatchID,
            changesMade: changesMade,
            otherIndicatorsWorkFlagTypes: otherIndicatorsWorkFlagTypes,
            readonlyIndicatorsWorkFlagTypes: readonlyIndicatorsWorkFlagTypes,
            postWorkBatch: postWorkBatch,
            matchWorkBatch: matchWorkBatch,
            saveInProgress: saveInProgress,
            saveChanges: saveChanges,
            workBatchOperationInProgress: workBatchOperationInProgress,
            genre: genre,
            distributionCategory: distributionCategory,
            genreCategories: genreCategories,
            switchSetTypeGroup: switchSetTypeGroup,
            isReadonly: isReadonly,
            isOriginalWork: isOriginalWork,
            duration: duration,
            showModal: showModal,
            hideModal: hideModal,
            clearModalSearchResults: clearModalSearchResults,
            workSourceIP: workSourceIP,
            updateComponentFieldsItems: updateComponentFieldsItems,
            refreshWorkDetails: refreshWorkDetails,
            showUpdateFieldsModal: showUpdateFieldsModal,
            editableFieldsDataView: editableFieldsDataView,
            dataGridTableData: dataGridTableData,
            setEditableFields: setEditableFields,
            updateEditableField: updateEditableField,
            activeTab: activeTab,
            tabs: tabs,
            formatFields: formatFields,
            isArrangement: isArrangement,
            isDistributed: isDistributed,
            isMaintainedMCPSI: isMaintainedMCPSI,
            isLibraryWork: isLibraryWork,
            isCommissionedForCommercial: isCommissionedForCommercial,
            editableFields: editableFields,
            workType: workType,
            otherIndicators: otherIndicators,
            readonlyIndicators: readonlyIndicators,
            readonlyFlagsField: readonlyFlagsField,
            musicSource: musicSource,
            workPart: workPart,
            workCreationDate: workCreationDate,
            genreCategoriesByDomOrInt: genreCategoriesByDomOrInt,
            openAccordion: openAccordion,
            closeAccordion: closeAccordion,
            defaultActiveAccordions: defaultActiveAccordions,
            roles: roles,
            isMaintainedLibraryWork: isMaintainedLibraryWork,
            excludeSourceTypePickShowIP: excludeSourceTypePickShowIP,
            musicArrangement: musicArrangement,
            lyricAdaptation: lyricAdaptation,
            myGeneralDataFormikRef: myGeneralDataFormikRef,
            setIsChangeGeneralForm: setIsChangeGeneralForm,
            filterGenreCategories: filterGenreCategories,
            resetMessageBanner: resetMessageBanner,
        }
        setWorkMaintenanceData(initialState);
  
        setInitialLoad(false);
      }
    }, [initialLoad]);


    return workMaintenanceData && workMaintenanceData.workMaintenanceGeneralDataViewData ? <WorkMaintenanceGeneralDataViewFormik
    // things that update the state
        workSourceIP={workSourceIP}
        editableFields={editableFields}
        workBatchOperationInProgress={workBatchOperationInProgress}
        workBatchID={workBatchID}
        refreshWorkDetails={handleWorkDetailRefresh}
        myGeneralDataFormikRef={myGeneralDataFormikRef}
        setIsChangeGeneralForm={setIsChangeGeneralForm}

        // things that only update on load
        workMaintenanceGeneralDataViewData={workMaintenanceData.workMaintenanceGeneralDataViewData}
        stampID={workMaintenanceData.stampID}
        compoundType={workMaintenanceData.compoundType}
        isMaintained={workMaintenanceData.isMaintained}
        registrationDate={workMaintenanceData.registrationDate}
        source={workMaintenanceData.source}
        sourceType={workMaintenanceData.sourceType}
        sourceSociety={workMaintenanceData.sourceSociety}
        mergeThisWork={workMaintenanceData.mergeThisWork}
        territorySpecificInfo={workMaintenanceData.territorySpecificInfo}
        updateWorkField={workMaintenanceData.updateWorkField}
        updateWorkFields={workMaintenanceData.updateWorkFields}
        lookupValues={workMaintenanceData.lookupValues}
        dataSource={workMaintenanceData.dataSource}
        changesMade={workMaintenanceData.changesMade}
        otherIndicatorsWorkFlagTypes={workMaintenanceData.otherIndicatorsWorkFlagTypes}
        readonlyIndicatorsWorkFlagTypes={workMaintenanceData.readonlyIndicatorsWorkFlagTypes}
        postWorkBatch={workMaintenanceData.postWorkBatch}
        matchWorkBatch={workMaintenanceData.matchWorkBatch}
        saveInProgress={workMaintenanceData.saveInProgress}
        saveChanges={workMaintenanceData.saveChanges}
        genre={workMaintenanceData.genre}
        distributionCategory={workMaintenanceData.distributionCategory}
        genreCategories={workMaintenanceData.genreCategories}
        switchSetTypeGroup={workMaintenanceData.switchSetTypeGroup}
        isReadonly={workMaintenanceData.isReadonly}
        isOriginalWork={workMaintenanceData.isOriginalWork}
        duration={workMaintenanceData.duration}
        showModal={workMaintenanceData.showModal}
        hideModal={workMaintenanceData.hideModal}
        clearModalSearchResults={workMaintenanceData.clearModalSearchResults}
        updateComponentFieldsItems={workMaintenanceData.updateComponentFieldsItems}
        showUpdateFieldsModal={workMaintenanceData.showUpdateFieldsModal}
        editableFieldsDataView={workMaintenanceData.editableFieldsDataView}
        dataGridTableData={workMaintenanceData.dataGridTableData}
        setEditableFields={workMaintenanceData.setEditableFields}
        updateEditableField={workMaintenanceData.updateEditableField}
        activeTab={workMaintenanceData.activeTab}
        tabs={workMaintenanceData.tabs}
        formatFields={workMaintenanceData.formatFields}
        isArrangement={workMaintenanceData.isArrangement}
        isDistributed={workMaintenanceData.isDistributed}
        isMaintainedMCPSI={workMaintenanceData.isMaintainedMCPSI}
        isLibraryWork={workMaintenanceData.isLibraryWork}
        isCommissionedForCommercial={workMaintenanceData.isCommissionedForCommercial}
        workType={workMaintenanceData.workType}
        otherIndicators={workMaintenanceData.otherIndicators}
        readonlyIndicators={workMaintenanceData.readonlyIndicators}
        readonlyFlagsField={workMaintenanceData.readonlyFlagsField}
        musicSource={workMaintenanceData.musicSource}
        workPart={workMaintenanceData.workPart}
        workCreationDate={workMaintenanceData.workCreationDate}
        genreCategoriesByDomOrInt={workMaintenanceData.genreCategoriesByDomOrInt}
        openAccordion={workMaintenanceData.openAccordion}
        closeAccordion={workMaintenanceData.closeAccordion}
        defaultActiveAccordions={workMaintenanceData.defaultActiveAccordions}
        roles={workMaintenanceData.roles}
        isMaintainedLibraryWork={workMaintenanceData.isMaintainedLibraryWork}
        excludeSourceTypePickShowIP={workMaintenanceData.excludeSourceTypePickShowIP}
        musicArrangement={workMaintenanceData.musicArrangement}
        lyricAdaptation={workMaintenanceData.lyricAdaptation}
        filterGenreCategories={workMaintenanceData.filterGenreCategories}
        resetMessageBanner={workMaintenanceData.resetMessageBanner}
    /> : <>Loading...</>

}

export default WorkMaintenanceWrapper;