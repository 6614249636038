import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import React from "react";
import { IJobState } from "../../../redux/types/IJobState";
import { hideModal } from "../../../redux/reducers/ModalReducer";

export interface IJobsInProgressViewContainerProps {
    jobsToDisplay: string[];
    jobs: IJobState[];
}


export interface IJobsProgressModalProps {
    hideModal: typeof hideModal;
    jobsToDisplay: string[];
};
type IJobProgressModalViewConnectProps = IJobsProgressModalProps & IJobsInProgressViewContainerProps;
const JobsInProgressModal: React.FC<IJobProgressModalViewConnectProps> = ({ hideModal, jobs, jobsToDisplay}) => {

    const [numberOfJobs, setNumberOfJobs] = React.useState(0);

    React.useEffect(() => {
        setNumberOfJobs(jobs.length);
        
    }, [])

    const displayedJobs = jobs && jobs.filter(j => jobsToDisplay.includes(j.type));
    if (!displayedJobs || displayedJobs.length === 0) {
        return null;
    }
    return (<div className="modal-dialog modalLarge settingsModal">
                <div className="modal-content">
                    <div className='modal-header' style={{
                           background: "#38429C",
                           color: "white",
                    }}>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => hideModal()}>
                            <i className="icon ms-Icon ms-Icon--ChromeClose" style={{color: "white"}} title="Close"></i>
                        </button>
                            <h1 className="modal-title">{'Jobs in Progress'}</h1> 
                </div>
                    <div className="paddingLarge">
                        <div className="jobsProcessModal">
                            <ul>
                                {displayedJobs.map((j, idx) => <li key={idx}>{j.type}: {j.reference}</li>)}
                            </ul>
                        </div>
                    </div>
                    <div className="row flexRow flexJustifyContentRight marginHorizontalVeryLarge">
                            <div className="form-group col-sm-12 margin-topSmall alignRight " style={{width: '300px'}}>
                                <button className="button buttonAutowidth btn-defaultSecondary margin-rightMedium" onClick={() => hideModal()}>{"Dismiss"}</button>
                            </div>
                    </div>
                </div>
            </div>)
}

export default connect(
    (state: IAppState, ownProps: IJobsProgressModalProps) => ({
        jobs: state.job && state.job.jobs,
        jobsToDisplay: ownProps.jobsToDisplay
    })
) (JobsInProgressModal);

