import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import * as React from 'react';
import { IMemberStatementPaymentRunDetails, IMemberStatementPaymentRunUsageDetail, IMemberStatementPaymentRunWork } from '../../../types/IMemberStatementSearchResult';
import IMembersPortalComponentDataItem from '../../../../redux/types/IMembersPortalComponentDataItem';
import { Key, useState } from 'react';
import { mp_addTab } from '../../../../redux/reducers/MembersPortalReducer';
import { ITabReduxItem } from '../../../../redux/types/ITabReduxItem';
import { MEMBER_STATEMENTS_DETAILS_PAGE, MEMBER_STATEMENTS_USAGE_DETAILS_PAGE } from '../../../Consts';
import IconTextButton from '../../../../repertoire/components/iconTextButton/IconTextButton';

export interface IMemberStatementDetailsGridProps {
    paymentRunDetails: IMemberStatementPaymentRunDetails[];
    paymentDetailGridsComponentData: IMembersPortalComponentDataItem;
    workDetailGridsComponentData: IMembersPortalComponentDataItem;
    usageDetailsPageComponentData: IMembersPortalComponentDataItem;
    usageDetailsGridsComponentData: IMembersPortalComponentDataItem;
    addTab: typeof mp_addTab;
    paymentRunCode: string;
    description: string;
}

export interface IMemberStatementDetailsGridRowType {
    key: number | string;
    distributionCode: string;
    distributionDescription: string;
    poolCode: string;
    poolDescription: string;
    segment: string;
    detailAmount: string;
    paymentRunWorks: IMemberStatementPaymentRunWork[]
}

export interface IMemberStatementWorkDetailsGridRowType {
    workNumber: string;
    ipiNameNumber: string;
    ipName: string;
    title: string;
    sharePercentage: number;
    amount: number;
    usageDetails: IMemberStatementPaymentRunUsageDetail[];
}

export const MemberStatementDetailsGrid: React.FC<IMemberStatementDetailsGridProps> = (props: IMemberStatementDetailsGridProps) => {

    const [expandedRows, setExpandedRows] = useState<Key[]>([]);

    const openUsageDetails = (record: IMemberStatementPaymentRunWork) => {
        const { addTab, paymentRunCode, description } = props;

        const usageDetailsTab: ITabReduxItem = {
            title: `Usage Details (${record.workNumber})`,
            component: MEMBER_STATEMENTS_USAGE_DETAILS_PAGE.component,
            memberStatementUsageDetailsState: {
                usageDetails: {
                    paymentCode: paymentRunCode,
                    description,
                    detail: record
                },
                loaded: true
            }
        }
        addTab(usageDetailsTab);
    }

    const dataSource: IMemberStatementDetailsGridRowType[] = props.paymentRunDetails.map(prd => ({
        distributionCode: prd.distributionCode,
        distributionDescription: prd.distributionDescription,
        poolCode: prd.poolCode,
        poolDescription: prd.poolDescription,
        segment: prd.segment,
        detailAmount: prd.detailAmount,
        paymentRunWorks: prd.paymentRunWorks,
        key: `${prd.distributionCode}-${prd.poolCode}-${prd.segment}`
    }))

    const columns: ColumnsType<IMemberStatementDetailsGridRowType> = props.paymentDetailGridsComponentData.fields.map(f => (
        {
            title: f.data,
            dataIndex: f.name,
            key: f.name,
            sorter: ['distributionCode', 'poolCode'].includes(f.name) ? (a, b) => a[f.name].localeCompare(b[f.name]) : null
        }
    ));

    const expandedRowRender = (record: IMemberStatementDetailsGridRowType, index: number) => {
        let workDetails = record.paymentRunWorks;

        let data: IMemberStatementPaymentRunWork[] = workDetails.map(w => ({ ...w, sharePercentage: parseFloat(w.sharePercentage).toFixed(2), amount: parseFloat(w.amount).toFixed(2) }));

        let columns: ColumnsType<IMemberStatementPaymentRunWork> = props.workDetailGridsComponentData.fields.map(w => {
            if (w.name.includes('open')) {
                return {
                    title: w.data.split('/').pop(), dataIndex: w.name, key: w.name, fixed: w.name.includes('open') ? 'right' : null, render: (value, record) => {
                        return <>
                            <IconTextButton
                                text={w.data.split('/').pop()}
                                icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                onClick={() => openUsageDetails(record)}
                            />
                        </>
                    }
                }
            }
            else return {
                title: w.data,
                dataIndex: w.name,
                key: w.name,
                sorted: ['workNumber', 'ipiNameNumber', 'ipName'].includes(w.name) ? (a, b) => a[w.data].localeCompare(b[w.data]) : null
            }
        });

        columns.push()

        return <Table columns={columns} dataSource={data} />
    }


    return <div>
        <Table dataSource={dataSource} columns={columns} expandable={{
            expandedRowKeys: expandedRows,
            expandedRowRender: (record, index) => expandedRowRender(record, index),
            onExpand(expanded, record) {
                if (expandedRows.includes(record.key)) {
                    setExpandedRows(expandedRows => expandedRows.filter(x => x !== record.key))
                }
                else {
                    setExpandedRows(expandedRows => [...expandedRows, record.key]);
                }
            },
        }} />
    </div>
}