import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import {
    mp_addTab,
    resetPagination, sortSearchResults, updatePagination, setDataSource, createNewDraftSetLists, createNewDraftLivePerformance
} from "../../redux/reducers/MembersPortalReducer";
import { searchLivePerformancesThunk, getLivePerformanceDetailsThunk} from "../../redux/thunks/MembersPortalThunks";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";
import LivePerformanceSubmissionPage from "./LivePerformanceSubmissionPage";
import { ILivePerformanceSearchQuery } from "../types/ILivePerformanceSearchQuery";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { FormatFields } from "../../redux/types/FormatFields";

export default connect(
    (state: IAppState) => ({
        dataSource: state.membersPortal.dataSource,
        livePerformanceSubmissionPageData: state.membersPortal.componentData.LivePerformanceSubmissionPage,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        searchSuccessful: state.membersPortal.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'livePerformanceSubmission')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'livePerformanceSubmission')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'livePerformanceSubmission')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        allResultsPerPage: state.membersPortal.resultsPerPage,
        roles: state.account.roles,
        memberPortalSearchType: MemberPortalSearchType.MyDraftSearch,
        currentUserNameNumber: state.account.currentUserNameNumber,
        livePerformanceMaintenanceGeneralDataViewData: state.membersPortal.componentData.LivePerformanceMaintenanceGeneralDataView,
        livePerformanceSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceSearchResults,
    }),
    (dispatch: Dispatch) => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        searchLivePerformances: (query: ILivePerformanceSearchQuery) =>
            dispatch<any>(searchLivePerformancesThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        addNewLivePerformance: (lookups: ILookupDictionary, isNew?: boolean, livePerformanceMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[], distributionCode?: string, currentUserNameNumber?: string) =>
        dispatch(createNewDraftLivePerformance(lookups, isNew, livePerformanceMaintenanceGeneralDataViewData, formats, distributionCode, currentUserNameNumber)),
        getLivePerformanceDetails: (usageId) => dispatch<any>(getLivePerformanceDetailsThunk(usageId))
    })
)(LivePerformanceSubmissionPage);
