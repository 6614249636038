import * as React from "react";

export interface IIconTextButtonProps {
  icon: string;
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const IconTextButton: React.SFC<IIconTextButtonProps> = ({
  icon,
  text,
  onClick,
  disabled
}) => {
  return (
      <div className={["IconTextButton", !!disabled && "disabled"].join(' ')} onClick={!disabled ? onClick : null}>
          <i data-testid={"icon-text-btn"} className={[icon, !!disabled && "disabledIcon"].join(' ')} />
      {text}
    </div>
  );
};

export default IconTextButton;
