import { IJob } from "../../job/types/IJob";

// Action names
type RENDER_JOB_PROGRESS_TYPE = "RENDER_JOB_PROGRESS";

// Action types
export interface RenderJobProgress {
    type: RENDER_JOB_PROGRESS_TYPE;
    payload: {
        jobs: IJob[];
    };
}

export type ActionTypes = RenderJobProgress;

// Action names
export const RENDER_JOB_PROGRESS: RENDER_JOB_PROGRESS_TYPE = "RENDER_JOB_PROGRESS";
