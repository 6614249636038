import * as React from "react";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { updateRepresentationSelector } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IIPRepresentation } from "../../../types/IIPRepresentation";
import { WideActionButton } from "../../actionButton/ActionButton";
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { REPERTOIRE_PAGE_FORM_ITEM_SIZE, TEXT_INPUT } from "../../../Consts";
import { IDropdownOption } from "office-ui-fabric-react";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";

export interface IPoolSelectionViewProps {
    interestedPartyRepresentation: IIPRepresentation;
    hideModal: () => void;
    updateRepresentationSelector: typeof updateRepresentationSelector;
    dataGridTableData: IRepertoireComponentDataItem,
    lookupValues: ILookupDictionary;
    isReadOnly?: boolean;
    incrementTempID?: () => void;
    title?: string;
    modalIndex?: number;
    pools: IUsagePool[];
}

interface IPoolSelectionViewState {
    representation: IIPRepresentation;
    setPools: any[];
    poolOptions: any[];
    allOptionSelected: boolean;
}

export default class PoolSelectionView extends React.PureComponent<IPoolSelectionViewProps, IPoolSelectionViewState> {

    constructor(props: IPoolSelectionViewProps) {
        super(props);
        this.state = {
            representation: this.props.interestedPartyRepresentation, 
            setPools:[],
            poolOptions:[],
            allOptionSelected: false,
        }
    }

    componentDidMount() {
        const { interestedPartyRepresentation, title, pools } = this.props;
        let updatedPools = [];
        let updateSetPools = [];
        let selectedPools = [];

        if (interestedPartyRepresentation) {
            if (title === 'Pools Include') {
                updatedPools = interestedPartyRepresentation.includePools.value.split(",");
            } else {
                updatedPools = interestedPartyRepresentation.excludePools.value.split(",");
            }

            if (!updatedPools.includes('')) {

                if (updatedPools.includes('ALL')) {
                    let allOption = { key: 0, text: "ALL", data: "ALL" };
                    selectedPools.push(allOption);
                }
                else {
                    updatedPools.map(x => {
                        updateSetPools.push((pools.find(item => item.poolCode === x)))
                    })

                    updateSetPools.map(s => {
                        selectedPools.push({ key: s.poolId, data: s.poolId, text: s.poolCode, selected: true })
                    })
                }                
                                
                this.setState({ setPools: selectedPools })
            }
        }
    }

    multiSelectChangePoolData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        const { setPools } = this.state;

        let result = [];
        if (option.key === 0 && option.selected == true) {
            result.push(option)
            this.setState({ setPools: result }) 
        } else {

            if (setPools.length) {
                result = [...new Set(setPools)].map(x => { return x })
            }

            if (option.key != 0 && result.filter(x => x.key == 0).length > 0) {
                result.splice(0, 1);
            }

            if (result.filter(x => x.key == option.key).length == 0) {
                result.push(option)
            }
            else {
                const index = result.map(x => x.key).indexOf(option.key);
                if (index > -1) {                
                    result.splice(index, 1);
                }
            }

            this.setState({ setPools: result })     
        }          
    }

    render() {
        const { representation, setPools } = this.state;
        const { hideModal, isReadOnly, title, pools, interestedPartyRepresentation, modalIndex, updateRepresentationSelector } = this.props;

        let buttonSaveAction = () => {

            if (interestedPartyRepresentation) {
                let code = [] as any;
                let repId;
                if (interestedPartyRepresentation.representationID) {
                    repId = interestedPartyRepresentation.representationID;
                } else {
                    repId = modalIndex;
                }

                if (!setPools.some(e => e.key === 0)) {
                    setPools.map(x => code.push((pools.find(item => item.poolId === x.key)).poolCode))

                    if (title === 'Pools Include') {
                        let resultRep: IIPRepresentation = {
                            ...representation,
                            allPoolsIncluded: false,
                            includePools: { value: (code.join(",")), inputType: TEXT_INPUT }
                        }
                        updateRepresentationSelector(repId, resultRep);
                        hideModal();
                    } else {
                        let resultRep: IIPRepresentation = {
                            ...representation,
                            excludePools: { value: (code.join(",")), inputType: TEXT_INPUT }
                        }
                        updateRepresentationSelector(repId, resultRep);
                        hideModal();
                    }
                }
                else {
                    let resultRep: IIPRepresentation = {
                        ...representation,
                        allPoolsIncluded: true,
                        includePools: { value: (setPools.filter(x => x.key === 0)[0].text), inputType: TEXT_INPUT }
                    }
                    updateRepresentationSelector(repId, resultRep);
                    hideModal();
                }
            }            
        };

        let buttonCloseAction = () => {
            hideModal();
        };

        let poolOptions = [];
        let filteredPools = [];

        if (pools) {
            filteredPools = pools.filter(k => k.rights.every(e => e.economicRight == interestedPartyRepresentation.economicRights.value));
            filteredPools.map(x => poolOptions.push({ key: x.poolId, text: x.poolCode, data: x.poolId }));

            if (title === 'Pools Include') {
                let allOption = { key: 0, text: "ALL", data: "ALL" };
                poolOptions.unshift(allOption);
            }
            
        }

        let buttonDeselectAction = (value: any) => {
            let updatedPools = [];
            updatedPools = setPools.filter(item => item.key !== value.key);
            this.setState({ setPools: updatedPools })            
        };

        if (representation) {

            return (
                <div className="modal-dialog settingsModal" style={{ width: 952 }}>
                    <div className="modal-content">
                        <div style={{ height: 'auto', background: 'white' }}>
                            <div className='modal-header' style={{
                                background: "#38429C",
                                color: "white",
                            }}>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => hideModal()}>
                                <i className="icon ms-Icon ms-Icon--ChromeClose" style={{ color: "white" }} title="Close"></i>
                            </button>
                            <h1 className="modal-title">{title}</h1>
                            </div>
                            <div style={{ padding: 15 }}>
                                <div className="row">                                    
                                    <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                                        <div style={{ height: '100', position: 'relative', width: 920, overflow: 'hidden' }}>
                                            <Dropdown
                                                label={"Pools"}
                                                selectedKeys={setPools.map(x => x.key)}
                                                options={poolOptions}
                                                multiSelect={true}
                                                onChange={this.multiSelectChangePoolData}
                                                placeholder={"No Pools selected."}
                                                styles={{
                                                    title: { height: 40, backgroundColor: "WhiteSmoke", borderWidth: 1, borderColor: "LightGray", padding: 4, width: 400 },
                                                    dropdownItems: { height: 200 }

                                                }}
                                                disabled={false}
                                                dropdownWidth={920} />
                                        </div>
                                    </div>                                  
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className="selected-item-container">
                                        <div style={{ fontSize: 13, fontWeight: 600 }}>Selected items</div>
                                        <hr style={{ borderBottom: 2 }}></hr>
                                        <div style={{ height: 50, overflowY: 'scroll' }}>
                                            <div className="selected-items-list">
                                                {setPools && setPools.map((x, index) => {
                                                    return <div className="selected-item">
                                                        <span>{x.text}</span> &nbsp;&nbsp;
                                                        <span className="close-icon" onClick={() => buttonDeselectAction(x)}>&times;</span>
                                                    </div>
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{ borderBottom: 2 }}></hr>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <WideActionButton
                                        buttonText="Cancel"
                                        buttonAction={buttonCloseAction}
                                        disabled={isReadOnly}
                                        className="button btn-defaultSecondary" />
                                    <WideActionButton
                                        buttonText="Save"
                                        buttonAction={buttonSaveAction}
                                        disabled={isReadOnly} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else return <div></div>
    }

}
