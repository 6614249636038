import * as React from "react";
import {
    SAVE_CHANGES_STATE_KEY,
    POST_TO_REPERTOIRE_STATE_KEY,

    COPY_AGREEMENT_STATE_KEY,
    DELETE_AGREEMENT_STATE_KEY,
    ADD_NEW_AGREEMENT_STATE_KEY,
    DELETE_AGREEMENT_PROMPT_STATE_KEY,
    COPY_METHOD_TOOLBAR,
    UNDO_METHOD_TOOLBAR,
    UNDO_CHANGES_ACCOUNT_KEY,
    ADD_METHOD_TOOLBAR,
    DELETE_METHOD_TOOLBAR,
    SAVE_METHOD_TOOLBAR,
    AGREEMENT_MAINTENANCE_TOOLBAR,
    HISTORY_METHOD_TOOLBAR,
    VERSION_HISTORY_STATE_KEY,
    ALL_ROLES,
    DELETE_AGREEMENTS_ROLE,
    UPDATE_AGREEMENTS_ROLE,
    ADD_TO_PACKAGE_AGREEMENT_STATE_KEY,
    ADD_TO_PACKAGES_TOOLBAR
} from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { copyExistingAgreement, clearModalSearchResults, nextWorkflowInSession } from "../../../../redux/reducers/RepertoireReducer";
import { showYesNoPrompt, hideModal } from "../../../../redux/reducers/ModalReducer";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { DataSource } from "../../../types/DataSource";
import { IAgreementState } from "../../../../redux/types/IAgreementState";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { SaveOption } from "../../../types/SaveOption";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";
import { EntityType } from "../../../types/EntityType";

export interface IAgreementMaintenanceToolbarProps {
    changesMade: boolean;
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    clearModalSearchResults?: typeof clearModalSearchResults;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData: IRepertoireComponentDataItem;
    activeTab: number;
    lookupValues: ILookupDictionary;
    tabs: ITabReduxItem[];
    agreement: IAgreementState;
    deleteAgreement: () => void;
    saveChanges: (postAgreement: boolean) => void;
    addNewAgreement: (lookups: ILookupDictionary, isNew?: boolean, dataAction?: IDataActionToolbar[]) => void;
    copyExistingAgreement: typeof copyExistingAgreement;
    undoAgreement: () => void;
    resetMessageBanner: () => void;
    isNew?: boolean;
    dataSource?: DataSource;
    workflowFieldsData: IRepertoireComponentDataItem;
    currentUser?: string;
    searchVersionHistory: (agreementID: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[]) => void;
    isReadonly: boolean;
    roles?: string[];
    isWorkflowSessionStarted?: boolean;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    currentWorkflowIndex?: number;
    refreshWorkDetails?: () => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    completeWorkflowOnSave: (value: boolean) => void;
    isOpenForWorkflowSession?: boolean;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>void;
}

export interface IAgreementMaintenanceToolbarState {
    loaded: boolean;
}

export type AgreementMaintenanceToolbarKeys = keyof IAgreementMaintenanceToolbarProps;

class AgreementMaintenanceToolbar extends React.Component<
    IAgreementMaintenanceToolbarProps,
    IAgreementMaintenanceToolbarState
> {
    actionData;

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        this.actionData = {};
    }



    copyWork() {
        const { copyExistingAgreement, tabs, activeTab, currentUser } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        copyExistingAgreement(actionList, currentUser);
    }

    deleteWorkAction = () => {
        const { deleteAgreement, toolbarData, showYesNoPrompt, hideModal } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === DELETE_AGREEMENT_PROMPT_STATE_KEY).data;
        const propsModal: IYesNoPromptViewModalProps = {
            yesCallback: () => { deleteAgreement(); },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, propsModal);
    }

    saveChangesAction = (saveOption: SaveOption) => {
        const { saveChanges } = this.props;
        const postAgreement: boolean = saveOption === SaveOption.SavePost;
        saveChanges(postAgreement);
    }

    setActionList = () => {
        const {
            tabs,
            activeTab
        } = this.props;

        const actionList = { ...tabs[activeTab] };
        const newActionList: IDataActionToolbar[] = [];

        actionList.dataActionToolbar.map(action => {
            const actionItem = this.setActionAndName(action);
            if (actionItem !== null)
                newActionList.push(actionItem);
        });

        return newActionList;
    }

    searchVersionHistory = () => {
        const { searchVersionHistory, clearModalSearchResults, agreement, tabs, activeTab } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        clearModalSearchResults();
        searchVersionHistory(agreement.agreementID, tabs[activeTab], actionList);
    };
   
    AddtoPackage= () => {
        const { addToPackages, agreement, lookupValues, currentUser,tabs, activeTab, dataSource } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        console.log(currentUser,agreement.agreementID);
        addToPackages(currentUser,EntityType.Agreements ,agreement.agreementID);
    }
    
    getDeleteAgreementRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(DELETE_AGREEMENTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateAgreementRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_AGREEMENTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    setActionAndName(action: IDataActionToolbar) {
        const {
            undoAgreement,
            deleteAgreement,
            addNewAgreement,
            toolbarData: { fields }
        } = this.props;

        switch (action.method) {

            case COPY_METHOD_TOOLBAR: {
                return {
                    action: this.copyWork.bind(this),
                    name: fields.find(copy => copy.name === COPY_AGREEMENT_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--Copy',
                    isReadOnly: this.getUpdateAgreementRoleDisabled()
                }
            }

            case UNDO_METHOD_TOOLBAR: {
                return {
                    action: undoAgreement,
                    name: fields.find(undo => undo.name === UNDO_CHANGES_ACCOUNT_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--Undo',
                    isReadOnly: this.getUpdateAgreementRoleDisabled()
                }

            }

            case ADD_METHOD_TOOLBAR: {
                return {
                    action: addNewAgreement,
                    name: fields.find(add => add.name === ADD_NEW_AGREEMENT_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--CircleAddition',
                    isReadOnly: this.getUpdateAgreementRoleDisabled()
                }
            }

            case DELETE_METHOD_TOOLBAR: {
                return {
                    action: this.deleteWorkAction.bind(this),
                    name: fields.find(match => match.name === DELETE_AGREEMENT_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--Delete',
                    isReadOnly: this.getDeleteAgreementRoleDisabled() || this.getUpdateAgreementRoleDisabled()
                }
            }

            case SAVE_METHOD_TOOLBAR: {
                return {
                    action: this.saveChangesAction,
                    name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: '',
                    isReadOnly: this.getUpdateAgreementRoleDisabled()
                }
            }

            case HISTORY_METHOD_TOOLBAR: {
                return {
                    action: this.searchVersionHistory,
                    name: fields.find(save => save.name === VERSION_HISTORY_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--History'
                }
            }
            
            case ADD_TO_PACKAGES_TOOLBAR: {
                return {
                    action: this.AddtoPackage,
                    name: fields.find(addtopackage => addtopackage.name === ADD_TO_PACKAGE_AGREEMENT_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--AppIconDefaultAdd'
                }
            }
            

            default: return null;
        }
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            agreement,
            activeTab,
            lookupValues,
            tabs,
            dataSource,
            toolbarData,
            workflowFieldsData,
            isReadonly,
            isWorkflowSessionStarted,
            nextWorkflowItem,
            cancelWorkflowSession,
            skipWorkflowItem,
            workflowSessionItems,
            currentWorkflowIndex,
            isOpenForWorkflowSession,
            saveWorkflow,
            completeWorkflowOnSave,
            roles
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateAgreementRoleDisabled() && changesMade

        const actionList = this.setActionList()

        return (
            <>
                <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                    <ToolbarComponent
                        scroll={scroll}
                        toolbarWidth={toolbarWidth}
                        saveResultData={saveResultData}
                        resetMessageBanner={resetMessageBanner}
                        changesMade={changesMade}
                        agreement={agreement}
                        activeTab={activeTab}
                        lookupValues={lookupValues}
                        tabs={tabs}
                        showYesNoPrompt={showYesNoPrompt}
                        hideModal={hideModal}
                        dataSource={dataSource}
                        clearModalSearchResults={clearModalSearchResults}
                        isReadonly={isReadonly}
                        dataActionToolbar={actionList}
                        toolbarData={toolbarData}
                        component={AGREEMENT_MAINTENANCE_TOOLBAR}
                        isNew={tabs[activeTab].agreementMaintenanceState.isNew}
                        workflowFieldsData={workflowFieldsData}
                        isWorkflowSessionStarted={isWorkflowSessionStarted}
                        nextWorkflowItem={nextWorkflowItem}
                        skipWorkflowItem={skipWorkflowItem}
                        cancelWorkflowSession={cancelWorkflowSession}
                        workflowSessionItems={workflowSessionItems}
                        currentWorkflowIndex={currentWorkflowIndex}
                        isOpenForWorkflowSession={isOpenForWorkflowSession}
                        saveWorkflow={saveWorkflow}
                        roles={roles}
                        completeWorkflowOnSave={completeWorkflowOnSave}
                    />
                </div>
            </>
        );
    }
}

export default AgreementMaintenanceToolbar;
