import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import { SEARCH_VIEW_PRODUCTS } from "../Consts";
import { ActionButtonFormik } from "../../repertoire/components/actionButton/ActionButton";
import { REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../repertoire/Consts";
import { Field, FieldArray, Form, Formik } from "formik";
import { IProductSearchQuery } from "../types/IProductSearchQuery";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IProductSearchResult } from "../types/IProductSearchResult";
import { IMembersPortalField } from "../../membersportal/types/IMembersPortalField";
import SearchResultsTable from "./ProductSearchTable";
import { SizedDropdownDataInputFormik } from "../components/dropdownDataInput/DropdownDataInputFormik";
import SearchViewKeyEventListener from "../components/searchView/SearchViewKeyEventListener";

export interface IProductsSearchViewProps {
    searchProducts: (query: IProductSearchQuery, modalOpen: boolean) => void;
    getProductDetails?: (dataSource: string, productCoreID: number, isForWorkflowSession?: boolean, openNewTab?: boolean,id1?:string,id2?:string,type?:string,originOfRevision?:string,productionType?:string,musicDuration?:number[]) => void;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    productSearchResults: IProductSearchResult[];
    searchSuccessful: boolean;
    lookupValues: ILookupDictionary;
    searchDisabled: boolean;
    getShowCommentSubjectAsDropdownConfigurationParameter?: (group: string) => void;
    dataSource: string;

    expandProductResult?: (productResult: number) => void;
    expandedProductResults?: number[];
    expandAll?: boolean;
    expandAllResults?: () => void;
}
const ProductsSearchView: React.FC<IProductsSearchViewProps> = ({
    searchProducts,
    getProductDetails,
    searchResultsTableData,
    searchViewData,
    searchSuccessful,
    productSearchResults,
    lookupValues,
    dataSource,

    expandProductResult,
    expandAll,
    expandAllResults,
    expandedProductResults,
}) => {
    const [filteredFields, setFilteredFields] = React.useState<IRepertoireField[] | IMembersPortalField[]>([]);
    const [filteredSearchResults, setFilteredSearchResults] = React.useState<IRepertoireField[]>([]);
    const [typesLookups, setTypesLookups] = React.useState([]);
    const [tableResults, setTableResults] = React.useState([]);
    const [hasSearchHappenedAlready, setHasSearchHappenedAlready] = React.useState(false);
    const componentInstance = SEARCH_VIEW_PRODUCTS;

    React.useEffect(() => {
        if (lookupValues && lookupValues.ProductCoreType) {
        setTypesLookups(lookupValues.ProductCoreType.lookups)
        }
    }, [lookupValues, lookupValues.ProductCoreType])
    React.useEffect(() => {
        if (searchViewData && searchViewData.fields) {
            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === componentInstance
            );
            setFilteredFields(filteredSearchFields);
        }
        if (searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance
            );
            setFilteredSearchResults(filteredFields);
        }
    }, [searchViewData])
    React.useEffect(() => {
        if (productSearchResults && productSearchResults.length > 0) {
            setHasSearchHappenedAlready(true)
            setTableResults(
                productSearchResults
            )
        }
    }, [productSearchResults])
    const onSearch = (values) => {
        const searchQuery = {
            artist: values.artist,
            contributor: values.contributor,
            dataSource: dataSource,
            hasOpenWorkflow: null,
            number: values.number,
            productBatchID: values.productBatchID,
            productIDs: values.productIDs,
            title: values.title,
            productType: values.productType?.data ? values.productType?.data : '',
            recordCompany: values.recordCompany,
        }
         searchProducts(searchQuery, false);
    };
    const CustomInputComponent = ({
        field,
        form: { touched, errors },
        ...props
    }) => (
        <div>
            <input type="text" {...field} {...props} />
            {touched[field.name] &&
                errors[field.name] && <div className="error">{errors[field.name]}</div>}
        </div>
    );
    const renderFields = () => {
        let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
            let formField: React.ReactElement = <></>;
            if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal')) {
                formField = <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                    <label>{`${searchField.data}:`}</label>
                    <Field label={`${searchField.data}`} name={`${searchField.name}`} component={CustomInputComponent} />
                </div>
            }
            if (searchField.fieldType === 'dropdown' && searchField.name === 'productType') {
                formField = <SizedDropdownDataInputFormik
                                label={searchField.data}
                                fieldName={searchField.name}
                                useComboBoxAsMenuWidth={true}
                                options={typesLookups}
                                allowNull={true}
                                isHidden={false}
                            />
            }
            return formField;
        }
        return <FieldArray
            name="draftWorkForm"
            render={() => (
                <div>
                    {filteredFields.length > 0 ? (
                        filteredFields.map(filteredSearchFieldsMapper)
                    ) : <>Loading...</>}
                </div>
            )}
        />
    };
    if (filteredFields && filteredFields.length > 0) {
        return (
            <div>
                <div className="searchView">
                    <Formik
                        initialValues={{
                            artist: '',
                            contributor: '',
                            dataSource: 'Intray',
                            hasOpenWorkflow: '',
                            number: '',
                            productBatchID: '',
                            productIDs: [],
                            title: '',
                            productType: '',
                            recordCompany: '',
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            onSearch(values)
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting, isValid, dirty, resetForm }) => (
                            <Form>
                                <div key='searchFields' className="row">
                                <SearchViewKeyEventListener setStateInitial={resetForm} />
                                    {renderFields()}
                                </div>
                                <div key='action' className="row">
                                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                                        <ActionButtonFormik buttonText="Search" isSubmitting={isSubmitting} isErrorInForm={!isValid && dirty} />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                    <div className="row">
                        {searchSuccessful && tableResults && tableResults.length <= 0 && <div key='results' >
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                                No results found.
                            </div>
                        </div>}
                    </div>    
                </div>

          
                <div className="SearchResultsTable">
                {hasSearchHappenedAlready && tableResults && tableResults.length > 0 && <div key='results'>
                    <SearchResultsTable
                        dataGridTableData={filteredSearchResults}
                        expandAllResults={expandAllResults}
                        data={tableResults}
                        getProductDetails={getProductDetails}
                        dataSource={dataSource}
                        expandResult={expandProductResult}
                        expandedResults={expandedProductResults}
                        expandAll={expandAll}
                     />
                </div>}
            </div>
            </div>
        );
    }
    return <div>Loading ...</div>
}
export default ProductsSearchView;