import IModalState from "../types/IModalState";
import {
    USAGELOADING_VIEW, YES_NO_PROMPT_VIEW, LOADING_VIEW, VERSION_HISTORY_VIEW, WORK_SUBMISSION_VIEW,
    COMMENTS_VIEW, FIELD_SETTING_VIEW, MESSAGE_VIEW, ASSING_WORKFLOW_VIEW, UPDATE_WORKFLOW_FIELDS_VIEW, UPDATE_WORKFLOW_VIEW, DISTRIBUTION_VERSION_HISTORY_VIEW, SEARCH_VIEW_DISTRIBUTIONS, IP_TRANSFER_VIEW, SCHEDULED_JOB_MODAL_VIEW
} from "../../repertoire/Consts";
import { MANUAL_JOB_VIEW } from "../../home/Consts";
import {
    ActionTypes,
    ShowModal,
    HideModal,
    SHOW_MODAL,
    HIDE_MODAL,
    UpdateComment,
    UPDATE_COMMENT
} from "../actions/ModalActions";
import { IYesNoPromptViewModalProps } from "../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { IVersionHistoryViewModalProps } from "../../repertoire/components/modalViews/versionHistoryView/VersionHistoryView";
import { ISubmissionPromptViewModalProps } from "../../repertoire/components/modalViews/workSubmission/WorkSubmission";
import { IComment } from "../../repertoire/types/IComment";
import { ICommentsViewProps } from "../../repertoire/components/modalViews/commentsView/CommentsView";
import { IFieldSettingViewModalProps } from "../../repertoire/components/modalViews/fieldSettingView/FieldSettingView";
import { IPopUpInfoProps } from "../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import { IAssingWorkflowViewProps } from "../../repertoire/components/assingWorkflow/AssingWorkflowView";
import { IUpdateWorkflowViewProps } from "../../repertoire/components/updateWorkflow/UpdateWorkflowView";
import { IShowFieldSettingViewModalProps } from "../../repertoire/components/repertoireModal/RepertoireModal";
import { IManualJobViewModalProps } from "../../home/components/modalViews/ManualJobView";
import { IIpTransferViewModalProps } from "../../repertoire/components/modalViews/ipTransferView/IpTransferView";
import { IScheduledJobModalViewProps } from "../../repertoire/components/modalViews/scheduledJobModalView/ScheduledJobModalView";

const initialState: IModalState = {
    displayModal: false,
    displayModalCloseButton: false,
    modalContent: undefined,
    modalProps: undefined,
    modalComponentInstance: undefined,
    title: undefined,
    modalMessage: undefined,
    isReadOnlyModal: undefined,
    key: undefined,
    index: undefined
};
export function showModal(
    modalContent: string,
    modalComponentInstance: string,
    modalProps: any,
    displayModalCloseButton: boolean,
    title: string,
    modalMessage?: string,
    isReadOnly?: boolean,
    key?: string,
    index?: number
): ShowModal {
    return {
        
        type: SHOW_MODAL,
        payload: {
            modalContent,
            modalProps,
            modalComponentInstance,
            displayModalCloseButton,
            title,
            modalMessage,
            isReadOnly,
            key,
            index
        }
    };
}

export function showLoading(): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: LOADING_VIEW,
            modalProps: null,
            modalComponentInstance: null,
            displayModalCloseButton: false,
            title: null
        }
    }
}

export function showMessage(props: IPopUpInfoProps): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: MESSAGE_VIEW,
            modalProps: props,
            modalComponentInstance: null,
            displayModalCloseButton: true,
            title: null
        }
    }
}

export function showYesNoPrompt(title: string, props: IYesNoPromptViewModalProps, message?: string): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: YES_NO_PROMPT_VIEW,
            modalProps: props,
            modalComponentInstance: null,
            displayModalCloseButton: false,
            title: title,
            modalMessage: message
        }
    }
}
export function showScheduledJobModalView(title: string, props: IScheduledJobModalViewProps, modalComponentInstance: string, message?: string): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: SCHEDULED_JOB_MODAL_VIEW,
            modalProps: props,
            modalComponentInstance: modalComponentInstance,
            displayModalCloseButton: false,
            title: title,
            modalMessage: message
        }
    }
}

export function showVersionHistoryModal(title: string, componentInstance: string, props: IVersionHistoryViewModalProps): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: VERSION_HISTORY_VIEW,
            modalProps: props,
            modalComponentInstance: componentInstance,
            displayModalCloseButton: true,
            title: title
        }
    }
}

export function showDistributionVersionHistoryModal(title: string, componentInstance: string, props: IVersionHistoryViewModalProps): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: DISTRIBUTION_VERSION_HISTORY_VIEW,
            modalProps: props,
            modalComponentInstance: componentInstance,
            displayModalCloseButton: true,
            title: title
        }
    }
}

export function showWorkSubmission(submission: string, props: ISubmissionPromptViewModalProps): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: WORK_SUBMISSION_VIEW,
            modalProps: props,
            modalComponentInstance: null,
            displayModalCloseButton: false,
            title: ''
        }
    }
}

export function updateComment(comment: IComment): UpdateComment {
    return {
        type: UPDATE_COMMENT,
        payload: {
            value: comment
        }
    }
}

export function showUpdateFieldsModal(title: string, props: IShowFieldSettingViewModalProps, key?: string): ShowModal {
    if(key)
        return {
            type: SHOW_MODAL,
            payload: {
                modalContent: FIELD_SETTING_VIEW,
                modalProps: props,
                modalComponentInstance: null,
                displayModalCloseButton: true,
                title: title,
                key: key
            }
        }
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: FIELD_SETTING_VIEW,
            modalProps: props,
            modalComponentInstance: null,
            displayModalCloseButton: true,
            title: title
        }
    }
}

export function openassignWorkflowTo(tittle: string, props: IAssingWorkflowViewProps): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: ASSING_WORKFLOW_VIEW,
            modalProps: props,
            modalComponentInstance: null,
            displayModalCloseButton: true,
            title: tittle
        }
    }
}
export function showUpdateWorkflow(tittle: string, props: IUpdateWorkflowViewProps): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: UPDATE_WORKFLOW_VIEW,
            modalProps: props,
            modalComponentInstance: null,
            displayModalCloseButton: true,
            title: tittle
        }
    }
}

export function hideModal(): HideModal {
    return {
        type: HIDE_MODAL
    };
}

export function showManualJobView(title: string, componentInstance: string, props: IManualJobViewModalProps): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: MANUAL_JOB_VIEW,
            modalProps: props,
            modalComponentInstance: componentInstance,
            displayModalCloseButton: true,
            title: title
        }
    }
}

export function showIpTransferView(title: string, componentInstance: string, props: IIpTransferViewModalProps): ShowModal {
    return {
        type: SHOW_MODAL,
        payload: {
            modalContent: IP_TRANSFER_VIEW,
            modalProps: props,
            modalComponentInstance: componentInstance,
            displayModalCloseButton: true,
            title: title
        }
    }
}


const modalReducer = (
    state = initialState,
    action: ActionTypes
): IModalState => {
    switch (action.type) {
        case SHOW_MODAL: {
            const {
                modalContent,
                modalComponentInstance,
                modalProps,
                displayModalCloseButton,
                title,
                modalMessage,
                isReadOnly,
                key,
                index
            } = action.payload;
            document.body.style.overflow = "hidden";
            return {
                ...state,
                displayModal: true,
                displayModalCloseButton,
                modalContent,
                modalComponentInstance,
                modalProps,
                title,
                modalMessage,
                isReadOnlyModal: isReadOnly,
                key: key,
                index: index
            };
        }
        case HIDE_MODAL: {
            document.body.style.overflow = "auto";
            return {
                ...state,
                title: undefined,
                displayModal: false
            };
        }
        case UPDATE_COMMENT: {
            const { value } = action.payload;
            return {
                ...state,
                modalProps: value
            };
        }
        default:
            return state;
    }
};

export default modalReducer;
