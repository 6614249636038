import React from "react";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";

interface IDraftAgreementsAttachmentsReviewProps {
    attachedFiles: IStoragePathItemData[];
}

const DraftAgreementsAttachmentsReview: React.FC<IDraftAgreementsAttachmentsReviewProps> = ({
    attachedFiles
}) => {
    return <>
        <div className="headerContainer">
            <div className="row fullWidth">
                <div
                    className={["headerSection", "activeSection"].join(" ")}
                    key={'Attachments'}
                    data-testid={'Attachments' + "-section"}
                >
                    {'Attachments'}
                </div>
                <>
                    {attachedFiles && attachedFiles.map((file, index) => {
                        return <div className="flexRow fullWidth">
                                    <div className="flexRow fullWidth">
                                            <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                <div className="flexRow" style={{gap: '10px',alignItems: 'baseline'}}>
                                                    <label >{'Name :'}</label>
                                                    {file.fullName}
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                    <div className="flexRow" style={{gap: '10px',alignItems: 'baseline'}}>
                                                        <label >{'Attachment Type :'}</label>
                                                        {file.attachmentTypeCode}
                                                    </div>
                                            </div>

                                    </div>
                                </div>

                    })}
                </>
            </div>
        </div>
    </>
}

export default DraftAgreementsAttachmentsReview;