export function isNullOrUndefined(value: any) {
    return value === null || value === undefined;
}

export function convertSecondsToTimeStamp(value: number) {
    
    const date = new Date(0);
    date.setSeconds(value);
    const timeString = date.toISOString().substring(11, 19);

    // if seconds provided are more than one day
    if (value > 86399) {
        const days = ((value / 60) / 60) / 24;
        const floorDays = Math.floor(days) * 24;
        const splittedArray = timeString.split(':');
        const newHoursCount = (Number(splittedArray[0]) + floorDays).toString();
        return `${newHoursCount}:${splittedArray[1]}:${splittedArray[2]}`;
    }

    return timeString;
}