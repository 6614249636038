import * as React from "react";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { clearModalSearchResults, setEditableFields, updateDraftAgreementField, updateDraftWorkField } from "../../../../redux/reducers/MembersPortalReducer";
import { SizedDropdownDataInput } from "../../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { LANGUAGE_LOOKUP, WORK_GENRE_LOOKUP, PORTAL_DEFAULT_VALUES_LOOKUP } from "../../../../lookup/Consts";
import { SizedTextDataInput } from "../../../../repertoire/components/textDataInput/TextDataInput";
import { ADD_NEW_ACTION, DRAFT_AGREEMENTS_AGREEMENT_SIGNATURE_DATE_STATE_KEY, DRAFT_AGREEMENTS_APPLICABLE_WORKS_TYPE_STATE_KEY, DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY, DRAFT_AGREEMENTS_CATEGORY_STATE_KEY, DRAFT_AGREEMENTS_COMMENTS_STATE_KEY, DRAFT_AGREEMENTS_CREATION_DATE_STATE_KEY, DRAFT_AGREEMENTS_EFFECTIVE_FROM_STATE_KEY, DRAFT_AGREEMENTS_EFFECTIVE_TO_STATE_KEY, DRAFT_AGREEMENTS_IP_NAME_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_IP_NAME_STATE_KEY, DRAFT_AGREEMENTS_PUBLISHER_AGREEMENT_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_SOCIETY_AFFILIATION_STATE_KEY, DRAFT_AGREEMENTS_SOCIETY_AGREEMENT_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_STATUS_STATE_KEY, DRAFT_AGREEMENTS_TERRITORY_STATE_KEY, DRAFT_AGREEMENTS_TYPE_STATE_KEY, DRAFT_WORKS_DURATION_STATE_KEY, DRAFT_WORKS_GENRE_STATE_KEY, DRAFT_WORKS_INSTRUMENTATION_STATE_KEY, DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY, DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY, DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY, DRAFT_WORKS_LANGUAGE_STATE_KEY, DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY, DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY, DRAFT_WORKS_TITLES_STATE_KEY, DRAFT_WORKS_WORK_PROPERTIES } from "../../../Consts";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import { SizedCheckboxDataInput } from "../../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import DataGridTable from "../../../../repertoire/components/dataGridTable/DataGridTable";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import FormattedTimeDataInput from "../../../../repertoire/components/timeFieldDataInput/FormattedTimeDataInput";
import { MEMBERS_PORTAL } from "../../../../accounts/Consts";
import { IContributorItem } from "../../../../repertoire/types/IContributorItem";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import SizedDatePickerDataInput from "../../../../repertoire/components/dateDataInput/DatePickerDataInput";
import { REPERTOIRE_PAGE_FORM_ITEM_SIZE, SEARCH_VIEW, SEARCH_VIEW_AGREEMENT_IP, SEARCH_VIEW_IPS } from "../../../../repertoire/Consts";
import ShowMore from "../../../../repertoire/components/showMore/ShowMore";

export interface IDraftAgreementsMaintenanceGeneralDataViewProps {
    
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    agreement: IAgreementState;
    modalProps: any;
    updateDraftAgreementField: typeof updateDraftAgreementField;
    index: number;
    title: string;
}

interface IDraftAgreementsMaintenanceGeneralDataViewState {
    loaded: boolean;
    activeHeaderSection?: string;
}

export default class DraftAgreementsMaintenanceGeneralDataView extends React.PureComponent<
    IDraftAgreementsMaintenanceGeneralDataViewProps,
    IDraftAgreementsMaintenanceGeneralDataViewState> {
    viewData;
    constructor(props: IDraftAgreementsMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = (header: IMembersPortalField) => {

        return (
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={header.name}
                data-testid={header.name + "-section"}
            >
                {header.data}
            </div>
        );
    };


    showFieldsModal = () => {

    }

    render() {
        const {
            tabs,
            activeTab,
            showModal,
            hideModal,
            agreement,
            modalProps,
            updateDraftAgreementField,
            index,
            title
        } = this.props;

        const { loaded, activeHeaderSection } = this.state;

        const changeData = (value: any, fieldName: string) => {
            const newModalProps = { ...modalProps };
            newModalProps[fieldName].value = value;
            const comments = agreement.comments;
            comments[index] = newModalProps;

            updateDraftAgreementField(comments, "draftAgreementsComments");
        }
        return (
            <div className="modal-dialog settingsModal" style={{ width: 952 }}>
                <div className="modal-content">
                    <div style={{ height: 'auto', background: 'white' }}>
                        <div className='modal-header' style={{
                            background: "#38429C",
                            color: "white",
                        }}>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => hideModal()}>
                            <i className="icon ms-Icon ms-Icon--ChromeClose" style={{ color: "white" }} title="Close"></i>
                            </button>
                            <h1 className="modal-title">{title}</h1>
                        </div>
                        <div style={{ padding: 15 }}></div>
                        <div className="row flexWrapRow paddingVeryLarge">
                            <div className="subjectTextBox">
                                <SizedTextDataInput
                                    label="Subject"
                                    value={modalProps.subject.value}
                                    changeData={(value) => {
                                        changeData(value, "subject");
                                    }}
                                    fieldName={"subject"}
                                    isHidden={false}
                                    Width={920}
                                />
                            </div>
                            <div className="subjectTextBox" style={{marginLeft: 16}}>
                                <label>Comments:</label>
                                <textarea style={{ minHeight: '200px', width: 920 }}
                                    value={modalProps.comment.value}
                                    onChange={(e) => {
                                        changeData(e.target.value, "comment");
                                    }}
                                />
                            </div>
                        </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <div className="form-group col-md-4 col-sm-5 col-xs-12">
                            <button
                                className="button btn-defaultSecondary"
                                onClick={() => {
                            changeData("", "subject");
                            changeData("", "comment");
                            }}
                            >
                            Clear
                            </button>
                            </div>
                            <div className="form-group col-md-4 col-sm-5 col-xs-12">
                            <button
                            className="button btn-defaultPrimary"
                            onClick={() => {
                            hideModal();
                            }}
                            >
                            Done
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}