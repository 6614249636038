import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';
import IAppState from "./types/IAppState";
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { reducers } from "./reducers";
import * as Local from "./LocalStorageMiddleWare";
import { synchronizeSessionStorageAcrossTabs } from "./SessionStorageSynchronization";

synchronizeSessionStorageAcrossTabs();
// const logger = createLogger({
//    predicate: (getState, action) => !LoggerActionBlackList.some(i => action.type.includes(i))
// });

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;


export default function configureStore(history: History, initialState?: IAppState, portalType?: string) {
    const middleware = [
        thunk,
        routerMiddleware(history),
        Local.loadStorage,
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(Local.saveStorage), applyMiddleware(...middleware))//enhancer
    );

    return store;
}


