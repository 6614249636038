import * as React from "react";

interface IMessageBannerProps {
    messages: string[];
    messageType: MessageType;
    resetMessageBanner: () => void;
    isNotClosable?: boolean;
}

export enum MessageType {
    Success,
    Danger,
    Information,
    Warning
}

export class MessageBanner extends React.Component<IMessageBannerProps>{
    constructor(props: IMessageBannerProps) {
        super(props);
    }

    render() {
        const { messages, messageType, resetMessageBanner, isNotClosable } = this.props;
        const renderMessages = () => {
            if (!messages || messages.length === 0)
                return null;
            if (messages.length === 1)
                return <div>{messages[0]}</div>;
            else return <ul>
                {messages.map((m, i) => <li key={i}>{m}</li>)}
            </ul>
        };
        const renderClassName = (): string => {
            let className: string = "col-md-12 alert alert-dismissible fade in";
            switch (messageType) {
                case MessageType.Success:
                    className += " alert-success"
                    break;
                case MessageType.Danger:
                    className += " alert-danger"
                    break;
                case MessageType.Information:
                    className += " alert-info"
                    break;
                case MessageType.Warning:
                    className += " alert-warning"
                    break;
            }
            return className;
        }

        if (messageType === MessageType.Information) {
        return <div className={renderClassName()}>
            <a className="close" aria-label="close" onClick={resetMessageBanner}>&times;</a>
                <div className="alert-content">
                    <span className="icon info ms-Icon ms-Icon--Info"></span>
                    <span className="text">
            {renderMessages()}
                    </span>
                </div>
        </div>
        }
        else {
            return <div className={renderClassName()}>
                {isNotClosable ? <></> : <a className="close" aria-label="close" onClick={resetMessageBanner}>&times;</a>}
                {renderMessages()}
            </div>
        }
    }
}