import React, { useEffect } from "react";
import { IAgreementState } from "../../../../redux/types/IAgreementState";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

interface IDraftAgreementsApplicableWorkReviewProps {
    agreement: IAgreementState;
}

const DraftAgreementsApplicableWorkReview: React.FC<IDraftAgreementsApplicableWorkReviewProps> = ({
    agreement
}) => {
    const [worksData, setWorksData] = React.useState([]);

    useEffect(() => {
  
      if (agreement?.agreementWorks) {
        const dataSource = agreement.agreementWorks.map((work, index) => ({
          key: index,
          workName: work?.workName?.value ? work.workName.value : "",
          workNumber: work?.workNumber?.value ? work.workNumber.value : "",
      }));
    setWorksData(dataSource)
    }
  
  
    }, [agreement]);
  
      const columns: ColumnsType<any>  = [
          {
              title: 'Work Title',
              dataIndex: 'workName',
              width: 600,
              key: 'workName',
            },
            {
              title: 'Work Number',
              dataIndex: 'workNumber',
              key: 'workNumber',
            },
  ]

  
      return <div>
                    <div className="headerContainer">
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={'Applicable Works'}
                data-testid={'Applicable Works' + "-section"}
            >
                {'Applicable Works'}
            </div>
        </div>
        <div className='applicableWorkReviewTable'>
          <Table 
          dataSource={worksData}
          columns={columns} />
          </div>
      </div> 
}

export default DraftAgreementsApplicableWorkReview;