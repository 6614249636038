import React from "react";

const InvalidAdditionalNumbersAlert = ({
    text
}) => {

       return <div className="col-md-12 alert alert-dismissible fade in alert-danger">
                            <div>
                                {text}
                            </div>
                        </div>;
}

export default InvalidAdditionalNumbersAlert;