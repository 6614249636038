import * as React from "react";
import SearchResultsTableSection, { ISearchResultsTableSectionProps } from "./SearchResultsTableSection";
import { MEMBER_STATEMENTS_RESULTS_FILES_KEY, MEMBER_STATEMENTS_RESULTS_ID_STATE_KEY } from "../../../membersportal/Consts";
import { StorageService } from "../../../dataingestion/services/StorageService";

export default class StatementSearchResultsTableSection extends SearchResultsTableSection {
    constructor(props: ISearchResultsTableSectionProps) {
        super(props);
    }

    onClickExternalIcon = () => {
        const {
            lookupValues,
            tableContentItem: { id },
            getMemberStatementDetails,
        } = this.props;
            
        getMemberStatementDetails(id, lookupValues);  
    };

    onClickDownload = (fileName: string, paymentRunCode: any) =>
    {
        var extension = fileName.slice(fileName.length - 3).toUpperCase();

        var path = `payments/statements/PaymentRun-${paymentRunCode}_${extension === '302' ? 'CRD' : extension}/${fileName}`;   

        StorageService.downloadFile(path, false, fileName).then(
            (downloadLink: string) => {
                const link = document.createElement('a')
                link.href = downloadLink
                link.setAttribute(
                    'download',
                    `${fileName}`,
                );
              
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);         
            }
        );
    }

    render() {
        return <div className="tableCell">{this.renderTitleDiv()}</div>;
    }

    renderTitleDiv = () => {
        const { tableContentItem, objectKey, index, expandResult, expanded } = this.props;

        if (objectKey === "sourcePool" && Array.isArray(tableContentItem[objectKey])) {
            tableContentItem[objectKey] = tableContentItem[objectKey].join(', ')
        }

        let item;
        if (!this.excludeObjectKeys(objectKey)) {
            item = tableContentItem[objectKey];
        }
        else if (typeof (tableContentItem[objectKey]) == "string") {
            item = tableContentItem[objectKey] || "";
        }
        else {
            item = (tableContentItem[objectKey] || []).length > 0 ?
                tableContentItem[objectKey][0].toString() || "" : "";
        }

        let onExpandResult = () => {
            expandResult(index);
        }

        switch (objectKey) {
            case MEMBER_STATEMENTS_RESULTS_ID_STATE_KEY:
                return this.renderFirstColumnWithButtons(item, onExpandResult, expanded);
                break;

            case MEMBER_STATEMENTS_RESULTS_FILES_KEY:
                return this.renderStatementFiles(expanded, objectKey, tableContentItem, item);
                break;

            default:
                return (
                    <div className="arrowIconDiv">
                        {this.renderRegularTableItem(item)}
                    </div>
                    );
                break;
        }
    };

    renderRegularTableItem = (item: any) => {
        return (
        <div className="workTitle">
            {item ? item.replace(/,\s*$/, "") : item}
        </div>
        )
    }

    renderFirstColumnWithButtons = (item: any, onExpandResult: any, expanded: any) => {
        return (
            <div className="arrowIconDiv">
                <img
                    src="assets/right-arrow.svg"
                    title="Expand title"
                    alt="Right Arrow icon"
                    className={expanded ? "arrowIconExpanded" : "arrowIcon"}
                    onClick={() => onExpandResult()}
                />
                {this.renderRegularTableItem(item)}
                <img
                    src="assets/external-black.svg"
                    title="Open Statement in a new tab"
                    alt="External icon"
                    className="externalIcon"
                    onClick={this.onClickExternalIcon}
                />
            </div>
        )
    }

    renderStatementFiles = (expanded: any, objectKey: any, tableContentItem: any, item: any) => {
        if (expanded && (tableContentItem[objectKey] || []).length > 1)
        {
            return this.renderStatementFilesExpanded(tableContentItem, objectKey)
        }
        else
        {
            return(
                <div className="arrowIconDiv">
                    <img
                        src="assets/external-black.svg"
                        title="Download Statement"
                        alt="External icon"
                        className="statementDownloadIcon"
                        onClick={() => this.onClickDownload(item, tableContentItem["paymentRunCode"])}
                    />
                    {this.renderRegularTableItem(item)}
                </div>
            )
        }
    };

    renderStatementFilesExpanded = (row: any, statementFilesKey: string) => {
        return (
            <div className="arrowIconDiv">
                <div className="multipleTitlesDiv">
                    {row[statementFilesKey].map((item, index) => (
                        <div key={index}>
                            <img
                                src="assets/external-black.svg"
                                title="Download Statement"
                                alt="External icon"
                                className="statementDownloadIcon"
                                onClick={() => this.onClickDownload(item, row["paymentRunCode"])}
                            />
                            {item}
                            {index + 1 !== row[statementFilesKey].length ? <span>,</span> : null}
                        </div>
                    ))}
                </div>
            </div>
        );
    };
}
