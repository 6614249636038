import * as React from "react";
import SizedTextDataInput from "../../textDataInput/TextDataInput";
import { IDistributionPoolState } from "../../../../redux/types/IDistributionPoolState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { hideModal } from "../../../../redux/reducers/ModalReducer";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { updateForeignSocietyInfo } from "../../../../redux/reducers/RepertoireReducer";
import { WideActionButton } from "../../actionButton/ActionButton";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { SizedDropdownDataInput } from "../../dropdownDataInput/DropdownDataInput";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { CURRENCY_LOOKUP } from "../../../../lookup/Consts";
import { CURRENCY_STATE_KEY } from "../../../Consts";

export interface IForeignSocietyInfoViewProps {
    distributionPool: IDistributionPoolState;
    updateForeignSocietyInfo: (distributionPoolID: number, value: any, fieldName: string) => void;
    dataGridTableData: IRepertoireComponentDataItem;
    hideModal: typeof hideModal;
    lookupValues: ILookupDictionary;
    isReadOnly?: boolean;
}

const ForeignSocietyInfoView: React.FunctionComponent<IForeignSocietyInfoViewProps> = ({ distributionPool, updateForeignSocietyInfo, dataGridTableData, hideModal, lookupValues, isReadOnly }) => {
    const { distributionPoolID, witholdingTaxAgreement, witholdingTaxNoAgreement, socialAndCulturalDeduction, bankCharges, adminFees, currency, exchangeRate, otherAdditions, otherDeductions, narrative } = distributionPool
    let fields: IRepertoireField[] = dataGridTableData.fields.filter(x => x.componentInstance === "ForeignSocietyInfo")

    const validate = (distributionPoolID: number, value: any, fieldName: string) => {
        let val = isNaN(value) ? undefined : value;
        if (val === undefined) return false;
        updateForeignSocietyInfo(distributionPoolID, val, fieldName)
    }

    const toSignificantDigits = (distributionPoolID : number, value : any, fieldName: string, digits : number) =>{
        let val = isNaN(value) ? undefined : Math.abs(parseFloat(Number(value).toFixed(digits)));
        if (val === undefined) return false;
        updateForeignSocietyInfo(distributionPoolID, val, fieldName)
    }
    
    return (
        <div className="col-md-12">
            <div className="row">
                <SizedTextDataInput
                    label={fields.find(x => x.name === "witholdingTaxAgreement").data}
                    value={witholdingTaxAgreement.value === 0 ? "0.00" : witholdingTaxAgreement.value}
                    fieldName={""}
                    changeData={(x) => validate(distributionPoolID, x, "witholdingTaxAgreement")}
                    isOnBlurOn={true}
                    onBlurEvent={(x) => toSignificantDigits(distributionPoolID, x, "witholdingTaxAgreement", 2)}
                    isHidden={false}
                    readOnly={isReadOnly}
                />

                <SizedTextDataInput
                    label={fields.find(x => x.name === "witholdingTaxNoAgreement").data}
                    value={witholdingTaxNoAgreement.value === 0 ? "0.00" : witholdingTaxNoAgreement.value}
                    fieldName={""}
                    changeData={(x) => validate(distributionPoolID, x, "witholdingTaxNoAgreement")}
                    isOnBlurOn={true}
                    onBlurEvent={(x) => toSignificantDigits(distributionPoolID, x, "witholdingTaxNoAgreement", 2)}
                    isHidden={false}
                    readOnly={isReadOnly}
                />
            </div>

            <div className="row">
                <SizedTextDataInput
                    label={fields.find(x => x.name === "bankCharges").data}
                    value={bankCharges.value === 0 ? "0.00" : bankCharges.value}
                    fieldName={"subject"}
                    changeData={(x) => validate(distributionPoolID, x, "bankCharges")}
                    isOnBlurOn={true}
                    onBlurEvent={(x) => toSignificantDigits(distributionPoolID, x, "bankCharges", 2)}
                    isHidden={false}
                    readOnly={isReadOnly}

                />
            </div>      
            
            <div className="row">
                <SizedTextDataInput
                    label={fields.find(x => x.name === "adminFees").data}
                    value={adminFees.value === 0 ? "0.00" : adminFees.value}
                    fieldName={""}
                    changeData={(x) => validate(distributionPoolID, x, "adminFees")}
                    isOnBlurOn={true}
                    onBlurEvent={(x) => toSignificantDigits(distributionPoolID, x, "adminFees", 2)}
                    isHidden={false}
                    readOnly={isReadOnly}

                />
            </div>


            <div className="row">
                <SizedDropdownDataInput
                    label={fields.find(x => x.name === "currency").data}
                    fieldName={CURRENCY_STATE_KEY}
                    value={currency.value}
                    changeData={(x) => updateForeignSocietyInfo(distributionPoolID, x, "currency")}
                    options={[...new Map(getLookupValues(CURRENCY_LOOKUP, lookupValues).map(item => [item.code, item])).values()]}
                    allowNull={true}
                    isHidden={false}
                    readOnly={isReadOnly}
                    />
            </div>

            <div className="row">
                <SizedTextDataInput
                    label={fields.find(x => x.name === "exchangeRate").data}
                    value={exchangeRate.value === 1 ? "1.00" : exchangeRate.value}
                    fieldName={"subject"}
                    changeData={(x) => validate(distributionPoolID, x, "exchangeRate")}
                    isOnBlurOn={true}
                    onBlurEvent={(x) => toSignificantDigits(distributionPoolID, x, "exchangeRate", 10)}
                    isHidden={false}
                    readOnly={isReadOnly}
                />
            </div>

            <div className="row">
                <SizedTextDataInput
                    label={fields.find(x => x.name === "socialAndCulturalDeduction").data}
                    value={socialAndCulturalDeduction.value === 0 ? "0.00" : socialAndCulturalDeduction.value}
                    fieldName={"subject"}
                    changeData={(x) => validate(distributionPoolID, x, "socialAndCulturalDeduction")}
                    isOnBlurOn={true}
                    onBlurEvent={(x) => toSignificantDigits(distributionPoolID, x, "socialAndCulturalDeduction", 2)}
                    isHidden={false}
                    readOnly={isReadOnly}
                />

                <SizedTextDataInput
                    label={fields.find(x => x.name === "otherAdditions").data}
                    value={otherAdditions.value === 0 ? "0.00" : otherAdditions.value}
                    fieldName={"subject"}
                    changeData={(x) => validate(distributionPoolID, x, "otherAdditions")}
                    isOnBlurOn={true}
                    onBlurEvent={(x) => toSignificantDigits(distributionPoolID, x, "otherAdditions", 2)}
                    isHidden={false}
                    readOnly={isReadOnly}
                />

                <SizedTextDataInput
                    label={fields.find(x => x.name === "otherDeductions").data}
                    value={otherDeductions.value === 0 ? "0.00" : otherDeductions.value}
                    fieldName={"subject"}
                    changeData={(x) => validate(distributionPoolID, x, "otherDeductions")}
                    isOnBlurOn={true}
                    onBlurEvent={(x) => toSignificantDigits(distributionPoolID, x, "otherAdditions", 2)}
                    isHidden={false}
                    readOnly={isReadOnly}
                />
            </div>

            <div className="row">
                <SizedTextDataInput
                    label={fields.find(x => x.name === "narrative").data}
                    value={narrative.value}
                    fieldName={"comment"}
                    changeData={(x) => updateForeignSocietyInfo(distributionPoolID, x, "narrative")}
                    textArea
                    isHidden={false}
                    readOnly={isReadOnly}

                />
            </div>

            <div className="row">
                <WideActionButton
                    buttonText="OK"
                    buttonAction={hideModal}
                />
            </div>
        </div>
    );
};

export default ForeignSocietyInfoView;