import * as React from "react";
import { SizedTextDataInput } from "../../textDataInput/TextDataInput";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IMembersPortalField } from "../../../../membersportal/types/IMembersPortalField";
import { EMPTY_STRING_VALUE } from "../../../Consts";
import DisplayTableSearchResults from "../../../../membersportal/sharedComponents/displayTableSearchResults/DisplayTableSearchResults";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import ActionButton from "../../actionButton/ActionButton";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { useMsal } from "@azure/msal-react";
import { IClaimUnidentifiedClaimModal } from "../../../../membersportal/types/IClaimUnidentifiedClaimModal";
import DisplayText from "../../text/DisplayText";
import { IRegisteredWorkSearchQuery } from "../../../../membersportal/types/IRegisteredWorkSearchQuery";

export interface UnPaidClaimsWorkSearchModalViewProps {
    searchRegisteredWorks: (query: IRegisteredWorkSearchQuery, modalOpen: boolean) => void;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    registeredWorkSearchResults: any;
    searchSuccessful: boolean;
    paymentStatementGeneralDataViewData?: IMembersPortalComponentDataItem;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    currentUserNameNumber?: string;
    modalTitle: string;
    hideModal: () => void;
    claimUnpaidUsage: (query: IClaimUnidentifiedClaimModal) => void
    usage: any;
    fields: IMembersPortalField[];
}
const UnPaidClaimsWorkSearchModalView: React.FC<UnPaidClaimsWorkSearchModalViewProps> = ({
    searchRegisteredWorks,
    searchViewData,
    searchSuccessful,
    searchResultsTableData,
    registeredWorkSearchResults,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    resetPagination,
    updatePagination,
    hideModal,
    modalTitle,
    claimUnpaidUsage,
    currentUserNameNumber,
    usage,
    fields,
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const [filteredFields, setFilteredFields] = React.useState<IRepertoireField[] | IMembersPortalField[]>([]);
    const [filteredSearchResults, setFilteredSearchResults] = React.useState<IRepertoireField[]>([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const { instance, accounts } = useMsal();

    const [formData, setFormData] = React.useState({
        title: '',
        contributor: '',
        ipNumber: '',
        workNumber: '',
            });

    React.useEffect(() => {
        setLoaded(true);
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        resetPagination('unpaidClaimsMembersWorkSearch');
    }, [])

    React.useEffect(() => {

        if (searchViewData && searchViewData.fields) {
            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === 'unpaidClaimsMembersWorkSearch'
            );

            setFilteredFields(filteredSearchFields);
        }

        if (searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === 'productWorkSearch'
        
            );
            setFilteredSearchResults(filteredFields);
        }

    }, [searchViewData])

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        setCurrentPage(currentPage);
    }

    const changeData = (value: any, fieldName: IRepertoireStateKeys) => {
        setFormData(
            {...formData, [fieldName]: value}
        )
     }


    const  keyDownSubmit = (value: any) => {
        if (value.key === 'Enter') {
            onClickSearch();
        }
    }

    const onClickSearch = () => {
        let searchQuery = {
            title: formData.title,
            ipNumber: formData.ipNumber,
            contributor: formData.contributor,
            workNumber: formData.workNumber,
            artist: null,
            currentUserNameNumber: currentUserNameNumber,
            status: null,
            dataSource: "Repertoire",
            ipName: null,
        };
        searchRegisteredWorks(searchQuery, true);
    };


    const rowActions = {
        textButton: {
            text: 'Select',
            onClick: (work) => {
                const query = {
                    usageGroupId: usage.id,
                    claimedWorkId: work.workID,
                    claimantCurrentUserNameNumber: currentUserNameNumber,
                }
                claimUnpaidUsage(query);
                hideModal();
            }
        }
    }


    const renderSearchFields = () => {
            let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
                let formField: React.ReactElement = <></>;

                if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal')) {
                    formField =
                        <div key={index}>
                            <SizedTextDataInput
                                fieldType={searchField.fieldType}
                                useEnterSubmit={true}
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={(value, fieldName) => changeData(value, fieldName)}
                                handleKeyDown={keyDownSubmit}
                                value={searchField.name === 'values' ? EMPTY_STRING_VALUE : formData[searchField.name]}
                                isHidden={false}
                            />
                        </div>
                }

                return formField;

            };
            return filteredFields.map(filteredSearchFieldsMapper);
    };

    const titleField = fields.find(field => field.name === "registeredSearchTitle")

    if (loaded && filteredFields && filteredFields.length > 0) {
        return (
            <div className="repertoireModal">
                <div className={"modal"}>
                    <div>
                            <div className="closeButton" onClick={hideModal}>
                                <img src={"assets/close.svg"} className="closeIcon" />
                            </div>
                    </div>
                    <div className="fixedPart" />
                    <div className="dynamicPart">
                        <div className="title">{modalTitle}</div>                       
                        <div className="searchView">
                        <div key='searchFields' className="row">
                        <div className="paddingVeryLarge"><DisplayText text={titleField.data} /></div>
                            {renderSearchFields()}
                        </div>
                        <div key='action' className="row">
                            <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                                <ActionButton buttonText="Search" buttonAction={onClickSearch}/>
                            </div>
                        </div>
                        {searchSuccessful && registeredWorkSearchResults && <div key='results' className="resultsScrollSection">
                        <div className="resultsScrollSectionArea">
                            <DisplayTableSearchResults 
                                searchSuccessful={searchSuccessful} 
                                searchResultsTableData={filteredSearchResults} 
                                searchResults={registeredWorkSearchResults}
                                indexOfFirstResult={indexOfFirstResult}
                                indexOfLastResult={indexOfLastResult}
                                resultsPerPage={resultsPerPage}
                                currentPage={currentPage}
                                updatePagination={updateUserPagination}
                                resetPagination={resetPagination}
                                rowActions={rowActions}
                            />
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
                <div className="fixedPart" />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default UnPaidClaimsWorkSearchModalView;