import React, { useEffect } from "react";
import { IAgreementState } from "../../../../redux/types/IAgreementState";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

interface IDraftAgreementsCommentsReviewProps {
    agreement: IAgreementState;
}

const DraftAgreementsCommentsReview: React.FC<IDraftAgreementsCommentsReviewProps> =  ({
    agreement,
}) => {
  const [commentData, setCommentData] = React.useState([]);

  useEffect(() => {

    if (agreement?.comments) {


      const dataSource = agreement.comments.map((comment, index) => ({
        key: index,
        category: comment?.commentCategory?.value ? comment.commentCategory.value : "",
        comment: comment?.comment?.value ? comment.comment.value : "",
        descriptions: comment?.comment?.value ? comment.comment.value : "",
        subject: comment?.subject?.value ? comment.subject.value : "",
        date: comment?.date?.value ? comment.date.value : "",
    }));
  
  ;
  setCommentData(dataSource)
  }


  }, [agreement]);

    const columns: ColumnsType<any>  = [
      {
        title: 'Comment',
        dataIndex: 'comment',
        width: 600,
        key: 'comment',
      },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
          },
          {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
          },
          {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
          },
]


    return <div>
              <div className="headerContainer">
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={'Comments'}
                data-testid={'Comments' + "-section"}
            >
                {'Comments'}
            </div>
        </div>
        <div className='commentTable'>
          <Table 
          dataSource={commentData}
          columns={columns} />
        </div>
    </div>
}

export default DraftAgreementsCommentsReview;