import * as React from "react";
import { throttle, last } from "lodash";
import { ALL_ROLES, EVENT_HANDLER_THROTTLE_TIME, VIEW_DISTRIBUTIONS_ROLE} from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { showModal, showYesNoPrompt, hideModal } from "../../../../redux/reducers/ModalReducer";
import { setChangesMade, addTab, clearModalSearchResults } from "../../../../redux/reducers/RepertoireReducer";
import ClaimMaintenanceToolbar, { IClaimMaintenanceToolbarProps } from "../claimMaintenanceToolbar/ClaimMaintenanceToolbar";
import { ClaimMaintenanceGeneralDataView } from "../claimMaintenanceGeneralDataView/ClaimMaintenanceGeneralDataView";
import { ClaimMaintenanceGridsView } from "../claimMaintenanceGridsView/ClaimMaintenanceGridsView";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { updateClaimField } from "../../../../redux/reducers/RepertoireReducer";
import { IClaimState } from "../../../../redux/types/IClaimState";
import { IDistributionState } from "../../../../redux/types/IDistributionState";
import { triggerManualJob } from "../../../../redux/thunks/HomeThunks";



export interface IClaimMaintenancePageProps {
    claimMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    claimMaintenanceGridsViewData: IRepertoireComponentDataItem;
    claimMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    claimMaintenanceToolbarData?: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    claim: IClaimState;
    setChangesMade: typeof setChangesMade;
    updateClaimField: typeof updateClaimField;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    addTab: typeof addTab;
    saveChanges: (claim: IClaimState) => void;
    deleteClaim?: (claimID: number, activeTab: number) => void;
    addNewClaim: (lookupValues: ILookupDictionary, isNew?: boolean, claimMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => void;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    lookupValues: ILookupDictionary;
    editableFields: IRepertoireField[];
    resetMessageBanner: () => void;
    undoClaimChanges?: (claimID: number) => void;
    roles?: string[];
    processClaim: (claim: IClaimState) => void;
    getOpenDistributions: () => void;
    openDistributions: IDistributionState[];
    triggerManualJob: typeof triggerManualJob;
    updatedScroll?: number;
}

interface IClaimMaintenancePageState {
    toolbarWidth: number;
}

export class ClaimMaintenancePage extends React.PureComponent<
    IClaimMaintenancePageProps,
    IClaimMaintenancePageState
> {
    toolbarParentRef;
    constructor(props: IClaimMaintenancePageProps) {
        super(props)
        this.state = {
            toolbarWidth: null
        };
        this.toolbarParentRef = React.createRef();
    }

    componentDidMount() {
        const { getOpenDistributions, roles } = this.props;
        if (roles.includes(VIEW_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES)) getOpenDistributions();
    };


    saveClaim() {
        const { saveChanges, claim } = this.props;
        saveChanges(claim);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    render() {
        const {
            claimMaintenanceGeneralDataViewData,
            claimMaintenanceGridsViewData,
            claimMaintenanceSaveResultData,
            claimMaintenanceToolbarData,
            dataGridTableData,
            updateClaimField,
            addTab,
            tabs,
            activeTab,
            showModal,
            hideModal,
            lookupValues,
            claim,
            addNewClaim,
            roles,
            processClaim,
            openDistributions,
            getOpenDistributions,
            triggerManualJob,
            showYesNoPrompt
        } = this.props;

        const isReadonly: boolean = tabs[activeTab].isReadonly;
        const title = claim ? claim.claimCode : null;

        if (this.props.claim) {            
            const {updatedScroll } = this.props;
            const {
                toolbarWidth
            } = this.state;

            const changesMade = tabs[activeTab].changesMade;

            return (
                <div>
                    <div><span className="title">{title}</span></div>

                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <ClaimMaintenanceToolbar
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            changesMade={changesMade}
                            saveResultData={claimMaintenanceSaveResultData}
                            toolbarData={claimMaintenanceToolbarData}
                            saveChanges={this.saveClaim.bind(this)}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            addNewClaim={() => addNewClaim(lookupValues, true, claimMaintenanceGeneralDataViewData)}
                            activeTab={activeTab}
                            tabs={tabs}
                            showYesNoPrompt={showYesNoPrompt}
                            hideModal={hideModal}
                            claim={claim}
                            isReadonly={isReadonly}
                            clearModalSearchResults={clearModalSearchResults}
                            roles={roles}
                            processClaim={processClaim}
                            triggerManualJob={triggerManualJob}
                            lookupValues={lookupValues}
                        />
                    </div>
                    <ClaimMaintenanceGeneralDataView
                        claimMaintenanceGeneralDataViewData={claimMaintenanceGeneralDataViewData}
                        updateClaimField={updateClaimField}
                        activeTab={activeTab}
                        tabs={tabs}
                        claim={claim}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        componentInstance={'claimsGeneral'}
                        openDistributions={openDistributions}
                        getOpenDistributions={getOpenDistributions}
                    />
                    <ClaimMaintenanceGridsView
                        claimMaintenanceGridsViewData={claimMaintenanceGridsViewData}
                        claim={claim}
                        hideModal={hideModal}
                        dataGridTableData={dataGridTableData}
                        showModal={showModal}
                        addTab={addTab}
                        activeTab={activeTab}
                        tabs={tabs}
                        isReadonly={false}
                        lookupValues={lookupValues}
                        updateClaimField={updateClaimField}
                        clearModalSearchResults={clearModalSearchResults}
                        saveResultData={claimMaintenanceSaveResultData}
                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                    />
                </div>

            );
        }
        return <div>Loading...</div>;
    }
}

