import * as React from "react";
import { EDITABLE_FIELDS, FIELD_LABELS, IS_HIDDEN_FIELD, USAGE_PAGE_FORM_ITEM_SIZE, USAGE_PAGE_FORM_WIDE_SIZE } from "../../../Consts";
import {
    USAGEWEIGHT_SEARCH_USAGES_STATE_KEY,
    SEARCH_VIEW_USAGES,
    USAGE_PAGE_FORM_ACTION_BUTTON_SIZE_2,
    USAGE_MATCHING_WORKS_SECTION_KEY,
    USAGE_MATCHING_PRODUCTS_SECTION_KEY
} from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { InlineActionButton } from "../../actionButton/ActionButton";

export interface IInlineActionButtonWrapper {
    wrapperClassName: string, buttonAction: () => any, buttonText: string, buttonClassName: string
}

export interface ITextDataInputProps {
    label?: string;
    value?: any;
    changeData: (value: string | boolean, fieldName: IRepertoireStateKeys, isOnBlur?: boolean) => void;
    fieldName: IRepertoireStateKeys;
    useEnterSubmit?: boolean;
    handleKeyDown?: any;
    readOnly?: boolean;
    fieldType?: string;
    maxLength?: number;
    clickPrompt?: boolean;
    onClick?: any;
    textArea?: boolean;
    isHidden?: boolean;
    isOnBlurOn?: boolean
    onBlurEvent?: any;
    componentInstance?: string;
    InlineActionButtonWrapper?: IInlineActionButtonWrapper;
}

interface ITextDataInputState { }

export const SizedTextDataInput = (props: ITextDataInputProps) => {

    if (props.isHidden)
        return null;

    let containerClass: string = "";

    if (props.componentInstance === SEARCH_VIEW_USAGES || USAGE_MATCHING_WORKS_SECTION_KEY || USAGE_MATCHING_PRODUCTS_SECTION_KEY) {

        if (props.fieldName === USAGEWEIGHT_SEARCH_USAGES_STATE_KEY)
            containerClass = USAGE_PAGE_FORM_ACTION_BUTTON_SIZE_2;
        else
            containerClass = USAGE_PAGE_FORM_ITEM_SIZE;
    }

    return <div className={containerClass}>
        <TextDataInput {...props} />
    </div>
}

export const WideTextDataInput = (props: ITextDataInputProps) => {

    if (props.isHidden)
        return null;

    return <div className={USAGE_PAGE_FORM_WIDE_SIZE}>
        <TextDataInput {...props} />
    </div>
}

export default class TextDataInput extends React.PureComponent<
    ITextDataInputProps,
    ITextDataInputState
    > {
    constructor(props: ITextDataInputProps) {
        super(props);

        this.state = {};
    }

    _handleNumberInput = (event) => {
        event.target.setAttribute("type", "tel")
        const caret = event.target.selectionEnd
        let element = event.target
        window.requestAnimationFrame(() => {
            if (caret === 0) {
                element.selectionStart = caret + 1
                element.selectionEnd = caret + 1
            }
            else {
                element.selectionStart = caret
                element.selectionEnd = caret
            }
        })
    }

    render() {
        const {
            label,
            value,
            changeData,
            fieldName,
            handleKeyDown,
            useEnterSubmit,
            readOnly,
            fieldType,
            maxLength,
            onClick,
            clickPrompt,
            textArea,
            isOnBlurOn,
            onBlurEvent,
            InlineActionButtonWrapper
        } = this.props;

        const inputType = fieldType === "integer" ? "number" : "text";
        const editableLabel = fieldName === EDITABLE_FIELDS ? "editableLabel" : "";

        let inputElem = textArea ?
            <textarea
                value={value || ''}
                onChange={event => changeData(event.target.value, fieldName)}
            />
            : useEnterSubmit ?
                <input
                    type={inputType}
                    value={value || ''}
                    onChange={event => changeData(event.target.value, fieldName)}
                    onKeyDown={event => handleKeyDown(event, fieldName)}
                    maxLength={maxLength}
                /> : (clickPrompt ?
                    <input
                        type={inputType}
                        value={value || ''}
                        onChange={event => changeData(event.target.value, fieldName)}
                        maxLength={maxLength}
                        onClick={event => onClick(fieldName)}
                    /> : (readOnly ?
                        <input
                            type={inputType}
                            value={value || ''}
                            onChange={event => changeData(event.target.value, fieldName)}
                            maxLength={maxLength}
                            disabled
                        /> : (isOnBlurOn) ?
                            <input
                                type={inputType}
                                value={value || ''}
                                onChange={event => changeData(event.target.value, fieldName, true)}
                                maxLength={maxLength}
                            /> :
                            <input
                                type={inputType}
                                value={value || ''}
                                onChange={event => {
                                    if (inputType === 'number')
                                        this._handleNumberInput(event);
                                    changeData(event.target.value, fieldName)
                                }}
                                maxLength={maxLength}
                                className={editableLabel}
                                onBlur={event => event.target.setAttribute("type", inputType)}
                            />));

        const addInlineActionButton = (reactElement: JSX.Element) => {
            const { InlineActionButtonWrapper } = this.props;
            let newElement =
                <div className={InlineActionButtonWrapper.wrapperClassName} >
                    {reactElement}
                    <InlineActionButton buttonText={InlineActionButtonWrapper.buttonText} className={InlineActionButtonWrapper.buttonClassName}
                        buttonAction={() => {
                            InlineActionButtonWrapper.buttonAction()
                        }}
                    />
                </div>;
            return newElement;
        }

        return (
            <div>
                {label ? <label>{label}:</label> : null}

                {
                    InlineActionButtonWrapper ? addInlineActionButton(inputElem) : inputElem
                }
            </div>
        );
    }
}

