import * as React from 'react';
import { ComboBox, IDropdownOption } from "@fluentui/react";
import { If } from '../../../core/components/If';
import { IStoragePathItemData } from '../../types/IStoragePathItemData';
import { DateHelper } from '../../../core/services/DateHelper';
import { IDistribution } from '../../../repertoire/types/usageTypes/IDistribution';
import { ILookupInstance } from '../../../lookup/types/ILookup';
import { IFileSystem } from '../../types/IFileSystem';
import { editingChanged, fileMetadataChanged } from '../../../redux/reducers/DataIngestionReducer';
import { IComponentDataItem } from '../../../core/types/IComponentDataItem';
import { IComponentField } from '../../../core/types/IComponentField';
import {
    FileMetadataKey,
    IFileMetadata,
    FILE_METADATA_FILE_SIZE,
    FILE_METADATA_SOURCE,
    FILE_METADATA_TRACKINGINFORMATION,
    FILE_METADATA_BATCH_ID
} from '../../types/IFileMetadata';
import { ATTACHMENT_TYPE_NOT_REQUIRED, CONTRIBUTORS_KEY, SEARCH_VIEW_LICENSES_FILEIMPORT, SHOPPINGCART_KEY } from "../../../repertoire/Consts";
import ShowMore from '../../../repertoire/components/showMore/ShowMore';
import { DELETE_NOW_LABEL, EDIT_LABEL, PROCESS_NOW_LABEL } from './StoragePathItemFields';
import { StorageService } from '../../services/StorageService';
import { IAttachedFile } from '../../../redux/types/IAttachedFile';
import { DataSource } from '../../../repertoire/types/DataSource';
import { IAddWorkAttachmentModel } from '../../../redux/types/IAddAttchmentWorkModel';
import { ITreeData } from '../../../repertoire/types/ITreeData';
import DropdownTreeDataInput from '../../../repertoire/components/dropdownTreeDataInput/DropdownTreeDataInput';
import { IUsagePool } from '../../../repertoire/types/usageTypes/IUsagePool';
import { CONFIRM_UNDO_PROMPT_STATE_KEY } from '../../Consts';
import { IYesNoPromptViewModalProps } from '../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView';
import { hideModal, showYesNoPrompt } from '../../../redux/reducers/ModalReducer';
import TextDataInput from "../../../repertoire/components/textDataInput/TextDataInput";
import DropdownDataInput, { SizedDropdownDataInput } from '../../../repertoire/components/dropdownDataInput/DropdownDataInput';
import { ILookupDictionary } from '../../../lookup/types/ILookupDictionary';
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { AGREEMENT_ATTACHMENT_TYPE_LOOKUP, PRODUCT_ATTACHMENT_TYPE_LOOKUP, WORK_ATTACHMENT_TYPE_LOOKUP } from '../../../lookup/Consts';
import { ITabReduxItem } from '../../../redux/types/ITabReduxItem';
import DateDisplay from '../../../repertoire/components/dateDisplay/DateDisplay';

interface IStoragePathItemProps {
    storagePathItemData: IComponentDataItem;
    data: IStoragePathItemData;
    fileMetadataChanged: typeof fileMetadataChanged;
    editingChanged: typeof editingChanged;
    openDirectory?: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    hasMetadata: boolean;
    canEditMetadata: boolean;
    distributions: IDistribution[];
    destinations: ILookupInstance[];
    index: number;
    matchNow: (data: IStoragePathItemData, index: number) => void;
    isRepertoireModule?: boolean;
    removeAttachmentFromMaintenanceScreen?: (fullName: string, guid: string, index: number) => void;
    deleteFile?: (fullName: string, name: string) => void;
    displayRemove?: boolean;
    sources?: ITreeData[];
    pools?: IUsagePool[];
    undoUsageIngestion?: (fullFilePath: string) => void;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    showSearchModal?: (modalProps: any, searchViewKey: string) => void;
    isReadOnly?: boolean;
    lookupValues?: ILookupDictionary;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    isWorkAttachmentTypeNotRequired?: boolean;
    isWorkAttachmentTypeOptional?: boolean;
    isProductAttachmentTypeNotRequired?: boolean;
    isProductAttachmentTypeOptional?: boolean;
    currentDirectory?: string;
    path?: string;
    agreementAttachmentTypeRequired?: string;
}

interface IStoragePathItemState {
    attachmentType?: string
}

export class StoragePathItem extends React.Component<IStoragePathItemProps, IStoragePathItemState>{
    constructor(props: IStoragePathItemProps) {
        super(props);

        this.state = {
            attachmentType: ""
        }
    }

    getItemIcon(): string {
        if (this.props.data.isDirectory) {
            return "icon node-icon ms-Icon ms-Icon--FabricFolder";
        }
        else {
            return "icon iconHover node-icon ms-Icon ms-Icon--Download";
        }
    }

    editingChanged() {
        const { editingChanged, data, index } = this.props;
        editingChanged(data, index);
    }

    matchNow() {
        const { matchNow, data, index } = this.props;
        matchNow(data, index);
    }

    deleteInputFile(){
        
    }

    onClick() {
        const { data: { name, fullName, isDirectory }, openDirectory, sources, path } = this.props;
        var lastDirectory = /[^/]*$/.exec(fullName)[0];
        var fullPath = path + '/' + lastDirectory;

        if (isDirectory) {
            openDirectory({
                fullName,
                name,
                fileSystems: undefined,
                canEditMetadata: undefined,
                hasMetadata: undefined,
                jobTypes: undefined,
                parent: undefined,
                path: fullPath
            },
                sources);
        }
    }

    onUndoIngestion() {
        const { data: { inProgress, isDirectory, fullName, isDirty, isEditing, name, path }, undoUsageIngestion, storagePathItemData, showYesNoPrompt, hideModal } = this.props;
        if (!isDirectory && !inProgress && !isDirty && !isEditing) {
            if (fullName.split('/')[0] === 'usage') {
                const title: string = storagePathItemData.fields.find(f => f.name === CONFIRM_UNDO_PROMPT_STATE_KEY).data;
                const props: IYesNoPromptViewModalProps = {
                    yesCallback: () => {
                        const fullFilePath = `${path}/${name}`;
                        undoUsageIngestion(fullFilePath);
                        hideModal();
                    },
                    noCallback: () => {
                        hideModal();
                    }
                }
                showYesNoPrompt(title, props);
            }
        }
    }

    onDownloadFile() {
        const { data: { fullName, isDirectory, name }, isRepertoireModule } = this.props;
        if (!isDirectory) {
            StorageService.downloadFile(fullName, isRepertoireModule, name).then(
                (downloadLink: string) => {
                    if (downloadLink && downloadLink.length > 0 && !isRepertoireModule) {
                        const link = document.getElementById("downloadLinkDataIngestion");
                        link.setAttribute('href', downloadLink);
                        link.click();
                        link.removeAttribute('href');
                    }
                    else if (downloadLink && downloadLink.length > 0 && isRepertoireModule) {
                        const link = document.getElementById("downloadRepertoireAttachments");
                        link.setAttribute('href', downloadLink);
                        link.click();
                        link.removeAttribute('href');
                    }
                }
            );
        }
    }

    onRemoveFile() {
        const { data: { fullName, isDirectory, name }, isRepertoireModule, removeAttachmentFromMaintenanceScreen, index, displayRemove } = this.props;
        if (!isDirectory && isRepertoireModule && displayRemove === true) {
            const splitName: string[] = fullName.split('/');
            removeAttachmentFromMaintenanceScreen(fullName, splitName[splitName.length - 1], index);
        }
    }

    onDeleteFile(){
        const {data: { fullName, isDirectory, name }, deleteFile} = this.props;
        deleteFile(fullName, name);
    }

    formatFileSize(bytes, decimals = 2) {
        if (bytes === 0)
            return '0 B';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    changeAttachmentType = (attachmentType) => {
        const { data, tabs, activeTab } = this.props;

        tabs[activeTab].changesMade = true;

        this.setState({ attachmentType: attachmentType });

        data.attachmentTypeCode = attachmentType;
    }

    renderFields(): JSX.Element[] {
        const { data, storagePathItemData, canEditMetadata, isReadOnly, path } = this.props;
        const dir: string = data.fullName.replace('/' + data.name, '');
        const fields: IComponentField[] = storagePathItemData.fields.filter(f => f.componentInstance === dir);
        if (!fields)
            return null;
        const cells: JSX.Element[] = fields.map(f => this.renderField(f));
        cells.push(<td key='lastModified'><div><DateDisplay value={data.lastModified} showTime={true} /></div></td>);
        const trackingCellIndex = cells.findIndex(c => c.key === FILE_METADATA_TRACKINGINFORMATION);
        if (trackingCellIndex === -1) {
            cells.push(<td key='trackingInformation'><div></div></td>);
        } else {
            cells.push(cells.splice(trackingCellIndex, 1)[0]);
        }

        let editLabel = storagePathItemData.fields.filter(f => f.name === EDIT_LABEL)[0] != undefined ? storagePathItemData.fields.filter(f => f.name === EDIT_LABEL)[0].data : "Edit";
        let matchNowLabel = storagePathItemData.fields.filter(f => f.name === PROCESS_NOW_LABEL)[0] != undefined ? storagePathItemData.fields.filter(f => f.name === PROCESS_NOW_LABEL)[0].data : "Process Now";
        let deleteLabel = storagePathItemData.fields.filter(f => f.name === DELETE_NOW_LABEL)[0] != undefined ? storagePathItemData.fields.filter(f => f.name === DELETE_NOW_LABEL)[0].data : "Delete";

        const isSyncInput = path === "ingestionMatchingData/sync/input";

        if (data.inProgress) {
            cells.push(<td key='progressIcon'>
                <div>
                    <i className="icon ms-Icon ms-Icon--SyncOccurence" title="In Progress"></i>
                </div>
            </td>);
        }
        else if (!data.isEditing || !canEditMetadata) {
            cells.push(<td key='option'>
                <div className="tableCell hasIcon">
                    <If condition={canEditMetadata && !isSyncInput}>


                        <ShowMore
                            options={[
                                {
                                    text: editLabel,
                                    onClick: () => { this.editingChanged() },
                                    icon: "assets/external.svg",
                                },
                                {
                                    text: matchNowLabel,
                                    onClick: () => { this.matchNow() },
                                    icon: "icon ms-Icon ms-Icon--TriggerAuto",
                                    isIcon: true
                                },
                                {
                                    text:deleteLabel,
                                    onClick: () => { this.onDeleteFile() },
                                    icon: "icon node-icon ms-Icon ms-Icon--Delete",
                                    isIcon: true
                                }
                            ]}
                            isReadOnly={isReadOnly}
                        />
                    </If>
                </div>
            </td>);
        }
        return cells;
    }

    renderField(field: IComponentField): JSX.Element {
        const { data, canEditMetadata } = this.props;
        const { isEditing } = data;
        const metadata: IFileMetadata = !!data.metadata ? data.metadata : {
            source: undefined,
            poolCode: undefined,
            distributionCode: undefined,
            format: undefined,
            outputFormat: undefined,
            jobID: undefined,
            destination: undefined,
            fileSize: undefined,
            batchID: undefined,
            rightHolder: undefined,
            shoppingCart: undefined,
            trackingInformation: undefined
        };

        if(metadata.poolCode)
            metadata.poolCode = decodeURIComponent(metadata.poolCode);

        const key: FileMetadataKey = !!field ? field.name as FileMetadataKey : undefined;

        if (!field) {
            return <td key={key}></td>;
        }
        else if (field && field.name === FILE_METADATA_TRACKINGINFORMATION) {
            return <td key='trackingInformation'><div>{metadata[key]}</div></td>
        }
        else if (field && field.name === FILE_METADATA_FILE_SIZE) {
            return <td key={key}><div>{this.formatFileSize(metadata[key])}</div></td>
        }
        else if (field && field.name === FILE_METADATA_SOURCE) {
            return <td key={key}>{this.renderSourceField(metadata.source, data.sources)}</td>
        }
        else if (!field.fieldType || field.fieldType === "readonly") {
            return <td key={key}><div>{metadata[key]}</div></td>
        }
        else {
            switch (field.fieldType) {
                case "boolean":
                    if ((!isEditing && canEditMetadata)) {
                        return <td key={key}>
                            <div>
                                <If condition={!!metadata[key]}>
                                    <span className="icon node-icon ms-Icon ms-Icon--CheckMark"></span>
                                </If>
                                {metadata[key].valueOf().toString().replace(/true/gi, "Yes").replace(/false/gi, "No")}
                            </div>
                        </td>
                    }

                case "dropdown":
                    if (!data.isEditing || !canEditMetadata) {
                        return <td key={key}><div>{metadata[key]}</div></td>
                    }
                    else {
                        return <td key={key}>
                            <div>
                                {this.renderOptions(key, field.componentInstance, metadata)}
                            </div>
                        </td>
                    }
                case "text":
                    if (!data.isEditing || !canEditMetadata) {
                        return <td key={key}><div>{metadata[key]}</div></td>
                    }
                    else {
                        const divClassName = key === 'rightHolder' ? 'tableCell' : 'tableCell hasIcon'
                        return <td key={key}>
                            {/* <div className={divClassName}> */}
                            {this.renderOptions(key, field.componentInstance, metadata)}
                            {/* </div> */}
                        </td>
                    }
                default:
                    return <td key={key}></td>;
            }
        }
    }

    renderSourceField(selectedSource: string, sources: ITreeData[]): JSX.Element {
        const { data, fileMetadataChanged, index } = this.props;
        if (data.isEditing) {
            const selectedSourceChanged = (stateKey: string, value: any) => {
                const source = sources.filter(s => s.id == value);
                source && source[0] && fileMetadataChanged(source[0].hierarchy, stateKey, index)
            }

            return (
                <DropdownTreeDataInput
                    options={sources}
                    readOnly={false}
                    fieldName={"source"}
                    changeData={(value, stateKey) => selectedSourceChanged(stateKey, value)}
                    value={selectedSource}
                    isHidden={false}
                    allowNull={true}
                />
            )
        }
        else {
            return <>{selectedSource}</>;
        }
    }

    renderOptions(key: FileMetadataKey, componentInstance: string = "", metadata: IFileMetadata) {
        const { data, destinations, pools, distributions, sources, showSearchModal } = this.props;
        let formatsFiltered: ILookupInstance[] = data.formats;

        var parentNode;
        let parentPools = [];
        let childPools = [];
        let filteredPools = [];
        let filteredDistributions = [];
        let customNotebooks = [];

        if (pools && metadata.source) {
            childPools = pools.filter(c => c.sources.filter(cs => cs.source == data.sources.filter(x => x.hierarchy == metadata.source)[0].id)[0]);
            sources.forEach(function (source) {
                if (metadata.source == source.hierarchy) {
                    parentNode = metadata.source.replace(source.title, '');
                    parentNode = parentNode.substring(0, parentNode.length - 1);
                }
            });
            if (parentNode != "Global") {
                parentPools = pools.filter(p => p.sources.filter(ps => ps.source == data.sources.filter(y => y.hierarchy == metadata.source)[0].id)[0]);

            }
            filteredPools = parentPools.concat(childPools).filter((elem, index, self) => {
                return index === self.indexOf(elem);
            });
        }

        if (filteredPools && metadata.poolCode && distributions) {
            filteredDistributions = distributions.filter(d => d.distributionStatus !== 'Closed' && d.distributionPools && d.distributionPools.some(dp => dp.poolCode == metadata.poolCode))
        }

        if (metadata.source) {
            customNotebooks = data.sources
            .filter(s => s.hierarchy === metadata.source)
            .flatMap(s => s.customNotebooks.filter(notebook => notebook!== null && notebook!== ""));
        }

        switch (key) {
            case "format":
                let inputComponentRoot = componentInstance.substring(0, componentInstance.lastIndexOf("/"));
                formatsFiltered = data.formats.filter(format => format.extraFields == null || format.extraFields["DataType"].split(',').map(f => inputComponentRoot.indexOf(f)).filter(value => value >= 0).length > 0 && format.extraFields["InOut"] == "input")
                if (customNotebooks.length > 0) {
                    customNotebooks.forEach(customNotebook => {
                        formatsFiltered.push({
                            typeID: 0,
                            code: `${customNotebook}.ipynb`,
                            description: `${customNotebook}.ipynb`
                        });
                    });
                }
                
                return (
                    <DropdownDataInput
                        options={formatsFiltered.map((format) => ({ code: format.description, description: format.description }))}
                        changeData={this.props.fileMetadataChanged}
                        value={metadata && metadata[key] && (formatsFiltered.some(f => f.description === metadata[key]))? metadata[key] as string : ''}
                        fieldName={key}
                        allowNull={true}
                        isHidden={false}
                        index={this.props.index}
                        useComboBoxAsMenuWidth={false}
                    />
                )

            case "outputFormat":

                let outputComponentRoot = componentInstance.substring(0, componentInstance.lastIndexOf("/"));
                formatsFiltered = data.formats.filter(format => format.extraFields == null || format.extraFields["DataType"].split(',').map(f => outputComponentRoot.indexOf(f)).filter(value => value >= 0).length > 0 && format.extraFields["InOut"] == "output")

                let defaultValue = formatsFiltered.filter(format => format.extraFields != null && format.extraFields["Default"] == "true")

                if (metadata.outputFormat == null && defaultValue.length > 0) {
                    this.props.fileMetadataChanged(defaultValue[0].description, key, this.props.index)
                }

                return (
                    <DropdownDataInput
                        options={formatsFiltered.map((format) => ({ code: format.description, description: format.description }))}
                        changeData={this.props.fileMetadataChanged}
                        value={metadata && metadata[key] && formatsFiltered.some(f => f.description === metadata[key]) ? metadata[key] as string : ''}
                        fieldName={key}
                        allowNull={true}
                        isHidden={false}
                        index={this.props.index}
                        useComboBoxAsMenuWidth={false}
                    />
                )
            case "distributionCode":
                return (
                    <DropdownDataInput
                        options={filteredDistributions.map((dist) => ({ code: dist.distributionCode, description: dist.distributionCode }))}
                        changeData={this.props.fileMetadataChanged}
                        value={metadata && metadata[key] ? metadata[key] as string : ''}
                        fieldName={key}
                        allowNull={true}
                        isHidden={false}
                        index={this.props.index}
                        useComboBoxAsMenuWidth={false}
                    />
                )
            case "poolCode":
                return (
                    <DropdownDataInput
                        options={filteredPools.map((pool) => ({ code: pool.poolCode, description: pool.poolCode }))}
                        changeData={this.props.fileMetadataChanged}
                        value={metadata && metadata[key] ? metadata[key] as string : ''}
                        fieldName={key}
                        allowNull={true}
                        isHidden={false}
                        index={this.props.index}
                        useComboBoxAsMenuWidth={false}
                    />
                )
            case "destination":
                return (
                    <DropdownDataInput
                        options={destinations.map((destination) => ({ code: destination.description, description: destination.description }))}
                        changeData={this.props.fileMetadataChanged}
                        value={metadata && metadata[key] ? metadata[key] as string : ''}
                        fieldName={key}
                        allowNull={true}
                        isHidden={false}
                        useComboBoxAsMenuWidth={false}
                        index={this.props.index}
                    />
                )
            case "rightHolder":
                return (
                    <>
                        <TextDataInput
                            value={metadata && metadata[key] ? metadata[key] as number : ''}
                            changeData={(value, stateKey) => this.props.fileMetadataChanged(metadata[key], key, this.props.index)}
                            readOnly={false}
                            isHidden={false}
                            fieldName={key}
                        />
                        <td>
                            <ShowMore
                                options={[
                                    {
                                        text: "SelectIP",
                                        onClick: () => {
                                            showSearchModal(data, CONTRIBUTORS_KEY);
                                        },
                                        icon: "assets/external.svg",
                                    }
                                ]}
                            />
                        </td>
                    </>);
            case "shoppingCart":
                return (
                    <>
                        <TextDataInput
                            value={metadata && metadata[key] ? metadata[key] as number : ''}
                            changeData={(value, stateKey) => this.props.fileMetadataChanged(metadata[key], key, this.props.index)}
                            readOnly={false}
                            isHidden={false}
                            fieldName={key}
                        />
                        <td>
                            <ShowMore
                                options={[
                                    {
                                        text: "Select Shopping Cart",
                                        onClick: () => {
                                            showSearchModal(data, SEARCH_VIEW_LICENSES_FILEIMPORT);
                                        },
                                        icon: "assets/external.svg",
                                    }
                                ]}
                            />
                        </td>
                    </>);
            default:
                return null;
        }
    }

    render() {
        if (!this.props.data) {
            return <div />
        }
        else {
            const icon: string = this.getItemIcon();
            const { data, storagePathItemData, isRepertoireModule, displayRemove, lookupValues, isWorkAttachmentTypeNotRequired, isProductAttachmentTypeNotRequired,
                isWorkAttachmentTypeOptional, isProductAttachmentTypeOptional, currentDirectory, agreementAttachmentTypeRequired } = this.props;
            const dir: string = data.fullName.replace('/' + data.name, '');
            const hasMetadata: boolean = !!storagePathItemData.fields.find(f => f.componentInstance === dir);
            const showUndoIngestion: boolean = dir.split('/')[0] === 'usage' &&
                (
                (dir.split('/')[3] === 'archive') ||
                (dir.split('/')[1] === 'society' && dir.split('/')[2] === 'archive')
                );

            let isAttachmentTypeHidden: boolean = true;

            let isAttachmentTypeNullable: boolean = true;

            if (currentDirectory) {
                if (currentDirectory === "work") {
                    isAttachmentTypeHidden = isWorkAttachmentTypeNotRequired;
                    isAttachmentTypeNullable = isWorkAttachmentTypeOptional;
                }

                if (currentDirectory === "product") {
                    isAttachmentTypeHidden = isProductAttachmentTypeNotRequired;
                    isAttachmentTypeNullable = isProductAttachmentTypeOptional;
                }

                if (currentDirectory === "agreement") {
                    isAttachmentTypeHidden = agreementAttachmentTypeRequired === ATTACHMENT_TYPE_NOT_REQUIRED;
                    isAttachmentTypeNullable = true;
                }
            }

            let attchmentTypeLookupOptions = [];
            switch (currentDirectory) {
                case "work":
                    attchmentTypeLookupOptions = getLookupValues(WORK_ATTACHMENT_TYPE_LOOKUP, lookupValues);
                    break;
                case "product":
                    attchmentTypeLookupOptions = getLookupValues(PRODUCT_ATTACHMENT_TYPE_LOOKUP, lookupValues);
                    break;
                case "agreement":
                    attchmentTypeLookupOptions = getLookupValues(AGREEMENT_ATTACHMENT_TYPE_LOOKUP, lookupValues);
            }

            if (!hasMetadata) {

                return <tr onClick={this.onClick.bind(this)} key={data.name}>
                    <td key='icon'><div><span className={icon} onClick={this.onDownloadFile.bind(this)} title={!data.isDirectory ? 'Download file' : 'Directory'}></span>{data.name}</div></td>
                    <td key='lastModified'><div><DateDisplay value={data.lastModified} showTime={true} /></div></td>
                    <td key='trackingInformation'><div>{data.metadata && data.metadata.trackingInformation ? data.metadata.trackingInformation : ''}</div></td>
                    <If condition={!isAttachmentTypeHidden}>
                        <td key='attachmentType'>
                            <div>{<DropdownDataInput
                                options={attchmentTypeLookupOptions}
                                changeData={this.changeAttachmentType}
                                value={data.attachmentTypeCode}
                                fieldName={"attachmentType"}
                                allowNull={isAttachmentTypeNullable}
                                isHidden={false}
                                index={this.props.index}
                            />}</div>
                        </td>
                    </If>
                    <If condition={isRepertoireModule && displayRemove === true}>
                        <td key='remove_action'><div><span className='icon node-icon ms-Icon ms-Icon--Delete' onClick={this.onRemoveFile.bind(this)} title='Remove attachment'></span></div></td>
                    </If>
                </tr>
            }
            else if (data.isDirectory) {
                return <tr onClick={this.onClick.bind(this)} key={data.name}>
                    <td key={'icon'}><div><span className={icon}></span>{data.name}</div></td>
                    <td key={'2'}></td>
                    <td key={'3'}></td>
                    <td key={'4'}></td>
                    <td key={'5'}></td>
                    <td key={'6'}></td>
                    <td key={'7'}><div><DateDisplay value={data.lastModified} showTime={true} /></div></td>
                    <td key={'8'}><div /></td>
                </tr>
            }
            else {
                const rows = [];
                rows.push(<tr onClick={this.onClick.bind(this)} key={data.name}>
                    <td key='icon'><div>
                        {showUndoIngestion ? <span className='icon iconHover node-icon ms-Icon ms-Icon--Undo' onClick={this.onUndoIngestion.bind(this)}></span> : ''}
                        <span className={icon} onClick={this.onDownloadFile.bind(this)}></span>{data.name}
                    </div></td>
                    {this.renderFields()}
                </tr>);
                const { validationError } = this.props.data;
                if (validationError)
                    rows.push(<tr key={data.name + ' validation'}>
                        <td key='validationMessage'><div className='text-danger'>{validationError}</div></td>
                    </tr>);

                return rows;
            }
        }
    }
}
