import * as React from 'react';
import { MemberStatementDetailsGrid } from '../statementDetailsGrid/MemberStatementDetailsGrid';
import { IMemberStatementSearchResult } from '../../../types/IMemberStatementSearchResult';
import IMembersPortalComponentDataItem from '../../../../redux/types/IMembersPortalComponentDataItem';
import { mp_addTab } from '../../../../redux/reducers/MembersPortalReducer';

export interface IMemberStatementDetailsPageProps {
    payment?: IMemberStatementSearchResult;
    paymentDetailPageComponentData: IMembersPortalComponentDataItem;
    paymentDetailGridsComponentData: IMembersPortalComponentDataItem;
    workDetailGridsComponentData: IMembersPortalComponentDataItem;
    usageDetailsPageComponentData: IMembersPortalComponentDataItem;
    usageDetailsGridsComponentData: IMembersPortalComponentDataItem;
    addTab: typeof mp_addTab;
}

export const MemberStatementDetailsPage: React.FC<IMemberStatementDetailsPageProps> = (props: IMemberStatementDetailsPageProps) => {
    React.useEffect(() => {
        const {
            paymentDetailPageComponentData: { fields }
        } = props;
        let data = {}
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);

            visibleFields.forEach(item => {
                data[item.name] = item.data;

                if (Object.keys(data).length === visibleFields.length) {
                    setLoaded(true);
                    setViewData(data);
                }
            });
        }
    }, [])

    const [loaded, setLoaded] = React.useState(false);
    const [viewData, setViewData] = React.useState({});

    if (props.payment && loaded) {
        const { paymentRunCode, description, paymentRunDetails } = props.payment

        return <div>
            <div><span className="title">Payment {paymentRunCode}</span></div>
            <div><span className='subtitle'>{viewData['description']}</span></div>
            <div><span>{description}</span></div>
            <br />
            <div><span className='subtitle'>{viewData['statementBy']}</span></div>
            <div>
                <MemberStatementDetailsGrid
                    paymentRunDetails={paymentRunDetails}
                    paymentRunCode={paymentRunCode}
                    description={description}
                    paymentDetailGridsComponentData={props.paymentDetailGridsComponentData}
                    workDetailGridsComponentData={props.workDetailGridsComponentData}
                    usageDetailsPageComponentData={props.usageDetailsPageComponentData}
                    usageDetailsGridsComponentData={props.usageDetailsGridsComponentData}
                    addTab={props.addTab}
                />
            </div>
        </div>
    }
    else
        return <div></div>

}