import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { setDataSource } from "../../redux/reducers/MembersPortalReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { DataSource } from "../../repertoire/types/DataSource";
import { SearchRequests } from "../../repertoire/services/SearchRequests";
import { AGREEMENT_MAINTENANCE_GROUP, CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { FormatFields } from "../../redux/types/FormatFields";
import { IDraftAgreementSearchResult } from "../types/IDraftAgreementsSearchResult";
import { IDraftAgreementSearchQuery } from "../types/IDraftAgreementSearchQuery";
import DraftAgreementsSearchView from "./DraftAgreementsSearchView";
import { IViewAsAccessRequest } from "../types/IViewAsAccessRequest";

export interface IDraftAgreementsPageProps {
    draftAgreementsPageData?: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchDraftAgreements?: (query: IDraftAgreementSearchQuery, lookupValues: ILookupDictionary) => void;
    draftAgreementsSearchResults: IDraftAgreementSearchResult[];
    memberAgreementSearchResults?: IDraftAgreementSearchResult[];
    searchSuccessful: boolean;
    draftAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getDraftAgreementDetails: (dataSource: string, workID: number, lookupValues: ILookupDictionary, draftAgreementsMaintenanceDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    addNewDraftAgreement: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    setDataSource: typeof setDataSource;
    dataSource: string;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    currentUserNameNumber?: string;
    expandWorkResult?: (workResult: number) => void;
    expandedWorkResults?: number[];
    expandAllResults: () => void;
    expandAll: boolean;
    draftAgreementSearchResults?: any;
    viewAsAccessRequests?: IViewAsAccessRequest[];
    getViewAsRequests?: (userName: string) => void;
    currentUser?: string;
}

const DraftAgreementsPage: React.FC<IDraftAgreementsPageProps> = ({
    draftAgreementsPageData,
    addNewDraftAgreement,
    lookupValues,
    draftAgreementsMaintenanceGeneralDataViewData,
    currentUserNameNumber,
    searchViewData,
    searchResultsTableData,
    searchDraftAgreements,
    searchSuccessful,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    resetPagination,
    sortSearchResults,
    expandAllResults,
    expandAll,
    draftAgreementSearchResults,
    getDraftAgreementDetails,
    viewAsAccessRequests,
    getViewAsRequests,
    currentUser
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        checkIfLoaded();
        getViewAsRequests(currentUser);

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
        if(viewAsAccessRequests && sessionStorage.getItem('currentViewAsUserName') === null){
            let defaultUser=viewAsAccessRequests.find(item=>item.defaultAccess===true)
           sessionStorage.setItem('currentViewAsUserName', defaultUser.ipName)
        }
    }, [draftAgreementsPageData, loaded])


    const checkIfLoaded = () => {

        if (draftAgreementsPageData.fields && draftAgreementsPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = draftAgreementsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }
    const addNewDraftWorks = (): void => {
        addNewDraftAgreement(lookupValues, currentUserNameNumber, true, draftAgreementsMaintenanceGeneralDataViewData);
        // SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
        //     .then(formats => {
                
        //     });
    }

    let currentDataSource = DataSource.Intray;

    if (loaded) {

        const getAgreementDetails = (dataSource: string, agreementID: number) => {
            getDraftAgreementDetails(dataSource, agreementID, lookupValues, draftAgreementsMaintenanceGeneralDataViewData);
        }

        let searchPageLabelTitle = null;
        if (draftAgreementsPageData && draftAgreementsPageData.fields.length > 0) {
            searchPageLabelTitle = draftAgreementsPageData.fields.find((v, i, a) => v.name == "searchPageLabelTitle").data;
        }

        let searchResults = draftAgreementSearchResults
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">{searchPageLabelTitle}</span>
                        <div className="newWorkButton" title="Register New Work">
                            <div className="IconTextButton" onClick={addNewDraftWorks}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                Register New Agreement
                            </div>
                        </div>
                    </div>
                </div>
                <DraftAgreementsSearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    lookupValues={lookupValues}
                    searchDraftAgreements={searchDraftAgreements}
                    draftAgreementsSearchResults={searchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    resetPagination={resetPagination}
                    sortSearchResults={sortSearchResults}
                    expandAllResults={expandAllResults}
                    expandAll={expandAll}
                    currentUserNameNumber={currentUserNameNumber}
                    getAgreementDetails={getAgreementDetails}
                    dataSource={currentDataSource}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default DraftAgreementsPage;
