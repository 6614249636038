import * as React from "react";
import { FOOTER_ABOUT, FOOTER_ABOUT_LINK, FOOTER_CONTACT, FOOTER_CONTACT_LINK, FOOTER_COPYRIGHT, FOOTER_CUSTOMER_LINK, FOOTER_CUSTOMER_NAME, FOOTER_DISCLAIMER, FOOTER_DISCLAIMER_LINK, FOOTER_POWEREDBY_TEXT, FOOTER_TITLE } from "../../../membersportal/Consts";
import IMembersPortalComponentDataItem from "../../../redux/types/IMembersPortalComponentDataItem";


interface IFooterState {
    isLoading: boolean
}

interface IFooterProps {
    getMembersPortalFields: () => void;
    footerViewData:IMembersPortalComponentDataItem;
}
export class Footer extends React.Component<IFooterProps,IFooterState>{
    
    viewData;
    constructor(props: IFooterProps) {
        super(props);
        this.state = {
            isLoading: false
        }
       this.viewData={};
    }

    componentDidMount() {    
        const {
            getMembersPortalFields,
            footerViewData
        } = this.props;
        if (!footerViewData || footerViewData.fields.length === 0) {
            getMembersPortalFields && getMembersPortalFields();
        }else{
            const { footerViewData: { fields } } = this.props;

            fields.forEach(item => {
                this.viewData[item.name] = item.data;
                if (Object.keys(this.viewData).length === fields.length) {
                    this.setState({ isLoading: true });
                }
            });  
        }
    }

    componentDidUpdate(prevProps: IFooterProps, prevState: IFooterState) {
        const { isLoading } = this.state;
        if (!isLoading) {
            if (!this.props.footerViewData) {
                return;
            }

            const { footerViewData: { fields } } = this.props;

            fields.forEach(item => {
                this.viewData[item.name] = item.data;
                if (Object.keys(this.viewData).length === fields.length) {
                    this.setState({ isLoading: true });
                }
            });
        }
    }


    render() {
        if (this.viewData)
            return (
                <footer className="footer">
                    <div className="footer-container">
                        <h3>{ this.viewData[FOOTER_TITLE]}</h3>
                        <div className="footer-links">
                            <a href={this.viewData[FOOTER_ABOUT_LINK]} key={this.viewData[FOOTER_ABOUT]}>{ this.viewData[FOOTER_ABOUT]}</a>
                            <a href={this.viewData[FOOTER_DISCLAIMER_LINK]}key={this.viewData[FOOTER_DISCLAIMER]}>{ this.viewData[FOOTER_DISCLAIMER]}</a>
                            <a href={this.viewData[FOOTER_CONTACT_LINK]}key={this.viewData[FOOTER_CONTACT]}>{ this.viewData[FOOTER_CONTACT]}</a>
                            <a href={this.viewData[FOOTER_CUSTOMER_LINK]}key={this.viewData[FOOTER_CUSTOMER_NAME]}>{ this.viewData[FOOTER_CUSTOMER_NAME]}</a>
                        </div>
                        <div>© {new Date().getFullYear()} { this.viewData[FOOTER_COPYRIGHT]}</div>
                        <hr></hr>
                        <div className="powered-by-text">Powered by { this.viewData[FOOTER_POWEREDBY_TEXT]}</div>
                    </div>
                </footer>
            );
        return <div></div>
    }
}