import { connect } from "react-redux";
import { Dispatch } from "redux";
import { cleanStoragePathThunk, getFilesystemsThunk, selectedNodeChangedThunk } from "../../../redux/thunks/DataIngestionThunks";
import IAppState from "../../../redux/types/IAppState";
import { ITreeData } from "../../../repertoire/types/ITreeData";
import { ITreeNodeData } from "../../../treeview/types/TreeNodeData";
import { FolderSection } from "../../types/FolderSection";
import { IFileSystem } from "../../types/IFileSystem";
import { DataIngestionSideMenu } from "./DataIngestionSideMenu";

export default connect(
    (state: IAppState) => (
        {
            filesystems: state.dataIngestion.filesystems,
            selectedFileSystem: state.dataIngestion.selectedFileSystem,
            sidebarIsCollapsed: state.dataIngestion.sidebarIsCollapsed,
            treeNodes: state.dataIngestion.treeNodes,
            selectedNode: state.dataIngestion.selectedNode,
            sources: state.repertoire.matchingSourceTreeData,
            roles: state.account.roles,
        }),
    (dispatch: Dispatch) => ({
        getFilesystems: (disallowedFolderSections: FolderSection[]) => dispatch<any>(getFilesystemsThunk(false, disallowedFolderSections)),
        selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => dispatch<any>(selectedNodeChangedThunk(node, sources)),
        cleanStoragePath: () => dispatch<any>(cleanStoragePathThunk()),
    })
)(DataIngestionSideMenu);