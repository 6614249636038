export interface IAgreementSearchState {
    customDateRange: boolean;
    dateRange?: string;
    dateFrom?: Date;
    dateTo?: Date;
}

export type IAgreementSearchStateKeys = keyof IAgreementSearchState;

export const AGREEMENT_CUSTOM_DATE_RANGE: IAgreementSearchStateKeys = "customDateRange";
export const AGREEMENT_DATE_RANGE: IAgreementSearchStateKeys = "dateRange";
export const AGREEMENT_DATE_FROM: IAgreementSearchStateKeys = "dateFrom";
export const AGREEMENT_DATE_TO: IAgreementSearchStateKeys = "dateTo";