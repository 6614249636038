import { IArtist } from "../../repertoire/types/IArtist";

export function createCopyOfTableContents(tableContents: any, parseToList?: boolean) {

  let fields:any[] = [];
  if(parseToList){
        Object.keys(tableContents).map(function(key){
            fields.push(tableContents[key])
        })
        return fields;
  }
  return [...tableContents];
}

export function CopyTableContents(tableContents: IArtist[]) {
    return [...tableContents];
}