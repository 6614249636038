import React from "react";
import { IInstrumentation } from "../../types/IInstrumentation";
import { IArtist } from "../../types/IArtist";
import { IContributorItem } from "../../types/IContributorItem";
import { ITitle } from "../../types/ITitle";
import DisplayTable from "../../../membersportal/sharedComponents/displayTable/DisplayTable";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { IProductWorkDataItems } from "../../types/IProductWorkDataItems";
import { createCopyOfTableContents } from "../../../membersportal/services/TableFunctions";
import { getLookupValues, mapLookupKey } from "../../../lookup/services/LookupHelpers";
import { IRepertoireField } from "../../types/IRepertoireField";
import { SizedActionButton } from "../../components/actionButton/ActionButton";
import { PRODUCT_WORKS_KEY, SEARCH_ACTION, WORK_SEARCH_VIEW } from "../../Consts";

export interface IProductMaintenanceWorkTableContainerProps {
    tableContents: IInstrumentation[] | IArtist[] | IContributorItem[] | ITitle[] | IProductWorkDataItems[];
    dataGridTableData: IRepertoireComponentDataItem;
    componentInstance: string;
    openWork: (openItem: IProductWorkDataItems) => void;
    changeData: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    stateKey: string;
    lookupValues: any;
    showModal: any;
    isDataSourceIntray: boolean;
    isRecordType: boolean;
}

export const SIDE_TRACK_SEQUENCE = 'S/T/Sq';

const ProductMaintenanceWorkTableContainer: React.FC<IProductMaintenanceWorkTableContainerProps> = ({
    tableContents,
    dataGridTableData,
    componentInstance,
    stateKey,
    changeData,
    openWork,
    lookupValues,
    showModal,
    isDataSourceIntray,
    isRecordType,
}) => {
    const MUSIC_USAGE = 'musicUsage';
    const lookup = mapLookupKey(stateKey, MUSIC_USAGE);
    const musicUsageOptions = getLookupValues(lookup, lookupValues);
    const [tableContentsWithSideTrackAndSequence, setTableContentsWithSideTrackAndSequence] = React.useState(tableContents);
    const [filteredTableData, setFilteredTableData] = React.useState(dataGridTableData.fields);

    React.useEffect(() => {
        const tableContentsCopy = createCopyOfTableContents(tableContents);

            let filteredTableData = dataGridTableData.fields.filter(
                (field: IRepertoireField) => {
                    if (!isRecordType) {
                        return field.componentInstance === componentInstance && field.data !== 'Work' && (field.name !== 'side' && field.name !== 'track' && field.name !== 'sequence')
                    }
                        return field.componentInstance === componentInstance && field.data !== 'Work'
                }
            )
        setFilteredTableData(filteredTableData);
        setTableContentsWithSideTrackAndSequence(tableContentsCopy);

    }, [tableContents,
        dataGridTableData,
        componentInstance,
        stateKey,
        changeData,
        openWork,
        lookupValues,
        showModal,
        isDataSourceIntray,
        dataGridTableData.fields
    ])

    const removeRowAction = (rowItem: any) => {
        const tableContentsCopy = createCopyOfTableContents(tableContents);
        if (tableContentsCopy.indexOf(rowItem) > -1) {
            tableContentsCopy.splice(tableContentsCopy.indexOf(rowItem), 1);
        }
        changeData(tableContentsCopy, stateKey, null, null);
    }

    const rowAction = (openItem: any) => {
        openWork(openItem)
    }

    const onChangeMusicUsageDropDownData = (
        value: any,
        fieldName: string,
        rowIndex: number,
    ) => {
        const tableContentsCopy = createCopyOfTableContents(tableContents);
        tableContentsCopy[rowIndex][fieldName].value = value
        changeData(tableContentsCopy, stateKey);
    };

    const onChangeDurationData = (
        value: any,
        fieldName: string,
        rowIndex: number,
    ) => {
        const tableContentsCopy = createCopyOfTableContents(tableContents);
        tableContentsCopy[rowIndex][fieldName].value = value
        changeData(tableContentsCopy, stateKey);
    };

    const onChangeSequenceData = (
        value: any,
        fieldName: string,
        rowIndex: number,
    ) => {
        const tableContentsCopy = createCopyOfTableContents(tableContents);
        tableContentsCopy[rowIndex][fieldName].value = value
        changeData(tableContentsCopy, stateKey);
    };

    const lookupOptionsAndOnChangeFunctions = {
        musicUsage: {
            options: musicUsageOptions,
            onChangeValue: onChangeMusicUsageDropDownData,
        },
        duration: {
            onChangeValue: onChangeDurationData,
        },
        sideTrackSequence: {
            onChangeValue: onChangeSequenceData,
        },
    };

    const rowActions = {
        iconButton: {
            icon: "icon ms-Icon ms-Icon--OpenInNewTab",
            onClick: rowAction,
        }            
    }

    return  <div>
            <DisplayTable
                tableContents={tableContentsWithSideTrackAndSequence}
                dataGridTableData={filteredTableData}
                showRowNumber={false}
                rowActions={rowActions}
                removeRowAction={removeRowAction}
                lookupOptionsAndOnChangeFunctions={lookupOptionsAndOnChangeFunctions}
                isRenderLinkTitle={true}
            
            />
            <div className="tableContainer">
                <SizedActionButton
                    buttonText={dataGridTableData.fields.find(f => f.name === SEARCH_ACTION).data}
                    buttonAction={() => showModal(WORK_SEARCH_VIEW, PRODUCT_WORKS_KEY, {isDataSourceIntray: isDataSourceIntray}, true, 'Search')}
                />
            </div>
        </div>
}

export default ProductMaintenanceWorkTableContainer;