import { connect } from "react-redux";
import IAppState from "../../redux/types/IAppState";
import { addTab, setProductDataSource, createNewProduct, expandProductResult, expandAllResults, resetPagination, updatePagination, sortSearchResults } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { Dispatch } from "redux";
import { showModal } from "../../redux/reducers/ModalReducer";
import {
    updateUserPreferencesThunk, searchProductsThunk, searchArtistsThunk, searchContributorsThunk, getProductDetailsThunk, searchWorksThunk, getShowCommentSubjectAsDropdownConfigurationParameterThunk,
    AddtoPackageThunk
} from "../../redux/thunks/RepertoireThunks";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IProductSearchQuery } from "../types/IProductSearchQuery";
import ProductsPage from "./ProductsPage";
import { IArtistSearchQuery } from "../types/IArtistSearchQuery";
import { IContributorSearchQuery } from "../types/IContributorSearchQuery";
import { IWorksSearchQuery } from "../types/IWorksSearchQuery";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { FormatFields } from "../../redux/types/FormatFields";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { ITabProps } from "../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        productsPageData: state.repertoire.componentData.ProductsPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        dataSource: state.repertoire.productsDataSource,
        productSearchResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].productsSearchResult,
        workSearchResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].worksSearchResult,
        artistsSearchResult: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].artistsSearchResult,
        contributorsSearchResult: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].contributorsSearchResult,
        lookupValues: state.lookupEntities,
        searchSuccessful: state.repertoire.searchSuccessful,
        productMaintenanceGeneralDataViewData: state.repertoire.componentData.ProductMaintenanceGeneralDataView,
        expandedProductResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].expandedProductResults,
        expandAll: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].expandAll,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'products')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'products')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'products')[0].resultsPerSection,
        currentPage: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage : undefined,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        roles: state.account.roles,
        useNewUIgridSearchResults: state.repertoire.useNewUIgridSearchResults,
        enableCuesheet:state.repertoire.enableCuesheet,
        cuesheetDatasource:state.repertoire.cuesheetDatasource,
        username:state.account.currentUser
    }),
    (dispatch: Dispatch) => ({
        setProductDataSource: (dataSource: string) => dispatch(setProductDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        getProductDetails: (dataSource: string, productCoreID: number,productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats?: FormatFields[],openEntityForWorflowSession?: boolean,openNewTab?: boolean,id1?:string,id2?:string,productType?:string,originOfRevision?:string,enableCuesheet?:boolean,cuesheetDatasource?:any) =>
            dispatch<any>(getProductDetailsThunk(dataSource, productCoreID,productMaintenanceGeneralDataView,formats,openEntityForWorflowSession,openNewTab,id1,id2,productType,originOfRevision,enableCuesheet,cuesheetDatasource)),
        searchProducts: (query: IProductSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchProductsThunk(query, false)),
        searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchWorksThunk(query, true)),
        searchArtists: (query: IArtistSearchQuery, dataSource: string) =>
            dispatch<any>(searchArtistsThunk(query, dataSource)),
        searchContributors: (query: IContributorSearchQuery) =>
            dispatch<any>(searchContributorsThunk(query)),
        addNewProduct: (lookups: ILookupDictionary, isNew?: boolean,productMaintenanceGeneralDataView?: IRepertoireComponentDataItem,formats?: FormatFields[]) => 
            dispatch(createNewProduct(lookups, isNew, productMaintenanceGeneralDataView, formats)),
        expandProductResult: (productResult: number) => dispatch<any>(expandProductResult(productResult)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        getShowCommentSubjectAsDropdownConfigurationParameter: (group: string) => dispatch<any>(getShowCommentSubjectAsDropdownConfigurationParameterThunk(group)),
        addToPackages:( userName:string,entityType:string,entityID:number)=>dispatch<any>(AddtoPackageThunk(userName,entityType,entityID)),
    })
)(ProductsPage);
