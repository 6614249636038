import 'rc-tooltip/assets/bootstrap_white.css';
import Tooltip from 'rc-tooltip';
import { FC, JSXElementConstructor, ReactElement, useState } from 'react';

export enum TooltipPlacement {
    top = 'top',
    bottom = 'bottom',
    left = 'left',
    right = 'right',
}

interface ITooltipProps {
    tooltipContent: string;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    showTooltip?: boolean;
    fieldName?: string;
    children: ReactElement<any, string | JSXElementConstructor<any>> | any;
}

const ITooltip: FC<ITooltipProps> = ({ children, tooltipContent, placement, showTooltip }) => {
    const [isShowing, setIsShowing] = useState(false);
    return showTooltip && tooltipContent && tooltipContent !== '' ? (
        <div onMouseEnter={() => setIsShowing(true)} onMouseLeave={() => setIsShowing(false)}>
            <Tooltip
            visible={isShowing}
            placement={placement ? placement : "top"} 
            trigger={['hover']} 
            overlay={<span>{tooltipContent}</span>}>
                <>{children}</>
                </Tooltip>
                </div>
        ) : children;
}

export default ITooltip;