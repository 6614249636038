import { Dropdown } from "reactstrap/lib";
import { nodeModuleNameResolver } from "typescript";
import { WORK_SUBJECT_LOOKUP } from "../../lookup/Consts";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { deleteUsagePoolFailure } from "../../redux/reducers/RepertoireReducer";
import { IPaymentRunDistributionState } from "../../redux/types/IPaymentRunDistributionState";
import { IPaymentRunState } from "../../redux/types/IPaymentRunState";
import { DATE_INPUT, DROPDOWN_INPUT, NOT_ALLOCATED_STATUS, READ_ONLY_INPUT, TEXT_INPUT, CHECKBOX_INPUT, INTEGER_INPUT, IS_ACTIVE, SHEET_MUSIC_WORK_TYPE_CODE, IS_MUSIC, } from "../Consts";
import { ISelectionCriteria } from "../types/ISelectionCriteria";
import { IPaymentRun } from "../types/usageTypes/IPaymentRun";
import { IPaymentRunDistribution } from "../types/usageTypes/IPaymentRunDistribution";

import { ISubjects } from "../types/usageTypes/ISubjects";

export class PaymentRunDetailsMapper {
    public static mapPaymentRunToState(paymentRun: IPaymentRun, lookupValues : ILookupDictionary): IPaymentRunState {
        if (!paymentRun) return null;

        const {
            paymentRunID,
            code,
            status,
            type,
            internalNarrative,
            externalNarrative,
            paymentRunDate,
            showOnPortal,
            versionNumber,
            paymentRunDistributions,
            showStatusChangeWarning,
            availableOnPortalFrom,
            availableOnPortalTo
        } = paymentRun;

        const state: IPaymentRunState = {
            paymentRunID,
            code,
            status,
            type,
            internalNarrative,
            externalNarrative,
            paymentRunDate,
            showOnPortal,
            versionNumber,
            showStatusChangeWarning: showStatusChangeWarning,
            paymentRunDistributions: PaymentRunDetailsMapper.mapPaymentRunDistributionsToState(paymentRunDistributions),
            availableOnPortalFrom,
            availableOnPortalTo
        };

        return state;

    }

    public static mapPaymentRunStateToPaymentRun(state: IPaymentRunState): IPaymentRun {
        if (state) {
            const {
                paymentRunID,
                code,
                status,
                type,
                internalNarrative,
                externalNarrative,
                paymentRunDate,
                showOnPortal,
                versionNumber,
                showStatusChangeWarning,
                paymentRunDistributions,
                availableOnPortalFrom,
                availableOnPortalTo
            } = state;

            const paymentRun: IPaymentRun = {
                paymentRunID,
                code,
                status,
                type,
                internalNarrative,
                externalNarrative,
                paymentRunDate,
                showOnPortal,
                versionNumber,
                showStatusChangeWarning,
                paymentRunDistributions: PaymentRunDetailsMapper.mapPaymentRunDistributionsStateToPaymentRunDistribution(paymentRunDistributions),
                availableOnPortalFrom,
                availableOnPortalTo
            };

            return paymentRun;
        }
        else {
            return null;
        }
    }

    public static mapPaymentRunDistributionsToState(paymentRunDistributions: IPaymentRunDistribution[]) : IPaymentRunDistributionState[] {
        if (!!!paymentRunDistributions) return null;

        let paymentRunDistributionsState: IPaymentRunDistributionState[] = [];
        paymentRunDistributions.forEach(prd => {
            let paymentRunDistributionState: IPaymentRunDistributionState = {
                paymentRunDistributionID: prd.paymentRunDistributionID,
                distributionID: prd.distributionID,
                paymentRunID: prd.paymentRunID,
                distributionCode: { inputType: DROPDOWN_INPUT, value: prd.distributionCode},
                status: {inputType: READ_ONLY_INPUT, value: prd.status},
                cat1: {inputType: READ_ONLY_INPUT, value: prd.cat1},
                cat2: {inputType: READ_ONLY_INPUT, value: prd.cat2},
                description: { inputType: READ_ONLY_INPUT, value: prd.description },
                year: { inputType: READ_ONLY_INPUT, value: prd.year}
            }
            paymentRunDistributionsState.push(paymentRunDistributionState);
        });
        return paymentRunDistributionsState;
    }

    public static mapPaymentRunDistributionsStateToPaymentRunDistribution(paymentRunDistributionsState: IPaymentRunDistributionState[]): IPaymentRunDistribution[] {
        if (!!!paymentRunDistributionsState) return null

        let paymentRunDistributions: IPaymentRunDistribution[] = [];
        paymentRunDistributionsState.forEach(prd => {
            let paymentRunDistribution: IPaymentRunDistribution = {
                paymentRunDistributionID: prd.paymentRunDistributionID,
                distributionID: prd.distributionID ? prd.distributionID : 0,
                paymentRunID: prd.paymentRunID ? prd.paymentRunID : 0,
                distributionCode: prd.distributionCode.value,
                status : prd.status.value,
                cat1: prd.cat1.value,
                cat2: prd.cat2.value,
                description: prd.description.value,
                year: prd.year.value
            }
            paymentRunDistributions.push(paymentRunDistribution);
        });
        return paymentRunDistributions;
    }
    

    public static mapSelectionCriteriaToSelectionCriteriaState(distributionPoolId: number, selectionCriteriaString: string): ISelectionCriteria {
        if (!!!selectionCriteriaString) return null;
        let selectionCriteria: ISelectionCriteria = JSON.parse(selectionCriteriaString) as ISelectionCriteria;
        selectionCriteria.distributionPoolId = distributionPoolId;
        return selectionCriteria;
    }

    public static mapSelectionCriteriaStateToSelectionCriteria(selectionCriteria: ISelectionCriteria): string {
        const selectionCriteriaString = JSON.stringify(selectionCriteria);
        return selectionCriteriaString;
    }

    public static createNewPaymentRun(lookups: ILookupDictionary): IPaymentRun {
        const paymentRun: IPaymentRun = {
            paymentRunID: 0,
            code: null,
            status: 'Open',
            type: 'Main',
            internalNarrative: null,
            externalNarrative: null,
            paymentRunDate: null,
            showOnPortal: true,
            versionNumber: 0,
            paymentRunDistributions: null,
            availableOnPortalFrom: null,
            availableOnPortalTo: null
        }

        return paymentRun;
    }

    public static createNewPaymentRunState(lookups: ILookupDictionary): IPaymentRunState {
        return PaymentRunDetailsMapper.mapPaymentRunToState(PaymentRunDetailsMapper.createNewPaymentRun(lookups), lookups);
    }

}