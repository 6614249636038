import * as React from "react";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import { EXTRACT_MATCHED_USAGE_WORKS_JOB_TYPE, OUTPUT_TYPE_TITLE, HIGH_WATER_MARK_KEY, HIGH_WATER_MARK_TITLE } from "../../Consts";
import { getOpenDistributionsThunk, getAllDistributionsThunk, getPoolsThunk } from "../../../redux/thunks/HomeThunks";
import { IUsagePoolState } from "../../../redux/types/IUsagePoolState";
import { IDistributionState } from "../../../redux/types/IDistributionState";
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import TextDataInput from "../../../repertoire/components/textDataInput/TextDataInput";
import { If } from "../../../core/components/If";

export interface IExtractMatchedUsageWorksJobParametersProps {
    setManualJobParameters: (parameters: ITriggerManualJob) => void;
    getOpenDistributions: typeof getOpenDistributionsThunk;
    getAllDistributions: typeof getAllDistributionsThunk;
    getPools: typeof getPoolsThunk;
    openDistributions?: IDistributionState[];
    allDistributions?:IDistributionState[];
    pools?: IUsagePoolState[];
    highWaterMark?: string;
    isUseAllDistributionEnabled: boolean;
    handleIsUseAllDistributionCheckbox: () => void
}

interface IExtractMatchedUsageWorksJobParametersState {
    selectedOpenDistributionID: string;
    selectedPools: any[];
    selectedFileFormat: string;
}

export class ExtractMatchedUsageWorksJobParameters extends React.Component<
    IExtractMatchedUsageWorksJobParametersProps,
    IExtractMatchedUsageWorksJobParametersState
> {
    constructor(props: IExtractMatchedUsageWorksJobParametersProps) {
        super(props);

        this.state = {
            selectedOpenDistributionID: '',
            selectedPools: [],
            selectedFileFormat: 'csv',
        };
    }

    componentDidMount() {
        const {
            getPools,
            pools,
            getOpenDistributions,
            getAllDistributions,
            openDistributions,
            allDistributions
        } = this.props;

        if (!pools || pools.length === 0) getPools();
        if (!openDistributions || openDistributions.length === 0) getOpenDistributions();
        if (!allDistributions || allDistributions.length === 0) getAllDistributions();
    }

    setParams = () => {
        const { setManualJobParameters, highWaterMark} = this.props;
        const { selectedOpenDistributionID, selectedPools, selectedFileFormat} = this.state;

        const filteredPools = selectedPools.filter(sp => sp !== 0);
        const manualJobParameters: ITriggerManualJob = {
            jobType: EXTRACT_MATCHED_USAGE_WORKS_JOB_TYPE,
            jobParameters: JSON.stringify({ selectedOpenDistributionID, selectedPools: filteredPools, fileFormat: selectedFileFormat, hwm: highWaterMark }),
        };
        setManualJobParameters(manualJobParameters);
    }

    render() {
        const { openDistributions, allDistributions, pools, highWaterMark, isUseAllDistributionEnabled, handleIsUseAllDistributionCheckbox } = this.props;
        const { selectedPools, selectedOpenDistributionID, selectedFileFormat} = this.state;

        let filteredPools = [];
        let filteredDistributions = [];

        let newDistribution = isUseAllDistributionEnabled ?  allDistributions : openDistributions;

        filteredDistributions = newDistribution.filter(d => d.distributionPools.length > 0)

        if (selectedOpenDistributionID && pools) {
            const selectedDistribution = filteredDistributions.filter(d => d.distributionID === Number.parseInt(selectedOpenDistributionID))[0];
            filteredPools = pools.filter(p => selectedDistribution.distributionPools.map(dp => dp.poolID).indexOf(p.poolID) > -1);
        }
        
        let distributionsOptions = [];
        let poolsOptions = [];
        let fileFormatOptions = [];

        fileFormatOptions.push(<option value="csv" title="CSV" key="csv" defaultValue={"CSV"}>CSV</option>);
        fileFormatOptions.push(<option value="json" title="JSON" key="json">JSON</option>);
        fileFormatOptions.push(<option value="native json" title="NATIVE JSON" key="native json">NATIVE JSON</option>);

        distributionsOptions.push(<option value="" title="emtpy" key=""></option>);

        if (filteredDistributions && filteredDistributions.length > 0) {           
            filteredDistributions.map((val) => (
                distributionsOptions.push(<option value={val.distributionID} title={val.distributionCode} key={val.distributionID}> {val.distributionCode + ' - ' + val.description}</option>)
            ));
        }

        if (filteredPools && filteredPools.length > 0) {
            let poolsOptionsTemp = filteredPools.map(val => ({
                key: val.poolID,
                text: val.poolCode,
                data: val.poolCode
            }));
            
            poolsOptions = poolsOptions.concat(poolsOptionsTemp);
            poolsOptions.sort((a, b) => a.text.localeCompare(b.text));
            poolsOptions.unshift({ key: 0, text: "All", data: "All" });
        }     

        const multiSelectChangeData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
            const { selectedPools } = this.state;

            let selectPoolsState = selectedPools;
            if (option.text === "All" && option.selected) {
                poolsOptions.forEach(function (value) {
                    selectPoolsState.push(value.key);
                });
            }
            else if (option.text === "All" && !option.selected) {
                selectPoolsState = [];
            }
            else if (option.text !== "All" && !selectPoolsState.includes(option.key)) {
                selectPoolsState.push(option.key);
            }
            else {
                const index = selectPoolsState.indexOf(option.key);

                if (index > -1) {
                    selectPoolsState.splice(index, 1);
                }

                if (selectedPools.includes(0)) {
                    selectPoolsState.splice(0, 1);
                }
            }

            this.setState({ selectedPools: selectPoolsState }, this.setParams);
        }

        const onRenderPeriodDropdownTitle = (
            options: IDropdownOption[] | undefined
        ): JSX.Element => {
            const displayValue = options.filter(o => o.text !== 'All').map(o => o.text).join(", ");
            return (
                <div>
                    <span>{displayValue}</span>
                </div>
            );
        };

        return (
            <>
                <div className="ms-Dropdown-container form-group col-md-6 col-sm-6 col-xs-12">
                    <label className="ms-Label ms-Dropdown-label">Distributions</label>
                        <select
                            required
                            onChange={(e) => {
                                this.setState({ selectedOpenDistributionID: e.target.value }, () => {
                                this.setState({ selectedPools: [] }, this.setParams);
                                });
                            }}
                            >
                            {distributionsOptions}
                        </select>
                    </div>
           
                 <div className="form-group closed-distribution-checkbox-container col-md-3 col-sm-6 col-xs-12">
                    <div className="checkboxContainer">
                        <label className="subContainer">
                            <input type="checkbox" id="customRange" 
                                checked={isUseAllDistributionEnabled}
                                onChange={handleIsUseAllDistributionCheckbox} />
                            <span className="inputCheckbox"></span>
                            <div className="checkboxLabel"> Include Closed Distribution </div>
                        </label>
                    </div>
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <Dropdown
                        label={"Pools"}
                        selectedKeys={selectedPools}
                        options={poolsOptions}
                        multiSelect={true}
                        onChange={multiSelectChangeData}
                        placeholder={""}
                        disabled={false}
                        required={true}
                        onRenderTitle={onRenderPeriodDropdownTitle}
                    />
                </div>

                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>{OUTPUT_TYPE_TITLE}</label>
                    <select required
                        onChange={(e) => {
                            this.setState({ selectedFileFormat: e.target.value }, () => {
                                this.setParams();
                            });
                        }}
                    >
                        {fileFormatOptions}
                    </select>
                </div>

                <If condition={selectedFileFormat === 'json' || selectedFileFormat =='native json'}>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <TextDataInput
                            label={HIGH_WATER_MARK_TITLE}
                            fieldName={HIGH_WATER_MARK_KEY}
                            changeData={null}
                            value={highWaterMark}
                            readOnly={true}
                            isHidden={false}
                        />
                    </div>
                </If>
            </>
        );
    }
}
