import axios from "axios";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IJobSearchResult } from "../types/IJobSearchResult";
import { IJobSearchState } from "../types/IJobSearchState";

export class SearchRequests {

    public static searchJobs(
        searchQuery: IJobSearchState
    ): Promise<IJobSearchResult[]> {
        return new Promise<IJobSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/jobs", searchQuery)
                .then(res => {
                    resolve(res.data);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getConfigurationParameter(key: string, group: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Component/ConfigurationParameter/" + key + "/" + group)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getContributors(
        searchQuery: IContributorSearchQuery
    ): Promise<IContributorSearchResult[]> {
        const {
            ipNumber,
            fullName,
            accountNumber,
            legalEntityType
        } = searchQuery;
        return new Promise<IContributorSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/contributors", {
                    ipiNumber: {
                        value: ipNumber && !isNaN(Number(ipNumber)) ? ipNumber : null,
                        type: 0
                    },
                    ipBaseNumber: {
                        value: ipNumber && !isNaN(Number(ipNumber)) ? "" : ipNumber,
                        type: 0
                    },
                    fullName: {
                        value: fullName,
                        type: 0
                    },
                    accountNumber: {
                        value: accountNumber,
                        type: 1
                    },
                    legalEntityType: {
                        value: legalEntityType,
                        type: 0
                    }
                })
                .then(response => {
                    const contributorSearchResultItems = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const contributorSearchResultItem = {
                            accountNumber: response.data[x].accountNumber,
                            type: response.data[x].type,
                            ipiNumber: response.data[x].ipiNumber,
                            ipName: response.data[x].fullName,
                            ipBaseNumber: response.data[x].ipBaseNumber,
                            societyAffiliation: response.data[x].societyAffiliation,
                            legalEntityType: response.data[x].legalEntityType,
                            societyOfLicense: response.data[x].defaultSocietyOfLicense

                        };

                        contributorSearchResultItems.push(contributorSearchResultItem);
                    }

                    resolve(contributorSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getOpenDistributions(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Distribution/DistributionsByStatus/1")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getAllDistributions(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Distribution/Distributions")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getPools(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/UsagePool/Pools")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    public static getDistributionsByCDType(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Distribution/DistributionsByCDType")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}

