import * as React from "react";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { setEditableFields } from "../../../../redux/reducers/MembersPortalReducer";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { SizedTextDataInput } from "../../../../repertoire/components/textDataInput/TextDataInput";
import { ADD_COVER_VERSION_ACTION, SEARCH_ACTION, DRAFT_SET_LISTS_ORIGINAL_TITLE_STATE_KEY, DRAFT_SET_LISTS_STATE_KEY, DRAFT_SET_LISTS_WORK_STATE_KEY, DRAFT_WORKS_DURATION_STATE_KEY, DRAFT_WORKS_GENRE_STATE_KEY, DRAFT_WORKS_INSTRUMENTATION_STATE_KEY, DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY, DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY, DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY, DRAFT_WORKS_LANGUAGE_STATE_KEY, DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY, DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY, DRAFT_WORKS_TITLES_STATE_KEY, DRAFT_WORKS_WORK_PROPERTIES, REGISTER_SET_LISTS_ORIGINAL_TITLE_STATE_KEY, REGISTER_SET_LISTS_STATE_KEY, DRAFT_SET_LISTS_DATE_CREATED, DRAFT_SET_LISTS_STATUS, DRAFT_SET_LISTS_ID, SET_LISTS_WORK_SEARCHVIEW_STATE_KEY } from "../../../Consts";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import DataGridTable from "../../../../repertoire/components/dataGridTable/DataGridTable";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ISetListsStateKeys } from "../../../types/ISetListsState";
import { IProductState } from "../../../../redux/types/IProductState";
import { MEMBERS_PORTAL } from "../../../../accounts/Consts";
import { PRODUCT_STATUS_LOOKUP } from "../../../../lookup/Consts";

export interface IDraftSetListsMaintenanceGeneralDataViewProps {
    dataGridTableData?: IRepertoireComponentDataItem;
    draftSetListsMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    draftSetListsMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    setList: IProductState;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: any[];
    isNewDraftSetList?: boolean;
    updateDraftSetListsField: (value: any, fieldName: ISetListsStateKeys, index?: number) => void;
    resetMessageBanner: () => void;
    changesMade: boolean;
    updateComponentFieldsItems?: (fields: IMembersPortalField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IMembersPortalComponentDataItem;
    dataGridTableEditableFieldsData?: IMembersPortalComponentDataItem;
    setEditableFields?: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    incrementTempID?: () => void;
    activeHeaderSection?: string;
    isMandatory?: boolean;
    clearModalSearchResults?: () => void;
}

interface IDraftSetListsMaintenanceGeneralDataViewState {
    loaded: boolean;
    activeHeaderSection?: string;
}

export default class DraftSetListsMaintenanceGeneralDataView extends React.PureComponent<
    IDraftSetListsMaintenanceGeneralDataViewProps,
    IDraftSetListsMaintenanceGeneralDataViewState> {
    viewData;
    constructor(props: IDraftSetListsMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            activeHeaderSection: props.activeHeaderSection ?? "draftWorkWorkProperties",
            loaded: true
        };
        this.viewData = {};
    }


    componentDidMount() {
        const {
            draftSetListsMaintenanceGeneralDataViewData: { fields }
        } = this.props;

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);

            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = (header: IMembersPortalField) => {

        return (
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={header.name}
                data-testid={header.name + "-section"}
            >
                {header.data}
                {header.data === 'General' ? <h5>The <span className="important-label">*</span>indicates a mandatory field.</h5> : <h5><span className="important-label">*</span>At least one Work should be added into your set list.</h5>}
            </div>
        );
    };


    showFieldsModal = () => {

    }

    render() {
        const {
            isReadonly,
            lookupValues,
            updateDraftSetListsField,
            incrementTempID,
            dataGridTableData,
            draftSetListsMaintenanceGridsViewData,
            tabs,
            activeTab,
            isMandatory,
            setList,
            clearModalSearchResults,
            showModal,
            hideModal
        } = this.props;

        const {
            productWork,
            originalTitle

        } = this.props.setList;
        const { loaded, activeHeaderSection } = this.state;

        const changeData = (value: any, fieldName: string) => {
            updateDraftSetListsField(value, fieldName as ISetListsStateKeys);
        }

        const getSetlistStatus = (instance: string) => {
            if (instance)
                return this.props.lookupValues[PRODUCT_STATUS_LOOKUP].lookups.filter(l => l.code === instance)[0].description;
            else
                return 'Draft';
        }


        if (loaded) {
            const isNew: boolean = setList.id1 === null || setList.id1 === '0';
            const addCoverVersion: boolean = !['REGISTERED', 'Registered'].includes(setList.status);
            return (
                <>
                    <div className="topMargin">
                        <div className="row">
                            <div className="headerContainer">
                                {this.renderGridViewHeaderSections(draftSetListsMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'membersPortalGeneralTitle'))}
                            </div>
                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={"Title"}
                                fieldName={DRAFT_SET_LISTS_ORIGINAL_TITLE_STATE_KEY}
                                changeData={changeData}
                                readOnly={false}
                                isHidden={false}
                                value={originalTitle}
                                isMandatory={true}
                            />}
                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={"ID"}
                                fieldName={DRAFT_SET_LISTS_ID}
                                changeData={changeData}
                                readOnly={true}
                                isHidden={isNew}
                                value={setList.id1}
                                isMandatory={false}
                            />}
                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={"Status"}
                                fieldName={DRAFT_SET_LISTS_STATUS}
                                changeData={changeData}
                                readOnly={true}
                                isHidden={isNew}
                                value={getSetlistStatus(setList.status)}
                                isMandatory={false}
                            />}
                        </div>
                        <div className="row">
                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={"Date Created"}
                                fieldName={DRAFT_SET_LISTS_DATE_CREATED}
                                changeData={changeData}
                                readOnly={true}
                                isHidden={isNew}
                                value={setList.dateCreated}
                                isMandatory={false}
                            />}
                        </div>
                    </div>

                    {<div className="row">

                        <div className="headerContainer">
                            {this.renderGridViewHeaderSections(draftSetListsMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'membersPortalWorkTitle'))}
                        </div>

                        <DataGridTable
                            tableContents={setList.productWork}
                            changeData={changeData}
                            dataGridTableData={dataGridTableData}
                            stateKey={DRAFT_SET_LISTS_WORK_STATE_KEY}
                            componentInstance={SET_LISTS_WORK_SEARCHVIEW_STATE_KEY}
                            showRowNumber={false}
                            isReadOnly={false}
                            tableActions={addCoverVersion ? [{ name: SEARCH_ACTION }, { name: ADD_COVER_VERSION_ACTION }] : [{ name: SEARCH_ACTION }]}
                            lookupValues={lookupValues}
                            incrementTempID={incrementTempID}
                            tabs={tabs}
                            activeTab={activeTab}
                            showDropdownsAsCodes={false}
                            clearModalSearchResults={clearModalSearchResults}
                            portalType={MEMBERS_PORTAL}
                            showModal={showModal}
                            hideModal={hideModal}
                        />

                    </div>}
                </>
            );
        }
        return <div />;
    }
}