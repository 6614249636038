import * as React from "react";
import {
    SAVE_METHOD_TOOLBAR,
    SAVE_CHANGES_STATE_KEY,
    ADD_METHOD_TOOLBAR,
    ADD_NEW_DRAFT_WORK_STATE_KEY,
    UNDO_METHOD_TOOLBAR,
    COPY_METHOD_TOOLBAR,
    COPY_DRAFT_WORK_STATE_KEY,
    UNDO_CHANGES_DRAFT_WORK_STATE_KEY,
    DRAFT_WORKS_MAINTENANCE_TOOLBAR, 
    ADD_NEW_WORK_STATE_KEY,
    COPY_WORK_STATE_KEY,
    LIVE_PERFORMANCE_MAINTENANCE_TOOLBAR,
    UNDO_CHANGES_DRAFT_LIVE_PERFORMANCE_STATE_KEY,
    ADD_NEW_DRAFT_LIVE_PERFORMANCE_STATE_KEY
} from "../../../Consts";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { clearModalSearchResults} from "../../../../redux/reducers/MembersPortalReducer";
import { showYesNoPrompt, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { IUsageGroup } from "../../../../repertoire/types/usageTypes/IUsageGroup";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";

export interface ILivePerformanceMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IMembersPortalComponentDataItem;
    saveResultData?: IMembersPortalComponentDataItem;
    usage: IUsageGroupState;
    dataSource: DataSource;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    isReadonly: boolean;
    undoDraftWorksChanges: () => void;
    addNewDraftWorks: () => void;
    isNew?: boolean;
    currentUser?: string;
    lookupValues: ILookupDictionary;
    showModal: typeof showModal;
    copyExistingDraftLivePerformance:  (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => void;
}

export interface ILivePerformanceToolbarState {
    dataAction: IDataActionToolbar[];
}

export type LivePerformanceMaintenanceToolbarKeys = keyof ILivePerformanceMaintenanceToolbarProps;

class LivePerformanceMaintenanceToolbar extends React.Component<
ILivePerformanceMaintenanceToolbarProps,
ILivePerformanceToolbarState
> {
    actionData;

    constructor(props) {
        super(props);
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    saveChangesAction = () => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    copyDraftWork = () => {
        
        const { tabs, activeTab, copyExistingDraftLivePerformance,currentUser, lookupValues } = this.props;  
        const actionList = tabs[activeTab].dataActionToolbar;        
        copyExistingDraftLivePerformance(actionList,currentUser,lookupValues);
    }
    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }
    
    getDataAction() {
        const {
            toolbarData: { fields },
            undoDraftWorksChanges,
            addNewDraftWorks,
            isNew
        } = this.props;
        const actionList: IDataActionToolbar[] = [];

         

        const save: IDataActionToolbar = {
            action: this.saveChangesAction.bind(this),
            component: LIVE_PERFORMANCE_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: ''
        };
        actionList.push(save);


        const undo: IDataActionToolbar = {
             action: undoDraftWorksChanges,
             component: LIVE_PERFORMANCE_MAINTENANCE_TOOLBAR,
             method: UNDO_METHOD_TOOLBAR,
             name: fields.find(undo => undo.name === UNDO_CHANGES_DRAFT_LIVE_PERFORMANCE_STATE_KEY).data,
             icon: 'icon ms-Icon ms-Icon--Undo'
        };
        actionList.push(undo);

        const add: IDataActionToolbar = {
            action: addNewDraftWorks,
            component: LIVE_PERFORMANCE_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(p => p.name === ADD_NEW_DRAFT_LIVE_PERFORMANCE_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition'
        }
        actionList.push(add);

        

        return actionList;
    }

    render() {
        const {
            changesMade,
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            activeTab,
            tabs,
            toolbarData,
            usage,
            lookupValues,
            isNew
        } = this.props;

        const isReadonly: boolean = tabs[activeTab].isReadonly;
        let { dataAction } = { ...this.state };
        return (
            <div className={isReadonly && !changesMade ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={this.resetMessageBanner.bind(this)}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction}
                    toolbarData={toolbarData}
                    component={LIVE_PERFORMANCE_MAINTENANCE_TOOLBAR}
                    isNew={isNew}
                    isReadonly={isReadonly}
                    usage={usage}
                    lookupValues={lookupValues}
                />
            </div>
        );
    }
}

export default LivePerformanceMaintenanceToolbar;
