import * as React from "react";
import {
    ADD_METHOD_TOOLBAR,
    UNDO_METHOD_TOOLBAR,
    COPY_METHOD_TOOLBAR,
    COPY_DRAFT_WORK_STATE_KEY,
    UNDO_CHANGES_DRAFT_WORK_STATE_KEY,
    DRAFT_WORKS_MAINTENANCE_TOOLBAR, 
    DRAFT_AGREEMENTS_MAINTENANCE_TOOLBAR,
    ADD_NEW_DRAFT_AGREEMENT_STATE_KEY,
    SAVE_METHOD_TOOLBAR,
    SAVE_CHANGES_STATE_KEY
} from "../../../Consts";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { showYesNoPrompt, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { IAgreementState } from "../../../../redux/types/IAgreementState";

export interface IDraftAgreementsMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IMembersPortalComponentDataItem;
    saveResultData?: IMembersPortalComponentDataItem;
    agreement: IAgreementState;
    dataSource: DataSource;
    activeTab: number;
    tabs: ITabReduxItem[];
    isReadonly: boolean;
    undoDraftWorksChanges: () => void;
    addNewDraftWorks?: () => void;
    addNewAgreement?:()=>void;
    isNew?: boolean;
    currentUser?: string;
    lookupValues: ILookupDictionary;
    showModal: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    ) =>void;
    copyDraftWork?:  (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => void;
}

export interface IDraftAgreementsMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

export type DraftAgreementsMaintenanceToolbarKeys = keyof IDraftAgreementsMaintenanceToolbarProps;

class DraftAgreementsMaintenanceToolbar extends React.Component<
    IDraftAgreementsMaintenanceToolbarProps,
    IDraftAgreementsMaintenanceToolbarState
> {

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    render() {
        const {
            changesMade,
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            activeTab,
            tabs,
            toolbarData,
            agreement,
            lookupValues,
        } = this.props;

        const isReadonly: boolean = tabs[activeTab].isReadonly;
        return (
            <div className={isReadonly && !changesMade ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={this.resetMessageBanner.bind(this)}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={[]}
                    toolbarData={toolbarData}
                    component={DRAFT_AGREEMENTS_MAINTENANCE_TOOLBAR}
                    isReadonly={isReadonly}
                    agreement={agreement}
                    lookupValues={lookupValues}
                />
            </div>
        );
    }
}

export default DraftAgreementsMaintenanceToolbar;
