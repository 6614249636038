
import { ComboBox, IComboBoxOption } from "@fluentui/react";
import { JOB_TYPE_LOOKUP } from "../../../lookup/Consts";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { MANUAL_USAGE_MATCHING_JOB_TYPE } from "../../Consts";
import { JobParameterControlFactory } from "../../helpers/JobParameterControlFactory";
import { IGenericJobTypeLookupInstance } from "../../types/IGenericJobTypeLookupInstance";
import { IJobParameterControlBaseDistribution } from "../../types/JobParameterControls/IJobParameterControlBaseDistribution";
import TextDataInput from "../../../repertoire/components/textDataInput/TextDataInput";
import React from "react";
import ActionButton from "../../../repertoire/components/actionButton/ActionButton";

const DISTRIBUTION = "USGM_Distribution";
const POOL = "USGM_Pool";
const FILENAME = "USGM_Filename";
const MATCH_TYPE = "USGM_MatchType";
const SCHEDULE = "USGM_Schedule"

const USGMFormFieldsView = ({
    triggerManualJob,
    createNewJob,
    formControls,
    lookups,
    manualJobDataSources,
    getUsageFilenames,
    getScheduledJob,
    upsertScheduledJobAsync,
    scheduledJob,
    getAllUsageFilenamesFromDistribution,
    usageFileNames
}) => {
    const [jobControls, setJobControls] = React.useState([]);
    const [distributionValue, setDistributionValue] = React.useState('');
    const [poolValue, setPoolValue] = React.useState('');
    const [filenameValue, setFileNameValue] = React.useState('');
    const [matchTypeValue, setMatchTypeValue] = React.useState('');
    const [scheduledValue, setScheduledValue] = React.useState('');

    React.useEffect(() => {
        const jobTypes = getLookupValues(JOB_TYPE_LOOKUP, lookups) as IGenericJobTypeLookupInstance;

        if (jobTypes.extraFields.GenericParametersJson && (jobTypes.extraFields.GenericParametersJson != '')) {
            let genericJobBaseParameters = JSON.parse(jobTypes.extraFields.GenericParametersJson) as { controlList: IJobParameterControlBaseDistribution[] }
            let jobControls = JobParameterControlFactory.InstantiateControlList(genericJobBaseParameters.controlList, manualJobDataSources) as IJobParameterControlBaseDistribution[];
            setJobControls(jobControls);
            jobControls.forEach(formItem => {
                if (formItem.ControlID === DISTRIBUTION) {
                    setDistributionValue(formItem.CurrentValue as string)
                }
                if (formItem.ControlID === POOL) {
                    setPoolValue(formItem.CurrentValue as string)
                }
                if (formItem.ControlID === MATCH_TYPE) {
                    setMatchTypeValue(formItem.CurrentValue as string)
                }
                if (formItem.ControlID === FILENAME) {
                    setFileNameValue(formItem.CurrentValue as string)
                }
            })
        }


    }, []);

    React.useEffect(() => {
        if((poolValue && distributionValue) && (poolValue!=='' && distributionValue!=='') ){
            if(poolValue == 'AllPools'){
                getAllUsageFilenamesFromDistribution(distributionValue)
            }
            else{
                getUsageFilenames(poolValue,distributionValue)
            }
        }

    }, [distributionValue,poolValue]);
    
    React.useEffect(() => {
        if (poolValue && distributionValue && matchTypeValue){
            getScheduledJob(MANUAL_USAGE_MATCHING_JOB_TYPE, JSON.stringify({controlList: formatparams()}))
        }
    }, [poolValue, distributionValue, matchTypeValue, filenameValue]);

    React.useEffect(() => {
        if (scheduledJob){
            setScheduledValue(scheduledJob.cronSchedule)
        }
    }, [scheduledJob]);

    const handleDistributionChange = (value: IComboBoxOption) => {
        setDistributionValue(value.key as string);
        setFileNameValue('');
        setPoolValue('');
        setScheduledValue('')
    }

    const handlePoolChange = (value: IComboBoxOption) => {
        setPoolValue(value.key as string)
        setScheduledValue('')
        setFileNameValue('')
    }

    const handleFilenameChange = (value: IComboBoxOption) => {
        setFileNameValue(value.key === "clearFile" ? '' : value.key as string);
        setScheduledValue('')
    }

    const handleMatchTypeChange = (value: IComboBoxOption) => {
        setMatchTypeValue(value.key as string)
        setScheduledValue('')
    }

    const handleScheduleChange = (value : string | number | boolean, fieldname: string) => {
        setScheduledValue(value as string)
    }

    const getLookupValues = (lookup: string, lookupValues: ILookupDictionary) => {
        let result = lookupValues[lookup] ? lookupValues[lookup].lookups : undefined;
        return result.sort((a, b) => a.code.localeCompare(b.code)).filter(e => e.code == MANUAL_USAGE_MATCHING_JOB_TYPE)[0];;
    }

    const getOptions = (formItem: any) => {
        
        let options=[];
        
        if(typeof formItem.Options == "string"){          
            if(formItem.Options==='pools' && distributionValue!==''){
                PushDistributionRestrictedPools(formItem, options)
            }
            else{
                options = formItem.DataSourceRepo[formItem.Options].Read();
            }
        }else{
            options = formItem.Options;
        }
        
        options.sort((a, b) => a.description.localeCompare(b.description));

        return options.map((v, i, a) => ({
            key: v.code,
            text: v.description,
        })).filter(x => !formItem.FilteredValues || formItem.FilteredValues.includes(x.key))  
    }

    const PushDistributionRestrictedPools = (formItem: any, options: any) => {
        let poolOptions: { code: string; description: string; pools?:[] }[];
        let distributionOptions: { code: string; description: string; pools?: [] }[];
        distributionOptions = formItem.DataSourceRepo['openDistributions'].Read(); 
        let poolsArray=[];
        distributionOptions.map(element=>{
            if(element.code===distributionValue){
                poolsArray.push(element.pools);
            }
        })
        poolOptions = formItem.DataSourceRepo['pools'].Read();  
        poolOptions.map(element=>{
             poolsArray.map(ele=>{
                if(ele.includes(element.code)){
                    options.push(element);
                }
            })
        })
        
        if(options.length > 0)
        {
            options.push(poolOptions.find(e => e.code == 'AllPools'))
        }
    }

    const formatparams = () => {
        return jobControls.map(control => { 
            if (control.ControlID === DISTRIBUTION) {
                return {ControlID: control.ControlID, CurrentValue: distributionValue}
            }
            if (control.ControlID === POOL) {
                return {ControlID: control.ControlID, CurrentValue: poolValue}
            }
            if (control.ControlID === MATCH_TYPE) {
                return  {ControlID: control.ControlID, CurrentValue: matchTypeValue}
            }
            if (control.ControlID === FILENAME) {
                return {ControlID: control.ControlID, CurrentValue: filenameValue}
            }
        })
    }

    const setParamsAndTriggerManualJob = () => {
        let params = JSON.stringify({controlList: formatparams()});
        return triggerManualJob({ jobType: MANUAL_USAGE_MATCHING_JOB_TYPE, jobParameters: params });
    }

    const getFormComponents = (jobControls) => {
            return jobControls.map(formItem => {

                if (formItem.Type === 'combobox' && formItem.ControlID === DISTRIBUTION) {
                    return (
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="dropdownDataInput">
                        <ComboBox
                                selectedKey={distributionValue}
                                label={formItem.Caption}
                                options={getOptions(formItem)}
                                onChange={(e, selectedOption, i, value) => {
                                    handleDistributionChange(selectedOption);
                                }}
                                useComboBoxAsMenuWidth
                                calloutProps={{ calloutMaxHeight: 270 }}
                        />
                        </div>
                        </div>
                    );
                }

                if (formItem.Type === 'combobox' && formItem.ControlID === POOL) {
            
                    return (
                        <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="dropdownDataInput">
                        <ComboBox
                                selectedKey={poolValue}
                                label={formItem.Caption}
                                options={getOptions(formItem)}
                                onChange={(e, selectedOption, i, value) => {
                                    handlePoolChange(selectedOption);
                                }}
                                useComboBoxAsMenuWidth
                                calloutProps={{ calloutMaxHeight: 270 }}
                        />
                        </div>
                        </div>
                    );
                }

                if (formItem.Type === 'combobox' && formItem.ControlID === MATCH_TYPE) {

            
                    return (<div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="dropdownDataInput">
                        <ComboBox
                                selectedKey={matchTypeValue}
                                label={formItem.Caption}
                                options={getOptions(formItem)}
                                onChange={(e, selectedOption, i, value) => {
                                    handleMatchTypeChange(selectedOption);
                                }}
                                useComboBoxAsMenuWidth
                                calloutProps={{ calloutMaxHeight: 270 }}
                        />
                        </div>
                        </div>
                    );
                }

                if (formItem.Type === 'text-input' && formItem.ControlID === FILENAME) {
                                  
                    return (<div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <div className="dropdownDataInput">
                        <ComboBox
                                selectedKey={filenameValue}
                                label={formItem.Caption}
                                options={[{ key: 'clearFile', text: '' },
                                ...(usageFileNames ? usageFileNames.data.map((file) => ({ key: file, text: file })):[])]}
                                onChange={(e, selectedOption, i, value) => {
                                    handleFilenameChange(selectedOption);
                                }}
                                useComboBoxAsMenuWidth
                                calloutProps={{ calloutMaxHeight: 270 }}
                                placeholder={"No File Selected."}
                        />
                        </div>
                        </div>
                    );
                }
            })
    }

    const setSchedule = () => {
        let schedule = {
            cronSchedule: scheduledValue,
            errorList: [],
            lastRunDate: null,
            parameters: JSON.stringify({controlList: formatparams()}),
            type: MANUAL_USAGE_MATCHING_JOB_TYPE
        }
        if (schedule){
            upsertScheduledJobAsync(schedule)
            createNewJob();
        }
    }
    
    const onClick = () => {
        createNewJob();
        setParamsAndTriggerManualJob();
    }
    const isValidJob = () => {
        return (distributionValue && poolValue && matchTypeValue && filenameValue) && (poolValue != "AllPools") || (poolValue &&  matchTypeValue && !filenameValue)
    }

    return <>
        <div className="row">
            {jobControls && getFormComponents(jobControls)}
        </div>
        <div className="row">
        
        <div className="form-group col-md-4 col-sm-6 col-xs-12">
        <TextDataInput
            label={"Schedule"}
            fieldName={SCHEDULE}
            changeData={handleScheduleChange}
            value={scheduledValue}
            readOnly={!(isValidJob())}
            isHidden={false}
            isMandatory={false}
        />
        </div>
            <div style={{ marginTop: 12 }} className="form-group col-md-2 col-sm-4 col-xs-12">
                <ActionButton buttonText={"Set Schedule"} buttonAction={setSchedule} disabled={!(isValidJob())}/>
            </div>
        </div>

        <div className="row">
            <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                    <ActionButton buttonText={"Create Manual Job"} buttonAction={onClick} disabled={!(isValidJob())}/>
            </div>
        </div>
    </>
}

export default USGMFormFieldsView;