import { IWorkState } from "../../redux/types/IWorkState";
import { ITitle } from "../types/ITitle";
import { INumber } from "../types/INumber";
import { IArtist } from "../types/IArtist";
import { Dictionary } from "../../core/types/Dictionary";
import { IContributorItem } from "../types/IContributorItem";
import { IValidationMessage } from "../types/IValidationMessage";
import { IWorksSearchResult } from "../types/IWorksSearchResult";
import { IContainedWork } from "../types/IContainedWork";

export class WorkValidator {

    private static validateTitles(titles: ITitle[]): boolean {
        let originalTitleCount: number;
        let emptyTitles: boolean;

        originalTitleCount = 0;
        emptyTitles = false;

        titles.forEach(w => {
            if (w.type.value === "OT") {
                originalTitleCount++;
            }
            if (!w.title.value) {
                emptyTitles = true;
            }
        })

        return (originalTitleCount === 1 && !emptyTitles);
    }

    private static validateTitleLengths(titles: ITitle[]): boolean {

        let titleTooLong: boolean;

        titleTooLong = false;

        titles.forEach(w => {
            if (w.title.value.length > 150){
                titleTooLong = true;
            }
        })

        return !titleTooLong;

    }

    private static validateNumbers(numbers: INumber[]): boolean {
        let emptyNumbers: boolean;

        emptyNumbers = false;

        numbers.forEach(w => {
            if (!w.number.value) {
                emptyNumbers = true;
            }
        })

        return !emptyNumbers;
    }
  
    private static validateContributorsShares(contributors: Dictionary<IContributorItem[]>,
        hasContainedWorks: boolean,
        workContributorTypes: string[],
        publisherContributorTypes: string[]): boolean {
        const flattenedContributors: IContributorItem[] = [];

        if (contributors) {
            for (var key in contributors) {
                flattenedContributors.push(...contributors[key]);
            }

            var publisherShareValid = true;
            var authorShareValid = true;
            var translatorShareValid = true;

            var perfMechValidation = flattenedContributors.every(c =>
                ((!c.perfPercentage.value || c.perfPercentage.value <= 100)
                && (!c.mechPercentage.value || c.mechPercentage.value <= 100)));

           
            if (flattenedContributors.every(c =>
                (c.copyLevyPercentage && c.copyLevyPercentage.value != undefined)
                && c.copyLicencePercentage && c.copyLicencePercentage.value != undefined)) {

                let translatorTypes = workContributorTypes?.filter(x => x.endsWith('T'));
                let authorTypes = workContributorTypes?.filter(x => !x.endsWith('T'));

                if (translatorTypes && authorTypes && publisherContributorTypes) {
                    let publishers = flattenedContributors.filter(i => publisherContributorTypes.includes(i.role.value))
                    let authors = flattenedContributors.filter(i => authorTypes.includes(i.role.value))
                    let translators = flattenedContributors.filter(i => translatorTypes.includes(i.role.value))

                    publisherShareValid = publishers.every(c =>
                    (c.copyLevyPercentage.value <= 100
                        && c.copyLicencePercentage.value <= 100));

                    translatorShareValid = translators.every(t =>
                    (t.copyLicencePercentage.value <= 100
                        && t.copyLevyPercentage.value <= 100));

                    authorShareValid = authors.every(a =>
                    (a.copyLevyPercentage.value <= 100
                        && a.copyLicencePercentage.value <= 100));   
                }                        
            }                     

            if (!perfMechValidation || !publisherShareValid || !authorShareValid || !translatorShareValid) {
                return false;
            }
            else {
                return true;
            }
        }
        else
            return !hasContainedWorks;
    }

    private static validateArtists(artists: IArtist[]): boolean {
        let emptyArtists: boolean;
        let hasType: boolean;

        emptyArtists = false;
        hasType = true;

        artists.forEach(w => {
            if (!w.type) {
                hasType = false;
            }
            if (!w.firstName && !w.lastName) {
                emptyArtists = true;
            }
        })

        return hasType && !emptyArtists;
    }

    private static validateComponents(components: IContainedWork[],worksSearchResults:IWorksSearchResult[]): boolean {

        let inValidComponent: boolean;
        inValidComponent=false;
        components.forEach(w=>{
            if(worksSearchResults!=undefined){
            const work = worksSearchResults.filter((c)=>c.workID===w.workCode);
            if(work[0]?.compoundType!==null&&work[0]?.compoundType!==undefined&&work[0]?.compoundType!=="" ){
                inValidComponent=true;
                 
            }  }         
        })
        return inValidComponent;

    }

    public static validateWork(work: IWorkState, workContributorTypes: string[], publisherContributorTypes: string[], worksSearchResults?: IWorksSearchResult[]): IValidationMessage[] {
        let workIsValid: IValidationMessage[];
        workIsValid = [];
        const hasContainedWorks: boolean = !!work.components && work.components.length > 0;

        if (!WorkValidator.validateTitles(work.titles))
            workIsValid.push({ messageKey: "titleValidationFailedMessage"})

        if(!WorkValidator.validateTitleLengths(work.titles))
            workIsValid.push({ messageKey: "titleTooLong"})

        if (!WorkValidator.validateNumbers(work.numbers))
            workIsValid.push({ messageKey: "numberValidationFailedMessage"})

        if (!WorkValidator.validateContributorsShares(work.contributors, hasContainedWorks, workContributorTypes, publisherContributorTypes))
            workIsValid.push({ messageKey: "workContributorShareTooHigh" })

        if (!WorkValidator.validateArtists(work.artists))
            workIsValid.push({ messageKey: "artistValidationFailedMessage"})

        if (!work.source)
            workIsValid.push({ messageKey: "sourceValidationFailedMessage"})
        if(worksSearchResults!=undefined){
            if(WorkValidator.validateComponents(work.components,worksSearchResults)){
                workIsValid.push({ messageKey: "componentsValidationFailedMessage"})
            }            
        }
        

        return workIsValid;
    }
}