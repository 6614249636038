import * as React from "react";
import IRepertoireComponentDataItem from "../../../../../redux/types/IRepertoireComponentDataItem";
import {
    hideModal,
    showModal
} from "../../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../../redux/types/ITabReduxItem";
import { SizedTextDataInput } from "../../../../components/textDataInput/TextDataInput";
import { IRepertoireStateKeys } from "../../../../types/IRepertoireStateKeys";
import { addNewRepresentation, getRepresentationGroups } from "../../../../../redux/reducers/RepertoireReducer";
import {
    IP_IPBASENUMBER_STATE_KEY,
    IP_REPRESENTATION_PARENT_STATE_KEY,
    IP_REPRESENTATION_REPRESENTATION_GROUP_STATE_KEY,
    IP_REPRESENTATION_START_DATE_STATE_KEY,
    IP_REPRESENTATION_END_DATE_STATE_KEY,
    IP_REPRESENTATION_DESCRIPTION_STATE_KEY,
    IP_REPRESENTATION_WORK_GROUP_STATE_KEY,
    IP_REPRESENTATION_USE_STATE_KEY,
    IP_REPRESENTATION_REPRESENTATION_TYPE_STATE_KEY,
    IP_REPRESENTATION_EXCLUDED_STATE_KEY
} from "../../../../Consts";
import SizedDateDataInput from "../../../../components/dateDataInput/DateDataInput";
import { REPRESENTATION_TYPE_LOOKUP } from "../../../../../lookup/Consts";
import { getLookupValues } from "../../../../../lookup/services/LookupHelpers";
import { SizedDropdownDataInput } from "../../../../components/dropdownDataInput/DropdownDataInput";
import { ILookupDictionary } from "../../../../../lookup/types/ILookupDictionary";
import { IRepresentation } from "../../../../types/usageTypes/IRepresentation";
import { convertToDropdownDataInputProps } from "../../../Helpers/RepresentationHelpers";
import { SizedCheckboxDataInput } from "../../../../components/checkboxDataInput/CheckboxDataInput";
import { If } from "../../../../../core/components/If";
import SaveResult from "../../../../components/saveResult/SaveResult";

export interface IIPRepresentationGeneralDataViewProps {
    ipBaseNumber: any;
    activeTab: number;
    tabs: ITabReduxItem[];
    hideModal: typeof hideModal;
    showModal: typeof showModal;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    ipRepresentationGeneralDataViewData: IRepertoireComponentDataItem;
    addNewRepresentation: typeof addNewRepresentation;
    getRepresentationGroups: typeof getRepresentationGroups;
    representationGroups: { code: string, description: string }[];
    representationUses: { code: string, description: string }[];
    representationWorkGroups: { code: string, description: string }[];
    resetMessageBanner: () => void;
    ipMaintenanceSaveResultData?: IRepertoireComponentDataItem;
}

interface IIPRepresentationGeneralDataViewState {
    loaded: boolean;
    scroll: number;
    toolbarWidth: number;
    representationObject: IRepresentation;
}

export default class IPRepresentationGeneralDataView extends React.PureComponent<
    IIPRepresentationGeneralDataViewProps,
    IIPRepresentationGeneralDataViewState
> {
    viewData;
    constructor(props: IIPRepresentationGeneralDataViewProps) {
        super(props);

        const { tabs, ipBaseNumber } = this.props;

        this.state = {
            loaded: false,
            scroll: 0,
            toolbarWidth: null,
            representationObject: {
                description: "",
                ipBaseNumber: ipBaseNumber,
                parent: "General Digital License",
                parentId: 500,
                excludedBool: false,
                representationTypeId: null
            }
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            ipRepresentationGeneralDataViewData: { fields }
        } = this.props;
        if (fields) {
        const visibleFields = fields.filter(field => !field.hidden);
        visibleFields.forEach(item => {
            this.viewData[item.name] = item.data;

            if (Object.keys(this.viewData).length === visibleFields.length) {
                this.setState({ loaded: true });
            }
        });
    }
    }

    saveRepresentation = () => {
        const { addNewRepresentation, tabs, activeTab } = this.props;

        addNewRepresentation(this.state.representationObject, activeTab);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    render() {
        const {
            ipBaseNumber,
            isReadonly,
            lookupValues,
            representationGroups,
            representationUses,
            representationWorkGroups,
            tabs,
            activeTab,
            ipMaintenanceSaveResultData
        } = this.props;

        const representationObject: IRepresentation = {
            ipBaseNumber: this.state.representationObject.ipBaseNumber,
            parent: this.state.representationObject.parent,
            parentId: this.state.representationObject.parentId,
            description: this.state.representationObject.description,          
            representationGroup: this.state.representationObject.representationGroup,          
            representationGroupId: this.state.representationObject.representationGroupId,          
            workGroupDescription: this.state.representationObject.workGroupDescription,          
            workGroupId: this.state.representationObject.workGroupId,          
            use: this.state.representationObject.use,          
            useId: this.state.representationObject.useId,          
            representationType: this.state.representationObject.representationType,          
            representationTypeId: this.state.representationObject.representationTypeId,          
            excludedBool: this.state.representationObject.excludedBool,          
            startDate: this.state.representationObject.startDate,          
            endDate: this.state.representationObject.endDate          
        };

        const changeData = (value: any, name: IRepertoireStateKeys) => {           

            let valueNumber = isNaN(value) ? null : Number(value);

            if (name === 'description') {
                representationObject.description = value;               
            }
            else if (name === 'representationGroup') {
                representationObject.representationGroup = value;
                representationObject.representationGroupId = valueNumber;
            }
            else if (name === 'workGroup') {
                representationObject.workGroupDescription = value;
                representationObject.workGroupId = valueNumber;
            }
            else if (name === 'use') {
                representationObject.use = value;
                representationObject.useId = valueNumber;
            }
            else if (name === 'representationType') {
                representationObject.representationType = value;
            }
            else if (name === 'excluded') {
                representationObject.excludedBool = value;
            }
            else if (name === 'startDate') {
                representationObject.startDate = value;
            }
            else if (name === 'endDate') {
                representationObject.endDate = value;
            }

            this.setState({ representationObject: representationObject });
        }

        return (
                <div className="">
 
                    <div className="row">
                        <div className="col-md-12">
                            <span className="title">Representation</span>
                            <div className="newWorkButton" title={`Add Representation to IP Base Number ${ipBaseNumber}`}>
                                <div className="IconTextButton" onClick={this.saveRepresentation.bind(this)}>
                                    <i className="icon ms-Icon ms-Icon--CircleAddition"></i>
                                    Add Representation
                                </div>
                            </div>
                        </div>
                    </div>

                    <If condition={tabs[activeTab].validateRepresentationUnsuccessful === undefined ? false : tabs[activeTab].validateRepresentationUnsuccessful}>
                        <div>
                            <SaveResult
                                componentInstance={"IPRepresentationGeneralDataView"}
                                saveSuccess={tabs[activeTab].addRepresentationSuccessful == null ? false : tabs[activeTab].addRepresentationSuccessful}
                                saveResultData={ipMaintenanceSaveResultData}
                                messages={tabs[activeTab].validationMessages}
                                resetMessageBanner={this.resetMessageBanner.bind(this)}
                                addRepresentationSuccessful={false}
                            />
                        </div>
                    </If>

                    <div className="row">

                        <SizedTextDataInput
                            label={this.viewData[IP_IPBASENUMBER_STATE_KEY]}
                            fieldName={IP_IPBASENUMBER_STATE_KEY}
                            changeData={changeData}
                            value={ipBaseNumber}
                            readOnly={true}
                            isHidden={!this.viewData[IP_IPBASENUMBER_STATE_KEY]}
                        />

                        <SizedTextDataInput
                            label={this.viewData[IP_REPRESENTATION_PARENT_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_PARENT_STATE_KEY}
                            changeData={changeData}
                            value={this.state.representationObject.parent}
                            readOnly={true}
                            isHidden={!this.viewData[IP_REPRESENTATION_PARENT_STATE_KEY]}
                        />

                        <SizedTextDataInput
                            label={this.viewData[IP_REPRESENTATION_DESCRIPTION_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_DESCRIPTION_STATE_KEY}
                            changeData={changeData}
                            value={this.state.representationObject.description ? this.state.representationObject.description : ""}
                            readOnly={false}
                            isHidden={!this.viewData[IP_REPRESENTATION_DESCRIPTION_STATE_KEY]}
                        />

                        <SizedDropdownDataInput
                            label={this.viewData[IP_REPRESENTATION_REPRESENTATION_TYPE_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_REPRESENTATION_TYPE_STATE_KEY}
                            value={this.state.representationObject.representationType ? this.state.representationObject.representationType : ""}
                            changeData={changeData}
                            options={getLookupValues(REPRESENTATION_TYPE_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[IP_REPRESENTATION_REPRESENTATION_TYPE_STATE_KEY]}
                        />

                        <SizedDropdownDataInput
                            label={this.viewData[IP_REPRESENTATION_REPRESENTATION_GROUP_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_REPRESENTATION_GROUP_STATE_KEY}
                            value={this.state.representationObject.representationGroup ? this.state.representationObject.representationGroup : ""}
                            changeData={changeData}
                            options={convertToDropdownDataInputProps(representationGroups)}
                            allowNull={true}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[IP_REPRESENTATION_REPRESENTATION_GROUP_STATE_KEY]}
                        />

                        <SizedDropdownDataInput
                            label={this.viewData[IP_REPRESENTATION_WORK_GROUP_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_WORK_GROUP_STATE_KEY}
                            value={this.state.representationObject.workGroupDescription ? this.state.representationObject.workGroupDescription : ""}
                            changeData={changeData}
                            options={convertToDropdownDataInputProps(representationWorkGroups)}
                            allowNull={true}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[IP_REPRESENTATION_WORK_GROUP_STATE_KEY]}
                        />

                        <SizedDropdownDataInput
                            label={this.viewData[IP_REPRESENTATION_USE_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_USE_STATE_KEY}
                            value={this.state.representationObject.use ? this.state.representationObject.use : ""}
                            changeData={changeData}
                            options={convertToDropdownDataInputProps(representationUses)}
                            allowNull={true}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[IP_REPRESENTATION_USE_STATE_KEY]}
                        />

                        <SizedDateDataInput
                            label={this.viewData[IP_REPRESENTATION_START_DATE_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_START_DATE_STATE_KEY}
                            changeData={changeData}
                            value={this.state.representationObject.startDate ? this.state.representationObject.startDate.toString() : null}
                            readOnly={false}
                            isHidden={!this.viewData[IP_REPRESENTATION_START_DATE_STATE_KEY]}
                        />

                        <SizedDateDataInput
                            label={this.viewData[IP_REPRESENTATION_END_DATE_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_END_DATE_STATE_KEY}
                            changeData={changeData}
                            value={this.state.representationObject.endDate ? this.state.representationObject.endDate.toString() : null}
                            readOnly={false}
                        isHidden={!this.viewData[IP_REPRESENTATION_END_DATE_STATE_KEY]}
                        />

                        <SizedCheckboxDataInput
                            label={this.viewData[IP_REPRESENTATION_EXCLUDED_STATE_KEY]}
                            fieldName={IP_REPRESENTATION_EXCLUDED_STATE_KEY}
                            value={this.state.representationObject.excludedBool ? this.state.representationObject.excludedBool : false}
                            changeData={changeData}
                            isHidden={false}
                            readonly={false}
                        />
                    </div>
                </div>
            );
    }
}