import { ComboBox } from "@fluentui/react";
import * as React from "react";
import { FunctionComponent } from "react";
import {
    EMPTY_STRING_VALUE,
    REGION_LABEL_VALUE,
    USAGE_PAGE_FORM_ITEM_SIZE,
    SOURCEMAJOR_SEARCH_USAGES_STATE_KEY,
    SOURCEMINOR_SEARCH_USAGES_STATE_KEY,
    SEARCH_VIEW_USAGES,
    USAGE_PAGE_FORM_ACTION_BUTTON_SIZE_2,
    USAGE_MATCHING_WORKS_SECTION_KEY,
    USAGE_MATCHING_PRODUCTS_SECTION_KEY
} from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";

export interface IDropdownInputProps {
    label?: string;
    value: any;
    changeData: (
        value: string | string[] | boolean,
        fieldName: IRepertoireStateKeys,
        index?: number
    ) => void;
    fieldName: IRepertoireStateKeys;
    options: {
        code: string;
        description: string;
        extraFields?: object;
    }[];
    allowNull: boolean;
    showDropdownsAsCodes?: boolean;
    readOnly?: boolean;
    isHidden: boolean;
    componentInstance?: string;
    index?: number;
    backendSearch?: boolean;
    onBackendSearch?: (value: string, e?: any) => void;
}

export const InputSizedDropdown: FunctionComponent<IDropdownInputProps> = (props: IDropdownInputProps) => {

    if (props.isHidden)
        return null;

    let containerClass: string = "";

    if (props.componentInstance === SEARCH_VIEW_USAGES || USAGE_MATCHING_WORKS_SECTION_KEY || USAGE_MATCHING_PRODUCTS_SECTION_KEY) {

        if (props.fieldName === SOURCEMINOR_SEARCH_USAGES_STATE_KEY)
            containerClass = USAGE_PAGE_FORM_ACTION_BUTTON_SIZE_2;
        else if (props.fieldName === SOURCEMAJOR_SEARCH_USAGES_STATE_KEY)
            containerClass = USAGE_PAGE_FORM_ACTION_BUTTON_SIZE_2;
        else
            containerClass = USAGE_PAGE_FORM_ITEM_SIZE;
    }

    return <div className={containerClass}>
        <DropdownInput {...props} />
    </div>
}

const DropdownInput: FunctionComponent<IDropdownInputProps> = ({
    options,
    label,
    changeData,
    value,
    fieldName,
    allowNull,
    showDropdownsAsCodes,
    readOnly,
    index,
    backendSearch,
    onBackendSearch
}) => {
    const makeOptionsList = () => {
        var arr = [];
        if (allowNull) {
            arr.push({ key: -1, text: "", data: "" });
        }
        if (options) {
            for (let i = 0; i < options.length; i++) {
                const opt = options[i];
                arr.push(
                    {
                        key: i,
                        data: opt.code,
                        text: showDropdownsAsCodes ? opt.code : opt.description,
                        title: showDropdownsAsCodes ? opt.description : null,
                        disabled: opt.extraFields && 'DisabledInDropdown' in opt.extraFields && opt.extraFields['DisabledInDropdown'] !== undefined ? opt.extraFields['DisabledInDropdown'] : false,
                    }
                );
            }
        }

        return arr;
    };

    const optionsFinal = makeOptionsList().sort((a, b) => a.text != null ? a.text.localeCompare(b.text) : 0);
    const showCodesClassName: string = showDropdownsAsCodes ? ' showAsCode ' : EMPTY_STRING_VALUE
    const pointerClassName: string = label === REGION_LABEL_VALUE ? ' pointerEnable ' : EMPTY_STRING_VALUE;
    const readOnlyFinal: boolean = label === REGION_LABEL_VALUE ? false : readOnly;
    const displayValue: string = !showDropdownsAsCodes ?
        (optionsFinal !== undefined ?
            (value && optionsFinal.find(opt => opt.data === value) ?
                optionsFinal.find(opt => opt.data === value).text : value || '')
            : value || ''
        ) : value || '';

    const selectedValue = !showDropdownsAsCodes ?
        optionsFinal.find((option) => option.text === displayValue) :
        optionsFinal.find((option) => option.data === displayValue);
    
    const onKeyDownAction = (key: string) => {
        if (backendSearch) onBackendSearch(key);
    };

    return (
        <div className="dropdownDataInput">
            <ComboBox
                label={label ? label + ':' : undefined}
                options={optionsFinal}
                onInputValueChange={(val) => { onKeyDownAction(val) }}
                onChange={(_event, item) => changeData(item.data, fieldName, index)}
                useComboBoxAsMenuWidth
                calloutProps={{ calloutMaxHeight: 270 }}
                placeholder={displayValue}
                selectedKey={selectedValue ? selectedValue.key : undefined}
                className={showCodesClassName.concat(pointerClassName)}
                disabled={readOnlyFinal}
                allowFreeInput={true}
                autoComplete={"on"}
            />
        </div>
    );
};

export default DropdownInput;