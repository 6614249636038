import React, { Component } from "react";
import { If } from "../../../../core/components/If";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { openassignWorkflowTo, hideModal, showMessage, showModal, showUpdateWorkflow } from "../../../../redux/reducers/ModalReducer";
import { cancelWorkflowSession, clearModalSearchResults, clearWorkflowSelection, markSelectAll, nextWorkflowInSession, setErrorWorkflowSession, skipWorkflowInSession, startWorkflowSession } from "../../../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../../../redux/types/FormatFields";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IAssingWorkflowViewProps } from "../../../components/assingWorkflow/AssingWorkflowView";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { IUpdateWorkflowViewProps } from "../../../components/updateWorkflow/UpdateWorkflowView";
import { ASSING_TO_METHOD_TOOLBAR, ASSING_TO_WORKFLOW_STATE_KEY, ASSING_VIEW_TITLE_STATE_KEY, DESELECT_ALL_METHOD_TOOLBAR, DESELECT_ALL_WORKFLOW_STATE_KEY, IS_ANY_COMPLETED_WORKFLOW_ERROR, START_WORKFLOW_SESSION_METHOD_TOOLBAR, START_WORKFLOW_SESSION_STATE_KEY, UPDATE_STATUS_WORKFLOW_STATE_KEY, UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR, UPDATE_WORKFLOW_VIEW_TITLE_STATE_KEY, WORKFLOW_COMPLETED_STATUS, WORKFLOW_PAGE_TOOLBAR, WORKFLOW_PAGE_VIEW } from "../../../Consts";
import { DataSource } from "../../../types/DataSource";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { SearchRequests } from "../../../services/SearchRequests";


export interface IWorkflowPageToolbarProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    clearModalSearchResults?: typeof clearModalSearchResults;
    scroll: number;
    toolbarWidth: number;
    activeTab: number
    tabs?: ITabReduxItem[];
    workflowPageToolbarData: IRepertoireComponentDataItem;
    workflowSearchResultsCount: number;
    onOpenAssignView?: typeof openassignWorkflowTo;
    assingWorkflowDataView?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    onAssignWorkflow: (username: string) => void;
    onUpdateStatusWorkflow: (status: string) => void;
    updateWorkflowViewData: IRepertoireComponentDataItem;
    onOpenUpdateWorkflowsView?: typeof showUpdateWorkflow;
    clearSelection?: typeof clearWorkflowSelection;
    users?: {
        code: string;
        description: string;
    }[];
    markSelectAll?: typeof markSelectAll;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    isWorkflowSessionStarted?: boolean;
    startWorkflowSession: typeof startWorkflowSession;
    getWorkDetails: (dataSource: string, workID: number, isForWorkflowSession?: boolean) => void;
    getWorkDetailsWorkflow: (dataSource: string, workID: number, selectedWorkflows: IWorkflowSearchResult[], openEntityForWorkflowSession?: boolean) => void;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems: IWorkflowSearchResult[];
    dataActionList?: IDataActionToolbar[];
    workFormats?: FormatFields[];
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    lookupValues?: ILookupDictionary;
    isAnySelectedCompletedError?: boolean;
    setErrorWorkflowSession?: typeof setErrorWorkflowSession;
    openEntityForWorkflowSession?: boolean;
    currentWorkflowIndex?: number;
    getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) => void;
    skipUsageWorkflowItem: (activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    getProductDetails: (dataSource: string, workID: number, isForWorkflowSession?: boolean) => void;
    getAgreementDetails: (dataSource: string, agreementID: number, isForWorkflowSession?: boolean) => void;
    skipProductWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , dataActions: IDataActionToolbar[]
        , formats: FormatFields[]
        ) =>void;
}

class WorkflowPageToolbar extends Component<IWorkflowPageToolbarProps, null>{
    toolbarParentRef;
    constructor(props) {
        super(props);
        this.toolbarParentRef = React.createRef();
    }

    setActionList = () => {
        const {
            tabs,
            activeTab
        } = this.props;

        const actionList = { ...tabs[activeTab] };
        const newActionList: IDataActionToolbar[] = [];

        actionList.dataActionToolbar.map(action => {
            newActionList.push(this.setActionAndName(action));
        });

        return newActionList;
    }

    onOpenAssingModalView() {
        const { onOpenAssignView, assingWorkflowDataView, hideModal, dataGridTableData, onAssignWorkflow, users, updatePagination, workflowSearchResultsCount } = this.props;
        
        const userList = [];

        if (users) {
            users.map(x => {
                userList.push({
                    userName: { value: x.description },
                    numberOfWorkflows:{ value: null },
                    assigned: { value: false }
                });
            });
        }   

        const tittle = assingWorkflowDataView.fields.find(x => x.name === ASSING_VIEW_TITLE_STATE_KEY).data;
        const props: IAssingWorkflowViewProps = {
            assingTo: onAssignWorkflow,
            assingWorkflowViewData: assingWorkflowDataView,
            hideModal: hideModal,
            items: userList,
            searchResultsTableData: dataGridTableData,
            updatePagination: updatePagination,
            totalWorkflows: workflowSearchResultsCount,
        }
        onOpenAssignView(tittle, props);
    }

    onOpenUpdateStatusView() {
        const { updateWorkflowViewData, hideModal, onUpdateStatusWorkflow, onOpenUpdateWorkflowsView } = this.props;

        const title = updateWorkflowViewData.fields.find(x => x.name === UPDATE_WORKFLOW_VIEW_TITLE_STATE_KEY).data;
        const props: IUpdateWorkflowViewProps = {
            updateWorkflows: onUpdateStatusWorkflow,
            updateWorkflowViewData: undefined,
            hideModal: hideModal
        }
        onOpenUpdateWorkflowsView(title, props);
    }

    onClearSelectedWorkflows() {
        const { clearSelection, markSelectAll } = this.props;
        markSelectAll(false);
        clearSelection();
    }

    onStartWorkflowSession() {
        const { startWorkflowSession, tabs, activeTab, getWorkDetailsWorkflow, workFormats, getUsageDetails, setErrorWorkflowSession, openEntityForWorkflowSession, workMaintenanceGeneralDataViewData, getProductDetails, workflowSessionItems, lookupValues, dataActionList, currentWorkflowIndex, getAgreementDetails } = this.props;
        const workflowsResult: IWorkflowSearchResult[] = tabs[activeTab].workflowSearchResult;
        if (workflowsResult) {
            const selectedWorkflows: IWorkflowSearchResult[] = workflowsResult.filter(x => x.selected);
            if (selectedWorkflows.some(x => x.status === WORKFLOW_COMPLETED_STATUS)) {
                setErrorWorkflowSession();
            }
            else {
                const firstItem = selectedWorkflows[0];
                startWorkflowSession(selectedWorkflows, 0);
                if (firstItem.entityType === "Usage (Work Based)" || firstItem.entityType == "Usage (Product Based)" || firstItem.entityType == "Usage (Live Performance)") {
                    getUsageDetails(firstItem.entityID, openEntityForWorkflowSession)
                }
                else if (firstItem.entityType === "Intray Product") {
                    getProductDetails(firstItem.setType, parseInt(firstItem.entityID), openEntityForWorkflowSession)
                }
                else if (firstItem.entityType === "Intray Agreement") {
                    getAgreementDetails(firstItem.setType, parseInt(firstItem.entityID), openEntityForWorkflowSession)
                }
                else {
                    getWorkDetailsWorkflow(firstItem.setType, parseInt(firstItem.entityID), selectedWorkflows, openEntityForWorkflowSession);
                }
            }
        }
    }

    setActionAndName(action: IDataActionToolbar) {
        const {
            workflowPageToolbarData: { fields },
            workflowSearchResultsCount,
        } = this.props;

        switch (action.method) {
            case ASSING_TO_METHOD_TOOLBAR: {
                return {
                    action: this.onOpenAssingModalView.bind(this),
                    name: fields.find(copy => copy.name === ASSING_TO_WORKFLOW_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--FollowUser'
                }
            }
            case UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR: {
                return {
                    action: this.onOpenUpdateStatusView.bind(this),
                    name: fields.find(copy => copy.name === UPDATE_STATUS_WORKFLOW_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--ProgressLoopOuter'
                }
            }
            case DESELECT_ALL_METHOD_TOOLBAR: {
                const name = `${workflowSearchResultsCount} ${fields.find(copy => copy.name === DESELECT_ALL_WORKFLOW_STATE_KEY).data}`
                return {
                    action: this.onClearSelectedWorkflows.bind(this),
                    name: name,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--Cancel'
                }
            }
            case START_WORKFLOW_SESSION_METHOD_TOOLBAR: {
                return {
                    action: this.onStartWorkflowSession.bind(this),
                    name: fields.find(copy => copy.name === START_WORKFLOW_SESSION_STATE_KEY).data,
                    component: action.component,
                    method: action.method,
                    icon: 'icon ms-Icon ms-Icon--Flow'
                }
            }

        }
    }

    render() {
        const { scroll
            , toolbarWidth
            , activeTab
            , tabs
            , workflowPageToolbarData
            , isWorkflowSessionStarted
            , startWorkflowSession
            , cancelWorkflowSession
            , skipWorkflowItem
            , nextWorkflowItem
            , workflowSessionItems
            , dataActionList
            , workFormats
            , workMaintenanceGeneralDataViewData
            , lookupValues
            , isAnySelectedCompletedError
            , currentWorkflowIndex,
            skipUsageWorkflowItem,
            skipProductWorkflowItem
        } = this.props;
        const actionList = this.setActionList();
        return (
            <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>


                <ToolbarComponent
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    activeTab={activeTab}
                    component={WORKFLOW_PAGE_TOOLBAR}
                    dataActionToolbar={actionList}
                    saveResultData={undefined}
                    tabs={tabs}
                    workflowFieldsData={undefined}
                    toolbarData={workflowPageToolbarData}
                    isWorkflowSessionStarted={isWorkflowSessionStarted}
                    startWorkflowSession={startWorkflowSession}
                    skipWorkflowItem={skipWorkflowItem}
                    cancelWorkflowSession={cancelWorkflowSession}
                    nextWorkflowItem={nextWorkflowItem}
                    workflowSessionItems={workflowSessionItems}
                    dataActionList={dataActionList}
                    workFormats={workFormats}
                    workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                    lookupValues={lookupValues}
                    currentWorkflowIndex={currentWorkflowIndex}
                    skipUsageWorkflowItem={skipUsageWorkflowItem}
                    skipProductWorkflowItem={skipProductWorkflowItem}
                />

                <If condition={isAnySelectedCompletedError}>
                    <p className="bold" style={{ marginTop: '25px', marginLeft: '10px' }}>{workflowPageToolbarData.fields.find(x => x.name === IS_ANY_COMPLETED_WORKFLOW_ERROR).data}</p>
                </If>
            </div>
        )
    }
}

export default WorkflowPageToolbar;