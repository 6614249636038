import React, { FunctionComponent } from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { EMPTY_STRING_VALUE, REGION_LABEL_VALUE, REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_2, REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3, REPERTOIRE_PAGE_FORM_ITEM_SIZE, REPERTOIRE_PAGE_FORM_LARGE_BOX_SIZE, REPERTOIRE_PAGE_FORM_WIDE_SIZE, SEARCH_VIEW_WORKFLOW, UPDATE_WORKFLOW_VIEW, UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY, WORKFLOW_ENTITY_TYPE_STATE_KEY } from "../../Consts";
import { TreeSelect } from "antd";
import { ITreeData } from "../../types/ITreeData";

export interface IDropdownTreeDataInputProps {
    label?: string;
    value: any;
    changeData: (
        value: string | string[] | boolean,
        fieldName: IRepertoireStateKeys
    ) => void;
    fieldName: IRepertoireStateKeys;
    options: ITreeData[];
    allowNull: boolean;
    showDropdownsAsCodes?: boolean;
    readOnly?: boolean;
    isHidden: boolean;
    componentInstance?: string;
}

export const SizedDropdownTreeDataInput: FunctionComponent<IDropdownTreeDataInputProps> = (props: IDropdownTreeDataInputProps) => {

    if (props.isHidden)
        return null;
    let containerClass: string = REPERTOIRE_PAGE_FORM_ITEM_SIZE;

    return <div className={containerClass}>
        <DropdownTreeDataInput {...props} />
    </div>
}

const DropdownTreeDataInput: FunctionComponent<IDropdownTreeDataInputProps> = ({
    options,
    label,
    changeData,
    value,
    fieldName,
    allowNull,
    showDropdownsAsCodes,
    readOnly
}) => {
    const showCodesClassName: string = showDropdownsAsCodes ? ' showAsCode ' : EMPTY_STRING_VALUE

    const onChange = value => {
      changeData(value, fieldName);
    }

    return (
        <div className="dropdownDataInput">
            {label ? <label>{label}:</label> : null}
            <TreeSelect
                className={'treeVewSelector'}
                disabled={readOnly}
                onChange={onChange}
                treeDataSimpleMode={true}
                treeData={options}
                placeholder={'Select Source'}
                dropdownStyle={{ overflow: 'auto' }}
                treeDefaultExpandAll={true}
                value={value}
                allowClear={true}
            />
        </div>
    );
};

export default DropdownTreeDataInput;