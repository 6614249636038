import axios from "axios";
import { IWork } from "../../repertoire/types/IWork";
import { IAddWorkAttachmentModel } from "../types/IAddAttchmentWorkModel";
import { IAttachedFile } from "../types/IAttachedFile";
import { IRemoveBlobAttachmentBase } from "../../repertoire/types/IRemoveBlobAttachmentBase";
import { IWorkContributor } from "../../repertoire/types/IWorkContributor";
import { IWorkDetailsQuery } from "../../membersportal/types/IWorkDetailsQuery";
import { IMemberWorkStatus } from "../../membersportal/types/IMemberWorkStatus";
import { IMemberStatementPaymentRunWork } from "../../membersportal/types/IMemberStatementSearchResult";
import { IProductCore } from "../../repertoire/types/IProductCore";
import { IUsageGroup } from "../../repertoire/types/usageTypes/IUsageGroup";
import { IAgreement } from "../../repertoire/types/IAgreement";
import { IAddAgreementAttachmentModel } from "../types/IAddAttchmentAgreementModel";
import { IRemoveAgreementAttachment } from "../../repertoire/types/IRemoveAgreementBlobAttachment";


export class MembersPortalService {

    static saveDraftWork(updatedWork: IWork): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('membersPortalWork/save/', updatedWork)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static saveDraftSetLists(updatedSetList: IProductCore): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('membersPortalSetList/save/', updatedSetList)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static saveDraftLivePerformance(updatedLivePerformance: IUsageGroup): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('membersPortalLivePerformance/SaveNewUsageGroup/', updatedLivePerformance)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static saveDraftAgreement(updatedAgreement: IAgreement, postAgreement?:boolean): Promise<any> {
        updatedAgreement.notes.forEach((note, index) => {
            note.notesID = -1 - index;
        });
        return new Promise<any>((resolve, reject) => {
            const url: string = "membersPortalAgreement/save/" + (postAgreement ? "true" : "false");
            axios
                .post(url, updatedAgreement)
                .then(res => {
                    res.data.agreement.societyAffiliation = updatedAgreement.societyAffiliation;
                    res.data.agreement.agreementAdminIP.forEach((assignee: any, index: number) => {
                        assignee.societyAffiliation = updatedAgreement.agreementAdminIP[index].societyAffiliation;
                    });
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }


    static deleteWork(workId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("membersPortalWork/delete/" + workId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static deleteSetList(setListId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("membersPortalWork/delete/" + setListId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static saveRegisteredWork(updatedRegisteredWork: IWork): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('registeredWork/save/', updatedRegisteredWork)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static deleteRegisteredWork(workId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .delete("registeredWork/delete/" + workId)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static removeWorkAttachment(model: IAddWorkAttachmentModel): Promise<boolean> {
        const url: string = "membersPortalWork/removeattachment"
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }

    static addAgreementAttachmentRepertoire(model: IAddAgreementAttachmentModel): Promise<IAttachedFile> {
        const url: string = "membersPortalAgreement/addAttachment"
        return new Promise<IAttachedFile>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }

    static removeAgreementAttachment(model: IAddAgreementAttachmentModel): Promise<boolean> {
        const url: string = "membersPortalAgreement/removeattachment"
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }


    static addAttachmentRepertoire(model: IAddWorkAttachmentModel): Promise<IAttachedFile> {
        const url: string = "membersPortalWork/addAttachment"
        return new Promise<IAttachedFile>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        })
    }



    static removeWorkBlobAttachment(model: IRemoveBlobAttachmentBase): Promise<boolean> {
        const url: string = "membersPortalWork/RemoveBlobAttachment";
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch((err => {
                    console.log(err);
                    reject(err);
                }))
        })
    }

    static removeAgreementBlobAttachment(model: IRemoveAgreementAttachment): Promise<boolean> {
        const url: string = "membersPortalAgreement/RemoveBlobAttachment";
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post(url, model)
                .then(res => {
                    resolve(res.data);
                })
                .catch((err => {
                    console.log(err);
                    reject(err);
                }))
        })
    }

    static getContributors(searchQuery: IWorkDetailsQuery): Promise<IWorkContributor[]> {
        const {
            workIDs,
            dataSource,
            setType,
            setTypeGroup,
            contributorTypesToShow
        } = searchQuery;
        return new Promise<IWorkContributor[]>((resolve, reject) => {
            axios
                .post("membersPortalWork/GetWorkContributors", {
                    workIDs: workIDs.map(v => v.toString()),
                    dataSource: dataSource,
                    setType: setType,
                    setTypeGroup: setTypeGroup,
                    contributorTypesToShow: contributorTypesToShow
                })
                .then(response => {
                    const workContributors: IWorkContributor[] = [];
                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];
                        if (data.mechanicalShare === -1)
                            data.mechanicalShare = '';
                        if (data.performingShare === -1)
                            data.performingShare = '';
                        if (!workContributors.includes(data)) {
                            workContributors.push(data)
                        }
                    }

                    resolve(workContributors);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    static getStatus(searchQuery: IWorkDetailsQuery): Promise<IMemberWorkStatus[]> {
        const {
            workIDs,
            dataSource,
            setTypeGroup,
        } = searchQuery;
        return new Promise<IMemberWorkStatus[]>((resolve, reject) => {
            axios
                .post("membersPortalWork/GetWorkStatus", {
                    workIDs: workIDs.map(v => v.toString()),
                    dataSource: dataSource,
                    setTypeGroup: setTypeGroup,
                    setType: null,
                    contributorTypesToShow: null
                })
                .then(response => {
                    const workStatus: IMemberWorkStatus[] = [];
                    for (let workID in response.data) {
                        const memberWorkStatus: IMemberWorkStatus = {
                            workID: Number(workID),
                            workStatus: response.data[workID]
                        };
                        workStatus.push(memberWorkStatus);
                    }
                    resolve(workStatus);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    static getStatementPaymentRunWorks(paymentRunID: number, userNameNumber: string): Promise<IMemberStatementPaymentRunWork[]> {
        return new Promise<IMemberStatementPaymentRunWork[]>((resolve, reject) => {
            axios
                .post("MembersPortalStatement/StatementWorks", {
                    paymentRunID: paymentRunID.toString(),
                    type: null,
                    status: null,
                    paymentRunDateFrom: null,
                    paymentRunDateTo: null,
                    userNameNumber
                })
                .then(response => {
                    const paymentRunWorks: IMemberStatementPaymentRunWork[] = [];
                    for (let x = 0; x < response.data.length; x++) {
                        const work = response.data[x];
                        let workSearchResultItem: IMemberStatementPaymentRunWork = {
                            workNumber: work.workNumber,
                            ipiNameNumber: work.ipiNameNumber,
                            ipName: work.ipName,
                            title: work.title,
                            sharePercentage: work.sharePercentage,
                            amount: work.amount,
                            distributionID: work.distributionID,
                            distributionCode: work.distributionCode,
                            poolID: work.poolID,
                            poolCode: work.poolCode,
                            usageDetails: work.usageDetails

                        }
                        paymentRunWorks.push(workSearchResultItem);
                    }
                    resolve(paymentRunWorks);
                })
                .catch(response => {
                    reject(response);
                })
        });
    }
}
