import * as React from "react";
import { DISCOUNT_TYPE_LOOKUP, DISTRIBUTION_STATUS_LOOKUP, DISTRIBUTION_SUB_TYPE_LOOKUP, DISTRIBUTION_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { IDistributionState, IDistributionStateKeys } from "../../../../redux/types/IDistributionState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { ALL_ROLES, COMPONENT_INSTANCE_DISTRIBUTIONS, CONFIGURE_DISTRIBUTIONS_ROLE, DISTRIBUTIONS_MAINTENANCE_GENERAL_DATA_VIEW, DISTRIBUTION_CAT1_STATE_KEY, DISTRIBUTION_CAT2_STATE_KEY, DISTRIBUTION_CLAIMS_FROM_STATE_KEY, DISTRIBUTION_CLAIMS_TO_STATE_KEY, DISTRIBUTION_CODE_STATE_KEY, DISTRIBUTION_DATE_STATE_KEY, DISTRIBUTION_DESCRIPTION_STATE_KEY, DISTRIBUTION_ID_STATE_KEY, DISTRIBUTION_STATEMENT_DESCRIPTION_STATE_KEY, DISTRIBUTION_STATUS_STATE_KEY, DISTRIBUTION_TYPE_STATE_KEY, DISTRIBUTION_USAGE_FROM_STATE_KEY, DISTRIBUTION_USAGE_TO_STATE_KEY, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, SEARCH_RESULTS_TABLE, SEARCH_VIEW, TITLE_EDITABLE_FIELDS_VIEW, DISTRIBUTION_LIVE_PERFORMANCE_STATE_KEY, DISTRIBUTION_DISCOUNT_TYPE_STATE_KEY, DISTRIBUTION_CLAIMABLE_STATE_KEY, DISTRIBUTION_SUB_TYPE_STATE_KEY } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { getLookupDefault, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { SizedTextDisplay } from "../../../../core/components/textDisplay/TextDisplay";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { updateDistributionField, setEditableFields } from "../../../../redux/reducers/RepertoireReducer";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";

export interface IDistributionMaintenanceGeneralDataViewProps {
    distributionMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    distribution: IDistributionState,
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    updateDistributionField: typeof updateDistributionField;
    roles: string[];
    setEditableFields: typeof setEditableFields;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    editableFields: IRepertoireField[];
    activeTab?: number;
    tabs?: ITabReduxItem[];
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    customer: string;
}

interface IDistributionMaintenanceGeneralDataViewState {
    loaded: boolean;
}

export class DistributionMaintenanceGeneralDataView extends React.PureComponent<
    IDistributionMaintenanceGeneralDataViewProps,
    IDistributionMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: IDistributionMaintenanceGeneralDataViewProps) {
        super(props);
        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            distributionMaintenanceGeneralDataViewData: { fields }
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }


    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableEditableFieldsData,
            setEditableFields,
        } = this.props;

        setEditableFields(DISTRIBUTIONS_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_DISTRIBUTIONS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableEditableFieldsData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], DISTRIBUTIONS_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_DISTRIBUTIONS, DISTRIBUTIONS_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    render() {
        const {
            isReadonly,
            lookupValues,
            updateDistributionField,
            customer,
        } = this.props;

        const {
            distributionID,
            distributionCode,
            distributionType,
            distributionSubType,
            distributionStatus,
            cat1,
            cat2,
            distributionDate,
            description,
            statementDescription,
            usageFrom,
            usageTo,
            claimsFrom,
            claimsTo,
            isLivePerformancePortal,
            discountType,
            claimable
        } = this.props.distribution;

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            updateDistributionField(value, name as IDistributionStateKeys);
        }

        const checkClaimable = ():boolean => {
            
            if (distributionType === "Foreign Lending" || distributionType === "Licenses Without Reporting Books" || distributionType === "Licenses Without Reporting Magazines"
                || distributionType === "Licenses Without Reporting Sheet Music" || distributionType === "Press Clippings" || distributionType === "RRO"
                || distributionType === "Spanish Libraries" || distributionType === "Usage Reporting") {
                return true;
            }
            return false;
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        if (loaded) {

            let claimableCheck = checkClaimable();

            return (
                <div>
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">General</h1>
                            <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Distribution Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedTextDataInput
                                label={this.viewData[DISTRIBUTION_ID_STATE_KEY]}
                                fieldName={DISTRIBUTION_ID_STATE_KEY}
                                changeData={changeData}
                                value={distributionID}
                                isHidden={!this.viewData[DISTRIBUTION_ID_STATE_KEY]}
                                readOnly={true}
                            />
                            <SizedTextDataInput
                                label={this.viewData[DISTRIBUTION_CODE_STATE_KEY]}
                                fieldName={DISTRIBUTION_CODE_STATE_KEY}
                                changeData={changeData}
                                value={distributionCode}
                                isHidden={!this.viewData[DISTRIBUTION_CODE_STATE_KEY]}
                                readOnly={true}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[DISTRIBUTION_TYPE_STATE_KEY]}
                                fieldName={DISTRIBUTION_TYPE_STATE_KEY}
                                value={distributionType ? distributionType : getLookupDefault(DISTRIBUTION_TYPE_LOOKUP, lookupValues)}
                                changeData={changeData}
                                options={getLookupValues(DISTRIBUTION_TYPE_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={isReadonly}
                                isHidden={!this.viewData[DISTRIBUTION_TYPE_STATE_KEY]} />
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[DISTRIBUTION_STATUS_STATE_KEY]}
                                fieldName={DISTRIBUTION_STATUS_STATE_KEY}
                                value={distributionStatus ? distributionStatus : getLookupDefault(DISTRIBUTION_STATUS_LOOKUP, lookupValues)}
                                changeData={changeData}
                                options={getLookupValues(DISTRIBUTION_STATUS_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={false}
                                isHidden={!this.viewData[DISTRIBUTION_STATUS_STATE_KEY]} />
                            <SizedTextDataInput
                                label={this.viewData[DISTRIBUTION_CAT1_STATE_KEY]}
                                fieldName={DISTRIBUTION_CAT1_STATE_KEY}
                                changeData={changeData}
                                value={cat1}
                                isHidden={!this.viewData[DISTRIBUTION_CAT1_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[DISTRIBUTION_CAT2_STATE_KEY]}
                                fieldName={DISTRIBUTION_CAT2_STATE_KEY}
                                changeData={changeData}
                                value={cat2}
                                isHidden={!this.viewData[DISTRIBUTION_CAT2_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                        </div>
                        <div className="row">
                            <SizedDateDataInput
                                label={this.viewData[DISTRIBUTION_DATE_STATE_KEY]}
                                fieldName={DISTRIBUTION_DATE_STATE_KEY}
                                changeData={changeData}
                                value={distributionDate ? distributionDate.toString() : null}
                                isHidden={!this.viewData[DISTRIBUTION_DATE_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[DISTRIBUTION_DESCRIPTION_STATE_KEY]}
                                fieldName={DISTRIBUTION_DESCRIPTION_STATE_KEY}
                                changeData={changeData}
                                value={description}
                                isHidden={!this.viewData[DISTRIBUTION_DESCRIPTION_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[DISTRIBUTION_STATEMENT_DESCRIPTION_STATE_KEY]}
                                fieldName={DISTRIBUTION_STATEMENT_DESCRIPTION_STATE_KEY}
                                changeData={changeData}
                                value={statementDescription}
                                isHidden={!this.viewData[DISTRIBUTION_STATEMENT_DESCRIPTION_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                        </div>
                        <div className="row">
                            <SizedDateDataInput
                                label={this.viewData[DISTRIBUTION_USAGE_FROM_STATE_KEY]}
                                fieldName={DISTRIBUTION_USAGE_FROM_STATE_KEY}
                                changeData={changeData}
                                value={usageFrom ? usageFrom.toString() : null}
                                isHidden={!this.viewData[DISTRIBUTION_USAGE_FROM_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedDateDataInput
                                label={this.viewData[DISTRIBUTION_USAGE_TO_STATE_KEY]}
                                fieldName={DISTRIBUTION_USAGE_TO_STATE_KEY}
                                changeData={changeData}
                                value={usageTo ? usageTo.toString() : null}
                                isHidden={!this.viewData[DISTRIBUTION_USAGE_TO_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedCheckboxDataInput
                                label={this.viewData[DISTRIBUTION_LIVE_PERFORMANCE_STATE_KEY]}
                                fieldName={DISTRIBUTION_LIVE_PERFORMANCE_STATE_KEY}
                                value={isLivePerformancePortal}
                                changeData={changeData}
                                readonly={isReadonly}
                                isHidden={!this.viewData[DISTRIBUTION_LIVE_PERFORMANCE_STATE_KEY]}
                                inline={true}
                            />
                        </div>
                        <div className="row">

                            <SizedDateDataInput
                                label={this.viewData[DISTRIBUTION_CLAIMS_FROM_STATE_KEY]}
                                fieldName={DISTRIBUTION_CLAIMS_FROM_STATE_KEY}
                                changeData={changeData}
                                value={claimsFrom ? claimsFrom.toString() : null}
                                isHidden={!this.viewData[DISTRIBUTION_CLAIMS_FROM_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedDateDataInput
                                label={this.viewData[DISTRIBUTION_CLAIMS_TO_STATE_KEY]}
                                fieldName={DISTRIBUTION_CLAIMS_TO_STATE_KEY}
                                changeData={changeData}
                                value={claimsTo ? claimsTo.toString() : null}
                                isHidden={!this.viewData[DISTRIBUTION_CLAIMS_TO_STATE_KEY]}
                                readOnly={isReadonly}
                            />                                                  
                            {customer == "CEDRO" ?
                                <SizedDropdownDataInput
                                label={this.viewData[DISTRIBUTION_DISCOUNT_TYPE_STATE_KEY]}
                                fieldName={DISTRIBUTION_DISCOUNT_TYPE_STATE_KEY}
                                value={discountType ? discountType : null}
                                changeData={changeData}
                                options={getLookupValues(DISCOUNT_TYPE_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={false}
                                isHidden={!this.viewData[DISTRIBUTION_DISCOUNT_TYPE_STATE_KEY]} />
                                : null}
                        </div>
                        <div className="row">
                        {customer == "CEDRO" ?
                                <SizedDropdownDataInput
                                    label={this.viewData[DISTRIBUTION_SUB_TYPE_STATE_KEY]}
                                    fieldName={DISTRIBUTION_SUB_TYPE_STATE_KEY}
                                    value={distributionSubType ? distributionSubType : getLookupDefault(DISTRIBUTION_SUB_TYPE_LOOKUP, lookupValues)}
                                    changeData={changeData}
                                    options={getLookupValues(DISTRIBUTION_SUB_TYPE_LOOKUP, lookupValues)}
                                    allowNull={false}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[DISTRIBUTION_SUB_TYPE_STATE_KEY]} 
                                />    
                                : null}
                            {customer == "CEDRO" ?
                                <>
                                <br /><br />
                                <SizedCheckboxDataInput
                                    label={this.viewData[DISTRIBUTION_CLAIMABLE_STATE_KEY]}
                                    fieldName={DISTRIBUTION_CLAIMABLE_STATE_KEY}
                                    value={claimable}
                                    changeData={changeData}
                                    readonly={isReadonly || claimableCheck}
                                    isHidden={!this.viewData[DISTRIBUTION_CLAIMABLE_STATE_KEY]}
                                /></> : null}  

                            
                        </div>
                    </div>
                </div>
            );
        }
        return <div />;

    }
}