import axios from "axios";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IDistributionSubjects } from "../../repertoire/types/usageTypes/IDistributionSubjects";
import { ILastPercentageSearchQuery } from "../../repertoire/types/usageTypes/ILastPercentageSearchQuery";
import { IPool } from "../types/IPool";

export class DistributionService {
    public static getDistributions(): Promise<IDistribution[]> {
        return new Promise<IDistribution[]>((resolve, reject) => {
            axios.get("Distribution/Distributions")
                .then(res => {
                    resolve(res.data);
                })
        });
    }
    
    public static getLastPercentage(searchQuery: ILastPercentageSearchQuery): Promise<IDistributionSubjects[]> {
        const {
            workType,
            licensingUseType,
            memberType,
            rightType
        } = searchQuery;
        return new Promise<IDistributionSubjects[]>((resolve, reject) => {
            axios
                .get("Distribution/Subject/" + rightType + '/' + licensingUseType + '/' + memberType + '/' + workType)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }


    public static getPools(): Promise<IPool[]> {
        return new Promise<IPool[]>((resolve, reject) => {
            axios.get("UsagePool/Pools")
                .then(res => {
                    resolve(res.data);
                })
        });
    }

    public static getPoolsByDistribution(distributionID: number): Promise<IPool[]> {
        return new Promise<IPool[]>((resolve, reject) => {
            axios.get("UsagePool/Pools/" + distributionID)
                .then(res => {
                    resolve(res.data);
                })
        });
    }
}