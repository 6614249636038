import * as signalR from "@microsoft/signalr";
import { IJob } from "../types/IJob";

export class JobMonitoringService {

    public static stopMonitoringProgress(): void {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/job")
            .build();

        connection.off("messageReceived");
        connection.stop();
    }

    public static startMonitoringProgress(callback: (jobs: IJob[]) => void): void {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl("/job")
            .build();

        connection.on("messageReceived", callback);

        connection.start();
    }
}