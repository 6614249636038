import axios from "axios";
import { ILookup } from "../types/ILookup";
import { ILookupDictionary } from "../types/ILookupDictionary";
import { Dictionary } from "../../core/types/Dictionary";

export class LookupService {
    static getLookUpEntities(): Promise<ILookupDictionary> {
        return new Promise<ILookupDictionary>((resolve, reject) => {
            axios
                .get("lookup")
                .then(res => {
                    let lookups: ILookup[] = res.data;
                    let lookupDictionary: ILookupDictionary = {};

                    lookups.forEach(lookup => {
                        lookupDictionary[lookup.entity] = lookup;
                    });
                    
                    resolve(lookupDictionary);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    private static getLookupsForLookupEntity(
        entity: string,
        lookups: object
    ): Promise<ILookup> {
        return new Promise<ILookup>((resolve, reject) => {
            axios
                .get("lookup/" + entity)
                .then(res => {
                    let lookup: ILookup = res.data;
                    lookups[entity] = lookup;
                    resolve(lookup);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getLookupsForEntity(entity: string): Promise<ILookup> {
        return new Promise<ILookup>((resolve, reject) => {
            axios
                .get("lookup/" + entity)
                .then(res => {
                    let lookup: ILookup = res.data;
                    resolve(lookup);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
    
    static getUsers(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            axios
                .get("lookup/" + "Users")
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    static getLookUpDefaults(): Promise<Dictionary<string>> {
        return new Promise<Dictionary<string>>((resolve, reject) => {
            axios
                .get("lookup/GetLookupDefaults")
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }
}