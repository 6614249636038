import * as React from "react";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IMembersPortalField } from "../types/IMembersPortalField";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { INTRAY, SEARCH_VIEW_DRAFT_WORKS } from "../Consts";
import { ActionButtonFormik } from "../../repertoire/components/actionButton/ActionButton";
import DisplayTableSearchResults from "../sharedComponents/displayTableSearchResults/DisplayTableSearchResults";
import { useMsal } from "@azure/msal-react";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IMemberStatementSearchResult } from "../types/IMemberStatementSearchResult";
import { FormatFields } from "../../redux/types/FormatFields";
import { IDraftWorkSearchQuery } from "../types/IDraftWorkSearchQuery";
import { IDraftWorkSearchResult } from "../types/IDraftWorkSearchResult";
import { Formik, Form, Field, FieldArray } from 'formik';
import { REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../repertoire/Consts";
import { WORK_STATUS_LOOKUP } from "../../lookup/Consts";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";

export interface IDraftWorksSearchViewProps {
    searchDraftWorks: (query: IDraftWorkSearchQuery, isModalOpen?: boolean) => void;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    draftWorkSearchResults: IDraftWorkSearchResult[];
    searchSuccessful: boolean;
    paymentStatementGeneralDataViewData?: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    resetPagination: (repertoireSection: string) => void;
    getMemberStatementDetails?: (payment: IMemberStatementSearchResult, lookupValues: ILookupDictionary, userNameNumber: string, formats?: FormatFields[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    disabled?: boolean;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    memberPortalSearchType?: MemberPortalSearchTypeItem;
    currentUserNameNumber?: string;
    expandAllResults: () => void;
    expandAll: boolean;
    isSearching?: boolean;
    getDraftWorkDetails: (dataSource: string, workId: number) => void;
    dataSource: string;
}



const DraftWorksSearchView: React.FC<IDraftWorksSearchViewProps> = ({
    searchDraftWorks,
    searchViewData,
    searchSuccessful,
    searchResultsTableData,
    draftWorkSearchResults,
    currentUserNameNumber,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    resetPagination,
    lookupValues,
    sortSearchResults,
    getDraftWorkDetails,
    memberPortalSearchType,
    dataSource
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const [filteredFields, setFilteredFields] = React.useState<IRepertoireField[] | IMembersPortalField[]>([]);
    const [filteredSearchResults, setFilteredSearchResults] = React.useState<IRepertoireField[]>([]);
    const [presentCurrentPage, setPresentCurrentPage] = React.useState(1);
    const { instance, accounts } = useMsal();

    const componentInstance = SEARCH_VIEW_DRAFT_WORKS;

    React.useEffect(() => {
        setLoaded(true);
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])


    React.useEffect(() => {

        if (searchViewData && searchViewData.fields) {
            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === componentInstance
            );

            setFilteredFields(filteredSearchFields);
        }

        if (searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance
        
            );
            setFilteredSearchResults(filteredFields);
        }

    }, [searchViewData])

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        setPresentCurrentPage(currentPage);
    }

    const onSearch = (values: {
                draftWorkArtist?: string,
                draftWorkIPName?: string,
                draftWorkIPNumber: string,
                draftWorkNumber: string,
                draftWorkStatus: string,
                draftWorkTitle: string, 
    }) => {
        let searchQuery = {
            title: values.draftWorkTitle,
            status: values.draftWorkStatus,
            workNumber: values.draftWorkNumber,
            artist: values.draftWorkArtist,
            ipNumber: values.draftWorkIPNumber,
            ipName: values.draftWorkIPName,
            dataSource: dataSource,
            currentUserNameNumber: currentUserNameNumber,
        }
        searchDraftWorks(searchQuery);
    };

      
      const CustomInputComponent = ({
        field, 
        form: { touched, errors }, 
        ...props
      }) => (
        <div>
          <input type="text" {...field} {...props} />
          {touched[field.name] &&
            errors[field.name] && <div className="error">{errors[field.name]}</div>}
        </div>
      );

    const renderSearchFields = () => {
            let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
                let formField: React.ReactElement = <></>;

                if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal')) {

                    formField =  <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                     <label>{`${searchField.data}:`}</label>
                            <Field label={`${searchField.data}`} name={`${searchField.name}`} component={CustomInputComponent} />
                    </div>
                }
              
                if (searchField.fieldType === 'select_action' && searchField.name === 'draftWorkStatus' ) {
                    let options: { code: string, description: string }[] = [];
                    if (lookupValues && memberPortalSearchType.code === MemberPortalSearchType.AllRegisteredSearch.code) {
                        options.push({code: null, description: ""})
                        options.push(... getLookupValues(WORK_STATUS_LOOKUP, lookupValues))
                    }
                    else if (lookupValues && memberPortalSearchType.code === MemberPortalSearchType.MyDraftSearch.code){
                        options = [
                            { code: null, description: ""},
                            { code: "DRAFT", description: "Draft" },
                            { code: "DRAFTSUB", description: "Draft Submitted" }
                        ];
                    }
                    formField =
                        <div key={index} className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                            <label>{`${searchField.data}:`}</label>
                            <Field label={`${searchField.data}`} name={`${searchField.name}`} as="select" >
                            {options.map(selectableOption => <option value={selectableOption.code}>{selectableOption.description}</option >)}
                            </Field>
                        </div>
                }
                return formField;
            };
            
        
        return <FieldArray
                    name="draftWorkForm"
                    render={() => (
                    <div>
                        {filteredFields.length > 0 ? (
                        filteredFields.map(filteredSearchFieldsMapper)
                        ) : <>Loading...</>}
                    </div>
                    )}
      />    
    };

    const handleSorting = (result) => {
        sortSearchResults(SEARCH_VIEW_DRAFT_WORKS, result)
    }

    const rowActions = {
        iconButton: {
            icon: "icon ms-Icon ms-Icon--OpenInNewTab",
            onClick: (work) => {
                getDraftWorkDetails(dataSource, work.workID)
            }
        }
    }

    if (loaded && filteredFields && filteredFields.length > 0) {
        return (
            <div className="searchView">
                <div key='searchFields' className="row">
                <Formik
                    initialValues={{
                        draftWorkTitle: null,
                        draftWorkStatus: null,
                        draftWorkNumber: null,
                        draftWorkArtist: null,
                        draftWorkIPNumber: null,
                        draftWorkIPName: null,
                }}
                    onSubmit={(values, { setSubmitting }) => {
                        onSearch(values)
                        setSubmitting(false);
                    }}
                    >
       {({ isSubmitting, isValid, dirty }) => (
         <Form>
                {renderSearchFields()}
                <div key='action' className="row">
                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                        <ActionButtonFormik buttonText="Search" isSubmitting={isSubmitting} isErrorInForm={!isValid && dirty} />
                    </div>
                </div>
         </Form>
       )}
     </Formik>
                </div>
                    {searchSuccessful && <div key='results'>
                        <DisplayTableSearchResults 
                            searchSuccessful={searchSuccessful} 
                            searchResultsTableData={filteredSearchResults} 
                            searchResults={draftWorkSearchResults}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={presentCurrentPage}
                            updatePagination={updateUserPagination}
                            lookupValues={lookupValues}
                            resetPagination={resetPagination}
                            sortSearchResults={handleSorting}
                            rowActions={rowActions}
                        />
            </div>}
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default DraftWorksSearchView;