import React from "react";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import { IInstrumentation } from "../../../repertoire/types/IInstrumentation";
import { IArtist } from "../../../repertoire/types/IArtist";
import { IContributorItem } from "../../../repertoire/types/IContributorItem";
import { ITitle } from "../../../repertoire/types/ITitle";
import DisplayTableDataCells from "./DisplayTableDataCells";
import { IProductWorkDataItems } from "../../../repertoire/types/IProductWorkDataItems";
import { ILookupOptionsAndOnChangeFunctions } from "./DisplayTable";
import { IIPsSearchResult } from "../../types/IIPsSearchResult";

export interface IDisplayTableRowProps {
    tableContentItem: IIPsSearchResult;
    index: number;
    tableData: IRepertoireField[];
    showRowNumber?: boolean;
    isExpandable?: boolean;
    rowAction?: (item: IIPsSearchResult) => void;
    removeRowAction?: (item: IIPsSearchResult) => void;
    lookupOptionsAndOnChangeFunctions?: ILookupOptionsAndOnChangeFunctions;
    isSearchResultTable?: boolean;
    onClickOpen?: (tableContentItem: IIPsSearchResult) => void;
}

const DisplayTableRow: React.FC<IDisplayTableRowProps> = ({
    tableContentItem,
    index,
    tableData,
    showRowNumber,
    isExpandable,
    rowAction,
    removeRowAction,
    lookupOptionsAndOnChangeFunctions,
    isSearchResultTable,
    onClickOpen
}) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const onExpandClicked = () => {
        setIsExpanded(!isExpanded);
    }
    const tableDataCells: JSX.Element[] = [];
    if (isExpandable) {
        tableDataCells.push(
            <td className="td">
                <i
                    title="Expand row"
                    className={["icon ms-Icon ms-Icon--Chevron", isExpanded ? "Down" : "Right"].join("")}
                    onClick={onExpandClicked}
                />
            </td>
        );
    }
    if (showRowNumber) {
        tableDataCells.splice(0, 0, <td key={`${index} RowNumber`} title="Row Number"><div className={"tableCell"}>{index + 1}</div></td>);
    }
    tableData.map((field, i) => {
        const isOpenCell = i == 0;
        tableDataCells.push(
            <DisplayTableDataCells
                cellData={field}
                tableContentItem={tableContentItem}
                isExpanded={isExpanded}
                rowAction={rowAction}
                removeRowAction={removeRowAction}
                lookupOptionsAndOnChangeFunctions={lookupOptionsAndOnChangeFunctions}
                rowIndex={index}
                isSearchResultTable={isSearchResultTable}
                onClickOpen={onClickOpen}
                isOpenCell={isOpenCell}
            />
        );
    })
    return <tr key={`repertoire row ${index}`} className="tr tableRow">{tableDataCells}</tr>;
}

export default DisplayTableRow;