import React from "react";
import { ReactNode } from "react";
import { IJobParameterControlBase } from "./IJobParameterControlBase";

export class JobParameterControlCollection {
    private controlList: IJobParameterControlBase[] = []

    add(control: IJobParameterControlBase): void {
        if (control)
            this.controlList.push(control);
    }

    addRange(controls: IJobParameterControlBase[]): void {
        for (let i = 0; i < controls.length; i++)
            this.add(controls[i]);
    }

    Render(): JSX.Element {
        let reactNodes: ReactNode[] = []
        for (let i = 0; i < this.controlList.length; i++) {
            const element = this.controlList[i];
            reactNodes.push(
                <div key={i} className="form-group col-md-6 col-sm-6 col-xs-12">
                    {this.controlList[i].Render()}
                </div>
            )
        }

        return React.createElement(React.Fragment, null, reactNodes)
    }
}