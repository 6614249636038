import moment from 'moment';

export const isDateRangesOverLapping = (rangeA, rangeB) => {
    const isEffectiveFromDateLineA =  !!rangeA.effectiveFrom;
    const isEffectiveToDateLineA = !!rangeA.effectiveTo;
    const isEffectiveFromDateLineB = rangeB && !!rangeB.effectiveFrom;
    const isEffectiveToDateLineB = rangeB && !!rangeB.effectiveTo;
    if (isEffectiveFromDateLineA) {
      const momentEffectiveFromDateA = moment(rangeA.effectiveFrom); 
      if (isEffectiveToDateLineA) {
        const momentEffectiveToDateA = moment(rangeA.effectiveTo);
        if (isEffectiveFromDateLineB) {
          const dateBFrom = rangeB.effectiveFrom;
          if (isEffectiveToDateLineB) {
            const dateBTo = rangeB.effectiveTo;
            const rangeIsBeforeNextRange = momentEffectiveFromDateA.isBefore(dateBFrom) && momentEffectiveToDateA.isBefore(dateBFrom);
            const rangeIsAfterNextRange = momentEffectiveFromDateA.isAfter(dateBTo) && momentEffectiveToDateA.isAfter(dateBTo);
            const rangeEncompassNextRange = momentEffectiveFromDateA.isBefore(dateBFrom) && momentEffectiveToDateA.isAfter(dateBTo);
            const rangeIsEncompassByNextRange = momentEffectiveFromDateA.isAfter(dateBFrom) && momentEffectiveToDateA.isBefore(dateBTo);
            const rangeOverLappsStartOrEndDay = momentEffectiveFromDateA.isSame(dateBFrom, 'day') || momentEffectiveToDateA.isSame(dateBFrom, 'day') || momentEffectiveFromDateA.isSame(dateBTo, 'day') || momentEffectiveToDateA.isSame(dateBTo, 'day')
            return !((rangeIsBeforeNextRange || rangeIsAfterNextRange) && !rangeEncompassNextRange && !rangeIsEncompassByNextRange && !rangeOverLappsStartOrEndDay); 
          }
          const rangeOverLappsStartOrEndDay = momentEffectiveFromDateA.isSame(dateBFrom, 'day') || momentEffectiveToDateA.isSame(dateBFrom, 'day');
          const rangeIsBeforeNextRange = momentEffectiveFromDateA.isBefore(dateBFrom) && momentEffectiveToDateA.isBefore(dateBFrom);
          return !rangeIsBeforeNextRange || rangeOverLappsStartOrEndDay; 
        }
          return false;
      }
      if (isEffectiveFromDateLineB) {
        const dateBFrom = rangeB.effectiveFrom;
        if (isEffectiveToDateLineB) {
          const dateBTo = rangeB.effectiveTo;
          const rangeOverLappsStartOrEndDay = momentEffectiveFromDateA.isSame(dateBFrom, 'day') || momentEffectiveFromDateA.isSame(dateBTo, 'day');
          const rangeAIsAfterRangeB = momentEffectiveFromDateA.isAfter(dateBTo) && momentEffectiveFromDateA.isAfter(dateBFrom);
          return !rangeAIsAfterRangeB || rangeOverLappsStartOrEndDay;
        }
        return true;
      }
      return false
    }
    return false; 
  }