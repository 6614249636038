import { IUsagePoolState } from "../../redux/types/IUsagePoolState";
import { isDateRangesOverLapping } from "../pools/components/poolMaintenanceGridsView/utils";
import { IUsageWeightSettingsDataItems } from "../types/usageTypes/IUsageWeightSettingsDataItems";

export class UsagePoolValidator {

    public static validateUsagePool(usage: IUsagePoolState): string[] {
        let usagePoolIsValid: string[];
        usagePoolIsValid = [];
        usage.poolWeightSettings.map(element => {
            if (Boolean(element.effectiveDateRange.value) === false) {
                usagePoolIsValid.push("Effective Date Range field cannot be empty")
            }
            if (Boolean(element.weightingCode.value) === false) {
                usagePoolIsValid.push("Weighing Code field cannot be empty")
            }
        });
        usage.poolEstimatedValuePerPointSettings.map(element=>{
          if (Boolean(element.evpp.value) === false) {
            usagePoolIsValid.push("EVPP field cannot be empty")
          }
          if (Boolean(element.effectiveFrom.value) === false) {
            usagePoolIsValid.push("EVPP Effective From field cannot be empty")
          }
          if(Boolean(isNaN(parseInt(element.evpp.value)))===true && Boolean(element.evpp.value) === true){
            usagePoolIsValid.push("EVPP field cannot be string")
          }
          if(!isNaN(parseInt(element.evpp.value))&&parseInt(element.evpp.value)<0){
            usagePoolIsValid.push("EVPP value should be greater than 0")
          }
        })
       

          function areAllObjectsUnique(arr:IUsageWeightSettingsDataItems[], ignoreProps:string[]):boolean {
            const seen = new Set();
          
            for (const obj of arr) {
              
              const uniqueKey = JSON.stringify(
                Object.keys(obj)
                  .filter(key => !ignoreProps.includes(key))
                  .sort()
                  .reduce((acc, key) => {
                    acc[key] = obj[key];
                    return acc;
                  }, {})
              );
          
              if (seen.has(uniqueKey)) {
                return false; 
              }
          
              seen.add(uniqueKey);
            }
          
            return true;
          }       
          
        
          const ignoreProperties = ['weightMultiplier', 'weightingCode','copySection','poolWeightMultiplierID','poolID'];

          function timeToMinutes(time) {
            if (time){
              var splitTime = time.split(':');
              return parseInt(splitTime[0]) * 60 + parseInt(splitTime[1]);
            }
            
          }
          function checkUniqueTimeRange(arr:IUsageWeightSettingsDataItems[]):boolean{
          for (let i = 0; i < arr.length; i++) {
           if(arr[i]?.effectiveDateRange.value===arr[i+1]?.effectiveDateRange.value){
            if(arr[i]?.musicSource.value===arr[i+1]?.musicSource.value && arr[i]?.use.value===arr[i+1]?.use.value 
              && arr[i]?.usageDimensionOne.value===arr[i+1]?.usageDimensionOne.value 
              && arr[i]?.usageDimensionTwo.value===arr[i+1]?.usageDimensionTwo.value
              && arr[i]?.usageDimensionThree.value===arr[i+1]?.usageDimensionThree.value 
              && arr[i]?.usageDimensionFour.value===arr[i+1]?.usageDimensionFour.value
              && arr[i]?.usageDimensionFive.value===arr[i+1]?.usageDimensionFive.value ){
              var startTime = arr[i]?.fromTime.value;
            var endTime = arr[i]?.toTime.value;
            var checkTime = arr[i+1]?.fromTime.value;
            
            var startMinutes = timeToMinutes(startTime);
            var endMinutes = timeToMinutes(endTime);
            var checkMinutes = timeToMinutes(checkTime);
            
            if (checkMinutes >= startMinutes && checkMinutes <= endMinutes) {
              return true;
            } 
            }            
           }
          }
          return false;
          }

          function checKFromTimeValidation(arr:IUsageWeightSettingsDataItems[]):boolean{
          for (let i = 0; i < arr.length; i++) {
            var startTime = arr[i]?.fromTime.value;
            var endTime = arr[i]?.toTime.value;
            
            var startMinutes = timeToMinutes(startTime);
            var endMinutes = timeToMinutes(endTime);
            
            if (startMinutes >= endMinutes) {
              return true;
            } 
          }
          return false;
          }

          if (!areAllObjectsUnique(usage.poolWeightSettings, ignoreProperties)) {
              usagePoolIsValid.push("You can not have the same combination of dimension values (including Music Source, Use and Time Ranges) configured more than once effective at the same time")
            } 
        
          if(checkUniqueTimeRange(usage.poolWeightSettings) && areAllObjectsUnique(usage.poolWeightSettings, ignoreProperties)){
            usagePoolIsValid.push("You can not have the same combination of dimension values (including Music Source, Use and Time Ranges) configured more than once effective at the same time")
          }
          if(checKFromTimeValidation(usage.poolWeightSettings)){
            usagePoolIsValid.push("From Time cannot be greater than To Time")
          }
          
        function checkOverLappingDates(evppDates){
          var dateRanges=evppDates.map(element=>{return {effectiveFrom:element.effectiveFrom.value,effectiveTo:element.effectiveTo.value}});
          const overlappingLineItem =  dateRanges.find((lineItem, index) => {
            return isDateRangesOverLapping(lineItem, dateRanges[index + 1])
        })
        return overlappingLineItem;
          }

        function checkDateValidation(evppDates){
          var dateRanges=evppDates.map(element=>{return { effectiveFrom:element.effectiveFrom.value, effectiveTo:element.effectiveTo.value }});
          dateRanges.map(element=>{
            var fromDate=element.effectiveFrom;
            var toDate=element.effectiveTo;
              if(fromDate&&toDate&&fromDate>toDate){
                usagePoolIsValid.push("From date can't be before to date")
              }
          })  
          }

        if(checkOverLappingDates(usage.poolEstimatedValuePerPointSettings)){
          usagePoolIsValid.push("Effective Date Ranges Overlap")
          }
        checkDateValidation(usage.poolEstimatedValuePerPointSettings);
       
          
          
        return usagePoolIsValid;
    }
}