import React, { RefObject } from "react";
import throttle from "lodash.throttle";

export interface ICarouselScrollButtonProps {
    content: any;
    distanceToScroll: number;
}

export interface ICarouselState {
    isOverflow: boolean;
}

const CarouselScrollButton = ({
    content, 
    distanceToScroll,
}) => {

    let carouselContentRef: RefObject<HTMLDivElement> = React.createRef();
    let continuousScrollInterval = 300;

    const [isOverflow, setIsOverflow] = React.useState(false);
    const [continuousScrollTimeout, setContinuousScrollTimeout] = React.useState();

    React.useEffect(() => {
        checkIsOverflow();
        window.addEventListener("resize", throttle(checkIsOverflow, 300));

        return window.removeEventListener("resize", throttle(checkIsOverflow, 300));
    }, [])

    React.useEffect(() => {
        checkIsOverflow();
    }, [content, distanceToScroll])


    const scrollHorizontal = (
        element: Element,
        change: number,
        duration: number
    ): void => {
        let start = element.scrollLeft;
        let currentTime = 0;
        let increment = 20;

        let animateScroll = () => {
            currentTime += increment;
            let scrollVal = linearEase(currentTime, start, change, duration);
            element.scrollLeft = scrollVal;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };

    // easing fn for scrolling
    const linearEase = (
        time: number,
        startVal: number,
        change: number,
        duration: number
    ) => {
        return (change * time) / duration + startVal;
    };

    const handleButtonHeld = (direction: string, event: any): void => {
        let scrollDistance = distanceToScroll;

        if (direction === "left") scrollDistance = -scrollDistance;
        scrollHorizontal(carouselContentRef.current, scrollDistance, 300);
        let continuousScrollTimeoutLocal;
        // Keep scrolling while mouse held down
        continuousScrollTimeoutLocal = setTimeout(
            handleButtonHeld,
            continuousScrollInterval,
            direction,
            event
        );

        setContinuousScrollTimeout(continuousScrollTimeoutLocal)
    };

    const handleButtonReleased = () => {
        clearTimeout(continuousScrollTimeout);
    };

    const getButtonDirectionStyle = (direction: string) => {
        return direction === "left" ? "leftScrollButton" : "rightScrollButton";
    };

    const getButtonContainerStyle = (direction: string) => {
        return direction === "left"
            ? ["buttonContainer", "leftShadow"].join(" ")
            : ["buttonContainer", "rightShadow"].join(" ");
    };

    const checkIsOverflow = () => {
        if (carouselContentRef.current) {
            const { scrollWidth, clientWidth } = carouselContentRef.current;
            const isOverFlowLocal = scrollWidth > clientWidth;
            setIsOverflow(isOverFlowLocal)
        }
    };

    const renderButton = (direction: string) => {
        if (isOverflow) {
            return (
                <button
                    className={getButtonContainerStyle(direction)}
                    onMouseDown={e => handleButtonHeld(direction, e)}
                    onMouseUp={handleButtonReleased}
                    onMouseOut={handleButtonReleased}
                >
                    <img
                        className={getButtonDirectionStyle(direction)}
                        src="assets/right-arrow.svg"
                        title={direction === "left" ? "Scroll left" : "Scroll right"}
                        alt={direction === "left" ? "left arrow" : "right arrow"}
                    />
                </button>
            );
        }
        return null;
    }

        return (
            <div className="CarouselScrollTabs">
                <div className="CarouselContainer">
                    {renderButton("left")}
                    <div ref={carouselContentRef} className="CarouselContents">
                        {content}
                    </div>
                    {renderButton("right")}
                </div>
            </div>
        );
}

export default CarouselScrollButton;
