import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    USAGE_GROUP_TITLE_STATE_KEY, USAGE_GROUP_ARTIST_STATE_KEY, USAGE_GROUP_COMPOSER_STATE_KEY, USAGE_GROUP_MATCH_STATUS_STATE_KEY,
    USAGE_GROUP_ALLOCATION_STATUS_STATE_KEY, USAGE_GROUP_SOURCE_MAJOR_STATE_KEY, USAGE_GROUP_SOURCE_MINOR_STATE_KEY, USAGE_GROUP_DISTRIBUTION_STATE_KEY,
    USAGE_GROUP_LICENSEE_WORK_NUMBER_STATE_KEY, USAGE_GROUP_USAGE_WEIGHT_STATE_KEY, USAGE_GROUP_USAGE_WEIGHT_CATEGORY_STATE_KEY, TITLE_EDITABLE_FIELDS_VIEW, EDITABLE_FIELDS,
    GENERAL_DATA_EDITABLE_FIELDS, USAGE_MAINTENANCE_GENERAL_DATA_VIEW, USAGE_GROUP_DISTRIBUTION_STATUS_STATE_KEY, USAGE_GROUP_DISTRIBUTION_DATE_STATE_KEY, EMPTY_STRING_VALUE, CONFIGURE_USAGES_ROLE, ALL_ROLES, USAGE_GROUP_PRODUCT_TYPE_STATE_KEY, SEARCH_VIEW, AGREEMENTS_IP_KEY,
    LOCATION_SECTION_TITLE, LOCATION_GOOGLE_VENUE, LOCATION_KNOWN_MUSIC_VENUE, LOCATION_COUNTRY, LOCATION_CITY, LOCATION_VENUE, LOCATION_EVENT_NAME, LOCATION_ADD_DETAILS_MANUALLY, SET_LIST_ADDITIONAL_SECTION_TITLE, SET_LIST_ADDITIONAL_PERFORMANCE_TYPE, SET_LIST_ADDITIONAL_STAGE, 
    SET_LIST_ADDITIONAL_PROMOTER, SET_LIST_ADDITIONAL_OTHER_ARTIST_PERFORM, VENUE_DETAILS_TITLE, VENUE_DETAILS_CONTACT_FIRST_NAME, VENUE_DETAILS_CONTACT_LAST_NAME, VENUE_DETAILS_CONTACT_PHONE_NUMBER, VENUE_DETAILS_VENUE_TYPE, VENUE_DETAILS_OTHER_VENUE_TYPE, USAGE_GROUP_APPROVAL_STATUS_STATE_KEY, USAGE_GROUP_POOL_CODE_STATE_KEY, USAGE_GROUP_QA_STATUS_STATE_KEY, USAGE_GROUP_REJECT_REASON_STATE_KEY
} from "../../../Consts";
import { showUpdateFieldsModal, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { SizedTextDisplay } from "../../../../core/components/textDisplay/TextDisplay";
import { setEditableFields, updateEditableFields, updateUsageField } from "../../../../redux/reducers/RepertoireReducer";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { stringify } from "querystring";
import { ITreeData } from "../../../types/ITreeData";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IUsageGroupStateKeys } from "../../../../redux/types/IUsageGroupState";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { getLookupValues, searchLookupValueByCode } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { USAGEMATCHSTATUS_LOOKUP, USAGE_GROUP_APPROVAL_STATUS_LOOKUP, USAGE_GROUP_QA_STATUS_LOOKUP, REJECT_REASON_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { IUsageAllocations } from "../../../types/usageTypes/IUsageAllocations";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import IconTextButton from "../../../components/iconTextButton/IconTextButton";
import { IUsageGroupLocation } from "../../../types/usageTypes/IUsageGroupLocation";
import { IUsageGroupSetListAdditional } from "../../../types/usageTypes/IUsageGroupSetListAdditional";
import { SizedTextDataInput } from "../../../components/usageComponents/textInput/TextInput";
import { IUsageGroupVenueDetails } from "../../../types/usageTypes/IUsageGroupVenueDetails";

export interface IUsageMaintenanceGeneralDataViewProps {
    usageMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
    title: string;
    composer: string;
    artist: string;
    distributionCode: string;
    distributions: IDistribution[];
    sourceMajor: string,
    sourceMinor: string,
    usageType: string,
    matchStatus: string,
    allocationStatus: string,
    allocations: IUsageAllocations[],
    licenseesWorkNumber: string,
    activeTab?: number;
    tabs?: ITabReduxItem[];
    isReadonly: boolean;
    usageWeight: number;
    weightCategory: string;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    hideModal?: typeof hideModal;
    showModal?: typeof showModal;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    setEditableFields: typeof setEditableFields;
    lookupSources: ITreeData[];
    updateUsageField: typeof updateUsageField;
    lookupValues: ILookupDictionary;
    roles?: string[];
    productType: string;
    location?: IUsageGroupLocation;
    setListAdditional?: IUsageGroupSetListAdditional;
    updateLocation?: (newLocation: IUsageGroupLocation) => void;
    updateSetListAdditional?: (newSetListAdditional: IUsageGroupSetListAdditional) => void;
    approvalStatus?: string;
    enableApprovalStatusEdit?: boolean;
    venueDetails?: IUsageGroupVenueDetails;
    updateUsageGroupVenueDetails?: (newVenueDetails: IUsageGroupVenueDetails) => void;
    qaStatus?: string;
    rejectReason?: string;
    poolCode?:string;

}

interface IUsageMaintenanceGeneralDataViewState {
    loaded: boolean;
    enableEditLocation: boolean;
    newLocationValue: IUsageGroupLocation;
    enableEditSetListAdditional: boolean;
    newSetListAdditionalValue: IUsageGroupSetListAdditional;
    enableEditVenueDetails: boolean;
    newVenueDetailsValue: IUsageGroupVenueDetails;
}

export default class UsageMaintenanceGeneralDataView extends React.PureComponent<
    IUsageMaintenanceGeneralDataViewProps,
    IUsageMaintenanceGeneralDataViewState> {
    viewData;
    constructor(props: IUsageMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            loaded: false,
            enableEditLocation: false,
            newLocationValue: {
                ...props.location
            },
            enableEditSetListAdditional: false,
            newSetListAdditionalValue: {
                ...props.setListAdditional
            },
            enableEditVenueDetails: false,
            newVenueDetailsValue: {
                ...props.venueDetails
            }
        };
        this.viewData = {};
    }


    componentDidMount() {
        const {
            usageMaintenanceGeneralDataViewData: { fields },
            usageType
        } = this.props;

        if (fields) {
            const filteredFields = fields.filter(f => f.componentInstance === usageType);
            const visibleFields = filteredFields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }

    }


    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab, usageType } = this.props;
        updateComponentFieldsItems(editableFields["default"], USAGE_MAINTENANCE_GENERAL_DATA_VIEW, usageType, USAGE_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableData,
            setEditableFields,
            usageType
        } = this.props;

        setEditableFields(USAGE_MAINTENANCE_GENERAL_DATA_VIEW, usageType)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    getSourceHierarchy(sourceMajor: string, sourceMinor: string) {
        return `${sourceMajor && sourceMajor}${sourceMinor && `/${sourceMinor}`}`;
    }

    renderUsageInfo = (changeData : any, distribution: any) => {

        const {
            allocations,
            sourceMajor,
            sourceMinor,
            usageWeight,
            weightCategory,
            distributionCode,
            licenseesWorkNumber,
            lookupValues,
            isReadonly,
            matchStatus,
            qaStatus,
            rejectReason,
            poolCode
        } = this.props

        return(
            <div className="row flexWrapRow">
                
                    {<SizedDropdownDataInput
                        label={this.viewData[USAGE_GROUP_MATCH_STATUS_STATE_KEY]}
                        fieldName={USAGE_GROUP_MATCH_STATUS_STATE_KEY}
                        value={matchStatus}
                        changeData={changeData}
                        options={getLookupValues(USAGEMATCHSTATUS_LOOKUP, lookupValues)}
                        allowNull={false}
                        readOnly={isReadonly}
                        isHidden={!this.viewData[USAGE_GROUP_MATCH_STATUS_STATE_KEY]} />}

                {
                    this.props.enableApprovalStatusEdit ?
                        <SizedDropdownDataInput
                            label={this.viewData[USAGE_GROUP_APPROVAL_STATUS_STATE_KEY]}
                            fieldName={USAGE_GROUP_APPROVAL_STATUS_STATE_KEY}
                            value={this.props.approvalStatus}
                            changeData={changeData}
                            options={getLookupValues(USAGE_GROUP_APPROVAL_STATUS_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={isReadonly}
                            isHidden={!this.viewData[USAGE_GROUP_APPROVAL_STATUS_STATE_KEY]} /> :
                        <SizedTextDisplay
                            label={this.viewData[USAGE_GROUP_APPROVAL_STATUS_STATE_KEY]}
                            fieldName={USAGE_GROUP_APPROVAL_STATUS_STATE_KEY}
                            value={this.props.approvalStatus ? this.props.approvalStatus : 'N/A'}
                            isHidden={false}
                        />
                }

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_ALLOCATION_STATUS_STATE_KEY]}
                    fieldName={USAGE_GROUP_ALLOCATION_STATUS_STATE_KEY}
                    value={allocations != null && allocations.length > 0 && allocations.some(x => x.allocationStatus == 'Allocated') ? 'Allocated' : 'Not Allocated'}
                    isHidden={!this.viewData[USAGE_GROUP_ALLOCATION_STATUS_STATE_KEY]}
                />}

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_SOURCE_MAJOR_STATE_KEY]}
                    fieldName={USAGE_GROUP_SOURCE_MAJOR_STATE_KEY}
                    value={(sourceMajor && sourceMinor) ? (this.getSourceHierarchy(sourceMajor, sourceMinor)).replace("Global/", ""): ""}
                    isHidden={!this.viewData[USAGE_GROUP_SOURCE_MAJOR_STATE_KEY]}
                />}

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_USAGE_WEIGHT_STATE_KEY]}
                    fieldName={USAGE_GROUP_USAGE_WEIGHT_STATE_KEY}
                    value={usageWeight}
                    isHidden={!this.viewData[USAGE_GROUP_USAGE_WEIGHT_STATE_KEY]}
                />}

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_USAGE_WEIGHT_CATEGORY_STATE_KEY]}
                    fieldName={USAGE_GROUP_USAGE_WEIGHT_CATEGORY_STATE_KEY}
                    value={weightCategory}
                    isHidden={!this.viewData[USAGE_GROUP_USAGE_WEIGHT_CATEGORY_STATE_KEY]}
                />}

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_DISTRIBUTION_STATE_KEY]}
                    fieldName={USAGE_GROUP_DISTRIBUTION_STATE_KEY}
                    value={distributionCode}
                    isHidden={!this.viewData[USAGE_GROUP_DISTRIBUTION_STATE_KEY]}
                />}

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_POOL_CODE_STATE_KEY]}
                    fieldName={USAGE_GROUP_POOL_CODE_STATE_KEY}
                    value={poolCode }
                    isHidden={!this.viewData[USAGE_GROUP_POOL_CODE_STATE_KEY]}
                />}

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_LICENSEE_WORK_NUMBER_STATE_KEY]}
                    fieldName={USAGE_GROUP_LICENSEE_WORK_NUMBER_STATE_KEY}
                    value={licenseesWorkNumber}
                    isHidden={!this.viewData[USAGE_GROUP_LICENSEE_WORK_NUMBER_STATE_KEY]}
                />}

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_DISTRIBUTION_STATUS_STATE_KEY]}
                    fieldName={USAGE_GROUP_DISTRIBUTION_STATUS_STATE_KEY}
                    value={distribution != null && distribution.distributionStatus}
                    isHidden={!this.viewData[USAGE_GROUP_DISTRIBUTION_STATUS_STATE_KEY]}
                />}

                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_DISTRIBUTION_DATE_STATE_KEY]}
                    fieldName={USAGE_GROUP_DISTRIBUTION_DATE_STATE_KEY}
                    value={distribution != null && distribution.distributionDate != null ? 
                        new Date(distribution.distributionDate).getFullYear() : EMPTY_STRING_VALUE }
                    isHidden={!this.viewData[USAGE_GROUP_DISTRIBUTION_DATE_STATE_KEY]}
                />}

                {<SizedDropdownDataInput
                    label={this.viewData[USAGE_GROUP_QA_STATUS_STATE_KEY]}
                    fieldName={USAGE_GROUP_QA_STATUS_STATE_KEY}
                    value={qaStatus}
                    changeData={changeData}
                    options={getLookupValues(USAGE_GROUP_QA_STATUS_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[USAGE_GROUP_QA_STATUS_STATE_KEY]}
                />}
                
                {<SizedTextDisplay
                    label={this.viewData[USAGE_GROUP_REJECT_REASON_STATE_KEY]}
                    fieldName={USAGE_GROUP_REJECT_REASON_STATE_KEY}
                    value={searchLookupValueByCode(REJECT_REASON_TYPE_LOOKUP, lookupValues, rejectReason)}
                    isHidden={!this.viewData[USAGE_GROUP_REJECT_REASON_STATE_KEY]}
                />}
            </div>
        )
    }

    render() {
        const {
            title,
            composer,
            artist,
            distributions,
            distributionCode,
            usageType,
            productType,
            showModal,
            location,
            setListAdditional,
            venueDetails
        } = this.props;

        const distribution: IDistribution = distributions.find((d) => d.distributionCode === distributionCode);
        const isLivePerformanceUsage  = distribution?.isLivePerformancePortal;       

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            this.props.updateUsageField(value, name as IUsageGroupStateKeys);
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_USAGES_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        return (
            <>
                <div>
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">Usage Group</h1>
                            <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                    {loaded ? (
                        <>
                            <div className="row">
                                {<SizedTextDisplay
                                    label={this.viewData[USAGE_GROUP_TITLE_STATE_KEY]}
                                    fieldName={USAGE_GROUP_TITLE_STATE_KEY}
                                    value={title}
                                    isHidden={!this.viewData[USAGE_GROUP_TITLE_STATE_KEY]}
                                />}

                                {<SizedTextDisplay
                                    label={this.viewData[USAGE_GROUP_COMPOSER_STATE_KEY]}
                                    fieldName={USAGE_GROUP_COMPOSER_STATE_KEY}
                                    value={composer}
                                    isHidden={!this.viewData[USAGE_GROUP_COMPOSER_STATE_KEY]}
                                />}

                                {<SizedTextDisplay
                                    label={this.viewData[USAGE_GROUP_ARTIST_STATE_KEY]}
                                    fieldName={USAGE_GROUP_ARTIST_STATE_KEY}
                                    value={artist}
                                    isHidden={!this.viewData[USAGE_GROUP_ARTIST_STATE_KEY]}
                                />}                     
                                    
                                { usageType === "UsageToProduct" &&
                                    <SizedTextDisplay
                                        label={this.viewData[USAGE_GROUP_PRODUCT_TYPE_STATE_KEY]}
                                        fieldName={USAGE_GROUP_PRODUCT_TYPE_STATE_KEY}
                                        value={productType}
                                        isHidden={!this.viewData[USAGE_GROUP_PRODUCT_TYPE_STATE_KEY]}
                                    />
                                }

                            </div>

                            {productType == 'SET LIST' && 
                                <>
                            <hr /> 
                            {isLivePerformanceUsage && (<div><h3>
                                <div className="flexContent flexGapSm">
                                    {this.viewData[LOCATION_SECTION_TITLE]} 
                                    <IconTextButton
                                        text={""}
                                        icon={`icon ms-Icon ms-Icon--${this.state.enableEditLocation ? 'AcceptMedium' : 'Edit'}`}
                                        onClick={() => {
                                            if (this.state.enableEditLocation)
                                                this.props.updateLocation(this.state.newLocationValue)

                                            this.setState({
                                                ...this.state,
                                                enableEditLocation: !this.state.enableEditLocation
                                            })
                                        }}
                                    />
                                </div>
                            </h3>
                            <div className="row">
                                {
                                    this.state.enableEditLocation ? 
                                        <SizedTextDataInput
                                            label={this.viewData[LOCATION_GOOGLE_VENUE]}
                                            fieldName={LOCATION_GOOGLE_VENUE}
                                            value={this.state.newLocationValue.googleVenue}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newLocationValue: {
                                                        ...this.state.newLocationValue,
                                                        googleVenue: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[LOCATION_GOOGLE_VENUE]}
                                            fieldName={LOCATION_GOOGLE_VENUE}
                                            value={(location || { googleVenue: null }).googleVenue}
                                            isHidden={false}
                                        />
                                }
                                
                                {
                                    this.state.enableEditLocation ? 
                                        <SizedTextDataInput
                                            label={this.viewData[LOCATION_KNOWN_MUSIC_VENUE]}
                                            fieldName={LOCATION_KNOWN_MUSIC_VENUE}
                                            value={this.state.newLocationValue.knownMusicVenue}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newLocationValue: {
                                                        ...this.state.newLocationValue,
                                                        knownMusicVenue: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[LOCATION_KNOWN_MUSIC_VENUE]}
                                            fieldName={LOCATION_KNOWN_MUSIC_VENUE}
                                            value={(location || {knownMusicVenue: null}).knownMusicVenue}
                                            isHidden={false}
                                        />
                                }
                                
                                {
                                    this.state.enableEditLocation ? 
                                        <SizedTextDataInput
                                            label={this.viewData[LOCATION_COUNTRY]}
                                            fieldName={LOCATION_COUNTRY}
                                            value={this.state.newLocationValue.country}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newLocationValue: {
                                                        ...this.state.newLocationValue,
                                                        country: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[LOCATION_COUNTRY]}
                                            fieldName={LOCATION_COUNTRY}
                                            value={(location || {country: null}).country}
                                            isHidden={false}
                                        />
                                }
                                
                                {
                                    this.state.enableEditLocation ? 
                                        <SizedTextDataInput
                                            label={this.viewData[LOCATION_CITY]}
                                            fieldName={LOCATION_CITY}
                                            value={this.state.newLocationValue.city}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newLocationValue: {
                                                        ...this.state.newLocationValue,
                                                        city: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[LOCATION_CITY]}
                                            fieldName={LOCATION_CITY}
                                            value={(location || {city: null}).city}
                                            isHidden={false}
                                        />
                                }
                                
                                {
                                    this.state.enableEditLocation ? 
                                        <SizedTextDataInput
                                            label={this.viewData[LOCATION_VENUE]}
                                            fieldName={LOCATION_VENUE}
                                            value={this.state.newLocationValue.venue}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newLocationValue: {
                                                        ...this.state.newLocationValue,
                                                        venue: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[LOCATION_VENUE]}
                                            fieldName={LOCATION_VENUE}
                                            value={(location || {venue: null}).venue}
                                            isHidden={false}
                                        />
                                }
                                
                                {
                                    this.state.enableEditLocation ? 
                                        <SizedTextDataInput
                                            label={this.viewData[LOCATION_EVENT_NAME]}
                                            fieldName={LOCATION_EVENT_NAME}
                                            value={this.state.newLocationValue.eventName}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newLocationValue: {
                                                        ...this.state.newLocationValue,
                                                        eventName: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[LOCATION_EVENT_NAME]}
                                            fieldName={LOCATION_EVENT_NAME}
                                            value={(location || {location: null}).eventName}
                                            isHidden={false}
                                        />
                                }

                                {
                                    this.state.enableEditLocation ? 
                                        <SizedCheckboxDataInput
                                            label={this.viewData[LOCATION_ADD_DETAILS_MANUALLY]}
                                            fieldName={LOCATION_ADD_DETAILS_MANUALLY}
                                            value={this.state.newLocationValue.addDetailsManually}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newLocationValue: {
                                                        ...this.state.newLocationValue,
                                                        addDetailsManually: value as boolean
                                                    }
                                                }) 
                                            } }
                                            inline={true}                                
                                        /> :
                                        <SizedCheckboxDataInput
                                            label={this.viewData[LOCATION_ADD_DETAILS_MANUALLY]}
                                            fieldName={LOCATION_ADD_DETAILS_MANUALLY}
                                            value={(location || {addDetailsManually: null}).addDetailsManually}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string): void => {}}
                                            inline={true}
                                        />
                                }
                            </div>
                            <hr /> </div>)}
                            {isLivePerformanceUsage && (<div><h3>
                                <div className="flexContent flexGapSm">
                                    {this.viewData[SET_LIST_ADDITIONAL_SECTION_TITLE]} 
                                    <IconTextButton
                                        text={""}
                                        icon={`icon ms-Icon ms-Icon--${this.state.enableEditSetListAdditional ? 'AcceptMedium' : 'Edit'}`}
                                        onClick={() => {
                                            if (this.state.enableEditSetListAdditional)
                                                this.props.updateSetListAdditional(this.state.newSetListAdditionalValue)

                                            this.setState({
                                                ...this.state,
                                                enableEditSetListAdditional: !this.state.enableEditSetListAdditional
                                            })
                                        }}
                                    />
                                </div>
                            </h3>
                            <div className="row">
                                {
                                    this.state.enableEditSetListAdditional ? 
                                        <SizedTextDataInput
                                            label={this.viewData[SET_LIST_ADDITIONAL_PERFORMANCE_TYPE]}
                                            fieldName={SET_LIST_ADDITIONAL_PERFORMANCE_TYPE}
                                            value={this.state.newSetListAdditionalValue.performanceType}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newSetListAdditionalValue: {
                                                        ...this.state.newSetListAdditionalValue,
                                                        performanceType: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[SET_LIST_ADDITIONAL_PERFORMANCE_TYPE]}
                                            fieldName={SET_LIST_ADDITIONAL_PERFORMANCE_TYPE}
                                            value={(setListAdditional || {performanceType: null}).performanceType}
                                            isHidden={false}
                                        />
                                }
                                {
                                    this.state.enableEditSetListAdditional ? 
                                        <SizedTextDataInput
                                            label={this.viewData[SET_LIST_ADDITIONAL_STAGE]}
                                            fieldName={SET_LIST_ADDITIONAL_STAGE}
                                            value={this.state.newSetListAdditionalValue.stage}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newSetListAdditionalValue: {
                                                        ...this.state.newSetListAdditionalValue,
                                                        stage: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[SET_LIST_ADDITIONAL_STAGE]}
                                            fieldName={SET_LIST_ADDITIONAL_STAGE}
                                            value={(setListAdditional || {stage: null}).stage}
                                            isHidden={false}
                                        />
                                }
                                {
                                    this.state.enableEditSetListAdditional ? 
                                        <SizedTextDataInput
                                            label={this.viewData[SET_LIST_ADDITIONAL_PROMOTER]}
                                            fieldName={SET_LIST_ADDITIONAL_PROMOTER}
                                            value={this.state.newSetListAdditionalValue.promoter}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newSetListAdditionalValue: {
                                                        ...this.state.newSetListAdditionalValue,
                                                        promoter: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[SET_LIST_ADDITIONAL_PROMOTER]}
                                            fieldName={SET_LIST_ADDITIONAL_PROMOTER}
                                            value={(setListAdditional || {promoter: null}).promoter}
                                            isHidden={false}
                                        />
                                }
                                {
                                    this.state.enableEditSetListAdditional ? 
                                        <SizedCheckboxDataInput
                                            label={this.viewData[SET_LIST_ADDITIONAL_OTHER_ARTIST_PERFORM]}
                                            fieldName={SET_LIST_ADDITIONAL_OTHER_ARTIST_PERFORM}
                                            value={this.state.newSetListAdditionalValue.otherArtistPerform}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newSetListAdditionalValue: {
                                                        ...this.state.newSetListAdditionalValue,
                                                        otherArtistPerform: value as boolean
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedCheckboxDataInput
                                            label={this.viewData[SET_LIST_ADDITIONAL_OTHER_ARTIST_PERFORM]}
                                            fieldName={SET_LIST_ADDITIONAL_OTHER_ARTIST_PERFORM}
                                            value={(setListAdditional || { otherArtistPerform: null }).otherArtistPerform}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string): void => {} }                                
                                        />
                                }
                            </div></div>)}
                            
                            
                            <hr /></> }
                            {isLivePerformanceUsage && (<div><h3>
                                <div className="flexContent flexGapSm">
                                    {this.viewData[VENUE_DETAILS_TITLE]} 
                                    <IconTextButton
                                        text={""}
                                        icon={`icon ms-Icon ms-Icon--${this.state.enableEditVenueDetails ? 'AcceptMedium' : 'Edit'}`}
                                        onClick={() => {
                                            if (this.state.enableEditVenueDetails)
                                                this.props.updateUsageGroupVenueDetails(this.state.newVenueDetailsValue)

                                            this.setState({
                                                ...this.state,
                                                enableEditVenueDetails: !this.state.enableEditVenueDetails
                                            })
                                        }}
                                    />
                                </div>
                            </h3>
                            <div className="row">
                                {
                                    this.state.enableEditVenueDetails ? 
                                        <SizedTextDataInput
                                            label={this.viewData[VENUE_DETAILS_CONTACT_FIRST_NAME]}
                                            fieldName={VENUE_DETAILS_CONTACT_FIRST_NAME}
                                            value={this.state.newVenueDetailsValue.firstName}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newVenueDetailsValue: {
                                                        ...this.state.newVenueDetailsValue,
                                                        firstName: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[VENUE_DETAILS_CONTACT_FIRST_NAME]}
                                            fieldName={VENUE_DETAILS_CONTACT_FIRST_NAME}
                                            value={(venueDetails || {firstName: null}).firstName}
                                            isHidden={false}
                                        />
                                }
                                {
                                    this.state.enableEditVenueDetails ? 
                                        <SizedTextDataInput
                                            label={this.viewData[VENUE_DETAILS_CONTACT_LAST_NAME]}
                                            fieldName={VENUE_DETAILS_CONTACT_LAST_NAME}
                                            value={this.state.newVenueDetailsValue.lastName}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newVenueDetailsValue: {
                                                        ...this.state.newVenueDetailsValue,
                                                        lastName: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[VENUE_DETAILS_CONTACT_LAST_NAME]}
                                            fieldName={VENUE_DETAILS_CONTACT_LAST_NAME}
                                            value={(venueDetails || {lastName: null}).lastName}
                                            isHidden={false}
                                        />
                                }
                                {
                                    this.state.enableEditVenueDetails ? 
                                        <SizedTextDataInput
                                            label={this.viewData[VENUE_DETAILS_CONTACT_PHONE_NUMBER]}
                                            fieldName={VENUE_DETAILS_CONTACT_PHONE_NUMBER}
                                            value={this.state.newVenueDetailsValue.phoneNumber}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newVenueDetailsValue: {
                                                        ...this.state.newVenueDetailsValue,
                                                        phoneNumber: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedTextDisplay
                                            label={this.viewData[VENUE_DETAILS_CONTACT_PHONE_NUMBER]}
                                            fieldName={VENUE_DETAILS_CONTACT_PHONE_NUMBER}
                                            value={(venueDetails || {phoneNumber: null}).phoneNumber}
                                            isHidden={false}
                                        />
                                }
                                {
                                    this.state.enableEditVenueDetails ? 
                                        <SizedCheckboxDataInput
                                            label={this.viewData[VENUE_DETAILS_VENUE_TYPE]}
                                            fieldName={VENUE_DETAILS_VENUE_TYPE}
                                            value={this.state.newVenueDetailsValue.venueType}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newVenueDetailsValue: {
                                                        ...this.state.newVenueDetailsValue,
                                                        venueType: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedCheckboxDataInput
                                            label={this.viewData[VENUE_DETAILS_VENUE_TYPE]}
                                            fieldName={VENUE_DETAILS_VENUE_TYPE}
                                            value={(venueDetails || { venueType: null }).venueType}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string): void => {} }                                
                                        />
                                }
                                {
                                    this.state.enableEditVenueDetails ? 
                                        <SizedCheckboxDataInput
                                            label={this.viewData[VENUE_DETAILS_OTHER_VENUE_TYPE]}
                                            fieldName={VENUE_DETAILS_OTHER_VENUE_TYPE}
                                            value={this.state.newVenueDetailsValue.otherTypeOfVenue}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string, isOnBlur?: boolean): void  => {
                                                this.setState({
                                                    ...this.state,
                                                    newVenueDetailsValue: {
                                                        ...this.state.newVenueDetailsValue,
                                                        otherTypeOfVenue: value as string
                                                    }
                                                }) 
                                            } }                                
                                        /> :
                                        <SizedCheckboxDataInput
                                            label={this.viewData[VENUE_DETAILS_OTHER_VENUE_TYPE]}
                                            fieldName={VENUE_DETAILS_OTHER_VENUE_TYPE}
                                            value={(venueDetails || { otherTypeOfVenue: null }).otherTypeOfVenue}
                                            isHidden={false} 
                                            changeData={(value: string | boolean, fieldName: string): void => {} }                                
                                        />
                                }
                            </div>
                            <hr /> </div>)}
                            {this.renderUsageInfo(changeData, distribution)}
                        </>
                    ) : ''}
                </div>
            </>
        );
    }
}