import * as React from "react";
import { throttle, last } from "lodash";
import { EVENT_HANDLER_THROTTLE_TIME, REPRESENTATION_PAGE } from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IInterestedParty } from "../../../types/IInterestedParty";
import { ISocIP } from "../../../types/ISocIP";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { setChangesMade, addTab, updateInterestedPartyField, sortInterestedPartyFieldData, resetMessageBanner, updateIpAgreementsFilterStateField, setEditableFields, updateEditableFields, clearModalSearchResults, displayRepresentationFlag, addNewRepresentation, getRepresentationGroupRequest, getRepresentationGroups, createNewRepresentation } from "../../../../redux/reducers/RepertoireReducer";
import { IInterestedPartyMaintenanceToolbarProps } from "../ipMaintenanceToolbar/IPMaintenanceToolbar";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IAgreementSearchState } from "../../../types/IAgreementSearchState";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IIPMaintenancePageUIConfiguration } from "../../../../redux/types/IIPMaintenancePageUIConfiguration";
import IPRepresentationGeneralDataView from "./components/IpRepresentationsGeneralDataView";

export interface IRepresentationPageProps {
    ipMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    ipMaintenanceGridsViewData: IRepertoireComponentDataItem;
    ipMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    ipMaintenanceToolbarData?: IRepertoireComponentDataItem;
    ipMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    interestedParty: IInterestedParty;
    setChangesMade: typeof setChangesMade;
    activeAccordion: string[];
    updateInterestedPartyField: typeof updateInterestedPartyField;
    sortIPFieldData: typeof sortInterestedPartyFieldData;
    openIPAccordion: (accordionName: string, componentName: string) => void;
    closeIPAccordion: (accordionName: string, componentName: string) => void;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    addTab: typeof addTab;
    saveChanges: (account: ISocIP) => void;
    deleteAccount: (ipBaseNumber: string, account: string, activeTab: number) => void;
    addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => void;
    undoIPChanges: (ipBaseNumber?: string, accountNumber?: string) => void;
    resetMessageBanner: () => void;
    ipMaintenanceTimeRangeSelectorViewData: IComponentDataItem;
    ipAgrementsSearchState: IAgreementSearchState;
    updateIpAgreementsFilterStateField: typeof updateIpAgreementsFilterStateField;
    filterIpAgreements: (searchState: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]) => void;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    searchSocIPVersionHistory: (socIPId: number, activeTabItem: ITabReduxItem, formats: FormatFields[], lookups: ILookupDictionary) => void;
    workflowFieldsData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    editableFields: IRepertoireField[];
    ipMaintenancePageUIConfiguration: IIPMaintenancePageUIConfiguration;
    getIPMaintenaceDisplaySettings: () => void;
    displayRepresentation: typeof displayRepresentationFlag;
    displayRepresentationBoolean: boolean;
    ipRepresentationGeneralDataViewData: IRepertoireComponentDataItem;
    ipBaseNumber: string;
    addNewRepresentation: typeof addNewRepresentation;
    createNewRepresentation: (ipBaseNumber: string, lookups: ILookupDictionary, isNew?: boolean, representationMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    getRepresentationGroups: typeof getRepresentationGroups;
    representationGroups: { code: string, description: string }[];
    representationUses: { code: string, description: string }[];
    representationWorkGroups: { code: string, description: string }[];
}

interface IRepresentationPageState {
    toolbarWidth: number;
}
export type IInterestedPartyMaintenanceToolbarKeys = keyof IInterestedPartyMaintenanceToolbarProps;

export class IPRepresentationPage extends React.PureComponent<
    IRepresentationPageProps,
    IRepresentationPageState
> {
    toolbarParentRef;
    constructor(props: IRepresentationPageProps) {
        super(props)
        this.state = {
            toolbarWidth: null
        };
        this.toolbarParentRef = React.createRef();
    }

    componentDidMount() {
        const { ipMaintenancePageUIConfiguration, getIPMaintenaceDisplaySettings } = this.props;
        if (!ipMaintenancePageUIConfiguration) {
            getIPMaintenaceDisplaySettings();
        }
    };

    addRepresentation() {
        const { addTab } = this.props;

        addTab(REPRESENTATION_PAGE);
    }

    render() {
        const {
            tabs,
            activeTab,
            showModal,
            hideModal,
            lookupValues,
            ipRepresentationGeneralDataViewData,
            ipBaseNumber,
            addNewRepresentation,
            getRepresentationGroups,
            representationGroups,
            representationUses,
            representationWorkGroups,
            resetMessageBanner,
            ipMaintenanceSaveResultData
        } = this.props;

        const isReadonly: boolean = tabs[activeTab].isReadonly;

        return (<IPRepresentationGeneralDataView
            ipBaseNumber={ipBaseNumber}
            activeTab={activeTab}
            tabs={tabs}
            hideModal={hideModal}
            showModal={showModal}
            isReadonly={isReadonly}
            lookupValues={lookupValues}
            ipRepresentationGeneralDataViewData={ipRepresentationGeneralDataViewData}
            addNewRepresentation={addNewRepresentation}
            getRepresentationGroups={getRepresentationGroups}
            representationGroups={representationGroups}
            representationUses={representationUses}
            representationWorkGroups={representationWorkGroups}
            resetMessageBanner={resetMessageBanner}
            ipMaintenanceSaveResultData={ipMaintenanceSaveResultData}
        />);
    }
}

