import { IComponentDataItem } from "../../core/types/IComponentDataItem";
import { SAMRO_FILENAME_VALIDATION_MESSAGE } from "../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindowFields";
import { FILE_FORMAT_SAMRO } from "../../dataingestion/Consts";
import { TreeNodeBuilder } from "../../dataingestion/services/TreeNodeBuilder";
import IDataIngestionComponentData from "../../dataingestion/types/IDataIngestionComponentData";
import { IFileSystem } from "../../dataingestion/types/IFileSystem";
import { IStoragePath } from "../../dataingestion/types/IStoragePath";
import { IStoragePathItemData } from "../../dataingestion/types/IStoragePathItemData";
import { SaveState } from "../../dataingestion/types/SaveState";
import { IJob } from "../../job/types/IJob";
import { ILookup, ILookupInstance } from "../../lookup/types/ILookup";
import { USAGE_SOCIETY_CODE, USAGE_SOCIETY_PATH, USAGE_TO_PRODUCT_INGESTION_PATH, USAGE_TO_PRODUCT_MATCH_TYPE_CODE, USAGE_TO_PRODUCT_MATCH_TYPE_ID, USAGE_TO_WORK_INGESTION_PATH, USAGE_TO_WORK_MATCH_TYPE_CODE, USAGE_TO_WORK_MATCH_TYPE_ID } from "../../repertoire/Consts";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IUsagePool } from "../../repertoire/types/usageTypes/IUsagePool";
import { TreeviewService } from "../../treeview/services/TreeviewService";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";
import {
    ActionTypes, AddFileToUploadQueue, ADD_FILE, CleanStoragePath, CLEAN_STORAGE_PATH_STATE, CLEAN_UPLOAD_FILES_STATE, ClearDataIngestionCache, CLEAR_DATA_INGESTION_CACHE, DataIngestionComponentDataFailure,
    DataIngestionComponentDataRequest,
    DataIngestionComponentDataSuccess, EditingChanged,
    EDITING_CHANGED, FETCH_DATA_INGESTION_COMPONENT_DATA_FAILURE,
    FETCH_DATA_INGESTION_COMPONENT_DATA_REQUEST,
    FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS, FileMetadataChanged,
    FILE_METADATA_CHANGED, GetDistributionsFailure, GetDistributionsRequest, GetDistributionsSuccess, GetFilesystemsFailure, GetFilesystemsRequest,
    GetFilesystemsSuccess, GetPoolsFailure, GetPoolsRequest, GetPoolsSuccess, GET_DISTRIBUTIONS_FAILURE, GET_DISTRIBUTIONS_REQUEST,
    GET_DISTRIBUTIONS_SUCCESS, GET_FILESYSTEMS_FAILURE, GET_FILESYSTEMS_REQUEST,
    GET_FILESYSTEMS_SUCCESS, GET_POOLS_FAILURE, GET_POOLS_REQUEST, GET_POOLS_SUCCESS, MatchNow, MATCH_NOW, ResetPagination, RESET_PAGINATION, SetDestinations, SetFileFormats, SetInDropZoneAction, SetProgressUploadFileProccess, SetSelectedNode, ResetMessageBanner, SET_DESTINATIONS, SET_FILE_FORMATS, SET_IN_DROP_ZONE, SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS, SET_SELECTED_NODE, RESET_MESSAGE_BANNER, ShowJobProgress,
    SHOW_JOB_PROGRESS, SortColumn,
    SORT_COLUMN, ToggleSidebarCollapsed,
    TOGGLE_SIDEBAR_COLLAPSED, UndoUsageIngestionFailure, UndoUsageIngestionRequest, UndoUsageIngestionSuccess, UNDO_USAGE_INGESTION_FAILURE, UNDO_USAGE_INGESTION_REQUEST, UNDO_USAGE_INGESTION_SUCCESS, UpdatePagination, UPDATE_PAGINATION, ValidationMessageChanged, VALIDATION_MESSAGE_CHANGED
    , SearchRightHolderRequest, SearchRightHolderSuccess, SearchRightHolderFailure, SEARCH_RIDGTHOLDERS_FAILURE, SEARCH_RIDGTHOLDERS_REQUEST, SEARCH_RIDGTHOLDERS_SUCCESS
    , UPDATE_STORAGE_PATHITEM_IP, UpdateStoragePathItemIP,SEARCH_LICENSES_REQUEST_SUCCESS, SEARCH_LICENSES_REQUEST_FAILURE, SearchLicensesRequest, SearchLicensesSuccess, SearchLicensesFailure, SEARCH_LICENSES_REQUEST, UPDATE_STORAGE_PATHITEM_SHOPPINGCART, UpdateStoragePathItemShoppingCart, GET_FOLDER_CONFIGURATION_REQUEST, GetFolderDefaultConfigurationRequest, GetFolderDefaultConfigurationSuccess, GET_FOLDER_CONFIGURATION_SUCCESS, GET_FOLDER_CONFIGURATION_FAILURE, GetFolderDefaultConfigurationFailure, LoadFolderListFailure, LoadFolderListSuccess, LoadFolderListRequest, LOAD_FOLDER_LIST_REQUEST, LOAD_FOLDER_LIST_FAILURE, LOAD_FOLDER_LIST_SUCCESS,
} from "../actions/DataIngestionActions";
import { IDataIngestionState } from "../types/IDataIngestionState";
import IDroppedFiles from "../types/IDroppedFiles";
import IFile from "../types/IFiles";
import { IPaginationDetail } from "../types/IPaginationDetail";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IP } from "../../repertoire/types/IP";
import { FolderSection } from "../../dataingestion/types/FolderSection";
import { IShoppingCartSearchQuery } from "../../repertoire/types/IShoppingCartSearchQuery";
import { IShoppingCartSearchResult } from "../../repertoire/types/IShoppingCartSearchResult";
import { ISourceSearchResult } from "../../settings/types/ISourceSearchResult";
import { USAGE_INGESTION_AUTO_PROCESS, USAGE_INGESTION_DEFAULT_DESTINATION, USAGE_INGESTION_DEFAULT_DISTRIBUTION, USAGE_INGESTION_DEFAULT_INPUT_FILE_FORMAT, USAGE_INGESTION_DEFAULT_OUTPUT_FILE_FORMAT, USAGE_INGESTION_DEFAULT_POOL } from "../../settings/Consts";
import { IParameterSearchResult } from "../../settings/types/IParameterSearchResult";

const initialState: IDataIngestionState = {
    componentData: {
        DataIngestionPage: {
            fields: []
        },
        ContainerDetailsWindow: {
            fields: []
        },
        StoragePathItem: {
            fields: []
        },
        YesNoPromptView: {
            fields: []
        },
        SearchResultsTable: {
            fields: []
        },
        SearchView: {
            fields: []
        },
        DataIngestionToolbar: {
            fields: []
        }
    },
    distributions: [],
    formats: [],
    destinations: undefined,
    storagePaths: [],
    filesystems: [],
    selectedFileSystem: undefined,
    sidebarWrapperIsActive: true,
    sidebarIsCollapsed: false,
    saveState: SaveState.Unchainged,
    treeNodes: [],
    selectedNode: undefined,
    droppedFiles: {
        files: [],
        inDropZone: false,
    },
    sortColumnName: undefined,
    sortColumnAscending: undefined,
    paginationDetails: [],
    pools: undefined,
    contributorsSearchResult: [],
    isSearching: false,
    licensesSearchResult: [],
    folderDefaultConfiguration: undefined,
    sorcesFolderList: []
}

export function getDataIngestionComponentDataRequest(): DataIngestionComponentDataRequest {
    return {
        type: FETCH_DATA_INGESTION_COMPONENT_DATA_REQUEST
    };
}

export function getDataIngestionComponentDataSuccess(componentData: IDataIngestionComponentData): DataIngestionComponentDataSuccess {
    return {
        type: FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS,
        payload: {
            componentData
        }
    };
}

export function getDataIngestionComponentDataFailure(error: string): DataIngestionComponentDataFailure {
    return {
        type: FETCH_DATA_INGESTION_COMPONENT_DATA_FAILURE,
        payload: { error }
    };
}

export function getDistributionsRequest(): GetDistributionsRequest {
    return {
        type: GET_DISTRIBUTIONS_REQUEST
    };
}

export function getDistributionsSuccess(distributions: IDistribution[]): GetDistributionsSuccess {
    return {
        type: GET_DISTRIBUTIONS_SUCCESS,
        payload: {
            distributions
        }
    };
}

export function getDistributionsFailure(error: string): GetDistributionsFailure {
    return {
        type: GET_DISTRIBUTIONS_FAILURE,
        payload: { error }
    };
}

export function undoUsageIngestionRequest(): UndoUsageIngestionRequest {
    return {
        type: UNDO_USAGE_INGESTION_REQUEST
    };
}

export function undoUsageIngestionSuccess(): UndoUsageIngestionSuccess {
    return {
        type: UNDO_USAGE_INGESTION_SUCCESS,
    };
}

export function undoUsageIngestionFailure(error: string): UndoUsageIngestionFailure {
    return {
        type: UNDO_USAGE_INGESTION_FAILURE,
        payload: {
            error
        }
    };
}

export function setFileFormats(formats: ILookup): SetFileFormats {
    return {
        type: SET_FILE_FORMATS,
        payload: {
            formats
        }
    };
}

export function setDestinations(destinations: ILookup): SetDestinations {
    return {
        type: SET_DESTINATIONS,
        payload: {
            destinations
        }
    };
}

export function getFilesystemsRequest(): GetFilesystemsRequest {
    return {
        type: GET_FILESYSTEMS_REQUEST
    };
}

export function getFilesystemsSuccess(filesystems: IFileSystem[], disallowedFolderSections?: FolderSection[]): GetFilesystemsSuccess {
    return {
        type: GET_FILESYSTEMS_SUCCESS,
        payload: {
            filesystems,
            disallowedFolderSections
        }
    };
}

export function getFilesystemsFailure(error: string): GetFilesystemsFailure {
    return {
        type: GET_FILESYSTEMS_FAILURE,
        payload: { error }
    };
}

export function getPoolsRequest(): GetPoolsRequest {
    return {
        type: GET_POOLS_REQUEST
    };
}

export function getPoolsSuccess(pools: IUsagePool[]): GetPoolsSuccess {
    return {
        type: GET_POOLS_SUCCESS,
        payload: {
            pools
        }
    };
}

export function getPoolsFailure(error: string): GetPoolsFailure {
    return {
        type: GET_POOLS_FAILURE,
        payload: { error }
    };
}

export function showJobProgress(jobs: IJob[]): ShowJobProgress {
    return {
        type: SHOW_JOB_PROGRESS,
        payload: { jobs }
    }
}

export function editingChanged(item: IStoragePathItemData, index: number): EditingChanged {
    return {
        type: EDITING_CHANGED,
        payload: { item, index }
    }
}

export function validationMessageChanged(message: string, index: number): ValidationMessageChanged {
    return {
        type: VALIDATION_MESSAGE_CHANGED,
        payload: { message, index }
    }
}

export function matchNow(item: IStoragePathItemData, index: number): MatchNow {
    return {
        type: MATCH_NOW,
        payload: { item, index }
    }
}

export function fileMetadataChanged(value: any, field: string | boolean | string[], index: number): FileMetadataChanged {
    return {
        type: FILE_METADATA_CHANGED,
        payload: { field, value, index }
    }
}

export function toggleSidebarCollapsed(): ToggleSidebarCollapsed {
    return {
        type: TOGGLE_SIDEBAR_COLLAPSED
    }
}

export function cleanStoragePath(): CleanStoragePath {
    return {
        type: CLEAN_STORAGE_PATH_STATE
    }

}

export function searchRightHoldersRequest(): SearchRightHolderRequest {
    return {
        type: SEARCH_RIDGTHOLDERS_REQUEST
    };
}

export function searchRightHoldersSuccess(
    result: IContributorSearchResult[]
): SearchRightHolderSuccess {
    return {
        type: SEARCH_RIDGTHOLDERS_SUCCESS,
        payload: {
            contributorsResult: result
        }
    };
}

export function searchRightHoldersFailure(
    error: string
): SearchRightHolderFailure {
    return {
        type: SEARCH_RIDGTHOLDERS_FAILURE,
        payload: {
            error
        }
    };
}

export function searchLicenses(): SearchLicensesRequest {
    return {
        type: SEARCH_LICENSES_REQUEST
    };
}

export function searchLicensesRequestSuccess(
    result: IShoppingCartSearchResult[]
): SearchLicensesSuccess {
    return {
        type: SEARCH_LICENSES_REQUEST_SUCCESS,
        payload: {
            licensesResult: result
        }
    };
}

export function searchLicensesRequestFailure(
    error: string
): SearchLicensesFailure {
    return {
        type: SEARCH_LICENSES_REQUEST_FAILURE,
        payload: {
            error
        }
    };
}

export function setSelectedNode(
    node: ITreeNodeData<IFileSystem>,
    filesystems: IFileSystem[],
    blobs: IStoragePath[],
    saveState: SaveState,
    sources: ITreeData[]
): SetSelectedNode {
    return {
        type: SET_SELECTED_NODE,
        payload: { node, filesystems, blobs, saveState, sources }
    };
}

export function resetMessageBanner(): ResetMessageBanner {
    return {
        type: RESET_MESSAGE_BANNER
    }
}

export function setProgressUploadFileProccess(message: string, file: IFile, isUploadComplete: boolean): SetProgressUploadFileProccess {
    return {
        type: SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS,
        payload: {
            message: message,
            file: file,
            isUploadComplete: isUploadComplete
        }
    }
}

export function setInDropZone(isDropZone: boolean): SetInDropZoneAction {
    return {
        type: SET_IN_DROP_ZONE,
        payload: {
            inDropZone: isDropZone
        }
    }
}

export function addFileToUpload(file: IFile, inProgress: boolean): AddFileToUploadQueue {
    return {
        type: ADD_FILE,
        payload: {
            file: file,
            inProgress: inProgress
        }
    }
}


export function sortColumn(columnName: string, ascending: boolean): SortColumn {
    return {
        type: SORT_COLUMN,
        payload: { columnName, ascending }
    };
}

export function CleanUploadFilesState() {
    return {
        type: CLEAN_UPLOAD_FILES_STATE
    }
}

export function updatePagination(resultsPerPage: number, currentPage: any, section: string): UpdatePagination {
    return {
        type: UPDATE_PAGINATION,
        payload: {
            resultsPerPage,
            currentPage,
            section
        }
    }
}

export function resetPagination(section: string): ResetPagination {
    return {
        type: RESET_PAGINATION,
        payload: {
            section
        }
    };
}

export function clearDataIngestionCache(): ClearDataIngestionCache {
    return {
        type: CLEAR_DATA_INGESTION_CACHE
    }
}
export function updateStoragePathItemIP(storagePathItemData: IStoragePathItemData, ip: IP): UpdateStoragePathItemIP {
    return {
        type: UPDATE_STORAGE_PATHITEM_IP,
        payload: {
            storagePathItemData,
            ip
        }
    }
}

export function updateLicenseImportShoppingCart(storagePathItemData: IStoragePathItemData, shoppingCart: IShoppingCartSearchResult): UpdateStoragePathItemShoppingCart {
    return {
        type: UPDATE_STORAGE_PATHITEM_SHOPPINGCART,
        payload: {
            storagePathItemData,
            shoppingCart
        }
    }
}

export function getFolderDefaultConfigurationRequest(path: string): GetFolderDefaultConfigurationRequest {
    return {
        type: GET_FOLDER_CONFIGURATION_REQUEST,
        payload: {
            path
        }
    }
}

export function getFolderDefaultConfigurationSuccess(folderDefaultConfiguration: ISourceSearchResult): GetFolderDefaultConfigurationSuccess {
    return {
        type: GET_FOLDER_CONFIGURATION_SUCCESS,
        payload: {
            folderDefaultConfiguration
        }
    }
}

export function getFolderDefaultConfigurationFailure(error: any): GetFolderDefaultConfigurationFailure {
    return {
        type: GET_FOLDER_CONFIGURATION_FAILURE,
        payload: {
            error
        }
    }
}

export function loadFolderListRequest(): LoadFolderListRequest {
    return {
        type: LOAD_FOLDER_LIST_REQUEST
    };
}

export function loadFolderListsuccess(folderList: IParameterSearchResult[]): LoadFolderListSuccess {
    return {
        type: LOAD_FOLDER_LIST_SUCCESS,
        payload: {
            folderList
        }
    };
}

export function loadFolderListFailure(error: string): LoadFolderListFailure {
    return {
        type: LOAD_FOLDER_LIST_FAILURE,
        payload: {
            error
        }
    };
}

function setSources(storagePath: IStoragePathItemData, sources: ITreeData[], selectedNode: IFileSystem): void {
    if (!storagePath || !storagePath.metadata) {
        return;
    }
    let filteredSources: ITreeData[] = [...sources];
    if (sources) {
        if (selectedNode.parent && selectedNode.parent.fullName.indexOf(USAGE_TO_PRODUCT_INGESTION_PATH) > -1)  {
            filteredSources = filteredSources.filter(x => x.matchType == USAGE_TO_PRODUCT_MATCH_TYPE_CODE);
        }
        else if (selectedNode.parent && selectedNode.parent.fullName.indexOf(USAGE_TO_WORK_INGESTION_PATH) > -1) {
            filteredSources = filteredSources.filter(x => x.matchType == USAGE_TO_WORK_MATCH_TYPE_CODE);
        }
        else if (selectedNode.parent && selectedNode.parent.fullName.indexOf(USAGE_SOCIETY_PATH) > -1) {
            filteredSources = filteredSources.filter(x => x.matchType == USAGE_TO_WORK_MATCH_TYPE_CODE)
        }
        storagePath.sources = filteredSources;
    }
}

function columnSorting(columnName: string, ascending: boolean, storagePaths: IStoragePathItemData[]): void {

    storagePaths.sort((a: any, b: any) => {
        // set to empty string if null
        let newA = a[columnName] ? a[columnName] : (a.metadata && a.metadata[columnName] ? a.metadata[columnName] : "")
        let newB = b[columnName] ? b[columnName] : (b.metadata && b.metadata[columnName] ? b.metadata[columnName] : "")

        if (!ascending) {
            return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
        }
        return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });
    });

}

function validateFileNames(blobs: IStoragePath[], componentData: IComponentDataItem) {
    blobs && blobs.forEach(b => {
        if (b.metadata && b.metadata.format === FILE_FORMAT_SAMRO) {
            let parts: string[] = b.name.split('_');
            let part = parts.length > 0 && parts.reverse()[0];
            parts = part.split('.');
            part = parts.length > 0 && parts[0];
            if (!part || isNaN(Number(part))) {
                const field = componentData.fields.find(x => x.name === SAMRO_FILENAME_VALIDATION_MESSAGE);
                b.validationError = field && field.data;
            }
        }
    });
}

const dataIngestionReducer = (
    state: IDataIngestionState | undefined,
    action: ActionTypes
): IDataIngestionState => {
    if (state === undefined) {
        return initialState;
    }
    switch (action.type) {
        case FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS: {
            
            const { componentData } = action.payload;
            return {
                ...state,
                componentData
            };
        }
        case GET_DISTRIBUTIONS_SUCCESS: {
            const { distributions } = action.payload;
            return { ...state, distributions };
        }
        case SET_FILE_FORMATS: {
            const { formats } = action.payload;
            const newFormats = [...formats.lookups];
            return {
                ...state,
                formats: newFormats
            };
        }
        case SET_DESTINATIONS: {
            const { destinations } = action.payload;
            return {
                ...state,
                destinations
            };
        }
        case GET_FILESYSTEMS_SUCCESS: {
            let { filesystems, disallowedFolderSections } = action.payload;

            if (disallowedFolderSections && disallowedFolderSections.includes(FolderSection.Usage)) {
                filesystems = filesystems.filter(x => !(x.name === 'usage'))
            }
            if (disallowedFolderSections && disallowedFolderSections.includes(FolderSection.Repertoire)) {
                filesystems = filesystems.filter(x => !(x.name === 'repertoire'))
            }
            if (disallowedFolderSections && disallowedFolderSections.includes(FolderSection.Payment)) {
                filesystems = filesystems.filter(x => !(x.name === 'payments'))
            }

            const selectedFileSystem: IFileSystem = filesystems && filesystems.length > 0 ? filesystems[0] : null;
            const treeNodes: ITreeNodeData<IFileSystem>[] = TreeNodeBuilder.build(filesystems);
            const selectedNode = treeNodes ? treeNodes[0] : null;

            return { ...state, filesystems, selectedFileSystem, treeNodes, selectedNode };

        }
        case SHOW_JOB_PROGRESS: {
            const { jobs } = action.payload;
            const nodes: ITreeNodeData<IFileSystem>[] = JSON.parse(JSON.stringify(state.treeNodes)) as ITreeNodeData<IFileSystem>[];
            if (jobs) {
                if (nodes) {
                    const flattened = TreeviewService.flatten(nodes);
                    flattened.forEach(n => n.progressCount = jobs.filter(j => n.object.jobTypes.includes(j.type)).length);
                }
            }
            return { ...state, treeNodes: nodes };
        }
        case EDITING_CHANGED: {
            const { item, index } = action.payload;
            const storagePaths: IStoragePathItemData[] = JSON.parse(JSON.stringify(state.storagePaths)) as IStoragePathItemData[];
            item.isDirty = true;
            item.isEditing = !item.isEditing;
            storagePaths[index] = item;
            return { ...state, storagePaths };
        }
        case VALIDATION_MESSAGE_CHANGED: {
            const { message, index } = action.payload;
            const storagePaths: IStoragePathItemData[] = JSON.parse(JSON.stringify(state.storagePaths)) as IStoragePathItemData[];
            storagePaths[index].validationError = message;
            return { ...state, storagePaths };
        }
        case MATCH_NOW: {
            const { item, index } = action.payload;
            const storagePaths: IStoragePathItemData[] = JSON.parse(JSON.stringify(state.storagePaths)) as IStoragePathItemData[];
            item.isDirty = true;
            storagePaths[index] = item;
            return { ...state, storagePaths };
        }
        case GET_POOLS_SUCCESS: {
            const { pools } = action.payload;
            return { ...state, pools }
        }
        case FILE_METADATA_CHANGED: {
            const { field, value, index } = action.payload;
            const storagePaths: IStoragePathItemData[] = JSON.parse(JSON.stringify(state.storagePaths)) as IStoragePathItemData[];

            const item = storagePaths[index];
            const { metadata, sources } = item;
            if (typeof (field) != 'boolean' && typeof (field) != 'object')
                if (item.metadata[field] !== value) {
                    item.metadata[field] = value;
                    item.isDirty = true;
                }

            const filterDistributionsByPool = (poolCode: string): IDistribution[] => {
                let filteredDistributions: IDistribution[] = [];
                if (poolCode && state.distributions) {
                    filteredDistributions = state.distributions
                        .filter(d => d.distributionPools && d.distributionPools.some(p => p.poolCode === metadata.poolCode));
                }
                return filteredDistributions;
            }

            switch (field) {
                case "format":
                    validateFileNames([item], state.componentData.ContainerDetailsWindow);
                    break;
                case "source":
                    const source = metadata && sources && sources.filter(s => s.hierarchy === metadata.source);
                    let filteredPools: IUsagePool[] = [];
                    if (source && source.length > 0 && state.pools) {
                        filteredPools = state.pools.filter(p => p.sources.some(s => s.source === source[0].id));
                    }
                    item.pools = filteredPools;
                    metadata.poolCode = '';
                    if (filteredPools && filteredPools.length > 0) {
                        metadata.poolCode = filteredPools[0].poolCode;
                    }
                    item.distributions = [];
                    if (metadata.poolCode) {
                        item.distributions = filterDistributionsByPool(metadata.poolCode);
                    }
                    break;
                case "poolCode":
                    item.distributions = [];
                    if (metadata.poolCode) {
                        item.distributions = filterDistributionsByPool(metadata.poolCode);
                    }
                    break;
                default:
                    break;
            }
            return { ...state, storagePaths };
        }
        case TOGGLE_SIDEBAR_COLLAPSED: {

            const isCollapseNew: boolean = !state.sidebarIsCollapsed;
            const isWrapperActiveNew: boolean = !state.sidebarWrapperIsActive;

            return {
                ...state,
                sidebarIsCollapsed: isCollapseNew,
                sidebarWrapperIsActive: isWrapperActiveNew

            };
        }
        case SET_SELECTED_NODE: {
            const { node, filesystems, blobs, saveState, sources } = action.payload;
            const nodes: ITreeNodeData<IFileSystem>[] = JSON.parse(JSON.stringify(state.treeNodes)) as ITreeNodeData<IFileSystem>[];
            const flattened: ITreeNodeData<IFileSystem>[] = TreeviewService.flatten(nodes);
            const folderDefaultConfiguration = state.folderDefaultConfiguration;

            const prev = flattened.find(n => n.object.fullName === state.selectedNode.object.fullName);
            const selectedNode = flattened.find(n => n.object.fullName === node.object.fullName);

            let sortColumnName = state.sortColumnName;
            let sortColumnAscending = state.sortColumnAscending;

            if (prev) {
                prev.active = false;
            }

            if (prev && prev.object.fullName === selectedNode.object.fullName)
                selectedNode.isExpanded = !selectedNode.isExpanded;
            else
                selectedNode.isExpanded = true;

            selectedNode.active = true;
            selectedNode.children = TreeNodeBuilder.build(filesystems);

            const defaultSource = folderDefaultConfiguration ? sources.filter(s => s.title === folderDefaultConfiguration.name)[0].hierarchy : null;
            const defaultFormat = folderDefaultConfiguration ? folderDefaultConfiguration.parameterInstances.filter(pi => pi.parameterName === USAGE_INGESTION_DEFAULT_INPUT_FILE_FORMAT)[0].currentValue : null;
            const defaultPoolCode = folderDefaultConfiguration ? folderDefaultConfiguration.parameterInstances.filter(pi => pi.parameterName === USAGE_INGESTION_DEFAULT_POOL)[0].currentValue : null;
            const defaultDistributionCode = folderDefaultConfiguration ? folderDefaultConfiguration.parameterInstances.filter(pi => pi.parameterName === USAGE_INGESTION_DEFAULT_DISTRIBUTION)[0].currentValue : null;
            const defaultOutputFormat = folderDefaultConfiguration ? folderDefaultConfiguration.parameterInstances.filter(pi => pi.parameterName === USAGE_INGESTION_DEFAULT_OUTPUT_FILE_FORMAT)[0].currentValue : null;
            const defaultConfigurationDestination = folderDefaultConfiguration ? folderDefaultConfiguration.parameterInstances.filter(pi => pi.parameterName === USAGE_INGESTION_DEFAULT_DESTINATION)[0].currentValue : null;
            const autoProcess = folderDefaultConfiguration ? JSON.parse(folderDefaultConfiguration.parameterInstances.filter(pi => pi.parameterName === USAGE_INGESTION_AUTO_PROCESS)[0].currentValue) : false;
            
            const defaultDestination: string = state.destinations.lookups.find(l => l.code === state.destinations.defaultValue).description;
            blobs && blobs.forEach(b => {
                if (b.metadata && !b.metadata.destination && !defaultConfigurationDestination) {
                    b.metadata.destination = defaultDestination;
                } else if (b.metadata && defaultConfigurationDestination) {
                    b.metadata.destination = defaultConfigurationDestination;
                }

                if (defaultSource && b.metadata && !b.metadata.source) b.metadata.source = defaultSource;
                if (defaultFormat && b.metadata && !b.metadata.format) b.metadata.format = defaultFormat;
                if (defaultPoolCode && b.metadata && !b.metadata.poolCode) b.metadata.poolCode = defaultPoolCode;
                if (defaultDistributionCode && b.metadata && !b.metadata.distributionCode) b.metadata.distributionCode = defaultDistributionCode;
                if (defaultOutputFormat && b.metadata && !b.metadata.outputFormat) b.metadata.outputFormat = defaultOutputFormat;

                if (autoProcess && defaultSource && defaultFormat && defaultPoolCode && defaultDistributionCode && defaultOutputFormat
                    && defaultConfigurationDestination && b.metadata) {
                    b.metadata.autoIngestFile = autoProcess;
                }
            });
            validateFileNames(blobs, state.componentData.ContainerDetailsWindow);

            const storagePaths: IStoragePathItemData[] = [];
            blobs && blobs.map(b => storagePaths.push({
                isEditing: false,
                isDirty: false,
                inProgress: false,
                sources: [],
                pools: [],
                formats: [],
                distributions: [],
                ...b
            }));

            storagePaths.forEach(i => setSources(i, sources, selectedNode.object));
            storagePaths.forEach(i => i.formats = state.formats);

            if (saveState == 0) {
                sortColumnName = undefined;
                sortColumnAscending = undefined;
            }
            else if (state.sortColumnName != undefined) {
                columnSorting(state.sortColumnName, state.sortColumnAscending, storagePaths)
            }

            return {
                ...state,
                treeNodes: nodes,
                selectedNode,
                storagePaths,
                saveState,
                selectedFileSystem: selectedNode.object,
                sortColumnName: sortColumnName,
                sortColumnAscending: sortColumnAscending
            };
        }
        
        case RESET_MESSAGE_BANNER: {
            const newState = Object.assign({}, state);
            newState.saveState = SaveState.Unchainged
            
            return newState;
        }

        case SORT_COLUMN: {
            const { columnName, ascending } = action.payload;
            const storagePaths: IStoragePathItemData[] = JSON.parse(JSON.stringify(state.storagePaths)) as IStoragePathItemData[];

            columnSorting(columnName, ascending, storagePaths)
            return { ...state, storagePaths, sortColumnName: columnName, sortColumnAscending: ascending }
        }

        case ADD_FILE: {
            const { file, inProgress } = action.payload
            const newState: IDroppedFiles = JSON.parse(JSON.stringify(state.droppedFiles)) as IDroppedFiles;
            let newFileList: IFile[] = newState.files;

            if (inProgress) {
                newFileList.push(file);
            }
            else {
                if (state.droppedFiles.files && state.droppedFiles.files.length > 0) {
                    const existedFile = newFileList.find(f => f.name === file.name && f.size == file.size);
                    const indexFile = newFileList.indexOf(existedFile, 0);

                    newFileList = newFileList.splice(indexFile, 1);
                }
            }
            return { ...state, droppedFiles: newState }
        }

        case SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS: {

            const { message, file, isUploadComplete } = action.payload;
            const newState: IDroppedFiles = JSON.parse(JSON.stringify(state.droppedFiles)) as IDroppedFiles;

            const existedFile: IFile = newState.files.find(f => f.name === file.name);

            if (existedFile) {
                const fileIndex = newState.files.indexOf(existedFile);
                if (fileIndex > -1) {
                    newState.files[fileIndex].message = message;

                    if (isUploadComplete)
                        newState.files[fileIndex].isInProgress = false;
                }
            }

            return { ...state, droppedFiles: newState };
        }

        case SET_IN_DROP_ZONE: {
            const { inDropZone } = action.payload;

            const newState: IDroppedFiles = { ...state }.droppedFiles;

            newState.inDropZone = inDropZone;

            return {
                ...state,
                droppedFiles: newState
            }
        }

        case CLEAN_UPLOAD_FILES_STATE: {
            const newState: IDroppedFiles = JSON.parse(JSON.stringify(state.droppedFiles)) as IDroppedFiles;

            newState.files = [];
            newState.inDropZone = false;

            return {
                ...state,
                droppedFiles: newState
            }
        }

        case CLEAN_STORAGE_PATH_STATE: {
            const newState: IDataIngestionState = JSON.parse(JSON.stringify(state)) as IDataIngestionState;

            newState.storagePaths = [];
            newState.filesystems= [];
            newState.selectedFileSystem = null;
            newState.treeNodes = [];
            newState.selectedNode = null;

            return {
                ...state,
                storagePaths: newState.storagePaths,
                filesystems: newState.filesystems,
                selectedFileSystem: newState.selectedFileSystem,
                treeNodes: newState.treeNodes,
                selectedNode: newState.selectedNode
            }
        }

        case UPDATE_PAGINATION: {
            const { resultsPerPage, currentPage, section } = action.payload;
            const currentPaginationDetails: IPaginationDetail[] = [];
            state.paginationDetails.map(c => currentPaginationDetails.push(Object.assign({}, c)));
            const currentPaginationDetail = currentPaginationDetails.find(d => d.section === section);

            if (currentPaginationDetail != null) {
                currentPaginationDetail.currentPage = currentPage;
                currentPaginationDetail.resultsPerPage = resultsPerPage;
                currentPaginationDetail.section = section;
            } else {
                currentPaginationDetails.push({
                    section: section,
                    currentPage: currentPage,
                    resultsPerPage: resultsPerPage
                });
            }

            return {
                ...state,
                paginationDetails: currentPaginationDetails,
            }
        }
        case RESET_PAGINATION: {
            const { section } = action.payload;
            const currentPaginationDetails: IPaginationDetail[] = [];

            state.paginationDetails.map(d => currentPaginationDetails.push(Object.assign({}, d)));
            const paginationDetail = currentPaginationDetails.find(d => d.section === section);
            if (paginationDetail != null) {
                paginationDetail.currentPage = 1;
            } else {
                currentPaginationDetails.push({
                    section: section,
                    currentPage: 1,
                    resultsPerPage: 10
                });
            }

            return {
                ...state,
                paginationDetails: currentPaginationDetails,
            }
        }
        case CLEAR_DATA_INGESTION_CACHE: {
            const newState: IDataIngestionState = JSON.parse(JSON.stringify(state)) as IDataIngestionState;
            newState.componentData = undefined;
            newState.distributions = [];
            newState.formats = [];
            newState.destinations = undefined;
            newState.contributorsSearchResult = [];

            return newState;

        }
        case SEARCH_RIDGTHOLDERS_REQUEST:
            return {
                ...state,
                isSearching: true
            };
        case SEARCH_RIDGTHOLDERS_SUCCESS: {
            const { contributorsResult } = action.payload;
            const newState: IDataIngestionState = JSON.parse(JSON.stringify(state)) as IDataIngestionState;
            newState.searchSuccessful = true;
            newState.isSearching = false;
            newState.licensesSearchResult = undefined;
            newState.contributorsSearchResult = contributorsResult;
            return newState;
        }
        case SEARCH_RIDGTHOLDERS_FAILURE: {
            const newState: IDataIngestionState = JSON.parse(JSON.stringify(state)) as IDataIngestionState;
            newState.searchSuccessful = false;
            newState.isSearching = false;
            newState.contributorsSearchResult = [];
            return newState;
        }
        case UPDATE_STORAGE_PATHITEM_IP: {
            const { storagePathItemData, ip } = action.payload;
            const newState: IDataIngestionState = JSON.parse(JSON.stringify(state));
            let storagePaths = newState.storagePaths;
            let newIpBaseNumber = Number(ip.ipBaseNumber)
            let newIpiNumber = Number(ip.ipiNumber)
            let currentStoragePath = storagePaths.find(a => a.fullName === storagePathItemData.fullName)
            if (currentStoragePath) {
                if (currentStoragePath.metadata["rightHolder"] !== newIpBaseNumber) {
                    currentStoragePath.metadata["rightHolder"] = newIpBaseNumber;
                    currentStoragePath.metadata["rightHolderNameNumber"] = newIpiNumber;
                    currentStoragePath.isDirty = true;
                }
            }
            return newState;
        }
        case SEARCH_LICENSES_REQUEST:
            return {
                ...state,
                isSearching: true
            };
        case SEARCH_LICENSES_REQUEST_SUCCESS: {
            const { licensesResult } = action.payload;
            const newState: IDataIngestionState = JSON.parse(JSON.stringify(state)) as IDataIngestionState;
            newState.searchSuccessful = true;
            newState.isSearching = false;
            newState.contributorsSearchResult = undefined;
            newState.licensesSearchResult = licensesResult;           
            return newState;
        }
        case SEARCH_LICENSES_REQUEST_FAILURE: {
            const newState: IDataIngestionState = JSON.parse(JSON.stringify(state)) as IDataIngestionState;
            newState.searchSuccessful = false;
            newState.isSearching = false;
            newState.licensesSearchResult = [];
            return newState;
        }
        case GET_FOLDER_CONFIGURATION_SUCCESS: {
            const { folderDefaultConfiguration } = action.payload;

            return {
                ...state,
                folderDefaultConfiguration
            }
        }
        case LOAD_FOLDER_LIST_SUCCESS: {
            const { folderList } = action.payload;
            return {
                ...state,
                sorcesFolderList: folderList
            }
        }

        default:
            return state;
    }
}

export default dataIngestionReducer;