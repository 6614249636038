import * as React from "react";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IDataActionToolbar } from "../../../repertoire/types/IDataActionToolbar";
import { DATA_INGESTION_TOOLBAR, DOWNLOAD_SCHEMA_METHOD_TOOLBAR, DOWNLOAD_SCHEMA_STATE_KEY, REFRESH_METHOD_TOOLBAR, REFRESH_STATE_KEY } from "../../Consts";
import ToolbarComponent from "../toolBar/ToolBarComponent";

export interface IDataIngestionToolbarProps {
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    refresh: () => void;
    downloadSchema?: () => void;
    component: string;
}

interface IDataIngestionToolbarState {
    dataAction: IDataActionToolbar[];
}

export class DataIngestionToolbar extends React.PureComponent<
    IDataIngestionToolbarProps,
    IDataIngestionToolbarState
> {

    constructor(props) {
        super(props);
    }

    setActionList = () => {
        const {
            refresh,
            downloadSchema,
        } = this.props;
        const newActionList: IDataActionToolbar[] = [];
        const methods: string[] = [];
        if (refresh) methods.push(REFRESH_METHOD_TOOLBAR);
        if (downloadSchema) methods.push(DOWNLOAD_SCHEMA_METHOD_TOOLBAR);
        methods.map(method => {
            newActionList.push(this.createActionFromMethod(method));
        });

        return newActionList;
    }

    createActionFromMethod(method: string): IDataActionToolbar {
        const {
            toolbarData: { fields },
            refresh,
            downloadSchema,
            component
        } = this.props;

        switch (method) {
            case REFRESH_METHOD_TOOLBAR: {
                return {
                    action: refresh,
                    name: fields.find(f => f.name === REFRESH_STATE_KEY)?.data,
                    component: component,
                    method: method,
                    icon: 'icon ms-Icon ms-Icon--Refresh'
                }
            }
            case DOWNLOAD_SCHEMA_METHOD_TOOLBAR: {
                return {
                    action: downloadSchema,
                    name: fields.find(f => f.name === DOWNLOAD_SCHEMA_STATE_KEY)?.data,
                    component: component,
                    method: method,
                    icon: 'icon ms-Icon ms-Icon--Download'
                }
            }
        }
    }


    render() {
        const {
            scroll,
            toolbarWidth,
        } = this.props;

        const actionList = this.setActionList()

        return (
            <div className=''>
                <ToolbarComponent
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    dataActionToolbar={actionList}
                    component={DATA_INGESTION_TOOLBAR}
                    isReadonly={false}
                    />
            </div>
        );
    }
}