import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { setDataSource } from "../../redux/reducers/MembersPortalReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { DataSource } from "../../repertoire/types/DataSource";
import { SearchRequests } from "../../repertoire/services/SearchRequests";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { FormatFields } from "../../redux/types/FormatFields";
import RegisteredAgreementsSearchView from "./RegisteredAgreementsSearchView";
import { IRegisteredAgreementSearchResult } from "../types/IRegisteredAgreementSearchResult";
import { IRegisteredAgreementSearchQuery } from "../types/IRegisteredAgreementSearchQuery";

export interface IRegisteredAgreementsPageProps {
    registeredAgreementsPageData?: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchRegisteredAgreements?: (query: IRegisteredAgreementSearchQuery, lookupValues: ILookupDictionary) => void;
    registeredAgreementsSearchResults: IRegisteredAgreementSearchResult[];
    memberAgreementSearchResults?: IRegisteredAgreementSearchResult[];
    searchSuccessful: boolean;
    registeredAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getRegisteredAgreementDetails: (dataSource: string, workID: number, lookupValues: ILookupDictionary, registeredAgreementsMaintenanceDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    addNewRegisteredAgreement: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, registeredAgreementsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    setDataSource: typeof setDataSource;
    dataSource: string;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    currentUserNameNumber?: string;
    expandWorkResult?: (workResult: number) => void;
    expandedWorkResults?: number[];
    expandAllResults: () => void;
    expandAll: boolean;
    registeredAgreementSearchResults?: any;
    getDraftAgreementDetails: (dataSource: string, agreementID: number, lookupValues: ILookupDictionary, registeredAgreementsMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
}

const RegisteredAgreementsPage: React.FC<IRegisteredAgreementsPageProps> = ({
    registeredAgreementsPageData,
    addNewRegisteredAgreement,
    lookupValues,
    registeredAgreementsMaintenanceGeneralDataViewData,
    currentUserNameNumber,
    searchViewData,
    searchResultsTableData,
    searchRegisteredAgreements,
    searchSuccessful,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    resetPagination,
    sortSearchResults,
    expandAllResults,
    expandAll,
    registeredAgreementSearchResults,
    getDraftAgreementDetails,
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();
    React.useEffect(() => {
        checkIfLoaded();
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])
    React.useEffect(() => {
        checkIfLoaded();
    }, [registeredAgreementsPageData, loaded])
    const checkIfLoaded = () => {
        if (registeredAgreementsPageData.fields && registeredAgreementsPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = registeredAgreementsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }
    const addNewDraftAgreements = (): void => {
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                addNewRegisteredAgreement(lookupValues, currentUserNameNumber, true, registeredAgreementsMaintenanceGeneralDataViewData, formats);
            });
    }
    let currentDataSource = DataSource.Repertoire;
    if (loaded) {
        const getAgreementDetails = (dataSource: string, agreementID: number) => {
            getDraftAgreementDetails(dataSource, agreementID, lookupValues, registeredAgreementsMaintenanceGeneralDataViewData);
        }
        let searchPageLabelTitle = null;
        if (registeredAgreementsPageData && registeredAgreementsPageData.fields.length > 0) {
            searchPageLabelTitle = registeredAgreementsPageData.fields.find((v, i, a) => v.name == "searchPageLabelTitle").data;
        }
        let searchResults = registeredAgreementSearchResults
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">{searchPageLabelTitle}</span>
                        <div className="newWorkButton" title="Register New Agreement">
                            <div className="IconTextButton" onClick={addNewDraftAgreements}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                Register New Agreement
                            </div>
                        </div>
                    </div>
                </div>
                <RegisteredAgreementsSearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    lookupValues={lookupValues}
                    searchRegisteredAgreements={searchRegisteredAgreements}
                    registeredAgreementsSearchResults={searchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    resetPagination={resetPagination}
                    sortSearchResults={sortSearchResults}
                    expandAllResults={expandAllResults}
                    expandAll={expandAll}
                    currentUserNameNumber={currentUserNameNumber}
                    getRegisteredAgreementDetails={getAgreementDetails}
                    dataSource={currentDataSource}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}
export default RegisteredAgreementsPage;