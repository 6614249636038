import { IMatchType } from "../../settings/types/IMatchType";
import { ISourceMatchType } from "../../settings/types/ISourceMatchType";
import { ISourceSearchResult } from "../../settings/types/ISourceSearchResult";
import { IProductType } from "../../settings/types/IProductType";
import { PropertyWindowSaveState } from "../../settings/types/PropertyWindowSaveState";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";

type GET_MATCH_TYPES_REQUEST_TYPE = "GET_MATCH_TYPES_REQUEST";
type GET_MATCH_TYPES_SUCCESS_TYPE = "GET_MATCH_TYPES_SUCCESS";
type GET_MATCH_TYPES_FAILURE_TYPE = "GET_MATCH_TYPES_FAILURE";

type GET_SOURCES_REQUEST_TYPE = "GET_SOURCES_REQUEST";
type GET_SOURCES_SUCCESS_TYPE = "GET_SOURCES_SUCCESS";
type GET_SOURCES_FAILURE_TYPE = "GET_SOURCES_FAILURE";

type LOAD_SOURCE_REQUEST_TYPE = "LOAD_SOURCE_REQUEST";
type LOAD_SOURCE_SUCCESS_TYPE = "LOAD_SOURCE_SUCCESS";
type LOAD_SOURCE_FAILURE_TYPE = "LOAD_SOURCE_FAILURE";

type UPDATE_SOURCE_REQUEST_TYPE = "UPDATE_SOURCE_REQUEST";
type UPDATE_SOURCE_SUCCESS_TYPE = "UPDATE_SOURCE_SUCCESS";
type UPDATE_SOURCE_FAILURE_TYPE = "UPDATE_SOURCE_FAILURE";

type UPDATE_SOURCE_HIERARCHY_REQUEST_TYPE = "UPDATE_SOURCE_HIERARCHY_REQUEST";
type UPDATE_SOURCE_HIERARCHY_SUCCESS_TYPE = "UPDATE_SOURCE_HIERARCHY_SUCCESS";
type UPDATE_SOURCE_HIERARCHY_FAILURE_TYPE = "UPDATE_SOURCE_HIERARCHY_FAILURE";

type ADD_CHILD_SOURCE_REQUEST_TYPE = "ADD_CHILD_SOURCE_REQUEST";
type ADD_CHILD_SOURCE_SUCCESS_TYPE = "ADD_CHILD_SOURCE_SUCCESS";
type ADD_CHILD_SOURCE_FAILURE_TYPE = "ADD_CHILD_SOURCE_FAILURE";

type UPDATE_PARAMETER_INSTANCES_REQUEST_TYPE = "UPDATE_PARAMETER_REQUEST";
type UPDATE_PARAMETER_INSTANCES_SUCCESS_TYPE = "UPDATE_PARAMETER_SUCCESS";
type UPDATE_PARAMETER_INSTANCES_FAILURE_TYPE = "UPDATE_PARAMETER_FAILURE";

type UPDATE_TREE_NODES_TYPE = "UPDATE_TREE_NODES";

// Action types
export interface GetMatchTypesRequest {
  type: GET_MATCH_TYPES_REQUEST_TYPE;
}
export interface GetMatchTypesSuccess {
  type: GET_MATCH_TYPES_SUCCESS_TYPE;
  payload: {
    matchTypes: IMatchType[];
  };
}
export interface GetMatchTypesFailure {
  type: GET_MATCH_TYPES_FAILURE_TYPE;
  payload: {
    error: string;
  };
}

export interface GetSourcesRequest {
  type: GET_SOURCES_REQUEST_TYPE;
}
export interface GetSourcesSuccess {
  type: GET_SOURCES_SUCCESS_TYPE;
  payload: {
    sourceMatchType: ISourceMatchType;
  };
}
export interface GetSourcesFailure {
  type: GET_SOURCES_FAILURE_TYPE;
  payload: {
    error: string;
  };
}

export interface LoadSourceRequest {
  type: LOAD_SOURCE_REQUEST_TYPE;
}
export interface LoadSourceSuccess {
  type: LOAD_SOURCE_SUCCESS_TYPE;
  payload: {
    sourceSearchResult: ISourceSearchResult;
    sourceID: number;
    productType: IProductType;
    saveState: PropertyWindowSaveState;
  };
}
export interface LoadSourceFailure {
  type: LOAD_SOURCE_FAILURE_TYPE;
  payload: {
    error: string;
  };
}

export interface UpdateSourceRequest {
  type: UPDATE_SOURCE_REQUEST_TYPE;
}
export interface UpdateSourceSuccess {
  type: UPDATE_SOURCE_SUCCESS_TYPE;
  payload: {
    updated: boolean;
  };
}
export interface UpdateSourceFailure {
  type: UPDATE_SOURCE_FAILURE_TYPE;
  payload: {
    error: string;
  };
}

export interface UpdateSourceHierarchyRequest {
  type: UPDATE_SOURCE_HIERARCHY_REQUEST_TYPE;
}
export interface UpdateSourceHierarchySuccess {
  type: UPDATE_SOURCE_HIERARCHY_SUCCESS_TYPE;
  payload: {
    updatedSourceMatchType: ISourceMatchType;
  };
}
export interface UpdateSourceHierarchyFailure {
  type: UPDATE_SOURCE_HIERARCHY_FAILURE_TYPE;
  payload: {
    error: string;
  };
}

export interface AddChildSourceRequest {
  type: ADD_CHILD_SOURCE_REQUEST_TYPE;
}
export interface AddChildSourceSuccess {
  type: ADD_CHILD_SOURCE_SUCCESS_TYPE;
}
export interface AddChildSourceFailure {
  type: ADD_CHILD_SOURCE_FAILURE_TYPE;
}

export interface UpdateParameterInstancesRequest {
  type: UPDATE_PARAMETER_INSTANCES_REQUEST_TYPE;
}

export interface UpdateParameterInstancesSuccess {
  type: UPDATE_PARAMETER_INSTANCES_SUCCESS_TYPE;
  payload: {
    success: boolean;
  };
}

export interface UpdateParameterInstancesFailure {
  type: UPDATE_PARAMETER_INSTANCES_FAILURE_TYPE;
  payload: {
    error: string;
  };
}

export interface UpdateTreeNodes {
  type: UPDATE_TREE_NODES_TYPE;
  payload: {
    updatedTreeNodes: ITreeNodeData<ISourceSearchResult>;
  };
}

export type ActionTypes =
  | GetMatchTypesRequest
  | GetMatchTypesSuccess
  | GetMatchTypesFailure
  | GetSourcesRequest
  | GetSourcesSuccess
  | GetSourcesFailure
  | LoadSourceRequest
  | LoadSourceSuccess
  | LoadSourceFailure
  | UpdateSourceRequest
  | UpdateSourceSuccess
  | UpdateSourceFailure
  | UpdateSourceHierarchyRequest
  | UpdateSourceHierarchySuccess
  | UpdateSourceHierarchyFailure
  | AddChildSourceRequest
  | AddChildSourceSuccess
  | AddChildSourceFailure
  | UpdateTreeNodes
  | UpdateParameterInstancesRequest
  | UpdateParameterInstancesSuccess
  | UpdateParameterInstancesFailure;

// Action names
export const GET_MATCH_TYPES_REQUEST: GET_MATCH_TYPES_REQUEST_TYPE =
  "GET_MATCH_TYPES_REQUEST";
export const GET_MATCH_TYPES_SUCCESS: GET_MATCH_TYPES_SUCCESS_TYPE =
  "GET_MATCH_TYPES_SUCCESS";
export const GET_MATCH_TYPES_FAILURE: GET_MATCH_TYPES_FAILURE_TYPE =
  "GET_MATCH_TYPES_FAILURE";

export const GET_SOURCES_REQUEST: GET_SOURCES_REQUEST_TYPE =
  "GET_SOURCES_REQUEST";
export const GET_SOURCES_SUCCESS: GET_SOURCES_SUCCESS_TYPE =
  "GET_SOURCES_SUCCESS";
export const GET_SOURCES_FAILURE: GET_SOURCES_FAILURE_TYPE =
  "GET_SOURCES_FAILURE";

export const LOAD_SOURCE_REQUEST: LOAD_SOURCE_REQUEST_TYPE =
  "LOAD_SOURCE_REQUEST";
export const LOAD_SOURCE_SUCCESS: LOAD_SOURCE_SUCCESS_TYPE =
  "LOAD_SOURCE_SUCCESS";
export const LOAD_SOURCE_FAILURE: LOAD_SOURCE_FAILURE_TYPE =
  "LOAD_SOURCE_FAILURE";

export const UPDATE_SOURCE_REQUEST: UPDATE_SOURCE_REQUEST_TYPE =
  "UPDATE_SOURCE_REQUEST";
export const UPDATE_SOURCE_SUCCESS: UPDATE_SOURCE_SUCCESS_TYPE =
  "UPDATE_SOURCE_SUCCESS";
export const UPDATE_SOURCE_FAILURE: UPDATE_SOURCE_FAILURE_TYPE =
  "UPDATE_SOURCE_FAILURE";

export const UPDATE_SOURCE_HIERARCHY_REQUEST: UPDATE_SOURCE_HIERARCHY_REQUEST_TYPE =
  "UPDATE_SOURCE_HIERARCHY_REQUEST";
export const UPDATE_SOURCE_HIERARCHY_SUCCESS: UPDATE_SOURCE_HIERARCHY_SUCCESS_TYPE =
  "UPDATE_SOURCE_HIERARCHY_SUCCESS";
export const UPDATE_SOURCE_HIERARCHY_FAILURE: UPDATE_SOURCE_HIERARCHY_FAILURE_TYPE =
  "UPDATE_SOURCE_HIERARCHY_FAILURE";

export const ADD_CHILD_SOURCE_REQUEST: ADD_CHILD_SOURCE_REQUEST_TYPE =
  "ADD_CHILD_SOURCE_REQUEST";
export const ADD_CHILD_SOURCE_SUCCESS: ADD_CHILD_SOURCE_SUCCESS_TYPE =
  "ADD_CHILD_SOURCE_SUCCESS";
export const ADD_CHILD_SOURCE_FAILURE: ADD_CHILD_SOURCE_FAILURE_TYPE =
  "ADD_CHILD_SOURCE_FAILURE";

export const UPDATE_TREE_NODES: UPDATE_TREE_NODES_TYPE = "UPDATE_TREE_NODES";

export const UPDATE_PARAMETER_INSTANCES_REQUEST: UPDATE_PARAMETER_INSTANCES_REQUEST_TYPE =
  "UPDATE_PARAMETER_REQUEST";
export const UPDATE_PARAMETER_INSTANCES_SUCCESS: UPDATE_PARAMETER_INSTANCES_SUCCESS_TYPE =
  "UPDATE_PARAMETER_SUCCESS";
export const UPDATE_PARAMETER_INSTANCES_FAILURE: UPDATE_PARAMETER_INSTANCES_FAILURE_TYPE =
  "UPDATE_PARAMETER_FAILURE";
