import * as React from "react";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import {Checkbox, Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import { IViewAsAccessRequest } from "../types/IViewAsAccessRequest";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { VIEW_AS_PAGE_SEARCH_RESULTS } from "../Consts";
import { NavLink } from "reactstrap";
import { Link } from 'react-router-dom';
import { If } from "../../core/components/If";
import MembersPortalModal from "../components/membersPortalModal/MembersPortalModal";
import { searchDataChanged } from "../../redux/reducers/RepertoireReducer";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IDraftWorkSearchResult } from "../types/IDraftWorkSearchResult";
import { IAllRegisteredWorkSearchResult } from "../types/IAllRegisteredWorkSearchResult";
import { IAllRegisteredWorkSearchQuery } from "../types/IAllRegisteredWorkSearchQuery";
import { showModal,hideModal, showYesNoPrompt, updateComment } from "../../redux/reducers/ModalReducer";


export interface IViewAsPageProps {
    viewAsPageData: IMembersPortalComponentDataItem;
    getViewAsRequests: (userName: string) => void;
    currentUser: string;
    viewAsAccessRequests: IViewAsAccessRequest[];
    getMembersPortalFields: () => void;
    searchResultsTableData: IMembersPortalComponentDataItem;
    saveViewAsAccess: (viewAsAccessRequests: IViewAsAccessRequest[], userName: string) => void;
    currentUserNameNumber: string;
    setCurrentUserNameNumber: (nameNumber: string, userName:string) => void;
    viewAsAccessSaveSuccess: boolean;
    cancelAccessRequest: (ipiNameNumber: string, userName: string) => void;
    cancelAccessRequestSuccess: boolean;
    setCurrentPage: (link: string) => void;
    currentViewAsUserName: string;
    resetViewAsSaveStatus: () => void;
    displayModal: boolean;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    updateWorkContributorIP: () => void;
    displayModalCloseButton: boolean;
    workNotFoundViewData: IMembersPortalComponentDataItem;
    loadingViewData: IMembersPortalComponentDataItem;
    yesNoPromptViewData: IMembersPortalComponentDataItem;
    dataGridTableData: IMembersPortalComponentDataItem;
    dataSource: string;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    lookupValues: ILookupDictionary;
    searchDataChanged: typeof searchDataChanged;
    draftWorkSearchResults: IDraftWorkSearchResult[];
    allRegisteredWorkSearchResults: IAllRegisteredWorkSearchResult[];
    searchAllRegisteredWorks: (query: IAllRegisteredWorkSearchQuery) => void;
    saveContributorComment: (contributorID: number, comments: string) => void;
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    updateModalComment: typeof updateComment;
    resetPagination: (repertoireSection: string) => void;
    searchViewData: IMembersPortalComponentDataItem;
    modalTitle: string;
    modalMessage: string;
    searchSuccessful: boolean;
    draftAgreementIPSearchResult: any[];
}

const ViewAsPage = (props: IViewAsPageProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const [viewAsAccounts, setviewAsAccounts] = React.useState<IViewAsAccessRequest[]>([]);
    const [saveDisabled, setSaveDisabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        checkIfLoaded();
        getRequestAccess();
        getMembersPortalData();
    }, []) 
    

    const checkIfLoaded = () => {
        const { viewAsPageData } = props;

        if (viewAsPageData.fields && viewAsPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = viewAsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }
    
    const getMembersPortalData = () => {
        const { getMembersPortalFields } = props;
        getMembersPortalFields();
    }

    const getRequestAccess = () => {
        const { getViewAsRequests, currentUser, viewAsAccessRequests } = props;
        getViewAsRequests(currentUser);
    }    

    const saveChanges = () => {
        const { saveViewAsAccess, currentUser, setCurrentUserNameNumber } = props;

        let viewAsIpNameNumber = viewAsAccounts.filter(x => x.viewAs == true)
        setCurrentUserNameNumber(viewAsIpNameNumber[0].ipiNumber, viewAsIpNameNumber[0].ipName);
        saveViewAsAccess(viewAsAccounts, currentUser);
    }

    const resetSaveStatus = () => {
        const { resetViewAsSaveStatus } = props
        resetViewAsSaveStatus()
    }

    const removeDuplicateFields = (orderedFields: IRepertoireField[]): IRepertoireField[] => orderedFields.filter((fields, index, array) => array.findIndex(field => field.componentInstance === fields.componentInstance && field.name === fields.name && field.fieldType === fields.fieldType) === index);

    const getColumns = () => {
       const { searchResultsTableData} = props;

         if (searchResultsTableData) {
            let removedItemAction: IRepertoireField = undefined;

            if (searchResultsTableData && searchResultsTableData.fields) {

                let filteredFields = searchResultsTableData.fields.filter(
                    (field: IRepertoireField) =>
                        field.componentInstance === VIEW_AS_PAGE_SEARCH_RESULTS
                );

                let orderedFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields, "order");

                if (!orderedFields && orderedFields.length > 0) {
                    orderedFields = filteredFields
                }

                orderedFields = removeDuplicateFields(orderedFields)

                if (removedItemAction) {
                    orderedFields.push(removedItemAction);
                }

                let columns: ColumnsType<IViewAsAccessRequest> = orderedFields.filter(x => !x.hidden).map((field, index) => {
                    switch (field.name) {
                        case 'defaultAccess':
                            return {
                                title: field.data,
                                dataIndex: field.name,
                                width: '20em',
                                key: field.name,
                                fixed: 'left',
                                render: (value, record) => {
                                    if (record.status != "Approved" || !record.ipiNumber) {
                                        return <Checkbox disabled={true} checked={record.defaultAccess} onChange={event => onChange(event.target.checked, field.name, record)}></Checkbox>                             
                                    }
                                    else {
                                       return <Checkbox checked={record.defaultAccess} onChange={event => onChange(event.target.checked, field.name, record)}></Checkbox>                             
                                    }
                                }
                            }
                        case 'viewAsAccess':
                            return {
                                title: field.data,
                                dataIndex: field.name,
                                width: '20em',
                                key: field.name,
                                fixed: 'left',
                                render: (value, record) => {
                                    if (record.status != "Approved" || !record.ipiNumber) {
                                        return <Checkbox disabled={true} checked={record.viewAs} onChange={event => onChange(event.target.checked, field.name, record)}></Checkbox>
                                    }
                                    else {
                                        return <Checkbox checked={record.viewAs} onChange={event => onChange(event.target.checked, field.name, record)}></Checkbox>
                                    }
                                }
                            }
                        case 'accountNumber':
                            return {
                                title: 'Account Number',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return record.accountNumber;
                                }
                            }
                        case 'ipiNumber':
                            return {
                                title: 'IPI Number',
                                dataIndex: field.name,
                                width: '8em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return record.ipiNumber;
                                }
                            }
                        case 'ipName':
                            return {
                                title: 'IP Name',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value, record, index) => {
                                    return record.ipName;
                                }
                            }
                        case 'accessStatus':
                            return {
                                title: 'Access Status',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                render: (value, record, index) => {
                                    return record.status;
                                }
                            }
                        case 'update_action':
                            return {
                                title: 'Action',
                                dataIndex: field.name,
                                width: '7em',
                                key: `${field.name}`,
                                render: (value, record, index) => {
                                    if (record.status != "Pending") {
                                        return <div hidden={true} className="IconTextButton" data-toggle="modal" data-target="#settingsModal">
                                            <i className="icon ms-Icon ms-Icon--Delete" aria-hidden="true"></i>
                                            Cancel Request
                                        </div>
                                    }
                                    else {
                                        return <div className="IconTextButton" data-toggle="modal" data-target="#settingsModal" onClick={event => cancelRequest(record) }>
                                            <i className="icon ms-Icon ms-Icon--Cancel" aria-hidden="true"></i>
                                            Cancel Request
                                        </div>
                                    }
                                }
                            }
                        default:
                            return {
                                title: field.data, dataIndex: field.name,
                                width: '6em',
                                key: `${field.name}`,
                                align: 'center',
                                sorter: (a, b) => isNaN(a[field.name]) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name],
                                render: (value) => value !== null ? <div key={`${value}_${index}`}>{value}</div> : null
                            }
                    }
                })
                return columns;
            }
        }        
    }

    const { viewAsAccessRequests } = props;

    const columns = getColumns();
     
    const getData = () => {
        const {currentUserNameNumber} = props;

        if (viewAsAccounts.length > 0) {
            return viewAsAccounts;
        }

        let currentIPNameNumberValue = currentUserNameNumber;

        if (viewAsAccessRequests) {
            const data: IViewAsAccessRequest[] = viewAsAccessRequests.map((row, index) => ({
                key: index,
                viewAs: (row.ipiNumber == currentIPNameNumberValue && row.status == "Approved" ? true : false),
                status: row.status,
                accountNumber: row.accountNumber,
                defaultAccess: row.defaultAccess,
                ipiNumber: row.ipiNumber,
                ipName: row.ipName,
                action: row.action,
                guid: row.guid
            }));
            return data
        }

    }

    const data = getData();

    const onChange = (value: boolean, fieldName: string, record: IViewAsAccessRequest) => {
        if (fieldName === "defaultAccess") {
            data.forEach(x => x.defaultAccess = false);
            let indexToUpdate = data.findIndex(item => item.key === record.key);
            data[indexToUpdate].defaultAccess = true;

            const changedData = Object.assign([], data);

            setviewAsAccounts(changedData);
            setSaveDisabled(false);
        }
        else if (fieldName === "viewAsAccess") {
            data.forEach(x => x.viewAs = false);
            let indexToUpdate = data.findIndex(item => item.key === record.key);
            data[indexToUpdate].viewAs = true;

            const changedData = Object.assign([], data);

            setviewAsAccounts(changedData);
            setSaveDisabled(false);
        }
    }

    const cancelRequest = (record: IViewAsAccessRequest) => {
        const { cancelAccessRequest,currentUser } = props;

        cancelAccessRequest(record.accountNumber, currentUser);

    }

    const currentIPName = () => {
        const { viewAsAccessRequests, currentUserNameNumber } = props;
        if (viewAsAccessRequests) {
            if (currentUserNameNumber) {
                const currentViewAsIp = data.filter(x => x.ipiNumber == currentUserNameNumber).map(y => y.ipName);
                return <span className="title">Currently viewing as: {currentViewAsIp[0]}</span>
            }

        }
        else {
            return <span></span>
        }      
    }
    
    const { viewAsAccessSaveSuccess, cancelAccessRequestSuccess, setCurrentPage, displayModal } = props

    return <div className="pageContent">
             {displayModal ? (<MembersPortalModal {...props} />) : null}
             <div className="container-fluid pageContent">
                <div className="row" >
                <div className="form-group col-md-12 ">
                <div className="formBox">
                        <div className="row">
                            <div>
                                <If condition={viewAsAccessSaveSuccess == true}>
                                    <div className="row">
                                        <div className="col-md-12 alert alert-success alert-dismissible fade in">
                                            <a href="#" onClick={() => resetSaveStatus()} className="close" data-dismiss="alert" aria-label="close">&times;</a>    
                                            Save Successful
                                        </div>
                                    </div>
                                </If>
                                <If condition={viewAsAccessSaveSuccess == false}>
                                    <div className="row">
                                        <div className="col-md-12 alert alert-danger alert-dismissible fade in">
                                            <a href="#" onClick={() => resetSaveStatus()} className="close" data-dismiss="alert" aria-label="close">&times;</a>    
                                            Save Failed
                                        </div>
                                    </div>
                                </If>
                                <If condition={cancelAccessRequestSuccess == true}>
                                    <div className="row">
                                        <div className="col-md-12 alert alert-success alert-dismissible fade in">
                                            <a href="#" onClick={() => resetSaveStatus()} className="close" data-dismiss="alert" aria-label="close">&times;</a>    
                                            Request Cancelled
                                        </div>
                                    </div>
                                </If>
                                <If condition={cancelAccessRequestSuccess == false}>
                                    <div className="row">
                                        <div className="col-md-12 alert alert-danger alert-dismissible fade in">
                                            <a href="#" onClick={() => resetSaveStatus()} className="close" data-dismiss="alert" aria-label="close">&times;</a>    
                                            Request Cancelled Failed
                                        </div>
                                    </div>
                                </If>
                            </div>
                        <div className="col-md-12">
                            <span className="title">View As</span>
                            <div className="newJobButton" title="Request Access">
                                <div className="IconTextButton">
                                    <i className="icon ms-Icon ms-Icon--CircleAddition" aria-hidden="true"></i>                            
                                        <NavLink tag={Link} to={"requestAccess"} onClick={() => setCurrentPage("requestAccess")}>Request Access</NavLink>
                                    </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>
                                        Below is the list of all the accounts you have access to. You can change the user you want to work as within the portal, just select the member using the checkbox in the "View As" column and press. As well you can set the default account to "View As" for anytime you log into the portal, set the checkbox in the column "Default" to select it.
                                    </p>
                                    <p> Don't forget to click the "Save Changes" button to update the View.</p>
                                    <p>
                                    {currentIPName()}
                                    </p>

                                </div>
                            </div>
                        <div className="tableContainer">
                            <Table
                                columns={columns}
                                dataSource={data}                                                            
                                rowClassName={() => 'editable-row'}
                                size={'middle'}
                                onChange={() => { } }
                                className="parent-table"
                                tableLayout={"fixed"}

                            />
                        </div>
                    </div>
                    <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                                <button className="button btn-defaultPrimary" disabled={saveDisabled}
                            onClick={() => saveChanges()}>
                            Save Changes
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>

}

export default ViewAsPage;