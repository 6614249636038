import * as React from "react";
import { IComponentDataItem } from "../../../core/types/IComponentDataItem";
import { ContainerDetailsWindow } from "../../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindow";
import { IFileSystem } from "../../../dataingestion/types/IFileSystem";
import { IStoragePathItemData } from "../../../dataingestion/types/IStoragePathItemData";
import { ILookupInstance } from "../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, fileMetadataChanged, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../redux/reducers/DataIngestionReducer";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../redux/reducers/ModalReducer";
import { addDroppedFile, addTab, cleanDroppedFilesRepertoireState, clearModalSearchResults, setEditableFields, setProgressUploadFileRepertoireProccess, sortProductFieldData, updateProductField, updateProductFields } from "../../../redux/reducers/RepertoireReducer";
import { RepertoireService } from "../../../redux/services/RepertoireService";
import { IAddProductAttachmentModel } from "../../../redux/types/IAddProductAttachmentModel";
import { IAttachedFile } from "../../../redux/types/IAttachedFile";
import IDroppedFiles from "../../../redux/types/IDroppedFiles";
import IFile from "../../../redux/types/IFiles";
import { IPaginationDetail } from "../../../redux/types/IPaginationDetail";
import { IProductState } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ISocietyInterestPercentage } from "../../../redux/types/ISocietyInterestPercentage";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { PRODUCTS_PAGE_VIEW, RECORDING_DETAILS } from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { IComment } from "../../types/IComment";
import { IIntrayProductDuplicate } from "../../types/IIntrayProductDuplicate";
import { IMatchingProduct } from "../../types/IMatchingProduct";
import { IProductAVRequest } from "../../types/IProductAVRequest";
import { IProductAVWork } from "../../types/IProductAVWork";
import { IProductAVWorkSubmission } from "../../types/IProductAVWorkSubmission";
import { IProductContributorDataItems } from "../../types/IProductContributorDataItems";
import { IProductDuplicate } from "../../types/IProductDuplicate";
import { IProductMaintenanceSubmissionConfig } from "../../types/IProductMaintenanceSubmissionConfig";
import { IProductNameDataItems } from "../../types/IProductNameDataItems";
import { IProductNumberDataItems } from "../../types/IProductNumberDataItems";
import { IProductPerformerDataItems } from "../../types/IProductPerformerDataItems";
import { IProductWorkDataItems } from "../../types/IProductWorkDataItems";
import { IRemoveProductAttachment } from "../../types/IRemoveProductBlobAttachment";
import { IRepertoireField } from "../../types/IRepertoireField";
import { ManualMergeOptions } from "../../types/ManualMergeOptions";
import { IDistribution } from "../../types/usageTypes/IDistribution";
import ProductMaintenanceAccordionList from "../productMaintenanceAccordionList/ProductMaintenanceAccordionList";
import ProductRecordingWrapper from "./ProductRecordingSectionWrapper";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { AGENCY_LOOKUP } from "../../../lookup/Consts";
import { IReplacedProducts } from "../../types/IReplacedProduct";

export interface IProductMaintenanceGridsViewProps {
    updateProductField: typeof updateProductField;
    updateProductFields: typeof updateProductFields;
    sortProductFieldData: typeof sortProductFieldData;
    productMaintenanceGridsViewData: IRepertoireComponentDataItem;
    productMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    avWorkDetailsAccordianViewData?: IRepertoireComponentDataItem;
    avSubmissionDetailsAccordianViewData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    showModal: typeof showModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    submitProductAVRequest: (productAVRequest: IProductAVRequest, productCoreID: number) => IProductAVWorkSubmission[];
    lookupValues: ILookupDictionary;
    activeAccordian: string[];
    openAccordian: (accordionName: string, componentName: string) => void;
    closeAccordian: (accordionName: string, componentName: string) => void;
    productType: string;
    dataSource: DataSource;
    productNames: IProductNameDataItems[];
    productNumbers: IProductNumberDataItems[];
    productPerformers: IProductPerformerDataItems[];
    productWorks: IProductWorkDataItems[];
    productDuplicates: IProductDuplicate[];
    intrayProductDuplicate: IIntrayProductDuplicate[];
    productAVWork: IProductAVWork;
    productContributors: IProductContributorDataItems[];
    productAVWorkSubmissions?: IProductAVWorkSubmission[];
    productAVRequest: IProductAVRequest;
    addTab: typeof addTab;
    tabs: ITabReduxItem[];
    activeTab: number;
    getWorkDetails: (dataSource: string, workID: number) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string) => void;
    getProductDetails: (dataSource: string, productID: number) => void;
    isReadonly: boolean;
    productCoreID: number;
    productMaintenanceSubmissionConfig?: IProductMaintenanceSubmissionConfig;
    distributions: IDistribution[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem) => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    attachedFiles: IStoragePathItemData[];
    removeProductAttachmentIfExists:(attachedFile: IAttachedFile, source: DataSource) => void;
    productAttachmentsDropped?: IDroppedFiles;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire: typeof setProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire: typeof cleanDroppedFilesRepertoireState;
    containerDetailsWindowComponentData: IComponentDataItem;
    sortColumn: typeof sortColumn;
    refreshProductDetails: () => void;
    hideModal?: typeof hideModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    productNotes: IComment[];
    currentUser?: string;
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    expandedProductResults?: number[];
    expandMatchingProduct?: (index: number) => void;
    matchingProducts: IMatchingProduct[];
    manualMergeProduct: (matchingProductId: number, manualMergeOption: ManualMergeOptions) => void;
    incrementTempID?: () => void;
    product?: IProductState;
    commentCategoryFlag?: boolean;
    productRecordingFormikRef: any;
    setIsChangeRecordingForm: (value: boolean) => void;
    societyInterestPercentage?: ISocietyInterestPercentage[];
    replacedProducts?: IReplacedProducts[];
}

interface IProductMaintenanceGridsViewState {
    activeHeaderSection: string;
}

export default class ProductMaintenanceGridsView extends React.PureComponent<
    IProductMaintenanceGridsViewProps,
    IProductMaintenanceGridsViewState
    > {
    constructor(props: IProductMaintenanceGridsViewProps) {
        super(props);

        this.state = {
            activeHeaderSection: "core"
        };
        const societyNames= getLookupValues(AGENCY_LOOKUP, this.props.lookupValues);
        if(societyNames && this.props.societyInterestPercentage && this.props.societyInterestPercentage !== null){
            this.props?.societyInterestPercentage.map(element=>{
                 let result = societyNames.filter(x=>x.code === element.societyCode);
                 if(result.length>0){
                    element.societyName=result[0].description;
                 }else{
                    element.societyName = "No Society";
                 }
            })

        }
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { productMaintenanceGridsViewData, dataSource, productType } = this.props;

        if (productMaintenanceGridsViewData && productMaintenanceGridsViewData.fields) {
            return productMaintenanceGridsViewData.fields.filter(f => (f.componentInstance === null && f.fieldType === null) || f.fieldType === dataSource.toString()
                || (f.componentInstance !== null ? f.componentInstance.toUpperCase() : null) === (productType ? productType.replace(/\s/g, "") : productType)).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    };

    removeAttachmentFromMaintenanceScreen(fullName:string, productID: number, dataSource: DataSource, guid: string,index: number){
         const {refreshProductDetails} = this.props;
        const attachedFile: IAttachedFile = {
            fileName: fullName,
                productCoreID: productID
        }
        const model:IRemoveProductAttachment ={
            Guid: guid,
            Source: dataSource,
            productCoreID: productID
        }

        RepertoireService.removeProductBlobAttachment(model)
            .then(res => {
                if(res){
                    RepertoireService.removeProductAttachment({attachedFile: attachedFile, dataSource: dataSource})
                            .then((res: boolean) => {
                                if(res){
                                    refreshProductDetails();
                                 }
                             })
            }})
    }


    uploadProductAttachment(fileName: string, productID: number, dataSource: DataSource) { 
        const model: IAddProductAttachmentModel ={
            attachedFile: {
                fileName: fileName,
                productCoreID: productID
            },
            dataSource: dataSource
        }
        return Promise.resolve(
            RepertoireService.addProductAttachmentRepertoire(model)
               .then((res :IAttachedFile) => {
                    return res;
               })
               .catch((err: any) => {
                   const newFile: IFile ={
                    isInProgress: true,
                    message: err,
                    name: fileName,
                    size:0
                   }
                setProgressUploadFileProccess(err, newFile, true);
                return false;
               })
        )
    }

    toggleAccordian(fieldName, componentName) {
        const {tabs, activeTab, closeAccordian, openAccordian} = this.props;
        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(fieldName)) {
            closeAccordian(fieldName, componentName);
        } else {
            openAccordian(fieldName, componentName);
        }
    };


    renderAccordions() {
        const {
            updateProductField,
            sortProductFieldData,
            productMaintenanceAccordionViewData,
            avWorkDetailsAccordianViewData,
            avSubmissionDetailsAccordianViewData,
            dataGridTableData,
            showModal,
            clearModalSearchResults,
            lookupValues,
            activeAccordian,
            openAccordian,
            closeAccordian,
            dataSource,
            productNames,
            productNumbers,
            productPerformers,
            productWorks,
            productDuplicates,
            intrayProductDuplicate,
            productAVWork,
            productContributors,
            addTab,
            tabs,
            activeTab,
            getWorkDetails,
            getIPDetails,
            getProductDetails,
            isReadonly,
            productAVWorkSubmissions,
            productAVRequest,
            submitProductAVRequest,
            distributions,
            selectedFileSystem,
            droppedFiles,
            setInDropZone,
            addFileToUpload,
            isDropZone,
            setProgressUploadFileProccess,
            cleanUploadFilesState,
            storagePaths,
            storagePathItemData,
            containerDetailsWindowComponentData,
            destinations,
            refreshDirectory,
            attachedFiles,
            addRepertoireAttachmentToState,
            removeProductAttachmentIfExists,
            addDroppedFileRepertoire,
            productAttachmentsDropped,
            cleanDroppedFilesRepertoire,
            setProgressUploadFileRepertoire,
            sortColumn,
            productMaintenanceSubmissionConfig,
            updateComponentFieldsItems,
            hideModal,
            editableFields,
            showUpdateFieldsModal,
            editableFieldsDataView,
            setEditableFields,
            productType,
            productNotes,
            currentUser,
            paginationDetails,
            updatePagination,
            matchingProducts,
            expandedProductResults,
            expandMatchingProduct,
            manualMergeProduct,
            incrementTempID,
            product,
            commentCategoryFlag,
            societyInterestPercentage,
            replacedProducts,
        } = this.props;
        const { activeHeaderSection } = this.state;
        const { productCoreID } = this.props;

        if(activeHeaderSection === 'attachment'){
            return (
                <div key={activeHeaderSection} className="topMarginRepertoireAttachment">
                    <ContainerDetailsWindow
                        addFileToUpload={addFileToUpload}
                        cancelChanges={undefined}
                        cleanUploadFilesState={cleanUploadFilesState}
                        containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                        currentDirectory={"product"}
                        destinations={destinations}
                        editingChanged={undefined}
                        fileMetadataChanged={fileMetadataChanged}
                        jobs={[]}
                        matchNow={undefined}
                        matchNowSubmit={undefined}
                        openDirectory={undefined}
                        pageContentIsActive={true}
                        distributions={distributions}
                        droppedFiles={droppedFiles}
                        isDropZone={isDropZone}
                        selectedFileSystem={selectedFileSystem}
                        setInDropZone={setInDropZone}
                        setProgressUploadFileProccess={setProgressUploadFileProccess}
                        sortColumn={sortColumn}
                        storagePathItemData={storagePathItemData}
                        storagePaths={attachedFiles}
                        refreshDirectory={refreshDirectory}
                        saveChanges={undefined}
                        saveState={undefined}
                        isRepertoireModule={true} 
                        toggleSidebarCollapsed={undefined} 
                        validationMessageChanged={undefined}
                        addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                        entityID={productCoreID}
                        entitySource={dataSource}
                        uploadAttachment={this.uploadProductAttachment.bind(this)}
                        removeAttachmentIfExists={removeProductAttachmentIfExists}
                        addDroppedFileRepertoire={addDroppedFileRepertoire}
                        attachmentsDropped={productAttachmentsDropped}
                        cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                        setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                        component={PRODUCTS_PAGE_VIEW}
                        removeAttachmentMaintenanceScreen={this.removeAttachmentFromMaintenanceScreen.bind(this)}
                        displayRemove={true}   
                        paginationDetails={paginationDetails}
                        updatePagination={updatePagination}
                        lookupValues={lookupValues}
                        tabs={tabs}
                        activeTab={activeTab}
                        isProductAttachmentTypeNotRequired={product.isProductAttachmentTypeNotRequired}
                        isProductAttachmentTypeOptional={product.isProductAttachmentTypeOptional}
                        isWorkAttachmentTypeNotRequired={false}
                        isWorkAttachmentTypeOptional={false}
                    />
                </div>
            )
        }
        return (
                <div key={activeHeaderSection}>
                    <ProductMaintenanceAccordionList
                        updateProductField={updateProductField}
                        sortProductFieldData={sortProductFieldData}
                        productMaintenanceAccordionViewData={productMaintenanceAccordionViewData}
                        avWorkDetailsAccordianViewData={avWorkDetailsAccordianViewData}
                        avSubmissionDetailsAccordianViewData={avSubmissionDetailsAccordianViewData}
                        componentInstance={activeHeaderSection}
                        dataGridTableData={dataGridTableData}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        activeAccordian={activeAccordian}
                        openAccordian={openAccordian}
                        closeAccordian={closeAccordian}
                        dataSource={dataSource}
                        productName={productNames}
                        productNumber={productNumbers}
                        productPerformer={productPerformers}
                        productWork={productWorks}
                        productDuplicate={productDuplicates}
                        intrayProductDuplicate={intrayProductDuplicate}
                        productAVWork={productAVWork}
                        productContributor={productContributors}
                        tabs={tabs}
                        activeTab={activeTab}
                        addTab={addTab}
                        getWorkDetails={getWorkDetails}
                        getIPDetails={getIPDetails}
                        getProductDetails={getProductDetails}
                        isReadonly={isReadonly}
                        productAVRequest={productAVRequest}
                        productAVWorkSubmissions={productAVWorkSubmissions}
                        productCoreID={productCoreID}
                        submitProductAVRequest={submitProductAVRequest}
                        productMaintenanceSubmissionConfig={productMaintenanceSubmissionConfig}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        hideModal={hideModal}
                        editableFields={editableFields}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        setEditableFields={setEditableFields}
                        productType={productType}
                        productNotes={productNotes}
                        currentUser={currentUser}
                        matchingProducts={matchingProducts}
                        expandedProductResults={expandedProductResults}
                        expandMatchingProduct={expandMatchingProduct}
                        manualMergeProduct={manualMergeProduct}
                        incrementTempID={incrementTempID}
                        product={product}
                        commentCategoryFlag={commentCategoryFlag}
                        toggleAccordian={this.toggleAccordian.bind(this)}
                        societyInterestPercentage={societyInterestPercentage}
                        replacedProducts={replacedProducts}
                />
                </div>
            );
        }
    
    render(){

        const{
            isReadonly,
            lookupValues,
            dataGridTableData,
            tabs,
            activeTab,
            productMaintenanceAccordionViewData,
            productRecordingFormikRef,
            updateProductFields,
            product,
            setIsChangeRecordingForm,
            updateProductField,
            } = this.props;

            const { activeHeaderSection} = this.state;

            const filteredField = productMaintenanceAccordionViewData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === activeHeaderSection && field.name === RECORDING_DETAILS
            );
            const isRecordingSectionVisible = filteredField[0]?.name === RECORDING_DETAILS && tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(filteredField[0]?.name);

            return (
                <>
                    <div className="GridsView">
                        <div className={["headerContainer",  isReadonly ? "pointerEnable" : ""].join(" ")}>
                             {this.renderGridViewHeaderSections()}
                        </div>
                        {this.renderAccordions()}
                        <ProductRecordingWrapper
                            product={product}
                            updateProductFields={updateProductFields}
                            productRecordingFormikRef={productRecordingFormikRef}
                            lookupValues={lookupValues}
                            dataGridTableData={dataGridTableData}
                            isFormVisible={isRecordingSectionVisible}
                            setIsChangeRecordingForm={setIsChangeRecordingForm}
                            changeData={updateProductField}
                        />
                    </div>
                </>
            )



    }
}
