import React, { useState } from 'react';
import { Button, Steps, } from 'antd';
import { DataSource } from '../../../../repertoire/types/DataSource';
import { MessageBanner, MessageType } from "../../../../repertoire/components/messageBanner/MessageBanner";
import { ITabReduxItem } from '../../../../redux/types/ITabReduxItem';
import InvalidAdditionalNumbersAlert from '../../../../repertoire/works/components/workMaintenanceAccordion/InvaildAdditionalNumberAlert';
import { resetMessageBanner } from '../../../../redux/reducers/RepertoireReducer';
enum FormPages {
    PropertiesPage = 0,
    ContributorsPage = 1,
    AttachmentPage = 2,
    ArtistsPage = 3
}
export interface Step {
    title: string;
    content: any;
}
export interface IDraftWorksMaintenanceFormProps {
    steps: Step[];
    readonly: boolean;
    saveChanges: () => void;
    confirmAndSubmit: () => void;
    dataSource?: string;
    current?: number;
    setCurrent?: (current: number) => void;
    tabs?: ITabReduxItem
    activeTab?: number;
    resetMessageBanner: () => void;
}
const DraftWorksMaintenanceForm = (props) => {

    const [submit, setSubmit] = useState(false)
    const [errorMessages, setErrorMessages] = useState([]);
    const handleSubmit = () => {
        setSubmit(props.confirmAndSubmit());
    }
    const next = () => {
        const { current, setCurrent, tabs, activeTab } = props;
        const next = current + 1;
        const state = tabs[activeTab].draftWorkMaintenanceState.work;
        switch (current) {
            case FormPages.PropertiesPage:
                if (validatePropertiesPage(state)) {
                    setCurrent(next);
                }
                break;
            case FormPages.ContributorsPage:
                props.saveChanges()
                setCurrent(next)
                break;
            case FormPages.AttachmentPage:
                setCurrent(next)
                break;
            case FormPages.ArtistsPage:
                setCurrent(next);
                break;

        }
    };
    const prev = () => {
        const { current, setCurrent } = props;
        setCurrent(current - 1);
    };
    const validatePropertiesPage = (state) => {
        const messages = [];
        if (state.originalTitle === '') {
            messages.push("Title cannot be empty");
        }
        if (state.duration <= 0) {
            messages.push("Duration cannot be 0");
        }
        if (messages.length > 0) {
            setErrorMessages(messages);
            return false;
        }
        setErrorMessages([]);
        return true;
    };

    const renderErrorMessages = (messages) => {
        const { resetMessageBanner } = props;
        return (
            <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Danger} messages={messages} />
        );
    };
    const items = props.steps.map((item) => ({ key: item.title, title: item.title }));
    const { current, dataSource } = props;
    const validationErrors = !!(errorMessages.length > 0);
    return (
        <>
            {dataSource === DataSource.Intray ? <Steps current={current} items={items} /> : <></>}
            <div style={{ padding: '20px' }}>{props.steps[current].content}</div>
            {!(validationErrors && renderErrorMessages(errorMessages)) && document.querySelector('.loaderWrapper') == null && submit && <div
            >
                <MessageBanner messageType={MessageType.Success}
                    messages={["Draft submitted"]}
                    resetMessageBanner={resetMessageBanner}
                />
            </div>
            }
            <div className="row">
                <div
                    className="btn-group form-group col-lg-2 col-lg-push-5 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                    style={{ marginTop: 24 }}
                >
                    {current > 0 && (
                        <Button data-testid={'prev'} className="button btn-defaultSecondary" onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                </div>
                <div
                    className="btn-group form-group col-lg-2 col-lg-push-5 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                    style={{ marginTop: 24 }}
                >
                    {current < props.steps.length - 1 && (
                        <Button data-testid={'next'} className="button btn-defaultSecondary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                </div>
                {current === props.steps.length - 1 && props.dataSource === DataSource.Intray && (
                    <>
                        <div
                            className="btn-group form-group col-lg-2 col-lg-push-3 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                            style={{ marginTop: 24 }}
                        >
                            <Button data-testid={'save-registration'} className="button btn-defaultSecondary" onClick={() => props.saveChanges()}>
                                Save Registration
                            </Button>
                        </div>
                        <div
                            className="btn-group form-group col-lg-2 col-lg-push-3 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                            style={{ marginTop: 24 }}
                        >
                            <Button
                                data-testid={'confirm-submit-registration'}
                                className="button btn-defaultPrimary btn-confirm"
                                onClick={() => handleSubmit()}
                            >
                                Confirm & Submit Registration
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {validationErrors && renderErrorMessages(errorMessages)}
        </>
    );
};
export default DraftWorksMaintenanceForm;