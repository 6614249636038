import { Table } from "antd";
import React, { useEffect, useState } from "react";
import IconTextButton from "../components/iconTextButton/IconTextButton";
export interface ISearchResultsTable {
    dataGridTableData: any,
    data: any,
    getProductDetails?: (dataSource: string, productCoreID: number, isForWorkflowSession?: boolean, openNewTab?: boolean,id1?:string,id2?:string,type?:string,originOfRevision?:string,productionType?:string,musicDuration?:number[]) => void;
    dataSource: string;
    expandResult: (index: number) => void;
    expandedResults: any[];
    expandAll: boolean;
    expandAllResults: () => void;
}
interface DataType {
    key: React.Key;
    name: string;
    age: string;
    address: string;
  }

const SearchResultsTable: React.FC<ISearchResultsTable> = ({
    dataGridTableData,
    data,
    getProductDetails,
    dataSource,
    expandResult,
    expandedResults,
    expandAll,
    expandAllResults,
}) => {
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    useEffect(() => {
        if (dataGridTableData && data && data.length) {
            setCount(data.length);
            updateExpansion();
            return;
        }
        setCount(count + 1);
    }, [dataGridTableData, data]);

    useEffect(() => {
        if (count > 0) {
            updateExpansion();
            return;
        }
    }, [expandAll]);

const updateExpansion = () => {
    let tempTableData = data;
    tempTableData = tempTableData.map((item, index) => {
        let numberExpanded;
        let titleExpanded;
    if (expandedResults && expandedResults.length && expandedResults.includes(index)) {
            numberExpanded =  `${data[index].number && data[index].number[0] ? data[index].number[0] : ''} ${data[index].number && data[index].number[1] ? data[index].number[1] : ''} ${data[index].number && data[index].number[2] ? data[index].number[2] : ''}`
            titleExpanded = `${data[index].title && data[index].title[0] ? data[index].title[0] : ''} ${data[index].title && data[index].title[1] ? data[index].title[1] : ''} ${data[index].title && data[index].title[2] ? data[index].title[2] : ''}`

    } else {
        numberExpanded =  `${data[index].number && data[index].number[0] ? data[index].number[0] : ''}`;
        titleExpanded =  `${data[index].title && data[index].title[0] ? data[index].title[0] : ''}`;
    }
    return {
      ...item, 
      number: numberExpanded ? numberExpanded : item.number,
      title: titleExpanded ? titleExpanded : item.title,
    }
    })
    setTableData(tempTableData);
}


    const expandRow = (index) => {
      const indexReal = (pageSize * (pageNumber - 1)) + index;
        expandResult(indexReal);
        updateExpansion();
    };

    const expandAllLines = () => {
        expandAllResults()
    }

    const handlePageChange = (page, pageNumber) => {
      setPageNumber(page);
      setPageSize(pageNumber);
    }

    const getColumns = () => {
        const columns: any = [
            {
                title: '',
                dataIndex: '',
                key: `${dataGridTableData[6].name}${2}`,
              render: (text, record, index) => {
                return (
                            <div className="tableCell">
                                <IconTextButton
                                    icon={`icon ms-Icon ${expandedResults && expandedResults.includes((pageSize * (pageNumber - 1)) + index) ? 'ms-Icon--ChevronDown' : 'ms-Icon--ChevronUp'}`}
                                    onClick={() => expandRow(index)}
                                />
                            </div>
                )}
              },
          {
            title: dataGridTableData[0].data,
            dataIndex: dataGridTableData[0].name,
            key: `${dataGridTableData[0].name}${1}`,
            sorter: (a, b) => isNaN(a[dataGridTableData[0].name]) ? a[dataGridTableData[0].name].localeCompare(b[dataGridTableData[0].name]) : a[dataGridTableData[0].name] - b[dataGridTableData[0].name]
          },
          {
            title: dataGridTableData[4].data,
            dataIndex: dataGridTableData[4].name,
            key: `${dataGridTableData[4].name}${2}`,
            width: 650,
            sorter: (a, b) => isNaN(a[dataGridTableData[4].name]) ? a[dataGridTableData[4].name].localeCompare(b[dataGridTableData[4].name]) : a[dataGridTableData[4].name] - b[dataGridTableData[4].name],
          },
          {
            title: dataGridTableData[7].data,
            dataIndex: dataGridTableData[7].name,
            key: `${dataGridTableData[7].name}${2}`,
          },
          {
            title: dataGridTableData[1].data,
            dataIndex: dataGridTableData[1].name,
            key: `${dataGridTableData[1].name}${2}`,
            sorter: (a, b) => isNaN(a[dataGridTableData[1].name]) ? a[dataGridTableData[1].name].localeCompare(b[dataGridTableData[1].name]) : a[dataGridTableData[1].name] - b[dataGridTableData[1].name],
          },
          {
            title: dataGridTableData[2].data,
            dataIndex: dataGridTableData[2].name,
            key: `${dataGridTableData[2].name}${2}`,
            sorter: (a, b) => isNaN(a[dataGridTableData[2].name]) ? a[dataGridTableData[2].name].localeCompare(b[dataGridTableData[2].name]) : a[dataGridTableData[2].name] - b[dataGridTableData[2].name],
          },
          {
            title: dataGridTableData[3].data,
            dataIndex: dataGridTableData[3].name,
            key: `${dataGridTableData[3].name}${2}`,
            sorter: (a, b) => isNaN(a[dataGridTableData[3].name]) ? a[dataGridTableData[3].name].localeCompare(b[dataGridTableData[3].name]) : a[dataGridTableData[3].name] - b[dataGridTableData[3].name],
          },
          {
            title: dataGridTableData[5].data,
            dataIndex: dataGridTableData[5].name,
            key: `${dataGridTableData[5].name}${2}`,
            sorter: (a, b) => isNaN(a[dataGridTableData[5].name]) ? a[dataGridTableData[5].name].localeCompare(b[dataGridTableData[5].name]) : a[dataGridTableData[5].name] - b[dataGridTableData[5].name],
          },
          {
            title: '',
            dataIndex: '',
            key: `${dataGridTableData[6].name}${2}`,
            render: (text, record) => (
                        <div className="tableCell">
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                onClick={() => getProductDetails(dataSource, record.productCoreID,false,false,record.id1,record.id2,record.productType,record.originOfRevision,record.productionType,record.musicDuration)}
                            />
                        </div>
            )
          }
        ]
        return columns.map((col, index) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
                onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                index: index,
              }),
            };
          });
    }
        return dataGridTableData && tableData ? <div className='flexColumn'>
            {expandAllResults && expandedResults ?
                            <div key='expandAll' className='arrowIconExpandDivDisplay flexRow expandCollapseButtonPosition'>
                                <div className={expandAll ?  "arrowIconExpandAllNotExpanded" : "arrowIconExpandAllAreExpanded"}>
                                    <i className="icon ms-Icon ms-Icon--DoubleChevronLeft" aria-hidden="true" id="expandResultsButton" onClick={() => expandAllLines()} title={expandAll ? "Collapse Results" : "Expand Results"}></i>
                                </div>
                                {expandAll ? <div className='arrowIconExpandText'> Collapse Results</div> : <div className='arrowIconExpandText'> Expand Results</div>}
                            </div> : <div></div>
            }
            <Table
                columns={getColumns()}
                dataSource={tableData}
                rowClassName={() => 'editable-row'}
                size={'small'}
                className="parent-table"
                pagination={{ showSizeChanger: true, pageSizeOptions: [10, 20, 50, 100], position: ['bottomRight', 'topRight'], onChange: handlePageChange}}
            />
        </div> : <>Loading...</>}

export default SearchResultsTable;