import { Dropdown } from "reactstrap/lib";
import { nodeModuleNameResolver } from "typescript";
import { WORK_SUBJECT_LOOKUP } from "../../lookup/Consts";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { deleteUsagePoolFailure } from "../../redux/reducers/RepertoireReducer";
import { IDistributionPoolState } from "../../redux/types/IDistributionPoolState";
import { IDistributionState } from "../../redux/types/IDistributionState";
import { DATE_INPUT, DROPDOWN_INPUT, NOT_ALLOCATED_STATUS, READ_ONLY_INPUT, TEXT_INPUT, CHECKBOX_INPUT, INTEGER_INPUT, IS_ACTIVE, SHEET_MUSIC_WORK_TYPE_CODE, IS_MUSIC, } from "../Consts";
import { ISelectionCriteria } from "../types/ISelectionCriteria";
import { IDistribution } from "../types/usageTypes/IDistribution";
import { IDistributionPool } from "../types/usageTypes/IDistributionPool";
import { IDistributionSettings } from "../types/usageTypes/IDistributionSettings";
import { IDistributionSubjects } from "../types/usageTypes/IDistributionSubjects";
import { IDistributionSubjectTypes } from "../types/usageTypes/IDistributionSubjectTypes";
import { ISubjects } from "../types/usageTypes/ISubjects";

export class DistributionDetailsMapper {
    public static mapDistributionToState(distribution: IDistribution, lookupValues : ILookupDictionary): IDistributionState {
        if (!distribution) return null;

        const {
            distributionID,
            distributionCode,
            distributionType,
            distributionSubType,
            distributionStatus,
            cat1,
            cat2,
            distributionDate,
            description,
            statementDescription,
            usageFrom,
            usageTo,
            claimsFrom,
            claimsTo,
            distributionPools,
            versionNumber,
            distributionSetTypeGroup,
            distributionRightType,
            distributionSubjects,
            isResetCacheEnabled,
            isLivePerformancePortal,
            discountType,
            claimable
        } = distribution;
        const state: IDistributionState = {
            distributionID,
            distributionCode,
            distributionType: distributionType ? (distributionType.split(/(?=[A-Z])/)).join(" ") : distributionType,
            distributionSubType: distributionSubType ? (distributionSubType.split(/(?=[A-Z])/)).join(" ") : distributionSubType,
            distributionStatus,
            cat1,
            cat2,
            distributionDate,
            description,
            statementDescription,
            usageFrom,
            usageTo,
            claimsFrom,
            claimsTo,
            distributionPools: DistributionDetailsMapper.mapDistributionPoolsToState(distributionPools),
            versionNumber,
            distributionSettings: DistributionDetailsMapper.mapDistributionToDistributionSettings(distributionSetTypeGroup, distributionRightType),
            distributionSubjectTypes: DistributionDetailsMapper.mapDistributionToDistributionSubjectTypes(distributionSubjects),
            subjects: DistributionDetailsMapper.mapDistributionSubjectsToState(distributionSubjects, lookupValues),
            isResetCacheEnabled,
            isLivePerformancePortal,
            claimable,
            discountType
        };

        return state;

    }

    public static mapStateToDistribution(state: IDistributionState, getOnlySelectedPools?: boolean): IDistribution {
        if (state) {
            const {
                distributionID,
                distributionCode,
                distributionType,
                distributionSubType,
                distributionStatus,
                cat1,
                cat2,
                distributionDate,
                description,
                statementDescription,
                usageFrom,
                usageTo,
                claimsFrom,
                claimsTo,
                distributionPools,
                versionNumber,
                distributionSettings,
                subjects,
                distributionSubjectTypes,
                isResetCacheEnabled,
                isLivePerformancePortal,
                discountType,
                claimable
            } = state;

            let filteredPools = getOnlySelectedPools ? distributionPools.filter((v, i, a) => v.selected.value ? v : null) : distributionPools;
            const distribution: IDistribution = {
                distributionID,
                distributionCode,
                distributionSubType: distributionSubType ? (distributionSubType.split(" ")).join("") : distributionSubType,
                distributionType: distributionType ? (distributionType.split(" ")).join("") : distributionType,
                distributionStatus,
                cat1,
                cat2,
                distributionDate,
                description,
                statementDescription,
                usageFrom,
                usageTo,
                claimsFrom,
                claimsTo,
                distributionPools: DistributionDetailsMapper.mapDistributionPoolsStateToDistributionPools(filteredPools),
                versionNumber,
                distributionSetTypeGroup: distributionSettings.distributionSettingsSetTypeGroup.value,
                distributionRightType: distributionSettings.distributionSettingsShareRightType.value,
                distributionSubjects: DistributionDetailsMapper.mapDistributionSubjectsStateToDistributionSubjects(subjects, distributionSubjectTypes, distributionSettings, distributionID),
                isResetCacheEnabled,
                isLivePerformancePortal,
                discountType,
                claimable
            };

            return distribution;
        }
        else {
            return null;
        }
    }

    public static mapDistributionToDistributionSettings(setTypeGroup: string, rightType: string): IDistributionSettings {

        let distributionSettings = {
            distributionSettingsSetTypeGroup: { value: setTypeGroup, inputType: DROPDOWN_INPUT },
            distributionSettingsShareRightType: { value: rightType, inputType: DROPDOWN_INPUT }
        };

        return distributionSettings;
    }

    public static mapDistributionToDistributionSubjectTypes(distributionSubjects: IDistributionSubjects[]): IDistributionSubjectTypes {
        if(distributionSubjects?.length){
            let distributionSubjectTypes :  IDistributionSubjectTypes= {
                distributionSubjectLicensingUseType: { value: distributionSubjects[0].licensingUseTypeCode, inputType: DROPDOWN_INPUT },
                distributionSubjectMemberType: { value: distributionSubjects[0].contributorMemberTypeCode, inputType: DROPDOWN_INPUT },
                distributionSubjectWorkType: { value: distributionSubjects[0].workTypeCode, inputType: DROPDOWN_INPUT }
             };
             return distributionSubjectTypes;
        }
        else{
            let distributionSubjectTypes :  IDistributionSubjectTypes= {
                distributionSubjectLicensingUseType: { value: null, inputType: DROPDOWN_INPUT },
                distributionSubjectMemberType: { value: null, inputType: DROPDOWN_INPUT },
                distributionSubjectWorkType: { value: null, inputType: DROPDOWN_INPUT }
             };
             return distributionSubjectTypes;
        }
    }

    public static mapDistributionPoolsToState(distributionPools: IDistributionPool[]): IDistributionPoolState[] {
        if (!!!distributionPools) return null;
        let distributionPoolsState: IDistributionPoolState[] = [];
        distributionPools.forEach(dp => {
            let distributionPoolState: IDistributionPoolState = {
                distributionPoolID: dp.distributionPoolID,
                poolID: dp.poolID,
                poolCode: { inputType: DROPDOWN_INPUT, value: dp.poolCode },
                description: { inputType: READ_ONLY_INPUT, value: dp.description },
                status: { inputType: READ_ONLY_INPUT, value: dp.status },
                segment: { inputType: TEXT_INPUT, value: dp.segment },
                selectionCriteria: this.mapSelectionCriteriaToSelectionCriteriaState(dp.distributionPoolID, dp.selectionCriteria),
                amountToAllocate: { inputType: dp.status === NOT_ALLOCATED_STATUS ? INTEGER_INPUT : READ_ONLY_INPUT, value: dp.amountToAllocate },
                amountAllocated: { inputType: READ_ONLY_INPUT, value: dp.amountAllocated },
                actualValuePerPoint: { inputType: READ_ONLY_INPUT, value: dp.actualValuePerPoint },
                nominalValuePerPoint: { inputType: dp.status === NOT_ALLOCATED_STATUS ? INTEGER_INPUT : READ_ONLY_INPUT, value: dp.nominalValuePerPoint },
                allocationRule: { inputType: READ_ONLY_INPUT, value: dp.allocationRule },
                selected: { inputType: CHECKBOX_INPUT, value: false },
                exchangeRate: { inputType: INTEGER_INPUT, value: dp.exchangeRate },
                witholdingTaxAgreement: { inputType: INTEGER_INPUT, value: dp.witholdingTaxAgreement },
                witholdingTaxNoAgreement: { inputType: INTEGER_INPUT, value: dp.witholdingTaxNoAgreement },
                socialAndCulturalDeduction: { inputType: INTEGER_INPUT, value: dp.socialAndCulturalDeduction },
                bankCharges: { inputType: INTEGER_INPUT, value: dp.bankCharges },
                adminFees: { inputType: INTEGER_INPUT, value: dp.adminFees },
                currency: { inputType: TEXT_INPUT, value: dp.currency },
                otherAdditions: { inputType: INTEGER_INPUT, value: dp.otherAdditions },
                otherDeductions: { inputType: INTEGER_INPUT, value: dp.otherDeductions },
                narrative: { inputType: TEXT_INPUT, value: dp.narrative },
                tdpsvAllocated: { inputType: READ_ONLY_INPUT, value: dp.tdpsvAllocated },
                tdpsvError: { inputType: READ_ONLY_INPUT, value: dp.tdpsvError },
                tdpsvUa: { inputType: READ_ONLY_INPUT, value: dp.tdpsvUa },
                tdpsvUu: { inputType: READ_ONLY_INPUT, value: dp.tdpsvUu },
                continuallyDistribute : { inputType: CHECKBOX_INPUT, value: dp.continuallyDistribute },
                adjustments: { inputType: CHECKBOX_INPUT, value: dp.adjustments },
                qaStatus:{ inputType: READ_ONLY_INPUT, value: dp.qaStatus },
                cdStatus:{inputType:READ_ONLY_INPUT, value:dp.continuallyDistribute}

            };
            distributionPoolsState.push(distributionPoolState);
        });
        return distributionPoolsState;
    }

    public static mapDistributionSubjectsToState(distributionSubjects: IDistributionSubjects[], lookupValues: ILookupDictionary): ISubjects[] {
        if (!distributionSubjects?.length) return null;
        
        let distributionSubjectsState: ISubjects[] = [];
        const workSubjectTypes = getLookupValues(WORK_SUBJECT_LOOKUP, lookupValues);

        let subjectValues = (distributionSubjects[0]?.workTypeCode == SHEET_MUSIC_WORK_TYPE_CODE && workSubjectTypes) ?
                workSubjectTypes.filter(type => type.extraFields[IS_ACTIVE] === true && type.extraFields[IS_MUSIC] === true ) :
                workSubjectTypes.filter(type => type.extraFields[IS_ACTIVE] === true && type.extraFields[IS_MUSIC] === false );

        distributionSubjects.filter(x => subjectValues.find(f => f.code == x.subjectCode))
        .map((n) => {
            let index = subjectValues.findIndex(f => f.code == n.subjectCode)
            distributionSubjectsState.push({
                code: n.subjectCode,
                subjectId: n.workSubjectID,
                value: n.percentageValue,
                name: subjectValues[index].description
            })
        })
        return distributionSubjectsState;
    }

    public static mapDistributionPoolsStateToDistributionPools(distributionPoolsState: IDistributionPoolState[]): IDistributionPool[] {
        if (!!!distributionPoolsState) return null

        let distributionPools: IDistributionPool[] = [];
        distributionPoolsState.forEach(dp => {
            let distributionPool: IDistributionPool = {
                distributionPoolID: dp.distributionPoolID,
                poolID: dp.poolID ? dp.poolID : 0,
                poolCode: dp.poolCode.value,
                description: dp.description.value,
                status: dp.status.value,
                segment: dp.segment.value,
                selectionCriteria: this.mapSelectionCriteriaStateToSelectionCriteria(dp.selectionCriteria),
                amountToAllocate: Number(dp.amountToAllocate.value),
                amountAllocated: Number(dp.amountAllocated.value),
                actualValuePerPoint: Number(dp.actualValuePerPoint.value),
                nominalValuePerPoint: Number(dp.nominalValuePerPoint.value),
                allocationRule: dp.allocationRule.value,
                exchangeRate: Number(dp.exchangeRate.value),
                witholdingTaxAgreement: Number(dp.witholdingTaxAgreement.value),
                witholdingTaxNoAgreement: Number(dp.witholdingTaxNoAgreement.value),
                socialAndCulturalDeduction: Number(dp.socialAndCulturalDeduction.value),
                bankCharges: Number(dp.bankCharges.value),
                adminFees: Number(dp.adminFees.value),
                currency: dp.currency.value,
                otherAdditions: Number(dp.otherAdditions.value),
                otherDeductions: Number(dp.otherDeductions.value),
                narrative: dp.narrative.value,
                tdpsvAllocated: Number(dp.tdpsvAllocated.value),
                tdpsvError: Number(dp.tdpsvError.value),
                tdpsvUa: Number(dp.tdpsvUa.value),
                tdpsvUu: Number(dp.tdpsvUu.value),
                continuallyDistribute: dp.continuallyDistribute.value,
                adjustments: dp.adjustments.value,
                qaStatus:dp.qaStatus.value
            };
            distributionPools.push(distributionPool);
        });
        return distributionPools;
    }

    public static mapDistributionSubjectsStateToDistributionSubjects(subjects: ISubjects[], distributionSubjectType: IDistributionSubjectTypes, distributionSettings: IDistributionSettings, distributionID: number): IDistributionSubjects[] {
        if (!subjects) return null

        let distributionSubjects: IDistributionSubjects[] = [];
        
        subjects.forEach(ds => {
            let distributionSubject: IDistributionSubjects = {
                workSubjectID: ds.subjectId,
                distributionID: distributionID,
                workTypeCode: distributionSubjectType.distributionSubjectWorkType.value,
                contributorMemberTypeCode: distributionSubjectType.distributionSubjectMemberType.value,
                licensingUseTypeCode: distributionSubjectType.distributionSubjectLicensingUseType.value,
                rightTypeCode: distributionSettings.distributionSettingsShareRightType.value,
                subjectCode: ds.code,
                percentageValue: ds.value
            };
            distributionSubjects.push(distributionSubject);
        });
        return distributionSubjects;
    }

    public static mapSelectionCriteriaToSelectionCriteriaState(distributionPoolId: number, selectionCriteriaString: string): ISelectionCriteria {
        if (!!!selectionCriteriaString) return null;
        let selectionCriteria: ISelectionCriteria = JSON.parse(selectionCriteriaString) as ISelectionCriteria;
        selectionCriteria.distributionPoolId = distributionPoolId;
        return selectionCriteria;
    }

    public static mapSelectionCriteriaStateToSelectionCriteria(selectionCriteria: ISelectionCriteria): string {
        const selectionCriteriaString = JSON.stringify(selectionCriteria);
        return selectionCriteriaString;
    }

    public static createNewDistribution(lookups: ILookupDictionary): IDistribution {
        const distribution: IDistribution = {
            distributionID: 0,
            distributionCode: "",
            distributionType: null,
            distributionSubType: null,
            distributionStatus: null,
            cat1: null,
            cat2: null,
            distributionDate: null,
            description: null,
            statementDescription: null,
            usageFrom: null,
            usageTo: null,
            claimsFrom: null,
            claimsTo: null,
            distributionPools: null,
            versionNumber: 0,
            distributionSetTypeGroup: null,
            distributionRightType: null,
            distributionSubjects: null,
            isResetCacheEnabled: true,
            isLivePerformancePortal: false,
            discountType: 'GR',
            claimable:false
        }

        return distribution;
    }

    public static createNewDistributionState(lookups: ILookupDictionary): IDistributionState {
        return DistributionDetailsMapper.mapDistributionToState(DistributionDetailsMapper.createNewDistribution(lookups), lookups);
    }

}