import { Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { AccountService } from "../../accounts/services/AccountService";
import { ComponentFields } from "../../core/services/ComponentService";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { UserPreferencesService } from "../../repertoire/services/UserPreferencesService";
import { hideModal, showLoading } from "../reducers/ModalReducer";
import { FormatFields } from "../types/FormatFields";
import IMembersPortalComponentDataItem from "../types/IMembersPortalComponentDataItem";
import { IMembersPortalPathInfo } from "../../membersportal/types/IMembersPortalPathInfo";
import { IMembersPortalField } from "../../membersportal/types/IMembersPortalField";
import { SearchRequests } from "../../membersportal/services/SearchRequests";
import { SearchRequests as RepertoireSearchRequests } from '../../repertoire/services/SearchRequests';
import { StorageRequests } from "../../membersportal/services/StorageRequests";
import { IDraftWorkSearchQuery } from "../../membersportal/types/IDraftWorkSearchQuery";
import { CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_GROUP_KEY, CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_KEY, CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PORTAL_CONFIGURATION_GROUP, WORK_MAINTENANCE_GROUP,AGREEMENT_MAINTENANCE_GROUP } from "../../membersportal/ConfigurationConsts";
import { ALL_REGISTERED_WORKS_PAGE, CLAIMS_HISTORY_PAGE, DRAFT_AGREEMENTS_MAINTENANCE_PAGE, DRAFT_AGREEMENTS_PAGE, DRAFT_SET_LISTS_MAINTENANCE_PAGE, DRAFT_SET_LISTS_PAGE, DRAFT_WORKS_MAINTENANCE_PAGE, DRAFT_WORKS_PAGE, GENERIC_ERROR, LIVE_PERFORMANCE_MAINTENANCE_PAGE, LIVE_PERFORMANCE_SUBMISSION_PAGE, MEMBER_STATEMENTS_DETAILS_PAGE, MEMBER_STATEMENTS_PAGE, REGISTERED_AGREEMENTS_PAGE, REGISTERED_SET_LISTS_PAGE, REGISTERED_WORKS_PAGE, REGISTERED_WORKS_VIEW_PAGE, SUBMIT_NEW_LIVE_PERFORMANCE_PAGE, UNPAID_CLAIMS_PAGE } from "../../membersportal/Consts";
import { MembersPortalSetListsValidator } from "../../membersportal/services/MembersPortalSetListsValidator";
import { MembersPortalWorkValidator } from "../../membersportal/services/MembersPortalWorkValidator";
import { IAllRegisteredWorkSearchQuery } from "../../membersportal/types/IAllRegisteredWorkSearchQuery";
import { IAllRegisteredWorkSearchResult } from "../../membersportal/types/IAllRegisteredWorkSearchResult";
import { IDraftSetListSearchQuery } from "../../membersportal/types/IDraftSetListSearchQuery";
import { IDraftSetListSearchResult } from "../../membersportal/types/IDraftSetListSearchResult";
import { IDraftWorkSearchResult } from "../../membersportal/types/IDraftWorkSearchResult";
import { IMemberStatementSearchQuery } from "../../membersportal/types/IMemberStatementSearchQuery";
import { IMemberStatementPaymentRunWork, IMemberStatementSearchResult } from "../../membersportal/types/IMemberStatementSearchResult";
import { IMemberWorkSearchResult } from "../../membersportal/types/IMemberWorkSearchResult";
import { IMemberWorkStatus } from "../../membersportal/types/IMemberWorkStatus";
import { IRegisteredSetListsSearchQuery } from "../../membersportal/types/IRegisteredSetListsSearchQuery";
import { IRegisteredSetListsSearchResults } from "../../membersportal/types/IRegisteredSetListsSearchResults";
import { IRegisteredWorkSearchQuery } from "../../membersportal/types/IRegisteredWorkSearchQuery";
import { IRegisteredWorkSearchResult } from "../../membersportal/types/IRegisteredWorkSearchResult";
import { IViewAsAccessRequest } from "../../membersportal/types/IViewAsAccessRequest";
import { IWorkDetailsQuery } from "../../membersportal/types/IWorkDetailsQuery";
import { ProductDetailsMapper } from "../../repertoire/services/ProductDetailsMapper";
import { WorkDetailsMapper } from "../../repertoire/services/WorkDetailsMapper";
import { DataSource } from "../../repertoire/types/DataSource";
import { IArtist } from "../../repertoire/types/IArtist";
import { IArtistSearchQuery } from "../../repertoire/types/IArtistSearchQuery";
import { IContributorItem } from "../../repertoire/types/IContributorItem";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IP } from "../../repertoire/types/IP";
import { IProductWork } from "../../repertoire/types/IProductWork";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";
import { IWork } from "../../repertoire/types/IWork";
import { IWorkContributor } from "../../repertoire/types/IWorkContributor";
import { IWorkSaveResult } from "../../repertoire/types/IWorkSaveResult";
import { IMatchWorksSearchQuery } from "../../repertoire/types/usageTypes/IMatchWorksSearchQuery";
import { IMatchWorksSearchResult } from "../../repertoire/types/usageTypes/IMatchWorksSearchResult";
import {
    cancelAccessRequest, cancelAccessRequestFailure, cancelAccessRequestSuccess, createNewDraftSetLists, createNewDraftWork,createNewDraftAgreement, deleteDraftSetListFailure, deleteDraftSetListRequest,
    deleteDraftSetListSuccess, deleteDraftWorkFailure, deleteDraftWorkRequest, deleteDraftWorkSuccess, deleteRegisteredWorkFailure, deleteRegisteredWorkRequest, deleteRegisteredWorkSuccess, getAllRegisteredWorkDetailsFailure, getAllRegisteredWorkDetailsRequest,
    getAllRegisteredWorkDetailsSuccess, getDraftSetListDetailsFailure, getDraftSetListDetailsRequest,
    getDraftSetListDetailsSuccess, getDraftWorkDetailsFailure, getDraftWorkDetailsRequest,
    getDraftWorkDetailsSuccess, getMembersPortalComponentDataFailure, getMembersPortalComponentDataRequest,
    getMembersPortalComponentDataSuccess, getMemberStatementDetailsFailure, getRegisteredWorkDetailsFailure, getRegisteredWorkDetailsRequest,
    getRegisteredWorkDetailsSuccess, getUserPreferenceFailure, getUserPreferenceRequest,
    getUserPreferenceSuccess, getViewAsAccessRequests, getViewAsAccessRequestsFailure, getViewAsAccessRequestsSuccess, mp_addTab, mp_openTab, mp_removeTab, removeWorkAttachmentFailure, removeWorkAttachmentRequest,
    removeWorkAttachmentSuccess, requestViewAsAccess, requestViewAsAccessFailure, requestViewAsAccessSuccess, saveDraftLivePerformanceFailure, saveDraftLivePerformanceSuccess, saveDraftSetListsFailure,
    saveDraftSetListSuccess, saveDraftWorkFailure, saveDraftWorkRequest, saveDraftWorkSuccess, saveRegisteredWorkFailure, saveRegisteredWorkRequest, saveRegisteredWorkSuccess, saveViewAsAccess, saveViewAsAccessFailure, saveViewAsAccessSuccess, searchAllRegisteredWorksFailure, searchAllRegisteredWorksRequest,
    searchAllRegisteredWorksSuccess, searchArtistsFailure, searchArtistsRequest,
    searchArtistsSuccess, searchContributorsFailure, searchContributorsRequest,
    searchContributorsSuccess, searchDraftSetListsFailure, searchDraftSetListsRequest,
    searchDraftSetListsSuccess, searchDraftWorksFailure, searchDraftWorksRequest,
    searchDraftWorksSuccess, searchMemberStatementsFailure, searchMemberStatementsRequest,
    searchMemberStatementsSuccess, searchRegisteredSetListsFailure, searchRegisteredSetListsRequest,
    searchRegisteredSetListsSuccess, searchRegisteredWorksFailure, searchRegisteredWorksRequest, searchRegisteredWorksSuccess, searchRequestAccessIPRequest, searchRequestAccessIPRequestFailure, searchRequestAccessIPRequestSuccess, undoDraftSetListChangesFailure, undoDraftSetListChangesRequest, undoDraftSetListChangesSuccess, undoDraftWorkChangesFailure, undoDraftWorkChangesRequest,
    undoDraftWorkChangesSuccess, undoRegisteredWorkChangesFailure, undoRegisteredWorkChangesRequest,
    undoRegisteredWorkChangesSuccess, updateFieldComponentRequest, updateFieldComponentRequestFailure,
    updateFieldComponentRequestSuccess, updateWorkContributorIPFailure, updateWorkContributorIPRequest,
    updateWorkContributorIPSuccess, searchLivePerformancesSuccess, getLivePerformanceDetailsRequest, getLivePerformanceDetailsSuccess, getLivePerformanceDetailsFailure,
    createNewDraftLivePerformance,
    searchUnpaidClaimsRequest,
    searchUnpaidClaimsFailure,
    searchUnpaidClaimsSuccess,
    getShareDecimalsToDisplayRequest,
    getShareDecimalsToDisplaySuccess,
    getShareDecimalsToDisplayFailure,
    refreshWorkDetailsRequest,
    refreshWorkDetailsSuccess,
    refreshWorkDetailsFailure,
    replaceTab,
    showModal, saveContributorComment, searchClaimsHistoryRequest, searchClaimsHistorySuccess, searchClaimsHistoryFailure, claimUnpaidUsageRequest, claimUnpaidUsageSuccess, claimUnpaidUsageFailure,
    searchDraftAgreementsSuccess,
    searchDraftAgreementsFailure,
    searchDraftAgreementsRequest,
    getDraftAgreementDetailsRequest,
    getDraftAgreementDetailsSuccess,
    getDraftAgreementDetailsFailure,
    searchRegisteredAgreementsSuccess,
    searchRegisteredAgreementsFailure,
    searchRegisteredAgreementsRequest,
    searchDraftAgreementsIPSuccess,
    searchDraftAgreementsIPFailure,
    searchDraftAgreementsIPRequest,
    searchDraftAgreementsWorksSuccess,
    searchDraftAgreementsWorksFailure,
    searchDraftAgreementsWorksRequest,
    saveDraftAgreementFailure,
    saveDraftAgreementSuccess,
    refreshAgreementDetailsRequest,
    refreshAgreementDetailsSuccess,
    refreshAgreementDetailsFailure,
    getMyCases,
    getMyCasesFailure,
    getMyCasesSuccess,
    getCRMIntegratedEnabledSuccess,
    getCRMIntegratedEnabledFailure,
    getCRMPowerPortalURLSuccess,
    getMyApplications,
    getMyApplicationsSuccess,
    getMyApplicationsFailure
} from "../reducers/MembersPortalReducer";
import { MembersPortalService } from "../services/MembersPortalService";
import { RepertoireService } from "../services/RepertoireService";
import { IAddWorkAttachmentModel } from "../types/IAddAttchmentWorkModel";
import IAppState from "../types/IAppState";
import { IAttachedFile } from "../types/IAttachedFile";
import IMembersPortalComponentData from "../types/IMembersPortalComponentData";
import { IProductState } from "../types/IProductState";
import { ITabReduxItem } from "../types/ITabReduxItem";
import { IWorkState } from "../types/IWorkState";
import { IUsageGroupState } from "../types/IUsageGroupState";
import { MembersPortalLivePerformanceValidator } from "../../membersportal/services/MembersPortalLivePerformanceValidator";
import { UsageDetailsMapper } from "../../repertoire/services/usageServices/UsageDetailsMapper";
import { IUsageSaveResult } from "../../repertoire/types/usageTypes/IUsageSaveResult";
import { IProductSaveResult } from "../../repertoire/types/IProductSaveResult";
import { IProductCore } from "../../repertoire/types/IProductCore";
import { ILivePerformanceSearchQuery } from "../../membersportal/types/ILivePerformanceSearchQuery";
import { removeAgreementAttachmentFailure, removeAgreementAttachmentRequest, removeAgreementAttachmentSuccess, searchLastPercentageFailure } from "../reducers/RepertoireReducer";
import { ILivePerformancesSearchResult } from "../../membersportal/types/ILivePerformancesSearchResult";
import { IUsageGroup } from "../../repertoire/types/usageTypes/IUsageGroup";
import { IUnpaidClaimsSearchQuery } from "../../membersportal/types/IUnpaidClaimsSearchQuery";
import { MY_PACKAGES_PAGE, WORK_NOT_FOUND_VIEW } from "../../repertoire/Consts";
import { CONFIGURATION_PARAMETER_SHARE_DECIMALS_TO_DISPLAY } from "../../repertoire/ConfigurationConsts";
import { IUnpaidClaimsSearchResult } from "../../membersportal/types/IUnpaidClaimsSearchResult";
import { IClaimUnidentifiedClaimModal } from "../../membersportal/types/IClaimUnidentifiedClaimModal";
import { IClaimsHistorySearchQuery, IClaimsHistorySearchResult } from "../../membersportal/types/IClaimsHistorySearchQuery";
import { IDraftAgreementSearchQuery } from "../../membersportal/types/IDraftAgreementSearchQuery";
import { IDraftAgreementSearchResult } from "../../membersportal/types/IDraftAgreementsSearchResult";
import { IAgreement } from "../../repertoire/types/IAgreement";
import { AgreementDetailsMapper } from "../../repertoire/services/AgreementDetailsMapper";
import { IRegisteredAgreementSearchQuery } from "../../membersportal/types/IRegisteredAgreementSearchQuery";
import { IRegisteredAgreementSearchResult } from "../../membersportal/types/IRegisteredAgreementSearchResult";
import { IIPsSearchQuery } from "../../repertoire/types/IIPsSearchQuery";
import { IIPsSearchResult } from "../../repertoire/types/IIPsSearchResult";
import { IWorksSearchQuery } from "../../repertoire/types/IWorksSearchQuery";
import { IWorksSearchResult } from "../../repertoire/types/IWorksSearchResult";
import { IAgreementState } from "../types/IAgreementState";
import { MembersPortalAgreementValidator } from "../../membersportal/services/MembersPortalAgreementValidator";
import { IAgreementSaveResult } from "../../repertoire/types/IAgreementSaveResult";
import { IApplication } from "../../membersportal/types/IApplication";
import { ICase } from "../../membersportal/types/ICase";
import { POWER_PORTAL_BASE_URL_KEY, CRM_INTEGRATED_KEY, CUSTOMER_GROUP } from "../../home/Consts";



export const getMembersPortalComponentDataThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>
> => {
    return (dispatch: Dispatch) => {
        dispatch(getMembersPortalComponentDataRequest());
        return ComponentFields.getMembersPortalComponentData()
            .then((res: IMembersPortalComponentData) => {
                dispatch(getMembersPortalComponentDataSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getMembersPortalComponentDataFailure(error));
            });
    };
};


export const undoDraftWorkChangesThunk = (
    dataSource: string,
    workID: number,
    lookups: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoDraftWorkChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getDraftWork(dataSource, workID)
            .then((work: IWork) => {
                dispatch(undoDraftWorkChangesSuccess(work, lookups));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(undoDraftWorkChangesFailure(err));
                dispatch(hideModal());
            });
    };
};

export const undoDraftSetListChangesThunk = (
    dataSource: string,
    setListID: number,
    lookups: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoDraftSetListChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getDraftSetList(dataSource, setListID)
            .then((setList: IProductCore) => {
                dispatch(undoDraftSetListChangesSuccess(setList, lookups));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(undoDraftSetListChangesFailure(err));
                dispatch(hideModal());
            });
    };
};

export const undoRegisteredWorkChangesThunk = (
    dataSource: string,
    workID: number,
    lookups: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(undoRegisteredWorkChangesRequest());
        dispatch(showLoading());
        return SearchRequests.getRegisteredWork(dataSource, workID)
            .then((work: IWork) => {
                dispatch(undoRegisteredWorkChangesSuccess(work, lookups));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(undoRegisteredWorkChangesFailure(err));
                dispatch(hideModal());
            });
    };
}

export const updateComponentFieldItemsThunk = (
    fields: IMembersPortalField[],
    componentName: string,
    componentInstance: string,
    componentDataFieldName: string,
    tabs: ITabReduxItem[],
    activeTab: number,
): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {

    return (dispatch: Dispatch) => {
        dispatch(showLoading());
        dispatch(updateFieldComponentRequest());
        return ComponentFields.updateMembersPortalComponentFieldItem(fields, componentName, componentInstance)
            .then((res: IMembersPortalField[]) => {
                if (res) {
                    dispatch(updateFieldComponentRequestSuccess(res, componentDataFieldName, componentInstance));
                    // remove tab and add it back in to force components to re-mount and reload ViewData
                    dispatch(mp_removeTab(activeTab));
                    dispatch(mp_addTab(tabs[activeTab], true));
                    dispatch(hideModal());
                }
            })
            .catch(error => {
                updateFieldComponentRequestFailure(error);
            });
    }
}

export const getUserPreferences = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUserPreferenceRequest())
        return UserPreferencesService.get()
            .then(res => {
                dispatch(getUserPreferenceSuccess(res.resultsPerPage, res.activeAccordions));
            })
            .catch(error => {
                dispatch(getUserPreferenceFailure());
                console.error(error);
            })
    }
}

export const searchMemberStatementsThunk = (statementsQuery: IMemberStatementSearchQuery): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchMemberStatementsRequest())
        SearchRequests.getMemberStatements(statementsQuery)
            .then((res: IMemberStatementSearchResult[]) => {
                return StorageRequests.getStatementFiles(res)
                    .then((finalRes: IMemberStatementSearchResult[]) => {
                        dispatch(searchMemberStatementsSuccess(finalRes));
                    })
            })
            .catch(err => {
                dispatch(searchMemberStatementsFailure(err));
            });
    }
}


export const searchUnpaidClaimsThunk = (searchQuery: IUnpaidClaimsSearchQuery, continuationToken: string): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        searchQuery.isMembersPortal = true;
        dispatch(searchUnpaidClaimsRequest())
        SearchRequests.getUnpaidClaims(searchQuery, continuationToken)
            .then((res: IUnpaidClaimsSearchResult[]) => {
                dispatch(searchUnpaidClaimsSuccess(res));
            })
            .catch(err => {
                dispatch(searchUnpaidClaimsFailure(err));
            });
    }
}

export const claimUnpaidUsageThunk = (query: IClaimUnidentifiedClaimModal): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(claimUnpaidUsageRequest())
        SearchRequests.claimUnpaidUsage(query)
            .then((res: IUnpaidClaimsSearchResult[]) => {
                dispatch(claimUnpaidUsageSuccess());
            })
            .catch(err => {
                dispatch(claimUnpaidUsageFailure());
            });
    }
}

export const searchClaimsHistoryThunk = (searchQuery: IClaimsHistorySearchQuery, continuationToken: string): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        searchQuery.isMembersPortal = true;
        dispatch(searchClaimsHistoryRequest())
        SearchRequests.getClaimsHistory(searchQuery, continuationToken)
            .then((res: IClaimsHistorySearchResult[]) => {
                dispatch(searchClaimsHistorySuccess(res));
            })
            .catch(err => {
                dispatch(searchClaimsHistoryFailure(err)); });
            }
        }
        

export const searchDraftSetListsThunk = (
    setListQuery: IDraftSetListSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchDraftSetListsRequest());

        return SearchRequests.getSetLists(setListQuery)
            .then((result: IDraftSetListSearchResult[]) => {
                if (result == null || result.length == 0) {
                    dispatch(searchDraftSetListsSuccess([]));
                } else {
                    dispatch(searchDraftSetListsSuccess(result));
                }
            }).catch(err => {
                dispatch(searchDraftSetListsFailure(err));
            });
    };
};


export const searchDraftAgreementsThunk = (
    draftAgreementsQuery: IDraftAgreementSearchQuery,
    lookupValues: ILookupDictionary,
    isModalSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchDraftAgreementsRequest(isModalSearch));
        return SearchRequests.getDraftAgreements(draftAgreementsQuery, lookupValues)
            .then((result: IDraftAgreementSearchResult[]) => {
                dispatch(searchDraftAgreementsSuccess(result));
                return;
            }).catch(err => {
                dispatch(searchDraftAgreementsFailure(err));
            });
    };
};

export const searchDraftAgreementsIPThunk = (
    draftAgreementsIPQuery: IIPsSearchQuery,
    isModalSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchDraftAgreementsIPRequest(isModalSearch));
        return SearchRequests.getDraftAgreementsIp(draftAgreementsIPQuery)
            .then((result: IIPsSearchResult[]) => {
                if (result == null || result.length == 0) {
                    dispatch(searchDraftAgreementsIPSuccess([]));
                    return [];
                }
                dispatch(searchDraftAgreementsIPSuccess(result));
                return;
            }).catch(err => {
                dispatch(searchDraftAgreementsIPFailure(err));
            });
    };
}; 

export const searchDraftAgreementsWorksThunk = (
    draftAgreementsWorksQuery: IWorksSearchQuery,
    isModalSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchDraftAgreementsWorksRequest(isModalSearch));
        return SearchRequests.getDraftAgreementWorks(draftAgreementsWorksQuery)
            .then((result: IWorksSearchResult[]) => {
                if (result == null || result.length == 0) {
                    dispatch(searchDraftAgreementsWorksSuccess([]));
                    return [];
                }
                dispatch(searchDraftAgreementsWorksSuccess(result));
                return;
            }).catch(err => {
                dispatch(searchDraftAgreementsWorksFailure(err));
            });
    };
};  

export const searchRegisteredAgreementsThunk = (
    registeredAgreementsQuery: IRegisteredAgreementSearchQuery,
    lookupValues: ILookupDictionary,
    isModalSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchRegisteredAgreementsRequest(isModalSearch));
        return SearchRequests.getRegisteredAgreements(registeredAgreementsQuery, lookupValues)
            .then((result: IRegisteredAgreementSearchResult[]) => {
                dispatch(searchRegisteredAgreementsSuccess(result));
                return;
            }).catch(err => {
                dispatch(searchRegisteredAgreementsFailure(err));
            });
    };
};

export const getDraftAgreementDetailsThunk = (
    dataSource: string,
    agreementId: number,
    lookupValues: ILookupDictionary,
    draftAgreementsMaintenanceDataViewData: IMembersPortalComponentDataItem,
    formats?: FormatFields[]
) => {
    return (dispatch: Dispatch) => {
        dispatch(getDraftAgreementDetailsRequest())
        dispatch(showLoading());
        return SearchRequests.getDraftAgreement(dataSource, agreementId)
            .then((res: IAgreement) => {
                dispatch(getDraftAgreementDetailsSuccess(res));
                let newDraftAgreementTab: ITabReduxItem = {
                    title: AgreementDetailsMapper.getDraftAgreementTitle(res),
                    component: DRAFT_AGREEMENTS_MAINTENANCE_PAGE.component,
                    agreementMaintenanceState: {
                        loaded: false,
                        agreement: AgreementDetailsMapper.mapAgreementToState(res),
                        agreementBatchOperationInProgress: false
                    },
                    changesMade: false,
                    formatFields: formats
                };
                dispatch(mp_addTab(newDraftAgreementTab, true));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getDraftAgreementDetailsFailure(err, agreementId));
                dispatch(hideModal());
            });
    };
}

export const getRegisteredAgreementDetailsThunk = (
    dataSource: string,
    agreementId: number,
    lookupValues: ILookupDictionary,
    registeredAgreementsMaintenanceDataViewData: IMembersPortalComponentDataItem,
    formats?: FormatFields[]
) => {}


export const searchDraftWorksThunk = (
    draftWorkQuery: IDraftWorkSearchQuery,
    isModalSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchDraftWorksRequest(isModalSearch));

        let setTypeGroup = "";
        let setType = "";

        let workIDs = [];
        let worksSearchResult: IDraftWorkSearchResult[] = [];

        const contributorTypesToShow = ['E', 'SE', 'AM', 'A', 'CA', 'C', 'AR', 'AD', 'TR', 'SA', 'SR'];

        // Get Index Work Details
        return SearchRequests.getDraftWorks(draftWorkQuery)
            .then((result: IDraftWorkSearchResult[]) => {
                if (result == null || result.length == 0) {
                    dispatch(searchDraftWorksSuccess([]));
                    return [];
                }
                workIDs = result.filter(w => w.dataSource == "Intray").map(pw => pw.workID);
                worksSearchResult = result;
                // Get SetTypeGroup
                return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_GROUP_KEY, PORTAL_CONFIGURATION_GROUP)
            }).then(setTypeGroupConfiguration => {
                setTypeGroup = setTypeGroupConfiguration;
                // Get SetType
                return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_KEY, PORTAL_CONFIGURATION_GROUP)
            }).then(setTypeConfiguration => {
                setType = setTypeConfiguration;
                if (workIDs.length == 0) {
                    return new Promise<IDraftWorkSearchResult[]>((resolve, reject) => resolve([]))
                }
                return hydrateWorkDetailsFromDatabase(
                    workIDs, worksSearchResult, setType, setTypeGroup, contributorTypesToShow
                );
            }).then((result: IDraftWorkSearchResult[]) => {
                dispatch(searchDraftWorksSuccess(result));
            }).catch(err => {
                dispatch(searchDraftWorksFailure(err));
            });
    };
};

export const getDraftWorkDetailsThunk = (
    dataSource: string,
    workId: number,
    lookupValues: ILookupDictionary,
    draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem,
    formats?: FormatFields[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDraftWorkDetailsRequest())
        dispatch(showLoading());
        return SearchRequests.getDraftWork(dataSource, workId)
            .then((res: IWork) => {
                dispatch(getDraftWorkDetailsSuccess(res));

                let newDraftWorkTab: ITabReduxItem = {
                    title: WorkDetailsMapper.getOriginalFullTitleFromWorkNames(res.workNames, workId),
                    component: DRAFT_WORKS_MAINTENANCE_PAGE.component,
                    draftWorkMaintenanceState: {
                        loaded: false,
                        work: WorkDetailsMapper.mapWorkToWorkState(res, lookupValues),
                    },
                    changesMade: false,
                    formatFields: formats
                };
                dispatch(mp_addTab(newDraftWorkTab, true));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getDraftWorkDetailsFailure(err, workId));
                dispatch(hideModal());
            });
    };
}

export const getDraftSetListDetailsThunk = (
    dataSource: string,
    productCoreId: number,
    lookupValues: ILookupDictionary,
    formats?: FormatFields[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDraftSetListDetailsRequest())
        dispatch(showLoading());
        return SearchRequests.getDraftSetList(dataSource, productCoreId)
            .then(async (res: IProductCore) => {
                if (res.productWorks && res.productWorks.length > 0) {
                    const productWorks: IProductWork[] = [];

                    const intrayWorkIDs: number[] = res.productWorks.filter(pw => pw.workDataSource == "Intray").map(pw => pw.workCode)
                    const repertoireWorkIDs: number[] = res.productWorks.filter(pw => pw.workDataSource == "Repertoire").map(pw => pw.workCode)
                    const totalWorkIDs = intrayWorkIDs.length + repertoireWorkIDs.length;

                    for(var retries = 0; retries < 5; retries++){

                        var t = 500 * (2 ** retries);
                        await new Promise(resolve => setTimeout(resolve, t));

                        if (intrayWorkIDs.length > 0) {
                            let hydratedWorks = hydrateProductWorksFromIndex(intrayWorkIDs, 'Intray', res.productWorks);
                            
                            (await hydratedWorks).forEach(w => {
                                productWorks.push(w)
                                var index = intrayWorkIDs.indexOf(w.workCode)
                                intrayWorkIDs.splice(index, 1)
                            })

                        }

                        if (repertoireWorkIDs.length > 0) {
                            let hydratedWorks = hydrateProductWorksFromIndex(repertoireWorkIDs, 'Repertoire', res.productWorks);
                            (await hydratedWorks).forEach(w => {
                                productWorks.push(w)
                                var index = repertoireWorkIDs.indexOf(w.workCode)
                                repertoireWorkIDs.splice(index, 1)
                            })
                        }

                        if(productWorks.length == totalWorkIDs){
                            break;
                        }
                    }
                    res.productWorks = productWorks;
                }
                dispatch(getDraftSetListDetailsSuccess(res));

                let newDraftSetListTab: ITabReduxItem = {
                    title: ProductDetailsMapper.getOriginalTitleFromProductNames(res.productNames),
                    component: DRAFT_SET_LISTS_MAINTENANCE_PAGE.component,
                    draftSetListsMaintenanceState: {
                        loaded: false,
                        setLists: ProductDetailsMapper.mapProductToState(res, formats),
                        dataSource: dataSource === DataSource.Intray ? DataSource.Intray : DataSource.Repertoire
                    },
                    changesMade: false,
                    formatFields: formats
                };
                dispatch(mp_addTab(newDraftSetListTab, true));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getDraftSetListDetailsFailure(err, productCoreId));
                dispatch(hideModal());
            });
    };
}

export const saveDraftWorkThunk = (
    work: IWorkState,
    draftWorkMaintenancePageData: IMembersPortalComponentDataItem,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        dispatch(saveDraftWorkRequest())
        let draftWorkValidationMessages = MembersPortalWorkValidator.validateWork(work, draftWorkMaintenancePageData);

        if (draftWorkValidationMessages.length === 0) {
            return MembersPortalService.saveDraftWork(WorkDetailsMapper.mapWorkStateToWork(work, lookupValues, true))
                .then((res: any) => {
                    if (res.response && res.response.status === 400) {
                        const errorMessages: IValidationMessage[] = res.response.data.messages || [GENERIC_ERROR];
                        const saveResult: IWorkSaveResult = {
                            messages: errorMessages,
                            work: res.response.data.work,
                            saved: false,
                            success: false,
                            validationSuccess: false
                        }
                        dispatch(saveDraftWorkFailure(saveResult, lookupValues));
                    }

                    else {
                        if (res.status && res.status === 200) {
                            dispatch(saveDraftWorkSuccess(res.data, lookupValues));
                        }
                    }
                })
                .catch((error: any) => {
                    dispatch(saveDraftWorkFailure(error, lookupValues));
                })
                .finally(() => dispatch(hideModal()));
        }
        else {
            const result: IWorkSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: MembersPortalWorkValidator.validateWork(work),
                work: WorkDetailsMapper.mapWorkStateToWork(work, lookupValues, true)
            }
            dispatch(saveDraftWorkFailure(result, lookupValues));
            dispatch(hideModal());
        }
    }
}

export const saveDraftSetListThunk = (
    setList: IProductState,
    draftSetListsMaintenancePageData: IMembersPortalComponentDataItem,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        let draftSetListValidationMessages = MembersPortalSetListsValidator.validateSetLists(setList, draftSetListsMaintenancePageData);
        if (draftSetListValidationMessages.length === 0) {

            return MembersPortalService.saveDraftSetLists(ProductDetailsMapper.mapStateToProduct(setList, true))
                .then(async res => {
                    if (res.status !== 200 || !res.data.success) {
                        const errorMessages: IValidationMessage[] = res.data.messages || [GENERIC_ERROR];
                        const saveResult: IProductSaveResult = {
                            messages: errorMessages,
                            product: res.data.product,
                            saved: false,
                            success: false,
                            validationSuccess: false
                        }
                        dispatch(saveDraftSetListsFailure(saveResult));
                        dispatch(hideModal());
                    }
                    if (res.data.product.productWorks && res.data.product.productWorks.length > 0) {
                        const productWorks: IProductWork[] = [];

                        const intrayWorkIDs: number[] = res.data.product.productWorks.filter(pw => pw.workDataSource == "Intray").map(pw => pw.workCode)
                        const repertoireWorkIDs: number[] = res.data.product.productWorks.filter(pw => pw.workDataSource == "Repertoire").map(pw => pw.workCode)
                        const totalWorkIDs = intrayWorkIDs.length + repertoireWorkIDs.length;

                        for(var retries = 0; retries < 5; retries++){

                            var t = 500 * (2 ** retries);
                            await new Promise(resolve => setTimeout(resolve, t));

                            if (intrayWorkIDs.length > 0) {
                                let hydratedWorks = hydrateProductWorksFromIndex(intrayWorkIDs, 'Intray', res.data.product.productWorks);
                                (await hydratedWorks).forEach(w => {
                                    productWorks.push(w)
                                    var index = intrayWorkIDs.indexOf(w.workCode)
                                    intrayWorkIDs.splice(index, 1)
                                })

                            }

                            if (repertoireWorkIDs.length > 0) {
                                let hydratedWorks = hydrateProductWorksFromIndex(repertoireWorkIDs, 'Repertoire', res.data.product.productWorks);
                                (await hydratedWorks).forEach(w => {
                                    productWorks.push(w)
                                    var index = repertoireWorkIDs.indexOf(w.workCode)
                                    repertoireWorkIDs.splice(index, 1)
                                })
                            }

                            if(productWorks.length == totalWorkIDs){
                                break;
                            }
                        }
                        res.data.product.productWorks = productWorks;

                    }
                    dispatch(saveDraftSetListSuccess(res.data));
                    dispatch(hideModal());

                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(saveDraftSetListsFailure(err));
                })
                .finally(() => hideModal())
        }
        else {
            const result: IProductSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: MembersPortalSetListsValidator.validateSetLists(setList),
                product: ProductDetailsMapper.mapStateToProduct(setList)
            }
            dispatch(saveDraftSetListsFailure(result));
            dispatch(hideModal());
        }
    }
}

export const saveDraftAgreementThunk = (
    agreement: IAgreementState,
    draftAgreementMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem,
    lookupValues: ILookupDictionary,
    postAgreement: boolean,
    currentUserNumber: string,
    defaultTerritories?:any
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        let draftAgreementValidationMessages = MembersPortalAgreementValidator.validateAgreement(agreement, currentUserNumber, draftAgreementMaintenanceGeneralDataViewData);
        if (draftAgreementValidationMessages.length === 0) {


            return MembersPortalService.saveDraftAgreement(AgreementDetailsMapper.mapMembersStateToAgreement(agreement, lookupValues,defaultTerritories), postAgreement)
                .then(async res => {
                    if (res.status !== 200 || !res.data.success) {
                        const errorMessages: IValidationMessage[] = res.data.messages || [GENERIC_ERROR];
                        const saveResult: IAgreementSaveResult = {
                            messages: errorMessages as any,
                            agreement: res.data.agreement,
                            saved: false,
                            success: false,
                            validationSuccess: false
                        }
                        dispatch(saveDraftAgreementFailure(saveResult));
                        dispatch(hideModal());
                    }
                    dispatch(saveDraftAgreementSuccess(res.data));
                    dispatch(hideModal());

                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(saveDraftAgreementFailure(err));
                })
                .finally(() => hideModal())
        }
        else {
            const result: IAgreementSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: MembersPortalAgreementValidator.validateAgreement(agreement, currentUserNumber) as any,
                agreement: AgreementDetailsMapper.mapStateToAgreement(agreement)
            }
            dispatch(saveDraftAgreementFailure(result));
            dispatch(hideModal());
        }
    }
}


export const saveDraftLivePerformanceThunk = (
    usage: IUsageGroupState
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        let draftLivePerformanceValidationMessages = MembersPortalLivePerformanceValidator.validateLivePerformance(usage);
        if (draftLivePerformanceValidationMessages.length === 0) {
            return MembersPortalService.saveDraftLivePerformance(UsageDetailsMapper.mapStateUsageGroup(usage, true))
            .then(async res => {
                if (res.status !== 200 || !res.data.success) {
                    let errorMessages: IValidationMessage[] = [];

                    res.data.messages.map(m => errorMessages.push({ messageKey: m }));

                    errorMessages = errorMessages || [{ messageKey: GENERIC_ERROR }];
                    const saveResult: IUsageSaveResult = {
                        messages: errorMessages,
                        usageGroup: res.data.usageGroup,
                        saved: false,
                        success: false,
                        validationSuccess: false
                    }
                    dispatch(saveDraftLivePerformanceFailure(saveResult));
                    dispatch(hideModal());
                }
                dispatch(saveDraftLivePerformanceSuccess(res.data));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(saveDraftLivePerformanceFailure(err));
            })
            .finally(() => hideModal())
        }
        else{
            const saveResult: IUsageSaveResult = {
                messages: MembersPortalLivePerformanceValidator.validateLivePerformance(usage),
                usageGroup: UsageDetailsMapper.mapStateUsageGroup(usage, true),
                saved: false,
                success: false,
                validationSuccess: false
            }
            dispatch(saveDraftLivePerformanceFailure(saveResult));
            dispatch(hideModal());
        }
       
    }
}
export const saveContributorCommentThunk = (
    contributorID: number,
    comments: string
): ThunkAction<void, IAppState, null, Action<string>> => {

    return (dispatch: Dispatch) => {
        dispatch(saveContributorComment(contributorID, comments));
        dispatch(hideModal());
    }

}

export const searchRegisteredWorksThunk = (
    registeredWorkQuery: IRegisteredWorkSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchRegisteredWorksRequest());

        let setTypeGroup = "";
        let setType = "";

        let workIDs = [];
        let worksSearchResult: IRegisteredWorkSearchResult[] = [];

        const contributorTypesToShow = ['E', 'SE', 'AM', 'A', 'CA', 'C', 'AR', 'AD', 'TR', 'SA', 'SR'];

        // Get Index Work Details
        return SearchRequests.getRegisteredWorks(registeredWorkQuery)
            .then((result: IRegisteredWorkSearchResult[]) => {
                if (result == null || result.length == 0) {
                    dispatch(searchRegisteredWorksSuccess([]));
                    return [];
                }

                workIDs = result.filter(w => w.dataSource == "Repertoire").map(pw => pw.workID);
                worksSearchResult = result;

                // Get SetTypeGroup
                return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_GROUP_KEY, PORTAL_CONFIGURATION_GROUP)
            }).then(setTypeGroupConfiguration => {
                setTypeGroup = setTypeGroupConfiguration;
                // Get SetType
                return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_KEY, PORTAL_CONFIGURATION_GROUP)
            }).then(setTypeConfiguration => {
                setType = setTypeConfiguration;
                if (workIDs.length == 0) {
                    return new Promise<IRegisteredWorkSearchResult[]>((resolve, reject) => resolve([]))
                }

                return hydrateWorkDetailsFromDatabase(
                    workIDs, worksSearchResult, setType, setTypeGroup, contributorTypesToShow
                );

            })
            .then((result: IRegisteredWorkSearchResult[]) => {
                dispatch(searchRegisteredWorksSuccess(result));
            })
            .catch(err => {
                dispatch(searchRegisteredWorksFailure(err));
            });
    };
};


export const searchRegisteredSetListsThunk = (
    setListQuery: IRegisteredSetListsSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchRegisteredSetListsRequest());

        return SearchRequests.getSetLists(setListQuery)
            .then((result: IRegisteredSetListsSearchResults[]) => {
                if (result == null || result.length == 0) {
                    dispatch(searchRegisteredSetListsSuccess([]));
                } else {
                    dispatch(searchRegisteredSetListsSuccess(result));
                }
            }).catch(err => {
                dispatch(searchRegisteredSetListsFailure(err));
            });
    };
};


export const searchAllRegisteredWorksThunk = (
    allRegisteredWorkQuery: IAllRegisteredWorkSearchQuery,
    isModalSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchAllRegisteredWorksRequest(isModalSearch));

        let setTypeGroup = "";
        let setType = "";

        let workIDs = [];
        let worksSearchResult: IAllRegisteredWorkSearchResult[] = [];

        const contributorTypesToShow = ['E', 'SE', 'AM', 'A', 'CA', 'C', 'AR', 'AD', 'TR', 'SA', 'SR'];

        // Get Index Work Details
        return SearchRequests.getAllRegisteredWorks(allRegisteredWorkQuery)
            .then((result: IAllRegisteredWorkSearchResult[]) => {
                if (result == null || result.length == 0) {
                    dispatch(searchAllRegisteredWorksSuccess([]));
                    return [];
                }
                workIDs = result.filter(w => w.dataSource == allRegisteredWorkQuery.dataSource).map(pw => pw.workID);
                worksSearchResult = result;
                // Get SetTypeGroup
                return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_GROUP_KEY, PORTAL_CONFIGURATION_GROUP)
            }).then(setTypeGroupConfiguration => {
                setTypeGroup = setTypeGroupConfiguration;
                // Get SetType
                return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_KEY, PORTAL_CONFIGURATION_GROUP)
            }).then(setTypeConfiguration => {
                setType = setTypeConfiguration;
                if (workIDs.length == 0) {
                    return new Promise<IAllRegisteredWorkSearchResult[]>((resolve, reject) => resolve([]))
                }

                return hydrateWorkDetailsFromDatabase(
                    workIDs, worksSearchResult, setType, setTypeGroup, contributorTypesToShow
                );
            }).then((result: IAllRegisteredWorkSearchResult[]) => {
                dispatch(searchAllRegisteredWorksSuccess(result));
            }).catch(err => {
                dispatch(searchAllRegisteredWorksFailure(err));
            });
    };
};

export const getRegisteredWorkDetailsThunk = (
    dataSource: string,
    workId: number,
    lookupValues: ILookupDictionary,
    registeredWorkMaintenanceDataViewData: IMembersPortalComponentDataItem,
    formats?: FormatFields[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getRegisteredWorkDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getRegisteredWork(dataSource, workId)
            .then((res: IWork) => {
                dispatch(getRegisteredWorkDetailsSuccess(res));

                let newRegisteredWorkTab: ITabReduxItem = {
                    title: WorkDetailsMapper.getOriginalFullTitleFromWorkNames(res.workNames, workId),
                    component: REGISTERED_WORKS_VIEW_PAGE.component,
                    registeredWorkMaintenanceState: {
                        loaded: false,
                        work: WorkDetailsMapper.mapWorkToWorkState(res, lookupValues),
                    },
                    changesMade: false,
                    formatFields: formats
                };
                dispatch(mp_addTab(newRegisteredWorkTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getRegisteredWorkDetailsFailure(err, workId));
                dispatch(hideModal());
            });
    };
}

export const getAllRegisteredWorkDetailsThunk = (
    dataSource: string,
    workId: number,
    lookupValues: ILookupDictionary,
    registeredWorkMaintenanceDataViewData: IMembersPortalComponentDataItem,
    formats?: FormatFields[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAllRegisteredWorkDetailsRequest())
        dispatch(showLoading());
        return SearchRequests.getRegisteredWork(dataSource, workId)
            .then((res: IWork) => {
                dispatch(getAllRegisteredWorkDetailsSuccess(res));

                let newRegisteredWorkTab: ITabReduxItem = {
                    title: WorkDetailsMapper.getOriginalFullTitleFromWorkNames(res.workNames, workId),
                    component: REGISTERED_WORKS_VIEW_PAGE.component,
                    registeredWorkMaintenanceState: {
                        loaded: false,
                        work: WorkDetailsMapper.mapWorkToWorkState(res, lookupValues),
                    },
                    changesMade: false,
                    formatFields: formats
                };
                dispatch(mp_addTab(newRegisteredWorkTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getAllRegisteredWorkDetailsFailure(err, workId));
                dispatch(hideModal());
            });
    };
}


export const saveRegisteredWorkThunk = (
    work: IWorkState,
    registeredWorkMaintenancePageData: IMembersPortalComponentDataItem,
    lookupValues: ILookupDictionary
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(showLoading())
        dispatch(saveRegisteredWorkRequest())
        let registeredWorkValidationMessages = MembersPortalWorkValidator.validateWork(work, registeredWorkMaintenancePageData);

        if (registeredWorkValidationMessages.length === 0) {
            return MembersPortalService.saveRegisteredWork(WorkDetailsMapper.mapWorkStateToWork(work, lookupValues, true))
                .then((res) => {
                    if (res.status !== 200 || !res.data.success) {
                        const errorMessages: IValidationMessage[] = res.data.messages || [GENERIC_ERROR];
                        const saveResult: IWorkSaveResult = {
                            messages: errorMessages,
                            work: res.data.work,
                            saved: false,
                            success: false,
                            validationSuccess: false
                        }
                        dispatch(saveRegisteredWorkFailure(saveResult, lookupValues));
                        dispatch(hideModal());
                    }
                    dispatch(saveRegisteredWorkSuccess(res.data, lookupValues));
                    dispatch(hideModal());
                })
                .catch(err => {
                    dispatch(hideModal());
                    dispatch(saveRegisteredWorkFailure(err, lookupValues));
                })
        }
        else {
            const result: IWorkSaveResult = {
                saved: false,
                success: false,
                validationSuccess: false,
                messages: MembersPortalWorkValidator.validateWork(work),
                work: WorkDetailsMapper.mapWorkStateToWork(work, lookupValues, true)
            }
            dispatch(saveRegisteredWorkFailure(result, lookupValues));
            dispatch(hideModal());
        }
    }
}

export const loadMembersPortalFromPathThunk = (pathInfo: IMembersPortalPathInfo, currentUserNameNumber: string, location: Location, tabs?: ITabReduxItem[], lookups?: ILookupDictionary): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        if (!pathInfo || !pathInfo.submodule)
            return null;
        const { submodule } = pathInfo;

        switch (submodule) {
            case 'draftWorks':
                dispatch(mp_addTab(DRAFT_WORKS_PAGE));
                break;
            case 'myRegisteredWorks':
                dispatch(mp_addTab(REGISTERED_WORKS_PAGE));
                break;
            case 'allRegisteredWorks':
                dispatch(mp_addTab(ALL_REGISTERED_WORKS_PAGE));
                break;
            case 'statements':
                dispatch(mp_addTab(MEMBER_STATEMENTS_PAGE));
                break;
            case 'draftSetLists':
                dispatch(mp_addTab(DRAFT_SET_LISTS_PAGE));
                break;
            case 'registeredSetLists':
                dispatch(mp_addTab(REGISTERED_SET_LISTS_PAGE));
                break;
            case 'mypackages':
                    dispatch(mp_addTab(MY_PACKAGES_PAGE))
                break;
            case 'livePerformanceSubmission':
                dispatch(mp_addTab(LIVE_PERFORMANCE_SUBMISSION_PAGE));
                break;
            case 'unpaidClaimsMembers':
                dispatch(mp_addTab(UNPAID_CLAIMS_PAGE));
                break;
            case 'draftAgreements':
            case 'agreements':
                dispatch(mp_addTab(DRAFT_AGREEMENTS_PAGE));
                break;
            case 'myRegisteredAgreement':
                dispatch(mp_addTab(REGISTERED_AGREEMENTS_PAGE));
                break;
            case 'submitNewLivePerformance':
                let newPerfTab = tabs && tabs.findIndex(x => x.title === 'Submit New Live Performance')
                if (newPerfTab >= 0)
                    dispatch(mp_openTab(newPerfTab))
                else {
                    SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                        .then(formats => {
                            const distribution = SearchRequests.getLivePerformancesDistribution()
                                .then(distribution => {
                                    dispatch(createNewDraftLivePerformance(lookups, true, null, formats, distribution ? distribution.distributionCode : "", currentUserNameNumber));
                                });
                        });
                }
                break;
            case 'registerNewAgreement':
                        dispatch(createNewDraftAgreement(lookups, currentUserNameNumber, true, null))
                break;
            case 'registerNewWork':
                SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                    .then(formats => {
                        dispatch(createNewDraftWork(lookups, currentUserNameNumber, true, null, formats))
                    });
                break;
            case 'registerNewSetLists':
                let newSetListTab = tabs && tabs.findIndex(x => x.title === 'New Draft Set Lists')
                if (newSetListTab >= 0)
                    dispatch(mp_openTab(newSetListTab))
                else
                    dispatch(createNewDraftSetLists(lookups, currentUserNameNumber, true, null));
                break;
            case 'claimsHistory':
                dispatch(mp_addTab(CLAIMS_HISTORY_PAGE));
                break;
        }
    }
};

export const deleteDraftWorkThunk = (
    dataSource: string,
    workId: number,
    activeTab: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteDraftWorkRequest());
        dispatch(showLoading());
        return MembersPortalService.deleteWork(workId)
            .then(result => {
                dispatch(deleteDraftWorkSuccess(result.data));
            })
            .finally(() => {
                dispatch(mp_removeTab(activeTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(deleteDraftWorkFailure(err));
                dispatch(hideModal());
            });
    }
};

export const deleteDraftSetListThunk = (
    dataSource: string,
    setListId: number,
    activeTab: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteDraftSetListRequest());
        dispatch(showLoading());
        return MembersPortalService.deleteSetList(setListId)
            .then(result => {
                dispatch(deleteDraftSetListSuccess(result.data));
            })
            .finally(() => {
                dispatch(mp_removeTab(activeTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(deleteDraftSetListFailure(err));
                dispatch(hideModal());
            });
    }
};

export const searchContributorsThunk = (
    searchBody: IContributorSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        searchBody.isMembersPortal = true;
        dispatch(searchContributorsRequest());
        return RepertoireSearchRequests.getContributors(searchBody)
            .then((res: IContributorSearchResult[]) => {
                let result = res.filter(x => x.type !== 'PG')
                dispatch(searchContributorsSuccess(result));
            })
            .catch(err => {
                dispatch(searchContributorsFailure(err));
            });
    };
};

export const updateWorkContributorIPThunk = (
    dataSource: DataSource,
    workContributor: IContributorItem, ip: IP
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(updateWorkContributorIPRequest());
        RepertoireService.linkedAgreements({
            dataSource: dataSource,
            ipBaseNumber: ip.ipBaseNumber,
            setTypeGroup: workContributor.setTypeGroup,
            setType: workContributor.setType
        })
            .then(res => {
                dispatch(updateWorkContributorIPSuccess(workContributor.workContributorID, ip, res.data));
            })
            .catch((error: any) => {
                dispatch(updateWorkContributorIPFailure());
            });
    };
};

export const searchArtistsThunk = (
    artist: IArtistSearchQuery,
    dataSource: string,
    keepModalOpenAfterSearch?: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchArtistsRequest());
        if (!keepModalOpenAfterSearch)
            dispatch(showLoading());
        return SearchRequests.getArtists(artist, dataSource)
            .then((res: IArtist[]) => {
                dispatch(searchArtistsSuccess(res));
                if (!keepModalOpenAfterSearch)
                    dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchArtistsFailure(err));
                if (!keepModalOpenAfterSearch)
                    dispatch(hideModal());
            });
    };
};

export const deleteRegisteredWorkThunk = (
    dataSource: string,
    workId: number,
    activeTab: number,
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(deleteRegisteredWorkRequest());
        dispatch(showLoading());
        return MembersPortalService.deleteWork(workId)
            .then(result => {
                dispatch(deleteRegisteredWorkSuccess(result.data));
            })
            .finally(() => {
                dispatch(mp_removeTab(activeTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(deleteRegisteredWorkFailure(err));
                dispatch(hideModal());
            });
    }
};

export const removeWorkAttachmentIfExistsThunk = (attachedFile: IAttachedFile, source: DataSource): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(removeWorkAttachmentRequest());
        const model: IAddWorkAttachmentModel = {
            attachedFile: attachedFile,
            dataSource: source
        }
        return MembersPortalService.removeWorkAttachment(model)
            .then((res: boolean) => {
                if (res) {
                    dispatch(removeWorkAttachmentSuccess(model.attachedFile));
                }
            })
            .catch((error: any) => {
                dispatch(removeWorkAttachmentFailure(error));
            });
    }
}




async function hydrateWorkDetailsFromDatabase(workIDs: number[], currentWorks: IDraftWorkSearchResult[], setType: string, setTypeGroup: string, contributorTypesToShow: string[]): Promise<IMemberWorkSearchResult[]> {
    return new Promise<IDraftWorkSearchResult[]>(async (resolve, reject) => {
        if (currentWorks == null || currentWorks.length == 0)
            return [];

        const workDetailsQuery: IWorkDetailsQuery = {
            workIDs: workIDs,
            currentWorks: currentWorks,
            dataSource: currentWorks[0].dataSource,
            setType: setType,
            setTypeGroup: setTypeGroup,
            contributorTypesToShow: contributorTypesToShow
        };

        let hydratedWorks: IDraftWorkSearchResult[] = currentWorks;
        let contributorsResult: IWorkContributor[]
        let workStatus: IMemberWorkStatus[] = [];

        // Get Contributor Details
        return await MembersPortalService.getContributors(workDetailsQuery)
            .then((result: IWorkContributor[]) => {
                contributorsResult = result;
                return MembersPortalService.getStatus(workDetailsQuery)
            }).then(result => {
                workStatus = result;
                hydratedWorks.forEach((w) => {

                    const currentWorkIndexContributors = w.contributors;
                    const currentWorkIndexRoles = w.role;
                    const currentWorkIndexContributorIDs = w.workContributorIDs;

                    let contributors = [];
                    let roles = [];
                    let contributorIDs = [];

                    // Reset model as was being parsed as null and can't .push() to null
                    w.ipNumber = [];
                    w.perfShare = [];
                    w.mechShare = [];


                    const currentWorkDatabaseContributors: IWorkContributor[] = contributorsResult.filter(x => x.workID == w.workID);

                    for (let i = 0; i < currentWorkDatabaseContributors.length; i++) {
                        let contributorIndex = currentWorkIndexContributorIDs.indexOf(currentWorkDatabaseContributors[i].workContributorID);
                        if (contributorIndex > -1) {
                            if (currentWorkIndexContributors[contributorIndex] != null) { contributors.push(currentWorkIndexContributors[contributorIndex]) };
                            if (currentWorkIndexRoles[contributorIndex] != null) { roles.push(currentWorkIndexRoles[contributorIndex]) };
                            if (currentWorkIndexContributorIDs[contributorIndex] != null) { contributorIDs.push(currentWorkIndexContributorIDs[contributorIndex]) };

                            let databaseContributor = currentWorkDatabaseContributors.filter(x => x.workContributorID == currentWorkIndexContributorIDs[contributorIndex])[0];

                            if (databaseContributor.ipiNumber != null) { w.ipNumber.push(databaseContributor.ipiNumber); } else { w.ipNumber.push(0) }
                            if (databaseContributor.performingShare != null) { w.perfShare.push(databaseContributor.performingShare); } else { w.perfShare.push(0) }
                            if (databaseContributor.mechanicalShare != null) { w.mechShare.push(databaseContributor.mechanicalShare); } else { w.mechShare.push(0) }
                        }
                    }

                    w.contributors = contributors;
                    w.role = roles;
                    w.workContributorIDs = contributorIDs;



                });
                return resolve(hydratedWorks);
            }).catch(err => {
                reject(err);
            })
    });
}


export const searchRequestAccessIPThunk = (
    searchBody: IContributorSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchRequestAccessIPRequest());
        dispatch(showLoading());

        return RepertoireSearchRequests.getContributors(searchBody)
            .then((res: IContributorSearchResult[]) => {
                dispatch(searchRequestAccessIPRequestSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(searchRequestAccessIPRequestFailure(err));
                dispatch(hideModal());
            });
    };
};

export const requestViewAsAccessThunk = (
    requestAccess: IContributorItem,
    userName: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(requestViewAsAccess());
        dispatch(showLoading());

        return AccountService.requestViewAsAccess(requestAccess.ipBaseNumber, userName)
            .then((res: boolean) => {
                dispatch(requestViewAsAccessSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(requestViewAsAccessFailure(err));
                dispatch(hideModal());
            });
    };
};

export const getViewAsAccessRequestsThunk = (
    userName: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getViewAsAccessRequests());
        dispatch(showLoading());

        return AccountService.getViewAsAccessRequests(userName)
            .then((res: IViewAsAccessRequest[]) => {
                dispatch(getViewAsAccessRequestsSuccess(res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getViewAsAccessRequestsFailure(err));
                dispatch(hideModal());
            });
    };
};


export const saveViewAsAccessThunk = (
    viewAsAccounts: IViewAsAccessRequest[],
    userName: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(saveViewAsAccess());
        dispatch(showLoading());

        let defaultAccount = viewAsAccounts.filter(x => x.defaultAccess == true)[0]
        if (!defaultAccount) {
            defaultAccount = viewAsAccounts.filter(x => x.viewAs === true)[0]
        }

        return AccountService.setViewAsDefaultAccount(defaultAccount?.accountNumber, userName)
            .then((res: boolean) => {
                dispatch(saveViewAsAccessSuccess(viewAsAccounts, res));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(saveViewAsAccessFailure(err));
                dispatch(hideModal());
            });
    };
};

export const cancelAccessRequestThunk = (
    accountNumber: string,
    userName: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(cancelAccessRequest());
        dispatch(showLoading());

        return AccountService.cancelAccessRequest(accountNumber, userName)
            .then((res: boolean) => {
                dispatch(cancelAccessRequestSuccess(res, accountNumber));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(cancelAccessRequestFailure(err));
                dispatch(hideModal());
            });
    };
};

export const getMyApplicationsThunk = (
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getMyApplications());

        return AccountService.getMyApplications()
            .then((res: IApplication[]) => {
                dispatch(getMyApplicationsSuccess(res));
            })
            .catch(err => {
                dispatch(getMyApplicationsFailure(err));
            });
    };
};

export const getMyCasesThunk = (
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getMyCases());

        return AccountService.getMyCases()
            .then((res: ICase[]) => {
                dispatch(getMyCasesSuccess(res));
            })
            .catch(err => {
                dispatch(getMyCasesFailure(err));
            });
    };
};


export const getMemberStatementDetailsThunk = (
    paymentSearchResult: IMemberStatementSearchResult,
    lookupValues: ILookupDictionary,
    userNameNumber: string,
    formats?: FormatFields[],
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        let res = paymentSearchResult;
        dispatch(showLoading());
        return MembersPortalService.getStatementPaymentRunWorks(paymentSearchResult.paymentRunID, userNameNumber)
            .then((paymentRunWorks: IMemberStatementPaymentRunWork[]) => {
                res.paymentRunDetails.forEach((paymentRunWork) => {
                    paymentRunWork.paymentRunWorks = paymentRunWorks.filter(x => x.distributionID == paymentRunWork.distributionID && x.poolID == paymentRunWork.poolID);
                });

                let memberStatementDetailsTab: ITabReduxItem = {
                    title: res.paymentRunCode,
                    component: MEMBER_STATEMENTS_DETAILS_PAGE.component,
                    memberStatementDetailsState: {
                        loaded: false,
                        payment: res,
                    },
                    changesMade: false,
                    formatFields: formats
                };
                //dispatch(getMemberStatementDetailsSuccess(res))
                dispatch(mp_addTab(memberStatementDetailsTab));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getMemberStatementDetailsFailure(err));
                dispatch(hideModal());
            })
    }
}

export const searchLivePerformancesThunk = (
    livePerformancesQuery: ILivePerformanceSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchDraftSetListsRequest());

        return SearchRequests.getLivePerformances(livePerformancesQuery)
            .then((result: ILivePerformancesSearchResult) => {
                dispatch(searchLivePerformancesSuccess(result));
            }).catch(err => {
                dispatch(searchLastPercentageFailure(err));
            });
    };
};

export const getLivePerformanceDetailsThunk = (
    usageId: string,
    formats?: FormatFields[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDraftSetListDetailsRequest())
        dispatch(showLoading());
        return SearchRequests.getLivePerformance(usageId)
            .then((res: IUsageGroup) => {
                dispatch(getLivePerformanceDetailsSuccess(res));
                let newLivePerformanceTab: ITabReduxItem = {
                    title: UsageDetailsMapper.getUsageTitle(res),
                    component: LIVE_PERFORMANCE_MAINTENANCE_PAGE.component,
                    livePerformanceMaintenanceState: {
                        loaded: false,
                        usageGroup: UsageDetailsMapper.mapUsageToState(res, [], [], true),
                        dataSource: undefined
                    },
                    changesMade: false,
                    formatFields: formats
                };
                dispatch(mp_addTab(newLivePerformanceTab, true));
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(getLivePerformanceDetailsFailure(err, usageId));
                dispatch(hideModal());
            });
    };
}

export const getShareDecimalsToDisplayThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getShareDecimalsToDisplayRequest());
        return SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_SHARE_DECIMALS_TO_DISPLAY, WORK_MAINTENANCE_GROUP)
            .then((res: number) => {
                dispatch(getShareDecimalsToDisplaySuccess(res));
            })
            .catch((error: any) => {
                dispatch(getShareDecimalsToDisplayFailure(error));
            });
    }
}

export const refreshWorkDetailsThunk = (
    dataSource: string,
    workID: number,
    lookups: ILookupDictionary,
    otherIndicatorsWorkFlagTypes: string[],
    activeTabItem: ITabReduxItem,
    formats: FormatFields[],
    readonlyIndicatorsWorkFlagTypes: string[]
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(refreshWorkDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getDraftWork(dataSource, workID)
            .then((res: IWork) => {
                dispatch(refreshWorkDetailsSuccess(res));
                let tab: ITabReduxItem = {
                    ...activeTabItem,
                    title: WorkDetailsMapper.getOriginalFullTitleFromWorkNames(res.workNames, res.workID),
                    draftWorkMaintenanceState: {
                        ...activeTabItem.draftWorkMaintenanceState,
                        loaded: false,
                        work: WorkDetailsMapper.mapWorkToWorkState(res, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes),
                        isNew: false
                    },
                    changesMade: false
                };
                dispatch(replaceTab(tab, activeTabItem.title))
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(refreshWorkDetailsFailure(err, workID));
                if (err == 404) {
                    dispatch(showModal(WORK_NOT_FOUND_VIEW, null, workID, true, "Work not found"));
                }
            });
    };
};

export const refreshAgreementDetailsThunk = (
    dataSource: string,
    agreementID: number,
    activeTabItem: ITabReduxItem,
    formats: FormatFields[],
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(refreshAgreementDetailsRequest());
        dispatch(showLoading());
        return SearchRequests.getDraftAgreement(dataSource, agreementID)
            .then((res: IAgreement) => {
                dispatch(refreshAgreementDetailsSuccess(res));
                let tab: ITabReduxItem = {
                    ...activeTabItem,
                    title: res.ipBaseNumber,
                    agreementMaintenanceState: {
                        loaded: false,
                        agreement: AgreementDetailsMapper.mapAgreementToState(res),
                        agreementBatchOperationInProgress: false
                    },
                    changesMade: false,
                    formatFields: formats
                };
                dispatch(replaceTab(tab, activeTabItem.title))
                dispatch(hideModal());
            })
            .catch(err => {
                dispatch(hideModal());
                dispatch(refreshAgreementDetailsFailure(err, agreementID));
                if (err == 404) {
                    dispatch(showModal(WORK_NOT_FOUND_VIEW, null, agreementID, true, "Work not found"));
                }
            });
    };
};

export const removeAgreementAttachmentIfExistsThunk = (attachedFile: IAttachedFile, source: DataSource): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(removeAgreementAttachmentRequest());
        const model: IAddWorkAttachmentModel = {
            attachedFile: attachedFile,
            dataSource: source
        }
        return MembersPortalService.removeAgreementAttachment(model)
            .then((res: boolean) => {
                if (res) {
                    dispatch(removeAgreementAttachmentSuccess(model.attachedFile));
                }
            })
            .catch((error: any) => {
                dispatch(removeAgreementAttachmentFailure(error));
            });
    }
}


export const getCRMIntegratedEnabledThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        return SearchRequests.getConfigurationParameter(CRM_INTEGRATED_KEY,CUSTOMER_GROUP)
            .then((res: any) => {                  
                if (res == 1) {
                    SearchRequests.getConfigurationParameter(POWER_PORTAL_BASE_URL_KEY, CUSTOMER_GROUP)
                        .then((res: any) => {  
                            dispatch(getCRMPowerPortalURLSuccess(res));                            
                            AccountService.getMyApplications()
                                .then((res: IApplication[]) => {
                                    dispatch(getMyApplicationsSuccess(res));
                                    AccountService.getMyCases()
                                        .then((res: ICase[]) => {
                                            dispatch(getMyCasesSuccess(res));
                                        })
                                        .catch(err => {
                                            dispatch(getMyCasesFailure(err));
                                        });
                                })
                        })
                        .then(() => {
                            dispatch(getCRMIntegratedEnabledSuccess(res));
                        })
                        .catch(err => {
                            dispatch(getMyApplicationsFailure(err));
                        });
                }
                else{
                    dispatch(getCRMIntegratedEnabledSuccess(res));
                }
            })
            .catch((error: any) => {
                dispatch(getCRMIntegratedEnabledFailure(error));
            });
    }
}

async function hydrateProductWorksFromIndex(workIDs: number[], dataSource: string, productWorks: IProductWork[]): Promise<IProductWork[]> {
    return new Promise<IProductWork[]>(async (resolve, reject) => {
        const productWorksToReturn: IProductWork[] = [];
        const workSearchQuery: IMatchWorksSearchQuery =
        {
            workIDs: workIDs,
            title: "",
            number: "",
            artist: "",
            writers: "",
            societyAccountNumber: "",
            workBatchID: "",
            dataSource: dataSource,
            hasOpenWorkflow: false
        };

        await SearchRequests.getSetListWorks(workSearchQuery)
            .then((result: IMatchWorksSearchResult[]) => {
                result.forEach((w) => {
                    const currentProductWorks: IProductWork[] = productWorks.filter(x => x.workCode === w.workID);

                    currentProductWorks.forEach(currentProductWork => {
                        let status = "Registered";
                        if (w.dataSource == "Intray") {
                            if (w.isDraftSubmitted)
                                status = "Draft Submitted";
                            else status = "Draft";
                        }
                        const hydratedWork: IProductWork = {
                            productWorkID: currentProductWork.productWorkID,
                            workDataSource: dataSource,
                            duration: currentProductWork.duration,
                            workCode: currentProductWork.workCode,
                            musicUsage: currentProductWork.musicUsage,
                            title: w.title.find(t => t !== undefined && t != null) ?? "",
                            number: w.number.find(t => t !== undefined && t != null) ?? "",
                            writer: w.writers.find(t => t !== undefined && t != null) ?? "",
                            artist: w.artists.find(a => a != undefined && a != null) ?? "",
                            status
                        }
                        productWorksToReturn.push(hydratedWork);
                    });
                });
                return resolve(productWorksToReturn);

            })
            .catch(err => {
                reject(err);
            });
    });
}
