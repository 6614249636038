import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import {
    hideModal,
    showModal,
    showUpdateFieldsModal
} from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { setEditableFields, updateArtistField } from "../../../../redux/reducers/RepertoireReducer";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IFieldSettingViewModalProps } from "../../../components/modalViews/fieldSettingView/FieldSettingView";
import { getLookupDefault, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { ALL_ROLES, ARTIST_FIRST_NAME_STATE_KEY, ARTIST_LAST_NAME_STATE_KEY, ARTIST_MAINTENANCE_GENERAL_DATA_VIEW, ARTIST_TYPE_STATE_KEY, COMPONENT_INSTANCE_ARTISTS, CONFIGURE_ARTISTS_ROLE, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, TITLE_EDITABLE_FIELDS_VIEW } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IArtistStateKeys } from "../../../../redux/types/IArtistState";
import { WORK_PERFORMER_LOOKUP } from "../../../../lookup/Consts";

export interface IArtistMaintenanceGeneralDataViewProps {
    artistMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    type: string;
    lastName: string;
    firstName: string;
    updateArtistField: typeof updateArtistField;
    changesMade: boolean;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: IRepertoireField[];
    roles?: string[];
}

interface IArtistMaintenanceGeneralDataViewState {
    loaded: boolean;
}

export default class ArtistMaintenanceGeneralDataView extends React.PureComponent<
    IArtistMaintenanceGeneralDataViewProps,
    IArtistMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: IArtistMaintenanceGeneralDataViewProps) {
        super(props);
        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            artistMaintenanceGeneralDataViewData: { fields }
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], ARTIST_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_ARTISTS, ARTIST_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableEditableFieldsData,
            setEditableFields,
        } = this.props;

        setEditableFields(ARTIST_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_ARTISTS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableEditableFieldsData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    render() {
        const {
            artistMaintenanceGeneralDataViewData,
            firstName,
            lastName,
            type,
            changesMade,
            updateArtistField,
            isReadonly,
            lookupValues
        } = this.props;

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            updateArtistField(value, name as IArtistStateKeys);
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_ARTISTS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        if (loaded) {
            return (
                <div className="">
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">General</h1>
                            <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Artist Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedTextDataInput
                                label={this.viewData[ARTIST_FIRST_NAME_STATE_KEY]}
                                fieldName={ARTIST_FIRST_NAME_STATE_KEY}
                                changeData={changeData}
                                value={firstName}
                                isHidden={!this.viewData[ARTIST_FIRST_NAME_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedTextDataInput
                                label={this.viewData[ARTIST_LAST_NAME_STATE_KEY]}
                                fieldName={ARTIST_LAST_NAME_STATE_KEY}
                                changeData={changeData}
                                value={lastName}
                                isHidden={!this.viewData[ARTIST_LAST_NAME_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[ARTIST_TYPE_STATE_KEY]}
                                fieldName={ARTIST_TYPE_STATE_KEY}
                                value={type ? type : getLookupDefault(WORK_PERFORMER_LOOKUP, lookupValues)}
                                changeData={changeData}
                                options={getLookupValues(WORK_PERFORMER_LOOKUP, lookupValues)}
                                allowNull={false}
                                readOnly={isReadonly}
                                isHidden={!this.viewData[ARTIST_TYPE_STATE_KEY]} />
                        </div>
                    </div>
                </div>
            );
        }
        return <div />;
    }
}