import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import IAppState from "../../redux/types/IAppState";
import { addTab, createNewPaymentRun, resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import {
    updateUserPreferencesThunk,
    searchPaymentRunsThunk,
    getPaymentRunDetailsThunk,
    searchPaymentRunVersionHistoryThunk
} from "../../redux/thunks/RepertoireThunks";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IPaymentRunSearchQuery } from "../types/IPaymentRunSearchQuery";
import PaymentRunPage from "./PaymentRunPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { FormatFields } from "../../redux/types/FormatFields";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { ITabProps } from "../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        paymentRunPageData: state.repertoire.componentData.PaymentRunPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        paymentRunSearchResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].paymentRunSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'paymentRuns')[0]?.indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'paymentRuns')[0]?.indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'paymentRuns')[0]?.resultsPerSection,
        currentPage: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage : undefined,
        paymentRunMaintenanceGeneralDataViewData: state.repertoire.componentData.PaymentRunMaintenanceGeneralDataView,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        roles: state.account.roles,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        searchPaymentRuns: (query: IPaymentRunSearchQuery) =>
            dispatch<any>(searchPaymentRunsThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => 
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getPaymentRunDetails: (paymentRunID: number, lookupValues: ILookupDictionary) => dispatch<any>(getPaymentRunDetailsThunk(paymentRunID, lookupValues)),
        addNewPaymentRun: (lookups: ILookupDictionary, isNew?: boolean, paymentRunMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) =>
            dispatch(createNewPaymentRun(lookups, isNew, paymentRunMaintenanceGeneralDataViewData)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        searchPaymentRunVersionHistory: (paymentRunId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) =>
            dispatch<any>(searchPaymentRunVersionHistoryThunk(paymentRunId, lookups, actionList, formats)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => 
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
    })
)(PaymentRunPage);
