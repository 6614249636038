import * as React from "react";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import { DRAFT_AGREEMENT_SUBMISSION_JOB_TYPE } from "../../Consts";
import { HIGH_WATER_MARK } from "../../../repertoire/Consts";
import ActionButton from "../../../repertoire/components/actionButton/ActionButton";
import {
    triggerManualJob,
} from "../../../redux/thunks/HomeThunks";
import DatePickerDataInput from "../../../repertoire/components/dateDataInput/DatePickerDataInput";

export interface IDraftAgreementSubmissionPostingJobParametersProps {
    createNewJob: () => any;
    getDaspHighWaterMark?: () => void;
    daspHighWaterMark?: any;
    setDaspHighWaterMark?: (hwm: string) => void;
    triggerManualJob: typeof triggerManualJob;
    updateManualJobParams?: (params: any) => void;
}


const DraftAgreementSubmissionPostingJobParameters = (props: IDraftAgreementSubmissionPostingJobParametersProps) => {

    React.useEffect(() => {
        const { getDaspHighWaterMark } = props;
        getDaspHighWaterMark();
    }, [])

    const handleHighWaterMarkChange = (value: string | boolean | Date, fieldname: string) => {
        const { setDaspHighWaterMark } = props;
        if (value) setDaspHighWaterMark(value.toString());
    }

    const onClickSave = () => {
        const { triggerManualJob, createNewJob, updateManualJobParams } = props;
        const { daspHighWaterMark } = props;

        const highWaterMark = daspHighWaterMark;

        const manualJobParameters: ITriggerManualJob = {
            jobType: DRAFT_AGREEMENT_SUBMISSION_JOB_TYPE,
            jobParameters: JSON.stringify({
                highWaterMark,
            }),
        };
        createNewJob();
        triggerManualJob(manualJobParameters);
        if (updateManualJobParams) {
            updateManualJobParams('');
        }
    };

    const { daspHighWaterMark } = props;

    return (
        <>
            <div className="row">
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <DatePickerDataInput
                        value={daspHighWaterMark}
                        fieldName={HIGH_WATER_MARK}
                        changeData={handleHighWaterMarkChange}
                        label={HIGH_WATER_MARK}
                        isHidden={false}
                        readOnly={false}
                    />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                    <ActionButton buttonText={"Save And Close"} buttonAction={() => onClickSave()} />
                </div>
            </div>
        </>
    );
    
}

export default DraftAgreementSubmissionPostingJobParameters;

