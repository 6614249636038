import * as React from "react";
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { updateUsagePoolField } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import CheckboxDataInput, { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { INTEGER_INPUT, POOL_ALLOCATE_SPECIFIC_ROLES_STATE_KEY, POOL_ALLOCATE_SPECIFIC_SOCIETIES_STATE_KEY, POOL_ALLOCATION_SETTINGS_KEY, POOL_CUSTOM_NOTEBOOK_STATE_KEY, POOL_DEFAULT_DURATION_PERCENTAGE_STATE_KEY, POOL_MISSING_SHARES_STATE_KEY, POOL_NON_SOCIETY_IPS_STATE_KEY, POOL_PD_IPS_STATE_KEY, POOL_PD_WORK_PERCENT_STATE_KEY, POOL_PERCENTAGE_DURATION_STATE_KEY, POOL_RECIPROCAL_AGREEMENTS_STATE_KEY, POOL_REFERENCE_IPS_STATE_KEY, POOL_UNIDENTIFIED_USAGE_STATE_KEY, POOL_WARSAW_RULE_STATE_KEY, REPERTOIRE_PAGE_FORM_ITEM_SIZE, TEXT_INPUT, USE_ADVERTISEMENT_DURATION_FOR_MATCHED_USAGE, USE_CUE_SHEET_DURATION_FOR_MATCHED_USAGE } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IUsagePoolAllocationSettingsDataItems } from "../../../types/usageTypes/IUsagePoolAllocationSettingsDataItems";
import { getLookupDefaultMultiSelect, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { AGENCY_LOOKUP, WORK_CONTRIBUTOR_TYPE_LOOKUP } from "../../../../lookup/Consts";

export interface IPoolMaintenanceAllocationSettingsGridProps {
    allocationSettingsGridViewData?: IRepertoireComponentDataItem;
    isReadonly: boolean;
    poolAllocationSettings: IUsagePoolAllocationSettingsDataItems;
    updateUsagePoolField: typeof updateUsagePoolField;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    lookupValues: ILookupDictionary;
}

export interface IPoolMaintenanceAllocationSettingsGridState {
    loaded: boolean;
    isAllocateSpecificRoles: boolean;
    isAllocateSpecificSocieties : boolean;
    rolesType:any[];
    societiesType:any[];
}

export default class PoolMaintenanceAllocationSettingsGrid extends React.Component<IPoolMaintenanceAllocationSettingsGridProps, IPoolMaintenanceAllocationSettingsGridState>{
    viewData;
    constructor(props: IPoolMaintenanceAllocationSettingsGridProps) {
        super(props);

        this.state = { loaded: false, isAllocateSpecificRoles: false, isAllocateSpecificSocieties:false, rolesType:[], societiesType:[] };
        this.viewData = {};
    }


    componentDidMount() {
        const {
            allocationSettingsGridViewData: { fields },
            poolAllocationSettings
        } = this.props;

        if (fields) {
            let visibleFields = fields.filter(field => !field.hidden);

            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
        if (poolAllocationSettings.allocateSpecificRoles.value) {
            this.setState({ isAllocateSpecificRoles: poolAllocationSettings.allocateSpecificRoles.value.length > 0  });
        }

        if (poolAllocationSettings.allocateSpecificSocieties.value) {
            this.setState({ isAllocateSpecificSocieties: poolAllocationSettings.allocateSpecificSocieties.value.length > 0  });
        }
    }

    render() {
        if (!this.state.loaded)
            return null;

        const flexColumn = {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        };

        const dropdownContainer = {
            marginTop: '10px'
          }
       

        const { poolAllocationSettings, isReadonly, tabs, activeTab,lookupValues,allocationSettingsGridViewData } = this.props;
        const lookupWorkContributorsTypeVals = getLookupValues(WORK_CONTRIBUTOR_TYPE_LOOKUP, lookupValues);
        const lookupAgencyTypeVals = getLookupValues(AGENCY_LOOKUP, lookupValues);
        const {
            unidentifiedUsage,
            nonSocietyIPs,
            referenceIPs,
            missingShares,
            warsawRule,
            percentageDurationForUnidentifiedUsage,
            defaultDurationPercentage,
            reciprocalAgreements,
            pdWork100Percent,
            pdiPs,
            useCueSheetDurationForMatchedUsage,
            useAdvertisementDurationForMatchedUsage,
            customNotebook,
            allocateSpecificRoles,
            allocateSpecificSocieties
        } = poolAllocationSettings;

        const { isAllocateSpecificRoles, isAllocateSpecificSocieties, rolesType, societiesType} = this.state;

        const rolesOptions = [];
        const societiesOptions = [];

        if (allocateSpecificRoles.value) {
            allocateSpecificRoles.value.forEach(item => {
                const id = lookupWorkContributorsTypeVals.filter(x => x.code === item)[0].typeID;
                if (!rolesType.includes(id)) {
                    rolesType.push(id);
                };
            });
        }

        if (allocateSpecificSocieties.value) {
            allocateSpecificSocieties.value.forEach(item => {
                if (!societiesType.includes(item)) {
                    societiesType.push(item);
                };
            });
        }

        if(lookupWorkContributorsTypeVals){
            lookupWorkContributorsTypeVals.forEach(x => rolesOptions.push({ key: x.typeID, text: x.description , data: x.code }));
        }
        
        if(lookupAgencyTypeVals){
            lookupAgencyTypeVals.sort((a, b) => a.description.localeCompare(b.description))
            .forEach(x => societiesOptions.push({ key: x.code, text: x.description, data: x.description }));
        }

        const changeData = (value: any, name: IRepertoireStateKeys, isOnBlur?: boolean) => {

            const { updateUsagePoolField } = this.props;

            poolAllocationSettings[name].value = value;
            updateUsagePoolField(poolAllocationSettings, POOL_ALLOCATION_SETTINGS_KEY);
        }

        const multiSelectChangeSocietiesData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
            const { updateUsagePoolField } = this.props;
                
                if (!societiesType.includes(option.key)) {
                    societiesType.push(option.key)
                }
                else {
                    const index = societiesType.indexOf(option.key);
                    if (index > -1) {
                        societiesType.splice(index, 1);
                    }
                }
                
                poolAllocationSettings[POOL_ALLOCATE_SPECIFIC_SOCIETIES_STATE_KEY].value= societiesType;
                updateUsagePoolField(poolAllocationSettings, POOL_ALLOCATION_SETTINGS_KEY);
        }

        const multiSelectChangeRoleData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                const { updateUsagePoolField } = this.props;
                
                if (!rolesType.includes(option.key)) {
                    rolesType.push(option.key)
                }
                else {
                    const index = rolesType.indexOf(option.key);
                    if (index > -1) {
                        rolesType.splice(index, 1);
                    }
                }
                
                const arrayCode =  rolesType.map(item => lookupWorkContributorsTypeVals.filter(x => x.typeID === item)[0].code);
                poolAllocationSettings[POOL_ALLOCATE_SPECIFIC_ROLES_STATE_KEY].value= arrayCode;
                updateUsagePoolField(poolAllocationSettings, POOL_ALLOCATION_SETTINGS_KEY);
        }

        const handleRenderTitle = (items: IDropdownOption[]) => {
            
            const titleArray = [];

            items.forEach((item) => {
                titleArray.push(item.data);
            })
            return <>{`${titleArray.join()}`}</>;
        }

        const handleIsAllocateSpecificRoles = (val) => {
            const { updateUsagePoolField } = this.props;
            this.setState({ isAllocateSpecificRoles: val });

            if(!val){
                this.setState({ rolesType:[]});
                poolAllocationSettings[POOL_ALLOCATE_SPECIFIC_ROLES_STATE_KEY].value= [];
                updateUsagePoolField(poolAllocationSettings, POOL_ALLOCATION_SETTINGS_KEY);
            }
            
        }

        const handleIsAllocateSpecificSocieties = (val) => {
            const { updateUsagePoolField } = this.props;
            this.setState({ isAllocateSpecificSocieties: val });

            if(!val){
                this.setState({ societiesType:[]});
                poolAllocationSettings[POOL_ALLOCATE_SPECIFIC_SOCIETIES_STATE_KEY].value= [];
                updateUsagePoolField(poolAllocationSettings, POOL_ALLOCATION_SETTINGS_KEY);
            }
            
        }
       

        return (
            <>
            <div className="row tableContainer">
                <SizedCheckboxDataInput
                    label={this.viewData[POOL_UNIDENTIFIED_USAGE_STATE_KEY]}
                    fieldName={POOL_UNIDENTIFIED_USAGE_STATE_KEY}
                    value={unidentifiedUsage.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[POOL_UNIDENTIFIED_USAGE_STATE_KEY]} />

                <SizedCheckboxDataInput
                    label={this.viewData[POOL_NON_SOCIETY_IPS_STATE_KEY]}
                    fieldName={POOL_NON_SOCIETY_IPS_STATE_KEY}
                    value={nonSocietyIPs.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[POOL_NON_SOCIETY_IPS_STATE_KEY]} />

                <SizedCheckboxDataInput
                    label={this.viewData[POOL_REFERENCE_IPS_STATE_KEY]}
                    fieldName={POOL_REFERENCE_IPS_STATE_KEY}
                    value={referenceIPs.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[POOL_REFERENCE_IPS_STATE_KEY]} />

                <SizedCheckboxDataInput
                    label={this.viewData[POOL_MISSING_SHARES_STATE_KEY]}
                    fieldName={POOL_MISSING_SHARES_STATE_KEY}
                    value={missingShares.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[POOL_MISSING_SHARES_STATE_KEY]} />

                <SizedCheckboxDataInput
                    label={this.viewData[POOL_WARSAW_RULE_STATE_KEY]}
                    fieldName={POOL_WARSAW_RULE_STATE_KEY}
                    value={warsawRule.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[POOL_WARSAW_RULE_STATE_KEY]} />

                <SizedCheckboxDataInput
                    label={this.viewData[POOL_RECIPROCAL_AGREEMENTS_STATE_KEY]}
                    fieldName={POOL_RECIPROCAL_AGREEMENTS_STATE_KEY}
                    value={reciprocalAgreements.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[POOL_RECIPROCAL_AGREEMENTS_STATE_KEY]} />

                <SizedCheckboxDataInput
                    label={this.viewData[POOL_PD_WORK_PERCENT_STATE_KEY]}
                    fieldName={POOL_PD_WORK_PERCENT_STATE_KEY}
                    value={pdWork100Percent.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[POOL_PD_WORK_PERCENT_STATE_KEY]} />

                <SizedCheckboxDataInput
                    label={this.viewData[POOL_PD_IPS_STATE_KEY]}
                    fieldName={POOL_PD_IPS_STATE_KEY}
                    value={pdiPs.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[POOL_PD_IPS_STATE_KEY]} />

                <SizedCheckboxDataInput
                    label={this.viewData[USE_CUE_SHEET_DURATION_FOR_MATCHED_USAGE]}
                    fieldName={USE_CUE_SHEET_DURATION_FOR_MATCHED_USAGE}
                    value={useCueSheetDurationForMatchedUsage.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[USE_CUE_SHEET_DURATION_FOR_MATCHED_USAGE]} />

                <SizedCheckboxDataInput
                    label={this.viewData[USE_ADVERTISEMENT_DURATION_FOR_MATCHED_USAGE]}
                    fieldName={USE_ADVERTISEMENT_DURATION_FOR_MATCHED_USAGE}
                    value={useAdvertisementDurationForMatchedUsage.value}
                    changeData={changeData}
                    readonly={isReadonly}
                    isHidden={!this.viewData[USE_ADVERTISEMENT_DURATION_FOR_MATCHED_USAGE]} />

                
            <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE} style={flexColumn as React.CSSProperties}> 
            <CheckboxDataInput
                        label={this.viewData[POOL_PERCENTAGE_DURATION_STATE_KEY]}
                        fieldName={POOL_PERCENTAGE_DURATION_STATE_KEY}
                        value={percentageDurationForUnidentifiedUsage.value}
                        changeData={changeData}
                        readonly={isReadonly}
                        isHidden={!this.viewData[POOL_PERCENTAGE_DURATION_STATE_KEY]} 
                        />

                    {percentageDurationForUnidentifiedUsage.value ?
                     <div style={dropdownContainer as React.CSSProperties}>
                        <SizedTextDataInput
                            label={this.viewData[POOL_DEFAULT_DURATION_PERCENTAGE_STATE_KEY]}
                            fieldName={POOL_DEFAULT_DURATION_PERCENTAGE_STATE_KEY}
                            changeData={changeData}
                            value={defaultDurationPercentage.value}
                            isHidden={!this.viewData[POOL_DEFAULT_DURATION_PERCENTAGE_STATE_KEY]}
                            fieldType={INTEGER_INPUT}
                            readOnly={isReadonly}
                            minValueForNumericField={0}
                            />
                            </div>
                        : <> </>}
               
                </div>
                <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE} style={flexColumn as React.CSSProperties}> 
                <CheckboxDataInput
                            label={this.viewData[POOL_ALLOCATE_SPECIFIC_ROLES_STATE_KEY]}
                            fieldName={POOL_ALLOCATE_SPECIFIC_ROLES_STATE_KEY}
                            value={isAllocateSpecificRoles}
                            changeData={handleIsAllocateSpecificRoles}
                            readonly={isReadonly}
                            isHidden={!this.viewData[POOL_ALLOCATE_SPECIFIC_ROLES_STATE_KEY]}
                             />
                
                    {this.state.isAllocateSpecificRoles ? 
                    <div  style={dropdownContainer as React.CSSProperties}>
                        <Dropdown
                            title=""
                            selectedKeys={rolesType ? rolesType as any : getLookupDefaultMultiSelect(lookupValues, WORK_CONTRIBUTOR_TYPE_LOOKUP, POOL_ALLOCATE_SPECIFIC_ROLES_STATE_KEY, allocationSettingsGridViewData)}
                            options={rolesOptions}
                            multiSelect={true}
                            onChange={multiSelectChangeRoleData}
                            onRenderTitle={handleRenderTitle}
                            placeholder={"No Role selected."}
                            styles={
                                {
                                    title: { height: 40, backgroundColor: "WhiteSmoke", borderWidth: 1, borderColor: "LightGray", padding: 4 }
                                }
                            }
                            disabled={isReadonly} /> </div>
                    : <> </>
                    }
                </div>
                <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE} style={flexColumn as React.CSSProperties}>
                        
                        <CheckboxDataInput
                            label={this.viewData[POOL_ALLOCATE_SPECIFIC_SOCIETIES_STATE_KEY]}
                            fieldName={POOL_ALLOCATE_SPECIFIC_SOCIETIES_STATE_KEY}
                            value={isAllocateSpecificSocieties}
                            changeData={handleIsAllocateSpecificSocieties}
                            readonly={isReadonly}
                            isHidden={!this.viewData[POOL_ALLOCATE_SPECIFIC_SOCIETIES_STATE_KEY]}
                            />
                        
                        {this.state.isAllocateSpecificSocieties ? 
                            <div style={dropdownContainer as React.CSSProperties}>
                                <Dropdown
                                    title=""
                                    selectedKeys={societiesType ? societiesType as any : getLookupDefaultMultiSelect(lookupValues, AGENCY_LOOKUP, POOL_ALLOCATE_SPECIFIC_SOCIETIES_STATE_KEY, allocationSettingsGridViewData)}
                                    options={societiesOptions}
                                    multiSelect={true}
                                    onChange={multiSelectChangeSocietiesData}
                                    placeholder={"No Society selected."}
                                    styles={{
                                        title: { height: 40, backgroundColor: "WhiteSmoke", borderWidth: 1, borderColor: "LightGray", padding: 4 }
                                    }}
                                    disabled={isReadonly} />
                            </div>
                            : <> </>
                        }
                        
                </div>
            </div>
            <div className="row tableContainer">
            <SizedTextDataInput
                    label={this.viewData[POOL_CUSTOM_NOTEBOOK_STATE_KEY]}
                    fieldName={POOL_CUSTOM_NOTEBOOK_STATE_KEY}
                    changeData={changeData}
                    value={customNotebook.value}
                    isHidden={!this.viewData[POOL_CUSTOM_NOTEBOOK_STATE_KEY]}
                    fieldType={TEXT_INPUT}
                    readOnly={isReadonly} />
            </div>
            </>
            )

    }
}