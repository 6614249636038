import React from "react";
import DisplayText from "../text/DisplayText";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import { IInstrumentation } from "../../../repertoire/types/IInstrumentation";
import { IArtist } from "../../../repertoire/types/IArtist";
import { IContributorItem } from "../../../repertoire/types/IContributorItem";
import { ITitle } from "../../../repertoire/types/ITitle";
import { IProductWorkDataItems } from "../../../repertoire/types/IProductWorkDataItems";
import { isTableAction } from "../../../repertoire/types/ITableAction";
import { CHECKBOX_INPUT, DEPENDENT_ROW_ACTION, REMOVE_ACTION } from "../../Consts";
import { BOOLEAN_READONLY_INPUT, DATE_INPUT, DROPDOWN_INPUT, READ_ONLY_TIMESPAN_INPUT } from "../../../repertoire/Consts";
import DisplayDuration from "../text/DisplayDuration";
import DropdownDataInput from "../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { ILookupOptionsAndOnChangeFunctions } from "./DisplayTable";
import IconTextButton from "../../../repertoire/components/iconTextButton/IconTextButton";
import DisplayDate from "../text/DisplayDate";
import DurationInput from "../durationInput/DurationInput";
import { IIPsSearchResult } from "../../types/IIPsSearchResult";
import TimeDataInputWrapper from "../timeFieldDataInput/TimeDataInputWrapper";

export interface IDisplayTableDataCellsProps {
    cellData: IRepertoireField;
    isExpanded: boolean;
    tableContentItem: IIPsSearchResult;
    rowAction?: (item: IIPsSearchResult) => void;
    removeRowAction?: (item: IIPsSearchResult) => void;
    lookupOptionsAndOnChangeFunctions?: ILookupOptionsAndOnChangeFunctions;
    rowIndex: number;
    isSearchResultTable?: boolean;
    onClickOpen?: (tableContentItem: IIPsSearchResult) => void;
    isOpenCell?: boolean;
}

const DisplayTableDataCells: React.FC<IDisplayTableDataCellsProps> = ({
    cellData,
    isExpanded,
    tableContentItem,
    rowAction,
    removeRowAction,
    lookupOptionsAndOnChangeFunctions,
    rowIndex,
    isSearchResultTable,
    onClickOpen,
    isOpenCell,
}) => {
    const isRemoveRowActionCell = isTableAction(cellData.fieldType) && cellData.fieldType === REMOVE_ACTION && removeRowAction;
    const isRowAction = isTableAction(cellData.fieldType) && cellData.fieldType === DEPENDENT_ROW_ACTION && rowAction;
    const isEditableDurationCell = cellData.fieldType === READ_ONLY_TIMESPAN_INPUT && lookupOptionsAndOnChangeFunctions.duration && lookupOptionsAndOnChangeFunctions.duration.onChangeValue;
    const isDurationCell = cellData.fieldType === READ_ONLY_TIMESPAN_INPUT;
    const isActiveDropDownCell = cellData.fieldType === DROPDOWN_INPUT;
    const isBooleanCell = cellData.fieldType === BOOLEAN_READONLY_INPUT;
    const isDateCell = cellData.fieldType === DATE_INPUT;
    const value = tableContentItem[cellData.name];
    const isCheckbox = cellData.fieldType === CHECKBOX_INPUT;

    let readOnlyValue = value && value.value ? value.value : '-';
    if (isSearchResultTable) {
        readOnlyValue = value ? value : '-'
    }

    const cellContent = () => {
        if (isRowAction) {
            return <IconTextButton icon={"icon ms-Icon ms-Icon--OpenInNewTab"} onClick={() => rowAction(tableContentItem)} />
        }

        if (isRemoveRowActionCell) {
            return <IconTextButton icon={"icon ms-Icon ms-Icon--Delete"} onClick={() => removeRowAction(tableContentItem)} />
        }

        if (isActiveDropDownCell && lookupOptionsAndOnChangeFunctions && lookupOptionsAndOnChangeFunctions[cellData.name]) {
            const options = lookupOptionsAndOnChangeFunctions[cellData.name].options;
            const onChange = lookupOptionsAndOnChangeFunctions[cellData.name].onChangeValue;
            return <DropdownDataInput
                value={value.value}
                options={options}
                changeData={dropDownValue => onChange(dropDownValue, cellData.name, rowIndex)}
                fieldName={cellData.name}
                allowNull={true}
                useComboBoxAsMenuWidth={false}
                isHidden={false}
            />
        }

        if (isEditableDurationCell) {
            const onChangeTime = lookupOptionsAndOnChangeFunctions.duration.onChangeValue
            return <TimeDataInputWrapper
                value={value.value}
                changeData={durationValue => onChangeTime(durationValue, cellData.name, rowIndex)}
                fieldName={cellData.name}
                label={''}
                isFormik={true}
            />
        }

        if (isDurationCell) {
            return <DisplayDuration time={value.value} />
        }

        if (isDateCell) {
            return <DisplayDate date={readOnlyValue} />
        }

        if (isBooleanCell) {
            if (cellData.name === 'isLibraryPublisher') {
                return <DisplayText text={value && value.valueOf().toString().replace(/true/gi, "Yes").replace(/false/gi, "No")} />
            }
            return <DisplayText text={value.value.valueOf().toString().replace(/true/gi, "Yes").replace(/false/gi, "No")} />
        }

        if (isCheckbox) {
            return <div className={"checkboxContainer checkBoxContainerSize"}>
                <label className="subContainer checkBoxContainerSize">
                    <input
                        type="checkbox"
                        checked={value}
                    />
                    <span className="inputCheckbox" />
                </label>
            </div>
        }

        return <DisplayText text={readOnlyValue} />
    }

    const onClickExternalIcon = () => {
        onClickOpen(tableContentItem);
    }
    const openElement = <img
        src="assets/external-black.svg"
        title="Open in a new tab"
        alt="External icon"
        className="externalIcon"
        onClick={onClickExternalIcon}
    />;
    return (
        <td key={`tableCell${cellData.name}${rowIndex}`} className={["td", isOpenCell ? "openCell" : ""].join(" ")} title={`${cellData.data}`}>
            <div className={["tableCell flexRow flexAlignItemsCenter tableData", isExpanded && "rowNotExpanded"].join(" ")}>
                {cellContent()}
                {isOpenCell ? openElement : <div />}
            </div>
        </td>
    )
}

export default DisplayTableDataCells;