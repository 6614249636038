import { useMsal } from '@azure/msal-react';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { MATCHING_ENGINE, MEMBERS_PORTAL } from '../../Consts';

interface ISignOutProps {
    logout: () => void;
    isSignedIn: boolean;
    portalType: string;
}

export const SignOut = (props: ISignOutProps) => {
    const { instance, accounts } = useMsal()

    React.useEffect(() => {
        sessionStorage.setItem('isSignedIn', 'false');
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('currentViewAsUserName');
        sessionStorage.removeItem('currentGuid');
        sessionStorage.removeItem('currentAccountNumber');
        props.logout();
        if (accounts.length !== 0) instance.logout();

    }, [])

    if (props.portalType !== MEMBERS_PORTAL){
        return props.isSignedIn ? <div /> : <Redirect to='/login' />
    }
    
    return props.portalType === MEMBERS_PORTAL && props.isSignedIn ? <div /> : <Redirect to='/login' />
}