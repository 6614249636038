export const INGESTION_PAGE_JOBS: string[] = ["WID", "CWR", "USG", "UP", "PP", "DM"];


export const FILE_NAME_SORTING_KEY: string = "name";
export const LAST_MODIFIED_SORTING_KEY: string = "lastModified";
export const TRACKING_INFORMATION_SORTING_KEY: string = "trackingInformation";
export const ATTACHMENT_TYPE_SORTING_KEY: string = "attachmentType";
export const MAJOR_SOURCE_SORTING_KEY: string = "majorSource";
export const SOURCE_SORTING_KEY: string = "source";
export const INPUT_FORMAT_SORTING_KEY: string = "format";
export const DISTRIBUTION_CODE_SORTING_KEY: string = "distributionCode";
export const POOL_CODE_SORTING_KEY: string = "poolCode";
export const OUTPUT_FORMAT_SORTING_KEY: string = "outputFormat";
export const DESTINATION_SORTING_KEY: string = "destination";
export const FILE_SIZE_SORTING_KEY: string = "fileSize";
export const BATCH_ID_SORTING_KEY: string = "batchID";
export const RIGHTHOLDER_SORTING_KEY: string = "rightHolder";
export const SHOPPINGCART_SORTING_KEY: string = "shoppingCart";

export const FILE_FORMAT_SAMRO: string = "SAMRO";

export const CONFIRM_UNDO_PROMPT_STATE_KEY: string = "ConfirmUndoMessage";

export const DATA_INGESTION_TOOLBAR: string = "DataIngestionToolbar";
export const REFRESH_METHOD_TOOLBAR: string = "Refresh";
export const DOWNLOAD_SCHEMA_METHOD_TOOLBAR: string = "DownloadSchema";

export const REFRESH_STATE_KEY = "refresh";
export const DOWNLOAD_SCHEMA_STATE_KEY = "downloadSchema";

export const CONTAINER_DETAILS_WINDOW_KEY = "ContainerDetailsWindow";

// Roles
export const ALL_ROLES = "All";
export const VIEW_INGESTION_USAGE_ROLE = "Usage.Ingestion.View";
export const CONTRIBUTE_INGESTION_USAGE_ROLE = "Usage.Ingestion.Contribute";
export const VIEW_INGESTION_REPERTOIRE_ROLE = "Repertoire.Ingestion.View";
export const CONTRIBUTE_INGESTION_REPERTOIRE_ROLE = "Repertoire.Ingestion.Contribute";
export const VIEW_PAYMENT_ROLE = "Payment.View";
export const CONTRIBUTE_PAYMENT_ROLE = "Payment.Contribute";
export const VIEW_POOLS_ROLE = "Pool.View";
export const VIEW_DISTRIBUTIONS_ROLE = "Distribution.View";
