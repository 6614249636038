import * as React from "react";
import { IPerformanceNumber } from "../../../types/usageTypes/IPerformanceNumber";
import { IUsageRowData } from "../../../types/usageTypes/IUsageRowData";

export interface IPerformanceNumbersSectionProps {
    usage: IUsageRowData
}

interface IPerformanceNumbersSectionState {
}

export class PerformanceNumbersSection extends React.PureComponent<
    IPerformanceNumbersSectionProps,
    IPerformanceNumbersSectionState
> {

    constructor(props: IPerformanceNumbersSectionProps) {
        super(props);
        this.state = {
        };
    }

    renderHorizontalRows() {
        const {
            usage
        } = this.props

        const rows: JSX.Element[] = [];

        usage.performanceNumbers.map((pn: IPerformanceNumber, index: number) => {
            rows.push(
                <div key={index} className="horizontalRow col-md-3">
                    <label className="section col-md-12">{pn.typeCode}:</label>
                    <p className="section col-md-12">{pn.number}</p>
                </div>
            );
        });

        return rows;
    }

    render() {
        const {

        } = this.props;
        const horizontalRows = this.renderHorizontalRows();

        return (
            <div className="performanceNumbers col-md-12">
                <div className="container">
                <h3>Performance Numbers</h3>
                    {horizontalRows}
                </div>
            </div>
        );

    }
}