import * as React from "react";
import { WideActionButton } from "../../actionButton/ActionButton";
import { updateRepresentationSelector } from "../../../../redux/reducers/RepertoireReducer";
import { READ_ONLY_INPUT, REPERTOIRE_PAGE_FORM_ITEM_SIZE, TEXT_INPUT } from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDropdownOption } from "office-ui-fabric-react";
import { TERRITORY_LOOKUP } from "../../../../lookup/Consts";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { IIPRepresentation } from "../../../types/IIPRepresentation";
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { SizedRadiobuttonDataInput } from "../../radioButtonDataInput/RadiobuttonDataInput";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import TextDataInput from "../../textDataInput/TextDataInput";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import { updateDraftAgreementField } from "../../../../redux/reducers/MembersPortalReducer";
import { useEffect, useState } from "react";
import { any } from "lodash/fp";

export interface ITerritorySelectionWithRadioBtnViewProps {
    agreement: IAgreementState;
    hideModal: () => void;
    updateDraftAgreementField?: typeof updateDraftAgreementField;
    lookupValues: ILookupDictionary;
    isReadOnly?: boolean;
    incrementTempID?: () => void;
    title?: string;
    modalIndex?: number;
    defaultTerritories?: any;

}

interface ITerritorySelectionWithRadioBtnViewState {
    setCountry: any[];
    setRegion: any[];
    territoryCodes: any[];
    isCountryEnabled: boolean;
    isRegionEnabled: boolean;
    agreementTerritories: any[];
    setCountriesOption: any[];
    setRegionOptions: any[];
    stringToFilter: string;
    showDropDown: boolean;
}


export default class TerritorySelectionWithRadioBtnView extends React.PureComponent<ITerritorySelectionWithRadioBtnViewProps, ITerritorySelectionWithRadioBtnViewState> {
    countryRef;
    regionRef
    divRef;

    constructor(props: ITerritorySelectionWithRadioBtnViewProps) {
        super(props);
        this.countryRef = React.createRef();
        this.regionRef = React.createRef();
        this.divRef = React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {
            setCountry: [],
            setRegion: [],
            territoryCodes: [],
            isCountryEnabled: true,
            isRegionEnabled: false,
            agreementTerritories: [],
            setCountriesOption: [],
            setRegionOptions: [],
            stringToFilter: '',
            showDropDown: false,
        }
    }
    handleClickOutside(event) {
        if (this.countryRef.current && !this.countryRef.current.contains(event.target) && this.state.showDropDown) {
        document.getElementById('territoryDropdown-content').classList.toggle('show');
        document.querySelector('.arrow-icon').classList.toggle('up');
        this.setState({ stringToFilter: '', showDropDown: !this.state.showDropDown})
        }
    }
    componentDidMount() {
        const { agreement, lookupValues, defaultTerritories } = this.props;
        let updatedTerritoryCodes = [];
        let defaultCountries = [];
        let defaultRegion = [];
        let updatedTerritory=[];
        let updatedAgreementTerritories=[];

        document.addEventListener('mousedown', this.handleClickOutside);
        
        let lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
        lookupValsTerritories = lookupValsTerritories.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description))

        if (defaultTerritories && !agreement.agreementTerritory ) {
           
            let countriesOptions = [];
            let regionOptions = [];
           
            if (lookupValsTerritories) {
                lookupValsTerritories.filter(item => !item.code.startsWith('2')).map(x => countriesOptions.push({ key: x.typeID, text: x.description, data: x.code }));
                lookupValsTerritories.filter(item => item.code.startsWith('2')).map(x => regionOptions.push({ key: x.typeID, text: x.description, data: x.code }));
            }
            if (defaultTerritories.includes && defaultTerritories.includes.length) {
                defaultTerritories.includes.map(item => updatedTerritoryCodes.push(`I: ${lookupValsTerritories.find(x => x.typeID === item).description}`))
            }
            if (defaultTerritories.excludes && defaultTerritories.excludes.length) {
                defaultTerritories.excludes.map(item => updatedTerritoryCodes.push(`E: ${lookupValsTerritories.find(x => x.typeID === item).description}`))
            }

            updatedTerritoryCodes.map(item=>updatedAgreementTerritories.push(
                {
                    agreementTerritoryID: 0,
                    agreementID: agreement.agreementID,
                    territory: lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).code,
                    isDeleted: false,
                    include: item.startsWith("I: ")?true:false
                }
            ))

        }
        if(agreement.agreementTerritory && agreement.agreementTerritory.length !==0 ){
                agreement.agreementTerritory.map(x => {
                    updatedTerritoryCodes.push(x.include ? `I: ${lookupValsTerritories.find(item => item.code === x.territory).description}` : (`E: ${lookupValsTerritories.find(item => item.code === x.territory).description}`))
                })

            updatedTerritoryCodes.map(item => {
                if ((lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).code).startsWith('2')) {
                    defaultRegion.push(lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).code)
                } else {
                    defaultCountries.push(lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).code)
                }

            })      
            
            updatedTerritoryCodes.map(item=>updatedAgreementTerritories.push(
                {
                    agreementTerritoryID: 0,
                    agreementID: agreement.agreementID,
                    territory: lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).code,
                    isDeleted: false,
                    include: item.startsWith("I: ")?true:false
                }
            ))
        }

        if (updatedTerritoryCodes && updatedTerritoryCodes.length) {
            
            const radioIncludeButtons = document.querySelectorAll('input[type="radio"][value="include"]');
            const radioExcludeButtons = document.querySelectorAll('input[type="radio"][value="exclude"]');
            let result = updatedTerritoryCodes.filter(item => !(lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).code).startsWith('2'))
            result.map(x => {
                let index = lookupValsTerritories.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description)).findIndex(item => item.description === x.replace(/^(I: |E: )/, ''))
                if (radioIncludeButtons) {
                    radioIncludeButtons[index]['checked'] = x.include ? x.include : ""
                }
                if (radioExcludeButtons) {
                    radioExcludeButtons[index]['checked'] = x.include ? !x.include : ""
                }
            })
            updatedTerritoryCodes.map(item=>{
                updatedTerritory.push({ territory: (lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).code), include: item.startsWith("I: ")?true:false, agreementTerritoryID: (lookupValsTerritories.find(x => x.description === item.replace(/^(I: |E: )/, '')).typeID) })
            }) 

            if (updatedAgreementTerritories && updatedAgreementTerritories.length > 0 && defaultRegion.length === 0) {
                updatedAgreementTerritories.forEach(x => {
                    if (x.territory.startsWith('2')) {
                        defaultRegion.push(x.territory)
                    }
                })
                
            }
            this.setState({ setCountry: defaultCountries, setRegion: defaultRegion, territoryCodes: updatedTerritoryCodes as any, agreementTerritories: updatedAgreementTerritories })

        }
    }
    componentDidUpdate(prevProps: ITerritorySelectionWithRadioBtnViewProps, prevState: ITerritorySelectionWithRadioBtnViewState) {
        let propsTerritoryCodes = [];
        let regionOptions = [];
        const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, prevProps.lookupValues);
        if(this.state.agreementTerritories.length===0){
            this.state.territoryCodes.map(x=>{
                if(x.startsWith("I: ")){
                    propsTerritoryCodes.push({
                        'isInclude': true, 'territory':  `I: ${lookupValsTerritories.find(item => item.description === x.replace(/^(I: |E: )/, '')).description}`})
                    }
                else{
                    propsTerritoryCodes.push({
                        'isInclude': false, 'territory': `E: ${lookupValsTerritories.find(item => item.description === x.replace(/^(I: |E: )/, '')).description}`})
                }
                
            })
            
        }
        else{
            const uniqueArray =  this.state.agreementTerritories.filter((value, index, self) =>
                index === self.findIndex((obj) => (
                  obj.territory === value.territory &&
                  obj.include === value.include &&
                  obj.agreementTerritoryID === value.agreementTerritoryID
                ))
              );
            uniqueArray.map(x => {
                propsTerritoryCodes.push({
                    'isInclude': x.include, 'territory': x.include ? `I: ${lookupValsTerritories.find(item => item.code === x.territory.replace(/^(I: |E: )/, '')).description}` : `E: ${lookupValsTerritories.find(item => item.code === x.territory.replace(/^(I: |E: )/, '')).description}`
                })
            })
        }
        

        if (lookupValsTerritories) {
            lookupValsTerritories.filter(item => item.code.startsWith('2')).map(x => regionOptions.push({ key: x.typeID, text: x.description, data: x.code }));
        }
        const radioIncludeButtons = document.querySelectorAll('input[type="radio"][value="include"]');
        const radioExcludeButtons = document.querySelectorAll('input[type="radio"][value="exclude"]');
        if (this.state.isRegionEnabled && radioIncludeButtons && radioExcludeButtons) {
            propsTerritoryCodes.map(x => {
                if (regionOptions.findIndex(item => item.text === x.territory.replace(/^(I: |E: )/, '')) <= radioIncludeButtons.length) {
                    if (radioIncludeButtons && regionOptions.findIndex(item => item.text === x.territory.replace(/^(I: |E: )/, '')) >= 0 && x.isInclude) {
                        radioIncludeButtons[regionOptions.findIndex(item => item.text === x.territory.replace(/^(I: |E: )/, ''))]['checked'] = x.isInclude;
                    } if (radioIncludeButtons && regionOptions.findIndex(item => item.text === x.territory.replace(/^(I: |E: )/, '')) >= 0 && !x.isInclude) {
                        radioExcludeButtons[regionOptions.findIndex(item => item.text === x.territory.replace(/^(I: |E: )/, ''))]['checked'] = !x.isInclude;
                    }
                }

            })
        } else if (this.state.isCountryEnabled && radioIncludeButtons && radioExcludeButtons) {
            let countryOptions = [];
            countryOptions = lookupValsTerritories.filter(item => !item.code.startsWith('2'))
            propsTerritoryCodes.map(x => {
                if (countryOptions.findIndex(item => item.description === x.territory.replace(/^(I: |E: )/, '')) <= radioIncludeButtons.length) {
                    if (radioIncludeButtons && countryOptions.findIndex(item => item.description === x.territory.replace(/^(I: |E: )/, '')) >= 0 && x.isInclude) {
                        radioIncludeButtons[countryOptions.findIndex(item => item.description === x.territory.replace(/^(I: |E: )/, ''))]['checked'] = x.isInclude;
                    } if (radioIncludeButtons && countryOptions.findIndex(item => item.description === x.territory.replace(/^(I: |E: )/, '')) >= 0 && !x.isInclude) {
                        radioExcludeButtons[countryOptions.findIndex(item => item.description === x.territory.replace(/^(I: |E: )/, ''))]['checked'] = !x.isInclude;
                    }
                }

            })
        }
    }
    updateTerritorySelectorState = (value: any, field: string) => {


        switch (field) {

            case "Country":
                this.setState({
                    isCountryEnabled: true,
                    isRegionEnabled: false
                });
                break;
            case "Region":
                this.setState({
                    isRegionEnabled: true,
                    isCountryEnabled: false
                });
                break;
        }
    };

    handleSearchFieldChange = (event: any) => {
        this.setState({ stringToFilter: event.target.value })
    }

    multiSelectChangeRegionData = (value?: any, isInclude?: boolean) => {
        const { setCountry, setRegion, isCountryEnabled, territoryCodes } = this.state;
        const { lookupValues } = this.props;

        let countriesOptions = [];
        let regionOptions = [];
        let lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
        lookupValsTerritories = lookupValsTerritories.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description))
        if (lookupValsTerritories) {
            lookupValsTerritories.filter(item => !item.code.startsWith('2')).map(x => countriesOptions.push({ key: x.typeID, text: x.description, data: x.code }));
            lookupValsTerritories.filter(item => item.code.startsWith('2')).map(x => regionOptions.push({ key: x.typeID, text: x.description, data: x.code }));
        }
        let result = [];
        let countryNames = [] as any;
        let newArr;
        let updatedTerritory = this.state.agreementTerritories;
        if (isCountryEnabled) {
            if (setCountry.length) {
                result = [...new Set(setCountry)].map(x => { return x })
            }
            if (!result.includes(value)) {
                result.push(value)
                countryNames.push((isInclude ? `I: ${countriesOptions.find(item => item.data === value).text}` : `E: ${countriesOptions.find(item => item.data === value).text}`))
                updatedTerritory.push({ territory: value, include: isInclude, agreementTerritoryID: countriesOptions.find(item => item.data === value).key })
                newArr = territoryCodes.concat(countryNames) as any
            }
            else {
                const index = result.indexOf(value);
                if (index > -1) {
                    result.splice(index, 1);
                    updatedTerritory = updatedTerritory.filter(x => x.territory !== value);
                    let updatedTerritoryCode = this.state.territoryCodes.filter(item => !item.includes(countriesOptions.find(item => item.data === value).text))
                    if (!result.includes(value)) {
                        result.push(value)
                        updatedTerritoryCode.push((isInclude ? `I: ${countriesOptions.find(item => item.data === value).text}` : `E: ${countriesOptions.find(item => item.data === value).text}`))
                        updatedTerritory.push({ territory: value, include: isInclude, agreementTerritoryID: countriesOptions.find(item => item.data === value).key })
                        newArr = updatedTerritoryCode.concat(updatedTerritoryCode) as any
                    }
                }
            }
            newArr = [...new Set(newArr)]
            this.setState({ setCountry: result, territoryCodes: newArr, agreementTerritories: updatedTerritory })
        }
        else {

            if (setRegion.length) {
                result = [...new Set(setRegion)].map(x => { return x })
            }
            if (!result.includes(value)) {
                result.push(value)
                countryNames.push((isInclude ? `I: ${regionOptions.find(item => item.data === value).text}` : `E: ${regionOptions.find(item => item.data === value).text}`))
                updatedTerritory.push({ territory: value, include: isInclude, agreementTerritoryID: regionOptions.find(item => item.data === value).key })
                newArr = territoryCodes.concat(countryNames) as any
            }
            else {
                const index = result.indexOf(value);
                if (index > -1) {
                    result.splice(index, 1);
                    updatedTerritory = updatedTerritory.filter(x => x.territory !== value);
                    let updatedTerritoryCode = this.state.territoryCodes.filter(item => !item.includes(regionOptions.find(item => item.data === value).text))
                    if (!result.includes(value)) {
                        result.push(value)
                        updatedTerritoryCode.push((isInclude ? `I: ${regionOptions.find(item => item.data === value).text}` : `E: ${regionOptions.find(item => item.data === value).text}`))
                        updatedTerritory.push({ territory: value, include: isInclude, agreementTerritoryID: regionOptions.find(item => item.data === value).key })
                        newArr = updatedTerritoryCode.concat(updatedTerritoryCode) as any
                    }
                }
            }
            newArr = [...new Set(newArr)]
            this.setState({ setRegion: result, territoryCodes: newArr, agreementTerritories: updatedTerritory })
        }
    }

    toggleDropdown() {
        document.getElementById('territoryDropdown-content').classList.toggle('show');
        document.querySelector('.arrow-icon').classList.toggle('up');
        this.setState({ stringToFilter: '' })
        this.setState({ showDropDown: !this.state.showDropDown })
    }


    render() {
        const { isCountryEnabled, isRegionEnabled, territoryCodes, agreementTerritories, setCountriesOption } = this.state;
        const { hideModal, isReadOnly, lookupValues, agreement, updateDraftAgreementField, title } = this.props;

        let closeModal = () => {
            
            document.removeEventListener('mousedown', this.handleClickOutside);
            hideModal();
        }
        let buttonSaveAction = () => {
            if (agreement) {
                let updatedAgreementTerritories = [] as any;
                agreementTerritories.map(x => updatedAgreementTerritories.push(
                    {
                        agreementTerritoryID: x.agreementTerritoryID,
                        agreementID: agreement.agreementID,
                        territory: x.territory,
                        isDeleted: false,
                        include: x.include
                    }));
                updateDraftAgreementField(updatedAgreementTerritories, 'agreementTerritory');
                document.removeEventListener('mousedown', this.handleClickOutside);
                hideModal();
            }
        };
        let buttonCloseAction = () => {
            document.removeEventListener('mousedown', this.handleClickOutside);
            hideModal();
        };
        let countriesOptions = [];
        let regionOptions = [];
        let lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
        lookupValsTerritories = lookupValsTerritories.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description));
        if (lookupValsTerritories) {
            lookupValsTerritories.filter(item => !item.code.startsWith('2')).map(x => countriesOptions.push({ key: x.typeID, text: x.description, data: x.code }));
            lookupValsTerritories.filter(item => item.code.startsWith('2')).map(x => regionOptions.push({ key: x.typeID, text: x.description, data: x.code }));
        }
        let buttonDeselectAction = (value: string) => {
            let updatedRegion = []
            let updatedCountries = [];
            this.setState({stringToFilter: ''})

            let regionEnabled = false;

            if ((lookupValsTerritories.find(x => value.replace(/^(I: |E: )/, '') === x.description).code).startsWith('2')) {
                regionEnabled = true
            }
            if (territoryCodes.filter(item => item !== value.replace(/^(I: |E: )/, ''))) {

                if (!territoryCodes.filter(item => item !== value.replace(/^(I: |E: )/, '')).includes(' ')) {
                    let lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
                    lookupValsTerritories = lookupValsTerritories.sort((itemA, itemB) => itemA.description.localeCompare(itemB.description))
                    territoryCodes.filter(item => item.replace(/^(I: |E: )/, '') !== value.replace(/^(I: |E: )/, '')).map(item => {
                        let result = lookupValsTerritories.find(x => item.replace(/^(I: |E: )/, '') === x.description).code
                        if (result.startsWith('2')) {
                            updatedRegion.push(lookupValsTerritories.find(x => item.replace(/^(I: |E: )/, '') === x.description).code)
                        } else {
                            updatedCountries.push(lookupValsTerritories.find(x => item.replace(/^(I: |E: )/, '') === x.description).code)
                        }
                    })

                }
            }

            const radioIncludeButtons = document.querySelectorAll('input[type="radio"][value="include"]');
            const radioExcludeButtons = document.querySelectorAll('input[type="radio"][value="exclude"]');

            if (!regionEnabled) {
                if (radioIncludeButtons) {
                    radioIncludeButtons[countriesOptions.findIndex(x => x.text === value.replace(/^(I: |E: )/, ''))]['checked'] = ""
                }
                if (radioExcludeButtons) {
                    radioExcludeButtons[countriesOptions.findIndex(x => x.text === value.replace(/^(I: |E: )/, ''))]['checked'] = ""
                }
            } else {
                if (radioIncludeButtons) {
                    radioIncludeButtons[regionOptions.findIndex(x => x.text === value.replace(/^(I: |E: )/, ''))]['checked'] = ""
                }
                if (radioExcludeButtons) {
                    radioExcludeButtons[regionOptions.findIndex(x => x.text === value.replace(/^(I: |E: )/, ''))]['checked'] = ""
                }
            }
            let deSelectedItem = lookupValsTerritories.find(x => value.replace(/^(I: |E: )/, '') === x.description).code;
            this.setState({ setCountry: updatedCountries, setRegion: updatedRegion, territoryCodes: territoryCodes.filter(item => item.replace(/^(I: |E: )/, '') !== value.replace(/^(I: |E: )/, '')), agreementTerritories: this.state.agreementTerritories.filter(x => x.territory !== deSelectedItem) })

        };
        if (agreement) {

            return (
                <div className="modal-dialog settingsModal" style={{ width: 952 }}>
                    <div className="modal-content">
                        <div style={{ height: 'auto', background: 'white' }}>

                            <div className='modal-header' style={{
                                background: "#38429C",
                                color: "white",
                            }}>
                                <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => closeModal()}>
                                    <i className="icon ms-Icon ms-Icon--ChromeClose" style={{ color: "white" }} title="Close"></i>
                                </button>
                                <h1 className="modal-title">{title}</h1>
                            </div>
                            <div style={{ padding: 15, height: `850px` }}>
                                <div style={{ fontWeight: 600, fontSize: 15 }}>Filter by:</div>
                                <div className="flexColumn flexJustifyContentSpaceBetween" style={{ height: '100%'}}>
                                    <div>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: 15, top: 0 }}>
                                        <SizedRadiobuttonDataInput
                                            onSelectionChange={(value) => this.updateTerritorySelectorState(value, "Country")}
                                            disabled={false}
                                            radioName=""
                                            title=""
                                            options={[
                                                { desc: "Country", code: 'C' }
                                            ]}
                                            selectedValue={isCountryEnabled ?
                                                'C' :
                                                ''} />
                                        <SizedRadiobuttonDataInput
                                            onSelectionChange={(value) => this.updateTerritorySelectorState(value, "Region")}
                                            disabled={false}
                                            radioName=""
                                            title=""
                                            options={[
                                                { desc: "Region", code: 'R' }
                                            ]}
                                            selectedValue={isRegionEnabled ?
                                                'R' :
                                                ''} />
                                    </div>

                                    <div ref={this.countryRef} style={{position: 'relative'}}>
                                        {isCountryEnabled && <>
                                            <div>
                                                <div style={{ height: '100', position: 'fixed', width: 920, overflow: 'hidden' }}>
                                                    <div className="territoryDropdown">
                                        

                                                        <div className="territoryDropbtn">
                                                        <input type="text" placeholder='Select Territories' id="myInput" onClick={() => this.toggleDropdown()} value={this.state.stringToFilter} onChange={this.handleSearchFieldChange} style={{ width: '100%', padding: 10 }} />
                                                            <span className="arrow-icon">⌵</span>
                                                        </div>


                                                        <div className="territoryDropdown-content" id="territoryDropdown-content">
                                                            { 
                                                            this.state.stringToFilter && this.state.stringToFilter !== '' ?
                                                                countriesOptions.map(
                                                                    element => {
                                                                        return (<div className={`territoryDropdown-item ${!element.text.toLowerCase().includes(this.state.stringToFilter.toLowerCase()) && 'hideCountryOrRegion' }`} data-country={element.data}>
                                                                            <span>{element.text}</span>
                                                                            <label><input type="radio" name={element.text} value="include" onChange={() => this.multiSelectChangeRegionData(element.data, true)} /> Include</label>
                                                                            <label><input type="radio" name={element.text} value="exclude" onChange={() => this.multiSelectChangeRegionData(element.data, false)} /> Exclude</label>
                                                                        </div>)
                                                                    }
                                                                ) : countriesOptions.map(element => {
                                                                return (<div className="territoryDropdown-item" data-country={element.data}>
                                                                    <span>{element.text}</span>
                                                                    <label><input type="radio" name={element.text} value="include" onChange={() => this.multiSelectChangeRegionData(element.data, true)} /> Include</label>
                                                                    <label><input type="radio" name={element.text} value="exclude" onChange={() => this.multiSelectChangeRegionData(element.data, false)} /> Exclude</label>
                                                                </div>)
                                                            })}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div></>}
                                        {isRegionEnabled && <>
                                            <div>
                                                <div style={{ height: '100', position: 'fixed', width: 920 }}>
                                                    <div className="territoryDropdown">
                                                    <div className="territoryDropbtn">
                                                        <input type="text" placeholder='Select Territories' id="myInput" onClick={() => this.toggleDropdown()} value={this.state.stringToFilter} onChange={this.handleSearchFieldChange} style={{ width: '100%', padding: 10 }} />
                                                            <span className="arrow-icon">⌵</span>
                                                        </div>



                                                        <div className="territoryDropdown-content" id="territoryDropdown-content">
                                                            {
                                                            this.state.stringToFilter && this.state.stringToFilter !== '' ?
                                                            regionOptions.map(
                                                                element => {
                                                                    return (<div className={`territoryDropdown-item ${!element.text.toLowerCase().includes(this.state.stringToFilter.toLowerCase()) && 'hideCountryOrRegion' }`} data-country={element.data}>
                                                                        <span>{element.text}</span>
                                                                        <label><input type="radio" name={element.text} value="include" onChange={() => this.multiSelectChangeRegionData(element.data, true)} /> Include</label>
                                                                        <label><input type="radio" name={element.text} value="exclude" onChange={() => this.multiSelectChangeRegionData(element.data, false)} /> Exclude</label>
                                                                    </div>)
                                                                }
                                                            ) :
                                                            regionOptions && regionOptions.map(element => {
                                                                return (<>
                                                                    <div id='region' className="territoryDropdown-item" data-country={element.data}>
                                                                        <span>{element.text}</span>
                                                                        <label><input type="radio" name={element.text} value="include" onChange={() => this.multiSelectChangeRegionData(element.data, true)} /> Include</label>
                                                                        <label><input type="radio" name={element.text} value="exclude" onChange={() => this.multiSelectChangeRegionData(element.data, false)} /> Exclude</label>
                                                                    </div>
                                                                </>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}

                                    </div>


                                    <div style={{ display: 'flex', flexDirection: 'row', padding: 15 }}>
                                        <div className="selected-item-container">
                                            <div style={{ fontSize: 13, fontWeight: 600 }}>Selected items</div>
                                            <hr style={{ borderBottom: 2 }}></hr>
                                            <div style={{ height: 'auto', overflowY: 'scroll' }}>
                                                <div className="selected-items-list">
                                                    {territoryCodes && territoryCodes.map((x) => {
                                                        return <div className="selected-item">
                                                            <span>{x}</span> &nbsp;&nbsp;
                                                            <span className="close-icon" onClick={() => buttonDeselectAction(x)}>&times;</span>
                                                        </div>
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div>
                                    <hr style={{ borderBottom: 2 }}></hr>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <WideActionButton
                                            buttonText="Cancel"
                                            buttonAction={buttonCloseAction}
                                            disabled={isReadOnly}
                                            className="button btn-defaultSecondary" />
                                        <WideActionButton
                                            buttonText="Save"
                                            buttonAction={buttonSaveAction}
                                            disabled={isReadOnly} />
                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
        else return <div></div>
    }



}