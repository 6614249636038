import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import IAppState from "../../redux/types/IAppState";
import { mp_addTab, resetPagination, sortSearchResults, updatePagination, expandMemberStatementResult, expandAllResults } from "../../redux/reducers/MembersPortalReducer";
import {
    getMemberStatementDetailsThunk, loadMembersPortalFromPathThunk, searchMemberStatementsThunk,
} from "../../redux/thunks/MembersPortalThunks";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { FormatFields } from "../../redux/types/FormatFields";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import MemberStatementsPage from "./MemberStatementsPage";
import { IMemberStatementSearchQuery } from "../types/IMemberStatementSearchQuery";
import { IMembersPortalPathInfo } from "../types/IMembersPortalPathInfo";
import { IMemberStatementSearchResult } from "../types/IMemberStatementSearchResult";

export default connect(
    (state: IAppState) => ({
        statementsPageData: state.membersPortal.componentData.MemberStatementsPage,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        memberStatementSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].memberStatementSearchResults,
        searchSuccessful: state.membersPortal.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'draftWorkss')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'draftWorkss')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'draftWorkss')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        memberPaymentStatementGeneralDataViewData: state.membersPortal.componentData.MemberPaymentStatementGeneralDataView,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        allResultsPerPage: state.membersPortal.resultsPerPage,
        roles: state.account.roles,
        currentUserNameNumber: state.account.currentUserNameNumber,
        expandedMemberStatementResults: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandedMemberStatementResults,
        expandAll: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandAll,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        searchMemberStatements: (query: IMemberStatementSearchQuery) =>
            dispatch<any>(searchMemberStatementsThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getMemberStatementDetails: (payment: IMemberStatementSearchResult, lookupValues: ILookupDictionary, userNameNumber: string, formats?: FormatFields[]) =>
            dispatch<any>(getMemberStatementDetailsThunk(payment, lookupValues, userNameNumber, formats)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        expandMemberStatementResult: (statementResult: number) => dispatch(expandMemberStatementResult(statementResult)),
        loadMembersPortalFromPath: (pathInfo: IMembersPortalPathInfo, currentUserNameNumber: string, location: Location) => dispatch<any>(loadMembersPortalFromPathThunk(pathInfo, currentUserNameNumber, location)),
        expandAllResults: () => dispatch<any>(expandAllResults()),

    })
)(MemberStatementsPage);
