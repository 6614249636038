import * as React from 'react';
import { IMenuItem } from '../../types/IMenuItem';
import { NavItem, NavLink } from 'reactstrap';
import { If } from '../If';
import { Link } from 'react-router-dom';
import { IEnableModulesConfigurationParametersItem } from '../../../redux/types/IEnableModulesConfigurationParameterItem';
import { MEMBERS_PORTAL } from '../../../accounts/Consts';

interface IFormBoxMenuProps {
    menuItems: IMenuItem[];
    setCurrentPage: (link: string) => void;
    enableModules?: IEnableModulesConfigurationParametersItem[];
    currentUserNameNumber?: string;
    portalType?: string;
    getUserCRMIPNumber: (guid: string) => void;   
}

export class FormBoxMenu extends React.Component<IFormBoxMenuProps>{
    constructor(props: IFormBoxMenuProps) {
        super(props);
    }

    renderMenuItem(item: IMenuItem, currentNameNumber:string): JSX.Element {

        const { enableModules, portalType } = this.props;

        let filteredItem: IMenuItem = item;
        if (enableModules && enableModules.length > 0) {
            const disableModules = enableModules.filter(x => !x.enable);
            if (disableModules && disableModules.length > 0) {
                if (filteredItem.children.length > 0) {
                    filteredItem.children = item.children.filter(function (current) {
                        if (disableModules.findIndex(x => x.link === current.link) === -1)
                            return current;
                    });
                }
            }
        }

        let menuItem = null;

        if (filteredItem.children.length > 0) {
            menuItem = <div key={filteredItem.link} className={["form-group col-lg-4 col-md-6 col-sm-6", !filteredItem.isEnabled && "disabledItem"].join(" ")}>
                <div className="formBox">
                    <div className="heading">
                        <span className={filteredItem.icon} aria-hidden="true"></span>
                        <span className="title">{filteredItem.label}</span>
                    </div>

                    <If condition={filteredItem.children && filteredItem.children.length > 0}>
                        <ul>
                            {
                                filteredItem.children.map((c: IMenuItem, i: number) =>
                                    c.isEnabled && ((portalType === MEMBERS_PORTAL && currentNameNumber) || portalType !== MEMBERS_PORTAL) ?
                                        <NavItem key={i}>
                                            <NavLink tag={Link} to={c.link} onClick={() => this.props.setCurrentPage(c.link)}>{c.label}</NavLink>
                                        </NavItem>
                                        : <li key={i}>{c.label}</li>
                                )
                            }
                        </ul>
                    </If>
                </div>
            </div>
        }
           
        return menuItem;
    }

    renderMenuItems(): JSX.Element[] {
        const { menuItems } = this.props;

        if (menuItems && menuItems.length > 0) {
            return menuItems.filter(m => m.link !== '/').map((i: IMenuItem) => this.renderMenuItem(i, this.props.currentUserNameNumber));
        }
        else {
            return null;
        }
    }

    render() {
        const {portalType} = this.props;
        let className = portalType === MEMBERS_PORTAL ? " row cardsList flexWrapRow " : " row cardsList "
        return (
            <div className={className}>
                {this.renderMenuItems()}
            </div>);
    }
}