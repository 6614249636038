import { IUsageGroupState } from "../../redux/types/IUsageGroupState";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";

export class MembersPortalLivePerformanceValidator {

    public static validateLivePerformance(usage: IUsageGroupState): IValidationMessage[] {
        let livePerformanceIsValid: IValidationMessage[];
        livePerformanceIsValid = [];

        if (Boolean(usage.fullPerformer) === false) {
            livePerformanceIsValid.push({ messageKey: "Performer field cannot be empty" })
        }
        if (Boolean(usage.location) === undefined) {
            livePerformanceIsValid.push({ messageKey: "Please enter valid Venue" })
        }
        if (Boolean(usage.venueDetails.firstName) === false) {
            livePerformanceIsValid.push({ messageKey: "Firstname field cannot be empty" })
        }
        if (Boolean(usage.venueDetails.lastName) === false) {
            livePerformanceIsValid.push({ messageKey: "Lastname field cannot be empty" })
        }
        if (Boolean(usage.venueDetails.phoneNumber) === false) {
            livePerformanceIsValid.push({ messageKey: "Contact number field cannot be empty" })
        }
        if (Boolean(usage.venueDetails.venueType) === false) {
            livePerformanceIsValid.push({ messageKey: "Venue type field cannot be empty" })
        }
        if (Boolean((usage.livePerformanceSetList)) === false) {
           livePerformanceIsValid.push({ messageKey: "Please select Setlist available" })
        }
        if (Boolean((usage.location)) === false) {
            livePerformanceIsValid.push({ messageKey: "Venue field cannot be empty" })
         }
         if (usage.files.length===0) {
            livePerformanceIsValid.push({ messageKey: "Proof of Performance field cannot be empty" })
         }
        usage.usages.map(element => {
            if (element.usageDate.value=== '') {
                livePerformanceIsValid.push({ messageKey: "Please enter valid Performance date" })
            }
            if (element.programStartDate.value === '') {
                livePerformanceIsValid.push({ messageKey: "Please enter valid Start time" })
            }
            if (element.programEndDate.value === '') {
                livePerformanceIsValid.push({ messageKey: "Please enter valid End time" })
            }
        });

        return livePerformanceIsValid;
    }
}