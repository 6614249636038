import { useMsal } from "@azure/msal-react";
import React, { useState } from 'react';
import { ComponentsHelper } from "../../core/services/ComponentHelper";

export interface IMyFormsPageProps {   
    location: any;
    powerPortalURL: string;
}

const MyFormsPage = (props: IMyFormsPageProps) => {
    const { instance, accounts } = useMsal();

    React.useEffect(() => {        
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])    

    
    let frame = <iframe src={`${props.powerPortalURL + "/SignIn?ReturnUrl=/en-US/membership-edit?id=" + props.location.aboutProps.guid}`} style={{ width: '100%', height: '100vh' }} ></iframe>        

    if (props.location.aboutProps.type === "new") {
        frame = <iframe src={`${props.powerPortalURL + "/SignIn?ReturnUrl=/en-US/membership-create"}`} style={{ width: '100%', height: '100vh' }} ></iframe>        
    }   

    return (
        frame
    );
    
}

export default MyFormsPage;