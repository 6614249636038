import {
    SAVE_METHOD_TOOLBAR
} from "../../../Consts";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";

export function getUsageDataAction(component: string) {

    const actionList: IDataActionToolbar[] = [];

    const save: IDataActionToolbar = {
        action: null,
        component: component,
        method: SAVE_METHOD_TOOLBAR,
        name: null,
    };
    actionList.push(save);

    return actionList;
}
