import accountReducer from "./AccountReducer";
import dataIngestionReducer from "./DataIngestionReducer";
import homeReducer from "./HomeReducer";
import jobReducer from "./JobReducer";
import lookupReducer from "./LookupReducer";
import modalReducer from "./ModalReducer";
import repertoireReducer from "./RepertoireReducer";
import membersPortalReducer from "./MembersPortalReducer";

export const reducers = {
    repertoire: repertoireReducer,
    modal: modalReducer,
    job: jobReducer,
    home: homeReducer,
    lookupEntities: lookupReducer,
    account: accountReducer,
    dataIngestion: dataIngestionReducer,
    membersPortal: membersPortalReducer,
};
