import * as React from "react";

export interface ISizedReadOnlyTextProps {
    label: string,
    value: string,
    size: string
}

interface ISizedReadOnlyTextState {
}

export class SizedReadOnlyText extends React.PureComponent<
    ISizedReadOnlyTextProps,
    ISizedReadOnlyTextState
> {

    constructor(props: ISizedReadOnlyTextProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        const {
            label,
            value,
            size
        } = this.props;

        return (
            <div className={size}>
                <label>{label}:</label>
                <p>{value}</p>
            </div>
        );

    }
}