import { Dictionary } from "../../core/types/Dictionary";
import IDataIngestionComponentData from "../../dataingestion/types/IDataIngestionComponentData";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IFileSystem } from "../../dataingestion/types/IFileSystem";
import { IStoragePath } from "../../dataingestion/types/IStoragePath";
import { IStoragePathItemData } from "../../dataingestion/types/IStoragePathItemData";
import { SaveState } from "../../dataingestion/types/SaveState";
import { IJob } from "../../job/types/IJob";
import { ILookup } from "../../lookup/types/ILookup";
import { ISourceMatchType } from "../../settings/types/ISourceMatchType";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";
import IFile from "../types/IFiles";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { IUsagePool } from "../../repertoire/types/usageTypes/IUsagePool";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IP } from "../../repertoire/types/IP";
import { FolderSection } from "../../dataingestion/types/FolderSection";
import { IShoppingCartSearchResult } from "../../repertoire/types/IShoppingCartSearchResult";
import { ISourceSearchResult } from "../../settings/types/ISourceSearchResult";
import { IParameterSearchResult } from "../../settings/types/IParameterSearchResult";

// Action names
type FETCH_DATA_INGESTION_COMPONENT_DATA_REQUEST_TYPE = "FETCH_DATA_INGESTION_COMPONENT_DATA_REQUEST";
type FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS_TYPE = "FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS";
type FETCH_DATA_INGESTION_COMPONENT_DATA_FAILURE_TYPE = "FETCH_DATA_INGESTION_COMPONENT_DATA_FAILURE";
type GET_DISTRIBUTIONS_REQUEST_TYPE = "GET_DISTRIBUTIONS_REQUEST";
type GET_DISTRIBUTIONS_SUCCESS_TYPE = "GET_DISTRIBUTIONS_SUCCESS";
type GET_DISTRIBUTIONS_FAILURE_TYPE = "GET_DISTRIBUTIONS_FAILURE";
type UNDO_USAGE_INGESTION_REQUEST_TYPE = "UNDO_USAGE_INGESTION_REQUEST";
type UNDO_USAGE_INGESTION_SUCCESS_TYPE = "UNDO_USAGE_INGESTION_SUCCESS";
type UNDO_USAGE_INGESTION_FAILURE_TYPE = "UNDO_USAGE_INGESTION_FAILURE";
type SET_FILE_FORMATS_TYPE = "SET_FILE_FORMATS";
type SET_DESTINATIONS_TYPE = "SET_DESTINATIONS";
type GET_POOLS_REQUEST_TYPE = "GET_POOLS_REQUEST";
type GET_POOLS_SUCCESS_TYPE = "GET_POOLS_SUCCESS";
type GET_POOLS_FAILURE_TYPE = "GET_POOLS_FAILURE";
type GET_FILESYSTEMS_REQUEST_TYPE = "GET_FILESYSTEMS_REQUEST";
type GET_FILESYSTEMS_SUCCESS_TYPE = "GET_FILESYSTEMS_SUCCESS";
type GET_FILESYSTEMS_FAILURE_TYPE = "GET_FILESYSTEMS_FAILURE";
type SHOW_JOB_PROGRESS_TYPE = "SHOW_JOB_PROGRESS";
type EDITING_CHANGED_TYPE = "EDITING_CHANGED";
type VALIDATION_MESSAGE_CHANGED_TYPE = "VALIDATION_MESSAGE_CHANGED";
type FILE_METADATA_CHANGED_TYPE = "FILE_METADATA_CHANGED";
type TOGGLE_SIDEBAR_COLLAPSED_TYPE = "TOGGLE_SIDEBAR_COLLAPSED";
type SET_SELECTED_NODE_TYPE = "SET_SELECTED_NODE";
type RESET_MESSAGE_BANNER_TYPE = "RESET_MESSAGE_BANNER";
type MATCH_NOW_TYPE = "MATCH_NOW";
type SET_IN_DROP_ZONE_TYPE = "SET_IN_DROP_ZONE";
type ADD_FILE_TYPE = "ADD_FILE"
type SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS_TYPE = "SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS"
type SORT_COLUMN_TYPE = "SORT_COLUMN";
type CLEAN_UPLOAD_FILES_STATE_TYPE = "CLEAN_UPLOAD_FILES_STATE";
type CLEAN_STORAGE_PATH_STATE_TYPE = "CLEAN_STORAGE_PATH_STATE";

type UPDATE_USER_PREFERENCE_TYPE = "UPDATE_USER_PREFERENCE";
type UPDATE_PAGINATION_TYPE = "UPDATE_PAGINATION";
type RESET_PAGINATION_TYPE = "RESET_PAGINATION";

type CLEAR_DATA_INGESTION_CACHE_TYPE = "CLEAR_DATA_INGESTION_CACHE";

type SEARCH_RIDGTHOLDERS_REQUEST_TYPE = "SEARCH_RIDGTHOLDERS_REQUEST";
type SEARCH_RIDGTHOLDERS_SUCCESS_TYPE = "SEARCH_RIDGTHOLDERS_SUCCESS";
type SEARCH_RIDGTHOLDERS_FAILURE_TYPE = "SEARCH_RIDGTHOLDERS_FAILURE";
type UPDATE_STORAGE_PATHITEM_IP_TYPE = "UPDATE_STORAGE_PATHITEM_IP";


type SEARCH_LICENSES_REQUEST_TYPE = "SEARCH_LICENSES_REQUEST";
type SEARCH_LICENSES_REQUEST_SUCCESS_TYPE = "SEARCH_LICENSES_REQUEST_SUCCESS";
type SEARCH_LICENSES_REQUEST_FAILURE_TYPE = "SEARCH_LICENSES_REQUEST_FAILURE";

type UPDATE_STORAGE_PATHITEM_SHOPPINGCART_TYPE = "UPDATE_STORAGE_PATHITEM_SHOPPINGCART";

type GET_FOLDER_CONFIGURATION_REQUEST_TYPE = "GET_FOLDER_CONFIGURATION_REQUEST";
type GET_FOLDER_CONFIGURATION_SUCCESS_TYPE = "GET_FOLDER_CONFIGURATION_SUCCESS";
type GET_FOLDER_CONFIGURATION_FAILURE_TYPE = "GET_FOLDER_CONFIGURATION_FAILURE";

type LOAD_FOLDER_LIST_REQUEST_TYPE = "LOAD_FOLDER_LIST_REQUEST";
type LOAD_FOLDER_LIST_SUCCESS_TYPE = "LOAD_FOLDER_LIST_SUCCESS";
type LOAD_FOLDER_LIST_FAILURE_TYPE = "LOAD_FOLDER_LIST_FAILURE";

// Action types
export interface DataIngestionComponentDataRequest {
    type: FETCH_DATA_INGESTION_COMPONENT_DATA_REQUEST_TYPE;
}

export interface DataIngestionComponentDataSuccess {
    type: FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS_TYPE;
    payload: {
        componentData: IDataIngestionComponentData;
    };
}

export interface DataIngestionComponentDataFailure {
    type: FETCH_DATA_INGESTION_COMPONENT_DATA_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetDistributionsRequest {
    type: GET_DISTRIBUTIONS_REQUEST_TYPE;
}

export interface GetDistributionsSuccess {
    type: GET_DISTRIBUTIONS_SUCCESS_TYPE;
    payload: {
        distributions: IDistribution[];
    };
}

export interface GetDistributionsFailure {
    type: GET_DISTRIBUTIONS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}
export interface UndoUsageIngestionRequest {
    type: UNDO_USAGE_INGESTION_REQUEST_TYPE;
}

export interface UndoUsageIngestionSuccess {
    type: UNDO_USAGE_INGESTION_SUCCESS_TYPE;
}

export interface UndoUsageIngestionFailure {
    type: UNDO_USAGE_INGESTION_FAILURE_TYPE;
    payload: {
        error: string;
    }
}

export interface SetFileFormats {
    type: SET_FILE_FORMATS_TYPE;
    payload: {
        formats: ILookup;
    };
}

export interface SetDestinations {
    type: SET_DESTINATIONS_TYPE;
    payload: {
        destinations: ILookup;
    };
}

export interface GetFilesystemsRequest {
    type: GET_FILESYSTEMS_REQUEST_TYPE;
}

export interface GetFilesystemsSuccess {
    type: GET_FILESYSTEMS_SUCCESS_TYPE;
    payload: {
        filesystems: IFileSystem[];
        disallowedFolderSections?: FolderSection[]
    };
}

export interface GetFilesystemsFailure {
    type: GET_FILESYSTEMS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface GetPoolsRequest {
    type: GET_POOLS_REQUEST_TYPE;
}

export interface GetPoolsSuccess {
    type: GET_POOLS_SUCCESS_TYPE;
    payload: {
        pools: IUsagePool[];
    };
}

export interface GetPoolsFailure {
    type: GET_POOLS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface ShowJobProgress {
    type: SHOW_JOB_PROGRESS_TYPE;
    payload: {
        jobs: IJob[];
    };
}

export interface EditingChanged {
    type: EDITING_CHANGED_TYPE;
    payload: {
        item: IStoragePathItemData;
        index: number;
    };
}

export interface ValidationMessageChanged {
    type: VALIDATION_MESSAGE_CHANGED_TYPE;
    payload: {
        message: string;
        index: number;
    };
}

export interface MatchNow {
    type: MATCH_NOW_TYPE;
    payload: {
        item: IStoragePathItemData;
        index: number;
    };
}


export interface FileMetadataChanged {
    type: FILE_METADATA_CHANGED_TYPE;
    payload: {
        value: any;
        field: string | boolean | string[];
        index: number;
    };
}

export interface ToggleSidebarCollapsed {
    type: TOGGLE_SIDEBAR_COLLAPSED_TYPE
}

export interface SetSelectedNode {
    type: SET_SELECTED_NODE_TYPE;
    payload: {
        node: ITreeNodeData<IFileSystem>;
        filesystems: IFileSystem[];
        blobs: IStoragePath[];
        saveState: SaveState;
        sources: ITreeData[];
    }
}

export interface ResetMessageBanner {
    type: RESET_MESSAGE_BANNER_TYPE;
}

export interface SetInDropZoneAction {
    type: SET_IN_DROP_ZONE_TYPE;
    payload: {
        inDropZone: boolean
    }
}

export interface AddFileToUploadQueue {
    type: ADD_FILE_TYPE;
    payload: {
        file: IFile,
        inProgress: boolean
    }
}

export interface SetProgressUploadFileProccess {
    type: SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS_TYPE;
    payload: {
        file: IFile,
        message: string,
        isUploadComplete: boolean
    }
}

export interface SortColumn {
    type: SORT_COLUMN_TYPE;
    payload: {
        columnName: string;
        ascending: boolean;
    };
}

export interface CleanUploadFileState {
    type: CLEAN_UPLOAD_FILES_STATE_TYPE;
}

export interface CleanStoragePath {
    type: CLEAN_STORAGE_PATH_STATE_TYPE
}

export interface UpdatePagination {
    type: UPDATE_PAGINATION_TYPE;
    payload: {
        resultsPerPage: number,
        currentPage: any,
        section: string
    }
}

export interface ResetPagination {
    type: RESET_PAGINATION_TYPE;
    payload: {
        section: string
    }
}
export interface ClearDataIngestionCache {
    type: CLEAR_DATA_INGESTION_CACHE_TYPE
}

export interface SearchRightHolderRequest {
    type: SEARCH_RIDGTHOLDERS_REQUEST_TYPE;
}

export interface SearchRightHolderSuccess {
    type: SEARCH_RIDGTHOLDERS_SUCCESS_TYPE;
    payload: {
        contributorsResult: IContributorSearchResult[];
    };
}

export interface SearchRightHolderFailure {
    type: SEARCH_RIDGTHOLDERS_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface SearchLicensesRequest {
    type: SEARCH_LICENSES_REQUEST_TYPE;    
}

export interface SearchLicensesSuccess {
    type: SEARCH_LICENSES_REQUEST_SUCCESS_TYPE;
    payload: {
        licensesResult: IShoppingCartSearchResult[];
    };
}

export interface SearchLicensesFailure {
    type: SEARCH_LICENSES_REQUEST_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export interface UpdateStoragePathItemIP {
    type: UPDATE_STORAGE_PATHITEM_IP_TYPE;
    payload: {
        storagePathItemData: IStoragePathItemData
        ip: IP
    }
}

export interface UpdateStoragePathItemShoppingCart {
    type: UPDATE_STORAGE_PATHITEM_SHOPPINGCART_TYPE;
    payload: {
        storagePathItemData: IStoragePathItemData
        shoppingCart: IShoppingCartSearchResult
    }
}

export interface GetFolderDefaultConfigurationRequest {
    type: GET_FOLDER_CONFIGURATION_REQUEST_TYPE;
    payload: {
        path: string;
    }
}

export interface GetFolderDefaultConfigurationSuccess {
    type: GET_FOLDER_CONFIGURATION_SUCCESS_TYPE;
    payload: {
        folderDefaultConfiguration: ISourceSearchResult;
    }
}

export interface GetFolderDefaultConfigurationFailure {
    type: GET_FOLDER_CONFIGURATION_FAILURE_TYPE;
    payload: {
        error: any;
    }
}

export interface LoadFolderListRequest {
    type: LOAD_FOLDER_LIST_REQUEST_TYPE;
}
export interface LoadFolderListSuccess {
    type: LOAD_FOLDER_LIST_SUCCESS_TYPE;
    payload: {
        folderList: IParameterSearchResult[];
    };
}
export interface LoadFolderListFailure {
    type: LOAD_FOLDER_LIST_FAILURE_TYPE;
    payload: {
        error: string;
    };
}

export type ActionTypes =
    DataIngestionComponentDataRequest
    | DataIngestionComponentDataSuccess
    | DataIngestionComponentDataFailure
    | GetDistributionsRequest
    | GetDistributionsSuccess
    | GetDistributionsFailure
    | SetFileFormats
    | SetDestinations
    | GetPoolsFailure
    | GetPoolsRequest
    | GetPoolsSuccess
    | GetFilesystemsRequest
    | GetFilesystemsSuccess
    | GetFilesystemsFailure
    | ShowJobProgress
    | EditingChanged
    | FileMetadataChanged
    | ToggleSidebarCollapsed
    | SetSelectedNode
    | MatchNow
    | ValidationMessageChanged
    | SetInDropZoneAction
    | AddFileToUploadQueue
    | SetProgressUploadFileProccess
    | SortColumn
    | CleanUploadFileState
    | CleanStoragePath
    | UpdatePagination
    | ResetPagination
    | ClearDataIngestionCache
    | SearchRightHolderRequest
    | SearchRightHolderFailure
    | SearchRightHolderSuccess
    | UpdateStoragePathItemIP
    | SearchLicensesRequest
    | SearchLicensesSuccess
    | SearchLicensesFailure
    | UpdateStoragePathItemShoppingCart
    | GetFolderDefaultConfigurationRequest
    | GetFolderDefaultConfigurationSuccess
    | GetFolderDefaultConfigurationFailure
    | LoadFolderListRequest
    | LoadFolderListSuccess
    | LoadFolderListFailure
    | ResetMessageBanner;

export const FETCH_DATA_INGESTION_COMPONENT_DATA_REQUEST: FETCH_DATA_INGESTION_COMPONENT_DATA_REQUEST_TYPE = "FETCH_DATA_INGESTION_COMPONENT_DATA_REQUEST";
export const FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS: FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS_TYPE = "FETCH_DATA_INGESTION_COMPONENT_DATA_SUCCESS";
export const FETCH_DATA_INGESTION_COMPONENT_DATA_FAILURE: FETCH_DATA_INGESTION_COMPONENT_DATA_FAILURE_TYPE = "FETCH_DATA_INGESTION_COMPONENT_DATA_FAILURE";
export const GET_DISTRIBUTIONS_REQUEST: GET_DISTRIBUTIONS_REQUEST_TYPE = "GET_DISTRIBUTIONS_REQUEST";
export const GET_DISTRIBUTIONS_SUCCESS: GET_DISTRIBUTIONS_SUCCESS_TYPE = "GET_DISTRIBUTIONS_SUCCESS";
export const GET_DISTRIBUTIONS_FAILURE: GET_DISTRIBUTIONS_FAILURE_TYPE = "GET_DISTRIBUTIONS_FAILURE";
export const UNDO_USAGE_INGESTION_REQUEST: UNDO_USAGE_INGESTION_REQUEST_TYPE = "UNDO_USAGE_INGESTION_REQUEST";
export const UNDO_USAGE_INGESTION_SUCCESS: UNDO_USAGE_INGESTION_SUCCESS_TYPE = "UNDO_USAGE_INGESTION_SUCCESS";
export const UNDO_USAGE_INGESTION_FAILURE: UNDO_USAGE_INGESTION_FAILURE_TYPE = "UNDO_USAGE_INGESTION_FAILURE";

export const SET_FILE_FORMATS: SET_FILE_FORMATS_TYPE = "SET_FILE_FORMATS";
export const SET_DESTINATIONS: SET_DESTINATIONS_TYPE = "SET_DESTINATIONS";
export const GET_POOLS_REQUEST: GET_POOLS_REQUEST_TYPE = "GET_POOLS_REQUEST";
export const GET_POOLS_SUCCESS: GET_POOLS_SUCCESS_TYPE = "GET_POOLS_SUCCESS";
export const GET_POOLS_FAILURE: GET_POOLS_FAILURE_TYPE = "GET_POOLS_FAILURE";
export const GET_FILESYSTEMS_REQUEST: GET_FILESYSTEMS_REQUEST_TYPE = "GET_FILESYSTEMS_REQUEST";
export const GET_FILESYSTEMS_SUCCESS: GET_FILESYSTEMS_SUCCESS_TYPE = "GET_FILESYSTEMS_SUCCESS";
export const GET_FILESYSTEMS_FAILURE: GET_FILESYSTEMS_FAILURE_TYPE = "GET_FILESYSTEMS_FAILURE";
export const SHOW_JOB_PROGRESS: SHOW_JOB_PROGRESS_TYPE = "SHOW_JOB_PROGRESS";
export const EDITING_CHANGED: EDITING_CHANGED_TYPE = "EDITING_CHANGED";
export const VALIDATION_MESSAGE_CHANGED: VALIDATION_MESSAGE_CHANGED_TYPE = "VALIDATION_MESSAGE_CHANGED";
export const FILE_METADATA_CHANGED: FILE_METADATA_CHANGED_TYPE = "FILE_METADATA_CHANGED";
export const TOGGLE_SIDEBAR_COLLAPSED: TOGGLE_SIDEBAR_COLLAPSED_TYPE = "TOGGLE_SIDEBAR_COLLAPSED";
export const SET_SELECTED_NODE: SET_SELECTED_NODE_TYPE = "SET_SELECTED_NODE";
export const RESET_MESSAGE_BANNER: RESET_MESSAGE_BANNER_TYPE = "RESET_MESSAGE_BANNER";
export const MATCH_NOW: MATCH_NOW_TYPE = "MATCH_NOW";
export const SET_IN_DROP_ZONE: SET_IN_DROP_ZONE_TYPE = "SET_IN_DROP_ZONE";
export const ADD_FILE: ADD_FILE_TYPE = "ADD_FILE";
export const SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS: SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS_TYPE = "SET_NEW_PROGRESS_UPLOAD_FILE_PROCCESS"
export const SORT_COLUMN: SORT_COLUMN_TYPE = "SORT_COLUMN";
export const CLEAN_UPLOAD_FILES_STATE: CLEAN_UPLOAD_FILES_STATE_TYPE = "CLEAN_UPLOAD_FILES_STATE"
export const CLEAN_STORAGE_PATH_STATE: CLEAN_STORAGE_PATH_STATE_TYPE = "CLEAN_STORAGE_PATH_STATE";
export const UPDATE_USER_PREFERENCE: UPDATE_USER_PREFERENCE_TYPE = "UPDATE_USER_PREFERENCE";
export const UPDATE_PAGINATION: UPDATE_PAGINATION_TYPE = "UPDATE_PAGINATION";
export const RESET_PAGINATION: RESET_PAGINATION_TYPE = "RESET_PAGINATION";
export const CLEAR_DATA_INGESTION_CACHE: CLEAR_DATA_INGESTION_CACHE_TYPE = "CLEAR_DATA_INGESTION_CACHE";
export const SEARCH_RIDGTHOLDERS_REQUEST: SEARCH_RIDGTHOLDERS_REQUEST_TYPE =
    "SEARCH_RIDGTHOLDERS_REQUEST";
export const SEARCH_RIDGTHOLDERS_SUCCESS: SEARCH_RIDGTHOLDERS_SUCCESS_TYPE =
    "SEARCH_RIDGTHOLDERS_SUCCESS";
export const SEARCH_RIDGTHOLDERS_FAILURE: SEARCH_RIDGTHOLDERS_FAILURE_TYPE =
    "SEARCH_RIDGTHOLDERS_FAILURE";
export const UPDATE_STORAGE_PATHITEM_IP: UPDATE_STORAGE_PATHITEM_IP_TYPE =
    "UPDATE_STORAGE_PATHITEM_IP";

export const SEARCH_LICENSES_REQUEST: SEARCH_LICENSES_REQUEST_TYPE =
    "SEARCH_LICENSES_REQUEST";
export const SEARCH_LICENSES_REQUEST_SUCCESS: SEARCH_LICENSES_REQUEST_SUCCESS_TYPE =
    "SEARCH_LICENSES_REQUEST_SUCCESS";
export const SEARCH_LICENSES_REQUEST_FAILURE: SEARCH_LICENSES_REQUEST_FAILURE_TYPE =
    "SEARCH_LICENSES_REQUEST_FAILURE";

export const UPDATE_STORAGE_PATHITEM_SHOPPINGCART: UPDATE_STORAGE_PATHITEM_SHOPPINGCART_TYPE =
    "UPDATE_STORAGE_PATHITEM_SHOPPINGCART";


export const GET_FOLDER_CONFIGURATION_REQUEST: GET_FOLDER_CONFIGURATION_REQUEST_TYPE = "GET_FOLDER_CONFIGURATION_REQUEST";
export const GET_FOLDER_CONFIGURATION_SUCCESS: GET_FOLDER_CONFIGURATION_SUCCESS_TYPE = "GET_FOLDER_CONFIGURATION_SUCCESS";
export const GET_FOLDER_CONFIGURATION_FAILURE: GET_FOLDER_CONFIGURATION_FAILURE_TYPE = "GET_FOLDER_CONFIGURATION_FAILURE";

export const LOAD_FOLDER_LIST_REQUEST: LOAD_FOLDER_LIST_REQUEST_TYPE = "LOAD_FOLDER_LIST_REQUEST";
export const LOAD_FOLDER_LIST_SUCCESS: LOAD_FOLDER_LIST_SUCCESS_TYPE = "LOAD_FOLDER_LIST_SUCCESS";
export const LOAD_FOLDER_LIST_FAILURE: LOAD_FOLDER_LIST_FAILURE_TYPE = "LOAD_FOLDER_LIST_FAILURE";