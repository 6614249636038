import * as React from "react";
import { throttle, last } from "lodash";
import { ALL_ROLES, ARTIST_MAINTENANCE_TOOLBAR, COMPONENT_INSTANCE_ARTISTS, COMPONENT_INSTANCE_IPS, DELETE_ARTIST_PROMPT_STATE_KEY, DELETE_IP_PROMPT_STATE_KEY, EVENT_HANDLER_THROTTLE_TIME, IP_MAINTENANCE_GENERAL_DATA_VIEW, IP_MAINTENANCE_PAGE, IP_MAINTENANCE_TOOLBAR, POST_TO_REPERTOIRE_STATE_KEY, REPERTOIRE_SIDE_MENU_ARTISTS_KEY, REPERTOIRE_SIDE_MENU_IPS_KEY, REPERTOIRE_SIDE_MENU_PRODUCTS_KEY, TOOLBAR_POSITION_BREAKPOINT, UPDATE_ARTISTS_ROLE } from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IAgreementSearchState } from "../../../types/IAgreementSearchState";
import { IIPAgreement } from "../../../types/IIPAgreement";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { If } from "../../../../core/components/If";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { addTab, clearModalSearchResults, setChangesMade, setEditableFields, sortArtistFieldData, updateArtistField } from "../../../../redux/reducers/RepertoireReducer";
import ArtistMaintenanceToolbar, { IArtistMaintenanceToolbarProps } from "../artistMaintenanceToolbar/ArtistMaintenanceToolbar";
import ArtistMaintenanceGeneralDataView from "../artistMaintenanceGeneralDataView/ArtistMaintenanceGeneralDataView";
import ArtistMaintenanceGridsView from "../artistMaintenanceGridsView/ArtistMaintenanceGridsView";
import { IArtistState } from "../../../../redux/types/IArtistState";
import { IArtistCore } from "../../../types/IArtistCore";

export interface IArtistMaintenancePageProps {
    artistMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    artistMaintenanceGridsViewData: IRepertoireComponentDataItem;
    artistMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    artistMaintenanceToolbarData?: IRepertoireComponentDataItem;
    artistMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    artist: IArtistState;
    setChangesMade: typeof setChangesMade;
    activeAccordion: string[];
    updateArtistField: typeof updateArtistField;
    sortArtistFieldData: typeof sortArtistFieldData;
    openArtistAccordion: (accordionName: string, componentName: string) => void;
    closeArtistAccordion: (accordionName: string, componentName: string) => void;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    addTab: typeof addTab;
    saveChanges: (artist: IArtistState) => void;
    deleteArtist: (performerID: number, activeTab: number) => void;
    addNewArtist: (lookupValues: ILookupDictionary, isNew?: boolean, artistMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => void;
    undoArtistChanges: (performerID: number) => void;
    resetMessageBanner: () => void;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    lookupValues: ILookupDictionary;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    editableFields: IRepertoireField[];
    roles?: string[];
    incrementTempID?: () => void;
    updatedScroll?: number;
}

interface IArtistMaintenancePageState {
    toolbarWidth: number;
}
export type IArtistMaintenanceToolbarKeys = keyof IArtistMaintenanceToolbarProps;

export class ArtistMaintenancePage extends React.PureComponent<
    IArtistMaintenancePageProps,
    IArtistMaintenancePageState
> {
    toolbarParentRef;
    constructor(props: IArtistMaintenancePageProps) {
        super(props)
        this.state = {
            toolbarWidth: null
        };
        this.toolbarParentRef = React.createRef();
    }

    saveArtist() {
        const { saveChanges, artist } = this.props;
        saveChanges(artist);
    }


    deleteAction() {
        const { artistMaintenanceToolbarData, showYesNoPrompt, hideModal } = this.props;

        const title: string = artistMaintenanceToolbarData.fields.find(f => f.name === DELETE_ARTIST_PROMPT_STATE_KEY).data;
        const props: IYesNoPromptViewModalProps = {
            yesCallback: () => { this.deleteArtist(); hideModal(); },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, props);
    }

    deleteArtist() {
        const { deleteArtist, artist, activeTab } = this.props;
        deleteArtist(artist.performerID, activeTab);
    }

    undoChanges = () => {
        const { undoArtistChanges, artist } = this.props;
        undoArtistChanges(artist.performerID);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    onUpdateComponentFieldItems(fields: IRepertoireField[], componentName: string) {
        const { updateComponentFieldsItems, activeTab, tabs } = this.props;
        const dataActions = getDataAction("", ARTIST_MAINTENANCE_TOOLBAR);
        updateComponentFieldsItems(fields, componentName, COMPONENT_INSTANCE_ARTISTS, REPERTOIRE_SIDE_MENU_ARTISTS_KEY, tabs, activeTab)
    }
    openAccordion(accordionName: string, componentName: string): void {
        const { openArtistAccordion, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        openArtistAccordion(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, true, componentName);
    }
    closeAccordion(accordionName: string, componentName: string): void {
        const { closeArtistAccordion, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        closeArtistAccordion(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, false, componentName);
    }

    render() {
        const {
            artistMaintenanceGeneralDataViewData,
            artistMaintenanceGridsViewData,
            artistMaintenanceAccordionViewData,
            artistMaintenanceToolbarData,
            artistMaintenanceSaveResultData,
            dataGridTableData,
            setChangesMade,
            updateArtistField,
            activeAccordion,
            addNewArtist,
            addTab,
            artist,
            tabs,
            activeTab,
            sortArtistFieldData,
            showModal,
            hideModal,
            setEditableFields,
            updateComponentFieldsItems,
            editableFields,
            editableFieldsDataView,
            showUpdateFieldsModal,
            lookupValues,
            roles,
            incrementTempID,
        } = this.props;

        const isReadonly: boolean = tabs[activeTab].isReadonly;

        if (this.props.artist) {
            const {
                firstName,
                lastName,
                fullName,
                number,
                type,
            } = this.props.artist

            const {updatedScroll } = this.props;
            const {
                toolbarWidth
            } = this.state;

            const changesMade = tabs[activeTab].changesMade;

            const title = fullName;

            return (
                <div>
                    <div><span className="title">{title}</span></div>
                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>
                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <ArtistMaintenanceToolbar
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            changesMade={changesMade}
                            saveResultData={artistMaintenanceSaveResultData}
                            toolbarData={artistMaintenanceToolbarData}
                            saveChanges={this.saveArtist.bind(this)}
                            deleteArtist={this.deleteAction.bind(this)}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            addNewArtist={() => addNewArtist(lookupValues, true, artistMaintenanceGeneralDataViewData)}
                            activeTab={activeTab}
                            tabs={tabs}
                            showYesNoPrompt={showYesNoPrompt}
                            hideModal={hideModal}
                            artist={artist}
                            undoArtistChanges={this.undoChanges.bind(this)}
                            isReadonly={isReadonly}
                            clearModalSearchResults={clearModalSearchResults}
                            roles={roles}
                        />
                    </div>

                    <ArtistMaintenanceGeneralDataView
                        artistMaintenanceGeneralDataViewData={artistMaintenanceGeneralDataViewData}
                        lastName={lastName}
                        firstName={firstName}
                        type={type}
                        updateArtistField={updateArtistField}
                        changesMade={changesMade}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        dataGridTableEditableFieldsData={dataGridTableData}
                        setEditableFields={setEditableFields}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        editableFields={editableFields}
                        activeTab={activeTab}
                        tabs={tabs}
                        hideModal={hideModal}
                        showModal={showModal}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        roles={roles}
                    />
                    <ArtistMaintenanceGridsView
                        artistNumbers={number}
                        showModal={showModal}
                        activeAccordion={activeAccordion}
                        openArtistAccordion={this.openAccordion.bind(this)}
                        closeArtistAccordion={this.closeAccordion.bind(this)}
                        dataGridTableData={dataGridTableData}
                        artistMaintenanceGridsViewData={artistMaintenanceGridsViewData}
                        artistMaintenanceAccordionViewData={artistMaintenanceAccordionViewData}
                        addTab={addTab}
                        tabs={tabs}
                        activeTab={activeTab}
                        componentInstance={null}
                        updateArtistField={updateArtistField}
                        sortArtistFieldData={sortArtistFieldData}
                        lookupValues={lookupValues}
                        isReadonly={isReadonly}
                        incrementTempID={incrementTempID}
                    />
                </div>

            );
        }
        return <div>Loading...</div>;
    }
}

