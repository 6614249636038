import axios from "axios";
import { IRegisterViewModel } from "../types/IRegisterViewModel";
import { ILoginViewModel } from "../types/ILoginViewModel";
import { Promise } from "es6-promise";
import { IRegistrationResponse } from "../types/IRegistrationResponse";
import { IViewAsAccessRequest } from "../../membersportal/types/IViewAsAccessRequest";
import { IApplication } from "../../membersportal/types/IApplication";
import { ICase } from "../../membersportal/types/ICase";

export class AccountService {

    public static register(
        viewModel: IRegisterViewModel
    ): Promise<IRegistrationResponse> {
        return new Promise<IRegistrationResponse>((resolve, reject) => {
            axios
                .post("Account/Register", viewModel)
                .then(res => {
                    if (res.data.success) {
                        sessionStorage.username = "";
                    }

                    resolve({ success: res.data.success, errors: res.data.errors });
                })
                .catch(res => {
                    resolve({ success: false, errors: res.data.errors });
                });
        });
    }

    public static login(postData: ILoginViewModel): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios
                .post("Account/Login", postData)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else {
                        reject(res);
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static logout(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            axios
                .get("Account/Logout")
                .then(res => {
                    resolve(true);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static async getAADLoginConfiguration() {
        const response = await axios
            .get("Account/AADLoginConfiguration")
        return response.data
    }

    public static async getB2CLoginConfiguration() {
        const response = await axios
            .get("Account/B2CLoginConfiguration")
        return response.data
    }

    public static loginType(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios
                .get("Account/LoginTypeFeatureSwitch")
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else {
                        reject(res);
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static requestViewAsAccess(
        accountNumber: string,
        userName:string
    ): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post("Account/RequestViewAsAccess", { AccountNumber: accountNumber, UserName: userName })
                .then(res => {                
                    resolve(res.data);
                })
                .catch(res => {
                    resolve(res.data);
                });
        });
    }

    public static getUserCRMValues(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios
                .get("Account/GetUserCRMValues")
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else {
                        reject(res);
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getUserCRMIpNumber(
        guid: string
    ): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            axios
                .get("Account/GetUserCRMIpNumber/" + guid)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else {
                        reject(res);
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    public static getViewAsAccessRequests(
        userName: string
    ): Promise<IViewAsAccessRequest[]> {
        return new Promise<IViewAsAccessRequest[]>((resolve, reject) => {
            axios
                .get("Account/GetViewAsAccessRequests/" + userName)
                .then(res => {
                    const viewAsAccessRequests = [];

                    for (let x = 0; x < res.data.length; x++) {
                        const viewAsAccessRequest = {
                            accountNumber: res.data[x].accountNumber,
                            ipName: res.data[x].ipName,
                            ipiNumber: res.data[x].ipiNumber,
                            status: res.data[x].status,
                            defaultAccess: res.data[x].defaultAccount,
                            guid: res.data[x].guid
                        };

                        viewAsAccessRequests.push(viewAsAccessRequest);
                    }
                    resolve(viewAsAccessRequests);

                })
                .catch(res => {
                    //resolve(res.data);
                });
        });
    }

    public static getMyApplications(
    ): Promise<IApplication[]> {
        return new Promise<IApplication[]>((resolve, reject) => {
            axios
                .get("Account/GetMyApplications")
                .then(res => {
                    const applications = [];

                    for (let x = 0; x < res.data.length; x++) {
                        const application = {
                            applicationId: res.data[x].applicationId,
                            name: res.data[x].name,
                            submittedDateTime: res.data[x].submittedDateTime,
                            status: res.data[x].status,
                            guid: res.data[x].guid,
                            lastModifiedDate: res.data[x].lastModifiedDateTime
                        };

                        applications.push(application);
                    }
                    resolve(applications);

                })
                .catch(res => {
                    reject(res.data);
                });
        });
    }

    public static getMyCases(
    ): Promise<ICase[]> {
        return new Promise<ICase[]>((resolve, reject) => {
            axios
                .get("Account/GetMyCases")
                .then(res => {
                    const cases = [];

                    for (let x = 0; x < res.data.length; x++) {
                        const caseReturn = {
                            ticketNumber: res.data[x].ticketNumber,
                            title: res.data[x].title,
                            caseType: res.data[x].caseType,
                            caseOrigin: res.data[x].caseOrigin,
                            submittedDateTime: res.data[x].submittedDateTime,
                            status: res.data[x].status,
                            subject: res.data[x].subjectId,
                            guid: res.data[x].guid,
                            lastModifiedDate: res.data[x].lastModifiedDateTime

                        };

                        cases.push(caseReturn);
                    }
                    resolve(cases);

                })
                .catch(res => {
                    reject(res.data);
                });
        });
    }

    public static setViewAsDefaultAccount(
        defaultAccount: string,
        userName: string
    ): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post("Account/SetViewAsDefaultAccount/", { AccountNumber: defaultAccount, UserName: userName })
                .then(res => {                   
                    resolve(res.data);

                })
                .catch(res => {
                    reject(res.data);
                });
        });
    }

    public static cancelAccessRequest(
        defaultAccount: string,
        userName: string
    ): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            axios
                .post("Account/CancelAccessRequest/", { AccountNumber: defaultAccount, UserName: userName })
                .then(res => {
                    resolve(res.data);

                })
                .catch(res => {
                    reject(res.data);
                });
        });
    }

    

}
