import * as React from "react";
import { REJECT_REASON_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal } from "../../../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { SEARCH_VIEW_WORKFLOW } from "../../../Consts";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IWorkflow } from "../../../types/IWorkflow";
import DropdownDataInput, { SizedDropdownDataInput } from "../../dropdownDataInput/DropdownDataInput";

export interface IRejectWorkflowViewModalProps {
    workflowFieldsData: IRepertoireComponentDataItem;
    workflow: IWorkflow;
    onApproveOrReject: (workflow: IWorkflow, approved: boolean) => void;
    title: string;
}

export interface IRejectWorkflowViewProps {
    props: IRejectWorkflowViewModalProps;
    hideModal: typeof hideModal;
    lookupValues: ILookupDictionary;
}

const RejectWorkflowView: React.SFC<IRejectWorkflowViewProps> = ({ props, hideModal, lookupValues }) => {
    const { workflowFieldsData, workflow, onApproveOrReject } = props;

    const [rejectReason, setRejectReason] = React.useState('');

    const changeData = (value: any) => {
        setRejectReason(value);
    }

    const rejectButtonText: string = workflowFieldsData.fields.find(f => f.name === "workflowRejectConfirm").data;

    const onClickReject = () => {
        let workflowWithRejectReason = workflow;
        workflowWithRejectReason.rejectReason = rejectReason;
        onApproveOrReject(workflowWithRejectReason, false);
        hideModal();
    }
    return (
        <div className="repertoireModal">
            <div className={"modal"}>
                <div>
                    <div className="closeButton" onClick={hideModal}>
                        <img src={"assets/close.svg"} className="closeIcon" />
                    </div>
                </div>
                <div className="fixedPart" />
                <div className="dynamicPart">
                    <div className="title">{props.title}</div>
                    <div className="modalBody" >
                        <div className="searchView">
                            <div className="row">
                                <DropdownDataInput
                                    options={lookupValues[REJECT_REASON_TYPE_LOOKUP].lookups}
                                    label={""}
                                    fieldName={"Reason"}
                                    value={rejectReason}
                                    changeData={changeData}
                                    allowNull={true}
                                    isHidden={false}
                                    componentInstance={SEARCH_VIEW_WORKFLOW}
                                />
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                                    <button className="button btn-defaultPrimary"
                                        onClick={onClickReject}>
                                        {rejectButtonText}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="fixedPart" />
            </div>
        </div>
    );
};

export default RejectWorkflowView;