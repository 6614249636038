import React, { PureComponent, FunctionComponent } from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";

export interface ICheckboxDataInputProps {
    label: string;
    value: any;
    changeData: (
        value: string | boolean,
        fieldName: IRepertoireStateKeys
    ) => void;
    fieldName: IRepertoireStateKeys;
    readonly?: boolean;
    isHidden?: boolean;
    inline?: boolean;
    key?: string;
}

export const SizedCheckboxDataInput: FunctionComponent<ICheckboxDataInputProps> = (props: ICheckboxDataInputProps) => {

    if (props.isHidden)
        return null;

    return <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
        <CheckboxDataInput {...props} />
    </div>
}

export default class CheckboxDataInput extends PureComponent<
    ICheckboxDataInputProps
> {
    constructor(props) {
        super(props);
    }

    render() {
        const { label, changeData, value, fieldName, readonly, isHidden, inline, key } = this.props;
        if (isHidden)
            return null;

        return (
            <div key={key} className={["checkboxContainer", inline && "inline"].join(" ")}
              style={{
                paddingTop: fieldName === "memberStatementCustomRange" ? "25px" : null
              }}
            >
                <label className="subContainer">
                    <input
                        className={"disabledItem"}
                        type={"checkbox"}
                        onChange={event => changeData(event.target.checked, fieldName)}
                        value={value || false}
                        checked={value || false}
                        disabled={readonly}
                        data-testid={fieldName}
                    />
                    <span className="inputCheckbox" />
                    <div className={["checkboxLabel", readonly ? "disabledItem" : ""].join(" ")}>{label}</div>
                </label>
            </div>
        );
    }
}
