import { connect } from "react-redux";
import { Dispatch } from "redux";
import { updateJobSearchStateField } from "../../../redux/reducers/HomeReducer";
import IAppState from "../../../redux/types/IAppState";
import { IJobSearchStateKeys } from "../../types/IJobSearchState";
import { JobSearchView } from "./JobSearchView";

export default connect(
    (state: IAppState) => ({
        lookups: state.lookupEntities,
        users: state.account.users,
        jobSearchState: state.home.jobSearchState,
        jobSearchViewData: state.home.componentData.JobSearchView
    }),
    (dispatch: Dispatch) => ({
        updateJobSearchStateField: (value: any, field: string) => dispatch<any>(updateJobSearchStateField(value, field)),
    })
)(JobSearchView);