
import React from "react";

import throttle from 'lodash.throttle';
import { EVENT_HANDLER_THROTTLE_TIME } from "../../../Consts";
import { ILicenseMaintenancePageProps, LicenseMaintenancePage } from "./LicenseMaintenancePage";


const LicenseMaintenancePageScrollListener: React.FC<ILicenseMaintenancePageProps> = (props) => {
    const [updatedScroll, setUpdatedScroll] = React.useState(0);
    React.useEffect(() => {
        window.addEventListener(
            "scroll",
            throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                trailing: true
            })
        );
        return  window.removeEventListener(
            "scroll",
            throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                leading: true
            })
        );
    }, []);
    const handleScroll = () => {
        setUpdatedScroll(window.scrollY);
    };
    return <LicenseMaintenancePage {...props} updatedScroll={updatedScroll} />
    
}
export default LicenseMaintenancePageScrollListener;