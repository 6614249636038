import { useMsal } from "@azure/msal-react";
import React, { useState } from 'react';
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { msalConfig } from "../../accounts/types/msalAuthConfig";

export interface IMyCasesPageProps {
    location: any;
    powerPortalURL: string;
}

const MyCasesPage = (props: IMyCasesPageProps) => {
    const { instance, accounts } = useMsal();

    React.useEffect(() => {       
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    let frame = <iframe src={`${props.powerPortalURL + "/SignIn?ReturnUrl=/en-US/support/edit-case/?id=" + props.location.aboutProps.guid}`} style={{ width: '100%', height: '100vh' }} ></iframe>        

    if (props.location.aboutProps.type === "new") {
        frame = <iframe src={`${props.powerPortalURL + "/SignIn?ReturnUrl=/en-US/support/create-case/"}`} style={{ width: '100%', height: '100vh' }} ></iframe>        
    }
        
    return (
        frame
    );
}

export default MyCasesPage;