import * as React from "react";

export interface IPopUpInfoProps {
    textToScreen?: string;
    imageToScreen?: string;
}

const PopUpInfo: React.SFC<IPopUpInfoProps> = ({
    textToScreen,
    imageToScreen
}) => {
    return (
        <div className="popUpInfo">
            {imageToScreen ? (
                <img className="popUpImage" src={imageToScreen} />
            ) : null}
            {textToScreen ? (
                <div className="popUpText">{textToScreen}</div>
            ) : null}
        </div>
    );
};

export default PopUpInfo;