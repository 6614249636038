import * as React from "react";
import { DataTypeHelper } from "../../../../core/services/DataTypeHelper";
import { AVAILABILITY_TYPE_LOOKUP, BINDING_TYPE_LOOKUP, BOOK_TYPE_LOOKUP, INDICATOR_VALUE_TYPE_LOOKUP, LEARNING_BOOK_TYPE_LOOKUP, MEDIA_CATEGORY_TYPE_LOOKUP, MEDIA_TYPE_LOOKUP, PERIODICITY_TYPE_LOOKUP, PUBLICATION_TYPE_LOOKUP, TRANSLATION_TYPE_LOOKUP, WORK_FLAG_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { ARTICLE_METADATA_KEY, AVAILABILITY, BINDING_TYPE, BOOK_METADATA_KEY, BOOK_TYPE, MAGAZINE_METADATA_KEY, MEDIUM_CATEGORY_TYPE, MEDIUM_TYPE, PERIODICITY_TYPE, PUBLICATION_TYPE, SHEET_MUSIC_METADATA_KEY, SHEET_MUSIC_TYPE, TEACHING_TYPE, TRANSLATION_TYPE, CREATIVE_COMMONS } from "../../../Consts";
import { IFlag } from "../../../types/IFlag";
import { IReadonlyFlagsField } from "../../../types/IReadonlyFlagsField";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";

export interface IWorkMetadataAccordionProps{
    tabContents: any;
    lookupValues?: ILookupDictionary;
    isReadOnly?: boolean;
    changeData: (value: any, name: IRepertoireStateKeys) => void;
    componentInstance: string;
    readonlyFlagsField: IReadonlyFlagsField[];
    readonlyIndicators: IFlag[];
    workMetadataAccordionViewData?: IRepertoireComponentDataItem
}

export interface IMetadataAccordianState{
    loaded: boolean;
}

export default class WorkMetadataAccordion extends React.PureComponent<IWorkMetadataAccordionProps,IMetadataAccordianState>{

    constructor(props: IWorkMetadataAccordionProps){
        super(props);
    }

    changeMetadataFields = ( value: any,objectKey: string) => {
        
        const { tabContents, componentInstance, changeData} = this.props;

        tabContents[objectKey] = value;

        changeData(tabContents, componentInstance);
        // this.setState({});
        
    };

    changeOtherIndicatorsFields = ( value: any, fieldName: string, index: number) => {
        
        const { tabContents, componentInstance, changeData} = this.props;

        if(fieldName == "C. Commons" || fieldName == "C. Commons (Carga)"){
            tabContents[index].value = value;
        }
        else{
            tabContents[index].value = value == true ? "1" : value == false ? "0" : value;
        }
        
        changeData(tabContents, componentInstance);
        
    };

    keyDownSubmit = (value: any, fieldName: IRepertoireStateKeys) => {
        
        if (value.key === 'Enter') {
            this.changeMetadataFields(fieldName,value);
        }
    }

    readonlyIndicatorsCheck = (fieldName: string) : boolean => {
        const{readonlyFlagsField, readonlyIndicators} = this.props

        let value = (readonlyIndicators && readonlyFlagsField) ? ((readonlyIndicators?.filter
            (x => x.flagTypeCode == (readonlyFlagsField?.filter
                (x => x.FieldNames?.includes(fieldName))[0]?.Code))[0]?.value)) : "0";

        let isReadOnly = DataTypeHelper.getBooleanFormString(value);
                
        return isReadOnly;
    }

    renderMetadataFields = () =>{
        const { tabContents, componentInstance, workMetadataAccordionViewData, lookupValues, isReadOnly } = this.props;

        if (workMetadataAccordionViewData && workMetadataAccordionViewData.fields){

            // filter metadataFields based on component instance
            let filteredMetadataFields = workMetadataAccordionViewData.fields.filter(
                (metadataField: IRepertoireField) =>
                    metadataField.componentInstance === componentInstance
            );

            let filteredMetadataFieldsMapper = (metadataField : IRepertoireField, index: number): React.ReactElement => {
                let reactElement: React.ReactElement= <></>;
                
                if(metadataField.fieldType === 'text' || metadataField.fieldType === 'integer' || metadataField.fieldType === 'decimal')
                    reactElement = 
                        <div key={index}>
                            <SizedTextDataInput
                                fieldType={metadataField.fieldType}
                                label={metadataField.data}
                                fieldName={metadataField.name}
                                changeData={this.changeMetadataFields} 
                                value ={tabContents[metadataField.name]}
                                readOnly={ isReadOnly ? isReadOnly : this.readonlyIndicatorsCheck(metadataField.name)}
                                isHidden={metadataField.hidden}
                            />
                        </div>;
                else if(metadataField.fieldType === "readonly")
                    reactElement = 
                    <div key={index}>
                            <SizedTextDataInput
                                fieldType={metadataField.fieldType}
                                label={metadataField.data}
                                fieldName={metadataField.name}
                                changeData={this.changeMetadataFields} 
                                value ={tabContents[metadataField.name]}
                                readOnly={metadataField.fieldType == "readonly"}
                                isHidden={metadataField.hidden}
                            />
                        </div>;
                else if (metadataField.fieldType === 'date')
                    reactElement = (metadataField.name === 'dateOfRegistration') ?
                    <div key={index}>
                        <SizedDateDataInput
                            label={metadataField.data}
                            fieldName={metadataField.name}
                            value={tabContents[metadataField.name]}
                            changeData={this.changeMetadataFields}
                            readOnly={true}
                            isHidden={metadataField.hidden}
                        />
                    </div>
                    :
                    <div key={index}>
                        <SizedDateDataInput
                            label={metadataField.data}
                            fieldName={metadataField.name}
                            value={tabContents[metadataField.name]}
                            changeData={this.changeMetadataFields}
                            readOnly={isReadOnly ? isReadOnly : this.readonlyIndicatorsCheck(metadataField.name)}
                            isHidden={metadataField.hidden}
                        />
                    </div>;
                else if (metadataField.fieldType === 'dropdown')
                {
                    let options: {code: string, description: string}[]= [];

                    if((metadataField.name === TRANSLATION_TYPE) && lookupValues)
                        options = getLookupValues(TRANSLATION_TYPE_LOOKUP, lookupValues)
                    else if (((componentInstance === SHEET_MUSIC_METADATA_KEY || componentInstance === BOOK_METADATA_KEY) && metadataField.name === BINDING_TYPE) && lookupValues)
                        options = getLookupValues(BINDING_TYPE_LOOKUP, lookupValues)
                    else if (((componentInstance === SHEET_MUSIC_METADATA_KEY  || componentInstance === BOOK_METADATA_KEY) && metadataField.name === MEDIUM_TYPE) && lookupValues)
                        options = getLookupValues(MEDIA_TYPE_LOOKUP, lookupValues)
                    else if (((componentInstance === SHEET_MUSIC_METADATA_KEY || componentInstance === BOOK_METADATA_KEY) && metadataField.name === MEDIUM_CATEGORY_TYPE) && lookupValues)
                        options = getLookupValues(MEDIA_CATEGORY_TYPE_LOOKUP, lookupValues)
                    else if ((componentInstance === SHEET_MUSIC_METADATA_KEY && metadataField.name === SHEET_MUSIC_TYPE) && lookupValues)
                        options = getLookupValues(BOOK_TYPE_LOOKUP, lookupValues)
                    else if ((componentInstance === BOOK_METADATA_KEY && metadataField.name === BOOK_TYPE) && lookupValues)
                        options = getLookupValues(BOOK_TYPE_LOOKUP, lookupValues)
                    else if ((componentInstance === BOOK_METADATA_KEY && metadataField.name === TEACHING_TYPE) && lookupValues)
                        options = getLookupValues(LEARNING_BOOK_TYPE_LOOKUP, lookupValues)
                    else if ((componentInstance === BOOK_METADATA_KEY && metadataField.name === AVAILABILITY) && lookupValues)
                        options = getLookupValues(AVAILABILITY_TYPE_LOOKUP, lookupValues)
                    else if ((componentInstance === MAGAZINE_METADATA_KEY && metadataField.name === PUBLICATION_TYPE) && lookupValues)
                        options = getLookupValues(PUBLICATION_TYPE_LOOKUP, lookupValues)
                    else if ((componentInstance === MAGAZINE_METADATA_KEY && metadataField.name === PERIODICITY_TYPE) && lookupValues)
                        options = getLookupValues(PERIODICITY_TYPE_LOOKUP, lookupValues)
                    else
                        options = undefined

                    reactElement =
                        <div key={index}>
                            <SizedDropdownDataInput
                                label={metadataField.data}
                                fieldName={metadataField.name}
                                changeData={this.changeMetadataFields}
                                value={tabContents[metadataField.name]}
                                options={options}
                                allowNull={true}
                                isHidden={metadataField.hidden}
                                readOnly={isReadOnly ? isReadOnly : this.readonlyIndicatorsCheck(metadataField.name)}
                                componentInstance={componentInstance}
                             />
                        </div>
                }
                else if (metadataField.fieldType === 'checkbox')
                        reactElement =
                            <div key={index}>
                                <SizedCheckboxDataInput
                                    label={metadataField.data}
                                    fieldName={metadataField.name}
                                    value={tabContents[metadataField.name]}
                                    changeData={this.changeMetadataFields}
                                    readonly={isReadOnly ? isReadOnly : this.readonlyIndicatorsCheck(metadataField.name)}
                                    isHidden={metadataField.hidden}
                                />
                            </div>
                
                return reactElement;
            };
            return filteredMetadataFields.map(filteredMetadataFieldsMapper)
        }

        
    };

    renderOtherIndicatorsFields = () =>{

        const { tabContents, componentInstance, lookupValues, isReadOnly } = this.props;

        let filteredOtherIndicatorsFieldsMapper = (flagField : IFlag, index: number): React.ReactElement => {

            let reactElement: React.ReactElement = <></>;

            if (flagField.dataType === "boolean")
                reactElement = (
                  <div key={index}>
                    <SizedCheckboxDataInput
                      label={flagField.name}
                      fieldName={flagField.flagTypeCode}
                      value={flagField.value == "1" ? true : false}
                      changeData={(value) =>
                        this.changeOtherIndicatorsFields(value, flagField.name, index)}
                      readonly={isReadOnly ? isReadOnly : this.readonlyIndicatorsCheck(flagField.name)}
                      isHidden={false}
                    />
                  </div>
                );
            else if (flagField.dataType === "dropdown")
                {
                    let options: {code: string, description: string}[]= [];
  
                    if((flagField.flagTypeCode === CREATIVE_COMMONS) && lookupValues)
                        options = getLookupValues(INDICATOR_VALUE_TYPE_LOOKUP, lookupValues)
  
                    reactElement = (
                          <div key={index}>
                            <SizedDropdownDataInput
                              label={flagField.name}
                              fieldName={flagField.flagTypeCode}
                              changeData={(value) =>
                                this.changeOtherIndicatorsFields(value, flagField.name, index)}
                              value={flagField.value}
                              options={options}
                              allowNull={true}
                              readOnly={isReadOnly ? isReadOnly : this.readonlyIndicatorsCheck(flagField.name)}
                              isHidden={false}
                              componentInstance={componentInstance}
                            />
                          </div>
                    );
                }
            else if (flagField.dataType === 'text')
                    reactElement = 
                        <div key={index}>
                            <SizedTextDataInput
                                fieldType={flagField.dataType}
                                label={flagField.name}
                                fieldName={flagField.flagTypeCode}
                                changeData={(value) =>
                                    this.changeOtherIndicatorsFields(value, flagField.name, index)}
                                value ={flagField.value}
                                readOnly={isReadOnly ? isReadOnly : this.readonlyIndicatorsCheck(flagField.name)}
                                isHidden={false}
                            />
                        </div>;

            return reactElement;
        }
        return tabContents ? tabContents.map(filteredOtherIndicatorsFieldsMapper) : null;
    }

    render() {
        const { componentInstance } = this.props;

        return(
            (componentInstance === 'otherIndicators' || componentInstance === 'readonlyIndicators') ?
            <div className="row">
                {this.renderOtherIndicatorsFields()}
            </div>
            :
            <div className="row">
                {this.renderMetadataFields()}
            </div>
        )

    }
}