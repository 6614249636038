import {
  GET_MATCH_TYPES_REQUEST,
  GET_MATCH_TYPES_SUCCESS,
  GET_MATCH_TYPES_FAILURE,
  GetMatchTypesRequest,
  GetMatchTypesSuccess,
  GetMatchTypesFailure,
  GET_SOURCES_REQUEST,
  GET_SOURCES_SUCCESS,
  GET_SOURCES_FAILURE,
  GetSourcesRequest,
  GetSourcesSuccess,
  GetSourcesFailure,
  LOAD_SOURCE_REQUEST,
  LOAD_SOURCE_SUCCESS,
  LOAD_SOURCE_FAILURE,
  LoadSourceRequest,
  LoadSourceSuccess,
  LoadSourceFailure,
  UPDATE_SOURCE_REQUEST,
  UPDATE_SOURCE_SUCCESS,
  UPDATE_SOURCE_FAILURE,
  UpdateSourceRequest,
  UpdateSourceSuccess,
  UpdateSourceFailure,
  UPDATE_SOURCE_HIERARCHY_REQUEST,
  UPDATE_SOURCE_HIERARCHY_SUCCESS,
  UPDATE_SOURCE_HIERARCHY_FAILURE,
  UpdateSourceHierarchyRequest,
  UpdateSourceHierarchySuccess,
  UpdateSourceHierarchyFailure,
  ADD_CHILD_SOURCE_REQUEST,
  ADD_CHILD_SOURCE_SUCCESS,
  ADD_CHILD_SOURCE_FAILURE,
  AddChildSourceRequest,
  AddChildSourceSuccess,
  AddChildSourceFailure,
  UPDATE_TREE_NODES,
  UpdateTreeNodes,
  UpdateParameterInstancesRequest,
  UPDATE_PARAMETER_INSTANCES_REQUEST,
  UpdateParameterInstancesSuccess,
  UPDATE_PARAMETER_INSTANCES_SUCCESS,
  UpdateParameterInstancesFailure,
  UPDATE_PARAMETER_INSTANCES_FAILURE
} from "./SettingsActions";
import { IMatchType } from "../../settings/types/IMatchType";
import { ISourceMatchType } from "../../settings/types/ISourceMatchType";
import { ISourceSearchResult } from "../../settings/types/ISourceSearchResult";
import { IProductType } from "../../settings/types/IProductType";
import { PropertyWindowSaveState } from "../../settings/types/PropertyWindowSaveState";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";

export function getMatchTypesRequest(): GetMatchTypesRequest {
  return {
    type: GET_MATCH_TYPES_REQUEST
  };
}

export function getMatchTypesSuccess(
  matchTypes: IMatchType[]
): GetMatchTypesSuccess {
  return {
    type: GET_MATCH_TYPES_SUCCESS,
    payload: {
      matchTypes
    }
  };
}

export function getMatchTypesFailure(error: string): GetMatchTypesFailure {
  return {
    type: GET_MATCH_TYPES_FAILURE,
    payload: {
      error
    }
  };
}

export function getSourcesRequest(): GetSourcesRequest {
  return {
    type: GET_SOURCES_REQUEST
  };
}

export function getSourcesSuccess(
  sourceMatchType: ISourceMatchType
): GetSourcesSuccess {
  return {
    type: GET_SOURCES_SUCCESS,
    payload: {
      sourceMatchType
    }
  };
}

export function getSourcesFailure(error: string): GetSourcesFailure {
  return {
    type: GET_SOURCES_FAILURE,
    payload: { error }
  };
}

export function loadSourceRequest(): LoadSourceRequest {
  return {
    type: LOAD_SOURCE_REQUEST
  };
}

export function loadSourceSuccess(
  sourceSearchResult: ISourceSearchResult,
  sourceID: number,
  productType: IProductType,
  saveState: PropertyWindowSaveState
): LoadSourceSuccess {
  return {
    type: LOAD_SOURCE_SUCCESS,
    payload: {
      sourceSearchResult,
      sourceID,
      productType,
      saveState
    }
  };
}

export function loadSourceFailure(error: string): LoadSourceFailure {
  return {
    type: LOAD_SOURCE_FAILURE,
    payload: {
      error
    }
  };
}

export function updateSourceRequest(): UpdateSourceRequest {
  return {
    type: UPDATE_SOURCE_REQUEST
  };
}

export function updateSourceSuccess(updated: boolean): UpdateSourceSuccess {
  return {
    type: UPDATE_SOURCE_SUCCESS,
    payload: {
      updated
    }
  };
}

export function updateSourceFailure(error: string): UpdateSourceFailure {
  return {
    type: UPDATE_SOURCE_FAILURE,
    payload: {
      error
    }
  };
}

export function updateSourceHierarchyRequest(): UpdateSourceHierarchyRequest {
  return {
    type: UPDATE_SOURCE_HIERARCHY_REQUEST
  };
}

export function updateSourceHierarchySuccess(
  updatedSourceMatchType: ISourceMatchType
): UpdateSourceHierarchySuccess {
  return {
    type: UPDATE_SOURCE_HIERARCHY_SUCCESS,
    payload: {
      updatedSourceMatchType
    }
  };
}

export function updateSourceHierarchyFailure(
  error: string
): UpdateSourceHierarchyFailure {
  return {
    type: UPDATE_SOURCE_HIERARCHY_FAILURE,
    payload: {
      error
    }
  };
}

export function addChildSourceRequest(): AddChildSourceRequest {
  return {
    type: ADD_CHILD_SOURCE_REQUEST
  };
}

export function addChildSourceSuccess(): AddChildSourceSuccess {
  return {
    type: ADD_CHILD_SOURCE_SUCCESS
  };
}

export function addChildSourceFailure(): AddChildSourceFailure {
  return {
    type: ADD_CHILD_SOURCE_FAILURE
  };
}

export function updateTreeNodes(
  updatedTreeNodes: ITreeNodeData<ISourceSearchResult>
): UpdateTreeNodes {
  return {
    type: UPDATE_TREE_NODES,
    payload: {
      updatedTreeNodes
    }
  };
}

export function updateParameterInstancesRequest(): UpdateParameterInstancesRequest {
  return {
    type: UPDATE_PARAMETER_INSTANCES_REQUEST
  };
}

export function updateParameterInstancesSuccess(
  success: boolean
): UpdateParameterInstancesSuccess {
  return {
    type: UPDATE_PARAMETER_INSTANCES_SUCCESS,
    payload: {
      success
    }
  };
}

export function updateParameterInstancesFailure(
  error: string
): UpdateParameterInstancesFailure {
  return {
    type: UPDATE_PARAMETER_INSTANCES_FAILURE,
    payload: {
      error
    }
  };
}
