import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import MyCasesPage from "./MyCasesPage";

export default connect(
    (state: IAppState) => ({
        powerPortalURL: state.membersPortal.powerPortalURL
    }),
    (dispatch: Dispatch) => ({
    })
)(MyCasesPage);