import type { TableColumnsType } from 'antd';
import { Space, Table } from 'antd';
import React from 'react';
import { SizedCheckboxDataInput } from '../../../components/checkboxDataInput/CheckboxDataInput';
import { USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY, USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY } from '../../../Consts';


export interface AllocationHeaderRowType {
    key: React.Key;
    AllocationUsageHeaderId?: number;
    PoolCode?: string;
    WorkNumber?: string;
    WorkName?: string;
    ProductNumber?: string;
    ProductName?: string;
    AllocationDate?: string;
    AllocationWeight?: string;
    TotalAmount?: string;
    UsageDetails?: AllocationDetailRowType[];
    IsSelected?: boolean;
    Status?: string;
    Distribution?: string;
    Description?: string;
    Segment?: string;
    CuesheetID?: string;
    CuesheetName?: string;
}

export interface AllocationDetailRowType {
    key: React.Key;
    AllocationUsageHeaderID?: number;
    IPNameNumber?: number;
    AccountNumber?: string;
    Amount?: string;
    SharePct?: string;
    Name?: string;
    PayeeAccountNumber?: string;
    PayeeIPBaseNumber?: string;
    PayeeIpNameNumber?: number;
    PayeeName?: string;
    PoolCode?: string;
    PointsAllocated?: string;
    WorkNumber?: string;
    WorkName?: string;
    WorkID?: string;
    WorkDuration?: string;
    IsIntray?: string;
}
interface IAdjustmentMaintenancePageProps {
    searchResults: AllocationHeaderRowType[];
    viewData: any;
    componentInstance?: string;
    addAdjustmentDetails?: (row: AllocationDetailRowType) => void;
    selectAllocationHeader?: (AllocationDetailRowType) => void;
}

const AllocationSelectionView: React.FC<IAdjustmentMaintenancePageProps> = (props: IAdjustmentMaintenancePageProps) => {
    const { viewData, componentInstance } = props;

    const onClick = (value) => {
        const { addAdjustmentDetails } = props;
        addAdjustmentDetails(value);
    }

    const onClickSelectAllocationHeader = (value) => {
        const { selectAllocationHeader } = props;
        selectAllocationHeader(value);
    }

    const expandedRowRender = (record: AllocationHeaderRowType) => {

        const details = record.UsageDetails;
        const data: AllocationDetailRowType[] = details.map((item, index) => {
            switch (componentInstance) {
                case USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY:
                    return {
                        key: index.toString(),
                        WorkName: item.WorkName,
                        WorkID: item.WorkID,
                        WorkDuration: item.WorkDuration,
                        Amount: item.Amount.toString().replace('0E-18', '0.000000000000000000'),
                        IsIntray: item.IsIntray
                    };
                case USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY:
                    return {
                        key: index.toString(),
                        WorkName: item.WorkName,
                        WorkID: item.WorkID,
                        WorkDuration: item.WorkDuration,
                        Amount: item.Amount.toString().replace('0E-18', '0.000000000000000000'),
                        IsIntray: item.IsIntray
                    };
                default:
                    return {
                        key: index.toString(),
                        AllocationUsageHeaderID: item.AllocationUsageHeaderID,
                        IPNameNumber: item.IPNameNumber,
                        AccountNumber: item.AccountNumber,
                        Name: item.Name,
                        Amount: item.Amount.replace('0E-18', '0.000000000000000000'),
                        PointsAllocated: item.PointsAllocated.replace('0E-18', '0.000000000000000000'),
                        SharePct: item.SharePct.replace('0E-18', '0.000000000000000000'),
                        PayeeIPBaseNumber: item.PayeeIPBaseNumber,
                        PayeeIpNameNumber: item.PayeeIpNameNumber,
                        PayeeAccountNumber: item.PayeeAccountNumber,
                        PayeeName: item.PayeeName,
                        PoolCode: item['PoolCode'],
                        WorkNumber: record.WorkNumber,
                        WorkName: record.WorkName
                    };
            }
        });

        const detailHeaderColumnRender = () => {
            switch (componentInstance) {
                case USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY:
                    return [
                        { title: headersFields['workName'], dataIndex: 'WorkName', key: 'WorkName' },
                        { title: headersFields['workID'], dataIndex: 'WorkID', key: 'WorkID' },
                        { title: headersFields['workDuration'], dataIndex: 'WorkDuration', key: 'WorkDuration' },
                        { title: headersFields['amount'], dataIndex: 'Amount', key: 'Amount' },
                        { title: headersFields['isIntray'], dataIndex: 'IsIntray', key: 'IsIntray' }
                    ];
                case USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY:
                    return [
                        { title: headersFields['workName'], dataIndex: 'WorkName', key: 'WorkName' },
                        { title: headersFields['workID'], dataIndex: 'WorkID', key: 'WorkID' },
                        { title: headersFields['workDuration'], dataIndex: 'WorkDuration', key: 'WorkDuration' },
                        { title: headersFields['amount'], dataIndex: 'Amount', key: 'Amount' },
                        { title: headersFields['isIntray'], dataIndex: 'IsIntray', key: 'IsIntray' }
                    ];
                default:
                    return [
                        {
                            title: "Actions",
                            dataIndex: 'operation',
                            key: 'operation',
                            render: (_, value) => {
                                return <><Space onClick={() => onClick(value)} size="middle"><a>Add Adjustment Details</a></Space></>
                            },
                        },
                        { title: viewData["accountNumber"], dataIndex: 'AccountNumber', key: 'AccountNumber' },
                        { title: viewData["ipName"], dataIndex: 'Name', key: 'Name' },
                        { title: viewData["ipiNumber"], dataIndex: 'IPNameNumber', key: 'IPNameNumber' },
                        { title: viewData["payeeAccountNumber"], dataIndex: 'PayeeAccountNumber', key: 'PayeeAccountNumber' },
                        { title: viewData["payeeNameNumber"], dataIndex: 'PayeeIpNameNumber', key: 'PayeeIpNameNumber' },
                        { title: viewData["payeeBaseNumber"], dataIndex: 'PayeeIPBaseNumber', key: 'PayeeIPBaseNumber' },
                        { title: viewData["payeeName"], dataIndex: 'PayeeName', key: 'PayeeName' },
                        { title: viewData["sharePct"], dataIndex: 'SharePct', key: 'SharePct' },
                        { title: viewData["pointsAllocated"], dataIndex: 'PointsAllocated', key: 'PointsAllocated' },
                        { title: viewData["amountAllocated"], dataIndex: 'Amount', key: 'Amount' },
                    ];
            }
        };

        return <Table columns={detailHeaderColumnRender()} dataSource={data} pagination={false} />;
    };

    const getMappedFields = () => {
        if (!componentInstance) {
            return;
        }
        const filtered = viewData.fields.filter(x => x.componentInstance === componentInstance);
        const result: { [id: string]: string; } = {};
        filtered.forEach(element => {
            result[element.name] = element.data;
        });
        return result;
    }

    const headerColumnRender = () => {
        switch (componentInstance) {
            case USAGE_GROUP_WORK_ALLOCATIONS_SECTION_KEY:
                return [
                    { title: headersFields['allocationStatus'], dataIndex: 'Status', key: 'Status' },
                    { title: headersFields['allocationDate'], dataIndex: 'AllocationDate', key: 'AllocationDate' },
                    { title: headersFields['distribution'], dataIndex: 'Distribution', key: 'Distribution' },
                    { title: headersFields['description'], dataIndex: 'Description', key: 'Description' },
                    { title: headersFields['poolCode'], dataIndex: 'PoolCode', key: 'PoolCode' },
                    { title: headersFields['Segment'], dataIndex: 'Segment', key: 'Segment' },
                    { title: headersFields['amount'], dataIndex: 'TotalAmount', key: 'TotalAmount' }
                ];
            case USAGE_GROUP_PRODUCT_ALLOCATIONS_SECTION_KEY:
                return [
                    { title: headersFields['allocationStatus'], dataIndex: 'Status', key: 'Status' },
                    { title: headersFields['allocationDate'], dataIndex: 'AllocationDate', key: 'AllocationDate' },
                    { title: headersFields['distribution'], dataIndex: 'Distribution', key: 'Distribution' },
                    { title: headersFields['description'], dataIndex: 'Description', key: 'Description' },
                    { title: headersFields['poolCode'], dataIndex: 'PoolCode', key: 'PoolCode' },
                    { title: headersFields['Segment'], dataIndex: 'Segment', key: 'Segment' },
                    { title: headersFields['amount'], dataIndex: 'TotalAmount', key: 'TotalAmount' },
                    { title: headersFields['productID1'], dataIndex: 'ProductNumber', key: 'ProductNumber' },
                    { title: headersFields['productName'], dataIndex: 'ProductName', key: 'ProductName' }
                ];
            default:
                return [
                    {
                        title: '', dataIndex: 'checkbox', key: 'checkbox', render: (_, value) => {
                            return <SizedCheckboxDataInput label=''
                                value={props.searchResults.filter(x => x.AllocationUsageHeaderId === value.AllocationUsageHeaderId)[0].IsSelected}
                                changeData={() => onClickSelectAllocationHeader(value)} fieldName='SelectAllocationHeader' />
                        }
                    },
                    { title: viewData["allocationHeaderID"], dataIndex: 'AllocationUsageHeaderId', key: 'AllocationUsageHeaderId' },
                    { title: viewData["poolCode"], dataIndex: 'PoolCode', key: 'PoolCode' },
                    { title: viewData["workNumber"], dataIndex: 'WorkNumber', key: 'WorkNumber' },
                    { title: viewData["workName"], dataIndex: 'WorkName', key: 'WorkName' },
                    { title: viewData["productNumber"], dataIndex: 'ProductNumber', key: 'ProductNumber' },
                    { title: viewData["allocationDate"], dataIndex: 'AllocationDate', key: 'AllocationDate' },
                    { title: viewData["allocationWeight"], dataIndex: 'AllocationWeight', key: 'AllocationWeight' },
                    { title: viewData["totalAmountAllocated"], dataIndex: 'TotalAmount', key: 'TotalAmount' }
                ];
        }
    };

    const headersFields = getMappedFields();
    const data: AllocationHeaderRowType[] = props.searchResults;

    return (
        <>
            <Table
                columns={headerColumnRender()}
                expandable={
                    {
                        expandedRowRender: ((record) => record.UsageDetails ? expandedRowRender(record) : undefined),
                        rowExpandable: ((record) => record.UsageDetails && record.UsageDetails.length > 0 ? true : false)
                    }
                }
                dataSource={data}
                size="small"
            />
        </>
    );
};

export default AllocationSelectionView;