import * as React from 'react';
import { ITreeData } from '../../../repertoire/types/ITreeData';
import { TreeView } from '../../../treeview/components/Tree-view';
import { ITreeNodeData } from '../../../treeview/types/TreeNodeData';
import { ALL_ROLES, VIEW_INGESTION_REPERTOIRE_ROLE, VIEW_INGESTION_USAGE_ROLE, VIEW_PAYMENT_ROLE } from '../../Consts';
import { FolderSection } from '../../types/FolderSection';
import { IFileSystem } from '../../types/IFileSystem';

interface IDataIngestionSideMenuProps {
    getFilesystems: (disallowedFolderSections: FolderSection[]) => void;
    filesystems: IFileSystem[];
    selectedFileSystem: IFileSystem;
    sidebarIsCollapsed: boolean;
    treeNodes: ITreeNodeData<IFileSystem>[];
    selectedNode: ITreeNodeData<IFileSystem>;
    selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => void;
    cleanStoragePath: () => void;
    sources: ITreeData[];
    roles?: string[];
}

export const DataIngestionSideMenu = (props: IDataIngestionSideMenuProps) => {
    const [loaded, setLoaded] = React.useState(false)

    React.useEffect(() => {
        const { cleanStoragePath } = props;
        cleanStoragePath();
        checkIfLoaded();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded]);

    React.useEffect(() => {

        const { getFilesystems, roles } = props;
        let disallowedFolderSections: FolderSection[] = []
        if (roles && !(roles.includes(VIEW_INGESTION_USAGE_ROLE) || roles.includes(ALL_ROLES))) {
            disallowedFolderSections.push(FolderSection.Usage);
        }
        if (roles && !(roles.includes(VIEW_INGESTION_REPERTOIRE_ROLE) || roles.includes(ALL_ROLES))) {
            disallowedFolderSections.push(FolderSection.Repertoire);
        }
        if (roles && !(roles.includes(VIEW_PAYMENT_ROLE) || roles.includes(ALL_ROLES))) {
            disallowedFolderSections.push(FolderSection.Payment);
        }

        getFilesystems(disallowedFolderSections);
    }, [loaded])

    const checkIfLoaded = () => {
        const { roles } = props;
        if (roles.length > 0) setLoaded(true);
    }
    const {
        treeNodes,
        selectedNode,
        sidebarIsCollapsed,
        selectedNodeChanged,
        sources
    } = props;

    if (!treeNodes || treeNodes.length === 0 || !loaded)
        return <div className="empty" />

    const onSelectedNodeChanged = (node: ITreeNodeData<IFileSystem>) => {
        selectedNodeChanged(node, sources);
    };

    return (
        <div className="scrollBarY scrollBarDataIngestion">
            <TreeView<IFileSystem>
                data={treeNodes}
                selectedNode={selectedNode}
                selectedNodeChanged={onSelectedNodeChanged}
                confirmChange={null}
                isEditing={false}
                addSourceButtonText={""}
                deleteSourceButtonText={""}
                addSource={null}
                deleteSource={null}
                sidebarCollapsed={sidebarIsCollapsed}
                alwaysExpanded={false} />
        </div>
    )
}