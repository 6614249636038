import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { showYesNoPrompt, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SAVE_CHANGES_STATE_KEY, ADD_NEW_ACCOUNT_STATE_KEY, DELETE_ACCOUNT_STATE_KEY, UNDO_CHANGES_ACCOUNT_KEY, IP_MAINTENANCE_TOOLBAR, UNDO_METHOD_TOOLBAR, SAVE_METHOD_TOOLBAR, ADD_METHOD_TOOLBAR, DELETE_METHOD_TOOLBAR, HISTORY_METHOD_TOOLBAR, VERSION_HISTORY_STATE_KEY, ARTIST_MAINTENANCE_TOOLBAR, UNDO_CHANGES_ARTIST_KEY, ADD_NEW_ARTIST_STATE_KEY, DELETE_ARTIST_STATE_KEY, UNDO_ADJUSTMENT_CHANGES_STATE_KEY, COPY_ADJUSTMENT_STATE_KEY, ADJUSTMENT_MAINTENANCE_TOOLBAR, COPY_METHOD_TOOLBAR, DELETE_ADJUSTMENTS_ROLE, ALL_ROLES, UPDATE_ADJUSTMENTS_ROLE} from "../../../Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { clearModalSearchResults, copyAdjustment } from "../../../../redux/reducers/RepertoireReducer";
import { IAdjustmentState } from "../../../../redux/types/IAdjustmentState";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";

export interface IAdjustmentMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData?: IRepertoireComponentDataItem;
    adjustment: IAdjustmentState;
    activeTab: number;
    tabs: ITabReduxItem[];
    lookupValues: ILookupDictionary;
    saveChanges: (saveOption?: string) => void;
    addNewAdjustment: (lookupValues: ILookupDictionary, isNew?: boolean, adjustmentMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => void;
    undoAdjustmentChanges: () => void;
    searchVersionHistory: (adjustmentId: string, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    copyAdjustment: () => void;
    isReadonly: boolean;
    showModal: typeof showModal;
    roles?: string[];
}

export type IAdjustmentMaintenanceToolbarKeys = keyof IAdjustmentMaintenanceToolbarProps;

export interface IAdjustmentMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

class AdjustmentMaintenanceToolbar extends React.Component<
    IAdjustmentMaintenanceToolbarProps,
    IAdjustmentMaintenanceToolbarState
> {

    constructor(props) {
        super(props)
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    saveChangesAction = (saveOption?: string) => {
        const { saveChanges } = this.props;
        saveChanges(saveOption);
    }

    searchVersionHistory = () => {
        const { searchVersionHistory, clearModalSearchResults, adjustment, lookupValues, tabs, activeTab } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        clearModalSearchResults();
        searchVersionHistory(adjustment.id, lookupValues, actionList, tabs[activeTab].formatFields);
    };

    copyAdjustment = () => {
        const { copyAdjustment } = this.props;
        copyAdjustment();
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_ADJUSTMENTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getDataAction() {
        const {
            undoAdjustmentChanges,
            toolbarData: { fields }
        } = this.props;

        const actionList: IDataActionToolbar[] = [];

        const undo: IDataActionToolbar = {
            action: undoAdjustmentChanges,
            component: ADJUSTMENT_MAINTENANCE_TOOLBAR,
            method: UNDO_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === UNDO_ADJUSTMENT_CHANGES_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Undo',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(undo);

        const searchVersionHistory: IDataActionToolbar = {
            action: this.searchVersionHistory,
            name: fields.find(save => save.name === VERSION_HISTORY_STATE_KEY).data,
            component: ADJUSTMENT_MAINTENANCE_TOOLBAR,
            method: HISTORY_METHOD_TOOLBAR,
            icon: 'icon ms-Icon ms-Icon--History'
        };
        actionList.push(searchVersionHistory);

        const copyAdjustmentAction: IDataActionToolbar = {
            action: this.copyAdjustment,
            component: ADJUSTMENT_MAINTENANCE_TOOLBAR,
            method: COPY_METHOD_TOOLBAR,
            name: fields.find(d => d.name === COPY_ADJUSTMENT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Copy',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(copyAdjustmentAction)


        const save: IDataActionToolbar = {
            action: this.saveChangesAction,
            component: ADJUSTMENT_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        return actionList;
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            adjustment,
            activeTab,
            toolbarData,
            tabs,
            isReadonly,
            lookupValues,
            clearModalSearchResults
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade

        return (
            <div className={isReadonly && !changesMade ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={adjustment.dataSource === 'Posted' ? false : changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    adjustment={adjustment}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction}
                    toolbarData={toolbarData}
                    component={ADJUSTMENT_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].adjustmentMaintenanceState.isNew}
                    isReadonly={isReadonly}
                    lookupValues={lookupValues}
                    clearModalSearchResults={clearModalSearchResults}
                />
            </div>
        );
    }

}

export default AdjustmentMaintenanceToolbar;
