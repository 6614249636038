import { withWarn } from "antd/lib/modal/confirm";
import * as React from "react";
import { If } from "../../../../core/components/If";
import { DataTypeHelper } from "../../../../core/services/DataTypeHelper";
import { Dictionary } from "../../../../core/types/Dictionary";
import {
    MUSIC_SOURCE_LOOKUP,
    WORK_DISTRIBUTION_CATEGORY_LOOKUP,
    WORK_FLAG_TYPE_LOOKUP,
    WORK_GENRE_LOOKUP,
    WORK_MEDLEY_TYPE_LOOKUP,
    WORK_PARTS_LOOKUP,
    WORK_SOURCE_LOOKUP,
    WORK_SOURCE_TYPE_LOOKUP,
    WORK_TYPE_LOOKUP,
    AGENCY_LOOKUP,
    MUSIC_ARRANGEMENT_LOOKUP,
    LYRIC_ADAPTATION_LOOKUP,
    ADDITIONAL_ATTRIBUTES_LOOKUP
} from "../../../../lookup/Consts";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, closeAccordion, matchWorkBatchRequest, postWorkBatchRequest, setEditableFields, switchSetTypeGroup, updateEditableFields, updateWorkField } from "../../../../redux/reducers/RepertoireReducer";
import { FormatFieldItem, FormatFields } from "../../../../redux/types/FormatFields";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IWorkGroupMaintenanceState } from "../../../../redux/types/IWorkGroupMaintenanceState";
import { IWorkStateKeys } from "../../../../redux/types/IWorkState";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import FormattedTimeDataInput from "../../../components/timeFieldDataInput/FormattedTimeDataInput";
import {
    COMPONENT_INSTANCE_WORKS,
    COMPOUND_TYPE_STATE_KEY, DURATION_STATE_KEY, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, GENRE_STATE_KEY, WORK_TYPE_STATE_KEY, MAINTAINED_STATE_KEY, MATCH_WORK_BATCH_STATE_KEY, MERGE_THIS_WORK_STATE_KEY, POST_WORK_BATCH_STATE_KEY, REG_DATE_STATE_KEY, SEARCH_VIEW,
    SEARCH_VIEW_WORKSOURCE_IP, SOURCE_IP_STATE_KEY, SOURCE_SOCIETY_STATE_KEY, SOURCE_STATE_KEY, SOURCE_TYPE_STATE_KEY, STAMP_ID_STATE_KEY, TITLE_EDITABLE_FIELDS_VIEW, WORK_BATCH_ID_STATE_KEY, WORK_IS_ARRANGEMENT_STATE_KEY,
    WORK_IS_DISTRIBUTED_STATE_KEY, WORK_IS_LIBRARY_STATE_KEY, WORK_IS_ORIGINAL_WORK_STATE_KEY, WORK_MAINTENANCE_GENERAL_DATA_VIEW, IS_DISPLAY, MUSIC_SOURCE_STATE_KEY, BOOK_WORK_TYPE_CODE, CODE_CATEGORY, DATA_TYPE, IP_REPRESENTATION_WORK_GROUP_STATE_KEY, WORK_PARTS_STATE_KEY, WORK_CREATION_DATE_STATE_KEY,
    WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY,
    DISTRIBUTION_CATEGORY_STATE_KEY,
    CONFIGURE_WORKS_ROLE,
    ALL_ROLES,
    SOURCE_IPI_NUMBER_STATE_KEY,
    WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY,
    WORK_IS_MAINTAINED_MCPSI_STATE_KEY,
    MUSIC_ARRANGEMENT_STATE_KEY,
    LYRIC_ADAPTATION_STATE_KEY,
    FIRST_PERFORMANCE_STATE_KEY,
    ADDITIONAL_ATTRIBUTES_KEY,
    MATCH_CONNECTOR_STATUS_STATE_KEY
} from "../../../Consts";
import { SearchRequests } from "../../../services/SearchRequests";
import { DataSource } from "../../../types/DataSource";
import { IFlag } from "../../../types/IFlag";
import { IReadonlyFlagsField } from "../../../types/IReadonlyFlagsField";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { ISourceIP } from "../../../types/ISourceIP";
import { ITerritorySpecificInfo } from "../../../types/ITerritorySpecificInfo";
import { IAdditionalAttribute } from "../../../types/IAdditionalAttribute";
import WorkBatchInput from "../workBatchInput/WorkBatchInput";
import WorkMaintenanceTerritorySpecificInfoView from "../workMaintenanceTerritorySpecificInfoView/WorkMaintenanceTerritorySpecificInfoView";
import { group } from "console";


export interface IWorkMaintenanceGeneralDataViewProps {
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    stampID: string;
    compoundType: string;
    isMaintained: boolean;
    registrationDate: string;
    source: string;
    sourceType: string;
    sourceSociety: string;
    genre: string;
    distributionCategory: string;
    workType: string;
    otherIndicators: IFlag[];
    readonlyIndicators: IFlag[];
    otherIndicatorsWorkFlagTypes: string[];
    readonlyIndicatorsWorkFlagTypes: string[];
    readonlyFlagsField: IReadonlyFlagsField[];
    workBatchID: number;
    territorySpecificInfo: ITerritorySpecificInfo;
    mergeThisWork: boolean;
    updateWorkField: typeof updateWorkField;
    lookupValues: ILookupDictionary;
    genreCategories: Dictionary<Array<string>>;
    dataSource: DataSource;
    changesMade: boolean;
    postWorkBatch: typeof postWorkBatchRequest;
    matchWorkBatch: typeof matchWorkBatchRequest;
    switchSetTypeGroup: typeof switchSetTypeGroup;
    loadWorkIceDistCacheAdditionalInfo: (workID: number, activeTabItem: ITabReduxItem) => void;
    saveInProgress: boolean;
    workBatchOperationInProgress: boolean;
    isReadonly: boolean;
    isOriginalWork: boolean;
    duration: number;
    isArrangement: boolean;
    isDistributed: boolean;
    isMaintainedMCPSI: boolean;
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    clearModalSearchResults?: typeof clearModalSearchResults;
    workSourceIP?: ISourceIP;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    refreshWorkDetails: () => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    updateEditableField: typeof updateEditableFields;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    formatFields: FormatFields[];
    isLibraryWork?: boolean;
    isCommissionedForCommercial?: boolean;
    editableFields: IRepertoireField[];
    musicSource?: string;
    workPart?: string;
    workCreationDate?: string;
    genreCategoriesByDomOrInt: Dictionary<Array<string>>;
    openAccordion: (accordionName: string, componentName: string) => void;
    closeAccordion: (accordionName: string, componentName: string) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    roles?: string[];
    isMaintainedLibraryWork?: boolean;
    excludeSourceTypePickShowIP?: string[];
    musicArrangement?: string;
    lyricAdaptation?: string;
    filterGenreCategories: boolean;
    pdArrangementMusicLyric?: boolean;
    recalculateDomesticorForeign: boolean;
    firstPerformance?: boolean;
    additionalAttributeAssigned?: IAdditionalAttribute[];
}

interface IWorkMaintenanceGeneralDataViewState {
    loaded: boolean;
    isHiddenSociety: boolean;
    isHiddenIP: boolean;
    isHiddenArrangements: boolean;
    isHiddenIPINumber: boolean;
    isAccordionActive: boolean;
    name: string;
    componentName: string;
}

export default class WorkMaintenanceGeneralDataView extends React.PureComponent<
    IWorkMaintenanceGeneralDataViewProps,
    IWorkMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: IWorkMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            loaded: false,
            isHiddenSociety: true,
            isHiddenIP: true,
            isHiddenIPINumber: true,
            isHiddenArrangements: true,
            isAccordionActive: true,
            name: "general",
            componentName: "WorkMaintenanceGeneralDataView"
        };
        this.viewData = {};

    }

    componentDidMount() {
        const {
            workMaintenanceGeneralDataViewData: { fields }, duration, formatFields,
            tabs,
            activeTab,
            openAccordion,
            defaultActiveAccordions,
            pdArrangementMusicLyric
        } = this.props;

        const { name, componentName } = this.state;

        if ((tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.find(x => x === name))
            || (defaultActiveAccordions && defaultActiveAccordions.find((a) => a.fieldName === name) && defaultActiveAccordions.find((a) => a.fieldName === name).isExpanded)) {

            openAccordion(name, componentName);
            this.setState({ isAccordionActive: true });
        }
        else {

            closeAccordion(name, componentName);
            this.setState({ isAccordionActive: false });
        }

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }

        this.setReadOnlySourceFields();
    }

    componentDidUpdate(prevProps: IWorkMaintenanceGeneralDataViewProps) {
        const { sourceType, isArrangement } = this.props;
        if (prevProps.sourceType != sourceType || prevProps.isArrangement != isArrangement) {
            this.setReadOnlySourceFields();
        }
    }

    onClick = (fieldName: IRepertoireStateKeys) => {
        if (fieldName == SOURCE_IP_STATE_KEY) {
            this.showSearchModal(fieldName, SEARCH_VIEW_WORKSOURCE_IP)
        }
    }

    showSearchModal = (modalProps: any, searchViewKey: string) => {
        const { showModal } = this.props;
        this.props.clearModalSearchResults();
        showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search");
    };

    setReadOnlySourceFields() {
        const { sourceType, excludeSourceTypePickShowIP, isArrangement } = this.props;
        if (sourceType === null || sourceType === "" || excludeSourceTypePickShowIP === null || excludeSourceTypePickShowIP.includes(sourceType)) {
            this.setState(() => ({
                isHiddenIP: true,
            }));
        }
        else {
            this.setState(() => ({
                isHiddenIP: false,
            }));
        }

        if (isArrangement === true) {
            this.setState(() => ({
                isHiddenArrangements: false,
            }));
        } else {
            this.setState(() => ({
                isHiddenArrangements: true,
            }));
        }

        if (sourceType !== "" && sourceType === "SO") {
            this.setState(() => ({
                isHiddenSociety: false,
            }));
        }
        else {
            this.setState(() => ({
                isHiddenSociety: true,
            }));
        }
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, activeTab, tabs } = this.props;
        updateComponentFieldsItems(editableFields["default"], WORK_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_WORKS, WORK_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    onLoadWorkIceDistCacheAdditionalInfo() {
        const { activeTab, tabs, loadWorkIceDistCacheAdditionalInfo } = this.props;
        const workID = tabs[activeTab].workMaintenanceState.work.workID;
        loadWorkIceDistCacheAdditionalInfo(workID, tabs[activeTab]);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableData,
            setEditableFields,
        } = this.props;

        setEditableFields(WORK_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_WORKS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    toggleAccordion = () => {
        const { isAccordionActive, name, componentName } = this.state;
        const {
            tabs,
            activeTab,
            openAccordion,
            closeAccordion
        } = this.props;

        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(name)) {
            closeAccordion(name, componentName);
        } else {
            openAccordion(name, componentName);
        }
        this.setState({
            isAccordionActive: !isAccordionActive
        });
    };

    render() {
        const {
            workMaintenanceGeneralDataViewData,
            stampID,
            compoundType,
            genre,
            distributionCategory,
            workType,
            otherIndicators,
            readonlyIndicators,
            isMaintained,
            registrationDate,
            source,
            sourceType,
            sourceSociety,
            mergeThisWork,
            territorySpecificInfo,
            otherIndicatorsWorkFlagTypes,
            readonlyIndicatorsWorkFlagTypes,
            readonlyFlagsField,
            lookupValues,
            dataSource,
            workBatchID,
            changesMade,
            postWorkBatch,
            matchWorkBatch,
            saveInProgress,
            workBatchOperationInProgress,
            loadWorkIceDistCacheAdditionalInfo,
            switchSetTypeGroup,
            isReadonly,
            isOriginalWork,
            duration,
            workSourceIP,
            isArrangement,
            isDistributed,
            isMaintainedMCPSI,
            refreshWorkDetails,
            isLibraryWork,
            isCommissionedForCommercial,
            tabs,
            activeTab,
            musicSource,
            musicArrangement,
            lyricAdaptation,
            workPart,
            workCreationDate,
            genreCategories,
            genreCategoriesByDomOrInt,
            isMaintainedLibraryWork,
            filterGenreCategories,
            pdArrangementMusicLyric,
            recalculateDomesticorForeign,
            firstPerformance
        } = this.props;

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {

            if (name == 'workType' && value == BOOK_WORK_TYPE_CODE && otherIndicators.length == 0) {
                const workFlagTypes = getLookupValues(WORK_FLAG_TYPE_LOOKUP, lookupValues);
                let flagValues = workFlagTypes ? workFlagTypes.filter(type => type.extraFields[IS_DISPLAY] === true && otherIndicatorsWorkFlagTypes.includes(type.extraFields[CODE_CATEGORY])) : null;

                const flags: IFlag[] = [];
                flagValues.map((s, i) => flags.push({
                    flagID: 0,
                    flagTypeCode: s.code,
                    createdDate: null,
                    lastModifiedBy: null,
                    value: s.extraFields[DATA_TYPE] == 'boolean' ? "0" : null,
                    dataType: s.extraFields[DATA_TYPE],
                    name: s.description,
                    workID: 0
                }));

                this.props.updateWorkField(flags, 'otherIndicators')
            }

            if (name == "workType" && readonlyIndicators.length == 0) {
                const workFlagTypes = getLookupValues(WORK_FLAG_TYPE_LOOKUP, lookupValues);
                let flagValues = workFlagTypes ? workFlagTypes.filter(type => type.extraFields[IS_DISPLAY] === true && readonlyIndicatorsWorkFlagTypes.includes(type.extraFields[CODE_CATEGORY])) : null;

                const flags: IFlag[] = [];
                flagValues.map((s, i) => flags.push({
                    flagID: 0,
                    flagTypeCode: s.code,
                    createdDate: null,
                    lastModifiedBy: null,
                    value: s.extraFields[DATA_TYPE] == 'boolean' ? "0" : null,
                    dataType: s.extraFields[DATA_TYPE],
                    name: s.description,
                    workID: 0
                }));

                this.props.updateWorkField(flags, 'readonlyIndicators')
            }

            this.props.updateWorkField(value, name as IWorkStateKeys);
        }

        const changeAdditionalAttribute = (value: any, name: IRepertoireStateKeys) => {

            const groupName = name.split(`${ADDITIONAL_ATTRIBUTES_KEY}_`)[1];

            if (value == null || value == '') {
                const index = this.props.additionalAttributeAssigned.findIndex(item => item.group === groupName);
                if (index !== -1) {
                    this.props.additionalAttributeAssigned.splice(index, 1);
                }
            } else {
                const index = this.props.additionalAttributeAssigned.findIndex(item => item.group === groupName);
                if (index !== -1) {
                    //if exists replace the code value
                    this.props.additionalAttributeAssigned[index].code = value;
                } else {
                    //if doesn't exist provide a new object
                    let newAttribute: IAdditionalAttribute = {
                        group: groupName,
                        code: value
                    };
                    this.props.additionalAttributeAssigned.push(newAttribute);
                }
            }

            this.props.updateWorkField(this.props.additionalAttributeAssigned, name as IWorkStateKeys);

        }

        const workTypeDropDownLookup = (): ILookupInstance[] => {
            const workTypes = getLookupValues(WORK_TYPE_LOOKUP, lookupValues);
            let options = workTypes ? workTypes.filter(type => type.extraFields[IS_DISPLAY] === true) : null
            return options;
        }

        const readonlyIndicatorsCheck = (): boolean => {
            let value = (readonlyIndicators && readonlyFlagsField) ? ((readonlyIndicators?.filter
                (x => x.flagTypeCode == (readonlyFlagsField?.filter
                    (x => x.FieldNames?.includes(WORK_TYPE_STATE_KEY))[0]?.Code))[0]?.value)) : "0";

            let isReadOnly = DataTypeHelper.getBooleanFormString(value);

            return isReadOnly;
        }

        const renderPostBatch = (): JSX.Element => {
            if (dataSource === DataSource.Intray) {
                return <WorkBatchInput
                    label={this.viewData[WORK_BATCH_ID_STATE_KEY]}
                    fieldName={WORK_BATCH_ID_STATE_KEY}
                    value={workBatchID}
                    changeData={changeData}
                    postButtonLabel={this.viewData[POST_WORK_BATCH_STATE_KEY]}
                    postButtonAction={() => postWorkBatch(workBatchID)}
                    matchButtonLabel={this.viewData[MATCH_WORK_BATCH_STATE_KEY]}
                    matchButtonAction={() => matchWorkBatch(workBatchID)}
                    isEnabled={!changesMade && !saveInProgress && !workBatchOperationInProgress && !isReadonly}
                />
            }
            else {
                return null;
            }
        }

        const renderAdditionalAttributes = () => {
            const { additionalAttributeAssigned } = this.props;
            const attributesLookupValues = getLookupValues(ADDITIONAL_ATTRIBUTES_LOOKUP, lookupValues);
            if (attributesLookupValues) {
                const distinctGroups = attributesLookupValues.map(attr => attr.additionalData)
                    .filter((n, i) => attributesLookupValues.map(attr => attr.additionalData).indexOf(n) === i);
                return distinctGroups.map(group => {
                    return <SizedDropdownDataInput
                        label={this.viewData[`${ADDITIONAL_ATTRIBUTES_KEY}_${group}`]}
                        fieldName={`${ADDITIONAL_ATTRIBUTES_KEY}_${group}`}
                        value={additionalAttributeAssigned?.find(x => x.group === group)?.code}
                        changeData={changeAdditionalAttribute}
                        options={attributesLookupValues.filter(x => x.additionalData === group)}
                        allowNull={true}
                        readOnly={isReadonly}
                        isHidden={!this.viewData[`${ADDITIONAL_ATTRIBUTES_KEY}_${group}`]} />;
                });
            }
            return null;
        }

        const renderMatchConnectorBox = (isReadonly: boolean) => {
            const { activeTab, tabs } = this.props;
            if (!this.viewData[MATCH_CONNECTOR_STATUS_STATE_KEY] || dataSource !== DataSource.Repertoire)
                return null;
            const matchConnectorValue = tabs[activeTab].iceDistCacheAdditionalInfoState?.matchConnectorFlag;
            let matchConnectorDescription = '';
            if (matchConnectorValue !== undefined && matchConnectorValue !== null) {
                matchConnectorDescription = matchConnectorValue ? 'Y' : 'N';
            }
            return <>
                <div className="form-group col-md-4 col-sm-5 col-xs-12 matchConnectorStatusButtonWrapper">
                    <button className="button" disabled={isReadonly} onClick={this.onLoadWorkIceDistCacheAdditionalInfo.bind(this)}>
                        {this.viewData[MATCH_CONNECTOR_STATUS_STATE_KEY]}
                    </button>
                    <input style={{ textAlign: "end" }} type="text" readOnly value={matchConnectorDescription}></input>
                </div>
            </>
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_WORKS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        return (
            <>
                <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <div className="itemAccordian"
                                onClick={this.toggleAccordion.bind(this)}
                            >
                                <h1 className="borderBootom">
                                    <i
                                        title="Expand section"
                                        className={["icon ms-Icon ms-Icon--ChevronRight",
                                            this.state.isAccordionActive ? "expanded" : null].join(" ")}
                                    >
                                    </i>
                                    General
                                </h1>
                            </div>
                            <button className="refreshBtn" title="Refresh work details" onClick={refreshWorkDetails.bind(this)}>
                                <i className="ms-Icon ms-Icon--Refresh"></i>
                            </button>
                            <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                    {this.state.isAccordionActive && loaded ? (
                        <>
                            <If condition={tabs[activeTab].workMaintenanceState.workGroup !== undefined && tabs[activeTab].workMaintenanceState.workGroup && tabs[activeTab].workMaintenanceState.workGroup.length > 0}>
                                <div className="row">
                                    <SizedTextDataInput
                                        label={this.viewData[IP_REPRESENTATION_WORK_GROUP_STATE_KEY]}
                                        fieldName={IP_REPRESENTATION_WORK_GROUP_STATE_KEY}
                                        value={tabs[activeTab].workMaintenanceState.workGroup}
                                        changeData={changeData}
                                        readOnly={true}
                                        isHidden={!this.viewData[IP_REPRESENTATION_WORK_GROUP_STATE_KEY]} />
                                </div>
                            </If>

                            <div className="row">
                                <SizedCheckboxDataInput
                                    label={this.viewData[MAINTAINED_STATE_KEY]}
                                    fieldName={MAINTAINED_STATE_KEY}
                                    value={isMaintained}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[MAINTAINED_STATE_KEY]} />
                                <SizedCheckboxDataInput
                                    label={this.viewData[MERGE_THIS_WORK_STATE_KEY]}
                                    fieldName={MERGE_THIS_WORK_STATE_KEY}
                                    value={mergeThisWork}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[MERGE_THIS_WORK_STATE_KEY]} />
                                <SizedCheckboxDataInput
                                    label={this.viewData[WORK_IS_ORIGINAL_WORK_STATE_KEY]}
                                    fieldName={WORK_IS_ORIGINAL_WORK_STATE_KEY}
                                    value={isOriginalWork}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[WORK_IS_ORIGINAL_WORK_STATE_KEY]} />
                            </div>
                            <div className="row">
                                <SizedCheckboxDataInput
                                    label={this.viewData[WORK_IS_MAINTAINED_MCPSI_STATE_KEY]}
                                    fieldName={WORK_IS_MAINTAINED_MCPSI_STATE_KEY}
                                    value={isMaintainedMCPSI}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[WORK_IS_MAINTAINED_MCPSI_STATE_KEY]}
                                />

                                <SizedCheckboxDataInput
                                    label={this.viewData[WORK_IS_DISTRIBUTED_STATE_KEY]}
                                    fieldName={WORK_IS_DISTRIBUTED_STATE_KEY}
                                    value={isDistributed}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[WORK_IS_DISTRIBUTED_STATE_KEY]}
                                />
                                <SizedCheckboxDataInput
                                    label={this.viewData[WORK_IS_ARRANGEMENT_STATE_KEY]}
                                    fieldName={WORK_IS_ARRANGEMENT_STATE_KEY}
                                    value={isArrangement}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[WORK_IS_ARRANGEMENT_STATE_KEY]} />

                            </div>
                            <div className="row">
                                <SizedCheckboxDataInput
                                    label={this.viewData[WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY]}
                                    fieldName={WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY}
                                    value={isMaintainedLibraryWork}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY]}
                                />
                                <SizedCheckboxDataInput
                                    label={this.viewData[WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY]}
                                    fieldName={WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY}
                                    value={isCommissionedForCommercial}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY]}
                                />
                                <SizedCheckboxDataInput
                                    label={this.viewData[WORK_IS_LIBRARY_STATE_KEY]}
                                    fieldName={WORK_IS_LIBRARY_STATE_KEY}
                                    value={isLibraryWork}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[WORK_IS_LIBRARY_STATE_KEY]}
                                />
                            </div>
                            <div className="row">
                                <SizedCheckboxDataInput
                                    label={this.viewData[FIRST_PERFORMANCE_STATE_KEY]}
                                    fieldName={FIRST_PERFORMANCE_STATE_KEY}
                                    value={firstPerformance}
                                    changeData={changeData}
                                    readonly={isReadonly}
                                    isHidden={!this.viewData[FIRST_PERFORMANCE_STATE_KEY]}
                                />
                            </div>
                            <div className="row">
                                <SizedTextDataInput
                                    label={this.viewData[STAMP_ID_STATE_KEY]}
                                    fieldName={STAMP_ID_STATE_KEY}
                                    value={stampID}
                                    changeData={changeData}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[STAMP_ID_STATE_KEY]} />
                                <SizedDropdownDataInput
                                    label={this.viewData[SOURCE_STATE_KEY]}
                                    fieldName={SOURCE_STATE_KEY}
                                    value={source}
                                    changeData={changeData}
                                    options={getLookupValues(WORK_SOURCE_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[SOURCE_STATE_KEY]} />
                                <SizedDropdownDataInput
                                    label={this.viewData[SOURCE_TYPE_STATE_KEY]}
                                    fieldName={SOURCE_TYPE_STATE_KEY}
                                    value={sourceType}
                                    changeData={changeData}
                                    options={getLookupValues(WORK_SOURCE_TYPE_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[SOURCE_TYPE_STATE_KEY]} />
                                <SizedDropdownDataInput
                                    label={this.viewData[SOURCE_SOCIETY_STATE_KEY]}
                                    fieldName={SOURCE_SOCIETY_STATE_KEY}
                                    value={sourceSociety}
                                    options={getLookupValues(AGENCY_LOOKUP, lookupValues)}
                                    changeData={changeData}
                                    allowNull={true}
                                    readOnly={(isReadonly === true ? isReadonly : this.state.isHiddenSociety)}
                                    isHidden={(isReadonly === true ? !isReadonly : this.state.isHiddenSociety)}
                                />
                                <SizedTextDataInput
                                    label={this.viewData[SOURCE_IP_STATE_KEY]}
                                    fieldName={SOURCE_IP_STATE_KEY}
                                    value={((workSourceIP === null) ? null : workSourceIP.fullName.value)}
                                    changeData={changeData}
                                    readOnly={(isReadonly === true ? isReadonly : this.state.isHiddenIP)}
                                    onClick={this.onClick}
                                    clickPrompt={(isReadonly === true ? !isReadonly : !this.state.isHiddenIP)}
                                    isHidden={(isReadonly === true ? !isReadonly : this.state.isHiddenIP)}
                                />
                                <SizedTextDataInput
                                    label={this.viewData[SOURCE_IPI_NUMBER_STATE_KEY]}
                                    fieldName={SOURCE_IPI_NUMBER_STATE_KEY}
                                    value={((workSourceIP === null) ? null : workSourceIP.ipiNameNumber)}
                                    changeData={changeData}
                                    readOnly={true}
                                    clickPrompt={false}
                                    isHidden={(isReadonly === true ? !isReadonly : this.state.isHiddenIP)}
                                />
                                <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                                    <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                                </button>
                                <SizedDateDataInput
                                    label={this.viewData[REG_DATE_STATE_KEY]}
                                    fieldName={REG_DATE_STATE_KEY}
                                    value={registrationDate}
                                    changeData={changeData}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[REG_DATE_STATE_KEY]} />

                                <SizedDropdownDataInput
                                    label={this.viewData[COMPOUND_TYPE_STATE_KEY]}
                                    fieldName={COMPOUND_TYPE_STATE_KEY}
                                    value={compoundType}
                                    changeData={changeData}
                                    options={getLookupValues(WORK_MEDLEY_TYPE_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[COMPOUND_TYPE_STATE_KEY]} />

                                <SizedDropdownDataInput
                                    label={this.viewData[GENRE_STATE_KEY]}
                                    fieldName={GENRE_STATE_KEY}
                                    value={genre}
                                    changeData={changeData}
                                    options={getLookupValues(WORK_GENRE_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[GENRE_STATE_KEY]} />

                                <If condition={lookupValues.WorkDistributionCategory.lookups.length !== undefined && lookupValues.WorkDistributionCategory.lookups.length > 0}>
                                    <SizedDropdownDataInput
                                        label={this.viewData[DISTRIBUTION_CATEGORY_STATE_KEY]}
                                        fieldName={DISTRIBUTION_CATEGORY_STATE_KEY}
                                        value={distributionCategory}
                                        changeData={changeData}
                                        options={getLookupValues(WORK_DISTRIBUTION_CATEGORY_LOOKUP, lookupValues)}
                                        allowNull={true}
                                        readOnly={isReadonly}
                                        isHidden={!this.viewData[DISTRIBUTION_CATEGORY_STATE_KEY]} />
                                </If>

                                <SizedDropdownDataInput
                                    label={this.viewData[WORK_PARTS_STATE_KEY]}
                                    fieldName={WORK_PARTS_STATE_KEY}
                                    value={workPart}
                                    changeData={changeData}
                                    options={getLookupValues(WORK_PARTS_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[WORK_PARTS_STATE_KEY]} />

                                <SizedDropdownDataInput
                                    label={this.viewData[WORK_TYPE_STATE_KEY]}
                                    fieldName={WORK_TYPE_STATE_KEY}
                                    value={workType}
                                    changeData={changeData}
                                    options={workTypeDropDownLookup()}
                                    allowNull={true}
                                    readOnly={isReadonly == true ? isReadonly : readonlyIndicatorsCheck()}
                                    isHidden={!this.viewData[WORK_TYPE_STATE_KEY]} />

                                <FormattedTimeDataInput
                                    fieldName={DURATION_STATE_KEY}
                                    label={this.viewData[DURATION_STATE_KEY]}
                                    isHidden={!this.viewData[DURATION_STATE_KEY]}
                                    value={duration}
                                    changeData={changeData}
                                    readOnly={isReadonly}
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    isDataGridSize={false}
                                />
                                <SizedDropdownDataInput
                                    label={this.viewData[MUSIC_SOURCE_STATE_KEY]}
                                    fieldName={MUSIC_SOURCE_STATE_KEY}
                                    value={musicSource}
                                    changeData={changeData}
                                    options={getLookupValues(MUSIC_SOURCE_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[MUSIC_SOURCE_STATE_KEY]}
                                />
                                <SizedDateDataInput
                                    label={this.viewData[WORK_CREATION_DATE_STATE_KEY]}
                                    fieldName={WORK_CREATION_DATE_STATE_KEY}
                                    value={workCreationDate}
                                    changeData={changeData}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[WORK_CREATION_DATE_STATE_KEY]}
                                />
                                <SizedDropdownDataInput
                                    label={this.viewData[MUSIC_ARRANGEMENT_STATE_KEY]}
                                    fieldName={MUSIC_ARRANGEMENT_STATE_KEY}
                                    value={musicArrangement}
                                    changeData={changeData}
                                    options={getLookupValues(MUSIC_ARRANGEMENT_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={this.state.isHiddenArrangements}

                                />
                                <SizedDropdownDataInput
                                    label={this.viewData[LYRIC_ADAPTATION_STATE_KEY]}
                                    fieldName={LYRIC_ADAPTATION_STATE_KEY}
                                    value={lyricAdaptation}
                                    changeData={changeData}
                                    options={getLookupValues(LYRIC_ADAPTATION_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={this.state.isHiddenArrangements}
                                />
                                {renderPostBatch()}
                            </div>
                            <div className="row">
                                {renderAdditionalAttributes()}
                            </div>
                            <hr /><div className="pointerEnable">
                                <WorkMaintenanceTerritorySpecificInfoView
                                    workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                                    territorySpecificInfo={territorySpecificInfo}
                                    changeData={changeData}
                                    lookupValues={lookupValues}
                                    switchSetTypeGroup={switchSetTypeGroup}
                                    workType={workType}
                                    genreCategoryLookup={genreCategories}
                                    workGenre={genre}
                                    genreCategoriesLookup={genreCategoriesByDomOrInt}
                                    readOnly={isReadonly}
                                    filterGenreCategories={filterGenreCategories}
                                    pdArrangementMusicLyric={pdArrangementMusicLyric}
                                    recalculateDomesticorForeign={recalculateDomesticorForeign}
                                />
                            </div><hr />
                            <div className="row">
                                {renderMatchConnectorBox(isReadonly)}
                            </div>
                        </>
                    ) : <></>}
                </div>
            </>
        );
    }
}
