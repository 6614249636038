export class DataTypeHelper{
    public static getBooleanFormString(value: string) : boolean{
      switch(value){
        case "true":
        case "1":
            return true;
        default:
            return false;

      }
    }
}