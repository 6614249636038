// Lookup keys
export const WORK_STATUS_LOOKUP = "WorkStatus";
export const WORK_SUB_STATUS_LOOKUP = "WorkSubStatus";
export const WORK_SOURCE_LOOKUP = "WorkSource";
export const WORK_SOURCE_ID_LOOKUP = "WorkSourceID";
export const WORK_SOURCE_TYPE_LOOKUP = "WorkSourceType";
export const WORK_CONTRIBUTOR_SOURCE_LOOKUP = "WorkContributorSource";
export const WORK_NAME_LOOKUP = "WorkName";
export const WORK_PERFORMER_LOOKUP = "WorkPerformer";
export const WORK_TYPE_LOOKUP = "WorkType";
export const WORK_PERFORMER_NUMBER_LOOKUP = "WorkPerformerNumber";
export const WORK_MEDLEY_TYPE_LOOKUP = "WorkMedleyType";
export const TERRITORY_LOOKUP = "Territory";
export const WORK_GENRE_LOOKUP = "WorkGenre";
export const SET_TYPE_GROUP_LOOKUP = "SetTypeGroup";
export const SHARE_RIGHT_TYPE_LOOKUP = "ShareRightType";
export const WORK_SUBJECT_LOOKUP = "WorkSubject";
export const LICENSING_USE_TYPE_LOOKUP = "LicensingUseType";
export const CONTRIBUTOR_MEMBER_TYPE_LOOKUP = "ContributorMemberType";
export const AGREEMENT_SOURCE_LOOKUP = "AgreementSource";
export const AGREEMENT_TYPE_LOOKUP = "AgreementType";
export const SET_TYPE_LOOKUP = "SetType";
export const LANGUAGE_LOOKUP = "Language";
export const WORK_NUMBER_LOOKUP = "WorkNumber";
export const WORK_CONTRIBUTOR_TYPE_LOOKUP = "WorkContributorType";
export const WORK_CONTRIBUTOR_SUB_TYPE_LOOKUP = "WorkContributorSubType";
export const APPLICABLE_WORKS_LOOKUP = "AgreementApplicableWorksType";
export const SPECIFIC_AGREEMENT_TYPE_LOOKUP = "SpecificAgreementType";
export const AGREEMENT_CATEGORY_LOOKUP = "AgreementCategory";
export const AGREEMENT_NUMBER_TYPE_LOOKUP = "AgreementNumberType";
export const AGREEMENT_STATUS_LOOKUP = "AgreementStatus";
export const JOB_TYPE_LOOKUP = "JobType";
export const JOB_STATUS_LOOKUP = "JobStatus";
export const PRODUCT_TYPE_LOOKUP = "ProductCoreType";
export const PRODUCT_SOURCE_LOOKUP = "ProductSource";
export const PRODUCT_SOURCE_TYPE_LOOKUP = "ProductSourceType";
export const PRODUCT_STATUS_TYPE_LOOKUP = "ProductStatus";
export const PRODUCT_NAME_TYPE_LOOKUP = "ProductNameType";
export const PRODUCT_NUMBER_TYPE_LOOKUP = "ProductNumberType";
export const PRODUCT_STATUS_LOOKUP = "ProductStatus";
export const PRODUCT_PERFORMER_TYPE_LOOKUP = "ProductPerformer";
export const AV_CATEGORY_LOOKUP = "AVCategory";
export const AV_VERSION_CATEGORY_LOOKUP = "AVVersionCategory";
export const CUE_TYPE_LOOKUP = "CueType";
export const CUE_SHEET_TYPE_LOOKUP = "CueSheetType";
export const AV_WORK_DESTINATION_LOOKUP = "AVWorkDestination";
export const CODE_OF_REVISION_LOOKUP = "AVWorkCodeOfRevision";
export const AD_TYPE_LOOKUP = "AdType"
export const AV_RIP_ROLE_LOOKUP = "AVRIPRoles";
export const WORK_SUBMISSION_STATUS_LOOKUP = "WorkSubmissionStatus";
export const FILE_TYPE_LOOKUP = "FileType";
export const MATCHING_OUTPUT_DESTINATION_LOOKUP = "MatchingOutputDestination";
export const BOOLEAN_YES_NO_LOOKUP = "BooleanYesNo";
export const DOMESTIC_OR_FOREIGN = "DomesticOrForeign";
export const INSTRUMENTATION_LOOKUP = "Instrumentation";
export const INSTRUMENT_LOOKUP = "Instrument";
export const ACCOUNT_TYPE_LOOKUP = "AccountType";
export const IPI_STATUS_LOOKUP = "IpiStatus";
export const DISTRIBUTIONTYPE_LOOKUP = 'DistributionType';
export const ALLOCATIONSTATUS_LOOKUP = 'AllocationStatus';
export const USAGEMATCHSTATUS_LOOKUP = 'UsageMatchStatus';
export const USAGE_GROUP_APPROVAL_STATUS_LOOKUP = 'UsageGroupApprovalStatus';
export const USAGEGROUP_INCLUDEFEESINERROR_LOOKUP = 'UsageGroupIncludeFeesInError';
export const AV_SUBMISSION_STATUS = 'AVSubmissionStatus';
export const ALLOCATION_RULE_LOOKUP = "AllocationRule";
export const DISTRIBUTION_CATEGORY_TYPE_LOOKUP = "DistributionCategoryType";
export const USAGE_TYPE_LOOKUP = "UsageType";
export const SET_TYPE_RIGHTS_LOOKUP = "SetTypeRights";
export const DISTRIBUTION_STATUS_LOOKUP = "DistributionStatus";
export const DISTRIBUTION_TYPE_LOOKUP = "DistributionType";
export const DISTRIBUTION_SUB_TYPE_LOOKUP = "DistributionSubType";
export const IP_AGREEMENT_CREATION_CLASS_LOOKUP = "IPAgreementCreationClass";
export const IP_AGREEMENT_ROLE_LOOKUP = "IPAgreementRole";
export const IP_AGREEMENT_ROLES_CREATION_CLASS_LOOKUP = "IPAgreementRolesCreationClasses";
export const MUSIC_SOURCE_LOOKUP = "MusicSource";
export const MUSIC_ARRANGEMENT_LOOKUP = "MusicArrangement";
export const LYRIC_ADAPTATION_LOOKUP = "LyricAdaptation";
export const MUSIC_USAGE_LOOKUP = "MusicUsage";
export const CLAIM_STATUS_LOOKUP = "ClaimStatus";
export const MEMBER_CLAIM_STATUS_LOOKUP = "MemberClaimStatus";
export const CLAIM_TYPE_LOOKUP = "ClaimType";
export const TRANSLATION_TYPE_LOOKUP = "TranslationType";
export const REPRESENTATION_TYPE_LOOKUP = "LicensingRepresentationType";
export const BINDING_TYPE_LOOKUP = "BindingType";
export const WORK_FLAG_LOOKUP = "WorkFlag";
export const BOOK_TYPE_LOOKUP = "BookType";
export const MEDIA_CATEGORY_TYPE_LOOKUP = "WorkSupportingMediaCategory";
export const MEDIA_TYPE_LOOKUP = "WorkSupportingMedia";
export const LEARNING_BOOK_TYPE_LOOKUP = "LearningBookType";
export const AVAILABILITY_TYPE_LOOKUP = "WorkProductAvailability";
export const PUBLICATION_TYPE_LOOKUP = "WorkPublicationType";
export const PERIODICITY_TYPE_LOOKUP = "WorkPeriodicityType";
export const WORK_FLAG_TYPE_LOOKUP = "WorkFlag";
export const INDICATOR_VALUE_TYPE_LOOKUP = "IndicatorValueType";
export const ADJUSTMENT_CATEGORY_LOOKUP = "AdjustmentCategory";
export const ADJUSTMENT_STATUS_LOOKUP = 'AdjustmentStatus';
export const WORK_PARTS_LOOKUP = "WorkParts";
export const WORK_GENRE_CATEGORY_LOOKUP = "WorkGenreCategory";
export const WORK_DISTRIBUTION_CATEGORY_LOOKUP = "WorkDistributionCategory";
export const CURRENCY_LOOKUP = "Currency";
export const STATEMENT_FORMAT_LOOKUP = "StatementFormat";
export const PAYMENTRUN_TYPE_LOOKUP = "PaymentRunType";
export const PAYMENTRUN_STATUS_LOOKUP = "PaymentRunStatus";
export const AGENCY_LOOKUP = "Agency";
export const LICENSING_USES_LOOKUP = "LicensingUses";
export const PRODUCTION_TYPE_LOOKUP = "ProductionType";
export const COMMENT_CATEGORY_LOOKUP = "CommentCategory";
export const USAGES_FEES_ERROR_LOOKUP = "UsagesFeesError";
export const MP_STATEMENT_DATE_FROM_LOOKUP = "MemberPortalStatementDateFrom";
export const PRODUCT_ATTACHMENT_TYPE_LOOKUP = "ProductAttachmentType";
export const WORK_ATTACHMENT_TYPE_LOOKUP = "WorkAttachmentType";
export const LICENSING_REQUEST_TYPE = "LicensingRequestType";
export const CLAIM_ORIGIN_LOOKUP = "ClaimOrigin";
export const CLAIM_DEPARTMENT_LOOKUP = "ClaimDepartment";
export const DISCOUNT_TYPE_LOOKUP = "DiscountType";
export const USAGE_GROUP_VENUE_TYPE_LOOKUP = "UsageGroupVenueType";
export const USAGE_GROUP_PERFORMANCE_TYPE_LOOKUP = "UsageGroupPerformanceType";
export const LICENSING_USES_TYPE_LOOKUP = "LicensingUses";
export const REJECT_REASON_TYPE_LOOKUP = "RejectReason";
export const AGREEMENT_ATTACHMENT_TYPE_LOOKUP = "AgreementAttachmentType";
export const IP_AGREEMENT_TIME_RANGE_LOOKUP = "IPAgreementTimeRange";
export const IP_REPRESENTATION_TIME_RANGE_LOOKUP = "IPRepresentationTimeRange";
export const JOB_TIME_RANGE_LOOKUP = "JobTimeRange";
export const QA_STATUS_LOOKUP = "QAStatus";
export const SOCIETY_OF_LICENSE_LOOKUP = "WorkSocietyOfLicense";
export const AGREEMENT_RETENTION_LOOKUP = "AgreementRetention";
export const AGREEMENT_RETAINED_UNTIL_LOOKUP = "AgreementRetainedUntil";
export const USAGE_GROUP_QA_STATUS_LOOKUP = "UsageGroupQAStatus";
export const PORTAL_DEFAULT_VALUES_LOOKUP = "PortalDefaultValues";
export const ADDITIONAL_ATTRIBUTES_LOOKUP = "WorkAdditionalAttribute";
export const SUBMITTER_LOOKUP = "Submitter";


