import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import IAppState from "../../redux/types/IAppState";
import { mp_addTab, createNewRegisteredWork, resetPagination, sortSearchResults, updatePagination, setDataSource, expandWorkResult, createNewDraftWork, expandAllResults } from "../../redux/reducers/MembersPortalReducer";
import {
    searchRegisteredWorksThunk,
    getRegisteredWorkDetailsThunk,
} from "../../redux/thunks/MembersPortalThunks";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IRegisteredWorkSearchQuery } from "../types/IRegisteredWorkSearchQuery";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { AddtoPackageThunk, updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import RegisteredWorksPage from "./RegisteredWorksPage";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";
import { FormatFields } from "../../redux/types/FormatFields";

export default connect(
    (state: IAppState) => ({
        dataSource: state.membersPortal.dataSource,
        registeredWorksPageData: state.membersPortal.componentData.RegisteredWorksPage,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        registeredWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].registeredWorkSearchResult,
        searchSuccessful: state.membersPortal.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'registeredWorks')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'registeredWorks')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'registeredWorks')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        registeredWorksMaintenanceGeneralDataViewData: state.membersPortal.componentData.RegisteredWorkMaintenanceGeneralDataView,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        allResultsPerPage: state.membersPortal.resultsPerPage,
        roles: state.account.roles,
        memberPortalSearchType: MemberPortalSearchType.MyRegisteredSearch,
        expandedWorkResults: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandedWorkResults,
        expandAll: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandAll,
        currentUserNameNumber: state.account.currentUserNameNumber,
    }),
    (dispatch: Dispatch) => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        searchRegisteredWorks: (query: IRegisteredWorkSearchQuery) =>
            dispatch<any>(searchRegisteredWorksThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getRegisteredWorkDetails: (dataSource: string, registeredWorksID: number, lookupValues: ILookupDictionary, registeredWorkMaintenanceDataViewData: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getRegisteredWorkDetailsThunk(dataSource, registeredWorksID, lookupValues, registeredWorkMaintenanceDataViewData, formats)),
        addNewDraftWorks: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, registeredWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch(createNewDraftWork(lookups, currentUserNameNumber, isNew, registeredWorksMaintenanceGeneralDataViewData, formats)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        expandWorkResult: (workResult: number) => dispatch<any>(expandWorkResult(workResult)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        addToPackages:( userName:string,entityType:string,entityID:number)=>dispatch<any>(AddtoPackageThunk(userName,entityType,entityID)),
    })
)(RegisteredWorksPage);