import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import { SettingsPage } from "./SettingsPage";
import {
    getMatchTypesThunk,
    getSourcesThunk,
    loadSourceThunk,
    updateSourceThunk,
    updateSourceHierarchyThunk,
    updateParameterInstancesThunk
} from "../../redux/thunks/SettingsThunk";
import { ISourceSearchResult } from "../types/ISourceSearchResult";
import { IProductType } from "../types/IProductType";
import { PropertyWindowSaveState } from "../types/PropertyWindowSaveState";
import { updateTreeNodes } from "../../redux/actions/SettingsActionCreators";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";
import { IParameterSearchResult } from "../types/IParameterSearchResult";
import { SettingType } from "../types/SettingType";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { initialiseLookupsThunk } from "../../redux/thunks/DataIngestionThunks";

export default connect(
    (state: IAppState, props?: any) => ({
        settingType: SettingType.Validation,
        path: props.match.path,
        roles: state.account.roles,
        formats: state.dataIngestion.formats,
        lookupValues: state.lookupEntities
    }),
    (dispatch: Dispatch) => ({
        getMatchTypes: () => dispatch<any>(getMatchTypesThunk()),
        getSources: (matchTypeID: number) =>
            dispatch<any>(getSourcesThunk(matchTypeID)),
        loadSource: (
            sourceID: number,
            selectedProductType: IProductType,
            settingType: number,
            saveState: PropertyWindowSaveState
        ) => {
            dispatch<any>(
                loadSourceThunk(sourceID, selectedProductType, settingType, saveState)
            );
        },
        updateSource: (
            sourceID: number,
            sourceName: string,
            sourceCode: string,
            sourceDescription: string,
            rejectDuplicateUsageFiles: boolean
        ) =>
            dispatch<any>(updateSourceThunk(sourceID, sourceName, sourceCode, sourceDescription, rejectDuplicateUsageFiles)),
        updateSourceHierarchy: (sourceTree: ISourceSearchResult) =>
            dispatch<any>(updateSourceHierarchyThunk(sourceTree)),
        updateTreeNodes: (updatedTreeNodes: ITreeNodeData<ISourceSearchResult>) =>
            dispatch(updateTreeNodes(updatedTreeNodes)),
        updateParameterInstances: (
            parameters: IParameterSearchResult[],
            sourceID: number,
            selectedProductType: IProductType,
            settingType: number
        ) =>
            dispatch<any>(
                updateParameterInstancesThunk(
                    parameters,
                    sourceID,
                    selectedProductType,
                    settingType
                )
            ),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        initialiseLookupsThunk: (lookupValues: ILookupDictionary) => dispatch<any>(initialiseLookupsThunk(lookupValues)),
    })
)(SettingsPage);
