import * as React from "react";
import { ITreeNodeData } from "../types/TreeNodeData";
import { If } from "../../core/components/If";

interface ITreeNodeProps<T> {
    nodeData: ITreeNodeData<T>;
    selectedNodeChanged: (nodeData: ITreeNodeData<T>) => void;
    isEditing: boolean;
    addSourceButtonText: string;
    deleteSourceButtonText: string;
    addSource: (parent: T) => void;
    deleteSource: (source: T) => void;
    alwaysExpanded: boolean;
    indentation: number;
    deleteRoleDisabled?: boolean;
}

export class TreeNode<T> extends React.Component<ITreeNodeProps<T>> {
    constructor(props: ITreeNodeProps<T>) {
        super(props);
    }

    onClick(
        event:
            | React.SyntheticEvent<HTMLInputElement>
            | React.MouseEvent<HTMLLIElement, MouseEvent>
    ) {
        event.stopPropagation();
        this.props.selectedNodeChanged(this.props.nodeData);
    }

    addSourceClick() {
        this.props.addSource(this.props.nodeData.object);
    }

    deleteSourceClick() {
        this.props.deleteSource(this.props.nodeData.object);
    }

    getChevronIcon(): string {
        const props = this.props;
        const data: ITreeNodeData<T> = props.nodeData;
        if (!props || props.alwaysExpanded)
            return "";
        else if (props.nodeData.isExpanded && data.children && data.children.length)
            return "icon expand-icon chevron ms-Icon ms-Icon--ChevronDown"
        else
            return "icon expand-icon chevron ms-Icon ms-Icon--ChevronRight";
            
    }

    renderChild(node: ITreeNodeData<T>, index: number): JSX.Element {
        const props = this.props;
        return <TreeNode
            key={index}
            nodeData={node}
            selectedNodeChanged={props.selectedNodeChanged}
            isEditing={props.isEditing}
            addSourceButtonText={props.addSourceButtonText}
            deleteSourceButtonText={props.deleteSourceButtonText}
            addSource={props.addSource}
            deleteSource={props.deleteSource}
            alwaysExpanded={props.alwaysExpanded}
            indentation={props.indentation + 1}
            deleteRoleDisabled={props.deleteRoleDisabled}
        />
    }

    renderChildren(): JSX.Element[] {
        const props = this.props;
        const data: ITreeNodeData<T> = props.nodeData;
        const isExpanded: boolean = props.alwaysExpanded || data.isExpanded;

        if (isExpanded && data.children && data.children.length > 0) {
            return data.children.map((node, index) => this.renderChild(node, index));
        }
        else {
            return null;
        }
    }

    renderListItemParts(): JSX.Element[] {
        const data: ITreeNodeData<T> = this.props.nodeData;
        const spans: JSX.Element[] = [];
        let i = 0;
        for (i = 0; i < this.props.indentation; i++) {
            spans.push(<span key={i} className="indent"></span>)
        }

        spans.push(<span key={i++} className={this.getChevronIcon()} />);
        if (data.icon) {
            spans.push(<span key={i++} className={data.icon} />);
        }
        spans.push(<span key={i++} className="text">{data.name}</span>);

        const count = this.props.nodeData.progressCount;
        if (count) {
            spans.push(<span key={i++} className="badge"><span className="icon ms-Icon ms-Icon--SyncOccurence" />{count}</span>);
        }

        return spans;
    }

    generateRandomID(): string{
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
    }

    renderEditHierarchyButtons(): JSX.Element[] {
        const data: ITreeNodeData<T> = this.props.nodeData;
        const spans: JSX.Element[] = [];
        let i = 0;
        const uuId = this.generateRandomID();
        // Add indent span if there is any props.indentation
        for (i = 0; i < this.props.indentation; i++) {
            spans.push(<span key={i} className="indent"></span>)
        }
        spans.push(<span key={i++} className={this.getChevronIcon()} />);

        spans.push(
            <span key={i} className="indent">
                <a
                    href={"#editHierarchy" + data.level + this.props.nodeData.id  + uuId}
                    data-toggle="collapse"
                ><i className="glyphicon glyphicon-pencil editIcon" /> {data.name}
                </a>
                <div
                    id={"editHierarchy" + data.level + this.props.nodeData.id + uuId}
                    className="collapse editBox indent"
                >
                    <ul className="indent">
                        <If condition={data.level < 2}>
                            <li>
                                <a onClick={this.addSourceClick.bind(this)}>
                                    {this.props.addSourceButtonText}
                                </a>
                            </li>
                        </If>
                        <If condition={data.level > 0 && !this.props.deleteRoleDisabled}>
                            <li>
                                <a className="deleteText" onClick={this.deleteSourceClick.bind(this)} >
                                    {this.props.deleteSourceButtonText}
                                </a>
                            </li>
                        </If>
                    </ul>
                </div>
            </span>)
        return spans;
    }

    render() {
        const props = this.props;
        if (!props.nodeData.visible) {
            return null;
        }
        let className = "list-group-item node-treeview";
        const style: React.CSSProperties = { color: undefined, backgroundColor: undefined, border: 'none' };

        let editingClassName = "list-group-item node-treeview";
        const editingStyle: React.CSSProperties = { backgroundColor: undefined, border: 'none' };

        if (this.props.nodeData.active && !props.isEditing) {
            className = className + " node-selected";
            style.color = '#FFFFFF'
        }

        return (<>
            <If condition={!props.isEditing}>
                <li onClick={this.onClick.bind(this)} className={className} style={style}>
                    {this.renderListItemParts()}
                </li>
            </If>

            <If condition={props.isEditing}>
                <li className={editingClassName} style={editingStyle}>
                    {/* Edit Source Hierarchy*/}
                    {this.renderEditHierarchyButtons()}
                </li>
            </If>



            {/* Children */}
            {this.renderChildren()}
        </>

        );
    }
}
