import { IProductState } from "../../redux/types/IProductState";
import { IValidationMessage } from "../types/IValidationMessage";

export class ProductValidator {

    public static validateProduct(product: IProductState): IValidationMessage[] {
        let productIsValid: IValidationMessage[];
        productIsValid = [];
        if ((product.productType === 'CUE SHEET') && (product.duration > product.productAVWork?.totalDuration && product.productAVWork?.totalDuration > 0))
        productIsValid.push({ messageKey: "durationValidationFailedMessage"})

        return productIsValid;
    }
}