import React, { PureComponent, FunctionComponent } from "react";
import { REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE, REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";

export interface IActionButtonProps {
    buttonText: string;
    buttonAction: () => void;
    disabled?: boolean;
    dataTestId?: string;
    className?: string;
}

export interface IActionButtonFormikProps {
    buttonText: string;
    disabled?: boolean;
    className?: string;
    isSubmitting: boolean;
    isErrorInForm: boolean;
}

export const SizedActionButton: FunctionComponent<IActionButtonProps> = (props: IActionButtonProps) => {
    return <div className={REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE}>
        <ActionButton {...props} />
    </div>
}

export const WideActionButton: FunctionComponent<IActionButtonProps> = (props: IActionButtonProps) => {
    return <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
        <ActionButton {...props} />
    </div>
}

export const InlineActionButton: FunctionComponent<IActionButtonProps> = (props: IActionButtonProps) => {
    return <ActionButton {...props} />
}

export default class ActionButton extends PureComponent<IActionButtonProps> {
    render() {
        const { buttonText, buttonAction, disabled, className, dataTestId } = this.props;
        return (
            <button onClick={buttonAction} className={className ? className : "button btn-defaultPrimary"} disabled={disabled} data-testid={dataTestId ?`${buttonText}-${dataTestId}-action-button`: buttonText+"-action-button"}>
                {buttonText}
            </button>
        );
    }
}

export class ActionButtonFormik extends PureComponent<IActionButtonFormikProps> {
    render() {
        const { buttonText, className, isSubmitting, isErrorInForm, disabled } = this.props;
        return (
            <button type="submit" disabled={isSubmitting || isErrorInForm || disabled} className={className ? className : "button btn-defaultPrimary"} data-testid={buttonText+"-action-button"
    }>
                {buttonText}
            </button>)
    }
}

