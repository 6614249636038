import { connect } from "react-redux";
import { Dispatch } from "redux";
import { hideModal, showModal, showUpdateFieldsModal } from "../../redux/reducers/ModalReducer";
import {
    addTab, expandMatchWorkResult, resetPagination, setUsageType, updatePagination,
    usageExpandAllResults, searchDataChanged, setEditableFields, sortSearchResults, updateEditableFields, updateUsageGroupMatches, addWorkOrProductToUsageSearchCriteria, setIsHundredResultsPerPage,
    onUpdatePoolCodeToSearch
} from "../../redux/reducers/RepertoireReducer";
import {
    getDistributionsThunk,
    getPoolsThunk,
    getUsageMaintenanceDataThunk,
    searchMatchWorksThunk,
    searchUsagesThunk,
    updateComponentFieldItemsThunk,
    getWorkDetailsThunk,
    getProductDetailsThunk,
    searchMatchProductsThunk,
    getMatchingSourcesThunk,
    getDistributionTypesThunk,
    updateUserPreferencesThunk,
    saveUsageGroupFromSearchResultsThunk,
    getUsagePoolDetailsThunk,
    searchUsagePoolsByCodeDistributionPoolsThunk,
    getDistributionDetailsThunk,
    getProductMaintenanceEnableCuesheets,
    getProductMaintenanceCuesheetsDataSource
} from "../../redux/thunks/RepertoireThunks";
import IAppState from "../../redux/types/IAppState";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IMatchWorksSearchQuery } from "../types/usageTypes/IMatchWorksSearchQuery";
import { IMatchProductsSearchQuery } from "../types/usageTypes/IMatchProductsSearchQuery";
import { IUsagesSearchQuery } from "../types/usageTypes/IUsagesSearchQuery";
import FindUsagePage from "./FindUsagePage";
import { IRepertoireField } from "../types/IRepertoireField";
import { IShowFieldSettingViewModalProps } from "../components/repertoireModal/RepertoireModal";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { FormatFields } from "../../redux/types/FormatFields";
import { ITreeData } from "../types/ITreeData";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { IUsageSearchResultRowType } from "../types/usageTypes/IUsageGroupsSearchResult";
import { IUsagePoolSearchQuery } from "../types/usageTypes/IUsagePoolSearchQuery";
import { ITabProps } from "../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        findUsagePageData: state.repertoire.componentData.FindUsagePage,
        searchViewData: state.repertoire.componentData.UsageSearchView,
        activeTab: state.repertoire.panes[props.paneIndex].activeTab,
        tabs: state.repertoire.panes[props.paneIndex].tabs,
        currentPage: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage : undefined,
        searchResultsTableData: state.repertoire.componentData.ResultsTable,
        usageGroupsSearchResult: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usageGroupsSearchResult,
        matchWorkSearchResult: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].matchWorkSearchResult,
        matchProductsSearchResult: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].matchProductsSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        workSearchFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'usages')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'usages')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'usages')[0].resultsPerSection,
        isHundredResultsPerPage: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].isHundredResultsPerPage,
        loadingViewData: state.repertoire.componentData.LoadingView,
        lookupValues: state.lookupEntities,
        distributions: state.repertoire.distributions,
        sourceMinors: state.repertoire.sourceMinors,
        sourceMajors: state.repertoire.sourceMajors,
        pools: state.repertoire.pools,
        expandedMatchWorkResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].expandedMatchWorkResults,
        usageExpandAll: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usageExpandAll,
        usageType: state.repertoire.usageType,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        editableFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields,
        workMaintenanceGeneralDataViewData: state.repertoire.componentData.WorkMaintenanceGeneralDataView,
        productMaintenanceGeneralDataViewData: state.repertoire.componentData.ProductMaintenanceGeneralDataView,
        lookupSources: state.repertoire.matchingSourceTreeData,
        workflowFieldsData: state.repertoire.componentData.WorkflowFieldsData,
        isWorkflowSessionStarted: state.repertoire.isWorkflowSessionStarted,
        workflowSessionItems: state.repertoire.workflowSessionItems,
        currentWorkflowIndex: state.repertoire.currentWorkflowIndex,
        isOpenForWorkflowSession: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].isEntityForWorkflowSession,
        distributionTypes: state.repertoire.distributionTypes,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        sources: state.repertoire.sources,
        matchingSources: state.repertoire.matchingSourceTreeData,
        continuationToken: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].continuationToken ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].continuationToken : undefined,
        roles: state.account.roles,
        saveResultData: state.repertoire.componentData.SaveResult,
        matchingEntityForUsageSearchCriteria: state.repertoire.matchingEntityForUsageSearchCriteria,
        usageMatchingDefaultsWorks: state.repertoire.usageMatchingDefaultsWorks,
        usageMatchingDefaultsProducts: state.repertoire.usageMatchingDefaultsProducts,
        poolCodeToSearch: state.repertoire.panes[state.repertoire.activePane] && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab]?.poolCodeToSearch && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab]?.poolCodeToSearch,
        customer: state.account.customer,
        usagePoolsSearchResults: state.repertoire.panes[state.repertoire.activePane] && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab]?.usagePoolSearchResult ? state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab]?.usagePoolSearchResult : undefined
    }),
    (dispatch: Dispatch) => ({
        hideModal: () => dispatch(hideModal()),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        searchUsages: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[], continuationToken?: string, isHundredResultsPerPage?: boolean) => dispatch<any>(searchUsagesThunk(query, false, usageType, lookupSources, continuationToken, isHundredResultsPerPage)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        setIsHundredResultsPerPage: (isHundredResultsPerPage: boolean) => dispatch(setIsHundredResultsPerPage(isHundredResultsPerPage)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) =>
            dispatch<any>(getUsageMaintenanceDataThunk(usageID, false)),
        getDistributions: () => dispatch<any>(getDistributionsThunk()),
        getPools: () => dispatch<any>(getPoolsThunk()),
        getSources: () => dispatch<any>(getMatchingSourcesThunk()),
        searchMatchWorks: (query: IMatchWorksSearchQuery, modalOpen: boolean) => dispatch<any>(searchMatchWorksThunk(query, true)),
        searchMatchProducts: (query: IMatchProductsSearchQuery, modalOpen: boolean) => dispatch<any>(searchMatchProductsThunk(query, true)),
        expandMatchWorkResult: (matchWorkResults: number) => dispatch<any>(expandMatchWorkResult(matchWorkResults)),
        usageExpandAllResults: () => dispatch<any>(usageExpandAllResults()),
        setUsageType: (usageType: string) => dispatch(setUsageType(usageType)),
        searchDataChanged: (field: string, value: any) => dispatch(searchDataChanged(field, value)),
        setEditableFields: (componentFieldName: string, componentInstance: string) => dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) => dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes)),
        getProductDetails: (dataSource: string, productCoreID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats?: FormatFields[]) =>
            dispatch<any>(getProductDetailsThunk(dataSource, productCoreID, productMaintenanceGeneralDataView, formats)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        getDistributionTypes: () => dispatch<any>(getDistributionTypesThunk()),
        updateEditableField: (value: any, key: string) => dispatch(updateEditableFields(value, key)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        updateUsageGroupMatches: (usageGroup: IUsageSearchResultRowType) => dispatch(updateUsageGroupMatches(usageGroup)),
        saveUsageGroup: (updatedUsageGroup: IUsageSearchResultRowType) => dispatch<any>(saveUsageGroupFromSearchResultsThunk(updatedUsageGroup)),
        getUsagePool: (poolId: number, matchingSources: ITreeData[],isPoolMaintainence?:boolean) => dispatch<any>(getUsagePoolDetailsThunk(poolId, matchingSources,true)),
        getDistributionDetails: (distributionID: number, lookupValues: ILookupDictionary) => dispatch<any>(getDistributionDetailsThunk(distributionID, lookupValues)),
        getProductMaintenanceEnableCuesheets:() => dispatch<any>(getProductMaintenanceEnableCuesheets()),
        getProductMaintenanceCuesheetsDataSource:() => dispatch<any>(getProductMaintenanceCuesheetsDataSource()),
        searchUsagePoolsByCodeDistributionPools: (query: IUsagePoolSearchQuery) => dispatch<any>(searchUsagePoolsByCodeDistributionPoolsThunk(query)),
        updatePoolCodeToSearch: (value: string) => dispatch<any>(onUpdatePoolCodeToSearch(value))
    })
)(FindUsagePage);
