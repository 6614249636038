import * as React from "react";
import { IRepertoireField } from "../../../types/IRepertoireField";

export interface ILoadingViewProps {
    fields: IRepertoireField[];
}

const LoadingView: React.SFC<ILoadingViewProps> = ({ fields }) => {
    const loadingMessage = fields ? fields[0].data : '';
    return (
        <div className="loadingView">
            <div className="loadingViewText" > {loadingMessage} </div>
        </div>
    );
};

export default LoadingView;