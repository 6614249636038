import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IMembersPortalField } from "../../membersportal/types/IMembersPortalField";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { SizedRadiobuttonDataInput } from "../components/radioButtonDataInput/RadiobuttonDataInput";
import SearchView from "../components/searchView/SearchView";
import ActionButton from "../components/actionButton/ActionButton";
import { SizedTextDataInput } from "../components/textDataInput/TextDataInput";
import { ALL_ROLES, INTERESTED_PARTY_TYPE, SEARCH_VIEW_IPS, UPDATE_IPS_ROLE, VIEW_IPS_ROLE } from "../Consts";
import { IIPsSearchQuery } from "../types/IIPsSearchQuery";
import { IIPsSearchResult } from "../types/IIPsSearchResult";
import { IRepertoireField } from "../types/IRepertoireField";
import { IRepertoireStateKeys } from "../types/IRepertoireStateKeys";
import DisplayTableSearchResults from "../components/displayTableSearchResults/DisplayTableSearchResults";
import SearchViewKeyEventListener from "../components/searchView/SearchViewKeyEventListener";

export interface IIPsPageProps {
    ipsPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchIPs: (query: IIPsSearchQuery, isSameSearch?: boolean) => void;
    ipsSearchResults: IIPsSearchResult[];
    getIPDetails: (ipBaseNumber?: string, accountNumber?: string, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem,lookupValues?:ILookupDictionary) => void;
    addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    searchSuccessful: boolean;
    interestedPartyGeneralDataView: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    useNewUIgridIPSearchResults?: boolean;
    skip?: number;
}

const IPsPage = (props: IIPsPageProps) => {
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()
    const [filteredSearchResults, setFilteredSearchResualts] = React.useState<IRepertoireField[]>([]);

    const initialFormState = {
        ipNumber: '',
        fullName: '',
        accountNumber: '',
        interestedPartyType: ''
    }

    const [formData, setFormData] = React.useState(initialFormState);

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])

    React.useEffect(() => {
        const { searchResultsTableData } = props
        if (searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === SEARCH_VIEW_IPS

            );
            setFilteredSearchResualts(filteredFields);
        }

    }, [props.searchResultsTableData])
    const addNewAccount = (): void => {
        const { addNewAccount, interestedPartyGeneralDataView, lookupValues } = props;
        addNewAccount(lookupValues, true, interestedPartyGeneralDataView);
    }
    const checkIfLoaded = () => {
        const { ipsPageData } = props;

        if (ipsPageData.fields && ipsPageData.fields.length > 1 && !loaded) {
            setLoaded(true);
            const header = ipsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const {
        searchViewData,
        searchResultsTableData,
        searchIPs,
        ipsSearchResults,
        searchSuccessful,
        interestedPartyGeneralDataView,
        indexOfFirstResult,
        indexOfLastResult,
        resultsPerPage,
        currentPage,
        resetPagination,
        updatePagination,
        lookupValues,
        sortSearchResults,
        useNewUIgridIPSearchResults,
        skip
    } = props;

    const getIPDetails = (ipBaseNumber?: string, accountNumber?: string) => {
        props.getIPDetails(ipBaseNumber, accountNumber,interestedPartyGeneralDataView,lookupValues);
    }

    const getUpdateRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(UPDATE_IPS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(VIEW_IPS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const onClickSearch = (isFilter?: boolean, params?: IIPsSearchQuery, isSameSearch?: boolean) => {
        if(isFilter){
            let searchQuery: IIPsSearchQuery = {
                ipNumber: params.ipNumber,
                fullName: params.fullName,
                accountNumber: params.accountNumber,
                interestedPartyType: params.interestedPartyType,
                count: 50,
                skip: skip ?? 0
            };
            searchIPs(searchQuery, isSameSearch);
        }
        else {
            resetPagination(SEARCH_VIEW_IPS);
            let searchQuery: IIPsSearchQuery = {
                ipNumber: formData.ipNumber,
                fullName: formData.fullName,
                accountNumber: formData.accountNumber,
                interestedPartyType: formData.interestedPartyType,
                count: 50,
                skip: 0
            };
            searchIPs(searchQuery, isSameSearch);
        }
        
    }

    const keyDownSubmit = (value: any, fieldName: IRepertoireStateKeys) => {
        if (value.key === 'Enter') {
            onClickSearch(false);
        }
    }
    const changeData = (value: any, fieldName: IRepertoireStateKeys) => {
        setFormData(
            { ...formData, [fieldName]: value }
        )
    }


    const renderSearchFields = () => {
        const { searchViewData } = props;

        let filteredSearchFields = searchViewData.fields.filter(
            (searchField: IRepertoireField | IMembersPortalField) =>
                searchField.componentInstance === SEARCH_VIEW_IPS
        );

        let options = [
            { desc: ComponentsHelper.getFieldValueByName("legalEntity", searchViewData.fields), code: 'L' },
            { desc: ComponentsHelper.getFieldValueByName("naturalPerson", searchViewData.fields), code: 'N' },
            { desc: ComponentsHelper.getFieldValueByName("internalReference", searchViewData.fields), code: 'IR' },
            { desc: ComponentsHelper.getFieldValueByName("all", searchViewData.fields), code: '' },
        ]

        let libraryPublisher = { desc: ComponentsHelper.getFieldValueByName("libraryPublisher", searchViewData.fields), code: 'LP' }

        if (libraryPublisher.desc.length > 0) {
            options.splice(2, 0, libraryPublisher);
        }

        let filteredSearchFieldsMapper = (searchField: IRepertoireField, index: number): React.ReactElement => {
            let reactElement: React.ReactElement = <></>;

            if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal'))
                reactElement =
                    <div key={index}>
                        <SizedTextDataInput
                            fieldType={searchField.fieldType}
                            useEnterSubmit={true}
                            label={searchField.data}
                            fieldName={searchField.name}
                            changeData={(value, fieldName) => changeData(value, fieldName)}
                            handleKeyDown={keyDownSubmit}
                            value={formData[searchField.name]}
                            isHidden={searchField.hidden}
                        />
                    </div>;
            else if (searchField.name === INTERESTED_PARTY_TYPE)
                reactElement =
                    <div key={index}>
                        <SizedRadiobuttonDataInput
                            selectedValue={formData.interestedPartyType}
                            onSelectionChange={(option) => setFormData({ ...formData, interestedPartyType: option })}
                            radioName="interestedPartyType"
                            title={searchField.data}
                            options={options}
                        />
                    </div>

            return reactElement
        };

        return filteredSearchFields.map(filteredSearchFieldsMapper);
        
    }

    const handleSorting = (result) => {
        sortSearchResults(SEARCH_VIEW_IPS, result)
    }

    const onClickOpenIP = (tableContentItem: IIPsSearchResult) => {
        const { ipBaseNumber, accountNumber } = tableContentItem;
        getIPDetails(ipBaseNumber, accountNumber);
    }

    const resetForm = () => {
        setFormData(initialFormState);
    }

    if (loaded) {
    return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find IP</span>
                    <div className="newWorkButton" title="Create New Account" hidden={getUpdateRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewAccount}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                        New Account
                                    </div>
                        </div>
                    </div>
            </div>
            <div className="searchView">
                <div key='searchFields' className="row">
                    <SearchViewKeyEventListener setStateInitial={resetForm} />
                    {renderSearchFields()}
                </div>
                <div key='action' className="row">
                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                        <ActionButton buttonText="Search" buttonAction={()=>onClickSearch(false)} />
                    </div>
                </div>
            </div>
            {props.searchSuccessful && <div key='results' className="SearchResultsTable">
                <DisplayTableSearchResults
                    searchSuccessful={searchSuccessful}
                    searchResultsTableData={filteredSearchResults}
                    searchResults={ipsSearchResults}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    updatePagination={updateUserPagination}
                    lookupValues={lookupValues}
                    resetPagination={resetPagination}
                    sortSearchResults={handleSorting}
                    onClickOpen={onClickOpenIP}
                    changeData={changeData}
                    search ={onClickSearch}
                    accountNumber={formData.accountNumber}
                    accountName={formData.fullName}
                    ipNumber={formData.ipNumber}
                    useNewUIgridIPSearchResults={useNewUIgridIPSearchResults}
                    skip={skip}
                />
            </div>}
            </div>
        );
    }
    return <div>Loading ...</div>

       
}

export default IPsPage;