import { useEffect, useState } from "react";
import { NavItem, NavLink } from "reactstrap"

export const VIEW_SETTINGS = {
    compactView: "compactView", 
    comfortView: "comfortView"
}

const ViewPreferenceDropDown = () => {
    const [preferences, setPreferences] = useState(VIEW_SETTINGS.comfortView);


    useEffect(() => {
        const storedPreferences = localStorage.getItem('userPreferencesViewStyle');
        if (storedPreferences) {
          setPreferences(JSON.parse(storedPreferences));
          if (storedPreferences === `"${VIEW_SETTINGS.compactView}"` || storedPreferences === `${VIEW_SETTINGS.compactView}`) {
            document.body.classList.add('compactView');
            } else { 
                document.body.classList.remove('compactView');
            }
        }
      }, []);

    const savePreferences = (updatedPreferences) => {
        localStorage.setItem('userPreferencesViewStyle', JSON.stringify(updatedPreferences));
        setPreferences(updatedPreferences);
        if (updatedPreferences === VIEW_SETTINGS.compactView) {
                document.body.classList.add('compactView');
        } else {
            document.body.classList.remove('compactView');
        }
      };


    return (
        <ul className="nav navbar-nav">
        <NavItem className="dropdown" key={'viewPreference'}>
        <NavLink className="dropdown-toggle">
            {`Display`}
            <b className="caret"></b>
        </NavLink>
            <ul className="dropdown-menu" >
                        <NavItem key={VIEW_SETTINGS.comfortView}>
                            <NavLink onClick={() => savePreferences(VIEW_SETTINGS.comfortView)}>{preferences === VIEW_SETTINGS.comfortView ?
                            <>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none">
                            <g clipPath="url(#clip0_1_9)">
                            <path d="M9.07627 0.0123005V1.82793H21.789V0.0123005H9.07627ZM3.62059 -0.0124943C3.38465 -0.0101207 3.15889 0.0840459 2.99116 0.250008L0.269531 2.97691L1.54967 4.25706L2.73052 3.07973V15.844H4.53903V3.08151L5.71811 4.25706L6.99825 2.97691L4.2784 0.250008C4.19193 0.164414 4.08915 0.0969907 3.97615 0.0519009C3.86314 0.00681116 3.74223 -0.0150513 3.62059 -0.0124943ZM9.07627 9.09391V10.9094H21.789V9.09391H9.07627ZM9.07627 18.1736V19.9821H21.789V18.1736H9.07627Z"></path>
                            <path d="M4.26244 19.7216C4.09471 19.8876 3.86895 19.9817 3.63301 19.9841C3.51137 19.9867 3.39046 19.9648 3.27746 19.9197C3.16446 19.8746 3.06167 19.8072 2.97521 19.7216L0.255346 16.9947L1.53549 15.7146L2.71457 16.8901L2.71457 1.2766L4.52308 1.2766L4.52308 16.8919L5.70393 15.7146L6.98407 16.9947L4.26244 19.7216Z"></path>
                            </g>
                            <defs>
                            <clipPath id="clip0_1_9">
                            <rect width="21.7872" height="20" fill="white"></rect>
                            </clipPath>
                            </defs>
                        </svg>
                            <b>{"Comfortable (Default)"}</b></> :
                                    <>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none">
                                    <g clipPath="url(#clip0_1_9)">
                                    <path d="M9.07627 0.0123005V1.82793H21.789V0.0123005H9.07627ZM3.62059 -0.0124943C3.38465 -0.0101207 3.15889 0.0840459 2.99116 0.250008L0.269531 2.97691L1.54967 4.25706L2.73052 3.07973V15.844H4.53903V3.08151L5.71811 4.25706L6.99825 2.97691L4.2784 0.250008C4.19193 0.164414 4.08915 0.0969907 3.97615 0.0519009C3.86314 0.00681116 3.74223 -0.0150513 3.62059 -0.0124943ZM9.07627 9.09391V10.9094H21.789V9.09391H9.07627ZM9.07627 18.1736V19.9821H21.789V18.1736H9.07627Z"></path>
                                    <path d="M4.26244 19.7216C4.09471 19.8876 3.86895 19.9817 3.63301 19.9841C3.51137 19.9867 3.39046 19.9648 3.27746 19.9197C3.16446 19.8746 3.06167 19.8072 2.97521 19.7216L0.255346 16.9947L1.53549 15.7146L2.71457 16.8901L2.71457 1.2766L4.52308 1.2766L4.52308 16.8919L5.70393 15.7146L6.98407 16.9947L4.26244 19.7216Z"></path>
                                    </g>
                                    <defs>
                                    <clipPath id="clip0_1_9">
                                    <rect width="21.7872" height="20" fill="white"></rect>
                                    </clipPath>
                                    </defs>
                                </svg>
                            {"Comfortable (Default)"} </>}</NavLink>
                        </NavItem>
                        <NavItem key={VIEW_SETTINGS.compactView}>
                            <NavLink onClick={() => savePreferences(VIEW_SETTINGS.compactView)} className="">
                                
                                {preferences === VIEW_SETTINGS.compactView ?
                                <>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none">
                                <g clipPath="url(#clip0_1_3)">
                                <path d="M9.07627 0.0123005V1.82793H21.789V0.0123005H9.07627ZM3.62059 -0.0124943C3.38465 -0.0101207 3.15889 0.0840459 2.99116 0.250008L0.269531 2.97691L1.54967 4.25707L2.73052 3.07973V15.844H4.53903V3.08151L5.71811 4.25707L6.99825 2.97691L4.2784 0.250008C4.19193 0.164414 4.08915 0.0969907 3.97615 0.0519009C3.86314 0.00681116 3.74223 -0.0150513 3.62059 -0.0124943ZM9.07627 4.55661V6.36512H21.789V4.55661H9.07627ZM9.07627 9.09391V10.9094H21.789V9.09391H9.07627ZM9.07627 13.6293V15.445H21.789V13.6293H9.07627ZM9.07627 18.1736V19.9821H21.789V18.1736H9.07627Z" />
                                <path d="M4.26244 19.7216C4.09471 19.8876 3.86895 19.9817 3.63301 19.9841C3.51137 19.9867 3.39046 19.9648 3.27746 19.9197C3.16446 19.8746 3.06167 19.8072 2.97521 19.7216L0.255346 16.9947L1.53549 15.7146L2.71457 16.8901L2.71457 1.2766L4.52308 1.2766L4.52308 16.8919L5.70393 15.7146L6.98407 16.9947L4.26244 19.7216Z" />
                                </g>
                                <defs>
                                <clipPath id="clip0_1_3">
                                <rect width="21.7872" height="20" fill="white"/>
                                </clipPath>
                                </defs>
                                </svg>
                                <b>{"Compact"}</b></> :
                                               <>
                                               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none">
                                               <g clipPath="url(#clip0_1_3)">
                                               <path d="M9.07627 0.0123005V1.82793H21.789V0.0123005H9.07627ZM3.62059 -0.0124943C3.38465 -0.0101207 3.15889 0.0840459 2.99116 0.250008L0.269531 2.97691L1.54967 4.25707L2.73052 3.07973V15.844H4.53903V3.08151L5.71811 4.25707L6.99825 2.97691L4.2784 0.250008C4.19193 0.164414 4.08915 0.0969907 3.97615 0.0519009C3.86314 0.00681116 3.74223 -0.0150513 3.62059 -0.0124943ZM9.07627 4.55661V6.36512H21.789V4.55661H9.07627ZM9.07627 9.09391V10.9094H21.789V9.09391H9.07627ZM9.07627 13.6293V15.445H21.789V13.6293H9.07627ZM9.07627 18.1736V19.9821H21.789V18.1736H9.07627Z" />
                                               <path d="M4.26244 19.7216C4.09471 19.8876 3.86895 19.9817 3.63301 19.9841C3.51137 19.9867 3.39046 19.9648 3.27746 19.9197C3.16446 19.8746 3.06167 19.8072 2.97521 19.7216L0.255346 16.9947L1.53549 15.7146L2.71457 16.8901L2.71457 1.2766L4.52308 1.2766L4.52308 16.8919L5.70393 15.7146L6.98407 16.9947L4.26244 19.7216Z" />
                                               </g>
                                               <defs>
                                               <clipPath id="clip0_1_3">
                                               <rect width="21.7872" height="20" fill="white"/>
                                               </clipPath>
                                               </defs>
                                               </svg>{
                                "Compact"} </>}
                                
                                </NavLink>
                        </NavItem>
            </ul>
        </NavItem >
        </ul>
    )
}

export default ViewPreferenceDropDown;