import { IAgreementState } from "../../redux/types/IAgreementState";
import { IAgreementProductWorkDataItems } from "../types/IAgreementProductWorkDataItems";
import { isNullOrUndefined } from "../../util/tools";
import { isEmpty } from "lodash";

export class AgreementValidator {

    public static validateEffectiveDate(agreement: IAgreementState): boolean {
        return isNullOrUndefined(agreement.effectiveFrom) ? false : true;
    }

    public static validateAgreementCategory(agreement: IAgreementState): boolean {
        return isNullOrUndefined(agreement.agreementCategory) ? false : true;
    }

    public static invalidAgreementCarveOuts(carveOuts: IAgreementProductWorkDataItems[]): boolean {
        let badpools : IAgreementProductWorkDataItems[] = [];
        let ids: { [key: number]: string } = {};
        carveOuts.forEach((row) => {
            if ((!isEmpty(ids) && (!!(ids[row.productNumber.value] === "") != !!(row.workName.value === ""))) || ids[row.productNumber.value] === row.workName.value) {
                badpools.push(row);
            } else{
              ids[row.productNumber.value] = row.workName.value;
            }
          });
        return badpools.length > 0
    }

    public static validateAgreement(agreement: IAgreementState): string[] {
        let agreementIsValid: string[];
        let agreementUsagePools = agreement.agreementUsagePools || [];
        let applicableworks = agreement.agreementWorks || [];
        agreementIsValid = [];

        if (((agreement.agreementProductWorks.length > 0) || (agreementUsagePools.length > 0)) && !agreement.licensedDirect)
            agreementIsValid.push("agreementCarveOutDisabled");

        if (agreementUsagePools.length > 0 && agreement.agreementProductWorks.length === 0)
            agreementIsValid.push("agreementCarveOutEmpty")

        if(agreement.agreementProductWorks.length > 0 ){
            
            if (applicableworks.length > 0)
                agreementIsValid.push("specificAgreementCannotHaveWorksAndProductWorks")

            //TODO: EM 2024/06/24. We need to discuss with QA people if removing this validation will be ok for the extension to the Carve Out Logic
            //if (agreement.applicableWorksType != "INC")
            //    agreementIsValid.push("specificAgreementWithTypeExcludeCannotHaveUsagePoolsOrProductWorks")
        }

        if(AgreementValidator.invalidAgreementCarveOuts(agreement.agreementProductWorks))
            agreementIsValid.push("conflictingProducts")

        if (!AgreementValidator.validateEffectiveDate(agreement))
            agreementIsValid.push("agreementEffectiveFromValidationMessage");

        if (agreement.effectiveFrom && agreement.effectiveTo && (agreement.effectiveTo < agreement.effectiveFrom))
            agreementIsValid.push("agreementEffectiveTimesValidationMessage");

        if (!AgreementValidator.validateAgreementCategory(agreement))
            agreementIsValid.push("agreementCategoryValidationMessage");

        if (agreement.agreementAdminIP.length > 0) {
            let ipBaseNumbers = agreement.agreementAdminIP.map((ip) => ip.administeredByIPBaseNumber.value);
            if (ipBaseNumbers.includes(agreement.ipBaseNumber))
                agreementIsValid.push("agreementIPMatchingValidationMessage");
        }
        return agreementIsValid;
    }
}