import * as React from 'react';
import { Link } from "react-router-dom";
import { NavLink } from 'reactstrap';
import { If } from '../../../core/components/If';
import { IComponentDataItem } from '../../../core/types/IComponentDataItem';
import { ILookupInstance } from '../../../lookup/types/ILookup';
import {
    addFileToUpload, CleanUploadFilesState, editingChanged,
    fileMetadataChanged, matchNow, setInDropZone, setProgressUploadFileProccess, sortColumn, toggleSidebarCollapsed, validationMessageChanged
} from '../../../redux/reducers/DataIngestionReducer';
import { hideModal, showModal, showYesNoPrompt } from '../../../redux/reducers/ModalReducer';
import { addDroppedFile, cleanDroppedFilesRepertoireState, setProgressUploadFileRepertoireProccess } from '../../../redux/reducers/RepertoireReducer';
import { IAttachedFile } from '../../../redux/types/IAttachedFile';
import IDroppedFiles from '../../../redux/types/IDroppedFiles';
import IFile from '../../../redux/types/IFiles';
import { IJobState } from '../../../redux/types/IJobState';
import { IPaginationDetail } from '../../../redux/types/IPaginationDetail';
import { DataSource } from '../../../repertoire/types/DataSource';
import { ITreeData } from '../../../repertoire/types/ITreeData';
import { IDistribution } from "../../../repertoire/types/usageTypes/IDistribution";
import { IUsagePool } from '../../../repertoire/types/usageTypes/IUsagePool';
import { FileSystemHelper } from '../../services/FileSystemHelper';
import { StorageService } from '../../services/StorageService';
import { IFileSystem } from '../../types/IFileSystem';
import { IStoragePathItemData } from '../../types/IStoragePathItemData';
import { SaveState } from '../../types/SaveState';
import { StoragePathItem } from '../storagePathItem/StoragePathItem';
import {
    BATCH_ID_TABLE_HEADER_FIELD, CANCELLED_MESSAGE_FIELD,
    CANCEL_BUTTON_TEXT_FIELD,
    DESTINATION_TABLE_HEADER_FIELD, DISABLE_DRAG_AND_DROP,
    DISABLE_DRAG_AND_DROP_AGREEMENTS, DISABLE_DRAG_AND_DROP_PRODUCTS, DISTRIBUTION_CODE_TABLE_HEADER_FIELD, DRAG_AND_DROP_INSTRUCTION, ERROR_OBTAINING_SAS, ERROR_UPLOADING_FILE_SAS, FILENAME_TABLE_HEADER_FIELD, FILE_SIZE_TABLE_HEADER_FIELD, INPUT_FORMAT_TABLE_HEADER_FIELD, LAST_MODIFIED_DATE_TABLE_HEADER_FIELD, OBTAINED_SAS, OUTPUT_FORMAT_TABLE_HEADER_FIELD, PATH_TO_DROP_FILE, POOL_CODE_TABLE_HEADER_FIELD, PREVENT_REFRESH_FIELD, REMOVE, REQUEST_SAS, SAVE_BUTTON_TEXT_FIELD,
    SAVE_FAILED_MESSAGE_FIELD,
    SAVE_SUCCESSFUL_MESSAGE_FIELD, SOURCE_TABLE_HEADER_FIELD, UPLOADING_FILE_SAS, UPLOAD_COMPLETED, VALIDATION_ERROR_MESSAGE, RIGHTHOLDER_TABLE_HEADER_FIELD, SHOPPINGCART_TABLE_HEADER_FIELD, ATTACHMENT_TYPE_TABLE_HEADER_FIELD
} from './ContainerDetailsWindowFields';
import { clearModalSearchResults } from "../../../redux/reducers/RepertoireReducer";
import {
    addDroppedFile as mpAddDroppedFile,
    cleanDroppedFilesRepertoireState as mpCleanDroppedFilesRepertoireState,
    setProgressUploadFileRepertoireProccess as mpSetProgressUploadFileRepertoireProccess
} from "../../../redux/reducers/MembersPortalReducer";
import { ILookupDictionary } from '../../../lookup/types/ILookupDictionary';
import { ITabReduxItem } from '../../../redux/types/ITabReduxItem';
import { ContainerDetailsWindow } from './ContainerDetailsWindow';


interface ILivePerformanceContainerDetailsWindowProps {
    containerDetailsWindowComponentData: IComponentDataItem;
    storagePathItemData: IComponentDataItem;
    pageContentIsActive: boolean;
    storagePaths: IStoragePathItemData[];
    fileMetadataChanged?: typeof fileMetadataChanged;
    saveChanges?: (fileSystem: IFileSystem, storagePaths: IStoragePathItemData[], sources: ITreeData[]) => void;
    cancelChanges?: (fileSystem: IFileSystem, sources: ITreeData[]) => void;
    openDirectory?: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    refreshDirectory?: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    currentDirectory?: string;
    toggleSidebarCollapsed?: typeof toggleSidebarCollapsed;
    validationMessageChanged?: typeof validationMessageChanged;
    saveState?: SaveState;
    distributions?: IDistribution[];
    destinations?: ILookupInstance[];
    lookupValues?: ILookupDictionary;
    editingChanged?: typeof editingChanged;
    selectedFileSystem?: IFileSystem;
    jobs?: IJobState[];
    matchNow?: typeof matchNow;
    matchNowSubmit?: (fileSystem: IFileSystem, storagePaths: IStoragePathItemData[], sources: ITreeData[]) => void;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    isRepertoireModule?: boolean;
    addRepertoireAttachmentToState?: (attachedFile: IAttachedFile) => void;
    uploadAttachment?: (fileName: string, id: number, dataSource: DataSource) => Promise<any>;
    removeAttachmentIfExists?: (attachedFile: IAttachedFile, source: DataSource) => void;
    attachmentsDropped?: IDroppedFiles;
    addDroppedFileRepertoire?: typeof addDroppedFile | typeof mpAddDroppedFile;
    setProgressUploadFileRepertoire?: typeof setProgressUploadFileRepertoireProccess | typeof mpSetProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire?: typeof cleanDroppedFilesRepertoireState | typeof mpCleanDroppedFilesRepertoireState;
    agreementAttachmentsDropped?: IDroppedFiles;
    entityID?: number;
    entitySource?: DataSource;
    component?: string;
    removeAttachmentMaintenanceScreen?: (fullName: string, workId: number, dataSource: DataSource, guid: string, index: number) => void;
    deleteBlobFile?: (fullName: string, name: string) => Promise<boolean>;
    displayRemove?: boolean;
    paginationDetails?: IPaginationDetail[];
    updatePagination?: (resultsPerPage: number, currentPage: number, section: string) => void;
    sources?: ITreeData[];
    getMatchingSources?: () => void;
    pools?: IUsagePool[];
    undoUsageIngestion?: (fileName: string) => void;
    showYesNoPrompt?: typeof showYesNoPrompt;
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    clearModalSearchResults?: typeof clearModalSearchResults;
    dataIngestionToolbarData?: IComponentDataItem;
    roles?: string[];
    activeTab?: number;
    tabs?: ITabReduxItem[];
    isWorkAttachmentTypeNotRequired?: boolean;
    isWorkAttachmentTypeOptional?: boolean;
    isProductAttachmentTypeNotRequired?: boolean;
    isProductAttachmentTypeOptional?: boolean;
    currentUserNameNumber: string;
}

export class LivePerformanceContainerDetailsWindow extends ContainerDetailsWindow {

    constructor(props: ILivePerformanceContainerDetailsWindowProps) {
        super(props);
    }

    renderTableBody(showMetadata: boolean): JSX.Element {
        const {
            storagePaths,
            selectedFileSystem,
            fileMetadataChanged,
            editingChanged,
            distributions,
            openDirectory,
            storagePathItemData,
            jobs,
            destinations,
            isRepertoireModule,
            displayRemove,
            paginationDetails,
            updatePagination,
            sources,
            pools,
            undoUsageIngestion,
            showYesNoPrompt,
            hideModal,
            lookupValues,
            tabs,
            activeTab,
            currentDirectory,
            isWorkAttachmentTypeNotRequired,
            isWorkAttachmentTypeOptional,
            isProductAttachmentTypeNotRequired, 
            isProductAttachmentTypeOptional,
        } = this.props;

        if (storagePaths.length != 0) {
            for (var storageItem of storagePaths) {
                if (storageItem.metadata != null) {
                    for (var job of jobs) {
                        if (storageItem.metadata.jobID == job.jobID) {
                            storageItem.inProgress = true;
                        }
                    }
                }
            }
        }

        let sectionPagination: IPaginationDetail = updatePagination && paginationDetails.find(pd => pd.section === (selectedFileSystem ? selectedFileSystem.fullName : ''));
        if (updatePagination && sectionPagination == undefined) sectionPagination = paginationDetails[paginationDetails.push({ section: selectedFileSystem ? selectedFileSystem.fullName : '', currentPage: 1, resultsPerPage: 10 }) - 1];
        const { currentPage, resultsPerPage }: IPaginationDetail = sectionPagination || { section: selectedFileSystem ? selectedFileSystem.fullName : '', currentPage: 1, resultsPerPage: 10 };
        const currentStoragePaths = updatePagination ? storagePaths.slice(
            (currentPage - 1) * resultsPerPage,
            (currentPage) * resultsPerPage
        ) : storagePaths;

        if (currentStoragePaths) {
            return <tbody>
                {
                    currentStoragePaths.map((p: IStoragePathItemData, idx: number) => {
                        const isReadOnly = this.determineIsReadOnly(p.fullName);

                        return (<StoragePathItem
                            key={idx}
                            index={idx + ((currentPage - 1) * resultsPerPage)}
                            data={p}
                            openDirectory={openDirectory}
                            hasMetadata={showMetadata}
                            canEditMetadata={selectedFileSystem && selectedFileSystem.canEditMetadata}
                            fileMetadataChanged={fileMetadataChanged}
                            editingChanged={editingChanged}
                            distributions={distributions}
                            storagePathItemData={storagePathItemData}
                            matchNow={this.matchNowUpdate.bind(this)}
                            destinations={FileSystemHelper.filtered_destination_lookup(selectedFileSystem, destinations)}
                            isRepertoireModule={isRepertoireModule}
                            removeAttachmentFromMaintenanceScreen={this.removeAttachmentFromMaintenanceScreen.bind(this)}
                            deleteFile={this.deleteFile.bind(this)}
                            displayRemove={displayRemove}
                            sources={sources}
                            pools={pools}
                            undoUsageIngestion={undoUsageIngestion}
                            showYesNoPrompt={showYesNoPrompt}
                            hideModal={hideModal}
                            showSearchModal={this.showSearchModal}
                            isReadOnly={isReadOnly}
                            lookupValues={lookupValues}
                            activeTab={activeTab}
                            tabs={tabs}
                            currentDirectory={currentDirectory}
                            isWorkAttachmentTypeNotRequired={isWorkAttachmentTypeNotRequired}
                            isWorkAttachmentTypeOptional={isWorkAttachmentTypeOptional}
                            isProductAttachmentTypeNotRequired={isProductAttachmentTypeNotRequired}
                            isProductAttachmentTypeOptional={isProductAttachmentTypeOptional}
                            path={selectedFileSystem?.path }
                        />)
                    }
                    )}
            </tbody>
        }
        else {
            return <tbody></tbody>
        }
    }

    removeAttachmentFromMaintenanceScreen(fullName: string, guid: string, index: number) {
        const { removeAttachmentMaintenanceScreen, entityID, entitySource } = this.props;

        removeAttachmentMaintenanceScreen(fullName, entityID, entitySource, guid, index);
    }

    deleteFile(fullName: string, name: string) {
        const { deleteBlobFile, refreshDirectory, selectedFileSystem } = this.props;

        deleteBlobFile(fullName, name).then((res: boolean) => {
            refreshDirectory(selectedFileSystem, this.props.sources);
        })
    }

    uploadFile(newFile: IFile, path: string, file: any) {
        const {
            addFileToUpload, 
            setProgressUploadFileProccess,
            setInDropZone
        } = this.props;

        addFileToUpload(newFile, true);

        setInDropZone(false);

        setProgressUploadFileProccess(this.viewData[REQUEST_SAS], newFile, false);

        return Promise.resolve(
            StorageService.getUploadUri(path, true).then(
                (uri: string) => {
                    if (uri && uri.length > 0) {
                        setProgressUploadFileProccess(this.viewData[OBTAINED_SAS], newFile, false);
                        setProgressUploadFileProccess(this.viewData[UPLOADING_FILE_SAS], newFile, false);
                    
                        return StorageService.uploadFile(uri, file).then(
                            (res: boolean) => {
                                if (res) {
                                    setProgressUploadFileProccess(this.viewData[UPLOAD_COMPLETED], newFile, true);
                                    return true;
                                }
                            },
                            reject => {
                                setProgressUploadFileProccess(this.viewData[ERROR_UPLOADING_FILE_SAS], newFile, true);

                                return false;
                            }
                        )
                            .catch(error => {
                                return false;
                            })
                    }
                },
                reject => {
                    setProgressUploadFileProccess(this.viewData[ERROR_OBTAINING_SAS], newFile, true);

                    return false;
                }
            )
        )
    }

    handleDrop = e => {
        const {
            cleanUploadFilesState,
            currentUserNameNumber,
            selectedFileSystem,
            sources,
            addRepertoireAttachmentToState,
            removeAttachmentIfExists,
            entitySource
        } = this.props;

        const files = [...e.dataTransfer.files];

        cleanUploadFilesState();

        files.forEach(file => {

            let newFile: IFile = {
                message: '',
                name: file.name,
                size: file.size,
                isInProgress: true
            }
            const fileToUpload: IFile = { ...newFile };
            fileToUpload.name = crypto.randomUUID();
            let fullPath: string = "";
            
            fullPath = `repertoireAttachments/usage/${fileToUpload.name}`;

            this.uploadFile(newFile, fullPath, file).then(
                (uploaded: boolean) => {
                    let finalFile:IAttachedFile={
                        fileName: file.name,
                        guid:fileToUpload.name
                    };
                    if (!uploaded) {
                        removeAttachmentIfExists(finalFile, entitySource);
                    }
                    else {                        
                        addRepertoireAttachmentToState(finalFile);
                    }
                }
            )
        });
        
        e.preventDefault();
    };

    renderStageBox(): JSX.Element {
        const {
            droppedFiles, 
            cleanUploadFilesState
        } = this.props;

        return (
            <If condition={droppedFiles && droppedFiles.files && droppedFiles.files.length > 0}>
                <div className="row">
                    <div className="col-md-12 alert alert-success alert-dismissible fade in">
                        <NavLink tag={Link} to="#" className="close" data-dismiss="alert" aria-label="close" onClick={cleanUploadFilesState} >&times;</NavLink>
                        {this.renderUploadStages(droppedFiles.files)}
                    </div>
                </div>
            </If>
        )  
    }
}