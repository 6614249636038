import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import SearchView from "../components/searchView/SearchView";
import {
    ALL_ROLES,
    SEARCH_VIEW_LICENSES,
    UPDATE_LICENSE_ROLE,
    VIEW_LICENSE_ROLE
} from "../Consts";
import { IShoppingCartSearchResult } from "../types/IShoppingCartSearchResult";
import { IShoppingCartSearchQuery } from "../types/IShoppingCartSearchQuery";
import { IShoppingCart } from "../types/IShoppingCart";
import { ILicenseInputItem } from "../../redux/types/ILicenseInputItem";

export interface ILicensesPageProps {
    licensesPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchLicenses: (query: IShoppingCartSearchQuery) => void;
    licensesSearchResults: IShoppingCart[];
    searchSuccessful: boolean;
    licenseMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getLicenseDetails: (licenseID: number, lookupValues: ILookupDictionary,licenseRequestWorkParameterFields: ILicenseInputItem[]) => void;
    addNewLicense: (lookups: ILookupDictionary,licenseRequestWorkParameterFields: ILicenseInputItem[], isNew?: boolean, licenseMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
}

const LicensesPage = (props: ILicensesPageProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])

    const checkIfLoaded = () => {
        const { licensesPageData } = props;

        if (licensesPageData.fields && licensesPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = licensesPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const addNewLicense = (): void => {
        const { addNewLicense, lookupValues, licenseMaintenanceGeneralDataViewData } = props;
        addNewLicense(lookupValues,[], true, licenseMaintenanceGeneralDataViewData);
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const getUpdateRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(UPDATE_LICENSE_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(VIEW_LICENSE_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const {
        searchViewData,
        searchResultsTableData,
        searchLicenses,
        licensesSearchResults,
        searchSuccessful,
        indexOfFirstResult,
        indexOfLastResult,
        resultsPerPage,
        currentPage,
        resetPagination,
        updatePagination,
        getLicenseDetails,
        sortSearchResults
    } = props;

    let { lookupValues } = props;

    if (loaded) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find License</span>
                        <div className="newWorkButton" title="Create New License" hidden={getUpdateRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewLicense}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                New License
                            </div>
                        </div>
                    </div>
                </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_LICENSES}
                    modalProps={undefined}
                    dataSource={undefined}
                    hideModal={undefined}
                    lookupValues={lookupValues}
                    searchLicenses={searchLicenses}
                    licensesSearchResults={licensesSearchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    getLicenseDetails={getLicenseDetails}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={getViewRoleDisabled()}
                />
            </div>
        );
    }

    return <div>Loading ...</div>
}

export default LicensesPage;