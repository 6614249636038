import * as React from 'react';
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import SearchView from "../components/searchView/SearchView";
import { searchDataChanged } from "../../redux/reducers/RepertoireReducer";
import { ALL_ROLES, SEARCH_VIEW_CLAIMS, UPDATE_CLAIMS_ROLE, VIEW_CLAIMS_ROLE } from "../Consts";
import { IClaimSearchResult } from "../types/IClaimSearchResult";
import { IClaimSearchQuery } from "../types/IClaimSearchQuery";
import { useMsal } from '@azure/msal-react';
import { ComponentsHelper } from '../../core/services/ComponentHelper';
import { msalConfig } from '../../accounts/types/msalAuthConfig';
import { IDistributionType } from '../types/usageTypes/IDistibutionType';
import { DISTRIBUTION_TYPE_LOOKUP } from '../../lookup/Consts';
import { ILookupInstance } from '../../lookup/types/ILookup';

export interface IClaimsPageProps {
    claimsPageData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    currentPage?: number;
    claimsGeneralDataView: IRepertoireComponentDataItem;
    addNewClaim: (lookups: ILookupDictionary, isNew?: boolean, claimMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => void;
    searchDataChanged: typeof searchDataChanged;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchClaims: (query: IClaimSearchQuery) => void;
    claimsSearchResults: IClaimSearchResult[];
    searchSuccessful: boolean;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    distributionTypes: IDistributionType[];
    getClaimDetails: (claimID: number, lookupValues: ILookupDictionary) => void;

}

export const ClaimsPage = (props: IClaimsPageProps) => {
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()

    const addNewClaim = (): void => {
        const { addNewClaim, lookupValues, claimsGeneralDataView } = props;
        addNewClaim(lookupValues, true, claimsGeneralDataView);
    }

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const checkIfLoaded = () => {
        const { claimsPageData } = props;

        if (claimsPageData.fields && claimsPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = claimsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }
    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const {
        searchViewData,
        searchResultsTableData,
        searchClaims,
        claimsSearchResults,
        searchSuccessful,
        indexOfFirstResult,
        indexOfLastResult,
        resultsPerPage,
        currentPage,
        resetPagination,
        updatePagination,
        getClaimDetails,
        sortSearchResults,
    } = props;

    const getUpdateRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(UPDATE_CLAIMS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(VIEW_CLAIMS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const addDistributionTypeToLookup = (lookupValues: ILookupDictionary): ILookupDictionary => {
        const {
            distributionTypes
        } = props;

        if (distributionTypes === undefined) return lookupValues;
        else if (lookupValues[DISTRIBUTION_TYPE_LOOKUP] === undefined) {
            const lookups: ILookupInstance[] = [];
            distributionTypes.forEach((dt, index) => {
                const lookup: ILookupInstance = {
                    typeID: dt.distributionTypeID,
                    code: dt.description,
                    description: dt.description
                };
                lookups.push(lookup);
            });
            lookupValues[DISTRIBUTION_TYPE_LOOKUP] = {
                entity: DISTRIBUTION_TYPE_LOOKUP,
                lookups: lookups,
                defaultValue: null
            };
            return lookupValues;
        }

        return lookupValues;
    }

    let { lookupValues } = props;
    lookupValues = addDistributionTypeToLookup(lookupValues);

    if (loaded) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Claim</span>
                        <div className="newWorkButton" title="Create New Claim" hidden={getUpdateRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewClaim}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                New Claim
                            </div>
                        </div>
                    </div>
                </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_CLAIMS}
                    modalProps={undefined}
                    dataSource={undefined}
                    hideModal={undefined}
                    lookupValues={lookupValues}
                    searchClaims={searchClaims}
                    claimSearchResults={claimsSearchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updatePagination}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={getViewRoleDisabled()}
                    getClaimDetails={getClaimDetails}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default ClaimsPage;