import React from "react";
import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import IShortCutKeys from "../../../redux/types/IShortCutKeys";

export interface IListenToShortCutKeysDataGridTableComponentProps {
    showSearchModal: () => void;
    addRow: () => void;
    deleteRow: () => void;
    handleClickOutside: (event: any) => void;
};
export interface IStateProps {shortCutKeys: IShortCutKeys[]}

export const DO_NOT_SHOW_MODAL = "DO_NOT_SHOW_MODAL";

type IListenToShortCutKeysDataGridTableConnectProps = IListenToShortCutKeysDataGridTableComponentProps & IStateProps;

const ListenToShortCutKeysDataGridTable: React.FC<IListenToShortCutKeysDataGridTableConnectProps> = ({
    shortCutKeys,
    showSearchModal,
    addRow,
    deleteRow,
    handleClickOutside,
}) => {
    const [keysPressed, setKeysPressed] = React.useState([]);
    const areCorrectKeysPressed = (actionCode, keysPressed) => {
        const shortCutKeysCommaSeparatedString = shortCutKeys && shortCutKeys.find(key => key.actionCode === actionCode)?.key
        const shortCutKeysArray = shortCutKeysCommaSeparatedString && shortCutKeysCommaSeparatedString.split(',');
        let filteredRequiredPressedKeys = shortCutKeysArray && shortCutKeysArray.filter(keyString => keysPressed[keyString]);
        return filteredRequiredPressedKeys?.length === shortCutKeysArray?.length;
    }

    const showSearchView  = (e) => {     
    if (areCorrectKeysPressed("SEARCH_CURRENT_GRID_ROW", keysPressed)){
        showSearchModal()
      }
    }

    const addTableRow  = (e) => {     
        if (areCorrectKeysPressed("ADD_NEW_IN_GRID", keysPressed)){
            addRow()
          }
        }
        const deleteTableRow = (e) => {     
            if (areCorrectKeysPressed("DELETE_GRID_ROW", keysPressed)){
                e.preventDefault();
                deleteRow()
              }
            }

    const setTheKeyPressTrue = (event) => {
        const newKeyPress = keysPressed;
        newKeyPress[event.key] = true;
        setKeysPressed(newKeyPress);
    }

    const setTheKeyPressFalse = (event) => {
        const newKeyPress = keysPressed;
        newKeyPress[event.key] = false;
        setKeysPressed(newKeyPress);
    }

 
    React.useEffect(() => {
        
        window.addEventListener('keydown', (event) => {
            setTheKeyPressTrue(event);
         });
         document.addEventListener('click', handleClickOutside);
           window.addEventListener('keydown', showSearchView)
           window.addEventListener('keydown', addTableRow)
           window.addEventListener('keydown', deleteTableRow)
 
           window.addEventListener('keyup', (event) => {
            setTheKeyPressFalse(event)
          });

          return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('keydown', showSearchView);
            window.removeEventListener('keydown', addTableRow);
            window.removeEventListener('keydown', deleteTableRow);
            window.removeEventListener('keyup', (event) => {
                setTheKeyPressFalse(event)
             });
             window.removeEventListener('keydown', (event) => {
                setTheKeyPressTrue(event)
             });
          }


    }, [])

return <></>;

}

export default connect((state: IAppState, props: IListenToShortCutKeysDataGridTableComponentProps) => ({
    shortCutKeys: state.repertoire.shortCutKeys,
}), null)(ListenToShortCutKeysDataGridTable);
