import { ILookupInstance } from "../../../lookup/types/ILookup";

export const convertToDropdownDataInputProps = (options: Array<{ code: string, description: string }>): ILookupInstance[] => {

    let dropDownOptions: Array<ILookupInstance>
    dropDownOptions = [];

    if (options === undefined) {
        return dropDownOptions;
    }

    for (let key in options) {

        let lookupInstance: ILookupInstance = {
            typeID: Number(key),
            code: key,
            description: String(options[key]),
            additionalData: null,
            extraFields: null
        };

        dropDownOptions.push(
            lookupInstance
        );
    }

    return dropDownOptions;
}