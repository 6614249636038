import React from "react";

const UnpaidClaimsInvalidDateAlert = ({
    text,
    handleCloseAlert,
}) => {

     return <div className="col-md-12 alert alert-dismissible fade in alert-danger">
                <div>
                    <a className="close" aria-label="close" onClick={handleCloseAlert}>&times;</a>
                    {text}
                </div>
            </div>;
}

export default UnpaidClaimsInvalidDateAlert;