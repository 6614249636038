import * as React from 'react';
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";


export const B2CUnauthenticated = () => {
    const { instance } = useMsal();

    useEffect(() => {
        instance.loginRedirect();
    }, []);

    return (
        <></>
    );
}