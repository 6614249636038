import React, { FunctionComponent, PureComponent } from "react";
import { CHECKBOX_INPUT, REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";

export interface IToggleInputProps {
    label?: string;
    value?: any;
    changeData: (value: string | boolean, fieldName: IRepertoireStateKeys) => void;
    fieldName: IRepertoireStateKeys;
    readonly?: boolean;
    disabled?: boolean;
}


export const ToggleButtonDataInput: FunctionComponent<IToggleInputProps> = (props: IToggleInputProps) => {
    return <div className={"form-group col-md-6 col-sm-6 col-xs-12"}>
    <ToggleInput {...props} />
</div>
}



class ToggleInput extends PureComponent<IToggleInputProps> {
    constructor(props: IToggleInputProps) {
        super(props);
    }

    render() {
        const { label, changeData, value, fieldName, readonly, disabled } = this.props;
        return (
            <div className={"toggleSwitchContacontainer"}>
                {label ? <label>{label}:</label> : null}

                <input
                    type={CHECKBOX_INPUT}
                    id={fieldName}
                    checked={!value}
                    onChange={event => changeData(event.target.value, fieldName)}
                    readOnly={readonly}
                    disabled={disabled}
                    data-testid={fieldName}
                    />
                    <label htmlFor={fieldName}>Toggle</label>
            </div>
        );
    }
}