import * as React from "react";
import { hideModal, showModal, updateComment } from "../../../redux/reducers/ModalReducer";
import { IComponentField } from "../../../core/types/IComponentField";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { getCWRAckHWM, getManualJobDataSources, triggerManualJob } from "../../../redux/thunks/HomeThunks";
import { IAckHWMState } from "../../types/IAckHWMState";
import { IDataSourceBase } from "../../types/JobParameterControls/IDataSource";
import IHomeComponentData from "../../types/IHomeComponentData";
import { JOBS_IN_PROGRESS_MODAL, LOADING_VIEW, REPERTOIRE_PAGE_JOBS, SEARCH_VIEW, SHORTCUT_KEY_MODAL } from "../../../repertoire/Consts";
import SearchView from "../../../repertoire/components/searchView/SearchView";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ICwrgParameters } from "../../../redux/types/ICwrgParameters";
import { IContributorSearchQuery } from "../../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../../repertoire/types/IContributorSearchResult";
import ShortcutKeyModalView from "../../../repertoire/components/shortcutKeyModalView/ShortcutKeyModalView";
import JobsInProgressModal from "../../../repertoire/components/jobsInProgressModal/JobsInProgressModal";

export interface IHomeModalProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    updateModalComment: typeof updateComment;
    modalTitle: string;
    modalMessage: string;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerModalPage?: number;
    currentModalPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    component?: string;
    modalContent: string;
    modalProps: any;
    displayModalCloseButton: boolean;
    lookups: ILookupDictionary;
    manualJobType: string;
    manualJobParameters: any;
    triggerManualJob: typeof triggerManualJob;
    cwrAckHWM: IAckHWMState[];
    getCWRAckHWM: typeof getCWRAckHWM;
    fetchManualJobDataSources: typeof getManualJobDataSources;
    manualJobDataSources: { [key: string]: IDataSourceBase; }
    componentData: IHomeComponentData;
    getPaymentRunIDs: () => void;
    paymentRunIDs: any;

    searchViewData?: IRepertoireComponentDataItem;
    modalComponentInstance?: string;
    dataSource?: string;
    searchResultsTableData?: IRepertoireComponentDataItem;
    searchContributors?: (
        query: IContributorSearchQuery
    ) => void;
    contributorsSearchResults?: IContributorSearchResult[];
    searchSuccessful?: boolean;
    searchViewResultsPerPage?: number;
    searchViewCurrentPage?: number;
    updateManualJobParams: (params: any) => void;

}

export interface IShowFieldSettingViewModalProps {
    componentViewData: IComponentField;
    componentInstance: string;
    dataGridViewData: IComponentField;
    onClickSave: () => void;
    inverted?: boolean;
}


const HomeModal: React.SFC<IHomeModalProps> = ({
    showModal,
    hideModal,
    updateModalComment,
    modalTitle,
    modalMessage,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerModalPage,
    currentModalPage,
    resetPagination,
    updatePagination,
    component,
    modalContent,
    modalProps,
    displayModalCloseButton,
    lookups,
    manualJobType,
    manualJobParameters,
    triggerManualJob,
    cwrAckHWM,
    getCWRAckHWM,
    fetchManualJobDataSources,
    manualJobDataSources,
    componentData,
    getPaymentRunIDs,
    paymentRunIDs,

    searchViewData,
    modalComponentInstance,
    dataSource,
    searchResultsTableData,
    searchContributors,
    contributorsSearchResults,
    searchSuccessful,
    searchViewResultsPerPage,
    searchViewCurrentPage,
    updateManualJobParams
}) => {
    const renderModalContent = () => {
        switch (modalContent) {
            case SEARCH_VIEW: {
                const updateJobParams = (params: any) => {
                    updateManualJobParams(params)
                };
                return (
                    <SearchView
                        searchViewData={searchViewData}
                        componentInstance={modalComponentInstance}
                        modalProps={modalProps}
                        dataSource={dataSource}
                        searchResultsTableData={searchResultsTableData}
                        searchContributors={searchContributors}
                        updateManualJobParams={updateJobParams}
                        contributorsSearchResults={contributorsSearchResults}
                        hideModal={hideModal}
                        searchSuccessful={searchSuccessful}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={searchViewResultsPerPage}
                        currentPage={searchViewCurrentPage}
                        resetPagination={resetPagination}
                        updatePagination={updatePagination}
                        manualJobParameters={manualJobParameters}
                    />
                );
            }
            default:
                return null;
        }
    };

    const renderMessage = () => {
        if (modalMessage != undefined) {
            return (<div>{modalMessage}</div>);
        }
    }

    const renderModal = () => {
        const styleModalContent = "modalBody";
        if (modalContent === LOADING_VIEW) {
            return <div className="loaderWrapper">
            <div className="spinner-dualball">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        }
        if (modalContent === SHORTCUT_KEY_MODAL) {
            return <div className="repertoireModal"> <ShortcutKeyModalView hideModal={hideModal} /></div>
        }
        if (modalContent === JOBS_IN_PROGRESS_MODAL) {
            return <div className="repertoireModal"> <JobsInProgressModal hideModal={hideModal} jobsToDisplay={REPERTOIRE_PAGE_JOBS} /></div>
        }
        return (
            <div className="repertoireModal">
                <div className="modal">
                    <div>
                        {displayModalCloseButton && (
                            <div className="closeButton" onClick={() => hideModal()}>
                                <img src={"assets/close.svg"} className="closeIcon" />
                            </div>
                        )}
                    </div>
                    <div className="fixedPart" />
                    <div className="dynamicPart">
                        <div className="title">{modalTitle}</div>
                        <div className="modalBody">{renderMessage()}</div>
                        <div className={styleModalContent}>{renderModalContent()}</div>
                    </div>
                    <div className="fixedPart" />
                </div>
            </div>
        );
    }

    return (
        renderModal()
    );
};

export default HomeModal;
