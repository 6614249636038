import * as React from "react";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { createCopyOfTableContents } from "../../services/TableFunctions";
import PaginationView from "../../../repertoire/components/paginationView/PaginationView";
import DisplayTable from "../displayTable/DisplayTable";
import DisplayText from "../text/DisplayText";
import { IIPsSearchResult } from "../../types/IIPsSearchResult";
import { ResultsTable } from "../ipComponents/ResultsTable";
import { SEARCH_VIEW_IPS } from "../../Consts";
import { IIPsSearchQuery } from "../../types/IIPsSearchQuery";


interface ISearchResultsPageProps {
    searchSuccessful: boolean;
    searchResultsTableData: IRepertoireField[];
    searchResults: IIPsSearchResult[];
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    sortSearchResults: (results: any) => void;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    onClickOpen?: (tableContentItem: IIPsSearchResult) => void;
    changeData?: (value: any, fieldName: string) => void;
    search?: (isFilter?: boolean, params?: IIPsSearchQuery, isSameSearch?: boolean) => void;
    continuationToken?: string;
    accountNumber?:string;
    accountName?:string;
    ipNumber?:string;
    useNewUIgridIPSearchResults?: boolean;
    skip?: number
}

const DisplayTableSearchResults: React.FC<ISearchResultsPageProps> = ({
    searchSuccessful,
    searchResultsTableData,
    searchResults,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    lookupValues,
    sortSearchResults,
    onClickOpen,
    changeData,
    search,
    continuationToken,
    accountNumber,
    accountName,
    ipNumber,
    useNewUIgridIPSearchResults,
    skip
}) => {
    const [indexOfFirstResultLocal, setIndexOfFirstResult] = React.useState(indexOfFirstResult);
    const [indexOfLastResultLocal, setIndexOfLastResult] = React.useState(indexOfLastResult);
    const [resultsPerPageLocal, setResultsPerPage] = React.useState(resultsPerPage);
    const [currentPageLocal, setCurrentPage] = React.useState(currentPage);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [activeSortHeaderSection, setActiveHeaderSection] = React.useState('');
    const [isAscending, setIsAssending] = React.useState(false);

    React.useEffect(() => {
      if (searchResults) {
          const currentResults = searchResults.slice(
              indexOfFirstResultLocal,
              indexOfLastResultLocal
        );
        useNewUIgridIPSearchResults ? setCurrentTableData(searchResults) : setCurrentTableData(currentResults);
        setCurrentPage(1)
      }
    }, [searchResultsTableData, searchResults])

    const updateContentsTable = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        setIndexOfFirstResult(indexOfFirstResult);
        setIndexOfLastResult(indexOfLastResult);
        setResultsPerPage(resultsPerPage);
        setCurrentPage(currentPage);
        const currentResults = searchResults.slice(
            indexOfFirstResult,
            indexOfLastResult
        );
        useNewUIgridIPSearchResults ? setCurrentTableData(searchResults) : setCurrentTableData(currentResults);
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
    }

    const sortTableByColumn = (section: string) => {
        const tableContentsCopy = createCopyOfTableContents(currentTableData);

        if (activeSortHeaderSection !== section) {
            setIsAssending(false);
        }

        tableContentsCopy.sort((a: any, b: any) => {
            let newA = a[section] || "";
            let newB = b[section] || "";

            newA = newA ? newA : "";
            newB = newB ? newB : "";

            if (isAscending) {
                return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
            }
            return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });
        });


        setCurrentTableData(tableContentsCopy);
        setActiveHeaderSection(section);


        setIsAssending(!isAscending);

        if (sortSearchResults) {
            sortSearchResults(tableContentsCopy);
        }
    };

    if (searchSuccessful && (searchResultsTableData && searchResultsTableData.length > 0) && searchResults) {

        return <div className="row">
            <div className="col-md-12 searchResultsDiv">
                <div className="SearchResultsTable">
                    {!useNewUIgridIPSearchResults&&<PaginationView
                        key='pagination1'
                        contentsLength={searchResults.length}
                        resultsPerPage={resultsPerPageLocal}
                        currentPage={currentPageLocal || 1}
                        updateContentsTable={updateContentsTable}
                repertoireSection={SEARCH_VIEW_IPS}
                
                    />}

                {!useNewUIgridIPSearchResults&&<DisplayTable
                        tableContents={currentTableData}
                        dataGridTableData={searchResultsTableData}
                        isSearchResultTable={true}
                        activeSortHeaderSection={activeSortHeaderSection}
                        isAscending={isAscending}
                        onClickOpen={onClickOpen}
                        sortTableByColumn={sortTableByColumn}
                    />}
              {useNewUIgridIPSearchResults &&
                <ResultsTable 
                    searchResultsTableData={searchResultsTableData} 
                    tableContents={currentTableData} 
                    onClickOpen={onClickOpen}
                    updatePagination={updatePagination}
                    changeData={changeData}
                    search={search}
                    resultsPerPage={resultsPerPageLocal}
                    indexOfFirstResult={indexOfFirstResultLocal}
                    indexOfLastResult={indexOfLastResultLocal}
                    currentPage={currentPageLocal}
                    updateContentsTable={updateContentsTable}
                    repertoireSection={SEARCH_VIEW_IPS}
                    continuationToken={continuationToken}
                    accountNumber={accountNumber}
                    accountName={accountName}
                    ipNumber={ipNumber}
                    searchResults={searchResults}
                    skip={skip}
                    resetPagination={resetPagination}
              />
                  }
                    {!useNewUIgridIPSearchResults&&<PaginationView
                        key='pagination2'
                        contentsLength={searchResults.length}
                        resultsPerPage={resultsPerPageLocal}
                        currentPage={currentPageLocal || 1}
                        updateContentsTable={updateContentsTable}
                        repertoireSection={SEARCH_VIEW_IPS}
                    />}
                </div>
            </div>
        </div>
    }

    return <DisplayText text={'No search result'} />
}

export default DisplayTableSearchResults