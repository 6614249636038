import * as React from "react";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { LICENSE_BILLING_KEY, LICENSE_USER_TYPE_STATE_KEY, LICENSE_GEOGRAPHICAL_AREA_TYPE_STATE_KEY, LICENSE_SECTOR_TYPE_STATE_KEY, LICENSE_USES_KEY, LICENSE_USES_TYPE_STATE_KEY, LICENSE_WORKS_KEY, LICENSE_COMMERCIAL_USE_TYPE_STATE_KEY, LICENSE_LICENSEE_NAME_STATE_KEY, LICENSE_LICENSEE_ADDRESS_STATE_KEY, LICENSE_USE_DETAILS_FOR_BILLING_STATE_KEY, LICENSE_BILLING_NAME_STATE_KEY, LICENSE_BILLING_ADDRESS_STATE_KEY, LICENSE_SIGNER_NAME_STATE_KEY, LICENSE_WORK_DATAGRID_VIEW } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { LICENSING_USES_LOOKUP, SET_TYPE_LOOKUP } from "../../../../lookup/Consts";
import ActionButton from "../../../components/actionButton/ActionButton";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import { ILicenseUserSearchResult } from "../../../types/ILicenseUserSearchResult";
import { addWorkToLicenseRequest, updateLicenseField, updateLicenseRequestWorkItem } from "../../../../redux/reducers/RepertoireReducer";
import { ILicenseUserSearchQuery } from "../../../types/ILicenseUserSearchQuery";
import { showModal } from "../../../../redux/reducers/ModalReducer";
import { LicenseMaintenanceWorkGrid } from "./grids/LicenseMaintenanceWorkGrid";
import { ILicenseStateKeys, IShoppingCartState } from "../../../../redux/types/IShoppingCartState";
import { ILicenseInputItem } from "../../../../redux/types/ILicenseInputItem";
import { ILicenseRequestWorkItem } from "../../../../redux/types/ILicenseRequestWorkItem";
import { ILicenseRequestItem } from "../../../../redux/types/ILicenseRequestItem";
import { EMPTY_STRING_VALUE } from "../../../../membersportal/Consts";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";

export interface ILicenseMaintenanceGridsViewProps {
    licenseMaintenanceGridsViewData: IRepertoireComponentDataItem,
    shoppingCart: IShoppingCartState,
    dataGridTableData?: IRepertoireComponentDataItem;
    searchResultTableData?: IRepertoireComponentDataItem;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    isNewShoppingCart?: boolean;
    shoppingCartParameters?: any;
    updateLicenseField: typeof updateLicenseField;
    searchLicenseUser?: (query: ILicenseUserSearchQuery) => void;
    crmUsers?: ILicenseUserSearchResult[];
    indexOfFirstResult?:    number;
    indexOfLastResult?:     number;
    resultsPerPage?:    number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    showModal: typeof showModal;
    addWorkToLicenseRequest: typeof addWorkToLicenseRequest;
    updateLicenseRequestWorkItem: typeof updateLicenseRequestWorkItem;
    getLicenseRequestWorkFields: (commercialUseToken: number, geographicalToken: number, sectorToken: number, userTypeToken: number, useTypeToken: string, workID: number, licenseRequestWorkID: number) => void;
    licenseRequestWorkParameterFields?: ILicenseInputItem[];
    licenseRequest?: ILicenseRequestItem;
    getWorksContributors: (workIds: number[]) => void;
    licenseWorksPricesImport: (licenseWorksPriceImport: any[]) => void;
    activeTab: number;
    tabs: ITabReduxItem[];
    licenseCalculateWorkPrice: (shoppingCart: IShoppingCartState, selectedLicenseRequestWorks: ILicenseRequestWorkItem[], licenseRequestWorkParameterFields: ILicenseInputItem[],
        licenseRequest: ILicenseRequestItem, lookupValues: ILookupDictionary, isModified: boolean) => void;
    tempLicenseWorkRows: ILicenseRequestWorkItem[];
    updateLicenseRequestWorkItemToLocalState: (licenseRequestWorks: ILicenseRequestWorkItem[]) => void;
    getWorkDetails: (dataSource: string, workID: number) => void;
    changesMade: boolean;
}

interface ILicenseMaintenanceGridsViewState {
    activeHeaderSection: string;
    loaded: boolean;
    isUsesGridModified: boolean;
}
export class LicenseMaintenanceGridsView extends React.Component<ILicenseMaintenanceGridsViewProps, ILicenseMaintenanceGridsViewState>{

    viewData;
    constructor(props: ILicenseMaintenanceGridsViewProps) {
        super(props);
        this.state = {
            activeHeaderSection: LICENSE_USES_KEY,
            loaded: false,
            isUsesGridModified: false
        };
        this.viewData = {};
    }

    componentDidUpdate() {
        
        this.loadData();
    }

    componentDidMount() {
        const {
            licenseMaintenanceGridsViewData: { fields },
            shoppingCart
        } = this.props
        shoppingCart.licenseRequests[0]?.licenseRequestWorks?.forEach(tlwr => {
            if (tlwr.workID !== 0 && tlwr.licenseRequestWorkID !== 0) { this.getDynamicColumns(tlwr); }
        });
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
    }
            });
        }
    }

    loadData() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.licenseMaintenanceGridsViewData) {
                return;
            }
            else {
                this.setState({ loaded: true });
            }
        }
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { licenseMaintenanceGridsViewData } = this.props;

        if (licenseMaintenanceGridsViewData && licenseMaintenanceGridsViewData.fields) {
            return licenseMaintenanceGridsViewData.fields.filter(f => f.fieldType === null).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    };

    filterSetTypeLookupValues = () => {
        const { lookupValues } = this.props;
        let allSetTypeLookupValues = getLookupValues(SET_TYPE_LOOKUP, lookupValues);
        return allSetTypeLookupValues.filter(x => x.typeID < 0)
    }

    filterShoppingCartParameters = (groupId:number) => {
        const { shoppingCartParameters } = this.props;
        if (shoppingCartParameters != undefined) {
            return shoppingCartParameters.filter(x => x.groupID == groupId).map(x => ({ code: x.parameterID, description: x.name }))
        }     
        return [({ code: '', description: '' })];
    }

    updateUsesModifiedFlag = () => {
        this.setState({
            isUsesGridModified: false
        })
    }

    refreshData = () => {
        const { shoppingCart, searchLicenseUser } = this.props;
        if (shoppingCart) {
            let searchQuery: ILicenseUserSearchQuery = {
                email: shoppingCart.userName.value
            };

            searchLicenseUser(searchQuery);
        }

        return true;
    }

    crmUsersNameOptions = () => {
        const { crmUsers } = this.props;
        
        return crmUsers && crmUsers.length > 0 ? crmUsers.map((user) => ({
            code: user.nameGuid,
            description: user.name
        })) : [{ code: '', description: '' }];
    };

    crmUsersAddressOptions = () => {
        const { crmUsers } = this.props;

        return crmUsers && crmUsers.length > 0 ? crmUsers.map((user) => ({
            code: user.addressGuid,
            description: user.address
        })) : [{ code: '', description: '' }];
    };

    getDynamicColumns = (e) => {
        const { shoppingCart, getLicenseRequestWorkFields } = this.props;
        if (!(ComponentsHelper.isEmpty(shoppingCart.commercialUse.value)
            || ComponentsHelper.isEmpty(shoppingCart.geographicalArea.value)
            || ComponentsHelper.isEmpty(shoppingCart.isUserExternal.value)
            || ComponentsHelper.isEmpty(shoppingCart.uses.value)
            || ComponentsHelper.isEmpty(shoppingCart.sector.value)
            || ComponentsHelper.isEmpty(shoppingCart.userTypes.value))) {
            getLicenseRequestWorkFields(shoppingCart.commercialUse.value, shoppingCart.geographicalArea.value, shoppingCart.sector.value, shoppingCart.userTypes.value, shoppingCart.uses.value, e.workID, e.licenseRequestWorkID);
        }
    }
    

    render() {
        const {
            shoppingCart,
            lookupValues,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            updatePagination,
            searchResultTableData,
            showModal,
            updateLicenseField,
            addWorkToLicenseRequest,
            updateLicenseRequestWorkItem,            
            licenseRequestWorkParameterFields,
            getLicenseRequestWorkFields,
            licenseRequest,
            getWorksContributors,
            licenseWorksPricesImport,
            tabs,
            activeTab,
            licenseCalculateWorkPrice,
            updateLicenseRequestWorkItemToLocalState,
            getWorkDetails,
            changesMade
        } = this.props;

        const {
            activeHeaderSection,
            loaded,
            isUsesGridModified
        } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            const useTabFields = [LICENSE_USES_TYPE_STATE_KEY, LICENSE_SECTOR_TYPE_STATE_KEY, LICENSE_GEOGRAPHICAL_AREA_TYPE_STATE_KEY, LICENSE_USER_TYPE_STATE_KEY, LICENSE_COMMERCIAL_USE_TYPE_STATE_KEY];
            if (useTabFields.indexOf(name)) {
                this.setState({ isUsesGridModified: true })
            }
            updateLicenseField(value, name as ILicenseStateKeys, undefined, activeHeaderSection);
        }

        const getAllLicenseRequestWork = () :ILicenseRequestWorkItem[] => {
            const { tempLicenseWorkRows } = this.props;
            let licenseRequestWork: ILicenseRequestWorkItem[] = [];
            
            if (tempLicenseWorkRows && tempLicenseWorkRows.length > 0) {
                tempLicenseWorkRows.map(r => {
                        licenseRequestWork.push(r);
                    });
            }

            return licenseRequestWork;
        }

        if (!loaded)
            return <div className="loading" />
        
        return (
            <div className="GridsView">
                <div className="headerContainer">
                    {this.renderGridViewHeaderSections()}
                </div>
                {activeHeaderSection === LICENSE_USES_KEY ?
                    <div>
                        <div className="row" style={{ paddingTop: "20px" }}>                        
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_USES_TYPE_STATE_KEY]}
                                fieldName={LICENSE_USES_TYPE_STATE_KEY}
                                value={shoppingCart.uses.value}
                                changeData={changeData.bind(this)}
                                options={getLookupValues(LICENSING_USES_LOOKUP, lookupValues)}
                                allowNull={true}
                                readOnly={false}
                                isHidden={false}
                                componentInstance={LICENSE_USES_KEY}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_SECTOR_TYPE_STATE_KEY]}
                                fieldName={LICENSE_SECTOR_TYPE_STATE_KEY}
                                value={shoppingCart.sector.value}
                                changeData={changeData.bind(this)}
                                options={this.filterShoppingCartParameters(1)}
                                allowNull={true}
                                readOnly={false}
                                isHidden={false}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_GEOGRAPHICAL_AREA_TYPE_STATE_KEY]}
                                fieldName={LICENSE_GEOGRAPHICAL_AREA_TYPE_STATE_KEY}
                                value={shoppingCart.geographicalArea.value}
                                changeData={changeData.bind(this)}
                                options={this.filterShoppingCartParameters(8)}
                                allowNull={true}
                                readOnly={false}
                                isHidden={false}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_USER_TYPE_STATE_KEY]}
                                fieldName={LICENSE_USER_TYPE_STATE_KEY}
                                value={shoppingCart.userTypes.value}
                                changeData={changeData.bind(this)}
                                readOnly={false}
                                isHidden={false}
                                options={this.filterShoppingCartParameters(6)}
                                allowNull={true}
                            />
                            <SizedDropdownDataInput
                                changeData={changeData.bind(this)}
                                label={this.viewData[LICENSE_COMMERCIAL_USE_TYPE_STATE_KEY]}
                                value={shoppingCart.commercialUse.value}
                                fieldName={LICENSE_COMMERCIAL_USE_TYPE_STATE_KEY}
                                options={this.filterShoppingCartParameters(7)}
                                readOnly={false}
                                isHidden={false}
                                allowNull={true}
                            />
                           
                            </div>
                        </div>
                        : <div> </div>}
                {activeHeaderSection === LICENSE_WORKS_KEY ?
                    <div>
                        <LicenseMaintenanceWorkGrid
                            tableContents={getAllLicenseRequestWork()}
                            componentInstance={LICENSE_WORK_DATAGRID_VIEW}
                            selectAll={false}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            changeData={() => { }}
                            search={() => { }}
                            searchResultsTableData={searchResultTableData}
                            repertoireSection={""}
                            showModal={showModal}
                            addWorkToLicenseRequest={addWorkToLicenseRequest}
                            shoppingCart={shoppingCart}
                            updateLicenseRequestWorkItem={updateLicenseRequestWorkItem}
                            licenseRequestWorkParameterFields={licenseRequestWorkParameterFields}
                            getLicenseRequestWorkFields={getLicenseRequestWorkFields}
                            licenseRequest={licenseRequest}
                            getWorksContributors={getWorksContributors}
                            licenseWorksPricesImport={licenseWorksPricesImport}
                            tabs={tabs}
                            activeTab={activeTab}
                            licenseCalculateWorkPrice={licenseCalculateWorkPrice}
                            lookupValues={lookupValues}
                            updateLicenseRequestWorkItemToLocalState={updateLicenseRequestWorkItemToLocalState}
                            isUsesGridModified={isUsesGridModified}
                            updateUsesModifiedFlag={this.updateUsesModifiedFlag}
                            getWorkDetails={getWorkDetails}
                            changesMade={changesMade}
                            />
                       
                    </div> : <div> </div>}
                {activeHeaderSection === LICENSE_BILLING_KEY ?
                    < div >
                        <div className="row" style={{ paddingTop: "20px" }}>
                            <div className="col-md-12">
                                <h1>Licensee details</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_LICENSEE_NAME_STATE_KEY]}
                                fieldName={LICENSE_LICENSEE_NAME_STATE_KEY}
                                value={licenseRequest.licenseeName.value}
                                changeData={changeData.bind(this)}
                                options={this.crmUsersNameOptions()}
                                allowNull={true}
                                readOnly={false}
                                isHidden={!this.viewData[LICENSE_LICENSEE_NAME_STATE_KEY]}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_LICENSEE_ADDRESS_STATE_KEY]}
                                fieldName={LICENSE_LICENSEE_ADDRESS_STATE_KEY}
                                value={licenseRequest.licenseeAddress.value}
                                changeData={changeData.bind(this)}
                                options={this.crmUsersAddressOptions()}
                                allowNull={true}
                                readOnly={false}
                                isHidden={!this.viewData[LICENSE_LICENSEE_ADDRESS_STATE_KEY]}
                            />
                            <div className="row">
                            <SizedCheckboxDataInput
                                label={this.viewData[LICENSE_USE_DETAILS_FOR_BILLING_STATE_KEY]}
                                fieldName={LICENSE_USE_DETAILS_FOR_BILLING_STATE_KEY}
                                value={licenseRequest.reuseDetailsForBilling.value}
                                changeData={changeData.bind(this)}
                                readonly={false}
                                isHidden={!this.viewData[LICENSE_USE_DETAILS_FOR_BILLING_STATE_KEY]} />
                            </div >
                       </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Billing details</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_BILLING_NAME_STATE_KEY]}
                                fieldName={LICENSE_BILLING_NAME_STATE_KEY}
                                value={licenseRequest.reuseDetailsForBilling.value ? licenseRequest.licenseeName.value : licenseRequest.billingName.value}
                                changeData={changeData.bind(this)}
                                options={this.crmUsersNameOptions()}
                                allowNull={true}
                                readOnly={false}
                                isHidden={!this.viewData[LICENSE_BILLING_NAME_STATE_KEY]}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_BILLING_ADDRESS_STATE_KEY]}
                                fieldName={LICENSE_BILLING_ADDRESS_STATE_KEY}
                                value={licenseRequest.reuseDetailsForBilling.value ? licenseRequest.licenseeAddress.value: licenseRequest.billingAddress.value}
                                changeData={changeData.bind(this)}
                                options={this.crmUsersAddressOptions()}
                                allowNull={true}
                                readOnly={false}
                                isHidden={!this.viewData[LICENSE_BILLING_ADDRESS_STATE_KEY]}
                            />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Signer</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[LICENSE_SIGNER_NAME_STATE_KEY]}
                                fieldName={LICENSE_SIGNER_NAME_STATE_KEY}
                                value={licenseRequest?.signerName?.value ?? EMPTY_STRING_VALUE}
                                changeData={changeData.bind(this)}
                                options={this.crmUsersNameOptions()}
                                allowNull={true}
                                readOnly={false}
                                isHidden={!this.viewData[LICENSE_SIGNER_NAME_STATE_KEY]}
                            />
                        </div>
                        <div key='action' className="row">
                            <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                                <ActionButton buttonText="Refresh" buttonAction={this.refreshData} disabled={false} />
                            </div>
                        </div>
                    </div>
                    : <div> </div>
                }
            </div>
        );
    }
}