import * as React from "react";
import {
    SAVE_METHOD_TOOLBAR,
    SAVE_CHANGES_STATE_KEY,
    ADD_METHOD_TOOLBAR,
    ADD_NEW_DRAFT_SET_LISTS_STATE_KEY,
    UNDO_METHOD_TOOLBAR,
    COPY_METHOD_TOOLBAR,
    UNDO_CHANGES_DRAFT_WORK_STATE_KEY,
    DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR,
    COPY_DRAFT_SET_LISTS_STATE_KEY,
} from "../../../Consts";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { showYesNoPrompt, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { IProductState } from "../../../../redux/types/IProductState";

export interface IDraftSetListsMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IMembersPortalComponentDataItem;
    saveResultData?: IMembersPortalComponentDataItem;
    setList: IProductState;
    dataSource: DataSource;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    isReadonly: boolean;
    undoDraftSetListChanges: () => void;
    addNewDraftSetList: () => void;
    isNew?: boolean;
    currentUser?: string;
    lookupValues: ILookupDictionary;
    showModal: typeof showModal;
    copyDraftSetList: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => void;
}

export interface IDraftSetListsMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

export type DraftSetListsMaintenanceToolbarKeys = keyof DraftSetListsMaintenanceToolbar;

class DraftSetListsMaintenanceToolbar extends React.Component<
    IDraftSetListsMaintenanceToolbarProps,
    IDraftSetListsMaintenanceToolbarState
> {
    actionData;

    constructor(props) {
        super(props);

        this.state = {
            dataAction: this.getDataAction()
        };
    }

    saveChangesAction = () => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    copyDraftSetList = () => {

        const { tabs, activeTab, copyDraftSetList, currentUser, lookupValues } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        copyDraftSetList(actionList, currentUser, lookupValues);
    }
    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    getDataAction() {
        const {
            toolbarData: { fields },
            undoDraftSetListChanges,
            addNewDraftSetList,
            isNew
        } = this.props;
        const actionList: IDataActionToolbar[] = [];

        const copyDraftSetListsAction: IDataActionToolbar = {
            action: this.copyDraftSetList,
            component: DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR,
            method: COPY_METHOD_TOOLBAR,
            name: fields.find(d => d.name === COPY_DRAFT_SET_LISTS_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Copy'
        };
        actionList.push(copyDraftSetListsAction);

        const undo: IDataActionToolbar = {
            action: undoDraftSetListChanges,
            component: DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR,
            method: UNDO_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === UNDO_CHANGES_DRAFT_WORK_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Undo'
        };
        actionList.push(undo);

        const add: IDataActionToolbar = {
            action: addNewDraftSetList,
            component: DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(p => p.name === ADD_NEW_DRAFT_SET_LISTS_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition'
        }
        actionList.push(add);

        const save: IDataActionToolbar = {
            action: this.saveChangesAction.bind(this),
            component: DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: ''
        };
        actionList.push(save);







        return actionList;
    }

    render() {
        const {
            changesMade,
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            activeTab,
            tabs,
            toolbarData,
            setList,
            lookupValues,
            isNew
        } = this.props;

        const isReadonly: boolean = tabs[activeTab].isReadonly;
        let { dataAction } = { ...this.state };

        //let isNew = (tabs[activeTab].draftSetListsMaintenanceState || tabs[activeTab].registeredSetListsMaintenanceState).isNew;
        return (
            <div className={isReadonly && !changesMade ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={this.resetMessageBanner.bind(this)}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction}
                    toolbarData={toolbarData}
                    component={DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR}
                    isNew={isNew}
                    isReadonly={isReadonly}
                    lookupValues={lookupValues}
                />
            </div>
        );
    }
}

export default DraftSetListsMaintenanceToolbar;
