const keys = [
    "open_action",
    "addNew_action",
    "search_action",
    "remove_action",
    "update_action",
    "dependentRow_action",
    "code_action",
    "grouped_action",
    "request_action"
];

export type ITableActionName = typeof keys[number];

export interface ITableAction {
    name: ITableActionName;
    disabled?: boolean;
}

export function isTableAction(key: any): key is ITableActionName {
    return keys.includes(key as ITableActionName)
}