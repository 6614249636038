import { connect } from "react-redux";
import { Dispatch } from "redux";
import { mp_addTab } from "../../../../redux/reducers/MembersPortalReducer";
import IAppState from "../../../../redux/types/IAppState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { MemberStatementUsageDetailsPage } from "./MemberStatementUsageDetailsPage";

export default connect(
    (state: IAppState) => ({
        usageDetailsPageComponentData: state.membersPortal.componentData.MemberStatementUsageDetailsPage,
        usageDetailsGridsComponentData: state.membersPortal.componentData.MemberStatementUsageDetailsGridsView,
        usageDetails: state.membersPortal.tabs[state.membersPortal.activeTab].memberStatementUsageDetailsState && state.membersPortal.tabs[state.membersPortal.activeTab].memberStatementUsageDetailsState.usageDetails,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
    })
)(MemberStatementUsageDetailsPage);
