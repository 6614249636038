// Components
export const HEADER: string = "Header";
export const JOB_SEARCH_VIEW: string = "JobSearchView";
export const JOB_SEARCH_RESULTS_VIEW: string = "JobSearchResultsView";
export const JOB_SEARCH_RESULTS_GRID: string = "JobSearchResultsGrid";
export const TIME_RANGE_SELECTOR: string = "TimeRangeSelector";

// Component Instances
export const OPTIONS: string = "options";

export const ENABLED_MODULES_CONFIGURATION_KEY = "EnabledModule";
export const MODULE_GROUP_CONFIGURATION_KEY = "Module";

export const MANUAL_JOB_VIEW: string = "ManualJobView";

// Job Types
export const BOOK_EXPORT_JOB_TYPE: string = "BEXP";
export const CWR_GENERATION_JOB_TYPE: string = "CWRG";
export const DRAFT_AGREEMENT_SUBMISSION_JOB_TYPE: string = "DRAFTAGR";
export const CWR_ACK2_JOB_TYPE: string = "CWRACK2";
export const AAG_JOB_TYPE: string = "AAG";
export const ATF_JOB_TYPE: string = "ATF";
export const UNIDENTIFIED_PERFORMANCE_JOB_TYPE: string = "UP";
export const UNDISTRIBUTED_SHARES_JOB_TYPE: string = "UDS";
export const PD_RULES_JOB_TYPE: string = "PD";
export const CISNet_JOB_TYPE: string = "CISNet";
export const SUSPENSE_RELEASE_JOB_TYPE: string = "SR";
export const GENERATE_STATEMENT_DETAILS_JOB_TYPE: string = "GSD";
export const GENERATE_PAYMENT_DETAILS_JOB_TYPE: string = "GPD";
export const POST_STATEMENTS_ON_PORTAL_JOB_TYPE: string = "PSOP";
export const MANUAL_USAGE_MATCHING_JOB_TYPE: string = "USGM";
export const RESET_DISTRIBUTION_CACHE_JOB_TYPE: string = "RDC";
export const EXTRACT_MATCHED_USAGE_WORKS_JOB_TYPE: string = "EMUW";
export const CLAIM_JOB_TYPE: string = "CLAIM";
export const GENERATE_BUSINESS_CENTRAL_PAYMENT_JOB_TYPE: string = "GBCP";
export const BOOK_CLAIM_SUSPENSE_RELEASE_JOB_TYPE: string = "BCSR";
export const AVI_SUBMISSION_JOB_TYPE: string = "CISAVI";

// Keys
export const DEADLINE_DATE_KEY: string = "Deadline Date";
export const DISTRIBUTION_DATE_KEY: string = "Distribution Date";
export const USAGE_DATE_FROM_KEY: string = "Usage Date from";
export const USAGE_DATE_TO_KEY: string = "Usage Date to";
export const MINIMUM_THRESHOLD_KEY: string = "Minimum Threshold";
export const HIGH_WATER_MARK_KEY: string = "HighWaterMark";
export const DISPLAY_CUSTOMER_LOGO_KEY = "DisplayCustomerLogo";
export const STATEMENT_FORMAT_FIELD_KEY: string = "statementFormat";
export const PAYMENTRUN_ID_FIELD_KEY: string = "paymentRunID";

//labels
export const OUTPUT_TYPE_TITLE: string = "Output Type";
export const HIGH_WATER_MARK_TITLE: string = "High Water Mark";

//Configuration parameters
export const CEDRO_CUSTOMER_NAME = "CEDRO";
export const ARTISJUS_CUSTOMER_NAME = "Artisjus";
export const SPANISH_POINT_CUSTOMER_NAME = "SpanishPoint";
export const IMRO_CUSTOMER_NAME = "IMRO";
export const CUSTOMER_KEY = "Customer";
export const CUSTOMER_GROUP = "CustomerConfiguration";
export const CUSTOMER_DATE_KEY = "CustomerDate";
export const CUSTOMER_TIME_ZONE = "CustomerTimeZone";
export const CUSTOMER_LOGOS_KEY = "CustomerLogos";
export const CRM_INTEGRATED_KEY = "IsCRMIntegrated";
export const POWER_PORTAL_BASE_URL_KEY = "PowerPortalBaseUrl";
export const CUSTOMER_DEFAULT_TERRITORIES = "DefaultTerritory";
export const SEPARATE_PUBLISHED_CHECKBOX = "SeparatePublishedCheckbox";
export const CWRG_GROUP = "CWRGenerationJob";

