import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { IDistributionState } from "../../redux/types/IDistributionState";
import { IPaymentRunState } from "../../redux/types/IPaymentRunState";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { DISTRIBUTION_SAVE_VALIDATION_CLAIMS_TO_NOT_SET, DISTRIBUTION_SAVE_VALIDATION_NO_POOL_ASSIGNED, DISTRIBUTION_SAVE_VALIDATION_USAGE_TO_NOT_SET, EMPTY_STRING_VALUE, DISTRIBUTION_SAVE_VALIDATION_CLAIMS_FROM_NOT_SET, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_DATE_NOT_SET, DISTRIBUTION_SAVE_VALIDATION_USAGE_FROM_NOT_SET, PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_RUN_DATE_NOT_SET, PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_ID_NOT_SET, PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_STATUS_NOT_SET, PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_TYPE_NOT_SET, PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_AVAILABLE_FROM_DATE_NOT_SET_CONDITIONALLY } from "../Consts";
import { IDistributionSaveResult } from "../types/usageTypes/IDistributionSaveResult";
import { DistributionDetailsMapper } from "./DistributionDetailsMapper";

export class PaymentRunValidator {

    public static validatePaymentRun(paymentRun: IPaymentRunState, paymentRunMaintenanceGeneralData: IRepertoireComponentDataItem): string[] {
        let errorMessages: string[] = [];
        if (!paymentRun.paymentRunDate)
            errorMessages.push(
                ComponentsHelper.getFieldValueByName(
                    PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_RUN_DATE_NOT_SET, 
                    paymentRunMaintenanceGeneralData.fields, 
                    EMPTY_STRING_VALUE)
                );

        if (!paymentRun.code)
            errorMessages.push(
                ComponentsHelper.getFieldValueByName(
                    PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_ID_NOT_SET, 
                    paymentRunMaintenanceGeneralData.fields, 
                    EMPTY_STRING_VALUE)
                );

        if (!paymentRun.status)
            errorMessages.push(
                ComponentsHelper.getFieldValueByName(
                    PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_STATUS_NOT_SET, 
                    paymentRunMaintenanceGeneralData.fields, 
                    EMPTY_STRING_VALUE)
                );

        if (!paymentRun.type)
            errorMessages.push(
                ComponentsHelper.getFieldValueByName(
                    PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_TYPE_NOT_SET, 
                    paymentRunMaintenanceGeneralData.fields, 
                    EMPTY_STRING_VALUE)
            );

        if (paymentRun.availableOnPortalTo && !(paymentRun.availableOnPortalFrom))
            errorMessages.push(
                ComponentsHelper.getFieldValueByName(
                    PAYMENTRUN_SAVE_VALIDATION_PAYMENTRUN_AVAILABLE_FROM_DATE_NOT_SET_CONDITIONALLY,
                    paymentRunMaintenanceGeneralData.fields,
                    EMPTY_STRING_VALUE)
            );
        return errorMessages;
    }
}