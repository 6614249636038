import * as React from "react";
import { hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { COMPONENT_INSTANCE_IPS, IP_TRANSFER_ENDDATE_STATE_KEY, IP_TRANSFER_IP_TYPE_STATE_KEY, IP_TRANSFER_PERCENTAGE_STATE_KEY, IP_TRANSFER_RECIPIENT_STATE_KEY, IP_TRANSFER_REGISTRATIONDATE_STATE_KEY, IP_TRANSFER_STARTDATE_STATE_KEY, IP_TRANSFER_STATE_KEY, IP_TRANSFER_SUBTYPE_STATE_KEY, IP_TRANSFER_TYPE_STATE_KEY, SEARCH_VIEW, SEARCH_VIEW_CONTRIBUTORS, SEARCH_VIEW_IPS } from "../../../Consts";
import { IIPsSearchQuery } from "../../../types/IIPsSearchQuery";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IipTransfer } from "../../../types/usageTypes/IipTransfer";
import ActionButton from "../../actionButton/ActionButton";
import SizedDateDataInput from "../../dateDataInput/DateDataInput";
import { SizedDropdownDataInput } from "../../dropdownDataInput/DropdownDataInput";
import { SizedTextDataInput } from "../../textDataInput/TextDataInput";

export interface IIpTransferViewModalProps {
    recipientIP: string;
    transferType: string;
    transferSubType: string;
    transferPercentage: number;
    transferIPType: string;
    transferStartDate: string;
    transferEndDate: string;
    transferRegistationDate: string;
}

export interface IIpTransferViewProps {
    props: IIpTransferViewModalProps;
    hideModal: typeof hideModal;
    showModal?: typeof showModal;
    ipTransferViewData: IRepertoireComponentDataItem;
    searchIPs?: (query: IIPsSearchQuery) => void;
    sourceIp?: string;
    ipTransfer: (ipTransferObject: IipTransfer) => void;
    tabs?: ITabReduxItem[];
    activeTab?: number;
}

export interface IpTransferViewState {
    ipTransfer: IipTransfer
}

export class IpTransferView extends React.Component<IIpTransferViewProps, IpTransferViewState> {

    constructor(props: IIpTransferViewProps) {
        super(props);     

        this.state = {
            ipTransfer: {
                recipientIP: null,
                transferType: "transferAll",
                transferSubType: null,
                transferPercentage: null,
                transferIpType: "All",
                transferStartDate: null,
                transferEndDate: null,
                transferRegistrationDate: null
            }
        };
    }

    showSearchModal = (modalProps: any) => {
        const { showModal } = this.props;
        showModal(SEARCH_VIEW, SEARCH_VIEW_CONTRIBUTORS, modalProps, true, "Search");
    };

    validateFloat(value: any) : string {

        let valueNumber = isNaN(value) ? undefined : Number(value);

        return (valueNumber != undefined && valueNumber > 0 && valueNumber <= 100) ? value : null;
    }

    transferIp(ipTransferObject: IipTransfer) {
        const { ipTransfer } = this.props;

        ipTransfer(ipTransferObject);
    }
         
    render() {       

        const { sourceIp } = this.props;

        const ipTransfer: IipTransfer = {
            sourceIP: sourceIp,
            recipientIP: this.state.ipTransfer.recipientIP,
            transferType: this.state.ipTransfer.transferType,
            transferSubType: this.state.ipTransfer.transferSubType,
            transferPercentage: this.state.ipTransfer.transferPercentage,
            transferIpType: this.state.ipTransfer.transferIpType,
            transferStartDate: this.state.ipTransfer.transferStartDate,
            transferEndDate: this.state.ipTransfer.transferEndDate,
            transferRegistrationDate: this.state.ipTransfer.transferRegistrationDate
        }

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            if (name === 'recipientIP') {
                ipTransfer.recipientIP = value;
            }

            if (name === 'transferType') {
                ipTransfer.transferType = value;
            }

            if (name === 'transferSubType') {
                ipTransfer.transferSubType = value;
            }

            if (name === 'transferPercentage') {
                ipTransfer.transferPercentage = this.validateFloat(value);
            }

            if (name === 'transferIpType') {
                ipTransfer.transferIpType = value;
            }

            if (name === 'transferStartDate') {
                ipTransfer.transferStartDate = value;
            }

            if (name === 'transferEndDate') {
                ipTransfer.transferEndDate = value;
            }

            if (name === 'transferRegistrationDate') {
                ipTransfer.transferRegistrationDate = value;
            }

            this.setState({ ipTransfer: ipTransfer });
        }

        var transferTypes: any[] =
            [{ description: "Transfer all works and money", code: "transferAll" },
             // Options not supported yet
             //{ description: "If the rightsholder has pending monies, the money goes to the new rightsholder", code: "transferMoney" },
             //{ description: "If the rightsholder has pending monies, the money is not transferred", code: "donotTransferMoney" },
             //{ description: "Some works transferred", code: "worksTransferred" }
            ];

        var transferIpTypes: any[] =
            [{ description: "All", code: "All" },
             { description: "Authors Only", code: "Authors" },
             { description: "Publisher Only", code: "Publishers" }];

        let fields: IRepertoireField[] = this.props.ipTransferViewData.fields.filter(x => x.componentInstance === COMPONENT_INSTANCE_IPS)

        return (
            <div className="form-group col-md-12">
                <div className="row">
                    <SizedTextDataInput
                        label={fields.find(x => x.name === IP_TRANSFER_RECIPIENT_STATE_KEY).data}
                        fieldName={IP_TRANSFER_RECIPIENT_STATE_KEY}
                        value={this.state.ipTransfer.recipientIP ? this.state.ipTransfer.recipientIP : null}
                        changeData={changeData}
                        readOnly={false}
                        isHidden={false} />
                </div>

                <div className="row">
                    <SizedDropdownDataInput
                        label={fields.find(x => x.name === IP_TRANSFER_TYPE_STATE_KEY).data}
                        fieldName={IP_TRANSFER_TYPE_STATE_KEY}
                        value={this.state.ipTransfer.transferType ? this.state.ipTransfer.transferType : null}
                        changeData={changeData}
                        options={transferTypes}
                        allowNull={false}
                        readOnly={false}
                        isHidden={false}
                        showDropdownsAsCodes={true}
                        selectedCode={"transferAll"} />

                    <SizedTextDataInput
                        label={fields.find(x => x.name === IP_TRANSFER_PERCENTAGE_STATE_KEY).data}
                        fieldName={IP_TRANSFER_PERCENTAGE_STATE_KEY}
                        value={this.state.ipTransfer.transferPercentage ? this.state.ipTransfer.transferPercentage : null}
                        changeData={changeData}
                        readOnly={false}
                        isHidden={false} />

                    <SizedDropdownDataInput
                        label={fields.find(x => x.name === IP_TRANSFER_IP_TYPE_STATE_KEY).data}
                        fieldName={IP_TRANSFER_IP_TYPE_STATE_KEY}
                        value={this.state.ipTransfer.transferIpType ? this.state.ipTransfer.transferIpType : null}
                        changeData={changeData}
                        options={transferIpTypes}
                        allowNull={false}
                        readOnly={false}
                        isHidden={false}
                        selectedCode={"All"} />
                </div>

                <div className="row">
                    <SizedDateDataInput
                        label={fields.find(x => x.name === IP_TRANSFER_STARTDATE_STATE_KEY).data}
                        fieldName={IP_TRANSFER_STARTDATE_STATE_KEY}
                        value={this.state.ipTransfer.transferStartDate ? this.state.ipTransfer.transferStartDate.toString() : null}
                        changeData={changeData}
                        readOnly={false}
                        isHidden={false} />

                    <SizedDateDataInput
                        label={fields.find(x => x.name === IP_TRANSFER_ENDDATE_STATE_KEY).data}
                        fieldName={IP_TRANSFER_ENDDATE_STATE_KEY}
                        value={this.state.ipTransfer.transferEndDate ? this.state.ipTransfer.transferEndDate.toString() : null}
                        changeData={changeData}
                        readOnly={false}
                        isHidden={false} />

                    <SizedDateDataInput
                        label={fields.find(x => x.name === IP_TRANSFER_REGISTRATIONDATE_STATE_KEY).data}
                        fieldName={IP_TRANSFER_REGISTRATIONDATE_STATE_KEY}
                        value={this.state.ipTransfer.transferRegistrationDate ? this.state.ipTransfer.transferRegistrationDate.toString() : null}
                        changeData={changeData}
                        readOnly={false}
                        isHidden={false} />                    
                    <div className="form-group col-lg-4 col-lg-push-4 col-md-4 col-md-push-5 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0">
                        <ActionButton buttonText="Transfer" buttonAction={() => this.transferIp(this.state.ipTransfer) } />
                    </div>
                </div>
            </div>
        );
    }
}