import { IWorkName } from "../types/IWorkName";
import { ITitle } from "../types/ITitle";
import { IWorkNumber } from "../types/IWorkNumber";
import { INumber } from "../types/INumber";
import { IWorkPerformer } from "../types/IWorkPerformer";
import { IArtist } from "../types/IArtist";
import { IArticle } from "../types/IArticle";
import { IWorkNote } from "../types/IWorkNote";
import { IComment } from "../types/IComment";
import { IWorkContributor } from "../types/IWorkContributor";
import { IContributorItem } from "../types/IContributorItem";
import {
    TEXT_INPUT,
    DROPDOWN_INPUT,
    DATE_INPUT,
    READ_ONLY_INPUT,
    BOOLEAN_READONLY_INPUT,
    CHECKBOX_INPUT,
    TEXT_READONLY_IN_REPERTOIRE_INPUT,
    EMPTY_STRING_VALUE,
    INTEGER_INPUT,
    CUSTOM_INPUT,
    READ_ONLY_TIMESPAN_INPUT,
    LEVY_COPY_TYPE_CODE,
    LICENCE_COPY_TYPE_CODE,
    ARTICLE_WORK_TYPE_CODE,
    UNIVERSITY_DIST_COMP_CODE,
    SHEET_MUSIC_WORK_TYPE_CODE,
    DISCONTINUED_CODE,
    BOOK_WORK_TYPE_CODE,
    MAGAZINE_WORK_TYPE_CODE,
    CHAPTER_WORK_TYPE_CODE,
    IS_DISPLAY,
    CODE_CATEGORY,
    DATA_TYPE,
    TIMESPAN_INPUT,
    INTEGER_PERCENTAGE_INPUT,
} from "../Consts";
import { IWork } from "../types/IWork";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { getLookupDefault, getLookupValues } from "../../lookup/services/LookupHelpers";
import { Dictionary } from "../../core/types/Dictionary";
import { DataSource } from "../types/DataSource";
import { IWorkState } from "../../redux/types/IWorkState";
import { ITerritorySpecificInfo } from "../types/ITerritorySpecificInfo";
import { IWorkTerritorySpecificInfo } from "../types/IWorkTerritorySpecificInfo";
import { IWorkDuplicate } from "../types/IWorkDuplicate";
import { IMatchingWork } from "../types/IMatchingWork";
import { IIntrayMatchingWork } from "../types/IIntrayMatchingWork";
import {
    LANGUAGE_LOOKUP,
    SET_TYPE_GROUP_LOOKUP,
    SET_TYPE_LOOKUP,
    WORK_GENRE_LOOKUP,
    WORK_TYPE_LOOKUP,
    WORK_MEDLEY_TYPE_LOOKUP,
    WORK_SOURCE_LOOKUP,
    WORK_SOURCE_TYPE_LOOKUP,
    WORK_STATUS_LOOKUP,
    WORK_SUB_STATUS_LOOKUP,
    SHARE_RIGHT_TYPE_LOOKUP,
    WORK_PARTS_LOOKUP,
    WORK_FLAG_TYPE_LOOKUP,
    WORK_GENRE_CATEGORY_LOOKUP,
    PORTAL_DEFAULT_VALUES_LOOKUP
} from "../../lookup/Consts";
import { IWorkContainedWork } from "../types/IWorkContainedWork";
import { IContainedWork } from "../types/IContainedWork";
import { IWorkSourceIP } from "../types/IWorkSourceIP";
import { ISourceIP } from "../types/ISourceIP";
import { IWorkContributorOther } from "../types/IWorkContributorOther";
import { IWorkInstrumentation } from "../types/IWorkInstrumentation";
import { IInstrumentation } from "../types/IInstrumentation";
import { IWorkInstrument } from "../types/IWorkInstrument";
import { IInstrument } from "../types/IInstrument";
import { IStoragePathItemData } from "../../dataingestion/types/IStoragePathItemData";
import { IAttachedFile } from "../../redux/types/IAttachedFile";
import { IWorkflow } from "../types/IWorkflow";
import { IReplacedWork } from "../types/IReplacedWork";
import { IWorkReplacedWork } from "../types/IWorkReplacedWork";
import { IAgreementLink } from "../types/IAgreementLink";
import { IWorkParentWork } from "../types/IWorkParentWork";
import { IWorkProduct } from "../types/IWorkProduct";
import { IWorkProductDataItems } from "../types/IWorkProductDataItems";
import { IWorkPaymentRun } from "../types/IWorkPaymentRun";
import { IWorkPaymentRunDataItems } from "../types/IWorkPaymentRunDataItems";
import { IWorkContributorShare } from "../types/IWorkContributorShare";
import { IWorkRepresentation } from "../types/IWorkRepresentation";
import { IRepresentation } from "../types/IRepresentation";
import { IWorkArticle } from "../types/IWorkArticle";
import { IWorkMetadata } from "../types/IWorkMetadata";
import { IWorkFlag } from "../types/IWorkFlag";
import { IWorkSheetMusic } from "../types/IWorkSheetMusic";
import { ISheetMusic } from "../types/ISheetMusic";
import { IBook } from "../types/IBook";
import { IWorkBook } from "../types/IWorkBook";
import { IChapter } from "../types/IChapter";
import { IWorkChapter } from "../types/IWorkChapter";
import { IWorkMagazine } from "../types/IWorkMagazine";
import { IMagazine } from "../types/IMagazine";
import { IFlag } from "../types/IFlag";
import { IIntrayWorkDuplicate } from "../types/IIntrayWorkDuplicate";
import { IWorkPrice } from "../types/IWorkPrice";
import { IPrice } from "../types/IPrice";
import { IWorkSubject } from "../types/IWorkSubject";
import { ISubject } from "../types/ISubject";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ISetListsState } from "../../membersportal/types/ISetListsState";
import { ISetListsWork } from "../../membersportal/types/ISetListsTitle";
import { IWorkNumberAdditional } from "../types/IWorkNumberAdditional";
import { INumberAdditional } from "../types/INumberAdditional";
import { IEdition } from "../types/IEdition";
import { IWorkEdition } from "../types/IWorkEdition";
import { NullLiteral } from "@babel/types";
import { object } from "yup";
import { MEMBERS_PORTAL } from "../../accounts/Consts";
import { IWorkEditionDataItems } from "../types/IWorkEditionDataItems";
import { IUsage } from "../types/usageTypes/IUsage";
import { IUsageGroup } from "../types/usageTypes/IUsageGroup";
import { IUsageGroupState } from "../../redux/types/IUsageGroupState";


export class WorkDetailsMapper {

    public static mapWorkProductToState(product: IWorkProduct[]): IWorkProductDataItems[] {
        if (product) {
            const stateProduct: IWorkProductDataItems[] = [];
            product.map(a => {
                stateProduct.push({
                    workProductID: a.workProductID,
                    productCoreID: a.productCoreID,
                    title: { value: a.title, inputType: READ_ONLY_INPUT },
                    number: { value: a.number, inputType: READ_ONLY_INPUT },
                    contributors: { value: a.contributors, inputType: READ_ONLY_INPUT },
                    artists: { value: a.artists, inputType: READ_ONLY_INPUT },
                    intrayIndicator: { value: a.dataSource === DataSource.Intray ? true : false, inputType: BOOLEAN_READONLY_INPUT },
                })
            });
            return stateProduct;
        }
        else {
            return [];
        }
    }

    public static mapStateToWorkProduct(stateProduct: IWorkProductDataItems[]): IWorkProduct[] {
        if (stateProduct) {
            const product: IWorkProduct[] = [];
            stateProduct.map(a => {
                product.push({
                    workProductID: a.workProductID,
                    productCoreID: a.productCoreID,
                    title: a.title.value,
                    number: a.number.value,
                    contributors: a.contributors.value,
                    artists: a.artists.value,
                    dataSource: a.intrayIndicator.value ? DataSource.Intray : DataSource.Repertoire,
                })
            });
            return product;
        }
        else {
            return [];
        }
    }

    public static mapStateToWorkPaymentRun(
        statePaymentRun: IWorkPaymentRunDataItems[]
    ): IWorkPaymentRun[] {
        if (statePaymentRun) {
            const paymentRun: IWorkPaymentRun[] = [];
            statePaymentRun.map((a) => {
                paymentRun.push({
                    paymentRunCode: a.paymentRunCode,
                    distributionCode: a.distributionCode,
                    poolCode: a.poolCode,
                    paymentRunDate: a.paymentRunDate,
                    amount: a.amount,
                    segment: a.segment,
                });
            });
            return paymentRun;
        } else {
            return [];
        }
    }


    public static mapWorkNamesToTitles(workNames: IWorkName[]): ITitle[] {
        if (workNames) {
            const titles: ITitle[] = [];
            workNames.map(n => {
                titles.push({
                    type: { value: n.type, inputType: DROPDOWN_INPUT },
                    language: { value: n.languageCode, inputType: DROPDOWN_INPUT },
                    title: { value: n.name, inputType: TEXT_INPUT },
                    workNameID: n.workNameID
                })
            });
            return titles;
        }
        else {
            return [];
        }
    }

    public static mapTitlesToWorkNames(titles: ITitle[]): IWorkName[] {
        if (titles) {
            const workNames: IWorkName[] = [];
            titles.map(title => workNames.push({
                workNameID: title.workNameID,
                type: title.type.value,
                name: title.title.value,
                languageCode: title.language.value
            }));
            return workNames;
        }
        else {
            return [];
        }
    }
    public static mapUsageTitlesToWorkNames(titles: string[],lookups): IWorkName[] {
        if (titles) {
            const workNames: IWorkName[] = [];
            titles.map((title,index) => workNames.push({
                workNameID: 0,
                type: index === 0 ? 'OT' : 'AT',
                name: title,
                languageCode: getLookupDefault(LANGUAGE_LOOKUP, lookups)
            }));
            return workNames;
        }
        else {
            return [];
        }
    }

    public static mapWorkFlagsToFlags(work: IWork, workFlag: IWorkFlag[], workType: string, lookupValues: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[]): IFlag[] {
        const flags: IFlag[] = [];
        const workFlagTypes = getLookupValues(WORK_FLAG_TYPE_LOOKUP, lookupValues);
        let flagValues = workFlagTypes ? workFlagTypes.filter(type => type.extraFields[IS_DISPLAY] === true && otherIndicatorsWorkFlagTypes?.includes(type.extraFields[CODE_CATEGORY])) : null;

        if (workType == BOOK_WORK_TYPE_CODE) {
            workFlag.filter(x => flagValues.find(f => (f.code === x.workFlagTypeCode)))
                .map((n) => {
                    let index = flagValues.findIndex(f => f.code == n.workFlagTypeCode)
                    flags.push({
                        flagID: n.workFlagID,
                        flagTypeCode: n.workFlagTypeCode,
                        value: n.value,
                        workID: n.workID,
                        lastModifiedBy: null,
                        dataType: flagValues[index].extraFields[DATA_TYPE],
                        name: flagValues[index].description,
                        createdDate: n.createdDate
                    })
                });

            flagValues.filter(x => !flags.map(f => f.flagTypeCode).includes(x.code))
                .map((n) => {
                    flags.push({
                        flagID: 0,
                        workID: work.workID,
                        createdDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
                        lastModifiedBy: null,
                        flagTypeCode: n.code,
                        dataType: n.extraFields[DATA_TYPE],
                        name: n.description,
                        value: null,
                    })
                });
        }

        return flags.sort((a, b) => a.flagTypeCode.localeCompare(b.flagTypeCode));
    }

    public static mapWorkFlagsToReadonlyIndicators(work: IWork, workFlag: IWorkFlag[], workType: string, lookupValues: ILookupDictionary, readonlyIndicatorsWorkFlagTypes: string[]): IFlag[] {

        const flags: IFlag[] = [];
        const workFlagTypes = getLookupValues(WORK_FLAG_TYPE_LOOKUP, lookupValues);
        const workTypesValues = [BOOK_WORK_TYPE_CODE, MAGAZINE_WORK_TYPE_CODE, SHEET_MUSIC_WORK_TYPE_CODE, ARTICLE_WORK_TYPE_CODE, CHAPTER_WORK_TYPE_CODE]
        let flagValues = workFlagTypes ? workFlagTypes.filter(type => type.extraFields[IS_DISPLAY] === true && readonlyIndicatorsWorkFlagTypes?.includes(type.extraFields[CODE_CATEGORY])) : null;

        if (workTypesValues.includes(work.workType)) {
            workFlag.filter(x => flagValues.find(f => (f.code === x.workFlagTypeCode)))
                .map((n) => {
                    let index = flagValues.findIndex(f => f.code == n.workFlagTypeCode)
                    flags.push({
                        flagID: n.workFlagID,
                        flagTypeCode: n.workFlagTypeCode,
                        value: n.value,
                        workID: n.workID,
                        lastModifiedBy: null,
                        dataType: flagValues[index].extraFields[DATA_TYPE],
                        name: flagValues[index].description,
                        createdDate: n.createdDate
                    })
                });

            flagValues.filter(x => !flags.map(f => f.flagTypeCode).includes(x.code))
                .map((n) => {
                    flags.push({
                        flagID: 0,
                        workID: work.workID,
                        createdDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
                        lastModifiedBy: null,
                        flagTypeCode: n.code,
                        dataType: n.extraFields[DATA_TYPE],
                        name: n.description,
                        value: null,
                    })
                });
        }

        return flags.sort((a, b) => a.flagTypeCode.localeCompare(b.flagTypeCode));
    }

    public static mapWorkNumbersToNumbers(workNumbers: IWorkNumber[]): INumber[] {
        if (workNumbers) {
            const numbers: INumber[] = [];
            workNumbers.map(n => {
                numbers.push({
                    type: { value: n.type, inputType: DROPDOWN_INPUT },
                    number: { value: n.number, inputType: TEXT_INPUT },
                    workNumberID: n.workNumberID
                })
            });
            return numbers;
        }
        else {
            return [];
        }
    }


    public static mapAdditionalWorkNumbersToAdditionalNumbers(workNumbersAdditional: IWorkNumberAdditional[]): INumberAdditional[] {
        if (workNumbersAdditional) {
            const numbersAdditional: INumberAdditional[] = [];
            workNumbersAdditional.map(n => {
                numbersAdditional.push({
                    type: { value: n.type, inputType: DROPDOWN_INPUT },
                    numberAdditional: { value: n.numberAdditional, inputType: TEXT_INPUT },
                    workNumberAdditionalID: n.workNumberAdditionalID,
                    isDeleted: n.isDeleted
                })
            });
            return numbersAdditional;
        }
        else {
            return [];
        }
    }

    public static mapNumbersToWorkNumbers(numbers: INumber[]): IWorkNumber[] {
        if (numbers) {
            const workNumbers: IWorkNumber[] = [];
            numbers.map(number => workNumbers.push({
                workNumberID: number.workNumberID,
                number: number.number.value.trim(),
                type: number.type.value
            }));
            return workNumbers;
        }
        else {
            return [];
        }
    }


    public static mapAdditionalNumbersToAdditionalWorkNumbers(numbers: INumberAdditional[]): IWorkNumberAdditional[] {
        if (numbers) {
            const workNumbers: IWorkNumberAdditional[] = [];
            numbers.map(number => workNumbers.push({
                workNumberAdditionalID: number.workNumberAdditionalID,
                numberAdditional: number.numberAdditional.value,
                type: number.type.value,
                isDeleted: number.isDeleted
            }));
            return workNumbers;
        }
        else {
            return [];
        }
    }

    public static mapWorkPerformersToArtists(workPerformers: IWorkPerformer[]): IArtist[] {
        if (workPerformers) {
            const artists: IArtist[] = [];
            workPerformers.map(p => {
                artists.push({
                    type: { value: p.type, inputType: DROPDOWN_INPUT },
                    firstName: { value: p.firstName ? p.firstName : '', inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT },
                    lastName: { value: p.lastName, inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT },
                    fullName: { value: p.fullName, inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT },
                    number: { value: p.number && p.number.length > 0 ? p.number[0].number : null, inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT },
                    workPerformerID: p.workPerformerID,
                    performerID: p.performerID,
                    performerNumberID: p.number && p.number.length > 0 ? p.number[0].performerNumberID : null
                })
            });
            return artists;
        }
        else {
            return [];
        }
    }

    public static mapWorkArticlesToArticles(workArticles: IWorkArticle[],
        workMetadata: IWorkMetadata[], workType: string, workFlag: IWorkFlag[], dateOfRegistration: string): IArticle {

        const articles: IArticle = {
            author: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].author,
            translator: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].translator,
            editor: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].editor,
            dateOfRegistration: workArticles == null || workArticles.length == 0 ? null : workArticles[0].dateOfRegistration,
            startPage: workArticles == null || workArticles.length == 0 ? null : workArticles[0].startPage,
            endPage: workArticles == null || workArticles.length == 0 ? null : workArticles[0].endPage,
            numberOfPages: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].numberOfPages,
            year: workArticles == null || workArticles.length == 0 ? null : workArticles[0].year,
            volume: workArticles == null || workArticles.length == 0 ? null : workArticles[0].volume,
            number: workArticles == null || workArticles.length == 0 ? null : workArticles[0].number,
            url: workArticles == null || workArticles.length == 0 ? null : workArticles[0].url,
            typeOfTranslation: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].typeOfTranslation,
            workMetadataArticleID: workArticles == null || workArticles.length == 0 ? null : workArticles[0].workMetadataArticleID == null ? 0 : workArticles[0].workMetadataArticleID,
            workMetadataID: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].workMetadataID,
            universityDistributonComp: workFlag ? workFlag.filter(x => x.workFlagTypeCode === UNIVERSITY_DIST_COMP_CODE)[0]?.value === '1' : false,
            universityDistributonCompID: workFlag ? workFlag.filter(x => x.workFlagTypeCode === UNIVERSITY_DIST_COMP_CODE)[0]?.workFlagID : 0,
            publicationDate: workArticles == null || workArticles.length == 0 ? null : workArticles[0].publicationDate
        };
        return articles;
    }

    public static mapWorkSheetMusicToSheetMusic(workSheetMusic: IWorkSheetMusic[],
        workMetadata: IWorkMetadata[], workType: string, workFlag: IWorkFlag[]): ISheetMusic {
        const sheetMusic: ISheetMusic = {
            author: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].author,
            translator: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].translator,
            editor: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].editor,
            numberOfPages: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].numberOfPages,
            typeOfTranslation: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].typeOfTranslation,
            workMetadataID: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].workMetadataID,
            workMetadataSheetMusicID: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].workMetadataSheetMusicID,
            corporateAuthor: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].corporateAuthor,
            instrumentation: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].instrumentation,
            musicalGenre: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].musicalGenre,
            musicalSubject: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].musicalSubject,
            dimensions: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].dimensions,
            typeOfBinding: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].typeOfBinding,
            medium: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].medium,
            scorePublicationType: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].scorePublicationType,
            sheetMusicType: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].sheetMusicType,
            additionalMaterial: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].additionalMaterial,
            typeOfMedium: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].typeOfMedium,
            discontinued: workFlag ? workFlag.filter(x => x.workFlagTypeCode === DISCONTINUED_CODE)[0]?.value === '1' : false,
            discontinuedFlagID: workFlag ? workFlag.filter(x => x.workFlagTypeCode === DISCONTINUED_CODE)[0]?.workFlagID : 0,
            publicationDate: workSheetMusic == null || workSheetMusic.length == 0 ? null : workSheetMusic[0].publicationDate
        };
        return sheetMusic;
    }

    public static mapWorkBookToBook(workBook: IWorkBook[],
        workMetadata: IWorkMetadata[], workType: string, workFlag: IWorkFlag[]): IBook {
        const book: IBook = {
            author: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].author,
            translator: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].translator,
            editor: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].editor,
            numberOfPages: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].numberOfPages,
            typeOfTranslation: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].typeOfTranslation,
            workMetadataID: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].workMetadataID,
            workMetadataBookID: workBook == null || workBook.length == 0 ? null : workBook[0].workMetadataBookID,
            corporateAuthor: workBook == null || workBook.length == 0 ? null : workBook[0].corporateAuthor,
            availability: workBook == null || workBook.length == 0 ? null : workBook[0].availability,
            cityOfPublication: workBook == null || workBook.length == 0 ? null : workBook[0].cityOfPublication,
            collections: workBook == null || workBook.length == 0 ? null : workBook[0].collections,
            dimensions: workBook == null || workBook.length == 0 ? null : workBook[0].dimensions,
            typeOfBinding: workBook == null || workBook.length == 0 ? null : workBook[0].typeOfBinding,
            medium: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].medium,
            countryOfPublication: workBook == null || workBook.length == 0 ? null : workBook[0].cityOfPublication,
            languageOfPublication: workBook == null || workBook.length == 0 ? null : workBook[0].languageOfPublication,
            additionalMaterial: workBook == null || workBook.length == 0 ? null : workBook[0].additionalMaterial,
            typeOfMedium: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].typeOfMedium,
            editorialSeal: workBook == null || workBook.length == 0 ? null : workBook[0].editorialSeal,
            numVolume: workBook == null || workBook.length == 0 ? null : workBook[0].numVolume,
            typeOfBook: workBook == null || workBook.length == 0 ? null : workBook[0].typeOfBook,
            typeOfTeaching: workBook == null || workBook.length == 0 ? null : workBook[0].typeOfTeaching,
            discontinued: workFlag ? workFlag.filter(x => x.workFlagTypeCode === DISCONTINUED_CODE)[0]?.value === '1' : false,
            discontinuedFlagID: workFlag ? workFlag.filter(x => x.workFlagTypeCode === DISCONTINUED_CODE)[0]?.workFlagID : 0,
            publicationDate: workBook == null || workBook.length == 0 ? null : workBook[0].publicationDate
        };
        return book;
    }

    public static mapWorkChapterToChapter(workChapter: IWorkChapter[],
        workMetadata: IWorkMetadata[], workType: string): IChapter {
        const chapter: IChapter = {
            author: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].author,
            translator: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].translator,
            editor: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].editor,
            numberOfPages: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].numberOfPages,
            typeOfTranslation: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].typeOfTranslation,
            endPage: workChapter == null || workChapter.length == 0 ? null : workChapter[0].endPage,
            startPage: workChapter == null || workChapter.length == 0 ? null : workChapter[0].startPage,
            numberOfChapter: workChapter == null || workChapter.length == 0 ? null : workChapter[0].numberOfChapter,
            workMetadataChapterID: workChapter == null || workChapter.length == 0 ? null : workChapter[0].workMetadataChapterID,
            workMetadataID: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].workMetadataID,
            publicationDate: workMetadata == null || workMetadata.length === 0 ? null : workMetadata[0].publicationDate
        };
        return chapter;
    }

    public static mapWorkMagazineToMagazine(workMagazine: IWorkMagazine[],
        workMetadata: IWorkMetadata[], workType: string, workFlag: IWorkFlag[], dateOfRegistration: string): IMagazine {
        const magazine: IMagazine = {
            author: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].author,
            translator: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].translator,
            editor: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].editor,
            workMetadataID: workMetadata == null || workMetadata.length == 0 ? null : workMetadata[0].workMetadataID,
            workMetadataPublicationID: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].workMetadataPublicationID,
            becameABook: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].becameABook,
            dateOfRegistration: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].dateOfRegistration,
            endDateOfPublication: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].endDateOfPublication,
            startDateOfPublication: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].startDateOfPublication,
            isForeignEdition: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].isForeignEdition,
            isPublished: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].isPublished,
            licenseRegistration: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].licenseRegistration,
            localEditionOfNationalPress: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].localEditionOfNationalPress,
            periodicity: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].periodicity,
            typeOfPublication: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].typeOfPublication,
            url: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].url,
            universityDistributonComp: workFlag ? workFlag.filter(x => x.workFlagTypeCode === UNIVERSITY_DIST_COMP_CODE)[0]?.value === '1' : false,
            universityDistributonCompID: workFlag && workFlag.length != 0 ? workFlag.filter(x => x.workFlagTypeCode === UNIVERSITY_DIST_COMP_CODE)[0]?.workFlagID : 0,
            publicationDate: workMagazine == null || workMagazine.length == 0 ? null : workMagazine[0].publicationDate
        };
        return magazine;
    }

    public static mapArtistsToWorkPerformers(artists: IArtist[]): IWorkPerformer[] {
        if (artists) {
            const performers: IWorkPerformer[] = [];
            artists.map(artist => performers.push({
                workPerformerID: artist.workPerformerID,
                performerID: artist.performerID,
                firstName: artist.firstName.value ? artist.firstName.value : undefined,
                lastName: artist.lastName.value ? artist.lastName.value : undefined,
                type: artist.type.value ? artist.type.value : undefined,
                number: artist.number && artist.number.value ? [{ number: artist.number.value, type: 'ISNI', performerID: artist.performerID, performerNumberID: artist.performerNumberID }] : undefined,
                fullName: artist.fullName.value ? artist.fullName.value : undefined,
                isMaintained: false
            }));
            return performers;
        }
        else {
            return [];
        }
    }
    public static mapUsagePerformerToWorkPerformers(artists: string[]): IWorkPerformer[] {
        if (artists) {
            const performers: IWorkPerformer[] = [];
            artists.map(artist => performers.push({
                workPerformerID: 0,
                performerID: 0,
                firstName:  undefined,
                lastName:  undefined,
                type:  'Artist',
                number:  undefined,
                fullName: artist ? artist : undefined,
                isMaintained: false
            }));
            return performers;
        }
        else {
            return [];
        }
    }

    public static mapArticlesToWorkArticles(articles: IArticle, workType: string, workID: number): IWorkArticle[] {

        if (articles && workType === ARTICLE_WORK_TYPE_CODE) {
            if (articles.dateOfRegistration == null && articles.startPage == null && articles.endPage == null && articles.publicationDate == null &&
                articles.year == null && articles.volume == null && articles.url == null)
                return null;

            const workArticle: IWorkArticle[] = [];
            workArticle.push({
                dateOfRegistration: articles.dateOfRegistration,
                startPage: articles.startPage,
                endPage: articles.endPage,
                year: articles.year,
                volume: articles.volume,
                number: articles.number,
                url: articles.url,
                workID: workID,
                workMetadataArticleID: articles.workMetadataArticleID,
                publicationDate: articles.publicationDate
            });

            return workArticle;
        }
        else {
            return [];
        }
    }

    public static mapSheetMusicToWorkSheetMusic(sheetMusic: ISheetMusic, workType: string, workID: number): IWorkSheetMusic[] {

        if (sheetMusic.additionalMaterial == null && sheetMusic.author == null && sheetMusic.corporateAuthor == null && sheetMusic.dimensions == null &&
            sheetMusic.discontinued == null && sheetMusic.editor == null && sheetMusic.instrumentation == null && sheetMusic.medium == null && sheetMusic.musicalGenre == null && sheetMusic.musicalSubject == null && sheetMusic.numberOfPages == null)
            return null;

        if (sheetMusic && workType === SHEET_MUSIC_WORK_TYPE_CODE) {
            const workSheetMusic: IWorkSheetMusic[] = [];
            workSheetMusic.push({
                corporateAuthor: sheetMusic.corporateAuthor,
                dimensions: sheetMusic.dimensions,
                instrumentation: sheetMusic.instrumentation,
                musicalGenre: sheetMusic.musicalGenre,
                musicalSubject: sheetMusic.musicalSubject,
                typeOfBinding: sheetMusic.typeOfBinding,
                scorePublicationType: sheetMusic.scorePublicationType,
                sheetMusicType: sheetMusic.sheetMusicType,
                additionalMaterial: sheetMusic.additionalMaterial,
                workID: workID,
                workMetadataSheetMusicID: sheetMusic.workMetadataSheetMusicID,
                publicationDate: sheetMusic.publicationDate
            });

            return workSheetMusic;
        }
        else {
            return [];
        }
    }

    public static mapBookToWorkBook(book: IBook, workType: string, workID: number): IWorkBook[] {

        if (book.additionalMaterial == null && book.author == null && book.availability == null && book.cityOfPublication == null && book.collections == null && book.corporateAuthor == null &&
            book.countryOfPublication == null && book.dimensions == null && book.editor == null && book.editorialSeal == null)
            return null;

        if (book && workType === BOOK_WORK_TYPE_CODE) {
            const workBook: IWorkBook[] = [];
            workBook.push({
                corporateAuthor: book.corporateAuthor,
                dimensions: book.dimensions,
                availability: book.availability,
                cityOfPublication: book.cityOfPublication,
                collections: book.collections,
                typeOfBinding: book.typeOfBinding,
                countryOfPublication: book.countryOfPublication,
                editorialSeal: book.editorialSeal,
                languageOfPublication: book.languageOfPublication,
                numVolume: book.numVolume,
                typeOfBook: book.typeOfBook,
                typeOfTeaching: book.typeOfTeaching,
                additionalMaterial: book.additionalMaterial,
                workID: workID,
                workMetadataBookID: book.workMetadataBookID,
                publicationDate: book.publicationDate
            });

            return workBook;
        }
        else {
            return [];
        }
    }

    public static mapChapterToWorkChapter(chapter: IChapter, workType: string, workID: number): IWorkChapter[] {

        if (chapter.author == null && chapter.editor == null && chapter.endPage == null && chapter.numberOfChapter == null && chapter.numberOfPages == null &&
            chapter.startPage == null && chapter.translator == null && chapter.typeOfTranslation == null)
            return null;

        if (chapter && workType === CHAPTER_WORK_TYPE_CODE) {
            const workChapter: IWorkChapter[] = [];
            workChapter.push({
                numberOfChapter: chapter.numberOfChapter,
                startPage: chapter.startPage,
                endPage: chapter.endPage,
                workID: workID,
                workMetadataChapterID: chapter.workMetadataChapterID,
                publicationDate: chapter.publicationDate
            });

            return workChapter;
        }
        else {
            return [];
        }
    }

    public static mapMagazineToWorkMagazine(magazine: IMagazine, workType: string, workID: number): IWorkMagazine[] {

        if (magazine.author == null && magazine.becameABook == null && magazine.dateOfRegistration == null && magazine.editor == null && magazine.endDateOfPublication == null &&
            magazine.periodicity == null && magazine.startDateOfPublication == null && magazine.translator == null && magazine.typeOfPublication == null && magazine.url == null)
            return null;

        if (magazine && workType === MAGAZINE_WORK_TYPE_CODE) {
            const workMagazine: IWorkMagazine[] = [];
            workMagazine.push({
                dateOfRegistration: magazine.dateOfRegistration,
                endDateOfPublication: magazine.endDateOfPublication,
                startDateOfPublication: magazine.startDateOfPublication,
                becameABook: magazine.becameABook,
                isForeignEdition: magazine.isForeignEdition,
                isPublished: magazine.isPublished,
                licenseRegistration: magazine.licenseRegistration,
                localEditionOfNationalPress: magazine.localEditionOfNationalPress,
                periodicity: magazine.periodicity,
                typeOfPublication: magazine.typeOfPublication,
                url: magazine.url,
                workID: workID,
                workMetadataPublicationID: magazine.workMetadataPublicationID == null ? 0 : magazine.workMetadataPublicationID,
                publicationDate: magazine.publicationDate
            });

            return workMagazine;
        }
        else {
            return [];
        }
    }

    public static mapMetadataToWorkMetadata(article: IArticle, sheetMusic: ISheetMusic, book: IBook, chapter: IChapter, magazine: IMagazine, workType: string, workID: number): IWorkMetadata[] {
        const workMetadata: IWorkMetadata[] = [];
        if (article && workType === ARTICLE_WORK_TYPE_CODE) {
            if (article.author == null && article.translator == null && article.editor == null && article.numberOfPages == null && article.typeOfTranslation)
                return null;

            workMetadata.push({
                author: article.author,
                translator: article.translator,
                editor: article.editor,
                numberOfPages: article.numberOfPages,
                typeOfTranslation: article.typeOfTranslation,
                typeOfMedium: null,
                medium: null,
                workID: workID,
                workMetadataID: article.workMetadataID,
                publicationDate: article.publicationDate
            });
            return workMetadata;
        }
        else if (sheetMusic && workType === SHEET_MUSIC_WORK_TYPE_CODE) {
            if (sheetMusic.author == null && sheetMusic.translator == null && sheetMusic.editor == null && sheetMusic.numberOfPages == null && sheetMusic.typeOfTranslation == null && sheetMusic.medium == null && sheetMusic.typeOfMedium == null)
                return null;
            workMetadata.push({
                author: sheetMusic.author,
                translator: sheetMusic.translator,
                editor: sheetMusic.editor,
                numberOfPages: sheetMusic.numberOfPages,
                typeOfTranslation: sheetMusic.typeOfTranslation,
                medium: sheetMusic.medium,
                typeOfMedium: sheetMusic.typeOfMedium,
                workID: workID,
                workMetadataID: sheetMusic.workMetadataID,
                publicationDate: sheetMusic.publicationDate
            });
            return workMetadata;
        }
        else if (book && workType === BOOK_WORK_TYPE_CODE) {
            if (book.author == null && book.translator == null && book.editor == null && book.numberOfPages == null && book.typeOfTranslation == null && book.medium == null && book.typeOfMedium == null)
                return null;
            workMetadata.push({
                author: book.author,
                translator: book.translator,
                editor: book.editor,
                numberOfPages: book.numberOfPages,
                typeOfTranslation: book.typeOfTranslation,
                medium: book.medium,
                typeOfMedium: book.typeOfMedium,
                workID: workID,
                workMetadataID: book.workMetadataID,
                publicationDate: book.publicationDate
            });
            return workMetadata;
        }
        else if (chapter && workType === CHAPTER_WORK_TYPE_CODE) {
            if (chapter.author == null && chapter.translator == null && chapter.editor == null && chapter.numberOfChapter == null)
                return null;
            workMetadata.push({
                author: chapter.author,
                translator: chapter.translator,
                editor: chapter.editor,
                numberOfPages: chapter.numberOfPages,
                typeOfTranslation: chapter.typeOfTranslation,
                typeOfMedium: null,
                medium: null,
                workID: workID,
                workMetadataID: chapter.workMetadataID,
                publicationDate: chapter.publicationDate
            });
            return workMetadata;
        }
        else if (magazine && workType === MAGAZINE_WORK_TYPE_CODE) {
            if (magazine.editor == null && magazine.author == null && magazine.translator == null)
                return null;
            workMetadata.push({
                author: magazine.author,
                translator: magazine.translator,
                editor: magazine.editor,
                numberOfPages: null,
                typeOfTranslation: null,
                medium: null,
                typeOfMedium: null,
                workID: workID,
                workMetadataID: magazine.workMetadataID,
                publicationDate: magazine.publicationDate
            });
            return workMetadata;
        }
        else {
            return [];
        }
    }

    public static mapMetadataToWorkFlag(article: IArticle, sheetMusic: ISheetMusic, book: IBook, magazine: IMagazine, otherIndicators: IFlag[], readonlyIndicators: IFlag[], workType: string, workID: number): IWorkFlag[] {

        const workFlag: IWorkFlag[] = [];
        if (article && workType === ARTICLE_WORK_TYPE_CODE) {

            workFlag.push({
                workFlagID: workID == 0 ? workID : article.universityDistributonCompID,
                workID: workID,
                workFlagTypeCode: UNIVERSITY_DIST_COMP_CODE,
                createdDate: article.dateOfRegistration,
                lastModifiedBy: null,
                value: article.universityDistributonComp ? '1' : '0'
            });
        }
        else if (sheetMusic && workType === SHEET_MUSIC_WORK_TYPE_CODE) {

            workFlag.push({
                workFlagID: workID == 0 ? workID : sheetMusic.discontinuedFlagID,
                workID: workID,
                workFlagTypeCode: DISCONTINUED_CODE,
                createdDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
                lastModifiedBy: null,
                value: sheetMusic.discontinued ? '1' : '0'
            });
        }
        else if (book && workType === BOOK_WORK_TYPE_CODE) {

            workFlag.push({
                workFlagID: workID == 0 ? workID : book.discontinuedFlagID,
                workID: workID,
                workFlagTypeCode: DISCONTINUED_CODE,
                createdDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
                lastModifiedBy: null,
                value: book.discontinued ? '1' : '0'
            });

            otherIndicators.map(s => workFlag.push({
                workFlagID: s.flagID,
                workFlagTypeCode: s.flagTypeCode,
                value: s.value,
                createdDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
                lastModifiedBy: s.lastModifiedBy,
                workID: s.workID

            }))
        }
        else if (workType === CHAPTER_WORK_TYPE_CODE) {

            readonlyIndicators.map(s => workFlag.push({
                workFlagID: s.flagID,
                workFlagTypeCode: s.flagTypeCode,
                value: s.value,
                createdDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
                lastModifiedBy: s.lastModifiedBy,
                workID: s.workID

            }))
            return workFlag;
        }
        else if (magazine && workType === MAGAZINE_WORK_TYPE_CODE) {

            workFlag.push({
                workFlagID: workID == 0 ? workID : magazine.universityDistributonCompID,
                workID: workID,
                workFlagTypeCode: UNIVERSITY_DIST_COMP_CODE,
                createdDate: magazine.dateOfRegistration == null ? new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString() : magazine.dateOfRegistration,
                lastModifiedBy: null,
                value: magazine.universityDistributonComp ? '1' : '0'
            });
        }
        else {
            return workFlag;
        }

        readonlyIndicators.map(s => workFlag.push({
            workFlagID: s.flagID,
            workFlagTypeCode: s.flagTypeCode,
            value: s.value,
            createdDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
            lastModifiedBy: s.lastModifiedBy,
            workID: s.workID

        }))

        return workFlag;
    }

    public static mapCommentsToWorkNotes(comments: IComment[]): IWorkNote[] {
        if (comments) {
            const workNotes: IWorkNote[] = [];
            comments.map(comment => workNotes.push({
                notesID: comment.notesID,
                username: comment.user.value,
                lastModifiedDate: null,
                subject: comment.subject.value,
                noteText: comment.comment.value,
                isModified: comment.isModified,
                commentCategory: comment.commentCategory.value,
            }));
            return workNotes;
        }
        else {
            return [];
        }
    }

    public static mapWorkDuplicatesToMatchingWorks(duplicates: IWorkDuplicate[]): IMatchingWork[] {
        if (duplicates) {
            const matches: IMatchingWork[] = [];

            duplicates.map((d, index) => {

                let match: IMatchingWork = { repertoireWorkID: d.repertoireWorkID, workID: index, isDefinite: { value: d.isDefinite, inputType: CHECKBOX_INPUT }, name: [], number: [], publisher: [], writer: [], artist: [] };

                d.name.map((n) => { match.name.push({ value: n, inputType: READ_ONLY_INPUT }) });
                d.number.map((n) => { match.number.push({ value: n, inputType: READ_ONLY_INPUT }) });
                d.publisher.map((p) => { match.publisher.push({ value: p, inputType: READ_ONLY_INPUT }) });
                d.writer.map((w) => { match.writer.push({ value: w, inputType: READ_ONLY_INPUT }) });
                d.artist.map((a) => { match.artist.push({ value: a, inputType: READ_ONLY_INPUT }) });

                matches.push(match);
            });

            return matches;
        }
        else {
            return [];
        }
    }

    public static mapMatchingWorksToWorkDuplicates(matchingWorks: IMatchingWork[]): IWorkDuplicate[] {
        if (matchingWorks) {
            const duplicates: IWorkDuplicate[] = [];

            matchingWorks.map((m, index) => {

                let duplicate: IWorkDuplicate = { repertoireWorkID: m.repertoireWorkID, isDefinite: m.isDefinite.value, name: [], number: [], publisher: [], writer: [], artist: [] };

                m.name.map((n) => { duplicate.name.push(n.value) });
                m.number.map((n) => { duplicate.number.push(n.value) });
                m.publisher.map((n) => { duplicate.publisher.push(n.value) });
                m.writer.map((n) => { duplicate.writer.push(n.value) });
                m.artist.map((n) => { duplicate.artist.push(n.value) });

                duplicates.push(duplicate);
            });

            return duplicates;
        }
        else {
            return [];
        }
    }

    public static mapIntrayWorkDuplicatesToIntrayMatchingWorks(duplicates: IIntrayWorkDuplicate[]): IIntrayMatchingWork[] {
        if (duplicates) {
            const matches: IIntrayMatchingWork[] = [];

            duplicates.map((d, index) => {
                matches.push({
                    duplicateIntrayWorkID: d.duplicateIntrayWorkID,
                    isDefinite: { value: d.isDefinite, inputType: CHECKBOX_INPUT },
                    name: { value: d.name, inputType: READ_ONLY_INPUT },
                    number: { value: d.number, inputType: READ_ONLY_INPUT },
                    contributorName: { value: d.contributorName, inputType: READ_ONLY_INPUT },
                    workID: index

                })
            });

            return matches;
        }
        else {
            return [];
        }
    }

    public static mapIntrayMatchingWorksToIntrayWorkDuplicates(matchingWorks: IIntrayMatchingWork[]): IIntrayWorkDuplicate[] {
        if (matchingWorks) {
            const duplicates: IIntrayWorkDuplicate[] = [];

            matchingWorks.map((m, index) => {
                duplicates.push({
                    duplicateIntrayWorkID: m.duplicateIntrayWorkID,
                    isDefinite: m.isDefinite.value,
                    name: m.name.value,
                    number: m.number.value,
                    contributorName: m.contributorName.value
                })
            });

            return duplicates;
        }
        else {
            return [];
        }
    }

    public static mapWorkNotesToComments(notes: IWorkNote[]): IComment[] {
        if (notes) {
            const comments: IComment[] = [];
            notes.map(n => {
                comments.push({
                    notesID: n.notesID,
                    user: { value: n.username, inputType: "" },
                    date: { value: n.lastModifiedDate, inputType: "" },
                    subject: { value: n.subject, inputType: TEXT_INPUT },
                    comment: { value: n.noteText, inputType: TEXT_INPUT },
                    isModified: n.isModified,
                    commentCategory: { value: n.commentCategory, inputType: DROPDOWN_INPUT },
                })
            });
            return comments;
        }
        else {
            return [];
        }
    }

    public static mapWorkContributorsOtherToContributors(workContributors: IWorkContributorOther[]): IContributorItem[] {
        if (workContributors) {
            const contributors: IContributorItem[] = [];
            workContributors.map(n => {
                contributors.push({
                    setType: 'other',
                    ipiNumber: { value: n.ipiNumber ? n.ipiNumber.toString() : null, inputType: TEXT_INPUT },
                    ipBaseNumber: n.ipBaseNumber,
                    accountNumber: { value: n.accountNumber, inputType: READ_ONLY_INPUT },
                    name: { value: n.fullName, inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT },
                    role: { value: n.type, inputType: DROPDOWN_INPUT },
                    subType: { value: n.subType, inputType: DROPDOWN_INPUT },
                    perfPercentage: { value: n.performingShare, inputType: INTEGER_PERCENTAGE_INPUT },
                    mechPercentage: { value: n.mechanicalShare, inputType: INTEGER_PERCENTAGE_INPUT },
                    relation: { value: "", inputType: TEXT_INPUT },
                    territory: { value: n.territory, inputType: DROPDOWN_INPUT },
                    source: { value: n.source, inputType: DROPDOWN_INPUT },
                    regDate: { value: n.registrationDate, inputType: DATE_INPUT },
                    percentageOfPub: { value: n.writerPublisherShare ? n.writerPublisherShare.toString() : "", inputType: TEXT_INPUT },
                    reference:{value: n.reference? n.reference: "", inputType: TEXT_INPUT},
                    workContributorID: n.workContributorOtherID,
                    setTypeGroup: n.setTypeGroup,
                    agreementChain: n.agreementChain ? n.agreementChain : [],
                    mechSocietyAffiliation: n.mechSocietyAffiliation ? n.mechSocietyAffiliation.toString() : EMPTY_STRING_VALUE,
                    perfSocietyAffiliation: n.perfSocietyAffiliation ? n.perfSocietyAffiliation.toString() : EMPTY_STRING_VALUE,
                    senderRep: { value: n.senderRep === null ? null : n.senderRep === true ? 'True' : 'False', inputType: DROPDOWN_INPUT },
                    sourceSociety: n.sourceSociety,
                    sourceIP: n.sourceIP,
                    sourceType: n.sourceType,
                    relationshipIDs: n.relationshipIDs,
                    performingShareToDisplay: { value: n.performingShare.toString(), inputType: INTEGER_PERCENTAGE_INPUT },
                    mechanicalShareToDisplay: { value: n.mechanicalShare.toString(), inputType: INTEGER_PERCENTAGE_INPUT },
                    societyOfLicense: { value: n.societyOfLicense, inputType: DROPDOWN_INPUT },
                })
            });
            return contributors;
        }
        else {
            return [];
        }
    }

    public static mapContributorsToWorkContributorsOther(contributorsOther: IContributorItem[], intrayWorkId: number): IWorkContributorOther[] {
        if (contributorsOther) {
            const workContributorsOther: IWorkContributorOther[] = [];
            contributorsOther.map(contributor => workContributorsOther.push({
                fullName: contributor.name.value,
                workContributorOtherID: contributor.workContributorID,
                contributorType: 1,
                performingShare: Number(contributor.perfPercentage.value),
                mechanicalShare: Number(contributor.mechPercentage.value),
                intrayWorkID: intrayWorkId,
                source: contributor.source.value,
                writerPublisherShare: isNaN(parseInt(contributor.percentageOfPub.value)) ? null : Number(contributor.percentageOfPub.value),
                senderRep: contributor.senderRep.value === null || contributor.senderRep.value.length === 0 ? null : contributor.senderRep.value.toString().toLowerCase() === 'true' ? true : false,
                setTypeGroup: contributor.setTypeGroup,
                territory: contributor.territory.value,
                registrationDate: contributor.regDate.value,
                ipiNumber: contributor.ipiNumber.value ? Number(contributor.ipiNumber.value) : null,
                ipBaseNumber: contributor.ipBaseNumber,
                type: contributor.role.value,
                subType: contributor.subType.value,
                accountNumber: contributor.accountNumber.value,
                agreementChain: contributor.agreementChain ? contributor.agreementChain : [],
                mechSocietyAffiliation: contributor.mechSocietyAffiliation ? contributor.mechSocietyAffiliation.toString() : EMPTY_STRING_VALUE,
                perfSocietyAffiliation: contributor.perfSocietyAffiliation ? contributor.perfSocietyAffiliation.toString() : EMPTY_STRING_VALUE,
                sourceSociety: contributor.sourceSociety,
                sourceIP: contributor.sourceIP,
                sourceType: contributor.sourceType,
                relationshipIDs: contributor.relationshipIDs,
                reference: contributor.reference? contributor.reference.value: null ,
                societyOfLicense: contributor.societyOfLicense.value,
            }));
            return workContributorsOther;
        }
        else {
            return [];
        }
    }

    public static mapWorkContributorsToContributors(workContributors: IWorkContributor[]): IContributorItem[] {
        if (workContributors) {
            const contributors: IContributorItem[] = [];
            workContributors.map(n => {
                contributors.push({
                    ipiNumber: { value: n.ipiNumber ? n.ipiNumber.toString() : null, inputType: TEXT_INPUT },
                    ipBaseNumber: n.ipBaseNumber,
                    accountNumber: { value: n.accountNumber, inputType: READ_ONLY_INPUT },
                    name: { value: n.fullName, inputType: TEXT_READONLY_IN_REPERTOIRE_INPUT },
                    role: { value: n.type, inputType: DROPDOWN_INPUT },
                    subType: { value: n.subType, inputType: DROPDOWN_INPUT },
                    perfPercentage: { value: n.performingShare, inputType: INTEGER_PERCENTAGE_INPUT },
                    mechPercentage: { value: n.mechanicalShare, inputType: INTEGER_PERCENTAGE_INPUT },
                    relation: { value: "", inputType: TEXT_INPUT },
                    territory: { value: n.territory, inputType: DROPDOWN_INPUT },
                    source: { value: n.source, inputType: DROPDOWN_INPUT },
                    regDate: { value: n.registrationDate, inputType: DATE_INPUT },
                    percentageOfPub: { value: n.writerPublisherShare ? n.writerPublisherShare.toString() : "", inputType: TEXT_INPUT },
                    reference:{value: n.reference? n.reference: "", inputType: TEXT_INPUT},
                    workContributorID: n.workContributorID,
                    setType: n.setType,
                    setTypeGroup: n.setTypeGroup,
                    agreementChain: n.agreementChain ? WorkDetailsMapper.mapAgreementChain(n.agreementChain) : [],
                    mechSocietyAffiliation: n.mechSocietyAffiliation ? n.mechSocietyAffiliation.toString() : EMPTY_STRING_VALUE,
                    perfSocietyAffiliation: n.perfSocietyAffiliation ? n.perfSocietyAffiliation.toString() : EMPTY_STRING_VALUE,
                    senderRep: { value: n.senderRep === null ? null : n.senderRep === true ? 'True' : 'False', inputType: DROPDOWN_INPUT },
                    sourceSociety: n.sourceSociety,
                    sourceIP: n.sourceIP,
                    sourceType: n.sourceType,
                    relationshipIDs: n.relationshipIDs,
                    performingShareToDisplay: { value: (n.performingShareToDisplay === -1) ?'':n.performingShareToDisplay.toString(), inputType: INTEGER_PERCENTAGE_INPUT },
                    mechanicalShareToDisplay: { value: (n.mechanicalShareToDisplay === -1)?'':n.mechanicalShareToDisplay.toString(), inputType: INTEGER_PERCENTAGE_INPUT },
                    copyLevyPercentage: n.workContributorShares ? { value: n.workContributorShares.filter(x => x.typeCode == LEVY_COPY_TYPE_CODE)[0]?.share, inputType: INTEGER_INPUT } : { value: null, inputType: INTEGER_INPUT },
                    copyLicencePercentage: n.workContributorShares ? { value: n.workContributorShares.filter(x => x.typeCode == LICENCE_COPY_TYPE_CODE)[0]?.share, inputType: INTEGER_INPUT } : { value: null, inputType: INTEGER_INPUT },
                    subTypeID: n.subTypeID,
                    comments: n.comments ? n.comments : null,
                    societyOfLicense: { value: n.societyOfLicense, inputType: DROPDOWN_INPUT },
                })
            });
            return contributors;
        }
        else {
            return [];
        }
    }

    public static mapContributorsToWorkContributors(contributors: IContributorItem[], intrayWorkId: number, lookups: ILookupDictionary, customer?: string): IWorkContributor[] {
        if (contributors) {
            const workContributors: IWorkContributor[] = [];
            contributors.map(contributor => workContributors.push({
                fullName: contributor.name.value,
                workContributorID: contributor.workContributorID,
                setType: contributor.setType,
                contributorType: 1,
                performingShare: Number(contributor.perfPercentage.value),
                mechanicalShare: Number(contributor.mechPercentage.value),
                intrayWorkID: intrayWorkId,
                source: contributor.source.value,
                writerPublisherShare: isNaN(parseInt(contributor.percentageOfPub.value)) ? null : Number(contributor.percentageOfPub.value),
                senderRep: contributor.senderRep.value === null || contributor.senderRep.value.length === 0 ? null : contributor.senderRep.value.toString().toLowerCase() === 'true' ? true : false,
                setTypeGroup: contributor.setTypeGroup,
                territory: contributor.territory.value,
                registrationDate: contributor.regDate.value,
                ipiNumber: contributor.ipiNumber.value ? Number(contributor.ipiNumber.value) : null,
                ipBaseNumber: contributor.ipBaseNumber,
                type: contributor.role.value,
                subType: contributor.subType.value,
                accountNumber: contributor.accountNumber.value,
                agreementChain: contributor.agreementChain ? this.mapAgreementChainWork(contributor.agreementChain) : [],
                mechSocietyAffiliation: contributor.mechSocietyAffiliation ? contributor.mechSocietyAffiliation.toString() : EMPTY_STRING_VALUE,
                perfSocietyAffiliation: contributor.perfSocietyAffiliation ? contributor.perfSocietyAffiliation.toString() : EMPTY_STRING_VALUE,
                sourceSociety: contributor.sourceSociety,
                sourceIP: contributor.sourceIP,
                sourceType: contributor.sourceType,
                relationshipIDs: contributor.relationshipIDs,
                performingShareToDisplay: Number(contributor.perfPercentage.value),
                mechanicalShareToDisplay: Number(contributor.mechPercentage.value),
                subTypeID: contributor.subTypeID,
                workContributorShares: this.mapWorkContributorShare(contributor, lookups, customer) ,
                comments: contributor.comments ? contributor.comments : null,
                societyOfLicense: contributor.societyOfLicense.value,
                reference: contributor.reference? contributor.reference.value :null
            }));
            return workContributors;
        }
        else {
            return [];
        }
    }
    public static mapUsageContributorsToWorkContributors(contributors: string[]): IWorkContributor[] {
        if (contributors) {
            const workContributors: IWorkContributor[] = [];
            contributors.map(contributor => workContributors.push({
                fullName: contributor,
                workContributorID: 0,
                setType: 'repertoire',
                contributorType: 1,
                performingShare: 0,
                mechanicalShare: 0,
                intrayWorkID: null,
                source: '',
                writerPublisherShare: undefined,
                senderRep: null,
                setTypeGroup: 'US',
                territory: '',
                registrationDate: null,
                ipiNumber:  undefined,
                ipBaseNumber: null,
                type: 'C',
                subType: null,
                accountNumber: '',
                agreementChain:  [],
                mechSocietyAffiliation:  EMPTY_STRING_VALUE,
                perfSocietyAffiliation:  EMPTY_STRING_VALUE,
                sourceSociety: '',
                sourceIP: null,
                sourceType: null,
                relationshipIDs: null,
                performingShareToDisplay: -1,
                mechanicalShareToDisplay: -1,
                subTypeID: null,
                workContributorShares: undefined ,
                comments:  '',
                societyOfLicense: null,
                reference: ''
            }));
            return workContributors;
        }
        else {
            return [];
        }
    }

    public static mapWorkContainedWorkToContainedWorks(work: IWorkContainedWork[]): IContainedWork[] {
        if (work) {
            const stateWork: IContainedWork[] = [];
            work.map(a => {
                stateWork.push({
                    containedWorkID: a.containedWorkID,
                    workNumberID: a.workID,
                    workCode: a.workCode,
                    title: { value: a.title, inputType: READ_ONLY_INPUT },
                    number: { value: a.number, inputType: READ_ONLY_INPUT },
                    writer: { value: a.writer, inputType: READ_ONLY_INPUT },
                    duration: { value: a.duration, inputType: TIMESPAN_INPUT },
                    intrayIndicator: { value: a.workDataSource === DataSource.Intray ? true : false, inputType: BOOLEAN_READONLY_INPUT },
                })
            });
            return stateWork;
        }
        else {
            return [];
        }
    }

    public static mapParentWorksToState(work: IWorkParentWork[]): IContainedWork[] {
        if (work) {
            const stateParentWorks: IContainedWork[] = [];

            work.map(a => {

                stateParentWorks.push({
                    containedWorkID: a.containedWorkID,
                    workNumberID: a.workID,
                    workCode: a.workCode,
                    title: { value: a.title, inputType: READ_ONLY_INPUT },
                    number: { value: a.number, inputType: READ_ONLY_INPUT },
                    duration: { value: 0, inputType: TIMESPAN_INPUT },
                    writer: { value: EMPTY_STRING_VALUE, inputType: READ_ONLY_INPUT },
                    intrayIndicator: undefined,
                    dataSource: a.dataSource != undefined ? a.dataSource : undefined
                })
            });

            return stateParentWorks;
        }
        else {
            return []
        }
    }

    public static mapContainedWorkToWorkContainedWorks(work: IContainedWork[]): IWorkContainedWork[] {
        if (work) {
            const workContainedWork: IWorkContainedWork[] = [];
            work.map(a => {

                workContainedWork.push({
                    containedWorkID: a.containedWorkID,
                    workID: a.workNumberID,
                    workCode: a.workCode,
                    title: a.title.value,
                    number: a.number.value,
                    writer: a.writer.value,
                    duration: a.duration.value,
                    workDataSource: a.intrayIndicator.value ? DataSource.Intray : DataSource.Repertoire,
                })
            });
            return workContainedWork;
        }
        else {
            return [];
        }
    }

    public static mapSourceIPToWorkSourceIP(sourceIP: ISourceIP): IWorkSourceIP {
        if (sourceIP) {
            const workSource: IWorkSourceIP = {
                workSourceIPID: sourceIP.sourceIPID,
                ipiNameNumber: sourceIP.ipiNameNumber,
                fullName: sourceIP.fullName.value,
            };

            return workSource;
        }
        else {
            return null;
        }
    }

    public static mapWorkSourceIPToSourceIP(workSourceIP: IWorkSourceIP): ISourceIP {
        if (workSourceIP) {

            const workSource: ISourceIP = {
                sourceIPID: workSourceIP.workSourceIPID,
                ipiNameNumber: workSourceIP.ipiNameNumber,
                fullName: { value: workSourceIP.fullName, inputType: TEXT_INPUT },
            };

            return workSource;
        }
        else {
            return null;
        }
    }

    public static mapWorkInstrumentationToInstrumentation(workInstrumentation: IWorkInstrumentation[]): IInstrumentation[] {
        if (workInstrumentation) {
            const instrumentation: IInstrumentation[] = [];
            workInstrumentation.map(i => {
                if (i.instrumentationCode !== 'CUSTOM') {
                    instrumentation.push(
                        {
                            type: { value: i.instrumentationCode, inputType: DROPDOWN_INPUT },
                            instrumentationID: i.workInstrumentationID,
                            description: { value: '', inputType: CUSTOM_INPUT },
                            workInstruments: []
                        })
                } else {
                    let instruments: IInstrument[] = [];
                    i.workInstruments.forEach(x => instruments.push({ workInstrumentID: x.workInstrumentID, instrument: { value: x.instrument, inputType: CUSTOM_INPUT } }));

                    instrumentation.push(
                        {
                            type: { value: i.instrumentationCode, inputType: DROPDOWN_INPUT },
                            instrumentationID: i.workInstrumentationID,
                            description: { value: i.description, inputType: CUSTOM_INPUT },
                            workInstruments: instruments
                        })
                }
            });
            return instrumentation;
        }
        else {
            return [];
        }
    }

    public static mapInstrumentationToWorkInstrumentation(instrumentation: IInstrumentation[]): IWorkInstrumentation[] {
        if (instrumentation) {
            const workInstrumentation: IWorkInstrumentation[] = [];
            instrumentation.map(i => {
                if (i.type.value !== 'CUSTOM') {
                    workInstrumentation.push({
                        instrumentationCode: i.type.value,
                        workInstrumentationID: i.instrumentationID,
                        description: '',
                        workInstruments: []
                    })
                } else {
                    let instruments: IWorkInstrument[] = [];
                    i.workInstruments.forEach(x =>
                        instruments.push({ workInstrumentID: x.workInstrumentID, instrument: x.instrument.value ? x.instrument.value : x.instrument.toString() })
                    );

                    workInstrumentation.push({
                        instrumentationCode: i.type.value,
                        workInstrumentationID: i.instrumentationID,
                        description: i.description.value,
                        workInstruments: instruments
                    })
                }
            });
            return workInstrumentation;
        }
        else {
            return [];
        }
    }

    public static mapWorkWorkflowToWorkflow(workflow: IWorkflow[]): IWorkflow[] {
        if (workflow) {
            const workContainedWork: IWorkflow[] = [];
            workflow.map(a =>
                workContainedWork.push({
                    id: a.id,
                    entityType: a.entityType,
                    status: a.status,
                    workflowType: a.workflowType,
                    entityID: a.entityID,
                    setType: a.setType,
                    assignedTo: a.assignedTo,
                    dateCreated: a.dateCreated,
                    dateLastUpdated: a.dateLastUpdated,
                    references: a.references,
                    values: a.values,
                    actions: a.actions,
                    errorMessages: a.errorMessages,
                    rejectReason: a.rejectReason
                }));
            return workContainedWork;

        }
        else {
            return [];
        }
    }

    public static mapWorkflowToWorkWorkflow(workflow: IWorkflow[]): IWorkflow[] {
        if (workflow) {
            const workContainedWork: IWorkflow[] = [];
            workflow.map(a =>
                workContainedWork.push({
                    id: a.id,
                    entityType: a.entityType,
                    status: a.status,
                    workflowType: a.workflowType,
                    entityID: a.entityID,
                    setType: a.setType,
                    assignedTo: a.assignedTo,
                    dateCreated: a.dateCreated,
                    dateLastUpdated: a.dateLastUpdated,
                    references: a.references,
                    values: a.values,
                    actions: a.actions,
                    errorMessages: a.errorMessages,
                    rejectReason: a.rejectReason
                }));
            return workContainedWork;

        }
        else {
            return [];
        }
    }

    public static mapReplacedWorkToWorkReplacedWork(replacedWorks: IReplacedWork[]): IWorkReplacedWork[] {
        const workReplacedWorks: IWorkReplacedWork[] = [];
        if (replacedWorks) {
            replacedWorks.map(rw =>
                workReplacedWorks.push({
                    replacedWorkID: rw.replacedWorkID,
                    name: rw.workName.value,
                    number: rw.number.value
                }));
        }
        return workReplacedWorks;
    }

    public static mapWorkReplacedWorktoReplacedWork(workReplacedWorks: IWorkReplacedWork[]): IReplacedWork[] {
        const replacedWorks: IReplacedWork[] = [];
        if (workReplacedWorks) {
            workReplacedWorks.map(rw =>
                replacedWorks.push({
                    workName: { value: rw.name, inputType: "" },
                    number: { value: rw.number, inputType: "" },
                    replacedWorkID: rw.replacedWorkID
                }));
        }
        return replacedWorks
    }

    public static getOriginalTitleFromWorkNames(names: IWorkName[]): string {
        if (Object.keys(names).length == 0 || names === null || names === undefined) {
            return 'New Work';
        }
        const original = names.find(n => n.type == 'OT');
        if (original) {
            return original.name;
        }
        return names[0].name;
    }

    public static getOriginalFullTitleFromWorkNames(names: IWorkName[], workID: number): string {
        if (!names) {
            return 'New Work';
        }
        const original = names.find(n => n.type == 'OT');
        if (original) {
            return '(' + workID.toString() + ') ' + original.name;
        }
        return '(' + workID.toString() + ') ' + names[0].name;
    }
    public static getOriginalTitleFromTitles(workID: number, titles: ITitle[]): string {
        if (!titles || titles.length === 0) {
            if (workID === 0)
                return 'untitled';
            else {
                return '(' + workID.toString() + ') ';
            }
        }
        const original = titles.find(t => t.type.value == 'OT');
        let title: string;
        if (original) {
            title = original.title.value;
        }
        else {
            title = titles[0].title.value;
        }
        if (workID === 0)
            return title;
        else {
            return '(' + workID.toString() + ') ' + title;
        }
    }

    public static mapRepresentationToWorkRepresentation(workRepresentations: IWorkRepresentation[]): IRepresentation[] {
        const representations: IRepresentation[] = [];

        if (workRepresentations) {
            workRepresentations.map(r =>
                representations.push({
                    description: r.description,
                    endDate: r.endDate,
                    startDate: r.startDate,
                    ipBaseNumber: r.ipBaseNumber,
                    representationGroup: r.representationGroup,
                    representationType: r.representationType,
                    representationGroupId: r.representationGroupId,
                    representationTypeId: r.representationTypeId,
                    useId: r.useId,
                    workGroupId: r.workGroupId,
                    workRepresentationID: r.workRepresentationID
                }));
        }
        return representations;


    }

    public static mapWorkPriceToPrice(workPrice: IWorkPrice[]): IPrice[] {
        const price: IPrice[] = [];

        if (workPrice) {
            workPrice.map(p =>
                price.push({
                    workPriceID: p.workPriceID,
                    workID: p.workID,
                    notes: p.notes,
                    startDate: p.startDate.value,
                    endDate: p.endDate.value,
                    price: p.price.value,
                    weight: p.weight? p.weight.value:null,
                    publisherPrice: p.publisherPrice.value,
                    source: p.source,
                    lastModifiedDate: p.lastModifiedDate,
                    isDeleted: p.isDeleted
                }));
        }
        return price;


    }

    public static mapPriceToWorkPrice(price: IPrice[]): IWorkPrice[] {
        const workPrice: IWorkPrice[] = [];

        if (price) {
            price.map(p =>
                workPrice.push({
                    workPriceID: p.workPriceID,
                    workID: p.workID,
                    notes: p.notes,
                    startDate: { value: p.startDate, inputType: DATE_INPUT },
                    endDate: { value: p.endDate, inputType: DATE_INPUT },
                    price: { value: p.price, inputType: INTEGER_INPUT }, 
                    weight: { value: p.weight, inputType: INTEGER_INPUT }, 
                    publisherPrice: { value: p.publisherPrice, inputType: INTEGER_INPUT }, 
                    source: p.source,
                    lastModifiedDate: p.lastModifiedDate,
                    isDeleted: p.isDeleted
                }));
        }
        return workPrice;


    }

    public static mapWorkEditionToEdition(workEdition: IEdition[]): IWorkEditionDataItems[] {
        if (workEdition) {
            const stateWorkEdition: IWorkEditionDataItems [] = [];
            workEdition.map(s =>
                stateWorkEdition.push({
                    workEditionID: s.workEditionID,
                    workID: s.workID,
                    createdDate: { value: s.createdDate, inputType: READ_ONLY_INPUT },
                    lastModifiedDate: { value: s.lastModifiedDate, inputType: READ_ONLY_INPUT },
                    isDeleted: { value: s.isDeleted, inputType: READ_ONLY_INPUT },
                    editionNumber: { value: s.editionNumber, inputType: READ_ONLY_INPUT },
                    editionOrigin: { value: s.editionOrigin, inputType: READ_ONLY_INPUT },
                    numberOfCopiesInCirculation: { value: s.numberOfCopiesInCirculation, inputType: INTEGER_INPUT },
                    numberOfPrinting: { value: s.numberOfPrinting, inputType: INTEGER_INPUT },
                    workNumberPrintedInEdition: { value: s.workNumberPrintedInEdition, inputType: INTEGER_INPUT },
                    completationEditionDate: { value: s.completationEditionDate, inputType: READ_ONLY_INPUT },
                    dateOfPrinting: { value: s.dateOfPrinting, inputType: READ_ONLY_INPUT },
                    lastModifiedBy: { value: s.lastModifiedBy, inputType: READ_ONLY_INPUT },
                    yearOfEdition: { value: s.yearOfEdition, inputType: READ_ONLY_INPUT }
                }));
                return stateWorkEdition;
        } else {
            return [];
        }
    }

    public static mapEditionToWorkEdition(editions: IWorkEditionDataItems[]): IEdition[] {
        const workEdition: IEdition[] = [];

        if (workEdition) {
            const stateWorkEdition: IEdition [] = [];
            workEdition.map(s =>
                stateWorkEdition.push({
                    workEditionID: s.workEditionID,
                    workID: s.workID,
                    createdDate: s.createdDate,
                    lastModifiedDate: s.lastModifiedDate,
                    isDeleted: s.isDeleted,
                    editionNumber: s.editionNumber,
                    editionOrigin: s.editionOrigin,
                    numberOfCopiesInCirculation: s.numberOfCopiesInCirculation,
                    numberOfPrinting: s.numberOfPrinting,
                    workNumberPrintedInEdition: s.workNumberPrintedInEdition,
                    completationEditionDate: s.completationEditionDate,
                    dateOfPrinting: s.dateOfPrinting,
                    lastModifiedBy: s.lastModifiedBy,
                    yearOfEdition: s.yearOfEdition
                }));
                return workEdition;
        } else {
            return [];
        }
    }

    public static mapWorkSubjectToSubject(workSubject: IWorkSubject[]): ISubject[] {
        const subject: ISubject[] = [];

        if (workSubject) {
            workSubject.map(s =>
                subject.push({
                    SubjectID: s.workSubjectID,
                    workID: s.workID,
                    subject: {inputType: TEXT_INPUT, value: s.subject},
                    subjectDeclared: s.subjectDeclared,
                    startDate: { inputType: DATE_INPUT, value: s.startDate },
                    endDate: { inputType: DATE_INPUT, value: s.endDate},
                    notes: {inputType: TEXT_INPUT, value: s.notes},
                    source: {inputType: TEXT_INPUT, value: s.source},
                    percentage: {inputType: TEXT_INPUT, value: s.percentage},
                    createdDate: {inputType: TEXT_INPUT, value: s.createdDate},
                    lastModifiedDate: {inputType: TEXT_INPUT, value: s.lastModifiedDate},
                    isDeleted: s.isDeleted,
                }));
        }
        return subject;


    }

    public static mapSubjectToWorkSubject(subject: ISubject[]): IWorkSubject[] {
        const workSubject: IWorkSubject[] = [];

        if (subject) {
            subject.filter(x => x.subject != null || x.subject != undefined).map(s =>
            {
                if (s.subject.value != null && s.subject.value != undefined && s.subject.value !== EMPTY_STRING_VALUE) {
                    workSubject.push({
                        workSubjectID: s.SubjectID,
                        workID: s.workID,
                        subject: s.subject.value,
                        startDate: s.startDate.value ? s.startDate.value : null,
                        endDate: s.endDate.value ? s.endDate.value : null,
                        percentage: s.percentage.value,
                        createdDate: new Date(),
                        lastModifiedDate: new Date(),
                        isDeleted: s.isDeleted,
                    })
                }
            });
        }
        return workSubject;


    }

    public static mapWorkToWorkState(work: IWork, lookupValues: ILookupDictionary,
        otherIndicatorsWorkFlagTypes?: string[],
        readonlyIndicatorsWorkFlagTypes?: string[]): IWorkState {
        if (!work || !lookupValues)
            return null;

        const {
            workID,
            stampID,
            isMaintained,
            dateOfRegistration,
            lastModifiedDate,
            source,
            sourceType,
            sourceSociety,
            duration,
            isFilmTVCommissioned,
            isDramaticoMusical,
            isAdUse,
            isOriginalWork,
            genre,
            distributionCategory,
            workType,
            isGrandRights,
            isLibraryWork,
            isCommissionedForCommercial,
            isRemix,
            medleyType,
            medleyCount,
            workBatchID,
            dataSource,
            fileImportID,
            workContributorSetTypes,
            versionNumber,
            workSubmissions,
            workSubmissionStatuses,
            iswcStatus,
            iswcSubmissionRetries,
            cisNetStatus,
            cisNetSubmissionRetries,
            widStatus,
            widSubmissionRetries,
            siteConfigurationJson,
            workContainedWorks,
            isArrangement,
            sourceIP,
            attachedFile,
            isDistributed,
            isMaintainedMCPSI,
            parentWorks,
            musicSource,
            workPart,
            workCreationDate,
            workEdition,
            validateRoleBasedOnIpiTypeWarning,
            showCommentSubjectAsDropdown,
            isWorkAttachmentTypeNotRequired,
            isWorkAttachmentTypeOptional,
            workNames,
            workBatch,
            isReplaced,
            concurrency,
            isMaintainedLibraryWork,
            musicArrangement,
            lyricAdaptation,
            firstPerformance,
            iceWorkKeyHyperlink,
            additionalAttributeAssigned
        } = work;

        const wp = work.workTerritorySpecificInfo;

        const workTerritorySpecificInfo: IWorkTerritorySpecificInfo[] = wp && wp.length > 0 ? wp : [{
            workTerritorySpecificInfoID: 0,
            isPDArrangement: false,
            isControlledComposition: false,
            isPCSpecific: false,
            isNonRoyaltyBearing: false,
            setTypeGroup: getLookupDefault(SET_TYPE_GROUP_LOOKUP, lookupValues),
            isOutOfCopyright: false,
            workStatus: getLookupDefault(WORK_STATUS_LOOKUP, lookupValues),
            workSubStatus: getLookupDefault(WORK_SUB_STATUS_LOOKUP, lookupValues),
            domesticOrForeign: null,
            validForUsageFrom: null,
            validForUsageTo: null,
            genreCategory: getLookupDefault(WORK_GENRE_CATEGORY_LOOKUP, lookupValues),
        }];

        if (!workTerritorySpecificInfo.some(x => x.setTypeGroup === getLookupDefault(SET_TYPE_GROUP_LOOKUP, lookupValues))) {
            workTerritorySpecificInfo.push({
                workTerritorySpecificInfoID: 0,
                isPDArrangement: false,
                isControlledComposition: false,
                isPCSpecific: false,
                isNonRoyaltyBearing: false,
                setTypeGroup: getLookupDefault(SET_TYPE_GROUP_LOOKUP, lookupValues),
                isOutOfCopyright: false,
                workStatus: getLookupDefault(WORK_STATUS_LOOKUP, lookupValues),
                workSubStatus: getLookupDefault(WORK_SUB_STATUS_LOOKUP, lookupValues),
                domesticOrForeign: null,
                validForUsageFrom: null,
                validForUsageTo: null,
                genreCategory: getLookupDefault(WORK_GENRE_CATEGORY_LOOKUP, lookupValues),
            })
        }
        const territorySpecificInfo: ITerritorySpecificInfo = {
            workTerritorySpecificInfo, setTypeGroup: getLookupDefault(SET_TYPE_GROUP_LOOKUP, lookupValues)
        };



        const licensesAndProducts: IWorkProductDataItems[] = WorkDetailsMapper.mapWorkProductToState(work.workProduct);
        const workPaymentRuns: IWorkPaymentRunDataItems[] =  WorkDetailsMapper.mapStateToWorkPaymentRun(work.workPaymentRun);
        const matchingRepertoireWorks: IMatchingWork[] = WorkDetailsMapper.mapWorkDuplicatesToMatchingWorks(work.matchingWorks);
        const matchingIntrayWorks: IIntrayMatchingWork[] = WorkDetailsMapper.mapIntrayWorkDuplicatesToIntrayMatchingWorks(work.matchingIntrayDuplicateWorks);
        const titles: ITitle[] = WorkDetailsMapper.mapWorkNamesToTitles(work.workNames);
        const numbers: INumber[] = WorkDetailsMapper.mapWorkNumbersToNumbers(work.workNumbers);
        const additionalNumbers: INumberAdditional[] = WorkDetailsMapper.mapAdditionalWorkNumbersToAdditionalNumbers(work.workNumbersAdditional);
        const artists: IArtist[] = WorkDetailsMapper.mapWorkPerformersToArtists(work.performers);
        const articleMetadata: IArticle = WorkDetailsMapper.mapWorkArticlesToArticles(work.workArticle, work.workMetadata, work.workType, work.workFlags, work.dateOfRegistration);
        const sheetMusicMetadata: ISheetMusic = WorkDetailsMapper.mapWorkSheetMusicToSheetMusic(work.workSheetMusic, work.workMetadata, work.workType, work.workFlags);
        const bookMetadata: IBook = WorkDetailsMapper.mapWorkBookToBook(work.workBook, work.workMetadata, work.workType, work.workFlags);
        const chapterMetadata: IChapter = WorkDetailsMapper.mapWorkChapterToChapter(work.workChapter, work.workMetadata, work.workType);
        const magazineMetadata: IMagazine = WorkDetailsMapper.mapWorkMagazineToMagazine(work.workMagazine, work.workMetadata, work.workType, work.workFlags, work.dateOfRegistration);
        const otherIndicators: IFlag[] = WorkDetailsMapper.mapWorkFlagsToFlags(work, work.workFlags, work.workType, lookupValues, otherIndicatorsWorkFlagTypes);
        const readonlyIndicators: IFlag[] = WorkDetailsMapper.mapWorkFlagsToReadonlyIndicators(work, work.workFlags, work.workType, lookupValues, readonlyIndicatorsWorkFlagTypes);
        const comments: IComment[] = WorkDetailsMapper.mapWorkNotesToComments(work.notes);
        const contributors: IContributorItem[] = WorkDetailsMapper.mapWorkContributorsToContributors(work.contributors);
        const contributorsOther: IContributorItem[] = WorkDetailsMapper.mapWorkContributorsOtherToContributors(work.contributorsOther);
        const instrumentation: IInstrumentation[] = WorkDetailsMapper.mapWorkInstrumentationToInstrumentation(work.workInstrumentation);
        const workflows: IWorkflow[] = WorkDetailsMapper.mapWorkWorkflowToWorkflow(work.workflows);
        const replacedWorks: IReplacedWork[] = WorkDetailsMapper.mapWorkReplacedWorktoReplacedWork(work.replacedWorks);
        const representations: IRepresentation[] = WorkDetailsMapper.mapRepresentationToWorkRepresentation(work.representations);
        const workPrice: IWorkPrice[] = WorkDetailsMapper.mapPriceToWorkPrice(work.workPrice);
        const subjects: ISubject[] = WorkDetailsMapper.mapWorkSubjectToSubject(work.subject);
        const originalTitle=(workNames.find(element=>element.type==='OT'))?.name;
        const language=(workNames.find(element=>element.type==='OT'))?.languageCode;
        const stateWorkEdition: IWorkEditionDataItems[] = WorkDetailsMapper.mapWorkEditionToEdition(work.workEdition);
        const sortedContributors: Dictionary<IContributorItem[]> = {};
        if (contributors) {
            contributors.map(c => {
                if (!sortedContributors[c.setType]) {
                    sortedContributors[c.setType] = [];
                }
                sortedContributors[c.setType].push(c);
            });
        }
        if (contributorsOther) {
            contributorsOther.map(c => {
                if (!sortedContributors['other']) {
                    sortedContributors['other'] = [];
                }
                sortedContributors['other'].push(c);
            });
        }
        const files: IStoragePathItemData[] = [];
        if (attachedFile) {
            attachedFile.map(file => {
                files.push(
                    {
                        fullName: `repertoireAttachments/work/${file.guid}`,
                        inProgress: false,
                        isDirectory: false,
                        isDirty: false,
                        isEditing: false,
                        lastModified: file.lastModifiedDate && file.lastModifiedDate.toString(),
                        sources: [],
                        metadata: undefined,
                        name: file.fileName,
                        pools: [],
                        formats: [],
                        distributions: [],
                        validationError: undefined,
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                    }
                )
            })
        }
        const components: IContainedWork[] = WorkDetailsMapper.mapWorkContainedWorkToContainedWorks(work.workContainedWorks)
        const workSourceIP: ISourceIP = WorkDetailsMapper.mapWorkSourceIPToSourceIP(work.workSourceIP)

        const parentWorksState: IContainedWork[] = WorkDetailsMapper.mapParentWorksToState(work.parentWorks);

        const workState: IWorkState = {
            workID,
            dataSource,
            stampID,
            isMaintained,
            registrationDate: dateOfRegistration,
            lastModifiedDate,
            source,
            workType,
            sourceSociety,
            territorySpecificInfo,
            duration,
            isFilmTVCommissioned,
            isDramaticoMusical,
            isAdUse,
            isOriginalWork,
            genre,
            distributionCategory,
            isGrandRights,
            isLibraryWork,
            isCommissionedForCommercial,
            isRemix,
            titles,
            numbers,
            additionalNumbers,
            contributors: sortedContributors,
            comments,
            medleyCount,
            compoundType: medleyType,
            artists,
            articleMetadata,
            sheetMusicMetadata,
            bookMetadata,
            chapterMetadata,
            magazineMetadata,
            otherIndicators,
            readonlyIndicators,
            workBatchID,
            licensesAndProducts,
            workPaymentRuns,
            matchingRepertoireWorks,
            matchingIntrayWorks,
            fileImportID,
            workContributorSetTypes,
            versionNumber,
            workSubmissions,
            workSubmissionStatuses,
            iswcStatus,
            iswcSubmissionRetries,
            cisNetStatus,
            cisNetSubmissionRetries,
            widStatus,
            widSubmissionRetries,
            siteConfigurationJson,
            components,
            workSourceIP,
            sourceType,
            sourceIP,
            instrumentation,
            isArrangement,
            files,
            workflows,
            replacedWorks,
            isDistributed,
            isMaintainedMCPSI,
            parentWorks: parentWorksState,
            musicSource,
            representations,
            workPart,
            workCreationDate,
            workPrice,
            subjects,
            workEdition: stateWorkEdition,
            validateRoleBasedOnIpiTypeWarning,
            showCommentSubjectAsDropdown,
            isWorkAttachmentTypeNotRequired,
            isWorkAttachmentTypeOptional,
            originalTitle,
            language,
            workBatch,
            isReplaced,
            concurrency,
            isMaintainedLibraryWork,
            musicArrangement,
            lyricAdaptation,
            firstPerformance,
            iceWorkKeyHyperlink,
            additionalAttributeAssigned,
        }
        return workState;
    }

    // public static mapSetListToSetListState(setList: ISetListsState): ISetListsState {
    //     if (!setList)
    //         return null;

    //     const {
    //         setListsWorks,
    //         dataSource,
    //         stampID,
    //         source,
    //         sourceType,
    //         setListID
    //     } = setList;


    //     const setListState: ISetListsState = {
    //         setListsWorks,
    //         setListID,
    //         dataSource,
    //         stampID,
    //         source,
    //         sourceType,
    //         originalTitle: "",
    //         draftSubmitted: false
    //     }
    //     return setListState;
    // }

    public static createNewWork(lookups: ILookupDictionary, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem): IWork {

        var portalType = localStorage.getItem('portalType');
        var durationDefault = getLookupValues(PORTAL_DEFAULT_VALUES_LOOKUP, lookups, "Duration", portalType)
        var isOriginalWorkDefault =  getLookupValues(PORTAL_DEFAULT_VALUES_LOOKUP, lookups, "OriginalWork", portalType)
        const work: IWork = {
            workID: 0,
            dataSource: DataSource.Intray,
            stampID: '',
            workType: getLookupDefault(WORK_TYPE_LOOKUP, lookups),
            source: getLookupDefault(WORK_SOURCE_LOOKUP, lookups),
            sourceType: getLookupDefault(WORK_SOURCE_TYPE_LOOKUP, lookups),
            medleyType: getLookupDefault(WORK_MEDLEY_TYPE_LOOKUP, lookups),
            medleyCount: 0,
            lastModifiedDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
            dateOfRegistration: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
            concurrency: null,
            genre: getLookupDefault(WORK_GENRE_LOOKUP, lookups),
            isAdUse: false,
            isDramaticoMusical: false,
            isGrandRights: false,
            isLibraryWork: false,
            isCommissionedForCommercial: false,
            isMaintained: false,
            isOriginalWork: durationDefault != undefined ? isOriginalWorkDefault?.[0]?.extraFields["Value"] == "true" : false,
            isRemix: false,
            duration: durationDefault != undefined ? Number(durationDefault?.[0]?.extraFields["Value"] ?? 0) : 0,
            sourceSociety: '',
            isFilmTVCommissioned: false,
            contributors: [],
            contributorsOther: [],
            workArticle: null,
            workFlags: [],
            workNames: [{
                workNameID: 0,
                type: "OT",
                name: "",
                lastModifiedDate: new Date(),
                languageCode: getLookupDefault(LANGUAGE_LOOKUP, lookups)
            }],
            workNumbers: [],
            workNumbersAdditional: [],
            performers: [],
            notes: [],
            workTerritorySpecificInfo: [{
                workTerritorySpecificInfoID: 0,
                setTypeGroup: getLookupDefault(SET_TYPE_GROUP_LOOKUP, lookups),
                workStatus: getLookupDefault(WORK_STATUS_LOOKUP, lookups),
                isOutOfCopyright: false,
                isControlledComposition: false,
                isPDArrangement: false,
                isPCSpecific: false,
                isNonRoyaltyBearing: false,
                genreCategory: getLookupDefault(WORK_GENRE_CATEGORY_LOOKUP, lookups),
            }],
            workInstrumentation: [],
            fileImportID: null,
            workContributorSetTypes: [],
            versionNumber: 0,
            workContainedWorks: [],
            isArrangement: false,
            attachedFile: [],
            replacedWorks: [],
            isDistributed: false,
            isMaintainedMCPSI: false,
            workPart: getLookupDefault(WORK_PARTS_LOOKUP, lookups),
            workPrice: [],
            subject: [],
            workEdition: [],
            workPaymentRun: [],
            originalTitle:'',
            language:'',
            showCommentSubjectAsDropdown: false,
            workBatch: null,
            isReplaced: false,
            additionalAttributeAssigned: []
        }

        const setTypes = lookups[SET_TYPE_LOOKUP].lookups.filter(l => l.typeID < 0);
        setTypes.map((s, i) => work.workContributorSetTypes.push({
            setType: s.code,
            workContributorSetTypeID: 0,
            inheritFromSetType: s.additionalData
        }));

        return work;
    }
    public static createNewWorkFromUsage(lookups: ILookupDictionary, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem,usage?:IUsageGroupState): IWork {

        var portalType = localStorage.getItem('portalType');
        var durationDefault = getLookupValues(PORTAL_DEFAULT_VALUES_LOOKUP, lookups, "Duration", portalType)
        var isOriginalWorkDefault =  getLookupValues(PORTAL_DEFAULT_VALUES_LOOKUP, lookups, "OriginalWork", portalType)
        const work: IWork = {
            workID: 0,
            dataSource: DataSource.Intray,
            stampID: '',
            workType: getLookupDefault(WORK_TYPE_LOOKUP, lookups),
            source: 'USAGE',
            sourceType: getLookupDefault(WORK_SOURCE_TYPE_LOOKUP, lookups),
            medleyType: getLookupDefault(WORK_MEDLEY_TYPE_LOOKUP, lookups),
            medleyCount: 0,
            lastModifiedDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
            dateOfRegistration: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(),
            concurrency: null,
            genre: getLookupDefault(WORK_GENRE_LOOKUP, lookups),
            isAdUse: false,
            isDramaticoMusical: false,
            isGrandRights: false,
            isLibraryWork: false,
            isCommissionedForCommercial: false,
            isMaintained: false,
            isOriginalWork: durationDefault != undefined ? isOriginalWorkDefault?.[0]?.extraFields["Value"] == "true" : false,
            isRemix: false,
            duration: 180,
            sourceSociety: '',
            isFilmTVCommissioned: false,
            contributors: WorkDetailsMapper.mapUsageContributorsToWorkContributors(usage.fullContributor.split("/")),
            contributorsOther: [],
            workArticle: null,
            workFlags: [],
            workNames: WorkDetailsMapper.mapUsageTitlesToWorkNames(usage.fullTitle.split('/'),lookups),
            workNumbers: [],
            workNumbersAdditional: [],
            performers: WorkDetailsMapper.mapUsagePerformerToWorkPerformers(usage.fullPerformer.split('/')),
            notes: [],
            workTerritorySpecificInfo: [{
                workTerritorySpecificInfoID: 0,
                setTypeGroup: getLookupDefault(SET_TYPE_GROUP_LOOKUP, lookups),
                workStatus: getLookupDefault(WORK_STATUS_LOOKUP, lookups),
                isOutOfCopyright: false,
                isControlledComposition: false,
                isPDArrangement: false,
                isPCSpecific: false,
                isNonRoyaltyBearing: false,
                genreCategory: getLookupDefault(WORK_GENRE_CATEGORY_LOOKUP, lookups),
            }],
            workInstrumentation: [],
            fileImportID: null,
            workContributorSetTypes: [],
            versionNumber: 0,
            workContainedWorks: [],
            isArrangement: false,
            attachedFile: [],
            replacedWorks: [],
            isDistributed: false,
            isMaintainedMCPSI: false,
            workPart: getLookupDefault(WORK_PARTS_LOOKUP, lookups),
            workPrice: [],
            subject: [],
            workEdition: [],
            workPaymentRun: [],
            originalTitle:'',
            language:'',
            showCommentSubjectAsDropdown: false,
            workBatch: null,
            isReplaced: false,
            additionalAttributeAssigned: []
        }

        const setTypes = lookups[SET_TYPE_LOOKUP].lookups.filter(l => l.typeID < 0);
        setTypes.map((s, i) => work.workContributorSetTypes.push({
            setType: s.code,
            workContributorSetTypeID: 0,
            inheritFromSetType: s.additionalData
        }));

        return work;
    }

    // public static createNewSetLists(): ISetListsState {
    //     let setListsWorks: ISetListsWork[] = [];
    //     const setList: ISetListsState = {
    //         setListsWorks,
    //         dataSource: DataSource.Intray,
    //         stampID: '',
    //         source: '',
    //         sourceType: '',
    //         setListID: 0,
    //         originalTitle: "",
    //         draftSubmitted: false
    //     }
    //     return setList;
    // }

    public static createNewWorkState(lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes?: string[], readonlyIndicatorsWorkFlagTypes?: string[], memberIP?: IWorkSourceIP): IWorkState {
        let newWork = WorkDetailsMapper.createNewWork(lookups)
        if (memberIP) {
            newWork.workSourceIP = memberIP;
            newWork.sourceIP = memberIP.ipiNameNumber
            newWork.source = "MEMBER"
        }
        return WorkDetailsMapper.mapWorkToWorkState(newWork, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes);
    }

    public static createNewWorkStateFromUsage(lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes?: string[], readonlyIndicatorsWorkFlagTypes?: string[], memberIP?: IWorkSourceIP,usage?:IUsageGroupState): IWorkState {
        let newWork = WorkDetailsMapper.createNewWorkFromUsage(lookups,undefined,usage)
        if (memberIP) {
            newWork.workSourceIP = memberIP;
            newWork.sourceIP = memberIP.ipiNameNumber
            newWork.source = "MEMBER"
        }
        return WorkDetailsMapper.mapWorkToWorkState(newWork, lookups, otherIndicatorsWorkFlagTypes, readonlyIndicatorsWorkFlagTypes);
    }


    public static flattenContributors(contributors: Dictionary<IContributorItem[]>): IContributorItem[] {
        const flattenedContributors: IContributorItem[] = [];
        if (contributors) {
            for (var key in contributors) {
                flattenedContributors.push(...contributors[key]);
            }
        }
        return flattenedContributors;
    }

    public static mapWorkStateToWork(workState: IWorkState, lookups: ILookupDictionary, isMembersPortal: boolean, customer?: string): IWork {
        const {
            dataSource,
            stampID,
            source,
            sourceType,
            workType,
            isDramaticoMusical,
            duration,
            isAdUse,
            isMaintained,
            isOriginalWork,
            genre,
            distributionCategory,
            sourceSociety,
            isGrandRights,
            isLibraryWork,
            isCommissionedForCommercial,
            isFilmTVCommissioned,
            compoundType,
            medleyCount,
            artists,
            articleMetadata,
            sheetMusicMetadata,
            magazineMetadata,
            otherIndicators,
            readonlyIndicators,
            bookMetadata,
            chapterMetadata,
            isRemix,
            territorySpecificInfo,
            titles,
            numbers,
            additionalNumbers,
            contributors,
            comments,
            registrationDate,
            workID,
            workBatchID,
            fileImportID,
            workContributorSetTypes,
            licensesAndProducts,
            matchingRepertoireWorks,
            matchingIntrayWorks,
            versionNumber,
            iswcStatus,
            iswcSubmissionRetries,
            workSubmissionStatuses,
            cisNetStatus,
            cisNetSubmissionRetries,
            widStatus,
            widSubmissionRetries,
            components,
            workSourceIP,
            sourceIP,
            instrumentation,
            isArrangement,
            files,
            workflows,
            replacedWorks,
            isDistributed,
            isMaintainedMCPSI,
            musicSource,
            representations,
            workGroupID,
            workPart,
            workCreationDate,
            workPrice,
            subjects,
            workEdition,
            validateRoleBasedOnIpiTypeWarning,
            showCommentSubjectAsDropdown,
            draftSubmitted,
            isWorkAttachmentTypeNotRequired,
            isWorkAttachmentTypeOptional,
            workPaymentRuns,
            originalTitle,
            language,
            workBatch,
            isReplaced,
            concurrency,
            isMaintainedLibraryWork,
            musicArrangement,
            lyricAdaptation,
            firstPerformance,
            additionalAttributeAssigned
        } = workState;

        const flattenedContributors: IContributorItem[] = WorkDetailsMapper.flattenContributors(contributors);

        const attachedFiles: IAttachedFile[] = [];

        if (files) {
            files.map(
                file => {
                    let guid: string = "";
                    const fullNameSplit: string[] = file.fullName.split('/');
                    if (fullNameSplit.length > 2)
                        guid = fullNameSplit[2];

                    attachedFiles.push({
                        fileName: file.name,
                        workId: workID,
                        guid: guid,
                        attachmentTypeID: file.attachmentTypeID,
                        attachmentTypeCode: file.attachmentTypeCode,
                    })
                }
            )
        }

        let workNamesList = WorkDetailsMapper.mapTitlesToWorkNames(titles);

        if (isMembersPortal) { 
            if (originalTitle) {
                workNamesList = workNamesList.map(element=>{
                    if(element.type==='OT'){
                        element.languageCode=language;
                        element.name=originalTitle
                    }
                    return element;
                })
            }
            if (workNamesList.filter(x => x.type === 'OT').length == 0) {
                workNamesList.push({
                    workNameID: 0 - Math.floor(Math.random() * 99999999),
                    type: 'OT',
                    languageCode: language,
                    name: originalTitle

                })
            }
        }
        const work: IWork = {
            workID: workID,
            dataSource,
            medleyCount: medleyCount,
            workType: workType,
            lastModifiedDate: null,
            concurrency: concurrency,
            workPaymentRun: WorkDetailsMapper.mapStateToWorkPaymentRun(workPaymentRuns),
            workNames: workNamesList,
            workNumbers: WorkDetailsMapper.mapNumbersToWorkNumbers(numbers),
            workNumbersAdditional: WorkDetailsMapper.mapAdditionalNumbersToAdditionalWorkNumbers(additionalNumbers),
            contributors: WorkDetailsMapper.mapContributorsToWorkContributors(flattenedContributors.filter(c => c.setType && c.setType !== 'other'), workID, lookups, customer),
            contributorsOther: WorkDetailsMapper.mapContributorsToWorkContributorsOther(flattenedContributors.filter(c => !c.setType || c.setType === 'other'), workID),
            performers: WorkDetailsMapper.mapArtistsToWorkPerformers(artists),
            workArticle: WorkDetailsMapper.mapArticlesToWorkArticles(articleMetadata, workType, workID),
            workSheetMusic: WorkDetailsMapper.mapSheetMusicToWorkSheetMusic(sheetMusicMetadata, workType, workID),
            workBook: WorkDetailsMapper.mapBookToWorkBook(bookMetadata, workType, workID),
            workChapter: WorkDetailsMapper.mapChapterToWorkChapter(chapterMetadata, workType, workID),
            workMagazine: WorkDetailsMapper.mapMagazineToWorkMagazine(magazineMetadata, workType, workID),
            notes: WorkDetailsMapper.mapCommentsToWorkNotes(comments),
            workInstrumentation: WorkDetailsMapper.mapInstrumentationToWorkInstrumentation(instrumentation),
            workTerritorySpecificInfo: territorySpecificInfo.workTerritorySpecificInfo,
            workMetadata: WorkDetailsMapper.mapMetadataToWorkMetadata(articleMetadata, sheetMusicMetadata, bookMetadata, chapterMetadata, magazineMetadata, workType, workID),
            workFlags: WorkDetailsMapper.mapMetadataToWorkFlag(articleMetadata, sheetMusicMetadata, bookMetadata, magazineMetadata, otherIndicators, readonlyIndicators, workType, workID),
            stampID,
            source,
            sourceType,
            isDramaticoMusical,
            duration,
            isAdUse,
            dateOfRegistration: registrationDate,
            isMaintained,
            isOriginalWork,
            genre,
            distributionCategory,
            sourceSociety,
            isGrandRights,
            isLibraryWork,
            isCommissionedForCommercial,
            isFilmTVCommissioned,
            medleyType: compoundType,
            isRemix,
            workBatchID: workBatchID ? Number(workBatchID) : null,
            fileImportID,
            workContributorSetTypes,
            workProduct: WorkDetailsMapper.mapStateToWorkProduct(licensesAndProducts),
            matchingWorks: WorkDetailsMapper.mapMatchingWorksToWorkDuplicates(matchingRepertoireWorks),
            matchingIntrayDuplicateWorks: WorkDetailsMapper.mapIntrayMatchingWorksToIntrayWorkDuplicates(matchingIntrayWorks),
            versionNumber,
            iswcStatus: iswcStatus ? iswcStatus : null,
            workSubmissionStatuses: workSubmissionStatuses,
            iswcSubmissionRetries: Number(iswcSubmissionRetries ? iswcSubmissionRetries : null),
            cisNetStatus: cisNetStatus ? cisNetStatus : null,
            cisNetSubmissionRetries: Number(cisNetSubmissionRetries ? cisNetSubmissionRetries : null),
            widStatus: widStatus ? widStatus : null,
            widSubmissionRetries: Number(widSubmissionRetries ? widSubmissionRetries : null),
            workContainedWorks: WorkDetailsMapper.mapContainedWorkToWorkContainedWorks(components),
            workSourceIP: WorkDetailsMapper.mapSourceIPToWorkSourceIP(workSourceIP),
            sourceIP,
            isArrangement,
            attachedFile: attachedFiles,
            workflows: workflows,
            replacedWorks: WorkDetailsMapper.mapReplacedWorkToWorkReplacedWork(replacedWorks),
            isDistributed,
            isMaintainedMCPSI,
            musicSource,
            representations: WorkDetailsMapper.mapRepresentationToWorkRepresentation(representations),
            workGroupId: workGroupID,
            workPart,
            workCreationDate,
            workPrice: WorkDetailsMapper.mapWorkPriceToPrice(workPrice),
            subject: WorkDetailsMapper.mapSubjectToWorkSubject(subjects),
            workEdition: WorkDetailsMapper.mapEditionToWorkEdition(workEdition),
            validateRoleBasedOnIpiTypeWarning: validateRoleBasedOnIpiTypeWarning,
            showCommentSubjectAsDropdown: showCommentSubjectAsDropdown,
            draftSubmitted,
            isWorkAttachmentTypeNotRequired,
            isWorkAttachmentTypeOptional,
            originalTitle,
            language,
            workBatch,
            isReplaced,
            isMaintainedLibraryWork,
            musicArrangement,
            lyricAdaptation,
            firstPerformance,
            additionalAttributeAssigned
        };

        return work;
    }

    // public static mapSetListsStateToSetLists(setListsState: ISetListsState, lookups: ILookupDictionary, isMembersPortal: boolean): ISetListsState {
    //     const {
    //         setListsWorks,
    //         dataSource,
    //         stampID,
    //         source,
    //         sourceType,
    //         setListID
    //     } = setListsState;




    //     const setLists: ISetListsState= {
    //         setListsWorks,
    //         dataSource,
    //         stampID,
    //         source,
    //         sourceType,
    //         setListID,
    //         originalTitle: "",
    //         draftSubmitted: false,

    //     };

    //     return setLists;
    // }

    private static mapAgreementChain(workAgreementChain: IAgreementLink[]): IAgreementLink[] {
        let agreementChain = workAgreementChain;
        agreementChain.forEach(x => {
            if (x.performingShareToDisplay != null)
                x.performingShareToDisplay = x.performingShareToDisplay.toString();
            if (x.mechanicalShareToDisplay != null)
                x.mechanicalShareToDisplay = x.mechanicalShareToDisplay.toString();

        })
        return agreementChain;
    }

    private static mapAgreementChainWork(agreementChain: IAgreementLink[]): IAgreementLink[] {
        let workAgreementChain = agreementChain;
        workAgreementChain.forEach(x => {
            if (x.performingShareToDisplay != null)
                x.performingShareToDisplay = Number(x.performingShareToDisplay);
            if (x.mechanicalShareToDisplay != null)
                x.mechanicalShareToDisplay = Number(x.mechanicalShareToDisplay);

        })
        return agreementChain;
    }

    private static mapWorkContributorShare(contributor: IContributorItem, lookupValues: ILookupDictionary, customer: string): IWorkContributorShare[] {
        let workContributorShares: IWorkContributorShare[] = [];

        customer = customer ?? EMPTY_STRING_VALUE;

        if (customer != 'CEDRO')
            return workContributorShares;

        let shareRightTypeLookup = getLookupValues(SHARE_RIGHT_TYPE_LOOKUP, lookupValues)
        if (contributor.copyLevyPercentage.value != null && shareRightTypeLookup.some(x => x.code == LEVY_COPY_TYPE_CODE)) {
            workContributorShares.push(
                {
                    share: Number(contributor.copyLevyPercentage.value),
                    workContributorID: contributor.workContributorID,
                    shareRightTypeID: shareRightTypeLookup.filter(x => x.code == LEVY_COPY_TYPE_CODE)[0]?.code,
                    typeCode: LEVY_COPY_TYPE_CODE
                })
        }

        if (contributor.copyLicencePercentage.value != null && shareRightTypeLookup.some(x => x.code == LICENCE_COPY_TYPE_CODE)) {
            workContributorShares.push(
                {
                    share: Number(contributor.copyLicencePercentage.value),
                    workContributorID: contributor.workContributorID,
                    shareRightTypeID: shareRightTypeLookup.filter(x => x.code == LICENCE_COPY_TYPE_CODE)[0]?.code,
                    typeCode: LICENCE_COPY_TYPE_CODE
                })
        }

        return workContributorShares;
    }
}