import * as React from "react";

export interface IDataGridTableSectionProps {
    data: any;
    title?: string;
}

const DataGridTableSection: React.SFC<IDataGridTableSectionProps> = ({
  data,
  title
}) => {
  return <div
    title={title !== undefined ? title: data}
    className="dataGridTableSection"
    >{data}</div>;
};

export default DataGridTableSection;
