import * as React from "react";
import { throttle } from "lodash";
import {
    EVENT_HANDLER_THROTTLE_TIME,
    DATA_GRID_TABLE_FIELDS_EXPANDER,
    AGREEMENT_MAINTENANCE_TOOLBAR,
    WORK_MAINTENANCE_TOOLBAR,
    REPERTOIRE_SIDE_AGREEMENTS_KEY,
    COMPONENT_INSTANCE_AGREEMENTS,
    AGREEMENT_MAINTENANCE_PAGE,
    ALL_ROLES,
    UPDATE_AGREEMENTS_ROLE
} from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    setChangesMade,
    updateAgreementField,
    sortAgreementFieldData,
    addTab,
    clearModalSearchResults,
    copyExistingAgreement,
    resetMessageBanner,
    setEditableFields,
    updateEditableFields,
    cleanDroppedFilesRepertoireState,
    addDroppedFile,
    setProgressUploadFileRepertoireProccess,
    postAgreementBatchRequest,
    nextWorkflowInSession
} from "../../../../redux/reducers/RepertoireReducer";
import AgreementMaintenanceGeneralDataView from "../agreementMaintenanceGeneralDataView/AgreementMaintenanceGeneralDataView";
import AgreementMaintenanceGridsView from "../agreementMaintenanceGridsView/AgreementMaintenanceGridsView";
import AgreementMaintenanceToolbar from "../agreementMaintenanceToolbar/AgreementMaintenanceToolbar";
import { hideModal, showModal, showUpdateFieldsModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import { IP } from "../../../types/IP";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IAgreementState } from "../../../../redux/types/IAgreementState";
import { DataSource } from "../../../types/DataSource";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { SearchRequests } from "../../../services/SearchRequests";
import { IComment } from "../../../types/IComment";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import {
    addFileToUpload,
    CleanUploadFilesState,
    setInDropZone,
    setProgressUploadFileProccess,
    sortColumn,
    updatePagination
} from "../../../../redux/reducers/DataIngestionReducer";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { ITreeNodeData } from "../../../../treeview/types/TreeNodeData";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../../../ConfigurationConsts";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { AgreementDetailsMapper } from "../../../services/AgreementDetailsMapper";
import { AGREEMENT_SOURCE_LOOKUP } from "../../../../lookup/Consts";
import { ITreeData } from "../../../types/ITreeData";
import { IPaginationDetail } from "../../../../redux/types/IPaginationDetail";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";

export interface IAgreementMaintenancePageProps {
    agreementMaintenanceGeneralViewData: IRepertoireComponentDataItem;
    agreementMaintenanceGridsViewData: IRepertoireComponentDataItem;
    agreementMaintenanceAccordionViewData: IRepertoireComponentDataItem;
    agreementMaintenanceToolbarData: IRepertoireComponentDataItem;
    agreementMaintenanceSaveResultData: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    setChangesMade: typeof setChangesMade;
    lookupValues: ILookupDictionary;
    activeTab: number;
    tabs: ITabReduxItem[];
    dataSource: string;
    agreement: IAgreementState;
    updateAgreementField: typeof updateAgreementField;
    sortAgreementFieldData: typeof sortAgreementFieldData;
    copyExistingAgreement: typeof copyExistingAgreement;
    deleteAgreement: (dataSource: DataSource, agreementId: number, activeTab: number) => void;
    saveChanges: (updatedAgreement: IAgreementState,
        postAgreement: boolean,
        dataActions?: IDataActionToolbar[],
        workflowsSessionItems?: IWorkflowSearchResult[],
        currentWorkflowIndex?: number,
        isOpenForWorkflowSession?: boolean,
        activeTab?: number
        ) => void;
    addNewAgreement: (lookups: ILookupDictionary, isNew?: boolean, dataActions?: IDataActionToolbar[], agreementMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    openWorkAccordian: (accordionName: string, componentName: string) => void;
    closeWorkAccordian: (accordionName: string, componentName: string) => void;
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    addTab: typeof addTab;
    clearModalSearchResults: typeof clearModalSearchResults;
    updateAgreementIP: (agreementAdminIPID: number, ip: IP) => void;
    showYesNoPrompt: typeof showYesNoPrompt;
    undoAgreement: (dataSource: string, agreementID: number) => void;
    resetMessageBanner: typeof resetMessageBanner;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    getAgreementDetails: (dataSource: string, agreementID: number, lookups: ILookupDictionary, dataActions?: IDataActionToolbar[], agreementMaintenanceGeneralDataView?: IRepertoireComponentDataItem, isOpenForWorkflowSession?: boolean) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string, ipMaintenanceGeneralViewData: IRepertoireComponentDataItem) => void;
    getProductDetails: (dataSource: string, productID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats: FormatFields[]) => void;
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    updateEditableField: typeof updateEditableFields;
    showUpdateFieldsModal: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    ipMaintenanceGeneralViewData: IRepertoireComponentDataItem,
    workflowFieldsData: IRepertoireComponentDataItem;
    comments: IComment[];
    currentUser?: string;
    containerDetailsWindowComponentData?: IComponentDataItem;
    distributions?: IDistribution[];
    usagePoolsSearchResults?: IUsagePool[];
    searchUsagePools: (query: IUsagePoolSearchQuery) => void;
    formats?: ILookupInstance[];
    destinations?: ILookupInstance[];
    selectedFileSystem?: IFileSystem;
    droppedFiles?: IDroppedFiles;
    setInDropZone?: typeof setInDropZone;
    addFileToUpload?: typeof addFileToUpload;
    sortColumn?: typeof sortColumn;
    isDropZone?: boolean;
    setProgressUploadFileProccess?: typeof setProgressUploadFileProccess;
    cleanUploadFilesState?: typeof CleanUploadFilesState;
    storagePaths?: IStoragePathItemData[];
    storagePathItemData?: IComponentDataItem;
    refreshDirectory?: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    getFilesystems?: (isRepertoireModule?: boolean) => void;
    filesystems?: IFileSystem[];
    treeNodes?: ITreeNodeData<IFileSystem>[];
    selectedNode?: ITreeNodeData<IFileSystem>;
    selectedNodeChanged?: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => void;
    getDataIngestionComponentData?: () => void;
    addRepertoireAttachmentToState?: (attachedFile: IAttachedFile) => void;
    attachedFiles?: IStoragePathItemData[];
    removeAgreementAttachmentIfExists?: (attachedFile: IAttachedFile, source: DataSource) => void;
    agreementAttachmentsDropped?: IDroppedFiles;
    cleanDroppedFilesRepertoire?: typeof cleanDroppedFilesRepertoireState;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire?: typeof setProgressUploadFileRepertoireProccess;
    shareDecimalsToDisplay?: number;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    editableFields: IRepertoireField[];
    refreshAgreementDetails: (activeTabItem: ITabReduxItem, dataSource: string, agreementID: number, editableFields: IRepertoireField[]) => void;
    sources: ITreeData[];
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    searchVersionHistory: (agreementID: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[]) => void;
    saveInProgress: boolean;
    postAgreementBatch: typeof postAgreementBatchRequest;
    roles?: string[];
    incrementTempID?: () => void;
    commentCategoryFlag?: boolean;
    agreementAttachmentTypeRequired?: string;
    updatedScroll?: number;
    isWorkflowSessionStarted?: boolean;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[],
        readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems: IWorkflowSearchResult[];
    currentWorkflowIndex?: number
    isOpenForWorkflowSession?: boolean;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>void;
    mergeAgreement: (agreement: IAgreementState, agreementId: number, lookups: ILookupDictionary, dataAction: IDataActionToolbar[]) => void;
}

interface IAgreementMaintenancePageState {
    toolbarWidth: number;
    isCompleteWorkflowOnSave: boolean;
}

export class AgreementMaintenancePage extends React.PureComponent<
    IAgreementMaintenancePageProps,
    IAgreementMaintenancePageState>
{
    toolbarParentRef;
    constructor(props: IAgreementMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            isCompleteWorkflowOnSave: true,
        }

        this.toolbarParentRef = React.createRef();
    }

    componentDidMount() {

        const {
            getFilesystems,
            getDataIngestionComponentData,
            containerDetailsWindowComponentData,
            cleanUploadFilesState,
        } = this.props;

        if (!containerDetailsWindowComponentData || !containerDetailsWindowComponentData.fields || containerDetailsWindowComponentData.fields.length === 0) {
            getDataIngestionComponentData();
        }
        getFilesystems(true);
        cleanUploadFilesState();
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    getWorkDetails(dataSource: string, workID: number) {
        const { lookupValues, workMaintenanceGeneralDataViewData, tabs, activeTab } = this.props;
        const dataActionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                this.props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, tabs[activeTab].readonlyIndicatorWorkFlagTypes, dataActionList, workMaintenanceGeneralDataViewData, formats);
            });
    }

    getProductDetails = (dataSource: string, productID: number) => {
        const { workMaintenanceGeneralDataViewData } = this.props;
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                this.props.getProductDetails(dataSource, productID, workMaintenanceGeneralDataViewData, formats);
            });
    }

    getAgreementDetails(dataSource: string, agreementID: number) {
        const { lookupValues, agreementMaintenanceGeneralViewData, isOpenForWorkflowSession } = this.props;
        const dataActionList = getDataAction(dataSource, AGREEMENT_MAINTENANCE_TOOLBAR);
        this.props.getAgreementDetails(dataSource, agreementID, lookupValues, dataActionList, agreementMaintenanceGeneralViewData, isOpenForWorkflowSession);
    }

    getIPDetails(ipBaseNumber: string, accountNumber: string) {
        const { ipMaintenanceGeneralViewData } = this.props;
        this.props.getIPDetails(ipBaseNumber, accountNumber, ipMaintenanceGeneralViewData);
    }

    saveAgreement(postAgreement: boolean) {
        const { saveChanges, agreement, dataSource, isWorkflowSessionStarted, isOpenForWorkflowSession, currentWorkflowIndex, workflowSessionItems, activeTab } = this.props;
        const { isCompleteWorkflowOnSave } = this.state;
        const dataActionList = getDataAction(postAgreement ? DataSource.Repertoire : dataSource, AGREEMENT_MAINTENANCE_TOOLBAR);
        if (agreement.source) {
            agreement.sourceID = getLookupValues(AGREEMENT_SOURCE_LOOKUP, this.props.lookupValues).find(a => a.code === agreement.source)?.typeID;
        }
        if (isCompleteWorkflowOnSave) {
            if (agreement.workflows && agreement.workflows[0] && agreement.workflows[0].status) {
                agreement.workflows[0].status = "Completed";
            }
        }
        saveChanges(agreement,
            postAgreement,
            dataActionList,
            workflowSessionItems,
            currentWorkflowIndex + 1,
            isOpenForWorkflowSession,
            activeTab);
    }

    deleteAgreement() {
        const { activeTab, dataSource, agreement } = this.props;
        this.props.deleteAgreement(dataSource === DataSource.Repertoire.toString() ? DataSource.Repertoire : DataSource.Intray, agreement.agreementID, activeTab);
    }

    undoAgreement = () => {
        const { dataSource, agreement, undoAgreement, resetMessageBanner } = this.props;
        undoAgreement(dataSource, agreement.agreementID);
        resetMessageBanner();
    }

    newAgreement() {
        const { addNewAgreement, lookupValues, tabs, activeTab, agreementMaintenanceGeneralViewData } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        addNewAgreement(lookupValues, true, actionList, agreementMaintenanceGeneralViewData);
    }

    onUpdateComponentFieldItems(fields: IRepertoireField[], componentName: string) {
        const { updateComponentFieldsItems, activeTab, tabs } = this.props;
        updateComponentFieldsItems(fields, componentName, COMPONENT_INSTANCE_AGREEMENTS, REPERTOIRE_SIDE_AGREEMENTS_KEY, tabs, activeTab);
    }

    openAccordion(accordionName: string, componentName: string): void {
        const { openWorkAccordian, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        openWorkAccordian(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, true, componentName);
    }
    closeAccordion(accordionName: string, componentName: string): void {
        const { closeWorkAccordian, updateUserPreferences, allResultsPerPage, defaultActiveAccordions } = this.props;
        closeWorkAccordian(accordionName, componentName);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, accordionName, false, componentName);
    }

    refreshAgreementDetail() {
        const { refreshAgreementDetails, dataSource, agreement, tabs, activeTab, editableFieldsDataView } = this.props;

        refreshAgreementDetails(tabs[activeTab], dataSource, agreement.agreementID, editableFieldsDataView.fields);
    }

    completeWorkflowOnSave(value: boolean) {
        this.setState({
            isCompleteWorkflowOnSave: value
        });
    }

    mergeAgreement(agreementId: number)
    {
        const { mergeAgreement, agreement, lookupValues } = this.props;
        const dataActions = getDataAction(DataSource.Repertoire, AGREEMENT_MAINTENANCE_TOOLBAR);
        mergeAgreement(agreement, agreementId, lookupValues, dataActions);


    }

    render() {
        const {
            agreementMaintenanceGeneralViewData,
            agreementMaintenanceGridsViewData,
            agreementMaintenanceAccordionViewData,
            agreementMaintenanceToolbarData,
            agreementMaintenanceSaveResultData,
            dataGridTableData,
            setChangesMade,
            lookupValues,
            activeTab,
            tabs,
            agreement,
            updateAgreementField,
            sortAgreementFieldData,
            openWorkAccordian,
            closeWorkAccordian,
            saveChanges,
            copyExistingAgreement,
            addNewAgreement,
            showModal,
            hideModal,
            showYesNoPrompt,
            updateAgreementIP,
            addTab,
            clearModalSearchResults,
            dataSource,
            setEditableFields,
            updateEditableField,
            editableFieldsDataView,
            showUpdateFieldsModal,
            workflowFieldsData,
            comments,
            currentUser,
            destinations,
            distributions,
            selectedFileSystem,
            droppedFiles,
            setInDropZone,
            sortColumn,
            addFileToUpload,
            isDropZone,
            setProgressUploadFileProccess,
            cleanUploadFilesState,
            storagePaths,
            storagePathItemData,
            containerDetailsWindowComponentData,
            refreshDirectory,
            addRepertoireAttachmentToState,
            attachedFiles,
            removeAgreementAttachmentIfExists,
            agreementAttachmentsDropped,
            cleanDroppedFilesRepertoire,
            addDroppedFileRepertoire,
            setProgressUploadFileRepertoire,
            shareDecimalsToDisplay,
            updateComponentFieldsItems,
            editableFields,
            sources,
            paginationDetails,
            updatePagination,
            searchVersionHistory,
            saveInProgress,
            postAgreementBatch,
            roles,
            incrementTempID,
            commentCategoryFlag,
            usagePoolsSearchResults,
            searchUsagePools,
            agreementAttachmentTypeRequired,
            isWorkflowSessionStarted,
            nextWorkflowItem,
            cancelWorkflowSession,
            skipWorkflowItem,
            workflowSessionItems,
            currentWorkflowIndex,
            saveWorkflow,
            isOpenForWorkflowSession,
            addToPackages
        } = this.props;

        const {
            toolbarWidth
        } = this.state

        const changesMade = tabs[activeTab].changesMade;

        const expanderFields = dataGridTableData.fields.filter(
            (field: IRepertoireField) => field.componentInstance === DATA_GRID_TABLE_FIELDS_EXPANDER);

        const isReadonly: boolean = tabs[activeTab].isReadonly;

        if (this.props.agreement) {
            const {
                agreementID,
                lastModifiedDate,
                ipBaseNumber,
                type,
                specificWorks,
                specificAgreementType,
                applicableWorksType,
                source,
                dataSource,
                sourceID,
                maintained,
                effectiveFrom,
                effectiveTo,
                effectiveNotifiedTo,
                effectiveNotifiedFrom,
                territory,
                agreementCategory,
                setTypeGroup,
                salesOrManufacture,
                agreementSignatureDate,
                bulkTransferWorks,
                priorRoyalties,
                expectedTermination,
                expectedTerminationText,
                postTermRetentionPeriod,
                postTermCollectionPeriod,
                submitterAssignorIPCode,
                libraryMusic,
                recordingPrefix,
                worksRetained,
                defaultRetainedUntil,
                defaultRetentionEndDate,
                defaultCollectionUntil,
                defaultCollectionPeriodEndDate,
                licensedDirect,
                useRelationships,
                ipName,
                ipNameNumber,
                agreementAdminIP,
                agreementNumbers,
                agreementWorks,
                agreementProductWorks,
                agreementShareSet,
                agreementRegion,
                agreementUsagePools,
                assignedSetTypes,
                availableSetTypes,
                agreementDuplicate,
                creationDate,
                agreementBatchID,
                agreementSourceType,
                agreementSourceIP,
                agreementSourceIPINameNumber,
                worldExcluding,
                replacedAgreements
            } = this.props.agreement;

            const {
                updatedScroll,
                usagePoolsSearchResults
            } = this.props;

            const {
            } = this.state;

            const agreementBatchOperationInProgress = tabs[activeTab].agreementMaintenanceState && tabs[activeTab].agreementMaintenanceState.agreementBatchOperationInProgress;

            return (
                <div className="">
                    <div><span className="title">Agreement: {agreementID > 0 ? agreementID : 0}</span></div>
                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>

                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <AgreementMaintenanceToolbar
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            toolbarData={agreementMaintenanceToolbarData}
                            saveResultData={agreementMaintenanceSaveResultData}
                            changesMade={changesMade}
                            saveChanges={this.saveAgreement.bind(this)}
                            addNewAgreement={this.newAgreement.bind(this)}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            agreement={agreement}
                            activeTab={activeTab}
                            lookupValues={lookupValues}
                            tabs={tabs}
                            hideModal={hideModal}
                            showYesNoPrompt={showYesNoPrompt}
                            deleteAgreement={this.deleteAgreement.bind(this)}
                            copyExistingAgreement={copyExistingAgreement}
                            undoAgreement={this.undoAgreement.bind(this)}
                            isNew={tabs[activeTab].agreementMaintenanceState.isNew}
                            dataSource={agreement.dataSource}
                            workflowFieldsData={workflowFieldsData}
                            currentUser={currentUser}
                            searchVersionHistory={searchVersionHistory}
                            clearModalSearchResults={clearModalSearchResults}
                            isReadonly={isReadonly}
                            roles={roles}
                            isWorkflowSessionStarted={isWorkflowSessionStarted}
                            nextWorkflowItem={nextWorkflowItem}
                            skipWorkflowItem={skipWorkflowItem}
                            cancelWorkflowSession={cancelWorkflowSession}
                            workflowSessionItems={workflowSessionItems}
                            currentWorkflowIndex={currentWorkflowIndex}
                            saveWorkflow={saveWorkflow}
                            completeWorkflowOnSave={this.completeWorkflowOnSave.bind(this)} 
                            isOpenForWorkflowSession={isOpenForWorkflowSession}
                            addToPackages={addToPackages} />
                    </div>
                    <AgreementMaintenanceGeneralDataView
                        key={agreementID}
                        agreementMaintenanceGeneralDataViewData={agreementMaintenanceGeneralViewData}
                        agreementMaintenanceAccordionViewData={agreementMaintenanceAccordionViewData}
                        expanderFieldsData={expanderFields}
                        updateAgreementField={updateAgreementField}
                        lookupValues={lookupValues}
                        changesMade={changesMade}
                        showModal={showModal}
                        addTab={addTab}
                        updateAgreementIP={updateAgreementIP}
                        lastModifiedDate={lastModifiedDate}
                        ipBaseNumber={ipBaseNumber}
                        type={type}
                        specificWorks={specificWorks}
                        specificAgreementType={specificAgreementType}
                        applicableWorksType={applicableWorksType}
                        dataSource={dataSource}
                        source={source}
                        sourceID={sourceID}
                        maintained={maintained}
                        effectiveFrom={effectiveFrom}
                        effectiveTo={effectiveTo}
                        effectiveNotifiedTo={effectiveNotifiedTo}
                        effectiveNotifiedFrom={effectiveNotifiedFrom}
                        creationDate={creationDate}
                        territory={territory}
                        agreementCategory={agreementCategory}
                        setTypeGroup={setTypeGroup}
                        salesOrManufacture={salesOrManufacture}
                        agreementSignatureDate={agreementSignatureDate}
                        bulkTransferWorks={bulkTransferWorks}
                        priorRoyalties={priorRoyalties}
                        expectedTermination={expectedTermination}
                        expectedTerminationText={expectedTerminationText}
                        postTermRetentionPeriod={postTermRetentionPeriod}
                        postTermCollectionPeriod={postTermCollectionPeriod}
                        submitterAssignorIPCode={submitterAssignorIPCode}
                        libraryMusic={libraryMusic}
                        recordingPrefix={recordingPrefix}
                        worksRetained={worksRetained}
                        defaultRetainedUntil={defaultRetainedUntil}
                        defaultRetentionEndDate={defaultRetentionEndDate}
                        defaultCollectionUntil={defaultCollectionUntil}
                        defaultCollectionPeriodEndDate={defaultCollectionPeriodEndDate}
                        licensedDirect={licensedDirect}
                        useRelationships={useRelationships}
                        ipName={ipName}
                        ipNameNumber={ipNameNumber}
                        tabs={tabs}
                        activeTab={activeTab}
                        openWorkAccordian={this.openAccordion.bind(this)}
                        closeWorkAccordian={this.closeAccordion.bind(this)}
                        agreementShareSet={agreementShareSet}
                        agreementRegion={agreementRegion}
                        agreementUsagePools={agreementUsagePools}
                        assignedSetTypes={assignedSetTypes}
                        availableSetTypes={availableSetTypes}
                        agreement={agreement}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFieldsDataView={editableFieldsDataView}
                        dataGridTableEditableFieldsData={dataGridTableData}
                        setEditableFields={setEditableFields}
                        editableFields={editableFields}
                        hideModal={hideModal}
                        getIPDetails={this.getIPDetails.bind(this)}
                        agreementBatchID={agreementBatchID}
                        postAgreementBatch={postAgreementBatch}
                        agreementBatchOperationInProgress={agreementBatchOperationInProgress}
                        isReadonly={isReadonly}
                        saveInProgress={saveInProgress}
                        roles={roles}
                        usagePoolsSearchResults={usagePoolsSearchResults}
                        searchUsagePools={searchUsagePools}
                        componentInstance={"Agreements"}
                        agreementSourceType={agreementSourceType}
                        agreementSourceIP={agreementSourceIP}
                        agreementSourceIPINameNumber={agreementSourceIPINameNumber}
                        worldExcluding={worldExcluding}
                    />

                    <AgreementMaintenanceGridsView
                        ipNameNumber={ipNameNumber}
                        agreementMaintenanceAccordionViewData={agreementMaintenanceAccordionViewData}
                        agreementMaintenanceGridsViewData={agreementMaintenanceGridsViewData}
                        dataGridTableData={dataGridTableData}
                        dataSource={dataSource}
                        lookupValues={lookupValues}
                        agreementAdminIP={agreementAdminIP}
                        agreementNumbers={agreementNumbers}
                        applicableWorks={agreementWorks}
                        agreementProductWorks={agreementProductWorks}
                        agreementDuplicate={agreementDuplicate}
                        tabs={tabs}
                        activeTab={activeTab}
                        componentInstance={type === "2" ? "specific" : "general"}
                        updateAgreementField={updateAgreementField}
                        sortAgreementFieldData={sortAgreementFieldData}
                        showModal={showModal}
                        hideModal={hideModal}
                        clearModalSearchResults={clearModalSearchResults}
                        addTab={addTab}
                        getProductDetails={this.getProductDetails.bind(this)}
                        getWorkDetails={this.getWorkDetails.bind(this)}
                        getIPDetails={this.getIPDetails.bind(this)}
                        getAgreementDetails={this.getAgreementDetails.bind(this)}
                        comments={comments}
                        replacedAgreements={replacedAgreements}
                        currentUser={currentUser}
                        addFileToUpload={addFileToUpload}
                        cleanUploadFilesState={cleanUploadFilesState}
                        containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                        destinations={destinations}
                        distributions={distributions}
                        droppedFiles={droppedFiles}
                        isDropZone={isDropZone}
                        selectedFileSystem={selectedFileSystem}
                        setInDropZone={setInDropZone}
                        setProgressUploadFileProccess={setProgressUploadFileProccess}
                        sortColumn={sortColumn}
                        storagePathItemData={storagePathItemData}
                        storagePaths={attachedFiles}
                        refreshDirectory={(x) => refreshDirectory(x, sources)}
                        addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                        attachedFiles={attachedFiles}
                        removeAgreementAttachmentIfExists={removeAgreementAttachmentIfExists}
                        agreement={agreement}
                        agreementAttachmentsDropped={agreementAttachmentsDropped}
                        cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                        addDroppedFileRepertoire={addDroppedFileRepertoire}
                        setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                        shareDecimalsToDisplay={shareDecimalsToDisplay}
                        refreshAgreementDetail={this.refreshAgreementDetail.bind(this)}
                        paginationDetails={paginationDetails}
                        updatePagination={updatePagination}
                        isReadonly={isReadonly}
                        incrementTempID={incrementTempID}
                        commentCategoryFlag={commentCategoryFlag}
                        agreementAttachmentTypeRequired={agreementAttachmentTypeRequired}
                        mergeAgreement={this.mergeAgreement.bind(this)}
                        openWorkAccordian={this.openAccordion.bind(this)}
                        closeWorkAccordian={this.closeAccordion.bind(this)}
                    />

                </div>
            );
        }
        else
            return null;
    }
}
