import { TreeSelect } from "antd";
import { useField } from "formik";

const TreeSelectFormik = ({ ...props }: { [x: string]: any; name: string }) => {
    const [field, , { setValue }] = useField(props);
    return (
        <TreeSelect
                {...field}
                {...props}
                
                onChange={(val) => {
                        setValue(val);
                }}
                style={{ width: '100%', minWidth: '200px', height: '38px' }}
                treeDataSimpleMode={true}
                treeData={props.options}
                placeholder={'Select Source'}
                dropdownStyle={{ overflow: 'auto' }}
                treeDefaultExpandAll={true}
                value={field.value}
                allowClear={true}
        />
    )
}

export default TreeSelectFormik;
