import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { AdjustmentMaintenancePage } from "./AdjustmentMaintenancePage";
import { showModal, showYesNoPrompt, hideModal                                               } from "../../../../redux/reducers/ModalReducer";
import { addTab, setChangesMade, updateAdjustmentField, createNewAdjustment, copyAdjustment, undoAdjustmentChanges, resetMessageBanner } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IAdjustmentState, IAdjustmentStateKeys } from "../../../../redux/types/IAdjustmentState";
import { searchDatabricksAllocationsThunk, saveAdjustmentThunk, searchAdjustmentVersionHistoryThunk, undoAdjustmentChangesThunk, updateComponentFieldItemsThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IAdjustment } from "../../../types/usageTypes/IAdjustment";
import { postAdjustmentBatchThunk } from "../../../../redux/thunks/JobThunks";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import { IAllocationSearchModel } from "../../../../redux/types/IAllocationSearchModel";
import { ITabProps } from "../../../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        adjustmentMaintenanceGeneralDataViewData: state.repertoire.componentData.AdjustmentMaintenanceGeneralDataView,
        adjustmentMaintenanceToolbarData: state.repertoire.componentData.AdjustmentMaintenanceToolbarData,
        adjustmentMaintenanceGridsViewData: state.repertoire.componentData.AdjustmentMaintenanceGridsView,
        allocationAdjustmentDetailsViewData: state.repertoire.componentData.AllocationAdjustmentDetailsViewData,
        adjustmentMaintenanceSaveResultData: state.repertoire.componentData.SaveResult,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        activeTab: state.repertoire.panes[props.paneIndex].activeTab,
        tabs: state.repertoire.panes[props.paneIndex].tabs,
        loaded: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState.loaded,
        adjustment: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState.adjustment,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        lookupValues: state.lookupEntities,
        editableFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields,
        distributions: state.dataIngestion.distributions,
        allocationRecords: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState.allocationHeaderDetails,
        saveInProgress: state.repertoire.saveInProgress,
        adjustmentBatchOperationInProgress: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState.adjustmentBatchOperationInProgress,
        roles: state.account.roles,

    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch(hideModal()),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        setChangesMade: (tabIndex: number | null, changesMade: boolean) => dispatch(setChangesMade(tabIndex, changesMade)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        updateAdjustmentField: (value: any, fieldName: IAdjustmentStateKeys) => dispatch(updateAdjustmentField(value, fieldName)),
        saveChanges: (adjustment: IAdjustmentState, saveOption?: string) => dispatch<any>(saveAdjustmentThunk(adjustment, saveOption)),
        addNewAdjustment: (lookupValues: ILookupDictionary, isNew?: boolean, adjustmentMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => dispatch<any>(createNewAdjustment(lookupValues, isNew, adjustmentMaintenanceGeneralDataView)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        copyAdjustment: () => dispatch<any>(copyAdjustment()),
        searchAllocation: (searchParams: IAllocationSearchModel) => dispatch<any>(searchDatabricksAllocationsThunk(searchParams)),
        postAdjustments: (batchId: number) => dispatch<any>(postAdjustmentBatchThunk(batchId)),
        undoAdjustmentChanges: (adjustmentId: string) => dispatch<any>(undoAdjustmentChangesThunk(adjustmentId)),
        searchAdjustmentVersionHistory: (adjustmentId: string, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) =>
            dispatch<any>(searchAdjustmentVersionHistoryThunk(adjustmentId, lookups, actionList, formats)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
    })
)(AdjustmentMaintenancePage);