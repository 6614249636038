import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import SearchView from "../components/searchView/SearchView";
import { ALL_ROLES, CONFIGURE_ARTISTS_ROLE, SEARCH_VIEW_PERFORMERS, UPDATE_ARTISTS_ROLE, VIEW_ARTISTS_ROLE } from "../Consts";
import { IArtist } from "../types/IArtist";
import { IArtistSearchQuery } from "../types/IArtistSearchQuery";

export interface IArtistsPageProps {
    artistsPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchArtists: (query: IArtistSearchQuery) => void;
    artistsSearchResults: IArtist[];
    getArtistDetails: (performerID: number, artistMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    addNewArtist: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    searchSuccessful: boolean;
    artistGeneralDataView: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    sortSearchResults: (name: string, results: any) => void;
}

const ArtistsPage = (props: IArtistsPageProps) => {
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()

    const addNewArtist = (): void => {
        const { addNewArtist, artistGeneralDataView, lookupValues } =props;
        addNewArtist(lookupValues, true, artistGeneralDataView);
    }

    React.useEffect(() => {
        checkIfLoaded();
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const checkIfLoaded =() => {
        const { artistsPageData } = props;

        if (artistsPageData.fields && artistsPageData.fields.length >= 1 && !loaded) {
            setLoaded(true)
            const header = artistsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const {
        searchViewData,
        searchResultsTableData,
        searchArtists,
        artistsSearchResults,
        searchSuccessful,
        artistGeneralDataView,
        indexOfFirstResult,
        indexOfLastResult,
        resultsPerPage,
        currentPage,
        resetPagination,
        updatePagination,
        roles,
        sortSearchResults
    } = props;

    const getArtistDetails = (performerID: number) => {
        props.getArtistDetails(performerID, artistGeneralDataView);
    }

    const getUpdateArtistRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(UPDATE_ARTISTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(VIEW_ARTISTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    if (loaded) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Artist</span>
                        <div className="newWorkButton" title="Create New Artist" hidden={getUpdateArtistRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewArtist}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                New Artist
                            </div>
                        </div>
                    </div>
                </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_PERFORMERS}
                    modalProps={undefined}
                    dataSource={undefined}
                    hideModal={undefined}
                    searchArtists={searchArtists}
                    artistsSearchResults={artistsSearchResults}
                    getArtistDetails={getArtistDetails}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updatePagination}
                    searchDisabled={getViewRoleDisabled()}
                    sortSearchResults={sortSearchResults}
                />
            </div>
        );
    }
    return <div>Loading ...</div>


    
}

export default ArtistsPage;