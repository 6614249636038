import React from "react";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { showModal } from "../../../../redux/reducers/MembersPortalReducer";
import { addUsageDetailsShareIPRow } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IUsagePoolState } from "../../../../redux/types/IUsagePoolState";
import TableHeaderSection from "../../../components/tableHeaderSection/TableHeaderSection";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { UsageRowView } from "../usageRowView/UsageRowView";
import { USAGE_ROWS_SECTION_KEY } from "../../../Consts";

interface IUsageMaintenanceDetailsGridProps {
    stateKey: IRepertoireStateKeys;
    isExpandable: boolean;
    expandUsageDetailRow?: (index: number) => void;
    tableContents: any;
    pool?: IUsagePoolState;
    dataGridTableData: IRepertoireComponentDataItem;
    changeData?: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    expandedUsageDetailsRow?: number[];
    addUsageDetailsShareIPRow?: typeof addUsageDetailsShareIPRow;
    lookupValues?: ILookupDictionary;
    showModal?: typeof showModal;
    resetMessageBanner?: () => void;
    updateUsagesAdvanced?: boolean;
}

const UsageMaintenanceDetailsGrid: React.FC<IUsageMaintenanceDetailsGridProps> = ({
    stateKey,
    isExpandable,
    expandUsageDetailRow,
    tableContents,
    pool,
    dataGridTableData,
    changeData,
    expandedUsageDetailsRow,
    addUsageDetailsShareIPRow,
    lookupValues,
    showModal,
    resetMessageBanner,
    updateUsagesAdvanced,
}) => {
    const [focusedRow, setFocusedRow] = React.useState<number>(-1);
    const [activeSortHeaderSection, setActiveSortHeaderSection] = React.useState<string>("");
    const [ascending, setAscending] = React.useState<boolean>(false);

    const sortTableByColumn = (section: string) => {
        let tableContentsCopy = JSON.parse(JSON.stringify(tableContents));
        if (activeSortHeaderSection !== section) {
            setAscending(false);
        }
        if (tableContentsCopy) {
            tableContentsCopy.sort((a: any, b: any) => {
                const newA = a[section] ? (a[section].value ? a[section].value : a[section]) || "" : "";
                const newB = b[section] ? (b[section].value ? b[section].value : b[section]) || "" : "";

                if (ascending) {
                    return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
                }
                return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });
            });
        }

        setActiveSortHeaderSection(section);
        setAscending(!ascending);
    };

    const onRowClick = (rowNumber) => {
        setFocusedRow(rowNumber);
        if (expandUsageDetailRow) {
            expandUsageDetailRow(rowNumber);
        }
    }


    const renderTableContents = () => {
        const rows: JSX.Element[] = [];
        if (tableContents) {
            let fieldsToRender: any = tableContents;
            fieldsToRender && fieldsToRender.map((tableContentItem: any, index: any) => {
                if (updateUsagesAdvanced) {
                    tableContentItem.durationPerPlay.inputType = "text"
                }
                let usageGridData = dataGridTableData;
                if (pool && pool.datesAndDimensions.length > 0) {
                    pool.datesAndDimensions.map(element => {
                        var startDate = new Date(element.effectiveFrom);
                        var endDate = element.effectiveTo != null ? new Date(element.effectiveTo) : new Date();
                        var dateToCheck = new Date(tableContentItem.usageDate.value);
                        if (dateToCheck >= startDate && dateToCheck <= endDate) {
                            tableContentItem.use.inputType = element.use ? 'text' : 'row';
                            tableContentItem.dimension1.inputType = element.dimension1 ? 'text' : 'row';
                            tableContentItem.dimension2.inputType = element.dimension2 ? 'text' : 'row';
                            tableContentItem.dimension3.inputType = element.dimension3 ? 'text' : 'row';
                            tableContentItem.dimension4.inputType = element.dimension4 ? 'text' : 'row';
                            tableContentItem.dimension5.inputType = element.dimension5 ? 'text' : 'row';
                        }
                        else {
                            tableContentItem.use.inputType = 'row';
                            tableContentItem.dimension1.inputType = 'row';
                            tableContentItem.dimension2.inputType = 'row';
                            tableContentItem.dimension3.inputType = 'row';
                            tableContentItem.dimension4.inputType = 'row';
                            tableContentItem.dimension5.inputType = 'row';
                        }
                    })
                } else {
                    tableContentItem.use.inputType = 'row';
                    tableContentItem.dimension1.inputType = 'row';
                    tableContentItem.dimension2.inputType = 'row';
                    tableContentItem.dimension3.inputType = 'row';
                    tableContentItem.dimension4.inputType = 'row';
                    tableContentItem.dimension5.inputType = 'row';
                }
                rows.push(
                    <UsageRowView
                        onRowClick={() => onRowClick(index)}
                        key={index}
                        usage={tableContentItem}
                        index={index}
                        dataGridTableData={usageGridData}
                        lookupValues={lookupValues}
                        changeData={changeData}
                        showModal={showModal}
                        addUsageDetailsShareIPRow={addUsageDetailsShareIPRow}
                        tableContents={tableContents}
                        resetMessageBanner={resetMessageBanner}
                        focusedRowNumber={focusedRow}
                        expandedUsageDetailsRow={expandedUsageDetailsRow}
                    />
                );
            });
        }

        return rows;
    };


    const getHeaderSections = () => {

        if (dataGridTableData && dataGridTableData.fields) {

            let filteredFields: IRepertoireField[] = dataGridTableData.fields.filter(
                (field: IRepertoireField) => field.componentInstance === USAGE_ROWS_SECTION_KEY && !field.hidden);
            let fieldsToRender = filteredFields;

            const headerSections = fieldsToRender.map((section: IRepertoireField, index: number) => (
                <td key={section.name} className={`td`}>
                    <TableHeaderSection
                        section={section.data}
                        fieldName={section.name}
                        isSortingActive={section.name === activeSortHeaderSection}
                        ascending={ascending}
                        onClickHeaderSection={sortTableByColumn}
                        sortable={true}
                    />
                </td>
            ));

            headerSections.sort((r1, r2) => {
                let index1 = dataGridTableData.fields.find(field => field.name === r1.key).order;
                let index2 = dataGridTableData.fields.find(field => field.name === r2.key).order;
    
                return index1 - index2
            });
            return headerSections;
        }
    };

    return (
        <>
            <div key="table" className={'row tableContainer'}>
                <div className="col-md-12">
                    <table className={["table", stateKey].join(" ")}>
                        <thead key="thead" className={"thead"}>
                            <tr key="headerRow" className="tr">
                                <td></td>
                                {getHeaderSections()}
                            </tr>
                        </thead>
                        <tbody key="body" className="tbody ">
                            {renderTableContents()}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default UsageMaintenanceDetailsGrid;