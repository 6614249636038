import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { FormatFields } from "../../redux/types/FormatFields";
import { IMemberStatementSearchResult } from "../types/IMemberStatementSearchResult";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import { IMembersPortalField } from "../types/IMembersPortalField";
import { SEARCH_VIEW_MEMBER_UNPAID_CLAIMS } from "../Consts";
import { ActionButtonFormik } from "../../repertoire/components/actionButton/ActionButton";
import { IUnpaidClaimsSearchQuery } from "../types/IUnpaidClaimsSearchQuery";
import DisplayTableSearchResults from "../sharedComponents/displayTableSearchResults/DisplayTableSearchResults";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { PRODUCT_WORKS_KEY, REPERTOIRE_PAGE_FORM_ITEM_SIZE, UNPAID_CLAIMS_WORK_SEARCH_VIEW } from "../../repertoire/Consts";
import { showModal } from "../../redux/reducers/MembersPortalReducer";
import { parseDateType } from "../util/dateHelpers";
import PositiveFeedBackUnpaidClaimsAlert from "./PositiveFeedBackUnpaidClaimsAlert";
import UnpaidClaimsInvalidDateAlert from "./UnpaidClaimsInvalidDateAlert";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import DatePickerFormikField from "./DatePickerFormik";
import TreeSelectFormik from "./TreeSelectFormik";
import * as Yup from 'yup';
import { useFormikContext } from 'formik';

const AutoSubmitToken = ({
    isSearching,
    shouldReload,
    setShouldReload,
    showUnpaidUsageClaimSuccessBanner,
    setShowSuccessAlert
}) => {
    const { submitForm } = useFormikContext();
    React.useEffect(() => {
        if (showUnpaidUsageClaimSuccessBanner) {
            setShowSuccessAlert(showUnpaidUsageClaimSuccessBanner);
            if (!isSearching && shouldReload) {
                setShouldReload(false);
                submitForm();
            }
        }
    }, [submitForm, shouldReload, isSearching, showUnpaidUsageClaimSuccessBanner]);
    return null;
};


export interface IUnpaidCLaimsMembersProps {
    searchUnpaidClaims: (query: IUnpaidClaimsSearchQuery, continuationToken: string) => void;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    unpaidClaimsSearchResults: any;
    searchSuccessful: boolean;
    paymentStatementGeneralDataViewData?: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    getMemberStatementDetails?: (payment: IMemberStatementSearchResult, lookupValues: ILookupDictionary, userNameNumber: string, formats?: FormatFields[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    memberPortalSearchType?: MemberPortalSearchTypeItem;
    currentUserNameNumber?: string;
    expandAllResults: () => void;
    expandAll: boolean;
    continuationToken: string;
    lookupSources: ITreeData[];
    distributions: { code: string; description: string; }[];
    showModal: typeof showModal;
    customerDatePreference: string;
    unpaidClaimsMembersPageData: IMembersPortalField[];
    isSearching?: boolean;
}

const UnpaidClaimsMembersPage: React.FC<IUnpaidCLaimsMembersProps> = ({
    searchUnpaidClaims,
    searchViewData,
    defaultActiveAccordions,
    allResultsPerPage,
    updateUserPreferences,
    continuationToken,
    searchSuccessful,
    searchResultsTableData,
    unpaidClaimsSearchResults,
    unpaidClaimsMembersPageData,

    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    lookupValues,
    sortSearchResults,
    lookupSources,
    distributions,
    showModal,
    customerDatePreference,
    tabs,
    activeTab,
    isSearching,
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const [shouldReload, setShouldReload] = React.useState(false);
    const [filteredFields, setFilteredFields] = React.useState<IRepertoireField[] | IMembersPortalField[]>([]);
    const [filteredSearchResults, setFilteredSearchResults] = React.useState<IRepertoireField[]>([]);
    const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
    const [showDateValidationAlert, setShowDateValidationAlert] = React.useState(false);
    const [presentCurrentPage, setPresentCurrentPage] = React.useState(1);
    const [tableResults, setTableResults] = React.useState([]);
    const { instance, accounts } = useMsal();


    const unpaidClaimsSearchSchema = Yup.object().shape({
        performanceStartDate: Yup.date().nullable(),
        performanceEndDate: Yup.date().nullable().min(
            Yup.ref('performanceStartDate'),
            "End date can't be before Start date"
        )
    });

    const componentInstance = SEARCH_VIEW_MEMBER_UNPAID_CLAIMS;

    React.useEffect(() => {
        setLoaded(true);
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    React.useEffect(() => {
        if (tabs[activeTab].showUnpaidUsageClaimSuccessBanner) {
        }
    }, [tabs, activeTab])

    React.useEffect(() => {
        if (searchViewData && searchViewData.fields) {
            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === componentInstance
            );
            setFilteredFields(filteredSearchFields);
        }

        if (searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance

            );
            setFilteredSearchResults(filteredFields);
        }

    }, [searchViewData])

    React.useEffect(() => {
        if (unpaidClaimsSearchResults && unpaidClaimsSearchResults.usageGroups && unpaidClaimsSearchResults.usageGroups) {
            const results = unpaidClaimsSearchResults.usageGroups;
            results && results.length > 0 && results.map(result => {
                result.dateLoaded = result.usages && result.usages.length > 0 ? parseDateType(result.usages[0].usageDate, customerDatePreference) : '-';
            })
            setTableResults(
                results
            )
        }

    }, [unpaidClaimsSearchResults])

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        setPresentCurrentPage(currentPage);
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const handleCloseDateAlert = () => {
        setShowDateValidationAlert(false);
    }

    const onSearch = (values: {
        usageTitle: string,
        usageContributor: string,
        usageArtist: string,
        performanceSource?: number,
        workNumber: string,
        distribution: string,
        performanceStartDate?: string,
        performanceEndDate?: string,
    }) => {

        let sourceMaj = '';
        let sourceMin = '';
        if (values.performanceSource) {
            const splitString = lookupSources.find(lookupSource => lookupSource.id === values.performanceSource).hierarchy.split("/").reverse();
            if (splitString.length > 2) {
                sourceMin = splitString[0];
                sourceMaj = splitString[1];
            } else {
                sourceMaj = splitString[0];
            }
        }


        let searchQuery = {
            fullTitle: values.usageTitle,
            fullContributor: values.usageContributor,
            fullPerformer: values.usageArtist,
            sourceMajor: sourceMaj,
            sourceMinor: sourceMin,
            workNumber: values.workNumber,
            distributionCode: values.distribution,
            isMembersPortal: true,
            performanceFromDate: values.performanceStartDate,
            performanceToDate: values.performanceEndDate,
        };
        searchUnpaidClaims(searchQuery, continuationToken);
    };

    const CustomInputComponent = ({
        field,
        form: { touched, errors },
        ...props
    }) => (
        <div>
            <input type="text" {...field} {...props} />
            {touched[field.name] &&
                errors[field.name] && <div className="error">{errors[field.name]}</div>}
        </div>
    );

    const renderFields = () => {
        let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
            let formField: React.ReactElement = <></>;

            if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal')) {

                formField = <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                    <label>{`${searchField.data}:`}</label>
                    <Field label={`${searchField.data}`} name={`${searchField.name}`} component={CustomInputComponent} />
                </div>
            }

            if (searchField.fieldType === 'dropdown' && searchField.name === 'distribution') {
                formField = <div key={index} className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                    <label>{`${searchField.data}:`}</label>
                    <Field label={`${searchField.data}`} name={`${searchField.name}`} as="select" >
                        <option value={''}>{''}</option >
                        {distributions && distributions.map(selectableOption => <option value={selectableOption.code}>{selectableOption.description}</option >)}
                    </Field>
                </div>
            }

            if (searchField.fieldType === 'dropdown' && searchField.name === 'performanceSource') {
                formField = <div key={index} className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                    <label>{`${searchField.data}:`}</label>
                    <TreeSelectFormik options={lookupSources ? lookupSources : undefined} name={`${searchField.name}`} />
                </div>
            }

            if (searchField.fieldType === 'date') {
                formField =
                    <div key={index} className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                        <label>{`${searchField.data}:`}</label>
                        <DatePickerFormikField label={`${searchField.data}`} name={`${searchField.name}`} customerDatePreference={customerDatePreference} />
                        <label><ErrorMessage name={`${searchField.name}`} /></label>
                    </div>
            }
            return formField;
        }

        return <FieldArray
            name="draftWorkForm"
            render={() => (
                <div>
                    {filteredFields.length > 0 ? (
                        filteredFields.map(filteredSearchFieldsMapper)
                    ) : <>Loading...</>}
                </div>
            )}
        />
    };

    const handleSorting = (result) => {
        sortSearchResults(SEARCH_VIEW_MEMBER_UNPAID_CLAIMS, result)
    }

    const rowActions = {
        iconButton: {
            icon: "icon ms-Icon ms-Icon--OpenInNewTab",
            onClick: (usage) => {
                showModal(UNPAID_CLAIMS_WORK_SEARCH_VIEW, PRODUCT_WORKS_KEY, { usage: usage }, true, 'Search')
                setShouldReload(true);
            }
        }
    }

    const alertText = unpaidClaimsMembersPageData.find(field => field.name === "unpaidClaimsMembersPageSuccessAlert")
    const alertInvalidDateText = unpaidClaimsMembersPageData.find(field => field.name === "unpaidClaimsMembersPageInvalidDateAlert")

    if (loaded && filteredFields && filteredFields.length > 0) {

        return (
            <div>
                <div className="searchView">
                    {showSuccessAlert && <PositiveFeedBackUnpaidClaimsAlert text={alertText.data} />}
                    {showDateValidationAlert && <UnpaidClaimsInvalidDateAlert text={alertInvalidDateText.data} handleCloseAlert={handleCloseDateAlert} />}
                    <Formik
                        initialValues={{
                            usageTitle: '',
                            usageContributor: '',
                            usageArtist: '',
                            performanceSource: undefined,
                            workNumber: '',
                            distribution: '',
                            performanceStartDate: undefined,
                            performanceEndDate: undefined,
                        }}
                        validationSchema={unpaidClaimsSearchSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            onSearch(values)
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting, isValid, dirty }) => (
                            <Form>
                                <div key='searchFields' className="row">
                                    {renderFields()}
                                </div>
                                <div key='action' className="row">
                                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                                        <ActionButtonFormik buttonText="Search" isSubmitting={isSubmitting} isErrorInForm={!isValid && dirty} />
                                    </div>
                                </div>
                                <AutoSubmitToken
                                    setShouldReload={setShouldReload}
                                    isSearching={isSearching}
                                    shouldReload={shouldReload}
                                    showUnpaidUsageClaimSuccessBanner={tabs[activeTab].showUnpaidUsageClaimSuccessBanner}
                                    setShowSuccessAlert={setShowSuccessAlert} />
                            </Form>
                        )}
                    </Formik>
                    <div className="row">
                        {searchSuccessful && tableResults && tableResults.length <= 0 && <div key='results' >
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                                No results found.
                            </div>
                        </div>}
                    </div>    
                </div>
                {searchSuccessful && tableResults && tableResults.length > 0 && <div key='results'>
                    <DisplayTableSearchResults
                        searchSuccessful={searchSuccessful}
                        searchResultsTableData={filteredSearchResults}
                        searchResults={tableResults}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={presentCurrentPage}
                        updatePagination={updateUserPagination}
                        lookupValues={lookupValues}
                        resetPagination={resetPagination}
                        sortSearchResults={handleSorting}
                        rowActions={rowActions}
                    />
                </div>}
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default UnpaidClaimsMembersPage;