import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addTab, setChangesMade, updateClaimField, createNewClaim, resetMessageBanner } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IClaimStateKeys, IClaimState } from "../../../../redux/types/IClaimState";
import { processClaimThunk, saveClaimThunk, updateComponentFieldItemsThunk } from "../../../../redux/thunks/RepertoireThunks";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import { getDistributionsByCDTypeThunk } from "../../../../redux/thunks/HomeThunks";

import { ITriggerManualJob } from "../../../../redux/types/ITriggerManualJob";
import { triggerManualClaimJob } from "../../../../redux/thunks/JobThunks";
import ClaimMaintenancePageScrollListener from "./ClaimMaintenancePageScrollListner";
import { ITabProps } from "../../../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        claimMaintenanceGeneralDataViewData: state.repertoire.componentData.ClaimMaintenanceGeneralDataView,
        claimMaintenanceGridsViewData: state.repertoire.componentData.ClaimMaintenanceGridsView,
        claimMaintenanceSaveResultData: state.repertoire.componentData.SaveResult,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        activeTab: state.repertoire.panes[props.paneIndex].activeTab,
        tabs: state.repertoire.panes[props.paneIndex].tabs,
        loaded: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].claimMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].claimMaintenanceState.loaded,
        claim: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].claimMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].claimMaintenanceState.claim,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        lookupValues: state.lookupEntities,
        editableFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields,
        claimMaintenanceToolbarData: state.repertoire.componentData.ClaimMaintenanceToolbar,
        roles: state.account.roles,
        openDistributions: state.home.openDistributions,
    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch(hideModal()),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        setChangesMade: (tabIndex: number, changesMade: boolean) => dispatch(setChangesMade(tabIndex, changesMade)),
        updateClaimField: (value: any, fieldName: IClaimStateKeys) => dispatch(updateClaimField(value, fieldName)),
        saveChanges: (claim: IClaimState) => dispatch<any>(saveClaimThunk(claim)),
        addNewClaim: (lookupValues: ILookupDictionary, isNew?: boolean, claimMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => dispatch<any>(createNewClaim(lookupValues, isNew, claimMaintenanceGeneralDataView)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) => dispatch(showYesNoPrompt(title, props)),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        processClaim: (claim:IClaimState) => dispatch<any>(processClaimThunk(claim)),
        getOpenDistributions: () => dispatch<any>(getDistributionsByCDTypeThunk()),
        triggerManualJob: (manualJob: ITriggerManualJob) =>
            dispatch<any>(triggerManualClaimJob(manualJob))
    })
)(ClaimMaintenancePageScrollListener);