export interface IRepresentationSearchState {
    customDateRange: boolean;
    dateRange?: string;
    dateFrom?: Date;
    dateTo?: Date;
}
export type IRepresentationSearchStateKeys = keyof IRepresentationSearchState;
export const REPRESENTATION_CUSTOM_DATE_RANGE: IRepresentationSearchStateKeys = "customDateRange";
export const REPRESENTATION_DATE_RANGE: IRepresentationSearchStateKeys = "dateRange";
export const REPRESENTATION_DATE_FROM: IRepresentationSearchStateKeys = "dateFrom";
export const REPRESENTATION_DATE_TO: IRepresentationSearchStateKeys = "dateTo";