import 'antd/dist/reset.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import './style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { unregister } from './registerServiceWorker';
import { Provider } from "react-redux";
import configureStore from "./redux/Store";
import App from './App';
import { createBrowserHistory } from 'history';
import { initializeIcons } from 'office-ui-fabric-react';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./accounts/types/msalAuthConfig"
import { getAADLoginConfigurationThunk, getB2CLoginConfigurationThunk, getPortalType } from './redux/thunks/AccountThunks';
import { setPortalType } from './redux/reducers/AccountReducer';
import { MATCHING_ENGINE, MEMBERS_PORTAL } from './accounts/Consts';
import { ConfigProvider } from 'antd';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const history = createBrowserHistory();
const store = configureStore(history);

unregister();

initializeIcons();

(async function () {
    try {
        const portalType = await getPortalType();
        const aadConfig = await getAADLoginConfigurationThunk();
        const b2cConfig = await getB2CLoginConfigurationThunk();

        if (portalType === MEMBERS_PORTAL) {
            msalConfig.auth.clientId = b2cConfig.clientId;
            msalConfig.auth.authority = b2cConfig.authority + b2cConfig.webPolicy;
            msalConfig.auth.knownAuthorities = [b2cConfig.authorityDomain];
            msalConfig.auth.redirectUri = "https://" + window.location.host + "/aadB2CLogin"
            msalConfig.auth.scope = b2cConfig.apiScope;
        }
        else if (portalType === MATCHING_ENGINE) {

            msalConfig.auth.clientId = aadConfig.clientId;
            msalConfig.auth.authority = aadConfig.authority;
            msalConfig.auth.scope = aadConfig.scope;
            msalConfig.auth.redirectUri = "https://" + window.location.host + "/aadLogin"
        }
        const msalInstance = new PublicClientApplication(msalConfig);

        store.dispatch(setPortalType(portalType));

        ReactDOM.render(
            <MsalProvider instance={msalInstance}>
                <Provider store={store}>
                    <BrowserRouter basename={baseUrl}>
                    <ConfigProvider theme={{ hashed: false }}>
                    <App portalType={portalType} dispatch={store.dispatch} instance={msalInstance} />
                    </ConfigProvider>
                        
                    </BrowserRouter>
                </Provider>
            </MsalProvider>,
            rootElement);
    }
    catch (e) {
        console.log(e);
    }
})();