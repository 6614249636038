import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import MyAccountPage from "./MyAccountPage";

export default connect(
    (state: IAppState) => ({
        isCRMIntegrationEnabled: state.membersPortal.isCRMIntegrationEnabled,
        powerPortalURL: state.membersPortal.powerPortalURL

    }),
    (dispatch: Dispatch) => ({
    })
)(MyAccountPage);