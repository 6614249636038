import {
    postWorkBatchRequest,
    postWorkBatchSuccess,
    postWorkBatchFailed,
    matchWorkBatchRequest,
    matchWorkBatchSuccess,
    matchWorkBatchFailed,
    postProductBatchRequest,
    postProductBatchSuccess,
    postProductBatchFailed,
    matchProductBatchRequest,
    matchProductBatchSuccess,
    matchProductBatchFailed,
    postAgreementBatchRequest,
    postAgreementBatchSuccess,
    postAgreementBatchFailed,
    allocateDistributionPoolsRequest,
    allocateDistributionPoolsSuccess,
    allocateDistributionPoolsFailure,
    exportDistributionPoolsRequest,
    exportDistributionPoolsSuccess,
    exportDistributionPoolsFailure,
    triggerManualClaimJobSuccess,
    triggerManualClaimJobFailure,
    triggerManualClaimJobRequest,
    runQAOnDistributionPoolsRequest,
    runQAOnDistributionPoolsFailure,
    runQAOnDistributionPoolsSuccess,
    updateScheduledJobField
} from "../reducers/RepertoireReducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import IAppState from "../types/IAppState";
import { JobService } from "../services/JobService";
import { IDistributionState } from "../types/IDistributionState";
import { DistributionDetailsMapper } from "../../repertoire/services/DistributionDetailsMapper";
import { ITriggerManualJob } from "../types/ITriggerManualJob";
import { IScheduledJobState } from "../types/IScheduledJobState";
import { hideModal } from "../reducers/ModalReducer";

export const postWorkBatchThunk = (workBatchID: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(postWorkBatchRequest(workBatchID));
        JobService.postWorkBatch(workBatchID)
            .then(res => {
                res.status === 200 ? dispatch(postWorkBatchSuccess()) : dispatch(postWorkBatchFailed());
            })
            .catch((error: any) => {
                dispatch(postWorkBatchFailed());
            })
    };
}

export const matchWorkBatchThunk = (workBatchID: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(matchWorkBatchRequest(workBatchID));
        JobService.matchWorkBatch(workBatchID)
            .then(res => {
                res.status === 200 ? dispatch(matchWorkBatchSuccess()) : dispatch(matchWorkBatchFailed());
            })
            .catch((error: any) => {
                dispatch(postWorkBatchFailed());
            })
    };
}

export const postProductBatchThunk = (productBatchID: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(postProductBatchRequest(productBatchID));
        JobService.postProductBatch(productBatchID)
            .then(res => {
                res.status === 200 ? dispatch(postProductBatchSuccess()) : dispatch(postProductBatchFailed());
            })
            .catch((error: any) => {
                dispatch(postProductBatchFailed());
            })
    };
}

export const matchProductBatchThunk = (productBatchID: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(matchProductBatchRequest(productBatchID));
        JobService.matchProductBatch(productBatchID)
            .then(res => {
                res.status === 200 ? dispatch(matchProductBatchSuccess()) : dispatch(matchProductBatchFailed());
            })
            .catch((error: any) => {
                dispatch(postProductBatchFailed());
            })
    };
}

export const postAgreementBatchThunk = (agreementBatchID: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(postAgreementBatchRequest(agreementBatchID));
        JobService.postAgreementBatch(agreementBatchID)
            .then(res => {
                res.status === 200 ? dispatch(postAgreementBatchSuccess()) : dispatch(postAgreementBatchFailed());
            })
            .catch((error: any) => {
                dispatch(postAgreementBatchFailed());
            })
    };
}

export const postAdjustmentBatchThunk = (adjustmentBatchID: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(postAgreementBatchRequest(adjustmentBatchID));
        JobService.postAdjustmentBatch(adjustmentBatchID)
            .then(res => {
                res.status === 200 ? dispatch(postAgreementBatchSuccess()) : dispatch(postAgreementBatchFailed());
            })
            .catch((error: any) => {
                dispatch(postAgreementBatchFailed());
            })
    };
}

export const allocateDistributionPoolsThunk = (distribution: IDistributionState, undoAllocation: boolean): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(allocateDistributionPoolsRequest())
        var distributionModel = DistributionDetailsMapper.mapStateToDistribution(distribution, true)
        JobService.allocateDistributionPools(distributionModel, undoAllocation)
            .then((res) => {
                if (res.status !== 200 || !res.data.success)
                    dispatch(allocateDistributionPoolsFailure(res.data));
                else
                    dispatch(allocateDistributionPoolsSuccess(res.data));
            })
            .catch(err => {
                dispatch(allocateDistributionPoolsFailure(err));
            })
    }
}

export const triggerManualClaimJob = (
    manualJob: ITriggerManualJob
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(triggerManualClaimJobRequest())
        return JobService.triggerManualJob(manualJob)
            .then((res: any[]) => {
                dispatch(triggerManualClaimJobSuccess());
            })
            .catch((error: any) => {
                dispatch(triggerManualClaimJobFailure(error));
            });
    }
}

export const upsertScheduledJobThunk = (schedule: IScheduledJobState, componentName?:string): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        JobService.upsertScheduledJob(schedule)
                .then((sch: IScheduledJobState) => {
                    if (sch && (!sch.errorList || sch.errorList?.length === 0)) {
                        dispatch(hideModal());
                    }
                    dispatch(updateScheduledJobField(sch.errorList, "errorList",componentName));
                })
                .catch(err => {
                    dispatch(hideModal());
                });
        }
}

export const runQAOnDistributionPoolsThunk = (distribution: IDistributionState, undoAllocation: boolean): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(runQAOnDistributionPoolsRequest())
        var distributionModel = DistributionDetailsMapper.mapStateToDistribution(distribution, true)
        JobService.runQAOnDistributionPools(distributionModel, undoAllocation)
            .then((res) => {
                if (res.status !== 200 || !res.data.success)
                    dispatch(runQAOnDistributionPoolsFailure(res.data));
                else
                    dispatch(runQAOnDistributionPoolsSuccess(res.data));
            })
            .catch(err => {
                dispatch(runQAOnDistributionPoolsFailure(err));
            })
    }
}