import * as React from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { EMPTY_STRING_VALUE, REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";
import TimeField from "./timeField";

export interface ITimeDataInputProps {
    label?: string;
    value?: any;
    changeData: (event: any, value: string, fieldName: IRepertoireStateKeys, index?: number) => void;
    fieldName: IRepertoireStateKeys;
    isHidden: boolean;
    isDataGridSize?: boolean;
    isMandatory?: boolean;
    readOnly?:boolean;
    errors?: any;
    isFormik?: boolean;
}

interface ITimeDataInputState { }

export const SizedTimeDataInput = (props: ITimeDataInputProps) => {

    if(props.isHidden)
        return null;
    if(props.isFormik) {
        return <TimeFieldInput {...props} />
    }

    return  <div className={!props.isDataGridSize ? REPERTOIRE_PAGE_FORM_ITEM_SIZE : EMPTY_STRING_VALUE}>
            <TimeFieldInput {...props} />
        </div>
}

export default class TimeFieldInput extends React.PureComponent<
    ITimeDataInputProps,
    ITimeDataInputState
>{
    constructor(props: ITimeDataInputProps){
        super(props);

        this.state={};
    }

    render(){
        const {
            label,
            value,
            changeData,
            fieldName,
            isMandatory,
            readOnly,
            errors,
        } = this.props;
        const showError = errors && errors[fieldName];
        return (
            <div className={showError ? "validationForkmikField dropdownDataInput" : "emptyValidationBoarder dropdownDataInput"}>
                {label ? <label className={EMPTY_STRING_VALUE.concat(isMandatory ? " isMandatory " : "", readOnly ? " disabledItem " : "")}>{label}:</label> : null}
                    <TimeField
                        value={value}
                        onChange={changeData}
                        showSeconds={true}
                        name={fieldName}
                        readOnly={readOnly}
                    />
            </div>
        )
    }




}