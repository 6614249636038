import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { FormatFields } from "../../redux/types/FormatFields";
import { IMemberStatementSearchResult } from "../types/IMemberStatementSearchResult";
import { IRepertoireStateKeys } from "../../repertoire/types/IRepertoireStateKeys";
import { IRepertoireField } from "../../repertoire/types/IRepertoireField";
import { IMembersPortalField } from "../types/IMembersPortalField";
import { EMPTY_STRING_VALUE, SEARCH_VIEW_CLAIMS_HISTORY, SEARCH_VIEW_MEMBER_UNPAID_CLAIMS } from "../Consts";
import ActionButton from "../../repertoire/components/actionButton/ActionButton";
import { SizedTextDataInput } from "../../repertoire/components/textDataInput/TextDataInput";
import { SizedDropdownDataInput } from "../../repertoire/components/dropdownDataInput/DropdownDataInput";
import DisplayTableSearchResults from "../sharedComponents/displayTableSearchResults/DisplayTableSearchResults";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { IClaimsHistorySearchQuery } from "../types/IClaimsHistorySearchQuery";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { MEMBER_CLAIM_STATUS_LOOKUP } from "../../lookup/Consts";

export interface IClaimsHistoryPageProps {
    searchClaimsHistory: (query: IClaimsHistorySearchQuery, continuationToken: string) => void;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    claimsHistorySearchResults: any;
    searchSuccessful: boolean;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    memberPortalSearchType?: MemberPortalSearchTypeItem;
    currentUserNameNumber?: string;
    expandAllResults: () => void;
    expandAll: boolean;
    continuationToken: string;
    lookupSources: ITreeData[];
    getUserCRMValues: () => void;
}

const ClaimsHistoryPage: React.FC<IClaimsHistoryPageProps> = ({
    searchClaimsHistory,
    searchViewData,
    defaultActiveAccordions,
    allResultsPerPage,
    updateUserPreferences,
    continuationToken,
    searchSuccessful,
    searchResultsTableData,
    claimsHistorySearchResults,
    currentUserNameNumber,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    lookupValues,
    sortSearchResults,
    getUserCRMValues,
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();
    const componentInstance = SEARCH_VIEW_CLAIMS_HISTORY;

    const [formData, setFormData] = React.useState({
        usageTitle: '',
        claimApprovalStatus:'',
        claimantCurrentUserNameNumber:''
    });

    React.useEffect(() => {
        if (!currentUserNameNumber) {
            getUserCRMValues();
        }
        setLoaded(true);
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
       
    }, [])

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const changeData = (value: any, fieldName: IRepertoireStateKeys) => {
        setFormData(
            {...formData, [fieldName]: value}
        )
     }

    const  keyDownSubmit = (value: any) => {
        if (value.key === 'Enter') {
            onClickSearch();
        }
    }

    const onClickSearch = () => {
        let searchQuery = {
            fullTitle: formData.usageTitle,
            claimApprovalStatus: formData.claimApprovalStatus,  
            isMembersPortal: true, 
            claimantCurrentUserNameNumber:currentUserNameNumber
        };
        searchClaimsHistory(searchQuery, continuationToken);
    };


    const renderSearchFields = () => {
            let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
                let formField: React.ReactElement = <></>;

                if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal')) {
                    formField =
                        <div key={index}>
                            <SizedTextDataInput
                                fieldType={searchField.fieldType}
                                useEnterSubmit={true}
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={(value, fieldName) => changeData(value, fieldName)}
                                handleKeyDown={keyDownSubmit}
                                value={searchField.name === 'values' ? EMPTY_STRING_VALUE : formData[searchField.name]}
                                isHidden={false}
                            />
                        </div>
                }

                if (searchField.fieldType === 'dropdown' && searchField.name === 'claimApprovalStatus') {
                    formField =
                        <div key={index}>
                            <SizedDropdownDataInput
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={changeData}
                                value={formData[searchField.name]}
                                options={getLookupValues(MEMBER_CLAIM_STATUS_LOOKUP, lookupValues)}
                                allowNull={true}
                                isHidden={searchField.hidden}
                                componentInstance={componentInstance}
                                readOnly={false}
                            />
                        </div>
                }

                return formField;

            };
            return filteredSearchFields.map(filteredSearchFieldsMapper);
    };

    const handleSorting = (result) => {
        sortSearchResults(SEARCH_VIEW_CLAIMS_HISTORY, result)
    }
    const filteredSearchFields = searchViewData.fields.filter(
        (searchField: IRepertoireField | IMembersPortalField) =>
            searchField.componentInstance === componentInstance
    );
    if (loaded && filteredSearchFields && filteredSearchFields.length > 0) {

        const results = claimsHistorySearchResults && claimsHistorySearchResults.priorClaims;   
            const searchResults = results && results.map((element:any)=>{
                switch(element.claimApprovalStatus) {
                    case 'Open':
                        return {...element,claimApprovalStatus:'Pending'};
                    case 'Approved':
                        return {...element,claimApprovalStatus:'Approved'};
                    case 'Assigned':
                        return {...element,claimApprovalStatus:'Pending - Assigned'};
                    case 'Rejected':
                        return {...element,claimApprovalStatus:'Rejected'};
                    case 'RejectedLowSimilarity':
                        return {...element,claimApprovalStatus:'Rejected - Low Similarity'};
                    case 'Cancelled':
                        return {...element,claimApprovalStatus:'Cancelled'};
                    default:
                        return {...element};
                }
            })
        const searchResultData= searchResultsTableData.fields.filter(
            (field: IRepertoireField) =>
                field.componentInstance === componentInstance
    
        )
        return (
            <div>
                <div className="searchView">
                    <div key='searchFields' className="row">
                    {renderSearchFields()}
                    </div>
                    <div key='action' className="row">
                        <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                            <ActionButton buttonText="Search" buttonAction={onClickSearch}/>
                        </div>
                    </div>
                    {searchSuccessful && <div key='results'>
                            <DisplayTableSearchResults 
                                searchSuccessful={searchSuccessful} 
                                searchResultsTableData={searchResultData} 
                                searchResults={searchResults}
                                indexOfFirstResult={indexOfFirstResult}
                                indexOfLastResult={indexOfLastResult}
                                resultsPerPage={resultsPerPage}
                                currentPage={currentPage}
                                updatePagination={updateUserPagination}
                                lookupValues={lookupValues}
                                resetPagination={resetPagination}
                                sortSearchResults={handleSorting}
                            />
                    </div>}
                </div>
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default ClaimsHistoryPage;
