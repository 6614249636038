import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import { SettingsPage } from "./SettingsPage";
import {
  getMatchTypesThunk,
  getSourcesThunk,
  loadSourceThunk,
  updateSourceThunk,
  updateSourceHierarchyThunk,
  updateParameterInstancesThunk,
  loadFolderListThunk
} from "../../redux/thunks/SettingsThunk";
import { ISourceSearchResult } from "../types/ISourceSearchResult";
import { IProductType } from "../types/IProductType";
import { PropertyWindowSaveState } from "../types/PropertyWindowSaveState";
import { updateTreeNodes } from "../../redux/actions/SettingsActionCreators";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";
import { IParameterSearchResult } from "../types/IParameterSearchResult";
import { SettingType } from "../types/SettingType";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { getDistributionsThunk, getPathFilesystems, getPoolsThunk, initialiseLookupsThunk, selectedNodeChangedThunk } from "../../redux/thunks/DataIngestionThunks";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { FolderSection } from "../../dataingestion/types/FolderSection";
import { IFileSystem } from "../../dataingestion/types/IFileSystem";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { getMatchingSourcesThunk } from "../../redux/thunks/RepertoireThunks";

export default connect(
    (state: IAppState, props?: any) => ({
        settingType: SettingType.Matching,
        path: props.match.path,
        roles: state.account.roles,
        distributions: state.dataIngestion.distributions,
        pools: state.dataIngestion.pools,
        lookupValues: state.lookupEntities,
        formats: state.dataIngestion.formats,
        filesystems: state.dataIngestion.filesystems,
        folderTreeNodes: state.dataIngestion.treeNodes,
        folderSelectedNode: state.dataIngestion.selectedNode,
        sources: state.repertoire.matchingSourceTreeData,
        sorcesFolderList: state.dataIngestion.sorcesFolderList
  }),
  (dispatch: Dispatch) => ({
    getMatchTypes: () => dispatch<any>(getMatchTypesThunk()),
    getSources: (matchTypeID: number) =>
      dispatch<any>(getSourcesThunk(matchTypeID)),
    loadSource: (
      sourceID: number,
      selectedProductType: IProductType,
      settingType: number,
      saveState: PropertyWindowSaveState
    ) => {
      dispatch<any>(
        loadSourceThunk(sourceID, selectedProductType, settingType, saveState)
      );
    },
    updateSource: (
      sourceID: number,
      sourceName: string,
      sourceCode: string,
      sourceDescription: string,
      rejectDuplicateUsageFiles: boolean
    ) =>
        dispatch<any>(updateSourceThunk(sourceID, sourceName, sourceCode, sourceDescription, rejectDuplicateUsageFiles)),
    updateSourceHierarchy: (sourceTree: ISourceSearchResult) =>
      dispatch<any>(updateSourceHierarchyThunk(sourceTree)),
    updateTreeNodes: (updatedTreeNodes: ITreeNodeData<ISourceSearchResult>) =>
      dispatch(updateTreeNodes(updatedTreeNodes)),
    updateParameterInstances: (
      parameters: IParameterSearchResult[],
      sourceID: number,
      selectedProductType: IProductType,
      settingType: number
    ) =>
      dispatch<any>(
        updateParameterInstancesThunk(
          parameters,
          sourceID,
          selectedProductType,
          settingType
        )
    ),
      saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
      getPools: () => dispatch<any>(getPoolsThunk()),
      getDistributions: () => dispatch<any>(getDistributionsThunk()),
      initialiseLookupsThunk: (lookupValues: ILookupDictionary) => dispatch<any>(initialiseLookupsThunk(lookupValues)),
      getPathFilesystems: (path: string, disallowedFolderSections: FolderSection[]) => dispatch<any>(getPathFilesystems(path, false, disallowedFolderSections)),
      selectedFolderNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => dispatch<any>(selectedNodeChangedThunk(node, sources)),
      getMatchingSources: () => dispatch<any>(getMatchingSourcesThunk()),
      getUsedFolderList: () => dispatch<any>(loadFolderListThunk()),
  })
)(SettingsPage);
