import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { EXTERNAL, INTERNAL, LICENSE_AFFILIATED_USER_KEY, LICENSE_AFFILIATED_USER_STATE_KEY, LICENSE_COMMERCIAL_USE_TYPE_STATE_KEY, LICENSE_EXTERNAL_USER_TYPE_STATE_KEY, LICENSE_ID_STATE_KEY, LICENSE_LICENSE_CODE_STATE_KEY, LICENSE_SHOPPING_CART_NAME_STATE_KEY, LICENSE_STATUS_STATE_KEY, LICENSE_TOTAL_AMOUNT_STATE_KEY, LICENSE_USER_TYPE_STATE_KEY, LICENSE_USES_KEY, REPERTOIRE_PAGE_FORM_ITEM_SIZE, SEARCH_VIEW, SEARCH_VIEW_LICENSE_USER, TEXT_INPUT, TEXT_READONLY_IN_REPERTOIRE_INPUT } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { updateLicenseField } from "../../../../redux/reducers/RepertoireReducer";
import { InlineActionButton } from "../../../components/actionButton/ActionButton";
import { showModal } from "../../../../redux/reducers/ModalReducer";
import { ILicenseStateKeys, IShoppingCartState } from "../../../../redux/types/IShoppingCartState";
import { ILicenseRequestItem } from "../../../../redux/types/ILicenseRequestItem";

export interface ILicenseMaintenanceGeneralDataViewProps {
    licenseMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    shoppingCart: IShoppingCartState,
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    updateLicenseField: typeof updateLicenseField;
    showModal: typeof showModal
    licenseRequest: ILicenseRequestItem;
    licenseCode?: string;
}

interface ILicenseMaintenanceGeneralDataViewState {
    loaded: boolean;
}

export class LicenseMaintenanceGeneralDataView extends React.PureComponent<
    ILicenseMaintenanceGeneralDataViewProps,
    ILicenseMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: ILicenseMaintenanceGeneralDataViewProps) {
        super(props);
        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidUpdate() {
        this.loadData();
    }

    componentDidMount() {
        const {
            licenseMaintenanceGeneralDataViewData: { fields }
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    loadData() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.licenseMaintenanceGeneralDataViewData) {
                return;
            }
            else {
                this.setState({ loaded: true });
            }
        }
    }


    showFieldsModal = () => {

    }

    showSearchModal = () => {
        const { showModal } = this.props;
        const user = this.props.shoppingCart;

        showModal(SEARCH_VIEW, SEARCH_VIEW_LICENSE_USER, {
            email: user
        }, true, "Search");
    };

    render() {
        const {
            isReadonly,
            lookupValues,
            updateLicenseField,
            licenseRequest,
            licenseCode
        } = this.props;

        const {
            shoppingCartID,
            userName,
            shoppingCartName 
        } = this.props.shoppingCart;

        const { loaded } = this.state;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            updateLicenseField(value, name as ILicenseStateKeys);
        }

        if (loaded) {
            return (
                <div>
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <h1 className="borderBootom">General</h1>
                        </div>
                    </div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>License Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                                <div>
                                    <label>{this.viewData[LICENSE_AFFILIATED_USER_STATE_KEY]}:</label>
                                    <div className={"wrapperLookup"}>
                                        <input
                                            disabled
                                            type={"text"}
                                            value={userName.value}
                                            maxLength={150}
                                        />
                                        <InlineActionButton
                                            buttonText={"Lookup"}
                                            className={"buttonLookup"}
                                            buttonAction={this.showSearchModal}
                                        />
                                    </div>
                                </div>
                            </div>

                            <SizedTextDataInput
                            changeData={changeData}
                                fieldName={LICENSE_SHOPPING_CART_NAME_STATE_KEY }
                                fieldType={TEXT_INPUT}
                                key={this.viewData[LICENSE_SHOPPING_CART_NAME_STATE_KEY]}
                                maxLength={100}
                                label={this.viewData[LICENSE_SHOPPING_CART_NAME_STATE_KEY]}
                                readOnly={false}
                                value={shoppingCartName.value}
                                isHidden={!this.viewData[LICENSE_SHOPPING_CART_NAME_STATE_KEY]}
                            />

                        </div>    
                        <div className="row">
                            <SizedTextDataInput 
                                changeData={changeData}
                                fieldName={this.viewData[LICENSE_LICENSE_CODE_STATE_KEY]}
                                fieldType={TEXT_READONLY_IN_REPERTOIRE_INPUT}
                                key={this.viewData[LICENSE_LICENSE_CODE_STATE_KEY]}
                                label={this.viewData[LICENSE_LICENSE_CODE_STATE_KEY]}
                                readOnly={true}
                                value={licenseCode}
                                isHidden={!this.viewData[LICENSE_LICENSE_CODE_STATE_KEY]}
                            />
                            <SizedTextDataInput 
                                changeData={changeData}
                                fieldName={this.viewData[LICENSE_TOTAL_AMOUNT_STATE_KEY]}
                                fieldType={TEXT_READONLY_IN_REPERTOIRE_INPUT}
                                key={this.viewData[LICENSE_TOTAL_AMOUNT_STATE_KEY]}
                                label={this.viewData[LICENSE_TOTAL_AMOUNT_STATE_KEY]}
                                readOnly={true}
                                value={licenseRequest.amountTotal}
                                isHidden={!this.viewData[LICENSE_TOTAL_AMOUNT_STATE_KEY]}
                            />
                            <SizedTextDataInput 
                                changeData={changeData}
                                fieldName={this.viewData[LICENSE_STATUS_STATE_KEY]}
                                fieldType={TEXT_READONLY_IN_REPERTOIRE_INPUT}
                                key={this.viewData[LICENSE_STATUS_STATE_KEY]}
                                label={this.viewData[LICENSE_STATUS_STATE_KEY]}
                                readOnly={true}
                                value={licenseRequest.status.value}
                                isHidden={!this.viewData[LICENSE_STATUS_STATE_KEY]}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return <div />;

    }
}