import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import { mp_addTab, resetPagination, sortSearchResults, updatePagination, expandMemberStatementResult, expandAllResults } from "../../redux/reducers/MembersPortalReducer";
import { loadMembersPortalFromPathThunk, searchClaimsHistoryThunk, searchUnpaidClaimsThunk } from "../../redux/thunks/MembersPortalThunks";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import { IMembersPortalPathInfo } from "../types/IMembersPortalPathInfo";
import ClaimsHistoryPage from "./ClaimsHistoryPage";
import { IUnpaidClaimsSearchQuery } from "../types/IUnpaidClaimsSearchQuery";
import { IClaimsHistorySearchQuery } from "../types/IClaimsHistorySearchQuery";
import { getUserCRMValuesThunk } from "../../redux/thunks/AccountThunks";

export default connect(
    (state: IAppState) => ({
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        searchSuccessful: state.membersPortal.searchSuccessful,
        claimsHistorySearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].claimsHistorySearchResults,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'claimsHistory')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'claimsHistory')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'claimsHistory')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        allResultsPerPage: state.membersPortal.resultsPerPage,
        roles: state.account.roles,
        currentUserNameNumber: state.account.currentUserNameNumber,
        expandedMemberStatementResults: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandedMemberStatementResults,
        expandAll: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].expandAll,
        continuationToken: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].continuationToken ? state.membersPortal.tabs[state.membersPortal.activeTab].continuationToken : undefined,
        lookupSources: state.membersPortal.matchingSourceTreeData,
    }),
    (dispatch: Dispatch) => ({
        searchClaimsHistory: (query: IClaimsHistorySearchQuery, continuationToken: string) =>
            dispatch<any>(searchClaimsHistoryThunk(query, continuationToken)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        loadMembersPortalFromPath: (pathInfo: IMembersPortalPathInfo, currentUserNameNumber: string, location: Location) => dispatch<any>(loadMembersPortalFromPathThunk(pathInfo, currentUserNameNumber, location)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        getUserCRMValues: () => dispatch<any>(getUserCRMValuesThunk()), 

    })
)(ClaimsHistoryPage);
