import React from "react";
import { ReactNode } from "react";
import { IJobParameterControl } from "./IJobParameterControl";

export class CheckboxListJobParameterControl
  implements IJobParameterControl<string[]>
{
  CurrentValue: string[];
  DefaultValue: string[];
  Options?: { code: string[]; description: string }[] = null;
  ControlID: string;
  Type:
    | "combobox"
    | "text-input"
    | "date-picker"
    | "numeric"
    | "radio-button"
    | "checkbox"
    | "checkbox-list"
    | "multiselect" = "checkbox-list";
  Caption: string;
  Readonly: boolean;

  constructor() {}

  Render(): ReactNode {
    return (
        <div className="dropdownDataInput"></div>
    );
  }
}
