import { ISourceSearchResult } from '../types/ISourceSearchResult';
import { ITreeNodeData } from '../../treeview/types/TreeNodeData';

export class TreeNodeBuilder {
    public static build(source: ISourceSearchResult, deletedNodes?: number[]): ITreeNodeData<ISourceSearchResult> {
        if (source === null) { return null; }

        let node: ITreeNodeData<ISourceSearchResult> = {
            name: source.name,
            id: source.sourceID,
            children: [],
            level: source.level,
            active: false,
            object: source,
            visible: deletedNodes.find(nodes => nodes === (source.sourceID === 0 ? source.fakeID : source.sourceID)) ? false : true,
            isExpanded: false
        };

        if (source.children) {
            source.children.map(r => 
                node.children.push(TreeNodeBuilder.build(r,deletedNodes)));
        }

        return node;
    }

    public static hideNode(data: ITreeNodeData<ISourceSearchResult>, id: number, fakeId: number): ITreeNodeData<ISourceSearchResult> {

        if (data === null) { return null; }

        if(data.id <= 0  && data.object.fakeID === fakeId){
           data.visible = false;
        }
        else if(data.id == id) {
            data.visible = false;
        }

        if (data.children != null) {
            data.children.map(c => TreeNodeBuilder.hideNode(c, id, fakeId));
        }

        return data;
    }

    public static showNode(data: ITreeNodeData<ISourceSearchResult>, ids: number[]): ITreeNodeData<ISourceSearchResult> {
        if (data === null) {
            return null;
        }

        if (ids.includes(data.id)) {
            data.object.actionType = 1; 
            data.visible = true;
        }

        if (data.children != null) {
            data.children = data.children.map(c => TreeNodeBuilder.showNode(c, ids));
        }

        return data;
    }

}