import { Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { LookupService } from "../../lookup/services/LookupService";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import {
    getLookupEntitiesFailure,
    getLookupEntitiesRequest,
    getLookupEntitiesSuccess
} from "../reducers/LookupReducer";
import IAppState from "../types/IAppState";

export const getLookupEntitiesThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>
> => {
    return (dispatch: Dispatch) => {
        dispatch(getLookupEntitiesRequest());
        return LookupService.getLookUpEntities()
            .then((res: ILookupDictionary) => {
                dispatch(getLookupEntitiesSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getLookupEntitiesFailure(error));
            });
    };
};