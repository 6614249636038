export const SHOW_JOBS_FROM_FIELD: string = "ShowJobsFrom";
export const CUSTOM_RANGE_FIELD: string = "CustomRange";
export const YES_FIELD: string = "Yes";
export const NO_FIELD: string = "No";
export const FROM_FIELD: string = "From";
export const TO_FIELD: string = "To";
export const WITH_REFERENCE: string = "withReference";

// options
export const LAST_24_HOURS_FIELD: string = "LAST24HOURS";

//IP Maintenance Time Range Selector options
export const SHOW_AGREEMENTS_FROM_FIELD: string = "showAgreements";
export const SHOW_IP_REPRESENTATION_FROM_FIELD: string = "showIPRepresentations";

//options
export const NOW_DEFUALT_FIELD: string = "NOW";
