import { connect } from "react-redux";
import { Dispatch } from "redux";
import { requestViewAsAccess, resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/MembersPortalReducer";
import { showModal } from "../../redux/reducers/ModalReducer";
import { getMembersPortalComponentDataThunk, requestViewAsAccessThunk, searchContributorsThunk, searchRequestAccessIPThunk } from "../../redux/thunks/MembersPortalThunks";
import IAppState from "../../redux/types/IAppState";
import { LOADING_VIEW } from "../../repertoire/Consts";
import { IContributorItem } from "../../repertoire/types/IContributorItem";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import RequestAccessPage from "./RequestAccessPage";

export default connect(
    (state: IAppState) => ({
        requestAccessPageData: state.membersPortal.componentData.RequestAccessPage,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        contributorsSearchResults: state.membersPortal.requestAccessIPSearchResult,
        searchSuccessful: state.membersPortal.searchSuccessful,
        interestedPartyGeneralDataView: state.repertoire.componentData.IPMaintenanceGeneralDataView,
        indexOfFirstResult: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].indexOfFirstResult ? state.membersPortal.tabs[state.membersPortal.activeTab].indexOfFirstResult : undefined,
        indexOfLastResult: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].indexOfLastResult ? state.membersPortal.tabs[state.membersPortal.activeTab].indexOfLastResult : undefined,
        resultsPerPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].resultsPerPage ? state.membersPortal.tabs[state.membersPortal.activeTab].resultsPerPage : undefined,
        allResultsPerPage: state.repertoire.resultsPerPage,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        lookupValues: state.lookupEntities,
        currentUser: state.account.currentUser,
        requestViewAsAccessSuccess: state.membersPortal.requestViewAsAccessSuccess,
        requestingViewAsAccess: state.membersPortal.requestingViewAsAccess,
        displayModal: state.membersPortal.isSearching ? true : state.modal.displayModal,
        modalProps: state.modal.modalProps,
        modalComponentInstance: state.modal.modalComponentInstance,
        modalTitle: state.modal.title,
        displayModalCloseButton: state.modal.displayModalCloseButton,
        isReadOnlyModal: state.modal.isReadOnlyModal,
        modalContent: state.membersPortal.isSearching && !state.membersPortal.isModalSearch ? LOADING_VIEW : state.modal.modalContent,
        loadingViewData: state.membersPortal.componentData.LoadingView,
        yesNoPromptViewData: state.membersPortal.componentData.YesNoPromptView,
        dataGridTableData: state.membersPortal.componentData.DataGridTable,
        dataSource: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState ? state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListsMaintenanceState.dataSource : undefined,
        draftWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkSearchResult,
        allRegisteredWorkSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].allRegisteredWorkSearchResult,

    }),
    (dispatch: Dispatch) => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        searchContributors: (query: IContributorSearchQuery) =>
            dispatch<any>(searchRequestAccessIPThunk(query)),
        getMembersPortalFields: () => dispatch<any>(getMembersPortalComponentDataThunk()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        requestViewAsAccessRequest: (requestAccess: IContributorItem, currentUser: string) => dispatch<any>(requestViewAsAccessThunk(requestAccess, currentUser))

    })
)(RequestAccessPage);