import * as React from "react";
import { IComment } from "../../../types/IComment";
import TextDataInput from "../../textDataInput/TextDataInput";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { If } from "../../../../core/components/If";
import { SizedDropdownDataInput } from "../../dropdownDataInput/DropdownDataInput";
import { COMMENT_CATEGORY_STATE_KEY } from "../../../Consts";
import { COMMENT_CATEGORY_LOOKUP } from "../../../../lookup/Consts";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import DateDisplay from "../../dateDisplay/DateDisplay";

export interface ICommentsViewProps {
    commentItem: IComment;
    updateComment: (commentId: number, value: any, fieldName: string) => void;
    lookupValues?: ILookupDictionary;
    commentCategoryFlag?: boolean;
}


const CommentsView: React.SFC<ICommentsViewProps> = ({ commentItem, updateComment, lookupValues, commentCategoryFlag }) => {
    const { notesID, user, subject, date, comment, commentCategory } = commentItem;

  return (
      <div className="form-group col-md-12">
          <div className="row">
              <TextDataInput
                  label={"User"}
                  value={user.value}
                  fieldName={""}
                  changeData={null}
                  readOnly
                  isHidden={false}
              />
              <div>
            <label>{"Date"}:</label>
            <DateDisplay value={date.value} showTime={true} />
            </div>
          </div>

          <div className="row">
              <TextDataInput
                  label={"Subject"}
                  value={subject.value}
                  fieldName={"subject"}
                  changeData={(x) => updateComment(notesID, x, "subject")}
                  isHidden={false}
              />
          </div>

          {commentCategoryFlag ? < div className="row">
              <SizedDropdownDataInput
                  label={"Category"}
                  fieldName={COMMENT_CATEGORY_STATE_KEY}
                  value={commentCategory.value}
                  changeData={(x) => updateComment(notesID, x, "commentCategory")}
                  options={getLookupValues(COMMENT_CATEGORY_LOOKUP, lookupValues)}
                  allowNull={true}
                  readOnly={false}
                  isHidden={false} />
          </div> : <div/>}


          <div className="row">
              <TextDataInput
                  label={"Comment"}
                  value={comment.value}
                  fieldName={"comment"}
                  changeData={(x) => updateComment(notesID, x, "comment")}
                  textArea
                  isHidden={false}

              />
          </div>
    </div>
  );
};

export default CommentsView;