export class RepertoireHelper {
    public static zeroPaddedIPINameNumber(ipiNameNumber?: string | number): string {
        if (ipiNameNumber == null) {
            return '';
        }
        let value: string = typeof (ipiNameNumber) === 'string' ? ipiNameNumber : ipiNameNumber.toString();
        return ('00000000000' + value).slice(-11);
    }

    public static trailingZeroesForShares(share: string, decimalsToDisplay: number): string {
        share = Number(parseFloat(share ? share : '0')).toFixed(decimalsToDisplay);
        return share;
    }
}