import ActionButton from "../../../repertoire/components/actionButton/ActionButton";
import { SizedCheckboxDataInput } from "../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import SizedDatePickerDataInput from "../../../repertoire/components/dateDataInput/DatePickerDataInput";
import { SizedDropdownDataInput } from "../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import SearchResultsTable from "../../../repertoire/components/searchResultsTable/SearchResultsTable";
import SearchView, { ISearchViewProps } from "../../../repertoire/components/searchView/SearchView";
import { SizedTextDataInput } from "../../../repertoire/components/textDataInput/TextDataInput";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import { SEARCH_VIEW_LIVE_PERFORMANCE_SUBMISSION } from "../../Consts";
import { ILivePerformanceSearchQuery } from "../../types/ILivePerformanceSearchQuery";
import { IMembersPortalField } from "../../types/IMembersPortalField";

export default class LivePerformanceSubmissionSearchView extends SearchView {
    constructor(props: ISearchViewProps) {
        super(props);

        super.state = {
            tableContents: [],
            livePerformanceSubmissionFromDate: null,
            livePerformanceSubmissionToDate: null
        }
    }

    renderLivePerformanceSubmissionSearchFields = () => {
        const { searchViewData, componentInstance } = this.props;

        if (searchViewData && searchViewData.fields) {

            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === componentInstance
            );
            let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
                let reactElement: React.ReactElement = <></>;

                if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal'))
                    reactElement =
                        <div key={index}>
                            <SizedTextDataInput
                                fieldType={searchField.fieldType}
                                useEnterSubmit={true}
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={this.disabledSearchFields(searchField.name) ? this.changeDataDisabled : (value, fieldName) => this.changeTextData(value, fieldName)}
                                handleKeyDown={this.keyDownSubmit}
                                value={this.state[searchField.name]}
                                readOnly={this.disabledSearchFields(searchField.name)}
                                isHidden={searchField.hidden}
                            />
                        </div>;
                else if (searchField.fieldType === 'date')
                    reactElement =
                        <div key={index}>
                            <SizedDatePickerDataInput
                                value={this.state[searchField.name]}
                                fieldName={searchField.name}
                                changeData={this.changeData}
                                label={searchField.data}
                                isHidden={searchField.hidden}
                            />
                        </div>
                else if (searchField.fieldType === 'checkbox')
                    reactElement =
                        <div key={index}>
                            <SizedCheckboxDataInput
                                label={searchField.data}
                                fieldName={searchField.name}
                                value={this.state[searchField.name]}
                                changeData={this.changeData}
                                readonly={false}
                            />
                        </div>
                else {
                    let options: { code: string, description: string }[] = [];
                    let disabledDropDown = false;
                    options.push({ code: "DRAFT", description: "Draft" }, { code: "DRAFTSUB", description: "Draft Submitted" })

                    reactElement =
                        <div key={index}>
                            <SizedDropdownDataInput
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={this.changeData}
                                value={this.state[searchField.name]}
                                options={options}
                                allowNull={true}
                                isHidden={searchField.hidden}
                                componentInstance={componentInstance}
                                readOnly={disabledDropDown}
                            />
                        </div>
                }

                return reactElement;

            };

            return filteredSearchFields.map(filteredSearchFieldsMapper);
        }
    };

    onClickSearch = () => {
        const {
            setPageOne,
            livePerformanceSubmissionFromDate,
            livePerformanceSubmissionToDate
        } = this.state;
        const {
            resetPagination,
            searchLivePerformances,
            currentUserNameNumber
        } = this.props;
        resetPagination(this.props.componentInstance);
        this.setState({ setPageOne: setPageOne + 1 })

        let searchQuery: ILivePerformanceSearchQuery = {
            dateFrom: livePerformanceSubmissionFromDate && new Date(livePerformanceSubmissionFromDate),
            dateTo: livePerformanceSubmissionToDate && new Date(livePerformanceSubmissionToDate),
            currentUserNameNumber
        }
        searchLivePerformances(searchQuery)
    }

    renderSearchResults() {
        const {
            searchResultsTableData,
            componentInstance,
            searchSuccessful,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            updatePagination,
            lookupValues,
            sortSearchResults,
            livePerformanceSearchResults,
            getLivePerformanceDetails 
        } = this.props;

        const { setPageOne } = this.state;

        if (searchSuccessful &&
            (!!livePerformanceSearchResults && livePerformanceSearchResults.length <= 0)) {
            return (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                        No results found.
                    </div>
                </div>
            )
        } else if (livePerformanceSearchResults && livePerformanceSearchResults.length >= 1) {
            return (<div className="row">
                <div className="col-md-12 searchResultsDiv">
                    <SearchResultsTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={livePerformanceSearchResults}
                        componentInstance={componentInstance}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_LIVE_PERFORMANCE_SUBMISSION}
                        sortSearchResults={sortSearchResults}
                        lookupValues={lookupValues}
                        getLivePerformanceDetails={getLivePerformanceDetails}
                    />
                </div>
            </div>)
        } else if (searchSuccessful === false) {
            return (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                        Error while searching, please contact customer support if issue persists.
                    </div>
                </div>
            )
        }
    }

    render() {
        const { searchDisabled } = this.props

        return (
            <div className="searchView">
                <div key='searchFields' className="row">
                    {this.renderLivePerformanceSubmissionSearchFields()}
                </div>
                <div key='action' className="row">
                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                        <ActionButton buttonText="Search" buttonAction={this.onClickSearch} disabled={searchDisabled} />
                    </div>
                </div>
                <div key='results'>
                    {this.renderSearchResults()}
                </div>
            </div>
        )
    }
}