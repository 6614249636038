import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IRepresentationState } from "../../redux/types/IRepresentationState";
import { EMPTY_STRING_VALUE } from "../Consts";
import { IRepresentation } from "../types/usageTypes/IRepresentation";

export class RepresentationDetailsMapper {

    public static createNewRepresentation(ipBaseNumber: string, lookups: ILookupDictionary): IRepresentation {
        const representation: IRepresentation = {
            ipBaseNumber: ipBaseNumber,
            description: EMPTY_STRING_VALUE,
            parent: EMPTY_STRING_VALUE,
            startDate: new Date,
            endDate: new Date,
            excludedBool: false,
            representationGroup: EMPTY_STRING_VALUE,
            representationGroupId: null,
            representationType: EMPTY_STRING_VALUE,
            representationTypeId: null,
            id: 0,
            parentId: 500,           
            use: EMPTY_STRING_VALUE,
            useId: null,
            workGroupDescription: EMPTY_STRING_VALUE,
            workGroupId: null
        };

        return representation;
    }

    public static createNewRepresentationState(ipBaseNumber: string, lookups: ILookupDictionary): IRepresentationState {
        return RepresentationDetailsMapper.mapNewRepresentationState(RepresentationDetailsMapper.createNewRepresentation(ipBaseNumber, lookups), lookups);
    }

    public static mapNewRepresentationState(representation: IRepresentation, lookupValues: ILookupDictionary): IRepresentationState {
        if (!representation || !lookupValues)
            return null;

        const { ipBaseNumber,
                description,
                parent,
                startDate,
                endDate,
                excludedBool,
                representationGroup,
                representationGroupId,
                representationType,
                parentId,
                representationTypeId,
                use,
                useId,
                workGroupDescription,
                workGroupId } = representation;

        const representationState: IRepresentationState = {
            ipBaseNumber,
            description,
            parent,
            startDate,
            endDate,
            excludedBool,
            representationGroup,
            representationGroupId,
            representationType,
            parentId,
            representationTypeId,
            use,
            useId,
            workGroupDescription,
            workGroupId        
        };

        return representationState;
    }
}