// Roles
export const ALL_ROLES = "All";
export const VIEW_MATCHING_SETTINGS_ROLE = "Settings.Matching.View";
export const UPDATE_MATCHING_SETTINGS_ROLE = "Settings.Matching.Update";
export const DELETE_MATCHING_SETTINGS_ROLE = "Settings.Matching.Delete";
export const VIEW_VALIDATION_SETTINGS_ROLE = "Settings.Validation.View";
export const UPDATE_VALIDATION_SETTINGS_ROLE = "Settings.Validation.Update";
export const DELETE_VALIDATION_SETTINGS_ROLE = "Settings.Validation.Delete";

// Usage Ingestion Configuration

export const WORK_BASED_FOLDERS_PATH = "usage/work based";
export const PRODUCT_BASED_FOLDERS_PATH = "usage/product based";

export const USAGE_TO_WORK_TYPE = "Usage to Work";
export const USAGE_TO_PRODUCT_TYPE = "Usage to Product";

export const USAGE_FILE_SYSTEM_TYPE = "usage";

export const USAGE_INGESTION_FOLDER_NAME = "UsageIngestionFolderName";
export const USAGE_INGESTION_DEFAULT_POOL = "UsageIngestionDefaultPool";
export const USAGE_INGESTION_DEFAULT_DISTRIBUTION = "UsageIngestionDefaultDistribution";
export const USAGE_INGESTION_DEFAULT_INPUT_FILE_FORMAT = "UsageIngestionDefaultInputFileFormat";
export const USAGE_INGESTION_DEFAULT_OUTPUT_FILE_FORMAT = "UsageIngestionDefaultOutputFileFormat";
export const USAGE_INGESTION_DEFAULT_DESTINATION = "UsageIngestionDefaultDestination";
export const USAGE_INGESTION_AUTO_PROCESS = "UsageIngestionAutoProcess";
export const CUSTOM_NOTEBOOK = "CustomNotebook";