import * as React from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import { BOOK_EXPORT_JOB_TYPE } from "../../Consts";
import { SEARCH_VIEW, SEARCH_VIEW_JOB_IP } from "../../../repertoire/Consts";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import ActionButton, { InlineActionButton, SizedActionButton } from "../../../repertoire/components/actionButton/ActionButton";
import { showModal } from "../../../redux/reducers/ModalReducer";
export interface IBookExportJobParametersProps {
    setManualJobParameters: (parameters: ITriggerManualJob) => void;
    lookupValues: ILookupDictionary;
    showModal: typeof showModal;
    globalManualJobParams: any;
}

interface IBookExportJobParametersState {
    publisher: string;
    isbnPrefix: string;
    formatExport: string;
    format: string;
}

export class BookExportJobParameters extends React.Component<
    IBookExportJobParametersProps,
    IBookExportJobParametersState
> {
    constructor(props: IBookExportJobParametersProps) {
        super(props);

        this.state = {
            publisher: "",
            isbnPrefix: "",
            formatExport: "",
            format: "",
        };
    }

    getGlobalParamValues = (): string => {
        const { globalManualJobParams } = this.props;

        let publisher = '';
        if (globalManualJobParams !== '') {
            const jobParams = JSON.parse(globalManualJobParams.jobParameters);
            publisher = jobParams.ipNameNumber;
        }
        return publisher
    }

    renderOptions = (values: IDropdownOption[]) => {
        const options = [];
        options.push(<option value="empty" title="emtpy" key=""></option>);

        values.map((c) =>
            options.push(
                <option value={c.data} title={c.text} key={c.key}>
                    {c.text}
                </option>
            )
        );

        return options;
    };

    setParams = () => {
        const { setManualJobParameters } = this.props;
        const { isbnPrefix, formatExport, format } = this.state;
        const publisher = this.getGlobalParamValues();

        const manualJobParameters: ITriggerManualJob = {
            jobType: BOOK_EXPORT_JOB_TYPE,
            jobParameters: JSON.stringify({
                publisher,
                isbnPrefix,
                formatExport,
                format,
            }),
        };
        setManualJobParameters(manualJobParameters);
    };

    showSearchModal = () => {
        const { showModal } = this.props;
        const { isbnPrefix, formatExport, format } = this.state;
        const publisher = this.getGlobalParamValues();

        showModal(SEARCH_VIEW, SEARCH_VIEW_JOB_IP, {
            publisher: publisher,
            isbnPrefix: isbnPrefix,
            formatExport: formatExport,
            format: format,
        }, true, "Search");
    };
    render() {
        const { isbnPrefix, formatExport, format } = this.state;
        const { lookupValues } = this.props;
        const publisher = this.getGlobalParamValues();

        var exportFormat: IDropdownOption[] = [
            { key: 1, text: "ONIX - Only applies to books", data: "ONIX" },
            { key: 2, text: "CSV for Books", data: "Books" },
            { key: 3, text: "CSV for Magazines", data: "Magazines" },
            { key: 4, text: "CSV for Sheet Music", data: "SheetMusic" },
        ];

        var formats = [];
        var workSupportingMediaCategory = lookupValues[
            "WorkSupportingMediaCategory"
        ].lookups.filter((x) => x.code !== "NULL" && x.code !== null);
        formats.push(<option value="empty" title="emtpy" key=""></option>);
        workSupportingMediaCategory.map((c) =>
            formats.push(
                <option value={c.code} title={c.description} key={c.typeID}>
                    {" "}
                    {c.description}
                </option>
            )
        );
        return (
            <>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>Publisher</label>
                    <div className="wrapperLookup">

                        <input
                            readOnly
                            type={"text"}
                            value={publisher}
                            maxLength={150}
                        />

                        <InlineActionButton buttonText="Lookup" className="buttonLookup"
                            buttonAction={() => this.showSearchModal()}
                        />
                    </div>
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>ISBN Prefix</label>
                    <input
                        type={"number"}
                        maxLength={10}
                        value={isbnPrefix}
                        onChange={(e) => {
                            this.setState({ isbnPrefix: e.target.value }, () => {
                                this.setParams();
                            });
                        }}
                    />
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>Format</label>
                    <select
                        onChange={(e) => {
                            this.setState({ format: e.target.value }, () => {
                                this.setParams();
                            });
                        }}
                    >
                        {formats}
                    </select>
                </div>

                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>Export Format</label>
                    <select
                        onChange={(e) => {
                            this.setState({ formatExport: e.target.value }, () => {
                                this.setParams();
                            });
                        }}
                    >
                        {this.renderOptions(exportFormat)}
                    </select>
                </div>
            </>
        );
    }
}
