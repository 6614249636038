import IAppState from "../../redux/types/IAppState";
import { connect } from "react-redux";
import IPsPage from "./IPsPage";
import { Dispatch } from "redux";
import { addTab, createNewAccount, resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { showModal } from "../../redux/reducers/ModalReducer";
import { IIPsSearchQuery } from "../types/IIPsSearchQuery";
import { searchIPsThunk, getIPDetailsThunk, updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { ITabProps } from "../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        ipsPageData: state.repertoire.componentData.IPsPage,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        ipsSearchResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].ipsSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        interestedPartyGeneralDataView: state.repertoire.componentData.IPMaintenanceGeneralDataView,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'ips')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'ips')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'ips')[0].resultsPerSection,
        currentPage: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage : undefined,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        roles: state.account.roles,
        continuationToken: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].continuationToken ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].continuationToken : undefined,
        useNewUIgridIPSearchResults: state.repertoire.useNewUIgridIPSearchResults,
        skip: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'ips')[0].skip,
    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        searchIPs: (query: IIPsSearchQuery, isSameSearch?: boolean) =>
            dispatch<any>(searchIPsThunk(query, false, isSameSearch)),
        getIPDetails: (ipBaseNumber: string, accountNumber: string, ipMaintenanceGeneralViewData?: IRepertoireComponentDataItem, lookups?: ILookupDictionary) =>
            dispatch<any>(getIPDetailsThunk(ipBaseNumber, accountNumber, ipMaintenanceGeneralViewData, lookups)),
        addNewAccount: (lookupValues: ILookupDictionary, isNew?: boolean, ipMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => dispatch(createNewAccount(lookupValues, isNew, ipMaintenanceGeneralViewData)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
    })
) (IPsPage);

