import React, { PureComponent } from "react";
import { If } from '../../../core/components/If';

interface IBooleanReadonlyDisplayProps {
    value: boolean
}


export default class BooleanReadonlyDisplay extends PureComponent<
    IBooleanReadonlyDisplayProps
    > {
    constructor(props) {
        super(props);
    }

    render() {
        const { value } = this.props;
        return (
            <div>
                <If condition={value}>
                    <span className="icon node-icon ms-Icon ms-Icon--CheckMark"></span>
                </If>
                {value.valueOf().toString().replace(/true/gi, "Yes").replace(/false/gi, "No")}
            </div>
        );
    }
}
