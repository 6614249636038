import { exit } from "process";
import * as React from "react";
import { RESET_DISTRIBUTION_CACHE_JOB_TYPE } from "../../../../home/Consts";
import { IJobSearchState } from "../../../../home/types/IJobSearchState";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showScheduledJobModalView, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, copyExistingDistribution } from "../../../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IDistributionState } from "../../../../redux/types/IDistributionState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IScheduledJobState } from "../../../../redux/types/IScheduledJobState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ITriggerManualJob } from "../../../../redux/types/ITriggerManualJob";
import { IScheduledJobModalViewProps } from "../../../components/modalViews/scheduledJobModalView/ScheduledJobModalView";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IPopUpInfoProps } from "../../../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { ADD_METHOD_TOOLBAR, ADD_NEW_DISTRIBUTION_STATE_KEY, DELETE_DISTRIBUTION_STATE_KEY, DELETE_METHOD_TOOLBAR, UNSAVED_ALLOCATE_STATE_KEY, DISTRIBUTION_MAINTENANCE_TOOLBAR, SAVE_CHANGES_STATE_KEY, SAVE_METHOD_TOOLBAR, UNDO_CHANGES_DISTRIBUTION_STATE_KEY, UNDO_METHOD_TOOLBAR, ALLOCATE_POOLS_DISTRIBUTION_STATE_KEY, ALLOCATE_METHOD_TOOLBAR, UNDO_ALLOCATION_POOLS_DISTRIBUTION_STATE_KEY, UNDO_ALLOCATION_METHOD_TOOLBAR, EXPORT_METHOD_TOOLBAR, DISTRIBUTION_EXPORT_STATE_KEY, VERSION_HISTORY_STATE_KEY, HISTORY_METHOD_TOOLBAR, SELECTION_CRITERIA_VIEW, IMPORT_DATA_VIEW, COPY_POOL_STATE_KEY, COPY_DISTRIBUTION_STATE_KEY, COPY_METHOD_TOOLBAR, ALLOCATE_POOLS_CONFIRMATION_STATE_KEY, UNDO_ALLOCATE_POOLS_CONFIRMATION_STATE_KEY, DELETE_DISTRIBUTIONS_ROLE, ALL_ROLES, HAMBURGER_MENU_TOOLBAR, IMPORT_METHOD_TOOLBAR, DISTRIBUTION_IMPORT_STATE_KEY, DISTRIBUTION_RESET_CACHE_STATE_KEY, RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR, UPDATE_DISTRIBUTIONS_ROLE,UNDO_QA_POOLS_DISTRIBUTION_STATE_KEY, RUN_QA_POOLS_DISTRIBUTION_STATE_KEY, RUN_QA_METHOD_TOOLBAR, UNDO_RUN_QA_METHOD_TOOLBAR, QAScheduledJob, CDAllocationScheduledJob, SET_CD_ALLOCATION_SCHEDULE_STATE_KEY, SET_CD_ALLOCATION_METHOD_TOOLBAR, IMPORT_POOL_DATA_VIEW_DISTRIBUTIONS } from "../../../Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IRepertoireField } from "../../../types/IRepertoireField";

export interface IDistributionMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    showScheduledJobModalView: typeof showScheduledJobModalView;
    scheduledJobModalViewData: IRepertoireComponentDataItem;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData?: IRepertoireComponentDataItem;
    distribution: IDistributionState;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    deleteDistribution: () => void;
    triggerManualJob: (manualJob: ITriggerManualJob) => void;
    undoDistribution: (distributionID: number, lookupValues: ILookupDictionary) => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    isReadonly: boolean;
    undoDistributionChanges: () => void;
    addNewDistribution: () => void;
    isNew?: boolean;
    allocateDistributionPools: () => void;
    undoAllocationDistributionPools: () => void;
    searchVersionHistory: (distributionId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) => void;
    lookupValues: ILookupDictionary;
    exportDistributionPools: () => void;
    showModal: typeof showModal;
    copyDistribution: typeof copyExistingDistribution
    allocationJobInProgress?: boolean;
    roles: string[];
    jobStatusQA?: string;
    runQAOnDistributionPools: () => void;
    revertQAOnDistributionPools: () => void;
    setCDAllocationSchedule: () => void;
    upsertScheduledJob: (schedule: IScheduledJobState,componentName?:string) => void;
}

interface IDistributionMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
    showSecondaryActions: Boolean;
    IsResetCacheEnabled: Boolean;
}

export class DistributionMaintenanceToolbar extends React.PureComponent<
    IDistributionMaintenanceToolbarProps,
    IDistributionMaintenanceToolbarState
> {

    constructor(props: IDistributionMaintenanceToolbarProps) {
        super(props);
        this.state = {
            dataAction: this.getDataAction(),
            showSecondaryActions: false,
            IsResetCacheEnabled: this.getResetCacheStatus()
        };
    }

    saveChangesAction = () => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    showImportModal = () => {
        const { showModal, distribution } = this.props;
        this.setState({showSecondaryActions: this.state.showSecondaryActions ? false : true});
        showModal(IMPORT_POOL_DATA_VIEW_DISTRIBUTIONS, null, distribution, true, "Import pools");
    }

    searchVersionHistory = () => {
        const { searchVersionHistory, clearModalSearchResults, distribution, lookupValues, tabs, activeTab } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        clearModalSearchResults();
        searchVersionHistory(distribution.distributionID, lookupValues, actionList, tabs[activeTab].formatFields);
    };

    copyDistribution = () => {
        const { tabs, activeTab, copyDistribution } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        this.setState({showSecondaryActions: this.state.showSecondaryActions ? false : true});
        copyDistribution(actionList);
    }
    resetCacheDistribution = () => {
        const { triggerManualJob, distribution } = this.props;
        if(distribution.distributionStatus === "Open"){
            var manualJob = {jobType: RESET_DISTRIBUTION_CACHE_JOB_TYPE, jobParameters: JSON.stringify({DistributionID : distribution.distributionID.toString()})}
            triggerManualJob(manualJob);
        }
        this.setState({showSecondaryActions: this.state.showSecondaryActions ? false : true});
    }
    deleteDistributionAction = () => {
        const { deleteDistribution, toolbarData, showYesNoPrompt, hideModal } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === DELETE_DISTRIBUTION_STATE_KEY).data;
        const propsModal: IYesNoPromptViewModalProps = {
            yesCallback: () => { deleteDistribution(); hideModal(); },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, propsModal);
    }
    allocateDistributionPoolsAction = () => {
        const { allocateDistributionPools, showModal, toolbarData, showYesNoPrompt, hideModal, undoDistribution, distribution, lookupValues, changesMade } = this.props;
        const title: string = (changesMade) ? toolbarData.fields.find(f => f.name === UNSAVED_ALLOCATE_STATE_KEY).data : toolbarData.fields.find(f => f.name === ALLOCATE_POOLS_CONFIRMATION_STATE_KEY).data
        if(changesMade){
            showModal(UNSAVED_ALLOCATE_STATE_KEY, null, null, true, "You have made unsaved changes to this Distribution. Please save before continuing.");
        }
        else{
            const propsModal: IYesNoPromptViewModalProps = {
                yesCallback: () => { allocateDistributionPools(); undoDistribution(distribution.distributionID, lookupValues); hideModal();},
                noCallback: () => { hideModal(); }
            }
            showYesNoPrompt(title, propsModal);
        }

        this.setState({IsResetCacheEnabled: distribution.isResetCacheEnabled});
        distribution.distributionPools?.forEach(element => {
            if(element.status.value == "Allocated") {
                this.setState({IsResetCacheEnabled: false});
                return;
            }
        });
    }
    undoAllocationDistributionPoolsAction = () => {
        const { undoAllocationDistributionPools, toolbarData, showYesNoPrompt, hideModal, undoDistribution, distribution, lookupValues } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === UNDO_ALLOCATE_POOLS_CONFIRMATION_STATE_KEY).data;
        const propsModal: IYesNoPromptViewModalProps = {
            yesCallback: () => { undoAllocationDistributionPools(); undoDistribution(distribution.distributionID, lookupValues); hideModal(); },
            noCallback: () => { hideModal(); }
        }
        this.setState({IsResetCacheEnabled: distribution.isResetCacheEnabled});
        distribution.distributionPools?.forEach(element => {
            if(element.status.value == "Allocated") {
                this.setState({IsResetCacheEnabled: false});
                return;
            }
        });
        showYesNoPrompt(title, propsModal);
    }

    runQADistributionPoolsAction = () => {
        const { upsertScheduledJob, runQAOnDistributionPools, toolbarData, showScheduledJobModalView, hideModal, undoDistribution, distribution, lookupValues, scheduledJobModalViewData } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === RUN_QA_POOLS_DISTRIBUTION_STATE_KEY).data;
        const viewProps: IScheduledJobModalViewProps = {
            fields: scheduledJobModalViewData.fields,
            yesCallback: () => { runQAOnDistributionPools(); undoDistribution(distribution.distributionID, lookupValues); hideModal(); },
            noCallback: () => { hideModal(); },
            componentName: QAScheduledJob,
            upsertScheduledJob: (schedule: IScheduledJobState,componentName?:string) => upsertScheduledJob(schedule,componentName),
            tab: undefined
        }
        showScheduledJobModalView(title, viewProps, QAScheduledJob);
    }

    setCDAllocationScheduleAction = () => {
        const { upsertScheduledJob,toolbarData,hideModal, showScheduledJobModalView,scheduledJobModalViewData } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === SET_CD_ALLOCATION_SCHEDULE_STATE_KEY).data;
        const viewProps: IScheduledJobModalViewProps = {
            fields: scheduledJobModalViewData.fields,
            noCallback: () => { hideModal(); },
            componentName: CDAllocationScheduledJob,
            upsertScheduledJob: (schedule: IScheduledJobState,componentName?:string) => upsertScheduledJob(schedule,componentName),
            tab: undefined
        }
        showScheduledJobModalView(title, viewProps, CDAllocationScheduledJob);
    }

    undoQADistributionPoolsAction = () => {
        const { revertQAOnDistributionPools, toolbarData, showYesNoPrompt, hideModal, undoDistribution, distribution, lookupValues } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === UNDO_QA_POOLS_DISTRIBUTION_STATE_KEY).data;
        const propsModal: IYesNoPromptViewModalProps = {
            yesCallback: () => { revertQAOnDistributionPools(); undoDistribution(distribution.distributionID, lookupValues); hideModal(); },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, propsModal);
    }

    getDeleteRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(DELETE_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    renderDistributionActions = () => {
        this.setState({showSecondaryActions: this.state.showSecondaryActions ? false : true});
    }

    getResetCacheStatus = () => {
        const { distribution } = this.props;
        let IsResetCacheEnabled = distribution.isResetCacheEnabled;
        distribution.distributionPools?.forEach(element => {
            if(element.status.value == "Allocated") {
                IsResetCacheEnabled = false;
                return IsResetCacheEnabled;
            }
        });
        return IsResetCacheEnabled;
    } 

    getDataAction() {
        const {
            deleteDistribution,
            undoDistributionChanges,
            addNewDistribution,
            exportDistributionPools,
            toolbarData: { fields },
            jobStatusQA
        } = this.props;
        const actionList: IDataActionToolbar[] = [];

        const add: IDataActionToolbar = {
            action: addNewDistribution,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === ADD_NEW_DISTRIBUTION_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(add);

        const deleteUsagePoolAction: IDataActionToolbar = {
            action: this.deleteDistributionAction,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: DELETE_METHOD_TOOLBAR,
            name: fields.find(d => d.name === DELETE_DISTRIBUTION_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Delete',
            isReadOnly: this.getDeleteRoleDisabled() || this.getUpdateRoleDisabled()
        };
        actionList.push(deleteUsagePoolAction);


        const save: IDataActionToolbar = {
            action: this.saveChangesAction,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        const undo: IDataActionToolbar = {
            action: undoDistributionChanges,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: UNDO_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === UNDO_CHANGES_DISTRIBUTION_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Undo',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(undo);

        const allocate: IDataActionToolbar = {
            action: this.allocateDistributionPoolsAction,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: ALLOCATE_METHOD_TOOLBAR,
            name: fields.find(allocate => allocate.name === ALLOCATE_POOLS_DISTRIBUTION_STATE_KEY).data,
            isReadOnly: true,
            icon: 'icon ms-Icon ms-Icon--DependencyAdd'
        };
        actionList.push(allocate);
        
        const setCDAllocationSchedule: IDataActionToolbar = {
            action: this.setCDAllocationScheduleAction,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: SET_CD_ALLOCATION_METHOD_TOOLBAR,
            name: fields.find(setCDAllocationSchedule => setCDAllocationSchedule.name === SET_CD_ALLOCATION_SCHEDULE_STATE_KEY).data,
            isReadOnly: true,
            icon: 'icon ms-Icon ms-Icon--DependencyAdd'
        };
        actionList.push(setCDAllocationSchedule);
        
        const undoAllocation: IDataActionToolbar = {
            action: this.undoAllocationDistributionPoolsAction,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: UNDO_ALLOCATION_METHOD_TOOLBAR,
            name: fields.find(undoAllocation => undoAllocation.name === UNDO_ALLOCATION_POOLS_DISTRIBUTION_STATE_KEY).data,
            isReadOnly: true,
            icon: 'icon ms-Icon ms-Icon--DependencyRemove'
        };
        actionList.push(undoAllocation);

        if (jobStatusQA) {
            const runQA: IDataActionToolbar = {
                action: this.runQADistributionPoolsAction,
                component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
                method: RUN_QA_METHOD_TOOLBAR,
                name: fields.find(runQA => runQA.name === RUN_QA_POOLS_DISTRIBUTION_STATE_KEY).data,
                isReadOnly: true,
                icon: 'icon ms-Icon ms-Icon--DependencyAdd'
            };
            actionList.push(runQA);

            const undoQA: IDataActionToolbar = {
                action: this.undoQADistributionPoolsAction,
                component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
                method: UNDO_RUN_QA_METHOD_TOOLBAR,
                name: fields.find(undoQA => undoQA.name === UNDO_QA_POOLS_DISTRIBUTION_STATE_KEY).data,
                isReadOnly: true,
                icon: 'icon ms-Icon ms-Icon--DependencyRemove'
            };
            actionList.push(undoQA);
        }


        const searchVersionHistory: IDataActionToolbar = {
            action: this.searchVersionHistory,
            name: fields.find(save => save.name === VERSION_HISTORY_STATE_KEY).data,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: HISTORY_METHOD_TOOLBAR,
            icon: 'icon ms-Icon ms-Icon--History'
        };
        actionList.push(searchVersionHistory);

        const exportPools: IDataActionToolbar = {
            action: exportDistributionPools,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: EXPORT_METHOD_TOOLBAR,
            name: fields.find(exportDistributionPools => exportDistributionPools.name === DISTRIBUTION_EXPORT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Export'
        }
        actionList.push(exportPools);

        const importPools: IDataActionToolbar = {
            action: this.showImportModal,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: IMPORT_METHOD_TOOLBAR,
            name: fields.find(d => d.name === DISTRIBUTION_IMPORT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Import',
            isReadOnly: this.getUpdateRoleDisabled()
        }
        actionList.push(importPools);

        const copyDistributionAction: IDataActionToolbar = {
            action: this.copyDistribution,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: COPY_METHOD_TOOLBAR,
            name: fields.find(d => d.name === COPY_DISTRIBUTION_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Copy',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(copyDistributionAction);

        const resetCacheDistribution: IDataActionToolbar = {
            action: this.resetCacheDistribution,
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR,
            name: fields.find(d => d.name === DISTRIBUTION_RESET_CACHE_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(resetCacheDistribution);

        const hamburgerIcon: IDataActionToolbar = {
            action: this.renderDistributionActions,
            name: "",
            component: DISTRIBUTION_MAINTENANCE_TOOLBAR,
            method: HAMBURGER_MENU_TOOLBAR,
            icon: 'icon ms-Icon ms-Icon--MoreVertical'
        };
        actionList.push(hamburgerIcon);
        return actionList;
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            distribution,
            activeTab,
            toolbarData,
            tabs,
            isReadonly,
            isNew,
            allocationJobInProgress,
            jobStatusQA
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade
        const actionFilter = ['Import','Export','Copy','ResetCache']

        return (
            <div className={isReadonly && !changesMade ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction.filter(
                        action =>
                        !actionFilter.includes(action.method)
                    )}
                    toolbarData={toolbarData}
                    component={DISTRIBUTION_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].distributionMaintenanceState.isNew}
                    isReadonly={isReadonly}
                    distribution={distribution}
                    allocationJobInProgress={allocationJobInProgress}
                    secondaryActions={this.state.dataAction.filter(
                        action =>
                        actionFilter.includes(action.method)
                    )}
                    showSecondaryActions={this.state.showSecondaryActions}
                    IsResetCacheEnabled={this.state.IsResetCacheEnabled}
                />
            </div>
        );

    }
}