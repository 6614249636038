import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { showYesNoPrompt, hideModal, showMessage } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IInterestedParty } from "../../../types/IInterestedParty";
import { SAVE_CHANGES_STATE_KEY, ADD_NEW_ACCOUNT_STATE_KEY, ADD_NEW_REPRESENTATION_STATE_KEY, DELETE_ACCOUNT_STATE_KEY, UNDO_CHANGES_ACCOUNT_KEY, IP_MAINTENANCE_TOOLBAR, UNDO_METHOD_TOOLBAR, SAVE_METHOD_TOOLBAR, ADD_METHOD_TOOLBAR, ADD_REPRESENTATION_TOOLBAR, DELETE_METHOD_TOOLBAR, HISTORY_METHOD_TOOLBAR, VERSION_HISTORY_STATE_KEY, IP_TRANSFER, IP_TRANSFER_STATE_KEY, DELETE_IPS_ROLE, ALL_ROLES, UPDATE_IPS_ROLE, CRM_EXTENDED_DETAILS_STATE_KEY, CRM_EXTENDED_DETAILS_TOOLBAR, MERGE_IP_PROMPT_STATE_KEY, MERGE_METHOD_TOOLBAR, MERGE_IP_ACCOUNT_KEY, SEARCH_VIEW, MERGE_DENIED_VIEW } from "../../../Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { clearModalSearchResults, getRepresentationGroups, ipTransferRequest } from "../../../../redux/reducers/RepertoireReducer";
import { SaveOption } from "../../../types/SaveOption";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IPopUpInfoProps } from "../../../components/modalViews/popUpInfo/PopUpInfo";
import { showModal } from "../../../../redux/reducers/MembersPortalReducer";

export interface IInterestedPartyMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData?: IRepertoireComponentDataItem;
    interestedParty: IInterestedParty;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    deleteAccount: () => void;
    addNewAccount: () => void;
    undoIPChanges: () => void;
    searchVersionHistory: (socIPId: number, activeTabItem: ITabReduxItem, formats: FormatFields[],lookups: ILookupDictionary) => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    isReadonly: boolean;
    workflowFieldsData: IRepertoireComponentDataItem;
    addRepresentationTab: () => void;
    createNewRepresentation: (ipBaseNumber: string, lookups: ILookupDictionary, isNew?: boolean, representationMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    lookupValues: ILookupDictionary;
    getRepresentationGroups: typeof getRepresentationGroups;
    ipTransfer: typeof ipTransferRequest;
    roles: string[];
    openIpExtendedDetailsCRMInNewTab?: () => void;
    mergeIP?: (ips: IInterestedParty[]) => void;   
    showMessage: typeof showMessage;
    showModal: typeof showModal;
}

export type IInterestedPartyMaintenanceToolbarKeys = keyof IInterestedPartyMaintenanceToolbarProps;

export interface IInterestedPartyMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

class IPMaintenanceToolbar extends React.Component<
    IInterestedPartyMaintenanceToolbarProps,
    IInterestedPartyMaintenanceToolbarState
> {

    constructor(props) {
        super(props)
        this.state = {
            dataAction: this.getDataAction(),
        };
    }

    searchVersionHistory = () => {
        const { searchVersionHistory, clearModalSearchResults, interestedParty, tabs, activeTab, lookupValues } = this.props;
        clearModalSearchResults();
        if (interestedParty.socIP)
            searchVersionHistory(interestedParty.socIP.socIPID, tabs[activeTab], tabs[activeTab].formatFields, lookupValues );
    }

    createNewRepresentation = () => {
        const { createNewRepresentation, interestedParty, lookupValues, getRepresentationGroups } = this.props;

        getRepresentationGroups(interestedParty.ipBaseNumber);

        createNewRepresentation(interestedParty.ipBaseNumber, lookupValues);
    }

    saveChangesAction = (saveOption: SaveOption) => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    ipTransfer = () => {
        const { ipTransfer } = this.props;
        ipTransfer();
    };

    getDeleteRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(DELETE_IPS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_IPS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }
    
    mergeIPAction = () => {
        const { mergeIP, toolbarData, showYesNoPrompt, hideModal, tabs, activeTab, showMessage ,showModal,interestedParty} = this.props;
        const title: string = toolbarData.fields.find(f => f.name === MERGE_IP_PROMPT_STATE_KEY).data;

        if (tabs[activeTab].interestedPartyMaintenanceState.interestedParty.mergeThisIP != undefined && tabs[activeTab].interestedPartyMaintenanceState.interestedParty.mergeThisIP != false) {

            if (tabs.length > 1) {
                const ipsToMerge = [...tabs];
                ipsToMerge.splice(activeTab, 1);

                var filteredIpsToMerge = ipsToMerge.filter(x => x.interestedPartyMaintenanceState != undefined
                    && x.interestedPartyMaintenanceState.interestedParty.mergeThisIP != undefined
                    && x.interestedPartyMaintenanceState.interestedParty.mergeThisIP == true);
                if (filteredIpsToMerge.length == 1) {

                    var ipIds = filteredIpsToMerge.map(x => x.interestedPartyMaintenanceState.interestedParty);
                    if(interestedParty.ipBaseNumber===null && (interestedParty.socIP===null || ipIds[0].socIP===null )){
                        showModal(title, MERGE_DENIED_VIEW, title, true, "Cannot merge an account that has an associated IP Base Number","Cannot be merged");
                    }
                    else{
                        var result = filteredIpsToMerge[0].title;
                        const propsModal: IYesNoPromptViewModalProps = {
                            yesCallback: () => { mergeIP(ipIds); hideModal(); },
                            noCallback: () => { hideModal(); }
                        }
                        showYesNoPrompt(title, propsModal, result.toString());
                    }
                 }
                else {

                    const warning: string = "testing";
                    const propsModal: IPopUpInfoProps = {
                        textToScreen: warning
                    }

                    showMessage(propsModal);
                }
            }

        }
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }


    getDataAction() {
        const {
            deleteAccount,
            addNewAccount,
            undoIPChanges,
            toolbarData: { fields },
            openIpExtendedDetailsCRMInNewTab
        } = this.props;

        const actionList: IDataActionToolbar[] = [];

        const undo: IDataActionToolbar = {
            action: undoIPChanges,
            component: IP_MAINTENANCE_TOOLBAR,
            method: UNDO_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === UNDO_CHANGES_ACCOUNT_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Undo',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(undo);

        const add: IDataActionToolbar = {
            action: addNewAccount,
            component: IP_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === ADD_NEW_ACCOUNT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(add);

        const deleteAccountAction: IDataActionToolbar = {
            action: deleteAccount,
            component: IP_MAINTENANCE_TOOLBAR,
            method: DELETE_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === DELETE_ACCOUNT_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Delete',
            isReadOnly: this.getDeleteRoleDisabled() || this.getUpdateRoleDisabled()
        };
        actionList.push(deleteAccountAction);

        const addRepresentation: IDataActionToolbar = {
            action: this.createNewRepresentation,
            component: IP_MAINTENANCE_TOOLBAR,
            method: ADD_REPRESENTATION_TOOLBAR,
            name: fields.find(undo => undo.name === ADD_NEW_REPRESENTATION_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(addRepresentation);

        const history: IDataActionToolbar = {
            action: this.searchVersionHistory,
            component: IP_MAINTENANCE_TOOLBAR,
            method: HISTORY_METHOD_TOOLBAR,
            name: (fields.find(history => history.name === VERSION_HISTORY_STATE_KEY) || { data: '' }).data,
            icon: 'icon ms-Icon ms-Icon--History'
        };
        actionList.push(history);

        if (fields.find(f => f.name === CRM_EXTENDED_DETAILS_STATE_KEY)) {
            const extendedDetails: IDataActionToolbar = {
                action: openIpExtendedDetailsCRMInNewTab,
                component: IP_MAINTENANCE_TOOLBAR,
                method: CRM_EXTENDED_DETAILS_TOOLBAR,
                name: fields.find(f => f.name === CRM_EXTENDED_DETAILS_STATE_KEY).data,
                icon: 'icon ms-Icon ms-Icon--OpenInNewTab',
                isReadOnly: true
            };
            actionList.push(extendedDetails);
        }

        const save: IDataActionToolbar = {
            action: this.saveChangesAction,
            component: IP_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        if (fields.find(ipTransfer => ipTransfer.name == IP_TRANSFER_STATE_KEY)) {
            const transferIP: IDataActionToolbar = {
                action: this.ipTransfer,
                component: IP_MAINTENANCE_TOOLBAR,
                method: IP_TRANSFER,
                name: fields.find(ipTransfer => ipTransfer.name == IP_TRANSFER_STATE_KEY).data,
                icon: 'icon ms-Icon ms-Icon--Copy',
                isReadOnly: this.getUpdateRoleDisabled()
            };
            actionList.push(transferIP);
        }
        
        const merge: IDataActionToolbar = {
            action: this.mergeIPAction,
            component: IP_MAINTENANCE_TOOLBAR,
            method: MERGE_METHOD_TOOLBAR,
            name: fields.find(merge => merge.name === MERGE_IP_ACCOUNT_KEY).data,
            icon: 'icon ms-Icon ms-Icon--BranchMerge',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(merge);


        return actionList;
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            interestedParty,
            activeTab,
            toolbarData,
            tabs,
            isReadonly,
            workflowFieldsData
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade

        return (
            <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={this.resetMessageBanner.bind(this)}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    interestedParty={interestedParty}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction}
                    toolbarData={toolbarData}
                    component={IP_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].interestedPartyMaintenanceState.isNew}
                    isReadonly={isReadonly}
                    workflowFieldsData={workflowFieldsData}
                />
            </div>
        );
    }

}

export default IPMaintenanceToolbar;
