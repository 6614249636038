import { ComboBox } from "@fluentui/react";
import React from "react";
import { ReactNode } from "react";
import { IDataSource } from "./IDataSource";
import { IJobParameterControl } from "./IJobParameterControl";

export class MultiselectJobParameterControl
  implements IJobParameterControl<string>
{
  CurrentValue: string[] = [];
  DefaultValue: string[] = [];
  Options?: { code: string; description: string }[] | string = null;
  ControlID: string;
  Type:
    | "combobox"
    | "text-input"
    | "date-picker"
    | "numeric"
    | "radio-button"
    | "checkbox"
    | "checkbox-list"
    | "multiselect" = "multiselect";
  Caption: string;
  Readonly: boolean;
  DataSourceRepo: { [key: string]: IDataSource<string> };

  constructor() {}

  Render(): ReactNode {
    let options: { code: string; description: string }[];
    if (typeof this.Options == "string")
      options = this.DataSourceRepo[this.Options].Read();
    else options = this.Options;

    return (
        <div className="dropdownDataInput">
          <ComboBox
            multiSelect
            label={this.Caption}
            options={options.map((v, i, a) => ({
              key: v.code,
              text: v.description,
              selected:
                this.DefaultValue.find((dv, di, da) => dv == v.code) != null,
            }))}
            onChange={(e, o, i, v) => {
              this.CurrentValue = o.selected
                ? this.CurrentValue.filter((v, i, a) => v != o.key).concat(
                    o.key + ""
                  )
                : this.CurrentValue.filter((v, i, a) => v != o.key);
            }}
            useComboBoxAsMenuWidth
            calloutProps={{ calloutMaxHeight: 270 }}
          />
        </div>
    );
  }
}
