import {
    clearModalSearchResults,
} from "../reducers/RepertoireReducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import IAppState from "../types/IAppState";
import { hideModal } from "../reducers/ModalReducer";

export const clearModalSearchResultsThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>
> => {
    return (dispatch: Dispatch) => {
        dispatch(hideModal());
        dispatch(clearModalSearchResults());
    };
};