import { IClaimState } from "../../redux/types/IClaimState";
import { IClaim } from "../types/IClaim";
import { IClaimedWork } from "../types/IClaimedWork";
import { IClaimedWorkDataItems } from "../types/IClaimWorkDataItems";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";


export class ClaimDetailsMapper {
    public static mapStateToClaim(state: IClaimState): IClaim {
        if (state) {
            const {
                memberNumber,
                claimCode,
                claimDate,
                claimStatus,
                department,
                endYearOfDistributions,
                name,
                origin,
                resolutionDate,
                rightHolderID,
                startYearOfDistributions,
                isDeleted,
                lastModifiedDate,
                claimID,
                claimedWorks,
                calculationType,
                calculation,
                claimType,
                targetDistribution
            } = state;


            const claim: IClaim = {
                memberNumber,
                claimCode,
                claimDate,
                claimStatus,
                department,
                endYearOfDistributions,
                name,
                origin,
                resolutionDate,
                rightHolderID,
                startYearOfDistributions,
                isDeleted,
                lastModifiedDate,
                claimID,
                claimedWorks: ClaimDetailsMapper.mapStateToWork(claimedWorks),
                calculationType,
                calculation,
                claimType,
                targetDistribution
            };            

            let endYear:number = +claim.endYearOfDistributions.toString().slice(0, 4);
            let startYear: number = +claim.startYearOfDistributions.toString().slice(0, 4);

            claim.endYearOfDistributions = endYear;
            claim.startYearOfDistributions = startYear;


            return claim;
        }
        else {
            return null;
        }
    }

    public static mapClaimToState(claim: IClaim): IClaimState {
        if (claim) {
            const {
                memberNumber,
                claimCode,
                claimDate,
                claimStatus,
                department,
                endYearOfDistributions,
                name,
                origin,
                resolutionDate,
                rightHolderID,
                startYearOfDistributions,
                isDeleted,
                lastModifiedDate,
                claimedWorks,
                claimID,
                calculationType,
                calculation, 
                claimType,
                targetDistribution
            } = claim;

            let selectedCalculationTypes = []
            const state: IClaimState = {
                memberNumber,
                claimCode,
                claimDate,
                claimStatus,
                department,
                endYearOfDistributions,
                name,
                origin,
                resolutionDate,
                rightHolderID,
                startYearOfDistributions,
                isDeleted,
                lastModifiedDate,
                claimID,
                claimedWorks: ClaimDetailsMapper.mapWorkToState(claimedWorks),
                calculationType,
                calculation,
                claimType,
                targetDistribution,
                selectedCalculationTypes
            };

            return state;
        }
        else {
            return null;
        }
    }

    public static mapWorkNumbersToState(workNumbers: Array<Object>): string[] {
        const stateWorkNumbers: string[] = []
        if (typeof workNumbers !== 'undefined' || workNumbers !== null) {
            workNumbers.map(wn => {
                stateWorkNumbers.push(wn['number'])
            })
        }        
        return stateWorkNumbers;
    }
    
    public static mapWorkToState(work: IClaimedWork[]): IClaimedWorkDataItems[] {
        if (work) {
            const stateWork: IClaimedWorkDataItems[] = [];
            work.map(a => {
                stateWork.push({
                    claimedWorkID: a.claimedWorkID,
                    workID: a.workID,
                    claimID: a.claimID,
                    workName: a.workName,
                    workNumbers: ClaimDetailsMapper.mapWorkNumbersToState(a.workNumbers)
                })
            });
            return stateWork;
        }
        else {
            return [];
        }
    }

   
    public static mapStateToWork(work: IClaimedWork[]): IClaimedWorkDataItems[] {
        if (work) {
            const stateWork: IClaimedWorkDataItems[] = [];
            work.map(a => {
                stateWork.push({
                    claimedWorkID: a.claimedWorkID,
                    workID: a.workID,
                    claimID: a.claimID,
                    workName: a.workName,
                    workNumbers: []
                })
            });
            return stateWork;
        }
        else {
            return [];
        }
    }

    public static createNewClaim(lookups: ILookupDictionary): IClaim {
        const claim: IClaim = {
            memberNumber: '',
            rightHolderID: '',
            name: '',
            claimCode: '',
            claimStatus: '',
            origin: '',
            claimDate: new Date(),
            department: '',
            resolutionDate: new Date(),
            startYearOfDistributions: 0,
            endYearOfDistributions: 0,
            targetDistribution: '',
            isDeleted: false,
            lastModifiedDate: new Date(),
            claimedWorks: [],
            claimID: 0,
            calculationType: [],
            calculation: {
                firstValue: false,
                calculateAllAffected: false,
                minimumAmount: undefined
            }, 
            claimType: ''
        }

        return claim;
    }

    public static createNewClaimState(lookups: ILookupDictionary): IClaimState {
        return ClaimDetailsMapper.mapClaimToState(ClaimDetailsMapper.createNewClaim(lookups));
    }
}