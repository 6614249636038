import * as React from 'react';
import { PropertyEditor } from './PropertyEditor';
import { IComponentField } from '../../core/types/IComponentField';
import { Dictionary } from '../../core/types/Dictionary';
import { ComponentFields } from '../../core/services/ComponentService';
import { ComponentsHelper as CH } from '../../core/services/ComponentHelper';
import { If } from '../../core/components/If';
import { IParameterData } from '../types/IParameterData';
import { PropertyWindowSaveState } from '../types/PropertyWindowSaveState';
import { ISourceSearchResult } from '../types/ISourceSearchResult';
import { IProductType } from '../types/IProductType';
import { SourceHeader } from './SourceHeader';
import { MessageBanner, MessageType } from '../../repertoire/components/messageBanner/MessageBanner';
import {  CUSTOM_NOTEBOOK, USAGE_INGESTION_AUTO_PROCESS, USAGE_INGESTION_DEFAULT_DESTINATION, USAGE_INGESTION_DEFAULT_DISTRIBUTION, USAGE_INGESTION_DEFAULT_INPUT_FILE_FORMAT, USAGE_INGESTION_DEFAULT_OUTPUT_FILE_FORMAT, USAGE_INGESTION_DEFAULT_POOL, USAGE_INGESTION_FOLDER_NAME } from '../Consts';
import { IDistribution } from '../../repertoire/types/usageTypes/IDistribution';
import { IUsagePool } from '../../repertoire/types/usageTypes/IUsagePool';
import { ILookupDictionary } from '../../lookup/types/ILookupDictionary';
import { ITreeNodeData } from '../../treeview/types/TreeNodeData';
import { IFileSystem } from '../../dataingestion/types/IFileSystem';
import { ITreeData } from '../../repertoire/types/ITreeData';
import { IParameterSearchResult } from '../types/IParameterSearchResult';

interface IPropertyWindowProps {
    data: IParameterData[],
    source: ISourceSearchResult,
    updateSource: () => void,
    cancelChanges: () => void,
    overrideChanged: (parameterID: number, inherited: boolean) => void,
    currentValueChanged: (parameterID: number, currentValue: string) => void,
    selectedProductTypeChanged: (event: React.SyntheticEvent<HTMLSelectElement>) => void,
    selectSourceName: string,
    selectSourceCode: string,
    saveState: PropertyWindowSaveState,
    sourceDescription: string,
    rejectDuplicateUsageFiles: boolean,
    productTypes: IProductType[],
    selectedProductType: IProductType,
    isDirty: boolean,
    isEditingSource: boolean,
    editSourceClick: () => void,
    saveSourceChangesClick: (
        sourceID: number,
        sourceName: string,
        sourceCode: string,
        sourceDescription: string,
        rejectDuplicateUsageFiles: boolean,
        usageConfigurationParameters: IParameterSearchResult[]
    ) => void,
    cancelSourceChangesClick: () => void,
    onToggleSidebarCollapsedClick: () => void,
    pageContentIsActive: boolean
    clearBanner: () => void;
    sourceValidationErrorMsg?: string;
    updateRoleDisabled?: boolean;
    distributions?: IDistribution[];
    pools?: IUsagePool[];
    lookupValues?: ILookupDictionary;
    folderSelectedNode: ITreeNodeData<IFileSystem>;
    sources?: ITreeData[];
    sorcesFolderList?: IParameterSearchResult[];
    usageIngestionFolderName?: IParameterSearchResult;
    usageIngestionDefaultPool?: IParameterSearchResult;
    usageIngestionDefaultDistribution?: IParameterSearchResult;
    usageIngestionDefaultInputFileFormat?: IParameterSearchResult;
    usageIngestionAutoProcess?: IParameterSearchResult;
    customNotebook?: IParameterSearchResult;
}

interface IPropertyWindowState {
    activeFields: Dictionary<IComponentField>,
}

enum Fields {
    SaveButton,
    CancelButton,
    Inherited,
    DefaultValue,
    CustomValue,
    LoadingText,
    ParameterNullError,
    SaveSuccessMessage,
    SaveFailedMessage,
    CancelledMessage,
    SaveValidationMessage,
    PropertyTypeText,
}

export class PropertyWindow extends React.Component<IPropertyWindowProps, IPropertyWindowState> {
    ComponentName: string = "PropertyWindow";
    constructor(props: IPropertyWindowProps) {
        super(props);

        this.state = {
            activeFields: {}
        };
    }

    componentDidMount() {
        ComponentFields.get(this.ComponentName)
            .then(activeFields => {
                this.setState({ activeFields });
            });
    }

    
    render() {
        let customValue: string;

        if (this.props.source != null && this.props.source.level === 0) {
            customValue = CH.getField(Fields.DefaultValue, Fields, this.state.activeFields).text;
        }
        else {
            customValue = CH.getField(Fields.CustomValue, Fields, this.state.activeFields).text;
        }

        let className: string = "container-fluid pageContent";

        if (this.props.pageContentIsActive) {
            className = className + " active";
        }

        const { source } = this.props;

        let usageIngestionDefaultOutputFileFormat = source?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_DEFAULT_OUTPUT_FILE_FORMAT)[0];
        let usageIngestionDefaultDestination = source?.parameterInstances.filter(p => p.parameterName === USAGE_INGESTION_DEFAULT_DESTINATION)[0];
        let sourceEditorParameters = [CUSTOM_NOTEBOOK, USAGE_INGESTION_FOLDER_NAME, USAGE_INGESTION_DEFAULT_POOL, USAGE_INGESTION_DEFAULT_DISTRIBUTION, USAGE_INGESTION_DEFAULT_INPUT_FILE_FORMAT,
            USAGE_INGESTION_AUTO_PROCESS, USAGE_INGESTION_DEFAULT_OUTPUT_FILE_FORMAT, USAGE_INGESTION_DEFAULT_DESTINATION];

        return (
            <div className={className}>
                
            <If condition={this.props.saveState != PropertyWindowSaveState.Unchanged}>
                <MessageBanner messageType={this.props.saveState == PropertyWindowSaveState.SaveSucceeded ? 
                    MessageType.Success : this.props.saveState == PropertyWindowSaveState.SaveFailed ?
                    MessageType.Danger : this.props.saveState == PropertyWindowSaveState.Cancelled ?
                    MessageType.Information : MessageType.Information} 
                    
                    messages={this.props.saveState == PropertyWindowSaveState.SaveFailed && this.props.sourceValidationErrorMsg.length > 0 ? 
                               [this.props.sourceValidationErrorMsg] : this.props.saveState == PropertyWindowSaveState.SaveSucceeded ?
                               [CH.getField(Fields.SaveSuccessMessage, Fields, this.state.activeFields).text] : this.props.saveState == PropertyWindowSaveState.SaveFailed ?
                               [CH.getField(Fields.SaveFailedMessage, Fields, this.state.activeFields).text] : this.props.saveState == PropertyWindowSaveState.Cancelled ? 
                               [CH.getField(Fields.CancelledMessage, Fields, this.state.activeFields).text] : [CH.getField(Fields.SaveValidationMessage, Fields, this.state.activeFields).text]} 
                    resetMessageBanner={this.props.clearBanner}/>
            </If>

            {/* Header */}
            <SourceHeader
                    selectSourceName={this.props.selectSourceName}
                    selectSourceCode={this.props.selectSourceCode}
                    sourceDescription={this.props.sourceDescription}
                    rejectDuplicateUsageFiles={this.props.rejectDuplicateUsageFiles}
                    sourceID={this.props.source == null ? null : this.props.source.sourceID}
                    usageIngestionFolderName={this.props.usageIngestionFolderName}
                    usageIngestionDefaultPool={this.props.usageIngestionDefaultPool}
                    usageIngestionDefaultDistribution={this.props.usageIngestionDefaultDistribution}
                    usageIngestionDefaultInputFileFormat={this.props.usageIngestionDefaultInputFileFormat}
                    usageIngestionDefaultOutputFileFormat={usageIngestionDefaultOutputFileFormat}
                    usageIngestionDefaultDestination={usageIngestionDefaultDestination}
                    usageIngestionAutoProcess={this.props.usageIngestionAutoProcess}
                    isEditing={this.props.isEditingSource}
                    editSourceClick={this.props.editSourceClick}
                    saveSourceChangesClick={this.props.saveSourceChangesClick}
                    cancelSourceChangesClick={this.props.cancelSourceChangesClick}
                    onToggleSidebarCollapsedClick={this.props.onToggleSidebarCollapsedClick}
                    updateRoleDisabled={this.props.updateRoleDisabled}
                    distributions={this.props.distributions}
                    pools={this.props.pools}
                    lookupValues={this.props.lookupValues}
                    folderSelectedNode={this.props.folderSelectedNode}
                    sources={this.props.sources}
                    sorcesFolderList={this.props.sorcesFolderList}
                    customNotebook={this.props.customNotebook}
                />

            {/* Loading message */}
            <If condition={this.props.data == null && this.props.source == null}>
                    <If condition={CH.getField(Fields.LoadingText, Fields, this.state.activeFields).exists}>
                        <div className='row'>
                            <label>{CH.getField(Fields.LoadingText, Fields, this.state.activeFields).text}</label>
                        </div>
                    </If>
                </If>

            {/* Product types combobox */}
            <div className='row'>
                    <If condition={true}>
                        <If condition={this.props.productTypes != null && this.props.productTypes.length > 1}>
                            <div className="form-group col-md-5">
                                <If condition={CH.getField(Fields.PropertyTypeText, Fields, this.state.activeFields).exists}>
                                    <label>{CH.getField(Fields.PropertyTypeText, Fields, this.state.activeFields).text}</label>
                                </If>

                                <select onChange={this.props.selectedProductTypeChanged.bind(this)}
                                    value={this.props.selectedProductType ? this.props.selectedProductType.name : 1}>
                                    {
                                        this.props.productTypes ? this.props.productTypes.map((p: IProductType, idx: number) =>
                                            <option key={idx} value={p.name}>{p.name}</option>) : null
                                    }
                                </select>
                            </div>
                        </If>

                    </If>

                </div>

            {/* Property editors */}
            <If condition={this.props.data && this.props.data.length > 0}>
                    <If condition={CH.getField(Fields.DefaultValue, Fields, this.state.activeFields).exists}>
                        <div className="row">
                            {
                                this.props.data ? this.props.data.filter(pd => sourceEditorParameters.indexOf(pd.parameterName) === -1).map((p: IParameterData, idx: number) =>
                                    <div key={idx}>
                                        <PropertyEditor data={p}
                                            overrideChanged={this.props.overrideChanged.bind(this)}
                                            currentValueChanged={this.props.currentValueChanged.bind(this)}
                                            inheritedValueText={CH.getField(Fields.Inherited, Fields, this.state.activeFields).text}
                                            customValueText={customValue}
                                            parameterNullError={CH.getField(Fields.ParameterNullError, Fields, this.state.activeFields).text} />
                                    </div>) : null
                            }
                        </div>
                    </If>

                </If>
            <br />

            {/* Save and cancel buttons */}
            <div className="row">
                    <If condition={CH.getField(Fields.CancelButton, Fields, this.state.activeFields).exists}>
                        <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                            <button className="button btn-defaultSecondary" disabled={!this.props.isDirty}
                                onClick={this.props.cancelChanges.bind(this)}
                            >{CH.getField(Fields.CancelButton, Fields, this.state.activeFields).text}</button>
                        </div>
                    </If>

                    <If condition={CH.getField(Fields.SaveButton, Fields, this.state.activeFields).exists}>
                        <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                            <button className="button btn-defaultPrimary" disabled={!this.props.isDirty || this.props.updateRoleDisabled}
                                onClick={this.props.updateSource.bind(this)}>
                                {CH.getField(Fields.SaveButton, Fields, this.state.activeFields).text}</button>
                        </div>
                    </If>
                </div>

                
            </div>
        );
    }
}