import React from "react";
import ProductMaintenanceRecordingSection, { IProductMaintenanceRecordingSectionProps } from "./ProductMaintenanceRecordingSection";


const ProductRecordingWrapper = ({
    product,
    dataGridTableData,
    lookupValues,
    isFormVisible,
    productRecordingFormikRef,
    updateProductFields,
    setIsChangeRecordingForm,
    changeData,
}) => {


 
    const [productMaintenanceData, setProductMaintenanceData] = React.useState<IProductMaintenanceRecordingSectionProps | null>(null);
    const [initialLoad, setInitialLoad] = React.useState(true);

    React.useEffect(() => {
      if (initialLoad && dataGridTableData) {
        const initialState = {
            product: product,
            dataGridTableData: dataGridTableData,
            lookupValues: lookupValues,
            productRecordingFormikRef: productRecordingFormikRef,
            updateProductFields: updateProductFields,
            setIsChangeRecordingForm: setIsChangeRecordingForm,
            isFormVisible: isFormVisible,
            changeData: changeData,
        }
        setProductMaintenanceData(initialState);

        setInitialLoad(false);
      }
    }, [initialLoad]);

return dataGridTableData && productMaintenanceData?.product ? <ProductMaintenanceRecordingSection
    product={productMaintenanceData.product}
    dataGridTableData={productMaintenanceData.dataGridTableData}
    lookupValues={productMaintenanceData.lookupValues}
    productRecordingFormikRef={productMaintenanceData.productRecordingFormikRef}
    updateProductFields={productMaintenanceData.updateProductFields}
    setIsChangeRecordingForm={productMaintenanceData.setIsChangeRecordingForm}
    isFormVisible={isFormVisible}
    changeData={productMaintenanceData.changeData}
/> : <></>;

}

export default ProductRecordingWrapper;