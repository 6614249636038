import * as React from "react";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../../Consts";
import { If } from "../../../../core/components/If";

export interface IWorkBatchInputProps {
    label?: string;
    value?: any;
    changeData: (value: string | boolean, fieldName: IRepertoireStateKeys) => void;
    fieldName: IRepertoireStateKeys;
    useEnterSubmit?: boolean;
    handleKeyDown?: any;
    readOnly?: boolean;
    postButtonLabel: string;
    postButtonAction: () => void;
    matchButtonLabel: string;
    matchButtonAction: () => void;
    isEnabled: boolean;
    isRemoveStyleWrapper?: boolean;
}

export default class WorkBatchInput extends React.PureComponent<IWorkBatchInputProps> {
    constructor(props: IWorkBatchInputProps) {
        super(props);
    }

    render() {
        const {
            label,
            value,
            changeData,
            fieldName,
            handleKeyDown,
            useEnterSubmit,
            readOnly,
            postButtonAction,
            postButtonLabel,
            matchButtonAction,
            matchButtonLabel,
            isEnabled,
            isRemoveStyleWrapper
        } = this.props;

        const displayInput = !label;
        return (
            <div className={!isRemoveStyleWrapper ? 'form-group col-lg-6 col-md-9 col-sm-12 col-xs-12': ''}>
                <If condition={!displayInput}>
                <div>
                    {label ? <label>{label}:</label> : null}
                </div>
                </If>
                <div className='workBatchInput'>
                    <If condition={!displayInput}>
                    {useEnterSubmit ?
                    
                        <input
                            className={"textInput"}
                            type={"text"}
                            value={value || ''}
                            onChange={event => changeData(event.target.value, fieldName)}
                            onKeyDown={event => handleKeyDown(event, fieldName)}
                        /> : (readOnly ?
                            <input
                                className={"textInput"}
                                type={"text"}
                                value={value || ''}
                                onChange={event => changeData(event.target.value, fieldName)}
                                disabled
                            /> :
                            <input
                                className={"textInput"}
                                type={"text"}
                                value={value || ''}
                                onChange={event => changeData(event.target.value, fieldName)}
                            />)
                    }
                    </If>
                    <If condition={!!matchButtonLabel}>
                        <button onClick={isEnabled ? matchButtonAction : null}
                            className={isEnabled ? 'enabledButton' : 'disabledButton'}>
                            {matchButtonLabel}
                        </button>
                    </If>
                    <If condition={!!postButtonLabel}>
                        <button onClick={isEnabled ? postButtonAction : null}
                            className={isEnabled ? 'enabledButton' : 'disabledButton'}>
                            {postButtonLabel}
                        </button>
                    </If>
                </div>
            </div>
        );
    }
}

