import * as React from "react";

export interface ISelectorViewProps {
    selection: string;
    option1: string,
    option2: string,
    setSelection: (selection: string) => void,
    selectorTitle?: string
    disabled?: boolean;
    customStyle?: string;
    key?: string;
}

const SelectorView: React.SFC<ISelectorViewProps> = ({ selection, key, setSelection, option1, option2, selectorTitle, disabled, customStyle }) => {
    const setSelectedOption = () => {
        if (!disabled) {
            if (selection === option1) {
                setSelection(option2)
            } else {
                setSelection(option1)
            }
        }
    };

    const highlightIfSelected = buttonValue => {
        if (buttonValue === selection) {
            return "selectorRadioButtonSelected"
        }
        else return "selectorRadioButtonUnSelected"
    }


    const setButtonLabel = (value) => {
        let firstLetter = value.substring(0, 1);
        let restOfWord = value.substring(1).toLowerCase();

        return firstLetter + restOfWord;
    }

    const customStyleValue: string = customStyle && customStyle.length > 0 ? `selectorRadioButtonsDiv ${customStyle}` : 'selectorRadioButtonsDiv';
    if (!disabled) {
        return (
            <div key={key} className="form-group col-md-4 col-sm-6 selectorView">
                <label className="selectorTitle">{selectorTitle}</label>
                <div className={customStyleValue}>
                    <div className="selectorRadioButtonsLeftSubDiv">
                        <input
                            type="button"
                            onClick={setSelectedOption}
                            value={setButtonLabel(option1)}
                            className={["selectorRadioButtonsText", highlightIfSelected(option1)].join(' ')}
                            data-testid={selectorTitle + " option1"}
                        />
                    </div>
                    <div className="selectorRadioButtonsRightSubDiv">
                        <input
                            type="button"
                            onClick={setSelectedOption}
                            value={setButtonLabel(option2)}
                            className={["selectorRadioButtonsText", highlightIfSelected(option2)].join(' ')}
                            data-testid={selectorTitle + " option2"}
                        />
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="form-group col-md-4 col-sm-6 selectorViewDisabled">
                <label className="selectorTitle">{selectorTitle}</label>
                <div className="selectorRadioButtonsDiv">
                    <div className="selectorRadioButtonsLeftSubDiv">
                        <input
                            type="button"
                            onClick={null}
                            value={setButtonLabel(option1)}
                            className={["selectorRadioButtonsText", highlightIfSelected(option1)].join(' ')}
                        />
                    </div>
                    <div className="selectorRadioButtonsRightSubDiv">
                        <input
                            type="button"
                            onClick={null}
                            value={setButtonLabel(option2)}
                            className={["selectorRadioButtonsText", highlightIfSelected(option2)].join(' ')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectorView;