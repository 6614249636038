import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { setDataSource } from "../../redux/reducers/RepertoireReducer";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { MemberPortalSearchTypeItem } from "../../redux/types/MemberPortalSearchTypeItem";
import { IDraftSetListSearchResult } from "../types/IDraftSetListSearchResult";
import { IMemberProductSearchResult } from "../types/IMemberProductSearchResult";
import DraftSetListsPage from "../draftSetLists/DraftSetListsPage";
import { IRegisteredSetListsSearchQuery } from "../types/IRegisteredSetListsSearchQuery";
import { FormatFields } from "../../redux/types/FormatFields";

export interface IRegisteredSetListsPageProps {
    registeredSetListsPageData?: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchRegisteredSetLists?: (query: IRegisteredSetListsSearchQuery) => void;
    registeredSetListsSearchResult?: IDraftSetListSearchResult[];
    memberSetListSearchResults?: IMemberProductSearchResult[];
    searchSuccessful: boolean;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    disabled?: boolean;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    setDataSource: typeof setDataSource;
    dataSource: string;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    currentUserNameNumber?: string;
    getDraftSetListDetails: (dataSource: string, productCoreId: number, lookupValues: ILookupDictionary, formats?: FormatFields[]) => void;
    memberPortalSearchType: MemberPortalSearchTypeItem;
    addNewDraftSetLists: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, registeredSetListsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem) => void;
}

const RegisteredSetListsPage = (props: IRegisteredSetListsPageProps) => {
    // TODO: EB 2023/04/03. DraftWorksPage should be replaced by a new component like
    // MemberWorksPage in order for the reutilization to have more sense.
    let memberSetListsPage = DraftSetListsPage

    return memberSetListsPage({
        setListsPageData: props.registeredSetListsPageData,
        searchViewData: props.searchViewData,
        searchResultsTableData: props.searchResultsTableData,
        searchDraftSetLists: props.searchRegisteredSetLists,
        draftSetListSearchResults: null,
        memberSetListSearchResults: props.registeredSetListsSearchResult,
        searchSuccessful: props.searchSuccessful,
        lookupValues: props.lookupValues,
        indexOfFirstResult: props.indexOfFirstResult,
        indexOfLastResult: props.indexOfLastResult,
        resultsPerPage: props.resultsPerPage,
        currentPage: props.currentPage,
        resetPagination: props.resetPagination,
        updatePagination: props.updatePagination,
        sortSearchResults: props.sortSearchResults,
        defaultActiveAccordions: props.defaultActiveAccordions,
        allResultsPerPage: props.allResultsPerPage,
        updateUserPreferences: props.updateUserPreferences,
        disabled: props.disabled,
        roles: props.roles,
        saveRoles: props.saveRoles,
        setDataSource: props.setDataSource,
        dataSource: props.dataSource,
        activeTab: props.activeTab,
        tabs: props.tabs,
        currentUserNameNumber: props.currentUserNameNumber,
        memberPortalSearchType: props.memberPortalSearchType,
        addNewDraftSetLists: props.addNewDraftSetLists,
        draftSetListsMaintenanceGeneralDataViewData: undefined,
        getDraftSetListDetails: props.getDraftSetListDetails
    })
}

export default RegisteredSetListsPage;
