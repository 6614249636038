import { ComponentFields } from "../../core/services/ComponentService";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import IAppState from "../types/IAppState";
import {
    getEnabledModuleFailure,
    getEnabledModuleRequest,
    getEnabledModuleSuccess,
    getHomeComponentDataFailure,
    getHomeComponentDataRequest,
    getHomeComponentDataSuccess,
    getManualJobParametersFailure,
    getManualJobParametersSuccess,
    getMenuItemsFailure,
    getMenuItemsRequest,
    getMenuItemsSuccess,
    searchJobsFailure,
    searchJobsRequest,
    searchJobsSuccess,
    setCurrentPage,
    triggerManualJobFailure,
    triggerManualJobRequest,
    triggerManualJobSuccess,
    getCWRAckHWMSuccess,
    getCWRAckHWMFailure,
    getAutomaticAgreementGHWMSuccess,
    getAutomaticAgreementGHWMFailure,
    getIceATFSuccess,
    getIceATFFailure,
    getDisplayCustomerLogoRequest,
    getDisplayCustomerLogoSuccess,
    getDisplayCustomerLogoFailure,
    getCustomerNameSuccess,
    getCustomerLogoAddresses,
    getManualJobDataSourcesRequest,
    getManualJobDataSourcesSuccess,
    getManualJobDataSourcesFailure,
    searchContributorsRequestHomeModal,
    searchContributorsSuccessHomeModal,
    searchContributorsFailureHomeModal,
    getOpenDistributionsSuccess,
    getOpenDistributionsFailure,
    getPoolsSuccess,
    getPoolsFailure,
    getOpenDistributionsRequest,
    getPoolsRequest,
    getEmuwHwmSuccess,
    getEmuwHwmFailure,
    getEmuwHwmRequest,
    getScheduledJobRequest,
    getScheduledJobSuccess,
    getScheduledJobFailure,
    upsertScheduledJobAsyncRequest,
    upsertScheduledJobAsyncSuccess,
    upsertScheduledJobAsyncFailure,
    getUsageFilenamesRequest,
    getUsageFilenamesSuccess,
    getUsageFilenamesFailure,
    getAllUsageFilenamesFromDistributionRequest,
    getAllUsageFilenamesFromDistributionSuccess,
    getAllUsageFilenamesFromDistributionFailure,
    getAllDistributionsRequest,
    getAllDistributionsSuccess,
    getAllDistributionsFailure,    
    getCWRGHighWaterMarkRequest,
    getCWRGHighWaterMarkSuccess,
    getCWRGHighWaterMarkFailure,
    getSeparatePublishedCheckboxRequest,
    getSeparatePublishedCheckboxSuccess,
    getSeparatePublishedCheckboxFailure,
    getDaspHighWaterMarkRequest,
    getDaspHighWaterMarkSuccess,
    getDaspHighWaterMarkFailure
} from "../reducers/HomeReducer";
import IHomeComponentData from "../../home/types/IHomeComponentData";
import { IMenuItem } from "../../core/types/IMenuItem";
import { IJobSearchResult } from "../../home/types/IJobSearchResult";
import { SearchRequests } from "../../home/services/SearchRequests";
import { IJobSearchState } from "../../home/types/IJobSearchState";
import { IScheduledJobState } from "../types/IScheduledJobState";
import { CUSTOMER_GROUP, CUSTOMER_KEY, CUSTOMER_LOGOS_KEY, CWRG_GROUP, DISPLAY_CUSTOMER_LOGO_KEY, ENABLED_MODULES_CONFIGURATION_KEY, MANUAL_JOB_VIEW, MODULE_GROUP_CONFIGURATION_KEY, SEPARATE_PUBLISHED_CHECKBOX } from "../../home/Consts";
import { hideModal, showManualJobView } from "../reducers/ModalReducer";
import { JobService } from "../services/JobService";
import { ITriggerManualJob } from "../types/ITriggerManualJob";
import { ICwrgParameters } from "../types/ICwrgParameters";
import { ICustomerLogo } from "../../home/types/ICustomerLogo";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";

export const getHomeComponentDataThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getHomeComponentDataRequest());
        return ComponentFields.getHomeComponentData()
            .then((res: IHomeComponentData) => {
                dispatch(getHomeComponentDataSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getHomeComponentDataFailure(error));
            });
    };
};

export const getMenuItemsThunk = (portalType: string): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getMenuItemsRequest());
        return ComponentFields.getMenuItems(portalType)
            .then((res: IMenuItem[]) => {
                dispatch(getMenuItemsSuccess(res));
                dispatch(setCurrentPage(window.location.pathname));
            })
            .catch((error: any) => {
                dispatch(getMenuItemsFailure(error));
            });
    };
};

export const searchJobsThunk = (searchQuery: IJobSearchState): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchJobsRequest());
        return SearchRequests.searchJobs(searchQuery)
            .then((res: IJobSearchResult[]) => {
                dispatch(searchJobsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(searchJobsFailure(error));
            });
    };
};

export const manualJobView = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        return dispatch(showManualJobView("Manual Job View", MANUAL_JOB_VIEW, {
            manualJobDetails: [""],
            getManualJobParameters: (value: any) => getManualJobParameters(dispatch, value)
        })
        );
    };
};

function getManualJobParameters(dispatch: Dispatch, value: any): void {
    JobService.getJobParameters(value)
        .then((res: any) => {
            dispatch(getManualJobParametersSuccess(res, value));
        })
        .catch((error: any) => {
            dispatch(getManualJobParametersFailure(error));
        });
};

export const getScheduledJob = (
    jobType: string, 
    jobParameters: string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getScheduledJobRequest())
        return JobService.getScheduledJob(jobType,jobParameters)
            .then((res: any[]) => {
                dispatch(getScheduledJobSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getScheduledJobFailure(error));
            });
    }
}

export const upsertScheduledJobAsync = (
    schedule : IScheduledJobState
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(upsertScheduledJobAsyncRequest())
        return JobService.upsertScheduledJob(schedule)
            .then((res: any[]) => {
                dispatch(upsertScheduledJobAsyncSuccess(res));
            })
            .catch((error: any) => {
                dispatch(upsertScheduledJobAsyncFailure());
            });
    }
}

export const getEnabledModulesThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getEnabledModuleRequest());
        return SearchRequests.getConfigurationParameter(ENABLED_MODULES_CONFIGURATION_KEY, MODULE_GROUP_CONFIGURATION_KEY)
            .then((res: any) => {
                dispatch(getEnabledModuleSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getEnabledModuleFailure(error));
            });
    }
}

export const triggerManualJob = (
    manualJob: ITriggerManualJob
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(triggerManualJobRequest())
        return JobService.triggerManualJob(manualJob)
            .then((res: any[]) => {
                dispatch(triggerManualJobSuccess());
            })
            .catch((error: any) => {
                dispatch(triggerManualJobFailure(error));
            });
    }
}

export const getUsageFilenames = (
    poolValue: string,
    distributionValue:string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getUsageFilenamesRequest())
        return JobService.getUsageFilenames(poolValue,distributionValue)
            .then((res: any[]) => {
                dispatch(getUsageFilenamesSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getUsageFilenamesFailure(error));
            });
    }
}

export const getAllUsageFilenamesFromDistribution = (
    distributionValue:string
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getAllUsageFilenamesFromDistributionRequest())
        return JobService.getAllUsageFilenamesFromDistribution(distributionValue)
            .then((res: any[]) => {
                dispatch(getAllUsageFilenamesFromDistributionSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getAllUsageFilenamesFromDistributionFailure(error));
            });
    }
}

export const getManualJobDataSources = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getManualJobDataSourcesRequest());
        return JobService.getManualJobDataSources()
            .then((res) => dispatch(getManualJobDataSourcesSuccess(res)))
            .catch((err) => dispatch(getManualJobDataSourcesFailure(err)))

    }
}

export const searchContributorsThunk = (
    searchBody: IContributorSearchQuery
): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(searchContributorsRequestHomeModal());
        return SearchRequests.getContributors(searchBody)
            .then((res: IContributorSearchResult[]) => {
                dispatch(searchContributorsSuccessHomeModal(res));
            })
            .catch(err => {
                dispatch(searchContributorsFailureHomeModal(err));
            });
    };
};

export const getCWRAckHWM = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        return JobService.getCWRAckHWM()
            .then((res: any) => {
                dispatch(getCWRAckHWMSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getCWRAckHWMFailure(error));
            });
    }
};

export const getAutomaticAgreementGHWM = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        return JobService.getAutomaticAgreementGHWM()
            .then((res: any) => {
                dispatch(getAutomaticAgreementGHWMSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getAutomaticAgreementGHWMFailure(error));
            });
    }
};

export const getICEATF = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        return JobService.getIceATF()
            .then((res: any) => {
                dispatch(getIceATFSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getIceATFFailure(error));
            });
    }
};

export const getEmuwHwm = (distributionId: number): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getEmuwHwmRequest(distributionId));
        return JobService.getEmuwHwm(distributionId)
            .then((res: any) => {
                dispatch(getEmuwHwmSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getEmuwHwmFailure(error));
            });
    }
};

export const displayCustomerLogoThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDisplayCustomerLogoRequest());
        return SearchRequests.getConfigurationParameter(DISPLAY_CUSTOMER_LOGO_KEY, MODULE_GROUP_CONFIGURATION_KEY)
            .then((res: number) => {
                dispatch(getDisplayCustomerLogoSuccess(res));
                if (res === 1) {
                    return SearchRequests.getConfigurationParameter(CUSTOMER_KEY, CUSTOMER_GROUP)
                        .then((customerName: string) => {
                            dispatch(getCustomerNameSuccess(customerName))
                            return SearchRequests.getConfigurationParameter(CUSTOMER_LOGOS_KEY, CUSTOMER_GROUP)
                                .then((customerLogos: ICustomerLogo[]) => {
                                    dispatch(getCustomerLogoAddresses(customerLogos, customerName))
                                })
                        })
                }
            })
            .catch((error: any) => {
                dispatch(getDisplayCustomerLogoFailure());
            });
    }
}

export const getOpenDistributionsThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        dispatch(getOpenDistributionsRequest());

        return SearchRequests.getOpenDistributions()
            .then((res: any) => {
                dispatch(getOpenDistributionsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getOpenDistributionsFailure(error));
            });
    }
};

export const getAllDistributionsThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        dispatch(getAllDistributionsRequest());

        return SearchRequests.getAllDistributions()
            .then((res: any) => {
                dispatch(getAllDistributionsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getAllDistributionsFailure(error));
            });
    }
};

export const getPoolsThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        dispatch(getPoolsRequest());

        return SearchRequests.getPools()
            .then((res: any) => {
                dispatch(getPoolsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getPoolsFailure(error));
            });
    }
};

export const getDistributionsByCDTypeThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {

        dispatch(getOpenDistributionsRequest());

        return SearchRequests.getDistributionsByCDType()
            .then((res: any) => {
                dispatch(getOpenDistributionsSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getOpenDistributionsFailure(error));
            });
    }
};


export const getCWRGHighWaterMarkThunk = (cwrgParams: ICwrgParameters): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getCWRGHighWaterMarkRequest());
        return JobService.getCWRGHighWaterMark(cwrgParams)
            .then((res: any) => {
                dispatch(getCWRGHighWaterMarkSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getCWRGHighWaterMarkFailure(error));
            });
    }
};

export const getDaspHighWaterMarkThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getDaspHighWaterMarkRequest());
        return JobService.getDaspHighWaterMark()
            .then((res: any) => {
                dispatch(getDaspHighWaterMarkSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getDaspHighWaterMarkFailure(error));
            });
    }
};

export const getSeparatePublishedCheckboxThunk = (): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(getSeparatePublishedCheckboxRequest());
        return SearchRequests.getConfigurationParameter(SEPARATE_PUBLISHED_CHECKBOX, CWRG_GROUP)
            .then((res: any) => {
                dispatch(getSeparatePublishedCheckboxSuccess(res));
            })
            .catch((error: any) => {
                dispatch(getSeparatePublishedCheckboxFailure(error));
            });
    }
}
