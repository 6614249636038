import React from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { EMPTY_STRING_VALUE, REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";
import { DatePicker } from 'antd';
import { connect } from "react-redux";
import dayjs from 'dayjs';
import IAppState from "../../../redux/types/IAppState";

export interface IDateDataInputProps {
    label: string;
    value: string | Date;
    changeData: (
        value: string | boolean | Date,
        fieldName?: IRepertoireStateKeys,
        index?: number
    ) => void;
    fieldName: IRepertoireStateKeys;
    readOnly?: boolean;
    isHidden: boolean;
    isMandatory?: boolean;
    customerDatePreference?: string;
    key?: string;
}

const SizedDatePickerDataInput: React.FC<IDateDataInputProps> = (props: IDateDataInputProps) => {
    if (props.isHidden)
        return null;
    return <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
        <DatePickerDataInput {...props} />
    </div>
}

const DatePickerDataInput:  React.FC<IDateDataInputProps> = ({ label, key, changeData, fieldName, readOnly, isMandatory, customerDatePreference, value }) => {
        const standardDateForProcessing = "YYYY-MM-DD";
        const EUROPEAN_DATE_FORMAT = "DD/MM/YYYY";
        const preferenceDateFormat = customerDatePreference ? customerDatePreference : EUROPEAN_DATE_FORMAT;

        const handleDateChange = (date) => {
            if(date) {
            changeData(date.format(standardDateForProcessing), fieldName)
            return;
            }
            changeData(undefined, fieldName)
        }
        
        return (
            <div key={key} className={'flexColumn'} >
                {label ? <label className={EMPTY_STRING_VALUE.concat(isMandatory ? " isMandatory " : "", readOnly ? " disabledItem " : "")}>{label}</label> : null}
                {readOnly ?
                  <DatePicker
                  value={value ? dayjs(value) : undefined}
                  onChange={handleDateChange}
                  disabled={true}
                  size="large"
                      style={{borderRadius: 0}}
                  format={preferenceDateFormat} />
                 :
                    <DatePicker
                        value={value ? dayjs(value) : undefined}
                        onChange={handleDateChange}
                        size="large"
                        style={{borderRadius: 0}}
                        format={preferenceDateFormat} />
                }
            </div>
        );
}

export default connect((state: IAppState) => {
    return ({
    customerDatePreference: state.account.customerDate,
    customerTimeZonePreference: state.account.customerTimeZone
})
})(SizedDatePickerDataInput);
