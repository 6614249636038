import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IRepertoireField } from "../../../types/IRepertoireField";
import ActionButton, { ActionButtonFormik, InlineActionButton, SizedActionButton, WideActionButton } from "../../../components/actionButton/ActionButton";
import TextDataInput, { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { IP_STATUS_STATE_KEY, LAST_MODIFIED_DATE_STATE_KEY, NOTE_KEY,CLEAR_PACKAGE_BUTTON,GENERATE_FILE_PACKAGE, LASTMODIFIED_DATE_KEY, ITEM_COUNT_KEY, DOWNLOAD_FILE_FORMAT_KEY } from "../../../Consts";
import { Form } from "reactstrap";
import DropdownDataInput, { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { IMyPackagesDetails } from "../../../types/IMyPackagesDetails";
import { EntityType } from "../../../types/EntityType";
import moment from "moment";

export interface MyPackagesGeneralDataViewProps {
    myPackagesGeneralDataViewData: IRepertoireComponentDataItem;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: IRepertoireField[];
    placeholder:string;
    DownloadFileFormats:any;
    clearPackages?:(myPackages:IMyPackagesDetails)=>void;
    myPackagesResult?:IMyPackagesDetails[];
    headerType?:string;
    getMyPackagesDetails?: (userName: string, modalOpen: boolean) => void;
    currentUser?:string;
    handleClearPackages: (updatedPackagesData: IMyPackagesDetails[], headerType: string) => void;
    generatePackage?: (myPackage: IMyPackagesDetails) => void;
    closePackage?: (myPackage: IMyPackagesDetails) => void;
    addPackageNote?: (myPackage: IMyPackagesDetails) => void;
}

interface MyPackagesGeneralDataViewState {
    loaded: boolean;
    selectedFormat?:any;
    lastModifiedDate?:string;
    packageItemsCount?:number;
    myPackagesResult?: IMyPackagesDetails[];
    note: string;
    disablebtn?:boolean;
}

export default class MyPackagesGeneralDataView extends React.PureComponent<
MyPackagesGeneralDataViewProps,
MyPackagesGeneralDataViewState
> {
    viewData;
    onClickSearch: () => void;
    constructor(props: MyPackagesGeneralDataViewProps) {
        super(props);
        this.state = {
            loaded: false,          
            note: "",    
            disablebtn:false      
        };
        this.viewData = {};
    }

    componentDidMount() {
        this.processData(this.props.myPackagesGeneralDataViewData);
        const filteredData = this.getInitialPackageData(this.props.headerType);
        const {DownloadFileFormats,myPackagesResult}=this.props;
        if (filteredData && filteredData.length > 0) {
            this.setState({
                lastModifiedDate: filteredData[0].lastModifiedDate? moment(filteredData[0].lastModifiedDate).format("DD/MM/YYYY hh:mm A"): 'N/A',
                packageItemsCount: filteredData[0].packageItemsCount,
                selectedFormat:  DownloadFileFormats.length > 0 ? DownloadFileFormats[0] : "Select",
                disablebtn: filteredData[0]===undefined || filteredData[0]?.packageItemsCount === 0  ? true : false         
            });
        }
        else{
            this.setState({
                lastModifiedDate:'',
                packageItemsCount: 0,
                selectedFormat:  DownloadFileFormats.length > 0 ? DownloadFileFormats[0] : "Select",
                disablebtn:true         
            });
        }
    }

    getInitialPackageData(defaultSection) {
        const { myPackagesResult } = this.props;    
        if(myPackagesResult && myPackagesResult['data'].length > 0) 
        {
          const sectionData = myPackagesResult['data'];
          const section=sectionData.filter(x=>x.entityType===defaultSection && x.status === "Open")
          .map(x => ({
            lastModifiedDate: x.lastModifiedDate,
            packageItemsCount: x.packageItems.length,
           }));
          return section
        }
    }

    processData(data) {
        if (data && data.fields) {
            const visibleFields = data.fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;
            });

            if (Object.keys(this.viewData).length === visibleFields.length) {
                this.setState({ loaded: true });
            }
        }
    }

    onSaveEditableFields = (value, fieldName) => {
        if (fieldName === this.viewData[DOWNLOAD_FILE_FORMAT_KEY]) {
            this.setState({ selectedFormat: value });
        }
        else if (fieldName === NOTE_KEY) {
            this.setState({ note: value });
        }
    }
    componentDidUpdate() {
        this.processData(this.props.myPackagesGeneralDataViewData);
        const filteredData = this.getInitialPackageData(this.props.headerType);
        const { DownloadFileFormats, myPackagesResult } = this.props;
        if (filteredData && filteredData.length > 0) {
            this.setState({
                lastModifiedDate: filteredData[0].lastModifiedDate ? moment(filteredData[0].lastModifiedDate).format("DD/MM/YYYY hh:mm A") : 'N/A',
                packageItemsCount: filteredData[0].packageItemsCount,
                disablebtn: filteredData[0] === undefined || filteredData[0]?.packageItemsCount === 0 ? true : false
            });
        }
        else {
            this.setState({
                lastModifiedDate: '',
                packageItemsCount: 0,
                selectedFormat: DownloadFileFormats.length > 0 ? DownloadFileFormats[0] : "Select",
                disablebtn: true
            });
        }
    }
    
    clearPackages=()=>{
        const { myPackagesResult,clearPackages,headerType,getMyPackagesDetails,currentUser}=this.props;
        const gridData = myPackagesResult['data'];
        const section=gridData.filter(x=>x.entityType===headerType && x.status === "Open")
        clearPackages(section[0]);

        setTimeout(() => {
            getMyPackagesDetails(currentUser,true);
        }, 1000);       
    }

    handleClear  = async () => {
        const { myPackagesResult,clearPackages,headerType,getMyPackagesDetails,currentUser,handleClearPackages}=this.props;
        const gridData = myPackagesResult['data'];
        const section=gridData.filter(x=>x.entityType===headerType && x.status === "Open")
        await clearPackages(section[0]);

        await getMyPackagesDetails(currentUser,true);

        const updatedGridData = myPackagesResult['data']

        handleClearPackages(updatedGridData,headerType);
    };

    handleGenerate = async () => {
        const { myPackagesResult, headerType, generatePackage, closePackage, getMyPackagesDetails, currentUser, addPackageNote } = this.props;
        const { selectedFormat, note } = this.state;
        const gridData = myPackagesResult['data'];
        const section = gridData.filter(x => x.entityType === headerType && x.status === "Open");
        let openPackage = section[0];
        if (openPackage.packageItems.length === 0) {
            return;
        }
        openPackage.note = note;
        openPackage.downloadFileFormat = selectedFormat;
        generatePackage(openPackage);
        setTimeout(() => {
            getMyPackagesDetails(currentUser, true);
        }, 1500);  
        this.setState({
            disablebtn:true,
            note:''
        })
    };

    render() {
        const {placeholder,DownloadFileFormats} = this.props;
        const {selectedFormat,lastModifiedDate,packageItemsCount,disablebtn } = this.state;
    return (
    <>
    
    <div className="package-form">
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
        <div className="form-group col-md-2 col-sm-5 col-xs-12">
            <label className={this.viewData[LASTMODIFIED_DATE_KEY]}><b>{this.viewData[LASTMODIFIED_DATE_KEY]}:</b></label>
            <div className="value">{lastModifiedDate}</div>
        </div>
        <div className="form-group col-md-2 col-sm-5 col-xs-12">
            <label className={this.viewData[ITEM_COUNT_KEY]}><b>{this.viewData[ITEM_COUNT_KEY]}:</b></label>
            <div className="value">{packageItemsCount}</div>
        </div>
        <div className="bold-label col-md-2 col-sm-5 col-xs-12">
                                    <DropdownDataInput  
                                        label={this.viewData[DOWNLOAD_FILE_FORMAT_KEY]}
                                        fieldName={this.viewData[DOWNLOAD_FILE_FORMAT_KEY]}
                                        value={selectedFormat}
                                        changeData={this.onSaveEditableFields}
                                        options={DownloadFileFormats.map(format => ({
                                            code: format,
                                            description: format
                                        }))}
                                        allowNull={true}
                                        readOnly={false}
                                        isHidden={false}
                                         />
        </div>
        <div className="bold-label col-md-6 col-sm-5 col-xs-12">
            <TextDataInput
                placeholder={placeholder}
                label={this.viewData[NOTE_KEY]}
                fieldName={NOTE_KEY}
                changeData={this.onSaveEditableFields}
                readOnly={false}
                isHidden={false}
                isMandatory={false}
                value={this.state.note}
            />
        </div>
    </div>
    <div >
        <div  style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', marginTop: '10px' }}>
            <div className="form-group col-md-2 col-sm-6 col-xs-12">
                <button disabled={false} className="button btn-outline-defaultPrimary" onClick={this.handleClear}>{this.viewData[CLEAR_PACKAGE_BUTTON]}</button>
            </div>
            <div className="form-group col-md-3 col-sm-6 col-xs-12">
                <button disabled={disablebtn} className="button btn-defaultPrimary" onClick={this.handleGenerate}>{this.viewData[GENERATE_FILE_PACKAGE]}</button>
            </div>
        </div>
    </div>
</div>
     </>
    );
  }
}
