import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import IShortCutKeys from "../../../redux/types/IShortCutKeys";

export interface IShortcutKeyModalViewProps {
    hideModal: () => void;
};
export interface IStateProps {shortCutKeys: IShortCutKeys[]}
type IShortcutKeyModalViewConnectProps = IShortcutKeyModalViewProps & IStateProps;
const ShortcutKeyModalView: React.FC<IShortcutKeyModalViewConnectProps> = ({shortCutKeys, hideModal}) => {
    const closeShortcut = shortCutKeys.find(key => key.actionCode === "CLOSE_ACTIVE_TAB")
    const saveShortcut = shortCutKeys.find(key => key.actionCode === "SAVE_DATA")
    const saveOne = shortCutKeys.find(key => key.actionCode === "SAVE_AND_MATCH")
    const saveTwo = shortCutKeys.find(key => key.actionCode === "SAVE_MATCH_POST_NEW")
    const saveThree = shortCutKeys.find(key => key.actionCode === "SAVE_MATCH_AUTO_MERGE")
    const searchCurrentGrid = shortCutKeys.find(key => key.actionCode === "SEARCH_CURRENT_GRID_ROW")
    const deleteGridRow = shortCutKeys.find(key => key.actionCode === "DELETE_GRID_ROW")
    const addRowInGrid = shortCutKeys.find(key => key.actionCode === "ADD_NEW_IN_GRID")
    const emptyAllFieldsSearchView = shortCutKeys.find(key => key.actionCode === "EMPTY_FIELD_ON_SEARCH_GRID")


    const renderShortCutRow = (shortcut) => {
        return <tr className="tr">
                    <td className="td strong">
                    <div className="paddingRightLarge">{renderShortcutKeys(shortcut.key)}</div>
                    </td>
                    <td className="td ">
                        <div className="paddingLeftLarge">{shortcut.description}</div>
                    </td>
                </tr>
    }

    const renderShortcutKeys = (keys) => {
        if (keys) {
        const areThereMoreThanOneKey = keys?.split(',')?.length > 0;
        return areThereMoreThanOneKey ? keys.split(',').join(' + ') : keys;
        }
        return '';
    }
    return (<div className="modal-dialog modalLarge settingsModal">
                <div className={"modal-content"}>
                    <div className='modal-header' style={{
                           background: "#38429C",
                           color: "white",
                    }}>
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => hideModal()}>
                            <i className="icon ms-Icon ms-Icon--ChromeClose" style={{color: "white"}} title="Close"></i>
                        </button>
                            <h1 className="modal-title">{'Keyboard Shortcuts'}</h1> 
                    </div>
                        {/* 
                        <div className="dynamicPart"> */}
                            <div className="modal-body" >
                                <div className="row tableContainer flexWrapRow">
                                    <div className="col-md-6">
                                        <h3>{'General'}</h3>
                                        <table className="table" >
                                            <tbody className="tbody" >
                                                {closeShortcut && renderShortCutRow(closeShortcut)}
                                                {saveShortcut && renderShortCutRow(saveShortcut)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">
                                        <h3>{'Repertoire & Usage Detail Screens'}</h3>
                                        <table className="table" >
                                            <tbody className="tbody" >
                                                {deleteGridRow && renderShortCutRow(deleteGridRow)} 
                                                {addRowInGrid && renderShortCutRow(addRowInGrid)}
                                                {searchCurrentGrid && renderShortCutRow(searchCurrentGrid)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">
                                        <h3>{'Intray Works'}</h3>
                                        <table className="table" >
                                            <tbody className="tbody" >
                                                {saveOne && renderShortCutRow(saveOne)}
                                                {saveTwo && renderShortCutRow(saveTwo)}
                                                {saveThree && renderShortCutRow(saveThree)}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6">
                                        <h3>{'Search Screens'}</h3>
                                        <table className="table" >
                                            <tbody className="tbody" >
                                            {emptyAllFieldsSearchView && renderShortCutRow(emptyAllFieldsSearchView)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row flexRow flexJustifyContentRight">
                                        <div className="form-group col-sm-12 margin-topSmall alignRight" style={{width: '300px'}}>
                                            <button className="button buttonAutowidth btn-defaultSecondary margin-rightMedium" onClick={() => hideModal()}>{"Dismiss"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>)
}

export default connect((state: IAppState, props: IShortcutKeyModalViewProps) => ({
    shortCutKeys: state.repertoire.shortCutKeys,
}), null)(ShortcutKeyModalView);