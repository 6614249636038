import { IParameterSearchResult } from "./IParameterSearchResult";
import { IMatchType } from "./IMatchType";
import { SettingType } from "./SettingType";

export interface IParameterData {
    searchResult: IParameterSearchResult,
    parameterInstanceID: number,
    overrideParentValue: boolean,
    defaultValue: string,
    currentValue: string,
    matchType: IMatchType,
    parameterName: string,
    parameterType: string,
    parameterSettingType: SettingType
    parameterDescription: string,
    isDirty: boolean,
    hasError: boolean,
    errorMessage: string,
    sourceID: number,
    parameterID: number,
    productTypeID: number,
    productTypeName: string,
    isRequired: boolean
}

export class ParameterData {
    searchResult: IParameterSearchResult;
    parameterInstanceID: number;
    overrideParentValue: boolean;
    defaultValue: string;
    currentValue: string;
    matchType: IMatchType;
    parameterName: string;
    parameterType: string;
    parameterSettingType: SettingType;
    parameterDescription: string;
    isDirty: boolean;
    hasError: boolean;
    errorMessage: string;
    sourceID: number;
    parameterID: number;
    productTypeID: number;
    productTypeName: string;
    isRequired: boolean;

    constructor(arg: IParameterSearchResult) {
        this.searchResult = arg;
        this.parameterInstanceID = arg.parameterInstanceID;
        this.overrideParentValue = arg.overrideParentValue;
        this.defaultValue = arg.defaultValue;
        this.currentValue = arg.currentValue;
        this.matchType = arg.matchType;
        this.parameterName = arg.parameterName;
        this.parameterType = arg.parameterType;
        this.parameterDescription = arg.parameterDescription;
        this.isDirty = false;
        this.hasError = false;
        this.errorMessage = '';
        this.sourceID = arg.sourceID;
        this.parameterID = arg.parameterID;
        this.productTypeID = arg.productTypeID;
        this.productTypeName = arg.productTypeName;
        this.isRequired = arg.isRequired;
    }

    public static FromSearchResults(args: IParameterSearchResult[]): IParameterData[] {
        let data: IParameterData[] = [];
        if(args) {
            args.map(a => data.push(new ParameterData(a)))
        }
        return data;
    }

    public static ToSearchResults(args: IParameterData[]): IParameterSearchResult[] {
        let results: IParameterSearchResult[] = [];
        if(args){
            args.map(a => results.push(
                {
                    parameterInstanceID: a.parameterInstanceID,
                    currentValue: a.currentValue,
                    overrideParentValue: a.overrideParentValue,
                    parameterName: a.parameterName,
                    defaultValue: a.defaultValue,
                    matchType: a.matchType,
                    parameterType: a.parameterType,
                    sourceID: a.sourceID,
                    parameterID: a.parameterID,
                    productTypeID: a.productTypeID,
                    productTypeName: a.productTypeName,
                    isRequired: a.isRequired,
                    parameterDescription: a.parameterDescription,
                    parameterSettingType: a.parameterSettingType
                }))
        }
        return results;
    }
}