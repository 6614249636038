import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { showModal, showYesNoPrompt, hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addTab, setChangesMade, resetMessageBanner, updateEditableFields, setEditableFields, updateUsagePoolField, removeTab, copyExistingPool, incrementTempID, sortWeightSettingsFieldData, setValidationWarningMessage } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { deleteUsagePoolThunk, saveUsagePoolThunk, searchPoolVersionHistoryThunk, updateComponentFieldItemsThunk, updateUserPreferencesThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { IUsagePoolState, IUsagePoolStateKeys } from "../../../../redux/types/IUsagePoolState";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import { ITreeData } from "../../../types/ITreeData";
import { IUsagePoolWeightSettingsStateKeys } from "../../../types/usageTypes/IUsagePoolWeightSettings";
import PoolMaintenancePagePageScrollListener from "./PoolMaintenancePageScrollListener";
import { ITabProps } from "../../../../redux/types/ITabProps";


export default connect(
    (state: IAppState, props: ITabProps) => ({
        usagePoolMaintenanceGeneralDataViewData:
            state.repertoire.componentData.UsagePoolMaintenanceGeneralDataView,
        usagePoolMaintenanceToolbarData:
            state.repertoire.componentData.UsagePoolMaintenanceToolbar,
        usagePoolMaintenanceGridsViewData: state.repertoire.componentData.UsagePoolMaintenanceGridsView,
        weightSettingsPoolMaintenance: state.repertoire.componentData.WeightSettingsPoolMaintenance,
        usagePoolMaintenanceAllocationSettingsGridViewData: state.repertoire.componentData.UsagePoolMaintenanceAllocationSettingsGrid,
        usagePoolMaintenanceWeightSettingsGridViewData: state.repertoire.componentData.UsagePoolMaintenanceWeightSettingsGrid,
        usagePoolMaintenanceEstimatedValuePerPointSettingsGridViewData:state.repertoire.componentData.UsagePoolMaintenanceEstimatedValuePerPointSettingsGrid,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        activeTab: state.repertoire.panes[props.paneIndex].activeTab,
        tabs: state.repertoire.panes[props.paneIndex].tabs,
        activeAccordion: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].activeAccordions,
        loaded: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usagePoolMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usagePoolMaintenanceState.loaded,
        pool: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usagePoolMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usagePoolMaintenanceState.usagePool,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        lookupValues: state.lookupEntities,
        defaultActiveAccordions: state.repertoire.defaultActiveAccordions,
        allResultsPerPage: state.repertoire.resultsPerPage,
        editableFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields,
        usagePoolMaintenanceSaveResultData: state.repertoire.componentData.SaveResult,
        sources: state.repertoire.matchingSourceTreeData,
        roles: state.account.roles,
        useNewUIPoolMaintenanceGeneralView: state.repertoire.useNewUIPoolMaintenanceGeneralView,
    }),
    dispatch => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        addTab: (tab: ITabReduxItem) =>
            dispatch(addTab(tab)),
        hideModal: () => dispatch(hideModal()),
        setChangesMade: (tabIndex: number, changesMade: boolean) =>
            dispatch(setChangesMade(tabIndex, changesMade)),
        updateUsagePoolField: (value: any, fieldName: IUsagePoolStateKeys, lookups?: ILookupDictionary) =>
            dispatch(updateUsagePoolField(value, fieldName, lookups)),
        saveChanges: (pool: IUsagePoolState) =>
            dispatch<any>(saveUsagePoolThunk(pool)),
        deleteUsagePool: (poolID: number, poolCode: string, sources: ITreeData[], activeTab?: number) =>
            dispatch<any>(deleteUsagePoolThunk(poolID, poolCode, sources, activeTab)),
        copyUsagePool: (dataAction: IDataActionToolbar[]) => dispatch<any>(copyExistingPool(dataAction)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        setEditableFields: (componentFieldName: string, componentInstance: string) =>
            dispatch<any>(setEditableFields(componentFieldName, componentInstance)),
        updateEditableField: (value: any, key: string) =>
            dispatch(updateEditableFields(value, key)),
        showUpdateFieldsModal: (tittle: string, props: IShowFieldSettingViewModalProps) =>
            dispatch<any>(showUpdateFieldsModal(tittle, props)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        removeTab: (index: number) =>
            dispatch(removeTab(index)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        searchPoolVersionHistory: (poolID: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[], sources: ITreeData[]) =>
            dispatch<any>(searchPoolVersionHistoryThunk(poolID, activeTabItem, actionList, sources)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) => dispatch(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        sortWeightSettingsFieldData: (value: any, fieldName: IUsagePoolWeightSettingsStateKeys) => 
        dispatch(sortWeightSettingsFieldData(value, fieldName)),
        setValidationWarningMessage: (displayWarning: boolean) => dispatch(setValidationWarningMessage(displayWarning)),
    })
)(PoolMaintenancePagePageScrollListener);