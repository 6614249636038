import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { IDistributionState } from "../../redux/types/IDistributionState";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { DISTRIBUTION_SAVE_VALIDATION_CLAIMS_TO_NOT_SET, DISTRIBUTION_SAVE_VALIDATION_NO_POOL_ASSIGNED, DISTRIBUTION_SAVE_VALIDATION_USAGE_TO_NOT_SET, EMPTY_STRING_VALUE, DISTRIBUTION_SAVE_VALIDATION_CLAIMS_FROM_NOT_SET, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_DATE_NOT_SET, DISTRIBUTION_SAVE_VALIDATION_USAGE_FROM_NOT_SET, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_TYPE, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_STATUS, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_CAT_ONE, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_CAT_TWO, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_DESCRIPTION, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_SET_TYPE, DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_SHARE_RIGHT_TYPE, DISTRIBUTION_SAVE_VALIDATION_AMOUNT_NOT_NUMERIC, DISTRIBUTION_SAVE_VALIDATION_PERCENTAGE_NOT_100, DISTRIBUTION_SAVE_NO_STATEMENT_DESCRIPTION } from "../Consts";
import { IValidationMessage } from "../types/IValidationMessage";
import { IDistributionSaveResult } from "../types/usageTypes/IDistributionSaveResult";
import { DistributionDetailsMapper } from "./DistributionDetailsMapper";

export class DistributionValidator {

    public static validateDistribution(distribution: IDistributionState, distributionMaintenanceGeneralData: IRepertoireComponentDataItem): IValidationMessage[] {
        let errorMessages: IValidationMessage[] = [];
        if (!distribution.usageFrom)
            errorMessages.push({
                messageKey: ComponentsHelper.getFieldValueByName(
                    DISTRIBUTION_SAVE_VALIDATION_USAGE_FROM_NOT_SET,
                    distributionMaintenanceGeneralData.fields,
                    EMPTY_STRING_VALUE)
            });
                

        if (!distribution.usageTo)
            errorMessages.push({
                messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_USAGE_TO_NOT_SET,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
            });

        if (!distribution.claimsFrom)
            errorMessages.push({
                messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_CLAIMS_FROM_NOT_SET,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
            });

        if (!distribution.claimsTo)
            errorMessages.push({
                messageKey: ComponentsHelper.getFieldValueByName(
                    DISTRIBUTION_SAVE_VALIDATION_CLAIMS_TO_NOT_SET,
                    distributionMaintenanceGeneralData.fields,
                    EMPTY_STRING_VALUE)
            });

        if (!distribution.distributionDate)
            errorMessages.push({
                messageKey: ComponentsHelper.getFieldValueByName(
                    DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_DATE_NOT_SET,
                    distributionMaintenanceGeneralData.fields,
                    EMPTY_STRING_VALUE)
            });

        if ((!distribution.distributionPools || distribution.distributionPools.length == 0) && !distribution.isLivePerformancePortal)
            errorMessages.push({
                messageKey: ComponentsHelper.getFieldValueByName(
                    DISTRIBUTION_SAVE_VALIDATION_NO_POOL_ASSIGNED,
                    distributionMaintenanceGeneralData.fields,
                    EMPTY_STRING_VALUE)
            });
        
            if (!distribution.distributionType)
                errorMessages.push({
                    messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_TYPE,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
                });

            if (!distribution.distributionStatus)
                errorMessages.push({
                    messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_STATUS,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
                });

            if (!distribution.cat1)
                errorMessages.push({
                    messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_CAT_ONE,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
                });

            if (!distribution.cat2)
                errorMessages.push({
                    messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_CAT_TWO,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
                });
                if (!distribution.description)
                    errorMessages.push({
                        messageKey: ComponentsHelper.getFieldValueByName(
                            DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_DESCRIPTION,
                            distributionMaintenanceGeneralData.fields,
                            EMPTY_STRING_VALUE)
                    });
                if (!distribution.statementDescription)
                    errorMessages.push({
                        messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_NO_STATEMENT_DESCRIPTION,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
                 });
                    if (distribution.distributionPools && !distribution.distributionPools.every(pool => !isNaN(Number(pool.amountToAllocate.value)))) {
                        errorMessages.push({
                            messageKey: ComponentsHelper.getFieldValueByName(
                                DISTRIBUTION_SAVE_VALIDATION_AMOUNT_NOT_NUMERIC,
                                distributionMaintenanceGeneralData.fields,
                                EMPTY_STRING_VALUE
                            )
                        });
                    }   
            if ((!distribution?.distributionSettings?.distributionSettingsSetTypeGroup?.value ))
                errorMessages.push({
                    messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_SET_TYPE,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
                });

            if ((!distribution?.distributionSettings?.distributionSettingsShareRightType?.value))
                errorMessages.push({
                    messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_DISTRIBUTION_NO_SHARE_RIGHT_TYPE,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
                });
            
        if (distribution.subjects && distribution.subjects.length > 0 ) {
            const totalValue = distribution.subjects
                .reduce((sum, subject) => sum + (subject.value || 0), 0);

            if (totalValue !== 100) {
                errorMessages.push({
                    messageKey: ComponentsHelper.getFieldValueByName(
                        DISTRIBUTION_SAVE_VALIDATION_PERCENTAGE_NOT_100,
                        distributionMaintenanceGeneralData.fields,
                        EMPTY_STRING_VALUE)
                });
            }
         }
        return errorMessages;
    }
}