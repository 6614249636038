import * as React from "react";

export interface ITableHeaderProps {
    section: string;
    fieldName: string;
    isSortingActive: boolean;
    ascending: boolean;
    onClickHeaderSection: (section: string, ascending: boolean) => void;
    sortable?: boolean;
}

interface ITableHeaderState { }

export default class TableHeader extends React.PureComponent<
    ITableHeaderProps,
    ITableHeaderState
    > {

    sorting: boolean;

    constructor(props: ITableHeaderProps) {
        super(props);

        this.sorting = false;
    }

    onClick = () => {
        const { onClickHeaderSection, fieldName } = this.props;
        this.sorting = !this.sorting;
        onClickHeaderSection(fieldName, this.sorting);
    };

    renderSortingIcons = () => {
        const { sortable } = this.props;

        if (sortable) {
            return (
                <img
                    src="assets/sort.svg"
                    title="Sort"
                    alt="Sort Icon"
                    className="sortIcon"
                />
            );
        }

        return <div className="sortIcon" />;
    };

    render() {
        const { section, sortable } = this.props;

        return (
            <div
                className={["headerCell ", sortable && "headerCellSortable"].join(" ")}
                onClick={sortable ? () => this.onClick() : null}
            >
                {section}
                {sortable && this.renderSortingIcons()}
            </div>
        );
    }
}
