import axios from "axios";
import { IUsageGroupResult, IUsageGroupsSearchResult } from "../../types/usageTypes/IUsageGroupsSearchResult";
import { IUsagesSearchQuery } from "../../types/usageTypes/IUsagesSearchQuery";
import { IMatchWorksSearchResult } from "../../types/usageTypes/IMatchWorksSearchResult";
import { IMatchWorksSearchQuery } from "../../types/usageTypes/IMatchWorksSearchQuery";
import { Dispatch } from "redux";
import { REPERTOIRE } from "../../Consts";
import { IUsageMatch } from "../../types/usageTypes/IUsageMatch";
import { getUsageMatchesFailure, getUsageMatchesSuccess, searchUsageGroupFailure, searchUsageGroupSuccess } from "../../../redux/reducers/RepertoireReducer";
import { hideModal } from "../../../redux/reducers/ModalReducer";
import { UsageDetailsMapper } from "./UsageDetailsMapper";
import { IProductSearchQuery } from "../../types/IProductSearchQuery";
import { IProductSearchResult } from "../../types/IProductSearchResult";
import { SearchRequests } from "../SearchRequests";
import { IMatchProductsSearchQuery } from "../../../repertoire/types/usageTypes/IMatchProductsSearchQuery";
import { startCase } from "lodash";
import { ITreeData } from "../../types/ITreeData";

export class UsageSearchRequests {

    public static getUsages(
        searchQuery: IUsagesSearchQuery,
        sources: ITreeData[],
        continuationToken?: string
    ): Promise<IUsageGroupsSearchResult> {
        const {
            usageType,
            type,
            distribution,
            pool,
            fileName,
            title,
            composer,
            artist,
            sourceMajor,
            sourceMinor,
            rightShareOwner,
            allocationStatus,
            includeFeesInError,
            totalWeight,
            licenseesWorkNumber,
            matchStatus,
            hasOpenWorkflow,
            matchID,
            titleContains,
            distributionsFilter,
            sourcesFilter,
            approvalStatus,
            estimatedValue,
            estimatedFromValue,
            estimatedToValue,
            productType
        } = searchQuery;
        return new Promise<IUsageGroupsSearchResult>((resolve, reject) => {
            axios
                .post("/Search/Usages", {
                    usageType: {
                        value: usageType.replace(/\s/g, ""),
                        type: 0
                    },
                    type: {
                        value: type ? (type.split(" ")).join("") : type,
                        type: 0
                    },
                    distribution: {
                        value: distribution,
                        type: 0
                    },
                    pool: {
                        value: pool,
                        type: 0
                    },
                    fileName: {
                        value: fileName,
                        type: 0
                    },
                    title: {
                        value: title,
                        type: 0
                    },
                    composer: {
                        value: composer,
                        type: 0
                    },
                    artist: {
                        value: artist,
                        type: 0
                    },
                    sourceMajor: {
                        value: sourceMinor === '' && sourceMajor !== '' ? '' : sourceMajor,
                        type: 0
                    },
                    sourceMinor: {
                        value: sourceMinor === '' && sourceMajor !== '' ? sourceMajor : sourceMinor,
                        type: 0
                    },
                    rightShareOwner: {
                        value: rightShareOwner,
                        type: 0
                    },
                    allocationStatus: {
                        value: allocationStatus,
                        type: 0
                    },
                    includeFeesInError: {
                        value: includeFeesInError,
                        type: 2
                    },
                    totalWeight: {
                        value: totalWeight,
                        type: 0
                    },
                    licenseesWorkNumber: {
                        value: licenseesWorkNumber,
                        type: 0
                    },
                    matchStatus: {
                        value: matchStatus,
                        type: 0
                    },
                    hasOpenWorkflow: {
                        value: hasOpenWorkflow,
                        type: 2
                    },
                    matchID: {
                        value: matchID,
                        type: 0
                    },
                    distributionsFilter: {
                        value: distributionsFilter,
                        type: 0
                    },
                    titleContains: {
                        value: titleContains,
                        type: 0
                    },
                    sourcesFilter: {
                        value: sourcesFilter,
                        type: 0
                    },
                    approvalStatus: {
                        value: approvalStatus,
                        type: 0
                    },
                    continuationToken: continuationToken,
                    estimatedValue:{
                        value: estimatedValue,
                        type: 0
                    },
                    estimatedFromValue:{
                        value: estimatedFromValue,
                        type: 0
                    },
                    estimatedToValue:{
                        value: estimatedToValue,
                        type: 0
                    },
                    productType: {
                        value: productType,
                        type: 0
                    }
                })
                .then(response => {
                    const usageSearchResultItems: IUsageGroupsSearchResult = {
                        continuationToken: response.data.continuationToken,
                        usageGroups: []
                    };
                    for (let x = 0; x < response.data.usageGroups.length; x++) {

                        let usageSearchResultItem: IUsageGroupResult = {
                            id: response.data.usageGroups[x].id,
                            title: response.data.usageGroups[x].fullTitle,
                            distributionCode: response.data.usageGroups[x].distributionCode,
                            poolCode: response.data.usageGroups[x].poolCode,
                            source: (this.getSourceHierarchy(response.data.usageGroups[x].sourceMajor, response.data.usageGroups[x].sourceMinor, sources)).replace("Global/", ""),
                            matchStatus: startCase(response.data.usageGroups[x].matchStatus),
                            allocationStatus: response.data.usageGroups[x].allocations && response.data.usageGroups[x].allocations.length > 0 ?
                                response.data.usageGroups[x].allocations.some(x => x.allocationStatus === 'Allocated') ?
                                    'Allocated' : 'Not Allocated' : 'Not Allocated',
                            weight: response.data.usageGroups[x].totalWeight,
                            matches: response.data.usageGroups[x].matches,
                            sourceMajor: response.data.usageGroups[x].sourceMajor,
                            sourceMinor: response.data.usageGroups[x].sourceMinor,
                            productType: response.data.usageGroups[x].productType
                        }
                        usageSearchResultItems.usageGroups.push(usageSearchResultItem);
                    }
                    resolve(usageSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        })
    }

    private static getSourceHierarchy(sourceMajor: string, sourceMinor: string, sources: ITreeData[]) {
       return `${sourceMajor && sourceMajor}${sourceMinor && `/${sourceMinor}`}`;
    }

    public static getUsage(usageID: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/usage/id/" + usageID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    public static getWork(dataSource: string, workID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/work/" + dataSource + '/' + workID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    public static getConfigurationParameter(key: string, group: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("/Component/ConfigurationParameter/" + key + "/" + group)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    public static getWorks(
        searchQuery: IMatchWorksSearchQuery
    ): Promise<IMatchWorksSearchResult[]> {
        const {
            workIDs,
            title,
            number,
            artist,
            writers,
            societyAccountNumber,
            dataSource,
            workBatchID,
            hasOpenWorkflow
        } = searchQuery;
        return new Promise<IMatchWorksSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/works", {
                    workIDs: {
                        values: workIDs.map(id => { return { value: id, type: 2 } })
                    },
                    title: {
                        value: title,
                        type: 0
                    },
                    number: {
                        value: number,
                        type: 0
                    },
                    artist: {
                        value: artist,
                        type: 0
                    },
                    contributor: {
                        value: writers,
                        type: 0
                    },
                    societyAccountNumber: {
                        value: societyAccountNumber,
                        type: 0
                    },
                    workBatchID: {
                        value: (isNaN(parseInt(workBatchID))) ? workBatchID: parseInt(workBatchID),
                        type: 2
                    },
                    dataSource: dataSource,
                    hasOpenWorkflow: {
                        value: hasOpenWorkflow,
                        type: 2
                    },
                })
                .then(response => {
                    const workSearchResultItems: IMatchWorksSearchResult[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let workSearchResultItem: IMatchWorksSearchResult = {
                            title: [],
                            number: [],
                            writers: [],
                            publishers: [],
                            artists: [],
                            compoundType: data.medleyType ? data.medleyType : '',
                            workID: response.data[x].workID,
                            contributors: [],
                            dataSource: dataSource,
                            duration: data.duration
                        }

                        for (let x = 0; x < 3; x++) {
                            if (data.workNames[x]) {
                                workSearchResultItem.title.push(data.workNames[x].name)
                            }

                            if (data.workNumbers[x]) {
                                workSearchResultItem.number.push(data.workNumbers[x].number)
                            }

                            if (data.performers[x]) {
                                workSearchResultItem.artists.push(data.performers[x].fullName)
                            }
                        }

                        if (data.contributors) {
                            for (let x = 0; x < data.contributors.length; x++) {
                                if (data.contributors[x].contributorType === 'Writer' && workSearchResultItem.writers.length < 3) {
                                    workSearchResultItem.writers.push(data.contributors[x].fullName)
                                }
                                if (data.contributors[x].contributorType === 'Publisher' && workSearchResultItem.publishers.length < 3) {
                                    workSearchResultItem.publishers.push(data.contributors[x].fullName)
                                }
                            }
                        }

                        workSearchResultItems.push(workSearchResultItem);
                    }

                    resolve(workSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getWorksByIdList(
        searchQuery: IMatchWorksSearchQuery
    ): Promise<IMatchWorksSearchResult[]> {
        const {
            workIDs,
            title,
            number,
            artist,
            writers,
            societyAccountNumber,
            dataSource,
            workBatchID,
            hasOpenWorkflow
        } = searchQuery;
        return new Promise<IMatchWorksSearchResult[]>((resolve, reject) => {
            axios
                .post("/search/worksByIdList", {
                    workIDs: {
                        values: workIDs.map(id => { return { value: id, type: 2 } })
                    },
                    title: {
                        value: title,
                        type: 0
                    },
                    number: {
                        value: number,
                        type: 0
                    },
                    artist: {
                        value: artist,
                        type: 0
                    },
                    contributor: {
                        value: writers,
                        type: 0
                    },
                    societyAccountNumber: {
                        value: societyAccountNumber,
                        type: 0
                    },
                    workBatchID: {
                        value: workBatchID,
                        type: 2
                    },
                    dataSource: dataSource,
                    hasOpenWorkflow: {
                        value: hasOpenWorkflow,
                        type: 2
                    },
                })
                .then(response => {
                    const workSearchResultItems: IMatchWorksSearchResult[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let workSearchResultItem: IMatchWorksSearchResult = {
                            title: [],
                            number: [],
                            writers: [],
                            publishers: [],
                            artists: [],
                            contributors: [],
                            compoundType: data.medleyType ? data.medleyType : '',
                            workID: response.data[x].workID,
                            dataSource: dataSource,
                            duration: data.duration
                        }

                        for (let x = 0; x < 3; x++) {
                            if (data.workNames[x]) {
                                workSearchResultItem.title.push(data.workNames[x].name)
                            }

                            if (data.workNumbers[x]) {
                                workSearchResultItem.number.push(data.workNumbers[x].number)
                            }

                            if (data.performers[x]) {
                                workSearchResultItem.artists.push(data.performers[x].fullName)
                            }
                        }

                        if (data.contributors) {
                            for (let x = 0; x < data.contributors.length; x++) {
                                if (data.contributors[x].contributorType === 'Writer' && workSearchResultItem.writers.length < 3) {
                                    workSearchResultItem.writers.push(data.contributors[x].fullName)
                                }
                                if (data.contributors[x].contributorType === 'Publisher' && workSearchResultItem.publishers.length < 3) {
                                    workSearchResultItem.publishers.push(data.contributors[x].fullName)
                                }
                                if (data.contributors[x]) {
                                    workSearchResultItem.contributors.push(data.contributors[x])
                                }
                            }
                        }

                        workSearchResultItems.push(workSearchResultItem);
                    }

                    resolve(workSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        });
    }

    public static getProducts(
        searchQuery: IMatchProductsSearchQuery
    ): Promise<IProductSearchResult[]> {
        const {
            productIDs,
            artist,
            contributor,
            dataSource,
            number,
            productBatchID,
            title,
            productType
        } = searchQuery;
        return new Promise<IProductSearchResult[]>((resolve, reject) => {
            axios
                .post("/Search/ProductUsageMatches", {
                    productIDs: {
                        values: productIDs.map(id => { return { value: id, type: 2 } })
                    },
                    type: {
                        value: productType,
                        type: 1
                    },
                    title: {
                        value: title,
                        type: 0
                    },
                    number: {
                        value: number,
                        type: 0
                    },
                    contributor: {
                        value: contributor,
                        type: 0
                    },
                    artist: {
                        value: artist,
                        type: 0
                    },
                    productBatchID: {
                        value: (isNaN(parseInt(productBatchID))) ? productBatchID: parseInt(productBatchID),
                        type: 2
                    },
                    dataSource: dataSource
                })
                .then(response => {
                    const productSearchResultItems: IProductSearchResult[] = [];

                    for (let x = 0; x < response.data.length; x++) {
                        const data = response.data[x];

                        let productSearchResultItem: IProductSearchResult = {
                            productCoreID: response.data[x].productCoreID,
                            dataSource: dataSource,
                            type: response.data[x].productType,
                            id1: response.data[x].id1,
                            id2: response.data[x].id2 ? response.data[x].id2 : '',
                            number: [],
                            title: [],
                            source: response.data[x].source,
                            artist: [],
                            contributor: [],
                            productionType:response.data[x].productionType ? response.data[x].productionType: '' ,
                            musicDuration: response.data[x].musicDuration ? [response.data[x].musicDuration] : []
                        }

                        for (let x = 0; x < 3; x++) {
                            if (data.productNames[x]) {
                                productSearchResultItem.title.push(data.productNames[x].name)
                            }

                            if (data.productPerformers[x]) {
                                productSearchResultItem.artist.push(data.productPerformers[x].performerName)
                            }

                            if (data.productNumbers[x]) {
                                productSearchResultItem.number.push(data.productNumbers[x].number)
                            }

                            if (data.productContributors[x]) {
                                productSearchResultItem.contributor.push(data.productContributors[x].name)
                            }
                        }

                        productSearchResultItems.push(productSearchResultItem);
                    }
                    resolve(productSearchResultItems);
                })
                .catch(res => {
                    reject(res);
                });
        })
    }

    public static async searchMatchingWorks(usageGroupsSearchResult: IUsageGroupsSearchResult, searchBody: IUsagesSearchQuery, dispatch: Dispatch, modalOpen: boolean, continuationToken?: string, startIndex?: number, endIndex?: number) {

        let workIDs = new Set<number>();
        
        for (let i = 0; i < usageGroupsSearchResult.usageGroups.length; i++) {
            if (usageGroupsSearchResult.usageGroups[i].matches === null) continue;
            for (let j = 0; j < usageGroupsSearchResult.usageGroups[i].matches.length; j++) {
                workIDs.add(usageGroupsSearchResult.usageGroups[i].matches[j].id);
            }
        }
        
        if(workIDs.size > 0){
            await UsageSearchRequests.workSearchQuery([ ...workIDs ], usageGroupsSearchResult, dispatch, modalOpen, continuationToken, startIndex)
        }

    }

    public static async workSearchQuery(workIDs: number[], usageGroupsSearchResult: IUsageGroupsSearchResult, dispatch: Dispatch, modalOpen: boolean, continuationToken?: string, startIndex?: number) {
        const searchQuery: IMatchWorksSearchQuery =
        {
            workIDs: workIDs,
            title: "",
            number: "",
            artist: "",
            writers: "",
            societyAccountNumber: "",
            workBatchID: "",
            dataSource: REPERTOIRE,
            hasOpenWorkflow: false
        };

        await UsageSearchRequests.getWorksByIdList(searchQuery)
            .then((worksResult: IMatchWorksSearchResult[]) => {
                for (let i = 0; i < usageGroupsSearchResult.usageGroups.length; i++) {
                    if (usageGroupsSearchResult.usageGroups[i].matches === null) continue;
                    for (let j = 0; j < usageGroupsSearchResult.usageGroups[i].matches.length; j++) {
                        const work: IMatchWorksSearchResult = worksResult.find(y => y.workID === usageGroupsSearchResult.usageGroups[i].matches[j].id);
                        if (work !== undefined) {
                            usageGroupsSearchResult.usageGroups[i].matches[j].numbers = work.number;
                            usageGroupsSearchResult.usageGroups[i].matches[j].performers = work.artists;
                            usageGroupsSearchResult.usageGroups[i].matches[j].titles = work.title;
                            usageGroupsSearchResult.usageGroups[i].matches[j].composers = work.writers;
                        }
                    }
                }
            })
            .catch(err => {
                dispatch(hideModal());
                // Dont error usage search results if work retrieval fails
                //dispatch(getUsageMatchesFailure(err));
            });
    }


    public static async searchMatchingProducts(usageGroupsSearchResult: IUsageGroupsSearchResult, searchBody: IUsagesSearchQuery, dispatch: Dispatch, modalOpen: boolean, continuationToken?: string) {

        let productIDs = new Set<number>();
        productIDs.add(-1);

        for (let i = 0; i < usageGroupsSearchResult.usageGroups.length; i++) {
            if (usageGroupsSearchResult.usageGroups[i].matches === null) continue;
            for (let j = 0; j < usageGroupsSearchResult.usageGroups[i].matches.length; j++) {
                    productIDs.add(usageGroupsSearchResult.usageGroups[i].matches[j].id);
            }
        }

        await UsageSearchRequests.productSearchQuery([ ...productIDs ], usageGroupsSearchResult, dispatch, modalOpen, continuationToken)
    }

    public static async productSearchQuery(productIDs: number[], usageGroupsSearchResult: IUsageGroupsSearchResult, dispatch: Dispatch, modalOpen: boolean, continuationToken?: string) {
        const searchQuery: IProductSearchQuery =
            {
                productIDs: productIDs,
                productType: "",
                title: "",
                number: "",
                contributor: "",
                artist: "",
                dataSource: REPERTOIRE,
                productBatchID: ""
            };

            await UsageSearchRequests.getProducts(searchQuery)
                .then((productsResult: IProductSearchResult[]) => {
                    for (let i = 0; i < usageGroupsSearchResult.usageGroups.length; i++) {
                        if (usageGroupsSearchResult.usageGroups[i].matches === null) continue;
                        for (let j = 0; j < usageGroupsSearchResult.usageGroups[i].matches.length; j++) {
                            const product: IProductSearchResult = productsResult.find(y => y.productCoreID === usageGroupsSearchResult.usageGroups[i].matches[j].id);
                            if (product !== undefined) {
                                const matchNumbers: string[] = []
                                matchNumbers.push(product.id1);
                                usageGroupsSearchResult.usageGroups[i].matches[j].numbers = matchNumbers;
                                usageGroupsSearchResult.usageGroups[i].matches[j].titles = product.title;
                                usageGroupsSearchResult.usageGroups[i].matches[j].performers = product.artist;
                                usageGroupsSearchResult.usageGroups[i].matches[j].composers = product.contributor;
                            }
                        }
                    }
                })
                .catch(err => {
                    dispatch(hideModal());
                    // Dont error usage search results if work retrieval fails
                    //dispatch(getUsageMatchesFailure(err));
                });
    }


}
