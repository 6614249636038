import React from "react";
import { IP_AGREEMENT_ROLES_CREATION_CLASS_LOOKUP, IP_AGREEMENT_ROLE_LOOKUP } from "../../../lookup/Consts";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import DropdownDataInput from "../dropdownDataInput/DropdownDataInput";
import { IP_ROLE_STATE_KEY } from "../../Consts";
import ITooltip from "../Tooltip/IToolTip";

const IPRoleSelector = ({lookupValues, changeData, fieldValue, creationClassValue }) => {
    const [roleOptions, setRoleOptions] = React.useState([]);
    const [roleValue, setRoleValue] = React.useState('');

    React.useEffect(() => {
        getRoleOptions(creationClassValue);
    }, [creationClassValue]);

    const getRoleOptions = (opts) => {

        const rolesLookups = getLookupValues(IP_AGREEMENT_ROLE_LOOKUP, lookupValues);
        const rolesCreationClassLookups = getLookupValues(IP_AGREEMENT_ROLES_CREATION_CLASS_LOOKUP, lookupValues);

        let newRolesLookups = rolesLookups;

        if (opts && opts !== '' && opts !== 'All' && rolesCreationClassLookups?.length) {
            newRolesLookups = [];
            const filteredLinkedRoleClasses = rolesCreationClassLookups.filter(x => opts === x.code);
            rolesLookups.filter(role => { 
                let hasBeenPushedAlready = false;
                filteredLinkedRoleClasses.forEach(roleClass => {
                    if ((roleClass.description === role.code && !hasBeenPushedAlready)) {
                        newRolesLookups.push(role)
                        hasBeenPushedAlready = true;
                    }
                })
            });

            const isRolePresentINewRoles = filteredLinkedRoleClasses.filter(role => role.code === fieldValue).length > 0;
            if (!isRolePresentINewRoles) {
                changeData(null, IP_ROLE_STATE_KEY);
            }

            setRoleOptions(newRolesLookups);
            return;
        }
        setRoleOptions(rolesLookups);
    }

    const handleChange  = (value, fieldName) => {
        const fullRole = !value || value === '' ? '' : roleOptions.find(x => x.code === value);
        setRoleValue(fullRole == '' ? '' : `${fullRole?.code} - ${fullRole?.description}`)
        changeData(value, fieldName);
    }

    return (
        <ITooltip
        tooltipContent={roleValue !== null && roleValue !== undefined ? roleValue : ''}
        showTooltip={roleValue !== null && roleValue !== undefined}
    >
        <DropdownDataInput  
        label={''}
        fieldName={IP_ROLE_STATE_KEY}
        value={fieldValue}
        changeData={handleChange}
        options={roleOptions}
        allowNull={true}
        readOnly={false}
        isHidden={false}
         /> </ITooltip>
    )
}

export default IPRoleSelector;