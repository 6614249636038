import { ITabReduxItem } from "../redux/types/ITabReduxItem";
import { IDataItem } from "./types/IDataItem";
import { IMembersPortalStateKeys } from "./types/IMembersPortalStateKeys";
import { ITableActionName } from "./types/ITableAction";


export const MEMBERS_PORTAL_SIDE_MENU_DRAFT_WORKS_KEY: IMembersPortalStateKeys = "draftWorks"
export const MEMBERS_PORTAL_SIDE_MENU_REGISTERED_WORKS_KEY: IMembersPortalStateKeys = "registeredWorks"

export const MEMBERS_PORTAL_SIDE_MENU_DRAFT_SET_LISTS_KEY: IMembersPortalStateKeys = "draftSetLists"
export const MEMBERS_PORTAL_SIDE_MENU_REGISTERED_SET_LISTS_KEY: IMembersPortalStateKeys = "registeredSetLists"
export const MEMBERSPORTAL_SIDE_MENU_REGISTER_NEW_SET_LISTS_KEY: IMembersPortalStateKeys = "registerNewSetLists";

export const REPERTOIRE: string = "Repertoire";
export const INTRAY: string = "Intray";

export const MEMBERSPORTAL_PAGE_DATA_VIEW: string = "MembersPortalPageDataView";

//TabIcon
export const DRAFT_WORK_INTRAY_TAB_ICON = "M205.25,236L226,273.281h44L290.094,236H284l-18,32H230l-18-32h-6.75Zm66.024-28.234q-0.145-4.165-.146-6.256,0-1.057-.5-1.058-1.562,0-4.7.132t-4.695.133a0.487,0.487,0,0,0-.608.476q0,2.117.2,6.388t0.2,6.415a22.46,22.46,0,0,1-1.826,4.973q-1.639,3.836-2.38,5.131a1.537,1.537,0,0,1-.529.688,8.682,8.682,0,0,1-1.111-2.513q-2.726-7.248-2.725-8.6v-1.349q-0.027-1.878.106-5.5,0.132-3.729.132-5.5,0-.714-5.052-0.714a46,46,0,0,0-4.656.159,1.244,1.244,0,0,0-.581.172,1.226,1.226,0,0,0-.08.595q0,0.793.08,6.216,0.078,3.756-.027,6.19-0.053,1.3-2.539,8.411-0.873,2.539-1.085,2.54a1.537,1.537,0,0,1-.529-0.688q-0.793-1.349-2.539-5.158-1.932-4.258-1.931-5.052,0-2.117.2-6.389t0.2-6.388a0.5,0.5,0,0,0-.609-0.5q-1.535,0-4.589-.133t-4.563-.132q-0.53,0-.529.873,0,2.117-.146,6.335T223.568,214q0,1.507,10.369,22.8a1.179,1.179,0,0,0,.741.767,24.463,24.463,0,0,0,3.809.159,24.157,24.157,0,0,0,3.465-.159,1.517,1.517,0,0,0,.608-0.172,2.775,2.775,0,0,0,.371-0.542q1.03-2.38,1.481-3.65l2.936-7.433,2.883,7.353q1.35,3.44,1.508,3.73a2.01,2.01,0,0,0,.344.5,2.456,2.456,0,0,0,.635.211,21.449,21.449,0,0,0,3.518.159,24.23,24.23,0,0,0,3.756-.159,1,1,0,0,0,.5-0.238,3.815,3.815,0,0,0,.291-0.529q0.714-1.349,5.713-12.141,4.92-10.581,4.92-10.66Q271.419,211.932,271.274,207.766ZM244,245h9v9h7l-11.5,10L237,254h7v-9Z";
export const DRAFT_WORK_INTRAY_TAB_ICON_TRANSFORM = "translate(-205.25 -200.438)"
export const DRAFT_WORK_REPERTOIRE_TAB_ICON = "M171.885,81.293H129.459L108.245,44.551,129.459,7.808h42.426L193.1,44.551Zm-2.571-5h-36.56L114.475,44.63l18.279-31.661h36.56L187.593,44.63Zm2.8-41.36q-0.129-3.691-.129-5.543,0-.937-0.445-0.937-1.383,0-4.16.117t-4.16.117a0.432,0.432,0,0,0-.539.422q0,1.875.175,5.66t0.176,5.684a19.876,19.876,0,0,1-1.617,4.406q-1.453,3.4-2.109,4.547a1.363,1.363,0,0,1-.469.609,7.693,7.693,0,0,1-.985-2.227q-2.413-6.422-2.414-7.617v-1.2q-0.023-1.664.094-4.875,0.117-3.3.117-4.875,0-.633-4.476-0.633a40.791,40.791,0,0,0-4.125.141,1.116,1.116,0,0,0-.516.152,1.091,1.091,0,0,0-.07.527q0,0.7.07,5.508,0.071,3.328-.023,5.484a46.32,46.32,0,0,1-2.25,7.453,6.838,6.838,0,0,1-.961,2.25,1.348,1.348,0,0,1-.469-0.609q-0.7-1.2-2.25-4.57a19.919,19.919,0,0,1-1.711-4.477q0-1.875.176-5.66t0.176-5.66a0.44,0.44,0,0,0-.539-0.445q-1.361,0-4.067-.117t-4.043-.117q-0.47,0-.469.773,0,1.875-.128,5.613t-0.129,5.613q0,1.336,9.187,20.2a1.046,1.046,0,0,0,.656.68,21.7,21.7,0,0,0,3.375.141,21.442,21.442,0,0,0,3.071-.141,1.356,1.356,0,0,0,.539-0.152A2.494,2.494,0,0,0,147,60.7q0.914-2.109,1.312-3.234l2.6-6.586,2.555,6.516q1.2,3.047,1.336,3.3a1.774,1.774,0,0,0,.3.445,2.152,2.152,0,0,0,.563.188,19.029,19.029,0,0,0,3.117.141,21.5,21.5,0,0,0,3.328-.141,0.887,0.887,0,0,0,.445-0.211,3.145,3.145,0,0,0,.258-0.469q0.633-1.2,5.063-10.758,4.359-9.375,4.359-9.445Q172.246,38.622,172.117,34.931Z";
export const DRAFT_WORK_REPERTOIRE_TAB_ICON_TRANSFORM = "translate(-106)";
export const REGISTERED_WORKS_INTRAY_TAB_ICON = "M205.25,236L226,273.281h44L290.094,236H284l-18,32H230l-18-32h-6.75Zm66.024-28.234q-0.145-4.165-.146-6.256,0-1.057-.5-1.058-1.562,0-4.7.132t-4.695.133a0.487,0.487,0,0,0-.608.476q0,2.117.2,6.388t0.2,6.415a22.46,22.46,0,0,1-1.826,4.973q-1.639,3.836-2.38,5.131a1.537,1.537,0,0,1-.529.688,8.682,8.682,0,0,1-1.111-2.513q-2.726-7.248-2.725-8.6v-1.349q-0.027-1.878.106-5.5,0.132-3.729.132-5.5,0-.714-5.052-0.714a46,46,0,0,0-4.656.159,1.244,1.244,0,0,0-.581.172,1.226,1.226,0,0,0-.08.595q0,0.793.08,6.216,0.078,3.756-.027,6.19-0.053,1.3-2.539,8.411-0.873,2.539-1.085,2.54a1.537,1.537,0,0,1-.529-0.688q-0.793-1.349-2.539-5.158-1.932-4.258-1.931-5.052,0-2.117.2-6.389t0.2-6.388a0.5,0.5,0,0,0-.609-0.5q-1.535,0-4.589-.133t-4.563-.132q-0.53,0-.529.873,0,2.117-.146,6.335T223.568,214q0,1.507,10.369,22.8a1.179,1.179,0,0,0,.741.767,24.463,24.463,0,0,0,3.809.159,24.157,24.157,0,0,0,3.465-.159,1.517,1.517,0,0,0,.608-0.172,2.775,2.775,0,0,0,.371-0.542q1.03-2.38,1.481-3.65l2.936-7.433,2.883,7.353q1.35,3.44,1.508,3.73a2.01,2.01,0,0,0,.344.5,2.456,2.456,0,0,0,.635.211,21.449,21.449,0,0,0,3.518.159,24.23,24.23,0,0,0,3.756-.159,1,1,0,0,0,.5-0.238,3.815,3.815,0,0,0,.291-0.529q0.714-1.349,5.713-12.141,4.92-10.581,4.92-10.66Q271.419,211.932,271.274,207.766ZM244,245h9v9h7l-11.5,10L237,254h7v-9Z";
export const REGISTERED_WORKS_INTRAY_TAB_ICON_TRANSFORM = "translate(-205.25 -200.438)"
export const REGISTERED_WORKS_REPERTOIRE_TAB_ICON = "M171.885,81.293H129.459L108.245,44.551,129.459,7.808h42.426L193.1,44.551Zm-2.571-5h-36.56L114.475,44.63l18.279-31.661h36.56L187.593,44.63Zm2.8-41.36q-0.129-3.691-.129-5.543,0-.937-0.445-0.937-1.383,0-4.16.117t-4.16.117a0.432,0.432,0,0,0-.539.422q0,1.875.175,5.66t0.176,5.684a19.876,19.876,0,0,1-1.617,4.406q-1.453,3.4-2.109,4.547a1.363,1.363,0,0,1-.469.609,7.693,7.693,0,0,1-.985-2.227q-2.413-6.422-2.414-7.617v-1.2q-0.023-1.664.094-4.875,0.117-3.3.117-4.875,0-.633-4.476-0.633a40.791,40.791,0,0,0-4.125.141,1.116,1.116,0,0,0-.516.152,1.091,1.091,0,0,0-.07.527q0,0.7.07,5.508,0.071,3.328-.023,5.484a46.32,46.32,0,0,1-2.25,7.453,6.838,6.838,0,0,1-.961,2.25,1.348,1.348,0,0,1-.469-0.609q-0.7-1.2-2.25-4.57a19.919,19.919,0,0,1-1.711-4.477q0-1.875.176-5.66t0.176-5.66a0.44,0.44,0,0,0-.539-0.445q-1.361,0-4.067-.117t-4.043-.117q-0.47,0-.469.773,0,1.875-.128,5.613t-0.129,5.613q0,1.336,9.187,20.2a1.046,1.046,0,0,0,.656.68,21.7,21.7,0,0,0,3.375.141,21.442,21.442,0,0,0,3.071-.141,1.356,1.356,0,0,0,.539-0.152A2.494,2.494,0,0,0,147,60.7q0.914-2.109,1.312-3.234l2.6-6.586,2.555,6.516q1.2,3.047,1.336,3.3a1.774,1.774,0,0,0,.3.445,2.152,2.152,0,0,0,.563.188,19.029,19.029,0,0,0,3.117.141,21.5,21.5,0,0,0,3.328-.141,0.887,0.887,0,0,0,.445-0.211,3.145,3.145,0,0,0,.258-0.469q0.633-1.2,5.063-10.758,4.359-9.375,4.359-9.445Q172.246,38.622,172.117,34.931Z";
export const REGISTERED_WORKS_REPERTOIRE_TAB_ICON_TRANSFORM = "translate(-106)";
export const ALL_REGISTERED_WORKS_INTRAY_TAB_ICON = "M1022.25,236L1043,273.281h44L1107.09,236H1101l-18,32h-36l-18-32h-6.75Zm38.75,9h9v9h7l-11.5,10L1054,254h7v-9Zm16.18-25.278q-6.225-16.134-7.51-18.888a1.2,1.2,0,0,0-.91-0.866c-0.46,0-1.15.031-2.06,0.092s-1.62.092-2.09,0.092q-0.735,0-2.22-.131t-2.19-.131a1.13,1.13,0,0,0-.73,1q-0.555,1.732-6.98,19.124-5.925,16.082-5.93,16.763,0,0.762,4.02.761a44.131,44.131,0,0,0,5.98-.21q0.7-.183,1.2-2.361a7.2,7.2,0,0,1,1-2.807c0.19-.157,1.77-0.236,4.75-0.236a57.859,57.859,0,0,1,7.03.184c0.33,0.175.79,1.521,1.39,4.04a1.412,1.412,0,0,0,1.13,1.18,32.94,32.94,0,0,0,4.82.21,30.043,30.043,0,0,0,4.88-.21,0.719,0.719,0,0,0,.66-0.655q0-.71-6.24-16.947h0Zm-10.63-2.125a25.2,25.2,0,0,1,1.21,4.774,1.161,1.161,0,0,1-.05.368q-0.2.42-1.92,0.419c-1.24,0-2.13-.017-2.68-0.052-1.04-.035-1.57-0.21-1.57-0.525a26.792,26.792,0,0,1,1.42-4.8q1.3-3.856,1.86-5.011a37.3,37.3,0,0,1,1.73,4.827h0Z";
export const ALL_REGISTERED_WORKS_INTRAY_TAB_ICON_TRANSFORM = "translate(-1022.25 -199.875)";
export const ALL_REGISTERED_WORKS_REPERTOIRE_TAB_ICON = "M365.885,171.293H323.459l-21.214-36.742,21.214-36.743h42.426L387.1,134.551Zm-2.571-5h-36.56L308.475,134.63l18.279-31.661h36.56l18.279,31.661Zm-7.474-30.684q-5.554-14.415-6.7-16.875a1.077,1.077,0,0,0-.821-0.774q-0.609,0-1.839.082t-1.864.082q-0.656,0-1.98-.117t-1.957-.117a1.011,1.011,0,0,0-.656.891q-0.494,1.547-6.235,17.086-5.3,14.367-5.3,14.976,0,0.681,3.586.68a39.357,39.357,0,0,0,5.344-.188q0.633-.164,1.078-2.109a6.437,6.437,0,0,1,.891-2.508,18.542,18.542,0,0,1,4.242-.211,51.792,51.792,0,0,1,6.281.164q0.445,0.235,1.242,3.61a1.263,1.263,0,0,0,1.008,1.054,29.586,29.586,0,0,0,4.313.188,26.873,26.873,0,0,0,4.359-.188,0.641,0.641,0,0,0,.586-0.586q0-.632-5.578-15.14h0Zm-9.492-1.9a22.891,22.891,0,0,1,1.078,4.266,0.915,0.915,0,0,1-.047.328q-0.165.375-1.711,0.375-1.665,0-2.391-.047-1.406-.047-1.406-0.469a24.3,24.3,0,0,1,1.266-4.289q1.171-3.444,1.664-4.476a33.148,33.148,0,0,1,1.547,4.312h0Z";
export const ALL_REGISTERED_WORKS_REPERTOIRE_TAB_ICON_TRANSFORM = "translate(-302.25 -97.813)";

//DraftWorks
export const SEARCH_VIEW_DRAFT_WORKS: string = 'draftWorks';
export const MEMBERS_PORTAL_SIDE_MENU: string = "MembersPortalSideMenuDataView";
export const MEMBERSPORTAL_SIDE_MENU_DRAFT_WORKS_KEY: IMembersPortalStateKeys = "viewMyDraftWorks";

//Agreements
export const SEARCH_VIEW_DRAFT_AGREEMENTS: string = 'draftAgreements';
export const SEARCH_VIEW_REGISTERED_AGREEMENTS: string = 'registeredAgreements';

//DraftSetLists
export const SEARCH_VIEW_DRAFT_SET_LISTS: string = 'draftSetLists';
export const MEMBERSPORTAL_SIDE_MENU_DRAFT_SET_LISTS_KEY: IMembersPortalStateKeys = "viewMyDraftSetLists";

//RegisteredSetLists
export const SEARCH_VIEW_REGISTERED_SET_LISTS: string = 'registeredSetLists';
export const MEMBERSPORTAL_SIDE_MENU_REGISTERED_SET_LISTS_KEY: IMembersPortalStateKeys = "viewMyRegisteredSetLists";

//RegisteredWorks
export const SEARCH_VIEW_REGISTERED_WORKS: string = 'registeredWorks';
export const MEMBERSPORTAL_SIDE_MENU_REGISTERED_WORKS_KEY: IMembersPortalStateKeys = "viewMyRegisteredWorks";

//AllRegisteredWorks
export const SEARCH_VIEW_ALL_REGISTERED_WORKS: string = 'allRegisteredWorks';
export const MEMBERSPORTAL_SIDE_MENU_ALL_REGISTERED_WORKS_KEY: IMembersPortalStateKeys = "viewAllRegisteredWorks";

//Members Portal Statements
export const SEARCH_VIEW_MEMBER_UNPAID_CLAIMS: string = 'unpaidClaimsMembers';
export const SEARCH_VIEW_CLAIMS_HISTORY: string = 'claimsHistory';
export const SEARCH_VIEW_MEMBER_STATEMENTS: string = 'memberStatements';
export const MEMBERSPORTAL_SIDE_MENU_STATEMENTS_KEY: IMembersPortalStateKeys = "memberStatements";
export const MEMBERS_PORTAL_VIEW_STATEMENTS_FROM_SEARCH_TYPE: IMembersPortalStateKeys = "memberStatementViewStatementsFrom";

export const MEMBER_STATEMENTS_RESULTS_ID_STATE_KEY: IMembersPortalStateKeys = 'paymentRunCode';
export const MEMBER_STATEMENTS_RESULTS_FILES_KEY: string = 'downloadName';

export const SEARCH_VIEW_SET_LIST_WORKS: string = "setListWorks"

export const SET_LIST_MODAL_SEARCH_VIEW: string = 'setListModalSearchView';

// Roles
export const ALL_ROLES = "All";
export const VIEW_DRAFT_WORKS_ROLE = "DraftWorks.View";
export const UPDATE_DRAFT_WORKS_ROLE = "DraftWorks.Update";
export const DELETE_DRAFT_WORKS_ROLE = "DraftWorks.Delete";
export const CONFIGURE_DRAFT_WORKS_ROLE = "DraftWorks.Configure";
export const VIEW_REGISTERED_WORKS_ROLE = "RegisteredWorks.View";
export const UPDATE_REGISTERED_WORKS_ROLE = "RegisteredWorks.Update";
export const DELETE_REGISTERED_WORKS_ROLE = "RegisteredWorks.Delete";
export const CONFIGURE_REGISTERED_WORKS_ROLE = "RegisteredWorks.Configure";


// Draft Works State Keys
export const SAVE_CHANGES_STATE_KEY: IMembersPortalStateKeys = "saveChanges";

export const DRAFT_WORKS_RESULTS_TITLE_STATE_KEY: IMembersPortalStateKeys = "title";


export const DRAFT_WORKS_ID_STATE_KEY: IMembersPortalStateKeys = "workID";
export const DRAFT_WORKS_STATUS_STATE_KEY: IMembersPortalStateKeys = "status";
export const DRAFT_WORKS_DURATION_STATE_KEY: IMembersPortalStateKeys = "duration";
export const DRAFT_WORKS_GENRE_STATE_KEY: IMembersPortalStateKeys = "genre";
export const DRAFT_WORKS_LANGUAGE_STATE_KEY: IMembersPortalStateKeys = "language";
export const DRAFT_WORKS_INSTRUMENTATION_STATE_KEY: IMembersPortalStateKeys = "instrumentation";
export const DRAFT_WORKS_TITLES_STATE_KEY: IMembersPortalStateKeys = "titles";
export const DRAFT_WORKS_ARTISTS_STATE_KEY: IMembersPortalStateKeys = "artists";
export const DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY: IMembersPortalStateKeys = "isOriginalWork";
export const DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY: IMembersPortalStateKeys = "isArrangement";
export const DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY: IMembersPortalStateKeys = "isLibraryWork";
export const DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY: IMembersPortalStateKeys = "isCommissionedForCommercial";
export const DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY: IMembersPortalStateKeys = "originalTitle";
export const REGISTER_SET_LISTS_STATE_KEY: IMembersPortalStateKeys = "registerNewSetLists";
export const REGISTER_SET_LISTS_ORIGINAL_TITLE_STATE_KEY: IMembersPortalStateKeys = "originalTitle";
export const REGISTER_SET_LISTS_TITLES_STATE_KEY: IMembersPortalStateKeys = "registerNewSetLists";
export const LIVE_PERFORMANCE_SUBMISSION_STATE_KEY: IMembersPortalStateKeys = "livePerformanceSubmission";

export const DRAFT_WORKS_STATUS_SEARCH_TYPE: IMembersPortalStateKeys = "draftWorkStatus";

export const DRAFT_WORKS_STATUS_DRAFT_TYPE = "DRAFT";
export const DRAFT_WORKS_STATUS_DRAFT_SUBMITTED_TYPE = "DRAFTSUB";

export const DRAFT_SET_LISTS_STATUS_SEARCH_TYPE: IMembersPortalStateKeys = "draftSetListStatus";
export const DRAFT_SET_LISTS_STATUS_DRAFT_TYPE = "DRAFT";
export const DRAFT_SET_LISTS_STATUS_DRAFT_SUBMITTED_TYPE = "DRAFTSUB";

export const DRAFT_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY: IMembersPortalStateKeys = "contributors";
export const DRAFT_AGREEMENT_ASSIGNEE_DETAILS_STATE_KEY: IMembersPortalStateKeys = "assignees";

export const DRAFT_AGREEMENT_ASSIGNEE_VIEW = "DraftAgreementsMaintenanceAssigneeView";

export const REGISTERED_WORKS_ID_STATE_KEY: IMembersPortalStateKeys = "workID";
export const REGISTERED_WORKS_STATUS_STATE_KEY: IMembersPortalStateKeys = "status";
export const REGISTERED_WORKS_DURATION_STATE_KEY: IMembersPortalStateKeys = "duration";
export const REGISTERED_WORKS_GENRE_STATE_KEY: IMembersPortalStateKeys = "genre";
export const REGISTERED_WORKS_INSTRUMENTATION_STATE_KEY: IMembersPortalStateKeys = "instrumentation";
export const REGISTERED_WORKS_ORIGINAL_WORK_STATE_KEY: IMembersPortalStateKeys = "isOriginalWork";
export const REGISTERED_WORKS_IS_ARRANGEMENT_STATE_KEY: IMembersPortalStateKeys = "isArrangement";
export const REGISTERED_WORKS_IS_LIBRARY_MUSIC_STATE_KEY: IMembersPortalStateKeys = "isLibraryWork";
export const REGISTERED_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY: IMembersPortalStateKeys = "isCommissionedForCommercial";

export const REGISTERED_WORKS_CONTRIBUTOR_DETAILS_STATE_KEY: IMembersPortalStateKeys = "contributors";

export const DELETE_DRAFT_WORK_STATE_KEY: IMembersPortalStateKeys = "deleteDraftWork";
export const ADD_NEW_DRAFT_WORK_STATE_KEY: IMembersPortalStateKeys = "addNewDraftWork";
export const ADD_NEW_DRAFT_AGREEMENT_STATE_KEY: IMembersPortalStateKeys = "addNewDraftAgreement";
export const ADD_NEW_DRAFT_SET_LISTS_STATE_KEY: IMembersPortalStateKeys = "addNewDraftSetList";
export const UNDO_CHANGES_DRAFT_WORK_STATE_KEY: IMembersPortalStateKeys = "undoChanges";
export const COPY_DRAFT_WORK_STATE_KEY: IMembersPortalStateKeys = "copyDraftWork";
export const DELETE_REGISTERED_WORK_STATE_KEY: IMembersPortalStateKeys = "deleteRegisteredWork";
export const ADD_NEW_REGISTERED_WORK_STATE_KEY: IMembersPortalStateKeys = "addNewRegisteredWork";
export const UNDO_CHANGES_REGISTERED_WORK_STATE_KEY: IMembersPortalStateKeys = "undoChanges";
export const COPY_REGISTERED_WORK_STATE_KEY: IMembersPortalStateKeys = "copyRegisteredWork";
export const VERSION_HISTORY_STATE_KEY: IMembersPortalStateKeys = "versionHistory";
export const ADD_NEW_WORK_STATE_KEY: IMembersPortalStateKeys = "addNewWork";
export const COPY_WORK_STATE_KEY: IMembersPortalStateKeys = "copyWork";

// Save DraftWorks Validation Messages
export const DRAFTWORKS_SAVE_VALIDATION_DRAFTWORKS_TITLE_NOT_SET = 'saveValidationDraftWorksTitleNotSet'
export const DRAFTSET_LISTS_SAVE_VALIDATION_DRAFTSET_LISTS_TITLE_NOT_SET = 'saveValidationDraftSetListsTitleNotSet'
export const DRAFTLIVE_PERFORMANCE_SAVE_VALIDATION_DRAFTLIVE_PERFORMANCE_TITLE_NOT_SET = 'saveValidationDraftLivePerformanceTitleNotSet'

// Save RegisteredWorks Validation Messages
export const REGISTEREDWORKS_SAVE_VALIDATION_REGISTEREDWORKS_TITLE_NOT_SET = 'saveValidationRegisteredWorksTitleNotSet'

// Save DraftAgreements Validation Messages
export const DRAFTAGREEMENT_SAVE_VALIDATION_DRAFTAGREEMENT_WORKS_NOT_SET = 'saveValidationDraftAgreementWorksNotSet'
export const DRAFT_AGREEMENT_SAVE_VALIDATION_DRAFT_AGREEMENT_USER_NEEDS_TO_BE_RELATED_TO_AGREEMENT = 'saveValidationDraftAgreementAssigneeNeedsToBeRelatedToUser'

export const UNPAID_CLAIMS_MEMBERS_PAGE: string = "UnpaidClaimsMembersPage";
export const UNPAID_CLAIMS_REGISTERED_WORKS_SEARCH: string = "UnpaidClaimsRegisteredWorksSearch";
export const CLAIMS_HISTORY_PAGE_VIEW: string = "ClaimsHistoryPage";
export const DRAFT_SET_LISTS_PAGE_VIEW: string = "DraftSetListsPage";
export const DRAFT_SET_LISTS_MAINTENANCE_GENERAL_DATA_VIEW: string = "DraftSetListsMaintenanceGeneralDataView";
export const DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR: string = "DraftSetListsMaintenanceToolbar";
export const DRAFT_SET_LISTS_MAINTENANCE_GRIDS_VIEW: string = "DraftSetListsMaintenanceGridsView";
export const DRAFT_SET_LISTS_WORK_PROPERTIES = "draftSetListsHeaderProperties";
export const REGISTERED_SET_LISTS_PAGE_VIEW: string = "RegisteredSetListsPage";

export const DRAFT_SET_LISTS_STATE_KEY: IMembersPortalStateKeys = "draftSetLists";
export const DELETE_DRAFT_SET_LISTS_STATE_KEY: IMembersPortalStateKeys = "deleteDraftSetList";
export const UNDO_CHANGES_SET_LISTS_WORK_STATE_KEY: IMembersPortalStateKeys = "undoChanges";
export const COPY_DRAFT_SET_LISTS_STATE_KEY: IMembersPortalStateKeys = "copyDraftSetList";
export const DRAFT_SET_LISTS_ORIGINAL_TITLE_STATE_KEY: IMembersPortalStateKeys = "originalTitle";
export const DRAFT_SET_LISTS_ID: IMembersPortalStateKeys = "id";
export const DRAFT_SET_LISTS_STATUS: IMembersPortalStateKeys = "status";
export const DRAFT_SET_LISTS_DATE_CREATED: IMembersPortalStateKeys = "dateCreated";
export const DRAFT_SET_LISTS_WORK_STATE_KEY: IMembersPortalStateKeys = "productWork";

export const DRAFT_AGREEMENTS_ASSIGNEE_SEARCH_PAGE_VIEW: string = "DraftAgreementsAssigneeSearchPage";
export const DRAFT_AGREEMENTS_PAGE_VIEW: string = "DraftAgreementsPage";
export const DRAFT_AGREEMENTS_MAINTENANCE_PAGE_VIEW: string = "DraftAgreementsMaintenancePage";
export const DRAFT_AGREEMENTS_MAINTENANCE_GENERAL_DATA_VIEW: string = "DraftAgreementsMaintenanceGeneralDataView";
export const DRAFT_AGREEMENTS_MAINTENANCE_TOOLBAR: string = "DraftAgreementsMaintenanceToolbar";
export const DRAFT_AGREEMENTS_MAINTENANCE_GRIDS_VIEW: string = "DraftAgreementsMaintenanceGridsView";
export const DRAFT_AGREEMENTS_IP_NAME_NUMBER_STATE_KEY: IMembersPortalStateKeys = "ipNameNumber";
export const DRAFT_AGREEMENTS_IP_NAME_STATE_KEY: IMembersPortalStateKeys = "ipName";
export const DRAFT_AGREEMENTS_SOCIETY_AFFILIATION_STATE_KEY: IMembersPortalStateKeys = "societyAffiliation";
export const DRAFT_AGREEMENTS_TYPE_STATE_KEY: IMembersPortalStateKeys = "specificAgreementType";
export const DRAFT_AGREEMENTS_CATEGORY_STATE_KEY: IMembersPortalStateKeys = "agreementCategory";
export const DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY: IMembersPortalStateKeys = "type";
export const DRAFT_AGREEMENTS_EFFECTIVE_FROM_STATE_KEY: IMembersPortalStateKeys = "effectiveFrom";
export const DRAFT_AGREEMENTS_EFFECTIVE_TO_STATE_KEY: IMembersPortalStateKeys = "effectiveTo";
export const DRAFT_AGREEMENTS_TERRITORY_STATE_KEY: IMembersPortalStateKeys = "agreementTerritory";
export const DRAFT_AGREEMENTS_AGREEMENT_SIGNATURE_DATE_STATE_KEY: IMembersPortalStateKeys = "agreementSignatureDate";
export const DRAFT_AGREEMENTS_PUBLISHER_AGREEMENT_NUMBER_STATE_KEY: IMembersPortalStateKeys = "publisherAgreementNumber";
export const DRAFT_AGREEMENTS_STATUS_STATE_KEY: IMembersPortalStateKeys = "status";
export const DRAFT_AGREEMENTS_APPLICABLE_WORKS_TYPE_STATE_KEY: IMembersPortalStateKeys = "applicableWorksType";
export const DRAFT_AGREEMENTS_SOCIETY_AGREEMENT_NUMBER_STATE_KEY: IMembersPortalStateKeys = "societyAgreementNumber";
export const DRAFT_AGREEMENTS_CREATION_DATE_STATE_KEY: IMembersPortalStateKeys = "creationDate";
export const DRAFT_AGREEMENTS_COMMENTS_STATE_KEY: string = "draftAgreementsComments";
export const DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY: string = "draftAgreementsApplicableWorks";

export const REGISTERED_AGREEMENTS_PAGE_VIEW: string = "RegisteredAgreementsPage";
export const REGISTERED_AGREEMENTS_MAINTENANCE_PAGE_VIEW: string = "RegisteredAgreementsMaintenancePage";

export const MY_PACKAGE_PAGE: string = "MyPackagesPage"; 
export const DRAFT_WORKS_PAGE_VIEW: string = "DraftWorksPage";
export const DRAFT_WORKS_MAINTENANCE_GENERAL_DATA_VIEW: string = "DraftWorkMaintenanceGeneralDataView";
export const DRAFT_WORKS_MAINTENANCE_TOOLBAR: string = "DraftWorkMaintenanceToolbar";
export const DRAFT_WORKS_MAINTENANCE_GRIDS_VIEW: string = "DraftWorkMaintenanceGridsView";

export const DRAFT_WORKS_STATE_KEY: IMembersPortalStateKeys = "draftWorks";

export const DRAFT_WORKS_WORK_PROPERTIES = "draftWorkHeaderProperties";
export const DRAFT_WORKS_CONTRIBUTOR_DETAILS = "draftWorkHeaderContributors";
export const DRAFT_WORKS_ATTACHMENTS = "draftWorkHeaderAttachments";
export const DRAFT_WORKS_ADDITIONAL_DETAILS = "draftWorkHeaderAdditionalDetails";

export const REGISTERED_WORKS_PAGE_VIEW: string = "RegisteredWorksPage";
export const REGISTERED_WORKS_MAINTENANCE_GENERAL_DATA_VIEW: string = "RegisteredWorksMaintenanceGeneralDataView";
export const REGISTERED_WORKS_MAINTENANCE_TOOLBAR: string = "RegisteredWorksMaintenanceToolbar";
export const REGISTERED_WORKS_MAINTENANCE_GRIDS_VIEW: string = "RegisteredWorksMaintenanceGridsView";

export const REGISTERED_WORKS_STATE_KEY: IMembersPortalStateKeys = "registeredWorks";

export const SET_LISTS_WORK_SEARCHVIEW_STATE_KEY: IMembersPortalStateKeys = "productWorkSearch";

export const REGISTERED_WORKS_WORK_PROPERTIES = "registeredWorkHeaderProperties";
export const REGISTERED_WORKS_CONTRIBUTOR_DETAILS = "registeredWorkHeaderContributors";
export const REGISTERED_WORKS_ATTACHMENTS = "registeredWorkHeaderAttachments";
export const REGISTERED_WORKS_ADDITIONAL_DETAILS = "registeredWorkHeaderAdditionalDetails";


export const ALL_REGISTERED_WORKS_PAGE_VIEW: string = "AllRegisteredWorksPage";
export const ALL_REGISTERED_WORKS_MAINTENANCE_GENERAL_DATA_VIEW: string = "AllRegisteredWorksMaintenanceGeneralDataView";
export const ALL_REGISTERED_WORKS_MAINTENANCE_TOOLBAR: string = "AllRegisteredWorksMaintenanceToolbar";
export const ALL_REGISTERED_WORKS_MAINTENANCE_GRIDS_VIEW: string = "AllRegisteredWorksMaintenanceGridsView";

export const ALL_REGISTERED_WORKS_STATE_KEY: IMembersPortalStateKeys = "allRegisteredWorks";

export const ALL_REGISTERED_WORKS_WORK_PROPERTIES = "allRegisteredWorkHeaderProperties";
export const ALL_REGISTERED_WORKS_CONTRIBUTOR_DETAILS = "allRegisteredWorkHeaderContributors";
export const ALL_REGISTERED_WORKS_ATTACHMENTS = "allRegisteredWorkHeaderAttachments";
export const ALL_REGISTERED_WORKS_ADDITIONAL_DETAILS = "allRegisteredWorkHeaderAdditionalDetails";

export const LIVE_PERFORMANCE_SUBMISSION_PAGE_VIEW: string = "LivePerformanceSubmissionPage";
export const SUBMIT_NEW_LIVE_PERFORMANCE_PAGE_VIEW: string = "SubmitNewLivePerformancePage";
export const SEARCH_VIEW_LIVE_PERFORMANCE_SUBMISSION: string = 'livePerformanceSubmission';
export const MEMBERSPORTAL_SIDE_MENU_LIVE_PERFORMANCE_SUBMISSION_KEY: IMembersPortalStateKeys = "livePerformanceSubmission";
export const MEMBERSPORTAL_SIDE_MENU_SUBMIT_NEW_LIVE_PERFORMANCE_KEY: IMembersPortalStateKeys = "submitNewLivePerformance";
export const MEMBERSPORTAL_UNPAID_CLAIMS_KEY: IMembersPortalStateKeys = "unpaidClaimsMembersPage";
export const MEMBERSPORTAL_CLAIMS_HISTORY_KEY: IMembersPortalStateKeys = "claimsHistoryPage";
export const LIVE_PERFORMANCE_MAINTENANCE_GENERAL_DATA_VIEW: string = "LivePerformanceMaintenanceGeneralDataView";
export const LIVE_PERFORMANCE_MAINTENANCE_TOOLBAR: string = "LivePerformanceMaintenanceToolbar";
export const LIVE_PERFORMANCE_LOCATION_STATE_KEY: IMembersPortalStateKeys = "location"
export const LIVE_PERFORMANCE_PERFORMANCE_DATE_STATE_KEY: IMembersPortalStateKeys = "usageDate";
export const LIVE_PERFORMANCE_START_TIME_STATE_KEY: IMembersPortalStateKeys = "programStartDate";
export const LIVE_PERFORMANCE_END_TIME_STATE_KEY: IMembersPortalStateKeys = "programEndDate";
export const LIVE_PERFORMANCE_PERFORMER_STATE_KEY: IMembersPortalStateKeys = "fullPerformer";
export const LIVE_PERFORMANCE_PERFORMANCE_TYPE_STATE_KEY: IMembersPortalStateKeys = "performanceType";
export const LIVE_PERFORMANCE_STAGE_STATE_KEY: IMembersPortalStateKeys = "stage";
export const LIVE_PERFORMANCE_PROMOTER_STATE_KEY: IMembersPortalStateKeys = "promoter";
export const LIVE_PERFORMANCE_OTHER_ARTIST_PERFORM_STATE_KEY: IMembersPortalStateKeys = "otherArtistPerform";
export const LIVE_PERFORMANCE_SET_LIST_ADDITIONAL_STATE_KEY: IMembersPortalStateKeys = "setListAdditional";
export const LIVE_PERFORMANCE_VENUE_CONTACT_FIRST_NAME_STATE_KEY: IMembersPortalStateKeys = "firstName";
export const LIVE_PERFORMANCE_SET_LIST_STATE_KEY: IMembersPortalStateKeys = "selectSetList";
export const LIVE_PERFORMANCE_MATCHING_PRODUCTS_STATE_KEY: IMembersPortalStateKeys = "matchingProducts";
export const LIVE_PERFORMANCE_VENUE_CONTACT_LAST_NAME_STATE_KEY: IMembersPortalStateKeys = "lastName";
export const LIVE_PERFORMANCE_VENUE_CONTACT_PHONE_NUMBER_STATE_KEY: IMembersPortalStateKeys = "phoneNumber";
export const LIVE_PERFORMANCE_VENUE_TYPE_STATE_KEY: IMembersPortalStateKeys = "venueType";
export const LIVE_PERFORMANCE_VENUE_OTHER_VENUE_TYPE_STATE_KEY: IMembersPortalStateKeys = "otherTypeOfVenue";
export const LIVE_PERFORMANCE_STATE_KEY: IMembersPortalStateKeys = "moreDates";
export const UNDO_CHANGES_DRAFT_LIVE_PERFORMANCE_STATE_KEY: IMembersPortalStateKeys = "undoChanges";
export const ADD_NEW_DRAFT_LIVE_PERFORMANCE_STATE_KEY: IMembersPortalStateKeys = "addNewLivePerformance";

export const LIVE_PERFORMANCE_MAINTENANCE_GRIDS_VIEW: string = "LivePerformanceMaintenanceGridsView";

export const IP_SAVE_VALIDATION_IP_TITLE_NOT_SET = 'saveValidationIPTitleNotSet'
export const IP_MODAL_VIEW: string = 'ipModalView';

export const REQUEST_ACCESS_STATE_KEY: IMembersPortalStateKeys = "requestAccess";
export const SEARCH_VIEW_REQUEST_ACCESS: string = "requestAccess";
export const REQUEST_ACCESS_PAGE_VIEW: string = "RequestAccessPage";

export const VIEW_AS_STATE_KEY: IMembersPortalStateKeys = "viewAs";

export const VIEW_AS_PAGE_VIEW: string = "ViewAsPage";
export const VIEW_AS_PAGE_SEARCH_RESULTS: string = "viewAs";

export const MEMBER_MY_APPLICATIONS: string = "myApplications";
export const MEMBER_MY_CASES: string = "myCases";



export const MEMBER_STATEMENT_PAGE_VIEW: string = "MemberStatementsPage";
export const MEMBER_PAYMENT_STATEMENT_GENERAL_DATA_VIEW: string = "MemberPaymentStatementGeneralDataView";
export const MEMBER_STATEMENTS_DETAILS_PAGE_VIEW: string = "MemberStatementDetailsPage";
export const MEMBER_STATEMENT_DETAILS_GRIDS_VIEW: string = "MemberStatementDetailsGridsView";
export const MEMBER_STATEMENT_WORK_DETAILS_GRIDS_VIEW: string = "MemberStatementWorkDetailsGridsView";
export const MEMBER_STATEMENT_USAGE_DETAILS_PAGE_VIEW: string = "MemberStatementUsageDetailsPage";
export const MEMBER_STATEMENT_USAGE_DETAILS_GRIDS_VIEW: string = "MemberStatementUsageDetailsGridsView";

export const EMPTY_STRING_VALUE = "";
export const GENERIC_ERROR: string = "genericError";
export const LANGUAGE_OBJECT_KEY = "language";

export const TOOLBAR_POSITION_BREAKPOINT = 105;
export const EVENT_HANDLER_THROTTLE_TIME = 100;


// Table Actions
export const OPEN_ACTION: ITableActionName = "open_action";
export const ADD_NEW_ACTION: ITableActionName = "addNew_action";
export const SEARCH_ACTION: ITableActionName = "search_action";
export const REMOVE_ACTION: ITableActionName = "remove_action";
export const UPDATE_ACTION: ITableActionName = "update_action";
export const DEPENDENT_ROW_ACTION: ITableActionName = "dependentRow_action";
export const CODE_ACTION: ITableActionName = "code_action";
export const GROUPED_ACTION: ITableActionName = "grouped_action";
export const REQUEST_ACTION: ITableActionName = "request_action";
export const SELECT_ACTION: ITableActionName = "select_action";
export const ADD_COVER_VERSION_ACTION: ITableActionName = "addCoverVersion_action";
export const ADD_NEW_DATE_ACTION: ITableActionName = "addNewDate_action";


export const PAYMENTRUN_MAINTENANCE_TOOLBAR: string = "PaymentRunMaintenanceToolbar";

export const COPY_METHOD_TOOLBAR = "Copy"
export const UNDO_METHOD_TOOLBAR = "Undo"
export const ADD_METHOD_TOOLBAR = "Add"
export const SAVE_METHOD_TOOLBAR = "Save"
export const HISTORY_METHOD_TOOLBAR = "History"
export const DELETE_METHOD_TOOLBAR = "Delete"
export const EXPORT_METHOD_TOOLBAR = "Export"
export const IMPORT_METHOD_TOOLBAR = "Import"

export const FOOTER_TITLE = "title";
export const FOOTER_ABOUT = "about";
export const FOOTER_DISCLAIMER = "disclaimer";
export const FOOTER_CONTACT = "contact";
export const FOOTER_CUSTOMER_NAME = "customerName";
export const FOOTER_ABOUT_LINK = "aboutLink";
export const FOOTER_DISCLAIMER_LINK = "disclaimerLink";
export const FOOTER_CONTACT_LINK = "contactLink";
export const FOOTER_CUSTOMER_LINK = "customerLink";
export const FOOTER_COPYRIGHT = "copyRight";
export const FOOTER_POWEREDBY_TEXT = "poweredByText";

export const DRAFT_WORKS_PAGE: ITabReduxItem = {
    title: "View My Draft Works",
    component: "DraftWorksPage",
    tabTitle: "View My Draft Works"
}

export const DRAFT_AGREEMENTS_PAGE: ITabReduxItem = {
    title: "View My Draft Agreements",
    component: "DraftAgreementsPage",
    tabTitle: "View My Draft Agreements"
}

export const REGISTERED_AGREEMENTS_PAGE: ITabReduxItem = {
    title: "View My Registered Agreements",
    component: "RegisteredAgreementsPage",
    tabTitle: "View My Registered Agreements"
}

export const DRAFT_WORKS_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Draft Works",
    component: "DraftWorksMaintenancePage",
    tabTitle: "Draft Works PT"
}

export const DRAFT_AGREEMENTS_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Draft Agreements",
    component: "DraftAgreementsMaintenancePage",
    tabTitle: "Draft Agreements PT"
}

export const REGISTERED_WORKS_VIEW_PAGE: ITabReduxItem = {
    title: "Registered View Page",
    component: "RegisteredWorksViewPage",
    tabTitle: "Registered Works View"
}

export const REGISTERED_WORKS_PAGE: ITabReduxItem = {
    title: "View My Registered Works",
    component: "RegisteredWorksPage",
    tabTitle: "View My Registered Works"
}

export const ALL_REGISTERED_WORKS_PAGE: ITabReduxItem = {
    title: "View All Registered Works",
    component: "AllRegisteredWorksPage",
    tabTitle: "View All Registered Works"
}

export const REGISTERED_WORKS_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Registered Works",
    component: "RegisteredWorksMaintenancePage",
    tabTitle: "Registered Works PT"
}

export const MEMBER_STATEMENTS_PAGE: ITabReduxItem = {
    title: "Statements",
    component: "MemberStatementsPage",
    tabTitle: "Statements"
}

export const MEMBER_STATEMENTS_DETAILS_PAGE: ITabReduxItem = {
    title: "Payment",
    component: "MemberStatementDetailsPage",
    tabTitle: "Payment Details"
}

export const DRAFT_SET_LISTS_PAGE: ITabReduxItem = {
    title: "View My Draft Set Lists",
    component: "DraftSetListsPage",
    tabTitle: "View My Draft Set Lists"
}

export const UNPAID_CLAIMS_PAGE: ITabReduxItem = {
    title: "View My Unpaid Claims",
    component: "UnpaidClaimsMembersPage",
    tabTitle: "Unpaid Claims"
}

export const CLAIMS_HISTORY_PAGE: ITabReduxItem = {
    title: "View My Claims History",
    component: "ClaimsHistoryPage",
    tabTitle: "Claims History"
}

export const REGISTERED_SET_LISTS_PAGE: ITabReduxItem = {
    title: "View My Registered Set Lists",
    component: "RegisteredSetListsPage",
    tabTitle: "View My Registered Set Lists"
}

export const MEMBER_STATEMENTS_USAGE_DETAILS_PAGE: ITabReduxItem = {
    title: 'Usage Details',
    component: "MemberStatementUsageDetailsPage",
    tabTitle: "Usage Details"
}

export const DRAFT_SET_LISTS_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Draft Set lists",
    component: "DraftSetListsMaintenancePage",
    tabTitle: "Draft Set lists PT"
}

export const LIVE_PERFORMANCE_SUBMISSION_PAGE: ITabReduxItem = {
    title: "View My Live Performance Submissions",
    component: "LivePerformanceSubmissionPage",
    tabTitle: "View My Live Performance Submissions"
}

export const SUBMIT_NEW_LIVE_PERFORMANCE_PAGE: ITabReduxItem = {
    title: "Submit New Live Performance",
    component: "SubmitNewLivePerformancePage",
    tabTitle: "Submit New Live Performance"
}
export const LIVE_PERFORMANCE_MAINTENANCE_PAGE: ITabReduxItem = {
    title: "Submit New Live Performance",
    component: "LivePerformanceMaintenancePage",
    tabTitle: "Submit New Live Performance PT"
}
