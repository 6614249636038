import React from "react";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import { IInstrumentation } from "../../../repertoire/types/IInstrumentation";
import { IArtist } from "../../../repertoire/types/IArtist";
import { IContributorItem } from "../../../repertoire/types/IContributorItem";
import { ITitle } from "../../../repertoire/types/ITitle";
import DisplayTableDataCells from "./DisplayTableDataCells";
import { IProductWorkDataItems } from "../../../repertoire/types/IProductWorkDataItems";
import { ILookupOptionsAndOnChangeFunctions, IRowActions } from "./DisplayTable";
import { IUnpaidClaimsSearchResult } from "../../types/IUnpaidClaimsSearchResult";
import { DEPENDENT_ROW_ACTION } from "../../Consts";
import { isTableAction } from "../../types/ITableAction";
import { IAgreementWorkDataItems } from "../../../repertoire/types/IAgreementWorkDataItems";

export interface IDisplayTableRowProps {
    tableContentItem: IInstrumentation | IArtist | IContributorItem | ITitle | IUnpaidClaimsSearchResult;
    index: number;
    tableData: IRepertoireField[];
    showRowNumber?: boolean;
    isExpandable?: boolean;
    rowActions?: IRowActions;
    removeRowAction?: (item: IProductWorkDataItems | IInstrumentation | IArtist | IContributorItem | ITitle | IUnpaidClaimsSearchResult | IAgreementWorkDataItems) => void;
    lookupOptionsAndOnChangeFunctions?: ILookupOptionsAndOnChangeFunctions;
    isSearchResultTable?: boolean;
    isRenderLinkTitle?: boolean;
    getAgreementDetails?: (dataSource: string, agreementID) => void;
}
const OPEN_NEW_TAB_CSS_CLASS = 'icon ms-Icon ms-Icon--OpenInNewTab'
const DisplayTableRow: React.FC<IDisplayTableRowProps> = ({
    tableContentItem,
    index,
    tableData,
    showRowNumber,
    isExpandable,
    rowActions,
    removeRowAction,
    lookupOptionsAndOnChangeFunctions,
    isSearchResultTable,
    isRenderLinkTitle,
    getAgreementDetails,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const onExpandClicked = () => {
        setIsExpanded(!isExpanded);
    }
    const tableDataCells: JSX.Element[] = []; 
    if (isExpandable) {
        tableDataCells.push(
            <td className="td">
                <i
                    title="Expand row"
                    className={["icon ms-Icon ms-Icon--Chevron", isExpanded ? "Down" : "Right"].join("")}
                    onClick={onExpandClicked}
                />
            </td>
        );
    }
    const isIconButtonThatNeedsToRenderAsALink = (cellData) => {
        return isTableAction(cellData.fieldType) && cellData.fieldType === DEPENDENT_ROW_ACTION && rowActions && rowActions.iconButton && rowActions.iconButton.onClick && rowActions.iconButton.icon === OPEN_NEW_TAB_CSS_CLASS;
    }
    const isRenderLink = isRenderLinkTitle || tableData.filter(item => isIconButtonThatNeedsToRenderAsALink(item))?.length === 1;
    if (showRowNumber) {
        tableDataCells.splice(0, 0, <td key={`${index} RowNumber`} title="Row Number"><div className={"tableCell"}>{index + 1}</div></td>);
    } 
    tableData.map((field) => {
            tableDataCells.push(
                <DisplayTableDataCells 
                    cellData={field} 
                    tableContentItem={tableContentItem} 
                    isExpanded={isExpanded} 
                    rowActions={rowActions} 
                    removeRowAction={removeRowAction}
                    lookupOptionsAndOnChangeFunctions={lookupOptionsAndOnChangeFunctions}
                    rowIndex={index}
                    isSearchResultTable={isSearchResultTable}
                    isLinkTitle={isRenderLink}
                    getAgreementDetails={getAgreementDetails}
                />
            );
        })
    return <tr key={`row ${index}`}  className="tr tableRow">{tableDataCells}</tr>;
}

export default DisplayTableRow;