import { IAdjustmentState } from "../../redux/types/IAdjustmentState";
import { IAdjustment } from "../types/usageTypes/IAdjustment";

export class AdjustmentDetailsMapper {
    public static mapAdjustmentToState(adjustment: IAdjustment): IAdjustmentState {
        if (!adjustment) return null;
        const {
            id,
            adjustmentDate,
            adjustmentCategory,
            adjustmentDistribution,
            adjustmentPool,
            adjustmentSegment,
            adjustmentStatus,
            sourceDistribution,
            sourcePool,
            sourceSegment,
            narrative,
            dataSource,
            versionNumber,
            adjustmentDetails,
            adjustmentBatchID
        } = adjustment

        const state: IAdjustmentState = {
            id,
            adjustmentDate,
            adjustmentCategory,
            adjustmentDistribution,
            adjustmentPool,
            adjustmentSegment,
            adjustmentStatus,
            sourceDistribution,
            sourcePool,
            sourceSegment,
            narrative,
            dataSource,
            versionNumber,
            adjustmentDetails,
            batchId: adjustmentBatchID
        }
        return state;
    }

    public static mapStateToAdjustment(state: IAdjustmentState): IAdjustment {
        if (state) {
            const {
                id,
                adjustmentCategory,
                adjustmentDate,
                adjustmentDistribution,
                adjustmentPool,
                adjustmentSegment,
                adjustmentStatus,
                sourceDistribution,
                sourcePool,
                sourceSegment,
                narrative,
                dataSource,
                versionNumber,
                adjustmentDetails,
                batchId
            } = state;

            const adjustment: IAdjustment = {
                id,
                adjustmentCategory,
                adjustmentDate,
                adjustmentDistribution,
                adjustmentPool,
                adjustmentSegment,
                adjustmentStatus,
                sourceDistribution,
                sourcePool,
                sourceSegment,
                narrative,
                dataSource,
                versionNumber,
                adjustmentDetails,
                adjustmentBatchID: batchId
            }
            return adjustment;
        }
        else return null;
    }
}