import React, { Component } from "react";
import { NavItem, NavLink } from 'reactstrap';
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { IWorkState } from "../../../redux/types/IWorkState";
import { ADD_METHOD_TOOLBAR, COPY_METHOD_TOOLBAR, DELETE_METHOD_TOOLBAR, DRAFT_WORKS_MAINTENANCE_TOOLBAR, EXPORT_METHOD_TOOLBAR, HISTORY_METHOD_TOOLBAR, IMPORT_METHOD_TOOLBAR, PAYMENTRUN_MAINTENANCE_TOOLBAR, SAVE_METHOD_TOOLBAR, TOOLBAR_POSITION_BREAKPOINT, UNDO_METHOD_TOOLBAR, ADD_NEW_WORK_STATE_KEY, COPY_WORK_STATE_KEY, ADD_NEW_DRAFT_WORK_STATE_KEY, COPY_DRAFT_WORK_STATE_KEY, ADD_NEW_DRAFT_SET_LISTS_STATE_KEY, COPY_DRAFT_SET_LISTS_STATE_KEY, DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR, ADD_NEW_DRAFT_LIVE_PERFORMANCE_STATE_KEY, DRAFT_AGREEMENTS_MAINTENANCE_TOOLBAR, ADD_NEW_DRAFT_AGREEMENT_STATE_KEY } from "../../Consts";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";
import { IAgreementState } from "../../../redux/types/IAgreementState";
import { IDistributionState } from "../../../redux/types/IDistributionState";
import { SaveOption } from "../../types/SaveOption";
import { If } from "../../../core/components/If";
import { FormatFields } from "../../../redux/types/FormatFields";
import { IArtistState } from "../../../redux/types/IArtistState";
import { IUsagePoolState } from "../../../redux/types/IUsagePoolState";
import { filter } from "lodash/fp";
import { IClaimState } from "../../../redux/types/IClaimState";
import { IAdjustmentState } from "../../../redux/types/IAdjustmentState";
import { IPaymentRunState } from "../../../redux/types/IPaymentRunState";
import { clearModalSearchResults } from "../../../redux/reducers/MembersPortalReducer";
import IMembersPortalComponentDataItem from "../../../redux/types/IMembersPortalComponentDataItem";
import IconTextButton from "../../../repertoire/components/iconTextButton/IconTextButton";
import { MessageBanner, MessageType } from "../../../repertoire/components/messageBanner/MessageBanner";
import { WorkflowBanner } from "../../../repertoire/components/workflowBanner/WorkflowBanner";
import { COPY_ICON, UNDO_ICON, ALLOCATE_METHOD_TOOLBAR, ALLOCATE_ICON, UNDO_ALLOCATION_METHOD_TOOLBAR, UNDO_ALLOCATION_ICON, MERGE_METHOD_TOOLBAR, MERGE_ICON, DELETE_ICON, NEW_ICON, ADD_REPRESENTATION_TOOLBAR, VERSION_HISTORY_ICON, MATCH_METHOD_TOOLBAR, AUTO_MATCH_ICON, ASSING_TO_METHOD_TOOLBAR, ASSING_TO_ICON, UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR, UPDATE_STATUS_ICON, DESELECT_ALL_METHOD_TOOLBAR, SELECTED_ICON, START_WORKFLOW_SESSION_METHOD_TOOLBAR, START_SESSION, RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR, WORK_MAINTENANCE_TOOLBAR, DISTRIBUTION_MAINTENANCE_TOOLBAR, UNDO_ALLOCATION_STATUS_VALUE, ALLOCATE_STATUS_VALUE, DISTRIBUTION_EXPORT_STATE_KEY, DISTRIBUTION_IMPORT_STATE_KEY, COPY_DISTRIBUTION_STATE_KEY, DISTRIBUTION_RESET_CACHE_STATE_KEY, PRODUCT_MAINTENANCE_TOOLBAR, ADJUSTMENT_MAINTENANCE_TOOLBAR, WORKFLOW_PAGE_TOOLBAR } from "../../../repertoire/Consts";
import { IValidationMessage } from "../../../repertoire/types/IValidationMessage";
import SaveResult from "../../../repertoire/components/saveResult/SaveResult";
import { DataSource } from "../../../repertoire/types/DataSource";
import { IRegisteredWorkState } from "../../../redux/types/IRegisteredWorkState";
import { ILicenseRequestItem } from "../../../redux/types/ILicenseRequestItem";
import { IUsageGroup } from "../../../repertoire/types/usageTypes/IUsageGroup";
import { IUsageGroupState } from "../../../redux/types/IUsageGroupState";

export interface IToolbarComponentProps {
    changesMade?: boolean;
    showYesNoPrompt?: typeof showYesNoPrompt;
    hideModal?: typeof hideModal;
    resetMessageBanner?: () => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    scroll?: number;
    toolbarWidth: number;
    toolbarData?: IMembersPortalComponentDataItem;
    saveResultData: IMembersPortalComponentDataItem;
    work?: IWorkState;
    agreement?: IAgreementState;
    distribution?: IDistributionState;
    paymentRun?: IPaymentRunState;
    artist?: IArtistState;
    activeTab: number;
    lookupValues?: ILookupDictionary;
    tabs: ITabReduxItem[];
    dataSource?: DataSource;
    isReadonly?: boolean;
    dataActionToolbar: IDataActionToolbar[];
    component: string;
    isNew?: boolean;
    workflowFieldsData?: IMembersPortalComponentDataItem;
    cancelWorkflowSession?: (activeTab: number) => void;
    currentWorkflowIndex?: number;
    dataActionList?: IDataActionToolbar[];
    workFormats?: FormatFields[];
    workMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    pool?: IUsagePoolState;
    refreshWorkDetails?: () => void;
    refreshProductDetails?: () => void;
    adjustment?: IAdjustmentState;
    claim?: IClaimState;
    allocationJobInProgress?: boolean;
    roles?: string[];
    secondaryActions?: IDataActionToolbar[];
    showSecondaryActions?: Boolean;
    IsResetCacheEnabled?: Boolean;
    license?: ILicenseRequestItem;
    usage?:IUsageGroupState;

    registeredWork?: IRegisteredWorkState;
}

export interface IToolbarState {
    loaded: boolean;
}

class ToolbarComponent extends Component<
    IToolbarComponentProps,
    IToolbarState
>{
    actionData;

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        this.actionData = {};
    }

    componentDidMount() {
        const {
            dataActionToolbar,
            tabs,
            activeTab,
            toolbarData: { fields }
        } = this.props;
        const { loaded } = this.state;

        dataActionToolbar.forEach(item => {
            let itemName = item.method;
            let itemData = this.getToolbarActionData(item);
            this.actionData[itemName] = itemData;
        });

        if (!loaded) this.checkIfLoaded();
    }

    componentDidUpdate() {
        const { loaded } = this.state;
        if (!loaded) this.checkIfLoaded();
    }

    checkIfLoaded = () => {
        const {
            dataActionToolbar,
            component,
            isNew
        } = this.props;

        let dataActionLength = 0;

        dataActionLength = dataActionToolbar.length

        if (Object.keys(this.actionData).length >= dataActionLength)
            this.setState({ loaded: true });
    };

    getToolbarStyleClasses = () => {
        const { scroll } = this.props;
        return scroll > TOOLBAR_POSITION_BREAKPOINT
            ? "container fixedToolbar" : "container";
    };

    getToolbarActionData = (dataActionToolbar: IDataActionToolbar) => {

        let iconPath = "";

        switch (dataActionToolbar.method) {
            case COPY_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(COPY_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case UNDO_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(UNDO_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case ALLOCATE_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(ALLOCATE_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case UNDO_ALLOCATION_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(UNDO_ALLOCATION_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case MERGE_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(MERGE_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case DELETE_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(DELETE_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case ADD_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(NEW_ICON);
                const addNew = () => {
                    dataActionToolbar.action(this.props.lookupValues);
                }
                return {
                    action: addNew,
                    iconPath
                };
            case ADD_REPRESENTATION_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(NEW_ICON);
                const addNewRep = () => {
                    dataActionToolbar.action(this.props.lookupValues);
                }
                return {
                    action: addNewRep,
                    iconPath
                };
            case HISTORY_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(VERSION_HISTORY_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case MATCH_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(AUTO_MATCH_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case ASSING_TO_METHOD_TOOLBAR: {
                iconPath = this.getToolbarActionIconPath(ASSING_TO_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            }
            case UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR: {
                iconPath = this.getToolbarActionIconPath(UPDATE_STATUS_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            }
            case DESELECT_ALL_METHOD_TOOLBAR: {
                iconPath = this.getToolbarActionIconPath(SELECTED_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            }
            case START_WORKFLOW_SESSION_METHOD_TOOLBAR: {
                iconPath = this.getToolbarActionIconPath(START_SESSION);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            }
            case RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR:
                return {
                    action: dataActionToolbar.action,
                    iconPath: ''
                };
            default:
                return {};
        }
    };

    getToolbarActionIconPath = (iconName: string) => {
        switch (iconName) {
            case "copy": return "icon ms-Icon ms-Icon--Copy";
            case "undo": return "icon ms-Icon ms-Icon--Undo";
            case "merge": return "icon ms-Icon ms-Icon--BranchMerge";
            case "delete": return "icon ms-Icon ms-Icon--Delete";
            case "addNew": return "icon ms-Icon ms-Icon--CircleAddition";
            case "versionHistory": return "icon ms-Icon ms-Icon--History";
            case "automatch": return "icon ms-Icon ms-Icon--TriggerAuto";
            case "assingTo": return "icon ms-Icon ms-Icon--FollowUser";
            case "updateStatus": return "icon ms-Icon ms-Icon--ProgressLoopOuter";
            case "deselect": return "icon ms-Icon ms-Icon--Cancel";
            case "startSession": return "icon ms-Icon ms-Icon--Flow";
            case "allocate": return "icon ms-Icon ms-Icon--DependencyAdd";
            case "undoAllocation": return "icon ms-Icon ms-Icon--DependencyRemove";
            default: return "";
        }
    };

    saveMethod = (saveOption: SaveOption) => {
        const { dataActionToolbar } = this.props;
        const filteredDataActionToolbar = dataActionToolbar.find(
            action =>
                action.method === SAVE_METHOD_TOOLBAR
        );
        filteredDataActionToolbar.action(saveOption);
    }

    renderToolbarButtons = () => {
        const {
            isReadonly,
            dataActionToolbar,
            dataSource,
            component,
            isNew,
            distribution,
            paymentRun,
            secondaryActions,
            IsResetCacheEnabled,
            work,
            tabs,
            activeTab,
            toolbarData: { fields }
        } = this.props;
        let filteredDataActionToolbar = dataActionToolbar.filter(
            action => action.method !== SAVE_METHOD_TOOLBAR            
        );
        
        if (tabs[activeTab].registeredWorkMaintenanceState != null) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                    action.method !== UNDO_METHOD_TOOLBAR);
            filteredDataActionToolbar = filteredDataActionToolbar.map(
                action => {
                    if (action.method === ADD_METHOD_TOOLBAR && fields.find(f => f.name === ADD_NEW_WORK_STATE_KEY)) {
                        action.name = fields.find(f => f.name === ADD_NEW_WORK_STATE_KEY).data
                    } 
                    if (action.method === COPY_METHOD_TOOLBAR && fields.find(f => f.name === COPY_WORK_STATE_KEY)) {
                        action.name = fields.find(f => f.name === COPY_WORK_STATE_KEY).data;
                    } 
                    return action;
                }

            )
        } else {
            filteredDataActionToolbar = filteredDataActionToolbar.map(
                action => {
                    if(component===DRAFT_WORKS_MAINTENANCE_TOOLBAR){
                        if (action.method === ADD_METHOD_TOOLBAR)  {
                            action.name = fields.find(f => f.name === ADD_NEW_DRAFT_WORK_STATE_KEY).data
                        }
                        if (action.method === COPY_METHOD_TOOLBAR) {
                            action.name = fields.find(f => f.name === COPY_DRAFT_WORK_STATE_KEY).data;
                        }
                    } 
                    else if(component===DRAFT_AGREEMENTS_MAINTENANCE_TOOLBAR){
                        if (action.method === ADD_METHOD_TOOLBAR)  {
                            action.name = fields.find(f => f.name === ADD_NEW_DRAFT_AGREEMENT_STATE_KEY).data
                        }
                        // if (action.method === COPY_METHOD_TOOLBAR) {
                        //     action.name = fields.find(f => f.name === COPY_DRAFT_WORK_STATE_KEY).data;
                        // }
                    } 
                    else if(component===DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR){
                        if (action.method === ADD_METHOD_TOOLBAR)  {
                            action.name = fields.find(f => f.name === ADD_NEW_DRAFT_SET_LISTS_STATE_KEY).data
                        }
                        if (action.method === COPY_METHOD_TOOLBAR) {
                            action.name = fields.find(f => f.name === COPY_DRAFT_SET_LISTS_STATE_KEY).data;
                        }
                    }
                      else{
                        if (action.method === ADD_METHOD_TOOLBAR)  {
                            action.name = fields.find(f => f.name === ADD_NEW_DRAFT_LIVE_PERFORMANCE_STATE_KEY).data
                        }
                    }               
                   
                    return action;
                }

            )
        }
        
        if (isNew === true) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(action =>
                action.method !== DELETE_METHOD_TOOLBAR
            )
        }

        if (component === WORK_MAINTENANCE_TOOLBAR && isNew === true) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                    action.method !== MATCH_METHOD_TOOLBAR && action.method !== MERGE_METHOD_TOOLBAR
            )
        }

        if (component === PAYMENTRUN_MAINTENANCE_TOOLBAR) {
            if (paymentRun.status === "Open") {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === DELETE_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
            else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === DELETE_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (paymentRun.showStatusChangeWarning) {
                this.renderWarningMessages()
            }
        }

        if (component === DISTRIBUTION_MAINTENANCE_TOOLBAR) {
            let selectedDistributionPools = (distribution === undefined || distribution.distributionPools === null) ? [] : distribution.distributionPools.filter(
                distributionPool =>
                    distributionPool.selected.value !== false
            )

            if (selectedDistributionPools.length > 0 && selectedDistributionPools.every(element => element.status.value === UNDO_ALLOCATION_STATUS_VALUE)) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === ALLOCATE_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
            else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === ALLOCATE_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (selectedDistributionPools.length > 0 && selectedDistributionPools.every(element => element.status.value === ALLOCATE_STATUS_VALUE)) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === UNDO_ALLOCATION_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
            else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === UNDO_ALLOCATION_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (isNew == true) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === EXPORT_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (distribution.distributionStatus === "Open" && IsResetCacheEnabled) {
                secondaryActions.map(
                    action => {
                        if (action.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            } else {
                secondaryActions.map(
                    action => {
                        if (action.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
        }

        return filteredDataActionToolbar.map(item =>
            <div className="toolbarButton" key={item.method}>
                <div className={(item.isReadOnly) ? "toolbarButtonContainer disabled" : "toolbarButtonContainer"}>
                    <IconTextButton
                        text={item.name}
                        icon={item.icon}
                        onClick={item.action}
                        disabled={item.isReadOnly}
                    />
                </div>
            </div>
        )
    };
    renderSecondaryActions = () => {
        const { tabs, activeTab, showSecondaryActions, toolbarData: { fields }, secondaryActions } = this.props;
        const contextMenuDisplay = !tabs[activeTab].title.includes("Find") ? 'contextualMenu show' : 'contextualMenu'
        return (
            <>
                {showSecondaryActions ?
                    <div className={contextMenuDisplay} id="containerContextualMenu">
                        <div className="toolbarWrapper">
                            <div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === EXPORT_METHOD_TOOLBAR)[0].action}>
                                        <i className="icon ms-Icon ms-Icon--Export" aria-hidden="true"></i>
                                        {fields.find(d => d.name === DISTRIBUTION_EXPORT_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>
                            <div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === IMPORT_METHOD_TOOLBAR)[0].action}>
                                        <i className="icon ms-Icon ms-Icon--Import" aria-hidden="true"></i>
                                        {fields.find(d => d.name === DISTRIBUTION_IMPORT_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>
                            <div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === COPY_METHOD_TOOLBAR)[0].action}>
                                        <i className="icon ms-Icon ms-Icon--Reply" aria-hidden="true"></i>
                                        {fields.find(d => d.name === COPY_DISTRIBUTION_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>
                            <div className={"toolbarButton"}>
                                <div className={secondaryActions.filter(x => x.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR)[0].isReadOnly ? "toolbarButtonContainer" : "toolbarButtonContainer disabled"}>
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR)[0].isReadOnly ? secondaryActions.filter(x => x.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR)[0].action : null}>
                                        <i className="" aria-hidden="true"></i>
                                        {fields.find(d => d.name === DISTRIBUTION_RESET_CACHE_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
            </>
        )
    }

    renderSaveButton = () => {
        const {
            changesMade,
            dataActionToolbar,
            isReadonly,
            component,
            isNew
        } = this.props;
        const lastIndex = dataActionToolbar.length;

        if (component === ADJUSTMENT_MAINTENANCE_TOOLBAR) {
            return (
                <div className="saveButton" key={lastIndex}>
                    <div className="saveButtonContainer btn-group" key={lastIndex}>
                        <button
                            title={dataActionToolbar.find(f => f.method === "Save").name}
                            aria-disabled={isReadonly}
                            className={changesMade ? "saveButton" : "disabledSaveButton"}
                            onClick={() => this.saveMethod(SaveOption.Save)}
                            disabled={!changesMade}
                        >
                            <div className="saveButtonText">{dataActionToolbar.find(f => f.method === "Save").name}</div>
                        </button>
                        <div>
                            <button
                                type="button"
                                className={"saveButton dropdown-toggle"}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-disabled={isReadonly}>
                                <span className="caret"></span>
                                <span className="sr-only">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu">
                                <NavItem key={1}>
                                    <NavLink
                                        onClick={() => this.saveMethod(SaveOption.SavePost)}
                                    >Save & Post</NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            if (component === WORKFLOW_PAGE_TOOLBAR || (component === DRAFT_WORKS_MAINTENANCE_TOOLBAR && isNew)|| (component === DRAFT_SET_LISTS_MAINTENANCE_TOOLBAR && isNew) || (component === DRAFT_AGREEMENTS_MAINTENANCE_TOOLBAR))
                return null;
            return (
                <div className="saveButton" key={lastIndex}>
                    <div className="saveButtonContainer" key={lastIndex}>
                        <button
                            aria-disabled={!changesMade}
                            className={changesMade ? "saveButton" : "disabledSaveButton"}
                            onClick={() => this.saveMethod(SaveOption.Save)}
                            disabled={!changesMade}
                        >
                            <div className="saveButtonText">{dataActionToolbar.find(f => f.method === "Save").name}</div>
                        </button>
                    </div>
                </div>
            );
        }
    };

    renderWarningMessages() {
        const { tabs, activeTab, saveResultData, resetMessageBanner } = this.props;
        const messages: string[] = [];

        if (tabs[activeTab].validationWarning) {
            messages.push(saveResultData.fields.find(x => x.name.toString() === 'validationError').data);
        }

        if (tabs[activeTab].sharesWarning) {
            messages.push(saveResultData.fields.find(x => x.name.toString() === 'workPerformingSharesNot100').data);
        }

        if (tabs[activeTab].paymentRunMaintenanceState && tabs[activeTab].paymentRunMaintenanceState.paymentRun && tabs[activeTab].paymentRunMaintenanceState.paymentRun.showStatusChangeWarning) {
            messages.push(saveResultData.fields.find(x => x.name.toString() === 'paymentRunStatusChangeWarningMessage').data);
        }

        if (tabs[activeTab].workMaintenanceState && tabs[activeTab].workMaintenanceState.work && tabs[activeTab].workMaintenanceState.work.validateRoleBasedOnIpiTypeWarning) {
            messages.push(saveResultData.fields.find(x => x.name.toString() === 'workContributorRoleIpiTypeInvalidMessage').data);
        }

        if (messages.length > 0) {
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Warning} messages={messages} />;
        }
    }

    render() {
        const { toolbarWidth, tabs, activeTab, saveResultData, resetMessageBanner, component, allocationJobInProgress } = this.props;
        const { loaded } = this.state;
        let sections = [];
        let messages: IValidationMessage[];
        let msg = { messageKey: '' };
        if (tabs[activeTab].validationMessages != null) {
            messages = [];
            tabs[activeTab].validationMessages.forEach(e => {

                if (messages.length < 1) {
                    let validationMessage: IValidationMessage = messages.filter(x => x.messageKey !== "A validation error has occurred during saving. Check below for the specific message.") ? { messageKey: "A validation error has occurred during saving. Check below for the specific message." } : { messageKey: "" };
                    messages.push(validationMessage);
                }
                    
                sections.push(saveResultData.fields.find(x => x.name.toString() === e.messageKey)?.section);
                let validationMessage: IValidationMessage = { messageKey: e.messageKey, additionalInfo: e.additionalInfo }
                messages.push(validationMessage);

            })
        }
        if (loaded) {
            return (
                <>
                    <div
                        className="toolbarWrapper"
                        id={"toolbar"}
                        style={{ width: toolbarWidth }}
                    >
                        {this.renderToolbarButtons()}
                        {component === DISTRIBUTION_MAINTENANCE_TOOLBAR ? this.renderSecondaryActions() : null}
                        {this.renderSaveButton()}
                    </div>
                    <If condition={(tabs[activeTab].saveSuccessful) || (tabs[activeTab].postSuccessful) || (tabs[activeTab].matchingSuccessful) ||
                        (tabs[activeTab].mergeSuccessful) || (tabs[activeTab].exportSuccessful) || (tabs[activeTab].deleteSuccessful) ||
                        (tabs[activeTab].newAllocateDistributionPoolsJobSuccess) || (messages != null) &&
                        (messages.length > 0) || ((sections.includes(null)) || (sections.includes(undefined)))}>
                        <SaveResult
                            componentInstance={component}
                            saveSuccess={tabs[activeTab].saveSuccessful}
                            postSuccess={tabs[activeTab].postSuccessful}
                            matchingSuccess={tabs[activeTab].matchingSuccessful}
                            mergeSuccess={tabs[activeTab].mergeSuccessful}
                            exportSuccess={tabs[activeTab].exportSuccessful}
                            saveResultData={saveResultData}
                            messages={messages}
                            resetMessageBanner={resetMessageBanner}
                            deleteAccount={tabs[activeTab].deleteSuccessful}
                            newAllocateDistributionPoolsJobSuccess={tabs[activeTab].newAllocateDistributionPoolsJobSuccess}
                            allocationJobInProgress={allocationJobInProgress}
                        />
                    </If>
                    {this.renderWarningMessages()}
                </>
            );
        }
        return <div />;
    }
}

export default ToolbarComponent;