import * as React from "react";
import { IComponentDataItem } from "../../../core/types/IComponentDataItem";
import { IComponentField } from "../../../core/types/IComponentField";
import PaginationView from "../../../repertoire/components/paginationView/PaginationView";
import TableHeaderSection from "../../../repertoire/components/tableHeaderSection/TableHeaderSection";
import { createCopyOfTableContents } from "../../../repertoire/services/TableFunctions";
import { IJobSearchResult } from "../../types/IJobSearchResult"; 
import { JOB_STATUS_FIELD } from "./JobSearchResultsGridFields";
import DateDisplay from "../../../repertoire/components/dateDisplay/DateDisplay";

export interface IJobSearchResultsGridProps {
    searchResultsTableData: IComponentDataItem;
    tableContents: IJobSearchResult[];
    newSearch?: boolean;
    customerTimeZone?: any;
}

interface IJobSearchResultsGridState {
    tableContents: IJobSearchResult[];
    activeSortHeaderSection: string;
    indexOfFirstResult: number;
    indexOfLastResult: number;
    resultsPerPage: number;
    currentPage: number;
   }

export default class JobSearchResultsGrid extends React.PureComponent<
    IJobSearchResultsGridProps,
    IJobSearchResultsGridState
    > {
    constructor(props: IJobSearchResultsGridProps) {
        super(props);

        this.state = {
            tableContents: props.tableContents,
            activeSortHeaderSection: undefined,
            indexOfFirstResult: 0,
            indexOfLastResult: 10,
            resultsPerPage: 10,
            currentPage: 1
        };
    }

    public ascending: boolean = false;

    componentDidUpdate = (prevProps: IJobSearchResultsGridProps) => {
        const { tableContents, newSearch } = this.props;
        const { indexOfLastResult, resultsPerPage } = this.state;
        
        if (prevProps.tableContents !== tableContents) {
            this.setState({
                tableContents
            });
        }

        if(newSearch){
            this.updateContentsTable(0, indexOfLastResult, resultsPerPage, 1)
        }
    };


    sortTableByColumn = (section: string) => {
        const { activeSortHeaderSection, tableContents } = this.state;
        const tableContentsCopy = createCopyOfTableContents(tableContents);

        if (activeSortHeaderSection !== section) {
            this.ascending = false;
        }

        tableContentsCopy.sort((a: any, b: any) => {
            // set to emtpy string if null
            let newA = a[section] || "";
            let newB = b[section] || "";

            newA = newA ? newA : "";
            newB = newB ? newB : "";

            if (!this.ascending) {
                return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
            }
            return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });
        });

        this.setState({
            tableContents: tableContentsCopy,
            activeSortHeaderSection: section
        });

        this.ascending = !this.ascending;
    };

    updateContentsTable(indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number): void {
        this.setState({ indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage });
    }

    getHeaderString = (key: string) => {
        const { searchResultsTableData } = this.props;

        for (let x = 0; x < searchResultsTableData.fields.length; x++) {
            if (searchResultsTableData.fields[x].name === key) {
                return searchResultsTableData.fields[x].data;
            }
        }
    };

    renderHeaderSections = () => {
        const { searchResultsTableData } = this.props;
        const { activeSortHeaderSection } = this.state;

        if (searchResultsTableData && searchResultsTableData.fields) {
            return searchResultsTableData.fields.map((section: IComponentField, index: number) => (
                <td key={index} className="td">
                    <TableHeaderSection
                        section={section.data}
                        fieldName={section.name}
                        isSortingActive={section.name === activeSortHeaderSection}
                        ascending={this.ascending}
                        onClickHeaderSection={this.sortTableByColumn}
                        sortable
                    />
                </td>
            ));
        }
    };

    renderPaginationView(): JSX.Element {
        const { tableContents, resultsPerPage, currentPage } = this.state;
        return <PaginationView
            contentsLength={tableContents ? tableContents.length : 0}
            resultsPerPage={resultsPerPage}
            currentPage={currentPage}
            updateContentsTable={this.updateContentsTable.bind(this)}
        />
    }

    renderJobStatusField(status: string): JSX.Element {
        switch (status) {
            case "Not Started":
                return <div className="tableCell statusNotStarted">
                    <span className="icon ms-Icon ms-Icon--HourGlass" />
                    {status}
                </div>
            case "In Progress":
                return <div className="tableCell statusInprogress">
                    <span className="icon ms-Icon ms-Icon--SyncStatus" />
                    {status}
                </div>
            case "Finished OK":
                return <div className="tableCell statusCompleted">
                    <span className="icon ms-Icon ms-Icon--Completed" />
                    {status}
                </div>
            case "Finished Error":
                return <div className="tableCell statusError">
                    <span className="icon ms-Icon ms-Icon--ErrorBadge" />
                    {status}
                </div>
            case "Cancelled":
                return <div className="tableCell statusCancelled">
                    <span className="icon ms-Icon ms-Icon--CircleStop" />
                    {status}
                </div>
            case "Partially Succeeded":
                return <div className="tableCell statusCancelled">
                    <span className="icon ms-Icon ms-Icon--CircleStop" />
                    {status}
                </div>
            default:
                return null;
        }
    }

    renderSearchResults = () => {
        const { tableContents, indexOfFirstResult, indexOfLastResult } = this.state;
        const { searchResultsTableData, customerTimeZone } = this.props;

       if (tableContents && tableContents.length > 0) {
            const currentResults: IJobSearchResult[] = tableContents.slice(
                indexOfFirstResult,
                indexOfLastResult
            );

            const keys: { name, title }[] = [];
            searchResultsTableData.fields.map(field => keys.push({ name: field.name, title: field.data }));

            return currentResults.map(
                (tableContentItem: IJobSearchResult, index: number) => {
                    const sections: JSX.Element[] = [];

                    keys.map((key: { name, title }, x: number) => {
                        if (key.name === JOB_STATUS_FIELD) {
                            sections.push(
                                <td key={`${index}.${x}`} className="td" title={key.title} >
                                    {this.renderJobStatusField(tableContentItem[key.name])}
                                </td>
                            );
                        }
                        else {
                            if (key.name === 'lastModifiedDate') {
                                sections.push(
                                    <td key={`${index}.${x}`} className="td" title={key.title} >
                                        <div className="tableCell wrapText">
                                            <DateDisplay value={tableContentItem[key.name]} showTime={true} />
                                        </div>
                                    </td>
                                );
        
                                return;
                            }
                            sections.push(
                                <td key={`${index}.${x}`} className="td" title={key.title} >
                                    <div className="tableCell wrapText">
                                        {tableContentItem[key.name]}
                                    </div>
                                </td>
                            );
                        }
                    });

                    return <tr key={index} className="tr">
                        {sections}
                    </tr>
                }
            );
        }
    };

    render() {
        return (
             <div className="SearchResultsTable">
                 {this.renderPaginationView()}
                 <div className="tableContainer">
                     <table className="table">
                         <thead className="thead">
                             <tr className="tr">{this.renderHeaderSections()}</tr>
                         </thead>
                         <tbody className="tbody">{this.renderSearchResults()}</tbody>
                     </table>
                     {this.renderPaginationView()}
                 </div>
             </div>
        );
    }
}
