import { IParameterSearchResult } from "../types/IParameterSearchResult";

export enum ValidationResult {
    Valid,
    TypeError,
    RequiredError
}

export class ParameterValidator {
    private static numberRegex: RegExp = /^-?\d+\.?\d*$/;

    public static isValid(param: IParameterSearchResult): ValidationResult {

        if (!param.overrideParentValue) { return ValidationResult.Valid; }

        if (this.isNullOrEmpty(param.currentValue)) {
            if (param.isRequired) { return ValidationResult.RequiredError; }
            else { return ValidationResult.Valid; }
        }

        if (param.parameterType === 'number') {
            if (this.isNumber(param.currentValue)) { return ValidationResult.Valid; }
            else { return ValidationResult.TypeError; }
        }

        if (param.parameterType === 'boolean') {
            if (this.isBoolean(param.currentValue)) { return ValidationResult.Valid; }
            else { return ValidationResult.TypeError; }
        }

        return ValidationResult.Valid
    }

    static isNumber(str: string): boolean {
        //return !isNaN(parseFloat(str)) && isFinite(parseFloat(str));
        return this.numberRegex.test(str);
    }

    static isBoolean(str: string): boolean {
        str = str.toLowerCase().trim();
        return str == 'true' || str == 'false';
    }

    static isNullOrEmpty(str: string): boolean {
        return str == null || str == undefined || str.length === 0;
    }
}