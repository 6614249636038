import * as React from "react";
import { hideModal } from "../../../../redux/reducers/ModalReducer";
import { updateEditableFields } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { CANCEL_BUTTON_TEXT_FIELD, CONFIGURE_ADJUSTMENTS_ROLE, SAVE_BUTTON_TEXT_FIELD, USAGE_DESCRIPTION_EDITABLE_VIEW } from "../../../Consts";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import DataGridTable from "../../dataGridTable/DataGridTable";

export interface IFieldSettingViewModalProps {
    tableContents: any;
    componentViewData: IRepertoireComponentDataItem;
    componentInstance: string;
    dataGridViewData: IRepertoireComponentDataItem;
    updateEditableFields: typeof updateEditableFields;
    onClickSave: () => void;
    onClickCancel: () => void;
    editableFieldsKey?: string;
}

interface IFieldSettingViewState {
    loaded: boolean;
}

export default class FieldSettingView extends React.PureComponent<
    IFieldSettingViewModalProps, IFieldSettingViewState>
{
    viewData;
    constructor(props: IFieldSettingViewModalProps) {
        super(props);
        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            componentViewData: { fields }
        } = this.props;

        if (fields) {
            fields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === fields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    changeData(value: any[], name: IRepertoireStateKeys) {
        const { updateEditableFields, editableFieldsKey } = this.props;
        updateEditableFields(value, editableFieldsKey);
    }

    renderActionButtons() {
        const { onClickSave, onClickCancel } = this.props;
        return (
            <div >
                <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                    <button className="button btn-defaultSecondary" onClick={onClickCancel}>
                        {this.viewData[CANCEL_BUTTON_TEXT_FIELD]}
                    </button>
                </div>

                <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                    <button className="button btn-defaultPrimary" onClick={onClickSave}>
                        {this.viewData[SAVE_BUTTON_TEXT_FIELD]}
                    </button>
                </div>
            </div>
        )
    }

    renderDataGridTable() {
        const { tableContents, dataGridViewData, componentInstance } = this.props;
        return (
            <div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <p>{this.viewData[USAGE_DESCRIPTION_EDITABLE_VIEW]}</p>
                </div>
                <DataGridTable
                    componentInstance={componentInstance}
                    dataGridTableData={dataGridViewData}
                    showRowNumber={false}
                    tableContents={tableContents}
                    hideModal={hideModal}
                    stateKey={componentInstance}
                    changeData={this.changeData.bind(this)}
                />
                {this.renderActionButtons()}
            </div>
        );
    }

    render() {
        const { loaded } = this.state;
        if (loaded) {
            return (
                <>
                    {this.renderDataGridTable()}
                </>
            );
        }
        else {
            return <div>Loading...</div>
        }
    }

};

