import * as React from 'react';
import { AccountService as Acc } from '../services/AccountService';
import { IRegisterViewModel } from '../types/IRegisterViewModel';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { If } from '../../core/components/If';
import { ComponentFields } from '../../core/services/ComponentService';
import { ComponentsHelper as CH } from '../../core/services/ComponentHelper';
import { Dictionary } from '../../core/types/Dictionary';
import { IComponentField } from '../../core/types/IComponentField';

interface IRegisterPageProps {
    location: any
}

interface IRegisterPageState {
    email: string,
    password: string,
    confirmPassword: string,
    username: string,
    redirectToReferror: boolean,
    activeFields: Dictionary<IComponentField>,
    showRegisterFailedMessage: boolean,
    validationErrorMessage: string,
    validationErrors: string[]
}

enum Fields {
    MusicIndustryHeader,
    RegisterHeader,
    UsernameLabel,
    EmailLabel,
    PasswordLabel,
    ConfirmPasswordLabel,
    RegisterButton,
    RegisterFailedMessage,
    UsernameRequiredMessage,
    EmailRequiredMessage,
    PasswordRequiredMessage,
    ConfirmationPasswordRequiredMessage,
    PasswordsMustMatchMessage
}

export class RegisterPage extends React.Component<IRegisterPageProps, IRegisterPageState>{
    ComponentName: string = "RegisterPage";
    constructor(props: IRegisterPageProps) {
        super(props);

        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            username: '',
            redirectToReferror: false,
            activeFields: {},
            showRegisterFailedMessage: false,
            validationErrorMessage: '',
            validationErrors: null
        };
    }

    componentDidMount() {
        ComponentFields.get(this.ComponentName)
            .then(activeFields => {
                this.setState({ activeFields });
            });
    }

    usernameChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({ username: e.currentTarget.value });

    }
    emailChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({ email: e.currentTarget.value });
    }

    passwordChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({ password: e.currentTarget.value });
    }

    confirmPasswordChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.setState({ confirmPassword: e.currentTarget.value });
    }

    register(event: (React.FormEvent<Form>|React.MouseEvent<HTMLButtonElement, MouseEvent>)) {
        event.preventDefault();

        if (this.isNullOrEmtpy(this.state.username)) {
            this.setState({ showRegisterFailedMessage: true, validationErrorMessage: CH.getField(Fields.UsernameRequiredMessage, Fields, this.state.activeFields).text })
        }
        else if (this.isNullOrEmtpy(this.state.email)) {
            this.setState({ showRegisterFailedMessage: true, validationErrorMessage: CH.getField(Fields.EmailRequiredMessage, Fields, this.state.activeFields).text })
        }
        else if (this.isNullOrEmtpy(this.state.password)) {
            this.setState({ showRegisterFailedMessage: true, validationErrorMessage: CH.getField(Fields.PasswordRequiredMessage, Fields, this.state.activeFields).text })
        }
        else if (this.isNullOrEmtpy(this.state.confirmPassword)) {
            this.setState({ showRegisterFailedMessage: true, validationErrorMessage: CH.getField(Fields.ConfirmationPasswordRequiredMessage, Fields, this.state.activeFields).text })
        }
        else if (this.state.password != this.state.confirmPassword) {
            this.setState({ showRegisterFailedMessage: true, validationErrorMessage: CH.getField(Fields.PasswordsMustMatchMessage, Fields, this.state.activeFields).text })
        }
        else {
            let vm: IRegisterViewModel = {
                email: this.state.email,
                password: this.state.password,
                confirmPassword: this.state.confirmPassword,
                username: this.state.username
            }

            Acc.register(vm)
                .then(res => {
                    if (res.success) {
                        this.setState({
                            redirectToReferror: true,
                            showRegisterFailedMessage: false,
                            validationErrors: null
                        });
                    }
                    else {
                        this.setState({
                            showRegisterFailedMessage: true,
                            validationErrorMessage: CH.getField(Fields.RegisterFailedMessage, Fields, this.state.activeFields).text,
                            validationErrors: res.errors
                        });
                    }
                });
        }
    }

    isNullOrEmtpy(arg: string): boolean {
        return arg == null || arg == undefined || arg.length == 0;
    }

    render() {

        if (this.state.redirectToReferror) {
            return <Redirect to='/login' />
        }

        return (
            <div className="wrapperLogin">
                <div className="bgLogin"></div>

                <div className="container-fluid pageContent">
                    <div className="row">
                        <div className="formBox col-xsm-12 col-sm-6 col-sm-push-3 col-md-6 col-md-push-3 col-lg-4 col-lg-push-4 shadow">
                            <div className="logo" title="Spanish Point Technologies">
                                <img className="img-responsive" src="logofull-spanishpoint.png" />
                            </div>

                            {/* Music industry header */}
                            <If condition={CH.getField(Fields.MusicIndustryHeader, Fields, this.state.activeFields).exists}>
                                <h2>{CH.getField(Fields.MusicIndustryHeader, Fields, this.state.activeFields).text}</h2>
                            </If>

                            {/* Register header */}
                            <If condition={CH.getField(Fields.RegisterHeader, Fields, this.state.activeFields).exists}>
                                <h3>{CH.getField(Fields.RegisterHeader, Fields, this.state.activeFields).text}</h3>
                            </If>

                            <div className="row">

                                {/* Username */}
                                <div className="form-group col-md-12">
                                    <If condition={CH.getField(Fields.UsernameLabel, Fields, this.state.activeFields).exists}>
                                        <label>{CH.getField(Fields.UsernameLabel, Fields, this.state.activeFields).text}</label>
                                    </If>
                                    <input type="text" value={this.state.username}
                                        onChange={this.usernameChanged.bind(this)}
                                        name='username' required autoFocus />
                                </div>

                                {/* Email */}
                                <div className="form-group col-md-12">
                                    <If condition={CH.getField(Fields.EmailLabel, Fields, this.state.activeFields).exists}>
                                        <label>{CH.getField(Fields.EmailLabel, Fields, this.state.activeFields).text}</label>
                                    </If>
                                    <input type="text" value={this.state.email}
                                        onChange={this.emailChanged.bind(this)}
                                        required name='email' />
                                </div>

                                {/* Password */}
                                <div className="form-group col-md-12">
                                    <If condition={CH.getField(Fields.PasswordLabel, Fields, this.state.activeFields).exists}>
                                        <label>{CH.getField(Fields.PasswordLabel, Fields, this.state.activeFields).text}</label>
                                    </If>
                                    <input type="password" value={this.state.password}
                                        onChange={this.passwordChanged.bind(this)}
                                        required name='password' />
                                </div>

                                {/* Confirm password */}
                                <div className="form-group col-md-12">
                                    <If condition={CH.getField(Fields.ConfirmPasswordLabel, Fields, this.state.activeFields).exists}>
                                        <label>{CH.getField(Fields.ConfirmPasswordLabel, Fields, this.state.activeFields).text}</label>
                                    </If>
                                    <input type="password" value={this.state.confirmPassword}
                                        onChange={this.confirmPasswordChanged.bind(this)}
                                        required name='confirm-password' />
                                </div>
                            </div>

                            {/* Register button */}
                            <div className="row">
                                <div className="col-lg-6 col-lg-push-6 col-md-6 col-md-push-6 col-sm-8 col-sm-push-4 col-xs-12 col-xs-push-0 form-group">
                                    <If condition={CH.getField(Fields.RegisterButton, Fields, this.state.activeFields).exists}>
                                        <button className="button btn-defaultPrimary"
                                            onClick={this.register.bind(this)}>
                                            {CH.getField(Fields.RegisterButton, Fields, this.state.activeFields).text}
                                        </button>
                                    </If>
                                </div>
                            </div>

                            {/* Register failed message */}
                            <If condition={this.state.showRegisterFailedMessage}>
                                <div>
                                    <div className="row">
                                        <div className="col-md-12 alert alert-danger alert-dismissible fade in">
                                            <label data-dismiss="alert" >{this.state.validationErrorMessage}</label>
                                        </div>
                                    </div>

                                    <div>
                                        {
                                            this.state.validationErrors ? this.state.validationErrors.map((error: string, idx: number) =>
                                                <div key={idx} className="row">
                                                    <div className="col-md-12 alert alert-danger alert-dismissible fade in">
                                                        <label data-dismiss="alert" >{error}</label>
                                                    </div>
                                                </div>) : null
                                        }
                                    </div>
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}