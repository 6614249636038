import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import IAppState from "../types/IAppState";
import * as ActionCreators from "../actions/SettingsActionCreators";
import { SourceService } from "../../settings/services/SourceService";
import { IMatchType } from "../../settings/types/IMatchType";
import { ISourceMatchType } from "../../settings/types/ISourceMatchType";
import { ISourceSearchResult } from "../../settings/types/ISourceSearchResult";
import { PropertyWindowSaveState } from "../../settings/types/PropertyWindowSaveState";
import { IProductType } from "../../settings/types/IProductType";
import { IParameterSearchResult } from "../../settings/types/IParameterSearchResult";
import { loadFolderListFailure, loadFolderListRequest, loadFolderListsuccess } from "../reducers/DataIngestionReducer";

export const getMatchTypesThunk = (): ThunkAction<
  void,
  IAppState,
  null,
  Action<string>
> => {
  return (dispatch: Dispatch) => {
    dispatch(ActionCreators.getMatchTypesRequest());
    return SourceService.getMatchTypes()
      .then((res: IMatchType[]) => {
        dispatch(ActionCreators.getMatchTypesSuccess(res));
      })
      .catch((error: any) => {
        dispatch(ActionCreators.getMatchTypesFailure(error));
      });
  };
};

export const getSourcesThunk = (
  matchTypeID: number
): ThunkAction<void, IAppState, null, Action<string>> => {
  return (dispatch: Dispatch) => {
    dispatch(ActionCreators.getSourcesRequest());
    return SourceService.getSources(matchTypeID)
      .then((res: ISourceMatchType) => {
        dispatch(ActionCreators.getSourcesSuccess(res));
      })
      .catch((error: string) => {
        dispatch(ActionCreators.getSourcesFailure(error));
      });
  };
};

export const loadSourceThunk = (
  sourceID: number,
  productType: IProductType,
  settingType: number,
  saveState: PropertyWindowSaveState
): ThunkAction<void, IAppState, null, Action<string>> => {
  return (dispatch: Dispatch) => {
    dispatch(ActionCreators.loadSourceRequest());
    return SourceService.loadSource(sourceID, productType.name, settingType)
      .then((sourceSearchResult: ISourceSearchResult) => {
        dispatch(
          ActionCreators.loadSourceSuccess(
              sourceSearchResult,
              sourceID,
              productType,
              saveState,
          )
        );
      })
      .catch((error: string) => {
        dispatch(ActionCreators.loadSourceFailure(error));
      });
  };
};

export const updateSourceThunk = (
  sourceID: number,
  sourceName: string,
  sourceCode: string,
  sourceDescription: string,
  rejectDuplicateUsageFiles: boolean
): ThunkAction<void, IAppState, null, Action<string>> => {
  return (dispatch: Dispatch) => {
    dispatch(ActionCreators.updateSourceRequest());
      return SourceService.updateSource(sourceID, sourceName, sourceCode, sourceDescription, rejectDuplicateUsageFiles)
      .then((updated: boolean) => {
        dispatch(ActionCreators.updateSourceSuccess(updated));
      })
      .catch((error: string) => {
        dispatch(ActionCreators.updateSourceFailure(error));
      });
  };
};

export const updateSourceHierarchyThunk = (
  sourceTree: ISourceSearchResult
): ThunkAction<void, IAppState, null, Action<string>> => {
  return (dispatch: Dispatch) => {
    dispatch(ActionCreators.updateSourceHierarchyRequest());
    return SourceService.updateSourceHierarchy(sourceTree)
      .then((res: ISourceMatchType) => {
        dispatch(ActionCreators.updateSourceHierarchySuccess(res));
      })
      .catch((error: string) => {
        dispatch(ActionCreators.updateSourceHierarchyFailure(error));
      });
  };
};

export const updateParameterInstancesThunk = (
  parameters: IParameterSearchResult[],
  sourceID: number,
  productType: IProductType,
  settingType: number
): ThunkAction<void, IAppState, null, Action<string>> => {
  return (dispatch: Dispatch) => {
    dispatch(ActionCreators.updateParameterInstancesRequest());
    return SourceService.update(parameters)
      .then((success: boolean) => {
        dispatch(ActionCreators.updateParameterInstancesSuccess(success));
        dispatch<any>(
          loadSourceThunk(
            sourceID,
            productType,
            settingType,
            success
              ? PropertyWindowSaveState.SaveSucceeded
              : PropertyWindowSaveState.SaveFailed
          )
        );
      })
      .catch((error: string) => {
        dispatch(ActionCreators.updateParameterInstancesFailure(error));
      });
  };
};

export const loadFolderListThunk = (): ThunkAction<void, IAppState, null, Action<string>> => {
    return (dispatch: Dispatch) => {
        dispatch(loadFolderListRequest());
        return SourceService.loadFolderListThunk()
            .then((res: IParameterSearchResult[]) => {
                dispatch(loadFolderListsuccess(res));
            })
            .catch((error: string) => {
                dispatch(loadFolderListFailure(error));
            });
    };
};
