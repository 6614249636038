import * as React from 'react';
import { IParameterData } from '../types/IParameterData';
import { If } from '../../core/components/If';

interface IPropertyEditorProps {
    data: IParameterData,
    overrideChanged: (parameterID: number, override: boolean) => void,
    currentValueChanged: (parameterID: number, currentValue: string) => void,
    inheritedValueText: string,
    customValueText: string,
    parameterNullError: string,
}

export class PropertyEditor extends React.Component<IPropertyEditorProps> {
    ComponentName: string = "PropertyEditor";

    constructor(props: IPropertyEditorProps) {
        super(props);
    }

    overrideChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.props.overrideChanged(this.props.data.parameterID, e.currentTarget.checked);
    }

    inheritChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.props.overrideChanged(this.props.data.parameterID, !e.currentTarget.checked);
    }

    currentValueChanged(e: React.SyntheticEvent<HTMLInputElement>) {
        this.props.currentValueChanged(this.props.data.parameterID, e.currentTarget.value);
    }

    render() {

        const renderHTML = (rawHTML: string) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

        if (this.props.data == null) {
            return <div>
                <label>{this.props.parameterNullError}</label>
            </div>
        }
        else {
            const param = this.props.data;
            return (
                <div className="form-group col-md-12 ">
                    <div className="formBox checkOptions">

                        {/* Name */}
                        <div className="title">{param.parameterName}</div>

                        {/* Description */}
                        <div className="row">
                            <If condition={param.parameterDescription != null}>
                                <div className="col-md-12">                                    
                                    {renderHTML(param.parameterDescription)}
                                </div>
                            </If>

                            {/* Default value */}
                            <div className="form-group col-md-5">
                                <label>{this.props.inheritedValueText}</label>

                                <div className="itemsWrapper">
                                    <input type="text" name="col-md-2" value={param.defaultValue ? param.defaultValue : ''} readOnly />
                                    <label className="radioBox">
                                        <input type="radio" name={param.parameterName} value=''
                                            checked={!param.overrideParentValue} onChange={this.inheritChanged.bind(this)} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>

                            <div className="form-group col-md-2 dividerOr"> Or </div>

                            {/* Current value */}
                            <div className="form-group col-md-5">
                                <label>{this.props.customValueText}</label>
                                
                                <div className="itemsWrapper">
                                    <input type="text" name="" value={param.currentValue ? param.currentValue : ''}
                                        onChange={this.currentValueChanged.bind(this)}
                                        readOnly={!this.props.data.overrideParentValue} />
                                    <label className="radioBox">
                                        <input type="radio" name={param.parameterName} checked={param.overrideParentValue}
                                            onChange={this.overrideChanged.bind(this)} value='' />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <If condition={param.hasError}>
                                    <div className="text-danger">
                                        {param.errorMessage}
                                    </div>
                                </If>

                            </div>
                        </div>
                    </div >
                </div >
            );
        }
    }
}