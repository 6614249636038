import * as React from "react";
import { useState } from "react";
import { IWorkContributor } from "../../../types/IWorkContributor";
import { InlineActionButton } from "../../actionButton/ActionButton";
import TextDataInput from "../../textDataInput/TextDataInput";

export interface IContributorCommentViewProps {
    contributor: IWorkContributor,
    saveContributorComment: (contributorID: number, comments: string)=>void
}

const ContributorCommentView: React.SFC<IContributorCommentViewProps> = ({ contributor, saveContributorComment }) => {

    const [comments, setComments] = useState(contributor.comments != null && contributor.comments.length > 0 ? contributor.comments : '');

    const changeData = (value: any) => {
        setComments(value);
    }

    return (
        <div className="form-group col-md-12">
            <div className="row">
                <label>Please write a comment for this Contributor, it could be an email address, phone number, etc.</label>
            </div><br />
            <div className="row">
                <TextDataInput
                    label={"Comment"}
                    value={comments}
                    fieldName={"comment"}
                    changeData={changeData}
                    isHidden={false}
                />
            </div>
            <br /><br />
            <div className='row' style={{ float: "right", width: "15em" }}>
                <InlineActionButton buttonAction={() => { saveContributorComment(contributor.workContributorID, comments) }} buttonText={'Save'} />
            </div>
        </div>
    );
};

export default ContributorCommentView;