import * as React from "react";
import IRepertoireComponentDataItem from "../../../../../../redux/types/IRepertoireComponentDataItem";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../../../redux/types/ITabReduxItem";
import { SizedTextDataInput } from "../../../../../components/textDataInput/TextDataInput";
import { IRepertoireStateKeys } from "../../../../../types/IRepertoireStateKeys";
import { addArtistToWork, addNewRepresentation, addTab, setDataSource, setEditableFields, sortSearchResults, updateEditableFields } from "../../../../../../redux/reducers/RepertoireReducer";
import {
    IP_REPRESENTATION_WORK_GROUP_STATE_KEY,
    IP_REPRESENTATION_WORK_GROUP_TYPE_STATE_KEY
} from "../../../../../Consts";
import { ILookupDictionary } from "../../../../../../lookup/types/ILookupDictionary";
import { IRepresentation } from "../../../../../types/usageTypes/IRepresentation";
import { If } from "../../../../../../core/components/If";
import SaveResult from "../../../../../components/saveResult/SaveResult";
import { IWorkGroupMaintenanceState } from "../../../../../../redux/types/IWorkGroupMaintenanceState";
import WorksPage from "../../../../../works/WorksPage";
import { IResultsPerPage } from "../../../../../../redux/types/IResultsPerPage";
import { IActiveAccordion } from "../../../../../../redux/types/IActiveAccordion";
import { IRepertoireField } from "../../../../../types/IRepertoireField";
import { IDataActionToolbar } from "../../../../../types/IDataActionToolbar";
import { IContributorSearchQuery } from "../../../../../types/IContributorSearchQuery";
import { IArtistSearchQuery } from "../../../../../types/IArtistSearchQuery";
import { IWorksSearchQuery } from "../../../../../types/IWorksSearchQuery";
import { IContributorSearchResult } from "../../../../../types/IContributorSearchResult";
import { IWorksSearchResult } from "../../../../../types/IWorksSearchResult";
import { IArtist } from "../../../../../types/IArtist";
import { FormatFields } from "../../../../../../redux/types/FormatFields";
import { DataSource } from "../../../../../types/DataSource";
import { IWorkflowSearchResult } from "../../../../../types/IWorkflowSearchResult";

export interface IWorkGroupGeneralDataViewProps {
    workGroupMaintenanceState?: IWorkGroupMaintenanceState;
    workGroupGeneralDataViewData: IRepertoireComponentDataItem;
    worksPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    dataSource: string;
    worksSearchResults: IWorksSearchResult[];
    artistsSearchResults: IArtist[];
    lookupValues: ILookupDictionary;
    contributorsSearchResults: IContributorSearchResult[];
    searchSuccessful: boolean;
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    expandedWorkResults: number[];
    expandAll: boolean;
    editableFieldsDataView: IRepertoireComponentDataItem;
    editableFields: IRepertoireField[];
    activeTab?: number;
    tabs?: ITabReduxItem[];
    dataGridTableData?: IRepertoireComponentDataItem;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    currentPage?: number;
    setDataSource: typeof setDataSource;
    addTab: typeof addTab;
    showModal: typeof showModal;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], actionList: IDataActionToolbar[], readonlyIndicatorsWorkFlagTypes: string[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean, workGroup?: string) => void; 
    searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    getWorkDetailsWorkflow: (
        dataSource: string,
        workID: number,
        lookups: ILookupDictionary,
        otherIndicatorsWorkFlagTypes: string[],
        dataActions: IDataActionToolbar[],
        workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
        formats: FormatFields[],
        readonlyIndicatorsWorkFlagTypes: string[],
        openEntityForWorflowSession?: boolean,
        activeTab?: number, currentWorkflowIndex?: number,
        workflows?: IWorkflowSearchResult[],
        workGroup?: string) => void;
    searchArtists: (query: IArtistSearchQuery, dataSource: string) => void;
    searchContributors: (
        query: IContributorSearchQuery,
        dataSource: string
    ) => void;
    addNewWork: (lookups: ILookupDictionary, actionList: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    expandWorkResult: (workResult: number) => void;
    expandAllResults: () => void;
    setEditableFields: typeof setEditableFields;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    hideModal?: typeof hideModal;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    addArtistToWork: typeof addArtistToWork;
    updateEditableField: typeof updateEditableFields;
    sortSearchResults: (name: string, results: any) => void;
    ipBaseNumber: any;
    isReadonly: boolean;
    ipRepresentationGeneralDataViewData: IRepertoireComponentDataItem;
    addNewRepresentation: typeof addNewRepresentation;
    resetMessageBanner: () => void;
    ipMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    readonlyIndicatorsWorkFlagTypes: string[];
    otherIndicatorsWorkFlagTypes: string[];
    getOtherIndicatorsWorkFlagTypes: () => void,
    getReadonlyIndicatorsWorkFlagTypes: () => void
}

interface IWorkGroupGeneralDataViewState {
    loaded: boolean;
    scroll: number;
    toolbarWidth: number;
    representationObject: IRepresentation;
}

export default class WorkGroupGeneralDataView extends React.PureComponent<
    IWorkGroupGeneralDataViewProps,
    IWorkGroupGeneralDataViewState
> {
    viewData;
    constructor(props: IWorkGroupGeneralDataViewProps) {
        super(props);

        this.viewData = {};
    }

    componentDidMount() {
        const {
            workGroupGeneralDataViewData: { fields }
        } = this.props;
        const visibleFields = fields.filter(field => !field.hidden);
        visibleFields.forEach(item => {
            this.viewData[item.name] = item.data;

            if (Object.keys(this.viewData).length === visibleFields.length) {
                this.setState({ loaded: true });
            }
        });
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    render() {
        const {
            worksPageData,
            searchViewData,
            searchResultsTableData,
            dataSource,
            setDataSource,
            getWorkDetails,
            getWorkDetailsWorkflow,
            searchWorks,
            worksSearchResults,
            searchArtists,
            artistsSearchResults,
            searchContributors,
            contributorsSearchResults,
            addNewWork,
            lookupValues,
            searchSuccessful,
            workMaintenanceGeneralDataViewData,
            expandedWorkResults,
            expandAll,
            expandWorkResult,
            expandAllResults,
            editableFieldsDataView,
            setEditableFields,
            updateComponentFieldsItems,
            editableFields,
            resetPagination,
            updatePagination,
            updateUserPreferences,
            tabs,
            activeTab,
            workGroupMaintenanceState,
            addArtistToWork,
            updateEditableField,
            allResultsPerPage,
            defaultActiveAccordions,
            showUpdateFieldsModal,
            hideModal,
            dataGridTableData,
            indexOfFirstResult,
            indexOfLastResult,
            currentPage,
            sortSearchResults,
            otherIndicatorsWorkFlagTypes,
            getOtherIndicatorsWorkFlagTypes,
            getReadonlyIndicatorsWorkFlagTypes,
            readonlyIndicatorsWorkFlagTypes
        } = this.props;

        const changeData = (value: any, name: IRepertoireStateKeys) => {

        }

        return (
            <div className="">

                <div className="row">

                    <SizedTextDataInput
                        label={this.viewData[IP_REPRESENTATION_WORK_GROUP_STATE_KEY]}
                        fieldName={IP_REPRESENTATION_WORK_GROUP_STATE_KEY}
                        changeData={changeData}
                        value={workGroupMaintenanceState && workGroupMaintenanceState.description ? workGroupMaintenanceState.description : ""}
                        readOnly={true}
                        isHidden={!this.viewData[IP_REPRESENTATION_WORK_GROUP_STATE_KEY]}
                    />

                    <SizedTextDataInput
                        label={this.viewData[IP_REPRESENTATION_WORK_GROUP_TYPE_STATE_KEY]}
                        fieldName={IP_REPRESENTATION_WORK_GROUP_TYPE_STATE_KEY}
                        changeData={changeData}
                        value={workGroupMaintenanceState && workGroupMaintenanceState.groupType ? workGroupMaintenanceState.groupType : ""}
                        readOnly={true}
                        isHidden={!this.viewData[IP_REPRESENTATION_WORK_GROUP_TYPE_STATE_KEY]}
                    />
                </div>

                <WorksPage
                    worksPageData={worksPageData}
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    dataSource={DataSource.Repertoire}
                    setDataSource={setDataSource}
                    addArtistToWork={addArtistToWork}
                    getWorkDetails={getWorkDetails}
                    getWorkDetailsWorkflow={getWorkDetailsWorkflow}
                    searchWorks={searchWorks}
                    worksSearchResults={worksSearchResults}
                    searchArtists={searchArtists}
                    artistsSearchResults={artistsSearchResults}
                    searchContributors={searchContributors}
                    contributorsSearchResults={contributorsSearchResults}
                    addNewWork={addNewWork}
                    lookupValues={lookupValues}
                    searchSuccessful={searchSuccessful}
                    workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                    expandedWorkResults={expandedWorkResults}
                    expandAll={expandAll}
                    expandWorkResult={expandWorkResult}
                    expandAllResults={expandAllResults}
                    editableFieldsDataView={editableFieldsDataView}
                    updateEditableField={updateEditableField}
                    setEditableFields={setEditableFields}
                    updateComponentFieldsItems={updateComponentFieldsItems}
                    editableFields={editableFields}
                    resetPagination={resetPagination}
                    updatePagination={updatePagination}
                    updateUserPreferences={updateUserPreferences}
                    workGroupId={workGroupMaintenanceState && workGroupMaintenanceState.workGroupId ? workGroupMaintenanceState.workGroupId : ""}
                    workGroup={workGroupMaintenanceState && workGroupMaintenanceState.description ? workGroupMaintenanceState.description : ""}
                    allResultsPerPage={allResultsPerPage}
                    defaultActiveAccordions={defaultActiveAccordions}
                    showUpdateFieldsModal={showUpdateFieldsModal}
                    activeTab={activeTab}
                    tabs={tabs}
                    hideModal={hideModal}
                    dataGridTableData={dataGridTableData}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    currentPage={currentPage}
                    sortSearchResults={sortSearchResults}
                    otherIndicatorsWorkFlagTypes={otherIndicatorsWorkFlagTypes}
                    getOtherIndicatorsWorkFlagTypes={getOtherIndicatorsWorkFlagTypes}
                    getReadonlyIndicatorsWorkFlagTypes={getReadonlyIndicatorsWorkFlagTypes}
                    readonlyIndicatorsWorkFlagTypes={readonlyIndicatorsWorkFlagTypes}
                    disabled={true}
                />

            </div>
        );
    }
}