import * as React from "react";
import { WORK_SUBMISSION_STATUS_LOOKUP } from "../../../../lookup/Consts";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showWorkSubmission } from "../../../../redux/reducers/ModalReducer";
import { addTab, clearModalSearchResults, sortWorkFieldData, updateContributorSetType, updateWorkField } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import ActionButton from "../../../components/actionButton/ActionButton";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { WORK_CISNET_STATUS, WORK_CISNET_SUBMISSIONS, WORK_CISNET_SUBMISSION_RETRIES, WORK_ICE_STATUS, WORK_ICE_SUBMISSIONS, WORK_ICE_SUBMISSION_RETRIES, WORK_ISWC_STATUS, WORK_ISWC_SUBMISSIONS, WORK_ISWC_SUBMISSION_RETRIES, WORK_WID_STATUS, WORK_WID_SUBMISSION_RETRIES } from "../../../Consts";
import { DataSource } from "../../../types/DataSource";
import { IAccordionData } from "../../../types/IAccordionData";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { ITableAction } from "../../../types/ITableAction";
import { IWorkContributorSetType } from "../../../types/IWorkContributorSetType";
import { IWorkSubmissionStatus } from "../../../types/IWorkSubmissionStatus";

export interface IWorkSubmissionDetailsProps {
    viewData: IRepertoireComponentDataItem;
    tableContents: any;
    changeData?: (value: any, name: IRepertoireStateKeys) => void;
    sortData?: (value: any, name: IRepertoireStateKeys) => void;
    dependentRowAction?: (value: any) => void;
    componentInstance: string;
    dataGridTableData: IRepertoireComponentDataItem;
    tableActions?: ITableAction[];
    stateKey: IRepertoireStateKeys;
    showModal?: typeof showModal;
    clearModalSearchResults?: typeof clearModalSearchResults;
    lookupValues?: ILookupDictionary;
    addTab?: typeof addTab;
    showDropdownsAsCodes?: boolean
    showRowNumber: boolean;
    setTypeGroup?: string;
    activeSetType?: string;
    dataSource?: DataSource;
    isReadOnly?: boolean;
    openInNewTab?: (openItem:any) => void;
    showWorkSubmissionDetail?: typeof showWorkSubmission;
    hideModal?: typeof hideModal;
    updateWorkField?: typeof updateWorkField;
    status? : string;
    retries?: number;
    componentName: string
    workSubmissionDetails?: IRepertoireComponentDataItem;
    work?: IWorkState;
    currentUser?: string;
    tabs: ITabReduxItem[];
    activeTab: number;
    incrementTempID?: () => void;
}

interface IWorkSubmissionDetailsState {
    loaded: boolean;
}



export default class WorkSubmissionDetails extends React.PureComponent<
IWorkSubmissionDetailsProps, 
IWorkSubmissionDetailsState
> {
    viewData;
 constructor(props: IWorkSubmissionDetailsProps){
     super(props);

     this.state = {
        loaded: false,
    };
    this.viewData = {};
 }

 componentDidMount() {
    const {
        workSubmissionDetails,
        componentName,
    } = this.props;

    if(workSubmissionDetails.fields){
        const filteredDetails = workSubmissionDetails.fields.filter(
            detail => detail.componentInstance === componentName);
    
        filteredDetails.forEach(item => {
            this.viewData[item.name] = item.data;
    
            if (Object.keys(this.viewData).length === filteredDetails.length) {
                this.setState({ loaded: true });
            }
        });
    }
}


change = (value: any, name: IRepertoireStateKeys) => {
    if (!this.props.isReadOnly)
        this.props.updateWorkField(value, name as IWorkStateKeys);
}

    render(){
        const {
         tableContents           
         ,tableActions            
         ,stateKey                
         ,sortData                
         ,componentInstance       
         ,dataGridTableData       
         ,showModal               
         ,clearModalSearchResults 
         ,lookupValues            
         ,addTab                  
         ,showRowNumber           
         ,showDropdownsAsCodes    
         ,dataSource              
         ,isReadOnly              
         ,openInNewTab            
         ,showWorkSubmissionDetail
         ,hideModal
         ,componentName
         ,work
         ,changeData
         ,currentUser
         ,tabs
         ,activeTab
         ,incrementTempID
        } = this.props 

        const { loaded} = this.state;

        if(loaded){
            return (
                <div>
                    <div className="row">
                        <SizedDropdownDataInput
                            label={this.viewData[componentName === WORK_ICE_SUBMISSIONS ? WORK_ICE_STATUS : componentName === WORK_ISWC_SUBMISSIONS ? WORK_ISWC_STATUS : componentName === WORK_CISNET_SUBMISSIONS ? WORK_CISNET_STATUS : WORK_WID_STATUS ]}
                            fieldName={componentName === WORK_ICE_SUBMISSIONS ? WORK_ICE_STATUS : componentName === WORK_ISWC_SUBMISSIONS ? WORK_ISWC_STATUS : componentName === WORK_CISNET_SUBMISSIONS ? WORK_CISNET_STATUS : WORK_WID_STATUS}
                            value={componentName === WORK_ICE_SUBMISSIONS ? work.workSubmissionStatuses.find(obj => obj.type === "ICES")?.status || "" : componentName === WORK_ISWC_SUBMISSIONS ? work.iswcStatus : componentName === WORK_CISNET_SUBMISSIONS ? work.cisNetStatus : work.widStatus }
                            changeData={this.change}
                            options={getLookupValues(WORK_SUBMISSION_STATUS_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={isReadOnly}
                            key={this.viewData[componentName === WORK_ICE_SUBMISSIONS ? WORK_ICE_STATUS : componentName === WORK_ISWC_SUBMISSIONS ? WORK_ISWC_STATUS : componentName === WORK_CISNET_SUBMISSIONS ? WORK_CISNET_STATUS : WORK_WID_STATUS ]}
                            isHidden={false}
                        />
                        <SizedTextDataInput
                            label={this.viewData[ componentName === WORK_ICE_SUBMISSIONS ? WORK_ICE_SUBMISSION_RETRIES : componentName === WORK_ISWC_SUBMISSIONS ? WORK_ISWC_SUBMISSION_RETRIES : componentName === WORK_CISNET_SUBMISSIONS ? WORK_CISNET_SUBMISSION_RETRIES : WORK_WID_SUBMISSION_RETRIES ]}
                            fieldName={componentName === WORK_ICE_SUBMISSIONS ? WORK_ICE_SUBMISSION_RETRIES : componentName === WORK_ISWC_SUBMISSIONS ? WORK_ISWC_SUBMISSION_RETRIES : componentName === WORK_CISNET_SUBMISSIONS ? WORK_CISNET_SUBMISSION_RETRIES : WORK_WID_SUBMISSION_RETRIES}
                            value={componentName === WORK_ICE_SUBMISSIONS ? work.workSubmissionStatuses.find(obj => obj.type === "ICES")?.retries || "" : componentName === WORK_ISWC_SUBMISSIONS ? work.iswcSubmissionRetries : componentName === WORK_CISNET_SUBMISSIONS ? work.cisNetSubmissionRetries : work.widSubmissionRetries }
                            changeData={this.change}
                            readOnly={isReadOnly}
                            fieldType={"integer"}
                            key={this.viewData[componentName === WORK_ICE_SUBMISSIONS ? WORK_ICE_SUBMISSION_RETRIES : componentName === WORK_ISWC_SUBMISSIONS ? WORK_ISWC_SUBMISSION_RETRIES : componentName === WORK_CISNET_SUBMISSION_RETRIES ? WORK_CISNET_STATUS : WORK_WID_SUBMISSION_RETRIES ]}
                            isHidden={false}
                        />
                    </div>

                    <DataGridTable
                    tableContents={tableContents}
                    tableActions={tableActions}
                    stateKey={stateKey}
                    changeData={changeData}
                    sortData={sortData}
                    componentInstance={componentInstance}
                    dataGridTableData={dataGridTableData}
                    showModal={showModal}
                    clearModalSearchResults={clearModalSearchResults}
                    lookupValues={lookupValues}
                    addTab={addTab}
                    showRowNumber={showRowNumber}
                    showDropdownsAsCodes={showDropdownsAsCodes}
                    dataSource={dataSource}
                    isReadOnly={isReadOnly}
                    openInNewTab={openInNewTab}
                    showWorkSubmissionDetail={showWorkSubmissionDetail}
                    hideModal={hideModal}
                    currentUser={currentUser}
                    tabs={tabs}
                    activeTab={activeTab}
                    incrementTempID={incrementTempID}
                    />
                </div>
            )
        }
        else {
            return (<div>Loading...</div>)
        }
    }
}