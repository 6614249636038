import * as React from "react";
import { SizedTextDataInput } from "../../textDataInput/TextDataInput";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IMembersPortalField } from "../../../../membersportal/types/IMembersPortalField";
import { DONOTCARE_ABOUTWORKFLOWS, EMPTY_STRING_VALUE, HASOPEN_WORKFLOWS, INTRAY, PRODUCT_WORK_SOURCE_STATE_KEY, REPERTOIRE, SEARCH_VIEW_HAS_OPEN_WORKFLOW, WITHOUTOPEN_WORKFLOWS } from "../../../Consts";
import DisplayTableSearchResults from "../../../../membersportal/sharedComponents/displayTableSearchResults/DisplayTableSearchResults";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import ActionButton from "../../actionButton/ActionButton";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IWorksSearchQuery } from "../../../types/IWorksSearchQuery";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { useMsal } from "@azure/msal-react";
import { addWorkOrProductToUsageSearchCriteria } from "../../../../redux/reducers/RepertoireReducer";

export interface UsageWorkSearchModalViewProps {
    searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    worksSearchResults: any;
    searchSuccessful: boolean;
    paymentStatementGeneralDataViewData?: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    currentUserNameNumber?: string;
    modalTitle: string;
    hideModal: () => void;
    handleAddNewWork: () => void;
    addWorkToUsageSearchCriteria: typeof addWorkOrProductToUsageSearchCriteria
    setTitle: (title: string) => void;
}
const UsageWorkSearchModalView: React.FC<UsageWorkSearchModalViewProps> = ({
    searchWorks,
    searchViewData,
    searchSuccessful,
    searchResultsTableData,
    worksSearchResults,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    lookupValues,
    hideModal,
    modalTitle,
    handleAddNewWork,
    addWorkToUsageSearchCriteria,
    setTitle,
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const [filteredFields, setFilteredFields] = React.useState<IRepertoireField[] | IMembersPortalField[]>([]);
    const [filteredSearchResults, setFilteredSearchResults] = React.useState<IRepertoireField[]>([]);
    const { instance, accounts } = useMsal();

    const [formData, setFormData] = React.useState({
        title: '',
        number: '',
        artist: '',
        writers: '',
        workType: '',
        societyAccountNumber: '',
        hasOpenWorkflow: DONOTCARE_ABOUTWORKFLOWS,
        workBatchID: undefined,
        workGroupID: undefined,
    });

    React.useEffect(() => {
        setLoaded(true);
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        resetPagination('works');
    }, [])

    React.useEffect(() => {

        if (searchViewData && searchViewData.fields) {
            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === 'works'
            );

            setFilteredFields(filteredSearchFields);
        }

        if (searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === 'works'

            );
            setFilteredSearchResults(filteredFields);
        }

    }, [searchViewData])

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
    }

    const changeData = (value: any, fieldName: IRepertoireStateKeys) => {
        setFormData(
            { ...formData, [fieldName]: value }
        )
    }

    const keyDownSubmit = (value: any) => {
        if (value.key === 'Enter') {
            onClickSearch();
        }
    }

    const onClickSearch = () => {
        let hasOpenWorkFlowBoolean = null;
        if (formData.hasOpenWorkflow === HASOPEN_WORKFLOWS) {
            hasOpenWorkFlowBoolean = true;
        }
        if (formData.hasOpenWorkflow === WITHOUTOPEN_WORKFLOWS) {
            hasOpenWorkFlowBoolean = false;
        }
        let searchQuery = {
            title: formData.title,
            number: formData.number,
            artist: formData.artist,
            writers: formData.writers,
            workType: formData.workType,
            societyAccountNumber: formData.societyAccountNumber,
            dataSource: REPERTOIRE,
            workBatchID: formData.workBatchID,
            hasOpenWorkflow: hasOpenWorkFlowBoolean,
            workGroupID: formData.workGroupID,
        };

        searchWorks(searchQuery, true);
    };

    const rowActions = {
        textButton: {
            text: 'Select',
            onClick: (work) => {
                setTitle(work.title[0])
                addWorkToUsageSearchCriteria(work);
                hideModal();
            }
        }
    }

    const renderSearchFields = () => {
        let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
            let formField: React.ReactElement = <></>;

            if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal')) {
                formField =
                    <div key={index}>
                        <SizedTextDataInput
                            fieldType={searchField.fieldType}
                            useEnterSubmit={true}
                            label={searchField.data}
                            fieldName={searchField.name}
                            changeData={(value, fieldName) => changeData(value, fieldName)}
                            handleKeyDown={keyDownSubmit}
                            value={searchField.name === 'values' ? EMPTY_STRING_VALUE : formData[searchField.name]}
                            isHidden={false}
                        />
                    </div>
            }

            return formField;

        };
        return filteredFields.map(filteredSearchFieldsMapper);
    };

    if (loaded && filteredFields && filteredFields.length > 0) {
        return (
            <div className="repertoireModal">
                <div className={"modal"}>
                    <div>
                        <div className="closeButton" onClick={hideModal}>
                            <img src={"assets/close.svg"} className="closeIcon" />
                        </div>
                    </div>
                    <div className="fixedPart" />
                    <div className="dynamicPart">
                        <div className="title">{modalTitle}</div>
                        <div className="newWorkButton" title="Create New Work" style={{ paddingRight: "24px" }}>
                            <div className="IconTextButton" onClick={handleAddNewWork}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                New Work
                            </div>
                        </div>
                        <div className="modalBody">
                            <div className="searchView">
                                <div key='searchFields' className="row">
                                    {renderSearchFields()}
                                </div>
                                <div key='action' className="row">
                                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                                        <ActionButton buttonText="Search" buttonAction={onClickSearch} />
                                    </div>
                                </div>
                                {searchSuccessful && <div key='results'>
                                    <DisplayTableSearchResults
                                        searchSuccessful={searchSuccessful}
                                        searchResultsTableData={filteredSearchResults}
                                        searchResults={worksSearchResults}
                                        indexOfFirstResult={indexOfFirstResult}
                                        indexOfLastResult={indexOfLastResult}
                                        resultsPerPage={resultsPerPage}
                                        currentPage={currentPage}
                                        updatePagination={updateUserPagination}
                                        lookupValues={lookupValues}
                                        resetPagination={resetPagination}
                                        rowActions={rowActions}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="fixedPart" />
                </div>
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default UsageWorkSearchModalView;