import { MemberPortalSearchType } from "../../../redux/types/MemberPortalSearchType";
import ActionButton from "../../../repertoire/components/actionButton/ActionButton";
import { SizedCheckboxDataInput } from "../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import SizedDateDataInput from "../../../repertoire/components/dateDataInput/DateDataInput";
import { SizedDropdownDataInput } from "../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import SearchResultsTable from "../../../repertoire/components/searchResultsTable/SearchResultsTable";
import SearchView, { ISearchViewProps } from "../../../repertoire/components/searchView/SearchView";
import SelectionTable from "../../../repertoire/components/selectionTable/SelectionTable";
import { SizedTextDataInput } from "../../../repertoire/components/textDataInput/TextDataInput";
import { SEARCH_VIEW_PRODUCTS } from "../../../repertoire/Consts";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import { EMPTY_STRING_VALUE, SEARCH_VIEW_DRAFT_SET_LISTS, SET_LIST_MODAL_SEARCH_VIEW } from "../../Consts";
import { IDraftSetListSearchQuery } from "../../types/IDraftSetListSearchQuery";
import { IMembersPortalField } from "../../types/IMembersPortalField";

export default class DraftSetListSearchView extends SearchView {
    constructor(props: ISearchViewProps) {
        super(props);

        super.state = {
            tableContents: [],
            draftSetListID: "",
            draftSetListStatus: "",
            draftSetListTitle: "",
        };
    }

    renderSearchFieldsDraftSetList = () => {
        const { searchViewData, componentInstance, memberPortalSearchType } = this.props;

        if (searchViewData && searchViewData.fields) {

            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === componentInstance
            );

            let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
                let reactElement: React.ReactElement = <></>;

                if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal'))
                    reactElement =
                        <div key={index}>
                            <SizedTextDataInput
                                fieldType={searchField.fieldType}
                                useEnterSubmit={true}
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={this.disabledSearchFields(searchField.name) ? this.changeDataDisabled : (value, fieldName) => this.changeTextData(value, fieldName)}
                                handleKeyDown={this.keyDownSubmit}
                                value={this.state[searchField.name]}
                                readOnly={this.disabledSearchFields(searchField.name)}
                                isHidden={searchField.hidden}
                            />
                        </div>;
                else if (searchField.fieldType === 'date')
                    reactElement =
                        <div key={index}>
                            <SizedDateDataInput
                                value={this.state[searchField.name]}
                                fieldName={searchField.name}
                                changeData={this.changeData}
                                label={searchField.data}
                                isHidden={searchField.hidden}
                            />
                        </div>
                else if (searchField.fieldType === 'checkbox')
                    reactElement =
                        <div key={index}>
                            <SizedCheckboxDataInput
                                label={searchField.data}
                                fieldName={searchField.name}
                                value={this.state[searchField.name]}
                                changeData={this.changeData}
                                readonly={false}
                            />
                        </div>
                else {
                    let options: { code: string, description: string }[] = [];
                    let disabledDropDown = false;
                    if (memberPortalSearchType === MemberPortalSearchType.MyDraftSearch)
                        options.push({ code: "DRAFT", description: "Draft" }, { code: "DRAFTSUB", description: "Draft Submitted" })
                    else
                        options.push({ code: "REGISTERED", description: "Registered" })
                    reactElement =
                        <div key={index}>
                            <SizedDropdownDataInput
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={this.changeData}
                                value={memberPortalSearchType === MemberPortalSearchType.MyDraftSearch ? this.state[searchField.name] : 'Registered'}
                                options={options}
                                allowNull={true}
                                isHidden={searchField.hidden}
                                componentInstance={componentInstance}
                                readOnly={disabledDropDown}
                            />
                        </div>
                }

                return reactElement;

            };

            return filteredSearchFields.map(filteredSearchFieldsMapper);
        }
    };

    onClickSearch = () => {
        const {
            setPageOne,
            draftSetListID,
            draftSetListStatus,
            draftSetListTitle
        } = this.state;
        const {
            dataSource,
            resetPagination,
            searchDraftSetLists,
            memberPortalSearchType,
            currentUserNameNumber
        } = this.props;
        resetPagination(this.props.componentInstance);
        this.setState({ setPageOne: setPageOne + 1 })

        let searchQuery: IDraftSetListSearchQuery = {
            title: draftSetListTitle == "" ? null : draftSetListTitle,
            status: memberPortalSearchType === MemberPortalSearchType.MyRegisteredSearch ? "REGISTERED" : draftSetListStatus == "" ? null : draftSetListStatus,
            currentUserNameNumber: currentUserNameNumber,
            setListID: draftSetListID == "" ? null : draftSetListID,
            dataSource: dataSource == "" ? null : dataSource,
        }
        searchDraftSetLists(searchQuery)
    }

    renderSearchResults() {
        const {
            searchResultsTableData,
            componentInstance,
            searchSuccessful,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            updatePagination,
            lookupValues,
            sortSearchResults,
            draftSetListsSearchResults,
            addSetListToLivePerformance,
            getDraftSetListDetails,
            hideModal
        } = this.props;

        const { setPageOne } = this.state;

        if (searchSuccessful &&
            (!!draftSetListsSearchResults && draftSetListsSearchResults.length <= 0)) {
            return (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                        No results found.
                    </div>
                </div>
            )
        } else if (draftSetListsSearchResults && draftSetListsSearchResults.length >= 1) {
            return (<div className="row">
                {componentInstance === SET_LIST_MODAL_SEARCH_VIEW ?
                    <SelectionTable
                        key={setPageOne}
                        searchResultsTableData={searchResultsTableData}
                        tableContents={draftSetListsSearchResults}
                        componentInstance={componentInstance}
                        sourceItem={undefined}
                        addSetListToLivePerformance={addSetListToLivePerformance}
                        hideModal={hideModal}
                        expandedResults={undefined}
                        expandAll={undefined}
                        expandResult={undefined}
                        expandAllResults={undefined}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        updatePagination={updatePagination}
                        repertoireSection={SEARCH_VIEW_PRODUCTS}
                    /> :
                    <div className="col-md-12 searchResultsDiv">
                        <SearchResultsTable
                            key={setPageOne}
                            searchResultsTableData={searchResultsTableData}
                            tableContents={draftSetListsSearchResults}
                            componentInstance={componentInstance}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            updatePagination={updatePagination}
                            repertoireSection={SEARCH_VIEW_DRAFT_SET_LISTS}
                            sortSearchResults={sortSearchResults}
                            lookupValues={lookupValues}
                            getDraftSetListDetails={getDraftSetListDetails}
                        />

                    </div>}
            </div>)
        } else if (searchSuccessful === false) {
            return (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                    No results found.
                    </div>
                </div>
            )
        }
    }
    render() {
        const { searchDisabled } = this.props

        return (
            <div className="searchView">
                <div key='searchFields' className="row">
                    {this.renderSearchFieldsDraftSetList()}
                </div>
                <div key='action' className="row">
                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                        <ActionButton buttonText="Search" buttonAction={this.onClickSearch} disabled={searchDisabled} />
                    </div>
                </div>
                <div key='results'>
                    {this.renderSearchResults()}
                </div>
            </div>
        )
    }
}