import React from "react";
import { WORK_PERFORMER_LOOKUP } from "../../../lookup/Consts";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { showModal } from "../../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import {
    ADMINISTERED_BY_KEY,
    AGREEMENTS_IP_KEY,
    APPLICABLE_WORKS_KEY,
    APPLICABLE_WORK_NAME_STATE_KEY,
    AGREEMENT_CARVEOUTS_KEY,
    AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY,
    ARTISTS_KEY,
    ARTIST_TYPE_STATE_KEY,
    CONTRIBUTORS_KEY,
    DATA_GRID_TABLE_FIELDS_EXPANDER,
    DISTRIBUTION_POOLS_KEY,
    DISTRIBUTION_POOL_SELECTION_CRITERIA_STATE_KEY,
    IPI_NUMBER_STATE_KEY,
    MATCHING_REPERTOIRE_PRODUCTS_KEY,
    MATCHING_REPERTOIRE_PRODUCT_NAME_STATE_KEY,
    MATCHING_REPERTOIRE_WORKS_KEY,
    MATCHING_REPERTOIRE_WORK_NAME_STATE_KEY,
    PRODUCT_CONTRIBUTORS_KEY,
    PRODUCT_PERFORMERS_KEY,
    PRODUCT_PERFORMER_NAME_STATE_KEY,
    SELECTION_CRITERIA_VIEW,
    IP_REPRESENTATION_KEY,
} from "../../Consts";
import { RepertoireHelper } from "../../services/RepertoireHelper";
import { DataSource } from "../../types/DataSource";
import { IRepertoireField } from "../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import DropdownDataInput from "../dropdownDataInput/DropdownDataInput";
import ShowMore from "../showMore/ShowMore";
import TextDataInput from "../textDataInput/TextDataInput";
import { IShowMoreItem } from "../../types/IShowMoreItem";
import { DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY } from "../../../membersportal/Consts";

export interface IFieldOptionsProps {
    field: IRepertoireField;
    tableContentItem: any;
    index: number;
    dataGridTableData: IRepertoireComponentDataItem;
    stateKey: IRepertoireStateKeys;
    isReadOnly?: boolean;
    dataSource?: DataSource;
    lookupValues?: ILookupDictionary;
    changeTableData: (
        value: any,
        stateKey: IRepertoireStateKeys,
        objectKey: string,
        rowIndex: number,
        componentInstance?: string,
        newOrderValue?: string
    ) => void;
    openInNewTab?: (openItem: any) => void;
    showModal?: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    )=>void;
    addNewAccount?: () => void;
    showSearchModal?: (modalProps: any, searchViewKey: string, index?: number) => void;
}

export const FieldOptions: React.FunctionComponent<IFieldOptionsProps> = ({
    field,
    tableContentItem,
    index,
    dataGridTableData,
    stateKey,
    isReadOnly,
    dataSource,
    lookupValues,
    changeTableData,
    openInNewTab,
    showModal,
    addNewAccount,
    showSearchModal,
}) => {
    const expanderFields = dataGridTableData.fields.filter(
        (field: IRepertoireField) =>
            field.componentInstance === DATA_GRID_TABLE_FIELDS_EXPANDER
    );

    const renderField = () => {
        return stateKey === ARTISTS_KEY ? (
            <DropdownDataInput
                value={tableContentItem[field.name].value}
                changeData={(value, stateKey) =>
                    changeTableData(value, field.name, field.name, index)
                }
                fieldName={field.name}
                options={getLookupValues(WORK_PERFORMER_LOOKUP, lookupValues)}
                allowNull={false}
                isHidden={false}
            />
        ) : (
            <div className={stateKey==='draftAgreementsApplicableWorks'?"fullWidth":""}>
                <TextDataInput
                    value={
                        field.name === IPI_NUMBER_STATE_KEY
                            ? RepertoireHelper.zeroPaddedIPINameNumber(
                                tableContentItem[field.name].value
                            )
                            : stateKey === MATCHING_REPERTOIRE_WORKS_KEY ? tableContentItem[field.name][0].value
                                : stateKey === MATCHING_REPERTOIRE_PRODUCTS_KEY ? tableContentItem[field.name][0].value : tableContentItem[field.name].value
                    }
                    fieldName={field.name}
                    changeData={(value, stateKey) =>
                        changeTableData(value, field.name, field.name, index)
                    }
                    readOnly={!canEnterFreeText}
                    isHidden={false}
                />
            </div>
        );
    };

    const renderShowMore = () => {
        switch (stateKey) {
            case CONTRIBUTORS_KEY:
                let options: IShowMoreItem[] = [];
                if (tableContentItem[field.name].value || tableContentItem["accountNumber"].value) {
                    if (expanderFields.find(e => e.name === "openIP"))
                        options.push({
                            text: expanderFields.find((e) => e.name === "openIP").data,
                            onClick: () => {
                                openInNewTab(tableContentItem);
                            },
                            icon: "assets/external.svg",
                        });
                    if (expanderFields.find((e) => e.name === "selectIP") && !isReadOnly) {
                        options.push(
                            {
                                text: expanderFields.find((e) => e.name === "selectIP").data,
                                onClick: () => {
                                    showSearchModal(tableContentItem, CONTRIBUTORS_KEY);
                                },
                                icon: "assets/external.svg",
                            })
                    }
                    return (
                        <ShowMore
                            options={options}
                        />
                    )
                }
                else {
                    if (expanderFields.find(e => e.name === "newIP"))
                        options.push({
                            text: expanderFields.find((e) => e.name === "newIP").data,
                            onClick: () => { addNewAccount() },
                            icon: "assets/external.svg",
                        });
                    if (expanderFields.find((e) => e.name === "selectIP") && !isReadOnly) {
                        options.push(
                            {
                                text: expanderFields.find((e) => e.name === "selectIP").data,
                                onClick: () => {
                                    showSearchModal(tableContentItem, CONTRIBUTORS_KEY);
                                },
                                icon: "assets/external.svg",
                            })
                    }
                    return (
                        <ShowMore
                            options={options}
                        />
                    )
                }
            case PRODUCT_CONTRIBUTORS_KEY:
                return (
                    <ShowMore
                        options={!isReadOnly && [
                            {
                                text: expanderFields.find(e => e.name === "openIP").data,
                                onClick: () => { openInNewTab(tableContentItem) },
                                icon: "assets/external.svg"
                            },
                            {
                                text: expanderFields.find(e => e.name === "selectIP").data,
                                onClick: () => { showSearchModal(tableContentItem, PRODUCT_CONTRIBUTORS_KEY); },
                                icon: "assets/external.svg"
                            }
                        ]}
                    />
                )
            case APPLICABLE_WORKS_KEY:
                if (field.name === APPLICABLE_WORK_NAME_STATE_KEY) {
                    return (
                        <ShowMore
                            options={!isReadOnly && [
                                {
                                    text: expanderFields.find(e => e.name === "openWork").data,
                                    onClick: () => { openInNewTab(tableContentItem) },
                                    icon: "assets/external.svg"
                                },
                                {
                                    text: expanderFields.find(e => e.name === "selectWork").data,
                                    onClick: () => { showSearchModal(tableContentItem, APPLICABLE_WORKS_KEY); },
                                    icon: "assets/external.svg"
                                }
                            ]}
                        />
                    )
                }
                else {
                    return <div></div>
                }
                case AGREEMENT_CARVEOUTS_KEY:
                    if (field.name === AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY) {
                        return (
                            <ShowMore
                                options={!isReadOnly && [
                                    {
                                        text: expanderFields.find(e => e.name === "openProduct").data,
                                        onClick: () => { openInNewTab(tableContentItem) },
                                        icon: "assets/external.svg"
                                    },
                                    {
                                        text: expanderFields.find(e => e.name === "selectProduct").data,
                                        onClick: () => { showSearchModal(tableContentItem, AGREEMENT_CARVEOUTS_PRODUCT_NAME_STATE_KEY); },
                                        icon: "assets/external.svg"
                                    }
                                ]}
                            />
                        )
                    }
                else {
                    return <div></div>
                }
            case MATCHING_REPERTOIRE_WORKS_KEY:
                if (field.name === MATCHING_REPERTOIRE_WORK_NAME_STATE_KEY) {
                    return (
                        <ShowMore
                            options={!isReadOnly && [
                                {
                                    text: expanderFields.find(e => e.name === "openWork").data,
                                    onClick: () => { openInNewTab(tableContentItem) },
                                    icon: "assets/external.svg"
                                },
                                {
                                    text: expanderFields.find(e => e.name === "selectWork").data,
                                    onClick: () => { showSearchModal(tableContentItem, MATCHING_REPERTOIRE_WORKS_KEY); },
                                    icon: "assets/external.svg"
                                }
                            ]}
                        />
                    )
                }
                else {
                    return <div></div>
                }
            case MATCHING_REPERTOIRE_PRODUCTS_KEY:
                if (field.name === MATCHING_REPERTOIRE_PRODUCT_NAME_STATE_KEY) {
                    return (
                        <ShowMore
                            options={!isReadOnly && [
                                {
                                    text: expanderFields.find(e => e.name === "openProduct").data,
                                    onClick: () => { openInNewTab(tableContentItem) },
                                    icon: "assets/external.svg"
                                },
                                {
                                    text: expanderFields.find(e => e.name === "selectProduct").data,
                                    onClick: () => { showSearchModal(tableContentItem, MATCHING_REPERTOIRE_PRODUCTS_KEY); },
                                    icon: "assets/external.svg"
                                }
                            ]}
                        />
                    )
                }
                else {
                    return <div></div>
                }
            case ARTISTS_KEY:
                if (dataSource === DataSource.Intray && field.name === ARTIST_TYPE_STATE_KEY) {
                    let options: IShowMoreItem[] = [];
                    if (expanderFields.find(e => e.name === "selectArtist"))
                        options.push({
                            text: expanderFields.find(e => e.name === "selectArtist").data,
                            onClick: () => { showSearchModal(tableContentItem, ARTISTS_KEY); },
                            icon: "assets/external.svg"
                        });
                    if (expanderFields.find(e => e.name === "openArtist"))
                        options.push({
                            text: expanderFields.find(e => e.name === "openArtist").data,
                            onClick: () => { openInNewTab(tableContentItem) },
                            icon: "assets/external.svg"
                        },)
                    return (
                        <ShowMore
                            options={!isReadOnly && options}
                        />
                    )
                }
                else {
                    return <div></div>
                }
            case PRODUCT_PERFORMERS_KEY:
                if (dataSource === DataSource.Intray && field.name === PRODUCT_PERFORMER_NAME_STATE_KEY) {
                    return (
                        <ShowMore
                            options={!isReadOnly && [
                                {
                                    text: expanderFields.find(e => e.name === "openArtist").data,
                                    onClick: () => { openInNewTab(tableContentItem) },
                                    icon: "assets/external.svg"
                                },
                                {
                                    text: expanderFields.find(e => e.name === "selectArtist").data,
                                    onClick: () => { showSearchModal(tableContentItem, PRODUCT_PERFORMERS_KEY, index); },
                                    icon: "assets/external.svg"
                                }
                            ]}
                        />
                    )
                }
                else return <div></div>
            case AGREEMENTS_IP_KEY:
            case ADMINISTERED_BY_KEY:
                return (
                    <ShowMore
                        options={!isReadOnly && [
                            {
                                text: expanderFields.find(e => e.name === "openIP").data,
                                onClick: () => { openInNewTab(tableContentItem) },
                                icon: "assets/external.svg"
                            },
                            {
                                text: expanderFields.find(e => e.name === "selectIP").data,
                                onClick: () => { showSearchModal(tableContentItem, AGREEMENTS_IP_KEY); },
                                icon: "assets/external.svg"
                            }
                        ]}
                    />
                )
            case IP_REPRESENTATION_KEY:
                return (
                    <ShowMore
                        options={!isReadOnly && [
                            {
                                text: field.data,
                                onClick: () => {showSearchModal(tableContentItem,field.data,index)},
                                icon: "assets/external.svg"
                            }
                        ]}
                    />
                )
            case DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY:
                return (
                    <ShowMore
                        options={!isReadOnly && [
                            // {
                            //     text: 'Open Work in new tab',
                            //     onClick: () => { openInNewTab(tableContentItem) },
                            //     icon: "assets/external.svg"
                            // },
                            {
                                text: 'Select Work',
                                onClick: () => { showSearchModal(tableContentItem, DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY,index) },
                                icon: "assets/external.svg"
                            }
                        ]}
                    />
                )
            default:
                return <div></div>
        }
    };

    const canEnterFreeText: boolean =
        stateKey === PRODUCT_CONTRIBUTORS_KEY ||
        stateKey === PRODUCT_PERFORMERS_KEY ||
        stateKey === ARTISTS_KEY;

    let className: string = (stateKey === IP_REPRESENTATION_KEY && (field.data === 'Territory Include' || field.data === 'Territory Exclude') || (field.data === 'Pools Include' || field.data === 'Pools Exclude') || (stateKey==='draftAgreementsApplicableWorks' &&(field.data==='Work Title') ))?"territoryContainer":"tableCell hasIcon";
    let tdClassName: string = "";
    if (
        (field.componentInstance === "productContributor" ||
            field.componentInstance === "productPerformer") &&
        field.componentName === "DataGridTable" &&
        (field.name === "name" || field.name === "performerName")
    ) {
        className = `${className} noPadding`;
        tdClassName = "td";
    }

    return (
        //<td key={field.name} title={field.data} className={tdClassName}>
        <div className={className}>
            {renderField()}
            {renderShowMore()}
        </div>
        //</td>
    );
};
