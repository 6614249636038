import * as React from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";

export interface ICombinedTextAndButtonDataInputProps {
    label?: string;
    value?: any;
    changeData: (value: string | boolean, fieldName: IRepertoireStateKeys) => void;
    fieldName: IRepertoireStateKeys;
    useEnterSubmit?: boolean;
    handleKeyDown?: any;
    readOnly?: boolean;
    buttonLabel: string;
    buttonAction: () => void;
    isEnabled: boolean;
}

export default class CombinedTextAndButtonDataInput extends React.PureComponent<ICombinedTextAndButtonDataInputProps> {
    constructor(props: ICombinedTextAndButtonDataInputProps) {
        super(props);
    }

    render() {
        const {
            label,
            value,
            changeData,
            fieldName,
            handleKeyDown,
            useEnterSubmit,
            readOnly,
            buttonAction,
            buttonLabel,
            isEnabled
        } = this.props;

        return (
            <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                <div>
                    {label ? <label>{label}:</label> : null}
                    </div>
                <div className='combinedTextAndButtonDataInput'>
                        {useEnterSubmit ?
                            <input
                                className={"textInput"}
                                type={"text"}
                                value={value || ''}
                                onChange={event => changeData(event.target.value, fieldName)}
                                onKeyDown={event => handleKeyDown(event, fieldName)}
                            /> : (readOnly ?
                                <input
                                    className={"textInput"}
                                    type={"text"}
                                    value={value || ''}
                                    onChange={event => changeData(event.target.value, fieldName)}
                                    disabled
                                /> :
                                <input
                                    className={"textInput"}
                                    type={"text"}
                                    value={value || ''}
                                    onChange={event => changeData(event.target.value, fieldName)}
                                />)
                        }
                    <div>
                        <button onClick={isEnabled ? buttonAction : null}
                            className={isEnabled ? 'enabledButton' : 'disabledButton'}>
                            {buttonLabel}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

