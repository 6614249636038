import * as React from "react";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import {
    STATUS_STATE_KEY,
    PUBLIC_DOMAIN_STATE_KEY,
    CONTROLLED_COMPOSITION_STATE_KEY,
    PC_SPECIFIC_STATE_KEY,
    PD_ARRANGEMENT_STATE_KEY,
    PD_ADL_STATE_KEY,
    PD_ADM_STATE_KEY,
    NON_ROYALTY_BEARING_STATE_KEY,
    SET_TYPE_GROUP_KEY,
    TERRITORY_SPECIFIC_INFO_STATE_KEY,
    WORK_DOMESTIC_FOREIGN_STATE_KEY,
    WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY,
    WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY,
    EMPTY_STRING_VALUE,
    REGION_STATE_KEY,
    SUB_STATUS_STATE_KEY,
    WORK_MAINTENANCE_TERRITORY_SPECIFIC_INFO_VIEW,
    GENRE_CATEGORY_STATE_KEY,
    GENRE,
    DEFAULT_RETAINED_UNTIL_STATE_KEY 
} from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { ITerritorySpecificInfo } from "../../../types/ITerritorySpecificInfo";
import { getLookupValues, getLookupDefault } from "../../../../lookup/services/LookupHelpers";
import { IWorkTerritorySpecificInfo } from "../../../types/IWorkTerritorySpecificInfo";
import { switchSetTypeGroup } from "../../../../redux/reducers/RepertoireReducer";
import { DOMESTIC_OR_FOREIGN, SET_TYPE_GROUP_LOOKUP, WORK_STATUS_LOOKUP, WORK_SUB_STATUS_LOOKUP, WORK_GENRE_CATEGORY_LOOKUP, WORK_GENRE_LOOKUP } from "../../../../lookup/Consts";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { Dictionary } from "../../../../core/types/Dictionary";
import { READONLY_FLAG_DOMESTIC_OR_FOREIGN } from "../../../ConfigurationConsts";

export interface IWorkMaintenanceTerritorySpecificInfoViewProps {
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    territorySpecificInfo: ITerritorySpecificInfo;
    changeData?: (
        value: string | boolean | ITerritorySpecificInfo,
        fieldName: IRepertoireStateKeys
    ) => void;
    lookupValues: ILookupDictionary;
    switchSetTypeGroup: typeof switchSetTypeGroup;
    readOnly: boolean;
    workGenre: string;
    genreCategoryLookup: Dictionary<Array<string>>;
    workType: string;
    genreCategoriesLookup: Dictionary<Array<string>>;
    filterGenreCategories: boolean;
    pdArrangementMusicLyric?: boolean;
    recalculateDomesticorForeign: boolean;
}

interface IWorkMaintenanceTerritorySpecificInfoViewState {
    loaded: boolean;
    domesticOrForeign: string;
}

export default class WorkMaintenanceTerritorySpecificInfoView extends React.PureComponent<
    IWorkMaintenanceTerritorySpecificInfoViewProps,
    IWorkMaintenanceTerritorySpecificInfoViewState
    > {
    viewData;
    constructor(props: IWorkMaintenanceTerritorySpecificInfoViewProps) {
        super(props);

        this.state = {
            loaded: false,
            domesticOrForeign: null
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            workMaintenanceGeneralDataViewData: { fields }
        } = this.props;
        const visibleFields = fields.filter(field => !field.hidden);
        visibleFields.forEach(item => {
            this.viewData[item.name] = item.data;

            if (Object.keys(this.viewData).length === visibleFields.length) {
                this.setState({ loaded: true });
            }
        });
        this.changeWorkTerritorySpecificInfo.bind(this);
        this.changeWorkTerritorySpecificInfo(this.props.territorySpecificInfo.setTypeGroup, SET_TYPE_GROUP_KEY);
    }

    changeWorkTerritorySpecificInfo(
        value: string | string[] | boolean,
        fieldName: IRepertoireStateKeys
    ): void {
        const info: IWorkTerritorySpecificInfo[] = [];
        this.props.territorySpecificInfo.workTerritorySpecificInfo.map(wp => info.push(Object.assign({}, wp)));
        const setTypeGroup = fieldName === SET_TYPE_GROUP_KEY && value ? value.toString() : this.props.territorySpecificInfo.setTypeGroup;
        let selectedInfo: IWorkTerritorySpecificInfo = info.find(wp => wp.setTypeGroup === setTypeGroup);
        if (!selectedInfo) {
            selectedInfo = {
                workTerritorySpecificInfoID: 0,
                setTypeGroup: setTypeGroup,
                workStatus: getLookupDefault(WORK_STATUS_LOOKUP, this.props.lookupValues),
                workSubStatus: getLookupDefault(WORK_SUB_STATUS_LOOKUP, this.props.lookupValues),
                isOutOfCopyright: false,
                isControlledComposition: false,
                isPCSpecific: false,
                isPDArrangement: false,
                isPDADM: false,
                isPDADL:false,
                isNonRoyaltyBearing: false,
                domesticOrForeign: null,
                validForUsageFrom: null,
                validForUsageTo: null,
                genreCategory: getLookupDefault(WORK_GENRE_CATEGORY_LOOKUP, this.props.lookupValues)
            };
            info.push(selectedInfo);
            if (fieldName !== SET_TYPE_GROUP_KEY) {
                selectedInfo[fieldName] = value;
            }

            this.props.changeData({ workTerritorySpecificInfo: info, setTypeGroup }, TERRITORY_SPECIFIC_INFO_STATE_KEY);
        }
        else {
            if (fieldName === SET_TYPE_GROUP_KEY) {
                this.props.switchSetTypeGroup(setTypeGroup);
            }
            else {
                selectedInfo[fieldName] = value;
                this.props.changeData({ workTerritorySpecificInfo: info, setTypeGroup }, TERRITORY_SPECIFIC_INFO_STATE_KEY);
                if (fieldName === "domesticOrForeign") {
                    this.setState({ domesticOrForeign: selectedInfo.domesticOrForeign });
                }
            }
        }
    }

    genreCategoryDropDownLookup = (): ILookupInstance[] => {
        const { lookupValues, workGenre, workType, genreCategoryLookup, genreCategoriesLookup, filterGenreCategories } = this.props;
        const { domesticOrForeign } = this.state;
        let genreCategories = getLookupValues(WORK_GENRE_CATEGORY_LOOKUP, lookupValues);

        if (filterGenreCategories) {
            if (genreCategoryLookup) {
                if (workType in genreCategoryLookup) {
                    genreCategories = genreCategories.filter(gc => genreCategoryLookup[workType].includes(gc.code));
                }
            }
            if (workGenre) {
                let options = genreCategories ? genreCategories.filter(type => type.extraFields[GENRE] === workGenre) : null

                if (genreCategoriesLookup) {
                    if (domesticOrForeign in genreCategoriesLookup) {
                        genreCategories = genreCategories.filter(category => genreCategoriesLookup[domesticOrForeign].includes(category.code));
                        return genreCategories.sort((a, b) => a.description.localeCompare(b.description));
                    }
                    return options;
                }
            }
            if (!workGenre && domesticOrForeign && domesticOrForeign === "FOR") {
                return genreCategories.filter(x => x.code === "SZ3N");
            }
        }

        return genreCategories.sort((a, b) => a.description.localeCompare(b.description));
    }


    render() {
        const { territorySpecificInfo, lookupValues, readOnly, pdArrangementMusicLyric,recalculateDomesticorForeign } = this.props;
        const selectedInfo = territorySpecificInfo.workTerritorySpecificInfo.find(wp => wp.setTypeGroup === territorySpecificInfo.setTypeGroup);
        if (!selectedInfo) {
            return null;
        }
        const tr = !!this.viewData[SET_TYPE_GROUP_KEY];
        const {
            setTypeGroup,
            isControlledComposition,
            isPCSpecific,
            isPDArrangement,
            isPDADM,
            isPDADL,
            isNonRoyaltyBearing,
            isOutOfCopyright,
            workStatus,
            workSubStatus,
            domesticOrForeign,
            validForUsageFrom,
            validForUsageTo,
            genreCategory
        } = selectedInfo;

        const { loaded } = this.state;
        const historyClass = readOnly ? 'row versionHistoryPointer ' : 'row';
        if (loaded) {
            return (
                <div>
                    <div className="row">
                        <SizedDropdownDataInput
                            label={this.viewData[REGION_STATE_KEY]}
                            fieldName={SET_TYPE_GROUP_KEY}
                            value={setTypeGroup}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            options={getLookupValues(SET_TYPE_GROUP_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={false}
                            isHidden={!this.viewData[REGION_STATE_KEY]}
                            componentInstance={WORK_MAINTENANCE_TERRITORY_SPECIFIC_INFO_VIEW}
                        />
                        <div className={readOnly ? 'versionHistoryPointer' : undefined}>
                        <SizedDropdownDataInput
                            label={this.viewData[STATUS_STATE_KEY]}
                            fieldName={STATUS_STATE_KEY}
                            value={workStatus}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            options={getLookupValues(WORK_STATUS_LOOKUP, lookupValues)}
                            allowNull={true}
                            readOnly={readOnly}
                            isHidden={!this.viewData[STATUS_STATE_KEY]}
                        />
                        </div>
                        {workStatus == "I" && 
                            <div className={readOnly ? 'versionHistoryPointer': undefined}>
                                <SizedDropdownDataInput 
                                    label={this.viewData[SUB_STATUS_STATE_KEY]}
                                    fieldName={SUB_STATUS_STATE_KEY}
                                    value={workSubStatus}
                                    changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                                    options={getLookupValues(WORK_SUB_STATUS_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={readOnly}
                                    isHidden={!this.viewData[SUB_STATUS_STATE_KEY]}
                                />
                            </div>
                        }
                    <div className={readOnly ? 'versionHistoryPointer' : undefined}>   
                        <SizedDropdownDataInput
                            label={this.viewData[WORK_DOMESTIC_FOREIGN_STATE_KEY]}
                            fieldName={WORK_DOMESTIC_FOREIGN_STATE_KEY}
                            value={domesticOrForeign}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            options={getLookupValues(DOMESTIC_OR_FOREIGN, lookupValues)}
                            allowNull={true}
                            readOnly={recalculateDomesticorForeign}
                            isHidden={!this.viewData[WORK_DOMESTIC_FOREIGN_STATE_KEY]}
                            />
                        </div>
                        <div className={readOnly ? 'versionHistoryPointer' : undefined}>
                            <SizedDropdownDataInput
                                label={this.viewData[GENRE_CATEGORY_STATE_KEY]}
                                fieldName={GENRE_CATEGORY_STATE_KEY}
                                value={genreCategory}
                                changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                                options={this.genreCategoryDropDownLookup()}
                                allowNull={true}
                                readOnly={readOnly}
                                isHidden={!this.viewData[GENRE_CATEGORY_STATE_KEY]}
                            />
                        </div>
                        <div className={readOnly ? 'versionHistoryPointer' : undefined}>
                        <SizedDateDataInput
                            label={this.viewData[WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY]}
                            fieldName={WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY}
                            value={validForUsageFrom ? validForUsageFrom.toString() : EMPTY_STRING_VALUE}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readOnly={readOnly}
                            isHidden={!this.viewData[WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY]}
                            />
                        </div>
                        <div className={readOnly ? 'versionHistoryPointer' : undefined}>
                        <SizedDateDataInput
                            label={this.viewData[WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY]}
                            fieldName={WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY}
                            value={validForUsageTo ? validForUsageTo.toString() : EMPTY_STRING_VALUE}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readOnly={readOnly}
                            isHidden={!this.viewData[WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY]}
                            />
                        </div>
                    </div>
                    <div className={historyClass}>

                        <SizedCheckboxDataInput
                            label={this.viewData[PUBLIC_DOMAIN_STATE_KEY]}
                            fieldName={PUBLIC_DOMAIN_STATE_KEY}
                            value={isOutOfCopyright}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readonly={readOnly}
                            isHidden={!this.viewData[PUBLIC_DOMAIN_STATE_KEY]}
                        />
                        <SizedCheckboxDataInput
                            label={this.viewData[NON_ROYALTY_BEARING_STATE_KEY]}
                            fieldName={NON_ROYALTY_BEARING_STATE_KEY}
                            value={isNonRoyaltyBearing}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readonly={readOnly}
                            isHidden={!this.viewData[NON_ROYALTY_BEARING_STATE_KEY]}
                        />
                        <SizedCheckboxDataInput
                            label={this.viewData[PD_ARRANGEMENT_STATE_KEY]}
                            fieldName={PD_ARRANGEMENT_STATE_KEY}
                            value={isPDArrangement}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readonly={readOnly}
                            isHidden={!this.viewData[PD_ARRANGEMENT_STATE_KEY]}
                        />
                        {pdArrangementMusicLyric &&
                         <SizedCheckboxDataInput
                            label={this.viewData[PD_ADM_STATE_KEY]}
                            fieldName={PD_ADM_STATE_KEY}
                            value={isPDADM}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readonly={readOnly}
                            isHidden={!this.viewData[PD_ADM_STATE_KEY]}
                        />}                      
                        {pdArrangementMusicLyric && 
                        <SizedCheckboxDataInput
                            label={this.viewData[PD_ADL_STATE_KEY]}
                            fieldName={PD_ADL_STATE_KEY}
                            value={isPDADL}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readonly={readOnly}
                            isHidden={!this.viewData[PD_ADL_STATE_KEY]}
                        />}

                        <SizedCheckboxDataInput
                            label={this.viewData[PC_SPECIFIC_STATE_KEY]}
                            fieldName={PC_SPECIFIC_STATE_KEY}
                            value={isPCSpecific}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readonly={readOnly}
                            isHidden={!this.viewData[PC_SPECIFIC_STATE_KEY]}
                        />
                        <SizedCheckboxDataInput
                            label={this.viewData[CONTROLLED_COMPOSITION_STATE_KEY]}
                            fieldName={CONTROLLED_COMPOSITION_STATE_KEY}
                            value={isControlledComposition}
                            changeData={this.changeWorkTerritorySpecificInfo.bind(this)}
                            readonly={readOnly}
                            isHidden={!this.viewData[CONTROLLED_COMPOSITION_STATE_KEY]}
                        />

                    </div>
                </div>
            );
        }
        return <div />;
    }
}
