import { Input, Space, Table } from 'antd';
import crypto from 'crypto';
import React from 'react';
import { showModal } from '../../../../redux/reducers/ModalReducer';
import { updateAdjustmentField } from '../../../../redux/reducers/RepertoireReducer';
import { IAdjustmentDetails } from '../../../../redux/types/IAdjustmentDetails';
import { IAdjustmentState } from '../../../../redux/types/IAdjustmentState';
import IRepertoireComponentDataItem from '../../../../redux/types/IRepertoireComponentDataItem';
import ActionButton, { SizedActionButton } from '../../../components/actionButton/ActionButton';
import IconTextButton from '../../../components/iconTextButton/IconTextButton';
import ShowMore from '../../../components/showMore/ShowMore';
import { ALLOCATION_ACCOUNT_NUMBER, ALLOCATION_ADJUSTMENT_IP_KEY, ALLOCATION_AMOUNT_ADJUSTED, ALLOCATION_DISTRIBUTION, ALLOCATION_HEADER_ID, ALLOCATION_IPI_NUMBER, ALLOCATION_IP_NAME, ALLOCATION_POINT_ADJUSTED, ALLOCATION_POOL, ALLOCATION_SEGMENT, ALLOCATION_SHARE_ADJUSTED, ALLOCATION_WORK_NAME, ALLOCATION_WORK_NUMBER, REMOVE_ACTION, SEARCH_VIEW } from '../../../Consts';
import { AllocationHeaderRowType } from '../allocationSelectionView/AllocationSelectionView';

interface IAllocationAdjustmentDetailsViewProps {
    allocationAdjustmentDetailsViewData: IRepertoireComponentDataItem;
    adjustment: IAdjustmentState;
    updateAdjustmentField: typeof updateAdjustmentField;
    showModal: typeof showModal;
    allocationRecords: AllocationHeaderRowType[];
}

interface AllocationAdjustmentDetailsRowType {
    key: React.Key;
    direction: string;
    allocationHeaderID?: number;
    adjustmentDetailID: number;
    [adjustmentDetailID: number]: number;
    distribution: string;
    pool: string;
    segment: string;
    workNumber: string;
    workName: string;
    accountNumber: string;
    name: string;
    ipiNumber: number;
    sharePctAdj: string;
    pointsAdj?: string;
    amountAdj: string;
}

interface IAllocationAdjustmentDetailsViewState {
    loaded: boolean;
}

export class AllocationAdjustmentDetailsView extends React.Component<
    IAllocationAdjustmentDetailsViewProps,
    IAllocationAdjustmentDetailsViewState> {

    viewData;
    constructor(props: IAllocationAdjustmentDetailsViewProps) {
        super(props);
        this.state = {
            loaded: false
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            allocationAdjustmentDetailsViewData: { fields }
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;
            });

            if (Object.keys(this.viewData).length === visibleFields.length) {
                this.setState({ loaded: true });
            }
        }
    }

    onInputChange = (key, index, id) => (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { adjustment: { adjustmentDetails }, updateAdjustmentField } = this.props;
        const adjustmentDetailsUpdate = adjustmentDetails;
        if (key == 'ipNumber')
            adjustmentDetailsUpdate[index].adjustmentDetailTo.find(x => x.adjustmentDetailID === id)[key] = +e.target.value;
        else
            adjustmentDetailsUpdate[index].adjustmentDetailTo.find(x => x.adjustmentDetailID === id)[key] = e.target.value;
        updateAdjustmentField(adjustmentDetailsUpdate, 'adjustmentDetails');
    };

    showSearchModal = (modalProps: any) => {
        const { showModal } = this.props;
        showModal(SEARCH_VIEW, ALLOCATION_ADJUSTMENT_IP_KEY, modalProps, true, "Search");
    };

    onClickRemove = (direction: string, index: number, detailsIndex?: number) => {
        const { updateAdjustmentField, adjustment: { adjustmentDetails } } = this.props;
        let updatedDetails = adjustmentDetails;

        if (adjustmentDetails[index]) {
            if (direction === 'From') {
                if (updatedDetails[index].adjustmentDetailFrom.length === 1)
                    updatedDetails.splice(index, 1);
                else
                    updatedDetails[index].adjustmentDetailFrom.splice(detailsIndex, 1);
            }
            else if (direction === 'To') {
                updatedDetails[index].adjustmentDetailTo.splice(detailsIndex, 1)
            }
        }
        updateAdjustmentField(updatedDetails, 'adjustmentDetails');
    };

    addRowToAdjustmentDetail = (index) => {
        const { adjustment, adjustment: { adjustmentDetails }, updateAdjustmentField } = this.props;
        let updatedAdjustmentDetails = adjustmentDetails;
        let updatedAdjustmentDetail = updatedAdjustmentDetails[index];
        updatedAdjustmentDetails[index].adjustmentDetailTo.push({
            adjustmentDetailID: index,
            adjustmentId: adjustment.id,
            allocationHeaderID: updatedAdjustmentDetail.id,
            distribution: adjustment.adjustmentDistribution,
            pool: adjustment.adjustmentPool,
            segment: adjustment.adjustmentSegment,
            workNumber: updatedAdjustmentDetail.adjustmentDetailFrom[0].workNumber,
            workName: updatedAdjustmentDetail.adjustmentDetailFrom[0].workName,
            accountNumber: '',
            name: '',
            ipNumber: undefined,
            sharePercentAdjusted: '',
            pointsAdjusted: '',
            amountAdjusted: ''
        });
        updateAdjustmentField(updatedAdjustmentDetails, 'adjustmentDetails');
    }

    applyAdjustmentToAllSelectedAllocationRecords = (adjustmentDetail: IAdjustmentDetails) => {
        const { adjustment, adjustment: { adjustmentDetails }, allocationRecords, updateAdjustmentField } = this.props;

        allocationRecords?.filter(x => x.IsSelected).forEach((value) => {

            const validateThatAdjustmentDetailIsAlreadyInAllocatedDetails = () => {
                return adjustmentDetails.some(x => x.id === value.AllocationUsageHeaderId);
            }

            const validateThatAdjustmentDetailsAreSimilarEnoughToBeCopied = (comparisonAdjDet: AllocationHeaderRowType, existingAdjDet: IAdjustmentDetails) => {
                const existingFromIps = existingAdjDet.adjustmentDetailFrom.map(x => x.ipNumber);
                const comparisonFromIps = comparisonAdjDet.UsageDetails.map(x => x[1]);

                return existingFromIps.every(r => comparisonFromIps.includes(r));
            }

            if (validateThatAdjustmentDetailIsAlreadyInAllocatedDetails()) return;
            if (!validateThatAdjustmentDetailsAreSimilarEnoughToBeCopied(value, adjustmentDetail)) return;

            const fromIps = adjustmentDetail.adjustmentDetailFrom.map(x => x.ipNumber);
            const usageDetails = value.UsageDetails.filter(x => fromIps.includes(x[1]));


            const newAllocationDetail: IAdjustmentDetails = {
                id: value.AllocationUsageHeaderId,
                adjustmentDetailFrom: usageDetails.map((item, index) => ({
                    adjustmentId: adjustment.id,
                    distribution: adjustment.sourceDistribution,
                    segment: adjustment.sourceSegment,
                    allocationHeaderID: item[0],
                    ipNumber: item[1],
                    accountNumber: item[3],
                    name: item[4],
                    amountAdjusted: (item[5] * 1).toString(),
                    pointsAdjusted: (item[6] * 1).toString(),
                    sharePercentAdjusted: (item[7] * 1).toString(),
                    pool: adjustmentDetail.adjustmentDetailFrom[0]['pool'],
                    workNumber: value.WorkNumber,
                    workName: value.WorkName
                })),
                adjustmentDetailTo: adjustmentDetail.adjustmentDetailTo.map((obj) => ({ ...obj }))
            }

            newAllocationDetail.adjustmentDetailTo.forEach(x => {
                const adj = newAllocationDetail.adjustmentDetailFrom[0];
                const amount = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.amountAdjusted) }, 0);
                const newDetailShares = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.sharePercentAdjusted) }, 0);
                const existingDetailShares = adjustmentDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.sharePercentAdjusted) }, 0);

                const newDetailPointsAdjusted = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.pointsAdjusted) }, 0);
                const existingDetailPointsAdjusted = adjustmentDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.pointsAdjusted) }, 0);



                x.allocationHeaderID = adj.allocationHeaderID;
                x.distribution = adjustment.adjustmentDistribution;
                x.segment = adj.segment;
                x.pool = adjustment.adjustmentPool;
                x.workNumber = adj.workNumber;
                x.workName = adj.workName;
                x.sharePercentAdjusted = ((Number(x.sharePercentAdjusted) / existingDetailShares) * newDetailShares).toString();
                x.pointsAdjusted = ((Number(x.pointsAdjusted) / existingDetailPointsAdjusted) * newDetailPointsAdjusted).toString();
                x.amountAdjusted = (amount * (Number(x.sharePercentAdjusted) / newDetailShares)).toString()
            })

            adjustmentDetails.push(newAllocationDetail);
            updateAdjustmentField(adjustmentDetails, 'adjustmentDetails');
        })
    }

    render() {
        const { adjustment, adjustment: { adjustmentDetails } } = this.props;
        const { loaded } = this.state;

        let tableList = [];
        if (loaded) {
            if (adjustmentDetails && adjustmentDetails.length > 0) {

                let totalAmount = 0;
                let totalPoints = 0;
                let totalSharePercentage = 0;

                adjustmentDetails.forEach((adjustmentDetail, index) => {
                    let data: AllocationAdjustmentDetailsRowType[] = [];

                    let adjustmentDetailIdOrigin = null;
                    if ((adjustmentDetail.adjustmentDetailFrom != null) && (adjustmentDetail.adjustmentDetailFrom.length > 0))
                        adjustmentDetailIdOrigin = adjustmentDetail.adjustmentDetailFrom[0];
                    if ((adjustmentDetailIdOrigin == null) && (adjustmentDetail.adjustmentDetailTo != null) && (adjustmentDetail.adjustmentDetailTo.length > 0))
                        adjustmentDetailIdOrigin = adjustmentDetail.adjustmentDetailTo[0];
                    
                    adjustmentDetail.id = adjustmentDetailIdOrigin ? adjustmentDetailIdOrigin.allocationHeaderID : null;
                    adjustmentDetail.adjustmentDetailFrom.forEach((adjustmentDetailFrom, i) => {
                        data.push({
                            key: i === 0 ? `${adjustmentDetailFrom.allocationHeaderID}_999_From` : `${adjustmentDetailFrom.allocationHeaderID}_${(i * -1)}_From`,
                            direction: 'From',
                            allocationHeaderID: adjustmentDetailFrom.allocationHeaderID,
                            distribution: adjustmentDetailFrom.distribution,
                            pool: adjustmentDetailFrom.pool,
                            segment: adjustmentDetailFrom.segment,
                            workNumber: adjustmentDetailFrom.workNumber,
                            workName: adjustmentDetailFrom.workName,
                            accountNumber: adjustmentDetailFrom.accountNumber,
                            name: adjustmentDetailFrom.name,
                            ipiNumber: adjustmentDetailFrom.ipNumber,
                            sharePctAdj: adjustmentDetailFrom.sharePercentAdjusted,
                            pointsAdj: adjustmentDetailFrom.pointsAdjusted,
                            amountAdj: adjustmentDetailFrom.amountAdjusted,
                            adjustmentDetailID: i
                        });
                        totalAmount += parseFloat(adjustmentDetailFrom.amountAdjusted)
                        totalPoints += parseFloat(adjustmentDetailFrom.pointsAdjusted)
                        totalSharePercentage += parseFloat(adjustmentDetailFrom.sharePercentAdjusted)
                    });

                    adjustmentDetail.adjustmentDetailTo.forEach((adjustmentDetailTo, i) => {

                        let pointsAdjusted = 
                            parseFloat(adjustmentDetailTo.sharePercentAdjusted) <= 100 && parseFloat(adjustmentDetailTo.sharePercentAdjusted) >= 0 ?
                            ((parseFloat(adjustmentDetailTo.sharePercentAdjusted) / totalSharePercentage) * totalPoints).toString() : "0"
                        let amountAdjusted = 
                            parseFloat(adjustmentDetailTo.sharePercentAdjusted) <= 100 && parseFloat(adjustmentDetailTo.sharePercentAdjusted) >= 0 ?
                            ((parseFloat(adjustmentDetailTo.sharePercentAdjusted) / totalSharePercentage) * totalAmount).toString() : "0"

                        data.push({
                            key: i === 0 ? `${adjustmentDetailTo.allocationHeaderID}_999_To` : `${adjustmentDetailTo.allocationHeaderID}_${(i*-1)}_To`,
                            direction: 'To',
                            allocationHeaderID: adjustmentDetailTo.allocationHeaderID,
                            distribution: adjustmentDetailTo.distribution,
                            pool: adjustmentDetailTo.pool,
                            segment: adjustmentDetailTo.segment,
                            workNumber: adjustmentDetailTo.workNumber,
                            workName: adjustmentDetailTo.workName,
                            accountNumber: adjustmentDetailTo.accountNumber,
                            name: adjustmentDetailTo.name,
                            ipiNumber: adjustmentDetailTo.ipNumber,
                            sharePctAdj: adjustmentDetailTo.sharePercentAdjusted,
                            pointsAdj: pointsAdjusted,
                            amountAdj: amountAdjusted,
                            adjustmentDetailID: i
                        });
                        adjustmentDetailTo.adjustmentDetailID = i;
                    });

                    let columns = [
                        {
                            title: '', dataIndex: 'direction', key: 'direction', render: (_, value, index) => {
                                const text = value.direction;
                                return <><Space size="middle"><strong>{text}</strong></Space></>
                            },
                        },
                        { title: this.viewData[ALLOCATION_HEADER_ID], dataIndex: ALLOCATION_HEADER_ID, key: ALLOCATION_HEADER_ID },
                        { title: this.viewData[ALLOCATION_DISTRIBUTION], dataIndex: ALLOCATION_DISTRIBUTION, key: ALLOCATION_DISTRIBUTION },
                        { title: this.viewData[ALLOCATION_POOL], dataIndex: ALLOCATION_POOL, key: ALLOCATION_POOL },
                        { title: this.viewData[ALLOCATION_SEGMENT], dataIndex: ALLOCATION_SEGMENT, key: ALLOCATION_SEGMENT },
                        {
                            title: this.viewData[ALLOCATION_WORK_NUMBER], dataIndex: ALLOCATION_WORK_NUMBER, key: ALLOCATION_WORK_NUMBER, render: (text, record, index) => {
                                return <><div className={"widerFieldNumber"}><span>{record.workNumber}</span></div></>
                            }
                        },
                        {
                            title: this.viewData[ALLOCATION_WORK_NAME], dataIndex: ALLOCATION_WORK_NAME, key: ALLOCATION_WORK_NAME, render: (text, record, index) => {
                                return <><div className={"widerField"}><span>{record.workName}</span></div></>
                            }
                        },
                        {
                            title: this.viewData[ALLOCATION_ACCOUNT_NUMBER], dataIndex: ALLOCATION_ACCOUNT_NUMBER, key: ALLOCATION_ACCOUNT_NUMBER, render: (text, record, index) => {
                                return <Input title={this.viewData[ALLOCATION_ACCOUNT_NUMBER]} className={"widerFieldNumber"} value={record.accountNumber} readOnly={true} onChange={this.onInputChange("accountNumber", index, record.adjustmentDetailID)} />
                            }
                        },
                        {
                            title: this.viewData[ALLOCATION_IP_NAME], dataIndex: ALLOCATION_IP_NAME, key: ALLOCATION_IP_NAME, render: (text, record, index) => {
                                return <Input title={this.viewData[ALLOCATION_IP_NAME]} className={"widerField"} value={record.name} readOnly={true} onChange={this.onInputChange("name", index, record.adjustmentDetailID)} />
                            }
                        },
                        {
                            title: this.viewData[ALLOCATION_IPI_NUMBER], dataIndex: ALLOCATION_IPI_NUMBER, key: ALLOCATION_IPI_NUMBER, render: (text, record) => (
                                <div title={this.viewData[ALLOCATION_IPI_NUMBER]} className='hasIcon'>
                                    <Input className={"widerFieldNumber"} value={record.ipiNumber} readOnly={record.direction === 'From'} type={'number'} onChange={this.onInputChange("ipNumber", index, record.adjustmentDetailID)} />
                                    {(record.direction === 'To' && adjustment.dataSource !== 'Posted') &&
                                        <ShowMore
                                            options={[
                                                {
                                                    text: 'Select IP',
                                                    onClick: () => { this.showSearchModal({ adjustmentDetails: adjustmentDetails[index], index: record.adjustmentDetailID }); },
                                                    icon: "assets/external.svg"
                                                }
                                            ]}
                                        />}
                                </div>
                            )
                        },
                        {
                            title: this.viewData[ALLOCATION_SHARE_ADJUSTED], dataIndex: ALLOCATION_SHARE_ADJUSTED, key: ALLOCATION_SHARE_ADJUSTED, render: (text, record) => (
                                <Input title={this.viewData[ALLOCATION_SHARE_ADJUSTED]} className={"percentageField"} value={record.sharePctAdj} readOnly={record.direction === 'From'} type={'number'} onChange={this.onInputChange("sharePercentAdjusted", index, record.adjustmentDetailID)} />
                            )
                        },
                        {
                            title: this.viewData[ALLOCATION_POINT_ADJUSTED], dataIndex: ALLOCATION_POINT_ADJUSTED, key: ALLOCATION_POINT_ADJUSTED, render: (text, record) => (
                                <Input title={this.viewData[ALLOCATION_POINT_ADJUSTED]} value={record.pointsAdj} readOnly={true} type={'number'} onChange={this.onInputChange("pointsAdjusted", index, record.adjustmentDetailID)} />
                            )
                        },                        {
                            title: this.viewData[ALLOCATION_AMOUNT_ADJUSTED], dataIndex: ALLOCATION_AMOUNT_ADJUSTED, key: ALLOCATION_AMOUNT_ADJUSTED, render: (text, record) => (
                                <Input title={this.viewData[ALLOCATION_AMOUNT_ADJUSTED]} className={"widerFieldNumber"} value={record.amountAdj} readOnly={true} type={'number'} onChange={this.onInputChange("amountAdjusted", index, record.adjustmentDetailID)} />
                            )
                        },
                        {
                            title: '', dataIndex: REMOVE_ACTION, key: REMOVE_ACTION, render: (text, record) => (
                                record.direction === 'From' ?
                                    <div className="tableCell">
                                        <IconTextButton onClick={() => this.onClickRemove('From', index, record.adjustmentDetailID)} text={'Remove'} icon={"icon ms-Icon ms-Icon--Delete"} disabled={adjustment.dataSource === 'Posted'} />
                                    </div>
                                    :
                                    <div>
                                        <IconTextButton onClick={() => this.onClickRemove('To', index, record.adjustmentDetailID)} text={'Remove'} icon={"icon ms-Icon ms-Icon--Delete"} disabled={adjustment.dataSource === 'Posted'} />
                                    </div>
                            )
                        }
                    ]

                    tableList.push(
                        <div key={index}>
                            <div className="row">
                                <div className="col-md-6">
                                    {index === 0 &&
                                        <span className="title">Adjustment Details for Selected Allocation Records</span>
                                    }

                                </div>
                                <div className="col-md-3">
                                    <ActionButton
                                        buttonAction={() => null}
                                        buttonText={'Search Work for Adjustment'}
                                        disabled={adjustment.dataSource === 'Posted'}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <ActionButton
                                        buttonAction={() => this.applyAdjustmentToAllSelectedAllocationRecords(adjustmentDetail)}
                                        buttonText={'Apply Adjustment to All Selected Allocation Records'}
                                        disabled={adjustment.dataSource === 'Posted'}
                                    />
                                </div>
                            </div>
                            <div className='tableContainer'>
                                <Table
                                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                />
                                <div className='row'>
                                    <SizedActionButton buttonAction={() => this.addRowToAdjustmentDetail(index)} buttonText={'Add New'} disabled={adjustment.dataSource === 'Posted'} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            return <div>{tableList}</div>
        }
        else return (<div></div>)
    }
}

export default (AllocationAdjustmentDetailsView);