import { Form, Input, InputRef, Select, Table } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormInstance } from "antd/lib/form/Form";
import { SizedActionButton } from "../../../components/actionButton/ActionButton";
import { WORK_NUMBER_LOOKUP } from "../../../../lookup/Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import CheckboxDataInput from "../../../components/checkboxDataInput/CheckboxDataInput";
import DropdownDataInput from "../../../components/dropdownDataInput/DropdownDataInput";
import TextDataInput, { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { INumberAdditional } from "../../../types/INumberAdditional";
import InvalidAdditionalNumbersAlert from "./InvaildAdditionalNumberAlert";

export interface IWorkNumberAdditionalTable {
    dataGridTableData: any,
    lookupValues: any,
    data: any,
    changeData: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    saveAdditionalWorkNumbers?: (pageNumber: number, additionalNumbers: INumberAdditional[]) => void;
    getCurrentPageDetails: (pageNumber: number) => void;
    workId: any;
}

interface Item {
    key: string;
    name: string;
    age: string;
    address: string;
  }

  interface DataType {
    key: React.Key;
    name: string;
    age: string;
    address: string;
  }

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
    index: number;
  }
  
  const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    options: any[];
    handleSave: (record: Item) => void;
  }
  
  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    options,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const selectRef = useRef(null);
    const form = useContext(EditableContext)!;
  
    useEffect(() => {
      if (editing) {
        if(title === 'Number') {
            inputRef.current!.focus();
        }
        if (title === 'Type') {
            selectRef.current!.focus();
        }
      }
    }, [editing]);
  
    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };
  
    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({ ...record, ...values });
      } catch (errInfo) {}
    };
  
    let childNode = children;

    const valueToDisplayForTheSelect = title === 'Type' && options && options.length > 0 && children && children[1] ? options.find(option => option.value === children[1])?.label : undefined;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
        >
          { title === 'Number' ? <Input ref={inputRef} onPressEnter={save} onBlur={save} /> :
          <Select
          ref={selectRef}
          onBlur={save}
      style={{
        width: 120,
      }}
      options={options}
    />}
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" style={{ paddingRight: 24, width: '120px', height: '40px' }} onClick={() => toggleEdit()}>
          {title === 'Type' && valueToDisplayForTheSelect ? [children[0], valueToDisplayForTheSelect] : children}
        </div>
      );
    }
  
    return <td {...restProps}>{childNode}</td>;
  };

const WorkNumberAdditionalTable: React.FC<IWorkNumberAdditionalTable> = ({
    dataGridTableData,
    lookupValues,
    data,
    changeData,
    saveAdditionalWorkNumbers,
    getCurrentPageDetails,
    workId
}) => {
    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(0);
    const [pageNumbersList, setPageNumbersList] = useState([1]);
    const [pageNumber, setPageNumber] = useState(1);
    const [showValidationBanner, setShowValidationBanner] = useState(false);

      const handleDelete = (key: React.Key) => {
        const dataForApi = dataSource.map(item => {
          return {
            ...item,
            isDeleted: item.key === key ? !item.isDeleted : item.isDeleted
          }
        })
        setDataSource(dataForApi)
        updateReduxState(dataForApi);
      };

      const handleAdd = () => {
        const newCount = count + 1;
        setDataSource([getEmptyRow(newCount), ...dataSource]);
        setCount(newCount);
        setPageNumber(1);
      };

      const getDropDownOptions = () => {
        if (lookupValues && lookupValues[WORK_NUMBER_LOOKUP] && lookupValues[WORK_NUMBER_LOOKUP].lookups) {
        let workNumberAdditionalLookup = lookupValues[WORK_NUMBER_LOOKUP].lookups.filter(x => { return x.extraFields.CoreOrExternal == 'Both' || x.extraFields.CoreOrExternal == 'External'})
        const workNumberOptions = workNumberAdditionalLookup.map(option => {
            return {
                code: option.code,
                description: option.description
            }
        }).sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()));
        return workNumberOptions;
    }
    return [];
      }


    useEffect(() => {
        if (dataGridTableData && data && data.length !== 0) {
          setCount(data.length);
          const fetchedData = getData(data);
            setDataSource(fetchedData)
            return;
        } else {
            setDataSource([])
        }
        setCount(count + 1);

    }, [dataGridTableData, data]);

    const resetStateWhenAdditionalNumbersAreClosed = () => {
      setDataSource([])
      setCount(0);
      setPageNumbersList([1])
      if (data && data.length !== 0) {
      const fetchedData = getData(data);

      let dataForApi = fetchedData.map((line, index) => {
        if (index <= 10) {
        return {numberAdditional: {
            value: line.number, 
            inputType: 'text',
        },
        type: {
            value: line.type,
            inputType: 'dropdown',
        },
        workNumberAdditionalID: line.workNumberAdditionalID,
        isDeleted: line.isDeleted
    }
  } });
    changeData(dataForApi, 'additionalNumbers')
      }
    }

    useEffect(() => {
      return () => {
        resetStateWhenAdditionalNumbersAreClosed()
      };
    }, [])

    const handleDropDownChange = (value, key) => {
      const updatedData = dataSource.map(item => { 
        return {
          ...item,
          type: item.key === key ? value : item.type,
      }
      })
      setDataSource(updatedData)
      updateReduxState(updatedData)

    }

    const isNumberValidForISWC = (value) => {
      var additionalNumberRegex = /^T\d{10}$/; // Work additional number format error. Work additional number should match this format: [T][0-9]{10}, eg T0625246801
      return additionalNumberRegex.test(value);
    }

    const changeNumber = (value, key) => {
      const updatedData = dataSource.map(item => {
        return {
          ...item,
          number: item.key === key ? value : item.number,
          isNumberValid: item.key === key ? isLineValidISWC(item) : item.isValidNumber,
      }
      })
      setDataSource(updatedData)
      updateReduxState(updatedData)
    }


    const getColumns = () => {
        let fields = dataGridTableData.fields.filter(x => x.componentInstance == 'additionalNumbers')
        const columns: any = [
        {
            title: fields[0].data,
            dataIndex: fields[0].name,
            key: `${fields[0].name}${1}`,
            render: (_, record: { key: React.Key, isDeleted: boolean, type: {value: string}, number: string }) => {
              return dataSource.length >= 1 ? (<DropdownDataInput
                fieldName={`${fields[0].name}`}
                value={record.type && record.type ? record.type : ''}
                changeData={(value, fieldName) => handleDropDownChange(value, record.key)}
                options={getDropDownOptions()}
                allowNull={true}
                readOnly={false}
                isHidden={false}
                />) : <></>;
            }
          },
          {
            title: fields[1].data,
            dataIndex: fields[1].name,
            key: `${fields[0].name}${2}`,
            render: (_, record: { key: React.Key, isDeleted: boolean, type: string, number: string, isNumberValid?: boolean}) => {
              return dataSource.length >= 1 ? (
                <div className={record.isNumberValid ? 'emptyValidationBorder' : 'validationForkmikField'}>
                <TextDataInput
                useEnterSubmit={true}
                fieldName={fields[0].name}
                handleKeyDown={() => {}}
                changeData={(value, fieldName) => changeNumber(value, record.key)}
                value={record.number ? record.number : ''}
                isHidden={false}
            />
            </div>
              ) : <></>;
            }
          },
          {
            title: fields[2].data,
            dataIndex: fields[2].name,
            key: `${fields[0].name}${3}`,
        render: (_, record: { key: React.Key, isDeleted: boolean }) =>
              dataSource.length >= 1 ? (
                <div className="additionalNumbersCheckbox">
                <CheckboxDataInput
                  label=''
                  value={record.isDeleted}
                  changeData={(value, fieldName) => handleDelete(record.key)}
                  fieldName=''
                /></div>) : null,
          }
        ]
        return columns.map((col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
                onCell: (record: DataType) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                options: getDropDownOptions(),
                handleSave,
              }),
            };
          });
    }

    const getEmptyRow = (newCount) => {
        return {
            key: `${newCount}`,
            type: '',
            numberAdditional: '',
            workNumberAdditionalID: 0,
            isDeleted: false,
            isNumberValid: true
        }
    }

    const getData = (dataSources) => {
        return dataSources && dataSources.length > 0 ? dataSources.map((line, index) => {  
        return {
            key: index,
            type: line.type.value,
            number: line.numberAdditional.value,
            workNumberAdditionalID: line.workNumberAdditionalID,
            isDeleted: line.isDeleted,
            isNumberValid: isLineValidISWC(line),
        }
    }): [getEmptyRow(count)] }

    const updateReduxState = (data) => {
      let dataForApi = data.map(line => {
        return {numberAdditional: {
            value: line.number, 
            inputType: 'text',
        },
        type: {
            value: line.type,
            inputType: 'dropdown',
        },
        workNumberAdditionalID: line.workNumberAdditionalID,
        isDeleted: line.isDeleted
    }});
    changeData(dataForApi, 'additionalNumbers')
    }

    const isLineValidISWC = (line) => {
      if (line?.type?.value !== "ISWC") {
        return true;
      } 
      return isNumberValidForISWC(line.numberAdditional.value)
    }

    const isLineValid = (line) => {
      if (!line.type || !line.type.value || line.type.value !== "ISWC") {
        return true;
      };
      return isNumberValidForISWC(line.numberAdditional.value);
    }

    const isAllAdditionalNumbersValid = (lines) => {
      return !(lines.filter(line => !isLineValid(line))?.length && lines.filter(line => !isLineValid(line))?.length > 0);
    }
    const saveAdditionalWorkNumber = () => {
      let dataForApi = dataSource.filter(line => !(!line.number || line.number === '' || !line.type || line.type === '') ).map(line => {
          return {numberAdditional: {
              value: line.number, 
              inputType: 'text',
          },
          type: {
              value: line.type,
              inputType: 'dropdown',
          },
          workNumberAdditionalID: line.workNumberAdditionalID,
          isDeleted: line.isDeleted
      }});
      dataForApi = dataForApi.filter(line => line.numberAdditional.value !== '' && line.type.value !== '');
      changeData(dataForApi, 'additionalNumbers')
      if (isAllAdditionalNumbersValid(dataForApi)) {
      const pageNumber = pageNumbersList.sort((a,b)=>a-b).reverse()[0];
      saveAdditionalWorkNumbers(pageNumber, dataForApi);
      setShowValidationBanner(false);
      } else {
        setShowValidationBanner(true);
      }
    }

    const getCurrentPageDetail = (pagination) => {
      let pageNumberList = pageNumbersList;
      setPageNumber(pagination.current);
      if(!pageNumberList.find(x => x === pagination.current)){
        pageNumberList.push(pagination.current);
        setPageNumbersList(pageNumberList);
        getCurrentPageDetails(pagination.current);
      }
    }
    const invalidAdditionalNumbersAlert = dataGridTableData.fields.find(field => field.name === "invalidAdditionalNumbersAlert");
    const handleSave = (row: DataType) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);

        const dataForApi = newData.map(line => {
            return {numberAdditional: {
                value: line.number, 
                inputType: 'text',
            },
            type: {
                value: line.type,
                inputType: 'dropdown',
            },
            workNumberAdditionalID: line.workNumberAdditionalID,
            isDeleted: line.isDeleted
        }});
        changeData(dataForApi, 'additionalNumbers')
      };

        return dataGridTableData && dataSource ? <div className='flexColumn'>
          {showValidationBanner && <InvalidAdditionalNumbersAlert text={invalidAdditionalNumbersAlert && invalidAdditionalNumbersAlert.data} />}
          <div className='flexRow'>
                     <SizedActionButton
                buttonText={'Add Number'}
                buttonAction={() => handleAdd()}
            />
                       {workId !== 0 && dataSource.length != 0 ? <SizedActionButton
                buttonText={'Save'}
                buttonAction={() => saveAdditionalWorkNumber()}
            /> : null}
            </div>
            <Table
                columns={getColumns()}
                dataSource={dataSource}
                rowClassName={() => 'editable-row'}
                size={'middle'}
                className="parent-table"
                onChange={getCurrentPageDetail}
                pagination={{
                  current: pageNumber,
                  pageSize: 10,
                  showSizeChanger: false,
                }}
            />
        </div> : <>Loading...</>}

export default WorkNumberAdditionalTable;