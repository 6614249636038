import * as React from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import { BOOK_EXPORT_JOB_TYPE, CWR_GENERATION_JOB_TYPE } from "../../Consts";
import { HIGH_WATER_MARK, INCLUDE_AGREEMENTS, LINK_TO_IPBASENUMBER, LOOKUP_WORKSOURCE, LOOKUP_WORKSTATUS, SCHEDULE, SEARCH_VIEW, SEARCH_VIEW_JOB_IP, SEPARATE_PUBLISHED, SHARE_SET } from "../../../repertoire/Consts";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import ActionButton, { InlineActionButton, SizedActionButton } from "../../../repertoire/components/actionButton/ActionButton";
import { showModal } from "../../../redux/reducers/ModalReducer";
import TextDataInput from "../../../repertoire/components/textDataInput/TextDataInput";
import {
    triggerManualJob,
    upsertScheduledJobAsync,
} from "../../../redux/thunks/HomeThunks";
import DatePickerDataInput from "../../../repertoire/components/dateDataInput/DatePickerDataInput";
import { ICwrgParameters } from "../../../redux/types/ICwrgParameters";
import { SizedCheckboxDataInput } from "../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import { If } from "../../../core/components/If";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import DropdownDataInput from "../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { SET_TYPE_LOOKUP } from "../../../lookup/Consts";

export interface ICWRGenerationJobParametersProps {
    lookupValues: ILookupDictionary;
    showModal: typeof showModal;
    globalManualJobParams: any;
    upsertScheduledJobAsync: typeof upsertScheduledJobAsync;
    createNewJob: () => any;
    getCWRGHighWaterMark?: (cwrgParams: ICwrgParameters) => void;
    cwrgHighWaterMark?: any;
    setCWRGHighWaterMark?: (hwm: string) => void;
    triggerManualJob: typeof triggerManualJob;
    manualJobParameters: ITriggerManualJob;
    updateManualJobParams?: (params: any) => void;
    getSeparatePublishedCheckbox?: () => void;
    separatePublishedCheckbox?: boolean;
}


const CWRGenerationJobParameters = (props: ICWRGenerationJobParametersProps) => {

    const [sources, setSources] = React.useState<string[]>([]);
    const [statuses, setStatuses] = React.useState<string[]>([]);
    const [schedule, setScheduleState] = React.useState('')
    const [linkToIPBaseNumber, setLinkToIPBaseNumber] = React.useState(false)
    const [separatePublished, setSeparatePublished] = React.useState(false)
    const [includeAgreements, setIncludeAgreements] = React.useState(false)
    const [shareSet, setShareSet] = React.useState('')

    const getGlobalParamValues = (): string => {
        const { globalManualJobParams } = props;

        let ipNameNumber = '';
        if (globalManualJobParams !== '') {
            const jobParams = JSON.parse(globalManualJobParams.jobParameters);
            ipNameNumber = jobParams.ipNameNumber;
        }
        return ipNameNumber
    }

    let ipNameNumber = getGlobalParamValues();

    React.useEffect(() => {
        const { getSeparatePublishedCheckbox } = props;
        getSeparatePublishedCheckbox();
    }, [])

    React.useEffect(() => {
        const { getCWRGHighWaterMark, cwrgHighWaterMark } = props;
        const highWaterMark = cwrgHighWaterMark;
        if (ipNameNumber === '') ipNameNumber = 'All';
        const cwrgParams: ICwrgParameters = {
            sources,
            statuses,
            ipNameNumber,
            highWaterMark,
            schedule,
            linkToIPBaseNumber,
            separatePublished
        };
        getCWRGHighWaterMark(cwrgParams);
    }, [ipNameNumber])

    const showSearchModal = () => {
        const { showModal } = props;

        showModal(SEARCH_VIEW, SEARCH_VIEW_JOB_IP, {
            ipNameNumber: ipNameNumber,
        }, true, "Search");
    };

    const getMultiSelectOptions = (lookupType: string) => {
        const { lookupValues } = props;

        var lookups = lookupValues[lookupType].lookups.filter((x) => x.code !== "NULL" && x.code !== null);

        const options = [];
        
        if (lookups) {
            lookups.map((c) => options.push({
                data: c.code,
                text: c.description,
                key: c.code
            }));
            options.sort((a, b) => a.text.localeCompare(b.text));
        }
        options.unshift({ key: "All", text: "All", data: "*" });
        return options;
    }

    const handleChangeMultiSelect = (lookupType: string, option?: IDropdownOption) => {

        let newOptions = [];
        if (lookupType === LOOKUP_WORKSOURCE) {
            newOptions = sources;
        } else if (lookupType === LOOKUP_WORKSTATUS) {
            newOptions = statuses;
        }

        let allOptions = getMultiSelectOptions(lookupType).map(x => x.key);
        allOptions = allOptions.filter(x => x !== "All");
        if (option.key == "All")
        {
            if (newOptions.length == allOptions.length) { 
                newOptions = [];
            }
            else {
                newOptions = [];
                newOptions.push(...allOptions);
            }
        }
        else if (option.selected) {
            newOptions.push(option.key.toString());
        }
        else {
            newOptions.splice(newOptions.indexOf(option.key.toString()), 1);
        }

        if (lookupType === LOOKUP_WORKSOURCE) {
            setSources([...newOptions]);
        } else if (lookupType === LOOKUP_WORKSTATUS) {
            setStatuses([...newOptions]);
        }

        const { getCWRGHighWaterMark, cwrgHighWaterMark } = props;
        const highWaterMark = cwrgHighWaterMark;
        if (ipNameNumber === '') ipNameNumber = 'All';
        const cwrgParams: ICwrgParameters = {
            sources,
            statuses,
            ipNameNumber,
            highWaterMark,
            schedule,
            linkToIPBaseNumber,
            separatePublished
        };
        getCWRGHighWaterMark(cwrgParams);
    };


    const handleScheduleChange = (value: string | boolean | number, fieldname: string) => {
        setScheduleState(value.toString());
    }


    const handleHighWaterMarkChange = (value: string | boolean | Date, fieldname: string) => {
        const { setCWRGHighWaterMark } = props;
        if(value) setCWRGHighWaterMark(value.toString());
    }

    const setSchedule = () => {
        const { upsertScheduledJobAsync, createNewJob, cwrgHighWaterMark } = props;
        
        const highWaterMark = cwrgHighWaterMark;
        if (ipNameNumber === '') ipNameNumber = 'All';

        let scheduledJob = {
            cronSchedule: schedule,
            errorList: [],
            lastRunDate: null,
            parameters: JSON.stringify({
                sources,
                statuses,
                ipNameNumber,
                highWaterMark,
                linkToIPBaseNumber,
                separatePublished,
                includeAgreements,
                shareSet,
            }),
            type: CWR_GENERATION_JOB_TYPE
        }
        if (scheduledJob) {
            upsertScheduledJobAsync(scheduledJob);
            createNewJob();
        }
    }

    const changeLinkToIPBaseNumber = (value, name) => {
        setLinkToIPBaseNumber(value);
    }

    const changeSeparatePublished = (value, name) => {
        setSeparatePublished(value);
    }

    const changeIncludeAgreements = (value, name) => {
        setIncludeAgreements(value);
    }

    const onChangeShareSet = (value, name) => {
        setShareSet(value);
    }

    const onClickSave = () => {
        const { triggerManualJob, createNewJob, updateManualJobParams } = props;
        const { cwrgHighWaterMark } = props;
        if (ipNameNumber === '') ipNameNumber = 'All';

        const highWaterMark = cwrgHighWaterMark;

        const manualJobParameters: ITriggerManualJob = {
            jobType: CWR_GENERATION_JOB_TYPE,
            jobParameters: JSON.stringify({
                sources,
                statuses,
                ipNameNumber,
                highWaterMark,
                linkToIPBaseNumber,
                separatePublished,
                includeAgreements,
                shareSet,
            }),
        };
        createNewJob();
        triggerManualJob(manualJobParameters);
        if (updateManualJobParams) {
            updateManualJobParams('');
        }
    };

    const { cwrgHighWaterMark, lookupValues } = props;

    return (
        <>
            <div className="row">
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>Source</label>
                    <Dropdown
                        selectedKeys={sources}
                        options={getMultiSelectOptions(LOOKUP_WORKSOURCE)}
                        multiSelect={true}
                        onChange={(e, o, i) => handleChangeMultiSelect(LOOKUP_WORKSOURCE, o)}
                        placeholder={""}
                        disabled={false} />
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>Status</label>
                    <Dropdown
                        selectedKeys={statuses}
                        options={getMultiSelectOptions(LOOKUP_WORKSTATUS)}
                        multiSelect={true}
                        onChange={(e, o, i) => handleChangeMultiSelect(LOOKUP_WORKSTATUS, o)}
                        placeholder={""}
                        disabled={false} />
                </div> 
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <label>IP Name Number</label>
                    <div className="wrapperLookup">

                        <input
                            readOnly
                            type={"text"}
                            value={ipNameNumber}
                            maxLength={150}
                        />

                        <InlineActionButton buttonText="Lookup" className="buttonLookup"
                            buttonAction={() => showSearchModal()}
                        />
                    </div>
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <DatePickerDataInput
                        value={cwrgHighWaterMark}
                        fieldName={HIGH_WATER_MARK}
                        changeData={handleHighWaterMarkChange}
                        label={HIGH_WATER_MARK}
                        isHidden={false}
                        readOnly={false}
                    />
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <SizedCheckboxDataInput
                        label={LINK_TO_IPBASENUMBER}
                        fieldName={LINK_TO_IPBASENUMBER}
                        value={linkToIPBaseNumber}
                        changeData={changeLinkToIPBaseNumber}
                        readonly={false} />
                </div> 
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <SizedCheckboxDataInput
                        label={INCLUDE_AGREEMENTS}
                        fieldName={INCLUDE_AGREEMENTS}
                        value={includeAgreements}
                        changeData={changeIncludeAgreements}
                        readonly={false} />
                </div>
                <div className="form-group col-md-6 col-sm-6 col-xs-12">
                    <DropdownDataInput
                        value={shareSet}
                        label={SHARE_SET}
                        changeData={onChangeShareSet}
                        fieldName={SHARE_SET}
                        options={getLookupValues(SET_TYPE_LOOKUP, lookupValues).filter(x => x.typeID < 0)}
                        allowNull={false}
                        isHidden={false}
                    ></DropdownDataInput>
                </div>
                <If condition={props.separatePublishedCheckbox}>
                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                        <SizedCheckboxDataInput
                            label={SEPARATE_PUBLISHED}
                            fieldName={SEPARATE_PUBLISHED}
                            value={separatePublished}
                            changeData={changeSeparatePublished}
                            readonly={false} />
                    </div>
                </If>
                <div className="form-group col-md-4 col-sm-6 col-xs-12">
                    <TextDataInput
                        label={SCHEDULE}
                        fieldName={SCHEDULE}
                        changeData={handleScheduleChange}
                        value={schedule}
                        isHidden={false}
                        isMandatory={false}
                    />
                </div>
                <div style={{ marginTop: 12 }} className="form-group col-md-2 col-sm-4 col-xs-12">
                    <ActionButton buttonText={"Set Schedule"} buttonAction={setSchedule} />
                </div>
            </div>

            <div className="row">
                <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                    <ActionButton buttonText={"Save And Close"} buttonAction={() => onClickSave()} />
                </div>
            </div>
        </>
    );
    
}

export default CWRGenerationJobParameters;

