import * as React from "react";
import { If } from "../../core/components/If";
import { IFileSystem } from "../../dataingestion/types/IFileSystem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IUsagePool } from "../../repertoire/types/usageTypes/IUsagePool";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";
import { IParameterSearchResult } from "../types/IParameterSearchResult";
import { SourceEditor } from "./SourceEditor";

interface ISourceHeaderProps {
  selectSourceName: string;
  selectSourceCode: string;
  sourceDescription: string;
  rejectDuplicateUsageFiles: boolean;
  usageIngestionFolderName?: IParameterSearchResult;
  usageIngestionDefaultPool?: IParameterSearchResult;
  usageIngestionDefaultDistribution?: IParameterSearchResult;
  usageIngestionDefaultInputFileFormat?: IParameterSearchResult;
  usageIngestionDefaultOutputFileFormat?: IParameterSearchResult;
  usageIngestionDefaultDestination?: IParameterSearchResult;
  usageIngestionAutoProcess?: IParameterSearchResult;
  customNotebook?: IParameterSearchResult;
  sourceID: number;
  isEditing: boolean;
  editSourceClick: () => void;
  saveSourceChangesClick: (
    sourceID: number,
    sourceName: string,
    sourceCode: string,
    sourceDescription: string,
    rejectDuplicateUsageFiles: boolean,
    usageConfigurationParameters: IParameterSearchResult[]
  ) => void;
  cancelSourceChangesClick: () => void;
  onToggleSidebarCollapsedClick: () => void;
  updateRoleDisabled?: boolean;
  distributions?: IDistribution[];
  pools?: IUsagePool[];
  lookupValues?: ILookupDictionary;
  folderSelectedNode: ITreeNodeData<IFileSystem>;
  sources?: ITreeData[];
  sorcesFolderList?: IParameterSearchResult[];
}

interface ISourceHeaderState {}

export class SourceHeader extends React.Component<
  ISourceHeaderProps,
  ISourceHeaderState
> {
  ComponentName: string = "SourceEditor";
  constructor(props: ISourceHeaderProps) {
    super(props);
  }

  render() {
    return (
      <div>
        {/* Selected source header */}
        <If condition={!this.props.isEditing}>
          <If condition={this.props.selectSourceName != null}>
            <div className="row">
              <div className="col-md-12">
                <button
                  type="button"
                  id="sidebarCollapse"
                  className="navbar-toggle btnLateralmenu"
                  onClick={this.props.onToggleSidebarCollapsedClick}
                >
                                <i className="glyphicon glyphicon-cog" />
                                Settings Menu
                </button>
                <h3>{this.props.selectSourceName}</h3>
                <button
                    className="editBtn"
                    onClick={this.props.editSourceClick}
                    hidden={this.props.updateRoleDisabled}
                >
                  <i className="glyphicon glyphicon-pencil" title="Edit" />
                </button>
                <p>{this.props.sourceDescription}</p>
              </div>
            </div>
          </If>
        </If>

        {/* Edit source header */}
            <If condition={this.props.isEditing}>
                <If condition={this.props.selectSourceName != null}>
                    <SourceEditor
                        selectSourceName={this.props.selectSourceName}
                        selectSourceCode={this.props.selectSourceCode}
                        sourceDescription={this.props.sourceDescription}
                        sourceID={this.props.sourceID}
                        saveChanges={this.props.saveSourceChangesClick}
                        cancelChanges={this.props.cancelSourceChangesClick}
                        rejectDuplicateUsageFiles={this.props.rejectDuplicateUsageFiles}
                        usageIngestionFolderName={this.props.usageIngestionFolderName}
                        usageIngestionDefaultPool={this.props.usageIngestionDefaultPool}
                        usageIngestionDefaultDistribution={this.props.usageIngestionDefaultDistribution}
                        usageIngestionDefaultInputFileFormat={this.props.usageIngestionDefaultInputFileFormat}
                        usageIngestionDefaultOutputFileFormat={this.props.usageIngestionDefaultOutputFileFormat}
                        usageIngestionDefaultDestination={this.props.usageIngestionDefaultDestination}
                        usageIngestionAutoProcess={this.props.usageIngestionAutoProcess}
                        distributions={this.props.distributions}
                        pools={this.props.pools}
                        lookupValues={this.props.lookupValues}
                        folderSelectedNode={this.props.folderSelectedNode}
                        sources={this.props.sources}
                        sorcesFolderList={this.props.sorcesFolderList}
                        customNotebook={this.props.customNotebook}
                    />
                </If>
            </If>
      </div>
    );
  }
}
