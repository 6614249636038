

export const msalConfig = {

    auth: {
        clientId: "",
        authority: "",
        scope: "",
        redirectUri: "",
        navigateToLoginRequestUrl: false,
        knownAuthorities: [],
        clientCapabilities: ["CP1"],
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, 
    }
};
