import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { addTab, updateUsagePoolField } from "../../../../redux/reducers/RepertoireReducer";
import { ADD_NEW_ACTION, CHECKBOX_INPUT, DATES_AND_DIMENSIONS, POOL_ALLOCATION_SETTINGS_KEY, POOL_ENABLED_STATE_KEY, POOL_ESTIMATED_VALUE_PER_POINT_SETTINGS_KEY, POOL_EVPP_ENABLED_STATE_KEY, POOL_RIGHT_TYPES_KEY, POOL_SOURCES_KEY, POOL_WEIGHT_SETTINGS_KEY, TEXT_INPUT } from "../../../Consts";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IUsagePoolStateKeys } from "../../../../redux/types/IUsagePoolState";
import { IUsagePoolSourceDataItems } from "../../../types/usageTypes/IUsagePoolSourceDataItems";
import { ITreeData } from "../../../types/ITreeData";
import { IUsagePoolRightTypeDataItem } from "../../../types/usageTypes/IUsagePoolRightTypeDataItem";
import { IUsagePoolAllocationSettingsDataItems } from "../../../types/usageTypes/IUsagePoolAllocationSettingsDataItems";
import PoolMaintenanceAllocationSettingsGrid from "../poolMaintenanceAllocationSettingsGrid/PoolMaintenanceAllocationSettingsGrid";
import DatesAndDimensionsWeightingTable from "./DatesAndDimensionsWeightingTable";
import { IUsageWeightSettingsDataItems } from "../../../types/usageTypes/IUsageWeightSettingsDataItems";
import PoolMaintenanceWeightSettingsGrid from "../poolMaintenanceWeightSettingsGrid/PoolMaintenanceWeightSettingsGrid";
import { IUsagePoolDatesAndDimensions } from "../../../types/usageTypes/IUsagePoolDatesAndDimensions";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import { IUsageEVPPSettingsDataItems } from "../../../types/usageTypes/IUsageEVPPSettingsDataItems";
import PoolMaintenanceEstimatedValuePerPointSettingsGrid from "../poolMaintenanceEstimatedValuePerPointSettingsGrid/poolMaintenanceEstimatedValuePerPointSettingsGrid";

export interface IUsagePoolMaintenanceGridsViewProps {
    weightSettingsPoolMaintenance: IRepertoireComponentDataItem;
    usagePoolMaintenanceGridsViewData: IRepertoireComponentDataItem;
    usagePoolMaintenanceAllocationSettingsGridViewData: IRepertoireComponentDataItem;
    usagePoolMaintenanceWeightSettingsGridViewData:IRepertoireComponentDataItem;
    usagePoolMaintenanceEstimatedValuePerPointSettingsGridViewData:IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    componentInstance: string;
    usagePoolSources: IUsagePoolSourceDataItems[];
    weightSettings:IUsageWeightSettingsDataItems[];
    poolEVPPSettings:IUsageEVPPSettingsDataItems[];
    addTab: typeof addTab;
    tabs: ITabReduxItem[];
    activeTab: number;
    lookupValues: ILookupDictionary;
    updateUsagePoolField: typeof updateUsagePoolField;
    lookupSources: ITreeData[];
    isReadonly: boolean;
    rightTypes?: IUsagePoolRightTypeDataItem[];
    allocationSettings?: IUsagePoolAllocationSettingsDataItems;
    poolID?: number;
    incrementTempID?: () => void;
    poolEnabled?:boolean;
    weightSettingsIsEnabled?: boolean;
    evppSettingsIsEnabled?:boolean;
    datesAndDimensions?: IUsagePoolDatesAndDimensions[];
    saveChanges: () => void;
    sortWeightSettingsFieldData;
    isBulkCheckboxActive?:boolean;
    changeBulkCheckbox?: (value: boolean) => void;
}

interface IusagePoolMaintenanceGridsViewState {
    activeHeaderSection: string;
    loaded: boolean;
}

export default class UsagePoolMaintenanceGridsView extends React.PureComponent<
    IUsagePoolMaintenanceGridsViewProps,
    IusagePoolMaintenanceGridsViewState
> {
    viewData;
    constructor(props: IUsagePoolMaintenanceGridsViewProps) {
        super(props);
        this.state = {
            activeHeaderSection: POOL_SOURCES_KEY,
            loaded: false
        }
        this.viewData = {};
    }

    componentDidUpdate() {
        this.loadData();
    }

    componentDidMount() {
        this.loadData();
    }


    loadData() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.usagePoolMaintenanceGridsViewData) {
                return;
            }
            else {
                this.setState({ loaded: true });
            }
        }
    }
    

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    changeData = (value: any, name: IRepertoireStateKeys) => {
        const { lookupValues, updateUsagePoolField } = this.props;
        updateUsagePoolField(value, name as IUsagePoolStateKeys, lookupValues);
    }

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { usagePoolMaintenanceGridsViewData } = this.props;

        if (usagePoolMaintenanceGridsViewData && usagePoolMaintenanceGridsViewData.fields) {
            return usagePoolMaintenanceGridsViewData.fields.filter(f => f.fieldType === null).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    };

    handleDeleteAllLinkedWeightsSettingsWhenTheDatesAndDimensionsAreDeleted = (deletedDatesAndDimensionsRow) => {
        const { weightSettings, datesAndDimensions } = this.props;
        if (deletedDatesAndDimensionsRow.effectiveDateRange) {
            if (weightSettings && weightSettings.length > 0) {
                const weightSettingsRemaining = weightSettings.filter(weights => weights.effectiveDateRange.value !== deletedDatesAndDimensionsRow.effectiveDateRange);
                const allRemainingDatesAndDimensions = datesAndDimensions.filter(dND => dND.effectiveDateRange !== deletedDatesAndDimensionsRow.effectiveDateRange);
                this.changeData(weightSettingsRemaining, POOL_WEIGHT_SETTINGS_KEY);
                this.changeData(allRemainingDatesAndDimensions , DATES_AND_DIMENSIONS);
            }
        }
    }


    render() {
        const {
            dataGridTableData,
            usagePoolSources,
            weightSettings,
            poolEVPPSettings,
            usagePoolMaintenanceGridsViewData,
            weightSettingsPoolMaintenance,
            usagePoolMaintenanceAllocationSettingsGridViewData,
            usagePoolMaintenanceWeightSettingsGridViewData,
            usagePoolMaintenanceEstimatedValuePerPointSettingsGridViewData,
            isReadonly,
            lookupValues,
            lookupSources,
            rightTypes,
            allocationSettings,
            activeTab,
            tabs,
            updateUsagePoolField,
            poolID,
            incrementTempID,
            poolEnabled,
            datesAndDimensions,
            saveChanges,
            weightSettingsIsEnabled,
            evppSettingsIsEnabled,
            sortWeightSettingsFieldData,
            isBulkCheckboxActive,
            changeBulkCheckbox,
        } = this.props;

        const {
            activeHeaderSection
        } = this.state;

        const filteredFields = usagePoolMaintenanceGridsViewData.fields.filter(
            (field: IRepertoireField) =>
                field.name === activeHeaderSection
        );

        const { loaded } = this.state;
        if (!loaded)
            return <div className="loading" />

        if (!allocationSettings) {
            const newAllocationSettings = {
                poolID: poolID !== undefined ? poolID : 0,
                poolAllocationSettingsID: 0,
                unidentifiedUsage: { value: false, inputType: CHECKBOX_INPUT },
                nonSocietyIPs: { value: false, inputType: CHECKBOX_INPUT },
                referenceIPs: { value: false, inputType: CHECKBOX_INPUT },
                missingShares: { value: false, inputType: CHECKBOX_INPUT },
                warsawRule: { value: false, inputType: CHECKBOX_INPUT },
                percentageDurationForUnidentifiedUsage: { value: false, inputType: CHECKBOX_INPUT },
                defaultDurationPercentage: { value: 0, inputType: TEXT_INPUT },
                reciprocalAgreements: { value: false, inputType: CHECKBOX_INPUT },
                pdWork100Percent: { value: false, inputType: CHECKBOX_INPUT },
                pdiPs: { value: false, inputType: CHECKBOX_INPUT },
            }

            updateUsagePoolField(newAllocationSettings, POOL_ALLOCATION_SETTINGS_KEY);
        }

        return (
            <>
                <div className="GridsView">
                    <div className="headerContainer">
                        {this.renderGridViewHeaderSections()}
                    </div>
                    {activeHeaderSection === POOL_SOURCES_KEY ?
                        <DataGridTable
                            tableContents={usagePoolSources}
                            changeData={this.changeData.bind(this)}
                            dataGridTableData={dataGridTableData}
                            stateKey={filteredFields[0].name}
                            componentInstance={POOL_SOURCES_KEY}
                            showRowNumber={true}
                            isReadOnly={isReadonly}
                            tableActions={[{ name: ADD_NEW_ACTION }]}
                            lookupValues={lookupValues}
                            lookupSources={lookupSources}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                        /> : <div> </div>}
                      {activeHeaderSection === POOL_RIGHT_TYPES_KEY ?
                        <DataGridTable
                            tableContents={rightTypes}
                            changeData={this.changeData.bind(this)}
                            dataGridTableData={dataGridTableData}
                            stateKey={filteredFields[0].name}
                            componentInstance={POOL_RIGHT_TYPES_KEY}
                            showRowNumber={true}
                            isReadOnly={isReadonly}
                            tableActions={[{ name: ADD_NEW_ACTION }]}
                            lookupValues={lookupValues}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                        /> : <div> </div>}
                    {activeHeaderSection === POOL_ALLOCATION_SETTINGS_KEY ?
                        <PoolMaintenanceAllocationSettingsGrid
                            allocationSettingsGridViewData={usagePoolMaintenanceAllocationSettingsGridViewData}
                            isReadonly={isReadonly}
                            poolAllocationSettings={allocationSettings}
                            updateUsagePoolField={updateUsagePoolField}
                            activeTab={activeTab}
                            tabs={tabs}
                            lookupValues={lookupValues} 
                        /> : <div> </div>}
                    {activeHeaderSection === POOL_WEIGHT_SETTINGS_KEY ? <div>
                        <SizedCheckboxDataInput
                                label={'Enable'}
                                fieldName={POOL_ENABLED_STATE_KEY}
                                value={weightSettingsIsEnabled}
                                changeData={this.changeData.bind(this)}
                                readonly={isReadonly}
                                inline={true}
                            />
                        <DatesAndDimensionsWeightingTable
                            weightSettingsPoolMaintenanceFields={weightSettingsPoolMaintenance}
                            changeData={this.changeData.bind(this)}
                            datesAndDimensions={datesAndDimensions}
                            saveChanges={saveChanges}
                            poolEnabled={weightSettingsIsEnabled}
                            handleDeleteAllLinkedWeightsSettingsWhenTheDatesAndDimensionsAreDeleted={this.handleDeleteAllLinkedWeightsSettingsWhenTheDatesAndDimensionsAreDeleted.bind(this)}
                        />
                        <div>
                            <PoolMaintenanceWeightSettingsGrid
                                    weightSettingsGridViewData={usagePoolMaintenanceWeightSettingsGridViewData}
                                    isReadonly={isReadonly}
                                    poolAllocationSettings={allocationSettings}
                                    updateUsagePoolField={updateUsagePoolField}
                                    activeTab={activeTab}
                                    tabs={tabs}
                                    tableContents={weightSettings}
                                    changeData={this.changeData.bind(this)}
                                    dataGridTableData={dataGridTableData}
                                    stateKey={filteredFields[0].name}
                                    componentInstance={POOL_WEIGHT_SETTINGS_KEY}
                                    poolEnabled={weightSettingsIsEnabled} 
                                    lookupSources={lookupSources} 
                                    lookupValues={lookupValues}   
                                    incrementTempID={incrementTempID} 
                                    datesAndDimensions={datesAndDimensions}
                                    sortWeightSettingsFieldData={sortWeightSettingsFieldData}
                                    isBulkCheckboxActive={isBulkCheckboxActive}
                                    changeBulkCheckbox={changeBulkCheckbox}
                                /> 
                        
                        </div>
                        </div>
                        : <></>}
                        {activeHeaderSection === POOL_ESTIMATED_VALUE_PER_POINT_SETTINGS_KEY ? <div>
                        <div>
                        <SizedCheckboxDataInput
                                label={'Enable'}
                                fieldName={POOL_EVPP_ENABLED_STATE_KEY}
                                value={evppSettingsIsEnabled}
                                changeData={this.changeData.bind(this)}
                                readonly={isReadonly}
                                inline={true}
                            />
                            <PoolMaintenanceEstimatedValuePerPointSettingsGrid
                                    estimatedValuePerPointSettingsGridViewData={usagePoolMaintenanceEstimatedValuePerPointSettingsGridViewData}
                                    isReadonly={isReadonly}
                                    updateUsagePoolField={updateUsagePoolField}
                                    activeTab={activeTab}
                                    tabs={tabs}
                                    tableContents={poolEVPPSettings}
                                    changeData={this.changeData.bind(this)}
                                    dataGridTableData={dataGridTableData}
                                    stateKey={filteredFields[0].name}
                                    componentInstance={POOL_ESTIMATED_VALUE_PER_POINT_SETTINGS_KEY}
                                    poolEnabled={evppSettingsIsEnabled} 
                                    lookupSources={lookupSources} 
                                    lookupValues={lookupValues}   
                                    incrementTempID={incrementTempID}
                                    evppSettingsIsEnabled={evppSettingsIsEnabled}
                                /> 
                        
                        </div>
                        </div>
                        : <></>}
                </div>
            </>
        );
    }
}