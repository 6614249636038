import React, { Component } from "react";
import { NavItem, NavLink } from 'reactstrap';
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { hideModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, nextWorkflowInSession, skipWorkflowInSession, startWorkflowSession } from "../../../redux/reducers/RepertoireReducer";
import { IProductState } from "../../../redux/types/IProductState";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { IWorkState } from "../../../redux/types/IWorkState";
import {
    ADD_METHOD_TOOLBAR,
    ADD_REPRESENTATION_TOOLBAR,
    ASSING_TO_ICON,
    ASSING_TO_METHOD_TOOLBAR,
    AUTO_MATCH_ICON,
    COPY_ICON,
    COPY_METHOD_TOOLBAR,
    DELETE_ICON,
    DELETE_METHOD_TOOLBAR,
    DESELECT_ALL_METHOD_TOOLBAR,
    HISTORY_METHOD_TOOLBAR,
    IP_MAINTENANCE_TOOLBAR,
    MATCH_METHOD_TOOLBAR,
    MERGE_ICON,
    MERGE_METHOD_TOOLBAR,
    NEW_ICON,
    PRODUCT_MAINTENANCE_TOOLBAR,
    SAVE_CHANGES_STATE_KEY,
    SAVE_METHOD_TOOLBAR,
    SELECTED_ICON,
    START_SESSION,
    START_WORKFLOW_SESSION_METHOD_TOOLBAR,
    TOOLBAR_POSITION_BREAKPOINT,
    UNDO_ICON,
    UNDO_METHOD_TOOLBAR,
    UPDATE_STATUS_ICON,
    UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR,
    VERSION_HISTORY_ICON,
    WORKFLOW_PAGE_TOOLBAR,
    WORK_MAINTENANCE_TOOLBAR,
    ALLOCATE_METHOD_TOOLBAR,
    SET_CD_ALLOCATION_METHOD_TOOLBAR,
    UNDO_ALLOCATION_METHOD_TOOLBAR,
    ALLOCATE_STATUS_VALUE,
    UNDO_ALLOCATION_STATUS_VALUE,
    DISTRIBUTION_MAINTENANCE_TOOLBAR,
    ALLOCATE_ICON,
    SET_CD_ALLOCATION_SCHEDULE_ICON,
    UNDO_ALLOCATION_ICON,
    EXPORT_METHOD_TOOLBAR,
    ADJUSTMENT_MAINTENANCE_TOOLBAR,
    COPY_DISTRIBUTION_STATE_KEY,
    DISTRIBUTION_EXPORT_STATE_KEY,
    DISTRIBUTION_IMPORT_STATE_KEY,
    DISTRIBUTION_RESET_CACHE_STATE_KEY,
    IMPORT_METHOD_TOOLBAR,
    RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR,
    PAYMENTRUN_MAINTENANCE_TOOLBAR,
    CRM_EXTENDED_DETAILS_TOOLBAR,
    OPEN_IN_NEW_TAB,
    LICENSE_MAINTENANCE_TOOLBAR,
    RUN_QA_METHOD_TOOLBAR,
    QA_STATUS_VALUE,
    UNDO_RUN_QA_METHOD_TOOLBAR,
    CUE_SHEET_TYPE,
    ADD_TO_PACKAGES_TOOLBAR,
    ADD_TO_PACKAGE_ICON,
    AGREEMENT_MAINTENANCE_TOOLBAR,
    COPY_WORK_STATE_KEY,
    ADD_TO_PACKAGE_KEY,
    VERSION_HISTORY_STATE_KEY,
} from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";
import { IInterestedParty } from "../../types/IInterestedParty";
import IconTextButton from "../iconTextButton/IconTextButton";
import SaveResult from "../saveResult/SaveResult";
import { IAgreementState } from "../../../redux/types/IAgreementState";
import { IDistributionState } from "../../../redux/types/IDistributionState";
import { SaveOption } from "../../types/SaveOption";
import { WorkflowBanner } from "../workflowBanner/WorkflowBanner";
import { If } from "../../../core/components/If";
import { IWorkflowSearchResult } from "../../types/IWorkflowSearchResult";
import { FormatFields } from "../../../redux/types/FormatFields";
import { MessageBanner, MessageType } from "../messageBanner/MessageBanner";
import { IArtistState } from "../../../redux/types/IArtistState";
import { IUsagePoolState } from "../../../redux/types/IUsagePoolState";
import { IClaimState } from "../../../redux/types/IClaimState";
import { IAdjustmentState } from "../../../redux/types/IAdjustmentState";
import { IWorkflowParams } from "../../types/IWorkFlowParams";
import { IPaymentRunState } from "../../../redux/types/IPaymentRunState";
import { IValidationMessage } from "../../types/IValidationMessage";
import { IShoppingCartState } from "../../../redux/types/IShoppingCartState";
import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import IShortCutKeys from "../../../redux/types/IShortCutKeys";
import ToolbarEventListener from "./ToolBarEventListner";
import { IMRO_CUSTOMER_NAME } from "../../../home/Consts";
export interface IToolbarComponentProps {
    changesMade?: boolean;
    showYesNoPrompt?: typeof showYesNoPrompt;
    hideModal?: typeof hideModal;
    resetMessageBanner?: () => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    scroll?: number;
    toolbarWidth: number;
    toolbarData?: IRepertoireComponentDataItem;
    saveResultData: IRepertoireComponentDataItem;
    work?: IWorkState;
    interestedParty?: IInterestedParty;
    product?: IProductState;
    agreement?: IAgreementState;
    distribution?: IDistributionState;
    paymentRun?: IPaymentRunState;
    artist?: IArtistState;
    activeTab: number;
    lookupValues?: ILookupDictionary;
    tabs: ITabReduxItem[];
    dataSource?: DataSource;
    isReadonly?: boolean;
    dataActionToolbar: IDataActionToolbar[];
    component: string;
    isNew?: boolean;
    workflowFieldsData?: IRepertoireComponentDataItem;
    isWorkflowSessionStarted?: boolean;
    startWorkflowSession?: typeof startWorkflowSession;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    currentWorkflowIndex?: number;
    dataActionList?: IDataActionToolbar[];
    workFormats?: FormatFields[];
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    isOpenForWorkflowSession?: boolean;
    pool?: IUsagePoolState;
    skipUsageWorkflowItem?: (activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    refreshWorkDetails?: () => void;
    refreshProductDetails?: () => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    adjustment?: IAdjustmentState;
    claim?: IClaimState;
    allocationJobInProgress?: boolean;
    roles?: string[];
    secondaryActions?: IDataActionToolbar[];
    showSecondaryActions?: Boolean;
    IsResetCacheEnabled?: Boolean;
    license?: IShoppingCartState;
    membersPortalWork?: IWorkState;
    updateWorkflowAndEntityStatus?: (workflowparams: IWorkflowParams, approvalStatus: boolean) => void;
    isLicensePageModified?: boolean;
    bookPriceCalculationsJobInProgress?: boolean;
    completeWorkflowOnSave?: (value: boolean) => void;
    cueSheetsEnabled?:boolean;
    cuesheetDatasource?:any;
    customer?: string;
    addToPackages?:(userName:string,entityType:string,entityID:number)=>void;
    skipProductWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , dataActions: IDataActionToolbar[]
        , formats: FormatFields[]
        ) =>void;
}

export interface IToolbarState {
    loaded: boolean;
    showAdditionalWorksItems: boolean;
}
  interface StateProps {
    shortCutKeys: IShortCutKeys[]; 
  }

type ICombinedToolbarConnectProps = IToolbarComponentProps & StateProps;
class ToolbarComponent extends Component<
    ICombinedToolbarConnectProps,
    IToolbarState
>{
    actionData;
    cueSheetEnabledProduct: any;
    popoverRef: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            showAdditionalWorksItems: false,
        };
        this.popoverRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.actionData = {};
    }

    saveMethod = (saveOption: SaveOption) => {
        const { dataActionToolbar } = this.props;
        const filteredDataActionToolbar = dataActionToolbar.find(
            action =>
                action.method === SAVE_METHOD_TOOLBAR
        );
        filteredDataActionToolbar.action(saveOption);
    }

    componentDidMount() {
        const {
            dataActionToolbar,
            toolbarData: { fields },
            cueSheetsEnabled
        } = this.props;

        const {
            loaded
        } = this.state;
        
        dataActionToolbar.forEach(item => {
            let itemName = item.method;
            let itemData = this.getToolbarActionData(item);
            this.actionData[itemName] = itemData;
        });
        document.addEventListener('mousedown', this.handleClickOutside);

        if (!loaded) this.checkIfLoaded();
    }

    componentDidUpdate() {
        const { loaded } = this.state;
        if (!loaded) this.checkIfLoaded();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }

    handleClickOutside(event) {
        if (this.popoverRef.current && !this.popoverRef.current.contains(event.target)) {
          this.setState({ showAdditionalWorksItems: false });
        }
      }

    showSaveButtonCommonCuesheet = () => {
        const {
            component,
            product,
            dataSource,
            cueSheetsEnabled,
            cuesheetDatasource
        } = this.props;

        return component == PRODUCT_MAINTENANCE_TOOLBAR && dataSource == DataSource.Repertoire && cueSheetsEnabled 
        && product?.productType == CUE_SHEET_TYPE && product?.originOfRevision && product?.originOfRevision != cuesheetDatasource.Current
    }

    checkIfLoaded = () => {
        const {
            dataActionToolbar,
            component,
            isNew
        } = this.props;

        let dataActionLength = 0;

        if (component === PRODUCT_MAINTENANCE_TOOLBAR) {
            dataActionLength = dataActionToolbar.filter(item =>
                item.method !== SAVE_CHANGES_STATE_KEY
            ).length;
        }
        else { dataActionLength = dataActionToolbar.length }

        if (Object.keys(this.actionData).length >= dataActionLength)
            this.setState({ loaded: true });
    };

    getToolbarStyleClasses = () => {
        const { scroll } = this.props;
        return scroll > TOOLBAR_POSITION_BREAKPOINT
            ? "container fixedToolbar" : "container";
    };

    getToolbarActionData = (dataActionToolbar: IDataActionToolbar) => {

        let iconPath = "";

        switch (dataActionToolbar.method) {
            case COPY_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(COPY_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case UNDO_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(UNDO_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case ALLOCATE_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(ALLOCATE_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case SET_CD_ALLOCATION_METHOD_TOOLBAR:
                    iconPath = this.getToolbarActionIconPath(SET_CD_ALLOCATION_SCHEDULE_ICON);
                    return {
                        action: dataActionToolbar.action,
                        iconPath
                    };
            case CRM_EXTENDED_DETAILS_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(OPEN_IN_NEW_TAB);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case UNDO_ALLOCATION_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(UNDO_ALLOCATION_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case MERGE_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(MERGE_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case DELETE_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(DELETE_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case ADD_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(NEW_ICON);
                const addNew = () => {
                    dataActionToolbar.action(this.props.lookupValues);
                }
                return {
                    action: addNew,
                    iconPath
                };
            case ADD_REPRESENTATION_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(NEW_ICON);
                const addNewRep = () => {
                    dataActionToolbar.action(this.props.lookupValues);
                }
                return {
                    action: addNewRep,
                    iconPath
                };
            case HISTORY_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(VERSION_HISTORY_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };

            case ADD_TO_PACKAGES_TOOLBAR:
                    iconPath = this.getToolbarActionIconPath(ADD_TO_PACKAGE_ICON);
                    return {
                        action: dataActionToolbar.action,
                        iconPath
                    };
            case MATCH_METHOD_TOOLBAR:
                iconPath = this.getToolbarActionIconPath(AUTO_MATCH_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            case ASSING_TO_METHOD_TOOLBAR: {
                iconPath = this.getToolbarActionIconPath(ASSING_TO_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            }
            case UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR: {
                iconPath = this.getToolbarActionIconPath(UPDATE_STATUS_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            }
            case DESELECT_ALL_METHOD_TOOLBAR: {
                iconPath = this.getToolbarActionIconPath(SELECTED_ICON);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            }
            case START_WORKFLOW_SESSION_METHOD_TOOLBAR: {
                iconPath = this.getToolbarActionIconPath(START_SESSION);
                return {
                    action: dataActionToolbar.action,
                    iconPath
                };
            }
            case RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR:
                return {
                    action: dataActionToolbar.action,
                    iconPath: ''
                };
            default:
                return {};
        }
    };

    getToolbarActionIconPath = (iconName: string) => {
        switch (iconName) {
            case "copy": return "icon ms-Icon ms-Icon--Copy";
            case "undo": return "icon ms-Icon ms-Icon--Undo";
            case "merge": return "icon ms-Icon ms-Icon--BranchMerge";
            case "delete": return "icon ms-Icon ms-Icon--Delete";
            case "addNew": return "icon ms-Icon ms-Icon--CircleAddition";
            case "versionHistory": return "icon ms-Icon ms-Icon--History";
            case "automatch": return "icon ms-Icon ms-Icon--TriggerAuto";
            case "assingTo": return "icon ms-Icon ms-Icon--FollowUser";
            case "updateStatus": return "icon ms-Icon ms-Icon--ProgressLoopOuter";
            case "deselect": return "icon ms-Icon ms-Icon--Cancel";
            case "startSession": return "icon ms-Icon ms-Icon--Flow";
            case "allocate": return "icon ms-Icon ms-Icon--DependencyAdd";
            case "undoAllocation": return "icon ms-Icon ms-Icon--DependencyRemove";
            case "openInNewTab": return "icon ms-Icon ms-Icon--OpenInNewTab";
            case "addtoPackage": return "icon ms-Icon ms-Icon--Packages";
            default: return "";
        }
    };

    renderToolbarButtons = () => {
        const {
            isReadonly,
            dataActionToolbar,
            dataSource,
            component,
            isNew,
            distribution,
            paymentRun,
            secondaryActions,
            IsResetCacheEnabled,
            work,
            interestedParty,
            claim
        } = this.props;

        let filteredDataActionToolbar = dataActionToolbar.filter(
            action =>
                action.method !== SAVE_METHOD_TOOLBAR
        );

        if (isNew === true) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(action =>
                action.method !== DELETE_METHOD_TOOLBAR
            )
        }

        if (component === WORK_MAINTENANCE_TOOLBAR && dataSource === DataSource.Repertoire) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                    action.method !== MATCH_METHOD_TOOLBAR 
            )
        }
        if (component === WORK_MAINTENANCE_TOOLBAR && dataSource === DataSource.Intray) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                    action.method !== ADD_TO_PACKAGES_TOOLBAR 
            )
        }
        if (component === WORK_MAINTENANCE_TOOLBAR && isNew === true) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                    action.method !== MATCH_METHOD_TOOLBAR && action.method !== MERGE_METHOD_TOOLBAR && action.method !== ADD_TO_PACKAGES_TOOLBAR
            )
        }

        if (component === PRODUCT_MAINTENANCE_TOOLBAR && dataSource === DataSource.Intray) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                    action.method !== ADD_TO_PACKAGES_TOOLBAR 
            )
        }
        if (component === PRODUCT_MAINTENANCE_TOOLBAR && isNew === true) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                   action.method !== ADD_TO_PACKAGES_TOOLBAR
            )
        }
        if (component === AGREEMENT_MAINTENANCE_TOOLBAR && dataSource === DataSource.Intray) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                    action.method !== ADD_TO_PACKAGES_TOOLBAR 
            )
        }
        if (component === AGREEMENT_MAINTENANCE_TOOLBAR && isNew === true) {
            filteredDataActionToolbar = filteredDataActionToolbar.filter(
                action =>
                   action.method !== ADD_TO_PACKAGES_TOOLBAR
            )
        }
        if (component === PAYMENTRUN_MAINTENANCE_TOOLBAR) {
            if (paymentRun.status === "Open") {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === DELETE_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
            else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === DELETE_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (paymentRun.showStatusChangeWarning) {
                this.renderWarningMessages()
            }
        }

        if (component === IP_MAINTENANCE_TOOLBAR) {

            if (interestedParty && interestedParty.ipExtendedDetails && interestedParty.ipExtendedDetails !== '') {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === CRM_EXTENDED_DETAILS_TOOLBAR && action.isReadOnly) {
                            action.isReadOnly = false;
                        }
                    }
                )
            } else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === CRM_EXTENDED_DETAILS_TOOLBAR && !action.isReadOnly) {
                            action.isReadOnly = true;
                        }
                    }
                )
            }
        }
       
        if (component === DISTRIBUTION_MAINTENANCE_TOOLBAR) {
            let selectedDistributionPools = (distribution === undefined || distribution.distributionPools === null) ? [] : distribution.distributionPools.filter(
                distributionPool =>
                    distributionPool.selected.value !== false
            )

            const canAllocate = (distributionPools) => {
                const firstCD = !!distributionPools[0].continuallyDistribute.value;
                return distributionPools.every(element => 
                    !!element.continuallyDistribute.value === firstCD && 
                    (element.status.value == UNDO_ALLOCATION_STATUS_VALUE || element.continuallyDistribute.value)
                );
            };

            if (selectedDistributionPools.length > 0 && canAllocate(selectedDistributionPools)){
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === ALLOCATE_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
            else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === ALLOCATE_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }
            if (selectedDistributionPools.length > 0 && selectedDistributionPools.find(pool => pool.continuallyDistribute.value === true)) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === SET_CD_ALLOCATION_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
            else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === SET_CD_ALLOCATION_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (selectedDistributionPools.length > 0 && selectedDistributionPools.every(element => element.status.value === ALLOCATE_STATUS_VALUE)) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === UNDO_ALLOCATION_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
            else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === UNDO_ALLOCATION_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (selectedDistributionPools.length > 0) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === RUN_QA_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }

            if (selectedDistributionPools.length == 0) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === RUN_QA_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }


            if (selectedDistributionPools.length > 0 && selectedDistributionPools.every(element => element.qaStatus.value === QA_STATUS_VALUE)) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === UNDO_RUN_QA_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
            else {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === UNDO_RUN_QA_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (isNew == true) {
                filteredDataActionToolbar.map(
                    action => {
                        if (action.method === EXPORT_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            }

            if (distribution.distributionStatus === "Open" && IsResetCacheEnabled) {
                secondaryActions.map(
                    action => {
                        if (action.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR) { action.isReadOnly = true }
                    }
                )
            } else {
                secondaryActions.map(
                    action => {
                        if (action.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR) { action.isReadOnly = false }
                    }
                )
            }
        }

        return filteredDataActionToolbar.map(item =>
            <div className="toolbarButton" key={item.method}>
                <div className={(item.isReadOnly) ? "toolbarButtonContainer disabled" : "toolbarButtonContainer"}
                    style={{pointerEvents: item.isReadOnly ? 'none' : 'auto'}}>
                    <IconTextButton
                        text={item.name}
                        icon={item.icon}
                        onClick={item.action}
                        disabled={item.isReadOnly}
                    />
                </div>
            </div>
        )
    };

    renderIceHyperLink = () => {
        const { work } = this.props;
        return (
            <div className="toolbarWrapper">
                <div className="toolbarButton">
                    <div className="toolbarButtonContainer">
                        <div className="IconTextButton">
                            <a href={ work.iceWorkKeyHyperlink } className="ice-work-link" target="_blank" rel="noopener noreferrer">
                                <i className="icon ms-Icon ms-Icon--Diamond" aria-hidden="true"></i>
                                ICE Link
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSecondaryActionsDistributions = () => {
        const { tabs, activeTab, showSecondaryActions, toolbarData: { fields }, secondaryActions } = this.props;
        const contextMenuDisplay = !tabs[activeTab].title.includes("Find") ? 'contextualMenu show' : 'contextualMenu'
        return (
            <>
                {showSecondaryActions ?
                    <div className={contextMenuDisplay} id="containerContextualMenu">
                        <div className="toolbarWrapper">
                            <div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === EXPORT_METHOD_TOOLBAR)[0].action}>
                                        <i className="icon ms-Icon ms-Icon--Export" aria-hidden="true"></i>
                                        {fields.find(d => d.name === DISTRIBUTION_EXPORT_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>
                            <div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === IMPORT_METHOD_TOOLBAR)[0].action}>
                                        <i className="icon ms-Icon ms-Icon--Import" aria-hidden="true"></i>
                                        {fields.find(d => d.name === DISTRIBUTION_IMPORT_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>
                            <div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === COPY_METHOD_TOOLBAR)[0].action}>
                                        <i className="icon ms-Icon ms-Icon--Reply" aria-hidden="true"></i>
                                        {fields.find(d => d.name === COPY_DISTRIBUTION_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>
                            <div className={"toolbarButton"}>
                                <div className={secondaryActions.filter(x => x.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR)[0].isReadOnly ? "toolbarButtonContainer" : "toolbarButtonContainer disabled"}>
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR)[0].isReadOnly ? secondaryActions.filter(x => x.method === RESET_CACHE_DISTRIBUTION_METHOD_TOOLBAR)[0].action : null}>
                                        <i className="" aria-hidden="true"></i>
                                        {fields.find(d => d.name === DISTRIBUTION_RESET_CACHE_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
            </>
        )
    }
    handleToggleExtraItems = () => { 
        this.setState({ showAdditionalWorksItems: !this.state.showAdditionalWorksItems });
    }


    renderSecondaryActionsWorks = () => {
        const { tabs, activeTab, showSecondaryActions, toolbarData: { fields }, secondaryActions, isNew } = this.props;
        const { showAdditionalWorksItems } = this.state
        const contextMenuDisplay = showAdditionalWorksItems ? 'contextualMenu show' : 'contextualMenu';
        return (
            <>
         {showSecondaryActions ?
         <div ref={this.popoverRef}>
            <div className="toolbarButton" key={"workMaintenanceMenuButton"}>
                <div className={"toolbarButtonContainer"}
                onClick={this.handleToggleExtraItems.bind(this)}
                    style={{pointerEvents: 'auto'}}>
                    <IconTextButton

                        text={""}
                        icon={"icon ms-Icon ms-Icon--MoreVertical"}
                        disabled={false}
                    />
                </div>
            </div>
            
                    <div className={contextMenuDisplay} id="containerContextualMenu">
                        <div className="toolbarWrapper">
                           {secondaryActions.filter(x => x.method === HISTORY_METHOD_TOOLBAR)?.length > 0 && !isNew && (<div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === HISTORY_METHOD_TOOLBAR)[0].action}>
                                        <i className="icon ms-Icon ms-Icon--History" aria-hidden="true"></i>
                                        {fields.find(d => d.name === VERSION_HISTORY_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>)}
                            {secondaryActions.filter(x => x.method === ADD_TO_PACKAGES_TOOLBAR)?.length > 0 && !isNew && (<div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={secondaryActions.filter(x => x.method === ADD_TO_PACKAGES_TOOLBAR)[0].action}>
                                        <i className="icon ms-Icon ms-Icon--AddIn" aria-hidden="true"></i>
                                        {fields.find(d => d.name === ADD_TO_PACKAGE_KEY).data}
                                    </div>
                                </div>
                            </div>)}
                            {secondaryActions.filter(x => x.method === COPY_METHOD_TOOLBAR)?.length > 0 && (<div className="toolbarButton">
                                <div className="toolbarButtonContainer">
                                    <div className="IconTextButton" onClick={() => {
                                        console.log('hello', secondaryActions.filter(x => x.method === COPY_METHOD_TOOLBAR)[0])
                                        secondaryActions.filter(x => x.method === COPY_METHOD_TOOLBAR)[0].action()
                                    }}>
                                        <i className="icon ms-Icon ms-Icon--Copy" aria-hidden="true"></i>
                                        {fields.find(d => d.name === COPY_WORK_STATE_KEY).data}
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                    </div> : null
                }
            </>
        )
    }

    renderSaveButton = () => {
        const {
            changesMade,
            dataActionToolbar,
            isReadonly,
            component,
            dataSource,
            customer
        } = this.props;
        
        const lastIndex = dataActionToolbar.length;
        if(dataActionToolbar.length>0){
        if ((component === WORK_MAINTENANCE_TOOLBAR || component === PRODUCT_MAINTENANCE_TOOLBAR) && dataSource === DataSource.Intray) {
            return (
                <div className="saveButton" key={lastIndex}>
                    <div className="saveButtonContainer btn-group" key={lastIndex}>
                        <button
                            title={dataActionToolbar.find(f => f.method === "Save").name}
                            aria-disabled={isReadonly}
                            className={changesMade? "saveButton" : "disabledSaveButton"}
                            onClick={() => this.saveMethod(SaveOption.Save)}
                            disabled={!changesMade}
                        >
                            <div className="saveButtonText">{dataActionToolbar.find(f => f.method === "Save").name}</div>
                        </button>
                        <div>
                            <button
                                type="button"
                                className={"saveButton dropdown-toggle"}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-disabled={isReadonly}>
                                <span className="caret"></span>
                                <span className="sr-only">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu">
                                <NavItem key={1}>
                                    <NavLink
                                        onClick={() => this.saveMethod(SaveOption.SaveMatch)}
                                    >Save & Match</NavLink>
                                </NavItem>
                                <NavItem key={2}>
                                    <NavLink
                                        onClick={() => this.saveMethod(SaveOption.SaveMatchPostNew)}
                                    >Save, Match & Post New</NavLink>
                                </NavItem>
                                {/* Remove this button for Work Maintenace component when customer is IMRO */}
                                {!(customer === IMRO_CUSTOMER_NAME && component === WORK_MAINTENANCE_TOOLBAR) && 
                                (
                                    <NavItem key={3}>
                                        <NavLink
                                            onClick={() => this.saveMethod(SaveOption.SaveMatchAutoMerge)}
                                        > Save, Match & Auto Merge
                                        </NavLink>
                                    </NavItem>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            );
        }
        else if (component === ADJUSTMENT_MAINTENANCE_TOOLBAR || (component === AGREEMENT_MAINTENANCE_TOOLBAR && dataSource === DataSource.Intray)) {
            return (
                <div className="saveButton" key={lastIndex}>
                    <div className="saveButtonContainer btn-group" key={lastIndex}>
                        <button
                            title={dataActionToolbar.find(f => f.method === "Save").name}
                            aria-disabled={isReadonly}
                            className={changesMade ? "saveButton" : "disabledSaveButton"}
                            onClick={() => this.saveMethod(SaveOption.Save)}
                            disabled={!changesMade}
                        >
                            <div className="saveButtonText">{dataActionToolbar.find(f => f.method === "Save").name}</div>
                        </button>
                        <div>
                            <button
                                type="button"
                                className={"saveButton dropdown-toggle"}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                aria-disabled={isReadonly}>
                                <span className="caret"></span>
                                <span className="sr-only">Toggle Dropdown</span>
                            </button>
                            <ul className="dropdown-menu">
                                <NavItem key={1}>
                                    <NavLink
                                        onClick={() => this.saveMethod(SaveOption.SavePost)}
                                    >Save & Post</NavLink>
                                </NavItem>
                            </ul>
                        </div>
                    </div>
                </div>
            );
        }
        else if (component === LICENSE_MAINTENANCE_TOOLBAR) {
            const { isLicensePageModified } = this.props;
            return (
                <div className="saveButton" key={lastIndex}>
                    <div className="saveButtonContainer" key={lastIndex}>
                        <button
                            aria-disabled={!(isLicensePageModified || changesMade)}
                            className={(isLicensePageModified || changesMade) ? "saveButton" : "disabledSaveButton"}
                            onClick={() => this.saveMethod(SaveOption.Save)}
                            disabled={!(isLicensePageModified || changesMade)}
                        >
                            <div className="saveButtonText">{dataActionToolbar.find(f => f.method === "Save").name}</div>
                        </button>
                    </div>
                </div>
            );
        }
        else if (this.showSaveButtonCommonCuesheet()){
                return (
                    <div className="saveButton" key={lastIndex}>
                       <div className="saveButtonContainer" key={lastIndex}>
                           <button
                               aria-disabled="false"
                               className="saveButton"
                               onClick={() => this.saveMethod(SaveOption.Save)}
                           >
                               <div className="saveButtonText">{dataActionToolbar.find(f => f.method === "Save").name}</div>
                           </button>
                       </div>
                   </div>
               );
        }
        else {
            if (component === WORKFLOW_PAGE_TOOLBAR)
                return null;
            
            return (
                 <div className="saveButton" key={lastIndex}>
                    <div className="saveButtonContainer" key={lastIndex}>
                        <button
                            aria-disabled={!changesMade}
                            className={changesMade ? "saveButton" : "disabledSaveButton"}
                            onClick={() => this.saveMethod(SaveOption.Save)}
                            disabled={!changesMade}
                        >
                            <div className="saveButtonText">{dataActionToolbar.find(f => f.method === "Save").name}</div>
                        </button>
                    </div>
                </div>
            );
        }
    }
    };

    renderWarningMessages() {
        const { tabs, activeTab, saveResultData, resetMessageBanner } = this.props;
        const messages: string[] = [];
        let isNotClosable = false;

        if (tabs[activeTab].validationWarning) {
             messages.push(saveResultData.fields.find(x => x.name.toString() === 'validationError').data);
        }

        if (tabs[activeTab].perfSharesWarning) {
            isNotClosable = true;
            messages.push(saveResultData.fields.find(x => x.name.toString() === 'workPerformingSharesNot100').data);
        }
        if (tabs[activeTab].mechSharesWarning) {
            isNotClosable = true;
            messages.push(saveResultData.fields.find(x => x.name.toString() === 'workMechanicalSharesNot100').data);
        }

        if (tabs[activeTab].paymentRunMaintenanceState && tabs[activeTab].paymentRunMaintenanceState.paymentRun && tabs[activeTab].paymentRunMaintenanceState.paymentRun.showStatusChangeWarning) {
            messages.push(saveResultData.fields.find(x => x.name.toString() === 'paymentRunStatusChangeWarningMessage').data);
        }

        if (tabs[activeTab].workMaintenanceState && tabs[activeTab].workMaintenanceState.work && tabs[activeTab].workMaintenanceState.work.validateRoleBasedOnIpiTypeWarning) {
            messages.push(saveResultData.fields.find(x => x.name.toString() === 'workContributorRoleIpiTypeInvalidMessage').data);
        }

        if (messages.length > 0) {
            return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Warning} messages={messages} isNotClosable={isNotClosable}/>;
        }
    }

    renderWorkflowBanner() {
        const { work
            , product
            , workflowFieldsData
            , isWorkflowSessionStarted
            , skipWorkflowItem
            , tabs
            , cancelWorkflowSession
            , nextWorkflowItem
            , workflowSessionItems
            , activeTab
            , currentWorkflowIndex
            , workMaintenanceGeneralDataViewData
            , workFormats
            , lookupValues
            , dataActionToolbar
            , isOpenForWorkflowSession,
            skipUsageWorkflowItem,
            saveWorkflow,
            refreshWorkDetails,
            refreshProductDetails,
            roles,
            updateWorkflowAndEntityStatus,
            completeWorkflowOnSave,
            agreement,
            skipProductWorkflowItem
        } = this.props;
        if (work != null) {
            if (work.workflows != null && work.workflows.length > 0) {
                return (
                    <WorkflowBanner
                        workflows={work.workflows}
                        workflowFieldsData={workflowFieldsData}
                        isWorkflowSessionStarted={isWorkflowSessionStarted}
                        nextWorkflowItem={nextWorkflowItem}
                        cancelWorkflowSession={cancelWorkflowSession}
                        skipWorkflowItem={skipWorkflowItem}
                        tabs={tabs}
                        workflowSessionItems={workflowSessionItems}
                        activeTab={activeTab}
                        currentWorkflowIndex={currentWorkflowIndex}
                        dataActionList={dataActionToolbar}
                        workFormats={workFormats}
                        workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                        lookupValues={lookupValues}
                        isOpenForWorkflowSession={isOpenForWorkflowSession}
                        skipUsageWorkflowItem={skipUsageWorkflowItem}
                        refreshDetails={refreshWorkDetails}
                        saveWorkflow={saveWorkflow}
                        roles={roles}
                        updateWorkflowAndEntityStatus={updateWorkflowAndEntityStatus}
                        completeWorkflowOnSave={completeWorkflowOnSave}
                        skipProductWorkflowItem={skipProductWorkflowItem}
                    />
                );
            }
            else {
                return null;
            }
        }
        else if (product != null) {
            if (product.workflows != null && product.workflows.length > 0) {
                return (
                    <WorkflowBanner
                        workflows={product.workflows}
                        workflowFieldsData={workflowFieldsData}
                        isWorkflowSessionStarted={isWorkflowSessionStarted}
                        nextWorkflowItem={nextWorkflowItem}
                        cancelWorkflowSession={cancelWorkflowSession}
                        skipWorkflowItem={skipWorkflowItem}
                        tabs={tabs}
                        workflowSessionItems={workflowSessionItems}
                        activeTab={activeTab}
                        currentWorkflowIndex={currentWorkflowIndex}
                        dataActionList={dataActionToolbar}
                        //workFormats={workFormats}
                        lookupValues={lookupValues}
                        isOpenForWorkflowSession={isOpenForWorkflowSession}
                        refreshDetails={refreshProductDetails}
                        saveWorkflow={saveWorkflow}
                        roles={roles}
                        updateWorkflowAndEntityStatus={updateWorkflowAndEntityStatus}
                        completeWorkflowOnSave={completeWorkflowOnSave}
                        skipProductWorkflowItem={skipProductWorkflowItem}
                    />
                )
            }
        }
        else if (agreement != null) {
            if (agreement.workflows != null && agreement.workflows.length > 0) {
                return (
                    <WorkflowBanner
                        workflows={agreement.workflows}
                        workflowFieldsData={workflowFieldsData}
                        isWorkflowSessionStarted={isWorkflowSessionStarted}
                        nextWorkflowItem={nextWorkflowItem}
                        cancelWorkflowSession={cancelWorkflowSession}
                        skipWorkflowItem={skipWorkflowItem}
                        tabs={tabs}
                        workflowSessionItems={workflowSessionItems}
                        activeTab={activeTab}
                        currentWorkflowIndex={currentWorkflowIndex}
                        dataActionList={dataActionToolbar}
                        lookupValues={lookupValues}
                        isOpenForWorkflowSession={isOpenForWorkflowSession}
                        refreshDetails={refreshProductDetails}
                        saveWorkflow={saveWorkflow}
                        roles={roles}
                        updateWorkflowAndEntityStatus={updateWorkflowAndEntityStatus}
                        completeWorkflowOnSave={completeWorkflowOnSave}
                        skipProductWorkflowItem={skipProductWorkflowItem}
                    />
                )
            }
        }
        else {
            return null;
        }
    }

    render() {
        const { toolbarWidth, tabs, activeTab, saveResultData, resetMessageBanner, changesMade, shortCutKeys, dataSource, component, allocationJobInProgress, bookPriceCalculationsJobInProgress, work } = this.props;
        const { loaded } = this.state;
        let sections = [];
        let messages: IValidationMessage[];
        let msg = { messageKey: '' };
        if (tabs[activeTab].validationMessages != null) {
            messages = [];
            Array.from(tabs[activeTab].validationMessages).forEach(e => {
                if (saveResultData.fields.find(x => x.name.toString() === e.messageKey)?.section == null) {
                    sections.push(saveResultData.fields.find(x => x.name.toString() === e.messageKey)?.section);
                    let validationMessage: IValidationMessage = { messageKey: e.messageKey, additionalInfo: e.additionalInfo }
                    messages.push(validationMessage);
                } else if (saveResultData.fields.find(x => x.name.toString() === e.messageKey) !== null && tabs[activeTab].component === 'LicenseMaintenancePage') {                 
                    let validationMessage: IValidationMessage = { messageKey: saveResultData.fields.find(x => x.name.toString() === e.messageKey).data };
                    messages.push(validationMessage);

                } else {
                    if (messages != undefined) {
                        let validationMessage: IValidationMessage = messages.filter(x => x.messageKey !== "A validation error has occurred during saving. Check below for the specific message.") ? { messageKey: "A validation error has occurred during saving. Check below for the specific message." } : { messageKey: "" };
                        messages.push(validationMessage);
                    }
                }
            })
        }
        if (loaded) {           

            return (
                <>
                <ToolbarEventListener
                    saveMethod={this.saveMethod.bind(this)}
                    component={component}
                    dataSource={dataSource}
                    changesMade={changesMade}
                    shortCutKeys={shortCutKeys}
                />
                    <div
                        className="toolbarWrapper"
                        id={"toolbar"}
                        style={{ width: toolbarWidth }}
                    >
                        {this.renderToolbarButtons()}
                        {work?.iceWorkKeyHyperlink ? this.renderIceHyperLink() : null}
                        {component === DISTRIBUTION_MAINTENANCE_TOOLBAR  ? this.renderSecondaryActionsDistributions() : null}
                        {component === WORK_MAINTENANCE_TOOLBAR  ? this.renderSecondaryActionsWorks() : null}
                        {this.renderSaveButton()}
                    </div>
                    <If condition={(tabs[activeTab].saveSuccessful) || (tabs[activeTab].postSuccessful) || (tabs[activeTab].matchingSuccessful) ||
                        (tabs[activeTab].mergeSuccessful) || (tabs[activeTab].exportSuccessful) || (tabs[activeTab].deleteSuccessful) ||
                        (tabs[activeTab].newAllocateDistributionPoolsJobSuccess) || (tabs[activeTab].processClaimJobSuccess) || (tabs[activeTab].mergeAgreementSuccessful) ||
                        (messages != null) &&
                        (messages.length > 0) || ((sections.includes(null)) || (sections.includes(undefined)))}>
                        <SaveResult
                            componentInstance={component}
                            saveSuccess={tabs[activeTab].saveSuccessful}
                            postSuccess={tabs[activeTab].postSuccessful}
                            matchingSuccess={tabs[activeTab].matchingSuccessful}
                            mergeSuccess={tabs[activeTab].mergeSuccessful}
                            exportSuccess={tabs[activeTab].exportSuccessful}
                            saveResultData={saveResultData}
                            messages={messages}
                            resetMessageBanner={resetMessageBanner}
                            deleteAccount={tabs[activeTab].deleteSuccessful}
                            newAllocateDistributionPoolsJobSuccess={tabs[activeTab].newAllocateDistributionPoolsJobSuccess}
                            allocationJobInProgress={allocationJobInProgress}
                            newBookPriceCalculationsJobSuccess={tabs[activeTab].newBookPriceCalculationsJobSuccess}
                            bookPriceCalculationsJobInProgress={bookPriceCalculationsJobInProgress}
                            processClaimJobSuccess={tabs[activeTab].processClaimJobSuccess}
                            mergeAgreementSuccess={tabs[activeTab].mergeAgreementSuccessful}
                        />
                    </If>
                    {this.renderWorkflowBanner()}
                    {this.renderWarningMessages()}
                </>
            );
        }
        return <div />;
    }
}

export default connect(   (state: IAppState, props: IToolbarComponentProps) => ({
    shortCutKeys: state.repertoire.shortCutKeys,
}), null)(ToolbarComponent);