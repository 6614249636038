import { useMsal } from "@azure/msal-react";
import * as React from "react";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ILookup, ILookupInstance } from "../../lookup/types/ILookup";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { setAdjustmentDataSource } from "../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import SearchView from "../components/searchView/SearchView";
import SelectorView from "../components/selectorView/SelectorView";
import { ALL_ROLES, INTRAY, POSTED, SEARCH_VIEW_ADJUSTMENTS, SEARCH_VIEW_DISTRIBUTIONS, UPDATE_ADJUSTMENTS_ROLE, VIEW_ADJUSTMENTS_ROLE, VIEW_DISTRIBUTIONS_ROLE, VIEW_POOLS_ROLE } from "../Consts";
import { IAdjustment } from "../types/usageTypes/IAdjustment";
import { IAdjustmentSearchQuery } from "../types/usageTypes/IAdjustmentSearchQuery";
import { IDistribution } from "../types/usageTypes/IDistribution";

export interface IAdjustmentPageProps {
    adjustmentDataSource: string;
    setAdjustmentDataSource: typeof setAdjustmentDataSource;
    adjustmentsPageData: IRepertoireComponentDataItem;
    adjustmentGeneralDataView: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchAdjustments: (query: IAdjustmentSearchQuery, dataSource: string) => void;
    addNewAdjustment: (lookupValues: ILookupDictionary, isNew: boolean, adjustmentGeneralDataView: IRepertoireComponentDataItem) => void;
    adjustmentsSearchResults: IAdjustment[];
    searchSuccessful: boolean;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    getDistributions?: () => void;
    getPools?: () => void;
    distributions?: IDistribution[];
    pools?: { code: string; description: string; }[];
    users?: { code: string; description: string; }[];
    getAdjustmentDetails: (adjustmentId: string, adjustments: IAdjustment[]) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
}

const AdjustmentsPage = (props: IAdjustmentPageProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        checkIfLoaded();
        getRoles();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);

    }, [])

    React.useEffect(() => {
        const { getDistributions, getPools, distributions, pools, roles } = props;
        checkIfLoaded();

        if (roles.length > 0 && distributions.length === 0 && (roles.includes(VIEW_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES)))
            getDistributions();
        if (roles.length > 0 && pools.length === 0 && (roles.includes(VIEW_POOLS_ROLE) || roles.includes(ALL_ROLES)))
            getPools();

    }, [props, loaded])

    React.useEffect(() => {
        const { getDistributions, getPools, roles } = props;
        if (roles.includes(VIEW_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES)) getDistributions();
        if (roles.includes(VIEW_POOLS_ROLE) || roles.includes(ALL_ROLES)) getPools();
    }, [loaded])


    const checkIfLoaded = () => {
        const { adjustmentsPageData, roles } = props;
        if (adjustmentsPageData.fields && adjustmentsPageData.fields.length >= 1 && !loaded) {
            if (roles.length > 0) setLoaded(true);
            const header = adjustmentsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const addNewAdjustment = (): void => {
        const { addNewAdjustment, adjustmentGeneralDataView, lookupValues } = props;
        addNewAdjustment(lookupValues, true, adjustmentGeneralDataView);
    }

    const getAdjustmentDetail = (adjustmentId: string) => {
        const { adjustmentsSearchResults, getAdjustmentDetails } = props;
        getAdjustmentDetails(adjustmentId, adjustmentsSearchResults);
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const {
        adjustmentDataSource,
        setAdjustmentDataSource,
        searchViewData,
        searchResultsTableData,
        searchAdjustments,
        adjustmentsSearchResults,
        adjustmentsPageData,
        searchSuccessful,
        indexOfFirstResult,
        indexOfLastResult,
        resultsPerPage,
        currentPage,
        resetPagination,
        updatePagination,
        sortSearchResults,
        distributions,
        pools,
        users,
        getAdjustmentDetails
    } = props;

    let { lookupValues } = props;

    const getUpdateRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(UPDATE_ADJUSTMENTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(VIEW_ADJUSTMENTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    if (loaded) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Adjustment</span>
                        <div className="newWorkButton" title="Create New Adjustment" hidden={getUpdateRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewAdjustment}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                New Adjustment
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <SelectorView
                        selection={adjustmentDataSource}
                        setSelection={setAdjustmentDataSource}
                        option1={INTRAY}
                        option2={POSTED}
                        selectorTitle={adjustmentsPageData.fields[1].data}
                    />
                </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_ADJUSTMENTS}
                    modalProps={undefined}
                    dataSource={adjustmentDataSource}
                    hideModal={undefined}
                    lookupValues={lookupValues}
                    searchAdjustments={searchAdjustments}
                    adjustmentsSearchResults={adjustmentsSearchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updatePagination}
                    sortSearchResults={sortSearchResults}
                    distributions={distributions}
                    pools={pools}
                    users={users}
                    getAdjustmentDetails={getAdjustmentDetail}
                    searchDisabled={getViewRoleDisabled()}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default AdjustmentsPage;