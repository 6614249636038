import { ADD_METHOD_TOOLBAR, ADD_REPRESENTATION_TOOLBAR, ADD_TO_PACKAGES_TOOLBAR, AGREEMENT_MAINTENANCE_TOOLBAR, ASSING_TO_METHOD_TOOLBAR, COPY_METHOD_TOOLBAR, DELETE_METHOD_TOOLBAR, DESELECT_ALL_METHOD_TOOLBAR, HISTORY_METHOD_TOOLBAR, MATCH_METHOD_TOOLBAR, MERGE_METHOD_TOOLBAR, PRODUCT_MAINTENANCE_TOOLBAR, SAVE_METHOD_TOOLBAR, START_WORKFLOW_SESSION_METHOD_TOOLBAR, UNDO_METHOD_TOOLBAR, UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR, WORKFLOW_PAGE_TOOLBAR, WORK_MAINTENANCE_TOOLBAR } from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { IDataActionToolbar } from "../../types/IDataActionToolbar";

export function getDataAction(dataSource: string, component: string) {

    const actionList: IDataActionToolbar[] = [];


    if ((!dataSource || dataSource.length === 0) && component === WORKFLOW_PAGE_TOOLBAR) {
        actionList.push({
            action: null,
            component: component,
            method: DESELECT_ALL_METHOD_TOOLBAR,
            name: null
        });

        actionList.push({
            action: null,
            component: component,
            method: ASSING_TO_METHOD_TOOLBAR,
            name: null
        });

        actionList.push({
            action: null,
            component: component,
            method: UPDATE_WORKFLOW_STATUS_METHOD_TOOLBAR,
            name: null
        });

        actionList.push({
            action: null,
            component: component,
            method: START_WORKFLOW_SESSION_METHOD_TOOLBAR,
            name: null
        });


        return actionList;
    }

    const copy: IDataActionToolbar = {
        action: null,
        component: component,
        method: COPY_METHOD_TOOLBAR,
        name: null
    };
    actionList.push(copy);

    const undo: IDataActionToolbar = {
        action: null,
        component: component,
        method: UNDO_METHOD_TOOLBAR,
        name: null
    };
    actionList.push(undo);

    const merge: IDataActionToolbar = {
        action: null,
        component: component,
        method: MERGE_METHOD_TOOLBAR,
        name: null
    };
    actionList.push(merge);

    const add: IDataActionToolbar = {
        action: null,
        component: component,
        method: ADD_METHOD_TOOLBAR,
        name: null
    };
    actionList.push(add);


    const match: IDataActionToolbar = {
        action: null,
        component: component,
        method: MATCH_METHOD_TOOLBAR,
        name: null,
    };
    actionList.push(match);

    const deleteWork: IDataActionToolbar = {
        action: null,
        component: component,
        method: DELETE_METHOD_TOOLBAR,
        name: null
    };
    actionList.push(deleteWork);

    const save: IDataActionToolbar = {
        action: null,
        component: component,
        method: SAVE_METHOD_TOOLBAR,
        name: null,
    };
    actionList.push(save);

    if ((component === WORK_MAINTENANCE_TOOLBAR) || (dataSource !== DataSource.Intray && (component === PRODUCT_MAINTENANCE_TOOLBAR || component === AGREEMENT_MAINTENANCE_TOOLBAR))) {
        const history: IDataActionToolbar = {
            action: null,
            component: component,
            method: HISTORY_METHOD_TOOLBAR,
            name: null
        };
        actionList.push(history);
        
        const addtoPackage: IDataActionToolbar = {
            action: null,
            component: component,
            method: ADD_TO_PACKAGES_TOOLBAR,
            name: null
        };
        actionList.push(addtoPackage);
    }
    return actionList;
}
