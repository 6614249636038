import { SUSPENSE_RELEASE_JOB_TYPE, UNDISTRIBUTED_SHARES_JOB_TYPE, UNIDENTIFIED_PERFORMANCE_JOB_TYPE } from "../Consts";
import { ComboBoxJobParameterControl } from "../types/JobParameterControls/ComboBoxJobParameterControl";
import { IJobParameterControlBase } from "../types/JobParameterControls/IJobParameterControlBase";
import { MultiselectJobParameterControl } from "../types/JobParameterControls/MultiselectJobParameterControl";

export class JobParameterCustomEvents {
    public static SubscribeCustomEvents(jobControls: IJobParameterControlBase[], jobType: string): void {
        switch(jobType) {
            case UNIDENTIFIED_PERFORMANCE_JOB_TYPE:
                let upDistribStatusComboControlIndex = jobControls.findIndex((v, i, o) => v.ControlID == 'UNDFDPERF_DistribStatusCtrl');
                let upDistribStatusComboControl = jobControls[upDistribStatusComboControlIndex] as ComboBoxJobParameterControl;
                let upDistribCodeMultiSelectControlIndex = jobControls.findIndex((v, i, o) => v.ControlID == 'UNDFDPERF_Distribution');
                let upDistribCodeMultiSelectControl = jobControls[upDistribCodeMultiSelectControlIndex] as MultiselectJobParameterControl;
                upDistribStatusComboControl.OnChange = (value) => {
                    if (value == "CLOSED")
                        upDistribCodeMultiSelectControl.Options = "closedDistributions";
                }
                
                upDistribStatusComboControl.OnChange(upDistribStatusComboControl.DefaultValue);

                break;

            case UNDISTRIBUTED_SHARES_JOB_TYPE:
                let udsDistribStatusComboControlIndex = jobControls.findIndex((v, i, o) => v.ControlID == 'UNDSTSHRS_DistribStatusCtrl');
                let udsDistribStatusComboControl = jobControls[udsDistribStatusComboControlIndex] as ComboBoxJobParameterControl;
                let udsDistribCodeMultiSelectControlIndex = jobControls.findIndex((v, i, o) => v.ControlID == 'UNDSTSHRS_Distribution');
                let udsDistribCodeMultiSelectControl = jobControls[udsDistribCodeMultiSelectControlIndex] as MultiselectJobParameterControl;
                udsDistribStatusComboControl.OnChange = (value) => {
                    if (value == "CLOSED")
                        udsDistribCodeMultiSelectControl.Options = "closedDistributions";
                }
                
                udsDistribStatusComboControl.OnChange(udsDistribStatusComboControl.DefaultValue);

                let udsOutputComboControlIndex =  jobControls.findIndex((v, i, o) => v.ControlID == 'UNDSTSHRS_Output');
                let udsOutputComboControl =  jobControls[udsOutputComboControlIndex] as ComboBoxJobParameterControl;
                udsOutputComboControl.OnChange(udsOutputComboControl.DefaultValue);
                
                break;
            case SUSPENSE_RELEASE_JOB_TYPE:
                let srDistribStatusComboControlIndex = jobControls.findIndex((v, i, o) => v.ControlID == 'SR_DistribStatusCtrl');
                let srDistribStatusComboControl = jobControls[srDistribStatusComboControlIndex] as ComboBoxJobParameterControl;
                let srDistribCodeComboControlIndex = jobControls.findIndex((v, i, o) => v.ControlID == 'SR_Distribution');
                let srDistribCodeComboControl = jobControls[srDistribCodeComboControlIndex] as ComboBoxJobParameterControl;
                srDistribStatusComboControl.OnChange = (value) => {
                    if (value == "CLOSED")
                        srDistribCodeComboControl.Options = "openDistributions";
                }

                srDistribStatusComboControl.OnChange(srDistribStatusComboControl.DefaultValue);

                break;


            default:
                break;
        }
    }
}