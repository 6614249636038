import * as React from "react";
import { IRepertoireField } from "../../../types/IRepertoireField";

export interface IYesNoPromptViewModalProps {  
    yesCallback: () => void;
    noCallback: () => void;
}

export interface IYesNoPromptViewProps {
    fields: IRepertoireField[];
    props: IYesNoPromptViewModalProps;
}

const YesNoPromptView: React.SFC<IYesNoPromptViewProps> = ({ fields, props }) => {
    const { yesCallback, noCallback } = props;
    const yesButtonText: string = fields.find(f=>f.name==="yesButton").data;
    const noButtonText: string = fields.find(f=>f.name==="noButton").data;
    return (
        <div className="yesNoPromptView">
            <div className="row">
                <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                    <button className="button btn-defaultSecondary"
                        onClick={noCallback}>
                        {noButtonText}
                    </button>
                </div>

                <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                    <button className="button btn-defaultPrimary"
                        onClick={yesCallback}>
                        {yesButtonText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default YesNoPromptView;