import * as React from 'react';
import { IComponentDataItem } from '../../../../core/types/IComponentDataItem';
import { addFileToUpload, CleanUploadFilesState, editingChanged, fileMetadataChanged, setInDropZone, setProgressUploadFileProccess, setSelectedNode, sortColumn, toggleSidebarCollapsed, validationMessageChanged } from '../../../../redux/reducers/DataIngestionReducer';
import IDroppedFiles from '../../../../redux/types/IDroppedFiles';
import IFile from '../../../../redux/types/IFiles';
import { ITreeData } from '../../../../repertoire/types/ITreeData';
import { IDistribution } from "../../../../repertoire/types/usageTypes/IDistribution";
import { StorageService } from '../../../../dataingestion/services/StorageService';
import { IFileSystem } from '../../../../dataingestion/types/IFileSystem';
import { SaveState } from '../../../../dataingestion/types/SaveState';
import {
    ERROR_OBTAINING_SAS, ERROR_UPLOADING_FILE_SAS, OBTAINED_SAS, REQUEST_SAS, UPLOADING_FILE_SAS, UPLOAD_COMPLETED
} from '../../../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindowFields';
import { ITabReduxItem } from '../../../../redux/types/ITabReduxItem';
import { IDistributionState } from '../../../../redux/types/IDistributionState';
import { hideModal } from '../../../../redux/reducers/ModalReducer';
import { ILookupDictionary } from '../../../../lookup/types/ILookupDictionary';

interface IImportViewProps {
    containerDetailsWindowComponentData: IComponentDataItem;
    pageContentIsActive: boolean;
fileMetadataChanged: typeof fileMetadataChanged;
    openDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    currentDirectory: string;
    toggleSidebarCollapsed: typeof toggleSidebarCollapsed;
    validationMessageChanged: typeof validationMessageChanged;
    saveState: SaveState;
    distributions: IDistribution[];
    editingChanged: typeof editingChanged;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    isRepertoireModule?: boolean;
    component?: string;
    lookupValues?: ILookupDictionary;
    importDistributionPools: (distribution: IDistributionState, file: string, lookupValues: ILookupDictionary) => void;
    tabs: ITabReduxItem[];
    activeTab: number;
    hideModal: typeof hideModal;
}

interface IImportViewState {
    loaded: boolean;
    path: string;
}

export class ImportView extends React.Component<
    IImportViewProps,
    IImportViewState
>{
    viewData;
    constructor(props: IImportViewProps) {
        super(props);

        this.state = {
            loaded: false,
            path: '',
        };
        this.viewData = {};
    }

    componentDidMount() {
        this.loadFields();
    }

    public ascending: boolean = false;

    loadFields() {
        const { loaded } = this.state;
        const { containerDetailsWindowComponentData: { fields } } = this.props;

        if (!loaded) {
            if (!this.props.containerDetailsWindowComponentData) {
                return;
            }
            if (fields)
                fields.forEach(item => {
                    this.viewData[item.name] = item.data;
                });
            this.setPath()
            if (Object.keys(this.viewData).length === fields.length) {
                this.setState({ loaded: true });
            }
        }
    }

    sortTableByColumn = (section: string, ascending: boolean) => {
        this.props.sortColumn(section, ascending);

    };

    setPath = () => {
        const { currentDirectory } = this.props;
        switch (currentDirectory) {
            case "distributions":
                this.setState(prevState => ({
                    path: "repertoire/distributions/pools/input/"
                }))
        }
    }

    handleImport = (fileName: string) => {
        const { currentDirectory, tabs, activeTab, importDistributionPools, lookupValues } = this.props;
        switch (currentDirectory) {
            case "distributions":
                const distribution = tabs[activeTab].distributionMaintenanceState.distribution
                importDistributionPools(distribution, fileName, lookupValues)
                break;
            default:
                break;
        }
    }

    uploadFile(newFile: IFile, path: string, file: any) {
        const { addFileToUpload, setProgressUploadFileProccess, setInDropZone, isRepertoireModule, hideModal } = this.props;

        addFileToUpload(newFile, true);
        setInDropZone(false);
        setProgressUploadFileProccess(this.viewData[REQUEST_SAS], newFile, false);

        return Promise.resolve(
            StorageService.getUploadUri(path, isRepertoireModule).then(
                (uri: string) => {
                    if (uri && uri.length > 0) {
                        setProgressUploadFileProccess(this.viewData[OBTAINED_SAS], newFile, false);
                        setProgressUploadFileProccess(this.viewData[UPLOADING_FILE_SAS], newFile, false);
                        return StorageService.uploadFile(uri, file).then(
                            (res: boolean) => {
                                if (res) {
                                    setProgressUploadFileProccess(this.viewData[UPLOAD_COMPLETED], newFile, true);
                                    this.handleImport(newFile.name);
                                    hideModal();
                                    return true;
                                }
                            },
                            reject => {
                                setProgressUploadFileProccess(this.viewData[ERROR_UPLOADING_FILE_SAS], newFile, true);
                                hideModal();
                                return false;
                            }
                        )
                            .catch(error => {
                                return false;
                            })
                    }
                },
                reject => {
                    setProgressUploadFileProccess(this.viewData[ERROR_OBTAINING_SAS], newFile, true);
                    return false;
                }
            )
        )
    }

    handleDrop = e => {
        const files = [...e.dataTransfer.files];
        files.forEach(file => {
            let newFile: IFile = {
                message: '',
                name: file.name,
                size: file.size,
                isInProgress: true
            }
            let fullPath: string = this.state.path + newFile.name;
            this.uploadFile(newFile, fullPath, file)
        });
        e.preventDefault();
    };

    handleDragLeave = e => {
        const { setInDropZone } = this.props;
        setInDropZone(false);
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragEnd = e => {
        const { setInDropZone } = this.props;
        setInDropZone(false);
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragEnter = e => {
        const { setInDropZone } = this.props;
        setInDropZone(true);
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragOver = e => {
        const { setInDropZone } = this.props;
        setInDropZone(true);
        e.preventDefault();
        e.stopPropagation();
    };

    render() {
        if (!this.state.loaded)
            return <div className="loading" />
        const { pageContentIsActive, isDropZone } = this.props;

        let className = "container-fluid ";
        let dragAndDropClassName = "dropBox notToDisplay";

        if (pageContentIsActive) {
            className = className + " active";
        }

        if (isDropZone) {
            dragAndDropClassName = "dropBox display";
        }
        return (
            <div className={`${className} flexRow flexJustifyContentCenter widthFull`}>
                <div className="row flexRow flexJustifyContentCenter widthFull">
                    <div className="form-group" style={{width: '90%'}}>
                        <div>
                            <div id="DragAndDropArea" className="dragAndDrop importFileDragAndDrop" onDragOver={e => this.handleDragOver(e)} onDragEnter={e => this.handleDragEnter(e)}>

                            <div className={dragAndDropClassName}
                                onDrop={e => this.handleDrop(e)}
                                onDragLeave={e => this.handleDragLeave(e)}
                                onDragEnd={e => this.handleDragEnd(e)}
                                onDragOver={e => this.handleDragOver(e)}
                                onDragEnter={e => this.handleDragEnter(e)}>
                                <div className="dropBoxContent">
                                    <div className="row">
                                        <span className="icon ms-Icon ms-Icon--Upload dopBoxContent-Icon" aria-hidden="true"></span>
                                    </div>
                                    <div className="row dropText">
                                        <span className="dopBoxContent-Heading">Drop files to upload</span>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
            </div>
        )
    }
}
