import axios from "axios";
import { IFileSystem } from "../types/IFileSystem";
import { IStoragePath } from "../types/IStoragePath";

export class StorageService {
    public static getFilesystems(path: string, isRepertoireModule?: boolean): Promise<IFileSystem[]> {
        return new Promise<IFileSystem[]>((resolve, reject) => {
            axios.post(encodeURI(`Storage/FileSystems`), { path: path, IsRepertoireModule: isRepertoireModule })
                .then(res => {
                    resolve(res.data);
                });
        });
    }

    public static getBlobs(path: string): Promise<IStoragePath[]> {
        return new Promise<IStoragePath[]>((resolve, reject) => {
            axios.post(encodeURI(`Storage/Blobs`), { path: path })
                .then(res => {
                    resolve(res.data);
                });
        });
    }

    public static updateMetadata(storagePaths: IStoragePath[]): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            axios.post(encodeURI(`Storage/Metadata`), storagePaths)
                .then(res => {
                    resolve(res.data);
                });
        });
    }

    public static downloadFile(path: string, isRepertoireModule?: boolean, optionalFileName?: string): Promise<string> {
        return new Promise<string>((resolve) => {
            const uri: string = `Storage/Download?filePath=${path}&isRepertoireModule=${isRepertoireModule ? true : false}&optionalFileName=${optionalFileName}`
            axios.get(encodeURI(uri))
                .then(res => {
                    if (res && res.status === 200)
                        resolve(res.data);
                    else
                        resolve(null);
                });
        });
    }

    public static async getUploadUri(path: string, isRepertoireModule?: boolean): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const uri: string = `Storage/Upload?filePath=${path}&isRepertoireModule=${isRepertoireModule ? true : false}`
            axios.get(encodeURI(uri))
                .then(res => {
                    if (res && res.status === 200)
                        resolve(res.data);
                    else
                        reject(null);

                })
                .catch(error => {
                    reject(false);
                });;
        });
    }

    public static uploadFile(uri: string, file: any): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            axios.put(uri, file, {
                headers: { 'x-ms-blob-type': 'BlockBlob' }
            })
                .then(res => {
                    if (res && res.status === 201)
                        resolve(true);
                    else
                        reject(res);
                })
                .catch(error => {
                    reject(false);
                });
        });
    }
}