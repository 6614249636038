import React from "react";
import { ComboBox } from "@fluentui/react";
import { ReactNode } from "react";
import { IJobParameterControl } from "./IJobParameterControl";
import {
  IDataSource,
  IDataSourceBase,
} from "../JobParameterControls/IDataSource";

export class ComboBoxJobParameterControl
  implements IJobParameterControl<string>
{
  CurrentValue: string;
  DefaultValue: string;
  Options?: { code: string; description: string }[] | string = null;
  ControlID: string;
  Type:
    | "combobox"
    | "text-input"
    | "date-picker"
    | "numeric"
    | "radio-button"
    | "checkbox"
    | "checkbox-list"
    | "multiselect" = "combobox";
  Caption: string;
  Readonly: boolean;
  DataSourceRepo: { [key: string]: IDataSource<string> };
  OnChange: (selectedOption: any) => void;
  OnSelect?: (selectedValue: string) => void;
  FilteredValues: string[];

  constructor() {
    this.OnChange = (selectedOption) => {
        if (selectedOption) {
            this.CurrentValue = selectedOption.key;
        }

        if (this.OnSelect) this.OnSelect(selectedOption.key);
    };
  }

  Render(): ReactNode {
    let options: { code: string; description: string }[];
    if (typeof this.Options == "string")
      options = this.DataSourceRepo[this.Options].Read();
    else options = this.Options;

    return (
        <div className="dropdownDataInput">
          <ComboBox
                selectedKey={this.CurrentValue}
                label={this.Caption}
                options={options.map((v, i, a) => ({
                    key: v.code,
                    text: v.description,
                })).filter(x => !this.FilteredValues || this.FilteredValues.includes(x.key))}
                onChange={(e, selectedOption, i, value) => {
                    this.OnChange(selectedOption);
                }}
                useComboBoxAsMenuWidth
                calloutProps={{ calloutMaxHeight: 270 }}
          />
        </div>
    );
  }
}
