import throttle from "lodash.throttle";
import * as React from "react";
import { If } from "../../../../core/components/If";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import {
    hideModal,
    showModal,
    showYesNoPrompt
} from "../../../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    EVENT_HANDLER_THROTTLE_TIME
} from "../../../Consts";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { WorkDetailsMapper } from "../../../../repertoire/services/WorkDetailsMapper";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { CleanUploadFilesState } from "../../../../redux/reducers/DataIngestionReducer";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import DraftWorksMaintenanceToolbar from "../../../draftWorks/components/draftWorksMaintenanceToolbar/DraftWorksMaintenanceToolbar";
import RegisteredWorksGeneralDataView from "../viewComponents/RegisteredWorksGeneralDataView";
import { FormatFields } from "../../../../redux/types/FormatFields";

export interface IRegisteredWorksViewPageProps {
    getFilesystems?: (isRepertoireModule?: boolean) => void;
    getDataIngestionComponentData?: () => void;
    containerDetailsWindowComponentData: IComponentDataItem;
    draftWorkMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    draftWorkMaintenanceToolbarData?: IMembersPortalComponentDataItem;
    draftWorkMaintenanceSaveResultData?: IMembersPortalComponentDataItem;
    draftWorkMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    work?: IWorkState;
    activeTab: number;
    tabs: ITabReduxItem[];
    dataSource: DataSource;
    lookupValues?: ILookupDictionary;
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    resetMessageBanner: () => void;
    addNewDraftWork: (lookupValues: ILookupDictionary, currentUserNameNumber: string, isNew?: boolean, draftWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    saveChanges: (work: IWorkState, draftWorksMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, lookupValues: ILookupDictionary) => void;
    undoDraftWorks?: (dataSource: string, draftWorksID: number, lookupValues: ILookupDictionary) => void;
    showModal: typeof showModal;
    copyDraftWork: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => void;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    currentUser: string;
    currentUserNameNumber?: string;
}

const RegisteredWorksViewPage: React.FC<IRegisteredWorksViewPageProps> = ({
    getFilesystems,
    getDataIngestionComponentData,
    containerDetailsWindowComponentData,
    cleanUploadFilesState,
    resetMessageBanner,
    lookupValues,
    work,
    tabs,
    dataSource,
    activeTab,
    addNewDraftWork, 
    draftWorkMaintenanceGeneralDataViewData,
    saveChanges,
    undoDraftWorks,
    draftWorkMaintenanceToolbarData,
    draftWorkMaintenanceSaveResultData,
    draftWorkMaintenanceGridsViewData,
    hideModal,
    showYesNoPrompt,
    dataGridTableData,
    showModal,
    copyDraftWork,
    currentUser,
    currentUserNameNumber,
}) =>  {
    const [scroll, setScroll] = React.useState(0);
    const toolbarParentRef = React.useRef<HTMLInputElement>(null);

    const handleScroll = () => {
        setScroll(window.scrollY)
    };

    React.useEffect(() => {
        window.addEventListener(
            "scroll",
            throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                trailing: true
            })
        );
        if (!containerDetailsWindowComponentData || !containerDetailsWindowComponentData.fields || containerDetailsWindowComponentData.fields.length === 0) {
            getDataIngestionComponentData();
        }
        getFilesystems(true);
        cleanUploadFilesState();

        return () => {
            window.removeEventListener(
                "scroll",
                throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                    leading: true
                }))
            }
    }, [])

    React.useEffect(() => {
        if (lookupValues && Object.keys(lookupValues).length > 0 && !work && tabs[tabs.length - 1].tabTitle === "Draft Works PT") {
            addNewDraftWorks();
        }
    }, [lookupValues, work])

    const saveAsDraftWork = () => {
        let draftWork: IWorkState = JSON.parse(JSON.stringify(work));
        draftWork.draftSubmitted = false;
        saveChanges(draftWork, draftWorkMaintenanceGeneralDataViewData, lookupValues);
    }
    const addNewDraftWorks = () => {
        addNewDraftWork(lookupValues, currentUserNameNumber, true, draftWorkMaintenanceGeneralDataViewData);
    }

    const undoChanges = () => {
        undoDraftWorks(dataSource, work.workID, lookupValues);
    }
    
    if (!work) {
        return <>Loading ...</>
    }
    const title = WorkDetailsMapper.getOriginalTitleFromTitles(work.workID, work.titles);
    const changesMade = tabs[activeTab].changesMade || false;
    const isReadonly: boolean = tabs[activeTab].isReadonly;

    let readOnlyDataGridTableData = JSON.parse(JSON.stringify(dataGridTableData));
    readOnlyDataGridTableData.fields = readOnlyDataGridTableData.fields.filter(f => f.fieldType !== 'remove_action')
    return (
    <div>
        <div><span className="title">{title}</span></div>
        <If condition={tabs[activeTab].versionNumber != null}>
            <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
            </If>
             <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
            </button>

            <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={toolbarParentRef}>
                <DraftWorksMaintenanceToolbar
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    showModal={showModal}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={null}
                    toolbarData={draftWorkMaintenanceToolbarData}
                    saveResultData={draftWorkMaintenanceSaveResultData}
                    work={work}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataSource={dataSource}
                    saveChanges={saveAsDraftWork}
                    isReadonly={isReadonly}
                    undoDraftWorksChanges={undoChanges}
                    addNewDraftWorks={() => addNewDraftWork(lookupValues, currentUserNameNumber, true, draftWorkMaintenanceGeneralDataViewData)}
                    isNew={tabs[activeTab].draftWorkMaintenanceState?.isNew}
                    lookupValues={lookupValues}
                    copyDraftWork={copyDraftWork}
                    currentUser={currentUser}
                />
            </div>
            <div style={{ padding: '20px' }}>
                <RegisteredWorksGeneralDataView
                    draftWorkMaintenanceGeneralDataViewData={draftWorkMaintenanceGeneralDataViewData}
                    work={work}
                    lookupValues={lookupValues}
                    editableFields={[]}
                    dataGridTableData={readOnlyDataGridTableData}
                    draftWorkMaintenanceGridsViewData={draftWorkMaintenanceGridsViewData}
                />
            </div>
    </div>
    );
}

export default RegisteredWorksViewPage
