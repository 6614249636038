import * as React from "react";
import { PureComponent, FunctionComponent } from "react";
import { USAGE_PAGE_FORM_ACTION_BUTTON_SIZE, USAGE_PAGE_FORM_ITEM_SIZE } from "../../../Consts";

export interface ICustomButtonProps {
    buttonText: string;
    buttonAction: () => void;
    disabled?: boolean;
}

export const SizedCustomButton: FunctionComponent<ICustomButtonProps> = (props: ICustomButtonProps) => {
    return <div className={USAGE_PAGE_FORM_ACTION_BUTTON_SIZE}>
        <CustomButton {...props} />
    </div>
}

export const WideCustomButton: FunctionComponent<ICustomButtonProps> = (props: ICustomButtonProps) => {
    return <div className={USAGE_PAGE_FORM_ITEM_SIZE}>
        <CustomButton {...props} />
    </div>
}

export default class CustomButton extends PureComponent<ICustomButtonProps> {
    render() {
        const { buttonText, buttonAction, disabled } = this.props;
        return (
            <button onClick={buttonAction} className="button btn-defaultPrimary" disabled={disabled}>
                {buttonText}
            </button>
        );
    }
}