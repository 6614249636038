import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";

type GET_LOOKUP_ENTITIES_REQUEST_TYPE = "GET_LOOKUP_ENTITIES_REQUEST";
type GET_LOOKUP_ENTITIES_SUCCESS_TYPE = "GET_LOOKUP_ENTITIES_SUCCESS";
type GET_LOOKUP_ENTITIES_FAILURE_TYPE = "GET_LOOKUP_ENTITIES_FAILURE";
type CLEAR_LOOKUP_CACHE_TYPE = "CLEAR_LOOKUP_CACHE";

export interface GetLookupEntitiesRequest {
    type: GET_LOOKUP_ENTITIES_REQUEST_TYPE;
}

export interface GetLookupEntitiesSuccess {
    type: GET_LOOKUP_ENTITIES_SUCCESS_TYPE;
    payload: {
        entities: ILookupDictionary;
    };
}

export interface GetLookupEntitiesFailure {
    type: GET_LOOKUP_ENTITIES_FAILURE_TYPE;
    payload: {
        error: string;
    };
}
export interface ClearLookupCache {
    type: CLEAR_LOOKUP_CACHE_TYPE;
}

export type ActionTypes = 
    GetLookupEntitiesRequest
    | GetLookupEntitiesSuccess
    | GetLookupEntitiesFailure
    | ClearLookupCache;

export const GET_LOOKUP_ENTITIES_REQUEST: GET_LOOKUP_ENTITIES_REQUEST_TYPE = "GET_LOOKUP_ENTITIES_REQUEST";
export const GET_LOOKUP_ENTITIES_SUCCESS: GET_LOOKUP_ENTITIES_SUCCESS_TYPE = "GET_LOOKUP_ENTITIES_SUCCESS";
export const GET_LOOKUP_ENTITIES_FAILURE: GET_LOOKUP_ENTITIES_FAILURE_TYPE = "GET_LOOKUP_ENTITIES_FAILURE";
export const CLEAR_LOOKUP_CACHE: CLEAR_LOOKUP_CACHE_TYPE = "CLEAR_LOOKUP_CACHE";