import * as React from "react";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { clearModalSearchResults, setEditableFields } from "../../../../redux/reducers/MembersPortalReducer";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { ADD_NEW_ACTION, DRAFT_AGREEMENTS_COMMENTS_STATE_KEY} from "../../../Consts";
import { showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import DataGridTable from "../../../../repertoire/components/dataGridTable/DataGridTable";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import { COMMENT_CATEGORY_LOOKUP } from "../../../../lookup/Consts";

export interface IDraftAgreementsMaintenanceGeneralDataViewProps {
    draftAgreementMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    draftAgreementMaintenanceCommentsView: any[];
    dataGridTableData?: IRepertoireComponentDataItem;
    draftAgreementMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    agreements: IAgreementState;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: any[];
    isNewDraftAgreement?: boolean;
    updateDraftAgreementField: (value: any, fieldName: IAgreementStateKeys, index?: number) => void;
    resetMessageBanner: () => void;
    changesMade: boolean;
    updateComponentFieldsItems?: (fields: IMembersPortalField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IMembersPortalComponentDataItem;
    dataGridTableEditableFieldsData?: IMembersPortalComponentDataItem;
    setEditableFields?: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    ) =>void;
    incrementTempID?: () => void;
    activeHeaderSection?: string;
    isMandatory?: boolean;
    shareDecimalsToDisplay?: number;
    clearModalSearchResults?: typeof clearModalSearchResults | (() => void);
}

const DraftAgreementsMaintenanceGeneralDataView:  React.FC<IDraftAgreementsMaintenanceGeneralDataViewProps> = ({
    isReadonly,
    lookupValues,
    updateDraftAgreementField,
    incrementTempID,
    dataGridTableData,
    draftAgreementMaintenanceGridsViewData,
    draftAgreementMaintenanceCommentsView,
    tabs,
    activeTab,
    shareDecimalsToDisplay,
    showModal,
    clearModalSearchResults,
    agreements,
}) => {
const [viewData, setViewData] = React.useState([]);
const [invisibleComments, setInvisibleComments] = React.useState([]);

React.useEffect(() => {
    if (agreements.comments) {
        let agreementsThatCanBeShowInMembersPortal = agreements.comments.filter(comment => comment.commentCategory.value === null || lookupValues[COMMENT_CATEGORY_LOOKUP].lookups.some(lookup => lookup.code === comment.commentCategory.value));
        let agreementsThatCanNotBeShowInMembersPortal = agreements.comments.filter(comment => comment.commentCategory.value !== null && !lookupValues[COMMENT_CATEGORY_LOOKUP].lookups.some(lookup => lookup.code === comment.commentCategory.value));
        setInvisibleComments(agreementsThatCanNotBeShowInMembersPortal)
        setViewData(agreementsThatCanBeShowInMembersPortal);
    }
}, [agreements]);


const renderGridViewHeaderSections = (header: IMembersPortalField) => {

    return (
        <div
            className={["headerSection", "activeSection"].join(" ")}
            key={header.name}
            data-testid={header.name + "-section"}
        >
            {header.data}
        </div>
    );
};

const changeData = (value: any, fieldName: string) => {
    let valuesToBeReturned = value;
    if (invisibleComments && invisibleComments.length > 0) {
        invisibleComments.forEach(comment => { valuesToBeReturned.push(comment) });
    }
    updateDraftAgreementField(valuesToBeReturned, "comments" as IAgreementStateKeys);
};

return (
    <>
        <div className="topMargin">
            <div className="row">
                <div className="headerContainer">
                    {renderGridViewHeaderSections(draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsComments'))}
                </div>
            </div>
                {
                    <div className="row">
                        <DataGridTable
                            tableContents={viewData ? viewData : []}
                            changeData={changeData}
                            dataGridTableData={dataGridTableData}
                            stateKey={DRAFT_AGREEMENTS_COMMENTS_STATE_KEY}
                            componentInstance={DRAFT_AGREEMENTS_COMMENTS_STATE_KEY}
                            showRowNumber={false}
                            isReadOnly={isReadonly}
                            tableActions={isReadonly ? [] : [{ name: ADD_NEW_ACTION }]}
                            lookupValues={lookupValues}
                            incrementTempID={incrementTempID}
                            tabs={tabs}
                            activeTab={activeTab}
                            showDropdownsAsCodes={false}
                            clearModalSearchResults={clearModalSearchResults}
                            showModal={showModal}                    
                            />
                    </div>
                }
            </div>
    </>
);



};

export default DraftAgreementsMaintenanceGeneralDataView;