import * as React from "react";
import { If } from "../../../../core/components/If";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import {
    hideModal,
    showYesNoPrompt
} from "../../../../redux/reducers/ModalReducer";
import {
    addDroppedFile,
    cleanDroppedFilesRepertoireState,
    setEditableFields, setProgressUploadFileRepertoireProccess
} from "../../../../redux/reducers/MembersPortalReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { Step } from "../../../components/multiStepForm/MultiStepForm";
import { IContributorItem } from "../../../../repertoire/types/IContributorItem";
import { Dictionary } from "../../../../core/types/Dictionary";
import { AGREEMENTS_KEY, AGREEMENTS_PAGE_VIEW, CONTRIBUTORS_KEY, DATE_INPUT, DROPDOWN_INPUT, INTEGER_INPUT, READ_ONLY_INPUT, TEXT_INPUT, WORKS_PAGE_VIEW } from "../../../../repertoire/Consts";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ITreeData } from "../../../../repertoire/types/ITreeData";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IDistribution } from "../../../../repertoire/types/usageTypes/IDistribution";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import { CleanUploadFilesState, addFileToUpload, fileMetadataChanged, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { IPaginationDetail } from "../../../../redux/types/IPaginationDetail";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { MEMBERS_PORTAL } from "../../../../accounts/Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { SearchRequests } from "../../../../repertoire/services/SearchRequests";
import { AGREEMENT_MAINTENANCE_GROUP, CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../../../ConfigurationConsts";
import DraftAgreementsMaintenanceForm from "../draftAgreementsMaintenanceForm/DraftAgreementsMaintenanceForm";
import DraftAgreementsMaintenanceToolbar from "../draftAgreementsMaintenanceToolbar/DraftAgreementsMaintenanceToolbar";
import DraftAgreementsMaintenanceGeneralDataView from "../draftAgreementsMaintenanceGeneralDataView/DraftAgreementsMaintenanceGeneralDataView";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import { clearModalSearchResults } from "../../../../redux/reducers/MembersPortalReducer";
import DraftAgreementsMaintenanceAssigneeView from "../draftAgreementsAssignee/DraftAgreementsMaintenanceAssigneeView";
import DraftAgreementsMaintenanceCommentsDataView from "../draftAgreementsMaintenanceCommentsDataView/DraftAgreementsMaintenanceCommentsDataView";
import DraftAgreementsMaintenanceApplicableWorksView from "../draftAgreementsMaintenanceApplicableWorksView/DraftAgreementsMaintenanceApplicableWorksView";
import { IAddAgreementAttachmentModel } from "../../../../redux/types/IAddAttchmentAgreementModel";
import { IRemoveAgreementAttachment } from "../../../../repertoire/types/IRemoveAgreementBlobAttachment";
import DraftAgreementsMaintenanceSubmissionPage from "../draftAgreementMaintenanceSubmissionPage/DraftAgreementsMaintenanceSubmissionPage";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { MembersPortalService } from "../../../../redux/services/MembersPortalService";
import IFile from "../../../../redux/types/IFiles";
import { ContainerDetailsWindow } from "../../../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindow";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { resetMessageBanner } from "../../../../redux/reducers/RepertoireReducer";
import { remove } from "lodash";

export interface IDraftAgreementsMaintenancePageProps {
    draftAgreementMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    draftAgreementMaintenanceCommentsView?: any[];
    draftAgreementsMaintenanceApplicableWorksView?:any[];
    draftAgreementMaintenanceToolbarData?: IMembersPortalComponentDataItem;
    draftAgreementMaintenanceSaveResultData?: IMembersPortalComponentDataItem;
    draftAgreementMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    draftAgreementMaintenanceCommentsData?: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    agreements?: any;
    activeTab: number;
    tabs: ITabReduxItem[];
    dataSource: DataSource;
    lookupValues?: ILookupDictionary;
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    resetMessageBanner: () => void;
    addNewDraftAgreement: (lookupValues: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftAgreementMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    updateDraftAgreementField?: (value: any, fieldName: IAgreementStateKeys, index?: number) => void;
    saveChanges: (work: IAgreementState, draftAgreementMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, lookupValues: ILookupDictionary, postAgreement: boolean, currentUserNumber: string,defaultTerritories?:any) => void;
    undoDraftAgreements?: (dataSource: string, draftWorksID: number, lookupValues: ILookupDictionary) => void;
    deleteDraftAgreement: (dataSource: string, draftWorksID: number, activeTab: number) => void;
    getDraftAgreementDetails: (dataSource: string, draftWorksID: number, lookupValues: ILookupDictionary, draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem) => void;
    showModal: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    ) =>void;
    clearModalSearchResults?: typeof clearModalSearchResults | (() => void);
    copyDraftAgreement: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => void;
    incrementTempID?: () => void;
    containerDetailsWindowComponentData: IComponentDataItem;
    distributions: IDistribution[];
    formats: ILookupInstance[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    attachedFiles: IStoragePathItemData[];
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire: typeof setProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire: typeof cleanDroppedFilesRepertoireState;
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    sortColumn: typeof sortColumn;
    getDataIngestionComponentData?: () => void;
    getFilesystems?: (isRepertoireModule?: boolean) => void;
    refreshAgreementDetails: (dataSource: string, agreementID: number, activeTabItem: ITabReduxItem,  formats?: FormatFields[]) => void;
    stepsCurrent?: number;
    stepsSetCurrent?: (current: number) => void;
    currentUser: string;
    portalType: string;
    currentUserNameNumber: string;
    getUserCRMIPNumber: (guid: string) => void;
    shareDecimalsToDisplay?: number;
    updatedScroll?: number;
    showSearchModal?: (modalProps: any, searchViewKey: string) => void;
    updateDraftAgreementWorkField?:(value: any, fieldName: IAgreementStateKeys, index?: number)=>void;
    getDraftAgreementWorkDetails?: (dataSource: string, workID: number, lookupValues: ILookupDictionary, agreementMaintenanceState: IMembersPortalComponentDataItem, formats?: FormatFields[]) => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    removeAgreementAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => void;
    agreementAttachmentsDropped?: IDroppedFiles;
    refreshWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], activeTabItem: ITabReduxItem, readonlyIndicatorsWorkFlagTypes: string[], formats?: FormatFields[]) => void;
    defaultTerritories?:any;
    getCustomerDefaultTerritories?:()=> void;
}


interface IDraftAgreementsMaintenancePageState {
    toolbarWidth: number;
    isBulkCheckboxActive?: boolean;
    contributorsLocal?: IContributorItem[];
    loaded: boolean;
}

const DraftAgreementsMaintenancePage: React.FC<IDraftAgreementsMaintenancePageProps> = ({
    getFilesystems,
    getDataIngestionComponentData,
    getUserCRMIPNumber,
    getCustomerDefaultTerritories,
    tabs,
    activeTab,
    agreements,
    draftAgreementMaintenanceGeneralDataViewData,
    draftAgreementMaintenanceCommentsView,
    draftAgreementMaintenanceToolbarData,
    draftAgreementMaintenanceSaveResultData,
    draftAgreementMaintenanceGridsViewData,
    draftAgreementMaintenanceCommentsData,
    draftAgreementsMaintenanceApplicableWorksView,
    hideModal,
    showYesNoPrompt,
    addNewDraftAgreement,
    updateDraftAgreementField,
    dataGridTableData,
    showModal,
    incrementTempID,
    dataSource,
    stepsCurrent,
    stepsSetCurrent,
    currentUser,
    currentUserNameNumber,
    portalType,
    clearModalSearchResults,
    updateDraftAgreementWorkField,
    getDraftAgreementWorkDetails,
    cleanUploadFilesState,
    containerDetailsWindowComponentData,
    destinations,
    distributions,
    droppedFiles,
    isDropZone,
    selectedFileSystem,
    storagePathItemData,
    attachedFiles,
    refreshDirectory,
    addRepertoireAttachmentToState,
    removeAgreementAttachmentIfExists,
    addDroppedFileRepertoire,
    agreementAttachmentsDropped,
    cleanDroppedFilesRepertoire,
    setProgressUploadFileRepertoire,
    paginationDetails,
    setInDropZone,
    lookupValues,
    defaultTerritories,
    refreshAgreementDetails,
    saveChanges,
    shareDecimalsToDisplay,
    updatedScroll,
    resetMessageBanner
}) => {
    
    const toolbarParentRef = React.useRef(null);
    const [toolbarWidth, setToolbarWidth] = React.useState(null);
    const [loaded, setLoaded] = React.useState(false);

    React.useEffect(() => {
        if (!containerDetailsWindowComponentData || !containerDetailsWindowComponentData.fields || containerDetailsWindowComponentData.fields.length === 0) {
            getDataIngestionComponentData();
        }
        getFilesystems(true);
        cleanUploadFilesState();
        getCustomerDefaultTerritories();

        if (!currentUserNameNumber) {
            getUserCRMIPNumber(sessionStorage.getItem('currentGuid'));
        }

        let isNewDraftAgreement: boolean = agreements ? agreements.agreementID && agreements.agreementID > 0 ? false : true : true;
        if (isNewDraftAgreement) {
           // this.addCurrentUserToContributors();
        }
        if (draftAgreementMaintenanceToolbarData && draftAgreementMaintenanceGridsViewData && lookupValues['AccountType']){
            setLoaded(true);
        }
    }, []);

    const undoChanges = () => {
        // const { dataSource, work, undoDraftWorks, lookupValues } = this.props;
        // undoDraftWorks(dataSource, work.workID, lookupValues);
    }

    React.useEffect(() => {
        if (draftAgreementMaintenanceToolbarData && draftAgreementMaintenanceGridsViewData && lookupValues['AccountType']) {
            setLoaded(true);
        }
    }, [lookupValues, draftAgreementMaintenanceGridsViewData, draftAgreementMaintenanceToolbarData, agreements]);


    const saveAsDraftAgreement = () => {
        let draftAgreement: IAgreementState = JSON.parse(JSON.stringify(agreements));
        draftAgreement.draftSubmitted = false;
        draftAgreement.status = lookupValues['AgreementStatus'].lookups.filter(x => x.code === 'draft')[0].code;
        draftAgreement.societyAffiliation = agreements.societyAffiliation;
        draftAgreement.agreementAdminIP = agreements.agreementAdminIP;
        saveChanges(draftAgreement, draftAgreementMaintenanceGeneralDataViewData, lookupValues, false, currentUserNameNumber, defaultTerritories);
    }

    const saveAndPostAgreement = () => {
        let draftAgreement: IAgreementState = JSON.parse(JSON.stringify(agreements));
        draftAgreement.draftSubmitted = true;
        draftAgreement.status = lookupValues['AgreementStatus'].lookups.filter(x => x.code === 'draftsubmitted')[0].code;
        draftAgreement.societyAffiliation = agreements.societyAffiliation;
        draftAgreement.agreementAdminIP = agreements.agreementAdminIP;
        saveChanges(draftAgreement, draftAgreementMaintenanceGeneralDataViewData, lookupValues, false, currentUserNameNumber);
    }
    
    const confirmAndSubmit = () => {
       
    }
    const uploadAgreementAttachment = (fileName: string, agreementID: number, dataSource: DataSource) => {
        const model: IAddAgreementAttachmentModel = {
            attachedFile: {
                fileName: fileName,
                agreementId: agreementID
            },
            dataSource: dataSource
        }
        return Promise.resolve(
            MembersPortalService.addAgreementAttachmentRepertoire(model)
                .then((res: IAttachedFile) => {
                    return res;
                })
                .catch((err: any) => {
                    const newFile: IFile = {
                        isInProgress: true,
                        message: err,
                        name: fileName,
                        size: 0
                    }
                    setProgressUploadFileProccess(err, newFile, true);
                    return false;
                })
        )
    }
    const refreshAgreementDetailsLocal = () => {
        let currentTab = tabs[activeTab];
        refreshAgreementDetails(
            dataSource,
            currentTab.agreementMaintenanceState.agreement.agreementID,
            currentTab
        )
    }

    const removeAttachmentFromMaintenanceScreen = (fullName: string, agreementId: number, dataSource: DataSource, guid: string, index: number) => {
        const attachedFile: IAttachedFile = {
            fileName: fullName,
            agreementId: agreementId,
        }
        const model: IRemoveAgreementAttachment = {
            Guid: guid,
            Source: dataSource,
            agreementID: agreementId,
        }

        MembersPortalService.removeAgreementBlobAttachment(model)
            .then(res => {
                if (res) {
                    MembersPortalService.removeAgreementAttachment({ attachedFile: attachedFile, dataSource: dataSource })
                        .then((res: boolean) => {
                            if (res) {
                                refreshAgreementDetailsLocal();
                            }
                        })
                }
            })
    }

    if (agreements && loaded) {
        
        const {
            ipBaseNumber,
            type,
            status,
            societyAffiliation,
            agreementAdminIP,
            ipNameNumber
        } = agreements;

        let isNewDraftAgreement: boolean = agreements?.agreementID < 1 ? true : false;

        const changesMade = tabs[activeTab].changesMade || false;
        const isReadonly: boolean = tabs[activeTab].isReadonly;

        let readOnlyDataGridTableData = JSON.parse(JSON.stringify(dataGridTableData));
        readOnlyDataGridTableData.fields = readOnlyDataGridTableData.fields.filter(f => f.fieldType !== 'remove_action')
        let updatedComponentData = containerDetailsWindowComponentData.fields.filter(x=>x.data!=='Tracking Information')
        let steps: Step[] =
            [
                {
                    title: draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsGeneral').data,//'General',
                    content: <DraftAgreementsMaintenanceGeneralDataView
                        draftAgreementMaintenanceGeneralDataViewData={draftAgreementMaintenanceGeneralDataViewData}
                        agreements={agreements}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        updateDraftAgreementField={updateDraftAgreementField}
                        showModal={showModal}
                        hideModal={hideModal}
                        isNewDraftAgreement={isNewDraftAgreement}
                        activeTab={activeTab}
                        tabs={tabs}
                        resetMessageBanner={resetMessageBanner}
                        incrementTempID={incrementTempID}
                        editableFields={[]}
                        changesMade={changesMade}
                        setEditableFields={setEditableFields}
                        activeHeaderSection={tabs[activeTab].activeHeaderSection}
                        dataGridTableData={dataGridTableData}
                        draftAgreementMaintenanceGridsViewData={draftAgreementMaintenanceGridsViewData}
                        defaultTerritories={defaultTerritories}
                    />
                },
                {
                   title: draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsAssignee').data,//'Assignee',
                    content:
                        <div className="GridsView">
                            <div className="AccordionContainer">
                            <DraftAgreementsMaintenanceAssigneeView 
                                draftAgreementMaintenanceGeneralDataViewData={draftAgreementMaintenanceGridsViewData}
                                updateDraftAgreementField={updateDraftAgreementField}
                                showModal={showModal}
                                hideModal={hideModal}
                                isNewDraftAgreement={isNewDraftAgreement}
                                dataGridTableData={dataGridTableData}
                                activeTab={activeTab}
                                tabs={tabs}
                                incrementTempID={incrementTempID}
                                clearModalSearchResults={clearModalSearchResults}
                                lookupValues={lookupValues}
                                agreements={agreements}
                            />
                            </div>
                        </div>
                },
                {
                    title: draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsApplicableWorks').data,//'Applicable Works',
                    content:
                    <div className="GridsView">
                            <div className="AccordionContainer">
                            <DraftAgreementsMaintenanceApplicableWorksView
                                        draftAgreementMaintenanceGridsViewData={draftAgreementMaintenanceGridsViewData}
                                        agreements={agreements}
                                        isReadonly={isReadonly}
                                        lookupValues={lookupValues}
                                        updateDraftAgreementField={updateDraftAgreementField}
                                        showModal={showModal}
                                        hideModal={hideModal}
                                        changesMade={changesMade}
                                        activeTab={activeTab}
                                        tabs={tabs}
                                        resetMessageBanner={resetMessageBanner}
                                        dataGridTableData={dataGridTableData}
                                        incrementTempID={incrementTempID} 
                                        updateDraftAgreementWorkField={updateDraftAgreementWorkField}
                                        getDraftAgreementWorkDetails={getDraftAgreementWorkDetails}
                                        />
                            </div>
                    </div>
                },
                {
                    title: draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsComments').data,//'Comments',
                    content:
                    <div className="GridsView">
                        <div className="AccordionContainer">
                            <DraftAgreementsMaintenanceCommentsDataView
                                draftAgreementMaintenanceGridsViewData={draftAgreementMaintenanceGridsViewData}
                                draftAgreementMaintenanceCommentsView={draftAgreementMaintenanceCommentsView}
                                agreements={agreements}
                                isReadonly={isReadonly}
                                lookupValues={lookupValues}
                                updateDraftAgreementField={updateDraftAgreementField}
                                showModal={showModal}
                                changesMade={changesMade}
                                activeTab={activeTab}
                                tabs={tabs}
                                resetMessageBanner={resetMessageBanner}
                                shareDecimalsToDisplay={shareDecimalsToDisplay}
                                editableFields={[]}
                                draftAgreementMaintenanceGeneralDataViewData={draftAgreementMaintenanceGeneralDataViewData}
                                dataGridTableData={dataGridTableData}
                                incrementTempID={incrementTempID}
                                clearModalSearchResults={clearModalSearchResults}
                            />
                        </div>
                    </div>
                },
                {
                    title: draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsAttachments').data,//'Attachments',
                    content: <div className={'topMarginRepertoireAttachment'}>
                        <h3
                            className={["headerSection", "activeSection"].join(" ")}
                            key={'artists'}
                        >
                            {'Attachments'}
                        </h3>
                        <ContainerDetailsWindow
                            addFileToUpload={addFileToUpload}
                            cancelChanges={undefined}
                            cleanUploadFilesState={cleanUploadFilesState}
                            containerDetailsWindowComponentData={{"fields":updatedComponentData}}
                            currentDirectory={"agreement"}
                            destinations={destinations}
                            editingChanged={undefined}
                            fileMetadataChanged={fileMetadataChanged}
                            jobs={[]}
                            matchNow={undefined}
                            matchNowSubmit={undefined}
                            openDirectory={undefined}
                            pageContentIsActive={true}
                            distributions={distributions}
                            droppedFiles={droppedFiles}
                            isDropZone={isDropZone}
                            selectedFileSystem={selectedFileSystem}
                            setInDropZone={setInDropZone}
                            setProgressUploadFileProccess={setProgressUploadFileProccess}
                            sortColumn={sortColumn}
                            storagePathItemData={storagePathItemData}
                            storagePaths={attachedFiles}
                            refreshDirectory={refreshDirectory}
                            saveChanges={undefined}
                            saveState={undefined}
                            isRepertoireModule={true}
                            toggleSidebarCollapsed={undefined}
                            validationMessageChanged={undefined}
                            addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                            entityID={agreements.agreementID}
                            entitySource={agreements.dataSource}
                            uploadAttachment={uploadAgreementAttachment}
                            removeAttachmentIfExists={removeAgreementAttachmentIfExists}
                            addDroppedFileRepertoire={addDroppedFileRepertoire}
                            attachmentsDropped={agreementAttachmentsDropped}
                            cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                            setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                            component={AGREEMENTS_KEY}
                            removeAttachmentMaintenanceScreen={removeAttachmentFromMaintenanceScreen}
                            displayRemove={true}
                            paginationDetails={paginationDetails}
                            updatePagination={updatePagination}
                            lookupValues={lookupValues}
                            tabs={tabs}
                            activeTab={activeTab}
                            isProductAttachmentTypeNotRequired={true}
                            isProductAttachmentTypeOptional={false}
                            isWorkAttachmentTypeNotRequired={true}
                            isWorkAttachmentTypeOptional={false}
                        />
                    </div>
                },
                {
                    title: draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsSubmission').data,//'Submission',
                    content:
                        <div className="GridsView">
                            <div className="AccordionContainer">
                                <DraftAgreementsMaintenanceSubmissionPage
                                    agreements={agreements}
                                    draftAgreementMaintenanceGeneralDataViewData={draftAgreementMaintenanceGeneralDataViewData}
                                    lookupValues={lookupValues}
                                    attachedFiles={attachedFiles}
                                    dataSource={dataSource}
                                    
                                    />
                            </div>
                        </div>
                }
            ];
        if(dataSource===DataSource.Repertoire){
            steps=steps.filter(x=>x.title==='Submission')
        }
        let zeroedStepsCurrent = stepsCurrent ?? 0;
        return (
            <div className="">
                <div><span className="title">{portalType === MEMBERS_PORTAL ? ipNameNumber : ipBaseNumber}</span></div>
                <If condition={tabs[activeTab].versionNumber != null}>
                    <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                </If>

                <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                    <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                </button>

                <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={toolbarParentRef}>
                    <DraftAgreementsMaintenanceToolbar
                        changesMade={changesMade}
                        showYesNoPrompt={showYesNoPrompt}
                        showModal={showModal}
                        hideModal={hideModal}
                        resetMessageBanner={resetMessageBanner}
                        scroll={updatedScroll}
                        toolbarWidth={null}
                        toolbarData={draftAgreementMaintenanceToolbarData}
                        saveResultData={draftAgreementMaintenanceSaveResultData}
                        agreement={agreements}
                        activeTab={activeTab}
                        tabs={tabs}
                        dataSource={dataSource}
                        isReadonly={isReadonly}
                        undoDraftWorksChanges={undoChanges}
                        addNewAgreement={() => SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, AGREEMENT_MAINTENANCE_GROUP)
                                                .then(formats => {
                                                    addNewDraftAgreement(lookupValues, currentUserNameNumber, true, draftAgreementMaintenanceGeneralDataViewData, formats)
                        })}
                        isNew={tabs[activeTab].draftWorkMaintenanceState?.isNew}
                        lookupValues={lookupValues}
                        currentUser={currentUser}
                    />
                </div>

                <DraftAgreementsMaintenanceForm
                    steps={type === '1' ? steps.filter(x => x.title !== 'Applicable Works') : steps}
                    readonly={false}
                    saveChanges={saveAsDraftAgreement}
                    confirmAndSubmit={saveAndPostAgreement}
                    dataSource={dataSource}
                    current={zeroedStepsCurrent}
                    setCurrent={stepsSetCurrent}
                    tabs={tabs}
                    activeTab={activeTab}
                    resetMessageBanner={resetMessageBanner}
                    currentUserNumber={currentUserNameNumber}
                />
            </div>
        );
    }
    else
        return <div>Loading...</div>
}
export default DraftAgreementsMaintenancePage;