import * as React from "react";
import {
    AD_TYPE_LOOKUP,
    AV_CATEGORY_LOOKUP,
    AV_VERSION_CATEGORY_LOOKUP,
    AV_WORK_DESTINATION_LOOKUP,
    CUE_SHEET_TYPE_LOOKUP,
    CUE_TYPE_LOOKUP,
    TERRITORY_LOOKUP,
    PRODUCTION_TYPE_LOOKUP,
    AGENCY_LOOKUP
} from "../../../lookup/Consts";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { updateProductField } from "../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import SizedDateDataInput from "../../components/dateDataInput/DateDataInput";
import { SizedDropdownDataInput } from "../../components/dropdownDataInput/DropdownDataInput";
import { SizedTextDataInput } from "../../components/textDataInput/TextDataInput";
import FormattedTimeDataInput from "../../components/timeFieldDataInput/FormattedTimeDataInput";
import {
    AVDETAILS_AIR_DATE_STATE_KEY,
    AVDETAILS_AIRED_UNTIL_STATE_KEY,
    AVDETAILS_CATEGORY_STATE_KEY,
    AVDETAILS_VERSION_CATEGORY_STATE_KEY,
    AVDETAILS_COMMERCIAL_AGENCY_STATE_KEY,
    AVDETAILS_COMMERCIAL_FORM_STATE_KEY,
    AVDETAILS_COMMERCIAL_PRODUCT_STATE_KEY,
    AVDETAILS_COMMERCIAL_TYPE_STATE_KEY,
    AVDETAILS_COMMERICIAL_BRAND_STATE_KEY,
    AVDETAILS_COUNTRY_OF_ORIGIN_STATE_KEY,
    AVDETAILS_VERSION_TERRITORY_STATE_KEY,
    AVDETAILS_CUE_SHEET_TYPE_STATE_KEY,
    AVDETAILS_DATE_OF_REVISION_STATE_KEY,
    AVDETAILS_DESTINATION_STATE_KEY,
    AVDETAILS_EPISODE_NUMER_STATE_KEY,
    AVDETAILS_MUSIC_DURATION_STATE_KEY,
    AVDETAILS_PRODUCTION_NUMBER_STATE_KEY,
    AVDETAILS_SOCIETY_SERIES_NUMBER_KEY,
    AVDETAILS_SERIES_IDENTIFIER_STATE_KEY,
    AVDETAILS_TOTAL_DURATION_STATE_KEY,
    AVDETAILS_TYPE_STATE_KEY,
    AVDETAILS_V_ISAN_STATE_KEY,
    AVDETAILS_YEAR_OF_PRODUCTION_STATE_KEY,
    PRODUCT_AV_WORKS_KEY,
    AVDETAILS_PRODUCTION_STATE_KEY,
    CUE_SHEET_TYPE,
    AVDETAILS_SUBMITTER_PERCENT_STATE_KEY,
    AVDETAILS_SOCIETY_OF_ORIGINAL_MUSIC_STATE_KEY,
    AVDETAILS_DATE_OF_SUBMISSION_STATE_KEY
} from "../../Consts";
import { IProductAVWork } from "../../types/IProductAVWork";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { IProductState } from "../../../redux/types/IProductState";

export interface IAVWorkDetailsAccordianProps {
    avWorkDetailsAccordianViewData?: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    isReadonly: boolean;
    productAVWork: IProductAVWork;
    updateProductField: typeof updateProductField;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    productType: string;
    getAvWorkType: (productType: string) => string;
    product?:IProductState;
}

export interface IAVWorkDetailsAccordianState {
    loaded: boolean;
}

export default class AVWorkDetailsAccordian extends React.Component<IAVWorkDetailsAccordianProps, IAVWorkDetailsAccordianState>{
    viewData;
    constructor(props: IAVWorkDetailsAccordianProps) {
        super(props);

        this.state = { loaded: false };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            avWorkDetailsAccordianViewData: { fields },
            getAvWorkType,
            productType
        } = this.props;

        if (fields) {
            let visibleFields = fields.filter(field => !field.hidden && field.componentInstance == PRODUCT_AV_WORKS_KEY + getAvWorkType(productType));

            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    getAVWorkDetails(): IProductAVWork {
        const { productAVWork } = this.props;
        if (productAVWork) {
            return productAVWork;
        }
        else {
            return {
                totalDuration: 0,
                musicDuration: 0
            } as IProductAVWork;
        }
    }


    render() {
        if (!this.state.loaded)
            return null;

        const { lookupValues, isReadonly, tabs, activeTab } = this.props;
        const avWorkDetails = this.getAVWorkDetails();

        const {
            airDate,
            airedUntil,
            versionCategory,
            category,
            codeOfRevision,
            countryOfOrigin,
            versionTerritory,
            cueSheetType,
            dateOfRevision,
            dateOfSubmission,
            destination,
            episodeNumber,
            musicDuration,
            productAVWorkID,
            productionNumber,
            societySeriesNumber,
            seriesIdentifier,
            totalDuration,
            type,
            vIsan,
            yearOfProduction,
            commercialBrand,
            commercialProduct,
            commercialAgency,
            commercialType,
            commercialForm,
            production,
            submitterInterestPercent,
            societyOfOriginalMusic
        } = avWorkDetails;
        const { product } = this.props;

        const productionLookupValues = getLookupValues(PRODUCTION_TYPE_LOOKUP, lookupValues);
        const productProductionLookup = productionLookupValues.find(lookup => lookup.typeID === product.production);
        let productProductionCode = ''
        if (productProductionLookup) {
            productProductionCode = productProductionLookup.code;
        }

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            avWorkDetails[name] = value;
            this.props.updateProductField(avWorkDetails, PRODUCT_AV_WORKS_KEY);
        }

        return (
            <div className="row">
                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_COUNTRY_OF_ORIGIN_STATE_KEY]}
                    fieldName={AVDETAILS_COUNTRY_OF_ORIGIN_STATE_KEY}
                    value={countryOfOrigin}
                    changeData={changeData}
                    options={getLookupValues(TERRITORY_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_COUNTRY_OF_ORIGIN_STATE_KEY]}
                />
                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_VERSION_TERRITORY_STATE_KEY]}
                    fieldName={AVDETAILS_VERSION_TERRITORY_STATE_KEY}
                    value={versionTerritory}
                    changeData={changeData}
                    options={getLookupValues(TERRITORY_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_VERSION_TERRITORY_STATE_KEY]}
                />
                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_TYPE_STATE_KEY]}
                    fieldName={AVDETAILS_TYPE_STATE_KEY}
                    value={type}
                    changeData={changeData}
                    options={getLookupValues(CUE_TYPE_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_TYPE_STATE_KEY]}
                />
  
                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_VERSION_CATEGORY_STATE_KEY]}
                    fieldName={AVDETAILS_VERSION_CATEGORY_STATE_KEY}
                    value={versionCategory}
                    changeData={changeData}
                    options={getLookupValues(AV_VERSION_CATEGORY_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_VERSION_CATEGORY_STATE_KEY]}
                /> 

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_SUBMITTER_PERCENT_STATE_KEY]}
                    fieldName={AVDETAILS_SUBMITTER_PERCENT_STATE_KEY}
                    value={submitterInterestPercent}
                    changeData={changeData}
                    readOnly={true}
                    isHidden={!this.viewData[AVDETAILS_SUBMITTER_PERCENT_STATE_KEY]}
                    fieldType="integer"
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_SERIES_IDENTIFIER_STATE_KEY]}
                    fieldName={AVDETAILS_SERIES_IDENTIFIER_STATE_KEY}
                    value={seriesIdentifier}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_SERIES_IDENTIFIER_STATE_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_PRODUCTION_NUMBER_STATE_KEY]}
                    fieldName={AVDETAILS_PRODUCTION_NUMBER_STATE_KEY}
                    value={productionNumber}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_PRODUCTION_NUMBER_STATE_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_SOCIETY_SERIES_NUMBER_KEY]}
                    fieldName={AVDETAILS_SOCIETY_SERIES_NUMBER_KEY}
                    value={societySeriesNumber}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_SOCIETY_SERIES_NUMBER_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_EPISODE_NUMER_STATE_KEY]}
                    fieldName={AVDETAILS_EPISODE_NUMER_STATE_KEY}
                    value={episodeNumber}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_EPISODE_NUMER_STATE_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_YEAR_OF_PRODUCTION_STATE_KEY]}
                    fieldName={AVDETAILS_YEAR_OF_PRODUCTION_STATE_KEY}
                    value={yearOfProduction}
                    changeData={changeData}
                    readOnly={isReadonly}
                    fieldType='integer'
                    isHidden={!this.viewData[AVDETAILS_YEAR_OF_PRODUCTION_STATE_KEY]}
                />

                <FormattedTimeDataInput
                    fieldName={AVDETAILS_TOTAL_DURATION_STATE_KEY}
                    label={this.viewData[AVDETAILS_TOTAL_DURATION_STATE_KEY]}
                    isHidden={!this.viewData[AVDETAILS_TOTAL_DURATION_STATE_KEY]}
                    value={totalDuration}
                    changeData={changeData}
                    readOnly={isReadonly}
                    tabs={tabs}
                    activeTab={activeTab}
                />

                <FormattedTimeDataInput
                    fieldName={AVDETAILS_MUSIC_DURATION_STATE_KEY}
                    label={this.viewData[AVDETAILS_MUSIC_DURATION_STATE_KEY]}
                    isHidden={!this.viewData[AVDETAILS_MUSIC_DURATION_STATE_KEY]}
                    value={product.productAVWork? product.productAVWork.musicDuration : product.duration}
                    changeData={changeData}
                    readOnly={false}
                    tabs={tabs}
                    activeTab={activeTab}
                />

                <SizedDateDataInput
                    label={this.viewData[AVDETAILS_AIRED_UNTIL_STATE_KEY]}
                    fieldName={AVDETAILS_AIRED_UNTIL_STATE_KEY}
                    value={airedUntil ? airedUntil.toString() : null}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_AIRED_UNTIL_STATE_KEY]}
                />

                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_CATEGORY_STATE_KEY]}
                    fieldName={AVDETAILS_CATEGORY_STATE_KEY}
                    value={category}
                    changeData={changeData}
                    options={getLookupValues(AV_CATEGORY_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_CATEGORY_STATE_KEY]}
                />

                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_CUE_SHEET_TYPE_STATE_KEY]}
                    fieldName={AVDETAILS_CUE_SHEET_TYPE_STATE_KEY}
                    value={cueSheetType}
                    changeData={changeData}
                    options={getLookupValues(CUE_SHEET_TYPE_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_CUE_SHEET_TYPE_STATE_KEY]}
                />

                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_DESTINATION_STATE_KEY]}
                    fieldName={AVDETAILS_DESTINATION_STATE_KEY}
                    value={destination}
                    changeData={changeData}
                    options={getLookupValues(AV_WORK_DESTINATION_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_DESTINATION_STATE_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_V_ISAN_STATE_KEY]}
                    fieldName={AVDETAILS_V_ISAN_STATE_KEY}
                    value={vIsan}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_V_ISAN_STATE_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_COMMERICIAL_BRAND_STATE_KEY]}
                    fieldName={AVDETAILS_COMMERICIAL_BRAND_STATE_KEY}
                    value={commercialBrand}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_COMMERICIAL_BRAND_STATE_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_COMMERCIAL_PRODUCT_STATE_KEY]}
                    fieldName={AVDETAILS_COMMERCIAL_PRODUCT_STATE_KEY}
                    value={commercialProduct}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_COMMERCIAL_PRODUCT_STATE_KEY]}
                />

                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_COMMERCIAL_TYPE_STATE_KEY]}
                    fieldName={AVDETAILS_COMMERCIAL_TYPE_STATE_KEY}
                    value={commercialType}
                    changeData={changeData}
                    options={getLookupValues(AD_TYPE_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_COMMERCIAL_TYPE_STATE_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_COMMERCIAL_AGENCY_STATE_KEY]}
                    fieldName={AVDETAILS_COMMERCIAL_AGENCY_STATE_KEY}
                    value={commercialAgency}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_COMMERCIAL_AGENCY_STATE_KEY]}
                />

                <SizedTextDataInput
                    label={this.viewData[AVDETAILS_COMMERCIAL_FORM_STATE_KEY]}
                    fieldName={AVDETAILS_COMMERCIAL_FORM_STATE_KEY}
                    value={commercialForm}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_COMMERCIAL_FORM_STATE_KEY]}
                />

                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_PRODUCTION_STATE_KEY]}
                    fieldName={AVDETAILS_PRODUCTION_STATE_KEY}
                    value={production ?? productProductionCode}
                    changeData={changeData}
                    options={getLookupValues(PRODUCTION_TYPE_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_PRODUCTION_STATE_KEY]}
                />

                <SizedDropdownDataInput
                    label={this.viewData[AVDETAILS_SOCIETY_OF_ORIGINAL_MUSIC_STATE_KEY]}
                    fieldName={AVDETAILS_SOCIETY_OF_ORIGINAL_MUSIC_STATE_KEY}
                    value={societyOfOriginalMusic}
                    changeData={changeData}
                    options={getLookupValues(AGENCY_LOOKUP, lookupValues)}
                    allowNull={true}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_SOCIETY_OF_ORIGINAL_MUSIC_STATE_KEY]}
                />

                <SizedDateDataInput
                    label={this.viewData[AVDETAILS_AIR_DATE_STATE_KEY]}
                    fieldName={AVDETAILS_AIR_DATE_STATE_KEY}
                    value={airDate ? airDate.toString() : null}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_AIR_DATE_STATE_KEY]}
                />

                <SizedDateDataInput
                    label={this.viewData[AVDETAILS_DATE_OF_REVISION_STATE_KEY]}
                    fieldName={AVDETAILS_DATE_OF_REVISION_STATE_KEY}
                    value={dateOfRevision ? dateOfRevision.toString() : null}
                    changeData={changeData}
                    readOnly={true}
                    isHidden={!this.viewData[AVDETAILS_DATE_OF_REVISION_STATE_KEY]}
                />

                <SizedDateDataInput
                    label={this.viewData[AVDETAILS_DATE_OF_SUBMISSION_STATE_KEY]}
                    fieldName={AVDETAILS_DATE_OF_SUBMISSION_STATE_KEY}
                    value={dateOfSubmission}
                    changeData={changeData}
                    readOnly={isReadonly}
                    isHidden={!this.viewData[AVDETAILS_DATE_OF_SUBMISSION_STATE_KEY]}
                />
            </div>
        );
    }
}