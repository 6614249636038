import { connect } from "react-redux";
import { Dispatch } from "redux";
import { mp_addTab } from "../../../../redux/reducers/MembersPortalReducer";
import IAppState from "../../../../redux/types/IAppState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { MemberStatementDetailsPage } from "./MemberStatementDetailsPage";

export default connect(
    (state: IAppState) => ({
        paymentDetailPageComponentData: state.membersPortal.componentData.MemberStatementDetailsPage,
        paymentDetailGridsComponentData: state.membersPortal.componentData.MemberStatementDetailsGridsView,
        workDetailGridsComponentData: state.membersPortal.componentData.MemberStatementWorkDetailsGridsView,
        usageDetailsPageComponentData: state.membersPortal.componentData.MemberStatementUsageDetailsPage,
        usageDetailsGridsComponentData: state.membersPortal.componentData.MemberStatementUsageDetailsGridsView,
        payment: state.membersPortal.tabs[state.membersPortal.activeTab].memberStatementDetailsState && state.membersPortal.tabs[state.membersPortal.activeTab].memberStatementDetailsState.payment,

    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
    })
)(MemberStatementDetailsPage);
