import { connect } from "react-redux";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { 
    addFileToUpload,
    CleanUploadFilesState,
    setInDropZone,
    setProgressUploadFileProccess,
    sortColumn,
    updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showMessage, showModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import {
    mp_addTab,
    createNewDraftWork,
    incrementTempID,
    resetMessageBanner,
    mp_setChangesMade,
    setWarningMessage,
    updateDraftWorkField,
    addRepertoireAttachmentSuccess,
    setProgressUploadFileRepertoireProccess,
    addDroppedFile,
    cleanDroppedFilesRepertoireState,
    registerStepsSetCurrent,
    copyExistingDraftWork
} from "../../../../redux/reducers/MembersPortalReducer";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import {
    saveDraftWorkThunk, deleteDraftWorkThunk, undoDraftWorkChangesThunk, getDraftWorkDetailsThunk, removeWorkAttachmentIfExistsThunk
} from "../../../../redux/thunks/MembersPortalThunks";
import IAppState from "../../../../redux/types/IAppState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IPopUpInfoProps } from "../../../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import { IYesNoPromptViewModalProps } from "../../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { FormatFields } from "../../../../redux/types/FormatFields";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { refreshWorkDetailsThunk, updateUserPreferencesThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IWorkState, IWorkStateKeys } from "../../../../redux/types/IWorkState";
import { getDataIngestionComponentDataThunk, getFilesystemsThunk, refreshDirectoryThunk, selectedNodeChangedThunk } from "../../../../redux/thunks/DataIngestionThunks";
import IFile from "../../../../redux/types/IFiles";
import { ITreeData } from "../../../../repertoire/types/ITreeData";
import { ITreeNodeData } from "../../../../treeview/types/TreeNodeData";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { DataSource } from "../../../../repertoire/types/DataSource";
import RegisteredWorksViewPage from "./RegisteredWorksViewPage";

export default connect(
    (state: IAppState) => ({
        draftWorkMaintenanceGeneralDataViewData: state.membersPortal.componentData.DraftWorkMaintenanceGeneralDataView,
        draftWorkMaintenanceToolbarData:
            state.membersPortal.componentData.DraftWorkMaintenanceToolbar,
        draftWorkMaintenanceSaveResultData: state.membersPortal.componentData.SaveResult,
        dataGridTableData: state.membersPortal.componentData.DataGridTable,
        draftWorkMaintenanceGridsViewData: state.membersPortal.componentData.DraftWorkMaintenanceGridsViewData,
        work: (state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState.work) ||
            (state.membersPortal.tabs[state.membersPortal.activeTab].registeredWorkMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].registeredWorkMaintenanceState.work),
        activeTab: state.membersPortal.activeTab,
        tabs: state.membersPortal.tabs,
        lookupValues: state.lookupEntities,
        dataSource: state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState!.work && state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState!.work!.dataSource,
        filesystems: state.dataIngestion.filesystems,
        treeNodes: state.dataIngestion.treeNodes,
        selectedNode: state.dataIngestion.selectedNode,
        attachedFiles: state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState!.work && state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState!.work.files,
        workAttachmentsDropped: state.membersPortal.tabs && state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].droppedFiles,
        storagePaths: state.dataIngestion.storagePaths,
        selectedFileSystem: state.dataIngestion.selectedFileSystem,
        destinations: state.dataIngestion.destinations && state.dataIngestion.destinations.lookups,
        droppedFiles: state.dataIngestion.droppedFiles,
        isDropZone: state.dataIngestion.droppedFiles.inDropZone,
        storagePathItemData: state.dataIngestion.componentData.StoragePathItem,
        distributions: state.dataIngestion.distributions,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        formats: state.dataIngestion.formats,
        paginationDetails: state.dataIngestion.paginationDetails,
        stepsCurrent: state.membersPortal.tabs[state.membersPortal.activeTab].draftWorkMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].registerStepsCurrent,
        currentUser: state.account.currentUser,
        portalType: state.account.portalType
    }),
    dispatch => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        hideModal: () => dispatch(hideModal()),
        updateDraftWorkField: (value: any, fieldName: IWorkStateKeys, index?: number) =>
            dispatch(updateDraftWorkField(value, fieldName, index)),
        saveChanges: (draftWorks: IWorkState, draftWorkMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, lookupValues: ILookupDictionary) =>
            dispatch<any>(saveDraftWorkThunk(draftWorks, draftWorkMaintenanceGeneralDataViewData, lookupValues)),
        addNewDraftWork: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftWorksMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch(createNewDraftWork(lookups, currentUserNameNumber, isNew, draftWorksMaintenanceGeneralDataViewData, formats)),
        deleteDraftWork: (dataSource: string, workId: number, activeTab: number) =>
            dispatch<any>(deleteDraftWorkThunk(dataSource, workId, activeTab)),
        showModal: (modalContent: string, modalComponentInstance: string, modalProps: any, displayModalCloseButton: boolean, title: string, modalMessage?: string, isReadOnly?: boolean) =>
            dispatch<any>(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title, modalMessage, isReadOnly)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        copyDraftWork: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => dispatch<any>(copyExistingDraftWork(actionList, userName, lookups)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        showMessage: (props: IPopUpInfoProps) => dispatch(showMessage(props)),
        undoDraftWorkChanges: (dataSource: string, workId: number, lookups: ILookupDictionary) => dispatch<any>(undoDraftWorkChangesThunk(dataSource, workId, lookups)),
        setChangesMade: (tabIndex: number, changesMade: boolean) => dispatch(mp_setChangesMade(tabIndex, changesMade)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        setWarningMessage: (displayWarning: boolean) => dispatch(setWarningMessage(displayWarning)),
        undoDraftWorks: (dataSource: string, workId: number, lookupValues: ILookupDictionary) =>
            dispatch<any>(undoDraftWorkChangesThunk(dataSource, workId, lookupValues)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        getDraftWorkDetails: (dataSource: string, workID: number, lookupValues: ILookupDictionary, draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem) =>
            dispatch<any>(getDraftWorkDetailsThunk(dataSource, workID, lookupValues, draftWorkMaintenanceDataViewData)),
        setInDropZone: (inDropZone: boolean) => dispatch(setInDropZone(inDropZone)),
        refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(refreshDirectoryThunk(filesystem, sources)),
        addFileToUpload: (file: IFile, isComplete: boolean) => dispatch<any>(addFileToUpload(file, isComplete)),
        setProgressUploadFileProccess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        cleanUploadFilesState: () => dispatch(CleanUploadFilesState()),
        refreshWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], activeTabItem: ITabReduxItem, readonlyIndicatorsWorkFlagTypes: string[], formats: FormatFields[]) =>
            dispatch<any>(refreshWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, activeTabItem, formats, readonlyIndicatorsWorkFlagTypes)),
        getFilesystems: (isRepertoireModule?: boolean) => dispatch<any>(getFilesystemsThunk(isRepertoireModule)),
        selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => dispatch<any>(selectedNodeChangedThunk(node, sources)),
        getDataIngestionComponentData: () => dispatch<any>(getDataIngestionComponentDataThunk()),
        addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => dispatch<any>(addRepertoireAttachmentSuccess(attachedFile)),
        removeWorkAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => dispatch<any>(removeWorkAttachmentIfExistsThunk(attachedFile, source)),
        addDroppedFileRepertoire: (file: IFile, inProgress: boolean) => dispatch(addDroppedFile(file, inProgress)),
        setProgressUploadFileRepertoire: (message: string, file: IFile, isUploadComplete: boolean) => dispatch(setProgressUploadFileRepertoireProccess(message, file, isUploadComplete)),
        cleanDroppedFilesRepertoire: () => dispatch(cleanDroppedFilesRepertoireState()),
        updatePagination: (resultsPerPage: number, currentPage: number, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),
        stepsSetCurrent: (current: number) => dispatch(registerStepsSetCurrent(current))
    })
)(RegisteredWorksViewPage);
