import * as React from "react";
import { hideModal } from "../../../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { VERSION_HISTORY_VIEW, DISTRIBUTION_VERSION_HISTORY_VIEW } from "../../../Consts";
import { IVersionHistorySearchResult } from "../../../types/IVersionHistorySearchResult";
import SelectionTable from "../../selectionTable/SelectionTable";
export interface IVersionHistoryViewModalProps {
    versionHistorySearchResult: any[];
    openCallback: (entityId: any, versionNumber: number, mergedWork: boolean) => void;
}

export interface IVersionHistoryViewProps {
    searchResultsTableData: IRepertoireComponentDataItem;
    props: IVersionHistoryViewModalProps;
    hideModal: typeof hideModal;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    componentInstance?: string;
}

const VersionHistoryView: React.SFC<IVersionHistoryViewProps> = ({ searchResultsTableData, hideModal, updatePagination, props, componentInstance }) => {
    const { openCallback, versionHistorySearchResult } = props;
    return (
        <div className="col-md-12 searchResultsDiv">
            <SelectionTable
                key={1}
                searchResultsTableData={searchResultsTableData}
                tableContents={versionHistorySearchResult}
                componentInstance={componentInstance}
                openHistoryVersion={openCallback}
                hideModal={hideModal}
                updatePagination={updatePagination}
            />
        </div>
    );
};

export default VersionHistoryView;