import { useMsal } from "@azure/msal-react";
import React, { useState } from 'react';
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import SearchView from "../../repertoire/components/searchView/SearchView";
import { SEARCH_VIEW_REQUEST_ACCESS, ALL_ROLES } from "../Consts";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../types/IContributorSearchResult";
import { IContributorItem } from "../../repertoire/types/IContributorItem";
import { If } from "../../core/components/If";
import { showModal, hideModal, showYesNoPrompt, updateComment } from "../../redux/reducers/ModalReducer";
import { searchDataChanged } from "../../redux/reducers/RepertoireReducer";
import { IDraftWorkSearchResult } from "../types/IDraftWorkSearchResult";
import { IAllRegisteredWorkSearchResult } from "../types/IAllRegisteredWorkSearchResult";
import { IAllRegisteredWorkSearchQuery } from "../types/IAllRegisteredWorkSearchQuery";
import MembersPortalModal from "../components/membersPortalModal/MembersPortalModal";

export interface IRequestAccessPageProps {
    requestAccessPageData: IMembersPortalComponentDataItem;
    searchViewData: IMembersPortalComponentDataItem;
    searchResultsTableData: IMembersPortalComponentDataItem;
    searchContributors: (query: IContributorSearchQuery) => void;
    contributorsSearchResults: IContributorSearchResult[];
    getIPDetails: (ipBaseNumber?: string, accountNumber?: string, ipMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => void;
    searchSuccessful: boolean;
    interestedPartyGeneralDataView: IRepertoireComponentDataItem;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    sortSearchResults: (name: string, results: any) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    lookupValues: ILookupDictionary;
    getMembersPortalFields: () => void;
    requestViewAsAccessRequest: (requestAccess: IContributorItem, userName:string) => void;
    currentUser: string;
    requestViewAsAccessSuccess: boolean;
    requestingViewAsAccess: boolean;
    displayModal: boolean;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    updateWorkContributorIP: () => void;
    displayModalCloseButton: boolean;
    workNotFoundViewData: IMembersPortalComponentDataItem;
    loadingViewData: IMembersPortalComponentDataItem;
    yesNoPromptViewData: IMembersPortalComponentDataItem;
    dataGridTableData: IMembersPortalComponentDataItem;
    dataSource: string;
    searchDataChanged: typeof searchDataChanged;
    draftWorkSearchResults: IDraftWorkSearchResult[];
    allRegisteredWorkSearchResults: IAllRegisteredWorkSearchResult[];
    searchAllRegisteredWorks: (query: IAllRegisteredWorkSearchQuery) => void;
    saveContributorComment: (contributorID: number, comments: string) => void;
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    updateModalComment: typeof updateComment;
    modalTitle: string;
    modalMessage: string;

}

const RequestAccessPage = (props: IRequestAccessPageProps) => {
    const [loaded, setLoaded] = useState(false);
    const { instance, accounts } = useMsal();
    const [showRow, setShowRow] = useState(true);

    React.useEffect(() => {
        checkIfLoaded();
        getMembersPortalData();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])

    const checkIfLoaded = () => {
        const { requestAccessPageData } = props;

        if (requestAccessPageData.fields && requestAccessPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = requestAccessPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const requestViewAsAccess = (requestAccess: IContributorItem) => {
        const { currentUser, requestViewAsAccessRequest } = props;
        setShowRow(true);
        requestViewAsAccessRequest(requestAccess,currentUser);
    }

    const getMembersPortalData = () => {
        const { getMembersPortalFields } = props;

        getMembersPortalFields();
    }

    const handleCloseClick = () => {
        setShowRow(false);
    };

    const {
        searchViewData,
        searchResultsTableData,
        searchContributors,
        contributorsSearchResults,
        searchSuccessful,
        interestedPartyGeneralDataView,
        indexOfFirstResult,
        indexOfLastResult,
        resultsPerPage,
        currentPage,
        resetPagination,
        updatePagination,
        sortSearchResults,
        lookupValues
        
    } = props;

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const getIPDetails = (ipBaseNumber?: string, accountNumber?: string) => {
        props.getIPDetails(ipBaseNumber, accountNumber, interestedPartyGeneralDataView);
    }

    const { requestViewAsAccessSuccess, displayModal } = props

    if (loaded) {
        return (
            <div className="pageContent">
                {displayModal ? (<MembersPortalModal {...props} />) : null}

                <div className="container-fluid pageContent">
                    <div className="row">
                        <div className="form-group col-md-12 TabsView">
                            <div>
                                {/* Save success */}
                                <If condition={requestViewAsAccessSuccess == true && showRow}>
                                    <div className="row">
                                        <div className="col-md-12 alert alert-success alert-dismissible fade in">
                                            Your access request has been successfully submitted. You can see the status of your account(s) and manage the view of the portal from the "View As" page.
                                            Note that in order to edit the "View As" and "Default" fields your request needs to be approved beforehand. Requests are typically approved in 24 hrs.
                                            <a className="close" aria-label="close" onClick={handleCloseClick}>&times;</a>
                                        </div>

                                    </div>
                                </If>

                                {/* Save failure */}
                                <If condition={requestViewAsAccessSuccess == false && showRow} >
                                    <div className="row">
                                        <div className="col-md-12 alert alert-danger alert-dismissible fade in">
                                            The request has not been submitted. You may have already requested access for this account. Please check the "View As" page to see the accounts you have access to.
                                            <a className="close" aria-label="close" onClick={handleCloseClick}>&times;</a>
                                        </div>
                                    </div>
                                </If>
                            </div>
                            <div id="tab-container" className="tab-content formBox tab-pane active fade in tab-content">
                                <div id="tab-container-pane-0" className="tab-pane active fade in" aria-labelledby="tab-container-tab-0" role="tabpanel" aria-hidden="false">
                                    <div className="requestAccessPage">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h1>Request Access to my Account(s)</h1> 
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>
                                                    Search using one or more of the fields below to find the account you want to request access for.
                                                </p>
                                            </div>
                                        </div>
                                        <SearchView
                                                searchViewData={searchViewData}
                                                searchResultsTableData={searchResultsTableData}
                                                componentInstance={SEARCH_VIEW_REQUEST_ACCESS}
                                                modalProps={undefined}
                                                dataSource={undefined}
                                                searchContributors={searchContributors }
                                                hideModal={undefined}
                                                contributorsSearchResults={contributorsSearchResults}
                                                getIPDetails={getIPDetails}
                                                searchSuccessful={searchSuccessful}
                                                indexOfFirstResult={indexOfFirstResult}
                                                indexOfLastResult={indexOfLastResult}
                                                resultsPerPage={resultsPerPage}
                                                currentPage={currentPage}
                                                resetPagination={resetPagination}
                                                lookupValues={lookupValues}
                                                updatePagination={updateUserPagination}
                                                sortSearchResults={sortSearchResults}
                                                searchDisabled={undefined}
                                                requestViewAsAccess={requestViewAsAccess}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default RequestAccessPage;