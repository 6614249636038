import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { showYesNoPrompt, hideModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SAVE_CHANGES_STATE_KEY, SAVE_METHOD_TOOLBAR, DELETE_METHOD_TOOLBAR, USAGE_POOL_MAINTENANCE_TOOLBAR, DELETE_POOL_STATE_KEY, COPY_METHOD_TOOLBAR, COPY_POOL_STATE_KEY, VERSION_HISTORY_STATE_KEY, HISTORY_METHOD_TOOLBAR, DELETE_WORKS_ROLE, ALL_ROLES, UPDATE_POOLS_ROLE } from "../../../Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { clearModalSearchResults } from "../../../../redux/reducers/RepertoireReducer";
import { IUsagePoolState } from "../../../../redux/types/IUsagePoolState";
import { ITreeData } from "../../../types/ITreeData";

export interface IUsagePoolMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData?: IRepertoireComponentDataItem;
    pool: IUsagePoolState;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    deleteUsagePool: () => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    isReadonly: boolean;
    copyUsagePool: (dataAction: IDataActionToolbar[]) => void;
    searchVersionHistory: (poolID: number, activeTabItem: ITabReduxItem, actionList: IDataActionToolbar[], sources: ITreeData[]) => void;
    sources?: ITreeData[];
    roles?: string[];
}

export type IUsagePoolMaintenanceToolbarKeys = keyof IUsagePoolMaintenanceToolbarProps;

export interface IUsagePoolMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

class UsagePoolMaintenanceToolbar extends React.Component<
    IUsagePoolMaintenanceToolbarProps,
    IUsagePoolMaintenanceToolbarState
> {

    constructor(props) {
        super(props)
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    saveChangesAction = () => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    copyPool = () => {
        const { tabs, activeTab, copyUsagePool } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        copyUsagePool(actionList);
    }

    searchVersionHistory = () => {
        const { searchVersionHistory, clearModalSearchResults, pool, tabs, activeTab, sources } = this.props;
        const actionList = tabs[activeTab].dataActionToolbar;
        clearModalSearchResults();
        searchVersionHistory(pool.poolID, tabs[activeTab], actionList, sources);
    };

    getDeleteRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(DELETE_WORKS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_POOLS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getDataAction() {
        const {
            deleteUsagePool,
            toolbarData: { fields }
        } = this.props;
        const actionList: IDataActionToolbar[] = [];

        const copyUsagePoolAction: IDataActionToolbar = {
            action: this.copyPool,
            component: USAGE_POOL_MAINTENANCE_TOOLBAR,
            method: COPY_METHOD_TOOLBAR,
            name: fields.find(d => d.name === COPY_POOL_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Copy',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(copyUsagePoolAction);

        const deleteUsagePoolAction: IDataActionToolbar = {
            action: deleteUsagePool,
            component: USAGE_POOL_MAINTENANCE_TOOLBAR,
            method: DELETE_METHOD_TOOLBAR,
            name: fields.find(d => d.name === DELETE_POOL_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Delete',
            isReadOnly: this.getDeleteRoleDisabled() || this.getUpdateRoleDisabled()
        };
        actionList.push(deleteUsagePoolAction);

        const searchVersionHistory: IDataActionToolbar = {
            action: this.searchVersionHistory,
            name: fields.find(save => save.name === VERSION_HISTORY_STATE_KEY).data,
            component: USAGE_POOL_MAINTENANCE_TOOLBAR,
            method: HISTORY_METHOD_TOOLBAR,
            icon: 'icon ms-Icon ms-Icon--History'
        };
        actionList.push(searchVersionHistory);


        const save: IDataActionToolbar = {
            action: this.saveChangesAction,
            component: USAGE_POOL_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        return actionList;
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            pool,
            activeTab,
            toolbarData,
            tabs,
            isReadonly,
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade

        let { dataAction } = { ...this.state };

        if (pool.poolID < 1 || isReadonly) {
            dataAction = dataAction.filter(action => action.method !== HISTORY_METHOD_TOOLBAR && action.method !== DELETE_METHOD_TOOLBAR)
        }
       return (
            <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    pool={pool}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={dataAction}
                    toolbarData={toolbarData}
                    component={USAGE_POOL_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].usagePoolMaintenanceState.isNew}
                    isReadonly={isReadonly}

                />
            </div>
        );
    }

}

export default UsagePoolMaintenanceToolbar;
