import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import IconTextButton from "../../../../repertoire/components/iconTextButton/IconTextButton";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { createCopyOfTableContents } from "../../../../repertoire/services/TableFunctions";
import {
    REMOVE_ACTION,
    DEPENDENT_ROW_ACTION,
    USAGE_MATCH_MAKE_DEFINITE,
    IP_LICENSING_REPRESENTATION_KEY,
    USAGE_MATCHING_WORKS_SECTION_KEY,
    USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY
} from "../../../Consts";
import DateDisplay from "../../dateDisplay/DateDisplay";


export interface IDataGridTableRowProps {
    tableContentsItem: any;
    index: number;
    isExpandable: boolean;
    dataGridTableData: IRepertoireComponentDataItem;
    componentInstance: string;
    changeData?: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    stateKey: IRepertoireStateKeys;
    tableContents: any;
    dependentRowAction?: (value: any) => void;
    clearAllRowsButThis?: (value: any) => void;
    updateIpRepresentationsIdsToRemove?: (tableContentItem: any) => number[];
    openWorkFlow?: boolean;
    onRowClick: () => void;
    focusedRowNumber: number;
    isExpandedUsageMatchingSection?:boolean;
}

interface IDataGridTableRowState {
    isExpanded: boolean;
    isExpandedUsageMatchingFlag:boolean;
}

export class DataGridTableRow extends React.PureComponent<
    IDataGridTableRowProps,
    IDataGridTableRowState
> {

    constructor(props: IDataGridTableRowProps) {
        super(props);
        this.state = {
            isExpanded: false,
            isExpandedUsageMatchingFlag:false
        };
    }
    componentDidUpdate(prevProps: IDataGridTableRowProps, prevState: IDataGridTableRowState) {
if(prevProps.isExpandedUsageMatchingSection!==this.props.isExpandedUsageMatchingSection){
    this.setState({ isExpandedUsageMatchingFlag: this.props.isExpandedUsageMatchingSection });
}
        
    }
    onExpandClicked = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
        this.setState({ isExpandedUsageMatchingFlag: !this.state.isExpandedUsageMatchingFlag });

    }

    renderLineBreak = (array: string[]) => {
        return (
            <div>
                {array.map((item, index) => (
                    <div key={index}>
                        {item}
                        {index + 1 !== array.length ? <span>,</span> : null}
                    </div>
                ))}
            </div>
        );
    }

    onClickRemove = (tableContentItem: any) => {
        const { changeData, stateKey, tableContents, updateIpRepresentationsIdsToRemove } = this.props;
        const tableContentsCopy = createCopyOfTableContents(tableContents);

        if (tableContentsCopy.indexOf(tableContentItem) > -1) {
            tableContentsCopy.splice(tableContentsCopy.indexOf(tableContentItem), 1);
        }
        let ipRepresIdsToRemove = []
        if (updateIpRepresentationsIdsToRemove) {
            ipRepresIdsToRemove = updateIpRepresentationsIdsToRemove(tableContentItem);
        }
        changeData(tableContentsCopy, stateKey, null, ipRepresIdsToRemove);
    };

    dependentRowAction(tableContentItem: any) {
        const { dependentRowAction } = this.props;

        dependentRowAction(tableContentItem);
    }

    clearAllRowsButThis(value: any) {
        this.props.clearAllRowsButThis(value);
    }

    renderRowColumns = () => {
        const {
            isExpandable,
            tableContentsItem,
            dataGridTableData,
            componentInstance,
            tableContents,
            openWorkFlow
        } = this.props;

        const {
            isExpanded,
            isExpandedUsageMatchingFlag
        } = this.state;

        const rowColumns: JSX.Element[] = [];

        if (isExpandable) {
            rowColumns.push(
                <td className="td">
                    <i
                        title="Expand row"
                        className={["icon ms-Icon ms-Icon--Chevron", (componentInstance===USAGE_MATCHING_WORKS_SECTION_KEY ? isExpandedUsageMatchingFlag : isExpanded) ? "Down" : "Right"].join("")}
                        onClick={() => this.onExpandClicked()}
                    />
                </td>
            );
        }

        let filteredFields = dataGridTableData.fields.filter(
            (field: IRepertoireField) =>
                field.componentInstance === componentInstance
        );

        filteredFields.map((field, x: number) => {
            const value = tableContentsItem[field.name];
            if (field.fieldType === REMOVE_ACTION) {
                rowColumns.push(
                    <td className="td" key={REMOVE_ACTION}>
                        <div className={["tableCell", (componentInstance===USAGE_MATCHING_WORKS_SECTION_KEY ? isExpandedUsageMatchingFlag : isExpanded) ? "rowExpanded" : "rowUnexpanded"].join(" ")}>
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--Delete"}
                                text={"Remove"}
                                onClick={() => this.onClickRemove(tableContentsItem)}
                                disabled={openWorkFlow}
                            />
                        </div>
                    </td>
                );
            }
            if (USAGE_PRODUCT_DETAILS_ROWS_SECTION_KEY === componentInstance && field.name === "") {
                rowColumns.push(
                    <td className="td" key={DEPENDENT_ROW_ACTION}>
                        <div className="tableCell">
                            <DateDisplay value={value} />
         
                        </div>
                    </td>
                );
                return;

            }
            if (field.fieldType === DEPENDENT_ROW_ACTION) {
                if (componentInstance === IP_LICENSING_REPRESENTATION_KEY) {
                    rowColumns.push(
                        <td className="td" key={DEPENDENT_ROW_ACTION}>
                            <div className="tableCell">
                                <IconTextButton
                                    icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                    text={tableContentsItem.workGroupDescription}
                                    onClick={() => this.dependentRowAction.bind(this)(tableContentsItem)}
                                    disabled={!tableContentsItem.workGroupDescription}
                                />
                            </div>
                        </td>
                    );
                }
                else {
                    rowColumns.push(
                        <td className="td" key={DEPENDENT_ROW_ACTION}>
                            <div className="tableCell">
                                <IconTextButton
                                    icon={"icon ms-Icon ms-Icon--OpenInNewTab"}
                                    text={field.data}
                                    onClick={() => this.dependentRowAction.bind(this)(tableContentsItem)}
                                    disabled={false}
                                />
                            </div>
                        </td>
                    );
                }
            }
            else if (field.name === USAGE_MATCH_MAKE_DEFINITE && tableContents.length > 1) {
                rowColumns.push(
                    <td className="td" key={"makeDefinite"}>
                        <div className={["tableCell", (componentInstance===USAGE_MATCHING_WORKS_SECTION_KEY ? isExpandedUsageMatchingFlag : isExpanded) ? "rowExpanded" : "rowUnexpanded"].join(" ")}>
                            <IconTextButton
                                icon={"icon ms-Icon ms-Icon--CircleAddition"}
                                text={"Make Definite"}
                                onClick={() => this.clearAllRowsButThis(tableContentsItem)}
                                disabled={openWorkFlow}
                            />
                        </div>
                    </td>)
            }
            else if (field.name === 'title' && USAGE_MATCHING_WORKS_SECTION_KEY === componentInstance) { 
                rowColumns.push(
                    <td key={field.name} className="td">
                        <div className={["tableCell", (componentInstance===USAGE_MATCHING_WORKS_SECTION_KEY ? isExpandedUsageMatchingFlag : isExpanded) ? "rowExpanded" : "rowUnexpanded"].join(" ")} >
                            <a                              
                                onClick={(event) => {
                                   event.preventDefault();  // Prevent the default anchor behavior
                                   this.dependentRowAction.bind(this)(tableContentsItem);
                                }}
                            >
                                {Array.isArray(value) ? this.renderLineBreak(value) : value}                               
                            </a>
                        </div>
                    </td>
                );
            }
            else {
                rowColumns.push(
                    <td key={field.name} className="td">
                        <div className={["tableCell", (componentInstance===USAGE_MATCHING_WORKS_SECTION_KEY ? isExpandedUsageMatchingFlag : isExpanded) ? "rowExpanded" : "rowUnexpanded"].join(" ")}>
                            {Array.isArray(value) ? this.renderLineBreak(value) : value}
                        </div>
                    </td>

                );
            }
        });
        return rowColumns;
    }

    render() {
        const {
            index,
            onRowClick,
            focusedRowNumber
        } = this.props;

        return (
            <tr className={`tr ${focusedRowNumber === index && 'focusedTableRow'}`} onClick={() => onRowClick()} key={index}>{this.renderRowColumns()}</tr >
        );

    }
}