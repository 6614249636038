import * as React from "react";
import { CSVLink } from "react-csv"
import { Button, Input, InputRef, PaginationProps, Row, Space, Table } from "antd";
import { Key, useRef, useState } from "react";
import { ColumnsType, ColumnType, FilterConfirmProps, FilterValue } from "antd/lib/table/interface";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { USAGEGROUPUPDATE_ACTION_STATE_KEY, USAGE_MATCHSTATUS_DEFINITE_MATCH } from "../../Consts";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IIPsSearchResult } from "../../types/IIPsSearchResult";
import { IRepertoireField } from "../../types/IRepertoireField";
import { createCopyOfTableContents } from "../../services/TableFunctions";
import { ComponentsHelper } from "../../../core/services/ComponentHelper";
import { IInterestedPartySearchResultRowType } from "../../types/IInterestedPartySearchResultRowType";
import { IUsageSearchResultRowType } from "../../types/usageTypes/IUsageGroupsSearchResult";
import { IIPsSearchQuery } from "../../types/IIPsSearchQuery";

export interface IResultsTableProps {
    searchResultsTableData: IRepertoireField[];
    tableContents: any;
    componentInstance?: string;
    selectAll?: boolean;
    isSelectedAll?: boolean;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    updatePagination?: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    repertoireSection?: string;
    sortSearchResults?: (name: string, results: any) => void;
    changeData?: (value: any, fieldName: string) => void;
    search?: (isFilter?: boolean, params?: IIPsSearchQuery, isSameSearch?: boolean) => void;
    continuationToken?: string;
    showFieldsModal?: (componentFieldName: string) => void;
    saveResultData?: IRepertoireComponentDataItem;
    resetMessageBanner?: () => void;
    isHundredResultsPerPage?: boolean;
    setIsHundredResultsPerPage?: (isHundredResultsPerPage: boolean) => void;
    onClickOpen?: (tableContentItem: IIPsSearchResult) => void;
    accountNumber?: string;
    accountName?: string;
    ipNumber?: string;
    updateContentsTable: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    searchResults?: IIPsSearchResult[];
    skip?: number;
    resetPagination: (repertoireSection: string) => void;
}

type DataIndex = keyof IIPsSearchResult;

export const ResultsTable: React.FC<IResultsTableProps> = (props: IResultsTableProps) => {

    const [selectedRows, setSelectedRows] = useState<IIPsSearchResult[]>([]);
    const [updatedUsageGroups, setUpdatedUsageGroups] = useState<IIPsSearchResult[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [showMoreRows, setShowMoreRows] = useState<number[]>([]);
    const [hasChanges, setHasChanges] = useState<number[]>([]);
    const [showMoreAll, setShowMoreAll] = useState<boolean>(true);
    const searchInput = useRef<InputRef>(null);
    const [expandedRows, setExpandedRows] = useState<Key[]>([]);
    const [allExpanded, setAllExpanded] = useState<boolean>(false);
    const [currentTableData, setCurrentTableData] = React.useState([]);
    const [queryParams, setQueryParams] = React.useState({
        ipNumber: '',
        fullName: '',
        accountNumber: '',
        interestedPartyType: ''
    });

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };
    const handleChange = (pagination, filters: globalThis.Record<string, FilterValue>, sorter) => {


        if (filters.accountNumber && props.accountNumber !== filters.accountNumber.toString()) {
            setQueryParams(
                { ...queryParams, accountNumber: filters.accountNumber.toString() }
            )
            props.search(true, queryParams);
        }
        else if (filters.accountName && props.accountName !== filters.accountName.toString()) {
            setQueryParams(
                { ...queryParams, fullName: filters.accountName.toString() }
            )
            props.search(true, queryParams);
        }
        else if (filters.ipBaseNumber && props.ipNumber !== filters.ipNumber.toString()) {
            setQueryParams(
                { ...queryParams, ipNumber: filters.ipNumber.toString() }
            )
            props.search(true, queryParams);
        }
        sortTableByColumn(sorter)
    }
    // props for the search filter pop up
    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<IIPsSearchResult> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button type="link" size="small" onClick={() => { close(); }}>
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />
        ),
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#fcfa5d', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const sortTableByColumn = (sorter: any) => {
        const { componentInstance, sortSearchResults, tableContents } = props;
        let descending = sorter.order === "ascend" ? false : true;

        const tableContentsCopy = createCopyOfTableContents(tableContents);

        tableContentsCopy.sort((a: any, b: any) => {
            // set to emtpy string if null
            let newA = sorter.field == 'sourceMajor' ? a['source'] || "" : a[sorter.field] || "";
            let newB = sorter.field == 'sourceMajor' ? b['source'] || "" : b[sorter.field] || "";

            newA = newA ? newA : "";
            newB = newB ? newB : "";

            if (!descending) {
                return isNaN(newA) ? (newA > newB ? 1 : -1) : (newA - newB);
            } else {
                return isNaN(newA) ? (newB > newA ? 1 : -1) : (newB - newA);
            }
        });

        if (sortSearchResults) {
            sortSearchResults(componentInstance, tableContentsCopy);
        }
    };
    //generate columns for the table
    const getColumns = () => {
        const { searchResultsTableData, componentInstance, tableContents } = props;
        const searchResults = tableContents;
        let removedItemAction: IRepertoireField = undefined;
        if (searchResultsTableData) {

            let orderedFields = ComponentsHelper.orderFieldsBySpecificField(searchResultsTableData, "order");
            if (!orderedFields && orderedFields.length > 0) {
                orderedFields = searchResultsTableData
            }
            if (removedItemAction) {
                orderedFields.push(removedItemAction);
            }


            let columns: ColumnsType<IIPsSearchResult> = orderedFields.map(field => {

                switch (field.name) {
                    case 'ipNameNumber':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            width: '10em',
                            key: field.name, ...getColumnSearchProps(field.name as keyof IIPsSearchResult),
                            render: (value, record) =>
                                <div className="arrowIconDiv">
                                    <img src="assets/external-black.svg" onClick={() => props.onClickOpen(record)} title="Open ip details in a new tab" alt="External icon" className="openInNewTab"></img>{value}
                                </div>
                        }
                    case 'ipBaseNumber':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name, ...getColumnSearchProps(field.name as keyof IIPsSearchResult),
                        }
                    case 'ipName':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name, ...getColumnSearchProps(field.name as keyof IIPsSearchResult),
                        }
                    case 'ipType':
                        const interestedPartyTypeFilter = searchResults.map(d => ({ text: d.ipType, value: d.ipType }));
                        const interestedPartyTypeData = interestedPartyTypeFilter.filter((v, i, a) => a.findIndex(v2 => (v2.text === v.text)) === i)
                        let interestedPartyTypeSet = interestedPartyTypeData.filter((element, index) => {
                            return interestedPartyTypeData.indexOf(element) === index;
                        });
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name,
                            filters: [...interestedPartyTypeSet],
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.ipType.includes(value.toString());
                            },
                            sorter: (a, b) => a.ipType != undefined ? a.ipType.localeCompare(b.ipType) : null
                        }
                    case 'accountName':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name, ...getColumnSearchProps(field.name as keyof IIPsSearchResult),

                        }
                    case 'accountNumber':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name, ...getColumnSearchProps(field.name as keyof IIPsSearchResult),
                        }
                    case 'societyAffiliation':
                        const societyAffiliationFilter = searchResults.map(d => ({ text: d.societyAffiliation, value: d.societyAffiliation }));
                        const societyAffiliationData = societyAffiliationFilter.filter((v, i, a) => a.findIndex(v2 => (v2.text === v.text)) === i);
                        let societyAffiliationSet = societyAffiliationData.filter((element, index) => {
                            return societyAffiliationData.indexOf(element) === index;
                        });
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name,
                            filters: [...societyAffiliationSet],
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.societyAffiliation.includes(value.toString());
                            },
                            sorter: (a, b) => a.societyAffiliation != undefined ? a.societyAffiliation.localeCompare(b.societyAffiliation) : null
                        }
                    case 'legalEntityType':
                        const legalEntityTypeFilter = searchResults.map(d => ({ text: d.legalEntityType, value: d.legalEntityType }));
                        const legalEntityTypeData = legalEntityTypeFilter.filter((v, i, a) => a.findIndex(v2 => (v2.text === v.text)) === i);
                        let legalEntityTypeSet = legalEntityTypeData.filter((element, index) => {
                            return legalEntityTypeData.indexOf(element) === index;
                        });
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name,
                            filters: [...legalEntityTypeSet],
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.legalEntityType.includes(value.toString());
                            },
                            sorter: (a, b) => a.legalEntityType != undefined ? a.legalEntityType.localeCompare(b.legalEntityType) : null
                        }
                    case 'birthDate':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name
                        }
                    case 'isLibraryPublisher':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name
                        }
                    default:
                        return { title: field.data, dataIndex: field.name, key: field.name, sorter: (a, b) => (isNaN(a[field.name]) && a[field.name] != undefined) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name] }
                }
            })
            return columns;
        }
    }
    const columns = getColumns();
    const { tableContents, skip } = props;
    const data: IIPsSearchResult[] = tableContents.map(row => ({
        key: row.id,
        accountNumber: row.accountNumber,
        firstName: row.firstName,
        lastName: row.lastName,
        ipName: row.ipName,
        ipType: row.ipType,
        ipNameNumber: row.ipNameNumber,
        ipBaseNumber: row.ipBaseNumber,
        societyAffiliation: row.societyAffiliation,
        legalEntityType: row.legalEntityType,
        accountName: row.accountName,
        birthDate: row.birthDate,
        isLibraryPublisher: row.isLibraryPublisher
    }));
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IIPsSearchResult[]) => {
            setSelectedRows(selectedRows);
        }
    };

    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
        if (type === 'prev') {
            return <div className="pageNumberButton">Previous</div>;
        }
        if (type === 'next') {
            return <div className="pageNumberButton">Next</div>;
        }
        return originalElement;
    };

    return (
        <div className="tableContainer">
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    defaultPageSize: props.resultsPerPage,
                    position: ['bottomRight', 'topRight'],
                    itemRender: itemRender,
                    onChange(page, pageSize) {

                        let isLastPage = page * pageSize === data.length ? true : false;
                        isLastPage = Math.ceil(data.length / pageSize) == page ? true : false;

                        const indexOfLastResult = page * pageSize;
                        const indexOfFirstResult = indexOfLastResult - props.resultsPerPage;
                        if (isLastPage) {
                            const obj = {
                                ipNumber: '',
                                fullName: '',
                                accountNumber: '',
                                interestedPartyType: '',
                                skip: skip
                            };
                            props.search(true, obj, true)
                        }
                        props.updateContentsTable(0, indexOfLastResult, pageSize, page, 'ips')

                    },
                    total: props.searchResults.length,
                    className: 'pageNumberDiv',
                    showSizeChanger: true,
                    pageSizeOptions: [10, 20, 50, 100],
                    defaultCurrent: props.currentPage,
                }}
                rowSelection={{ type: 'checkbox', ...rowSelection }}
                size={'middle'}
                onChange={handleChange}
                className="parent-table"
                tableLayout={"fixed"}
            />
        </div>
    );
}