import { ISourceIP } from "../../../types/ISourceIP";
import { IWorkSourceIP } from "../../../types/IWorkSourceIP";
import { formatValues } from "../workMaintenanceGeneralDataView/WorkMaintenanceGeneralDataViewFormik";

export const mapSourceIPToWorkSourceIP = (sourceIP: ISourceIP) => {
    if (sourceIP && sourceIP.fullName) {
        const workSource = {
            workSourceIPID: sourceIP.sourceIPID,
            ipiNameNumber: sourceIP.ipiNameNumber,
            fullName: sourceIP.fullName,
        };

        return workSource;
    }
    else {
        return null;
    }
}

export const updateWorkWithGeneralWorkData = (updatedWork, oldWork) => {

    const newUpdatedWork = formatValues(updatedWork);

    let sourceIPValue = newUpdatedWork.sourceIPValue; 
    let workSourceIPValue =  newUpdatedWork.workSourceIP;
    let sourceSocietyValue = newUpdatedWork.sourceSociety;

    if (newUpdatedWork.sourceType === "SO") {
        workSourceIPValue = null;
        sourceIPValue = null;
    }
    if (newUpdatedWork.sourceType != "SO") {
        sourceSocietyValue = null;
    }
    if (newUpdatedWork.sourceType === "") {
        sourceSocietyValue = null;
        workSourceIPValue = null;
        sourceIPValue = null;
    }

    const activeWorkTerritories = newUpdatedWork.workTerritorySpecificInfo.filter(info => info.isActive);

    const territoryId = (newTerritory) => {
        const territoryPossiblyUpdatedBySaving = oldWork.territorySpecificInfo.workTerritorySpecificInfo.find(territory => territory.setTypeGroup === newTerritory.setTypeGroup);
        return territoryPossiblyUpdatedBySaving?.workTerritorySpecificInfoID ? territoryPossiblyUpdatedBySaving?.workTerritorySpecificInfoID : 0;
    }

const newWork = {
    ...oldWork,
    compoundType: newUpdatedWork.compoundType,
    distributionCategory: newUpdatedWork.distributionCategory,
    domesticOrForeign: newUpdatedWork.domesticOrForeign,
    duration: newUpdatedWork.duration,
    genre: newUpdatedWork.genre,
    genreCategory: newUpdatedWork.genreCategory,
    isArrangement: newUpdatedWork.isArrangement,
    isCommissionedForCommercial: newUpdatedWork.isCommissionedForCommercial,
    isControlledComposition: newUpdatedWork.isControlledComposition,
    isDistributed: newUpdatedWork.isDistributed,
    isLibraryWork: newUpdatedWork.isLibraryWork,
    isMaintained: newUpdatedWork.isMaintained,
    isMaintainedLibraryWork: newUpdatedWork.isMaintainedLibraryWork,
    isMaintainedMCPSI: newUpdatedWork.isMaintainedMCPSI,
    isOriginalWork: newUpdatedWork.isOriginalWork,
    isOutOfCopyright: newUpdatedWork.isOutOfCopyright,
    lyricAdaptation: newUpdatedWork.lyricAdaptation,
    mergeThisWork: newUpdatedWork.mergeThisWork,
    musicArrangement: newUpdatedWork.musicArrangement ,
    musicSource: newUpdatedWork.musicSource,
    region: newUpdatedWork.region,
    registrationDate: newUpdatedWork.registrationDate === '' ? null : newUpdatedWork.registrationDate,
    source: newUpdatedWork.source,
    sourceIPINumber: newUpdatedWork.sourceIPINumber,
    sourceIP: sourceIPValue === '' ? null : sourceIPValue,
    sourceSociety: sourceSocietyValue,
    sourceType: newUpdatedWork.sourceType,
    stampID: newUpdatedWork.stampID,
    validForUsageFrom: newUpdatedWork.validForUsageFrom,
    validForUsageTo: newUpdatedWork.validForUsageTo,
    workBatchID: newUpdatedWork.workBatchID,
    workCreationDate: newUpdatedWork.workCreationDate === '' ? null : newUpdatedWork.workCreationDate,
    workGroup: newUpdatedWork.workGroup,
    workPart: newUpdatedWork.workPart,
    workStatus: newUpdatedWork.workStatus,
    workSubStatus: newUpdatedWork.workSubStatus,
    workSourceIP: mapSourceIPToWorkSourceIP(workSourceIPValue),
    workType: newUpdatedWork.workType,
    territorySpecificInfo: {workTerritorySpecificInfo:  activeWorkTerritories.map(info => {
        return {
            workTerritorySpecificInfoID: territoryId(info),
            isControlledComposition: info.isControlledComposition,
            setTypeGroup: info.setTypeGroup,  
            domesticOrForeign: info.domesticOrForeign ? info.domesticOrForeign : null,
            isPCSpecific: info.isPCSpecific,
            isPDArrangement: info.isPDArrangement,
            isNonRoyaltyBearing: info.isNonRoyaltyBearing,
            isOutOfCopyright: info.isOutOfCopyright,
            workStatus: info.workStatus,
            workSubStatus: info.workSubStatus,
            validForUsageFrom: info.validForUsageFrom === '' ? null : info.validForUsageFrom,
            validForUsageTo: info.validForUsageTo === '' ? null : info.validForUsageTo,
            genreCategory: info.genreCategory,
        }
    }),
    setTypeGroup: newUpdatedWork.workTerritorySpecificInfo[0].setTypeGroup,               
},
}

return newWork;
}