import React, { FunctionComponent } from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { APPLICABLE_WORKS_KEY, ASSING_TO_SEARCH_WORKFLOW_STATE_KEY, DISTRIBUTION_SUBJECTS_KEY, DISTRIBUTION_SUBJECT_LICENSING_TYPE_STATE_KEY, DISTRIBUTION_SUBJECT_MEMBER_TYPE_STATE_KEY, DISTRIBUTION_SUBJECT_WORK_TYPE_STATE_KEY, EMPTY_STRING_VALUE, REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_2, REPERTOIRE_PAGE_FORM_ACTION_BUTTON_SIZE_3, REPERTOIRE_PAGE_FORM_ITEM_SIZE, REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION, REPERTOIRE_PAGE_FORM_WIDE_SIZE, SEARCH_VIEW_WORKFLOW, UPDATE_WORKFLOW_VIEW, UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY, WORKFLOW_ENTITY_TYPE_STATE_KEY } from "../../Consts";
import { DropdownMenuItemType, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { ComboBox } from "@fluentui/react";
import { IMembersPortalStateKeys } from "../../../membersportal/types/IMembersPortalStateKeys";
import { Field, useField } from "formik";

export interface IDropdownDataInputFormikProps {
    label?: string;
    fieldName: IRepertoireStateKeys | IMembersPortalStateKeys;
    options: {
        code: string;
        description: string;
        extraFields?: object;
    }[];
    allowNull: boolean;
    showDropdownsAsCodes?: boolean;
    readOnly?: boolean;
    isHidden: boolean;
    componentInstance?: string;
    selectedCode?: string;
    index?: number;
    useComboBoxAsMenuWidth?: boolean;
    isMandatory?: boolean;
    errors?: any;
    onChange?: (fieldName: string, value: any) => void;
}

export const SizedDropdownDataInputFormik: FunctionComponent<IDropdownDataInputFormikProps> = (props: IDropdownDataInputFormikProps) => {

    return <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem`}>
        <DropdownDataInputFormik {...props} />
    </div>
}

export const makeOptionsList = (allowNull, fieldName, options, showDropdownsAsCodes) => {
    var arr: IDropdownOption[] = [];
    if (allowNull && fieldName !== "setTypeGroup") {
        arr.push({ key: -1, text: "", data: "" });
    }

    if (options) {
        var optionGroups: { [key: string]: IDropdownOption[] } = {};
        if (fieldName === "numbers") options.sort((a, b) => a.description.localeCompare(b.description.toLowerCase()));

        if (fieldName === "musicArrangement" || fieldName === "lyricAdaptation") {
            options.forEach((opt, i) => {
                const optElement: IDropdownOption = {
                    key: i,
                    data: opt.code,
                    text: fieldName === "musicArrangement" ? opt.extraFields['ArrangementType'] : opt.extraFields['AdaptationType'],
                    title: showDropdownsAsCodes ? opt.description : null,
                    disabled: opt.extraFields && 'DisabledInDropdown' in opt.extraFields && opt.extraFields['DisabledInDropdown'] !== undefined ? Boolean(opt.extraFields['DisabledInDropdown']).valueOf() : false
                };
                arr.push(optElement);
            });
        }
        else {
            options.forEach((opt, i) => {
                const optElement: IDropdownOption = {
                    key: opt?.key ? opt.key : i,
                    data: opt.code,
                    text: showDropdownsAsCodes ? opt.code : opt.description,
                    title: showDropdownsAsCodes ? opt.description : null,
                    disabled: opt.extraFields && 'DisabledInDropdown' in opt.extraFields && opt.extraFields['DisabledInDropdown'] !== undefined ? Boolean(opt.extraFields['DisabledInDropdown']).valueOf() : false,
                };
                if (opt.extraFields && 'Type' in opt.extraFields && opt.extraFields['Type'] !== undefined) {
                    if (opt.extraFields['Type'].toString() in optionGroups) {
                        if (['ISRC', 'ISWC'].includes(opt.code))
                            optionGroups[opt.extraFields['Type'].toString()].unshift(optElement);
                        else
                            optionGroups[opt.extraFields['Type'].toString()].push(optElement);
                    } else {
                        optionGroups[opt.extraFields['Type'].toString()] = [optElement];
                    }
                } else {
                    arr.push(optElement);
                }
            });
        }

        for (const group in optionGroups) {
            const groupItem = { key: `Group-${group}`, text: group, title: group, itemType: DropdownMenuItemType.Header };
            if (group === 'Other') {
                arr.unshift(groupItem, ...optionGroups[group]);
            } else {
                arr.push(groupItem, ...optionGroups[group]);
            }
        }
    }
    return arr;
};

export const DropdownDataInputFormik: FunctionComponent<IDropdownDataInputFormikProps> = ({
    options,
    label,
    fieldName,
    allowNull,
    showDropdownsAsCodes,
    readOnly,
    useComboBoxAsMenuWidth,
    isMandatory,
    errors,
    onChange,
    selectedCode
}) => {
    
    const showCodesClassName: string = EMPTY_STRING_VALUE
    const pointerClassName: string = ' pointerEnable ';
    const readOnlyFinal: boolean = fieldName === APPLICABLE_WORKS_KEY ? true : readOnly;

    const optionsFinal = makeOptionsList(allowNull, fieldName, options, showDropdownsAsCodes);

    const showError = errors && errors[fieldName];
    return (

        <Field name={fieldName} errorName={label}>
            {({ field, form }) => {
                return (<>
                    {label && <label>{label}</label>}
                    <div className={showError ? "validationForkmikField dropdownDataInput" : "emptyValidationBoarder dropdownDataInput"}>
                        <ComboBox
                            {...field}
                            id={fieldName}
                            options={optionsFinal}
                            defaultSelectedKey={selectedCode ? optionsFinal.find(x => x.data === selectedCode)?.key : field?.value?.key}
                            dataItemKey="data"
                            onChange={(event, option) => {
                                onChange && onChange(fieldName, option);
                                form.setFieldValue(fieldName, option)
                            }}
                            useComboBoxAsMenuWidth={useComboBoxAsMenuWidth}
                            calloutProps={{ calloutMaxHeight: 270 }}
                            className={showCodesClassName.concat(pointerClassName,
                                isMandatory ? " required " : "",
                                readOnlyFinal ? " disabledItem " : "")}
                            disabled={readOnlyFinal}
                            allowFreeInput={true}
                            autoComplete={"on"}
                            data-testid={fieldName + "-combo-box"}
                        />
                    </div>
                </>
                )
            }}
        </Field>
    );
};

export default DropdownDataInputFormik;
