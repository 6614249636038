import React from "react";
import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import IShortCutKeys from "../../../redux/types/IShortCutKeys";

export interface ISearchViewKeyEventListenerComponentProps {
    setStateInitial: () => void;
};
export interface IStateProps {shortCutKeys: IShortCutKeys[]}

type ICombinedToolbarConnectProps = ISearchViewKeyEventListenerComponentProps & IStateProps;

const SearchViewKeyEventListener: React.FC<ICombinedToolbarConnectProps> = ({
    setStateInitial,
    shortCutKeys,
}) => {
    const [keysPressed, setKeysPressed] = React.useState([]);
    const areCorrectKeysPressed = (actionCode, keysPressed) => {
        const shortCutKeysCommaSeparatedString = shortCutKeys && shortCutKeys.find(key => key.actionCode === actionCode)?.key
        const shortCutKeysArray = shortCutKeysCommaSeparatedString ? shortCutKeysCommaSeparatedString.split(','): [];
        let filteredRequiredPressedKeys = shortCutKeysArray && shortCutKeysArray.filter(keyString => keysPressed[keyString]);
        return filteredRequiredPressedKeys?.length === shortCutKeysArray?.length;
    }
    const emptySearchFields  = (e) => {     

    if (areCorrectKeysPressed("EMPTY_FIELD_ON_SEARCH_GRID", keysPressed)){
        setStateInitial()
      }
    }

    const setTheKeyPressTrue = (event) => {
        const newKeyPress = keysPressed;
        newKeyPress[event.key] = true;
        setKeysPressed(newKeyPress);
    }

    const setTheKeyPressFalse = (event) => {
        const newKeyPress = keysPressed;
        newKeyPress[event.key] = false;
        setKeysPressed(newKeyPress);
    }

 
    React.useEffect(() => {
        
        window.addEventListener('keydown', (event) => {
            setTheKeyPressTrue(event);
         });
 
           window.addEventListener('keydown', emptySearchFields)
 
           window.addEventListener('keyup', (event) => {
            setTheKeyPressFalse(event)
          });

          return () => {
            window.removeEventListener('keydown', emptySearchFields);
            window.removeEventListener('keyup', (event) => {
                setTheKeyPressFalse(event)
             });
             window.removeEventListener('keydown', (event) => {
                setTheKeyPressTrue(event)
             });
          }


    }, [])

return <></>;

}

export default connect((state: IAppState, props: ISearchViewKeyEventListenerComponentProps) => ({
    shortCutKeys: state.repertoire.shortCutKeys,
}), null)(SearchViewKeyEventListener);
