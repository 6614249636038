import { ComponentsHelper } from "../../core/services/ComponentHelper";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IWorkState } from "../../redux/types/IWorkState";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";
import { DRAFTWORKS_SAVE_VALIDATION_DRAFTWORKS_TITLE_NOT_SET, EMPTY_STRING_VALUE } from "../Consts";

export class MembersPortalWorkValidator {

    public static validateWork(work: IWorkState, draftWorkMaintenanceGeneralData?: IMembersPortalComponentDataItem): IValidationMessage[] {
        let workIsValid: IValidationMessage[];
        workIsValid = [];
        if (work.titles.length < 1)
        workIsValid.push({ 
            messageKey:ComponentsHelper.getFieldValueByName(
                DRAFTWORKS_SAVE_VALIDATION_DRAFTWORKS_TITLE_NOT_SET, 
                draftWorkMaintenanceGeneralData.fields, 
                EMPTY_STRING_VALUE)
        });
        return workIsValid;
    }
}