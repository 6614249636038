import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { showYesNoPrompt, hideModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SAVE_CHANGES_STATE_KEY, ADD_NEW_ACCOUNT_STATE_KEY, DELETE_ACCOUNT_STATE_KEY, UNDO_CHANGES_ACCOUNT_KEY, IP_MAINTENANCE_TOOLBAR, UNDO_METHOD_TOOLBAR, SAVE_METHOD_TOOLBAR, ADD_METHOD_TOOLBAR, DELETE_METHOD_TOOLBAR, HISTORY_METHOD_TOOLBAR, VERSION_HISTORY_STATE_KEY, ARTIST_MAINTENANCE_TOOLBAR, UNDO_CHANGES_ARTIST_KEY, ADD_NEW_ARTIST_STATE_KEY, DELETE_ARTIST_STATE_KEY, DELETE_ARTISTS_ROLE, ALL_ROLES, UPDATE_ARTISTS_ROLE } from "../../../Consts";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { clearModalSearchResults } from "../../../../redux/reducers/RepertoireReducer";
import { IArtistCore } from "../../../types/IArtistCore";
import { IArtistState } from "../../../../redux/types/IArtistState";

export interface IArtistMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData?: IRepertoireComponentDataItem;
    artist: IArtistState;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    deleteArtist: () => void;
    addNewArtist: () => void;
    undoArtistChanges: () => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    isReadonly: boolean;
    roles?: string[];
}

export type IArtistMaintenanceToolbarKeys = keyof IArtistMaintenanceToolbarProps;

export interface IArtistMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
}

class ArtistMaintenanceToolbar extends React.Component<
    IArtistMaintenanceToolbarProps,
    IArtistMaintenanceToolbarState
> {

    constructor(props) {
        super(props)
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    saveChangesAction = () => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    getDeleteArtistRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(DELETE_ARTISTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateArtistRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_ARTISTS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getDataAction() {
        const {
            deleteArtist,
            addNewArtist,
            undoArtistChanges,
            toolbarData: { fields }
        } = this.props;

        const actionList: IDataActionToolbar[] = [];

        const undo: IDataActionToolbar = {
            action: undoArtistChanges,
            component: ARTIST_MAINTENANCE_TOOLBAR,
            method: UNDO_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === UNDO_CHANGES_ARTIST_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Undo',
            isReadOnly: this.getUpdateArtistRoleDisabled()
        };
        actionList.push(undo);

        const add: IDataActionToolbar = {
            action: addNewArtist,
            component: ARTIST_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === ADD_NEW_ARTIST_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition',
            isReadOnly: this.getUpdateArtistRoleDisabled()
        };
        actionList.push(add);

        const deleteArtistAction: IDataActionToolbar = {
            action: deleteArtist,
            component: ARTIST_MAINTENANCE_TOOLBAR,
            method: DELETE_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === DELETE_ARTIST_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Delete',
            isReadOnly: this.getDeleteArtistRoleDisabled() || this.getUpdateArtistRoleDisabled()
        };
        actionList.push(deleteArtistAction);


        const save: IDataActionToolbar = {
            action: this.saveChangesAction,
            component: ARTIST_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateArtistRoleDisabled()
        };
        actionList.push(save);

        return actionList;
    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            artist,
            activeTab,
            toolbarData,
            tabs,
            isReadonly,
        } = this.props;

        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateArtistRoleDisabled() && changesMade

        return (
            <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    artist={artist}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction}
                    toolbarData={toolbarData}
                    component={ARTIST_MAINTENANCE_TOOLBAR}
                    isNew={tabs[activeTab].artistMaintenanceState.isNew}
                    isReadonly={isReadonly}

                />
            </div>
        );
    }

}

export default ArtistMaintenanceToolbar;
