import * as React from "react";
import { ADJUSTMENT_CATEGORY_LOOKUP, ADJUSTMENT_STATUS_LOOKUP } from "../../../../lookup/Consts";
import { IAdjustmentState, IAdjustmentStateKeys } from "../../../../redux/types/IAdjustmentState";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { SizedTextDataInput } from "../../../components/textDataInput/TextDataInput";
import { ADJUSTMENT_ADJUSTMENT_DISTRIBUTION_STATE_KEY, ADJUSTMENT_ADJUSTMENT_POOL_STATE_KEY, ADJUSTMENT_ADJUSTMENT_SEGMENT_STATE_KEY, ADJUSTMENT_BATCH_ID_STATE_KEY, ADJUSTMENT_CATEGORY_STATE_KEY, ADJUSTMENT_DATE_STATE_KEY, ADJUSTMENT_NARRATIVE_STATE_KEY, ADJUSTMENT_SOURCE_DISTRIBUTION_STATE_KEY, ADJUSTMENT_SOURCE_POOL_STATE_KEY, ADJUSTMENT_SOURCE_SEGMENT_STATE_KEY, ADJUSTMENT_STATUS_STATE_KEY, POST_ADJUSTMENT_BATCH_STATE_KEY, POST_AGREEMENT_BATCH_STATE_KEY } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { getLookupDefault, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { updateAdjustmentField } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { DataSource } from "../../../types/DataSource";
import WorkBatchInput from "../../../works/components/workBatchInput/WorkBatchInput";
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { If } from "../../../../core/components/If";
import {
    REPERTOIRE_PAGE_FORM_ITEM_SIZE
} from "../../../Consts";

export interface IAdjustmentMaintenanceGeneralDataViewProps {
    adjustmentMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    adjustment: IAdjustmentState,
    updateAdjustmentField: (value: any, fieldName: keyof IAdjustmentState) => void;
    activeTab: number;
    tabs: ITabReduxItem[];
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    sourceDistributions: { code: string; description: string }[];
    sourcePools: { code: string; description: string }[];
    sourceSegments: { code: string; description: string }[];
    adjustmentDistributions: { code: string; description: string }[];
    adjustmentPools: { code: string; description: string }[];
    adjustmentSegments: { code: string; description: string }[];
    changesMade: boolean;
    saveInProgress: boolean;
    adjustmentBatchOperationInProgress: boolean;
    postAdjustmentBatch: (adjustmentBatchId: number) => void
}

interface IAdjustmentMaintenanceGeneralDataViewState {
    loaded: boolean;
    selectedPools: any[];
}

export class AdjustmentMaintenanceGeneralDataView extends React.PureComponent<
    IAdjustmentMaintenanceGeneralDataViewProps,
    IAdjustmentMaintenanceGeneralDataViewState
> {
    viewData;
    constructor(props: IAdjustmentMaintenanceGeneralDataViewProps) {
        super(props);
        this.state = {
            loaded: false,
            selectedPools: []
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            adjustmentMaintenanceGeneralDataViewData: { fields }
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    render() {
        const {
            isReadonly,
            lookupValues,
            updateAdjustmentField,
            sourceDistributions,
            sourcePools,
            sourceSegments,
            adjustmentDistributions,
            adjustmentPools,
            adjustmentSegments,
            changesMade,
            saveInProgress,
            adjustmentBatchOperationInProgress,
            postAdjustmentBatch
        } = this.props;

        const {
            adjustmentCategory,
            adjustmentDate,
            adjustmentDistribution,
            adjustmentPool,
            adjustmentSegment,
            adjustmentStatus,
            sourceDistribution,
            sourcePool,
            sourceSegment,
            narrative,
            batchId,
            dataSource
        } = this.props.adjustment;

        const { loaded, selectedPools } = this.state;


        const changeData = (value: any, name: IRepertoireStateKeys) => {
            if (name === "sourceDistribution") {
                selectedPools.splice(0)
            }
            updateAdjustmentField(value, name as IAdjustmentStateKeys);
        }

        const multiSelectChangeData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
            this.props.updateAdjustmentField(option.data, "sourcePool");
            if (!selectedPools.includes(option.key)) {
                selectedPools.push(option.key)
            }
            else {
                const index = selectedPools.indexOf(option.key);
                if (index > -1) {
                    selectedPools.splice(index, 1);
                }
            }
        }

        if (sourcePool) {
            sourcePool.forEach(item => {
                if (!selectedPools.includes(item)) {
                    selectedPools.push(item);
                };
            });
        }

        let dropdownOptions = [];
        sourcePools
            .forEach(x => dropdownOptions.push({ key: x.code, text: x.code, data: x.code }));

        const renderPostBatch = (): JSX.Element => {
            if (dataSource === DataSource.Intray) {
                return <WorkBatchInput
                    label={this.viewData[ADJUSTMENT_BATCH_ID_STATE_KEY]}
                    fieldName={ADJUSTMENT_BATCH_ID_STATE_KEY}
                    value={batchId}
                    changeData={changeData}
                    postButtonLabel={this.viewData[POST_ADJUSTMENT_BATCH_STATE_KEY]}
                    postButtonAction={() => postAdjustmentBatch(batchId)}
                    matchButtonLabel={null}
                    matchButtonAction={() => true}
                    readOnly={isReadonly}
                    isEnabled={!changesMade && !saveInProgress && !adjustmentBatchOperationInProgress && !isReadonly}
                />
            }
            else {
                return null;
            }
        }

        if (loaded) {
            return (
                <div>
                    <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <h1>Adjustment Information</h1>
                            </div>
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[ADJUSTMENT_SOURCE_DISTRIBUTION_STATE_KEY]}
                                fieldName={ADJUSTMENT_SOURCE_DISTRIBUTION_STATE_KEY}
                                changeData={changeData}
                                value={sourceDistribution || ''}
                                isHidden={!this.viewData[ADJUSTMENT_SOURCE_DISTRIBUTION_STATE_KEY]}
                                readOnly={isReadonly}
                                options={sourceDistributions}
                                allowNull={false}
                            />
                            <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                                <Dropdown
                                    label={this.viewData[ADJUSTMENT_SOURCE_POOL_STATE_KEY]}
                                    selectedKeys={selectedPools}
                                    options={dropdownOptions}
                                    multiSelect={true}
                                    onChange={multiSelectChangeData}
                                    placeholder={"No Pools selected."}
                                    disabled={!(dataSource === DataSource.Intray)}
                                />
                            </div>
 
                            <SizedDropdownDataInput
                                label={this.viewData[ADJUSTMENT_SOURCE_SEGMENT_STATE_KEY]}
                                fieldName={ADJUSTMENT_SOURCE_SEGMENT_STATE_KEY}
                                changeData={changeData}
                                value={sourceSegment}
                                isHidden={!this.viewData[ADJUSTMENT_SOURCE_SEGMENT_STATE_KEY]}
                                readOnly={isReadonly || selectedPools.length > 1}
                                options={sourceSegments}
                                allowNull={true}
                            />
                        </div>
                        <div className="row">
                            <SizedDropdownDataInput
                                label={this.viewData[ADJUSTMENT_ADJUSTMENT_DISTRIBUTION_STATE_KEY]}
                                fieldName={ADJUSTMENT_ADJUSTMENT_DISTRIBUTION_STATE_KEY}
                                changeData={changeData}
                                value={adjustmentDistribution || ''}
                                isHidden={!this.viewData[ADJUSTMENT_ADJUSTMENT_DISTRIBUTION_STATE_KEY]}
                                readOnly={isReadonly}
                                options={adjustmentDistributions}
                                allowNull={false}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[ADJUSTMENT_ADJUSTMENT_POOL_STATE_KEY]}
                                fieldName={ADJUSTMENT_ADJUSTMENT_POOL_STATE_KEY}
                                changeData={changeData}
                                value={adjustmentPool}
                                isHidden={!this.viewData[ADJUSTMENT_ADJUSTMENT_POOL_STATE_KEY]}
                                readOnly={isReadonly}
                                options={adjustmentPools}
                                allowNull={true}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[ADJUSTMENT_ADJUSTMENT_SEGMENT_STATE_KEY]}
                                fieldName={ADJUSTMENT_ADJUSTMENT_SEGMENT_STATE_KEY}
                                changeData={changeData}
                                value={adjustmentSegment}
                                isHidden={!this.viewData[ADJUSTMENT_ADJUSTMENT_SEGMENT_STATE_KEY]}
                                readOnly={isReadonly}
                                options={adjustmentSegments}
                                allowNull={true}
                            />
                        </div>
                        <div className="row">
                            <SizedDateDataInput
                                label={this.viewData[ADJUSTMENT_DATE_STATE_KEY]}
                                fieldName={ADJUSTMENT_DATE_STATE_KEY}
                                changeData={changeData}
                                value={adjustmentDate}
                                isHidden={!this.viewData[ADJUSTMENT_DATE_STATE_KEY]}
                                readOnly={isReadonly}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[ADJUSTMENT_STATUS_STATE_KEY]}
                                fieldName={ADJUSTMENT_STATUS_STATE_KEY}
                                changeData={changeData}
                                value={adjustmentStatus || ''}
                                isHidden={!this.viewData[ADJUSTMENT_STATUS_STATE_KEY]}
                                readOnly={isReadonly}
                                options={getLookupValues(ADJUSTMENT_STATUS_LOOKUP, lookupValues)}
                                allowNull={false}
                            />
                            <SizedDropdownDataInput
                                label={this.viewData[ADJUSTMENT_CATEGORY_STATE_KEY]}
                                fieldName={ADJUSTMENT_CATEGORY_STATE_KEY}
                                changeData={changeData}
                                value={adjustmentCategory || ''}
                                isHidden={!this.viewData[ADJUSTMENT_CATEGORY_STATE_KEY]}
                                readOnly={isReadonly}
                                options={getLookupValues(ADJUSTMENT_CATEGORY_LOOKUP, lookupValues)}
                                allowNull={false}
                            />
                        </div>
                        <div className="row">
                            <SizedTextDataInput
                                label={this.viewData[ADJUSTMENT_NARRATIVE_STATE_KEY]}
                                fieldName={ADJUSTMENT_NARRATIVE_STATE_KEY}
                                changeData={changeData}
                                value={narrative}
                                isHidden={!this.viewData[ADJUSTMENT_NARRATIVE_STATE_KEY]}
                                readOnly={isReadonly}
                                textArea={true}
                            />
                        </div>
                        <If condition={dataSource === DataSource.Intray}>
                            <div className="row">
                                {renderPostBatch()}
                            </div>
                        </If>
                    </div>
                </div>
            );
        }
        return <div />;
    }
}