import axios from "axios";
import { ISourceSearchResult } from "../types/ISourceSearchResult";
import { IUpdateSourceDto } from "../types/UpdateSourceDto";
import { IParameterSearchResult } from "../types/IParameterSearchResult";
import { IMatchType } from "../types/IMatchType";
import { ISourceMatchType } from "../types/ISourceMatchType";
import { SettingType } from "../types/SettingType";
import { Dictionary } from "../../core/types/Dictionary";

export class SourceService {
  public static getSources(matchTypeID: number): Promise<ISourceMatchType> {
    return new Promise<ISourceMatchType>((resolve, reject) => {
        axios
            .get(`Sources/GetSources/` + matchTypeID)
            .then(res => {
                resolve(res.data);
            });
    });
    }

    public static getSourcesForAllMatchTypes(): Promise<Dictionary<ISourceMatchType>> {
        return new Promise<Dictionary<ISourceMatchType>>((resolve) => {
            axios
                .get(`Sources/GetSourcesForAllMatchTypes`)
                .then(res => {
                    resolve(res.data);
                });
        });
    }

  public static update(parameters: IParameterSearchResult[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      axios
        .post(`Sources/Update`, parameters)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          return false;
        });
    });
  }

  public static getMatchTypes(): Promise<IMatchType[]> {
    return new Promise<IMatchType[]>((resolve, reject) => {
        axios
            .get(`Sources/GetMatchTypes`)
            .then(res => {
                resolve(res.data);
            });
    });
  }

  public static loadSource(
    sourceID: number,
    productType: string,
      settingType: SettingType
  ): Promise<ISourceSearchResult> {
    return new Promise<ISourceSearchResult>((resolve, reject) => {
        axios
            .post(`Sources/LoadSource`, { sourceID, productType, settingType })
            .then(res => {
                resolve(res.data);
            });
    });
  }

  public static updateSource(
      sourceID: number,
      name: string,
      code: string,
      description: string,
      rejectDuplicateUsageFiles: boolean
  ): Promise<boolean> {
    let source: IUpdateSourceDto = {
        sourceName: name,
        sourceCode: code,
        sourceID: sourceID,
        sourceDescription: description,
        rejectDuplicateUsageFiles: rejectDuplicateUsageFiles
    };

    return new Promise<boolean>((resolve, reject) => {
        axios
            .post(`Sources/UpdateSource`, source)
            .then(res => {
                if (res.data.success) {
                    resolve(true);
                } else {
                    reject(new Error(res.data.errorMessage));
                }
            });
    });
  }

  public static updateSourceHierarchy(
    sourceTree: ISourceSearchResult
  ): Promise<ISourceMatchType> {
    return new Promise<ISourceMatchType>((resolve, reject) => {
        axios
            .post(`Sources/UpdateSourceHierarchy`, sourceTree)
            .then(res => {
                resolve(res.data);
            });
    });
    }

    public static getFolderDefaultConfiguration(path: string, isRepertoireModule?: boolean): Promise<ISourceSearchResult> {
        return new Promise<ISourceSearchResult>((resolve) => {
            axios
                .post(`Sources/LoadFolderConfiguartion`, { path: path, IsRepertoireModule: isRepertoireModule })
                .then(res => {
                    if (res.data.success === true) {
                        resolve(res.data.result);
                    }
                    else {
                        resolve(null);
                    }
                });
        });
    }

    public static loadFolderListThunk(): Promise<IParameterSearchResult[]> {
        return new Promise<IParameterSearchResult[]>((resolve, reject) => {
            axios
                .get(`Sources/LoadFolderList`)
                .then(res => {
                    resolve(res.data);
                });
        });
    }
}
