import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { IP_SAVE_VALIDATION_IP_TITLE_NOT_SET } from "../../membersportal/Consts";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IInterestedParty } from "../../repertoire/types/IInterestedParty";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";
import { EMPTY_STRING_VALUE } from "../Consts";

export class IntertestedPartyValidator {

    public static validateIntertestedParty(fromIP: IInterestedParty, toIP:IInterestedParty,ipMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem): IValidationMessage[] {
        let IpIsValid: IValidationMessage[];
        IpIsValid = [];
        if (fromIP==undefined|| toIP==undefined)
        IpIsValid.push({ 
            messageKey:ComponentsHelper.getFieldValueByName(
                IP_SAVE_VALIDATION_IP_TITLE_NOT_SET,ipMaintenanceGeneralDataViewData.fields,EMPTY_STRING_VALUE)
        });
        return IpIsValid;
    }
}