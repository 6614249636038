import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import MyFormsPage from "./MyFormsPage";

export default connect(
    (state: IAppState) => ({    
        powerPortalURL: state.membersPortal.powerPortalURL
    }),
    (dispatch: Dispatch) => ({               
    })
)(MyFormsPage);