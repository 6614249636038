import * as React from "react";
import { Button, Steps } from "antd";
import { DataSource } from "../../../repertoire/types/DataSource";


enum FormPages {
    AttachmentPage = 2,
}

export interface Step {
    title: string;
    content: any;
}

export interface IMultiStepFormProps {
    steps: Step[];
    readonly: boolean;
    saveChanges: () => void;
    confirmAndSubmit: () => void;
    dataSource?: string;
    current?: number;
    setCurrent?: (current: number) => void;
}



export default class MultiStepForm extends React.PureComponent<IMultiStepFormProps, {}> {
    constructor(props: IMultiStepFormProps) {
        super(props);
    }
    
    
    next = () => {
        const { current,setCurrent,saveChanges } = this.props;
            const next = current + 1;
    
            //Early save to create a work id entry for attachments.
            if (next === FormPages.AttachmentPage) {
                saveChanges();
            }
            setCurrent(next);
        
    }

    prev = () => {
        const { current, setCurrent } = this.props;
        setCurrent(current - 1);
    };
    

    renderButtons = () => {     
       
            return (<div>
                <div className="btn-group form-group col-lg-2 col-lg-push-3 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0" style={{ marginTop: 24 }}>
                    <Button data-testid={"save-registration"} className="button btn-defaultSecondary" onClick={() => this.props.saveChanges()}>
                     Save Registration
                    </Button>
                </div>
                <div className="btn-group form-group col-lg-2 col-lg-push-3 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0" style={{ marginTop: 24 }}>
                    <Button data-testid={"confirm-submit-registration"} className="button btn-defaultPrimary" onClick={() => this.props.confirmAndSubmit()}>
                        Confirm & Submit Registration
                    </Button>
                </div>
            </div>);
    };

    render() {
        const { current, dataSource } = this.props;
        const items = this.props.steps.map((item) => ({ key: item.title, title: item.title }));
        return (
            <>
                {dataSource === DataSource.Intray ? <Steps current={current} items={items} /> : <></> }
                    <div style={{ padding: '20px' }}>{this.props.steps[current].content}</div>
                        <div className="row">
                                <div className="btn-group form-group col-lg-2 col-lg-push-5 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0" style={{ marginTop: 24 }}>
                                    {current > 0 && (
                                        <Button data-testid={"prev"} className="button btn-defaultSecondary" onClick={this.prev}>
                                            Previous
                                        </Button>
                                     )}
                                </div>
                                <div className="btn-group form-group col-lg-2 col-lg-push-5 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0" style={{ marginTop: 24 }}>
                                    {current < this.props.steps.length - 1 && (
                                        <Button data-testid={"next"} className= "button btn-defaultSecondary" onClick={this.next}>
                                            Next
                                        </Button>
                                    )}
                                </div>
                                    {current === this.props.steps.length - 1 && this.props.dataSource === DataSource.Intray && (
                                        <>
                                            {this.renderButtons()}
                                        </>
                                    )}
                                </div>
            </>
        );
    }
}
