﻿import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
    editingChanged,
    fileMetadataChanged,
    toggleSidebarCollapsed,
    matchNow,
    validationMessageChanged,
    setInDropZone,
    addFileToUpload,
    setProgressUploadFileProccess,
    sortColumn,
    CleanUploadFilesState,
    updatePagination,
    resetPagination,
    resetMessageBanner
} from "../../../redux/reducers/DataIngestionReducer";
import { showModal, showYesNoPrompt } from "../../../redux/reducers/ModalReducer";
import {
    cancelChangesThunk,
    openDirectoryThunk,
    saveChangesThunk,
    matchNowThunk,
    refreshDirectoryThunk,
    undoUsageIngestionThunk
} from "../../../redux/thunks/DataIngestionThunks";
import { clearModalSearchResultsThunk } from "../../../redux/thunks/ModalThunks";
import { deleteBlobFileThunk, getMatchingSourcesThunk } from "../../../redux/thunks/RepertoireThunks";
import IAppState from "../../../redux/types/IAppState";
import { IAttachedFile } from "../../../redux/types/IAttachedFile";
import IFile from "../../../redux/types/IFiles";
import { IYesNoPromptViewModalProps } from "../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { DataSource } from "../../../repertoire/types/DataSource";
import { ITreeData } from "../../../repertoire/types/ITreeData";
import { IFileSystem } from "../../types/IFileSystem";
import { IStoragePathItemData } from "../../types/IStoragePathItemData";
import ScrollEventListenerWrapperContainerDetailsWindow from "./ScrollEventListenerWrapperContainerDetailsWindow";
import { removeAgreementAttachmentIfExistsThunk } from "../../../redux/thunks/MembersPortalThunks";

export default connect(
    (state: IAppState) => (
        {
            jobs: state.job && state.job.jobs,
            containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
            storagePathItemData: state.dataIngestion.componentData.StoragePathItem,
            distributions: state.dataIngestion.distributions,
            formats: state.dataIngestion.formats,
            storagePaths: state.dataIngestion.storagePaths,
            selectedFileSystem: state.dataIngestion.selectedFileSystem,
            saveState: state.dataIngestion.saveState,
            destinations: state.dataIngestion.destinations && state.dataIngestion.destinations.lookups,
            droppedFiles: state.dataIngestion.droppedFiles,
            isDropZone: state.dataIngestion.droppedFiles.inDropZone,
            isRepertoireModule: false,
            paginationDetails: state.dataIngestion.paginationDetails,
            sources: state.repertoire.matchingSourceTreeData,
            pools: state.dataIngestion.pools,
            dataIngestionToolbarData: state.dataIngestion.componentData.DataIngestionToolbar,
            currentDirectory: undefined,
            pageContentIsActive: false,
            roles: state.account.roles,
            lookupValues: state.lookupEntities,
        }),
    (dispatch: Dispatch) => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        editingChanged: (item: IStoragePathItemData, index: number) => dispatch(editingChanged(item, index)),
        fileMetadataChanged: (field: string, value: any, index: number) => dispatch(fileMetadataChanged(field, value, index)),
        toggleSidebarCollapsed: () => dispatch(toggleSidebarCollapsed()),
        saveChanges: (fileSystem: IFileSystem, storagePaths: IStoragePathItemData[], sources: ITreeData[]) => dispatch<any>(saveChangesThunk(fileSystem, storagePaths, sources)),
        matchNow: (item: IStoragePathItemData, index: number) => dispatch<any>(matchNow(item, index)),
        matchNowSubmit: (fileSystem: IFileSystem, storagePaths: IStoragePathItemData[], sources: ITreeData[]) => dispatch<any>(matchNowThunk(fileSystem, storagePaths, sources)),
        cancelChanges: (fileSystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(cancelChangesThunk(fileSystem, sources)),
        openDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(openDirectoryThunk(filesystem, sources)),
        validationMessageChanged: (message: string, index: number) => dispatch(validationMessageChanged(message, index)),
        setInDropZone: (inDropZone: boolean) => dispatch(setInDropZone(inDropZone)),
        refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(refreshDirectoryThunk(filesystem, sources)),
        addFileToUpload: (file: IFile, isComplete: boolean) => dispatch<any>(addFileToUpload(file, isComplete)),
        setProgressUploadFileProccess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        cleanUploadFilesState: () => dispatch<any>(CleanUploadFilesState()),
        updatePagination: (resultsPerPage: number, currentPage: any, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),
        resetPagination: (section: string) => dispatch<any>(resetPagination),
        getMatchingSources: () => dispatch<any>(getMatchingSourcesThunk()),
        undoUsageIngestion: (fullFilePath: string) => dispatch<any>(undoUsageIngestionThunk(fullFilePath)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) => dispatch(showYesNoPrompt(title, props)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch<any>(clearModalSearchResultsThunk()),
        removeAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => dispatch<any>(removeAgreementAttachmentIfExistsThunk(attachedFile, source)),
        deleteBlobFile: (fullName: string, name: string) => dispatch<any>(deleteBlobFileThunk(fullName, name))
    })
)(ScrollEventListenerWrapperContainerDetailsWindow);
