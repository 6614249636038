import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import ProductWorkSearchModalView from "./ProductWorkSearchModalView";
import { resetPagination, updatePagination } from "../../../../redux/reducers/RepertoireReducer";
import { Dispatch } from "redux";

export default connect(
    (state: IAppState) => ({
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0]?.indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0]?.indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'works')[0]?.resultsPerSection,
        currentPage: state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab] && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].currentPage ? state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].currentPage : undefined,
        allResultsPerPage: state.repertoire.resultsPerPage,
        continuationToken: state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab] && state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].continuationToken ? state.repertoire.panes[state.repertoire.activePane].tabs[state.repertoire.panes[state.repertoire.activePane].activeTab].continuationToken : undefined,
    }), 
    (dispatch: Dispatch) => ({
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
    })
    )
(ProductWorkSearchModalView);