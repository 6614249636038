import { IAgreementState } from "../../redux/types/IAgreementState";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { IValidationMessage } from "../../repertoire/types/IValidationMessage";
import { DRAFT_AGREEMENT_SAVE_VALIDATION_DRAFT_AGREEMENT_USER_NEEDS_TO_BE_RELATED_TO_AGREEMENT, DRAFTAGREEMENT_SAVE_VALIDATION_DRAFTAGREEMENT_WORKS_NOT_SET, EMPTY_STRING_VALUE } from "../Consts";

export class MembersPortalAgreementValidator {

    public static validateAgreement(agreement: IAgreementState, currentUserNumber: string, draftAgreementMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem): IValidationMessage[] {
        let agreementIsValid: IValidationMessage[];
        agreementIsValid = [];

        const isCurrentUserEitherAssigneeOrAssignor = (agreement, currentUserNumber) => {
            let isEitherAssigneeOrAssignor = false
            agreement.agreementAdminIP && agreement.agreementAdminIP.length > 0 && agreement.agreementAdminIP.forEach((assignee) => {
                if (assignee?.assigneeNameNumber?.value == currentUserNumber || assignee?.administeredIP?.value == currentUserNumber || assignee?.administeredByIPBaseNumber?.value == currentUserNumber ) {
                    isEitherAssigneeOrAssignor = true;
                }
            })


            if(agreement?.ipNameNumber == currentUserNumber) {
                isEitherAssigneeOrAssignor = true;
            }
            return isEitherAssigneeOrAssignor;
        } 


        if (!isCurrentUserEitherAssigneeOrAssignor(agreement, currentUserNumber)) {
            agreementIsValid.push({ 
                messageKey: DRAFT_AGREEMENT_SAVE_VALIDATION_DRAFT_AGREEMENT_USER_NEEDS_TO_BE_RELATED_TO_AGREEMENT
            }) 
        }
        // if (agreement.agreementWorks.length < 1)
        //     agreementIsValid.push({ 
        //     messageKey:ComponentsHelper.getFieldValueByName(
        //         DRAFTAGREEMENT_SAVE_VALIDATION_DRAFTAGREEMENT_WORKS_NOT_SET, 
        //         draftAgreementMaintenanceGeneralDataViewData.fields, 
        //         EMPTY_STRING_VALUE)
        // });
        return agreementIsValid;
    }
}