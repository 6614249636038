import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { addTab, sortArtistFieldData, updateArtistField } from "../../../../redux/reducers/RepertoireReducer";
import { ADD_NEW_ACTION, ARTIST_NUMBERS_KEY } from "../../../Consts";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IArtistNumberDataItems } from "../../../types/IArtistNumberDataItems";
import { IArtistStateKeys } from "../../../../redux/types/IArtistState";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";

export interface IArtistMaintenanceGridsViewProps {
    artistMaintenanceGridsViewData: IRepertoireComponentDataItem;
    artistMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    activeAccordion: string[];
    openArtistAccordion: (accordionName: string, componentName: string) => void;
    closeArtistAccordion: (accordionName: string, componentName: string) => void;
    componentInstance: string;
    showModal: typeof showModal;
    artistNumbers: IArtistNumberDataItems[];
    addTab: typeof addTab;
    tabs: ITabReduxItem[];
    activeTab: number;
    lookupValues: ILookupDictionary;
    updateArtistField: typeof updateArtistField;
    sortArtistFieldData: typeof sortArtistFieldData;
    isReadonly: boolean;
    incrementTempID?: () => void;
}

interface IArtistMaintenanceGridsViewState {
    activeHeaderSection: string;
    loaded: boolean;
}

export default class ArtistMaintenanceGridsView extends React.PureComponent<
    IArtistMaintenanceGridsViewProps,
    IArtistMaintenanceGridsViewState
> {
    viewData;
    constructor(props: IArtistMaintenanceGridsViewProps) {
        super(props);
        this.state = {
            activeHeaderSection: "artistNumbers",
            loaded: false
        }
        this.viewData = {};
    }

    componentDidUpdate() {
        this.loadData();
    }

    componentDidMount() {
        this.loadData();
    }


    loadData() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.artistMaintenanceGridsViewData) {
                return;
            }
            else {
                this.setState({ loaded: true });
            }
        }
    }
    

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    sortData(value: any, name: IRepertoireStateKeys): void {
        this.props.sortArtistFieldData(value, name as IArtistStateKeys);
    }

    changeData = (value: any, name: IRepertoireStateKeys) => {
        this.props.updateArtistField(value, name as IArtistStateKeys);
    }

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { artistMaintenanceGridsViewData } = this.props;

        if (artistMaintenanceGridsViewData && artistMaintenanceGridsViewData.fields) {
            return artistMaintenanceGridsViewData.fields.filter(f => f.fieldType === null).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    };


    render() {
        const {
            dataGridTableData,
            artistNumbers,
            artistMaintenanceGridsViewData,
            isReadonly,
            lookupValues,
            tabs,
            activeTab,
            incrementTempID,
        } = this.props;

        const {
            activeHeaderSection
        } = this.state;

        const filteredFields = artistMaintenanceGridsViewData.fields.filter(
            (field: IRepertoireField) =>
                field.name === activeHeaderSection
        );

        const { loaded } = this.state;
        if (!loaded)
            return <div className="loading" />

        return (
            <>
                <div className="GridsView">
                    <div className="headerContainer">
                        {this.renderGridViewHeaderSections()}
                    </div>
                    {activeHeaderSection === ARTIST_NUMBERS_KEY ?
                        <DataGridTable
                            tableContents={artistNumbers}
                            sortData={this.sortData.bind(this)}
                            changeData={this.changeData.bind(this)}
                            dataGridTableData={dataGridTableData}
                            stateKey={filteredFields[0].name}
                            componentInstance={ARTIST_NUMBERS_KEY}
                            showRowNumber={true}
                            isReadOnly={isReadonly}
                            tableActions={[{ name: ADD_NEW_ACTION }]}
                            lookupValues={lookupValues}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                        /> : <div> </div>}
                </div>
            </>
        );
    }
}