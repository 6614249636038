import * as React from "react";
import SearchResultsTableSection from "../searchResultsTableSection/SearchResultsTableSection";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../types/IRepertoireField";
import { ComponentsHelper } from "../../../core/services/ComponentHelper";
import { selectSingleWorkflow, selectAllWorkflow, markSelectAll } from "../../../redux/reducers/RepertoireReducer";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { ILicenseInputItem } from "../../../redux/types/ILicenseInputItem";
import SearchResultsTable, { ISearchResultsTableProps } from "./SearchResultsTable";
import StatementSearchResultsTableSection from "../searchResultsTableSection/StatementSearchResultsTableSection";



export default class StatementSearchResultsTable extends SearchResultsTable {
    constructor(props: ISearchResultsTableProps) {
        super(props);

        super.state = {
            tableContents: props.tableContents,
            activeSortHeaderSection: undefined,
            indexOfFirstResult: props.indexOfFirstResult ? props.indexOfFirstResult : 0,
            indexOfLastResult: props.indexOfLastResult ? props.indexOfLastResult : 10,
            resultsPerPage: props.resultsPerPage ? props.resultsPerPage : 10,
            currentPage: props.currentPage ? props.currentPage : 1,
        };
    }

    renderSearchResults = () => {
        const { tableContents, indexOfFirstResult, indexOfLastResult } = this.state;
        const { searchResultsTableData,
            componentInstance,
            getWorkDetails,
            getAgreementDetails,
            getIPDetails,
            getProductDetails,
            getArtistDetails,
            getUsagePoolDetails,
            lookupValues,
            getDistributionDetails,
            expandedResults,
            expandAll,
            expandResult,
            expandAllResults,
            getUsageDetails,
            getAdjustmentDetails,
            getPaymentRunDetails,
            getLicenseDetails,
            getDraftWorkDetails,
            getMemberStatementDetails,
        } = this.props;
        let orderedFields: IRepertoireField[] = [];
        let removedItemAction: IRepertoireField = undefined;
        const currentResults = tableContents.slice(
            indexOfFirstResult,
            indexOfLastResult
        );

        let filteredFields = searchResultsTableData.fields.filter(
            (field: IRepertoireField) =>
                field.componentInstance === componentInstance
        );

        const actionFieldIndex = filteredFields.findIndex(({ name }) => name === "update_action" || name === "addNew_action");
        if (actionFieldIndex > -1) {
            removedItemAction = filteredFields[actionFieldIndex];
            filteredFields.splice(actionFieldIndex, 1);
        }
        orderedFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields, "order");


        if (!orderedFields && orderedFields.length > 0)
            orderedFields = filteredFields

        if (removedItemAction)
            orderedFields.push(removedItemAction);

        const keys: { name, title }[] = [];
        orderedFields.map(field => keys.push({ name: field.name, title: field.data }));

        const onSelectRow = (e, tableContentItem: any, index: number) => {
            this.onSelectRow(tableContentItem, index);
        }

        return currentResults.map(
            (tableContentItem: any, index: number) => {
                const sections: JSX.Element[] = [];

                keys.map((key: { name, title }, x: number) => {
                    sections.push(
                        <td key={`${index}.${x}`} className="td" title={
                            key.name in tableContentItem ? tableContentItem[key.name] : key.title
                        } >
                            <StatementSearchResultsTableSection
                                objectKey={key.name}
                                tableContentItem={tableContentItem}
                                getAgreementDetails={getAgreementDetails}
                                getIPDetails={getIPDetails}
                                getArtistDetails={getArtistDetails}
                                getUsagePoolDetails={getUsagePoolDetails}
                                lookupValues={lookupValues}
                                getDistributionDetails={getDistributionDetails}
                                getAdjustmentDetails={getAdjustmentDetails}
                                componentInstance={componentInstance}
                                expanded={((expandedResults && expandedResults.includes(index)) || expandAll) ? true : false}
                                getWorkDetails={getWorkDetails}
                                getProductDetails={getProductDetails}
                                getUsageDetails={getUsageDetails}
                                getPaymentRunDetails={getPaymentRunDetails}
                                getLicenseDetails={getLicenseDetails}
                                getDraftWorkDetails={getDraftWorkDetails}
                                getMemberStatementDetails={getMemberStatementDetails}
                                renderExpandRow={((key.name === 'title' && componentInstance === 'draftWorks') || (key.name === 'paymentRunCode' && componentInstance === 'memberStatements')) ? true : false}
                                expandResult={expandResult}
                                index={index}
                            />
                        </td>
                    );
                });

                return (
                    <tr key={index} className="tr">
                        {sections}
                    </tr>
                );
            }
        );
    };

    render() {
        return (
            <div className="SearchResultsTable">
                {this.renderExpandAll()}
                {this.renderSelectAll()}
                {this.renderPaginationView()}
                <div key='tableContainer' className="tableContainer">
                    <table className="table">
                        <thead className="thead">
                            <tr className="tr">
                                {this.renderHeaderSections()}
                            </tr>
                        </thead>
                        <tbody className="tbody">{this.renderSearchResults()}</tbody>
                    </table>
                    {this.renderPaginationView()}
                </div>
            </div>
        );
    }
}
