import { connect } from "react-redux";
import { Footer } from "./Footer";
import { Dispatch } from "redux";
import { getMembersPortalComponentDataThunk } from "../../../redux/thunks/MembersPortalThunks";
import IAppState from "../../../redux/types/IAppState";
export default connect(
    (state: IAppState) => ({
        footerViewData: state.membersPortal.componentData.FooterView,
    }),
    (dispatch: Dispatch) => ({
        getMembersPortalFields: () => dispatch<any>(getMembersPortalComponentDataThunk()),
    })
)(Footer);
