import React from "react";
import { IAgreementState } from "../../../../redux/types/IAgreementState";
import DraftAgreementsGeneralReview from "./DraftAgreementsGeneralReview";
import DraftAgreementsAssigneeReview from "./DraftAgreementsAssigneeReview";
import DraftAgreementsApplicableWorkReview from "./DraftAgreementsApplicableWorkReview";
import DraftAgreementsCommentsReview from "./DraftAgreementsCommentsReview";
import DraftAgreementsAttachmentsReview from "./DraftAgreementsAttachmentsReview";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { DataSource } from "../../../../repertoire/types/DataSource";

interface IDraftAgreementsMaintenanceSubmissionPageProps {
    agreements: IAgreementState;
    draftAgreementMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    attachedFiles: IStoragePathItemData[];
    dataSource?:string
}

const DraftAgreementsMaintenanceSubmissionPage: React.FC<IDraftAgreementsMaintenanceSubmissionPageProps> = ({
    agreements,
    draftAgreementMaintenanceGeneralDataViewData,
    lookupValues,
    attachedFiles,
    dataSource
}) => {
    return <>
    <DraftAgreementsGeneralReview agreement={agreements} draftAgreementMaintenanceGeneralDataViewData={draftAgreementMaintenanceGeneralDataViewData} lookupValues={lookupValues} dataSource={dataSource} />
    <DraftAgreementsAssigneeReview agreement={agreements} />
    {agreements.type === '2' && <DraftAgreementsApplicableWorkReview agreement={agreements} />}
    <DraftAgreementsCommentsReview agreement={agreements} />
    {attachedFiles && <DraftAgreementsAttachmentsReview attachedFiles={attachedFiles} />}
    </>
}

export default DraftAgreementsMaintenanceSubmissionPage;