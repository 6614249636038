import { ITreeNodeData } from "../types/TreeNodeData";

export class TreeviewService {
    public static flatten<T>(nodes: ITreeNodeData<T>[]): ITreeNodeData<T>[] {
        let result: ITreeNodeData<T>[] = [];

        nodes.forEach((n: ITreeNodeData<T>) => {
            result.push(n);
            if (n.children) {
                result = result.concat(TreeviewService.flatten(n.children));
            }
        });

        return result;
    }
}