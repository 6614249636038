import * as React from 'react';
import { ITabReduxItem } from '../../../redux/types/ITabReduxItem';
import { FULL_TIME_DURATION_FORMAT_KEY } from '../../ConfigurationConsts';
import { IRepertoireStateKeys } from '../../types/IRepertoireStateKeys';
import { SizedTextDataInput } from '../textDataInput/TextDataInput';
import { SizedTimeDataInput } from './TimeFieldDataInput';

export interface IFormattedTimeDataInputProps {
    value: any;
    changeData: (value: any, name: IRepertoireStateKeys, isOnBlur?: boolean, index?: number) => void;
    fieldName: string;
    label: string;
    activeTab: number;
    tabs: ITabReduxItem[];
    readOnly?: boolean;
    isHidden?: boolean;
    isDataGridSize?: boolean;
    isMandatory?: boolean;
    errors?: any;
    isFormik?: boolean;
}

interface IFormattedTimeDataInputState {
    time: string;
}

export default class FormattedTimeDataInput extends React.Component<IFormattedTimeDataInputProps, IFormattedTimeDataInputState>{
    constructor(props: IFormattedTimeDataInputProps) {
        super(props)
        this.state = {
            time: this.secondsIntToTimeString(this.props.value) || '00:00:00'
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ time: this.secondsIntToTimeString(nextProps.value) || '00:00:00' });
    }

    onTimeChange = (event, value: string, name: string) => {
        if (value && value.length >= 8) {
            const newTime = value.replace(/-/g, ':');
            const timeSeconds = newTime.padEnd(8, value.substr(5, 3));
            this.setState({ time: timeSeconds });
            const seconds: number = this.timeToIntSeconds(timeSeconds);
            if (!isNaN(seconds)) {
                this.props.changeData(seconds, name);
            }
        }
        else {
            this.setState({ time: value });
        }
    }

    secondsIntToTimeString(value: number): string {
        return value && !isNaN(value) && new Date(value * 1000).toISOString().substr(11, 8)
    }

    timeToIntSeconds(time: string): number {
        const split = time.split(':');
        if (split.length > 1) {
            if (split.length == 3) {
                return (+split[0]) * 60 * 60 + (+split[1]) * 60 + (+split[2]);
            }
            else {
                return (+split[0]) * 60 + (+split[1]);
            }
        }
        else {
            return Number(time);
        }
    }


    render() {
        const { tabs, activeTab, fieldName, label, changeData, readOnly, value, isHidden, isDataGridSize, isMandatory, errors, isFormik } = this.props;
        const { time } = this.state;
        const durationFormat = tabs[activeTab] && tabs[activeTab].formatFields && tabs[activeTab].formatFields.find(({ field }) => field === fieldName);
        if (durationFormat != null) {
            const activeFormats = durationFormat.formats.find(({ active }) => active);
            if (activeFormats && activeFormats.name === FULL_TIME_DURATION_FORMAT_KEY) {
                return <SizedTimeDataInput
                    value={time || ''}
                    changeData={this.onTimeChange.bind(this)}
                    fieldName={fieldName}
                    label={label}
                    isHidden={isHidden}
                    isDataGridSize={isDataGridSize}
                    isMandatory={isMandatory}
                    readOnly={readOnly}
                    errors={errors}
                    isFormik={isFormik}
                />
            }
        }
        else {
            return <SizedTextDataInput
                label={label}
                fieldName={fieldName}
                value={value}
                changeData={changeData}
                readOnly={readOnly}
                fieldType={"string"}
                isHidden={isHidden}
                isOnBlurOn={true}
                isMandatory={isMandatory}
            />
        }
    }
}