import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import IAppState from "../../redux/types/IAppState";
import {
    mp_addTab,
    resetPagination, sortSearchResults, updatePagination, setDataSource, createNewDraftSetLists
} from "../../redux/reducers/MembersPortalReducer";
import {
    getDraftSetListDetailsThunk,
    searchDraftSetListsThunk,
} from "../../redux/thunks/MembersPortalThunks";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { IDraftSetListSearchQuery } from "../types/IDraftSetListSearchQuery";
import DraftSetListsPage from "./DraftSetListsPage";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { FormatFields } from "../../redux/types/FormatFields";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { updateUserPreferencesThunk } from "../../redux/thunks/RepertoireThunks";
import IMembersPortalComponentDataItem from "../../redux/types/IMembersPortalComponentDataItem";
import { MemberPortalSearchType } from "../../redux/types/MemberPortalSearchType";

export default connect(
    (state: IAppState) => ({
        dataSource: state.membersPortal.dataSource,
        setListsPageData: state.membersPortal.componentData.DraftSetListsPage,
        searchViewData: state.membersPortal.componentData.SearchView,
        searchResultsTableData: state.membersPortal.componentData.SearchResultsTable,
        draftSetListSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListSearchResult,
        searchSuccessful: state.membersPortal.searchSuccessful,
        lookupValues: state.lookupEntities,
        indexOfFirstResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'setLists')[0]?.indexOfFirstResult,
        indexOfLastResult: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'setLists')[0]?.indexOfLastResult,
        resultsPerPage: state.membersPortal.resultsPerPage.filter(x => x.repertoireSection === 'setLists')[0]?.resultsPerSection,
        currentPage: state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].currentPage ? state.membersPortal.tabs[state.membersPortal.activeTab].currentPage : undefined,
        defaultActiveAccordions: state.membersPortal.defaultActiveAccordions,
        allResultsPerPage: state.membersPortal.resultsPerPage,
        roles: state.account.roles,
        memberPortalSearchType: MemberPortalSearchType.MyDraftSearch,
        currentUserNameNumber: state.account.currentUserNameNumber,
        draftSetListsMaintenanceGeneralDataViewData: state.membersPortal.componentData.DraftSetListsMaintenanceGeneralDataView,
    }),
    (dispatch: Dispatch) => ({
        setDataSource: (dataSource: string) => dispatch(setDataSource(dataSource)),
        getDraftSetListDetails: (dataSource: string, productCoreId: number, lookupValues: ILookupDictionary, formats?: FormatFields[]) =>
            dispatch<any>(getDraftSetListDetailsThunk(dataSource, productCoreId, lookupValues, formats)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        searchDraftSetLists: (query: IDraftSetListSearchQuery) =>
            dispatch<any>(searchDraftSetListsThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) =>
            dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        addNewDraftSetLists: (lookups: ILookupDictionary, currentUserNameNumber?: string, isNew?: boolean, draftSetListsMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch(createNewDraftSetLists(lookups, currentUserNameNumber, isNew, draftSetListsMaintenanceGeneralDataViewData, formats)),
    })
)(DraftSetListsPage);
