import { connect } from "react-redux";
import { Dispatch } from "redux";
import { setCurrentPage } from "../../../redux/reducers/HomeReducer";
import IAppState from "../../../redux/types/IAppState";
import { FormBoxMenu } from "./FormBoxMenu";
import { getUserCRMIpNumberByGuidThunk } from "../../../redux/thunks/AccountThunks";

export default connect(
    (state: IAppState) => ({
        menuItems: state.home.menuItems,
        enableModules: state.home.enableModules,
        currentUserNameNumber: state.account.currentUserNameNumber,
        portalType: state.account.portalType
    }),
    (dispatch: Dispatch) => ({
        setCurrentPage: (link: string) => dispatch(setCurrentPage(link)),
        getUserCRMIPNumber: (guid: string) => dispatch<any>(getUserCRMIpNumberByGuidThunk(guid))
    })
)(FormBoxMenu);