import React from "react";
import { MaskedTextField } from "@fluentui/react";
import { ReactNode } from "react";
import { IJobParameterControl } from "./IJobParameterControl";

export class NumericJobParameterControl
  implements IJobParameterControl<number>
{
  CurrentValue: number;
  DefaultValue: number;
  Options?: { code: number; description: string }[] = null;
  ControlID: string;
  Type:
    | "combobox"
    | "text-input"
    | "date-picker"
    | "numeric"
    | "radio-button"
    | "checkbox"
    | "checkbox-list"
    | "multiselect" = "numeric";
  Caption: string;
  Readonly: boolean;

  constructor() {}

  Render(): ReactNode {
    let maskFormat: { [key: string]: RegExp } = {
      "*": /[0-9\.]/,
    };

    return (
        <div className="dropdownDataInput">
          <label>{this.Caption}</label>
          <input
            type="number"
            value={this.CurrentValue}
            onChange={(e) => {
              this.CurrentValue =
                e.target.value.length > 0 ? Number(e.target.value) : null;
            }}
          />
      </div>
    );
  }
}
