import IAppState from "../../redux/types/IAppState";
import { connect } from "react-redux";
import ClaimsPage from "./ClaimsPage";
import { Dispatch } from "redux";
import { resetPagination, sortSearchResults, updatePagination } from "../../redux/reducers/RepertoireReducer";
import {
    addTab, createNewClaim, searchDataChanged
} from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import { showModal } from "../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { IWorksSearchQuery } from "../types/IWorksSearchQuery";
import {
    searchWorksThunk, searchClaimsThunk, getClaimsDetailsThunk
} from "../../redux/thunks/RepertoireThunks";
import { IClaimSearchQuery } from "../types/IClaimSearchQuery";
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { dissocPath } from "lodash/fp";
import { ITabProps } from "../../redux/types/ITabProps";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        claimsPageData: state.repertoire.componentData.ClaimsPage,
        claimsGeneralDataView: state.repertoire.componentData.ClaimMaintenanceGeneralDataView,
        lookupValues: state.lookupEntities,
        currentPage: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage : undefined,
        searchViewData: state.repertoire.componentData.SearchView,
        searchResultsTableData: state.repertoire.componentData.SearchResultsTable,
        claimsSearchResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].claimSearchResult,
        searchSuccessful: state.repertoire.searchSuccessful,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'claims')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'claims')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'claims')[0].resultsPerSection,
        allResultsPerPage: state.repertoire.resultsPerPage,
        roles: state.account.roles,
        distributionTypes: state.repertoire.distributionTypes

    }),
    (dispatch: Dispatch) => ({
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        addNewClaim: (lookupValues: ILookupDictionary, isNew?: boolean, claimMaintenanceGeneralViewData?: IRepertoireComponentDataItem) => dispatch(createNewClaim(lookupValues, isNew, claimMaintenanceGeneralViewData)),
        searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchWorksThunk(query, true)),
        searchDataChanged: (field: string, value: any) => dispatch(searchDataChanged(field, value)),
        searchClaims: (query: IClaimSearchQuery) => dispatch<any>(searchClaimsThunk(query)),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        getClaimDetails: (claimID: number, lookupValues: ILookupDictionary) => dispatch<any>(getClaimsDetailsThunk(claimID, lookupValues))
    })
)(ClaimsPage);

