import { useField } from 'formik';

const CustomInputComponent = ({
    field,
    form: { errors, setFieldValue },
    onChange,
    ...props
}) => {
    const isError = field && errors;

    const handleChange = (event) => {
        const value = event.target.value;
        field && setFieldValue(value);
        onChange && onChange(event);
    };

    return (
        <div className={isError && "validationForkmikField" }>
            <input {...field} {...props} onChange={handleChange} data-testid={field && field.name} type='text'/>
        </div>
    );
};


export const InputFieldFormik = ({ name, onChange, ...props }) => {
    const [field, meta, helpers] = useField(name);
    return (
        <CustomInputComponent
            field={field}
            form={{ errors: meta.error, setFieldValue: helpers.setValue }}
            onChange={onChange}
            {...props}
        />
    );
};
