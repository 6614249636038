import throttle from "lodash.throttle";
import * as React from "react";
import { If } from "../../../../core/components/If";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import {
    hideModal,
    showModal,
    showYesNoPrompt
} from "../../../../redux/reducers/ModalReducer";
import {
    addDroppedFile,
    cleanDroppedFilesRepertoireState,
    setEditableFields, setProgressUploadFileRepertoireProccess
} from "../../../../redux/reducers/MembersPortalReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    EVENT_HANDLER_THROTTLE_TIME, SEARCH_VIEW_DRAFT_SET_LISTS
} from "../../../Consts";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { DataSource } from "../../../../repertoire/types/DataSource";
import MultiStepForm, { Step } from "../../../components/multiStepForm/MultiStepForm";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ITreeData } from "../../../../repertoire/types/ITreeData";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { IDistribution } from "../../../../repertoire/types/usageTypes/IDistribution";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import { CleanUploadFilesState, addFileToUpload, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
// import { addDroppedFile, cleanDroppedFilesRepertoireState, setProgressUploadFileRepertoireProccess } from "../../../../redux/reducers/RepertoireReducer";
import { IPaginationDetail } from "../../../../redux/types/IPaginationDetail";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import LivePerformanceMaintenanceGeneralDataView from "../livePerformanceMaintenanceGeneralDataView/LivePerformanceMaintenanceGeneralDataView";
import LivePerformanceMaintenanceToolbar from "../livePerformanceMaintenanceToolbar/LivePerformanceMaintenanceToolbar";
import { IUsageGroupState, IUsageGroupStateKeys } from "../../../../redux/types/IUsageGroupState";
import { IUsageGroupSetListAdditionalStateKeys } from "../../../../repertoire/types/usageTypes/IUsageGroupSetListAdditional";
import { IUsageGroupVenueDetailsStateKeys } from "../../../../repertoire/types/usageTypes/IUsageGroupVenueDetails";
import LivePerformanceMultiStepForm from "../../../components/multiStepForm/LivePerformanceMultiStepForm";
import { UsageDetailsMapper } from "../../../../repertoire/services/usageServices/UsageDetailsMapper";

export interface ILivePerformanceMaintenancePageProps {
    livePerformanceMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem;
    livePerformanceMaintenanceToolbarData?: IMembersPortalComponentDataItem;
    draftWorkMaintenanceSaveResultData?: IMembersPortalComponentDataItem;
    livePerformanceMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    usage?: IUsageGroupState;
    activeTab: number;
    tabs: ITabReduxItem[];
    dataSource: DataSource;
    lookupValues?: ILookupDictionary;
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    resetMessageBanner: () => void;
    addNewDraftLivePerformance: (lookupValues: ILookupDictionary, isNew?: boolean, livePerfromanceMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem) => void;
    updateLivePerformanceField?: (value: any, fieldName: IUsageGroupStateKeys, index?: number) => void;
    updateLivePerformanceSetListAdditionalField: (value: any, fieldName: IUsageGroupSetListAdditionalStateKeys, index?: number) => void;
    updateLivePerformanceVenueDetailsField: (value: any, fieldName: IUsageGroupVenueDetailsStateKeys, index?: number) => void;
    saveChanges: (usage: IUsageGroupState, livePerformanceMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem, lookupValues: ILookupDictionary) => void;
    getDraftWorkDetails: (dataSource: string, draftWorksID: number, lookupValues: ILookupDictionary, draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem) => void;
    showModal: typeof showModal;
    clearModalSearchResults: () => void;
    copyExistingDraftLivePerformance: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => void;
    incrementTempID?: () => void;
    containerDetailsWindowComponentData: IComponentDataItem;
    distributions: IDistribution[];
    formats: ILookupInstance[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    attachedFiles: IStoragePathItemData[];
    removeWorkAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => void;
    workAttachmentsDropped?: IDroppedFiles;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire: typeof setProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire: typeof cleanDroppedFilesRepertoireState;
    paginationDetails: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    sortColumn: typeof sortColumn;
    getDataIngestionComponentData?: () => void;
    getFilesystems?: (isRepertoireModule?: boolean) => void;
    refreshWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], activeTabItem: ITabReduxItem, readonlyIndicatorsWorkFlagTypes: string[], formats?: FormatFields[]) => void;
    stepsCurrent?: number;
    stepsSetCurrent?: (current: number) => void;
    currentUser: string;
    portalType: string;
    currentUserNameNumber: string;
    updatedScroll?: number;
}


interface IILivePerformanceMaintenancePagePropsState {
    toolbarWidth: number;
    isBulkCheckboxActive?: boolean;
    loading:boolean;
}

export class LivePerformanceMaintenancePage extends React.PureComponent<
    ILivePerformanceMaintenancePageProps,
    IILivePerformanceMaintenancePagePropsState
> {
    toolbarParentRef;
    constructor(props: ILivePerformanceMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            isBulkCheckboxActive: false,
            loading:true
        };

        this.toolbarParentRef = React.createRef();
    }

    componentDidMount() {
        const {
            getFilesystems,
            getDataIngestionComponentData,
            containerDetailsWindowComponentData } = this.props;
        if (!containerDetailsWindowComponentData || !containerDetailsWindowComponentData.fields || containerDetailsWindowComponentData.fields.length === 0) {
            getDataIngestionComponentData();
            if(containerDetailsWindowComponentData){
            this.setState({ loading: false });}
        }
        getFilesystems(true);
        CleanUploadFilesState();

    }

    confirmAndSubmit = () => {
        const { saveChanges, usage, livePerformanceMaintenanceGeneralDataViewData, lookupValues } = this.props;
        let draftLivePerformance: IUsageGroupState = JSON.parse(JSON.stringify(usage));
        draftLivePerformance.approvalStatus = 'Pending';
        draftLivePerformance.usages.forEach(x => {
            x.weight.value = '1';
            x.usageCount.value = '1'
        });
        draftLivePerformance.totalWeight = 1 + usage.moreDates.length;
        draftLivePerformance.fullTitle = (draftLivePerformance.matchingProducts != null && draftLivePerformance.matchingProducts.length > 0) ?
            `${draftLivePerformance.matchingProducts[0].title[0]} (${draftLivePerformance.usages[0]?.usageDate?.value?.split('-').reverse().join('/')})` : '';
        saveChanges(draftLivePerformance, livePerformanceMaintenanceGeneralDataViewData, lookupValues); 
        this.setState({ isBulkCheckboxActive: false });
    }

    saveAsDraftLivePerformance = () => {
        const { saveChanges, usage, livePerformanceMaintenanceGeneralDataViewData, lookupValues } = this.props;
        let draftLivePerformance: IUsageGroupState = JSON.parse(JSON.stringify(usage));
        draftLivePerformance.usages.forEach(x => {
            x.weight.value = '1';
            x.usageCount.value = '1'
        });
        draftLivePerformance.totalWeight = 1 + usage.moreDates.length;
        draftLivePerformance.fullTitle = (draftLivePerformance.matchingProducts != null && draftLivePerformance.matchingProducts.length > 0) ?
            `${draftLivePerformance.matchingProducts[0].title[0]} (${draftLivePerformance.usages[0]?.usageDate?.value?.split('-').reverse().join('/')})` : '';
        saveChanges(draftLivePerformance, livePerformanceMaintenanceGeneralDataViewData, lookupValues);
        this.setState({ isBulkCheckboxActive: false });
    }

    undoChanges = () => {
        //const { dataSource, usage, undoDraftWorks, lookupValues } = this.props;
        //undoDraftWorks(dataSource, usage.id, lookupValues);
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }



    refreshWorkDetails() {
        const { tabs, activeTab, lookupValues, dataSource } = this.props;
        let currentTab = tabs[activeTab];
        this.props.refreshWorkDetails(
            dataSource,
            currentTab.workMaintenanceState.work.workID,
            lookupValues,
            tabs[activeTab].otherIndicatorWorkFlagTypes,
            currentTab,
            tabs[activeTab].readonlyIndicatorWorkFlagTypes
        )
    }

    render() {
        const { containerDetailsWindowComponentData, currentUserNameNumber } = this.props;
        let usage = this.props.usage ?? UsageDetailsMapper.createNewUsageState(this.props.lookupValues, null, parseInt(currentUserNameNumber));
        if (usage) {
            const {
                tabs,
                activeTab,
                livePerformanceMaintenanceGeneralDataViewData,
                livePerformanceMaintenanceToolbarData,
                draftWorkMaintenanceSaveResultData,
                livePerformanceMaintenanceGridsViewData,
                hideModal,
                showYesNoPrompt,
                addNewDraftLivePerformance,
                updateLivePerformanceField,
                dataGridTableData,
                showModal,
                copyExistingDraftLivePerformance,
                incrementTempID,
                dataSource,
                stepsCurrent,
                stepsSetCurrent,
                currentUser,
                addFileToUpload,
                cleanUploadFilesState,
                destinations,
                distributions,
                droppedFiles,
                isDropZone,
                selectedFileSystem,
                setInDropZone,
                setProgressUploadFileProccess,
                sortColumn,
                storagePathItemData,
                attachedFiles,
                refreshDirectory,
                addRepertoireAttachmentToState,
                removeWorkAttachmentIfExists,
                addDroppedFileRepertoire,
                workAttachmentsDropped,
                cleanDroppedFilesRepertoire,
                setProgressUploadFileRepertoire,
                paginationDetails,
                updatePagination,
                refreshWorkDetails,
                getDataIngestionComponentData,
            } = this.props;

        const { fullPerformer, dateLoaded } = usage;

        let isNewLivePerformance: boolean = this.props.usage?.id ? true : false;
            let { lookupValues } = this.props;

            const title = dateLoaded + "-" + fullPerformer;
            const changesMade = tabs[activeTab].changesMade || false;
            const isReadonly: boolean = tabs[activeTab].isReadonly;

            let readOnlyDataGridTableData = JSON.parse(JSON.stringify(dataGridTableData));

            //TODO extract steps into separate files to clean up this component
            let steps: Step[] =
                [
                    {
                        title: 'Performance Details',
                        content: <LivePerformanceMaintenanceGeneralDataView
                            livePerformanceMaintenanceGeneralDataViewData={livePerformanceMaintenanceGeneralDataViewData}
                            usage={usage}
                            isReadonly={isReadonly}
                            lookupValues={lookupValues}
                            updateLivePerformanceField={updateLivePerformanceField}
                            showModal={showModal}
                            hideModal={hideModal}
                            isNewLivePerformance={isNewLivePerformance}
                            activeTab={activeTab}
                            tabs={tabs}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            incrementTempID={incrementTempID}
                            editableFields={[]}
                            changesMade={changesMade}
                            setEditableFields={setEditableFields}
                            activeHeaderSection={tabs[activeTab].activeHeaderSection}
                            dataGridTableData={dataGridTableData}
                            livePerformanceMaintenanceGridsViewData={livePerformanceMaintenanceGridsViewData} 
                            addFileToUpload={addFileToUpload} 
                            cleanUploadFilesState={cleanUploadFilesState} 
                            containerDetailsWindowComponentData={containerDetailsWindowComponentData} 
                            destinations={destinations} 
                            distributions={distributions} 
                            droppedFiles={droppedFiles} 
                            isDropZone={isDropZone} 
                            selectedFileSystem={selectedFileSystem} 
                            setInDropZone={setInDropZone} 
                            setProgressUploadFileProccess={setProgressUploadFileProccess} 
                            sortColumn={sortColumn} 
                            storagePathItemData={storagePathItemData} 
                            attachedFiles={attachedFiles} 
                            refreshDirectory={refreshDirectory} 
                            paginationDetails={paginationDetails} 
                            updatePagination={updatePagination} 
                            addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                            removeWorkAttachmentIfExists={removeWorkAttachmentIfExists}
                            cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                            setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                            refreshWorkDetails={refreshWorkDetails}
                            dataSource={dataSource}   
                            getDataIngestionComponentData={getDataIngestionComponentData}   
                            addDroppedFileRepertoire={addDroppedFileRepertoire}
                            currentUserNameNumber={currentUserNameNumber}
                        />
                    },
                    {
                        title: 'Submission',
                        content: <div>
                            <div className="">
                                <h3>General</h3>
                                <LivePerformanceMaintenanceGeneralDataView
                                    livePerformanceMaintenanceGeneralDataViewData={livePerformanceMaintenanceGeneralDataViewData}
                                    usage={usage}
                                    isReadonly={true}
                                    lookupValues={lookupValues}
                                    updateLivePerformanceField={updateLivePerformanceField}
                                    showModal={showModal}
                                    hideModal={hideModal}
                                    isNewLivePerformance={isNewLivePerformance}
                                    activeTab={activeTab}
                                    tabs={tabs}
                                    resetMessageBanner={this.resetMessageBanner.bind(this)}
                                    incrementTempID={incrementTempID}
                                    editableFields={[]}
                                    changesMade={changesMade}
                                    setEditableFields={setEditableFields}
                                    activeHeaderSection={tabs[activeTab].activeHeaderSection}
                                    dataGridTableData={readOnlyDataGridTableData}
                                    livePerformanceMaintenanceGridsViewData={livePerformanceMaintenanceGridsViewData}
                                    addFileToUpload={addFileToUpload}
                                    cleanUploadFilesState={cleanUploadFilesState}
                                    containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                                    destinations={destinations}
                                    distributions={distributions}
                                    droppedFiles={droppedFiles}
                                    isDropZone={isDropZone}
                                    selectedFileSystem={selectedFileSystem}
                                    setInDropZone={setInDropZone}
                                    setProgressUploadFileProccess={setProgressUploadFileProccess}
                                    sortColumn={sortColumn}
                                    storagePathItemData={storagePathItemData}
                                    attachedFiles={attachedFiles}
                                    refreshDirectory={refreshDirectory}
                                    paginationDetails={paginationDetails}
                                    updatePagination={updatePagination} 
                                    addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                                    removeWorkAttachmentIfExists={removeWorkAttachmentIfExists}
                                    cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                                    setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                                    refreshWorkDetails={refreshWorkDetails}
                                    dataSource={dataSource}
                                    getDataIngestionComponentData={getDataIngestionComponentData}
                                    currentUserNameNumber={currentUserNameNumber}
                                />
                            </div>
                        </div>
                    }
                ];

            if (dataSource !== DataSource.Intray) {
                steps = steps.filter(s => s.title === 'Submission')
            }

            let zeroedStepsCurrent = stepsCurrent ?? 0;
            const {updatedScroll} = this.props;
            const {
                toolbarWidth
            } = this.state;
            return (
                <div className="">
                    {/* <div><span className="title">{title}</span></div> */}
                    <If condition={tabs[activeTab].versionNumber != null}>
                        <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                    </If>

                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>

                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <LivePerformanceMaintenanceToolbar
                            changesMade={changesMade}
                            showYesNoPrompt={showYesNoPrompt}
                            showModal={showModal}
                            hideModal={hideModal}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            toolbarData={livePerformanceMaintenanceToolbarData}
                            saveResultData={draftWorkMaintenanceSaveResultData}
                            usage={usage}
                            activeTab={activeTab}
                            tabs={tabs}
                            dataSource={dataSource}
                            saveChanges={this.saveAsDraftLivePerformance.bind(this)}
                            isReadonly={isReadonly}
                            undoDraftWorksChanges={this.undoChanges.bind(this)}
                            addNewDraftWorks={() => addNewDraftLivePerformance(lookupValues, true, livePerformanceMaintenanceGeneralDataViewData)}
                            isNew={isNewLivePerformance}
                            lookupValues={lookupValues}
                            copyExistingDraftLivePerformance={copyExistingDraftLivePerformance}
                            currentUser={currentUser}
                        />
                    </div>

                    <LivePerformanceMultiStepForm
                        steps={steps}
                        readonly={false}
                        saveChanges={null}
                        confirmAndSubmit={this.confirmAndSubmit.bind(this)}
                        dataSource={dataSource}
                        current={zeroedStepsCurrent}
                        setCurrent={stepsSetCurrent}
                        usage={usage}
                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                        />
                </div>
            );
    }
}}
