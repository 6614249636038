import React from "react";

const PositiveFeedBackUnpaidClaimsAlert = ({
    text
}) => {
    const [isOpen, setIsOpen] = React.useState(true)
    const handleCloseAlert = () => {
        setIsOpen(false);
    }

       return isOpen ?  <div className="col-md-12 alert alert-dismissible fade in alert-success">
                            <div>
                                <a className="close" aria-label="close" onClick={handleCloseAlert}>&times;</a>
                                {text}
                            </div>
                        </div> : <></>;
}

export default PositiveFeedBackUnpaidClaimsAlert;