import React from "react";
import { ContainerDetailsWindow, IContainerDetailsWindowProps } from "./ContainerDetailsWindow";
import throttle from 'lodash.throttle';
import { EVENT_HANDLER_THROTTLE_TIME } from "../../../repertoire/Consts";
import { LivePerformanceContainerDetailsWindow } from "./LivePerformanceContainerDetailsWindow";

interface ILivePerformanceProps {
    isLivePerformance?: boolean
}

type IScrollEventListenerWrapperProps = IContainerDetailsWindowProps & ILivePerformanceProps;

const ScrollEventListenerWrapperContainerDetailsWindow: React.FC<IScrollEventListenerWrapperProps> = (props) => {

    const [updatedScroll, setUpdatedScroll] = React.useState(0);

    React.useEffect(() => {

        window.addEventListener(
            "scroll",
            throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                trailing: true
            })
        );

        return  window.removeEventListener(
            "scroll",
            throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                leading: true
            })
        );

    }, []);

    const handleScroll = () => {
        setUpdatedScroll(window.scrollY);
    };



    return props.isLivePerformance ? <LivePerformanceContainerDetailsWindow {...props} updatedScroll={updatedScroll} /> : <ContainerDetailsWindow
    {...props}
    updatedScroll={updatedScroll}
    /> 
    
}
export default ScrollEventListenerWrapperContainerDetailsWindow;