import React, { PureComponent, FunctionComponent } from "react";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";
import { EMPTY_STRING_VALUE, REPERTOIRE_PAGE_FORM_ITEM_SIZE } from "../../Consts";

export interface ISimpleTimeDataInputProps {
    label: string;
    value: any;
    changeData: (
        value: string | boolean,
        fieldName: IRepertoireStateKeys
    ) => void;
    fieldName: IRepertoireStateKeys;
    readonly?: boolean;
    isHidden?: boolean;
    inline?: boolean;
    isMandatory?:boolean;
}

export const SizedCheckboxDataInput: FunctionComponent<ISimpleTimeDataInputProps> = (props: ISimpleTimeDataInputProps) => {

    if (props.isHidden)
        return null;

    return <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
        <SimpleTimeDataInput {...props} />
    </div>
}

export default class SimpleTimeDataInput extends PureComponent<
ISimpleTimeDataInputProps
> {
    constructor(props) {
        super(props);
    }

    render() {
        const { label, changeData, value, fieldName, readonly, isHidden, isMandatory } = this.props;
        if (isHidden)
            return null;
        return (
            <div className="timeInputContainer">
                {label ? <label className={EMPTY_STRING_VALUE.concat(isMandatory ? " isMandatory " : "")}>{label}</label> : null}
                <label className="timeInputSubContainer" htmlFor="appt"> 
                {readonly ?
                    <input 
                    id="appt" 
                    name="appt"
                    className="timeInput"
                    type="time"
                    onChange={event => changeData(event.target.value, fieldName)}
                    value={value || false}
                    disabled={readonly}
                    data-testid={fieldName}
                />  :
                     <input 
                    id="appt" 
                    name="appt"
                    className="timeInput"
                    type="time"
                    onChange={event => changeData(event.target.value, fieldName)}
                    value={value || false}
                    data-testid={fieldName}
                /> 
                }                                 
                </label>
            </div>
        );
    }
}
