import { connect } from "react-redux";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showMessage, showModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import {
    mp_addTab,

    incrementTempID,
    resetMessageBanner,
    mp_setChangesMade,
    setWarningMessage,
    addRepertoireAttachmentSuccess,
    setProgressUploadFileRepertoireProccess,
    addDroppedFile,
    cleanDroppedFilesRepertoireState,
    registerStepsSetCurrent,
    updateLivePerformanceField,
    updateLivePerformanceSetListAdditionalField,
    updateLivePerformanceVenueDetailsField,
    copyExistingDraftLivePerformance,
    createNewDraftLivePerformance
} from "../../../../redux/reducers/MembersPortalReducer";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import {
    saveDraftWorkThunk, deleteDraftWorkThunk, undoDraftWorkChangesThunk, getDraftWorkDetailsThunk, removeWorkAttachmentIfExistsThunk, saveDraftLivePerformanceThunk, searchDraftSetListsThunk
} from "../../../../redux/thunks/MembersPortalThunks";
import IAppState from "../../../../redux/types/IAppState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IPopUpInfoProps } from "../../../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import { IYesNoPromptViewModalProps } from "../../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../../../redux/types/IResultsPerPage";
import { FormatFields } from "../../../../redux/types/FormatFields";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { refreshWorkDetailsThunk, updateUserPreferencesThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { getDataIngestionComponentDataThunk, getFilesystemsThunk, refreshDirectoryThunk, selectedNodeChangedThunk } from "../../../../redux/thunks/DataIngestionThunks";
import IFile from "../../../../redux/types/IFiles";
import { ITreeData } from "../../../../repertoire/types/ITreeData";
import { ITreeNodeData } from "../../../../treeview/types/TreeNodeData";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { LivePerformanceMaintenancePage } from "./LivePerformanceMaintenancePage";
import { IUsageGroupState, IUsageGroupStateKeys } from "../../../../redux/types/IUsageGroupState";
import { IUsageGroupSetListAdditionalStateKeys } from "../../../../repertoire/types/usageTypes/IUsageGroupSetListAdditional";
import { IUsageGroupVenueDetailsStateKeys } from "../../../../repertoire/types/usageTypes/IUsageGroupVenueDetails";
import { IDraftSetListSearchQuery } from "../../../types/IDraftSetListSearchQuery";
import LivePerformanceMaintenancePageScrollListener from "./LivePerformanceMaintenanceScrollListener";

export default connect(
    (state: IAppState) => ({
        livePerformanceMaintenanceGeneralDataViewData: state.membersPortal.componentData.LivePerformanceMaintenanceGeneralDataView,
        livePerformanceMaintenanceToolbarData:
            state.membersPortal.componentData.LivePerformanceMaintenanceToolbar,
        draftWorkMaintenanceSaveResultData: state.membersPortal.componentData.SaveResult,
        dataGridTableData: state.membersPortal.componentData.DataGridTable,
        livePerformanceMaintenanceGridsViewData: state.membersPortal.componentData.LivePerformanceMaintenanceGridsViewData,
        usage: (state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState.usageGroup) ||
            (state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState.usageGroup),
        activeTab: state.membersPortal.activeTab,
        tabs: state.membersPortal.tabs,
        lookupValues: state.lookupEntities,
        dataSource: state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState!.usageGroup && state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState!.dataSource,
        filesystems: state.dataIngestion.filesystems,
        treeNodes: state.dataIngestion.treeNodes,
        selectedNode: state.dataIngestion.selectedNode,
        attachedFiles: state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState!.usageGroup && state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState!.usageGroup.files,
        workAttachmentsDropped: state.membersPortal.tabs && state.membersPortal.tabs[state.membersPortal.activeTab] && state.membersPortal.tabs[state.membersPortal.activeTab].droppedFiles,
        storagePaths: state.dataIngestion.storagePaths,
        selectedFileSystem: state.dataIngestion.selectedFileSystem,
        destinations: state.dataIngestion.destinations && state.dataIngestion.destinations.lookups,
        droppedFiles: state.dataIngestion.droppedFiles,
        isDropZone: state.dataIngestion.droppedFiles.inDropZone,
        storagePathItemData: state.dataIngestion.componentData.StoragePathItem,
        distributions: state.dataIngestion.distributions,
        containerDetailsWindowComponentData: state.dataIngestion.componentData.ContainerDetailsWindow,
        formats: state.dataIngestion.formats,
        paginationDetails: state.dataIngestion.paginationDetails,
        stepsCurrent: state.membersPortal.tabs[state.membersPortal.activeTab].livePerformanceMaintenanceState && state.membersPortal.tabs[state.membersPortal.activeTab].registerStepsCurrent,
        currentUser: state.account.currentUser,
        draftSetListsSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].draftSetListSearchResult,
        registeredSetListsSearchResults: state.membersPortal.tabs[state.membersPortal.activeTab] === undefined ? undefined : state.membersPortal.tabs[state.membersPortal.activeTab].registeredSetListsSearchResult,
        portalType: state.account.portalType,
        currentUserNameNumber: state.account.currentUserNameNumber
    }),
    dispatch => ({
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        hideModal: () => dispatch(hideModal()),
        searchDraftSetLists: (query: IDraftSetListSearchQuery) =>
            dispatch<any>(searchDraftSetListsThunk(query)),
        updateLivePerformanceField: (value: any, fieldName: IUsageGroupStateKeys, index?: number) =>
            dispatch(updateLivePerformanceField(value, fieldName, index)),
        updateLivePerformanceSetListAdditionalField: (value: any, fieldName: IUsageGroupSetListAdditionalStateKeys, index?: number) =>
            dispatch(updateLivePerformanceSetListAdditionalField(value, fieldName, index)),
        updateLivePerformanceVenueDetailsField: (value: any, fieldName: IUsageGroupVenueDetailsStateKeys, index?: number) =>
            dispatch(updateLivePerformanceVenueDetailsField(value, fieldName, index)),
        saveChanges: (usage: IUsageGroupState) =>
            dispatch<any>(saveDraftLivePerformanceThunk(usage)),
        addNewDraftLivePerformance: (lookups: ILookupDictionary, isNew?: boolean, livePerfromanceMaintenanceGeneralDataViewData?: IMembersPortalComponentDataItem, formats?: FormatFields[]) =>
            dispatch(createNewDraftLivePerformance(lookups, isNew, livePerfromanceMaintenanceGeneralDataViewData, formats)),
        showModal: (modalContent: string, modalComponentInstance: string, modalProps: any, displayModalCloseButton: boolean, title: string, modalMessage?: string, isReadOnly?: boolean) =>
            dispatch<any>(showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title, modalMessage, isReadOnly)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        copyExistingDraftLivePerformance: (actionList: IDataActionToolbar[], userName: string, lookups: ILookupDictionary) => dispatch<any>(copyExistingDraftLivePerformance(actionList, userName, lookups)),
        updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => dispatch<any>(updateUserPreferencesThunk(allResultsPerPage, activeAccordions, newActiveAccordionName, accordionExpanded, componentName, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection)),
        showMessage: (props: IPopUpInfoProps) => dispatch(showMessage(props)),
        setChangesMade: (tabIndex: number, changesMade: boolean) => dispatch(mp_setChangesMade(tabIndex, changesMade)),
        addTab: (tab: ITabReduxItem) => dispatch(mp_addTab(tab)),
        sortColumn: (section: string, ascending: boolean) => dispatch<any>(sortColumn(section, ascending)),
        setWarningMessage: (displayWarning: boolean) => dispatch(setWarningMessage(displayWarning)),
        undoDraftWorks: (dataSource: string, workId: number, lookupValues: ILookupDictionary) =>
            dispatch<any>(undoDraftWorkChangesThunk(dataSource, workId, lookupValues)),
        incrementTempID: () => dispatch<any>(incrementTempID()),
        getDraftWorkDetails: (dataSource: string, workID: number, lookupValues: ILookupDictionary, draftWorkMaintenanceDataViewData: IMembersPortalComponentDataItem) =>
            dispatch<any>(getDraftWorkDetailsThunk(dataSource, workID, lookupValues, draftWorkMaintenanceDataViewData)),
        setInDropZone: (inDropZone: boolean) => dispatch(setInDropZone(inDropZone)),
        refreshDirectory: (filesystem: IFileSystem, sources: ITreeData[]) => dispatch<any>(refreshDirectoryThunk(filesystem, sources)),
        addFileToUpload: (file: IFile, isComplete: boolean) => dispatch<any>(addFileToUpload(file, isComplete)),
        setProgressUploadFileProccess: (message: string, file: IFile, isUploadComplete: boolean) => dispatch<any>(setProgressUploadFileProccess(message, file, isUploadComplete)),
        cleanUploadFilesState: () => dispatch(CleanUploadFilesState()),
        refreshWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], activeTabItem: ITabReduxItem, readonlyIndicatorsWorkFlagTypes: string[], formats: FormatFields[]) =>
            dispatch<any>(refreshWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, activeTabItem, formats, readonlyIndicatorsWorkFlagTypes)),
        getFilesystems: (isRepertoireModule?: boolean) => dispatch<any>(getFilesystemsThunk(isRepertoireModule)),
        selectedNodeChanged: (node: ITreeNodeData<IFileSystem>, sources: ITreeData[]) => dispatch<any>(selectedNodeChangedThunk(node, sources)),
        getDataIngestionComponentData: () => dispatch<any>(getDataIngestionComponentDataThunk()),
        addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => dispatch<any>(addRepertoireAttachmentSuccess(attachedFile)),
        removeWorkAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => dispatch<any>(removeWorkAttachmentIfExistsThunk(attachedFile, source)),
        addDroppedFileRepertoire: (file: IFile, inProgress: boolean) => dispatch(addDroppedFile(file, inProgress)),
        setProgressUploadFileRepertoire: (message: string, file: IFile, isUploadComplete: boolean) => dispatch(setProgressUploadFileRepertoireProccess(message, file, isUploadComplete)),
        cleanDroppedFilesRepertoire: () => dispatch(cleanDroppedFilesRepertoireState()),
        updatePagination: (resultsPerPage: number, currentPage: number, section: string) =>
            dispatch<any>(updatePagination(resultsPerPage, currentPage, section)),
        stepsSetCurrent: (current: number) => dispatch(registerStepsSetCurrent(current))
    })
)(LivePerformanceMaintenancePageScrollListener);