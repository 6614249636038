import axios, { Axios, AxiosResponse } from "axios";
import { IMemberStatementSearchResult } from "../types/IMemberStatementSearchResult";
import { IStoragePath } from "../types/IMemberStatementSearchResult";

export class StorageRequests {
    public static async getStatementFiles(statements: IMemberStatementSearchResult[]): Promise<IMemberStatementSearchResult[]> {
        return new Promise<IMemberStatementSearchResult[]>((resolve, reject) => {
            Promise.all(statements.map(setDownloadProperties))
            .then((response: IMemberStatementSearchResult[]) => {
                resolve(response);
            })
            .catch (response => {
                reject(response);
            })
        });
    }
}

var setDownloadProperties = function setDownloadProperties(statement: IMemberStatementSearchResult): Promise<IMemberStatementSearchResult>{
    return new Promise((resolve, reject ) => {
        statement.downloadLink = [];
        statement.downloadName = [];

        axios.all([
            axios.post("/Storage/Blobs", {
                Path: "payments/statements/PaymentRun-" + statement.paymentRunCode + "_CRD",
                IsRepertoireModule: false
            }),
            axios.post("/Storage/Blobs", {
                Path: "payments/statements/PaymentRun-" + statement.paymentRunCode + "_CSV",
                IsRepertoireModule: false
            }),
            axios.post("/Storage/Blobs", {
                Path: "payments/statements/PaymentRun-" + statement.paymentRunCode + "_PDF",
                IsRepertoireModule: false
            })
        ])
        .then(axios.spread((crdResponse: AxiosResponse<IStoragePath[]>, csvResponse: AxiosResponse<IStoragePath[]>, pdfResponse: AxiosResponse<IStoragePath[]>) => {
            crdResponse.data.forEach(function (crdStoragePath) {
                statement.downloadLink?.push(crdStoragePath.fullName)
                statement.downloadName?.push(crdStoragePath.name)
            })

            csvResponse.data.forEach(function (csvStoragePath) {
                statement.downloadLink?.push(csvStoragePath.fullName)
                statement.downloadName?.push(csvStoragePath.name)
            })

            pdfResponse.data.forEach(function (pdfStoragePath) {
                statement.downloadLink?.push(pdfStoragePath.fullName)
                statement.downloadName?.push(pdfStoragePath.name)
            })

            resolve(statement)
        }))
        .catch(response => {
            reject(response);
        })
    });
};


