import * as React from 'react';
import { IStoragePathItemData } from '../types/IStoragePathItemData';
import { Dictionary } from '../../core/types/Dictionary';
import { IDistribution } from '../../repertoire/types/usageTypes/IDistribution';
import { ILookup, ILookupInstance } from '../../lookup/types/ILookup';
import { ISourceMatchType } from '../../settings/types/ISourceMatchType';
import { JobMonitoringService } from "../../job/services/JobMonitoringService";
import { renderJobProgress } from "../../redux/reducers/JobReducer";
import { IJob } from '../../job/types/IJob';
import { IJobState } from '../../redux/types/IJobState';
import { IComponentDataItem } from '../../core/types/IComponentDataItem';
import { CONFIRM_EXIT_FIELD, PAGE_TITLE_FIELD } from './DataIngestionPageFields';
import ContainerDetailsWindowContainer from './containerDetailsWindow/ContainerDetailsWindowContainer';
import { ILookupDictionary } from '../../lookup/types/ILookupDictionary';
import {
    setFileFormats,
    showJobProgress,
    toggleSidebarCollapsed
} from '../../redux/reducers/DataIngestionReducer';
import DataIngestionSideMenuContainer from './dataIngestionSideMenu/DataIngestionSideMenuContainer';
import IDroppedFiles from '../../redux/types/IDroppedFiles';
import { hideModal, showModal, showYesNoPrompt } from '../../redux/reducers/ModalReducer';
import DataIngestionModal from './dataIngestionModal/DataIngestionModal';
import IRepertoireComponentDataItem from '../../redux/types/IRepertoireComponentDataItem';
import { IContributorSearchResult } from "../../repertoire/types/IContributorSearchResult";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import { IP } from "../../repertoire/types/IP";
import { useMsal } from '@azure/msal-react';
import { ComponentsHelper } from '../../core/services/ComponentHelper';
import { msalConfig } from '../../accounts/types/msalAuthConfig';
import { ALL_ROLES, VIEW_DISTRIBUTIONS_ROLE, VIEW_POOLS_ROLE } from '../Consts';
import { IShoppingCartSearchQuery } from '../../repertoire/types/IShoppingCartSearchQuery';
import { IShoppingCartSearchResult } from '../../repertoire/types/IShoppingCartSearchResult';

export interface IDataIngestionPageProps {
    renderJobProgress: typeof renderJobProgress;
    showJobProgress: typeof showJobProgress;
    jobs: IJobState[];
    getDataIngestionFields: () => void;
    dataIngestionPageComponentData: IComponentDataItem;
    getDistributions: () => void;
    initialiseLookupsThunk: (lookups: ILookupDictionary) => void;
    distributions: IDistribution[];
    formats: ILookupInstance[];
    destinations: ILookup;
    lookups: ILookupDictionary;
    sources: Dictionary<ISourceMatchType>;
    storagePaths: IStoragePathItemData[];
    sidebarWrapperIsActive: boolean;
    toggleSidebarCollapsed: typeof toggleSidebarCollapsed;
    droppedFiles: IDroppedFiles;
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    displayModal: boolean;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    modalTitle: string;
    yesNoPromptViewData: IRepertoireComponentDataItem;
    modalMessage: string;
    displayModalCloseButton: boolean;
    contributorsSearchResults: IContributorSearchResult[];
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    searchViewData: IComponentDataItem;
    searchResultsTableData: IComponentDataItem;
    searchSuccessful: boolean;
    searchContributors: (
        query: IContributorSearchQuery
    ) => void;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    isSearching: boolean;
    updateStoragePathItemIP?: (storagePathItemData: IStoragePathItemData, ip: IP) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    getPools?: () => void;
    searchLicenses: (query: IShoppingCartSearchQuery) => void;
    licensesSearchResults: IShoppingCartSearchResult[];
    updateLicenseImportShoppingCart?: (storagePathItemData: IStoragePathItemData, shoppingCart: IShoppingCartSearchResult) => void;
}

export const DataIngestionPage = (props: IDataIngestionPageProps) => {
    const [loaded, setLoaded] = React.useState(false)
    const [viewData, setViewData] = React.useState({})
    const { instance, accounts } = useMsal()

    const prevProps = React.useRef(props);
    

    React.useEffect(() => {
        getRoles();

        JobMonitoringService.stopMonitoringProgress();

        JobMonitoringService.startMonitoringProgress(monitoringCallback);

        const {
            getDataIngestionFields,
            getDistributions,
            initialiseLookupsThunk,
            dataIngestionPageComponentData,
            lookups,
            formats,
            destinations,
        } = props;


        if (!dataIngestionPageComponentData || dataIngestionPageComponentData.fields.length === 0) {
            getDataIngestionFields();
        }

        checkIfLoaded();

        if (!formats || formats.length === 0 || !destinations || destinations.lookups.length === 0) {
            initialiseLookupsThunk(lookups);
        }

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);

        window.addEventListener("beforeunload", confirmExit);

        return () => {
            window.removeEventListener("beforeunload", confirmExit);
            JobMonitoringService.stopMonitoringProgress();
        }
    }, [])

    React.useEffect(() => {
        const { lookups, formats, roles, distributions, getDistributions } = props;
        checkIfLoaded();

        const fileTypes = lookups['FileType'];
        if (fileTypes && !prevProps.current.lookups['FileType']) {
            if (!formats || formats.length === 0)
                setFileFormats(lookups['FileType']);
        }
    }, [props, loaded]);

    React.useEffect(() => {
        const { getDistributions, getPools, roles } = props;
        if (roles.includes(VIEW_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES)) getDistributions();
        if (roles.includes(VIEW_POOLS_ROLE) || roles.includes(ALL_ROLES)) getPools();
    }, [loaded])

    const checkIfLoaded = () => {
        let localViewData = {};
        const { dataIngestionPageComponentData: { fields }, roles } = props;

        if (fields) {
            if (fields && fields.length > 0 && !loaded) {
                if (roles.length > 0) setLoaded(true);
                const header = fields.find(f => f.name === "PageTitle");
                if (header) {
                    document.title = header.data;
                }
            }
        }
        if (!loaded && fields) {
            fields.forEach(item => {
                localViewData[item.name] = item.data;
            });
            setViewData(localViewData);
        }
    }
    const getRoles = () => {
        const { saveRoles } = props;

        let scope =
        {
            scopes: [msalConfig.auth.scope]
        }
        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const monitoringCallback = (jobs: IJob[]): void => {
        const { renderJobProgress, showJobProgress } = props;
        showJobProgress(jobs);
        renderJobProgress(jobs);
    }


    const confirmExit = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        const message = viewData[CONFIRM_EXIT_FIELD] as string;
        e.returnValue = message;
        return message;
    }

    const {
        sidebarWrapperIsActive,
        toggleSidebarCollapsed,
        displayModal,
    } = props;
    let className: string = "sidebarWrapper";

    if (!sidebarWrapperIsActive) {
        className = className + " active";
    }

    if (!loaded)
        return <div className="loading" />

    return (
        <>
            <div className="wrapperPageLateralmenu">
                {/* Nav menu */}
                <nav className={className}>
                    <button type="button" id="sidebarClose"
                        className="navbar-toggle btnClose"
                        onClick={toggleSidebarCollapsed}>
                        <i className="icon ms-Icon ms-Icon--ChromeClose" ></i>Close Options
                    </button>

                    {/* Blob Containers */}
                    <DataIngestionSideMenuContainer />
                    <hr />
                </nav>
                {/* Main window */}
                <ContainerDetailsWindowContainer />
                <a target="_blank" id="downloadLinkDataIngestion"></a>
                {displayModal ? (<DataIngestionModal {...props} />) : null}
            </div>
        </>
    );
}