import * as React from 'react';
import { IMemberStatementPaymentRunWork, IMemberStatementSearchResult } from '../../../types/IMemberStatementSearchResult';
import IMembersPortalComponentDataItem from '../../../../redux/types/IMembersPortalComponentDataItem';
import { mp_addTab } from '../../../../redux/reducers/MembersPortalReducer';
import { MemberStatementUsageDetailsGrid } from '../statementDetailsGrid/MemberStatementUsageDetailsGrid';
import { IMemberStatementUsageDetails } from '../../../../redux/types/IMemberStatementUsageDetails';

export interface IMemberStatementUsageDetailsPageProps {
    usageDetails?: IMemberStatementUsageDetails;
    usageDetailsPageComponentData: IMembersPortalComponentDataItem;
    usageDetailsGridsComponentData: IMembersPortalComponentDataItem;
    addTab: typeof mp_addTab;
}

export const MemberStatementUsageDetailsPage: React.FC<IMemberStatementUsageDetailsPageProps> = (props: IMemberStatementUsageDetailsPageProps) => {
    React.useEffect(() => {
        const {
            usageDetailsPageComponentData: { fields }
        } = props;
        let data = {}
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);

            visibleFields.forEach(item => {
                data[item.name] = item.data;

                if (Object.keys(data).length === visibleFields.length) {
                    setLoaded(true);
                    setViewData(data);
                }
            });
        }
    }, [])

    const [loaded, setLoaded] = React.useState(true);
    const [viewData, setViewData] = React.useState({});

    if (props.usageDetails) {
        const { detail, paymentCode, description } = props.usageDetails

        return <div>
            <div><span className="title">Usage Details {detail.workNumber}</span></div>
            <div><span className='subtitle'>Payment {paymentCode}</span></div>
            <div><span>{description}</span></div>
            <br />
            <div className='dataRow'>
                <div>
                    <h3>{viewData['amount']}</h3>
                    <div>{detail.amount}</div>
                </div>
                <div>
                    <h3>{viewData['distributionCode']}</h3>
                    <div>{detail.distributionCode}</div>
                </div>
                <div>
                    <h3>{viewData['poolCode']}</h3>
                    <div>{detail.poolCode}</div>
                </div>
                <div>
                    <h3>{viewData['workNumber']}</h3>
                    <div>{detail.workNumber}</div>
                </div>
            </div>

            <div>
                <MemberStatementUsageDetailsGrid
                    gridDetails={props.usageDetails.detail}
                    usageDetailsGridsComponentData={props.usageDetailsGridsComponentData}
                />
            </div>
        </div>
    }
    else
        return <div></div>

}