import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../redux/types/IAppState";
import { IJob } from "../../job/types/IJob";
import { renderJobProgress } from "../../redux/reducers/JobReducer";
import { DataIngestionPage } from "./DataIngestionPage";
import {
    getDataIngestionComponentDataThunk,
    getDistributionsThunk,
    getPoolsThunk,
    initialiseLookupsThunk,
    searchRightHoldersThunk, 
    searchLicensesThunk
} from "../../redux/thunks/DataIngestionThunks";
import {
    showJobProgress,
    toggleSidebarCollapsed,
    updateLicenseImportShoppingCart,
    updateStoragePathItemIP
} from "../../redux/reducers/DataIngestionReducer";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { showModal, showYesNoPrompt } from "../../redux/reducers/ModalReducer";
import { IYesNoPromptViewModalProps } from "../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { clearModalSearchResultsThunk } from "../../redux/thunks/ModalThunks";
import { IContributorSearchQuery } from "../../repertoire/types/IContributorSearchQuery";
import {
    resetPagination, updatePagination
} from "../../redux/reducers/RepertoireReducer";
import { IP } from "../../repertoire/types/IP";
import { IStoragePathItemData } from '../../dataingestion/types/IStoragePathItemData';
import { saveRoles } from "../../redux/reducers/AccountReducer";
import { IShoppingCartSearchQuery } from "../../repertoire/types/IShoppingCartSearchQuery";
import { IShoppingCartSearchResult } from "../../repertoire/types/IShoppingCartSearchResult";

export default connect(
    (state: IAppState) => (
        {
            jobs: state.job && state.job.jobs,
            dataIngestionPageComponentData: state.dataIngestion.componentData.DataIngestionPage,
            distributions: state.dataIngestion.distributions,
            formats: state.dataIngestion.formats,
            destinations: state.dataIngestion.destinations,
            lookups: state.lookupEntities,
            sidebarWrapperIsActive: state.dataIngestion.sidebarWrapperIsActive,
            displayModal: state.modal.displayModal,
            modalContent: state.modal.modalContent,
            modalProps: state.modal.modalProps,
            modalComponentInstance: state.modal.modalComponentInstance,
            modalTitle: state.modal.title,
            displayModalCloseButton: state.modal.displayModalCloseButton,    
            yesNoPromptViewData: state.dataIngestion.componentData.YesNoPromptView,
            modalMessage: state.modal.modalMessage,
            searchViewData: state.dataIngestion.componentData.SearchView,
            searchResultsTableData: state.dataIngestion.componentData.SearchResultsTable,
            contributorsSearchResults: state.dataIngestion.contributorsSearchResult,
            searchSuccessful: state.dataIngestion.searchSuccessful,
            indexOfFirstResult: state.dataIngestion.indexOfFirstResult,
            indexOfLastResult: state.dataIngestion.indexOfLastResult,
            resultsPerPage: state.dataIngestion.resultsPerPage,
            currentPage: state.dataIngestion.currentPage,
            roles: state.account.roles,
            licensesSearchResults: state.dataIngestion.licensesSearchResult,
        }),
    (dispatch: Dispatch) => ({
        renderJobProgress: (jobs: IJob[]) => dispatch(renderJobProgress(jobs)),
        getDataIngestionFields: () => dispatch<any>(getDataIngestionComponentDataThunk()),
        getDistributions: () => dispatch<any>(getDistributionsThunk()),
        initialiseLookupsThunk: (lookups: ILookupDictionary) => dispatch<any>(initialiseLookupsThunk(lookups)),
        showJobProgress: (jobs: IJob[]) => dispatch(showJobProgress(jobs)),
        toggleSidebarCollapsed: () => dispatch(toggleSidebarCollapsed()),
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch<any>(clearModalSearchResultsThunk()),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        searchContributors: (query: IContributorSearchQuery) => dispatch<any>(searchRightHoldersThunk(query)),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        updateStoragePathItemIP: (storagePathItemData: IStoragePathItemData, ip: IP) => dispatch<any>(updateStoragePathItemIP(storagePathItemData, ip)),
        saveRoles: (roles: string[]) => dispatch<any>(saveRoles(roles)),
        getPools: () => dispatch<any>(getPoolsThunk()),
        searchLicenses: (query: IShoppingCartSearchQuery) => dispatch<any>(searchLicensesThunk(query)),
        updateLicenseImportShoppingCart: (storagePathItemData: IStoragePathItemData, shoppingCart: IShoppingCartSearchResult) => dispatch<any>(updateLicenseImportShoppingCart(storagePathItemData,shoppingCart))

    })
)(DataIngestionPage);