import { Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { createNewRepresentation } from "../reducers/RepertoireReducer";
import IAppState from "../types/IAppState";
import IRepertoireComponentDataItem from "../types/IRepertoireComponentDataItem";

export const newRepresentationThunk = (ipBaseNumber: string, lookups: ILookupDictionary, isNew?: boolean, representationMaintenanceGeneralDataView?: IRepertoireComponentDataItem): ThunkAction<
    void,
    IAppState,
    null,
    Action<string>
> => {
    return (dispatch: Dispatch) => {
        dispatch(createNewRepresentation(ipBaseNumber, lookups, isNew, representationMaintenanceGeneralDataView));
    };
};