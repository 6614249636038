import * as React from "react";
import { AV_SUBMISSION_STATUS } from "../../../lookup/Consts";
import { getLookupValues } from "../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { updateProductField } from "../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import { SizedActionButton } from "../../components/actionButton/ActionButton";
import { SizedCheckboxDataInput } from "../../components/checkboxDataInput/CheckboxDataInput";
import DataGridTable from "../../components/dataGridTable/DataGridTable";
import SizedDateDataInput from "../../components/dateDataInput/DateDataInput";
import { SizedDropdownDataInput } from "../../components/dropdownDataInput/DropdownDataInput";
import { SizedTextDataInput } from "../../components/textDataInput/TextDataInput";
import {
    AVSUBMISSION_AV_STATUS_STATE_KEY,
    AVSUBMISSION_RECEIVED_DATE_STATE_KEY,
    AVSUBMISSION_REQUEST_BATCH_ID_STATE_KEY,
    AVSUBMISSION_REQUEST_DATE_STATE_KEY,
    AVSUBMISSION_REQUIRED_BY_DATE_STATE_KEY,
    AVSUBMISSION_SUBMISSION_STATE_KEY,
    AVSUBMISSION_URGENT_STATE_KEY,
    PRODUCT_AV_REQUEST_KEY,
    REQUEST_ACTION
} from "../../Consts";
import { IProductAVRequest } from "../../types/IProductAVRequest";
import { IProductAVWork } from "../../types/IProductAVWork";
import { IProductAVWorkSubmission } from "../../types/IProductAVWorkSubmission";
import { IProductMaintenanceSubmissionConfig } from "../../types/IProductMaintenanceSubmissionConfig";
import { IRepertoireStateKeys } from "../../types/IRepertoireStateKeys";

export interface IAVSubmissionDetailsAccordianProps {
    avSubmissionDetailsAccordianViewData: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    isReadonly: boolean;
    productAVWorkSubmissions?: IProductAVWorkSubmission[];
    productAVRequest: IProductAVRequest;
    updateProductField: typeof updateProductField;
    tabs: ITabReduxItem[];
    activeTab: number;
    sortData: (value: any, name: IRepertoireStateKeys) => void;
    productCoreID: number;
    submitProductAVRequest: (productAVRequest: IProductAVRequest, productCoreID: number) => IProductAVWorkSubmission[];
    productMaintenanceSubmissionConfig: IProductMaintenanceSubmissionConfig;
    incrementTempID?: () => void;
}

export interface IAVSubmissionDetailsAccordianState {
    loaded: boolean;
}

export default class AVSubmissionDetailsAccordian extends React.Component<IAVSubmissionDetailsAccordianProps, IAVSubmissionDetailsAccordianState>{
    viewData;
    constructor(props: IAVSubmissionDetailsAccordianProps) {
        super(props);

        this.state = { loaded: false };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            avSubmissionDetailsAccordianViewData: { fields }
        } = this.props;

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    getAVRequestDetails(): IProductAVRequest {
        const { productAVRequest, productMaintenanceSubmissionConfig } = this.props;
        if (productAVRequest) {
            return productAVRequest;
        }
        else {
            var requiredBy: string | Date = new Date();
            if (productMaintenanceSubmissionConfig) {
                requiredBy.setDate(requiredBy.getDate() + productMaintenanceSubmissionConfig.RequiredByDays);
            }
            requiredBy = requiredBy.toISOString();

            return {
                submissionDB: 'CisNet',
                submissionType: 'AVRequest',
                avStatus: 'Queued',
                requestDate: new Date().toISOString(),
                requiredByDate: requiredBy
            } as IProductAVRequest;
        }
    }


    render() {
        if (!this.state.loaded)
            return null;

        const {
            lookupValues,
            isReadonly,
            productAVWorkSubmissions,
            avSubmissionDetailsAccordianViewData,
            activeTab,
            tabs,
            sortData,
            dataGridTableData,
            productCoreID,
            submitProductAVRequest,
            incrementTempID,
        } = this.props;

        const avRequestDetails = this.getAVRequestDetails();

        const {
            submissionID,
            requestBatchID,
            avStatus,
            requestDate,
            requiredByDate,
            urgent
        } = avRequestDetails;

        const changeData = (value: any, name: IRepertoireStateKeys) => {
            avRequestDetails[name] = value;
            this.props.updateProductField(avRequestDetails, PRODUCT_AV_REQUEST_KEY);
        }

        const submitRequest = () => {
            submitProductAVRequest(avRequestDetails, productCoreID);
        }

        const submissions: IProductAVWorkSubmission[] = !!productAVWorkSubmissions ? productAVWorkSubmissions : [];

        return (
            <>
                <div className="row">
                    <SizedTextDataInput
                        label={this.viewData[AVSUBMISSION_REQUEST_BATCH_ID_STATE_KEY]}
                        fieldName={AVSUBMISSION_REQUEST_BATCH_ID_STATE_KEY}
                        value={requestBatchID}
                        changeData={changeData}
                        readOnly={isReadonly}
                        isHidden={!this.viewData[AVSUBMISSION_REQUEST_BATCH_ID_STATE_KEY]}
                    />

                    <SizedDropdownDataInput
                        label={this.viewData[AVSUBMISSION_AV_STATUS_STATE_KEY]}
                        fieldName={AVSUBMISSION_AV_STATUS_STATE_KEY}
                        value={avStatus}
                        changeData={changeData}
                        options={getLookupValues(AV_SUBMISSION_STATUS, lookupValues)}
                        allowNull={true}
                        readOnly={isReadonly}
                        isHidden={!this.viewData[AVSUBMISSION_AV_STATUS_STATE_KEY]}
                    />

                    <SizedDateDataInput
                        label={this.viewData[AVSUBMISSION_REQUEST_DATE_STATE_KEY]}
                        fieldName={AVSUBMISSION_REQUEST_DATE_STATE_KEY}
                        value={requestDate ? requestDate.toString() : null}
                        changeData={changeData}
                        readOnly={isReadonly}
                        isHidden={!this.viewData[AVSUBMISSION_REQUEST_DATE_STATE_KEY]}
                    />

                </div>
                <div className="row">
                    <SizedDateDataInput
                        label={this.viewData[AVSUBMISSION_REQUIRED_BY_DATE_STATE_KEY]}
                        fieldName={AVSUBMISSION_REQUIRED_BY_DATE_STATE_KEY}
                        value={requiredByDate ? requiredByDate.toString() : null}
                        changeData={changeData}
                        readOnly={isReadonly}
                        isHidden={!this.viewData[AVSUBMISSION_REQUIRED_BY_DATE_STATE_KEY]}
                    />

                    <SizedCheckboxDataInput
                        label={this.viewData[AVSUBMISSION_URGENT_STATE_KEY]}
                        fieldName={AVSUBMISSION_URGENT_STATE_KEY}
                        value={urgent}
                        changeData={changeData}
                        readonly={isReadonly}
                        inline={true}
                        isHidden={!this.viewData[AVSUBMISSION_URGENT_STATE_KEY]} />
                </div>
                <div className="row">
                    <SizedActionButton
                        buttonText={this.viewData[REQUEST_ACTION]}
                        buttonAction={submitRequest}
                        disabled={isReadonly}
                    />
                </div>
                <div className="row">
                    <DataGridTable
                        tableContents={submissions}
                        tableActions={[]}
                        stateKey={AVSUBMISSION_SUBMISSION_STATE_KEY}
                        changeData={null}
                        sortData={sortData}
                        componentInstance={'avSubmissionDetails'}
                        dataGridTableData={dataGridTableData}
                        showModal={null}
                        clearModalSearchResults={null}
                        lookupValues={lookupValues}
                        addTab={null}
                        showRowNumber={false}
                        dataSource={null}
                        isReadOnly={true}
                        dependentRowAction={null}
                        openInNewTab={null}
                        tabs={tabs}
                        activeTab={activeTab}
                        incrementTempID={incrementTempID}
                    />
                </div>
            </>
        );
    }
}