import axios, { AxiosResponse } from "axios";
import { IUserPreferences, IUserPreferencesArchive } from "../../redux/types/IUserPreferences";
import { IUserPreferencesSaveResult } from "../types/IUserPreferencesSaveResult";

export class UserPreferencesService {
    public static get(): Promise<IUserPreferences> {
        return new Promise<IUserPreferences>((resolve, reject) => {
            axios
                .get<IUserPreferencesArchive>(`Account/UserPreferences`)
                .then(res => {
                    resolve(res.data.userPreferences);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }
    public static update ( userPreferences: IUserPreferences, userId?: number ) : Promise<IUserPreferencesSaveResult> {
        return new Promise<IUserPreferencesSaveResult>((resolve, reject) => {
            axios
                .post<IUserPreferencesSaveResult>(`Account/UserPreferences`, userPreferences)
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }
}
