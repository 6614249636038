import React, { useState } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { IUsageGroupLocation } from "../../../repertoire/types/usageTypes/IUsageGroupLocation";

export interface IMapSearchProps {
    updateGoogleVenue: (venue: IUsageGroupLocation) => void;
}

function MapsSearch(props: IMapSearchProps) {
    const [searchResult, setSearchResult] = useState<any>("Result: none");

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyCa72Yu-mARGglrWXu6VgUKmwsr2QvCF14",
        libraries: ["places"]
    });

    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
    }

    let placeAddresCompoponent = {
        TOWN: 'postal_town',
        COUNTRY: 'country'
    }

    function getAddressComponent(address_components, key) {
        var value = '';
        var postalCodeType = address_components.filter(aComp =>
            aComp.types.some(typesItem => typesItem === key))
        if (postalCodeType != null && postalCodeType.length > 0)
            value = postalCodeType[0].long_name
        return value;
    }

    function onPlaceChanged() {
        if (searchResult != null) {
            const { updateGoogleVenue } = props;
            const place = searchResult.getPlace();

            let venue: IUsageGroupLocation = {
                googleVenue: place.place_id,
                venue: place.name,
                country: getAddressComponent(place.address_components, placeAddresCompoponent.COUNTRY),
                city: getAddressComponent(place.address_components, placeAddresCompoponent.TOWN),
                addDetailsManually: false,
            }
            updateGoogleVenue(venue)
        } else {
            alert("Please enter text");
        }
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div className="App">
            <div id="searchColumn">
                <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                    <input className="mapSearch"
                        type="text"
                        placeholder="Search for Venue"
                    />
                </Autocomplete>
            </div>
        </div>
    );
}

export default MapsSearch;
