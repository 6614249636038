import * as React from "react";
import {
    LICENSE_MAINTENANCE_TOOLBAR,
    SAVE_METHOD_TOOLBAR,
    SAVE_CHANGES_STATE_KEY,
    DELETE_METHOD_TOOLBAR,
    DELETE_LICENSE_STATE_KEY,
    ADD_METHOD_TOOLBAR,
    ADD_NEW_LICENSE_STATE_KEY,
    UNDO_METHOD_TOOLBAR,
    COPY_METHOD_TOOLBAR,
    COPY_LICENSE_STATE_KEY,
    UNDO_CHANGES_LICENSE_STATE_KEY,
    SUBMIT_LICENSE,
    LICENSE_SUBMIT_CONFIRMATION_STATE_KEY
} from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { clearModalSearchResults, copyExistingLicense} from "../../../../redux/reducers/RepertoireReducer";
import { showYesNoPrompt, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";
import ToolbarComponent from "../../../components/toolBar/ToolBarComponent";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { IShoppingCartState } from "../../../../redux/types/IShoppingCartState";

export interface ILicenseMaintenanceToolbarProps {
    changesMade: boolean;
    showYesNoPrompt: typeof showYesNoPrompt;
    hideModal: typeof hideModal;
    resetMessageBanner: () => void;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData?: IRepertoireComponentDataItem;
    license: IShoppingCartState;
    activeTab: number;
    tabs: ITabReduxItem[];
    saveChanges: () => void;
    addNewLicense: () => void;
    deleteLicense: () => void;
    undoLicenseChanges: () => void;
    clearModalSearchResults?: typeof clearModalSearchResults;
    isReadonly: boolean;
    isNew?: boolean;
    copyLicense: typeof copyExistingLicense;
    submitLicense: () => void;
    bookPriceCalculationsJobInProgress?: boolean;
    isStatusSubmitted?: boolean;
}

export type ILicenseMaintenanceToolbarKeys = keyof ILicenseMaintenanceToolbarProps;

export interface ILicenseMaintenanceToolbarState {
    dataAction: IDataActionToolbar[];
    toggleSaveBtn: boolean;
}

class LicenseMaintenanceToolbar extends React.Component<
    ILicenseMaintenanceToolbarProps,
    ILicenseMaintenanceToolbarState
> {

    constructor(props) {
        super(props)
        this.state = {
            toggleSaveBtn: false,
            dataAction: this.getDataAction()
        };
    }

    copyLicense = () => {
        const { tabs, activeTab, copyLicense } = this.props;
        const shoppingCart = tabs[activeTab].licenseMaintenanceState.shoppingCart;
        copyLicense(shoppingCart);
    }

    saveChangesAction = () => {
        const { saveChanges } = this.props;
        saveChanges();
    }

    onSubmitLicense = () => {
        const { showYesNoPrompt, hideModal,toolbarData } = this.props;
        const title: string = toolbarData.fields.find(f => f.name === LICENSE_SUBMIT_CONFIRMATION_STATE_KEY).data;

        const propsModal: IYesNoPromptViewModalProps = {
            yesCallback: () => { this.submitLicenseProcess(); hideModal(); },
            noCallback: () => { hideModal(); }
        }

        showYesNoPrompt(title, propsModal);
    }

    submitLicenseProcess(){
        const { submitLicense } = this.props;
        const dataAction = [...this.state.dataAction];

        const dataActionIndex = dataAction.findIndex(d=> d.method === SUBMIT_LICENSE);
        dataAction[dataActionIndex].isReadOnly = true;
        this.setState({ dataAction : dataAction})

        submitLicense();
    }

    getDataAction() {
        const {
            toolbarData: { fields },
            addNewLicense,
            deleteLicense,
            undoLicenseChanges,
            isStatusSubmitted
        } = this.props;
        
        const actionList: IDataActionToolbar[] = [];

        const deleteLicenseAction: IDataActionToolbar = {
            action: deleteLicense,
            component: LICENSE_MAINTENANCE_TOOLBAR,
            method: DELETE_METHOD_TOOLBAR,
            name: fields.find(d => d.name === DELETE_LICENSE_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Delete'
        };
        actionList.push(deleteLicenseAction);

        const add: IDataActionToolbar = {
            action: addNewLicense,
            component: LICENSE_MAINTENANCE_TOOLBAR,
            method: ADD_METHOD_TOOLBAR,
            name: fields.find(p => p.name === ADD_NEW_LICENSE_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--CircleAddition'
        }
        actionList.push(add);

        const save: IDataActionToolbar = {
            action: this.saveChangesAction,
            component: LICENSE_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: ''
        };
        actionList.push(save);

        const undo: IDataActionToolbar = {
            action: undoLicenseChanges,
            component: LICENSE_MAINTENANCE_TOOLBAR,
            method: UNDO_METHOD_TOOLBAR,
            name: fields.find(undo => undo.name === UNDO_CHANGES_LICENSE_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Undo'
        };
        actionList.push(undo);

        const copyLicenseAction: IDataActionToolbar = {
            action: this.copyLicense,
            component: LICENSE_MAINTENANCE_TOOLBAR,
            method: COPY_METHOD_TOOLBAR,
            name: fields.find(d => d.name === COPY_LICENSE_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Copy'
        };
        actionList.push(copyLicenseAction);

        const submitLicenseAction: IDataActionToolbar = {
            action: this.onSubmitLicense,
            component: LICENSE_MAINTENANCE_TOOLBAR,
            method: SUBMIT_LICENSE,
            name: fields.find(d => d.name === SUBMIT_LICENSE).data,
            icon: 'icon ms-Icon ms-Icon--SubstitutionsIn',
            isReadOnly: isStatusSubmitted
        };
        actionList.push(submitLicenseAction);

        return actionList;
    }

    toggleSaveButton() {
        const { toggleSaveBtn } = this.state;
        if (!toggleSaveBtn)
            this.setState({ toggleSaveBtn: true })
    }

    disableSaveButton() {
        const { toggleSaveBtn } = this.state;
        if (toggleSaveBtn)
            this.setState({ toggleSaveBtn: false })
    }

    render() {
        const {
            changesMade,
            showYesNoPrompt,
            hideModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            license,
            activeTab,
            toolbarData,
            tabs,
            isReadonly,
            isNew,
            bookPriceCalculationsJobInProgress
        } = this.props;
        const {
            toggleSaveBtn
        } = this.state;
        return (
            <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                <ToolbarComponent
                    changesMade={changesMade}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    resetMessageBanner={resetMessageBanner}
                    scroll={scroll}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    license={license}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={this.state.dataAction}
                    toolbarData={toolbarData}
                    component={LICENSE_MAINTENANCE_TOOLBAR}
                    isNew={isNew}
                    isReadonly={isReadonly}
                    isLicensePageModified={toggleSaveBtn}
                    bookPriceCalculationsJobInProgress={bookPriceCalculationsJobInProgress}
                />
            </div>
        );
    }

}

export default LicenseMaintenanceToolbar;

