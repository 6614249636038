import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { ColumnActionsMode } from 'office-ui-fabric-react';
import { msalConfig } from '../../accounts/types/msalAuthConfig';
import { EMPTY_STRING_VALUE } from '../../repertoire/Consts';
import { IRepertoireField } from '../../repertoire/types/IRepertoireField';
import { Dictionary } from '../types/Dictionary';
import { IComponentField } from '../types/IComponentField';

export class ComponentsHelper {

    public static getField(field: number, fields: any, activeFields: Dictionary<IComponentField>): { exists: boolean, text: string, toolTip: string } {
        let fieldName = fields[field];
        let f = activeFields[fieldName];

        return {
            exists: !!f,
            text: f && f.data,
            toolTip: f && f.toolTip,
        };
    }

    public static getFieldValueByName(fieldName: string, fieldList: { name: string, data: string }[], defaultValue: string = EMPTY_STRING_VALUE): string {
        if (fieldList === null || fieldList.length === 0)
            return defaultValue;

        return (fieldList.filter((v, i, a) => v.name === fieldName ? v : null)[0] || { data: defaultValue }).data;
    }
    
    public static orderFieldsBySpecificField(fields: IRepertoireField[], orderBy: string) : IRepertoireField[]{
        if(!fields)
        return [];

        return fields.sort((a, b) => ((isNaN(a[orderBy]) ? 0 : a[orderBy]) > (isNaN(b[orderBy]) ? 0 : b[orderBy])) ? 1 : 
                                        ((isNaN(a[orderBy]) ? 0 : a[orderBy]) === (isNaN(b[orderBy]) ? 0 : b[orderBy]) ? 1 : -1) -1)

    }

    public static createBearerHeaderFromAADLogin(instance: IPublicClientApplication, account: AccountInfo): void {
        axios.interceptors.request.use(async (config) => {
            const url = config.url;
            if (url && (url.includes('http://') || url.includes('https://'))) {
                return config;
            }

            if (!account) {
                return config;
            }

            let scope =
            {
                scopes: [msalConfig.auth.scope]
            }
            

            const response = await instance.acquireTokenSilent({
                ...scope,
                account,
            });

            const bearer = `Bearer ${response.accessToken}`;
            config.headers.Authorization = bearer;

            return config;
        });
    }

    public static parseJwt(token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    public static isEmpty(value) {
        return (value == null || (typeof value === "string" && value.trim().length === 0));
    }

}