import React from "react";
import DisplayTableRow from "./DisplayTableRow";
import DisplayText from "../text/DisplayText";
import { IRepertoireField } from "../../../repertoire/types/IRepertoireField";
import TableHeaderSection from "../../../repertoire/components/tableHeaderSection/TableHeaderSection";
import { IInstrumentation } from "../../../repertoire/types/IInstrumentation";
import { IArtist } from "../../../repertoire/types/IArtist";
import { IContributorItem } from "../../../repertoire/types/IContributorItem";
import { ITitle } from "../../../repertoire/types/ITitle";
import { IProductWorkDataItems } from "../../../repertoire/types/IProductWorkDataItems";
import { ILookupInstance } from "../../../lookup/types/ILookup";
import { IIPsSearchResult } from "../../types/IIPsSearchResult";

export interface IDisplayTableProps {
    tableContents: IIPsSearchResult[];
    dataGridTableData: IRepertoireField[];
    showRowNumber?: boolean;
    isExpandable?: boolean;
    rowAction?: (item: IIPsSearchResult) => void;
    removeRowAction?: (item: IIPsSearchResult) => void;
    lookupOptionsAndOnChangeFunctions?: ILookupOptionsAndOnChangeFunctions;
    isSearchResultTable?: boolean;
    sortTableByColumn?: (section: string, ascending:boolean) => void;
    activeSortHeaderSection?: string;
    isAscending?: boolean;
    onClickOpen?: (tableContentItem: IIPsSearchResult) => void;
}

export interface ILookupOptionsAndOnChangeFunctions {
    musicUsage?: {
        options: ILookupInstance[],
        onChangeValue: (
            value: any,
            fieldName: string,
            rowIndex: number,
        ) => void,
    },
    duration?: {
        onChangeValue: (
            value: any,
            fieldName: string,
            rowIndex: number,
        ) => void,
    }
}

const DisplayTable: React.FC<IDisplayTableProps> = ({
    tableContents,
    dataGridTableData,
    isExpandable,
    showRowNumber,
    rowAction,
    removeRowAction,
    lookupOptionsAndOnChangeFunctions,
    isSearchResultTable,
    sortTableByColumn,
    activeSortHeaderSection,
    isAscending,
    onClickOpen
}) => {
    
    const renderTableContents = () => {
        const rows: JSX.Element[] = [];
            tableContents && tableContents.map((tableContentItem, index: number) => {
                rows.push(
                    <DisplayTableRow
                        tableContentItem={tableContentItem} 
                        index={index} 
                        tableData={dataGridTableData} 
                        showRowNumber={showRowNumber} 
                        isExpandable={isExpandable}
                        rowAction={rowAction}
                        removeRowAction={removeRowAction}
                        lookupOptionsAndOnChangeFunctions={lookupOptionsAndOnChangeFunctions}
                        isSearchResultTable={isSearchResultTable}
                        onClickOpen={onClickOpen}
                    />
                 );
            });   
        return rows;
    };


    const getHeaderSections = () => {
            const headerSections = dataGridTableData.map((section: IRepertoireField) => (       
                <td key={section.name} className="td">
                        <TableHeaderSection
                            section={section.data}
                            fieldName={section.name}
                            isSortingActive={section.name === activeSortHeaderSection}
                            ascending={isAscending}
                            onClickHeaderSection={sortTableByColumn}
                            sortable
                        />
                </td>
            ));

            if (showRowNumber) {
                headerSections.splice(0, 0, <td key="rowNumber" title="Row Number"></td>);
            }
            return headerSections;
    };

    const renderHeader = () => {
        let sections = getHeaderSections();
        return <tr key="headerRow" className="tr">{isExpandable && <td />}{sections}</tr>;
    };

    return  dataGridTableData && dataGridTableData.length > 0 ? <div className="tableContainer">
                <table className="table">
                    <thead key="thead" className={"tableContainer table thead tableHeader"}>
                        {renderHeader()}
                    </thead>
                    <tbody className="tbody">
                        {renderTableContents()}
                    </tbody>
                </table>
            </div> : <DisplayText text={"Loading ..."} />
}

export default DisplayTable;