import * as React from "react";
import { IScheduledJobState, IScheduledJobStateKeys } from "../../../../redux/types/IScheduledJobState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { SizedTextDataInput } from "../../../components/usageComponents/textInput/TextInput";
import { IRepertoireField } from "../../../types/IRepertoireField";
import CombinedTextAndButtonDataInput from "../../textDataInput/CombinedTextAndButtonDataInput";

export interface IScheduledJobModalViewProps {
    fields: IRepertoireField[];
    noCallback?: () => void;
    yesCallback?: () => void;
    upsertScheduledJob: (schedule: IScheduledJobState,componentName?:string) => void;
    componentName: string;
    tab?: ITabReduxItem;
    changeData?: (value: any, fieldName: IScheduledJobStateKeys, componentName?: string) => void;
}

const ScheduledJobModalView: React.SFC<IScheduledJobModalViewProps> = ({ fields, yesCallback, noCallback, upsertScheduledJob, tab, componentName, changeData }) => {

    const [loading, setLoading] = React.useState(false);
    const onClickYes = () => {
        setLoading(true) 
        yesCallback();
    }
    React.useEffect(() => {
        if (tab?.scheduledJobStateState?.every(scheduledJob=>scheduledJob.errorList.length > 0)) {
            setLoading(false);
        }
    }, [tab.scheduledJobStateState.every(scheduledJob=>scheduledJob.errorList)]);

    const onClickNo = () => {
        changeData([], "errorList");
        noCallback();
    }

    const typeOfJob = () =>{
        switch(componentName){
            case "CDAllocationScheduledJob":
                let alkScheduledJob =  tab.scheduledJobStateState.find(scheduledjob=>scheduledjob.type==="ALK")
                alkScheduledJob? alkScheduledJob.cronSchedule:tab.scheduledJobStateState.find(scheduledjob=>scheduledjob.type==="").type = "ALK"
                alkScheduledJob = tab.scheduledJobStateState.find(scheduledjob=>scheduledjob.type==="ALK")
                return alkScheduledJob.cronSchedule
                
            case "QAScheduledJob": 
            let QAScheduledJob =  tab.scheduledJobStateState.find(scheduledjob=>scheduledjob.type==="QA")
            QAScheduledJob? QAScheduledJob.cronSchedule:tab.scheduledJobStateState.find(scheduledjob=>scheduledjob.type==="").type = "QA"
            QAScheduledJob = tab.scheduledJobStateState.find(scheduledjob=>scheduledjob.type==="QA")
                return QAScheduledJob.cronSchedule
                
            default:
                console.log("Job doesen't exist")
        }
        
    }
    const onClicksetJobSchedule = () => {
        switch(componentName){
            case "CDAllocationScheduledJob":
                let alkJob =  tab.scheduledJobStateState.find(scheduledjob=>scheduledjob.type==="ALK")
                upsertScheduledJob(alkJob,componentName);
                break;
            case "QAScheduledJob": 
            let qaJob =  tab.scheduledJobStateState.find(scheduledjob=>scheduledjob.type==="QA")
            upsertScheduledJob(qaJob,componentName);
                break;
            default:
                console.log("Job doesen't exist")
        }
    }
    const setJobScheduleText: string = fields.find(f => f.name === "setJobSchedule").data;
    const yesButtonText: string = fields.find(f => f.name === "yesButton").data;
    const noButtonText: string = fields.find(f => f.name === "noButton").data;

    const renderFields = () => {
        const filteredFields: IRepertoireField[] = fields.filter(f => f.componentInstance === componentName && f.fieldType !== null)
        let filteredSearchFieldsMapper = (searchField: IRepertoireField, index: number): React.ReactElement => {
            let reactElement: React.ReactElement = <></>;

            if ((searchField.name === 'cronSchedule'))
                reactElement =
                    <div key={index}>
                        <CombinedTextAndButtonDataInput
                            useEnterSubmit={false}
                            label={searchField.data}
                            fieldName={searchField.name}
                            changeData={(value, fieldName) => changeData(value, fieldName as IScheduledJobStateKeys, `${componentName}`)}
                            value={typeOfJob()}
                            readOnly={false}
                            buttonAction={onClicksetJobSchedule}
                            buttonLabel={setJobScheduleText}
                            isEnabled={true}
                        />
                    </div>;

            return reactElement;
        };
        return filteredFields.map(filteredSearchFieldsMapper);
    }

    const showErrorMessages = () => {
        let showMessages = (message: string, index: number): React.ReactElement => {
            let reactElement: React.ReactElement = <></>;

            reactElement =
                <div className="col-md-12 alert alert-dismissible fade in alert-warning" key={index}>
                    <div>
                        {fields.find(f => f.name === message).data}
                    </div>
                </div>;

            return reactElement;
        };
        let errors = tab.scheduledJobStateState.find(scheduledJob=>scheduledJob.errorList.length > 0).errorList.map(showMessages)
        return errors;
    }
    return (
         <div className="scheduledJobView">
            {
                tab?.scheduledJobStateState?.find(scheduledJob=>scheduledJob.errorList.length > 0) && showErrorMessages()
            }
            {loading && <div>Loading...</div>}
            {componentName==="CDAllocationScheduledJob"&&<div className="closeButton" onClick={noCallback} ><img src="assets/close.svg" className="closeIcon"></img></div>}            
           <div className="row">
                {renderFields()}
            </div>
            
           {componentName!="CDAllocationScheduledJob"&&
           <div className="row">
                <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                    <button className="button btn-defaultSecondary" disabled={loading}
                        onClick={onClickNo}>
                        {noButtonText}
                    </button>
                </div>
           
                <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                    <button className="button btn-defaultPrimary" disabled={loading}
                        onClick={onClickYes}>
                        {yesButtonText}
                    </button>
                </div>
            </div>
            }
        </div>
    );
};

export default ScheduledJobModalView;