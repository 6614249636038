import * as React from "react";
import { hideModal } from "../../../redux/reducers/ModalReducer";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { CANCEL_BUTTON_TEXT_FIELD, SAVE_BUTTON_TEXT_FIELD, UPDATE_WORKFLOW_VIEW, UPDATE_WORKFLOW_VIEW_DESCRIPTION_STATE_KEY, UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY } from "../../Consts";
import { SizedDropdownDataInput } from "../dropdownDataInput/DropdownDataInput";

export interface IUpdateWorkflowViewProps {
    hideModal: typeof hideModal;
    updateWorkflowViewData?: IRepertoireComponentDataItem;
    updateWorkflows: (username: string) => void;
}

interface IUpdateWorkflowViewState{
    loaded: boolean;
    selectedStatus: string;
    statusValues:{
        code: string;
        description: string;
    }[] 
}

export default class UpdateWorkflowView extends React.PureComponent<
IUpdateWorkflowViewProps,IUpdateWorkflowViewState>{
    viewData;

    constructor(props: IUpdateWorkflowViewProps){
        super(props);

        this.state = {
            loaded: false,
            selectedStatus: "Assigned",
            statusValues: [{code: "Assigned", description:"Assigned"}
                          ,{code: "Cancelled", description:"Cancelled"}
                          ,{code: "Completed", description:"Completed"}
                          ,{code: "Open", description:"Open"}]
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            updateWorkflowViewData: {fields }
        } = this.props;

        if(fields){
            fields.forEach(item => {
                this.viewData[item.name] = item.data;
    
                if (Object.keys(this.viewData).length === fields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    changeData = (value: any) =>{
        this.setState({selectedStatus: value.toString()});
    }
    onClikCancel(){
        const {hideModal} = this.props;
        hideModal();
    }

    onClickSave(){
        const {updateWorkflows} = this.props;
        const {selectedStatus} = this.state;
            updateWorkflows(selectedStatus);
    }
    
    render(){
        const {loaded, statusValues, selectedStatus} = this.state;
        
        if(loaded){
            return (
            <div>
                <div className="centerTittle">
                    <p>{this.viewData[UPDATE_WORKFLOW_VIEW_DESCRIPTION_STATE_KEY]}</p>
                </div>
                <div className="col-md-12 cols-lg-12 col-sm-12 lg-xs-12">
                    <SizedDropdownDataInput
                        label={this.viewData[UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY]}
                        fieldName={this.viewData[UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY]}
                        changeData={this.changeData}
                        value={selectedStatus}
                        options={statusValues}
                        allowNull={false}
                        isHidden={false}
                        showDropdownsAsCodes={false}
                        componentInstance={UPDATE_WORKFLOW_VIEW}
                    />
                </div>
                <div>
                        <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                            <button className="button btn-defaultSecondary" onClick={this.onClikCancel.bind(this)}>
                                {this.viewData[CANCEL_BUTTON_TEXT_FIELD]}
                            </button>
                        </div>

                        <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
                            <button className="button btn-defaultPrimary" onClick={this.onClickSave.bind(this)}>
                                {this.viewData[SAVE_BUTTON_TEXT_FIELD]}
                            </button>
                        </div>
                    </div>
            </div>
        )
        }
        else return null;
    }
}