import React from "react";
import { JobMonitoringService } from "../job/services/JobMonitoringService";


const CleanUpJobMonitoringService = () => {
    React.useEffect(() => {
        return  JobMonitoringService.stopMonitoringProgress();
    }, []);
    return <></>
}
export default CleanUpJobMonitoringService;