import * as React from "react";
import { SizedDropdownDataInput } from "../../dropdownDataInput/DropdownDataInput";
import SizedDateDataInput from "../../dateDataInput/DateDataInput";
import { WideActionButton } from "../../actionButton/ActionButton";
import { updateSelectionCriteria } from "../../../../redux/reducers/RepertoireReducer";
import { IDistributionPoolState } from "../../../../redux/types/IDistributionPoolState";
import { ISelectionCriteria, ISelectionCriteriaDateRange, ISelectionCriteriaDistPoolSegment } from "../../../types/ISelectionCriteria";
import DataGridTable from "../../dataGridTable/DataGridTable";
import { ADD_NEW_ACTION, DATE_INPUT, DISTRIBUTION_POOLS_KEY, DISTRIBUTION_POOL_DATE_RANGE_SELECTION_CRITERIA_KEY, DISTRIBUTION_POOL_DATE_RANGE_SELECTION_CRITERIA_STATE_KEY, DISTRIBUTION_POOL_SELECTION_CRITERIA_KEY, DISTRIBUTION_POOL_SELECTION_CRITERIA_STATE_KEY, DROPDOWN_INPUT } from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IDataItem } from "../../../types/usageTypes/IDataItem";
import { IDistPoolSegmentDataItems } from "../../../types/IDistPoolSegmentDataItems";
import {IDateRangeDataItems} from "../../../types/IDateRangeDataItems";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import CheckboxDataInput from "../../checkboxDataInput/CheckboxDataInput";
import { SizedTextDataInput } from "../../textDataInput/TextDataInput";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { If } from "../../../../core/components/If";
import { ARTISJUS_CUSTOMER_NAME, SPANISH_POINT_CUSTOMER_NAME } from "../../../../home/Consts";
import { SizedRadiobuttonDataInput } from "../../radioButtonDataInput/RadiobuttonDataInput";

export interface ISelectionCriteriaViewProps {
    distributionPool: IDistributionPoolState;
    hideModal: () => void;
    updateSelectionCriteria: typeof updateSelectionCriteria;
    dataGridTableData: IRepertoireComponentDataItem,
    lookupValues: ILookupDictionary;
    distributions?: IDistribution[];
    isReadOnly?: boolean;
    tabs?: ITabReduxItem[];
    activeTab?: number;
    incrementTempID?: () => void;
    customer?: string;
}

interface ISelectionCriteriaViewState {
    selectionCriteria: ISelectionCriteria;
}


class SelectionCriteriaView extends React.PureComponent<ISelectionCriteriaViewProps, ISelectionCriteriaViewState> {

    constructor(props: ISelectionCriteriaViewProps) {
        super(props);
        this.state = {
            selectionCriteria: this.props.distributionPool.selectionCriteria
        }
    }

    componentDidMount() {
        const { distributionPool } = this.props;
        if (distributionPool.selectionCriteria) {
            this.setState(prevState => ({
                selectionCriteria: {
                    ...prevState.selectionCriteria,
                    type: distributionPool.selectionCriteria.type,
                    dateRanges:distributionPool.selectionCriteria.dateRanges || [],
                    distPoolSegments: distributionPool.selectionCriteria.distPoolSegments || []
                }
            }))
        }
    }

    updateSelectionCriteriaState = (value: any, field: string) => {
        switch (field) {
            case "Type":
                this.setState(prevState => ({
                    selectionCriteria: {
                        ...prevState.selectionCriteria,
                        type: value,

                    }
                }));
                break;
            case "DateRange":
                this.setState(prevState => ({
                    selectionCriteria: {
                        ...prevState.selectionCriteria,
                        isDateRangeEnabled: value
                    }
                }));
                break;
            case "PoolSegment":
                this.setState(prevState => ({
                    selectionCriteria: {
                        ...prevState.selectionCriteria,
                        isPoolSegmentEnabled: value
                    }
                }));
                break;
            case "CustomQuery":
                this.setState(prevState => ({
                    selectionCriteria: {
                        ...prevState.selectionCriteria,
                        isCustomQueryEnabled: value
                    }
                }));
                break;
            case "CustomQueryText":
                this.setState(prevState => ({
                    selectionCriteria: {
                        ...prevState.selectionCriteria,
                        customQuery: value
                    }
                }));
                break;
            case "isFileNameEnabled":
                this.setState(prevState => ({
                    selectionCriteria: {
                        ...prevState.selectionCriteria,
                        isFileNameEnabled: value
                    }
                }));
                break;
            case "fileName":
                this.setState(prevState => ({
                    selectionCriteria: {
                        ...prevState.selectionCriteria,
                        fileName: value
                    }
                }));
                break;
            case "SourceUsageMultipliers":
                value = (value === "revertSourceUsageMultipliers")
                this.setState(prevState => ({
                    selectionCriteria: {
                        ...prevState.selectionCriteria,
                        revertSourceUsageMultipliers: value
                    }
                }));
                break;
        }
    };

    updateDistPoolSegment = (value: IDistPoolSegmentDataItems[], name: string) => {

        let distPoolSegments: ISelectionCriteriaDistPoolSegment[] = value.map((dsp) => ({
            distribution: dsp.distribution.value,
            pool: dsp.pool.value,
            segment: dsp.segment.value,
            id: dsp.distPoolSegmentID
        }));
        this.setState((prevState) => ({
            selectionCriteria: {
                ...prevState.selectionCriteria,
                distPoolSegments
            }
        }));

    }

    updateDateRanges = (value: IDateRangeDataItems[]) => {

        let dateRanges: ISelectionCriteriaDateRange[] = value.map((dsp) => ({
            fromDate:dsp.fromDate.value,
            toDate:dsp.toDate.value,
            id: dsp.dateRangeSegmentID
        }));
        this.setState((prevState) => ({
            selectionCriteria: {
                ...prevState.selectionCriteria,
                dateRanges
            }
        }));

    }

    renderDistPoolSegmentSection = () => {
        const { dataGridTableData, lookupValues, distributions, isReadOnly,tabs, activeTab,incrementTempID} = this.props;
        const { selectionCriteria: { distPoolSegments } } = this.state;
        let dataItems: IDistPoolSegmentDataItems[] = distPoolSegments.map((dsp) => ({
            distPoolSegmentID: dsp.id,
            distribution: { inputType: DROPDOWN_INPUT, value: dsp.distribution || '' },
            pool: { inputType: DROPDOWN_INPUT, value: dsp.pool || '' },
            segment: { inputType: DROPDOWN_INPUT, value: dsp.segment || '' }
        }));

        return (
            <DataGridTable
                tableContents={dataItems}
                changeData={this.updateDistPoolSegment}
                dataGridTableData={dataGridTableData}
                stateKey={DISTRIBUTION_POOL_SELECTION_CRITERIA_STATE_KEY}
                componentInstance={DISTRIBUTION_POOL_SELECTION_CRITERIA_KEY}
                showRowNumber={false}
                isReadOnly={isReadOnly}
                tableActions={[{ name: ADD_NEW_ACTION }]}
                lookupValues={lookupValues}
                distributions={distributions}
                tabs={tabs}
                activeTab={activeTab}
                incrementTempID={incrementTempID}
            />
        )
    }

    renderDateRangeSection = () => {
        const { dataGridTableData, lookupValues, isReadOnly,tabs,activeTab,incrementTempID } = this.props;
        const { selectionCriteria: { dateRanges } } = this.state;
        let dataItems: IDateRangeDataItems[] = dateRanges.map((dsp) => ({
            fromDate:{inputType: DATE_INPUT, value: dsp.fromDate || '' },
           toDate:{inputType: DATE_INPUT, value: dsp.toDate || ''},
           dateRangeSegmentID: dsp.id,
        }));
        return (
            <DataGridTable
                tableContents={dataItems}
                changeData={this.updateDateRanges}
                dataGridTableData={dataGridTableData}
                stateKey={DISTRIBUTION_POOL_DATE_RANGE_SELECTION_CRITERIA_STATE_KEY}
                componentInstance={DISTRIBUTION_POOL_DATE_RANGE_SELECTION_CRITERIA_KEY}
                showRowNumber={false}
                isReadOnly={isReadOnly}
                tableActions={[{ name: ADD_NEW_ACTION }]}
                lookupValues={lookupValues}
                tabs={tabs}
                activeTab={activeTab}
                incrementTempID={incrementTempID}
            />
        )
    }
    
    render(): React.ReactNode {
        const { selectionCriteria } = this.state;
        const { distributionPool, updateSelectionCriteria, hideModal, isReadOnly,customer } = this.props;
        let buttonAction = () => {
            updateSelectionCriteria(distributionPool.distributionPoolID, selectionCriteria);
            hideModal();
        };

        if (selectionCriteria) {
            return (
                <div className="col-md-12">
                    <div className="selectionCriteriaOptions">
                        <CheckboxDataInput
                            label="Date Range"
                            fieldName=""
                            value={selectionCriteria.isDateRangeEnabled}
                            changeData={(value) => this.updateSelectionCriteriaState(value, "DateRange")}
                            readonly={false}
                            isHidden={false}
                        />
                           {selectionCriteria.isDateRangeEnabled &&
                             <div className="row">
                             {this.renderDateRangeSection()}
                            </div>
                        }
                        <CheckboxDataInput
                            label="Distribution/Pool/Segment"
                            fieldName=""
                            value={selectionCriteria.isPoolSegmentEnabled}
                            changeData={(value) => this.updateSelectionCriteriaState(value, "PoolSegment")}
                            readonly={false}
                            isHidden={false}
                        />
                        {selectionCriteria.isPoolSegmentEnabled &&
                            <div className="row">
                                {this.renderDistPoolSegmentSection()}
                            </div>
                        }
                        <CheckboxDataInput
                            label="Custom Query"
                            fieldName=""
                            value={selectionCriteria.isCustomQueryEnabled}
                            changeData={(value) => this.updateSelectionCriteriaState(value, "CustomQuery")}
                            readonly={false}
                            isHidden={false}
                        />
                        <SizedRadiobuttonDataInput
                            key={"sourceUsageMultipliers"}
                                selectedValue={
                                    selectionCriteria.revertSourceUsageMultipliers ?
                                    "revertSourceUsageMultipliers" :
                                    "keepSourceUsageMultipliers"
                                }
                                onSelectionChange={(value) => this.updateSelectionCriteriaState(value, "SourceUsageMultipliers")}
                                radioName="keepSourceUsageMultipliers"
                                title="Source Usage Multipliers"
                                options={[
                                    { desc: "Revert Source Usage multipliers", code: "revertSourceUsageMultipliers" },
                                    { desc: "Keep Source Usage multipliers", code: "keepSourceUsageMultipliers" },
                                ]}
                        />
                        {selectionCriteria.isCustomQueryEnabled && 
                        <div className="row">
                            <SizedTextDataInput
                                    fieldType={""}
                                    useEnterSubmit={true}
                                    label=""
                                    fieldName={""}
                                    changeData={(value) => this.updateSelectionCriteriaState(value, "CustomQueryText")}
                                    value={selectionCriteria.customQuery}
                                    isHidden={false}
                                /> 
                        </div>
                        }  
                        <If condition={customer === ARTISJUS_CUSTOMER_NAME || customer == SPANISH_POINT_CUSTOMER_NAME}>
                            <>
                                <CheckboxDataInput
                                    label="File Name"
                                    fieldName=""
                                    value={selectionCriteria.isFileNameEnabled}
                                    changeData={(value) => this.updateSelectionCriteriaState(value, "isFileNameEnabled")}
                                    readonly={false}
                                    isHidden={false}
                                />
                                {selectionCriteria.isFileNameEnabled && 
                                <div className="row">
                                    <SizedTextDataInput
                                            fieldType={""}
                                            useEnterSubmit={true}
                                            label=""
                                            fieldName={""}
                                            changeData={(value) => this.updateSelectionCriteriaState(value, "fileName")}
                                            value={selectionCriteria.fileName}
                                            isHidden={false}
                                            handleKeyDown={(e) => { }}
                                        /> 
                                </div>
                                }
                                </>
                        </If>

                        

                        <div className="row">
                            <WideActionButton
                                buttonText="OK"
                                buttonAction={buttonAction}
                                disabled={isReadOnly}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        else return <div></div>
    }



};

export default SelectionCriteriaView;