import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../../redux/types/IAppState";
import { RouteChildrenProps } from 'react-router';
import {
    loginThunk
} from "../../../redux/thunks/AccountThunks";
import { ILoginViewModel } from "../../types/ILoginViewModel";
import { B2CCallbackCatch } from "./B2CCallbackCatch";

export default connect(
    (state: IAppState, props: RouteChildrenProps) => ({
        redirect: state.router?.location || undefined,
        isSignedIn: state.account.isSignedIn
    }),
    (dispatch: Dispatch) => ({
        login: (viewModel: ILoginViewModel, redirect: any) => dispatch<any>(loginThunk(viewModel, redirect)),
    })
)(B2CCallbackCatch);