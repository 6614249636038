import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
import * as React from "react";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { TimeRangeSelector } from "../../../../home/components/timeRangeSelector/TimeRangeSelector";
import { NOW_DEFUALT_FIELD } from "../../../../home/components/timeRangeSelector/TimeRangeSelectorFields";
import { IP_AGREEMENT_CREATION_CLASS_LOOKUP, IP_AGREEMENT_ROLES_CREATION_CLASS_LOOKUP, IP_AGREEMENT_ROLE_LOOKUP, IP_AGREEMENT_TIME_RANGE_LOOKUP } from "../../../../lookup/Consts";
import { getLookupDefault, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addTab, setEditableFields, setWorkGroupMaintenanceState, sortInterestedPartyFieldData, updateIpAgreementsFilterStateField, updateIpRepresentationFilterStateField, updateRepresenIdsToDelete } from "../../../../redux/reducers/RepertoireReducer";
import { IIPMaintenancePageUIConfiguration } from "../../../../redux/types/IIPMaintenancePageUIConfiguration";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import ActionButton from "../../../components/actionButton/ActionButton";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { ALL_CREATION_CLASSES, CREATION_CLASS, IP_AGREEMENTS, IP_AGREEMENTS_KEY, IP_MAINTENANCE_TIME_RANGE_SELECTOR, IP_NAMES_KEY, IP_LICENSING_REPRESENTATION_KEY, SEARCH_DATA_FIELD, WORK_GROUP_PAGE, DATA_GRID_TABLE, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, IP_NAMES_EDITABLE_FIELD_TITLE, CONFIGURE_IPS_ROLE, ALL_ROLES, IP_MERGED_ACCOUNTS_KEY, IP_REPRESENTATION_KEY, IP_MAINTENANCE_REPRESENTATION_TIME_RANGE_SELECTOR, ADD_NEW_ACTION, REMOVE_ACTION, DROPDOWN_INPUT, DATE_INPUT, TEXT_INPUT, IP_ROLE_STATE_KEY } from "../../../Consts";
import { IAgreementSearchState } from "../../../types/IAgreementSearchState";
import { IInterestedPartyStateKeys } from "../../../types/IInterestedParty";
import { IIPAgreement } from "../../../types/IIPAgreement";
import { IName } from "../../../types/IName";
import { IIpLicensingRepresentation } from "../../../types/IIpLicensingRepresentation";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { ManualMergeOptions } from "../../../types/ManualMergeOptions";
import { IMergedAccounts } from "../../../types/IMergedAccounts";
import { IRepresentationSearchState } from "../../../types/IRepresentationSearchState";
import { IIPRepresentation } from "../../../types/IIPRepresentation";
import DropdownDataInput, { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";

export interface IIPMaintenanceGridsViewProps {
    ipMaintenanceGridsViewData: IRepertoireComponentDataItem;
    ipMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    activeAccordion: string[];
    openIPAccordion: (accordionName: string, componentName: string) => void;
    closeIPAccordion: (accordionName: string, componentName: string) => void;
    componentInstance: string;
    showModal: typeof showModal;
    ipNames: IName[];
    ipLicensingRepresentations: IIpLicensingRepresentation[];
    ipAgreements?: IIPAgreement[];
    ipMergedAccounts?:IMergedAccounts[];
    addTab: typeof addTab;
    tabs: ITabReduxItem[];
    activeTab: number;
    updateInterestedPartyField:  (value: any, fieldName: IInterestedPartyStateKeys) => void;
    sortInterestedPartyFieldData: typeof sortInterestedPartyFieldData;
    ipMaintenanceTimeRangeSelectorViewData: IComponentDataItem;
    agreementSearchState: IAgreementSearchState;
    updateIpAgreementsFilterStateField: typeof updateIpAgreementsFilterStateField;
    filterIpAgreements: (searchState: IAgreementSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[]) => void;
    filterIpRepresentations: (searchState: IRepresentationSearchState, ipBaseNumber?: string, accountNumber?: string, creationClasses?: string[], role?: string) => void;
    isReadonly: boolean;
    representationSearchState: IRepresentationSearchState;
    updateIpRepresentationFilterStateField : typeof updateIpRepresentationFilterStateField;
    ipMaintenancePageUIConfiguration: IIPMaintenancePageUIConfiguration;
    lookupValues: ILookupDictionary;
    activeHeaderSection?: string;
    ipBaseNumber?: string;
    updateRepresentationIdsToDelete: typeof updateRepresenIdsToDelete;
    setWorkGroupState: typeof setWorkGroupMaintenanceState;
    incrementTempID?: () => void;
    updateIPNames?: (ipNames: IName[]) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableFields: IRepertoireField[];
    roles: string[];
    manualMergeIP: (matchingWorkId: number, manualMergeOption: ManualMergeOptions) => void;
    ipRepresentations?:IIPRepresentation[];
    customer?:string;
    accountNumberValid?: boolean;
    searchUsagePools?: (query: IUsagePoolSearchQuery) => void;

}

interface IIPMaintenanceGridsViewState {
    activeHeaderSection: string;
    loaded: boolean;
    selectedCreationClasses: string[];
    roleOptions: ILookupInstance[];
    role: any;
}

export default class IPMaintenanceGridsView extends React.PureComponent<
    IIPMaintenanceGridsViewProps,
    IIPMaintenanceGridsViewState
> {
    viewData;
    constructor(props: IIPMaintenanceGridsViewProps) {
        super(props);
        const defaultCreationClass: string = props.lookupValues && props.lookupValues[IP_AGREEMENT_CREATION_CLASS_LOOKUP] && props.lookupValues[IP_AGREEMENT_CREATION_CLASS_LOOKUP].defaultValue;
        this.state = {
            activeHeaderSection: props.activeHeaderSection ?? "ipNames",
            loaded: false,
            selectedCreationClasses: defaultCreationClass ? defaultCreationClass.split(',').map(s => s.trim()) : [],
            roleOptions: [],
            role: '',
        }
        this.viewData = {};
    }

    componentDidUpdate(prevProps) {
        this.loadData();
        if((this.props.ipAgreements && this.props.ipAgreements !== prevProps.ipAgreements) && (this.props.agreementSearchState && this.props.agreementSearchState === prevProps.agreementSearchState) ){
            this.onFilterAgreements();  
        }
    }

    componentDidMount() {
        const { lookupValues } = this.props;
        const { selectedCreationClasses } = this.state;
        this.getRoleOptions(selectedCreationClasses);
        this.loadData();
        this.onFilterAgreements();
    }


    loadData() {
        const { loaded } = this.state;
        const { lookupValues } = this.props;
        if (!loaded) {
            if (!this.props.ipMaintenanceTimeRangeSelectorViewData) {
                return;
            }

            const { ipMaintenanceTimeRangeSelectorViewData: { fields } } = this.props;
            if (fields) {

                fields.forEach(item => {
                    this.viewData[item.name] = item.data;

                    if (Object.keys(this.viewData).length === fields.length && lookupValues && Object.keys(lookupValues).length > 0) {
                        this.setState({ loaded: true });
                    }
                });
            }
        }
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    sortData(value: any, name: IRepertoireStateKeys): void {
        this.props.sortInterestedPartyFieldData(value, name as IInterestedPartyStateKeys);
    }

    filterAgreements(searchState: IAgreementSearchState, ipBaseNumber?: string, account?: string) {
        const { filterIpAgreements } = this.props;
        const { selectedCreationClasses } = this.state;

        filterIpAgreements(searchState, ipBaseNumber, account, selectedCreationClasses);
    }

    filterRepresntations(searchState: IRepresentationSearchState, ipBaseNumber?: string, account?: string) {
        const { filterIpRepresentations } = this.props;
        const { selectedCreationClasses, role } = this.state;
        let updatedSearchState={...searchState,
            dateFrom: (searchState?.dateFrom ? new Date(searchState.dateFrom).toISOString().split('T')[0] : undefined) as unknown as Date,
            dateTo: (searchState?.dateTo ? new Date(searchState.dateTo).toISOString().split('T')[0] : undefined) as unknown as Date,
            }
        filterIpRepresentations(updatedSearchState, ipBaseNumber, account, selectedCreationClasses, role);
    }

    onFilterAgreements() {
        const { agreementSearchState, tabs, activeTab } = this.props;
        let account = '';
        let ipBaseNumber = '';

        if (tabs[activeTab].interestedPartyMaintenanceState && tabs[activeTab].interestedPartyMaintenanceState.interestedParty) {

            if (tabs[activeTab].interestedPartyMaintenanceState.interestedParty.socIP)
                account = tabs[activeTab].interestedPartyMaintenanceState.interestedParty.socIP.accountNumber;

            if (tabs[activeTab].interestedPartyMaintenanceState.interestedParty.ipBaseNumber)
                ipBaseNumber = tabs[activeTab].interestedPartyMaintenanceState.interestedParty.ipBaseNumber;

            agreementSearchState.dateRange = NOW_DEFUALT_FIELD;
            this.filterAgreements(agreementSearchState, ipBaseNumber, account);
        }
    }
    onFilterRepresentation() {
        const { representationSearchState, tabs, activeTab } = this.props;
        let account = '';
        let ipBaseNumber = '';

        if (tabs[activeTab].interestedPartyMaintenanceState && tabs[activeTab].interestedPartyMaintenanceState.interestedParty) {

            if (tabs[activeTab].interestedPartyMaintenanceState.interestedParty.socIP)
                account = tabs[activeTab].interestedPartyMaintenanceState.interestedParty.socIP.accountNumber;

            if (tabs[activeTab].interestedPartyMaintenanceState.interestedParty.ipBaseNumber)
                ipBaseNumber = tabs[activeTab].interestedPartyMaintenanceState.interestedParty.ipBaseNumber;

            representationSearchState.dateRange = NOW_DEFUALT_FIELD;
            this.filterRepresntations(representationSearchState, ipBaseNumber, account);
        }
    }
    

    onClickSearch() {
        const { agreementSearchState,representationSearchState, tabs, activeTab } = this.props;
        const { activeHeaderSection } = this.state;
        const account = !tabs[activeTab].interestedPartyMaintenanceState.interestedParty.socIP ? '' : tabs[activeTab].interestedPartyMaintenanceState.interestedParty.socIP.accountNumber;
        const ipBaseNumber = !tabs[activeTab].interestedPartyMaintenanceState.interestedParty.ipBaseNumber ? '' : tabs[activeTab].interestedPartyMaintenanceState.interestedParty.ipBaseNumber;  
        if(activeHeaderSection!==IP_REPRESENTATION_KEY){
                this.filterAgreements(agreementSearchState, ipBaseNumber, account);
            }else{
                this.filterRepresntations(representationSearchState,ipBaseNumber,account)
            }
        
    }

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { ipMaintenanceGridsViewData, componentInstance } = this.props;

        if (ipMaintenanceGridsViewData && ipMaintenanceGridsViewData.fields) {
            return ipMaintenanceGridsViewData.fields.filter(f => f.fieldType === null).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    };

    changeRoleSelector = (value: any, name: IRepertoireStateKeys) => {
        this.setState({ role: value })
    }

    getRoleOptions = (opts) => {
        const { lookupValues } = this.props;

        const rolesLookups = getLookupValues(IP_AGREEMENT_ROLE_LOOKUP, lookupValues);
        const rolesCreationClassLookups = getLookupValues(IP_AGREEMENT_ROLES_CREATION_CLASS_LOOKUP, lookupValues);

        let newRolesLookups = rolesLookups;

        if (opts && opts[0] !== 'All' && rolesCreationClassLookups?.length) {
            newRolesLookups = [];
            const filteredLinkedRoleClasses = rolesCreationClassLookups.filter(x => opts.includes(x.code))
            rolesLookups.filter(role => { 
                let hasBeenPushedAlready = false;
                filteredLinkedRoleClasses.forEach(roleClass => {
                    if ((roleClass.description === role.code && !hasBeenPushedAlready)) {
                        newRolesLookups.push(role)
                        hasBeenPushedAlready = true;
                    }
                })
            });
            this.setState({ roleOptions: newRolesLookups });
            return;
        }
        this.setState({ roleOptions: rolesLookups });
    }

    multiSelectChangeData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        const { lookupValues, ipMaintenanceAccordionViewData } = this.props;
        const creationClassLookups = getLookupValues(IP_AGREEMENT_CREATION_CLASS_LOOKUP, lookupValues);
        const allField = ipMaintenanceAccordionViewData.fields.filter(x => x.componentInstance === IP_AGREEMENTS && x.name === ALL_CREATION_CLASSES);
        const all = allField && allField[0].data;
        let opts: string[] = []
        if (option.key === -1) {
            if (opts.indexOf(all) < 0) {
                opts = [all]
            }
        }
        else {
            opts = [...this.state.selectedCreationClasses.filter(x => x !== all)]
            const i = opts.indexOf(option.data, 0);
            if (option.selected && i === -1) {
                opts.push(option.data);
            }
            else {
                if (i > -1) {
                    opts.splice(i, 1);
                }
                if (opts.length === creationClassLookups.length) {
                    opts = [all]
                }
            }
        }
        this.setState({ selectedCreationClasses: opts, role: '' })
        this.getRoleOptions(opts);
    }

    formatIpLicensingRepresentations(ipLicensingRepresentations: IIpLicensingRepresentation[]): IIpLicensingRepresentation[] {

        if (ipLicensingRepresentations !== undefined) {
            ipLicensingRepresentations.forEach(function (value) {

                if (value.startDate != null && value.startDate.value === undefined) {
                    value.startDateString = new Date(value.startDate.toString()).toISOString().slice(0, 10);
                }

                if (value.endDate != null && value.endDate.value === undefined) {
                    value.endDateString = new Date(value.endDate.toString()).toISOString().slice(0, 10);
                }
            })
        }

        return ipLicensingRepresentations;
    }

    changeData = (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => {

        const { updateRepresentationIdsToDelete, ipLicensingRepresentations, updateIPNames,updateInterestedPartyField } = this.props;
        if (name === IP_LICENSING_REPRESENTATION_KEY) {
            let updatedIpLicensingRepresentations = ipLicensingRepresentations.filter(i => !ipRepresentationsIdsToRemove.includes(i.id));
            updateRepresentationIdsToDelete(ipRepresentationsIdsToRemove, updatedIpLicensingRepresentations ?? []);
        }
        if (name === IP_NAMES_KEY) {
            updateIPNames(value);
        }
        if (name===IP_REPRESENTATION_KEY){
            updateInterestedPartyField(value,"ipRepresentation" as any);
        }

    }

    dependentRowAction = (tableContentItem: any) => {

        const { addTab, setWorkGroupState } = this.props;

        let ipLicensingRepresentation: any = tableContentItem ? tableContentItem as IIpLicensingRepresentation : undefined;

        if (ipLicensingRepresentation.workGroup || tableContentItem.componentInstance === IP_LICENSING_REPRESENTATION_KEY) {

            addTab(WORK_GROUP_PAGE);

            setWorkGroupState(ipLicensingRepresentation);
        }
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], DATA_GRID_TABLE, IP_NAMES_KEY, DATA_GRID_TABLE, tabs, activeTab);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableEditableFieldsData,
            setEditableFields,
        } = this.props;

        setEditableFields(DATA_GRID_TABLE, IP_NAMES_KEY)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = IP_NAMES_EDITABLE_FIELD_TITLE;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableEditableFieldsData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    render() {
        const {
            dataGridTableData,
            ipNames,
            ipAgreements,
            ipMergedAccounts,
            ipLicensingRepresentations,
            ipMaintenanceGridsViewData,
            ipMaintenanceTimeRangeSelectorViewData,
            agreementSearchState,
            updateIpAgreementsFilterStateField,
            representationSearchState,
            updateIpRepresentationFilterStateField,
            isReadonly,
            lookupValues,
            ipMaintenanceAccordionViewData,
            tabs,
            activeTab,
            incrementTempID,
            ipRepresentations,
            customer,
            showModal,
            accountNumberValid
        } = this.props;

        const creationClass = ipMaintenanceAccordionViewData.fields.filter(x => x.componentInstance === IP_AGREEMENTS && x.name === CREATION_CLASS);
        const allField = ipMaintenanceAccordionViewData.fields.filter(x => x.componentInstance === IP_AGREEMENTS && x.name === ALL_CREATION_CLASSES);
        const all = allField && allField[0] && allField[0].data;

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_IPS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const {
            activeHeaderSection,
            selectedCreationClasses,
            roleOptions
        } = this.state;

        const filteredFields = ipMaintenanceGridsViewData.fields.filter(
            (field: IRepertoireField) =>
                field.name === activeHeaderSection
        );

        const { loaded } = this.state;
        if (!loaded)
            return <div className="loading" />

        let creationClassOptions = [];
        creationClassOptions.push({ key: -1, text: all, data: all })
        const creationClassLookups = getLookupValues(IP_AGREEMENT_CREATION_CLASS_LOOKUP, lookupValues);
        creationClassLookups && creationClassLookups.forEach(x => creationClassOptions.push({ key: x.typeID, text: x.description, data: x.code }));

        let mappedValues: number[] = selectedCreationClasses.indexOf(all) >= 0 ? [-1] : [];
        creationClassLookups && creationClassLookups.forEach(function (x) {
            if (selectedCreationClasses.filter(y => y === x.code).length > 0) {
                mappedValues.push(x.typeID);
            }
        });

        return (
            <>
                <div className="GridsView">
                    <div className="headerContainer">
                        {this.renderGridViewHeaderSections()}
                    </div>
                    {activeHeaderSection === IP_NAMES_KEY ?
                        <>
                        <div className="col-md-12 editableSectionHeading">
                                <button className="editBtn position-right" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                        <DataGridTable
                            tableContents={ipNames}
                            sortData={this.sortData.bind(this)}
                            dataGridTableData={dataGridTableData}
                            stateKey={filteredFields[0].name}
                            componentInstance={IP_NAMES_KEY}
                            showRowNumber={true}
                            isReadOnly={isReadonly}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                            changeData={this.changeData.bind(this)}  
                            /></> : <div> </div>}

                    {activeHeaderSection === IP_LICENSING_REPRESENTATION_KEY ? (
                    <>
                        <div className="topMargin">
                            <TimeRangeSelector
                                timeRangeSelectorData={ipMaintenanceTimeRangeSelectorViewData}
                                jobSearchState={undefined}
                                updateJobSearchStateField={undefined}
                                componentDataName={IP_MAINTENANCE_TIME_RANGE_SELECTOR}
                                agreementSearchState={agreementSearchState}
                                representationSearchState={representationSearchState}
                                updateIpRepresentationFilterStateField={updateIpRepresentationFilterStateField}
                                timeRangeOptions={getLookupValues(IP_AGREEMENT_TIME_RANGE_LOOKUP, lookupValues)}
                                dateRangeDefault={getLookupDefault(IP_AGREEMENT_TIME_RANGE_LOOKUP, lookupValues)}
                                componentName={IP_LICENSING_REPRESENTATION_KEY} />
                            <div className="row">
                                <div className="form-group col-md-3 col-sm-6">
                                    <label>{creationClass && creationClass[0].data}</label>
                                    <Dropdown
                                        selectedKeys={mappedValues}
                                        options={creationClassOptions}
                                        multiSelect={true}
                                        onChange={this.multiSelectChangeData.bind(this)}
                                        disabled={false} />
                                </div>
                                <div className="form-group col-lg-4 col-lg-push-5 col-md-4 col-md-push-5 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0">
                                    <ActionButton buttonText={this.viewData[SEARCH_DATA_FIELD]} buttonAction={this.onClickSearch.bind(this)} />
                                </div>
                            </div>
                        </div>
                        <DataGridTable
                            tableContents={this.formatIpLicensingRepresentations(ipLicensingRepresentations)}
                            sortData={this.sortData.bind(this)}
                            dataGridTableData={dataGridTableData}
                            stateKey={filteredFields[0].name}
                            componentInstance={IP_LICENSING_REPRESENTATION_KEY}
                            showRowNumber={true}
                            isReadOnly={isReadonly}
                            changeData={this.changeData.bind(this)}                            
                            tabs={tabs}
                            activeTab={activeTab}
                            dependentRowAction={this.dependentRowAction.bind(this)}
                            incrementTempID={incrementTempID} />
                    </>) : 
                    <div> </div>}

                    {activeHeaderSection === IP_REPRESENTATION_KEY ? (
                    <>
                        <div className="topMargin">
                            <TimeRangeSelector
                                timeRangeSelectorData={ipMaintenanceTimeRangeSelectorViewData}
                                jobSearchState={undefined}
                                updateJobSearchStateField={undefined}
                                componentDataName={IP_MAINTENANCE_REPRESENTATION_TIME_RANGE_SELECTOR}
                                representationSearchState={representationSearchState}
                                updateIpRepresentationFilterStateField={updateIpRepresentationFilterStateField}
                                timeRangeOptions={getLookupValues(IP_AGREEMENT_TIME_RANGE_LOOKUP, lookupValues)}
                                dateRangeDefault={getLookupDefault(IP_AGREEMENT_TIME_RANGE_LOOKUP, lookupValues)}
                                componentName={IP_REPRESENTATION_KEY} />
                            <div className="row">
                                <div className="form-group col-md-3 col-sm-6">
                                    <label>{creationClass && creationClass[0].data}</label>
                                    <Dropdown
                                        selectedKeys={mappedValues}
                                        options={creationClassOptions.sort((a, b) => a.text.localeCompare(b.text))}
                                        multiSelect={true}
                                        onChange={this.multiSelectChangeData.bind(this)}
                                        disabled={false} />
                                </div>
                                <div className="form-group col-md-3 col-sm-6">
                                    <label>{'Role'}</label>
                                    <DropdownDataInput  
                                        label={this.viewData[IP_ROLE_STATE_KEY]}
                                        fieldName={IP_ROLE_STATE_KEY}
                                        value={this.state.role}
                                        changeData={this.changeRoleSelector.bind(this)}
                                        options={roleOptions}
                                        allowNull={true}
                                        readOnly={false}
                                        isHidden={false}
                                         />
                                </div>
                            </div>
                            
                            <div className="flexRow flexJustifyContentRight">
                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0">
                                    <ActionButton buttonText={this.viewData[SEARCH_DATA_FIELD]} buttonAction={this.onClickSearch.bind(this)} />
                            </div>
                            </div>
                        </div>
                        <DataGridTable
                            tableContents={ipRepresentations}
                            sortData={this.sortData.bind(this)}
                            dataGridTableData={dataGridTableData}
                            stateKey={filteredFields[0].name}
                            componentInstance={IP_REPRESENTATION_KEY}
                            showRowNumber={true}
                            isReadOnly={isReadonly}
                            changeData={this.changeData.bind(this)}                            
                            tabs={tabs}
                            activeTab={activeTab}
                            dependentRowAction={this.dependentRowAction.bind(this)}
                            incrementTempID={incrementTempID}
                            tableActions={[{ name: ADD_NEW_ACTION,disabled:!accountNumberValid },{name: REMOVE_ACTION }]} 
                            creationClassOptions={creationClassOptions}
                            lookupValues={lookupValues}
                            customer={customer}
                            showModal={showModal}

                            />
                    </>) : 
                    <div> </div>}

                    {activeHeaderSection === IP_AGREEMENTS_KEY ?
                        <>
                            <div className="topMargin">
                                <TimeRangeSelector
                                    timeRangeSelectorData={ipMaintenanceTimeRangeSelectorViewData}
                                    jobSearchState={undefined}
                                    updateJobSearchStateField={undefined}
                                    componentDataName={IP_MAINTENANCE_TIME_RANGE_SELECTOR}
                                    agreementSearchState={agreementSearchState}
                                    updateIpAgreementsFilterStateField={updateIpAgreementsFilterStateField}
                                    timeRangeOptions={getLookupValues(IP_AGREEMENT_TIME_RANGE_LOOKUP, lookupValues)}
                                    dateRangeDefault={getLookupDefault(IP_AGREEMENT_TIME_RANGE_LOOKUP, lookupValues)}
                                    componentName={IP_AGREEMENTS_KEY}
                                />

                                <div className="row">
                                    <div className="form-group col-md-3 col-sm-6">
                                        <label>{creationClass && creationClass[0].data}</label>
                                        <Dropdown
                                            selectedKeys={mappedValues}
                                            options={creationClassOptions}
                                            multiSelect={true}
                                            onChange={this.multiSelectChangeData.bind(this)}
                                            disabled={false}
                                        />
                                    </div >

                                    <div className="form-group col-lg-4 col-lg-push-5 col-md-4 col-md-push-5 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0">
                                        <ActionButton buttonText={this.viewData[SEARCH_DATA_FIELD]} buttonAction={this.onClickSearch.bind(this)} />
                                    </div>
                                </div>
                            </div>

                            <DataGridTable
                                tableContents={ipAgreements}
                                sortData={this.sortData.bind(this)}
                                dataGridTableData={dataGridTableData}
                                stateKey={filteredFields[0].name}
                                componentInstance={IP_AGREEMENTS_KEY}
                                showRowNumber={true}
                                isReadOnly={isReadonly}
                            />
                        </>
                        : <div> </div>}
                    
                    {activeHeaderSection === IP_MERGED_ACCOUNTS_KEY ?
                        <>
                        <div className="col-md-12 editableSectionHeading">
                            <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                        <DataGridTable
                            tableContents={ipMergedAccounts}
                            sortData={this.sortData.bind(this)}
                            dataGridTableData={dataGridTableData}
                            stateKey={filteredFields[0].name}
                            componentInstance={IP_MERGED_ACCOUNTS_KEY}
                            showRowNumber={true}
                            isReadOnly={isReadonly}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                            changeData={this.changeData.bind(this)}  
                            /></> : <div> </div>}

                </div>
            </>
        );
    }
}