import * as React from "react";
import SearchView from "../../../repertoire/components/searchView/SearchView";
import { ASSING_WORKFLOW_VIEW, CONTRIBUTOR_COMMENTS_VIEW, IMPORT_DATA_VIEW, LOADING_VIEW, MESSAGE_VIEW, SEARCH_VIEW, TERRITORY_SELECTION_VIEW, TERRITORY_SELECTION_WITH_RADIO_BTN_VIEW, UNPAID_CLAIMS_WORK_SEARCH_VIEW, UPDATE_WORKFLOW_VIEW, WORK_NOT_FOUND_VIEW, WORK_SUBMISSION_VIEW, YES_NO_PROMPT_VIEW } from "../../../repertoire/Consts";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import { showModal, hideModal, updateComment } from "../../../redux/reducers/ModalReducer";
import { addArtistToWork, setDataSource, searchDataChanged, addWorkToProduct } from "../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../redux/types/ITabReduxItem";
import LoadingView from "../../../repertoire/components/modalViews/loadingView/LoadingView";
import PopUpInfo from "../../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import WorkNotFoundView from "../../../repertoire/components/modalViews/workNotFoundView/WorkNotFoundView";
import YesNoPromptView from "../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import { DataSource } from "../../../repertoire/types/DataSource";
import { IArtist } from "../../../repertoire/types/IArtist";
import { IArtistSearchQuery } from "../../../repertoire/types/IArtistSearchQuery";
import { IContributorItem } from "../../../repertoire/types/IContributorItem";
import { IContributorSearchQuery } from "../../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../types/IContributorSearchResult";
import { IP } from "../../../repertoire/types/IP";
import { IWorksSearchQuery } from "../../../repertoire/types/IWorksSearchQuery";
import { IWorksSearchResult } from "../../../repertoire/types/IWorksSearchResult";
import { IDraftWorkSearchQuery } from "../../types/IDraftWorkSearchQuery";
import { IDraftWorkSearchResult } from "../../types/IDraftWorkSearchResult";
import { IRegisteredWorkSearchQuery } from "../../types/IRegisteredWorkSearchQuery";
import { IRegisteredWorkSearchResult } from "../../types/IRegisteredWorkSearchResult";
import { IAllRegisteredWorkSearchQuery } from "../../types/IAllRegisteredWorkSearchQuery";
import { IAllRegisteredWorkSearchResult } from "../../types/IAllRegisteredWorkSearchResult";
import DraftSetListSearchView from "../../draftSetLists/components/DraftSetListSearchView";
import { DRAFT_AGREEMENTS_PAGE_VIEW, SET_LIST_MODAL_SEARCH_VIEW } from "../../Consts";
import { IDraftSetListSearchQuery } from "../../types/IDraftSetListSearchQuery";
import { IDraftSetListSearchResult } from "../../types/IDraftSetListSearchResult";
import ContributorCommentView from "../../../repertoire/components/modalViews/contributorCommentView/ContributorCommentView";
import UnpaidClaimsWorkSearchModalContainer from "../../../repertoire/components/modalViews/unpaidClaimsWorkSearchView/UnpaidClaimsWorkSearchModalContainer";
import { IIPsSearchQuery } from "../../../repertoire/types/IIPsSearchQuery";
import { IIPsSearchResult } from "../../../repertoire/types/IIPsSearchResult";
import TerritorySelectionView from "../../../repertoire/components/modalViews/territorySelectionView/TerritorySelectionView";
import TerritorySelectionWithRadioBtnView from "../../../repertoire/components/modalViews/territorySelectionWithRadioBtnView/TerritorySelectionWithRadioBtnView";
import { IAgreementState, IAgreementStateKeys } from "../../../redux/types/IAgreementState";
import { SELECT_IP } from "../../draftAgreements/components/draftAgreementsAssignee/DraftAgreementsMaintenanceAssigneeView";
import { updateDraftAgreementField } from "../../../redux/reducers/MembersPortalReducer";
import DraftAgreementsMaintenanceCommentsModalView from "../../draftAgreements/components/draftAgreementsMaintenanceCommentsDataView/DraftAgreementsMaintenanceCommentsModalView";


export interface IRepertoireModalProps {
    showModal:(
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    ) =>void;
    hideModal: typeof hideModal;
    updateModalComment?: typeof updateComment;
    addArtistToWork?: typeof addArtistToWork;
    updateWorkContributorIP: (dataSource: DataSource, workContributor: IContributorItem, ip: IP) => void;
    modalContent: string;
    modalComponentInstance: string;
    modalProps: any;
    displayModalCloseButton: boolean;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    workNotFoundViewData: IRepertoireComponentDataItem;
    loadingViewData: IRepertoireComponentDataItem;
    yesNoPromptViewData: IRepertoireComponentDataItem;
    dataGridTableData: IRepertoireComponentDataItem;
    dataSource: string;
    setDataSource?: typeof setDataSource;
    agreementsDataSource?: string;
    productDataSource?: string;
    modalTitle: string;
    searchArtists?: (query: IArtistSearchQuery, dataSource: string) => void;
    artistsSearchResults?: IArtist[],
    contributorsSearchResults?: IContributorSearchResult[],
    searchContributors?: (
        query: IContributorSearchQuery
    ) => void;
    searchDraftSetLists?: (query: IDraftSetListSearchQuery) => void;
    draftSetListsSearchResults?: IDraftSetListSearchResult[],
    searchSuccessful: boolean;
    componentInstance?: string;
    modalMessage: string;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    component?: string;
    lookupValues: ILookupDictionary;
    searchDataChanged: typeof searchDataChanged;
    isReadOnlyModal?: boolean;
    tabs?: ITabReduxItem[];
    activeTab?: number;
    addWorkToProduct?: typeof addWorkToProduct;
    searchWorks?: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    worksSearchResults?: IWorksSearchResult[];
    searchDraftWorks?: (query: IDraftWorkSearchQuery) => void;
    searchRegisteredWorks?: (query: IRegisteredWorkSearchQuery) => void;
    draftWorkSearchResults: IDraftWorkSearchResult[];
    registeredWorkSearchResults?: IRegisteredWorkSearchResult[];
    addWorkToSetList?: (work: IDraftWorkSearchResult) => void;
    addSetListToLivePerformance?: (setList: IDraftSetListSearchResult) => void;
    allRegisteredWorkSearchResults: IAllRegisteredWorkSearchResult[];
    searchAllRegisteredWorks: (query: IAllRegisteredWorkSearchQuery) => void;
    currentUserNameNumber?: string;
    saveContributorComment: (contributorID: number, comment: string) => void;
    searchIPs?: (query: IIPsSearchQuery) => void;
    draftAgreementIPSearchResult?:IIPsSearchResult[];
    updateDraftAgreementField?: typeof updateDraftAgreementField;
    draftAgreementWorksSearchResult?:IWorksSearchResult[];
    updateDraftAgreementWorkField?:(value: any, fieldName: IAgreementStateKeys, index?: number)=>void;
    agreement?: IAgreementState;
    modalIndex?: number;
    modalKey?:string;
    defaultTerritories?:any;
}

export interface IShowFieldSettingViewModalProps {
    componentViewData: IRepertoireComponentDataItem;
    componentInstance: string;
    dataGridViewData: IRepertoireComponentDataItem;
    onClickSave: () => void;
    inverted?: boolean;
}


const MembersPortalModal: React.FC<IRepertoireModalProps> = ({
    hideModal,
    modalContent,
    modalComponentInstance,
    displayModalCloseButton,
    modalProps,
    searchViewData,
    searchResultsTableData,
    workNotFoundViewData,
    loadingViewData,
    dataSource,
    setDataSource,
    agreementsDataSource,
    productDataSource,
    modalTitle,
    searchArtists,
    searchContributors,
    artistsSearchResults,
    contributorsSearchResults,
    addArtistToWork,
    updateWorkContributorIP,
    yesNoPromptViewData,
    searchSuccessful,
    modalMessage,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    searchWorks,
    addWorkToProduct,
    worksSearchResults,
    searchDraftWorks,
    draftWorkSearchResults,
    searchRegisteredWorks,
    registeredWorkSearchResults,
    addWorkToSetList,
    addSetListToLivePerformance,
    allRegisteredWorkSearchResults,
    searchDraftSetLists,
    draftSetListsSearchResults,
    tabs,
    activeTab,
    currentUserNameNumber,
    searchAllRegisteredWorks,
    saveContributorComment,
    searchIPs,
    updateDraftAgreementField,
    lookupValues,
    isReadOnlyModal,
    agreement,
    draftAgreementWorksSearchResult,
    draftAgreementIPSearchResult,
    updateDraftAgreementWorkField,
    modalIndex,
    modalKey,
    defaultTerritories
}) => {

    const renderModalContent = () => {
        switch (modalContent) {
            case UNPAID_CLAIMS_WORK_SEARCH_VIEW: {
                return  <UnpaidClaimsWorkSearchModalContainer
                         searchRegisteredWorks={searchRegisteredWorks}
                         searchViewData={searchViewData}
                         registeredWorkSearchResults={registeredWorkSearchResults}
                         searchSuccessful={searchSuccessful}
                         searchResultsTableData={searchResultsTableData}
                         hideModal={hideModal}  
                         modalTitle={modalTitle}
                         usage={modalProps["usage"]}
                 />
            }
            case SEARCH_VIEW: {
                const updateContributorIP = (workContributor: IContributorItem, ip: any) => {
                    if(modalTitle === SELECT_IP) {
                        const assignees = agreement.agreementAdminIP;
                        assignees[modalIndex] = {
                            ...modalProps,
                            administeredName: {
                                ...modalProps.administeredName,
                                value: ip.ipName ? ip.ipName : '',
                            },
                            administeredByIPBaseNumber: { 
                                ...modalProps.administeredBaseNumber,
                                value: ip.ipBaseNumber ? ip.ipBaseNumber : '',
                            },
                            societyAffiliation: {
                                ...modalProps.societyAffiliation,
                                value: ip.societyAffiliation ? ip.societyAffiliation : '',
                            },
                            assigneeNameNumber: {
                                ...modalProps.assigneeNameNumber,
                                value: ip.ipNameNumber ? ip.ipNameNumber : '',
                            },
                        }
                        updateDraftAgreementField(assignees, 'agreementAdminIP')
                    } else {
                    updateWorkContributorIP(dataSource as DataSource, workContributor, ip)
                    }
                };
                const updateDraftAgreementIPNameNumberGeneral = (workContributor: IContributorItem, ip: any) => {
                    if(modalComponentInstance === 'ips')  {
                        const IpData = {
                            ipName:  ip.ipName ? ip.ipName : '',
                            ipiNumber:  ip.ipiNumber ? ip.ipiNumber : '',
                            ipBaseNumber: ip.ipBaseNumber ? ip.ipBaseNumber : '',
                            ipNameNumber: ip.ipNameNumber ? ip.ipNameNumber : '',
                            societyAffiliation:  ip.societyAffiliation ? ip.societyAffiliation : '',
                    }

                    updateDraftAgreementField(IpData, 'ipName')
                } else {
                    updateWorkContributorIP(dataSource as DataSource, workContributor, ip)
                    }
                };

                const newDataSource = (modalProps && modalProps["dataSource"]) ? DataSource.Repertoire : dataSource;
                return (
                    modalComponentInstance === SET_LIST_MODAL_SEARCH_VIEW ?
                        <DraftSetListSearchView
                            searchViewData={searchViewData}
                            searchResultsTableData={searchResultsTableData}
                            componentInstance={SET_LIST_MODAL_SEARCH_VIEW}
                            modalProps={undefined}
                            dataSource={DataSource.Repertoire}
                            hideModal={hideModal}
                            searchDraftSetLists={searchDraftSetLists}
                            draftSetListsSearchResults={draftSetListsSearchResults ? draftSetListsSearchResults : tabs[activeTab].draftSetListSearchResult || []}
                            searchSuccessful={searchSuccessful}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            resetPagination={resetPagination}
                            updatePagination={updatePagination}
                            searchDisabled={false}
                            addSetListToLivePerformance={addSetListToLivePerformance}
                            currentUserNameNumber={currentUserNameNumber}
                        />
                        :
                        <SearchView
                            searchViewData={searchViewData}
                            componentInstance={modalComponentInstance}
                            modalProps={modalProps}
                            dataSource={newDataSource}
                            searchResultsTableData={searchResultsTableData}
                            searchArtists={searchArtists}
                            searchContributors={searchContributors}
                            searchWorks={searchWorks}
                            artistsSearchResults={artistsSearchResults}
                            contributorsSearchResults={contributorsSearchResults}
                            worksSearchResults={worksSearchResults}
                            addArtistToWork={addArtistToWork}
                            updateWorkContributorIP={modalComponentInstance === 'ips' && modalTitle !== SELECT_IP ? updateDraftAgreementIPNameNumberGeneral : updateContributorIP}
                            hideModal={hideModal}
                            searchSuccessful={searchSuccessful}
                            indexOfFirstResult={indexOfFirstResult}
                            indexOfLastResult={indexOfLastResult}
                            resultsPerPage={resultsPerPage}
                            currentPage={currentPage}
                            resetPagination={resetPagination}
                            updatePagination={updatePagination}
                            addWorkToProduct={addWorkToProduct}
                            searchDraftWorks={searchAllRegisteredWorks}
                            draftWorkSearchResults={allRegisteredWorkSearchResults}
                            isModalSearch={true}
                            isMembersPortal={true}
                            addWorkToSetList={addWorkToSetList}
                            searchIPs={searchIPs}
                            draftAgreementWorksSearchResult={draftAgreementWorksSearchResult}
                            draftAgreementIPSearchResult={draftAgreementIPSearchResult}
                            updateDraftAgreementWorkField={updateDraftAgreementWorkField}
                            modalIndex={modalIndex}
                            updateDraftAgreementField={updateDraftAgreementField}
                            modalKey={modalKey}
                        />
                );
            }
            case DRAFT_AGREEMENTS_PAGE_VIEW: {
                return (
                    <DraftAgreementsMaintenanceCommentsModalView
                        showModal={showModal}
                        hideModal={hideModal}
                        activeTab={activeTab}
                        tabs={tabs}
                        agreement={agreement}
                        modalProps ={modalProps}
                        updateDraftAgreementField={updateDraftAgreementField}
                        index={modalIndex}
                        title={modalTitle}
                    />
                )
            }
            case WORK_NOT_FOUND_VIEW: {
                return <WorkNotFoundView fields={workNotFoundViewData.fields} workId={modalProps} />
            }
            case LOADING_VIEW: {
                return <LoadingView fields={loadingViewData.fields} />
            }
            case MESSAGE_VIEW: {
                return <PopUpInfo textToScreen={modalProps.textToScreen} />
            }
            case YES_NO_PROMPT_VIEW: {
                return <YesNoPromptView fields={yesNoPromptViewData.fields} props={modalProps} />
            }
            case CONTRIBUTOR_COMMENTS_VIEW: {
                return <ContributorCommentView saveContributorComment={saveContributorComment} contributor={modalProps} />
            }
            case TERRITORY_SELECTION_WITH_RADIO_BTN_VIEW :{                
                return <TerritorySelectionWithRadioBtnView
                        hideModal={hideModal}
                        agreement={modalProps}
                        updateDraftAgreementField={updateDraftAgreementField}
                        lookupValues={lookupValues}
                        isReadOnly={isReadOnlyModal}
                        title={modalTitle}
                        defaultTerritories={defaultTerritories}
                    />
            }
            default:
                return null;
        }
    };

    const renderMessage = () => {
        if (modalMessage != undefined) {
            return (<div>{modalMessage}</div>);
        }
    }

    const renderModal = () => {
        const styleModalContent = (modalContent === ASSING_WORKFLOW_VIEW || modalContent === UPDATE_WORKFLOW_VIEW) ? "modalBody noFlex" : "modalBody";
        if (modalContent === LOADING_VIEW) {
            return <div className="loaderWrapper">
            <div className="spinner-dualball">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        }
        
        if (modalContent === WORK_SUBMISSION_VIEW) {
            return (
                <div className="repertoireModal">
                    {renderModalContent()}
                </div>
            );
        }
        if (modalContent === DRAFT_AGREEMENTS_PAGE_VIEW){
            return (
                <div className="repertoireModal">
                    {renderModalContent()}
                </div>
            );
        }
        if (modalContent === TERRITORY_SELECTION_WITH_RADIO_BTN_VIEW){
            return (
                <div className="repertoireModal">
                    {renderModalContent()}
                </div>
            );
        }
        else {
            return (
                <div className="repertoireModal">
                    <div className={"modal " + (modalContent === IMPORT_DATA_VIEW ? "importData" : "")}>
                        <div>
                            {displayModalCloseButton && (
                                <div className="closeButton" onClick={() => hideModal()}>
                                    <img src={"assets/close.svg"} className="closeIcon" />
                                </div>
                            )}
                        </div>
                        <div className="fixedPart" />
                        <div className="dynamicPart">
                            <div className="title">{modalTitle}</div>
                            <div className="modalBody">{renderMessage()}</div>
                            <div className={styleModalContent}>{renderModalContent()}</div>
                        </div>
                        <div className="fixedPart" />
                    </div>
                </div>
            );
        }
    }

    return (
        renderModal()
    );
};

export default MembersPortalModal;
