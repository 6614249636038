import IconTextButton from "../../../../components/iconTextButton/IconTextButton";
import { ILicenseWorksRowType } from "../../../../types/usageTypes/ILicenseWorksRowType";

export interface ILicensesMaintenanceWorkGridToolBar {

    data?: ILicenseWorksRowType[];
    hasChanges?: number[]
    showModal: () => void;
    disabled?: boolean;
    exportPrice: () => void;
    onFileChange: (event) => void;
    calculatePrice: () => void;
    importDisabled?: boolean;
}

const LicensesMaintenanceWorkGridToolBar = (props: ILicensesMaintenanceWorkGridToolBar) => {

    const { showModal, disabled, exportPrice, onFileChange, calculatePrice, importDisabled } = props;


    return (

        <div
            className="toolbarWrapper"
            id={"toolbar"}
        >
            <div style={{paddingLeft:"6px", paddingRight:"6px" , paddingBottom:"3px"}}>
                <IconTextButton text="Add Work" icon={"icon ms-Icon ms-Icon--Add"} disabled={disabled}
                    onClick={() => showModal()} />
            </div>

            <div style={{ paddingLeft: "6px", paddingBottom:"3px" }}>
                <IconTextButton text="Calculate Price" icon={"icon ms-Icon ms-Icon--CheckMark"} disabled={disabled} onClick={() => calculatePrice()}/>
            </div>

            <div style={{paddingLeft:"6px", paddingRight:"6px", paddingBottom:"3px"}}>
                <IconTextButton text="Export Price" icon={"icon ms-Icon ms-Icon--Download"} disabled={disabled} onClick={() => exportPrice()} />
            </div>

            <div style={{ paddingLeft: "6px", paddingRight: "6px", paddingBottom: "3px" }}>
                <div className={["IconTextButton", !(!disabled || !importDisabled) && "disabled"].join(' ')} >
                    <label htmlFor="prices-file" id="fileinput-label" style={{ marginTop: "0px", marginBottom: "5px", fontFamily: "inherit" }}  >
                        <i data-testid={"icon-text-btn"} className={"icon ms-Icon ms-Icon--Upload"} />
                        Import Price
                    </label>
                </div>         
                <input type="file" onChange={onFileChange} id="prices-file" hidden disabled={disabled || importDisabled} />
            </div>

            <div style={{ paddingLeft: "6px", paddingRight: "6px", paddingBottom: "3px" }}>
                <div className={["IconTextButton", !(!disabled || !importDisabled) && "disabled"].join(' ')} >
                    <label htmlFor="prices-file" id="fileinput-label" style={{ marginTop: "0px", marginBottom: "5px", fontFamily: "inherit" }}  >
                        <i data-testid={"icon-text-btn"} className={"icon ms-Icon ms-Icon--Upload"} />
                        Import Work
                    </label>
                </div>         
                <input type="file" onChange={onFileChange} id="prices-file" hidden disabled={disabled || importDisabled} />
            </div>
        </div>
    );
}

export default LicensesMaintenanceWorkGridToolBar