import React from "react";

import IShortCutKeys from "../../../redux/types/IShortCutKeys";
import { SaveOption } from "../../types/SaveOption";
import { ADJUSTMENT_MAINTENANCE_TOOLBAR, PRODUCT_MAINTENANCE_TOOLBAR, WORK_MAINTENANCE_TOOLBAR } from "../../Consts";
import { DataSource } from "../../types/DataSource";

interface IToolbarComponentEventListenerProps {
    saveMethod: (saveOption: SaveOption) => void;
    component: string;
    dataSource: string;
    changesMade: boolean; 
    shortCutKeys: IShortCutKeys[]
}


const ToolbarEventListener: React.FC<IToolbarComponentEventListenerProps> = ({
    saveMethod,
    component,
    dataSource,
    changesMade,
    shortCutKeys 
}) => {
    const [keysPressed, setKeysPressed] = React.useState({});
    React.useEffect(() => {
        window.addEventListener('keydown', (event) => {
            let keydown = keysPressed;
            keydown[event.key] = true;
            setKeysPressed(keydown);
         });
           window.addEventListener('keydown', saveKeyListener)
           window.addEventListener('keyup', (event) => {
            let keydown = keysPressed;
            keydown[event.key] = false;
             setKeysPressed(keydown);
          });


          return () => {
            window.removeEventListener('keydown', saveKeyListener);
            window.removeEventListener('keyup', (event) => {
                let keydown = keysPressed;
            keydown[event.key] = false;
             setKeysPressed(keydown);
             });
             window.removeEventListener('keydown', (event) => {
                let keydown = keysPressed;
                keydown[event.key] = true;
                 setKeysPressed(keydown);
             });
          }
    }, []);
    const saveKeyListener = (e) => {

        const areCorrectKeysPressed = (actionCode, keysPressed) => {
            const shortCutKeysCommaSeparatedString = shortCutKeys && shortCutKeys.find(key => key.actionCode === actionCode)?.key
            const shortCutKeysArray = shortCutKeysCommaSeparatedString.split(',');
            let filteredRequiredPressedKeys = shortCutKeysArray && shortCutKeysArray.filter(keyString => keysPressed[keyString]);
            return filteredRequiredPressedKeys?.length === shortCutKeysArray?.length;
        }
        if (areCorrectKeysPressed("SAVE_DATA", keysPressed) && changesMade) {
            e.preventDefault();
            saveMethod(SaveOption.Save)
        }

        if (areCorrectKeysPressed("SAVE_AND_MATCH", keysPressed) && changesMade) {
             saveMethod(SaveOption.Save)
        }

        if ((component === WORK_MAINTENANCE_TOOLBAR || component === PRODUCT_MAINTENANCE_TOOLBAR) && dataSource === DataSource.Intray) {
                if ( areCorrectKeysPressed("SAVE_MATCH_POST_NEW", keysPressed) && changesMade) {
                    saveMethod(SaveOption.SaveMatchPostNew)
                }
                if (areCorrectKeysPressed("SAVE_MATCH_AUTO_MERGE", keysPressed) && changesMade) {
                    saveMethod(SaveOption.SaveMatchAutoMerge)
                }
        }
        if (component === ADJUSTMENT_MAINTENANCE_TOOLBAR) {
            if (areCorrectKeysPressed("SAVE_MATCH_POST_NEW", keysPressed) && changesMade) {
                saveMethod(SaveOption.SavePost)
            }
        }
        }




    return <></>
    
}
export default ToolbarEventListener;