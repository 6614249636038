import { connect } from "react-redux";
import { Dispatch } from "redux";
import IAppState from "../../../redux/types/IAppState";
import { LoginPage } from "./LoginPage";
import { RouteChildrenProps } from 'react-router';
import {
    getAccountComponentDataThunk,
    getAppVersionNumberThunk,
    loginThunk,
    loginTypeThunk,
    logoutThunk,
} from "../../../redux/thunks/AccountThunks";
import { ILoginViewModel } from "../../types/ILoginViewModel";

export default connect(
    (state: IAppState, props: RouteChildrenProps) => ({
        location: props.match.path,
        loginFailed: state.account.loginFailed,
        isSignedIn: state.account.isSignedIn,
        loginPageDataItem: state.account.componentData.LoginPage,
        redirect: state.router?.location || undefined,
        appVersionNumber: state.account.appVersionNumber,
        loginType: state.account.loginType,
        currentUserNameNumber: sessionStorage.currentUserNameNumber,
    }),
    (dispatch: Dispatch) => ({ 
        login: (viewModel: ILoginViewModel, redirect: any) => dispatch<any>(loginThunk(viewModel, redirect)),
        logout: () => dispatch<any>(logoutThunk()),
        getAccountFields: () => dispatch<any>(getAccountComponentDataThunk()), 
        getAppVersionNumber: () => dispatch<any>(getAppVersionNumberThunk()),
        getLoginType: () => dispatch<any>(loginTypeThunk()),
    })
)(LoginPage);