export enum ManualMergeOptions {
    PostNew = "PostNew",
    MergeControlled = "Merge Controlled",
    MergeAll = "Merge All",
    MergeTitlesNumbersAndArtists = "Merge Titles, Numbers and Artists Only",
    MergeAllAndReplaceIPs = "Merge All and Replace IPs",
    MergeRepertoire = "Merge Repertoire",
    MergeAdditionalWorkNumbers = "Merge Additional Work Numbers",
    MergeAllAndReplaceProductWorks = "Merge All And Replace Product Works",
    MergeRepertoireTitlesNumbers = "Merge Titles Numbers"
}