import * as React from "react";
import { hideModal, showModal } from "../../../redux/reducers/ModalReducer";
import { ILookupDictionary } from "../../../lookup/types/ILookupDictionary";
import YesNoPromptView from "../../../repertoire/components/modalViews/yesNoPromptView/YesNoPromptView";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { JOBS_IN_PROGRESS_MODAL, MESSAGE_VIEW, REPERTOIRE_PAGE_JOBS, YES_NO_PROMPT_VIEW } from "../../../repertoire/Consts";
import { SEARCH_VIEW } from "../../../repertoire/Consts";
import SearchView from "../../../repertoire/components/searchView/SearchView";
import { IContributorSearchQuery } from "../../../repertoire/types/IContributorSearchQuery";
import { IContributorSearchResult } from "../../../repertoire/types/IContributorSearchResult";
import { IP } from "../../../repertoire/types/IP";
import { IStoragePathItemData } from '../../../dataingestion/types/IStoragePathItemData';
import PopUpInfo from "../../../repertoire/components/modalViews/popUpInfo/PopUpInfo";
import { IShoppingCartSearchQuery } from "../../../repertoire/types/IShoppingCartSearchQuery";
import { IShoppingCartSearchResult } from "../../../repertoire/types/IShoppingCartSearchResult";
import JobsInProgressModal from "../../../repertoire/components/jobsInProgressModal/JobsInProgressModal";
import { INGESTION_PAGE_JOBS } from "../../Consts";

export interface IDataIngestionModalProps {
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    modalTitle: string;
    modalMessage: string;
    modalContent: string;
    modalProps: any;
    displayModalCloseButton: boolean;
    lookups: ILookupDictionary;
    yesNoPromptViewData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    modalComponentInstance: string;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchContributors: (
        query: IContributorSearchQuery
    ) => void;
    contributorsSearchResults: IContributorSearchResult[],
    searchSuccessful: boolean;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    updateStoragePathItemIP?: (storagePathItemData: IStoragePathItemData, ip: IP) => void;
    searchLicenses: (query: IShoppingCartSearchQuery) => void;
    licensesSearchResults: IShoppingCartSearchResult[];
    updateLicenseImportShoppingCart?: (storagePathItemData: IStoragePathItemData, shoppingCart: IShoppingCartSearchResult) => void;
}

const DataIngestionModal: React.SFC<IDataIngestionModalProps> = ({
    showModal,
    hideModal,
    modalTitle,
    modalMessage,
    modalContent,
    modalProps,
    displayModalCloseButton,
    yesNoPromptViewData,
    searchViewData,
    modalComponentInstance,
    searchResultsTableData,
    searchContributors,
    contributorsSearchResults,
    searchSuccessful,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    updateStoragePathItemIP,
    searchLicenses,
    licensesSearchResults,
    updateLicenseImportShoppingCart
}) => {

    const renderModalContent = () => {
        switch (modalContent) {
            
            case JOBS_IN_PROGRESS_MODAL: {
                return <div className="repertoireModal"> <JobsInProgressModal hideModal={hideModal} jobsToDisplay={REPERTOIRE_PAGE_JOBS} /></div>
            }
            case YES_NO_PROMPT_VIEW: {
                return <YesNoPromptView fields={yesNoPromptViewData.fields} props={modalProps} />;
            }
            case MESSAGE_VIEW: {
                return <PopUpInfo textToScreen={modalProps.textToScreen} />
            }
            case SEARCH_VIEW: {
                return (
                    <SearchView
                        searchViewData={searchViewData}
                        componentInstance={modalComponentInstance}
                        modalProps={modalProps}
                        dataSource={undefined}
                        agreementsDataSource={undefined}
                        searchResultsTableData={searchResultsTableData}
                        searchArtists={undefined}
                        searchContributors={searchContributors}
                        searchWorks={undefined}
                        artistsSearchResults={undefined}
                        contributorsSearchResults={contributorsSearchResults}
                        worksSearchResults={undefined}
                        addArtistToWork={undefined}
                        addArtistToProduct={undefined}
                        addWorkToProduct={undefined}
                        updateWorkContributorIP={undefined}
                        updateProductContributorIP={undefined}
                        updateAgreementIP={undefined}
                        updateStoragePathItemIP={updateStoragePathItemIP}
                        updateAgreementWork={undefined}
                        updateAgreementCarveOutProduct={undefined}
                        updateMatchingWork={undefined}
                        hideModal={hideModal}
                        searchSuccessful={searchSuccessful}
                        updateRelatedWork={undefined}
                        updateWorkSourceIP={undefined}
                        expandedWorkResults={undefined}
                        expandedProductResults={undefined}
                        expandAll={undefined}
                        expandWorkResult={undefined}
                        expandProductResult={undefined}
                        expandAllResults={undefined}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        currentPage={currentPage}
                        resetPagination={resetPagination}
                        updatePagination={updatePagination}
                        searchLicenses={searchLicenses}
                        licensesSearchResults={licensesSearchResults}
                        updateLicenseImportShoppingCart={updateLicenseImportShoppingCart}

                    />
                );
            }
            default:
                return null;
        }
    };

    const renderMessage = () => {
        if (modalMessage != undefined) {
            return (<div>{modalMessage}</div>);
        }
    }

    const renderModal = () => {
        const styleModalContent = "modalBody";
        return (
            <div className="repertoireModal">
            <div className="modal">
                <div>
                    {displayModalCloseButton && (
                        <div className="closeButton" onClick={() => hideModal()}>
                            <img src={"assets/close.svg"} className="closeIcon" />
                        </div>
                    )}
                </div>
                <div className="fixedPart" />
                <div className="dynamicPart">
                    <div className="title">{modalTitle}</div>
                    
                    <div className="modalBody">{renderMessage()}</div>
                    <div className={styleModalContent}>{renderModalContent()}</div>
                </div>
                <div className="fixedPart" />
            </div>
        </div>
);
    }

    return (
        renderModal()
    );
};

export default DataIngestionModal;
