import * as React from "react";
import { SITE_PAGE_FORM_ITEM_SIZE } from "../Consts";

export interface ITextDisplayProps {
    label?: string;
    value?: any;
    fieldName?: any;
    fieldType?: string;
    maxLength?: number;
    onClick?: any;
    isHidden: boolean;
    isOnBlurOn?: boolean
    onBlurEvent?: any;
}

interface ITextDisplayState { }

export const SizedTextDisplay = (props: ITextDisplayProps) => {

    if (props.isHidden)
        return null;

    return <div className={SITE_PAGE_FORM_ITEM_SIZE}>
        <TextDisplay {...props} />
    </div>
}

export default class TextDisplay extends React.PureComponent<
    ITextDisplayProps,
    ITextDisplayState
> {
    constructor(props: ITextDisplayProps) {
        super(props);

        this.state = {};
    }


    render() {
        const {
            label,
            value,
            fieldName,
            fieldType,
            maxLength,
            onClick,
            isOnBlurOn,
            onBlurEvent
        } = this.props;

        return (<>
            <label style={{display: 'block'}}>
                {label}
            </label>
             {value ? value : ''}
             </>
        );
    }
}

