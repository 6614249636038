import axios from "axios"
import { IAllocationSearchModel } from "../types/IAllocationSearchModel";

export class DatabricksApiService {
    static searchAllocations(searchModel: IAllocationSearchModel): Promise<any> {
        return new Promise<any>((resolve) => {
            axios
                .post("databricks/Allocations/", searchModel)
                .then(res => {
                    let resultObj = this.mapResultToObject(res.data);
                    resolve(resultObj)
                })
        })
    }

    static mapResultToObject = (results: any) => { 
        const schema = results['manifest']['schema']['columns']
        const data = results['result']['data_array']
        let resultObj = [];
        if (data) {
            data.forEach((item) => {
                let itemObj = {}
                item.forEach((field, index) => {
                    itemObj[schema[index]['name']] = field;
                });
                resultObj.push(itemObj);
            });
        }
       
        return resultObj;
    }
}