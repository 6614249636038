import React, { useState } from "react";
import { Button, Steps } from "antd";
import { MembersPortalLivePerformanceValidator } from "../../services/MembersPortalLivePerformanceValidator";
import { IUsageGroupState } from "../../../redux/types/IUsageGroupState";
import { MessageBanner, MessageType } from "../../../repertoire/components/messageBanner/MessageBanner";
import { DataSource } from "../../../repertoire/types/DataSource";

export interface Step {
    title: string;
    content: any;
}

export interface ILivePerfromanceMultiStepFormProps {
    steps: Step[];
    readonly: boolean;
    saveChanges: () => void;
    confirmAndSubmit: () => void;
    dataSource?: string;
    current?: number;
    setCurrent?: (current: number) => void;
    usage?: IUsageGroupState;
    resetMessageBanner: () => void;
}

const LivePerformanceMultiStepForm = (props) => {
    const [errorMessages, setErrorMessages] = useState([]);

    const validatePageFields = (usage) => {
        const livePerformanceIsValid = [];
        if (Boolean(usage.fullPerformer) === false) {
            livePerformanceIsValid.push("Performer field cannot be empty")
        }
        if (Boolean(usage.location) === undefined) {
            livePerformanceIsValid.push("Please enter valid Venue")
        }
        if (Boolean(usage.venueDetails.firstName) === false) {
            livePerformanceIsValid.push("Firstname field cannot be empty")
        }
        if (Boolean(usage.venueDetails.lastName) === false) {
            livePerformanceIsValid.push("Lastname field cannot be empty")
        }
        if (Boolean(usage.venueDetails.phoneNumber) === false) {
            livePerformanceIsValid.push("Contact number field cannot be empty")
        }
        if (Boolean(usage.venueDetails.venueType) === false) {
            livePerformanceIsValid.push("Venue type field cannot be empty")
        }
        if (Boolean(usage.livePerformanceSetList) === false) {
            livePerformanceIsValid.push("Please select Selist available")
        }
        usage.usages.map(element => {
            if (Boolean(element.dateLoaded) === false) {
                livePerformanceIsValid.push("Please enter valid Performance date")
            }
            else if (Boolean(element.programStartDate) === false) {
                livePerformanceIsValid.push("Please enter valid Start date")
            }
            else if (Boolean(element.programEndDate) === false) {
                livePerformanceIsValid.push("Please enter valid end date")
            }
        });

        return livePerformanceIsValid;
    };

    const next = () => {
        const { current, setCurrent, usage } = props;
        if (current === 0) {
            let draftLivePerformanceValidationMessages = validatePageFields(usage);
            if (draftLivePerformanceValidationMessages.length === 0) {
                setErrorMessages([]);
                const next = current + 1;
                setCurrent(next);
            } else {
                setErrorMessages(draftLivePerformanceValidationMessages);
            }
        }

    }

    const prev = () => {
        const { current, setCurrent } = props;
        setCurrent(current - 1);
    };


    const renderErrorMessages = (messages) => {
        const { resetMessageBanner } = props;
        return (
            <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Danger} messages={messages} />
        );
    };

    const items = props.steps.map((item) => ({ key: item.title, title: item.title }));
    const { current, dataSource } = props;
    const validationErrors = !!(errorMessages.length > 0);
    return (
        <>
            {dataSource === DataSource.Intray ? <Steps current={current} items={items} /> : <></>}
            <div style={{ padding: '20px' }}>{props.steps[current].content}</div>
            <div className="row">
                <div
                    className="btn-group form-group col-lg-2 col-lg-push-5 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                    style={{ marginTop: 24 }}
                >
                    {current > 0 && (
                        <Button data-testid={'prev'} className="button btn-defaultSecondary" onClick={() => prev()}>
                            Previous
                        </Button>
                    )}
                </div>
                <div
                    className="btn-group form-group col-lg-2 col-lg-push-5 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                    style={{ marginTop: 24 }}
                >
                    {current < props.steps.length - 1 && (
                        <Button data-testid={'next'} className="button btn-defaultSecondary" onClick={() => next()}>
                            Next
                        </Button>
                    )}
                </div>
                {current === props.steps.length - 1 && props.dataSource === DataSource.Intray && (
                    <>
                        <div
                            className="btn-group form-group col-lg-2 col-lg-push-3 col-md-3 col-md-push-6 col-sm-6 col-sm-push-0 col-xs-12 col-xs-push-0"
                            style={{ marginTop: 24 }}
                        >
                            <Button
                                data-testid={'confirm-submit-registration'}
                                className="button btn-defaultPrimary"
                                onClick={() => props.confirmAndSubmit()}
                            >
                                Submit Performance
                            </Button>
                        </div>
                    </>
                )}
            </div>
            {validationErrors && renderErrorMessages(errorMessages)}
        </>
    );
};

export default LivePerformanceMultiStepForm;
