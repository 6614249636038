export interface IFileMetadata {
    source: string;
    distributionCode: string;
    poolCode: string;
    format: string;
    outputFormat: string;
    jobID: number;
    destination: string;
    fileSize: number;
    batchID: number;
    rightHolder: number;
    rightHolderNameNumber?: number;
    shoppingCart: number;
    trackingInformation: string;
    autoIngestFile?: boolean;
}

export type FileMetadataKey = keyof IFileMetadata;

export const FILE_METADATA_FORMAT: FileMetadataKey = "format";
export const FILE_METADATA_OUTPUT_FORMAT: FileMetadataKey = "outputFormat";
export const FILE_METADATA_SOURCE: FileMetadataKey = "source";
export const FILE_METADATA_DISTRIBUTION: FileMetadataKey = 'distributionCode';
export const FILE_METADATA_POOL_CODE: FileMetadataKey = "poolCode";
export const FILE_METADATA_DESTINATION: FileMetadataKey = "destination";
export const FILE_METADATA_FILE_SIZE: FileMetadataKey = "fileSize";
export const FILE_METADATA_BATCH_ID: FileMetadataKey = "batchID";
export const FILE_METADATA_RIGHTHOLDER: FileMetadataKey = "rightHolder";
export const FILE_METADATA_SHOPPINGCART: FileMetadataKey = "shoppingCart";
export const FILE_METADATA_TRACKINGINFORMATION: FileMetadataKey = "trackingInformation";

export const FileMeatdataKeys: FileMetadataKey[] = [
    FILE_METADATA_FORMAT,
    FILE_METADATA_OUTPUT_FORMAT,
    FILE_METADATA_SOURCE,
    FILE_METADATA_DISTRIBUTION,
    FILE_METADATA_POOL_CODE,
    FILE_METADATA_DESTINATION,
    FILE_METADATA_FILE_SIZE,
    FILE_METADATA_BATCH_ID,
    FILE_METADATA_RIGHTHOLDER,
    FILE_METADATA_SHOPPINGCART,
    FILE_METADATA_TRACKINGINFORMATION,
]
