import * as React from "react";
import {
    USAGE_MAINTENANCE_TOOLBAR,
    ALL_ROLES,
    UPDATE_USAGES_ROLE,
    OPEN_SEARCH_WORK_STATE_KEY,
    SEARCH_MATCHING_WORKS_TOOLBAR,
    OPEN_NEW_WORK_FROM_USAGE_STATE_KEY,
    OPEN_NEW_WORK_FROM_USAGE_MATCHING_WORKS_TOOLBAR,
    WORK_MAINTENANCE_TOOLBAR
} from "../../../Consts";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { clearModalSearchResults, nextWorkflowInSession, startWorkflowSession } from "../../../../redux/reducers/RepertoireReducer";
import { showYesNoPrompt, hideModal, showModal } from "../../../../redux/reducers/ModalReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IDataActionToolbar } from "../../../../repertoire/types/IDataActionToolbar";
import ToolbarComponent from "../../../components/usageComponents/toolBar/ToolBarComponent";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";
import { DataSource } from "../../../types/DataSource";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../../../ConfigurationConsts";
import { SearchRequests } from "../../../services/SearchRequests";

export interface IUsageMaintenanceMatchingWorksToolbarProps {
    changesMade: boolean;
    hideModal: typeof hideModal;
    showModal: typeof showModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    clearModalSearchResults?: typeof clearModalSearchResults;
    scroll: number;
    toolbarWidth: number;
    toolbarData: IRepertoireComponentDataItem;
    saveResultData: IRepertoireComponentDataItem;
    activeTab: number;
    tabs: ITabReduxItem[];
    searchWorks: () => void;
    resetMessageBanner: () => void;
    isNew?: boolean;
    currentUser?: string;
    usageMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    usageGroup: IUsageGroupState;
    workflowFieldsData?: IRepertoireComponentDataItem;
    isWorkflowSessionStarted?: boolean;
    startWorkflowSession?: typeof startWorkflowSession;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    currentWorkflowIndex?: number;
    isOpenForWorkflowSession?: boolean;
    skipWorkWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    formatFields: FormatFields[];
    lookupValues: ILookupDictionary;
    refreshWorkDetails?: () => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    completeWorkflowOnSave: (value: boolean) => void;
    roles?: string[];
    updateWorkflowAndEntityStatus?: (workflowparams: IWorkflowParams, approvalStatus: boolean) => void;
    updateClaimWorkflowAndEntityStatus?: (workflowparams: IWorkflowParams, callFrom: string, refreshDetails: () => void) => void;
    showSearchCriteria?: (value: boolean) => void;
    addNewWork: (lookups: ILookupDictionary, actionList?: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], workGroupId?: string, workGroup?: string, paneIndex?: number,isFromUsage?:boolean,usage?:IUsageGroupState,isOpenedInSecondTab?: boolean) => void;
    paneIndex?:number;
    componentInstance?:string;
}

export interface IUsageMaintenanceMatchingWorksToolbarState {
    dataAction: IDataActionToolbar[];
}

export type UsageMaintenanceMatchingWorksToolbarKeys = keyof IUsageMaintenanceMatchingWorksToolbarProps;

class UsageMaintenanceMatchingWorksToolbar extends React.Component<
    IUsageMaintenanceMatchingWorksToolbarProps,
    IUsageMaintenanceMatchingWorksToolbarState
> {
    actionData;

    constructor(props) {
        super(props);
        this.state = {
            dataAction: this.getDataAction()
        };
    }

    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_USAGES_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getDataAction() {
        const {
            toolbarData: { fields },
            tabs } = this.props;

        const actionList: IDataActionToolbar[] = [];
        const openSearch: IDataActionToolbar = {
            action: this.searchWorkAction.bind(this),
            component: USAGE_MAINTENANCE_TOOLBAR,
            method: SEARCH_MATCHING_WORKS_TOOLBAR,
            name: fields.find(save => save.name === OPEN_SEARCH_WORK_STATE_KEY).data,
            icon: 'icon ms-Icon ms-Icon--Search',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(openSearch);
        const openCreateNewWork: IDataActionToolbar = {
            action: this.openWorkAction.bind(this),
            component: USAGE_MAINTENANCE_TOOLBAR,
            method: OPEN_NEW_WORK_FROM_USAGE_MATCHING_WORKS_TOOLBAR,
            name: 'Create New Work from Usage Group',
            icon: 'icon ms-Icon ms-Icon--CircleAddition',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(openCreateNewWork);

        return actionList;
    }
    openWorkAction = () => {
        const { addNewWork,lookupValues,usageGroup,tabs,activeTab} = this.props;
        const actionList = getDataAction(DataSource.Intray, WORK_MAINTENANCE_TOOLBAR);
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                addNewWork(lookupValues,actionList,null,null,formats,null,null,1,true,usageGroup,true);
            });
        
    }

    searchWorkAction = () => {
        const { showSearchCriteria } = this.props;

        if (this.state.dataAction.find(x => x.name === "Open Search Work To Match")) {
            showSearchCriteria(true)
            this.setState({
                dataAction: [{
                    action: this.searchWorkAction.bind(this),
                    component: USAGE_MAINTENANCE_TOOLBAR,
                    method: SEARCH_MATCHING_WORKS_TOOLBAR,
                    name: "Close Search Work To Match",
                    icon: 'icon ms-Icon ms-Icon--Search',
                    isReadOnly: this.getUpdateRoleDisabled()
                },{
                    action: this.openWorkAction.bind(this),
                    component: USAGE_MAINTENANCE_TOOLBAR,
                    method: OPEN_NEW_WORK_FROM_USAGE_MATCHING_WORKS_TOOLBAR,
                    name: 'Create New Work from Usage Group',
                    icon: 'icon ms-Icon ms-Icon--CircleAddition',
                    isReadOnly: this.getUpdateRoleDisabled()
                }]
            });
        } else {
            showSearchCriteria(false)
            this.setState({
                dataAction: [{
                    action: this.searchWorkAction.bind(this),
                    component: USAGE_MAINTENANCE_TOOLBAR,
                    method: SEARCH_MATCHING_WORKS_TOOLBAR,
                    name: "Open Search Work To Match",
                    icon: 'icon ms-Icon ms-Icon--Search',
                    isReadOnly: this.getUpdateRoleDisabled()
                },
                {
                    action: this.openWorkAction.bind(this),
                    component: USAGE_MAINTENANCE_TOOLBAR,
                    method: OPEN_NEW_WORK_FROM_USAGE_MATCHING_WORKS_TOOLBAR,
                    name: 'Create New Work from Usage Group',
                    icon: 'icon ms-Icon ms-Icon--CircleAddition',
                    isReadOnly: this.getUpdateRoleDisabled()
                }]
            });
        }


    }

    render() {
        const {
            showYesNoPrompt,
            hideModal,
            showModal,
            resetMessageBanner,
            scroll,
            toolbarWidth,
            saveResultData,
            activeTab,
            tabs,
            toolbarData,
            usageGroup,
            clearModalSearchResults,
            workflowFieldsData,
            isWorkflowSessionStarted,
            startWorkflowSession,
            cancelWorkflowSession,
            nextWorkflowItem,
            skipWorkflowItem,
            workflowSessionItems,
            currentWorkflowIndex,
            isOpenForWorkflowSession,
            skipWorkWorkflowItem,
            formatFields,
            lookupValues,
            refreshWorkDetails,
            saveWorkflow,
            roles,
            updateWorkflowAndEntityStatus,
            updateClaimWorkflowAndEntityStatus,
            completeWorkflowOnSave,
            componentInstance
        } = this.props;
        let {
            changesMade,
        } = this.props;

        changesMade = !this.getUpdateRoleDisabled() && changesMade
        let { dataAction } = { ...this.state };
        return (
            <>
                <ToolbarComponent
                    scroll={scroll}
                    usageGroup={usageGroup}
                    toolbarWidth={toolbarWidth}
                    saveResultData={saveResultData}
                    resetMessageBanner={resetMessageBanner}
                    changesMade={changesMade}
                    activeTab={activeTab}
                    tabs={tabs}
                    dataActionToolbar={dataAction}
                    showYesNoPrompt={showYesNoPrompt}
                    hideModal={hideModal}
                    showModal={showModal}
                    clearModalSearchResults={clearModalSearchResults}
                    isReadonly={undefined}
                    toolbarData={toolbarData}
                    component={componentInstance}
                    isNew={false}
                    workflowFieldsData={workflowFieldsData}
                    isWorkflowSessionStarted={isWorkflowSessionStarted}
                    startWorkflowSession={startWorkflowSession}
                    cancelWorkflowSession={cancelWorkflowSession}
                    nextWorkflowItem={nextWorkflowItem}
                    skipWorkflowItem={skipWorkflowItem}
                    workflowSessionItems={workflowSessionItems}
                    currentWorkflowIndex={currentWorkflowIndex}
                    isOpenForWorkflowSession={isOpenForWorkflowSession}
                    skipWorkWorkflowItem={skipWorkWorkflowItem}
                    lookupValues={lookupValues}
                    formatFields={formatFields}
                    refreshWorkDetails={refreshWorkDetails}
                    saveWorkflow={saveWorkflow}
                    roles={roles}
                    updateWorkflowAndEntityStatus={updateWorkflowAndEntityStatus}
                    updateClaimWorkflowAndEntityStatus={updateClaimWorkflowAndEntityStatus}
                    completeWorkflowOnSave={completeWorkflowOnSave}
                />
            </>
        );
    }
}

export default UsageMaintenanceMatchingWorksToolbar;
