import React from "react";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../types/msalAuthConfig";

interface IMSALLoginButtonProps {
    containerClass: string
}

export const MSALLoginButton = ({ containerClass }: IMSALLoginButtonProps) => {
    const { instance } = useMsal();

    const handleAADLogin = () => {
        let scope =
        {
            scopes: [msalConfig.auth.scope]
        }

        instance.loginRedirect(scope).catch(e => {
            console.log(e);
        });
    }
    return (
        <div className={containerClass} >
            <button className="button btn-defaultPrimary"
                onClick={() => handleAADLogin()}
            >Azure AD Login
            </button>
        </div>
    );
}
