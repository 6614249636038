import { PublicClientApplication } from "@azure/msal-browser";
import * as React from "react";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { MEMBERS_PORTAL } from "../../accounts/Consts";

interface IPrivateRouteProps {
    component: React.ReactType;
    path: string;
    exact?: boolean;
    instance: PublicClientApplication;
    portalType: string,
    scope?: any
}

const getIsAuthenticated = () => {
    return sessionStorage.isAuthenticated && sessionStorage.isAuthenticated == 'true' ? true : false
}
const PrivateRoute: React.SFC<IPrivateRouteProps> = ({
    component: Component,
    ...rest
}) => (

    <Route
        {...rest}
        render={props => {
            if (getIsAuthenticated()) { return <Component {...props} /> }
            else if (rest.portalType === MEMBERS_PORTAL) {
                rest.instance
                    .handleRedirectPromise()
                .then((tokenResponse) => {
                    if (!tokenResponse) {
                        const accounts = rest.instance.getAllAccounts();
                        if (accounts.length === 0) {
                            rest.instance.acquireTokenRedirect(rest.scope);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
                return null;
            }
            else { return <Redirect to={{ pathname: "/login", state: { from: props.location } }} /> }
        }}
    />
);

export { PrivateRoute };