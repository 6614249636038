import * as React from "react";
import { IComponentField } from "../../core/types/IComponentField";
import { Dictionary } from "../../core/types/Dictionary";
import { ComponentFields } from "../../core/services/ComponentService";
import { ComponentsHelper as CH } from "../../core/services/ComponentHelper";
import { If } from "../../core/components/If";
import { SizedCheckboxDataInput } from "../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import { SizedDropdownDataInput } from "../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IUsagePool } from "../../repertoire/types/usageTypes/IUsagePool";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { getLookupValues } from "../../lookup/services/LookupHelpers";
import { FILE_TYPE_LOOKUP } from "../../lookup/Consts";
import { IFileSystem } from "../../dataingestion/types/IFileSystem";
import { ITreeNodeData } from "../../treeview/types/TreeNodeData";
import { IParameterSearchResult } from "../types/IParameterSearchResult";
import { ITreeData } from "../../repertoire/types/ITreeData";
import { USAGE_TO_PRODUCT_TYPE, USAGE_TO_WORK_TYPE } from "../Consts";
import { SizedTextDataInput } from "../../repertoire/components/textDataInput/TextDataInput";
import { REPERTOIRE_PAGE_FORM_LARGE_BOX_SIZE } from "../../repertoire/Consts";

interface ISourceEditorProps {
  selectSourceName: string;
  selectSourceCode: string;
  sourceDescription: string;
  rejectDuplicateUsageFiles: boolean;
  usageIngestionFolderName?: IParameterSearchResult;
  usageIngestionDefaultPool?: IParameterSearchResult;
  usageIngestionDefaultDistribution?: IParameterSearchResult;
  usageIngestionDefaultInputFileFormat?: IParameterSearchResult;
  usageIngestionDefaultOutputFileFormat?: IParameterSearchResult;
  usageIngestionDefaultDestination?: IParameterSearchResult;
  usageIngestionAutoProcess?: IParameterSearchResult;
  customNotebook?: IParameterSearchResult;  
  sourceID: number;
  saveChanges: (
      sourceID: number,
      sourceName: string,
      sourceCode: string,
      sourceDescription: string,
      rejectDuplicateUsageFiles: boolean,
      usageConfigurationParameters: IParameterSearchResult[]
  ) => void;
  cancelChanges: () => void;
  distributions?: IDistribution[];
  pools?: IUsagePool[];
  lookupValues?: ILookupDictionary;
  folderSelectedNode: ITreeNodeData<IFileSystem>;
  sources?: ITreeData[];
  sorcesFolderList?: IParameterSearchResult[];
}

interface ISourceEditorState {
    activeFields: Dictionary<IComponentField>;
    sourceName: string;
    sourceCode: string;
    sourceDescription: string;
    rejectDuplicateUsageFiles: boolean;
    usageIngestionFolderName: string;
    usageIngestionDefaultPool: string;
    usageIngestionDefaultDistribution: string;
    usageIngestionDefaultInputFileFormat: string;
    usageIngestionDefaultOutputFileFormat: string;
    usageIngestionDefaultDestination: string;
    usageIngestionAutoProcess: string;
    customNotebook: string;
}

enum Fields {
    UpdateSourceSave,
    UpdateSourceCancel,
    SourceHeader,
    SourceCodeHeader,
    DescriptionHeader,
    RejectDuplicateUsageFiles,
    UsageIngestionFolderName,
    UsageIngestionDefaultPool,
    UsageIngestionDefaultDistribution,
    UsageIngestionDefaultInputFileFormat,
    UsageIngestionAutoProcess
}

export class SourceEditor extends React.Component<
  ISourceEditorProps,
  ISourceEditorState
> {
  ComponentName: string = "SourceEditor";
  constructor(props: ISourceEditorProps) {
    super(props);

    this.state = {
        activeFields: {},
        sourceName: props.selectSourceName,
        sourceCode: props.selectSourceCode,
        sourceDescription: props.sourceDescription,
        rejectDuplicateUsageFiles: props.rejectDuplicateUsageFiles,
        usageIngestionFolderName: props.usageIngestionFolderName ? props.usageIngestionFolderName.currentValue : null,
        usageIngestionDefaultPool: props.usageIngestionDefaultPool ? props.usageIngestionDefaultPool.currentValue : null,
        usageIngestionDefaultDistribution: props.usageIngestionDefaultDistribution ? props.usageIngestionDefaultDistribution.currentValue : null,
        usageIngestionDefaultInputFileFormat: props.usageIngestionDefaultInputFileFormat ? props.usageIngestionDefaultInputFileFormat.currentValue : null,
        usageIngestionDefaultOutputFileFormat: props.usageIngestionDefaultOutputFileFormat ? props.usageIngestionDefaultOutputFileFormat.currentValue : null,
        usageIngestionDefaultDestination: props.usageIngestionDefaultDestination ? props.usageIngestionDefaultDestination.currentValue : null, 
        usageIngestionAutoProcess: props.usageIngestionAutoProcess ? props.usageIngestionAutoProcess.currentValue : null,
        customNotebook: null,
    };
  }

  componentDidMount() {
    ComponentFields.get(this.ComponentName).then(activeFields => {
      this.setState({ activeFields });
    });
  }

  sourceNameChanged(e: React.SyntheticEvent<HTMLInputElement>) {
    this.setState({ sourceName: e.currentTarget.value });
  }

  sourceDescriptionChanged(
    e:
      | React.SyntheticEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) {
    this.setState({ sourceDescription: e.currentTarget.value });
  }

    rejectDuplicatesChanged(value: any, fieldName: string)
    {
        this.setState({ rejectDuplicateUsageFiles: value });
    }

    updateAutoProcessAfterEdit() {
        let autoProcess = this.autoIngestionCanBeEdited() ? JSON.parse(this.state.usageIngestionAutoProcess) : false;

        this.setState({ usageIngestionAutoProcess: String(autoProcess) });
    }

    usageIngestionAutoProcessChanged(value: any, fieldName: string) {
        let newValue = value;
        this.setState({ usageIngestionAutoProcess: String(newValue) });
    }

    usageIngestionFolderNameChanged(value: any, fieldName: string) {
        let newValue = value;
        this.setState({ usageIngestionFolderName: newValue }, this.updateAutoProcessAfterEdit);
    }

    usageIngestionDefaultPoolChanged(value: any, fieldName: string) {
        let newValue = value;
        this.setState({ usageIngestionDefaultPool: newValue }, this.updateAutoProcessAfterEdit);
    }

    usageIngestionDefaultDistributionChanged(value: any, fieldName: string) {
        let newValue = value;
        this.setState({ usageIngestionDefaultDistribution: newValue }, this.updateAutoProcessAfterEdit);
    }

    usageIngestionDefaultInputFileFormatChanged(value: any, fieldName: string) {
        let newValue = value;
        this.setState({ usageIngestionDefaultInputFileFormat: newValue }, this.updateAutoProcessAfterEdit);
    }

    customNotebookChanged(value: any, fieldName: string) {
        let newValue = value;
        this.setState({ customNotebook: newValue });
    }

    updateCodeChanged(value: any) {
        let newValue = value;
        this.setState({ sourceCode: newValue });
    }

    save() {
        const {
            usageIngestionFolderName,
            usageIngestionDefaultPool,
            usageIngestionDefaultDistribution,
            usageIngestionDefaultInputFileFormat,
            usageIngestionAutoProcess,
            customNotebook
        } = this.props;

        let usageConfigurationParameters: IParameterSearchResult[] = [];

        if (usageIngestionFolderName && usageIngestionDefaultPool && usageIngestionDefaultDistribution && usageIngestionDefaultInputFileFormat && usageIngestionAutoProcess) {
            this.props.usageIngestionAutoProcess.overrideParentValue = true;
            this.props.usageIngestionAutoProcess.currentValue = this.state.usageIngestionAutoProcess;

            this.props.usageIngestionFolderName.overrideParentValue = true;
            this.props.usageIngestionFolderName.currentValue = this.state.usageIngestionFolderName;

            this.props.usageIngestionDefaultPool.overrideParentValue = true;
            this.props.usageIngestionDefaultPool.currentValue = this.state.usageIngestionDefaultPool;

            this.props.usageIngestionDefaultDistribution.overrideParentValue = true;
            this.props.usageIngestionDefaultDistribution.currentValue = this.state.usageIngestionDefaultDistribution;

            this.props.usageIngestionDefaultInputFileFormat.overrideParentValue = true;
            this.props.usageIngestionDefaultInputFileFormat.currentValue = this.state.usageIngestionDefaultInputFileFormat;

            this.props.usageIngestionDefaultOutputFileFormat.overrideParentValue = true;
            this.props.usageIngestionDefaultDestination.overrideParentValue = true;

            usageConfigurationParameters.push(this.props.usageIngestionFolderName);
            usageConfigurationParameters.push(this.props.usageIngestionDefaultPool);
            usageConfigurationParameters.push(this.props.usageIngestionDefaultDistribution);
            usageConfigurationParameters.push(this.props.usageIngestionDefaultInputFileFormat);
            usageConfigurationParameters.push(this.props.usageIngestionDefaultOutputFileFormat);
            usageConfigurationParameters.push(this.props.usageIngestionDefaultDestination);
            usageConfigurationParameters.push(this.props.usageIngestionAutoProcess);
        }

        if (customNotebook) {
            customNotebook.overrideParentValue = true;
            customNotebook.currentValue = this.state.customNotebook != null? this.state.customNotebook : customNotebook.currentValue ;

            usageConfigurationParameters.push(customNotebook);
        }

        this.props.saveChanges(
            this.props.sourceID,
            this.state.sourceName.trim(),
            this.state.sourceCode,
            this.state.sourceDescription,
            this.state.rejectDuplicateUsageFiles,
            usageConfigurationParameters
        );
  }

  cancel() {
    this.setState({
        sourceName: this.props.selectSourceName,
        sourceDescription: this.props.sourceDescription,
        rejectDuplicateUsageFiles: this.props.rejectDuplicateUsageFiles,
        usageIngestionFolderName: this.props.usageIngestionFolderName  ? this.props.usageIngestionFolderName.currentValue : null,
        usageIngestionDefaultPool: this.props.usageIngestionDefaultPool ? this.props.usageIngestionDefaultPool.currentValue : null,
        usageIngestionDefaultDistribution: this.props.usageIngestionDefaultDistribution ? this.props.usageIngestionDefaultDistribution.currentValue : null,
        usageIngestionDefaultInputFileFormat: this.props.usageIngestionDefaultInputFileFormat ? this.props.usageIngestionDefaultInputFileFormat.currentValue : null,
        usageIngestionAutoProcess: this.props.usageIngestionAutoProcess ? this.props.usageIngestionAutoProcess.currentValue : null
    });

    this.props.cancelChanges();
    }

    autoIngestionCanBeEdited() {
        return (this.state.usageIngestionFolderName && this.state.usageIngestionDefaultPool &&
            this.state.usageIngestionDefaultDistribution && this.state.usageIngestionDefaultInputFileFormat)
            ? true : false;
    }

    render() {
        const { pools, distributions, lookupValues, folderSelectedNode, sourceID, sources, usageIngestionFolderName, sorcesFolderList, customNotebook } = this.props

        let sourceNode = sources ? sources.filter(s => s.id == sourceID)[0]: null;
        var parentNode;
        let parentPools = [];
        let childPools = [];
        let filteredPools = [];
        let filteredInputFileFormats = [];
        let filteredDistributions = [];
        let filteredUsageFolderOtions = [];

        let inputFileFormats = lookupValues != undefined ? getLookupValues(FILE_TYPE_LOOKUP, lookupValues) : null;
        let usageFolderOtions = folderSelectedNode
            ? folderSelectedNode.children.map((node) => ({ code: node.object.path, description: node.object.name })).filter(f => f.description !== "main")
            : null;

        if (pools && distributions) {

            if (pools && sourceNode) {
                childPools = pools.filter(c => c.sources.filter(cs => cs.source == sources.filter(x => x.hierarchy == sourceNode.hierarchy)[0].id)[0]);
                sources.forEach(function (source) {
                    if (sourceNode.hierarchy == source.hierarchy) {
                        parentNode = sourceNode.hierarchy.replace(source.title, '');
                        parentNode = parentNode.substring(0, parentNode.length - 1);
                    }
                });
                if (parentNode != "Global") {
                    parentPools = pools.filter(p => p.sources.filter(ps => ps.source == sources.filter(y => y.hierarchy == sourceNode.hierarchy)[0].id)[0]);

                }
                filteredPools = parentPools.concat(childPools).filter((elem, index, self) => {
                    return index === self.indexOf(elem);
                });
            }

            if (filteredPools && this.state.usageIngestionDefaultPool && distributions) {
                filteredDistributions = distributions.filter(d => d.distributionStatus !== 'Closed' && d.distributionPools && d.distributionPools.some(dp => dp.poolCode == this.state.usageIngestionDefaultPool))
            }

            if (inputFileFormats != null && inputFileFormats != undefined) {
                filteredInputFileFormats = inputFileFormats.filter(format => format.extraFields == null || format.extraFields["DataType"].split(',').indexOf(folderSelectedNode.object.fullName) > -1 && format.extraFields["InOut"] == "input");
            }

            
            filteredUsageFolderOtions = usageFolderOtions && sorcesFolderList && usageIngestionFolderName
                ? usageFolderOtions.filter(f => sorcesFolderList.map(sf => sf.currentValue).indexOf(f.code) === -1 || sorcesFolderList.filter(sf => sf.sourceID === usageIngestionFolderName.sourceID)[0]?.currentValue === f.code)
                : null

            if (customNotebook) filteredInputFileFormats.push({ typeID: 0, code: customNotebook.currentValue, description: customNotebook.currentValue })
            
        }

    return (
      <div>
        {/* Text inputs */}
            <div className="row">
                <div className="col-md-8" >
                    <If
                        condition={
                            CH.getField(
                                Fields.SourceHeader,
                                Fields,
                                this.state.activeFields
                             ).exists
                        }
                    >
                        <label>
                            {
                                CH.getField(
                                    Fields.SourceHeader,
                                    Fields,
                                    this.state.activeFields
                                ).text
                            }
                          </label>
                    </If>
                    <div style={{ fontSize: "18.75px" }}>
                        <input
                            type="text"
                            name=""
                            value={this.state.sourceName}
                            onChange={this.sourceNameChanged.bind(this)}
                        />
                    </div>
                </div>

                <SizedTextDataInput
                    fieldType={"text"}
                    label={CH.getField(
                        Fields.SourceCodeHeader,
                        Fields,
                        this.state.activeFields
                    ).text}
                    fieldName={null}
                    changeData={this.updateCodeChanged.bind(this)}
                    readOnly={false}
                    isHidden={false}
                    value={this.state.sourceCode}
                />                
            </div>

            <div className="row">
                <div className="col-md-12" >
                    <p>
                        <If
                            condition={
                                CH.getField(
                                    Fields.DescriptionHeader,
                                    Fields,
                                    this.state.activeFields
                                ).exists
                            }
                        >
                            <label>
                                {
                                    CH.getField(
                                        Fields.DescriptionHeader,
                                        Fields,
                                        this.state.activeFields
                                    ).text
                                }
                            </label>
                        </If>

                        <textarea
                            value={this.state.sourceDescription}
                            onChange={this.sourceDescriptionChanged.bind(this)}
                        />
                    </p>
                </div>
            </div>

            {sourceNode != undefined && (sourceNode.matchType === USAGE_TO_PRODUCT_TYPE || sourceNode.matchType === USAGE_TO_WORK_TYPE) ?
                <>
                    <div className="row" style={{ paddingTop: "20px" }}>
                        <SizedDropdownDataInput
                            label={this.state.activeFields?.usageIngestionFolderName?.data}
                            fieldName={null}
                            value={this.state.usageIngestionFolderName != null ? this.state.usageIngestionFolderName : ""}
                            changeData={this.usageIngestionFolderNameChanged.bind(this)}
                            options={filteredUsageFolderOtions}
                            allowNull={true}
                            readOnly={false}
                            isHidden={false}
                        />
                        <SizedDropdownDataInput
                            label={this.state.activeFields?.usageIngestionDefaultPool?.data}
                            fieldName={null}
                            value={this.state.usageIngestionDefaultPool != null ? this.state.usageIngestionDefaultPool : ""}
                            changeData={this.usageIngestionDefaultPoolChanged.bind(this)}
                            options={filteredPools.map((pool) => ({ code: pool.poolCode, description: pool.poolCode }))}
                            allowNull={true}
                            readOnly={false}
                            isHidden={false}
                        />
                        <SizedDropdownDataInput
                            label={this.state.activeFields?.usageIngestionDefaultDistribution?.data}
                            fieldName={null}
                            value={this.state.usageIngestionDefaultDistribution != null ? this.state.usageIngestionDefaultDistribution : ""}
                            changeData={this.usageIngestionDefaultDistributionChanged.bind(this)}
                            options={filteredDistributions.map((dist) => ({ code: dist.distributionCode, description: dist.distributionCode }))}
                            allowNull={true}
                            readOnly={false}
                            isHidden={false}
                        />
                    </div>

                    <div className="row" style={{ paddingTop: "20px" }}>
                        <SizedDropdownDataInput
                            label={this.state.activeFields?.usageIngestionDefaultInputFileFormat?.data}
                            fieldName={null}
                            value={this.state.usageIngestionDefaultInputFileFormat != null ? this.state.usageIngestionDefaultInputFileFormat : ""}
                            changeData={this.usageIngestionDefaultInputFileFormatChanged.bind(this)}
                            options={filteredInputFileFormats.map((format) => ({ code: format.description, description: format.description }))}
                            allowNull={true}
                            readOnly={false}
                            isHidden={false}
                        />

                        <SizedDropdownDataInput
                            label={this.state.activeFields?.usageIngestionDefaultOutputFileFormat?.data}
                            fieldName={null}
                            value={this.state.usageIngestionDefaultOutputFileFormat != null ? this.state.usageIngestionDefaultOutputFileFormat : ""}
                            changeData={null}
                            options={[]}
                            allowNull={true}
                            readOnly={true}
                            isHidden={false}
                        />

                        <SizedDropdownDataInput
                            label={this.state.activeFields?.usageIngestionDefaultDestination?.data}
                            fieldName={null}
                            value={this.state.usageIngestionDefaultDestination != null  ? this.state.usageIngestionDefaultDestination : ""}
                            changeData={null}
                            options={[]}
                            allowNull={true}
                            readOnly={true}
                            isHidden={false}
                        />
                    </div>

                    <div className="row" style={{ paddingTop: "20px" }}>
                        <SizedCheckboxDataInput
                            label={this.state.activeFields?.usageIngestionAutoProcess?.data}
                            fieldName={null}
                            value={this.state.usageIngestionAutoProcess != null ? JSON.parse(this.state.usageIngestionAutoProcess) : false}
                            changeData={this.usageIngestionAutoProcessChanged.bind(this)}
                            readonly={!this.autoIngestionCanBeEdited()}
                        />

                        <SizedCheckboxDataInput
                            label={this.state.activeFields?.RejectDuplicateUsageFilesHeader?.data}
                            fieldName={null}
                            value={this.state.rejectDuplicateUsageFiles}
                            changeData={this.rejectDuplicatesChanged.bind(this)}
                            readonly={false}
                        />
                    </div>

                    <div className="row" style={{ paddingTop: "20px" }}>
                        <SizedTextDataInput
                            fieldType={"text"}
                            label={this.state.activeFields?.customNotebook?.data}
                            fieldName={null}
                            changeData={this.customNotebookChanged.bind(this)}
                            readOnly={false}
                            isHidden={false}
                            value={this.state.customNotebook != null ? this.state.customNotebook : customNotebook.currentValue }
                        />

                    </div>
                </> :
                    <div className="row" style={{ paddingTop: "20px" }}>
                        <SizedCheckboxDataInput
                            label={this.state.activeFields?.RejectDuplicateUsageFilesHeader?.data}
                            fieldName={null}
                            value={this.state.rejectDuplicateUsageFiles}
                            changeData={this.rejectDuplicatesChanged.bind(this)}
                            readonly={false}
                    />
                    </div>
            }

        {/* Buttons */}
        <div className="row">
          <If
            condition={
              CH.getField(
                Fields.UpdateSourceCancel,
                Fields,
                this.state.activeFields
              ).exists
            }
          >
            <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
              <button
                className="button btn-defaultSecondary"
                onClick={this.cancel.bind(this)}
              >
                {
                  CH.getField(
                    Fields.UpdateSourceCancel,
                    Fields,
                    this.state.activeFields
                  ).text
                }
              </button>
            </div>
          </If>

          <If
            condition={
              CH.getField(
                Fields.UpdateSourceCancel,
                Fields,
                this.state.activeFields
              ).exists
            }
          >
            <div className="form-group col-lg-3 col-lg-push-6 col-md-4 col-md-push-4 col-sm-6 col-sm-push-0 col-xs-6 col-xs-push-0">
              <button
                className="button btn-defaultPrimary"
                onClick={this.save.bind(this)}
              >
                {
                  CH.getField(
                    Fields.UpdateSourceSave,
                    Fields,
                    this.state.activeFields
                  ).text
                }
              </button>
            </div>
          </If>
        </div>
      </div>
    );
  }
}
