import { ILookupInstance } from "../../lookup/types/ILookup";
import { IFileSystem } from "../types/IFileSystem";

export class FileSystemHelper {
    public static getAncestors(filesystem: IFileSystem): IFileSystem[] {
        if (!filesystem)
            return null;

        const ancestors: IFileSystem[] = [];
        ancestors.push(filesystem);
        let next: IFileSystem = filesystem;
        while (next.parent) {
            ancestors.push(next.parent);
            next = next.parent;
        }

        return ancestors.reverse();
    }
    
    public static filtered_destination_lookup(systemPath:IFileSystem, destinations:ILookupInstance[]) {
        var destination_lookup;
        if (systemPath !== undefined) {
            if (systemPath.fullName.includes('usage/')) {
                destination_lookup = destinations.filter(x => !x.code.includes('repertoire'))
            }
            else if (systemPath.fullName.includes('repertoire/')) {
                destination_lookup = destinations.filter(x => !x.code.includes('usage'))
            }
        }
        else {
            destination_lookup = destinations;
        }
        return destination_lookup;
    }
}