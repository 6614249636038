import * as React from "react";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { sortWeightSettingsFieldData, updateUsagePoolField } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { ADD_NEW_ACTION, COPY_ACTION, POOL_SOURCES_KEY, POOL_WEIGHT_SETTINGS_KEY, REMOVE_ACTION } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IUsagePoolAllocationSettingsDataItems } from "../../../types/usageTypes/IUsagePoolAllocationSettingsDataItems";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { ITreeData } from "../../../types/ITreeData";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IUsagePoolDatesAndDimensions } from "../../../types/usageTypes/IUsagePoolDatesAndDimensions";
import { IUsagePoolWeightSettingsStateKeys } from "../../../types/usageTypes/IUsagePoolWeightSettings";
import { IUsagePoolStateKeys } from "../../../../redux/types/IUsagePoolState";

export interface IPoolMaintenanceWeightSettingsGridProps {
    weightSettingsGridViewData?: IRepertoireComponentDataItem;
    isReadonly: boolean;
    poolAllocationSettings: IUsagePoolAllocationSettingsDataItems;
    updateUsagePoolField: typeof updateUsagePoolField;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    lookupSources: ITreeData[];
    incrementTempID?: () => void;
    lookupValues: ILookupDictionary;
    tableContents: any;
    changeData?: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[]) => void;
    componentInstance: string;
    dataGridTableData: IRepertoireComponentDataItem;
    stateKey: IRepertoireStateKeys;
    poolEnabled?:boolean;
    datesAndDimensions?: IUsagePoolDatesAndDimensions[];
    sortWeightSettingsFieldData: typeof sortWeightSettingsFieldData;
    isBulkCheckboxActive?:boolean;
    changeBulkCheckbox?: (value: boolean) => void;
}

export interface IIPoolMaintenanceWeightSettingsGridState {
    loaded: boolean;
    activeHeaderSection: string;
}

export default class PoolMaintenanceAllocationSettingsGrid extends React.Component<IPoolMaintenanceWeightSettingsGridProps, IIPoolMaintenanceWeightSettingsGridState>{
    viewData;
    constructor(props: IPoolMaintenanceWeightSettingsGridProps) {
        super(props);

        this.state = { loaded: false,activeHeaderSection:POOL_SOURCES_KEY };
        this.viewData = {};
    }
    handleBlur = (event, field) => {
        const {tableContents, updateUsagePoolField} = this.props;
        tableContents.forEach(item => {
            if (!this.isValidNumber(item.weightMultiplier.value)) {
                item.weightMultiplier.value = "";
            }
        });
        updateUsagePoolField(tableContents, "poolWeightSettings" as IUsagePoolStateKeys);
    };
    componentDidMount() {
        const {
            weightSettingsGridViewData: { fields }
        } = this.props;

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    isValidNumber(value) {
        return /^-?\d+(\.\d+)?$|^$/.test(value);
    }
    isValidDigit(char) {
        var charCodeZero = "0".charCodeAt(0);
        var charCodeNine = "9".charCodeAt(0);
        return char.charCodeAt(0) >= charCodeZero && char.charCodeAt(0) <= charCodeNine;
    }
    changeData = (value: any, name: IRepertoireStateKeys) => {
        const { updateUsagePoolField } = this.props;
        value.forEach(item => {
            if (item.weightMultiplier.value.length === 1 && !this.isValidDigit(item.weightMultiplier.value)) {
                item.weightMultiplier.value = "";
            }
        });
        updateUsagePoolField(value, name as IUsagePoolStateKeys);
    }
    sortData(value: any, name: IRepertoireStateKeys): void {
        this.props.sortWeightSettingsFieldData(value, name as IUsagePoolWeightSettingsStateKeys);
    }

    render() {
        if (!this.state.loaded)
            return null;

        const {lookupSources,incrementTempID, isBulkCheckboxActive,
            changeBulkCheckbox,lookupValues,tableContents,dataGridTableData,datesAndDimensions,weightSettingsGridViewData,tabs,activeTab,poolEnabled,isReadonly,stateKey} = this.props;
        const {
            activeHeaderSection
        } = this.state;
        const filteredFields = weightSettingsGridViewData.fields.filter(
            (field: IRepertoireField) =>
                field.name === activeHeaderSection
        );
        return (
            <div onBlur={(event) => this.handleBlur(event, filteredFields)} tabIndex={0}>
                            <DataGridTable
                            tableContents={tableContents}
                            changeData={this.changeData.bind(this)}
                            dataGridTableData={dataGridTableData}
                            stateKey={stateKey}
                            componentInstance={POOL_WEIGHT_SETTINGS_KEY}
                            showRowNumber={true}
                            isReadOnly={poolEnabled?false:true}
                            tableActions={[{ name: ADD_NEW_ACTION,disabled: datesAndDimensions?.length>0?false:true},{name: REMOVE_ACTION }, {name: REMOVE_ACTION }, {name: COPY_ACTION}]}
                            lookupValues={lookupValues}
                            lookupSources={lookupSources}
                            tabs={tabs}
                            activeTab={activeTab}
                            incrementTempID={incrementTempID}
                            datesAndDimensions={datesAndDimensions}
                            sortData={this.sortData.bind(this)}
                            allowMultiSelectChbk={true}
                            isBulkCheckboxActive={isBulkCheckboxActive}
                            changeBulkCheckbox={changeBulkCheckbox}
                        />

                    
            </div>
            )

    }
}