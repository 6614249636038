import * as React from "react";
import { triggerManualJob } from "../../../redux/thunks/HomeThunks";
import { ITriggerManualJob } from "../../../redux/types/ITriggerManualJob";
import ActionButton from "../../../repertoire/components/actionButton/ActionButton";

export interface ISaveAndCloseProps {
    triggerManualJob: typeof triggerManualJob;
    manualJobParameters: ITriggerManualJob;
    createNewJob: () => any;
    updateManualJobParams?: (params: any) => void;
}

export class SaveAndClose extends React.Component<ISaveAndCloseProps> {
    constructor(props: ISaveAndCloseProps) {
        super(props);
    }

    onClick = () => {
        const { triggerManualJob, manualJobParameters, createNewJob, updateManualJobParams } = this.props;

        this.props.createNewJob.bind(this);
        createNewJob();
        triggerManualJob(manualJobParameters);
        if (updateManualJobParams) {
           updateManualJobParams('');
        }
    };

    render() {
        return (
            <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                <ActionButton buttonText={"Save And Close"} buttonAction={() => this.onClick()} />
            </div>
        );
    }
}
