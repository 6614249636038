import { IArtistState } from "../../redux/types/IArtistState";
import { DROPDOWN_INPUT, TEXT_INPUT } from "../Consts";
import { IArtistCore } from "../types/IArtistCore";
import { IArtistNumber } from "../types/IArtistNumber";
import { IArtistNumberDataItems } from "../types/IArtistNumberDataItems";


export class ArtistDetailsMapper {

    public static mapStateToNumbers(stateNumber: IArtistNumberDataItems[]): IArtistNumber[] {
        if (stateNumber) {
            const number: IArtistNumber[] = [];
            stateNumber.map(n =>
                number.push({
                    performerNumberID: n.performerNumberID,
                    performerID: n.performerID,
                    type: n.numberType.value,
                    number: n.number.value
                }));
            return number;
        }
        else {
            return [];
        }
    }

    public static mapNumbersToState(number: IArtistNumber[]): IArtistNumberDataItems[] {
        if (number) {
            const stateNumber: IArtistNumberDataItems[] = [];
            number.map(n =>
                stateNumber.push({
                    performerNumberID: n.performerNumberID,
                    performerID: n.performerID,
                    numberType: { value: n.type, inputType: DROPDOWN_INPUT },
                    number: { value: n.number, inputType: TEXT_INPUT }
                }));
            return stateNumber;
        }
        else {
            return [];
        }
    }

    public static mapStateToArtist(state: IArtistState): IArtistCore {
        if (state) {
            const {
                firstName,
                lastName,
                number,
                type,
                performerID,
                performerNameID,
                performerRoleID
            } = state;


            const artist: IArtistCore = {
                performerID,
                firstName,
                lastName,
                fullName: firstName + ' ' + lastName,
                type,
                number: ArtistDetailsMapper.mapStateToNumbers(number),
                performerRoleID,
                performerNameID
            };

            return artist;
        }
        else {
            return null;
        }
    }

    public static mapArtistToState(artist: IArtistCore): IArtistState {
        if (artist) {
            const {
                firstName,
                lastName,
                fullName,
                number,
                type,
                performerID,
                performerNameID,
                performerRoleID
            } = artist;


            const state: IArtistState = {
                performerID,
                firstName,
                lastName,
                fullName,
                type,
                number: ArtistDetailsMapper.mapNumbersToState(number),
                performerNameID,
                performerRoleID
            };

            return state;
        }
        else {
            return null;
        }
    }
}