import { useMsal } from "@azure/msal-react";
import { lookup } from "dns";
import * as React from "react";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { DISTRIBUTION_SUB_TYPE_LOOKUP, DISTRIBUTION_TYPE_LOOKUP } from "../../lookup/Consts";
import { ILookup, ILookupInstance } from "../../lookup/types/ILookup";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { copyExistingDistribution } from "../../redux/reducers/RepertoireReducer";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import SearchView from "../components/searchView/SearchView";
import { ALL_ROLES, CONFIGURE_DISTRIBUTIONS_ROLE, SEARCH_VIEW_DISTRIBUTIONS, UPDATE_DISTRIBUTIONS_ROLE, VIEW_DISTRIBUTIONS_ROLE } from "../Consts";
import { IDistributionType } from "../types/usageTypes/IDistibutionType";
import { IDistribution } from "../types/usageTypes/IDistribution";
import { IDistributionSearchQuery } from "../types/usageTypes/IDistributionSearchQuery";
import { IDistributionSubType } from "../types/usageTypes/IDistributionSubType";

export interface IDistributionsPageProps {
    distributionsPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchDistributions: (query: IDistributionSearchQuery) => void;
    distributionsSearchResults: IDistribution[];
    searchSuccessful: boolean;
    distributionMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    distributionTypes: IDistributionType[];
    distributionSubTypes: IDistributionSubType[];
    getDistributionTypes: () => void;
    getDistributionSubTypes: () => void;
    getDistributionDetails: (distributionID: number, lookupValues: ILookupDictionary) => void;
    addNewDistribution: (lookups: ILookupDictionary, isNew?: boolean, distributionMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => void;
    sortSearchResults: (name: string, results: any) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
}

const DistributionsPage = (props: IDistributionsPageProps) => {
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])

    const checkIfLoaded = () => {
        const { distributionsPageData } = props;

        if (distributionsPageData.fields && distributionsPageData.fields.length >= 1 && !loaded) {
            setLoaded(true);
            const header = distributionsPageData.fields.find(f => f.name === "PageTitle");
            if (header) {
                document.title = header.data;
            }
        }
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const addNewDistribution = (): void => {
        const { addNewDistribution, lookupValues, distributionMaintenanceGeneralDataViewData } = props;
        addNewDistribution(lookupValues, true, distributionMaintenanceGeneralDataViewData);
    }

    const addDistributionTypeToLookup = (lookupValues: ILookupDictionary): ILookupDictionary => {
        const {
            distributionTypes
        } = props;

        if (distributionTypes === undefined) return lookupValues;
        else if (lookupValues[DISTRIBUTION_TYPE_LOOKUP] === undefined) {
            const lookups: ILookupInstance[] = [];
            distributionTypes.forEach((dt, index) => {
                const lookup: ILookupInstance = {
                    typeID: index,
                    code: dt.description,
                    description: dt.description
                };
                lookups.push(lookup);
            });
            lookupValues[DISTRIBUTION_TYPE_LOOKUP] = {
                entity: DISTRIBUTION_TYPE_LOOKUP,
                lookups: lookups,
                defaultValue: null
            };
            return lookupValues;
        }

        return lookupValues;
    }

    const addDistributionSubTypeToLookup = (lookupValues: ILookupDictionary): ILookupDictionary => {
        const {
            distributionSubTypes
        } = props;

        if (distributionSubTypes === undefined) return lookupValues;
        else if (lookupValues[DISTRIBUTION_SUB_TYPE_LOOKUP] === undefined) {
            const lookups: ILookupInstance[] = [];
            distributionSubTypes.forEach((dt, index) => {
                const lookup: ILookupInstance = {
                    typeID: index,
                    code: dt.description,
                    description: dt.description
                };
                lookups.push(lookup);
            });
            lookupValues[DISTRIBUTION_SUB_TYPE_LOOKUP] = {
                entity: DISTRIBUTION_SUB_TYPE_LOOKUP,
                lookups: lookups,
                defaultValue: null
            };
            return lookupValues;
        }

        return lookupValues;
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const {
        searchViewData,
        searchResultsTableData,
        searchDistributions,
        distributionsSearchResults,
        searchSuccessful,
        indexOfFirstResult,
        indexOfLastResult,
        resultsPerPage,
        currentPage,
        resetPagination,
        updatePagination,
        getDistributionDetails,
        sortSearchResults
    } = props;

    let { lookupValues } = props;

    lookupValues = addDistributionTypeToLookup(lookupValues);
    lookupValues = addDistributionSubTypeToLookup(lookupValues);

    const getUpdateRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(UPDATE_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    const getViewRoleDisabled = (): boolean => {
        const { roles } = props;
        if (roles && (roles.includes(VIEW_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    if (loaded) {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Distribution</span>
                        <div className="newWorkButton" title="Create New Distribution" hidden={getUpdateRoleDisabled()}>
                            <div className="IconTextButton" onClick={addNewDistribution}>
                                <i className="icon ms-Icon ms-Icon--CircleAddition"
                                    aria-hidden="true">
                                </i>
                                New Distribution
                            </div>
                        </div>
                    </div>
                </div>
                <SearchView
                    searchViewData={searchViewData}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_DISTRIBUTIONS}
                    modalProps={undefined}
                    dataSource={undefined}
                    hideModal={undefined}
                    lookupValues={lookupValues}
                    searchDistributions={searchDistributions}
                    distributionsSearchResults={distributionsSearchResults}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    updatePagination={updateUserPagination}
                    getDistributionDetails={getDistributionDetails}
                    sortSearchResults={sortSearchResults}
                    searchDisabled={getViewRoleDisabled()}
                />
            </div>
        );
    }
    return <div>Loading ...</div>
}

export default DistributionsPage;