import React from "react";

import throttle from 'lodash.throttle';
import { SCREEN_WIDTH_CHANGE } from "../../../Consts";
import ContributorsDataView, { IContributorsDataViewProps } from "./ContributorsDataView";


const ContributorsDataViewResizeListener: React.FC<IContributorsDataViewProps> = (props) => {
    const [showContributorTabsAsDropdown, setShowContributorTabsAsDropdown] = React.useState(false);
    React.useEffect(() => {
        window.addEventListener("resize", throttle(checkScreenWidth, 300));
        checkScreenWidth();
        return  window.removeEventListener("resize", throttle(checkScreenWidth, 300));
    }, []);
    const checkScreenWidth = () => {
        const { innerWidth } = window;
        const innerWidthIsLessThanScreenWidthChange = innerWidth < SCREEN_WIDTH_CHANGE
        setShowContributorTabsAsDropdown(innerWidthIsLessThanScreenWidthChange)
    };
    return <ContributorsDataView {...props} showContributorTabsAsDropdown={showContributorTabsAsDropdown} />
    
}
export default ContributorsDataViewResizeListener;