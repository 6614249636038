import * as React from "react";

export interface IPaginationViewProps {
    contentsLength: number;
    resultsPerPage: number;
    currentPage: any;
    updateContentsTable: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: any, repertoireSection: string) => void;
    repertoireSection?: string;
}

export default class PaginationView extends React.Component<IPaginationViewProps>{
    constructor(props: IPaginationViewProps) {
        super(props);
    }
    
    renderOptions = () => {
        const { contentsLength } = this.props;
        const options = [
            <option key={0}>10</option>,
            <option key={1}>25</option>,
            <option key={2}>50</option>,
            <option key={3}>100</option>,
            <option key={4}>250</option>,
            <option key={5}>500</option>,
            <option key={6}>1000</option>
        ];

        if (contentsLength > 1000) {
            return options;
        }else if (contentsLength > 500) {
            return options.slice(0, 7);
        } else if (contentsLength > 250) {
            return options.slice(0, 6);
        } else if (contentsLength > 100) {
            return options.slice(0, 5);
        } else if (contentsLength > 50) {
            return options.slice(0, 4);
        } else if (contentsLength> 25) {
            return options.slice(0, 3);
        } else if (contentsLength > 10) {
            return options.slice(0,2);
        }

        return null;
    };

    changeResultsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setPageNumber(this.props.currentPage, Number(event.target.value));
    };

    getNumberOfPages = () => {
        const { contentsLength, resultsPerPage } = this.props;
        return Math.ceil(contentsLength / resultsPerPage);
    };

    changePageNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pageNumber = event.currentTarget.value;
        const { resultsPerPage } = this.props;
        this.setPageNumber(pageNumber, resultsPerPage);
    };

    setPageNumber = (newPageNumber: any, resultsPerPage: number) => {
        const { contentsLength, repertoireSection } = this.props;
        if (newPageNumber == "") {
            this.props.updateContentsTable(0, resultsPerPage, resultsPerPage, "", repertoireSection)
            return;
        }
        
        newPageNumber = Number(newPageNumber)
        if(!newPageNumber){
            return;
        }

        const numberOfPages = Math.ceil(contentsLength / resultsPerPage);

        if (newPageNumber >= numberOfPages) {
            newPageNumber = numberOfPages;
        }
        else if (newPageNumber < 1) {
            newPageNumber = 1
        }

        const indexOfLastResult = newPageNumber * resultsPerPage;
        const indexOfFirstResult = indexOfLastResult - resultsPerPage;
        this.props.updateContentsTable(indexOfFirstResult, indexOfLastResult, resultsPerPage, newPageNumber, repertoireSection);
    }

    increasePageNumber = () => {
        const { currentPage, resultsPerPage} = this.props;
        this.setPageNumber(currentPage + 1, resultsPerPage);
    };

    decreasePageNumber = () => {
        const { currentPage, resultsPerPage } = this.props;
        if (currentPage == "" || currentPage == 1) { return; }
        this.setPageNumber(currentPage - 1, resultsPerPage);
    };

    renderPageNumbers = () => {
        const { currentPage } = this.props;
        const previous = "Prev";
        const next = "Next";
        return (
            <div className="pageNumberDiv">

                <div className="pageNumberButton" onClick={this.decreasePageNumber}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" enableBackground="new 0 0 129 129" className="pageNumberPrevIcon"><g><path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"></path></g></svg>
                    <div>{previous}</div>
                </div>
                <div className="inputDiv">
                    <input
                        className="pageNumberInput"
                        type={"text"}
                        value={currentPage}
                        onChange={this.changePageNumber}
                    />
                    of {this.getNumberOfPages()}
                </div>
                <div className="pageNumberButton" onClick={this.increasePageNumber}>
                    <div>{next}</div>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129" enableBackground="new 0 0 129 129" className="pageNumberNextIcon"><g><path d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"></path></g></svg>

                </div>
            </div>
        );
    };

    render() {
        const { contentsLength, resultsPerPage } = this.props;

        return (
            <div className="paginationDiv">
                <div className="showPerPageDiv">
                    <div className="resultsNumberDiv">
                        {contentsLength} Results
                    </div>
                    {this.renderOptions() !== null && (
                        <div>
                            Show:
                            <select value={resultsPerPage}
                                onChange={event => this.changeResultsPerPage(event)}
                                className="resultsPerPageSelect"
                            >
                                {this.renderOptions()}
                            </select>
                            per page
                        </div>
                    )}
                </div>
                {this.getNumberOfPages() > 1 && <div>{this.renderPageNumbers()}</div>}
            </div>
        );
    }

}