import React, { Component } from "react";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import IconTextButton from "../../../repertoire/components/iconTextButton/IconTextButton";
import { TOOLBAR_POSITION_BREAKPOINT } from "../../../repertoire/Consts";
import { IDataActionToolbar } from "../../../repertoire/types/IDataActionToolbar";
import { DOWNLOAD_SCHEMA_METHOD_TOOLBAR, REFRESH_METHOD_TOOLBAR } from "../../Consts";

export interface IToolbarComponentProps {
    scroll?: number;
    toolbarWidth: number;
    isReadonly?: boolean;
    dataActionToolbar: IDataActionToolbar[];
    component: string;
}

export interface IToolbarState {
    loaded: boolean;
}

class ToolbarComponent extends Component<
    IToolbarComponentProps,
    IToolbarState
>{
    actionData;

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };
        this.actionData = {};
    }

    componentDidMount() {
        const {
            dataActionToolbar,
        } = this.props;
        const { loaded } = this.state;

        dataActionToolbar.forEach(item => {
            let itemName = item.method;
            let itemData = this.getToolbarActionData(item);
            this.actionData[itemName] = itemData;
        });

        if (!loaded) this.checkIfLoaded();
    }

    componentDidUpdate() {
        const { loaded } = this.state;
        if (!loaded) this.checkIfLoaded();
    }

    checkIfLoaded = () => {
        const {
            dataActionToolbar,
        } = this.props;

        if (Object.keys(this.actionData).length >=  dataActionToolbar.length)
            this.setState({ loaded: true });
    };

    getToolbarStyleClasses = () => {
        const { scroll } = this.props;
        return scroll > TOOLBAR_POSITION_BREAKPOINT
            ? "container fixedToolbar" : "container";
    };

    getToolbarActionData = (dataActionToolbar: IDataActionToolbar) => {
        switch (dataActionToolbar.method) {
            case DOWNLOAD_SCHEMA_METHOD_TOOLBAR: case REFRESH_METHOD_TOOLBAR:
                return {
                    action: dataActionToolbar.action,
                    iconPath: dataActionToolbar.icon
                };
            default:
                return {};
        }
    };

    renderToolbarButtons = () => {
        const {
            dataActionToolbar,
        } = this.props;

        return dataActionToolbar.map(item =>
            <div className="toolbarButton" key={item.method}>
                <div className={(item.isReadOnly) ? "toolbarButtonContainer disabled" : "toolbarButtonContainer"}>
                    <IconTextButton
                        text={item.name}
                        icon={item.icon}
                        onClick={item.action}
                        disabled={item.isReadOnly}
                    />
                </div>
            </div>
        )
    };


    render() {
        const { toolbarWidth } = this.props;
        const { loaded } = this.state;
        if (loaded) {
            return (
                <>
                    <div
                        className="toolbarWrapper"
                        id={"toolbar"}
                        style={{ width: toolbarWidth }}
                    >
                        {this.renderToolbarButtons()}
                    </div>
                </>
            );
        }
        return <div />;
    }
}

export default ToolbarComponent;