import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IAgreement } from "../../../types/IAgreement";
import * as React from "react";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { updateAgreementField, addTab, setEditableFields, updateEditableFields, postAgreementBatchRequest } from "../../../../redux/reducers/RepertoireReducer";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { SizedTextDataInput, WideTextDataInput } from "../../../components/textDataInput/TextDataInput";
import {
    IP_BASE_NUMBER_STATE_KEY,
    AGREEMENT_LAST_MODIFIED_DATE_STATE_KEY,
    IP_NAME_NUMBER_STATE_KEY,
    IP_NAME_STATE_KEY,
    SOURCE_STATE_KEY,
    TYPE_STATE_KEY,
    AGREEMENT_CATEGORY_STATE_KEY,
    AGREEMENT_MAINTAINED_STATE_KEY,
    APPLICABLE_WORKS_TYPE_STATE_KEY,
    SPECIFIC_AGREEMENT_TYPE_STATE_KEY,
    SET_TYPE_GROUP_STATE_KEY,
    REPERTOIRE,
    ASSIGNED_SET_TYPES_STATE_KEY,
    SEARCH_VIEW,
    IPS_PAGE,
    CONTRIBUTORS_KEY,
    REPERTOIRE_PAGE_FORM_ITEM_SIZE,
    AGREEMENTS_IP_KEY,
    AGREEMENT_MAINTENANCE_GENERAL_DATA_VIEW,
    EDITABLE_FIELDS_DATA_VIEW,
    TITLE_EDITABLE_FIELDS_VIEW,
    EDITABLE_FIELDS,
    GENERAL_DATA_EDITABLE_FIELDS,
    COMPONENT_INSTANCE_AGREEMENTS,
    AGREEMENT_CREATION_DATE_STATE_KEY,
    AGREEMENT_BATCH_ID_STATE_KEY,
    EMPTY_STRING_VALUE,
    POST_AGREEMENT_BATCH_STATE_KEY,
    CONFIGURE_AGREEMENTS_ROLE,
    ALL_ROLES,
    AGREEMENT_POOL_OPTION_STATE_KEY,
    AGREEMENT_SOURCE_TYPE_STATE_KEY,
    AGREEMENT_SOURCE_IP_STATE_KEY,
    AGREEMENT_SOURCE_IPI_NAME_NUMBER_STATE_KEY,
    WORLD_EXCLUDING_STATE_KEY,
    SET_TYPE_GROUP_EXCLUDING_STATE_KEY
} from "../../../Consts";
import SizedDateDataInput from "../../../components/dateDataInput/DateDataInput";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { SizedCheckboxDataInput } from "../../../components/checkboxDataInput/CheckboxDataInput";
import { getLookupDefault, getLookupDefaultDescription, getLookupDefaultID, getLookupDefaultMultiSelect, getLookupDefaultV2, getLookupID, getLookupValues, searchLookupValueByCode } from "../../../../lookup/services/LookupHelpers";
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import AgreementMaintenanceAccordion from "../agreementMaintenanceAccordion/AgreementMaintenanceAccordion";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IAgreementShareSet } from "../../../types/IAgreementShareSet";
import { IAgreementUsagePool } from "../../../types/IAgreementUsagePool";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IP } from "../../../types/IP";
import ShowMore from "../../../components/showMore/ShowMore";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import {
    AGREEMENT_CATEGORY_LOOKUP,
    AGREEMENT_SOURCE_LOOKUP,
    AGREEMENT_TYPE_LOOKUP,
    APPLICABLE_WORKS_LOOKUP,
    SET_TYPE_GROUP_LOOKUP,
    SET_TYPE_LOOKUP,
    SPECIFIC_AGREEMENT_TYPE_LOOKUP,
    WORK_SOURCE_TYPE_LOOKUP,
} from "../../../../lookup/Consts";
import { If } from "../../../../core/components/If";
import { RepertoireHelper } from "../../../services/RepertoireHelper";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { DataSource } from "../../../types/DataSource";
import WorkBatchInput from "../../../works/components/workBatchInput/WorkBatchInput";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { IAgreementRegion } from "../../../../redux/types/IAgreementRegion";
import { IAgreementSourceIP } from "../../../types/IAgreementSourceIP";
import { ISourceIP } from "../../../types/ISourceIP";


export interface IAgreementMaintenanceGeneralDataViewProps {
    agreementMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    agreementMaintenanceAccordionViewData: IRepertoireComponentDataItem;
    expanderFieldsData: IRepertoireField[];
    updateAgreementField: typeof updateAgreementField;
    updateAgreementIP: (agreementAdminIPID: number, ip: IP) => void;
    openWorkAccordian: (accordionName: string, componentName: string) => void;
    closeWorkAccordian: (accordionName: string, componentName: string) => void;
    showModal: typeof showModal;
    addTab: typeof addTab;
    lookupValues: ILookupDictionary;
    tabs: ITabReduxItem[];
    activeTab: number;
    changesMade: boolean;
    lastModifiedDate: Date;
    ipBaseNumber: string;
    type: string;
    specificWorks: number;
    specificAgreementType: string;
    applicableWorksType: string;
    dataSource: DataSource;
    source: string;
    sourceID: number;
    maintained: boolean;
    effectiveFrom: Date;
    effectiveTo?: Date;
    effectiveNotifiedTo: Date;
    effectiveNotifiedFrom: Date;
    creationDate?: Date;
    territory: string;
    agreementCategory: string;
    setTypeGroup: string;
    salesOrManufacture: number;
    agreementSignatureDate: Date;
    bulkTransferWorks: boolean;
    priorRoyalties: boolean;
    expectedTermination: number;
    expectedTerminationText: string;
    postTermRetentionPeriod: boolean;
    postTermCollectionPeriod: boolean;
    submitterAssignorIPCode: string;
    libraryMusic: boolean;
    recordingPrefix: string;
    worksRetained?: string;
    defaultRetainedUntil: string;
    defaultRetentionEndDate: Date;
    defaultCollectionUntil: string;
    defaultCollectionPeriodEndDate: Date;
    licensedDirect: boolean;
    ipName: string;
    ipNameNumber: number;
    agreementRegion: IAgreementRegion[];
    assignedSetTypes?: string[];
    availableSetTypes?: string[];
    agreementShareSet: IAgreementShareSet[];
    agreementUsagePools: IAgreementUsagePool[];
    agreement: IAgreementState;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableEditableFieldsData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    editableFields: IRepertoireField[];
    hideModal?: typeof hideModal;
    getIPDetails: (ipBaseNumber: string, accountNumber: string) => void;
    agreementBatchID: number;
    isReadonly: boolean;
    postAgreementBatch?: typeof postAgreementBatchRequest;
    agreementBatchOperationInProgress: boolean;
    saveInProgress: boolean;
    roles: string[];
    usagePoolsSearchResults?: IUsagePool[];
    searchUsagePools?: (query: IUsagePoolSearchQuery) => void;
    componentInstance?:string;
    agreementSourceType?: string;
    agreementSourceIP?: ISourceIP;
    agreementSourceIPINameNumber?: number;
    worldExcluding?: boolean;
    useRelationships?:boolean;
}

interface IAgreementMaintenanceGeneralDataViewState {
    loaded: boolean;
    setTypes: any[];
    pools: any[]
    setTypeGroup:any[];
}

export default class AgreementMaintenanceGeneralDataView extends React.PureComponent<
    IAgreementMaintenanceGeneralDataViewProps,
    IAgreementMaintenanceGeneralDataViewState> {
    viewData;
    constructor(props: IAgreementMaintenanceGeneralDataViewProps) {
        super(props);

        this.state = {
            loaded: false,
            pools: [],
            setTypes: [],
            setTypeGroup:[]
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            agreementMaintenanceGeneralDataViewData: { fields }, 
            searchUsagePools
        } = this.props;
        
        const query: IUsagePoolSearchQuery = {
            poolCode: "",
            cat1: "",
            cat2: "",
            allocationRule: ""
        }
        searchUsagePools(query)

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }

        initializeIcons();
    }

    obtainAgreementSetTypes(agreementShareSet: IAgreementShareSet[]) {
        let setTypes = [];



        return setTypes;
    }
    showSearchModal = (modalProps: any) => {
        const { showModal } = this.props;
        showModal(SEARCH_VIEW, AGREEMENTS_IP_KEY, modalProps, true, "Search");
    };

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], AGREEMENT_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_AGREEMENTS, AGREEMENT_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableEditableFieldsData,
            setEditableFields,
        } = this.props;

        setEditableFields(AGREEMENT_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_AGREEMENTS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableEditableFieldsData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    render() {
        const {
            lastModifiedDate,
            ipBaseNumber,
            type,
            specificAgreementType,
            applicableWorksType,
            dataSource,
            sourceID,
            maintained,
            agreementCategory,
            ipName,
            ipNameNumber,
            lookupValues,
            agreementMaintenanceGeneralDataViewData,
            agreementMaintenanceAccordionViewData,
            expanderFieldsData,
            updateAgreementField,
            tabs,
            activeTab,
            effectiveFrom,
            effectiveTo,
            effectiveNotifiedTo,
            effectiveNotifiedFrom,
            salesOrManufacture,
            agreementSignatureDate,
            bulkTransferWorks,
            priorRoyalties,
            expectedTermination,
            expectedTerminationText,
            postTermRetentionPeriod,
            postTermCollectionPeriod,
            libraryMusic,
            recordingPrefix,
            worksRetained,
            defaultRetainedUntil,
            defaultRetentionEndDate,
            defaultCollectionUntil,
            defaultCollectionPeriodEndDate,
            licensedDirect,
            useRelationships,
            openWorkAccordian,
            closeWorkAccordian,
            agreementShareSet,
            agreementUsagePools,
            agreementRegion,
            agreement,
            getIPDetails,
            creationDate,
            changesMade,
            agreementBatchID,
            postAgreementBatch,
            agreementBatchOperationInProgress,
            isReadonly,
            saveInProgress,
            usagePoolsSearchResults,
            componentInstance,
            agreementSourceType,
            agreementSourceIP,
            agreementSourceIPINameNumber,
            worldExcluding
        } = this.props;
        const { loaded, setTypes, setTypeGroup, pools} = this.state;
        const changeData = (value: any, name: IRepertoireStateKeys) => {
            this.props.updateAgreementField(value, name as IAgreementStateKeys);
        }

        const multiSelectChangeData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
            this.props.updateAgreementField(option.data, "agreementShareSet");
            if (!setTypes.includes(option.key)) {
                setTypes.push(option.key)
            }
            else {
                const index = setTypes.indexOf(option.key);
                if (index > -1) {
                    setTypes.splice(index, 1);
                }
            }
        }

        const multiSelectChangeRegionData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
            this.props.updateAgreementField(option.data, "agreementRegion");
           
                if (!setTypeGroup.includes(option.key)) {
                    setTypeGroup.push(option.key)
                }
                else {
                    const index = setTypeGroup.indexOf(option.key);
                    if (index > -1) {
                        setTypeGroup.splice(index, 1);
                    }
                }
            
        }

        const multiSelectChangePool = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
            this.props.updateAgreementField(option.data, "agreementUsagePools")
            if (!pools.includes(option.key)) {
                pools.push(option.key)
            }
            else {
                const index = pools.indexOf(option.key);
                if (index > -1) {
                    pools.splice(index, 1);
                }
            }
        }

        const filteredFields = agreementMaintenanceAccordionViewData.fields;
        const lookupVals = getLookupValues(SET_TYPE_LOOKUP, lookupValues);
        const lookupValsRegion= getLookupValues(SET_TYPE_GROUP_LOOKUP, lookupValues);

        if (agreementShareSet) {
            agreementShareSet.forEach(item => {
                if (lookupVals && lookupVals.length > 0) {
                    const id = lookupVals?.filter(x => x.code === item.setType)[0]?.typeID;
                    if (id && !setTypes.includes(id)) {
                        setTypes.push(id);
                    };
                }
            });
        }

        if (agreementRegion){
            agreementRegion.forEach(item => {
                if (lookupValsRegion && lookupValsRegion.length > 0) {
                const id = lookupValsRegion.filter(x => x.code === item.setTypeGroup)[0]?.typeID;
                if (id && !setTypeGroup.includes(id)) {
                    setTypeGroup.push(id);
                };
            }
            });
        }

        if (agreementUsagePools) {
            agreementUsagePools.forEach(item => {
                if(item.usagePoolId){
                    if (!pools.includes(item.usagePoolId)) {
                        pools.push(item.usagePoolId);
                    };
                }
            });
        }

        let dropdownRegionOptions = [];
        if(lookupValsRegion){
            lookupValsRegion.forEach(x => dropdownRegionOptions.push({ key: x?.typeID, text: x.description, data: x.code }));
        }

        let dropdownOptions = [];
        let pooldropdownOptions = [];
        if (usagePoolsSearchResults && pooldropdownOptions.length === 0){
        pooldropdownOptions = [...new Set(usagePoolsSearchResults)].map(pool => { return { key: pool.poolId, text: pool.poolCode, data: pool.poolId } }).sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: 'base' }));
        }
        const newLookupValues = lookupVals.filter(x => x?.typeID && x?.typeID < 0 && x.code.toUpperCase() !== REPERTOIRE.toUpperCase())

        newLookupValues && newLookupValues.length > 0 && newLookupValues.forEach(x => dropdownOptions.push({ key: x?.typeID, text: x.description, data: x.code }));
        const displayDotsMenu = this.viewData[IP_BASE_NUMBER_STATE_KEY];

        const renderPostBatch = (): JSX.Element => {
            if (dataSource === DataSource.Intray) {
                return <WorkBatchInput
                    label={this.viewData[AGREEMENT_BATCH_ID_STATE_KEY]}
                    fieldName={AGREEMENT_BATCH_ID_STATE_KEY}
                    value={agreementBatchID}
                    changeData={changeData}
                    postButtonLabel={this.viewData[POST_AGREEMENT_BATCH_STATE_KEY]}
                    postButtonAction={() => postAgreementBatch(agreementBatchID)}
                    matchButtonLabel={null}
                    matchButtonAction={() => true}
                    readOnly={isReadonly}
                    isEnabled={!changesMade && !saveInProgress && !agreementBatchOperationInProgress && !isReadonly}
                />
            }
            else {
                return null;
            }
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = this.props;
            if (roles && (roles.includes(CONFIGURE_AGREEMENTS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const getMandatoryFlag = (fieldName: string) => {
            let filteredFields = agreementMaintenanceGeneralDataViewData.fields.filter(field => field.name == fieldName);
            if (filteredFields && filteredFields.length > 0) {
                return filteredFields[0].isMandatory;
            } else {
                return false;
            }
        }

        const matchingEngineSource = () => {
            if (ipBaseNumber == null){
                agreement.source = "MANUAL";
                agreement.sourceID = getLookupID(AGREEMENT_SOURCE_LOOKUP, lookupValues, "MANUAL")
                return searchLookupValueByCode(AGREEMENT_SOURCE_LOOKUP, lookupValues, "MANUAL")
            }
            else{
                return searchLookupValueByCode(AGREEMENT_SOURCE_LOOKUP, lookupValues, agreement.source)
            }
        }

        if (loaded) {
            if (filteredFields && filteredFields.length > 0) {
                return (             
                        <div className="">
                            <div className="row">
                                <div className="col-md-12 editableSectionHeading">
                                    <h1 className="borderBootom">General</h1>
                                    <button className="editBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)} disabled={getConfigureRoleDisabled()}>
                                        <i className="ms-Icon ms-Icon--Settings"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>
                                        The
                                        <b style={{ color: "#cd0303", margin: "5px", fontWeight: 600 }}>
                                            *
                                        </b>
                                        indicates a mandatory field.
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <SizedTextDataInput
                                    label={this.viewData[IP_BASE_NUMBER_STATE_KEY]}
                                    fieldName={IP_BASE_NUMBER_STATE_KEY}
                                    changeData={changeData}
                                    value={ipBaseNumber}
                                    isHidden={!this.viewData[IP_BASE_NUMBER_STATE_KEY]}
                                    readOnly={isReadonly}
                                    isMandatory
                                    objectKey={componentInstance}
                                />

                                <If condition={this.viewData && this.viewData[IP_BASE_NUMBER_STATE_KEY]}>
                                    <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE} style={{ marginTop: 35,paddingLeft: 0 }}>
                                        {ipBaseNumber === null ? <ShowMore
                                            options={[
                                                {
                                                    text: expanderFieldsData.find(e => e.name === "selectIP").data,
                                                    onClick: () => { this.showSearchModal(agreement); },
                                                    icon: "assets/external.svg"
                                                }
                                            ]}
                                        /> : <ShowMore
                                            options={[
                                                {
                                                    text: expanderFieldsData.find(e => e.name === "openIP").data,
                                                    onClick: () => { this.props.getIPDetails(ipBaseNumber, null) },
                                                    icon: "assets/external.svg"
                                                },
                                                {
                                                    text: expanderFieldsData.find(e => e.name === "selectIP").data,
                                                    onClick: () => { this.showSearchModal(agreement); },
                                                    icon: "assets/external.svg"
                                                }
                                            ]}
                                        />}



                                    </div>
                                </If>
                            </div>
                            <div className="row">
                                <SizedTextDataInput
                                    label={this.viewData[IP_NAME_NUMBER_STATE_KEY]}
                                    fieldName={IP_NAME_NUMBER_STATE_KEY}
                                    changeData={null}
                                    value={RepertoireHelper.zeroPaddedIPINameNumber(ipNameNumber)}
                                    readOnly={isReadonly || true}
                                    isHidden={!this.viewData[IP_NAME_NUMBER_STATE_KEY]}
                                    isMandatory={getMandatoryFlag(IP_NAME_NUMBER_STATE_KEY)}
                                />

                                <SizedTextDataInput
                                    label={this.viewData[IP_NAME_STATE_KEY]}
                                    fieldName={IP_NAME_STATE_KEY}
                                    changeData={null}
                                    value={ipName}
                                    readOnly={isReadonly || true}
                                    isHidden={!this.viewData[IP_NAME_STATE_KEY]}
                                    isMandatory={getMandatoryFlag(IP_NAME_STATE_KEY)}
                                />
                            </div>
                            <div className="row">
                                <SizedDateDataInput
                                    label={this.viewData[AGREEMENT_LAST_MODIFIED_DATE_STATE_KEY]}
                                    fieldName={AGREEMENT_LAST_MODIFIED_DATE_STATE_KEY}
                                    changeData={changeData}
                                    value={lastModifiedDate ? lastModifiedDate.toString() : null}
                                    isHidden={!this.viewData[AGREEMENT_LAST_MODIFIED_DATE_STATE_KEY]}
                                    readOnly={true}
                                    isMandatory={getMandatoryFlag(AGREEMENT_LAST_MODIFIED_DATE_STATE_KEY)}
                                />

                                <SizedDateDataInput
                                    label={this.viewData[AGREEMENT_CREATION_DATE_STATE_KEY]}
                                    fieldName={AGREEMENT_CREATION_DATE_STATE_KEY}
                                    changeData={changeData}
                                    value={creationDate ? creationDate.toString() : null}
                                    isHidden={!this.viewData[AGREEMENT_CREATION_DATE_STATE_KEY]}
                                    readOnly={true}
                                    isMandatory={getMandatoryFlag(AGREEMENT_CREATION_DATE_STATE_KEY)}
                                />
                            </div>
                            <hr />
                            <div className="row">
                                <If condition={agreement.agreementSourceIP != null}>
                                    <SizedTextDataInput
                                        label={this.viewData[SOURCE_STATE_KEY]}
                                        fieldName={SOURCE_STATE_KEY}
                                        changeData={changeData}
                                        value={getLookupDefaultDescription(AGREEMENT_SOURCE_LOOKUP, lookupValues)}
                                        isHidden={!this.viewData[SOURCE_STATE_KEY]}
                                        readOnly={true}
                                    />
                                </If>
                                <If condition={agreement.source != getLookupDefault(AGREEMENT_SOURCE_LOOKUP, lookupValues) || (agreement.agreementSourceIP == null)}>
                                    <SizedDropdownDataInput
                                        label={this.viewData[SOURCE_STATE_KEY]}
                                        fieldName={SOURCE_STATE_KEY}
                                        value={matchingEngineSource()}
                                        changeData={changeData}
                                        options={getLookupValues(AGREEMENT_SOURCE_LOOKUP, lookupValues)}
                                        allowNull={false}
                                        readOnly={isReadonly}
                                        isHidden={!this.viewData[SOURCE_STATE_KEY]}
                                        isMandatory={getMandatoryFlag(SOURCE_STATE_KEY)}
                                    />
                                </If>

                                <SizedCheckboxDataInput
                                    label={this.viewData[AGREEMENT_MAINTAINED_STATE_KEY]}
                                    fieldName={AGREEMENT_MAINTAINED_STATE_KEY}
                                    value={maintained}
                                    changeData={changeData}
                                    isHidden={!this.viewData[AGREEMENT_MAINTAINED_STATE_KEY]}
                                    readonly={isReadonly}
                                />
                            </div>
                            
                            <div className="row">
                                <SizedDropdownDataInput
                                    label={this.viewData[AGREEMENT_SOURCE_TYPE_STATE_KEY]}
                                    fieldName={AGREEMENT_SOURCE_TYPE_STATE_KEY}
                                    value={agreementSourceType ? agreementSourceType : getLookupDefaultV2(AGREEMENT_SOURCE_TYPE_STATE_KEY, WORK_SOURCE_TYPE_LOOKUP, lookupValues, agreementMaintenanceGeneralDataViewData)}
                                    changeData={changeData}
                                    options={getLookupValues(WORK_SOURCE_TYPE_LOOKUP, lookupValues)}
                                    allowNull={true}
                                    readOnly={isReadonly}
                                    isHidden={!this.viewData[AGREEMENT_SOURCE_TYPE_STATE_KEY]}
                                />

                                <SizedTextDataInput
                                    label={this.viewData[AGREEMENT_SOURCE_IP_STATE_KEY]}
                                    fieldName={AGREEMENT_SOURCE_IP_STATE_KEY}
                                    changeData={changeData}
                                    value={agreementSourceIP ? agreementSourceIP.fullName.value : null}
                                    isHidden={!this.viewData[AGREEMENT_SOURCE_IP_STATE_KEY]}
                                    readOnly={true}
                                />

                                <SizedTextDataInput
                                    label={this.viewData[AGREEMENT_SOURCE_IPI_NAME_NUMBER_STATE_KEY]}
                                    fieldName={AGREEMENT_SOURCE_IPI_NAME_NUMBER_STATE_KEY}
                                    changeData={changeData}
                                    value={agreementSourceIP ? agreementSourceIP.ipiNameNumber : null}
                                    isHidden={!this.viewData[AGREEMENT_SOURCE_IPI_NAME_NUMBER_STATE_KEY]}
                                    readOnly={true}
                                />
                            </div>

                            <div className="row">                                
                                <SizedDropdownDataInput
                                    label={this.viewData[TYPE_STATE_KEY]}
                                    fieldName={TYPE_STATE_KEY}
                                    value={type ? type : getLookupDefaultV2(TYPE_STATE_KEY, AGREEMENT_TYPE_LOOKUP, lookupValues, agreementMaintenanceGeneralDataViewData)}
                                    changeData={changeData}
                                    options={getLookupValues(AGREEMENT_TYPE_LOOKUP, lookupValues)}
                                    allowNull={false}
                                    isHidden={!this.viewData[TYPE_STATE_KEY]}
                                    readOnly={isReadonly}
                                    isMandatory={getMandatoryFlag(TYPE_STATE_KEY)}
                                />

                                <SizedDropdownDataInput
                                    label={this.viewData[AGREEMENT_CATEGORY_STATE_KEY]}
                                    fieldName={AGREEMENT_CATEGORY_STATE_KEY}
                                    value={agreementCategory ? agreementCategory : getLookupDefaultV2(AGREEMENT_CATEGORY_STATE_KEY, AGREEMENT_CATEGORY_LOOKUP, lookupValues, agreementMaintenanceGeneralDataViewData)}
                                    changeData={changeData}
                                    options={getLookupValues(AGREEMENT_CATEGORY_LOOKUP, lookupValues)}
                                    allowNull={false}
                                    isHidden={!this.viewData[AGREEMENT_CATEGORY_STATE_KEY]}
                                    readOnly={isReadonly}
                                    isMandatory={getMandatoryFlag(AGREEMENT_CATEGORY_STATE_KEY)}
                                />
                            </div>
                            {type == "2" ?
                                <div className="row">
                                    <SizedDropdownDataInput
                                        label={this.viewData[APPLICABLE_WORKS_TYPE_STATE_KEY]}
                                        fieldName={APPLICABLE_WORKS_TYPE_STATE_KEY}
                                        value={applicableWorksType ? applicableWorksType : getLookupDefaultV2(APPLICABLE_WORKS_TYPE_STATE_KEY, APPLICABLE_WORKS_LOOKUP, lookupValues, agreementMaintenanceGeneralDataViewData)}
                                        changeData={changeData}
                                        options={getLookupValues(APPLICABLE_WORKS_LOOKUP, lookupValues)}
                                        allowNull={true}
                                        isHidden={!this.viewData[APPLICABLE_WORKS_TYPE_STATE_KEY]}
                                        readOnly={isReadonly}
                                        isMandatory={getMandatoryFlag(APPLICABLE_WORKS_TYPE_STATE_KEY)}
                                    />

                                    <SizedDropdownDataInput
                                        label={this.viewData[SPECIFIC_AGREEMENT_TYPE_STATE_KEY]}
                                        fieldName={SPECIFIC_AGREEMENT_TYPE_STATE_KEY}
                                        value={specificAgreementType ? specificAgreementType : getLookupDefaultV2(SPECIFIC_AGREEMENT_TYPE_STATE_KEY, SPECIFIC_AGREEMENT_TYPE_LOOKUP, lookupValues, agreementMaintenanceGeneralDataViewData)}
                                        changeData={changeData}
                                        options={getLookupValues(SPECIFIC_AGREEMENT_TYPE_LOOKUP, lookupValues)}
                                        allowNull={true}
                                        isHidden={!this.viewData[SPECIFIC_AGREEMENT_TYPE_STATE_KEY]}
                                        readOnly={isReadonly}
                                        isMandatory={true}
                                    />
                                </div>
                                : <div></ div>}
                            <div className="row">
                                <SizedCheckboxDataInput
                                    label={this.viewData[WORLD_EXCLUDING_STATE_KEY]}
                                    fieldName={WORLD_EXCLUDING_STATE_KEY}
                                    value={worldExcluding}
                                    changeData={changeData}
                                    isHidden={!this.viewData[WORLD_EXCLUDING_STATE_KEY]}
                                    readonly={isReadonly}
                                />
                                <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>                                    
                                <Dropdown
                                    label={worldExcluding ? this.viewData[SET_TYPE_GROUP_EXCLUDING_STATE_KEY] : this.viewData[SET_TYPE_GROUP_STATE_KEY]}
                                    selectedKeys={setTypeGroup ? setTypeGroup as any : getLookupDefaultMultiSelect(lookupValues, SET_TYPE_GROUP_LOOKUP, SET_TYPE_GROUP_STATE_KEY, agreementMaintenanceGeneralDataViewData)}
                                    options={dropdownRegionOptions}
                                    multiSelect={true}
                                    onChange={multiSelectChangeRegionData}
                                    placeholder={"No Region selected."}
                                    styles={
                                        {
                                            title: { height: 40, backgroundColor: "WhiteSmoke", borderWidth: 1, borderColor: "LightGray", padding: 4 }
                                        }
                                    }
                                    disabled={isReadonly}
                                />
                                </div>
                                <If condition={this.viewData && this.viewData[ASSIGNED_SET_TYPES_STATE_KEY]}>
                                    <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                                        <Dropdown
                                            className={EMPTY_STRING_VALUE.concat(["ADM", "BO", "PUB", "SPB", "SPG"].includes(agreementCategory) ?
                                                " required " : getMandatoryFlag(ASSIGNED_SET_TYPES_STATE_KEY) ?
                                                    " required " : "",
                                                isReadonly ? " disabledItem " : "")}
                                            label={this.viewData[ASSIGNED_SET_TYPES_STATE_KEY]}
                                            selectedKeys={setTypes.length > 0 ? setTypes : getLookupDefaultMultiSelect(lookupValues, SET_TYPE_LOOKUP, ASSIGNED_SET_TYPES_STATE_KEY, agreementMaintenanceGeneralDataViewData)}
                                            options={dropdownOptions}
                                            multiSelect={true}
                                            onChange={multiSelectChangeData}
                                            placeholder={"No Set Types selected."}
                                            styles={
                                                {
                                                    title: { height: 40, backgroundColor: "WhiteSmoke", borderWidth: 1, borderColor: "LightGray", padding: 4 }
                                                }
                                            }
                                            disabled={isReadonly}
                                        />
                                    </div>
                                </If>
                            </div>
                            {type==="2"? 
                                <div  className="row">
                                    <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                                    <Dropdown
                                        label={this.viewData[AGREEMENT_POOL_OPTION_STATE_KEY]}
                                        selectedKeys={pools}
                                        options={pooldropdownOptions}
                                        multiSelect={true}
                                        onChange={multiSelectChangePool}
                                        placeholder={"No Pools selected."}
                                    />
                                    </div>
                                </div>
                            : <div></div>}
                            <If condition={this.props.dataSource === DataSource.Intray}>
                                <div className="row">
                                    {renderPostBatch()}
                                </div>
                            </If>
                            <div className="row">
                              
                                    <AgreementMaintenanceAccordion
                                        agreementMaintenanceGeneralDataViewData={agreementMaintenanceGeneralDataViewData}
                                        updateAgreementField={updateAgreementField}
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        effectiveFrom={effectiveFrom}
                                        effectiveTo={effectiveTo}
                                        effectiveNotifiedTo={effectiveNotifiedTo}
                                        effectiveNotifiedFrom={effectiveNotifiedFrom}
                                        salesOrManufacture={salesOrManufacture}
                                        agreementSignatureDate={agreementSignatureDate}
                                        bulkTransferWorks={bulkTransferWorks}
                                        priorRoyalties={priorRoyalties}
                                        expectedTermination={expectedTermination}
                                        expectedTerminationText={expectedTerminationText}
                                        postTermRetentionPeriod={postTermRetentionPeriod}
                                        postTermCollectionPeriod={postTermCollectionPeriod}
                                        libraryMusic={libraryMusic}
                                        recordingPrefix={recordingPrefix}
                                        worksRetained={worksRetained}
                                        defaultRetainedUntil={defaultRetainedUntil}
                                        defaultRetentionEndDate={defaultRetentionEndDate}
                                        defaultCollectionUntil={defaultCollectionUntil}
                                        defaultCollectionPeriodEndDate={defaultCollectionPeriodEndDate}
                                        licensedDirect={licensedDirect}
                                        useRelationships={useRelationships}
                                        useRelationshipsReadOnly={agreement.useRelationshipsReadOnly}
                                        field={filteredFields[0]}
                                        lookupValues={lookupValues}
                                        openWorkAccordian={openWorkAccordian}
                                        closeWorkAccordian={closeWorkAccordian}
                                        isReadonly={isReadonly}
                                        licensedDirectReadOnly={agreement.agreementCategory === 'CO' ? true : agreement.licensedDirectReadOnly}
                                    />
                             
                            </div>
                        </div>
                    )
                    
                
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
}
