import * as React from "react";

export interface IContributorsDataViewButtonProps {
  text: string;
  onClick: () => void;
  active: boolean;
}

const ContributorsDataViewButton: React.SFC<
  IContributorsDataViewButtonProps
> = ({ text, onClick, active }) => {
  return (
    <div className={["carouselButton", active && "activeItem"].join(" ")}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default ContributorsDataViewButton;
