export interface IJobSearchState {
    jobType: string;
    jobStatus: string;
    user: string;
    customDateRange: boolean;
    dateRange?: string;
    dateFrom?: Date;
    dateTo?: Date;
    withReference?: string;
}

export type IJobSearchStateKeys = keyof IJobSearchState;

export const JOB_TYPE: IJobSearchStateKeys = "jobType";
export const JOB_STATUS: IJobSearchStateKeys = "jobStatus";
export const JOB_USER: IJobSearchStateKeys = "user";
export const JOB_CUSTOM_DATE_RANGE: IJobSearchStateKeys = "customDateRange";
export const JOB_DATE_RANGE: IJobSearchStateKeys = "dateRange";
export const JOB_DATE_FROM: IJobSearchStateKeys = "dateFrom";
export const JOB_DATE_TO: IJobSearchStateKeys = "dateTo";
export const WITH_REFERENCE: IJobSearchStateKeys = "withReference";